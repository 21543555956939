<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-left: 285px"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px"
      />
    </div>
    <div style="height: 8rem"></div>
    <div class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0 pb-32">
      <div class="flex flex-row mt-32 lg:mt-0 justify-center">
        <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
          <h1
            class="
              text-6xl
              tracking-tighter
              font-medium
              text-center
              print:text-7xl
            "
            style="color: #201747"
          >
            <span v-if="access2X">Deals</span>
          </h1>
          <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
            <ul
              class="
                leading-7
                font-medium
                gap-10
                text-justify
                leading-7
                font-medium
                text-gray-600
              "
              style="list-style-type: disc"
            >
              <li>
                Ingest investor interests and intelligently match/ heat map
                interests with potential investees and coinvestors
              </li>
              <li>
                Filter, view, and save deals and potential co-investors and
                monitor progress toward deals
              </li>
              <li>
                Opt in/out of sharing investment interests/ being matched with
                other co-investors
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <div class="mt-8 grid grid-cols-3 gap-4">
        <!-- Portfolio Card -->
        <router-link to="/2X-Ecosystem/my-portfolio">
          <div
            class="container_foto container_foto-2x-gender long-text"
            :class="
              user &&
              user.organization &&
              user.organization.portfolioAccess == false
                ? 'greyd-out'
                : ''
            "
          >
            <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
            <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/magnifying.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>View Your Portfolio</h2>
              <h4>
                View how gender smart your portfolio is, aligned with the 2X
                Criteria.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/BusinessImpacts.png"
              alt=""
            />
          </div>
        </router-link>
        <!-- Portfolio Card End -->

        <!-- Deals Card -->
        <router-link
          :to="
            dealPipelineStatus.status
              ? '/2X-Ecosystem/2X-Matchmaking'
              : '/2X-Ecosystem/2X-Deals'
          "
          v-if="access2X && user.sophisticatedInvestor"
        >
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
            <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left article_cols_two">
              <img
                src="@/assets/images/Writing-removebg.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>View Intelligent Deal Matches</h2>
              <h4 style="font-size: 13px">
                View current and intelligently suggested potential deals,
                investees, and co-investors that meet your investment criteria.
                Save your favorite deals and upvote/ downvote to crowd-source
                opinions on deals.
              </h4>
            </article>
            <img class="bg-shade" src="@/assets/images/community.png" alt="" />
          </div>
        </router-link>
        <div class="container_foto container_foto-2x-gender long-text" v-else>
          <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
          <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
          <article class="text-left">
            <img
              src="@/assets/images/Writing-removebg.png"
              alt="visibility-icon"
              class="card-icon"
            />
            <h2>View Intelligent Deal Matches</h2>
            <h4 style="font-size: 13px">
              Do you want access to the deal-making platform and directory to be
              matched with companies, funds, and FIs looking for investment?
              Gain access by
              <a
                href="https://www.2xcollaborative.org/membership"
                style="color: cornflowerblue"
                target="_blank"
                ><u><strong> a 2X Global member</strong></u></a
              >
              as part of your membership benefits, or pay an annual access fee
              to Equilo to join the
              <a
                href="https://www.equilo.io/2xassessmentservices"
                style="color: cornflowerblue"
                target="_blank"
                ><u><strong> 2X Deal platform</strong></u></a
              >
              independent of 2X.
            </h4>
          </article>
          <img class="bg-shade" src="@/assets/images/community.png" alt="" />
        </div>
        <!-- Deals Card End -->

        <!-- 2X Investee Directory -->
        <router-link
          to="/2X-Ecosystem/2XDirectory"
          v-if="access2X && user.sophisticatedInvestor"
        >
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
            <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/community.png"
                alt="visibility-icon"
                class="card-icon"
              />

              <h2>View Directory of Deals</h2>
              <h4>
                This searchable directory of current and potential 2X investees
                is accessible to 2X Global members. View 2X eligibility
                and 2X Criteria for organizations who have completed 2X
                Assessments and granted permission to share.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/2X-investeeDirectory.png"
              alt=""
            />
          </div>
        </router-link>
        <div class="container_foto container_foto-2x-gender long-text" v-else>
          <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
          <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
          <article class="text-left">
            <img
              src="@/assets/images/community.png"
              alt="visibility-icon"
              class="card-icon"
            />

            <h2>View Directory of Deals</h2>
            <h4>
              Do you want access to the deal-making platform and directory to be
              matched with companies, funds, and FIs looking for investment?
              Gain access by
              <a
                href="https://www.2xcollaborative.org/membership"
                style="color: cornflowerblue"
                target="_blank"
                ><u><strong> a 2X Global member</strong></u></a
              >
              as part of your membership benefits, or pay an annual access fee
              to Equilo to join the
              <a
                href="https://www.equilo.io/2xassessmentservices"
                style="color: cornflowerblue"
                target="_blank"
                ><u><strong> 2X Deal platform</strong></u></a
              >
              independent of 2X.
            </h4>
          </article>
          <img
            class="bg-shade"
            src="@/assets/images/2X-assessment/2X-investeeDirectory.png"
            alt=""
          />
        </div>
        <!-- End 2X Investee Directory -->

        <!-- 2X Co-Investor & Partner Directory -->
        <router-link
          to="/2X-Ecosystem/co-investor-and-partner"
          v-if="access2X && user.sophisticatedInvestor"
        >
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
            <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/community.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>View Directory of Co-Investors</h2>
              <h4>
                Use this searchable directory to find 2X co-investors, partners,
                and technical service providers for collaboration. Those
                included here have chosen to be listed and have consented to
                share their their information with a broader investor and
                partner community.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/community-platform.png"
              alt=""
            />
          </div>
        </router-link>
        <div class="container_foto container_foto-2x-gender long-text" v-else>
          <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
          <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
          <article class="text-left">
            <img
              src="@/assets/images/community.png"
              alt="visibility-icon"
              class="card-icon"
            />
            <h2>View Directory of Co-Investors</h2>
            <h4>
              Do you want access to the deal-making platform and directory to be
              matched with companies, funds, and FIs looking for investment?
              Gain access by
              <a
                href="https://www.2xcollaborative.org/membership"
                style="color: cornflowerblue"
                target="_blank"
                ><u><strong> a 2X Global member</strong></u></a
              >
              as part of your membership benefits, or pay an annual access fee
              to Equilo to join the
              <a
                href="https://www.equilo.io/2xassessmentservices"
                style="color: cornflowerblue"
                target="_blank"
                ><u><strong> 2X Deal platform</strong></u></a
              >
              independent of 2X.
            </h4>
          </article>
          <img
            class="bg-shade"
            src="@/assets/images/2X-assessment/community-platform.png"
            alt=""
          />
        </div>
        <!-- 2X Co-Investor & Partner Directory -->
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
export default {
  name: "twoXDealsSubLanding",
  data: function () {
    return {
      user: [],
      active: false,
      // paid: false,
      gbvpaid: false,
      access2X: false,
      dealPipelineStatus: [],
    };
  },
  async created() {
    this.dealPipelineStatus = await this.checkDealPipelineStatus();
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },
    checkDealPipelineStatus() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/check-deal-pipeline-status", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  },
  computed: {},
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if (this.user.access2x == false) {
      this.$router.push({ path: "/2X-Ecosystem" });
    }
    // if (this.user.organization.subscription.price < 1) {
    //   this.paid = false;
    // } else {
    //   this.paid = true;
    // }
    if (this.user.access2x) {
      this.access2X = true;
    } else {
      this.access2X = false;
    }
    if (env.gbvteams.includes(this.user.organization.name)) {
      this.gbvpaid = true;
    } else {
      this.gbvpaid = false;
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      // { name: "2X Assessment", url: "/2X-Ecosystem" },
      { name: "Deals", url: "/2X-Ecosystem/twoXDeals" },
    ]);
  },
};
</script>

