<template>
    <div class="container">
        <div class="chart">
            <div class="chart__grid">
                <div class="grid__element">
                    <p style="right: -158%">We Need to Talk</p>
                </div>
                <div class="grid__element">
                    <p style="right: -170%">More Work to Do</p>
                </div>
                <div class="grid__element">
                    <p style="right: -139%">Getting there!</p>
                </div>
                <div class="grid__element">
                    <p style="right: -109%">Advancing</p>
                </div>
                <div class="grid__element">
                    <p style="right: -115%">Role Model</p>
                </div>
                <div class="grid__element">
                    <p style="right: -120%">Bonus Level</p>
                </div>
            </div>
            <div class="chart__block" v-for="(score, index) in scoreData" :key="index" v-show="index < 4">
                <div class="block__label flex">
                    <div class="domain-icon tooltip">
                        <img :src="
            score.domain == 'Leadership' ? require('@/assets/images/leadership.svg') :
                score.domain == 'Workforce' ? require('@/assets/images/workforce.svg') :
                    score.domain == 'Value Chains' ? require('@/assets/images/value_chain.svg') :
                        require('@/assets/images/society.svg')" alt="Indirect Investment" />
                        <span class="domain_label_tooltiptext">{{ score.domain }}</span>
                    </div>
                    <span style="width:100%">{{ score.parameter }}</span>
                </div>
                <div class="block__data">
                    <!-- <div class="data">
              <span class="data__bar data__bar--prev" data-width="90"></span>
              <span class="data__number data__number--prev"> {{ (+score.bonusScore + +score.thresholdScore) | toFixedTwo }}</span>
            </div> -->
                    <div class="data">
                        <span class="data__bar data__bar--bonus tooltip"
                            :style="{'width': + ((+score.bonusScore + +score.thresholdScore) / 6 * 100) + '% !important'}"
                            >
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Bonus Score - {{ + score.bonusScore | toFixedTwo}}</span>
                            </span>
                        </span>
                        <span class="data__bar data__bar--curr tooltip"
                            :style="{ 'background-color':  dataBarStyle , 'width': + ((score.thresholdScore) / 6 * 100) + '% !important' }"
                            >
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Threshold Score - {{ + score.thresholdScore | toFixedTwo }}</span>
                            </span>
                        </span>
                        <span class="data__number data__number--curr font-bold" :style="{ 'color':  dataBarStyle }">{{ ((score.bonusScore !== '' ||
                            score.thresholdScore
                            !== '')) ? (Number(+score.bonusScore + +score.thresholdScore).toFixed(2)) :(score.showNotApplicantFlag ? 'Unknown' : 'Not Applicable')
                        }}</span>
                    </div>
                    <div class="data">
                        <span class="data__bar data__bar--bonus tooltip"
                            :style="{'width' : + ((+scoreDataOld[index].bonusScore + +scoreDataOld[index].thresholdScore) / 6 * 100) + '% !important'}"
                            >
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Bonus Score - {{ + scoreDataOld[index].bonusScore | toFixedTwo}}</span>
                            </span>
                        </span>
                        <span class="data__bar data__bar--curr tooltip"
                            :style="{ 'background-color': barStyle , 'width': + ((scoreDataOld[index].thresholdScore) / 6 * 100) + '% !important' }"
                            
                            >
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Threshold Score - {{ + scoreDataOld[index].thresholdScore |
                                toFixedTwo}}</span>
                            </span>
                        </span>
                        <span class="data__number data__number--curr font-bold" :style="{ 'color':  dataBarStyle }">{{
                        (scoreDataOld[index].bonusScore !== '' || scoreDataOld[index].thresholdScore !== '') ?
                            (Number(+scoreDataOld[index].bonusScore + +scoreDataOld[index].thresholdScore).toFixed(2)) :
                           (scoreDataOld[index].showNotApplicantFlag ? 'Unknown' : 'Not Applicable')
                        }}</span>
                    </div>
                    <!-- <div class="data">
              <span class="data__bar data__bar--benchmark"></span>
              <span class="data__number data__number--benchmark">3.5</span>
            </div> -->
                </div>
            </div>
        </div>
        <div class="chart">
            <div class="chart__grid">
                <div class="grid__element">
                    <p style="right: -158%">We Need to Talk</p>
                </div>
                <div class="grid__element">
                    <p style="right: -170%">More Work to Do</p>
                </div>
                <div class="grid__element">
                    <p style="right: -139%">Getting there!</p>
                </div>
                <div class="grid__element">
                    <p style="right: -109%">Advancing</p>
                </div>
                <div class="grid__element">
                    <p style="right: -115%">Role Model</p>
                </div>
                <div class="grid__element">
                    <p style="right: -120%">Bonus Level</p>
                </div>
            </div>
            <div class="chart__block" v-for="(score, index) in scoreData" :key="index" v-show="index > 3">
                <div class="block__label flex">
                    <div class="domain-icon tooltip">
                        <img :src="
            score.domain == 'Leadership' ? require('@/assets/images/leadership.svg') :
                score.domain == 'Workforce' ? require('@/assets/images/workforce.svg') :
                    score.domain == 'Value Chains' ? require('@/assets/images/value_chain.svg') :
                        require('@/assets/images/society.svg')" alt="Indirect Investment" />
                        <span class="domain_label_tooltiptext">{{ score.domain }}</span>
                    </div>
                    <span style="width:100%">{{ score.parameter }}</span>
                </div>
                <div class="block__data">
                    <!-- <div class="data">
              <span class="data__bar data__bar--prev" data-width="90"></span>
              <span class="data__number data__number--prev"> {{ (+score.bonusScore + +score.thresholdScore) | toFixedTwo }}</span>
            </div> -->
                    <div class="data">
                        <span class="data__bar data__bar--bonus tooltip"
                            :style="{'width': + ((+score.bonusScore + +score.thresholdScore) / 6 * 100) + '% !important'}"
                            >
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Bonus Score - {{ + score.bonusScore | toFixedTwo}}</span>
                            </span>
                        </span>
                        <span class="data__bar data__bar--curr tooltip"
                            :style="{ 'background-color':  dataBarStyle , 'width': + ((score.thresholdScore) / 6 * 100) + '% !important' }"
                            >
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Threshold Score - {{ + score.thresholdScore | toFixedTwo }}</span>
                            </span>
                        </span>
                        <span class="data__number data__number--curr font-bold"  :style="{ 'color':  dataBarStyle }">{{ ((score.bonusScore !== '' ||
                            score.thresholdScore
                            !== '')) ? (Number(+score.bonusScore + +score.thresholdScore).toFixed(2)) :(score.showNotApplicantFlag ? 'Unknown' : 'Not Applicable')
                        }}</span>
                    </div>
                    <div class="data">
                        <span class="data__bar data__bar--bonus tooltip"
                            :style="{'width' : + ((+scoreDataOld[index].bonusScore + +scoreDataOld[index].thresholdScore) / 6 * 100) + '% !important'}"
                            >
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Bonus Score - {{ + scoreDataOld[index].bonusScore | toFixedTwo }}</span>
                            </span>
                        </span>
                        <span class="data__bar data__bar--curr tooltip"
                            :style="{ 'background-color': barStyle, 'width': + ((scoreDataOld[index].thresholdScore) / 6 * 100) + '% !important' }">
                            <span class="bonus_score_tooltiptext">
                                <span class=""> Threshold Score - {{ + scoreDataOld[index].thresholdScore |
                                toFixedTwo}}</span>
                            </span>
                        </span>
                        <span class="data__number data__number--curr font-bold"  :style="{ 'color':  dataBarStyle }">{{
                        (scoreDataOld[index].bonusScore !== '' || scoreDataOld[index].thresholdScore !== '') ?
                            (Number(+scoreDataOld[index].bonusScore + +scoreDataOld[index].thresholdScore).toFixed(2)) :
                           (scoreDataOld[index].showNotApplicantFlag ? 'Unknown' : 'Not Applicable')
                        }}</span>
                    </div>
                    <!-- <div class="data">
              <span class="data__bar data__bar--benchmark"></span>
              <span class="data__number data__number--benchmark">3.5</span>
            </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "roiProgressBarsSectionMultiple",
    props: {
        roiData: [Array, Object],
        scoreData: Array,
        scoreDataOld: Array
    },
    data: function () {
        return {

        }
    },
    methods: {
        redirectToPillar(score) {
            this.$store.state.citationIds = [];
            this.$router.push({
                name: "roiPillar",
                params: {

                },
                query: {
                    survey: score.parameter,
                    recordId: this.roiData.recordId,
                },
            });
            // window.open(routeData.href, "_blank");
        }
    },
    computed: {
        barStyle() {
            return !this.$route.path.split('/').includes('roi') ? '#2B215E' : '#201741';
        },
        dataBarStyle() {
            return !this.$route.path.split('/').includes('roi') ? '#821A7D' : '#fe5000';
        }
     },
    mounted() {
        this.scoreData.forEach(element => {
            if (element.bonusScore != '') {
                element.bonusScore = Number(element.bonusScore).toFixed(2);
            }
            if (element.thresholdScore != '') {
                element.thresholdScore = Number(element.thresholdScore).toFixed(2);
            }
        });
        this.scoreDataOld.forEach(element => {
            if (element.bonusScore != '') {
                element.bonusScore = Number(element.bonusScore).toFixed(2);
            }
            if (element.thresholdScore != '') {
                element.thresholdScore = Number(element.thresholdScore).toFixed(2);
            }
        });
    }
};
</script>
<style scoped>
* {
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    /* padding: 0 50px; */
}

.chart {
    flex: 1 1 50%;
    position: relative;
    padding: 40px 0;
    margin: 20px 0;
    counter-reset: gridnumber;
}

.chart__block {
    display: grid;
    grid-template-columns: 40% 60%;
}

.chart__block:not(:last-child) {
    margin-bottom: 35px;
}

.block__label,
.block__data {
    flex: 1 1 50%;
}

.block__label {
    font-weight: 700;
    color: #1b1b1b;
    font-size: 13px;
    padding-right: 20px;
}

.data {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
}

.data:hover {
    z-index: 5;
}

.data__bar {
    display: inline-block;
    width: 50%;
    height: 6px;
    border-radius: 3px;
}

.data__bar--prev {
    background-color: #9d9d9c;
}

.data__bar--curr {
    background-color: #fe5000;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;
}

.data__bar--bonus {
    background-color: #fae053;
}

.yellow-bonus {
    color: #fae053;
}

.data__bar--benchmark {
    background-color: #0077c8;
}

.blue-benchmark {
    color: #0077c8;
}

/* Change using data attribute */
[data-width="90"] {
    width: 100%;
}

.data__number {
    font-size: 10px;
    margin-left: 5px;
}

.data__number--prev {
    color: #9d9d9c;
}

.data__number--curr {
    color: #fe5000;
}

.data__number--benchmark {
    color: #0077c8;
}

.chart__grid {
    position: absolute;
    left: 40%;
    top: 0;
    width: 60%;
    height: 100%;
    display: flex;
    border: 1px solid #e9e9e9;
}

.chart__grid::before {
    content: counter(gridnumber);
    position: absolute;
    top: -20px;
    left: -5px;
    font-size: 10px;
    color: #646363;
}

.grid__element {
    position: relative;
    flex: 1 1 auto;
    counter-increment: gridnumber;
}

.grid__element:not(:last-child) {
    border-right: 1px solid #e9e9e9;
}

.grid__element::before {
    content: counter(gridnumber);
    position: absolute;
    top: -20px;
    right: -5px;
    font-size: 10px;
    color: #646363;
}

.grid__element p {
    font-size: 10px;
    position: absolute;
    bottom: -15px;
    transform: rotate(55deg);
    transform-origin: top left;
    right: -100%;
    white-space: nowrap;
    overflow: visible;
    display: inline-block;
    padding: 0;
    margin: 0;
    color: #646363;
}

.tooltip .bonus_score_tooltiptext {
    font-family: "Montserrat", arial;
    padding: 15px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 280px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    font-weight: bold;
    top: 8px;
}

.tooltip:hover .bonus_score_tooltiptext {
    visibility: visible;
}

.domain-icon {
    height: 35px !important;
    width: 35px !important;
    margin-right: 7px;
}

.tooltip .domain_label_tooltiptext {
    font-family: "Montserrat", arial;
    padding: 3px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 135px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 23px;
}

.tooltip:hover .domain_label_tooltiptext {
    visibility: visible;
}
</style>