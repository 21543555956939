const roiSurveyName = {
  "roi-one": {
    title: "Enterprise Intake Information",
    roiScoringSurveyMod: 1,
  },
  "roi-two": { title: "Business Ownership", roiScoringSurveyMod: 2 },
  "roi-three": {
    title: "Employment & Leadership",
    roiScoringSurveyMod: 3,
  },
  "roi-four": { title: "Diversity in Recruitment", roiScoringSurveyMod: 4 },
  "roi-five": {
    title: "Performance & Progression",
    roiScoringSurveyMod: 5,
  },
  "roi-six": {
    title: "Professional Development",
    roiScoringSurveyMod: 6,
  },
  "roi-seven": {
    title: "Gender Lens in Policies",
    roiScoringSurveyMod: 7,
  },
  "roi-eight": {
    title: "Workforce Support Policies",
    roiScoringSurveyMod: 8,
  },
  "roi-nine": {
    title: "GBVH Prevention and Response",
    roiScoringSurveyMod: 9,
  },
  "roi-ten": {
    title: "Inclusive Health Insurance",
    roiScoringSurveyMod: 10,
  },
  "roi-eleven": {
    title: "Fair Treatment of PT",
    roiScoringSurveyMod: 11,
  },
  "roi-twelve": {
    title: "Gender-Responsive Procurement",
    roiScoringSurveyMod: 12,
  },
  "roi-thirteen": {
    title: "Gender in Value Chains",
    roiScoringSurveyMod: 13,
  },
  "roi-fourteen": {
    title: "Gender Equality Beyond the Work Place",
    roiScoringSurveyMod: 14,
  },
  "roi-fifteen": { title: "Climate Risks", roiScoringSurveyMod: 15 },
  "roi-sixteen": {
    title: "Community Engagement through CSR",
    roiScoringSurveyMod: 16,
  },
  "roi-seventeen": {
    title: "Financial Products & Services",
    roiScoringSurveyMod: 17,
  },
};

module.exports = {
  roiSurveyName,
};
