<template>
  <transition name="modal">
    <div class="modal-mask" v-on:click="setModal()">
      <div class="modal-wrapper">
        <div class="modal-container rounded-[12px] w-[24.5625rem] h-[17.5rem]">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  props: {
    isActive: Boolean,
    setModal: Function
  },
  data: function () {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.88);
  display: table;
  transition: opacity 0.4s ease;
}

button {
  border: none;
  background: #f44336;
  color: #fff;
  padding: 0.625rem;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0rem auto;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.33);
  transition: all 0.2s ease;
}

.modal-default-button {
  float: right;
}

///
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
</style>
