<template>
  <!-- :class="isTwoxPortofolio ? '' : 'step4-section'" -->
  <div>
    <div :class="isTwoxPortofolio ? 'py-8' : 'pb-10'" >
      <p class="label">
        Invite an entity to take the 2X Certification Self-Assessment. You may invite your portfolio companies, your pipeline
        as well as your alumni. The results of their Certification Self-Assessment results are then rolled up and aggregated
        into your 2X Portfolio dashboard.
      </p>
    </div>
    <div class="investee-details">
      <div class="enter-investee-details flex justify-between">
        <div class="email">
          <p>E-mail address</p>
          <input type="text" placeholder="Type e-mail address" v-model="emailAddress" />
        </div>
        <div class="company-name">
          <p>Select type of Company</p>
          <select name="type" id="type" v-model="portfolioType">
            <option value="" disabled selected>Select portfolio type</option>
            <option value="Alumni">Alumni</option>
            <option value="Pipeline">Pipeline</option>
            <option value="Portfolio">Portfolio</option>
          </select>
        </div>
      </div>
      <div class="send-invite text-right">
        <button :class="{ 'in-active-button': !portfolioType || !emailAddress }"
          :disabled="!portfolioType || !emailAddress" @click="sendInviteToCompany(portfolioType, emailAddress)">
          Send invite
        </button>
      </div>
    </div>
    <div class="investee-details">
      <div class="enter-investee-details flex justify-between">
        <div class="company-name">
          <p class="text-xl" style="font-weight: 500; font-size: 18px">
            Ready to see how your portfolio companies, your pipeline as well as
            your alumni perform across the 2X Criteria?
          </p>
        </div>
      </div>
      <div class="send-invite text-right">
        <button @click="
          $router.push({ path: '/2X-Ecosystem/2X-Certification/2X-Portfolio' })
          " class="!bg-[#19486A] opacity-80">
          View your 2X Portfolio
        </button>
      </div>
    </div>
    <div class="investees-lists">
      <div class="investee-heading">
        <h2>
          Investees ({{
            filteredCompanyList?.length ? filteredCompanyList?.length : 0
          }})
        </h2>
      </div>
      <div class="search-company-name">
        <input type="text" v-model="searchCompanyQuery" placeholder="Type company’s name" />
      </div>
      <div class="companies-list overflow-auto" id="scrollbar_style">
        <div class="company flex justify-between" v-for="(company, index) in filteredCompanyList" :key="index"
          :class="{ 'request-sent': company.isRequestSent }">
          <div class="flex inner-company-details">
            <div class="company-image">
              <img src="./../../assets/images/IMM/yellow-circle.svg" v-if="company.status == 'Request sent'" />
              <img src="./../../assets/images/IMM/temp-company-image.png" v-else />
            </div>
            <div class="company-title">{{ company?.organization?.name }} <span v-if="company?.portfolioType">({{
              company?.portfolioType }})</span></div>
          </div>
          <div class="request-button-section">
            <button class="request-button flex bg-[#FCC30B]" v-if="company.status == 'Request sent'">
              <img src="./../../assets/images/IMM/yellow-eye.svg" />
              Request sent
            </button>
            <button class="request-button flex bg-[#4C9F38]" v-if="company.status == 'Request accepted'">
              <img src="./../../assets/images/IMM/white-check.svg" />
              Accepted
            </button>
            <button class="request-button flex bg-[#FF3A21]" v-if="company.status == 'Request rejected'">
              <img src="./../../assets/images/IMM/validation-close.svg" />
              Rejected
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-investee-list" v-if="!filteredCompanyList?.length">
      <div>
        <img src="./../../assets/images/IMM/box.svg" class="block m-auto" />
      </div>
      <p class="text-center">Your investees list is empty</p>
    </div>
    <div v-if="isInviteSuccess" class="invitation-sent-confirmation flex">
      <img src="@/assets/images/2x_certify/filled.svg" />
      <p>Invitation was sent via e-mail</p>
    </div>
  </div>
</template>
<script>
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import apiService from "@/helpers/apiServices.js";
import auth from "@/helpers/auth";

export default {
  name: "PortfolioSection",
  props : {
    isTwoxPortofolio :  {
            type : Boolean,
            default : false
        }
  },
  data() {
    return {
      companyList: [],
      emailAddress: "",
      isInviteSuccess: false,
      searchQuery: "",
      searchCompanyQuery: "",
      user: [],
      portfolioType: "",
    };
  },
  methods: {
    getCompanyList() {
      try {
        apiService
          .fetch2XPortfolioInvestees(this.user?._id)
          .then(async (response) => {
            this.companyList = response.data.data;
          });
      } catch (error) {
        this.companyList = [];
        console.error("Failed to search companies:", error);
      }
    },
    async sendInviteToCompany(c_name, c_email) {
      let validate = this.validEmail(c_email);
      if (validate) {
        const payload = {
          emails: [c_email],
          portfolioType: this.portfolioType,
        };

        try {
          const response = await axios.post(
            `${env.apiUrl}/2x-invites/2x-investees`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("session")}`,
              },
            }
          );
          if (response.data.data) {
            this.$swal.fire({
              icon: "success",
              text: "Success! Your invitation was sent!",
              showConfirmButton: true,
            });
            this.portfolioType = "";
            this.emailAddress = "";
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Please try again!",
              showConfirmButton: true,
            });
          }
          return response.data.data;
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            text: error.response.data.error || "Something went wrong!",
            showConfirmButton: true,
          });
          throw error;
        }
      } else {
        alert("Please enter valid email address!");
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    },
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.getCompanyList();
  },
  computed: {
    filteredCompanyList() {
      let data;
      if (this.searchCompanyQuery) {
        data = this.companyList.filter((company) =>
          (company?.organization?.name)?.toLowerCase()?.includes(this.searchCompanyQuery.toLowerCase())
        );
      } else {
        data = this.companyList;
      }
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>

  .label {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #21191d;
  }

  .investee-details {
    margin-top: 24px;

    .enter-investee-details {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #21191d;
      }

      input {
        margin-top: 8px;
        width: 300px;
        height: 48px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 8px;
      }

      select {
        margin-top: 8px;
        width: 300px;
        height: 48px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 8px;
      }

      input::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea4ba;
      }

      .company-name {}
    }

    .send-invite {
      margin-top: 16px;

      button {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        gap: 8px;
        background: #395aff;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
      }

      .in-active-button {
        background: #e1e3e9;
        cursor: auto;
        pointer-events: none;
      }
    }
  }

  .investees-lists {
    margin-top: 24px;

    .investee-heading {
      width: 616px;
      height: 32px;
      gap: 16px;

      h2 {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }
    }

    .search-company-name {
      margin-top: 16px;

      input {
        width: 302px;
        height: 48px;
        padding: 12px;
        border-radius: 8px;
        gap: 8px;
        border: 1px solid #e1e3e9;
        color: linear-gradient(0deg, #e1e3e9, #e1e3e9),
          linear-gradient(0deg, #ffffff, #ffffff);
      }

      input::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea4ba;
      }
    }

    .companies-list {
      margin-top: 16px;
      width: 630px;
      max-height: 368px;

      .company {
        width: 616px;
        height: 64px;
        padding: 16px;
        border-radius: 12px;
        border: 1px;
        gap: 12px;
        border: 1px solid #e1e3e9;
        color: #e1e3e9;
        margin-bottom: 12px;

        .inner-company-details {
          gap: 12px;

          .company-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: left;
            color: #2c2e35;
          }
        }

        .request-button-section {
          .request-button {
            // width: 136px;
            height: 32px;
            padding: 6px 12px 6px 12px;
            border-radius: 24px;
            gap: 4px;
            // background: #FCC30B;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: right;
            color: #ffffff;
          }
        }
      }

      .request-sent {
        background: #fff8e0;
        border: none;
      }
    }

    #scrollbar_style::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #e1e3e9;
      border-radius: 10px;
      background-color: #e1e3e9;
      // margin: 10px;
    }

    #scrollbar_style::-webkit-scrollbar {
      width: 6px;
      padding-right: 20px;
    }

    #scrollbar_style::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px #717686;
      background-color: #717686;
    }
  }

  .empty-investee-list {
    margin-top: 21px;
    width: 616px;
    height: 128px;
    padding: 24px 0px 24px 0px;
    border-radius: 7px;
    gap: 16px;
    background: #f4f6f9;

    img {}

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #717686;
      margin-top: 16px;
    }
  }

  .invitation-sent-confirmation {
    width: 270px;
    height: 56px;
    top: 1010px;
    left: 586px;
    padding: 16px;
    border-radius: 12px;
    gap: 8px;
    background: #e5fafa;
    margin: auto;
    margin-top: 77px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #2c2e35;
    }
  }
</style>
