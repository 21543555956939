<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-left: 285px"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px"
      />
    </div>  
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container" style="margin-bottom: 6rem">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  "
                >
                  <img
                    class="h-16 print:h-auto print:w-64"
                    src="@/assets/images/2X-assessment/2X-myFavAssessment.png"
                  />
                  <h1
                    class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    "
                  >
                    2X Assessments Shared By Me
                  </h1>
                </div>
                <p
                  class="
                    text-gray-600
                    print:text-2xl print:mt-10
                    font-medium
                    text-center
                  "
                >
                   Manage 2X Assessment shared with others.
                </p>
              </div>
            </div>
            <div class="inviteForm mb-2">
              <div class="EmailInvitemember">
                <div class="EmailInput">
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    v-model="invitedUserEmail"
                  />
                </div>
                <div class="InviteBtn tooltip">
                  <div @click="onInviteClick">
                    <button class="text-white text-sm font-medium">
                      Invite members
                    </button>
                  </div>
                  </div>
                  <!-- <span class="download-csv-tooltip" v-if="!user.listed2x" 
                    v-html='`<u><strong><a href="https://www.equilo.io/2xassessmentservices" target="_blank">Get listed</a></u></strong> to share your results.`'>
                  </span> -->
                </div>
              </div>
              <div
                class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
              >
                <div
                  class="
                    shadow
                    overflow-hidden
                    border-b border-gray-200
                    sm:rounded-lg
                  "
                >
                  <table
                    class="divide-y divide-gray-200 bg-white"
                    style="min-width: 1100px"
                    v-if="!apiLoading"
                  >
                    <thead>
                      <tr>
                        <th class="px-6 py-4 whitespace-no-wrap">
                          <div>
                            <div class="text-sm leading-5 text-gray-900">
                              First Name
                            </div>
                          </div>
                        </th>
                        <th class="px-6 py-4 pl-0 whitespace-no-wrap">
                          <div>
                            <div class="text-sm leading-5 text-gray-900">
                              Last Name
                            </div>
                          </div>
                        </th>
                        <th class="px-6 py-4 whitespace-no-wrap">
                          <div>
                            <div class="text-sm leading-5 text-gray-900">
                              Email
                            </div>
                          </div>
                        </th>
                        <th class="px-6 py-4 whitespace-no-wrap">
                          <div>
                            <div class="text-sm leading-5 text-gray-900">
                              Action
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200" v-if="sharedOrgData.length">
                      <tr v-for="(orgData, index) in sharedOrgData" :key="index">
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <!-- default field -->
                          <div>
                            <div class="text-sm leading-5 text-gray-900">
                              {{ orgData.firstname }}
                            </div>
                          </div>
                        </td>

                        <td class="py-4 whitespace-no-wrap">
                          <!-- default field -->
                          <div>
                            <div class="text-sm leading-5 text-gray-900">
                              {{ orgData.lastname }}
                            </div>
                          </div>
                        </td>

                        <td class="px-6 py-4 whitespace-no-wrap">
                          <!-- default field -->
                          <div>
                            <div class="text-sm leading-5 text-gray-900">
                              {{ orgData.email }}
                            </div>
                          </div>
                        </td>

                        <td class="px-6 py-4 whitespace-no-wrap">
                          <button
                            @click="remove(orgData.email)"
                            class="
                              text-white
                              mr-1
                              py-2
                              px-3
                              rounded-lg
                              bg-red-600
                              text-sm
                              ml-0
                            "
                          >
                            Remove
                          </button>
                        </td>

                        <!-- Options -->
                      </tr>

                      <!-- More rows... -->
                    </tbody>
                    <tbody class="bg-white divide-y divide-gray-200" v-else-if="!apiLoading && sharedOrgData.length == 0">
                      <tr>
                        <td colspan="4" style="text-align:center;"  class="px-6 py-4 whitespace-no-wrap">It looks you did not share anything yet with anyone, please start sharing by inviting the members</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            <!-- new content end-->
          </div>
        </div>
      </div>
    </transition>
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="askConfirmation">
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="
                  mx-auto
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-12
                  w-12
                  rounded-full
                  bg-red-100
                  sm:mx-0 sm:h-10 sm:w-10
                "
              >
                <svg
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Are you sure?
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Are you sure you want to delete this shared assessment? This will
                    permanently remove the shared assessment.<br />
                    This action cannot be undone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                @click="confirmed()"
                type="button"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-transparent
                  px-4
                  py-2
                  bg-red-600
                  text-base
                  leading-6
                  font-medium
                  text-white
                  shadow-sm
                  hover:bg-red-500
                  focus:outline-none
                  focus:border-red-700
                  focus:shadow-outline-red
                  transition
                  ease-in-out
                  duration-150
                  sm:text-sm sm:leading-5
                "
              >
                Remove
              </button>
            </span>
            <span
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
              <button
                @click="canceled()"
                type="button"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-gray-300
                  px-4
                  py-2
                  bg-white
                  text-base
                  leading-6
                  font-medium
                  text-gray-700
                  shadow-sm
                  hover:text-gray-500
                  focus:outline-none
                  focus:border-blue-300
                  focus:shadow-outline-blue
                  transition
                  ease-in-out
                  duration-150
                  sm:text-sm sm:leading-5
                "
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "@/helpers/cookies";
import auth from "@/helpers/auth";
import axios from "axios";
import env from "@/../env";
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "gbvhSharedAssessment",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      sharedOrgData: [],
      isSharedChecked: "",
      user: [],
      popup: false,
      askConfirmation: false,
      apiLoading: false,
    };
  },
  components: {
    pageLoader,
  },

  created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "Settings",
        url: "/settings/account",
      },
      {
        name: "2X Assessment Shared By Me",
        url: "/settings/AssessmentsSharedByMe",
      },
    ]);
    this.endofcreated = true;
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    this.sharedOrgData = await this.getSharedOrgData();
  },
  methods: {
    remove: function (item) {
      console.log(item);
      this.object = item;
      this.askConfirmation = true;
    },
    canceled: function () {
      this.object = {};
      this.askConfirmation = false;
    },
    closePopup: function () {
      this.editUser = false;
      this.invite = false;
    },
    confirmed() {
      const _self = this;
      let email = { email: _self.object };
      axios
        .post(env.apiUrl + "/assesment2x/remove-2xassessment-access/", email, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          // _self.data.splice(_self.data.indexOf(_self.object), 1);
          if (res) {
            _self.object = {};
            _self.askConfirmation = false;
            this.apiLoading = true;
            this.sharedOrgData = await this.getSharedOrgData();
            this.apiLoading = false;
            alert("Shared Assesment removed successfully");
          }
        });
    },
    getSharedOrgData() {
      // const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl + "/assesment2x/shared2x-assessments?sharedByMe=true",
            // { email: _self.user.email },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    onOrgClick(orgName) {
      let queryData = {
        recordId: orgName.recordId,
      };
      this.$router.push({
        path: "/gbvh/gbvh-roadmap-assessment",
        query: queryData,
      });
    },
     validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
     async onInviteClick() {
      let validate = this.validEmail(this.invitedUserEmail);
      if (validate) {
        let usersAry = [];
        usersAry.push(this.invitedUserEmail);
        let body = {
          user_id: this.user._id,
          sharedEmails: usersAry,
        };
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData.acknowledged) {
          this.$swal.fire({
            icon: "success",
            text: "Success! Your invitation was sent!",
            showConfirmButton: true,
          });
          this.sharedOrgData = await this.getSharedOrgData();
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Please try again!",
            showConfirmButton: true,
          });
        }
        this.invitedUserEmail = "";
      } else {
        alert("Please enter valid email address!");
      }
    },
     sendInviteToUser(body) {
      return new Promise(function(resolve) {
        axios
          .post(env.apiUrl + "/assesment2x/send-invite", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
  },
  computed: {
    isSharedEmpty() {
      return this.sharedOrgData.length == 0 ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.inviteForm{
  float: right;
}
.EmailInvitemember {
      display: flex;
      margin-top: 33px;
      .EmailInput {
        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;
          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;
          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
.favorite-shared-button-section {
  text-align: right;
  padding: 20px 0 0;
}
/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

.delete-icon {
  height: 20px;
  margin: 10px 93% !important;
  cursor: pointer;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 15px;
    cursor: pointer;
    width: 40px;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 4px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #0097d1;
    box-shadow: inset 0 0 0 30px #0097d1;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
  /* Switch Sizes */
  .toggleSwitch.large {
    width: 70px;
    height: 30px;
    top: 5px;
  }
  .toggleSwitch.large a {
    width: 29px;
    height: 28px;
    top: 0.8px;
  }
  .toggleSwitch.large > span {
    height: 34px;
    line-height: 35px;
  }
  .toggleSwitch.large input:checked ~ a {
    left: 46px;
  }
  .toggleSwitch.large > span span {
    font-size: 1.1em;
  }
  .toggleSwitch.large > span span:first-of-type {
    left: 50%;
  }
}

/*  End Toggle Switch  */

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.tooltip .coming-soon-tooltip {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 160px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -19px;
  top: -70px;
}
.tooltip:hover .coming-soon-tooltip {
  visibility: visible;
}
.tooltip .coming-soon-tooltip::after {
  right: 95%;
}
</style>
