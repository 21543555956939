<template>
    <div class="image-icon"
        :style="'background-color:' + color">
        <div class="icon">{{ initials }}</div>
    </div>
</template>

<script>
export default {
    name: 'ImageIcon',
    data() {
        return {
            colors: [{ letters: ['A', 'B', 'C', 'D', 'E'], color: '#3F7E44' },
            { letters: ['F', 'G', 'H', 'I', 'J'], color: '#395AFF' },
            { letters: ['K', 'L', 'M', 'N'], color: '#FF3A21' },
            { letters: ['O', 'P', 'Q'], color: '#666461' },
            { letters: ['R', 'S', 'T', 'U'], color: '#A21942' },
            { letters: ['V', 'W'], color: '#FCC30B' },
            { letters: ['X', 'Y', 'Z'], color: '#19486A' },
            ],
        }
    },
    props: {
        name: {
            type: String,
            required: true
        }
    },
    computed: {
        initials() {
            const names = this.name.split(' ');
            let initials = names.map(name => name.charAt(0).toUpperCase()).join('');
            if (initials.length > 2) {
                initials = initials.slice(0, 2);
            }
            return initials;
        },
        color() {
            return this.colors.filter(obj => obj?.letters.includes(this.initials))?.[0]?.color;
        }
    }
}
</script>

<style scoped>
.image-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 600;
}
</style>