var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-indigo-900",style:('background-color:' + _vm.env.brandingColors.navy)},[_c('div',{staticClass:"max-w-md w-full"},[_vm._m(0),_c('div',{staticClass:"mt-8"},[_c('input',{attrs:{"type":"hidden","name":"remember","value":"true"}}),_c('div',{staticClass:"rounded-md shadow-sm"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.password1),expression:"input.password1"}],staticClass:"appearance-none rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5",class:{
              'border-red-600': _vm.error,
              'animate__animated animate__shakeX': _vm.error_animation,
            },attrs:{"aria-label":"Email address","name":"password","type":"password","required":"","placeholder":"New password"},domProps:{"value":(_vm.input.password1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "password1", $event.target.value)}}}),_vm._l((_vm.errors),function(error,index){return _c('p',{key:index,staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(error)+" ")])})],2),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.password2),expression:"input.password2"}],staticClass:"appearance-none rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5",class:{
              'border-red-600': _vm.error,
              'animate__animated animate__shakeX': _vm.error_animation,
            },attrs:{"aria-label":"Email address","name":"password","type":"password","required":"","placeholder":"Retype password"},domProps:{"value":(_vm.input.password2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "password2", $event.target.value)}}})])]),_c('div',{staticClass:"mt-6"},[_c('button',{staticClass:"group relative w-full flex justify-center py-3 px-4 border border-transparent leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out font20px",class:{ 'cursor-not-allowed text-[#201747]': _vm.isLoading },style:('background-color: ' + _vm.env.brandingColors.teal),attrs:{"type":"submit","disabled":!_vm.input.password1},on:{"click":_vm.debouncedLogin}},[_c('span',{staticClass:"absolute right-0 inset-y-0 flex items-center pr-3"},[_c('svg',{staticClass:"h-8 w-8 text-indigo-700 group-hover:text-indigo-400 transition ease-in-out duration-150",attrs:{"version":"1.1","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('path',{attrs:{"d":"M10,16l4,-4l-4,-4","stroke-linecap":"round","stroke-width":"1.5","stroke":"currentColor","fill":"none","stroke-linejoin":"round"}}),_c('path',{attrs:{"fill":"none","d":"M0,0h24v24h-24Z"}})])]),(!_vm.isLoading)?_c('span',[_vm._v("Reset password")]):_c('span',{staticClass:"text-[#201747]"},[_vm._v("Reseting password...")]),(_vm.isLoading)?_c('div',{staticClass:"button-loading-cover"}):_vm._e()])]),(_vm.error)?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-red-500 text-xs"},[_vm._v(_vm._s(_vm.error))])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"h-20 m-auto my-10",attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo equilo"}}),_c('h2',{staticClass:"mt-6 text-center text-3xl leading-9 font-extrabold text-white"},[_vm._v(" Reset password "),_c('span',{staticClass:"tooltip TooltipImgText",staticStyle:{"text-align":"left"}},[_c('img',{attrs:{"src":require("../../assets/images/helpblack.png"),"alt":"background-image"}}),_c('span',{staticClass:"tooltiptext bg-gray-800 text-white p-4 rounded-md shadow-lg"},[_c('p',{staticClass:"font-bold mb-2"},[_vm._v("Password Policy Rules:")]),_c('ul',{staticClass:"list-disc list-inside"},[_c('li',[_vm._v("One lowercase letter required.")]),_c('li',[_vm._v("One uppercase letter required.")]),_c('li',[_vm._v("Minimum 8 characters required.")]),_c('li',[_vm._v("One number required.")]),_c('li',[_vm._v("One special character required. Choose from: !@#$%^&*")])])])])]),_c('p',{staticClass:"mt-2 text-center text-md leading-5 text-gray-200"},[_vm._v(" Set a new strong password. ")])])
}]

export { render, staticRenderFns }