<template>
    <div class="fixed z-50 inset-0 overflow-y-auto">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity cursor-pointer">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 600px;">
                <div class="Modal_Readmorestyle p-6">
                    <div class="ModalInnerStyle">
                        <div class="heading-section flex justify-between items-center">
                            <h1 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                Share your 2X Certificate
                            </h1>
                            <img class="cursor-pointer" src="@/assets/images/cros-icon.svg" @click="closeModal()"/>
                        </div>
                        <div class="enter-email-section mt-7 flex justify-between">
                            <input type="text" placeholder="Enter e-mail address here"/>
                            <button class="send-button">Send</button>
                        </div>
                        <p class="mt-2">To view the result send by e-mail, they must have or create an account</p>
                        <div class="mt-7 flex justify-between">
                            <div class="social-button flex flex">
                                <img src="@/assets/images/linkedin.svg" />
                                <img src="@/assets/images/facebook.svg" />
                                <img src="@/assets/images/twitter.svg" />
                            </div>
                            <button class="copy-link-button flex justify-center">
                                Copy link
                                <img src="@/assets/images/blue-chain-link.svg" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ShareModal",
    data() {
        return {

        }
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
    }
}
</script>
<style lang="scss" scoped>
.Modal_Readmorestyle {
    position: relative;
    display: flex;

    .ModalInnerStyle {
        background: #fff;
        .heading-section {
            width: 552px;
            height: 32px;
            gap: 28px;

        }

        h1 {
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #19486A;
        }

        p {
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #717686;
        }
        .enter-email-section {
            width: 552px;
            height: 48px;
            gap: 12px;
            input {
                margin: 0;
                width: 378px;
                height: 48px;
                padding: 12px;
                border-radius: 8px;
                border: 1px solid #E1E3E9;
                gap: 8px;
            }
            input::placeholder {
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #717686;
            }
            .send-button {
                width: 162px;
                height: 48px;
                padding: 12px 24px 12px 24px;
                border-radius: 8px;
                gap: 8px;
                background: #FCC30B;
                color:#fff;
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
            }
        }

        .social-button {
            width: 184px;
            height: 48px;
            gap: 20px;

        }
        .copy-link-button {
            width: 316px;
            height: 48px;
            padding: 12px 24px 12px 24px;
            border-radius: 8px;
            gap: 8px;
            border: 1px solid #19486A;
            color: #19486A;
            font-family: Arial;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;

        }
    }
}
</style>