<template>
  <div class="w-full">
    <div
      class="flex flex-col my-5 items-center w-full"
      v-for="(dataset, index) in datasets"
      v-bind:key="dataset"
    >
      <p class="py-5">Tier ranking</p>

      <div class="flex flex-col w-full" style="width: 300px">
        <div class="flex flex-row">
          <p
            v-if="dataset == 'tier1'"
            class="w-8/12 h-10 mt-3 text-center"
            style="color: rgb(43, 212, 196); font-size: 15px; font-weight: 500"
          >
            Tier 1
          </p>
          <!-- <p
            v-else
            class="w-8/12 h-10 mt-3 text-left"
            style="color: green; font-size: 15px; font-weight: 500"
          >
            Tier 1
          </p> -->

          <div v-if="dataset == 'tier1'" class="h-8 w-10 mb-3 w-4/12">
            <img :src="flagUrls[index]" />
          </div>
        </div>
        <div class="flex flex-row">
          <p
            v-if="dataset == 'tier2'"
            class="w-8/12 h-10 mt-3 text-center"
            style="color: #f6b545; font-size: 15px; font-weight: 500"
          >
            Tier 2
          </p>
          <!-- <p
            v-else
            class="w-8/12 h-10 mt-3 text-left"
            style="color: #f6b545; font-size: 15px; font-weight: 500"
          >
            Tier 2
          </p> -->
          <div v-if="dataset == 'tier2'" class="h-8 w-10 mb-3 w-4/12">
            <img :src="flagUrls[index]" />
          </div>
        </div>
        <div class="flex flex-row">
          <p
            v-if="dataset == 'tier2watch'"
            class="w-8/12 h-10 mt-3 text-center"
            style="color: #fe5000; font-size: 15px; font-weight: 500"
          >
            Tier 2 Watch
          </p>
          <!-- <p
            v-else
            class="w-8/12 h-10 mt-3 text-left"
            style="color: rgb(254, 80, 0); font-size: 15px; font-weight: 500"
          >
            Tier 2 Watch
          </p> -->
          <div v-if="dataset == 'tier2watch'" class="h-8 w-10 mb-3 w-4/12">
            <img :src="flagUrls[index]" />
          </div>
        </div>
        <div class="flex flex-row">
          <p
            v-if="dataset == 'tier3'"
            class="w-8/12 h-10 mt-3 text-center"
            style="color: red; font-size: 15px; font-weight: 500"
          >
            Tier 3
          </p>
          <!-- <p
            v-else
            class="w-8/12 h-10 mt-3 text-left"
            style="color: rgb(178, 40, 30); font-size: 15px; font-weight: 500"
          >
            Tier 3
          </p> -->
          <div v-if="dataset == 'tier3'" class="h-8 w-10 mb-3 w-4/12">
            <img :src="flagUrls[index]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tierChart",
  props: [
    "chartData",
    "chartLabels",
    "countryD",
    "indicator",
    "chartLabelsForCharts",
  ],
  data: function () {
    return {
      tier: "",
      data: [],
      datasets: [{}],
      flagUrls: [],
    };
  },
  methods: {
    changeText: function (text, index) {
      this.country = [];
      this.country = this.countryD[index];
      this.country.values["country"] = this.country.humanName;
      this.country.values["flagUrl"] = this.country.flagUrl;
      this.country.values["region"] =
        this.country.region == "Eastern Europe & Central Asia"
          ? "Europe & Central Asia"
          : this.country.region + " Region";
      this.country.values["income"] = this.country.income + " Group";
      this.flagUrls.push(this.country.flagUrl);
      const keys = Object.keys(this.country.values);
      for (var k = 0; k < keys.length; k++) {
        const key = keys[k];
        let value = this.country.values[key];
        text = text.toString().split('{{'+key+'}}').join(value);
        // let's try to convert to a number
        if (!isNaN(Number(text))) {
          text = Number(text);
        }
      }

      return text;
    },
    fillIn: function (array) {
      if (array && array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeText(array[i]);
        }
        return array;
      } else {
        return [];
      }
    },
  },
  mounted() {
    // TRF_TPTIER_T_138

    /*
        "value" : "0.33" => TIER2Watch
        "value" : "0.66" => TIER2
        "value" : "1" => TIER1
        "value" : "0" => TIER3
        "value" : "Missing" => MISSING
    */
    this.datasets = [];
    for (let i = 0; i < this.chartData[0].data.length; i++) {
      let value = "";
      value = this.changeText(this.chartData[0].data[i], i);
      this.tier = "tier1";
      if (value == "Missing" || value == 'Unknown') {
        this.tier = "0";
      } else if (value == "Tier 3" || value == 0) {
        this.tier = "tier3";
      } else if (value == "Tier 2 watch" || value == 0.33) {
        this.tier = "tier2watch";
      } else if (value == "Tier 2" || value == 0.66) {
        this.tier = "tier2";
      } else if (value == "Tier 1" || value == 1) {
        this.tier = "tier1";
      }
      this.datasets.push(this.tier);
    }
  },
};
</script>

<style>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
