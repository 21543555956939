<template>
  <div>
    <color-dropdown
      v-model="currentColorScheme"
      :plot-color-schemes="plotColorSchemes"
      :current-color="currentColorScheme"
      v-if="modal"
    ></color-dropdown>
    <div :id="`chart-${this.id}`"></div>
  </div>
</template>

<script>
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { processDataset, colorSchemes, colors, getMaxValue } from "./utils";
import ColorDropdown from "./colorDropdown.vue";

export default {
  name: "plot-radial-chart",
  components: {
    ColorDropdown,
  },
  props: [
    "id",
    "chartData",
    "chartLabels",
    "color",
    "country",
    "indicator",
    "plotColorSchemes",
    "modal",
  ],
  data() {
    return {
      data: [],
      labels: [],
      currentColorScheme: "",
    };
  },
  mounted() {
    this.currentColorScheme = this.plotColorSchemes[4].value;
    const [newData, labels] = processDataset(
      this.chartData,
      this.chartLabels,
      colorSchemes,
      colors,
      this.country,
      this.indicator
    );
    this.labels = labels;
    this.data = newData.reduce((acc, d) => {
      return acc.concat(
        d.data.map((i, index) => ({
          label: this.labels[index],
          gender: newData.length > 1 ? d.label : "Percentage",
          value: Number(i),
        }))
      );
    }, []);

    const chart = this.createChart(this.currentColorScheme);

    document.getElementById(`chart-${this.id}`).appendChild(chart);
  },
  watch: {
    currentColorScheme(newVal) {
      const newChart = this.createChart(newVal);
      // remove the old chart
      const chartContainer = document.getElementById(`chart-${this.id}`);
      chartContainer.innerHTML = "";

      // append the updated chart
      chartContainer.appendChild(newChart);
    },
  },
  methods: {
    createChart(color) {
      const maxValue = getMaxValue(this.data);

      const values = [
        maxValue,
        maxValue * 0.8,
        maxValue * 0.6,
        maxValue * 0.4,
        maxValue * 0.2,
      ].map((value) => value.toFixed(2));

      const longitude = d3
        .scalePoint(new Set(Plot.valueof(this.data, "label")), [180, -180])
        .padding(0.5)
        .align(1);

      return Plot.plot({
        height: 400,
        projection: {
          type: "azimuthal-equidistant",
          rotate: [0, -90],
          // Note: 0.625° corresponds to max. length (here, 0.5), plus enough room for the labels
          domain: d3
            .geoCircle()
            .center([0, 90])
            .radius(maxValue + maxValue / 10)(),
        },
        color: {
          legend: true,
          scheme: color,
        },
        marks: [
          // grey discs
          Plot.geo(values, {
            geometry: (r) => d3.geoCircle().center([0, 90]).radius(r)(),
            stroke: "black",
            fill: "black",
            strokeOpacity: 0.3,
            fillOpacity: 0.03,
            strokeWidth: 0.5,
          }),

          // white axes
          Plot.link(longitude.domain(), {
            x1: longitude,
            y1: 180,
            x2: 0,
            y2: 90,
            stroke: "white",
            strokeOpacity: 0.5,
            strokeWidth: 2.5,
          }),

          // tick labels
          Plot.text(values, {
            x: 180,
            y: (d) => 90 - d,
            dx: 2,
            textAnchor: "start",
            text: (d) => `${d}%`,
            fill: "currentColor",
            stroke: "white",
            fontSize: 12,
          }),

          // axes labels
          Plot.text(longitude.domain(), {
            x: longitude,
            y: { value: 90 - maxValue + maxValue / 10 },
            text: Plot.identity,
            lineWidth: 10,
          }),

          // areas
          Plot.area(this.data, {
            x1: ({ label }) => longitude(label),
            y1: ({ value }) => 90 - value,
            x2: 0,
            y2: 90,
            fill: "gender",
            stroke: "gender",
            strokeWidth: 5,
            curve: "cardinal-closed",
            fillOpacity: 0.25,
          }),

          // points
          Plot.dot(this.data, {
            x: ({ label }) => longitude(label),
            y: ({ value }) => 90 - value,
            fill: "gender",
            stroke: "white",
          }),

          // interactive labels
          Plot.text(
            this.data,
            Plot.pointer({
              x: ({ label }) => longitude(label),
              y: ({ value }) => 90 - value,
              text: (d) => `${(100 * d.value).toFixed(0)}%`,
              textAnchor: "start",
              dx: 4,
              fill: "currentColor",
              stroke: "white",
              maxRadius: 10,
            })
          ),
        ],
      });
    },
  },
};
</script>

<style scoped>
#chart {
  width: 100%;
  height: 400px;
}
</style>
