var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',{staticClass:"card-section"},[_c('local-loader')],1):_c('div',{staticClass:"card-section relative"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"font-bold text-xl"},[_vm._v("Energy Consumption & GHG Emissions")]),_c('div',[_vm._v(_vm._s(_vm.createdAt?.split("T")[0] || null))])]),(_vm.status === 'completed')?_c('div',{staticClass:"mt-5 flex justify-center"},[_c('svg',{staticClass:"h-24 w-24 text-green-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M5 13l4 4L19 7"}})])]):(_vm.status === 'in-progress')?_c('div',{staticClass:"flex items-center justify-between mt-5 pb-5"},[_c('div',{staticClass:"w-3/5"},[_c('div',{staticClass:"text-right mb-1"},[_c('span',{staticClass:"text-[#4DC9BE] font-bold"},[_vm._v(" "+_vm._s(_vm.percentage)+"% ")]),_vm._v(" Completed ")]),_c('k-progress',{staticStyle:{"width":"110%"},attrs:{"percent":_vm.percentage,"color":"#4DC9BE","line-height":16,"show-text":false}})],1),_c('router-link',{staticClass:"primary-navy-button",attrs:{"to":"/imm/surveys/e&s"}},[_vm._v(" Continue ")])],1):_c('div',{staticClass:"w-full mt-10 h-52"},[_vm._v(" Track and analyze your energy consumption and greenhouse gas (GHG) emissions. ")]),_c('div',{staticClass:"mt-8 flex gap-12 justify-between absolute bottom-0 pb-5 pr-12 w-full"},[_vm._m(0),(_vm.status === 'completed')?_c('div',{staticClass:"flex justify-end gap-4 w-1/2"},[_c('router-link',{class:[
          'secondary-navy-button',
          { 'opacity-50 cursor-not-allowed': _vm.investeeId },
        ],attrs:{"to":"/imm/surveys/energy-ghg","disabled":_vm.investeeId}},[_vm._v(" Repeat ")]),_c('router-link',{staticClass:"primary-teal-button",attrs:{"to":"/imm/surveys/energy-ghg","disabled":_vm.user.featureFlags?.includes('sweef')}},[_vm._v(" View results ")])],1):(_vm.status === 'in-progress')?_c('div'):_c('div',{staticClass:"flex justify-end gap-4 w-1/2 items-end mt-40"},[_c('router-link',{class:[
          'primary-navy-button',
          { 'opacity-50 cursor-not-allowed': _vm.investeeId },
        ],attrs:{"to":"/imm/surveys/energy-ghg","disabled":_vm.investeeId}},[_vm._v(" Launch ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-left items-end gap-4 w-1/2 text-[#2177C1] font-bold text-sm"},[_c('div',[_vm._v("< > API integration")]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/dashboard/info.svg")}}),_vm._v(" Methodology ")])])
}]

export { render, staticRenderFns }