<template>
  <div class="static mx-20 py-20">
    <div id="notLoaded" v-if="isLoading" key="loading">
      <page-loader></page-loader>
    </div>
    <h1 class="text-2xl font-bold">Third Party Verification</h1>
    <div>
      <div class="flex justify-between items-center">
        <div
          class="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded-lg relative my-5"
          role="alert"
        >
          <strong class="font-bold">Note: </strong>
          <span class="block sm:inline"
            >Upon uploading the files, the system will process them in the
            background to extract the pertinent information. The page will
            automatically fetch the most recent data.</span
          >
        </div>
      </div>

      <div class="mb-20">
        <label
          for="file-upload"
          class="inline-block montserrat font-bold text-1xl text-white cursor-pointer bg-blue-500 hover:bg-blue-700 border-blue-500 border-2 rounded-[16px] p-3"
        >
          Upload Files
        </label>
        <input
          ref="inputFileRef"
          class="hidden"
          id="file-upload"
          type="file"
          title="Upload"
          multiple
          v-on:change="handleInputFilesOnChange"
        />
        <p class="mt-2 text-sm text-gray-600">Maximum file size: 1 MB</p>
      </div>
    </div>
    <div v-if="!isLoading">
      <div v-if="twoXVerifiedData.length === 0">
        <h2 class="text-xl font-bold">No data found</h2>
        <span>
          Please upload the files to start the verification process.
        </span>
      </div>
      <div v-else>
        <div
          class="bg-purple-100 border border-purple-400 text-purple-700 px-4 py-3 rounded-lg relative my-5 w-1/3"
        >
          <strong class="font-bold">Remaining answers to be verified: </strong>
          <span class="block sm:inline">
            <span class="font-bold">{{ remainingSurveyAnswers }}</span> out of
            <span class="font-bold">{{ surveyAnswersLength }}</span>
          </span>
        </div>
        <div
          class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative my-5"
        >
          <span class="font-bold">❗</span>
          For the answers marked as
          <span class="font-bold bg-red-500 rounded-md px-2 py-1 text-white"
            >NO</span
          >, please provide the relevant information and documents to support
          your answer. You can upload the documents with the
          <span class="font-bold">Upload Files</span> button.
        </div>
        <div
          v-for="data in twoXVerifiedData"
          :key="data._id"
          class="border p-4 mb-4 rounded-lg shadow-md"
          :class="{
            'bg-green-100': data.answer === 'YES',
            'bg-red-100': data.answer === 'NO',
            'bg-yellow-100': data.answer === 'UNKNOWN',
          }"
        >
          <div class="flex flex-col space-y-2">
            <div class="flex">
              <span class="font-semibold">Question:</span>
              <span class="ml-2">{{ data.question }}</span>
            </div>
            <div class="flex">
              <span class="font-semibold">Data found?:</span>
              <span
                class="ml-2 rounded-md px-2 py-1 text-white font-bold"
                :class="{
                  'bg-green-500': data.answer === 'YES',
                  'bg-red-500': data.answer === 'NO',
                  'bg-yellow-500': data.answer === 'UNKNOWN',
                  'bg-gray-500': data.answer === 'ERROR',
                }"
                >{{ data.answer }}</span
              >
            </div>
            <div>
              <button
                @click="toggleCitations(data._id)"
                class="text-blue-500 hover:underline"
              >
                {{ data.showCitations ? "Hide Citations" : "Show Citations" }}
              </button>
              <div v-if="data.showCitations">
                <span class="font-semibold">Citations:</span>
                <div
                  v-for="citation in data.citations"
                  :key="citation._id"
                  class="ml-4 mt-2 bg-white p-2 rounded-md"
                >
                  <div class="flex">
                    <span class="font-semibold">Document Title:</span>
                    <span class="ml-2">{{ citation.document_title }}</span>
                  </div>
                  <div class="flex">
                    <span class="font-semibold">Section:</span>
                    <span class="ml-2">{{ citation.section }}</span>
                  </div>
                  <div class="flex">
                    <span class="font-semibold">Page:</span>
                    <span class="ml-2">{{ citation.page }}</span>
                  </div>
                  <div>
                    <span class="font-semibold">Relevant passages:</span>
                    <div
                      v-for="passage in citation.relevant_passages"
                      :key="passage"
                      class="ml-4 mt-1"
                    >
                      <span>{{ passage }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/../env.js";
import axios from "axios";
import pageLoader from "@/components/page-loader.vue";
import Cookies from "@/helpers/cookies";
import auth from "@/helpers/auth";
import io from "socket.io-client";

export default {
  name: "twoXThirdPartyVerifierFileUploader",
  data() {
    return {
      isLoading: true,
      files: [],
      socket: null,
      user: null,
      twoXVerifiedData: [],
      surveyAnswersLength: 0,
      remainingSurveyAnswers: 0,
      answersWithYes: 0,
      maxFileSize: 10 * 1024, // 1 MB in bytes
    };
  },
  components: {
    pageLoader,
  },
  async mounted() {
    this.user = await auth.me();
    if (
      !this.user.featureFlags ||
      !this.user.featureFlags.includes("ML Add on")
    ) {
      this.$router.push({ path: "/" });
    }
    await this.fetchSurveyAnswersLength();
    await this.setupWebSocket();
    this.remainingSurveyAnswers = this.calculateRemainingSurveyAnswers();
    this.isLoading = false;
  },
  methods: {
    async handleInputFilesOnChange(e) {
      this.isLoading = true;
      let files = e.target.files;
      let endpoint = "/certify2x/upload-files-for-verification";
      let form = new FormData();

      // check file sizes
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > this.maxFileSize) {
          this.$swal.fire({
            icon: "error",
            title: "File Too Large",
            text: `${files[i].name} exceeds the maximum file size of 10 MB.`,
            showConfirmButton: true,
          });
          this.isLoading = false;
          this.$refs.inputFileRef.value = null;
          return;
        }
      }

      Array.from(files).forEach((file, index) => {
        form.append(`files[${index}]`, file);
      });

      form.append("retryErrors", false);
      form.append("retryUnknown", false);

      try {
        await axios.post(`${env.apiUrl}${endpoint}`, form, {
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
            "Content-Type": "multipart/form-data",
          },
        });

        let fileNames = Array.from(files).map((f) => f.name);
        this.$swal.fire({
          icon: "success",
          title: "Files Uploaded Successfully!",
          text: "Uploaded Files:\n" + fileNames.join(", "),
          showConfirmButton: true,
        });
        this.$refs.inputFileRef.value = null;
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: "error",
          title: "Something went Wrong!",
          showConfirmButton: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setupWebSocket() {
      this.socket = io("ws://localhost:3000");

      // send the teamId to the server
      this.socket.emit("filterByTeam", this.user.organization._id);

      this.socket.on("init", (response) => {
        this.twoXVerifiedData = response.map((data) => ({
          ...data,
          showCitations: false,
        }));
      });

      this.socket.on("change", (response) => {
        this.twoXVerifiedData = response.map((data) => ({
          ...data,
          showCitations: false,
        }));
      });

      this.socket.on("needTeamId", () => {
        this.socket.emit("filterByTeam", this.user.organization._id);
      });
    },
    async fetchSurveyAnswersLength() {
      try {
        const response = await axios.get(
          `${env.apiUrl}/certify2x/get-company-data?companyId=${this.user.organization._id}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        this.surveyAnswersLength = response.data.data.surveyResponsesLength;
        this.answersWithYes = response.data.data.companyData?.filter(
          (data) => data.answer === "YES"
        ).length;
      } catch (error) {
        console.log(error);
      }
    },
    calculateRemainingSurveyAnswers() {
      return this.surveyAnswersLength - this.answersWithYes;
    },
    toggleCitations(id) {
      const data = this.twoXVerifiedData.find((data) => data._id === id);
      if (data) {
        data.showCitations = !data.showCitations;
      }
    },
  },
};
</script>
