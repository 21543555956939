<template>
  <div :key="'updateNumberComponents'+updateKey">
    <div class="flex items-center gap-3 justify-end" v-if="$route.query.fromVerifier && info.surveyQType != 'Calculated'">
      <img
        src="@/assets/images/edit-dark.svg"
        alt="edit-icon"
        class="h-6 w-6 cursor-pointer"
        @click="$emit('openEditModalFromCustomComponent', info)"
      />
    <verification-dropdown :value="info" />
    </div>
    <div class="flex my-[1rem]">
      <h3 class="font-bold max-w-[32rem]">{{ title }}</h3>
      <div class="flex" v-if="!noInfo">
        <div v-if="value">
          {{ value }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import apiService from "@/helpers/apiServices.js";
import VerificationDropdown from "../my2xCertificationFullAssessmentResult/verificationDropdown.vue";

export default {
  name: "number",
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { VerificationDropdown },
  data() {
    return {
      title: this.info.title,
      value: this.info.value,
      noInfo: false,
      updateKey: 0,
    };
  },
};
</script>
