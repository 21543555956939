<template>
  <div class="leading-7 font-medium">
    <!-- To make the sources unique -->
    <div style="display: none">{{ id }}</div>
    <p :id="id" v-if="loading">Loading.....</p>
    <p :id="id" v-else v-html="formatData"></p>
  </div>
</template>

<script>
export default {
  name: "textparser",
  props: ["text", "country", "currency"],
  data: function () {
    return {
      showId: true,
      id: "",
      loading: true,
      formatData: null,
      customValues: this.$store.state.ipCodes,
    };
  },
  methods: {
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    numberFormat: function (value) {
      if (!isNaN(value)) {
        if (value > 1000) {
          value = Math.round(value);
          value = Number(value).toLocaleString();
        } else if ((value < 10) & (value >= 2)) {
          // if the decimal would be ".0" anyway
          if (value % 1 == 0) {
            value = Number.parseFloat(value).toFixed(0);
          } else {
            value = Number.parseFloat(value).toFixed(1);
          }
        } else if ((value < 2) & (value != 0)) {
          // if the decimal would be ".#0" anyway
          if ((value * 10) % 1 == 0) {
            value = Number.parseFloat(value).toFixed(1);
          } else {
            value = Number.parseFloat(value).toFixed(2);
          }
        } else {
          value = Math.round(value);
        }

        return value;
      } else {
        return value;
      }
    },
    addCitation: function (test) {
      this.$store.commit("addCitationForRank", test);
    },
    format: function (raw) {
      // Make sure that if we have an enter in our text, we also add this to a new line.
      // raw = raw.replace('\n', '<br/><br/>')

      if (!this.id) {
        this.id = this.makeId(7);
      }

      let text = raw || "";
      let values =
        this.country && this.country.values ? this.country.values : [];
      values["COUNTRY"] = (this.country || {}).humanName;
      const keys = Object.keys(values);
      for (var j = 0; j < keys.length; j++) {
        const key = keys[j];
        if (typeof values[key] !== "undefined") {
          if (values[key] === "") {
            values[key] = "Missing";
          }

          if (values[key] === "Missing") {
            values[key] = "Unknown";
          }

          if (typeof this.customValues[key] !== "undefined") {
            if (
              values[key].toString() == "yes" ||
              values[key].toString() == "1" ||
              values[key].toString() == "1.00"
            ) {
              values[key] = this.customValues[key][0];
            } else if (
              values[key].toString() == "no" ||
              values[key].toString() == "0" ||
              values[key].toString() == "0.00"
            ) {
              values[key] = this.customValues[key][1];
            } else if (values[key].toString() == "0.5") {
              values[key] = this.customValues[key][2];
            } else if (
              values[key].toString() == "0.3" ||
              values[key].toString() == "0.30"
            ) {
              values[key] = this.customValues[key][0];
            } else if (values[key].toString() == "0.15") {
              values[key] = this.customValues[key][2];
            }

            if (
              key == "GBV_CHDPRT_T_4600" ||
              key == "GBV_MEDSRV_T_4506" ||
              key == "GBV_MTLSRV_T_4507"
            ) {
              if (
                values[key].toString() == "0.8" ||
                values[key].toString() == "0.80"
              ) {
                values[key] = this.customValues[key][0];
              }
              if (
                values[key].toString() == "0.4" ||
                values[key].toString() == "0.40"
              ) {
                values[key] = this.customValues[key][2];
              }
            }

            // values[key] = Number(values[key]) > 0 ? this.customValues[key][0] : this.customValues[key][1];
          }

          if (key === "TRF_TPTIER_T_138") {
            if (values[key] === "Missing") {
              values[key] = "Missing";
            } else if (
              (values[key] || "").toString() === "0" ||
              (values[key] || "").toString() === "0.00"
            ) {
              values[key] = "Tier 3";
            } else if ((values[key] || "").toString() === "0.33") {
              values[key] = "Tier 2 watch";
            } else if ((values[key] || "").toString() === "0.66") {
              values[key] = "Tier 2";
            } else if (
              (values[key] || "").toString() === "1" ||
              (values[key] || "").toString() === "1.00"
            ) {
              values[key] = "Tier 1";
            }
          }
          if (key === "WRK_HLTHLV_T_6028") {
            if (values[key] === "Missing") {
              values[key] = "Missing";
            } else if (
              (values[key] || "").toString() === "0" ||
              (values[key] || "").toString() === "0.00"
            ) {
              values[key] = "no paid leave";
            } else if ((values[key] || "").toString() === "0.25") {
              values[key] = "only leave for women to care for children";
            } else if ((values[key] || "").toString() === "0.5") {
              values[key] = "leave for both parents to care for children";
            } else if ((values[key] || "").toString() === "0.75") {
              values[key] =
                "leave for both parents to care for children and spouse";
            } else if (
              (values[key] || "").toString() === "1" ||
              (values[key] || "").toString() === "1.00"
            ) {
              values[key] =
                "leave for both men and women to care for any family member";
            }
          }

          if (key === "WFH_SICKLV_T_6033") {
            if (values[key] === "Missing") {
              values[key] = "Missing";
            } else if (
              (values[key] || "").toString() === "0" ||
              (values[key] || "").toString() === "0.00"
            ) {
              values[key] = "no";
            } else if ((values[key] || "").toString() === "0.25") {
              values[key] = "less than 1 month";
            } else if ((values[key] || "").toString() === "0.5") {
              values[key] = "1 - 2.9 months";
            } else if ((values[key] || "").toString() === "0.75") {
              values[key] = "3 months - 5.9 months";
            } else if (
              (values[key] || "").toString() === "1" ||
              (values[key] || "").toString() === "1.00"
            ) {
              values[key] = "6 months or more";
            }
          }

          if (key === "WFH_SKLVPT_T_6034") {
            if (values[key] === "Missing") {
              values[key] = "Missing";
            } else if (
              (values[key] || "").toString() === "0" ||
              (values[key] || "").toString() === "0.00"
            ) {
              values[key] = "not guaranteed to workers";
            } else if ((values[key] || "").toString() === "0.25") {
              values[key] =
                "guaranteed to part-time workers who work at least 20 hours per week";
            } else if ((values[key] || "").toString() === "0.5") {
              values[key] =
                "guaranteed to part-time workers who work fewer than 20 hours per week";
            } else if ((values[key] || "").toString() === "0.75") {
              values[key] =
                "guaranteed to workers generally, with no exclusions based on part-time work";
            } else if (
              (values[key] || "").toString() === "1" ||
              (values[key] || "").toString() === "1.00"
            ) {
              values[key] =
                "guaranteed to part-time workers without an explicit minimum hourly requirement";
            }
          }

          if (key === "CAR_FAINCV_T_6765") {
            if (values[key] === "Missing") {
              values[key] = "Missing";
            } else if (
              (values[key] || "").toString() === "0" ||
              (values[key] || "").toString() === "0.00"
            ) {
              values[key] = "no paid leave for fathers";
            } else if ((values[key] || "").toString() === "0.25") {
              values[key] = "parental leave but no incentives";
            } else if ((values[key] || "").toString() === "0.5") {
              values[key] = "2 weeks or fewer reserved for fathers";
            } else if ((values[key] || "").toString() === "0.75") {
              values[key] = "more than 2 weeks reserved for fathers";
            } else if (
              (values[key] || "").toString() === "1" ||
              (values[key] || "").toString() === "1.00"
            ) {
              values[key] =
                "leave length or payment bonus for fathers sharing leave";
            }
          }

          if (key !== "COUNTRY") {
            // because this does not require a source

            if (
              values[key] &&
              (values[key].toLowerCase().indexOf("missing") > -1) |
                (values[key].toLowerCase().indexOf("unknown") > -1)
            ) {
              text = text
                .split("{{" + keys[j] + "}}")
                .join(values[key])
                .split("%" + keys[j] + "%")
                .join(values[key]);
              //text = text.split('{{'+keys[j]+'}}').join(values[key]).split('%'+keys[j]+'%').join(values[key]);
            } else {
              let value = "";
              let valueWithPercentage = "";
              if (
                key.includes("WRK_WAGEGP_F_159") |
                key.includes("GBV_SEXRTO_T_154")
              ) {
                value =
                  Number(values[key]).toFixed(2) +
                  "[C-" +
                  this.country.ISO2 +
                  "-" +
                  key +
                  "]";
                valueWithPercentage =
                  this.numberFormat(values[key]) +
                  "%" +
                  "[C-" +
                  this.country.ISO2 +
                  "-" +
                  key +
                  "]";
              } else if (key.includes("year")) {
                value = values[key];
              } else {
                value =
                  this.numberFormat(values[key]) +
                  "[C-" +
                  this.country.ISO2 +
                  "-" +
                  key +
                  "]";
                valueWithPercentage =
                  this.numberFormat(values[key]) +
                  "%" +
                  "[C-" +
                  this.country.ISO2 +
                  "-" +
                  key +
                  "]";
              }

              text = text
                .split("{{" + keys[j] + "}}%")
                .join(valueWithPercentage)
                .split("{{" + keys[j] + "}}")
                .join(value)
                .split("%" + keys[j] + "%")
                .join(value);
              //text = text.split('{{'+keys[j]+'}}').join(value).split('%'+keys[j]+'%').join(value);
            }
          } else {
            text = text
              .split("{{" + keys[j] + "}}")
              .join((this.country || {}).humanName)
              .split("%" + keys[j] + "%")
              .join((this.country || {}).humanName);
          }
        }
      }

      // VIA STORE??
      // Replace sources
      let nr = 0;
      const tags = text.match(/\[.*?\]/g, "");
      for (const index in tags) {
        const tag = tags[index];
        nr += 1;
        const citationId = tag.replace("[", "").replace("]", "");

        //var splitUnderscore = citationId.split("_");
        //var resultUnderscrore = splitUnderscore[splitUnderscore.length - 1];

        if (
          citationId.includes("region") ||
          citationId.includes("income") ||
          citationId.includes("global") ||
          citationId.includes("hist")
        ) {
          text = text.replace(tag, "");
        } else {
          let test = { id: citationId, placeId: this.id + nr };
          this.addCitation(test); // TODO!!!
          // uncomment the next line + comment the line afters IF you want to see the ID locally (debugging purposes).
          text = text.replace(
            tag,
            `<span source='${tag
              .replace("[", "")
              .replace(
                "]",
                ""
              )}' onclick="window.showSource(this);"><sup style="cursor: pointer;">[${
              test.rank + 1
            }]</sup></span>`
          );
          let data = { index: test.rank + 1, tag: tag };
          this.$store.commit("addBibliographyData", data);
        }
      }
      const f = false;
      if (f) {
        text = text.split("*").join(`
          <div class="mt-3"></div>
          <br/>
          <ul class="float float-left mb-10 mr-1">
            <li>
              <svg class="w-5 h-5 mx-1 text-blue-600 inline" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                  <path d="M19,12h-14"></path>
                  <path d="M14,17l5,-5"></path>
                  <path d="M14,7l5,5"></path>
                </g>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
              </li/>
          </ul>
        `);
      }

      if (text.indexOf("*") > -1) {
        var array = text.split("*").join("*§").split("*");
        var t = "";
        for (var i = 0; i < array.length; i++) {
          if (array[i].indexOf("§") > -1) {
            t += `
              <div class="mt-3 flex flex-row w-full items-start">
                <div class="w-8 flex-shrink-0">
                <svg class="w-5 h-5 mx-1 text-blue-600 inline" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                    <path d="M19,12h-14"></path>
                    <path d="M14,17l5,-5"></path>
                    <path d="M14,7l5,5"></path>
                  </g>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>

                </div>
                <div class="w-full text-align-left">${array[i].replace(
                  "§",
                  ""
                )}</div>
              </div>`;
          } else {
            t += array[i];
          }
        }

        text = t;
      }

      /*
      .join(`
        <div class="mt-3"></div>
        <br/>
        <div>
            <svg class="w-5 h-5 mx-1 text-blue-600 inline" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                <path d="M19,12h-14"></path>
                <path d="M14,17l5,-5"></path>
                <path d="M14,7l5,5"></path>
              </g>
              <path fill="none" d="M0,0h24v24h-24Z"></path>
            </svg>
        <div>
      `)
      */

      //text = text.split('Missing').join('Missing');

      text = text.replaceAll(
        "LGBTQIA+",
        `<span>LGBTQIA+<sup style="cursor: pointer;" id='Lesbian, Gay, Bi-sexual, Transgender, Queer, Intersex, and Asexual' onclick="window.showFullForm(this);">[&#8505;]</sup></span>`
      );
      text = text.replace(
        new RegExp("PLWD" + "(?![_])", "g"),
        `<span>PLWD<sup style="cursor: pointer;" id='Persons Living with a Disability' onclick="window.showFullForm(this);">[&#8505;]</sup></span>`
      );
      text = text.replace(
        new RegExp("GESI" + "(?![_])", "g"),
        `<span>GESI<sup style="cursor: pointer;" id='Gender Equality and Social Inclusion' onclick="window.showFullForm(this);">[&#8505;]</sup></span>`
      );
      text = text.replace(
        new RegExp("HIV/AIDS" + "(?![_])", "g"),
        `<span>HI<p style="display:none">hi</p>V/AID<p style="display:none">hi</p>S<sup style="cursor: pointer;" id='Human immunodeficiency virus/ Acquired Immunodeficiency Syndrome' onclick="window.showFullForm(this);">[&#8505;]</sup></span>`
      );
      text = text.replace(
        new RegExp("[^PL,_]HIV[^._—]", "g"),
        `<span> HIV<sup style="cursor: pointer;" id='Human immunodeficiency virus' onclick="window.showFullForm(this);">[&#8505;]</sup></span> `
      );
      text = text.replaceAll(
        "HIV—",
        `<span> HIV<sup style="cursor: pointer;" id='Human immunodeficiency virus' onclick="window.showFullForm(this);">[&#8505;]</sup></span>—`
      );
      text = text.replaceAll(
        "HIV.",
        `<span> HIV<sup style="cursor: pointer;" id='Human immunodeficiency virus' onclick="window.showFullForm(this);">[&#8505;]</sup></span>.`
      );
      text = text.replace(
        new RegExp("AIDS" + "(?![_])", "g"),
        `<span>AIDS<sup style="cursor: pointer;" id='Acquired Immunodeficiency Syndrome' onclick="window.showFullForm(this);">[&#8505;]</sup></span>`
      );

      text = text.replace(new RegExp("{{CURRENCY}}", "g"), this.currency);
      text = text.replaceAll("{{region}}", this.country.region + " Region");
      text = text.replaceAll(
        "{{income}}",
        this.toTitleCase(this.country.income) + " Group"
      );
      this.formatData = text;
      this.loading = false;
      return this.formatData;
    },
    value: function (input) {
      return this.format("{{" + input + "}}");
    },
    makeId: function (length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  created() {
    this.id = this.makeId(7);
  },
  mounted() {
    this.format(this.text);
  },
};
</script>

<style></style>
