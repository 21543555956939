
<template>
    <div class="work-sans-regular">
        <div v-if="isDetailed==='detailed'" class="content mt-4 flex items-center">
        <img :src="getImgSrc" :class="imageClass" />
        <p class="work-sans-regular" v-if="forCard === 'Leadership' || forCard === 'Employment'">
            {{ Math.round(scorePercent) }}% of senior managers are women (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p>
        <p class="work-sans-regular" v-else-if="forCard === 'Supplychain' || forCard === 'Entrepreneurship'">
            {{ Math.round(scorePercent) }}% of suppliers are women owned (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p>
        <p class="work-sans-regular" v-else-if="forCard === 'Portfolio'">
            {{ Math.round(scorePercent) }}% of portfolio companies meet 2X Criteria minimum requirements (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p>
    </div>
    <div v-if="isDetailed==='redacted'" class="content mt-4 flex items-center work-sans-regular">
                    <img
                      class="h-6 w-6 rounded-full"
                      v-if="isAboveThreshold"
                      src="@/assets/images/2x_certify/Rightcircle.svg"
                    />
        <p class="work-sans-regular" v-if="forCard === 'Leadership' && isAboveThreshold">
            {{ Math.round(scorePercent) }}% of senior managers are women (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p>
        <p class="work-sans-regular" v-else-if="forCard === 'Employment' && isAboveThreshold">
            {{ Math.round(scorePercent) }}% of senior managers are women (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p>
        <p class="work-sans-regular" v-else-if="forCard === 'Supplychain' && isAboveThreshold">
            {{ Math.round(scorePercent) }}% of suppliers are women owned (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p>
        <p class="work-sans-regular" v-else-if="forCard === 'Entrepreneurship' && isAboveThreshold">
            {{ Math.round(scorePercent) }}% of suppliers are women owned (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p>
        <p class="work-sans-regular" v-else-if="forCard === 'Portfolio' && isAboveThreshold">
            {{ Math.round(scorePercent) }}% of portfolio companies meet 2X Criteria minimum requirements (
            <span :class="thresholdClass">
                {{ isAboveThreshold ? '&#62;' : '&#60;' }}
                {{ scoreThreshold }}
            </span>
            minimum threshold)
        </p> 
    </div>
 </div>
</template>
<script>
import Rightcircle from '../assets/images/2x_certify/Rightcircle.svg'
import RedStatus from '../assets/images/2x_certify/Status-red-X.svg'
export default {
    name: 'My2XCertificationScoreCommonCard',
    props: {
        scorePercent: {
            type: [Number, String],
            required: true
        },
        scoreThreshold: {
            type: [Number, String],
            required: true
        },
        forCard: {
            type: [Number, String],
            required: true
        },
        isDetailed : {
           type: [String],
        }
    },
    computed: {
        isAboveThreshold() {
            return Math.round(this.scorePercent) > parseFloat(this.scoreThreshold);
        },
        getImgSrc() {
            return this.isAboveThreshold ? Rightcircle : RedStatus;
        },
        imageClass() {
        return this.isAboveThreshold ? 'h-6 w-6 rounded-full' : 'h-6 w-6 rounded-full p-0.5';
        },
        thresholdClass() {
            return this.isAboveThreshold ? 'teal-color' : 'dark-red-color';
        },
    }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.work-sans-regular {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: #21191D;
}
.content {
    gap: 4px;

    img {
        width: 24px;
        height: 24px;
    }

    .teal-color {
        color: #4C9F38;
        font-weight: 700;
    }

    .dark-red-color {
        color: #FF3A21;
        font-weight: 700;
    }
}
</style>