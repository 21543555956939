<template>
    <div class="static" id="Top">
        <div class="navsticky ml-20" style="width:250px;">
            <span>Scroll to...</span>
            <ol class="global_analysis_side_bar_list">
                <li>
                    <button @click="scrollTo('Top')" id="SectionOverview1" class="buttonlink"
                        style="margin-left:-10px;">
                        <span>Snapshot</span>
                    </button>
                </li>

                <li>
                    <button @click="scrollTo('genderequalityheatmap')" id="SectionIndices1" class="buttonlink"
                        style="margin-left:-10px;">
                        <span>Equality Heatmap</span>
                    </button>
                </li>

                <li>
                    <button @click="scrollTo('gbvheatmap')" id="SectionIndices1" class="buttonlink"
                        style="margin-left:-10px;">
                        <span>GBV Risk Heat Map</span>
                    </button>
                </li>
            </ol>
            <br>
            <span>Go to...</span>
            <ol class="global_analysis_side_bar_list">
                <li>
                    <router-link :to="paidUser ? '/gesi-analysis/choose-country' : '/gesi-upgrade'" class="buttonlink"
                        style="margin-left:-10px; width: 130px;">
                        <span>Choose a Country to Learn More</span>
                    </router-link>
                </li>
            </ol>
        </div>

        <div class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0">

            <div id="SpiderChart"></div>
            <div class="flex flex-row items-center justify-center">
                <div class="flex flex-col items-center justify-center">

                    <div
                        class="text-4xl print:text-4xl tracking-tighter font-medium flex flex-row items-center justify-center">
                        <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl">Global Gender
                            Equality Snapshot</h1>
                    </div>


                    <div class="relative lg:container md:w-full lg:px-0 mx-auto mt-0 mb-20 print:mt-0 flex flex-row items-center justify-center"
                        v-scroll-reveal="{ duration: 200, afterReveal: show }" id="chart">
                        <div class="z-20 relative flex items-center justify-center stupid-container"
                            v-if="appearItems.indexOf('chart') !== -1" :key="1">
                            <spiderchart category='region' :iso='country.ISO2' :paddingL='40' :paddingR='40'
                                :paddingT='-30' :paddingB='40' class="z-10 overflow-visible"
                                style="position: relative; height:100%; width: 100%;"></spiderchart>
                            <template v-for="(color, taab) in taabs">
                                <!--ACCESS-->
                                <router-link to="/" exact event="" v-if="color === env.brandingColors.orange"
                                    class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden"
                                    style="right: -10%; top: 18%;" id="2uur" v-bind:key="taab.name"
                                    :style="'cursor: default; background-color:' + color + ';color: white'">
                                    {{ taab }}
                                </router-link>

                                <!--LAW-->
                                <router-link to="/" exact event="" v-if="color === env.brandingColors.navy"
                                    class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold lg:mb-0 -mb-16 z-50 print:hidden"
                                    id="8uur" style="left: -14%; bottom:15%" v-bind:key="taab.name"
                                    :style="'cursor: default; background-color:' + color + ';color: white'">
                                    {{ taab }}
                                </router-link>

                                <!--POWER-->
                                <router-link to="/" exact event="" v-if="color === '#F5D10F'"
                                    class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold -mt-6 lg:mt-0 print:hidden"
                                    id="10uur" style="left: -19%; top:30%" v-bind:key="taab.name"
                                    :style="'cursor: default; background-color:' + color + ';color: white'">
                                    {{ taab }}
                                </router-link>

                                <!--knowledge-->
                                <router-link to="/" exact event="" v-if="color === env.brandingColors.teal"
                                    class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden"
                                    id="12uur" style="bottom:8%" v-bind:key="taab.name"
                                    :style="'cursor: default; background-color:' + color + ';color: white'">
                                    {{ taab }}
                                </router-link>

                                <!--human-->
                                <router-link to="/" exact event="" v-if="color === env.brandingColors.blue"
                                    class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold mb-10 z-50 print:hidden"
                                    id="9uur" style="right: -24%; top: 68%;" v-bind:key="taab.name"
                                    :style="'cursor: default; background-color:' + color + ';color: white'">
                                    {{ taab }}
                                </router-link>

                                <!--roles-->
                                <router-link to="/" exact event="" v-if="color == '#666666'"
                                    class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden"
                                    id="11uur" style="left: 5%; top: 16%;" v-bind:key="taab.name"
                                    :style="'cursor: default; background-color:' + env.brandingColors.gray + '; color: white'">
                                    {{ taab }}
                                </router-link>
                            </template>
                        </div>

                    </div>


                    <div class="flex flex-col mt-20 mx-auto" style="max-width: 960px">
                        <p class="text-justify leading-7 text-gray-600" style="font-size: 14px; line-height: 1.5;">
                            Use Equilo's global gender analysis snapshot to explore gender equality strengths and
                            weaknesses across
                            regions and income groups for all countries globally. This is a tool to help users
                            to:
                        <ol class="ml-10">
                            <li>1) Understand regional or income group gender equality challenges and opportunities for
                                programming or advocacy efforts</li>
                            <li>2) Benchmark of a particular country within a region or income group and benchmarking
                                between regions and income groups</li>
                        </ol>
                        <br />
                        This diagram displays gender equality scores across 15 themes and
                        <span class="triggerElement hoverTrigger">6 TAAP domains.
                            <span
                                class="hoverMethodology w-customWidth absolutetop-0 right-0 bg-white rounded-lg shadow-xl border w-auto text-sm border-gray-200">
                                <methodology />
                            </span>
                        </span>
                        Each point comprises indicators measuring both gender parity and women's empowerment that
                        represent a
                        unique intersection of a theme and domain. A score of 1 (outer circle) indicates perfect gender
                        equality,
                        while a score of 0 (inner circle) indicates complete gender inequality. Any gender equality
                        score above 1
                        indicates gender inequality to the advantage of women.
                        <br /><br />
                        <em>Click the legend items at the bottom of each chart to show or hide different regions or
                            income groups.</em>
                        </p>
                    </div>

                </div>
            </div>
            <div class="flex flex-row my-10 items-center justify-center">
                    <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
                        <router-link :to="paidUser ? '/gesi-analysis/choose-country' : '/gesi-upgrade'"
                            :style="'background-color: ' + env.brandingColors.navy + '; color: white !important; width: 300px;height: 50px;'"
                            class="text-sm text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center">
                            <!-- <svg class="feather feather-arrow-right transform rotate-180" xmlns="http://www.w3.org/2000/svg"
                            width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                        </svg> -->
                            <span>Choose a Country to Learn More</span>
                        </router-link>
                    </div>
                </div>
            <div id="genderequalityheatmap"></div>
            <div class="flex flex-row items-center justify-center">
                <div class="flex flex-col mt-20 items-center justify-center">

                    <div
                        class="text-4xl print:text-4xl tracking-tighter font-medium flex flex-row items-center justify-center">
                        <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl">Gender Equality
                            Heat Map</h1>
                    </div>

                    <gesiHeatMapGlobalAnalysis />

                    <div class="flex flex-col mt-40 mx-auto" style="max-width: 960px">
                        <p class="leading-7 gap-10 text-justify leading-7 text-gray-600"
                            style="font-size: 14px; line-height: 1.5;">
                            Use Equilo's global gender equality heat map to explore where there are strengths and
                            weaknesses across all countries globally. This is a tool to help users to:
                        <ol class="ml-10">
                            <li>1) Target countries for investment in areas with relative challenges or strengths</li>
                            <li>2) Develop projects to target geographies for specific development priority focus</li>
                            <li>3) Benchmark countries with their peers for learning and advocacy purposes</li>
                        </ol>
                        <br />
                        The map above displays the gender equality score for each intersection of theme and domain. A
                        score of 1 (green) indicates perfect gender equality, while a score of 0 (orange) indicates
                        complete gender inequality. Orange indicates a challenge, while green indicates a strength. Any
                        gender equality score above 1 indicates gender inequality to the advantage of women.
                        <br /><br />
                        <em><b>Select a thematic area in the drop-down menus above to view gender
                                equality scores for that intersection.</b> You may first select a domain and then a
                            theme, or vice versa. <b>If you what to explore a new domain or theme, click "Reset" to
                                clear your selections</b> and view the full menu of domain and theme options.</em>
                        </p>
                    </div>

                </div>
            </div>

            <div id="gbvheatmap"></div>
            <!-- HEAT MAP -->
            <transition name="fadeIn">
                <div class="flex flex-row mt-32 mx-auto items-center justify-center">
                    <section id="HeatMap" class="w-full container mx-auto px-4 lg:px-0" style="max-width: 960px">
                        <div class="flex flex-col">
                            <h1 class="text-6xl leading-10 font-medium text-center">GBV Risk Score Heat Map</h1>
                            <div class="text-gray-600 w-full mt-3">

                                <heatmap :startTheme="'GBV Risk Score'" :startDomain="'Overall GBV Risk Score'"
                                    :lockedTheme="'GBV Risk Score'" :gbvrisk="true" sector="country" />

                                <div class="flex flex-col mx-auto" style="max-width: 960px;margin-top: 20rem;">
                                    <p class="leading-7 gap-10 text-justify leading-7 text-gray-600"
                                        style="font-size: 14px; line-height: 1.5;">
                                        <br /><br />
                                        Use Equilo's GBV Risk Score heat map to explore where there is high or low GBV
                                        risk across all countries globally. This is a tool to help users to:
                                    <ol class="ml-10">
                                        <li>1) Understand relative GBV risk across countries and regions</li>
                                        <li>2) Target and prioritize investment to mitigate GBV risk</li>
                                        <li>3) Benchmark countries with their peers for learning and advocacy purposes
                                        </li>
                                    </ol>
                                    <br />
                                    The map above displays the GBV Risk score for each applicable domain selected. A
                                    score of 100 (orange) indicates that there is high GBV Risk in that country, while a
                                    score of 0 (yellow) indicates that there is low GBV Risk.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </transition>
        </div>
    </div>
</template>

<script>
// const axios = require('axios');
import env from '@/../env';
// import Cookies from '@/helpers/cookies'
import auth from '@/helpers/auth';
import spiderchart from '@/components/charts/demo-spider.vue';
import heatmap from '@/components/charts/heatmap.vue';
import gesiHeatMapGlobalAnalysis from '@/components/charts/gesiHeatMapGlobalAnalysis.vue';
import methodology from '@/components/methodology';

export default {
    name: 'globalAnalysis',
    data: function () {
        return {
            q: '',
            data: [],
            active: false,
            env,
            user: {},
            country: {
                income: '', // let's define it. Otherwise we cannot use toLowerCase in the sourcecode
            },
            chartLabels: {},
            taabs: {
                "Access to Resources": env.taabColors.accessToResources,
                "Law & Policy": env.taabColors.lawAndPolicy,
                "Power & Decision-Making": env.taabColors.powerAndDecision,
                "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
                "Human Dignity": env.taabColors.humanDignity,
                "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities
            },
            appearItems: [],
            sector: {},
            category: 'region',
            paidUser: false,
        }
    },
    components: {
        spiderchart,
        heatmap,
        methodology,
        gesiHeatMapGlobalAnalysis
    },
    methods: {
        changeCategory: function (cat) {
            if (cat == 'region') {
                this.category = 'region';
            } else if (cat == 'income') {
                this.category = 'income';
            }
        },
        mouseOver: function (index) {
            this.active = index;
        },
        opacity: function (hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            const rgb = result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
            return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.25)'
        },
        scrollTo(id) {
            document.getElementById(id).scrollIntoView();
        },
        show(el) {
            if (this.appearItems.indexOf(el.id) < 0) {
                this.appearItems.push(el.id)
            }
        }
    },
    computed: {
    },
    async mounted() {
        this.$store.commit('resetCitations');

        this.user = await auth.me().catch(() => {
            console.log('Error login');
        });
        if (this.user.organization.accessGesi) {
            this.paidUser = true;
        } else {
            this.paidUser = false;
        }

        this.$store.commit('setBreadcrumbs', [
            { 'name': 'Global Analysis', 'url': '/global-analysis' },
        ])
    }
}
</script>
<style>
.global_analysis_side_bar_list li {
    margin-left: 1rem !important;
}

.leading-10 {
    line-height: 3.5rem !important;
}

.stupid-container {
    width: 780px;
    height: 720px;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .stupid-container {
        width: 753px;
        height: 600px;
    }
}

hover.Trigger .hoverMethodology {
    visibility: hidden;
    position: absolute;
    z-index: 50;
    width: 400px;
}

.triggerElement {
    border-bottom: 1px dotted #201747;
}

.hoverTrigger:hover .hoverMethodology {
    visibility: visible;
    width: 550px;
}

.list-item {
    display: inline-block;
    margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
    transition: all 1s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
    {
    opacity: 0;
    transform: translateY(30px);
}

.slide-in-move {
    transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
    transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
    transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
    transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
    transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
    opacity: 0;
}

.slide-in-enter {
    transform: translateY(2em);
}

.slide-in-leave-to {
    transform: translateY(-1em);
}

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.donatebutton {
    position: fixed !important;
    top: 74px;
    margin-left: 87%;
    float: right;
}

.mytooltip .mytext {
    visibility: hidden;
    width: 140px;
    background-color: #2BD4C4;
    color: #fff;
    z-index: 1;
    top: -1px;
    right: 111%;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    font-size: 0.80em;
    width: 800%;
}

.mytooltip {
    position: relative;
    display: inline-block;
}

.mytooltip .mytext:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -15px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent #2BD4C4 transparent transparent;
}

.mytooltip:hover .mytext {
    visibility: visible;
}
</style>
