<template>
  <div class="flex justify-between my-[1rem] w-full gap-10">
    <h3 class="font-bold">{{ title }}</h3>
    <div v-if="!noInfo">
      <div v-if="value">
        {{ value }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "number",
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: this.info.title,
      value: this.info.value,
      noInfo: false,
    };
  },
};
</script>
