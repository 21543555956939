<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-indigo-900"
    :style="'background-color:' + env.brandingColors.navy"
  >
    <div class="max-w-md w-full">
      <div>
        <img
          class="h-20 m-auto my-10"
          src="@/assets/images/logo.png"
          alt="logo equilo"
        />

        <h2
          class="mt-6 text-center text-3xl leading-9 font-extrabold text-white"
        >
          Reset password
          <span class="tooltip TooltipImgText" style="text-align: left">
            <img
              src="../../assets/images/helpblack.png"
              alt="background-image"
            />
            <span
              class="tooltiptext bg-gray-800 text-white p-4 rounded-md shadow-lg"
            >
              <p class="font-bold mb-2">Password Policy Rules:</p>
              <ul class="list-disc list-inside">
                <li>One lowercase letter required.</li>
                <li>One uppercase letter required.</li>
                <li>Minimum 8 characters required.</li>
                <li>One number required.</li>
                <li>One special character required. Choose from: !@#$%^&*</li>
              </ul>
            </span>
          </span>
        </h2>
        <p class="mt-2 text-center text-md leading-5 text-gray-200">
          Set a new strong password.
        </p>
      </div>
      <div class="mt-8">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm">
          <div>
            <input
              aria-label="Email address"
              name="password"
              type="password"
              required
              class="appearance-none rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="New password"
              :class="{
                'border-red-600': error,
                'animate__animated animate__shakeX': error_animation,
              }"
              v-model="input.password1"
            />
            <p
              v-for="(error, index) in errors"
              :key="index"
              style="color: white"
            >
              {{ error }}
            </p>
          </div>
          <div>
            <input
              aria-label="Email address"
              name="password"
              type="password"
              required
              class="appearance-none rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Retype password"
              :class="{
                'border-red-600': error,
                'animate__animated animate__shakeX': error_animation,
              }"
              v-model="input.password2"
            />
          </div>
        </div>

        <div class="mt-6">
          <button
            type="submit"
            :disabled="!input.password1"
            @click="debouncedLogin"
            :style="'background-color: ' + env.brandingColors.teal"
            class="group relative w-full flex justify-center py-3 px-4 border border-transparent leading-5 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out font20px"
            :class="{ 'cursor-not-allowed text-[#201747]': isLoading }"
          >
            <span class="absolute right-0 inset-y-0 flex items-center pr-3">
              <svg
                class="h-8 w-8 text-indigo-700 group-hover:text-indigo-400 transition ease-in-out duration-150"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M10,16l4,-4l-4,-4"
                  stroke-linecap="round"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linejoin="round"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </span>
            <span v-if="!isLoading">Reset password</span>
            <span v-else class="text-[#201747]">Reseting password...</span>
            <div v-if="isLoading" class="button-loading-cover"></div>
          </button>
        </div>
        <div class="mt-3" v-if="error">
          <div class="text-red-500 text-xs">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/helpers/users";
import env from "@/../env.js";

export default {
  name: "login",
  data() {
    return {
      error: false,
      env,
      error_animation: false,
      input: {
        resetCode: "",
        password1: "",
        password2: "",
      },
      rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "Minimum 8 characters required.", regex: /.{8,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        {
          message: "One special character required. choose from: !@#$%^&*",
          regex: /(?=.*[!@#$%^&*])/,
        },
      ],
      errors: [],
      isLoading: false,
    };
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
      };
    },
    debouncedLogin() {
      this.debounce(this.login, 500)();
    },
    async login() {
      if (this.input.resetCode) {
        if (!this.isPasswordValid()) return;
        if (
          this.input.password1 &&
          this.input.password2 &&
          this.input.password1 === this.input.password2
        ) {
          this.isLoading = true;
          try {
            const res = await Users.resetPassword(
              this.input.resetCode,
              this.input.password1
            );
            this.isLoading = false;
            if (res.data.error) {
              alert(res.data.message);
              this.$router.replace({ name: "reset_password" });
            } else if (res.data.message === "Password updated") {
              alert("You can now login with your new password");
              this.$router.push({ name: "login" }); // navigate to login page
            }
          } catch (e) {
            this.error = "Something unexpected happened.";
            this.error_animation = true;
            setTimeout(() => {
              this.error_animation = false;
            }, 2000);
          }
        } else {
          this.error = "Passwords are not equal to each other";
          this.error_animation = true;
          setTimeout(() => {
            this.error_animation = false;
          }, 2000);
        }
      } else {
        this.error = "Please provide us with a valid token.";
        this.error_animation = true;
        setTimeout(() => {
          this.error_animation = false;
        }, 2000);
      }
    },
    isPasswordValid() {
      this.errors = this.rules
        .filter((condition) => !condition.regex.test(this.input.password1))
        .map((condition) => condition.message);
      return this.errors.length === 0;
    },
  },
  created() {
    this.input.resetCode = this.$route.params.resetCode;
  },
};
</script>
<style>
.font20px {
  font-size: 20px;
}
</style>
<style lang="scss" scoped>
.TooltipImgText {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    padding-left: 8px;
    line-height: 22px;
    color: #ffff;
  }
  .tooltiptext {
    padding: 10px;
    max-width: 278px;
    top: 20px;
    left: -124px;
    text-align: inherit;
    background-color: #6b787f !important;
    height: 180px !important;
    li {
      height: 20px;
    }
    &::after {
      top: -10px;
      right: 0;
      height: 0;
      width: 0;
      left: 0;
      margin: 0 auto;
      border-color: transparent transparent #201747 transparent;
    }
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}
.disable-pointer-events {
  pointer-events: none;
}
.button-loading-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.5);
  color: #201747;
}
</style>
