<script>
import { Bar } from "vue-chartjs";
import env from "@/../env.js";

export default {
  name: "barChartVertical",
  extends: Bar,
  props: ["chartData", "chartLabels", "countryD", "indicator"],
  data: function () {
    return {
      max: 100,
      beginAtZero: true,
      labels: [],
      data: [],
      datasets: [],
      colors: [
        env.geoColors.countrybar,
        env.geoColors.regionbar,
        env.geoColors.incomebar,
        env.taabColors.humanDignity,
        env.chartColors.jotaColor,
        env.chartColors.muColor,
        env.chartColors.piColor,
        env.chartColors,
      ],
      line: [],
      country: [],
    };
  },
  methods: {
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    changeText: function (text, index) {
      if (index < this.countryD.length) {
        this.country = [];
        this.country = this.countryD[index];

        this.country.values["country"] = this.country.humanName;
        this.country.values["region"] =
          this.country.region == "Eastern Europe & Central Asia"
            ? "Europe & Central Asia"
            : this.country.region + " Region";
        this.country.values["income"] =
          this.toTitleCase(this.country.income) + " Group";

        if (this.indicator && this.indicator.dataType === "MAX50") {
          this.max = 50;
        } else if (this.indicator && this.indicator.dataType === "GBVSEX") {
          this.max = 1.1;
          this.beginAtZero = false;
        } else if (this.indicator && this.indicator.dataType === "PEOPLE") {
          this.max = undefined; // Undefined, as this will remove the max
        }

        const keys = Object.keys(this.country.values);
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k];
          let value = this.country.values[key];
          text = text
            .toString()
            .split("{{" + key + "}}")
            .join(value);
          // let's try to convert to a number
          if (!isNaN(Number(text))) {
            text = Number(text);
          }
        }
        return text;
      }
      else {
        this.country = [];
        this.country = this.countryD[0];

        this.country.values["country"] = this.country.humanName;
        this.country.values["region"] =
          this.country.region == "Eastern Europe & Central Asia"
            ? "Europe & Central Asia"
            : this.country.region + " Region";
        this.country.values["income"] =
          this.toTitleCase(this.country.income) + " Group";

        if (this.indicator && this.indicator.dataType === "MAX50") {
          this.max = 50;
        } else if (this.indicator && this.indicator.dataType === "GBVSEX") {
          this.max = 1.1;
          this.beginAtZero = false;
        } else if (this.indicator && this.indicator.dataType === "PEOPLE") {
          this.max = undefined; // Undefined, as this will remove the max
        }

        const keys = Object.keys(this.country.values);
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k];
          let value = this.country.values[key];
          text = text
            .toString()
            .split("{{" + key + "}}")
            .join(value);
          // let's try to convert to a number
          if (!isNaN(Number(text))) {
            text = Number(text);
          }
        }
        return text;
      }
      // if (index == this.countryD.length) {
      //   this.country = [];
      //   this.country = this.countryD[0];
      //   this.country.values["country"] = this.country.humanName;
      //   this.country.values["region"] =
      //     this.country.region == "Eastern Europe & Central Asia"
      //       ? "Europe & Central Asia"
      //       : this.country.region + " Region";
      //   this.country.values["income"] =
      //     this.toTitleCase(this.country.income) + " Group";

      //   const keys = Object.keys(this.country.values);
      //   for (let k = 0; k < keys.length; k++) {
      //     const key = keys[k];
      //     let value = this.country.values[key];
      //     text = text
      //       .toString()
      //       .split("{{" + key + "}}")
      //       .join(value);
      //     // let's try to convert to a number
      //     if (!isNaN(Number(text))) {
      //       text = Number(text);
      //     }
      //   }

      //   return text;
      // }
    },

    fillIn: function (array, index) {
      if (array && array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeText(array[i], index);
        }
        return array;
      } else {
        return [];
      }
    },
    fillInForLabels: function (array) {
      if (array && array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeTextForLables(array[i], i);
        }
        return array;
      } else {
        return [];
      }
    },
    changeTextForLables: function (text, index) {
      if (index < this.countryD.length) {
        this.country = [];
        this.country = this.countryD[index];
        this.country.values["country"] = this.country.humanName;
        this.country.values["region"] =
          this.country.region == "Eastern Europe & Central Asia"
            ? "Europe & Central Asia"
            : this.country.region + " Region";
        this.country.values["income"] =
          this.toTitleCase(this.country.income) + " Group";

        if (this.indicator && this.indicator.dataType === "MAX50") {
          this.max = 50;
        } else if (this.indicator && this.indicator.dataType === "GBVSEX") {
          this.max = 1.1;
          this.beginAtZero = false;
        } else if (this.indicator && this.indicator.dataType === "PEOPLE") {
          this.max = undefined; // Undefined, as this will remove the max
        }

        const keys = Object.keys(this.country.values);
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k];
          let value = this.country.values[key];
          text = text
            .toString()
            .split("{{" + key + "}}")
            .join(value);
          // let's try to convert to a number
          if (!isNaN(Number(text))) {
            text = Number(text);
          }
        }
        return text;
      }
      if (index == this.countryD.length) {
        this.country = [];
        this.country = this.countryD[index];
        this.country.values["country"] = this.country.humanName;
        this.country.values["region"] =
          this.country.region == "Eastern Europe & Central Asia"
            ? "Europe & Central Asia"
            : this.country.region + " Region";
        this.country.values["income"] =
          this.toTitleCase(this.country.income) + " Group";

        if (this.indicator && this.indicator.dataType === "MAX50") {
          this.max = 50;
        } else if (this.indicator && this.indicator.dataType === "GBVSEX") {
          this.max = 1.1;
          this.beginAtZero = false;
        } else if (this.indicator && this.indicator.dataType === "PEOPLE") {
          this.max = undefined; // Undefined, as this will remove the max
        }

        const keys = Object.keys(this.country.values);
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k];
          let value = this.country.values[key];
          text = text
            .toString()
            .split("{{" + key + "}}")
            .join(value);
          // let's try to convert to a number
          if (!isNaN(Number(text))) {
            text = Number(text);
          }
        }
        return text;
      }
    },
  },
  mounted() {
    let chartLabelsData = this.chartLabels;
    if (chartLabelsData[0] == "{{country}}") {
      chartLabelsData = [];
      for (var x = 0; x < this.countryD.length; x++) {
        chartLabelsData.push("{{country}}");
      }
    }
    this.labels = this.fillInForLabels(chartLabelsData);
    // this.labels.push("Global");

    this.data = this.chartData || [];
    for (var i = 0; i < this.data.length; i++) {
      let id = this.data[i].label
        .toLowerCase()
        .split("{")
        .join("")
        .split("}")
        .join("");
      if (id.includes("female & male") || id.includes("male & female")) {
        id = "male";
      } else if (
        id.includes("female") ||
        id.includes("women") ||
        id.includes("girl")
      ) {
        id = "female";
      } else if (
        id.includes("male") ||
        id.includes("men") ||
        id.includes("boy")
      ) {
        id = "male";
      }
      // this.datasets[0].data.push(this.datasets[1].data[0]);
      const color = this.colors[i];
      if (this.chartData[0].label == "{{country}}") {
        this.datasets.push({
          label: this.changeText(this.data[i].label, i),
          data: this.fillIn(this.data[i].data, i),
          backgroundColor: color,
        });
      } else {
        this.datasets.push({
          label: this.changeText(this.data[i].label, i),
          data: this.fillIn(this.data[i].data, 0),
          backgroundColor: color,
        });
      }
    }
    if (this.chartData[0].label == "{{country}}") {
      let allData = this.datasets[0].data;
      this.datasets.forEach((data, d) => {
        allData.forEach((item, i) => {
          if (d > 0 && d == i) {
            data.data.push(item);
          }
        });
      });
    }

    for (var l = 0; l < this.labels.length; l++) {
      this.labels[l] = this.labels[l].split(" ");
    }

    if (this.indicator.primaryField == "ENV_TMPRCH_T_5505") {
      this.line = [
        {
          type: "line",
          id: "hLine",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 2, // data-value at which the line is drawn
          borderWidth: 2,
          borderColor: env.brandingColors.gray,
          label: {
            enabled: true,
            position: "center",
            content: "2°C Increase",
            backgroundColor: env.brandingColors.gray,
            //fontFamily: "basier"
          },
        },
      ];
      this.max = 3;
    } else {
      this.max = 1.4; // The value was 5 but changed to 1.4 as mentioned in https://app.asana.com/0/1200022095349364/1200986818388571
    }
    let filtertData = [];
    this.datasets.forEach((data) => {
      if (data.label && data.data) {
        filtertData.push(data);
      }
    });
    this.datasets = [];
    this.datasets = filtertData;
    this.datasets.forEach(data => {
      if (data.label == 'Global') {
        data.backgroundColor = "#898989";
      }
    })
    // let glob = [];
    // console.log("this.data", this.data);
    // glob = this.fillIn(this.data[3].data, 0);
    // this.datasets.push({
    //   label: "Global",
    //   backgroundColor: "#898989",
    //   data: [glob[0]],
    // });

    const _self = this;
    this.renderChart(
      {
        labels: this.labels,
        datasets: this.datasets,
      },
      {
        fontFamily: "basier",
        legend: {
          display: true,
          labels: {
            fontSize: 13,
            fontFamily: "basier",
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: this.beginAtZero,
                suggestedMax: this.max,
                fontFamily: "basier",
                fontSize: 12,
                callback: function (value) {
                  if (_self.indicator.dataType === "PERCENT") {
                    return value.toFixed(0) + "%"; // convert it to percentage
                  } else {
                    //https://stackoverflow.com/questions/28523394/charts-js-formatting-y-axis-with-both-currency-and-thousands-separator
                    if (parseInt(value) >= 1000) {
                      return value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    } else {
                      return value;
                    }
                  }
                },
                // max: this.max,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: 13,
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                fontFamily: "basier",
              },
            },
          ],
        },
        tooltips: {
          titleFontFamily: "basier",
          bodyFontFamily: "basier",
          callbacks: {
            title: function (tooltipItems) {
              //Return value for title
              const tt = JSON.parse(JSON.stringify(tooltipItems[0])); // otherwise we change the title under the chart
              const xLabel = tt.xLabel;
              return (
                xLabel.toString().split(",").join(" ") +
                "/" +
                _self.datasets[tooltipItems[0].datasetIndex].label
              );
            },
            label: function (tooltipItem) {
              if (_self.indicator && _self.indicator.dataType === "PEOPLE") {
                return Number(
                  Math.round(tooltipItem.yLabel) || 0
                ).toLocaleString();
              } else if (
                _self.indicator &&
                _self.indicator.dataType === "NUMBER"
              ) {
                return Number(
                  Math.round(tooltipItem.yLabel * 10) / 10 || 0
                ).toLocaleString();
              } else if (
                _self.indicator &&
                _self.indicator.dataType === "MONEY"
              ) {
                return Number(
                  Math.round(tooltipItem.yLabel || 0)
                ).toLocaleString();
              } else {
                return Number(tooltipItem.yLabel || 0).toFixed(1) + "%";
              }
            },
          },
        },
        annotation: {
          annotations: this.line,
        },
      }
    );
  },
};
</script>

<style></style>
