import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";

export const getSurveyData = async function (surveyId, investee = null) {
  try {
    const url = investee
      ? `${env.apiUrl}/survey-answers/survey/${surveyId}?investee=${investee}`
      : `${env.apiUrl}/survey-answers/survey/${surveyId}`;
    const {
      data: { data },
      status,
    } = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });

    if (
      status === 200 &&
      Array.isArray(data.surveyResponse) &&
      data.surveyResponse.length
    ) {
      const { isComplete, surveyResponse } = data;
      return {
        isComplete: isComplete || false,
        surveyResponse: Array.isArray(surveyResponse) ? surveyResponse : [],
      };
    }
  } catch (error) {
    console.error("error fetching climate data:", error);
  }

  return {
    isComplete: false,
    surveyResponse: [],
  };
};

// TODO: this is WIP
export const fetchATData = async function (page) {
  try {
    const data = await axios.get(
      `${env.apiUrl}/imm/imm-framework?page=${page}`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      }
    );
    console.log("DATA", data);
  } catch (error) {
    console.error("Error fetching AT data:", error);
    this.error = {
      message: "We couldn't fetch the data. Please contact support.",
      error: true,
    };
  }
};
