<script>
import { Bar } from 'vue-chartjs'
import env from '@/../env.js';

export default {
    name: 'barChartVertical',
    extends: Bar,
    props: ['chartData', 'chartLabels', 'assessmentYear'],
    data: function () {
        return {
            max: 100,
            beginAtZero: true,
            labels: [],
            data: [],
            datasets: [],
            colors: [env.geoColors.countrybar, env.geoColors.regionbar, env.geoColors.incomebar, env.geoColors.lmicbar, env.chartColors.jotaColor, env.chartColors.muColor, env.chartColors.piColor, env.chartColors],
            line: [],
        }
    },
    methods: {
        toTitleCase: function (phrase) {
            return (phrase || '').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
    },
    mounted() {
        let femaleFirstAssess = [];
        let maleFirstAssess = [];

        femaleFirstAssess = [
            this.chartData[0].totalPtFtWomenEmployees,
            this.chartData[0].midManagerWom,
            this.chartData[0].ftWomenEmployees, 
            this.chartData[0].ptWomenEmployees, 
            this.chartData[0].howManyWomenThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness,
            this.chartData[0].howManyWomenFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization
        ]
        maleFirstAssess = [
            this.chartData[0].totalPtFtEmployees - this.chartData[0].totalPtFtWomenEmployees,
            this.chartData[0].midManagerTot - this.chartData[0].midManagerWom,
            this.chartData[0].ftEmployees - this.chartData[0].ftWomenEmployees, 
            this.chartData[0].ptEmployees - this.chartData[0].ptWomenEmployees, 
            this.chartData[0].howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness - this.chartData[0].howManyWomenThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness, 
            this.chartData[0].howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization - this.chartData[0].howManyWomenFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization
        ]
        
        if (this.chartData.length == 2) {
            var femaleSecondAssess = [];
            var maleSecondAssess = [];
            femaleSecondAssess = [
                this.chartData[1].totalPtFtWomenEmployees,
                this.chartData[1].midManagerWom, 
                this.chartData[1].ftWomenEmployees, 
                this.chartData[1].ptWomenEmployees, 
                this.chartData[1].howManyWomenThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness, 
                this.chartData[1].howManyWomenFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization
            ]
            maleSecondAssess = [
                this.chartData[1].totalPtFtEmployees - this.chartData[1].totalPtFtWomenEmployees,
                this.chartData[1].midManagerTot - this.chartData[1].midManagerWom,
                this.chartData[1].ftEmployees - this.chartData[1].ftWomenEmployees,
                this.chartData[1].ptEmployees - this.chartData[1].ptWomenEmployees, 
                this.chartData[1].howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness - this.chartData[1].howManyWomenThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness, 
                this.chartData[1].howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization - this.chartData[1].howManyWomenFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization
            ]
        }
        if (this.chartData.length == 1) {
            this.max = this.chartData[0].totalPtFtEmployees
            this.datasets = [
                {
                    label: this.assessmentYear[0] + ' - Women',
                    data: femaleFirstAssess,
                    backgroundColor: '#0077C8',
                    stack: 'Stack 0',
                },
                {
                    label: this.assessmentYear[0] + ' - Men',
                    data: maleFirstAssess,
                    backgroundColor: '#FE5000',
                    stack: 'Stack 0',
                },
            ]
        }

        if (this.chartData.length == 2) {
            this.max = this.chartData[0].totalPtFtEmployees > this.chartData[1].totalPtFtEmployees ? this.chartData[0].totalPtFtEmployees : this.chartData[1].totalPtFtEmployees

            this.datasets = [
                {
                    label: this.assessmentYear[1] + ' - Women',
                    data: femaleSecondAssess,
                    backgroundColor: '#0077C8',
                    stack: 'Stack 0',
                },
                {
                    label: this.assessmentYear[1] + ' - Men',
                    data: maleSecondAssess,
                    backgroundColor: '#FE5000',
                    stack: 'Stack 0',
                },
                {
                    label: this.assessmentYear[0] + ' - Women',
                    data: femaleFirstAssess,
                    backgroundColor: '#2BD4C4',
                    stack: 'Stack 1',
                },
                {
                    label: this.assessmentYear[0] + ' - Men',
                    data: maleFirstAssess,
                    backgroundColor: '#FAE053',
                    stack: 'Stack 1',
                },
            ]
        }
        this.max = Math.round(parseInt(this.max));
        let length = (String(this.max)).split("").length;
        let num, roundNum;
        roundNum = Math.pow(10, length - 1)
        let onesValue = (String(this.max)).split("")[0];
        num = roundNum * (String(this.max)).split("")[0];
        if (this.max < (num + (roundNum/2))) {
            this.max = num + (roundNum/2);
        } else {
            this.max = num + roundNum;
        }
        let stepRatio = this.max / 5;
        let stepDividend = 5 * Math.pow(10, length - 2);
        if (stepRatio % stepDividend != 0 && onesValue > 1 && length > 1) {
            let diff = (stepDividend - (stepRatio % stepDividend))
            this.max = this.max + (diff * 5);
        }
        if(this.max < 10) {
            this.max = 10;
        }
        const _self = this;
        this.renderChart({
            labels: this.chartLabels,
            datasets: this.datasets
        },
            {
                fontFamily: "basier",
                legend: {
                    display: true,
                    labels: {
                        "fontSize": 13,
                        fontFamily: "basier"
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            display: false,
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: this.max,
                            stepSize: this.max / 5,
                            min: 0,
                            callback: function (value) {
                                var numFormat = new Intl.NumberFormat("en-US");
                                return numFormat.format(value);
                            }
                        },
                        type: 'linear',
                    }]
                },
                responsive: true,
                maintainAspectRatio: false,
                fontSize: 12,
                tooltips: {
                    titleFontFamily: "basier",
                    bodyFontFamily: "basier",
                    callbacks: {
                        title: function (tooltipItems) {
                            //Return value for title
                            const tt = JSON.parse(JSON.stringify(tooltipItems[0])); // otherwise we change the title under the chart
                            const xLabel = tt.xLabel;
                            let assessStatus = _self.datasets[tooltipItems[0].datasetIndex].label.split('-')[0] + ' - ';
                            return assessStatus + xLabel.toString().split(',').join(' ') + '/' + _self.datasets[tooltipItems[0].datasetIndex].label.split('-')[1];
                        },
                        label: function (tooltipItem) {
                            var numFormat = new Intl.NumberFormat("en-US");
                            if (_self.indicator && _self.indicator.dataType === 'PEOPLE') {
                                return numFormat.format(Number(Math.round(tooltipItem.yLabel) || 0)).toLocaleString();
                            } else if (_self.indicator && _self.indicator.dataType === 'NUMBER') {
                                return numFormat.format(Number((Math.round(tooltipItem.yLabel * 10) / 10) || 0)).toLocaleString();
                            } else if (_self.indicator && _self.indicator.dataType === 'MONEY') {
                                return numFormat.format(Number(Math.round(tooltipItem.yLabel || 0))).toLocaleString();
                            } else {
                                return numFormat.format(Number(tooltipItem.yLabel || 0).toFixed(0));
                            }

                        }
                    }
                },
                annotation: {
                    annotations: this.line
                }
            })
    }
}
</script>

<style>

</style>
