<template>
  <div class="static ecosystem-landing">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div class="container mx-auto px-4 lg:px-0 pb-32" v-if="endofcreated">
      <!--Logo-->
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img
            src="@/assets/images/2X-Global_logo_transparent.svg"
            alt="background-image"
            style="height: 50px"
          />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>

      <!-- Content Wrepper -->
      <div class="ContentWrapper w-12/12">
        <div class="container mx-auto">
          <!-- <div class="flex flex-col mx-auto w-11/12 mt-12 mb-12">
            <a-steps
              progress-dot
              :current="this.current_step"
              size="small"
              @change="progressClick"
            >
              <a-step
                title="Step 1"
                description="2X Challenge Self-Assessment"
              />
              <a-step
                title="Step 2"
                description="2X Challenge Self-Assessment Results"
              />
              <a-step
                title="Step 3"
                description="2X Certification Self-Assessment"
              />
              <a-step
                title="Step 4"
                description="2X Certification Self-Assessment Results "
              />
              <a-step title="Step 5" v-if="user.status2xCertification == 'Validation in Process'" description="Upload Documents" />
              <a-step title="Step 5" v-else description="Third Party Verification" />
              <a-step title="Step 6" description="2X Global Certification" />
              <a-step title="Step 7" description="Take Action" />
            </a-steps>
          </div> -->
          <steps-component-twox
            :currentStep="current_step"
            :userStatus="user.status2xCertification"
          />
        </div>
        <div class="mb-6" v-if="!user?.verifier || Object.keys(user.verifier).length === 0">
          <SelectVerifier></SelectVerifier>
          <button
          @click="openCertificationModal()"
          class="certification-partner-button ml-auto mt-5 px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
        >
          <span class="pr-1"
            >Select Your Third Party Verifier</span
          >
        </button>
        </div>
        <div class="mb-6" v-else>
          <button
          class="certification-partner-button ml-auto mt-5 px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
        >
          <span class="pr-1"
            >Contact your Third Party Verifier</span
          >
        </button>
        </div>

        <div :class="{'pointer-events-none opacity-50' : (!user?.verifier || Object.keys(user.verifier).length === 0)}">
          <div class="container-div mt-4 mb-6">
            <div class="flex">
              <h1
                class="tracking-tighter font-medium print:text-7xl"
                style="color: #19486A; max-width: 845px"
              >
                <span style="font-family: Work Sans">Add documents</span>
              </h1>
            </div>
            <div
              class="flex gap-2 items-center font-bold cursor-pointer"
              style="color: #3F7E44"
              @click="documentsModal = true"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.16675 4.83342H9.83342V6.50008H8.16675V4.83342ZM8.16675 8.16675H9.83342V13.1667H8.16675V8.16675ZM9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z"
                  fill="#3F7E44"
                />
              </svg>
              <span>What documents should I upload?</span>
            </div>
          </div>
          <div
            class="congrats-box-doc p-5 mb-5 text-center"
            id="app"
            @dragover="dragover"
            @drop="drop"
          >
            <div class="flex w-full items-center justify-center text-center">
              <div>
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                  <div class="center-button">
                    <span
                      class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                    >
                      Choose files
                    </span>
                  </div>

                  <p class="center-span">Drag one or more files here</p>
                </label>
              </div>
            </div>
          </div>
          <div
            class="relative overflow-x-auto document-upload-table-section"
            v-if="filelist.length"
            v-cloak
          >
            <table
              class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-base"
              >
                <tr class="table-heading-row">
                  <th scope="col" class="px-6 py-4 w-3/5">Document</th>
                  <th scope="col" class="px-6 py-4 w-1/5">Date uploaded</th>
                  <th scope="col" class="px-6 py-4 w-1/5">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-content-row dark:bg-gray-800 text-base"
                  v-for="(file, i) in filelist"
                  :key="i"
                >
                  <td
                    scope="row"
                    class="font-medium text-gray-900 whitespace-nowrap dark:text-white w-3/5"
                    style="
                      text-decoration: underline;
                      text-decoration-thickness: 2px;
                      text-decoration-color: #2c2e35;
                    "
                  >
                    {{ file.name }}
                  </td>
                  <td
                    class="font-medium text-gray-900 whitespace-nowrap dark:text-white w-1/5"
                  >
                    {{ moment().format("DD MMMM,  YYYY") }}
                  </td>
                  <td class="w-1/5">
                    <div
                      class="flex gap-2 items-center font-bold cursor-pointer"
                      style="color: #ff2700"
                      @click="removeDocument(file._id)"
                    >
                      <img
                        src="@/assets/images/remove-icon.svg"
                        alt="background-image"
                        style="width: 24px; padding: 2px"
                      />
                      <span>Remove</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          v-if="saveDocuments"
          class="save-upload-doc flex gap-2 items-center font-bold"
          style="margin-right: 40%"
        >
          <img src="@/assets/images/2x_certify/filled.svg" />
          <span
            style="
              font-family: Arial;
              font-weight: 400;
              font-size: 16px;
              color: #2c2e35;
            "
            >Your uploads have been saved</span
          >
        </button>
        <!-- Prompt for documents removed -->
        <button
          v-if="removeDocuments"
          class="save-upload-doc flex gap-2 items-center font-bold"
          style="margin-right: 40%;"
        >
          <img src="@/assets/images/2x_certify/filled.svg" />
          <span
            style="
              font-family: Arial;
              font-weight: 400;
              font-size: 16px;
              color: #2c2e35;
            "
            >Document has been removed!</span
          >
        </button>
        <!-- Validation request sent prompt -->
        <button
          v-if="validationRequestSent"
          class="save-upload-doc flex gap-2 items-center font-bold"
          style="margin-right: 40%;"
        >
          <img src="@/assets/images/2x_certify/filled.svg" />
          <span
            style="
              font-family: Arial;
              font-weight: 400;
              font-size: 16px;
              color: #2c2e35;
            "
            >Verification request sent!</span
          >
        </button>
      </div>
    </div>
    <hr />
    <div class="container mx-auto flex justify-between" v-if="endofcreated" :class="{'pointer-events-none opacity-50' : (!user?.verifier || Object.keys(user.verifier).length === 0)}">
      <button
        @click="
          $router.push({
            path: '/2X-Ecosystem/2X-Certification/PreliminaryResults',
          })
        "
        class="previous-button my-9 px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
      >
        <span class="pr-1">Previous step</span>
      </button>
      <div class="flex">
        <button
          @click="saveDocumentsFiles()"
          :style="
            filelist.length
              ? 'background: #19486A'
              : 'background: #e1e3e9;pointer-events: none;'
          "
          class="save-documets-button my-9 mr-2 px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
        >
          <span class="pr-1">Save to continue later</span>
        </button>
        <button
          :style="
            filelist.length
              ? 'background: #19486A'
              : 'background: #e1e3e9;pointer-events: none;'
          "
          class="send-documets-button my-9 px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
          @click="sendValidationRequest()"
        >
          <span class="pr-1">Send documents for verification </span>
        </button>
      </div>
    </div>
    <!-- certification partner Modal -->
    <AccreditedCertificationPartnerMOdal
      v-if="certificationpartnerModal"
      :partnersList="partnersList"
      :user="user"
      :certificationId="certificationScoreData._id"
      @close="closeCertificationModal"
    />
    <!--what documents upload modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="documentsModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 660px; width: 100%"
        >
          <span
            @click="closeDocumentsModal()"
            class="close z-10"
            style="right: 16px; top: 5px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle" style="display: block">
            <div class="ModalInnerStyle">
              <h2 style="font-weight: 600; text-align: left; line-height: 32px">
                Documents
              </h2>
              <div>
                <p class="content">
                  Please upload any documentation that provides evidence of any
                  of your responses, including all responses to which you
                  responded "Yes", affirmed, or attested to during the 2X basic
                  ESG screen. These documents will be used by 2X Global's
                  accredited certification partner to review and verify your
                  assessment results. The more complete your documentation is,
                  the quicker the verification and review process will be. The
                  accredited certification partner will follow-up as needed.
                </p>
                <div
                  class="congrats-box-doc p-5 mb-5 text-center mt-5"
                  id="app"
                  @dragover="dragover"
                  @drop="drop"
                >
                  <div
                    class="flex w-full items-center justify-center text-center"
                  >
                    <div>
                      <input
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                      />
                      <label
                        for="assetsFieldHandle"
                        class="block cursor-pointer"
                      >
                        <div class="center-button">
                          <span
                            class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          >
                            Choose files
                          </span>
                        </div>

                        <p class="center-span-modal">
                          Drag one or more files here
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex mt-10 justify-between">
                <button
                  @click="closeDocumentsModal()"
                  class="modal-link-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                >
                  Cancel
                </button>
                <button
                  @click="closeDocumentsModal()"
                  class="modal-send-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "@/helpers/cookies";
import auth from "@/helpers/auth";
import AccreditedCertificationPartnerMOdal from "@/components/AccreditedCertificationPartnerMOdal.vue";
import SelectVerifier from "@/components/2x-certification/selectThirdPartyVerifierSection.vue";
import axios from "axios";
import env from "@/../env";
import StepsComponentTwox from '../../components/StepsComponentTwox.vue';
import apiService from '@/helpers/apiServices.js';
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "twoxCertifyUploadDocuments",
  data: function () {
    return {
      endofcreated: false,
      user: [],
      current_step: 2,
      delimiters: ["${", "}"],
      filelist: [],
      certificationpartnerModal: false,
      documentsModal: false,
      saveDocuments: false,
      sendRequest: false,
      uploadedFileNames: [],
      removeDocuments: false,
      validationRequestSent: false,
      partnersList: [],
      certificationSurveyData: [],
      status2xCertification: "",
      entityChosen: "",
      certificationScoreData: [],
    };
  },
  components: {
    AccreditedCertificationPartnerMOdal,
    StepsComponentTwox,
    SelectVerifier,
    pageLoader
  },
  methods: {
    async fetchPartnersList() {
        await apiService.fetchThirdPartyPartnerList()
        .then(async response => {
            this.partnersList = response.data.data;
        })
    },
    // async getCertificationData() {
    //     await apiService.fetch2xCertificationData(this.entityChosen, this.$route?.query?.teamId, 'Full')
    //     .then(async response => {
    //         this.certificationScoreData = response.data.data;
    //     })
    // },
    sendValidationRequest() {
      axios
        .post(
          env.apiUrl + `/certify2x/2x-certification-validation-request`,
          { validationInProcess: true },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async () => {
          this.validationRequestSent = true;
          setTimeout(() => {
            this.validationRequestSent = false;
          }, 3000);
        });
    },
    saveDocumentsFiles() {
      this.saveDocuments = true;
      setTimeout(() => {
        this.saveDocuments = false;
      }, 3000);
    },
    removeDocumentsFiles() {
      this.removeDocuments = true;
      setTimeout(() => {
        this.removeDocuments = false;
      }, 3000);
    },
    openCertificationModal() {
      this.certificationpartnerModal = true;
    },
    closeCertificationModal() {
      this.certificationpartnerModal = false;
    },
    closeDocumentsModal() {
      this.documentsModal = false;
    },
    progressClick(step) {
      switch (step) {
        case 0:
          this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
          break;
        case 1:
          this.$router.push({
            path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
          });
          break;
        case 2:
          this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
          break;
        case 3:
          this.$router.push({
            path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
          });
          break;
        case 4:
          if(this.user.status2xCertification == 'Validation in Process') {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
            });
          } else {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/Verification",
            });
          }
          break;
        case 5:
          if (
            this.validate2xStatus(
              this.user?.status2xCertification,
              "Validation in Process"
            )
          )
            break;
          else {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
            });
            break;
          }
        case 6:
          if (
            this.validate2xStatus(
              this.user?.status2xCertification,
              "Validation in Process"
            )
          )
            break;
          else {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/score-card",
            });
            break;
          }
        default:
          break;
      }
    },
    validate2xStatus(userStatus, status) {
      return userStatus === status;
    },
    onChange() {
      let files = [];
      this.uploadedFileNames = [];
      files.push(...this.$refs.file.files);
      files.map((item) => {
        this.uploadedFileNames.push(item.name);
      });
      this.generateDocumentPresignURL();
    },
    removeDocument(id) {
      axios
        .delete(env.apiUrl + `/certify2x/verification-documents/${id}`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async () => {
          await this.getAllDocuments();
          await this.removeDocumentsFiles();
        });
    },
    generateDocumentPresignURL() {
      let body = {
        files: this.uploadedFileNames,
        urlType: "upload",
        module: "CERTIFICATION_2X_VERIFICATION_DOCS",
      };
      axios
        .post(env.apiUrl + `/other/presignedUrl`, body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          const fileRes = res.data.data;
          if (fileRes.length) {
            const fileData = [];
            fileRes.map((item) => {
              fileData.push({
                key: item.key,
                name: item.fileName,
              });
              axios
                .put(item.url, {
                  headers: {
                    Authorization: "Bearer " + Cookies.get("session"),
                  },
                })
                .then(async () => {});
            });
            this.addDocuments(fileData);
          }
        });
    },
    addDocuments(data) {
      let body = {
        documents: data,
      };
      axios
        .post(env.apiUrl + `/certify2x/verification-documents`, body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async () => {
          await this.getAllDocuments();
        });
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      console.log(event);
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    getAllDocuments() {
      apiService.fetchUploadDocumentsData(this.$route?.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
        .then(response => {
          this.filelist = response.data.data;
        });
    },
  },
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.$store.commit("setCertPageStatus", this.user?.status2xCertification);
    if(!this.$store.state.certPageAccess.isUploadDocPage) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
      });
    }
    if(this.$store.state.certPageAccess.isVerificationPage) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification/Verification",
      });
    }
    if(this.$route.query.teamId) {
      await apiService.fetch2xUserSurvey('2x', this.$route.query.teamId)
      .then(async response => {
        this.certificationSurveyData = await response.data.data;
      })
      await apiService.fetch2xCertRequest(this.$route.query.teamId)
      .then(async response => {
        this.status2xCertification = response?.data?.data?.status;
      })
    }
    else {
      this.status2xCertification = this.user?.status2xCertification;
      if(this.user.surveyData){
        this.certificationSurveyData = this.user.surveyData;
      } else {
        this.$router.push({ path: '/2X-Ecosystem/2X-Certification' })
      }
    }
    if (this.certificationSurveyData) {
      this.certificationSurveyData?.forEach((data) => {
        if (data.surveyId == "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    // await this.getCertificationData();
    await this.getAllDocuments();
    await this.fetchPartnersList();
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setNewBreadcrumbs", [
      // { name: "Dashboard", url: "/" },
      { name: "2X Certification", url: "/2X-Ecosystem/2X-Certification" },
      { name: "Get Your 2X Certification", url: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" },
    ]);
    this.endofcreated = true;
  },
};
</script>

<style lang="scss" scoped>
.save-upload-doc {
  background: #e5fafa;
  padding: 16px;
  border-radius: 12px;
  float: right;
  margin-top: 70px;
  margin-right: 14%;
}
.validation-request-button {
  margin-right: 16%;
}
.request-send-button {
  background: #e5fafa;
  padding: 16px;
  border-radius: 12px;
  margin: 0 auto;
}
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.Modal_Readmorestyle {
  padding: 30px 0 10px 30px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    padding-right: 20px;
    .space-between {
      justify-content: space-between;
      border-bottom: 1px solid #e1e3e9;
      padding: 24px 0px 24px 0px;
    }
    p {
      font-weight: 400;
      font-family: Arial;
      color: #2c2e35;
      font-size: 16px;
      line-height: 24px;
    }
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 16px;
      color: #2c2e35;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      background-color: #19486A;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }
    .modal-link-button {
      font-weight: bold;
      color: #19486A;
      border-style: solid;
      border-color: #19486A;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }
    .upload-documents-button {
      font-weight: bold;
      color: white;
      background: #3F7E44;
      padding: 8px 16px 8px 16px;
    }
    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #19486A;
    }
    .congrats-box-doc {
      border: 1px solid #3F7E44;
      border-radius: 16px;
      padding: 32px, 0px, 32px, 0px;
      background: #eaf6ff;
      border-style: dashed;
      height: 160px;
    }
  }
}
.previous-button {
  font-weight: bold;
  font-size: 16px;
  color: #19486A;
  border-style: solid;
  border-color: #19486A;
  border-width: 1px;
  gap: 8px;
  padding: 12px 24px 12px 24px;
}
.send-documets-button {
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-style: solid;
  border-width: 1px;
  gap: 8px;
  padding: 12px 24px 12px 24px;
}
.save-documets-button {
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-style: solid;
  background: #19486A;
  border-width: 1px;
  gap: 8px;
  padding: 12px 24px 12px 24px;
}
// table,
// th,
// td {
//   border: 1px solid #caccd1;
//   font-family: arial;
// }
.container-div {
  display: flex;
  justify-content: space-between;
}
.center-button {
  position: relative;
  top: 27px;
}
.center-span {
  position: relative;
  top: 35px;
  line-height: 20px;
  font-family: arial;
  color: #19486A;
  right: 20px;
}
.bg-grey {
  background: #e1e3e9;
}
.ContentWrapper {
  margin-top: 10px;
  .certification-partner-button {
    font-weight: bold;
    font-size: 16px;
    color: #19486A;
    border-style: solid;
    border-color: #19486A;
    border-width: 1px;
    padding: 12px 24px 12px 24px;
  }
  .upload-documents-button {
    font-weight: bold;
    color: white;
    background: #3F7E44;
    width: 150px;
    padding: 8px 16px 8px 16px;
  }

  .congrats-box-doc {
    border: 1px solid #3F7E44;
    border-radius: 16px;
    padding: 32px, 0px, 32px, 0px;
    background: #eaf6ff;
    border-style: dashed;
    height: 160px;
  }
  .document-upload-table-section {
    border-radius: 16px;
    border: 1px solid #e1e3e9;
    table {
      th,
      td {
        border: 1px solid #e1e3e9;
      }
      .table-heading-row {
        th {
          gap: 32px;
          border-top: none;
          background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
            linear-gradient(0deg, #e1e3e9, #e1e3e9);
          font-family: Arial;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #2c2e35;
        }
        th:first-child {
          border-top-left-radius: 16px;
          border-left: none;
        }
        th:last-child {
          border-top-right-radius: 16px;
          border-right: none;
        }
      }
      .table-content-row {
        img {
          width: 24px;
          height: 24px;
        }
        td {
          border-bottom: none;
          height: 68px;
          padding: 22px 24px;
          gap: 1px;
          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #2c2e35;
        }
        td:first-child {
          border-left: none;
        }
        td:last-child {
          border-right: none;
        }
      }
    }
  }
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}
[v-cloak] {
  display: none;
}
router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans !important;
  padding-top: 7em;
  .breadcrumbs-main-container {
    margin-top: 100px;
  }
  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
