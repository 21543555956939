<template>
  <div class="flex flex-row w-full bg-gray-100">

    <sidebar></sidebar>
    <div class="flex flex-row overflow-x-auto w-full">
      <div class="flex flex-row px-10 py-10 w-full center-items" style="padding-top: 100px;">
        <div class="sm:px-6 lg:px-8">
          <div class="bg-white shadow overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 pb-5 border-b border-gray-200 sm:px-6" style="display: flex;">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Change Password
              </h3>
              <span class="tooltip TooltipImgText">
                <img
                  src="../../assets/images/helpblack.png"
                  alt="background-image"
                />
                <span class="tooltiptext">
                  <p><strong>Password Policy Rules:</strong></p>
                  <ul>
                    <li> - One lowercase letter required.</li>
                    <li> - One uppercase letter required.</li>
                    <li> - Minimum 8 characters required.</li>
                    <li> - One number required.</li>
                    <li> - One special character required. choose from: !@#$%^&*</li>
                  </ul>
                </span>
            </span>
            </div>
            <div class="mt-5">
              <dl>
                <div class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500" style="margin-top: 6px;">
                    Current Password
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 w-full">
                    <input
                      ref="currentPassword"
                      type="password"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="Current Password"
                      v-bind:class="{ 'border-red-600': errorFields.includes('currentPassword'), 'animate__animated animate__shakeX': errorFields.includes('currentPassword') }"
                      v-model="user.currentpassword"
                    >
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500" style="margin-top: 6px;">
                    New Password
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    <input
                      ref="newPassword"
                      type="password"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="New Password"
                      v-bind:class="{ 'border-red-600': errorFields.includes('newPassword'), 'animate__animated animate__shakeX': errorFields.includes('newPassword') }"
                      v-model="user.newpassword"
                    >
                    <p v-for="(error, index) in errors" :key="index" style="color: red"> {{ error }}</p>
                  </dd>
                </div>
                <div class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500" style="margin-top: 6px;">
                    Retype Password
                  </dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                    <input
                      ref="retypePassword"
                      type="password"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="Retype Password"
                      v-bind:class="{ 'border-red-600': errorFields.includes('retypePassword'), 'animate__animated animate__shakeX': errorFields.includes('retypePassword') }"
                      v-model="user.retypepassword"
                    >
                  </dd>
                </div>
              </dl>

              <button @click="update" class="text-white my-5 mr-5 py-2 px-4 rounded-lg font-medium float-right text-sm hover:shadow-lg" style="background-color: rgb(32, 23, 71);">
                Change Password
              </button>
            </div>
        <div class="mt-3" v-if="error">
          <div class="text-red-500 text-xs text-center">{{error}}</div>
        </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from '@/components/sidebar.vue'
import auth from '@/helpers/auth';
import Users from '@/helpers/users'

export default {
  name: 'Overview',
  data: function () {
    return {
      error: false,
      errorFields: [],
      errorPasswordValidation: false,
      user: {},
      rules: [
        { message:'One lowercase letter required.', regex:/[a-z]+/ },
        { message:"One uppercase letter required.",  regex:/[A-Z]+/ },
        { message:"Minimum 8 characters required.", regex:/.{8,}/ },
        { message:"One number required.", regex:/[0-9]+/ },
        { message:"One special character required.", regex:/(?=.*[!@#$%^&*])/ }
      ],
      errors: [],
    }
  },
  components: {
      sidebar,
  },
  methods: {
    update: function(){
      this.error = "";
      this.errorFields = [];

      if (!this.user.currentpassword) {
        this.error = "Current Password can not be empty";
        this.addInputError('currentPassword');
        this.$refs.currentPassword.focus();
        return;
      }

      if(!this.isPasswordValid()) return;

      if (
        this.user.newpassword && this.user.retypepassword &&
        this.user.newpassword === this.user.retypepassword
      ) {
        Users.changepassword(this.user.newpassword, this.user.currentpassword, this.user.email)
          .then(async (response) => {
            this.errorFields = [];
            this.error = "";

            alert(response.data.message);
            document.location.reload();
          })
          .catch(() => {
            alert("Something unexpected happened please try again");
          });
      } else {
        this.error = "Passwords are not equal to each other";
        this.addInputError('newPassword');
        this.addInputError('retypePassword');
        this.$refs.newPassword.focus();
      }
    },
    isPasswordValid() {
      this.errors = [];
      for (let condition of this.rules) {
        if (!condition.regex.test(this.user?.newpassword)) {
          this.errors.push(condition.message);
        }
      }
      if(this.errors.length > 0) this.errorPasswordValidation = true;
      this.addInputError('newPassword');
      this.$refs.newPassword.focus();
      return this.errors.length === 0;
    },
    addInputError (inputName) {
      if (this.errorFields.indexOf(inputName) === -1) {
        this.errorFields.push(inputName);
      }
    },
  },
  async created(){
    this.user = await auth.me().catch(() => {});
          
          if(!this.user){
              this.$router.replace({
                name: "login"
              })
          }
    this.$store.commit('downloadable', false);
    this.$store.commit("isFreeState", true);
    this.$store.commit('setBreadcrumbs', [
      {'name': 'Settings', 'url': '/settings/account'},
      {'name': 'Change Password', 'url': '/settings/changepassword' },
    ])

  }
}
</script>
<style lang="scss" scoped>
.TooltipImgText {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    padding-left: 8px;
    line-height: 22px;
    color: #ffff;
  }
  .tooltiptext {
    max-width: 360px;
    top: 20px;
    left: -124px;
    background-color: #201747 !important;
    text-align: inherit;
    &::after {
     top: -10px;
     right: 0;
     height: 0;
     width: 0;
     left: 0;
     margin: 0 auto; 
     border-color: transparent transparent #201747 transparent;
    }
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}
</style>
