<template>
    <div>
        <div v-if="!isDeals" class="PoweredbyTitle_logo">
            <div class="PoweredbyContentWrepper">
                <img src="@/assets/images/2X-Challenge-logo.png" alt="background-image" style="height: 50px;" />
            </div>
            <div style="display: flex; justify-content: flex-end">
                <div class="Poweredby_logoImg mt-2">
                    <div class="powered-text flex">
                        <span>Powered by:</span>
                    </div>
                    <img src="@/assets/images/logo-navy.png" alt="background-image" />
                </div>
            </div>
        </div>
        <div class="PoweredbyTitle_logo bg-image" style="padding: 0;">
            <div class="PoweredbyContentWrepper bg-text-style">
                <div class="PoweredbyTitleWrepper mt-5">
                    <h1 class="text-6xl work-sans-regular tracking-tighter font-medium" style="max-width: 500px; color: #fff;">
                        <span>{{ heroTitle }}</span>
                    </h1>
                </div>
                <div class="flex flex-col mt-10 mx-auto items-center justify-center" style="max-width: 845px">
                    <p class="work-sans-regular  text-lg leading-7 font-normal gap-10 text-justify" style="color: #fff;" >
                        {{ heroSubTitle }} <span v-if="isDeals">
                            These matchmaking pages are made available by Equilo Inc. to
                            users subject to these
                            <router-link
                            to="/dealsTermsOfUse"
                            style="color: #19486A"
                            target="_blank"
                            class="font-medium leading-7"
                            >terms of use</router-link
                            >
                            and this
                            <router-link
                            to="/investorsPrivacyPolicy"
                            style="color: #19486A"
                            target="_blank"
                            class="font-medium leading-7"
                            >privacy notice</router-link
                            >.
                            </span>  
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "HeroSection2xChallange",
    props: {
        heroTitle: {
            type: String,
            required: true
        },
        heroSubTitle: {
            type: String,
            required: true
        },
        isDeals : {
            type : Boolean,
            default : false
        }
    },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.work-sans-regular {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 170px;
    padding: 170px 0 0;
    margin-top: 20px;

    .bg-text-style {
        margin-left: 9rem;
        position: relative;
        top: 20rem;
        color: #F4F1ED;
        width: 570px;
    }

    .PoweredbyTitleWrepper {
        max-width: 847px;
        display: flex;

        img {
            width: 100px;
            height: 100px !important;
            object-fit: contain;
            display: none;
        }


    }

    .Poweredby_logoImg {
        display: flex;
        justify-content: flex-end;

        .powered-text {
            span {
                font-size: 14px;
                line-height: 20px;
                margin-right: 35px;
                font-weight: 400;
                color: #19486A;
            }
        }

        img {
            width: 100px;
            height: 33px;
            object-fit: contain;
            margin-right: 35px;
            max-width: 100px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.bg-image {
    background-image:
        linear-gradient(201deg,
            rgba(0, 0, 0, 0.1) 55%,
            rgba(221, 166, 58, 0.8) 100%),
        url("./.././assets/images/2x-challenge-bg.png");
    height: 700px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

h1 {
    font-style: normal;
    font-size: 45px;
    line-height: 45px;
    color: #000;
}
</style>