<template>
  <div class="static">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated && theme && theme._id" key="content">
        <div class="flex flex-col items-center justify-center mt-top-5 w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <img
                    v-if="countryData.countries.length == 1"
                    class="mr-5 h-16 print:h-auto print:w-64"
                    :src="country.flagUrl"
                  />
                  <h1
                    v-if="sector == false && countryData.countries.length == 1"
                    class="text-6xl tracking-tighter font-medium text-center print:text-7xl"
                  >
                    {{ country.humanName }}
                  </h1>
                  <h1
                    v-if="sector"
                    class="text-4xl tracking-tighter font-medium text-center print:text-7xl"
                  >
                    {{ sector.name }}
                    <span v-if="countryData.countries.length == 1"
                      >in {{ country.humanName }}</span
                    >
                  </h1>
                  <h1
                    v-if="sector == false && countryData.countries.length > 1"
                    class="text-4xl tracking-tighter font-medium text-center print:text-7xl"
                  >
                    Multiple Countries
                  </h1>
                </div>
                <p
                  v-if="countryData.countries.length == 1"
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center"
                >
                  Region:
                  {{
                    country.region == "Eastern Europe & Central Asia"
                      ? "Europe & Central Asia"
                      : country.region
                  }}
                  | Income Group:
                  {{ toTitle(country.income || "") }}
                  <span v-if="sector && sector.id"
                    >| Sector: {{ sector.name }}</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div
            id="buttonContainer"
            class="flex flex-row items-center justify-center gap-2 mt-10"
          >
            <button
              :style="'background-color:' + env.brandingColors.navy"
              @click="scrollTo('introductionSection')"
              class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
            >
              <span>Scroll to Introduction</span>
            </button>
            <button
              :style="'background-color:' + env.brandingColors.navy"
              @click="scrollTo('SectionGenderEquality')"
              class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
            >
              <span>Scroll to Gender Equality Scores</span>
            </button>
            <button
              :style="'background-color:' + env.brandingColors.navy"
              @click="scrollTo('SectionIndicators')"
              class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
            >
              <span>Scroll to Indicators</span>
            </button>
            <button
              :style="'background-color:' + env.brandingColors.navy"
              @click="scrollTo('SectionWhatWorks')"
              class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
            >
              <span>Scroll to What Works</span>
            </button>
          </div>
          <div
            class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4"
            style="width: 50%; margin-top: 13px"
          >
            <svg
              class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Artboard 329</title>
              <circle
                cx="11.059"
                cy="11.059"
                r="7.059"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                x1="20"
                y1="20"
                x2="16.05"
                y2="16.05"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <v-select
              class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
              placeholder="Select Indicators"
              label="indicator"
              @input="redirectIndicator"
              :options="themesOptions"
            ></v-select>
          </div>
          <!-- <div id="sideNav" class="sideNavMenu gap-2">
                    <button @click="scrollTo('introductionSection')" class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 shadow border border-indigo-200 flex flex-row items-center justify-center gap-2 mt-5">
                        <span>Introduction</span>
                    </button>
                    <button @click="scrollTo('SectionGenderEquality')" class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 shadow border border-indigo-200 flex flex-row items-center justify-center gap-2 mt-5">
                        <span>Gender Equality Scores</span>
                    </button>
                    <button @click="scrollTo('SectionIndicators')" class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 shadow border border-indigo-200 flex flex-row items-center justify-center gap-2 mt-5">
                        <span>Indicators</span>
                    </button>
                    <button @click="scrollTo('SectionWhatWorks')" class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 shadow border border-indigo-200 flex flex-row items-center justify-center gap-2 mt-5">
                        <span>What Works</span>
                    </button>
                </div> -->
        </div>

        <main>
          <div class="navsticky">
            <span>Scroll to...</span>
            <ol class="global_analysis_side_bar_list">
              <li>
                <button
                  @click="scrollTo('introductionSection')"
                  id="introductionSection1"
                  class="buttonlink"
                  style="margin-left: -10px"
                >
                  <span>Introduction</span>
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('SectionGenderEquality')"
                  id="SectionGenderEquality1"
                  class="buttonlink"
                  style="margin-left: -10px"
                >
                  <span
                    >Snapshot<span style="visibility: hidden">extra</span></span
                  >
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('SectionIndicators')"
                  id="SectionIndicators1"
                  class="buttonlink"
                  style="margin-left: -10px"
                >
                  <span>Indicators</span>
                </button>
              </li>
            </ol>
            <br />
            <span>Go to...</span>
            <ol class="global_analysis_side_bar_list">
              <li>
                <router-link
                  :to="
                    '/reports/' +
                    $route.params.iso2 +
                    '/' +
                    $route.params.sector +
                    '/what-works'
                  "
                  class="buttonlink"
                  style="margin-left: -10px"
                  ><span>What Works</span></router-link
                >
              </li>
              <li>
                <router-link
                  :to="
                    '/reports/' +
                    $route.params.iso2 +
                    '/' +
                    $route.params.sector +
                    '/domains'
                  "
                  class="buttonlink"
                  style="margin-left: -10px"
                  ><span>Domains</span></router-link
                >
              </li>
              <li v-if="countryData.countries.length == 1">
                <router-link
                  :to="
                    '/reports/' +
                    country.ISO2 +
                    '/' +
                    $route.params.sector +
                    '/themes'
                  "
                  class="buttonlink"
                  style="margin-left: -10px"
                  ><span>Themes</span></router-link
                >
              </li>
            </ol>
          </div>
          <div>
            <section
              class="w-full container mx-auto mt-20 px-4 lg:px-0 maincontent"
              id="introductionSection"
              style="max-width: 960px"
            >
              <h1
                class="text-4xl print:text-4xl tracking-tighter font-medium flex flex-row items-center justify-center"
              >
                {{ themeData.name }}
              </h1>
              <div class="flex flex-col mt-10">
                <div class="flex">
                  <h1
                    class="text-2xl leading-6 font-medium mb-2"
                    style="margin: auto 0"
                  >
                    1. Introduction
                  </h1>
                </div>
                <div class="text-gray-600 w-full mt-3">
                  <item
                    :text="themeData.intro"
                    :country="country"
                    class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
              </div>
            </section>

            <section
              v-if="!sector || !sector.id.includes('CVID_')"
              class="w-full container mx-auto mt-20 px-4 lg:px-0 marginbottom3"
              v-scroll-reveal
              style="max-width: 960px"
            >
              <div
                class="shadow overflow-hidden rounded-lg border border-indigo-200"
                :style="'background-color:' + env.brandingColors.navy"
              >
                <div
                  class="px-4 py-5 border-b border-indigo-200 sm:px-6 flex row cursor-pointer"
                  @click="togglePanel('covid')"
                >
                  <h3 class="text-lg leading-6 font-medium text-white">
                    What are the impacts of COVID-19?
                  </h3>
                  <div
                    style="margin-left: auto; cursor: pointer"
                    class="print:leading-relaxed print:text-sm text-white"
                  >
                    <svg
                      class="transform transition duration-150 feather feather-chevron-right no-print"
                      :class="[
                        openPanels.indexOf('covid') > -1 || print
                          ? 'rotate-90'
                          : 'rotate-0',
                      ]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </div>
                <transition name="slide-fade">
                  <div
                    :class="
                      openPanels.indexOf('covid') > -1 || print
                        ? 'block'
                        : 'hidden'
                    "
                    class="flex flex-col mt-5 px-10 pb-5"
                  >
                    <div class="text-white w-full">
                      <item
                        :text="themeData.covid"
                        :country="country"
                        class="print:leading-relaxed print:text-xs leading-7"
                      ></item>
                    </div>
                  </div>
                </transition>
              </div>
            </section>

            <div id="SectionGenderEquality" class="maincontent">
              <span style="visibility: hidden">SectionGenderEquality</span>
            </div>
            <section
              id="spiderchart_section"
              class="w-full container mx-auto print:mt-10 margintop1 block px-4 lg:px-0 border-t border-gray-300"
              v-scroll-reveal
              style="max-width: 960px"
            >
              <div class="flex flex-col margintop3 print:mt-10">
                <h1 class="text-2xl leading-6 font-medium mb-2">
                  2. Overview of Gender Equality Scores for
                  {{ themeData.name }} by
                  <span class="triggerElement hoverTrigger text-2xl"
                    >Domain
                    <span
                      class="hoverMethodology absolutetop-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                    >
                      <methodology />
                    </span>
                  </span>
                </h1>
                <div class="text-gray-600 w-full font-medium leading-7 mt-3">
                  <p>
                    The following chart depicts how
                    <span v-for="(cont, i) in themeData.countries" :key="i">
                      <span v-if="i < themeData.countries.length - 1">
                        {{ cont.humanName + ", " }}</span
                      >
                      <span v-else> {{ cont.humanName }}</span>
                    </span>
                    measures up to other countries in the region, countries
                    within the same income group, and to all lower middle income
                    countries globally. Explore each
                    <span class="triggerElement hoverTrigger"
                      >domain
                      <span
                        class="hoverMethodology absolutetop-0 top-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                      >
                        <methodology />
                      </span>
                    </span>
                    and theme intersection gender equality, parity, and
                    confidence scores.
                  </p>
                </div>
                <div class="block pb-i-avoid">
                  <div class="flex flex-col items-center mt-16">
                    <h1 class="text-lg leading-6 font-semibold">
                      <span>{{ themeData.name }}</span> Gender Equality Scores
                      by
                      <span class="triggerElement hoverTrigger"
                        >Domain
                        <span
                          class="hoverMethodology absolutetop-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                        >
                          <methodology />
                        </span>
                      </span>
                    </h1>
                    <p class="text-gray-500 text-sm font-medium mt-2">
                      Hover on the chart to get more information about the
                      <span class="triggerElement hoverTrigger"
                        >domains
                        <span
                          class="hoverMethodology abosolutetop-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                        >
                          <methodology />
                        </span>
                      </span>
                    </p>
                    <p class="text-gray-500 text-sm font-medium mt-2">
                      1.0 = Perfect gender equality
                    </p>
                    <br /><br />
                    <div
                      class="container mx-auto flex flex-row items-center justify-center"
                    >
                      <template>
                        <spiderchart-multiple
                          chartCategory="theme"
                          :styles="myStyles"
                          :themeData="themeData"
                          :countryD="countryData"
                          :iso="$route.params.iso2"
                          key="1"
                          v-if="
                            (theme.intersections || []).length > 3 &&
                            countryData.countries.length > 1
                          "
                          id="spiderchart"
                        ></spiderchart-multiple>
                        <spiderchart
                          :styles="myStyles"
                          :themeId="$route.params.themeId"
                          :iso="$route.params.iso2"
                          key="1"
                          v-if="
                            (theme.intersections || []).length > 3 &&
                            countryData.countries.length == 1
                          "
                          id="spiderchart"
                        ></spiderchart>
                        <spiderchart-multiple
                          chartCategory="theme"
                          :styles="myStyles"
                          :themeData="themeData"
                          :countryD="countryData"
                          :iso="$route.params.iso2"
                          key="1"
                          v-if="
                            (theme.intersections || []).length == 3 &&
                            countryData.countries.length > 1
                          "
                          id="spiderchart"
                        ></spiderchart-multiple>
                        <spiderchart
                          :styles="myStyles3Cases"
                          :themeId="$route.params.themeId"
                          :iso="$route.params.iso2"
                          key="2"
                          v-else-if="
                            (theme.intersections || []).length === 3 &&
                            countryData.countries.length == 1
                          "
                          id="spiderchart"
                        ></spiderchart>
                        <lineChart
                          :styles="myStylesLine"
                          :themeId="$route.params.themeId"
                          key="3"
                          :iso="$route.params.iso2"
                          id="intersection-bar-chart"
                          v-if="
                            (theme.intersections || []).length < 3 &&
                            countryData.countries.length == 1
                          "
                        >
                        </lineChart>
                        <lineChart-multiple
                          chartCategory="theme"
                          :styles="myStylesLine"
                          :themeId="$route.params.themeId"
                          :themeData="themeData"
                          :countryD="countryData"
                          key="3"
                          :iso="$route.params.iso2"
                          id="intersection-bar-chart"
                          v-if="
                            (theme.intersections || []).length < 3 &&
                            countryData.countries.length > 1
                          "
                        >
                        </lineChart-multiple>
                      </template>
                    </div>
                    <div
                      class="w-full flex flex-row"
                      v-if="false && (theme.intersections || []).length > 2"
                    >
                      <div
                        class="flex flex-wrap w-8/12 mx-auto items-center"
                        style="justify-content: center"
                      >
                        <div
                          v-for="(color, taab) in chartLabels"
                          v-bind:key="taab.name"
                          class="rounded-lg p-2 px-4 m-1 text-white flex flex-row"
                          :style="'color:' + color.font"
                        >
                          <div class="mr-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              :style="'color:' + color.color"
                              :fill="color.color"
                              stroke="currentColor"
                            >
                              <line
                                x1="0"
                                y1="12"
                                x2="24"
                                y2="12"
                                stroke-width="6"
                              ></line>
                            </svg>
                          </div>
                          <div>{{ taab }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div id="SectionIndicators" class="maincontent">
              <span style="visibility: hidden">SectionIndicators</span>
            </div>
            <section
              class="w-full container mx-auto mt-20 px-4 lg:px-20 py-10 bg-gray-100 border border-gray-200 rounded-lg shadow-2xl"
            >
              <div class="flex flex-col mt-10" v-scroll-reveal>
                <h1 class="text-2xl leading-6 font-medium mb-2">
                  3. Explore The Indicators
                </h1>
                <div
                  class="text-gray-600 w-full text-justify font-medium leading-7 mt-3"
                >
                  <p>
                    Scroll down to explore data related to this specific theme.
                    Each indicator includes country-specific data, accompanied
                    by regional and global averages for comparison. A short
                    descriptive narrative accompanies each statistic to help
                    understand the "so what." Most of these indicators power the
                    gender equality score for this theme, although some
                    supplementary descriptive statistics may appear.
                  </p>
                </div>
              </div>

              <!-- FOR EACH INDICATOR -->
              <div
                v-for="(indicator, index) in chartDataForCharts[0].indicators"
                v-bind:key="indicator._id"
              >
                <div
                  class="flex flex-row w-full mt-32 pb-i-avoid"
                  :id="
                    'indicator' +
                    indicator.name.toLowerCase().trim().split(/\s+/).join('-')
                  "
                >
                  <!--Dom: {{indicator.taab}} {{indicatorColor(indicator.taab)}}-->
                  <div
                    class="flex flex-col w-6/12 pr-5 print:pr-10"
                    v-scroll-reveal
                  >
                    <p
                      class="tracking-tight text-lg font-medium mb-2"
                      :style="
                        'color: ' + env.brandingColors.navy + ' !important;'
                      "
                      :id="'indicator' + indicator.name"
                    >
                      3.{{ index + 1 }}. {{ indicator.name }}
                    </p>
                    <div>
                      <div
                        class="rounded-lg text-black flex flex-col text-md font-medium mt-4 gap-2"
                      >
                        <p class="flex flex-row items-center justify-start">
                          <svg
                            class="w-5 h-5 mx-2"
                            :style="'color: ' + indicatorColor(indicator.taab)"
                            version="1.1"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                          >
                            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                            <g
                              stroke-linecap="round"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linejoin="round"
                            >
                              <path d="M19,12h-14"></path>
                              <path d="M14,17l5,-5"></path>
                              <path d="M14,7l5,5"></path>
                            </g>
                            <path fill="none" d="M0,0h24v24h-24Z"></path>
                          </svg>
                          <a
                            :href="
                              '/#/reports/' +
                              $route.params.iso2 +
                              '/' +
                              $route.params.sector +
                              '/domains/' +
                              indicator.taab
                            "
                            :style="'color: ' + indicatorColor(indicator.taab)"
                            >Domain: {{ indicator.taab }}</a
                          >
                        </p>
                        <p
                          v-if="
                            (indicator.meta && indicator.meta.scored) ||
                            indicator.meta.scored == 'true'
                          "
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Contributes to the Gender Equality Score</i>
                        </p>
                        <p
                          v-if="
                            indicator.meta.scored === false ||
                            indicator.meta.scored === 'false'
                          "
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Contextual Indicator (Non-Scored)</i>
                        </p>
                        <p
                          v-if="
                            (indicator.meta &&
                              indicator.meta.proxyUsed === 'No') ||
                            indicator.meta.proxyUsed === 'Missing'
                          "
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Internationally Standardized Indicator</i>
                        </p>
                        <p
                          v-if="
                            indicator.meta && indicator.meta.proxyUsed === 'Alt'
                          "
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Proxy Data Used (Non-Standard)</i>
                        </p>
                        <p
                          v-if="
                            indicator.meta && indicator.meta.proxyUsed === 'Old'
                          "
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Indicator with Outdated Data</i>
                        </p>
                        <p
                          v-if="indicator.sectorSpecific == true"
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Sector-Specific Indicator</i>
                          <img
                            class="ml-1 w-1/12 h-5"
                            v-if="$route.params.sector != 'country'"
                            :src="currentIndustry.icon"
                          />
                        </p>
                      </div>
                    </div>
                    <p class="text-gray-600 mt-5 text-justify">
                      <!--<item :text="indicator.text" :country="country"></item>-->
                      <rounded-item
                        :text="indicator.text"
                        :country="country"
                      ></rounded-item>
                    </p>
                    <p class="text-gray-600 mt-5 text-justify">
                      <!--<item :text="indicator.chartText" :country="country"></item>-->
                      <rounded-item
                        v-if="countryData.countries.length == 1"
                        :text="indicator.chartText"
                        :country="country"
                        :currency="currency"
                      ></rounded-item>
                    </p>
                  </div>
                  <div
                    class="flex flex-col items-center w-6/12"
                    v-scroll-reveal
                  >
                    <div class="radarchart">
                      <doughnutChart
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels[0]"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'doughnutChart' &&
                          countryData.countries.length == 1
                        "
                      >
                      </doughnutChart>
                      <doughnutChart-multiple
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :countryD="countryData.countries"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :chartLabelsForCharts="chartLabelsForCharts"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'doughnutChart' &&
                          countryData.countries.length > 1
                        "
                      >
                      </doughnutChart-multiple>

                      <barChartVertical
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'barChartVertical' &&
                          countryData.countries.length == 1
                        "
                      >
                      </barChartVertical>
                      <div
                        v-if="
                          indicator.chartData.length > 5 &&
                          indicator.chartType == 'barChartVertical'
                        "
                      >
                        <div
                          v-for="(
                            countr, contIndex
                          ) in themeDataUnfiltered.countries"
                          :key="contIndex"
                        >
                          <div
                            style="
                              font-size: 20px;
                              font-weight: 600;
                              text-align: center;
                              margin: 15px;
                            "
                            v-if="
                              countr.indicators &&
                              countr.indicators[index] &&
                              countr.indicators[index].chartType &&
                              countr.indicators[index].chartType ==
                                'barChartVertical' &&
                              countryData.countries.length > 1
                            "
                          >
                            {{ countr.humanName }}
                          </div>
                          <barChartVertical
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="countryData.countries[contIndex]"
                            :chartData="countr.indicators[index].chartData"
                            :chartLabels="countr.indicators[index].chartLabels"
                            :indicator="countr.indicators[index]"
                            v-if="
                              countr.indicators &&
                              countr.indicators[index] &&
                              countr.indicators[index].chartType &&
                              countr.indicators[index].chartType ==
                                'barChartVertical' &&
                              countryData.countries.length > 1
                            "
                          >
                          </barChartVertical>
                        </div>
                      </div>
                      <barChartVertical-multiple
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :countryD="countryData.countries"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :chartLabelsForCharts="chartLabelsForCharts"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'barChartVertical' &&
                          countryData.countries.length > 1 &&
                          indicator.chartData.length < 6 &&
                          indicator.chartLabels[0] == '{{country}}'
                        "
                      >
                      </barChartVertical-multiple>
                      <barChartVertical-multiple-for-one-variable
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :countryD="countryData.countries"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :chartLabelsForCharts="chartLabelsForCharts"
                        :indicator="indicator"
                        v-else-if="
                          indicator.chartType == 'barChartVertical' &&
                          countryData.countries.length > 1 &&
                          indicator.chartData.length < 6 &&
                          indicator.chartLabels[0] != '{{country}}' &&
                          (indicator.chartLabels.length == 1 ||
                            indicator.chartLabels.length == 2)
                        "
                      >
                      </barChartVertical-multiple-for-one-variable>
                      <div v-else>
                        <div
                          v-if="
                            indicator.chartType == 'barChartVertical' &&
                            countryData.countries.length > 1 &&
                            indicator.chartData.length < 6
                          "
                        >
                          <div
                            v-for="(
                              countr, contIndex
                            ) in themeDataUnfiltered.countries"
                            :key="contIndex"
                          >
                            <div
                              style="
                                font-size: 20px;
                                font-weight: 600;
                                text-align: center;
                                margin: 15px;
                              "
                            >
                              {{ countr.humanName }}
                            </div>
                            <barChartVertical
                              class="print:scale-75 transform radarchart"
                              :index="index + 1"
                              :country="countryData.countries[contIndex]"
                              :chartData="countr.indicators[index].chartData"
                              :chartLabels="
                                countr.indicators[index].chartLabels
                              "
                              :indicator="countr.indicators[index]"
                            >
                            </barChartVertical>
                          </div>
                        </div>
                      </div>

                      <radarChart
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'radarChart' &&
                          countryData.countries.length == 1
                        "
                      >
                      </radarChart>
                      <radarChart-multiple
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :countryD="countryData.countries"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :chartLabelsForCharts="chartLabelsForCharts"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'radarChart' &&
                          countryData.countries.length > 1
                        "
                      >
                      </radarChart-multiple>

                      <bubbleChart
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'bubbleChart' &&
                          countryData.countries.length == 1
                        "
                      >
                      </bubbleChart>
                      <div
                        v-if="
                          indicator.chartData.length > 1 &&
                          indicator.chartType == 'bubbleChart'
                        "
                      >
                        <div
                          v-for="(
                            countr, contIndex
                          ) in themeDataUnfiltered.countries"
                          :key="contIndex"
                        >
                          <div
                            style="
                              font-size: 20px;
                              font-weight: 600;
                              text-align: center;
                              margin: 15px;
                            "
                          >
                            {{ countr.humanName }}
                          </div>
                          <bubbleChart
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="countryData.countries[contIndex]"
                            :chartData="countr.indicators[index].chartData"
                            :chartLabels="countr.indicators[index].chartLabels"
                            :indicator="countr.indicators[index]"
                            v-if="
                              indicator.chartType == 'bubbleChart' &&
                              countryData.countries.length > 1
                            "
                          >
                          </bubbleChart>
                        </div>
                      </div>
                      <bubbleChart-multiple
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :countryD="countryData.countries"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :chartLabelsForCharts="chartLabelsForCharts"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'bubbleChart' &&
                          countryData.countries.length > 1 &&
                          indicator.chartData.length == 1
                        "
                      >
                      </bubbleChart-multiple>

                      <checkCrossChart
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'checkcross' &&
                          countryData.countries.length == 1
                        "
                      >
                      </checkCrossChart>
                      <div
                        v-if="
                          indicator.chartData.length > 1 &&
                          indicator.chartType == 'checkcross'
                        "
                      >
                        <div
                          v-for="(
                            countr, contIndex
                          ) in themeDataUnfiltered.countries"
                          :key="contIndex"
                        >
                          <div
                            style="
                              font-size: 20px;
                              font-weight: 600;
                              text-align: center;
                              margin: 15px;
                            "
                          >
                            {{ countr.humanName }}
                          </div>
                          <checkCrossChart
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="countryData.countries[contIndex]"
                            :chartData="countr.indicators[index].chartData"
                            :chartLabels="countr.indicators[index].chartLabels"
                            :indicator="countr.indicators[index]"
                            v-if="
                              indicator.chartType == 'checkcross' &&
                              countryData.countries.length > 1
                            "
                          >
                          </checkCrossChart>
                        </div>
                      </div>
                      <checkCrossChart-multiple
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :countryD="countryData.countries"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :chartLabelsForCharts="chartLabelsForCharts"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'checkcross' &&
                          countryData.countries.length > 1 &&
                          indicator.chartData.length == 1
                        "
                      >
                      </checkCrossChart-multiple>

                      <tierChart
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'tierChart' &&
                          countryData.countries.length == 1
                        "
                      >
                      </tierChart>
                      <tierChart-multiple
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :countryD="countryData.countries"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :chartLabelsForCharts="chartLabelsForCharts"
                        :indicator="indicator"
                        v-if="
                          indicator.chartType == 'tierChart' &&
                          countryData.countries.length > 1
                        "
                      >
                      </tierChart-multiple>
                      <label
                        class="recentlyUpdatedLabel"
                        v-if="
                          indicator.meta &&
                          checkRecentlyUpdated(indicator.meta.createdTime)
                        "
                      >
                        Recently updated!
                      </label>
                    </div>
                    <div class="histData w-full">
                      <historicalData
                        v-if="countryData.countries.length == 1"
                        :indicator="indicator"
                        :country="country"
                      ></historicalData>
                      <!-- <p v-if="(indicator.unknownHist > 0) & (indicator.unknownHist == indicator.variables.length)" class="text-sm"><i>No historical data available for these indicators.</i></p> -->
                      <p
                        v-if="
                          (indicator.unknownHist > 0) &
                          (indicator.unknownHist !=
                            (indicator.variables && indicator.variables.length))
                        "
                        class="text-sm mt-4"
                      >
                        <i
                          >No historical data available for the other
                          indicators.</i
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div id="SectionWhatWorks" class="maincontent">
              <span style="visibility: hidden">SectionWhatWorks1</span>
            </div>
            <section
              class="container mx-auto margintop3 px-4 lg:px-0"
              style="max-width: 960px"
            >
              <div class="flex flex-col mt-10 mb-10" v-scroll-reveal>
                <h1 class="text-2xl leading-6 font-medium mb-2">
                  4. What Works?
                </h1>
                <div
                  class="text-gray-600 w-full text-justify mt-3 font-medium leading-7"
                >
                  <p>
                    It's time for action! Understanding the challenges and
                    opportunities to improve gender equality is the important
                    first step to support action and change. The next step is
                    taking that information and doing something about it. Click
                    the button below to read information about what works to
                    harness gender equality opportunities and address challenges
                    for this theme, including resources and tools to support
                    action.
                  </p>
                </div>
                <div
                  class="w-full container mx-auto print:hidden px-4 lg:px-0 flex flex-col items-start justify-start gap-4"
                >
                  <router-link
                    :to="
                      '/reports/' +
                      $route.params.iso2 +
                      '/' +
                      $route.params.sector +
                      '/what-works'
                    "
                    :style="'background-color:' + env.brandingColors.navy"
                    class="px-3 py-2 my-4 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
                  >
                    <p class="font-medium capitalize ml-3">See What Works!</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-arrow-right"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </router-link>
                </div>
              </div>
            </section>

            <section
              class="w-full container mx-auto mt-32 mb-32 print:hidden px-4 lg:px-0 flex flex-col items-end justify-end gap-4"
              v-if="!print"
            >
              <router-link
                :to="
                  '/reports/' +
                  $route.params.iso2 +
                  '/' +
                  $route.params.sector +
                  '/themes'
                "
                v-if="countryData.countries.length == 1"
                :style="'background-color:' + env.brandingColors.navy"
                class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
              >
                <p class="font-medium capitalize ml-3">Back to All Themes</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </router-link>
              <router-link
                to="/gesi-analysis/choose-country"
                :style="'background-color:' + env.brandingColors.navy"
                class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
              >
                <p class="font-medium capitalize ml-3">
                  Back to Country Overview
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </router-link>
            </section>

            <section
              class="w-full container mx-auto mt-32 mb-32 px-4 lg:px-0"
              style="max-width: 960px"
            >
              <div class="flex flex-col mt-10 justify-start">
                <p class="text-xs text-gray-500">
                  Data Use and Citation: Equilo grants full permission to its
                  users to copy and paste information that appears in the
                  dashboard for their own internal use, directly citing the
                  original data sources and citing Equilo for analysis and
                  scoring. Please request permission from Equilo before
                  utilizing our content for external publications.
                </p>
                <p
                  class="text-xs text-gray-500 mt-5 mb-1"
                  style="max-width: 960px"
                >
                  Sources on this page:<br />
                </p>
                <ol class="list-decimal mx-5">
                  <li
                    v-for="citation in allBibliographyData"
                    v-bind:key="
                      citation.id + String(Math.floor(Math.random() * 1000))
                    "
                  >
                    {{
                      ((citation || {}).text || "")
                        .toString()
                        .replace("n.d.", citation.year + ". ")
                    }}
                    {{ citation && citation.text ? "" : "Source not found " }}
                    (<a
                      target="_blank"
                      :href="citation.url"
                      style="word-wrap: break-word"
                      >{{ citation.url }}</a
                    >)
                  </li>
                </ol>
                <p class="text-xs text-gray-500 mt-5" style="max-width: 960px">
                  Equilo uses the TAAP framework, which stands for Transforming
                  Agency, Access, and Power. Comprised of six domains, the TAAP
                  framework provides a structure to analyze and amplify the
                  voices, opportunities, and dignity of all people, especially
                  those from socially excluded groups. More information about
                  this approach can be found at taapinclusion.org. Equilo Dash
                  uses 15 themes that cut across two or more of the TAAP
                  domains, for a total of 32 unique TAAP domain/theme
                  intersections for analysis and scoring. Each gender equality
                  score is based on a specific set of indicators within a given
                  intersection.
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
      <div v-else style="margin-top: 10%">
        <div class="flex flex-row justify-center items-center mt-3 ml-20">
          <div class="flex flex-col item-center justify-center">
            <div
              class="flex flex-row items-center justify-center print:flex-col"
            >
              <!-- <img class="h-16 print:h-auto print:w-64"  src="@/assets/images/2X-assessment/My2XAssessments3b.png" alt="" /> -->
              <h1
                class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
              >
                No Data Found
              </h1>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env.js";
import item from "@/components/text.vue";
//import themeNameCustom from "@/components/theme-name-custom.vue";
import roundedItem from "@/components/text-roundnumbers.vue";
import Cookies from "@/helpers/cookies";

import spiderchart from "@/components/charts/theme-intersections.vue";
import spiderchartMultiple from "@/components/charts/theme-intersections-multiple-countries.vue";
import lineChart from "@/components/charts/theme-intersections-only-2.vue";
import lineChartMultiple from "@/components/charts/theme-intersections-only-2-multiple-countries.vue";

import barChartVertical from "@/components/charts/barChartVertical.vue";
import doughnutChart from "@/components/charts/doughnutChart.vue";
import radarChart from "@/components/charts/radarChart.vue";
import bubbleChart from "@/components/charts/bubbleChart.vue";
import checkCrossChart from "@/components/charts/checkCrossChart.vue";
import tierChart from "@/components/charts/tierChart.vue";

import barChartVerticalMultipleForOneVariable from "@/components/charts/barChartVertical-multiple-for-one.vue";

import barChartVerticalMultiple from "@/components/charts/barChartVertical-multiple.vue";
import doughnutChartMultiple from "@/components/charts/doughnutChart-multiple.vue";
import radarChartMultiple from "@/components/charts/radarChart-multiple.vue";
import bubbleChartMultiple from "@/components/charts/bubbleChart-multiple.vue";
import checkCrossChartMultiple from "@/components/charts/checkCrossChart-multiple.vue";
import tierChartMultiple from "@/components/charts/tierChart-multiple.vue";

import historicalData from "@/components/historical-data.vue";
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
import methodology from "@/components/methodology";

import moment from "moment";

export default {
  name: "country-theme",
  data: function () {
    return {
      country: {},
      theme: {},
      print: false,
      panel: false,
      chartLabels: {},
      openPanels: [],
      indicators: [],
      themesOptions: [],
      env,
      taabColors: {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
      },
      appearItems: [],
      sector: { name: "" },
      currency: {},
      endofcreated: false,
      countryData: [],
      themeData: [],
      sectorData: [],
      chartLabelsForCharts: [],
      chartDataForCharts: [],
      themeDataUnfiltered: [],
      sourceData: [],
      json_data: [],
      allBibliographyData: [],
    };
  },
  components: {
    item,
    roundedItem,
    spiderchart,
    lineChart,
    lineChartMultiple,
    spiderchartMultiple,
    barChartVertical,
    doughnutChart,
    radarChart,
    bubbleChart,
    checkCrossChart,
    tierChart,
    barChartVerticalMultipleForOneVariable,
    barChartVerticalMultiple,
    doughnutChartMultiple,
    radarChartMultiple,
    bubbleChartMultiple,
    checkCrossChartMultiple,
    tierChartMultiple,
    historicalData,
    pageLoader,
    methodology,
    //themeNameCustom
    //scoreChart
  },
  methods: {
    generateBibliographyCSV() {
      this.allBibliographyData.forEach((data, index) => {
        if (
          data.text &&
          data.url &&
          data.url != "Missing" &&
          data.text != "Missing"
        ) {
          let arr = [];
          arr.push(index + 1);
          arr.push(data.text);
          arr.push(data.url);
          this.json_data.push(arr);
        } else {
          let arr = [];
          arr.push(index + 1);
          arr.push("Source not found ()");
          this.json_data.push(arr);
        }
      });
    },
    async getBibliographyData() {
      if (this.bibliographySourceIds && this.bibliographySourceIds.length) {
        let arr = [];
        this.bibliographySourceIds.forEach(async (data) => {
          let val = data.tag.replace(/[[\]]/g, "");
          arr.push(val);
        });
        await this.getSource(arr);
      }
    },
    async getSource(sourceId) {
      let body = { ids: sourceId };
      return axios
        .post(env.apiUrl + "/sources/all-citation", body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.sourceData = res.data;
          // let item = { "index": index, "data": this.sourceData };
          this.sourceData = res.data;
          this.allBibliographyData = this.sourceData;
          // if (this.bibliographySourceIds.length == this.allBibliographyData.length) {
          await this.generateBibliographyCSV();
          // }
        });
    },
    redirectFromAnotherpage() {
      var hashValue = location.hash;
      var lashash = hashValue.lastIndexOf("#");
      if (
        hashValue.substring(lashash + 1).indexOf("/") === -1 ||
        hashValue.substring(lashash + 1).indexOf("/") > 0
      ) {
        const element = document.getElementById(
          "indicator" + hashValue.substring(lashash + 1)
        );
        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition + offset;
        window.scrollTo({
          top: offsetPosition - 80,
          behavior: "smooth",
        });
      }
    },
    redirectIndicator(name) {
      if (name) {
        var indicatorId = name.toLowerCase().trim().split(/\s+/).join("-");
        const element = document.getElementById("indicator" + indicatorId);
        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition + offset;
        window.scrollTo({
          top: offsetPosition - 80,
          behavior: "smooth",
        });
      }
    },
    // We go for an array so we can have multiple panels open at the same time (eg. download PDF)
    togglePanel: function (panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
    toFixed: function (n, x) {
      if (n == "Missing") {
        return "Missing";
      } else {
        return n.toFixed(x || 2);
      }
    },
    getPercentage(number) {
      return Math.round((number * 100) / 2);
    },
    value: function (input) {
      return this.format("{{" + input + "}}");
    },
    opacity: function (hex) {
      if (!hex) {
        return "#black";
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    getCountry: function () {
      const _self = this;
      const isoCodes = this.$route.params.iso2.split(",");
      return new Promise(function (resolve) {
        let iso = { isoCodes };
        axios
          .post(
            env.apiUrl +
              "/countries" +
              // _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector,
            iso,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getCurrency: function () {
      const _self = this;
      return new Promise(function (resolve) {
        const filter = "AND({ISO} = '" + _self.country.ISO2 + "')";
        const params = { filterByFormula: filter || "" };
        const headers = {
          Authorization: `Bearer ${env.airTableAccessToken}`,
          "Content-Type": "application/json",
        };

        axios
          .get(
            "https://api.airtable.com/v0/appXebWdllnMq540w/Countries?maxRecords=1&view=Grid%20view",
            { params: params, headers: headers }
          )
          .then((res) => {
            resolve(res.data.records);
          });
      });
    },
    getTheme: function () {
      const _self = this;
      const isoCodes = this.$route.params.iso2.split(",");
      return new Promise(function (resolve) {
        let iso = { isoCodes };
        axios
          .post(
            env.apiUrl +
              "/reports" +
              // _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector +
              "/themes/" +
              _self.$route.params.themeId,
            iso,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    scrollTo(id) {
      //document.getElementById(id).scrollIntoView();

      const element = document.getElementById(id);
      const offset = 0;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      var lis = document
        .getElementsByClassName("navsticky")[0]
        .getElementsByTagName("ol")[0]
        .getElementsByTagName("li");
      for (
        let i = 0;
        i <
        document.getElementsByTagName("ol")[0].getElementsByTagName("li")
          .length;
        i++
      ) {
        lis[i].classList.remove("active");
      }
      document.getElementById(id + "1").parentElement.classList.add("active");
    },
    toTitle(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    show(el) {
      if (this.appearItems.indexOf(el.id) < 0) {
        this.appearItems.push(el.id);
      }
    },
    getSector: function () {
      const _self = this;
      return new Promise(function (resolve) {
        // Validate if you want general country information (count/base) or a specific sector
        if (["BASE", "country"].indexOf(_self.$route.params.sector) > -1) {
          resolve(false);
        } else {
          axios
            .get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso: _self.$route.params.iso2 },
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        }
      });
    },
    indicatorColor(taab) {
      if (taab === "Access to Resources") {
        return env.taabColors.accessToResources;
      } else if (taab === "Law & Policy") {
        return env.taabColors.lawAndPolicy;
      } else if (taab === "Power & Decision-Making") {
        return env.taabColors.powerAndDecisio;
      } else if (taab === "Knowledge & Beliefs") {
        return env.taabColors.knowledgeAndBeliefs;
      } else if (taab === "Human Dignity") {
        return env.taabColors.humanDignity;
      } else if (taab === "Roles & Responsibilities") {
        return env.taabColors.rolesAndResponsibilities;
      }
      return true;
    },
    extractVars: function (text) {
      let arrStr = text.split(/[{{}}]/);
      arrStr = arrStr.filter(
        (a) =>
          a.includes("_") &
          !a.includes("income") &
          !a.includes("global") &
          !a.includes("region")
      );
      return arrStr;
    },
    checkRecentlyUpdated(createdTime) {
      if (!createdTime) return false;
      return moment().diff(moment(createdTime), "months", true) <= 3;
    },
  },
  computed: {
    bibliographySourceIds() {
      return this.$store.state.bibliographyData;
    },
    citations() {
      const compare = (a, b) => {
        if (a.rank < b.rank) {
          return -1;
        }
        if (a.rank > b.rank) {
          return 1;
        }
        return 0;
      };
      const x = this.$store.state.citations;
      return x.sort(compare);
    },
    myStyles() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      };
    },
    myStyles3Cases() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        marginBottom: "-100px",
        justifyContent: "center",
      };
    },
    myStylesLine() {
      return {
        height: "600px",
        position: "relative",
        width: "50%",
        display: "flex",
        justifyContent: "center",
      };
    },
  },
  async created() {
    // validate if you have access to this country.
    this.user = await auth.me().catch(() => {});
    this.$store.dispatch("emptyBibliographyData");
    if (
      (this.$route.params.iso2 != "EQ" && !this.user.organization.accessGesi) ||
      document.location.href.indexOf("print=") > -1
    ) {
      this.$router.push({ name: "notAllowed" });
    }

    this.$store.commit("resetCitations");

    this.countryData = await this.getCountry(); // lets do an await before rendering other items.
    this.country = this.countryData.countries[0];
    this.themeDataUnfiltered = await this.getTheme();
    this.themeData = await this.getTheme();
    this.theme = this.themeData.countries[0];
    // this.country.values = this.theme.values;

    this.themeData.countries.forEach((obj, index) => {
      this.countryData.countries[index].values = obj.values;
    });

    this.chartDataForCharts = [...this.themeData.countries];
    this.themeData.countries.forEach((data) => {
      this.chartLabelsForCharts.push(data.humanName);
    });
    this.chartLabelsForCharts.push("Global");
    this.sector = await this.getSector(); // lets do an await before rendering other items.
    const _self = this;
    const urlindustries = env.apiUrl + "/industries/";
    axios
      .get(urlindustries, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      })
      .then(function (res) {
        _self.count = res.data.count;
        _self.industries = res.data.data;
        if (_self.industries) {
          _self.currentIndustry = _self.industries.filter(
            (a) => a.id == _self.sector.parent
          )[0];
        }
        //_self.filteredIndustries = JSON.parse(JSON.stringify(_self.industries));
      });

    const currencyData = await this.getCurrency();
    if (typeof currencyData[0].fields.Currency_Plural !== "undefined") {
      this.currency = currencyData[0].fields.Currency_Plural;
    } else {
      this.currency = "in local currency";
    }
    // is this code not unsed???
    if (this.theme && this.theme.indicators && this.theme.indicators.length) {
      for (var i = 0; i < this.theme.indicators.length; i++) {
        if (
          this.theme?.indicators[i]?.name?.indexOf("Genital Cutting") < 0
          // &&
          // this.theme?.indicators[i]?.meta?.value !== "Missing"
        ) {
          //create flag if there is an indicator with unknown historical data
          const variables = this.extractVars(
            this.theme.indicators[i].chartText
          );
          let unknownHistorical = 0;
          for (let j = 0; j < variables.length; j++) {
            if (
              (this.country.values[`${variables[j]}_hist_change`] == "") |
              (this.country.values[`${variables[j]}_hist_change`] ==
                "Missing") |
              (this.country.values[`${variables[j]}_hist_change`] == "Unknown")
            ) {
              unknownHistorical += 1;
            }
          }

          this.theme.indicators[i].unknownHist = unknownHistorical;
          this.theme.indicators[i].variables = variables;

          this.indicators.push(this.theme.indicators[i]);
          this.themesOptions.push(this.theme.indicators[i].name);
        }
      }
    }
    this.chartLabels[this.country.humanName] = {
      color: env.geoColors.countryspider,
      font: env.geoColors.countryspider,
      background: this.opacity(env.geoColors.countryspider),
    };
    this.chartLabels[this.country.region] = {
      color: env.geoColors.regionspider,
      font: env.geoColors.regionspider,
      background: this.opacity(env.geoColors.regionspider),
    };
    this.chartLabels[this.country.income] = {
      color: env.geoColors.incomespider,
      font: env.geoColors.incomespider,
      background: this.opacity(env.geoColors.incomespider),
    };
    this.chartLabels["LMIC"] = {
      color: env.geoColors.lmicspider,
      font: env.geoColors.lmicspider,
      background: this.opacity(env.geoColors.lmicspider),
    };
    this.chartLabels["Perfect Equality"] = {
      color: "#3F37C9",
      font: "#3F37C9",
      background: "#3F37C9",
    };

    if (document.location.href.indexOf("print=true") > -1) {
      this.print = true;
    }
    this.$store.commit("downloadable", true);

    this.$store.commit("setBreadcrumbs", [
      {
        name: "Countries",
        url: "/",
      },
      {
        name:
          this.$route.params.iso2.split(",").length == 1
            ? this.country.humanName || this.country.name
            : "Multiple Countries",
        url: "/gesi-analysis/" + this.$route.params.iso2 + "/choose-sector",
      },
      {
        name: (this.sector || { name: "country" }).name,
        url:
          "/gesi-analysis/" +
          this.$route.params.iso2 +
          "/" +
          this.$route.params.sector +
          "/snapshot",
      },

      {
        name: "Themes",
        url:
          "/reports/" +
          this.$route.params.iso2 +
          "/" +
          this.$route.params.sector +
          "/themes",
      },
      {
        name: this.themeData && this.themeData.name ? this.themeData.name : "",
        url:
          "/reports/" +
          this.$route.params.iso2 +
          "/" +
          this.$route.params.sector +
          "/themes/" +
          this.$route.params.themeId,
      },
    ]);
    if (this.countryData.countries.length > 1) {
      this.chartDataForCharts[0].indicators.forEach((data, d) => {
        if (
          data.chartType == "barChartVertical" &&
          data.chartData.length < 6 &&
          data.chartLabels[0] != "{{country}}" &&
          (data.chartLabels.length == 1 || data.chartLabels.length == 2)
        ) {
          let a = "";
          let a2 = "";
          let a_global = "";
          let a2_global = "";
          a =
            this.themeDataUnfiltered.countries[0].indicators[d].chartData[0]
              .data[0];
          if (
            this.themeDataUnfiltered.countries[0].indicators[d].chartData[0]
              .data[1]
          ) {
            a2 =
              this.themeDataUnfiltered.countries[0].indicators[d].chartData[0]
                .data[1];
            a2_global =
              this.themeDataUnfiltered.countries[0].indicators[d].chartData[
                this.themeDataUnfiltered.countries[0].indicators[d].chartData
                  .length - 1
              ].data[1];
          }
          a_global =
            this.themeDataUnfiltered.countries[0].indicators[d].chartData[
              this.themeDataUnfiltered.countries[0].indicators[d].chartData
                .length - 1
            ].data[0];
          this.chartDataForCharts[0].indicators[d].chartData = [];
          for (let z = 0; z < this.countryData.countries.length; z++) {
            if (a2) {
              this.chartDataForCharts[0].indicators[d].chartData.push({
                data: [a, a2],
                label: "{{country}}",
              });
            } else {
              this.chartDataForCharts[0].indicators[d].chartData.push({
                data: [a],
                label: "{{country}}",
              });
            }
          }
          if (a2_global) {
            this.chartDataForCharts[0].indicators[d].chartData.push({
              data: [a_global, a2_global],
              label: "Global",
            });
          } else {
            this.chartDataForCharts[0].indicators[d].chartData.push({
              data: [a_global],
              label: "Global",
            });
          }
        } else {
          data.chartData.forEach((item, i) => {
            let a = "";
            a = this.chartDataForCharts[0].indicators[d].chartData[i].data[0];
            this.chartDataForCharts[0].indicators[d].chartData[i].data = [];
            for (let z = 0; z < this.countryData.countries.length; z++) {
              this.chartDataForCharts[0].indicators[d].chartData[i].data.push(
                a
              );
            }
            if (
              this.chartDataForCharts[0].indicators[d].chartData[i].label ==
                "{{region}}" ||
              this.chartDataForCharts[0].indicators[d].chartData[i].label ==
                "LMIC" ||
              this.chartDataForCharts[0].indicators[d].chartData[i].label ==
                "{{income}}"
            ) {
              this.chartDataForCharts[0].indicators[d].chartData[i].label = "";
              this.chartDataForCharts[0].indicators[d].chartData[i].label =
                "{{country}}";
            }
            if (
              this.chartDataForCharts[0].indicators[d].chartData[
                i
              ].data[0].includes("_region")
            ) {
              this.chartDataForCharts[0].indicators[d].chartData.push(
                this.chartDataForCharts[0].indicators[d].chartData[0]
              );
            }
            if (
              this.chartDataForCharts[0].indicators[d].chartType ==
                "barChartVertical" ||
              this.chartDataForCharts[0].indicators[d].chartType == "radarChart"
            ) {
              let len =
                this.themeDataUnfiltered.countries[0].indicators[d].chartData[i]
                  .data.length;
              if (
                this.themeDataUnfiltered.countries[0].indicators[d].chartData[
                  i
                ].data[len - 1].includes("_global")
              ) {
                this.chartDataForCharts[0].indicators[d].chartData[i].data.push(
                  this.themeDataUnfiltered.countries[0].indicators[d].chartData[
                    i
                  ].data[len - 1]
                );
              }
            }
          });
          if (data.chartLabels[0] == "{{country}}") {
            data.chartLabels = [];
            for (let z = 0; z < this.countryData.countries.length; z++) {
              this.chartDataForCharts[0].indicators[d].chartLabels.push(
                "{{country}}"
              );
            }
          }
        }
      });
    }
    let allCountry = [];
    this.countryData.countries.forEach((data) => {
      allCountry.push(data.humanName);
    });
    this.$gtag.event("themes", {
      domain_name: "",
      theme_name: this.themeData.name,
      sector_name: this.sector.name,
      country_name: allCountry,
      page_name: this.$route.name,
      userId: this.user._id,
      team_id: this.user.organization._id,
      organizationName: this.user.organization.name,
    });
    this.$gtag.event("sectors", {
      domain_name: "",
      theme_name: this.themeData.name,
      sector_name: this.sector.name,
      country_name: allCountry,
      page_name: this.$route.name,
      userId: this.user._id,
      team_id: this.user.organization._id,
      organizationName: this.user.organization.name,
    });
    this.$gtag.event("countries", {
      domain_name: "",
      theme_name: this.themeData.name,
      sector_name: this.sector.name,
      country_name: allCountry,
      page_name: this.$route.name,
      userId: this.user._id,
      team_id: this.user.organization._id,
      organizationName: this.user.organization.name,
    });
    this.endofcreated = true;
    setTimeout(async () => {
      await this.getBibliographyData();
      let data = {
        data: this.json_data,
        country: allCountry,
        sector: this.sector.name,
      };
      await this.$store.commit("setBibliographyData", data);
    }, 2000);
  },
  mounted() {},
  beforeUpdate() {
    setTimeout(() => {
      this.redirectFromAnotherpage();
    }, 3000);
  },
  beforeDestroy() {
    this.$store.commit("setBibliographyData", []);
  },
};

import $ from "jquery";
$(window)
  .scroll(function () {
    var scrollDistance = $(window).scrollTop();
    // Show/hide menu on scroll
    //if (scrollDistance >= 850) {
    //		$('nav').fadeIn("fast");
    //} else {
    //		$('nav').fadeOut("fast");
    //}

    // Assign active class to nav links while scolling
    $(".maincontent").each(function (i) {
      if ($(this).position().top <= scrollDistance) {
        var lis = document
          .getElementsByClassName("navsticky")[0]
          .getElementsByTagName("ol")[0]
          .getElementsByTagName("li");
        for (
          let i = 0;
          i <
          document.getElementsByTagName("ol")[0].getElementsByTagName("li")
            .length;
          i++
        ) {
          lis[i].classList.remove("active");
        }
        $(".navsticky ol li").eq(i).addClass("active");
      }
    });
  })
  .scroll();
</script>
<style>
.global_analysis_side_bar_list li {
  margin-left: 1rem !important;
}
.DownloadBtn p {
  display: flex;
  align-items: center;
  border: 1px solid #1b1b1b;
  border-radius: 20px;
  color: #1b1b1b;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 11px 17px;
  transition: 0.3s ease;
}

.DownloadBtn p img {
  margin-right: 25px;
  width: 15px;
  height: 15px;
}

.DownloadBtn p:hover {
  background: rgb(32, 23, 71);
  color: #fff;
  border-color: rgb(32, 23, 71);
}

.DownloadBtn a {
  display: flex;
  align-items: center;
  border: 1px solid #1b1b1b;
  border-radius: 20px;
  color: #1b1b1b;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 11px 10px;
  transition: 0.3s ease;
}

.DownloadBtn a img {
  margin-right: 8px;
  width: 15px;
  height: 15px;
}

.DownloadBtn a:hover {
  background: rgb(32, 23, 71);
  color: #fff;
  border-color: rgb(32, 23, 71);
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
    .slide-fade-leave-to

        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

@media print {
  .radarchart {
    max-height: 400px !important;
  }
}

.histData {
  max-width: 400px !important;
}

#buttonContainer {
  text-align: center;
}

#sideNav {
  position: fixed;
  margin-top: 300px;
  left: -65px;
  border-radius: 0 5px 5px 0;
}

#sideNav button {
  background-color: #201747;
  padding: 8px;
  width: 120px;
  height: 38px;
  font-size: 10px;
  color: #201747;
}

#sideNav button:hover {
  background-color: #e2e8f0;
  color: #201747;
}

.navigateButton {
  color: red;
  border: 2px solid red;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.hoverTrigger .hoverMethodology {
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 50;
  width: 400px;
}

.hoverTrigger {
  position: relative;
  display: inline-block;
}

.triggerElement {
  border-bottom: 1px dotted black;
}

.hoverTrigger:hover .hoverMethodology {
  visibility: visible;
  width: 550px;
}
.margintop1 {
  margin-top: 1em;
}
.margintop2 {
  margin-top: 2em;
}
.margintop3 {
  margin-top: 3em;
}
.marginbottom3 {
  margin-bottom: 3em;
}
.recentlyUpdatedLabel {
  color: darkorange;
  padding-top: 30px;
  display: block;
}
</style>
