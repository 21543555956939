import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";

export const getSubscriptions = () => {
  return new Promise(function (resolve) {
    axios
      .get(env.apiUrl + "/subscriptions/get-subscription-info", {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      })
      .then(function (res) {
        resolve(res.data.data);
      });
  });
};
