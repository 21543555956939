<script>
import { Pie } from "vue-chartjs";

export default {
  name: "pieChart",
  extends: Pie,
  props: ["totalValue", "sectorLabels", "mainTitle", "isTwoxCriteriaChart"],
  data: function () {
    return {
      max: 0,
      beginAtZero: true,
      labels: [],
      datasets: [],
    };
  },
  mounted() {
    this.renderMyChart();
  },
  methods: {
    renderMyChart() {
      this.labels = this.sectorLabels;
      this.datasets = [
        {
          label: ["Sectors"],
          data: this.totalValue,
          backgroundColor: [
            "#2BD4C4",
            "#FE5000",
            "#FAE053",
            "#898989",
            "#0077C8",
            "#B2281E",
            "#7B5FCB",
            "#779ecb",
            "#cfaec6",
            "#ff8850",
            "#201747",
          ],
          hoverOffset: 4,
        },
      ];
      let total = 0;
      this.totalValue.filter((data) => {
        total = total + data;
      });
      this.max = Math.floor(total);
      const _self = this;

      this.renderChart(
        {
          labels: this.labels,
          datasets: this.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          fontFamily: "basier",
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontSize: 13,
              fontFamily: "basier",
            },
          },
          tooltips: {
            titleFontFamily: "basier",
            bodyFontFamily: "basier",
            callbacks: {
              title: function (tooltipItems) {
                return _self.labels[tooltipItems[0].index];
              },
              label: function (tooltipItems) {
                return _self.datasets[0].data[tooltipItems.index] + "%";
              },
            },
          },
        }
      );
    },
  },
  watch: {
    totalValue: function () {
      this.renderMyChart();
    },
  },
};
</script>