<script>
/*
  Input should look like this:
  data = [
    {name: 'running', value1: }
  ]
  More information: https://www.chartjs.org/docs/latest/charts/radar.html
*/

import axios from "axios";
import Cookies from "@/helpers/cookies";
const _ = require("underscore");
import { Radar } from "vue-chartjs";
import env from "@/../env";
// import {initHoverBox} from './ChartFunction'

export default {
  extends: Radar,
  data: function () {
    return {
      latestData: [],
      targetData: [],
      targetDataYear: [],
      oldestData: [],
      country: {},
      axes: [" ", " ", " ", " "],
      fullAxes: [],
      axeColors: [],
      one: [],
      domains: [],
      hoverIndex: false,
      taabs: [
        "Reduce GBV in Product & Company",
        "Address Systemic Risk",
        "Assess Project Risks in Due Diligence",
        "Make Proactive Investments in GBV Reduction",
      ],
    };
  },
  methods: {
    formatLabel(str, maxwidth) {
      var sections = [];
      var words = str.split(" ");
      var temp = "";
      words.forEach(function (item, index) {
        if (temp.length > 0) {
          var concat = temp + " " + item;

          if (concat.length > maxwidth) {
            sections.push(temp);
            temp = "";
          } else {
            if (index == words.length - 1) {
              sections.push(concat);
              return;
            } else {
              temp = concat;
              return;
            }
          }
        }

        if (index == words.length - 1) {
          sections.push(item);
          return;
        }

        if (item.length < maxwidth) {
          temp = item;
        } else {
          sections.push(item);
        }
      });

      return sections;
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    getCountry: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl + "/countries/" + _self.iso + "/sectors/ENRG_GOVPOL",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    calc: function (input) {
      if (typeof input !== undefined && input !== "Missing" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    abrev: function (input) {
      let abrevString = "";
      for (let i = 0; i < input.toString().split(" ").length; i++) {
        if (input.toString().split(" ")[i] !== "&") {
          abrevString += input.toString().split(" ")[i].substr(0, 1);
        }
      }
      return abrevString;
    },
  },
  props: ["iso", "paddingL", "paddingR", "paddingT", "paddingB", "details"],
  async created() {
    this.latestData.push(
      Number(this.details[0].reduceCompanyGbvStrategyCurrentScore),
      Number(this.details[0].systemicRiskStrategyCurrentScore),
      Number(this.details[0].esgRiskStrategyCurrentScore),
      Number(this.details[0].proactiveInvestmentStrategyCurrentScore)
    );
    if (this.details[1]) {
      this.oldestData.push(
        Number(this.details[1].reduceCompanyGbvStrategyCurrentScore),
        Number(this.details[1].systemicRiskStrategyCurrentScore),
        Number(this.details[1].esgRiskStrategyCurrentScore),
        Number(this.details[1].proactiveInvestmentStrategyCurrentScore)
      );
    }
    this.targetData.push(
      Number(this.details[0].reduceCompanyGbvStrategyFutureScore),
      Number(this.details[0].systemicRiskStrategyFutureScore),
      Number(this.details[0].esgRiskStrategyFutureScore),
      Number(this.details[0].proactiveInvestmentStrategyFutureScore)
    );
    this.country = await this.getCountry();
    this.axeColors = ["#9D9D9C", "#9D9D9C", "#9D9D9C", "#9D9D9C"];

    let currentYear = new Date(this.details[0].dateOfSurvey).toLocaleString("en-US", { month: "short" }) + " " + new Date(this.details[0].dateOfSurvey).getFullYear()
    let oldestYear = this.details[1]
      ? new Date(this.details[1].dateOfSurvey).toLocaleString("en-US", { month: "short" }) + " " + new Date(this.details[1].dateOfSurvey).getFullYear()
      : "";
    let chartLabel1 = "GBVH Assessment " + currentYear;
    let chartLabel2 = "GBVH Assessment " + oldestYear;
    const _self = this;

    this.renderChart(
      {
        labels: this.axes,
        datasets: [
          {
            data: this.details[0] ? this.latestData : [],
            label: chartLabel1,
            pointBackgroundColor: "#0077C8",
            fill: false,
            borderColor: "#0077C8",
            backgroundColor: this.opacity("#0077C8"),
            pointHitRadius: 8,
          },
          {
            data: this.details[1] ? this.oldestData : [],
            label: chartLabel2,
            borderColor: "#9D9D9C",
            pointBackgroundColor: "#9D9D9C",
            fill: false,
            backgroundColor: this.opacity("#9D9D9C"),
            pointHitRadius: 8,
          },
          {
            data: this.targetData ? this.targetData : [],
            label: "Future Targets",
            fill: false,
            pointBackgroundColor: "#2BD4C4",
            pointHitRadius: 8,
            backgroundColor: "#2BD4C4",
            borderColor: "#2BD4C4",
          },
          {
            data: [100, 100, 100, 100],
            fill: false,
            label: "hide",
            pointBackgroundColor: this.axeColors,
            pointBorderColor: this.axeColors,
            pointHitRadius: 8,
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        spanGaps: false,
        layout: {
          padding: {
            left: this.paddingL,
            right: this.paddingR,
            top: this.paddingT,
            bottom: this.paddingB,
          },
        },

        legend: {
          position: "right",
          align: "end",
          labels: {
            display: false,
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("hide");
            },
            padding: 10,
            fontSize: 12,
            fontFamily: "basier",
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
            }
          },

          // Function to only hide Perfect geneder equality
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }

            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items

            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);

            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;

            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });

              if (!someShown) return;
            }

            meta.hidden = hidden;

            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },

        scale: {
          angleLines: {
            display: false,
            color: "rgb(243, 243, 243)",
            lineWidth: 2,
          },
          gridLines: {
            display: false,
            color: "rgb(243, 243, 243)",
            lineWidth: 2,
          },
          pointLabels: {
            fontColor: this.axeColors,
            fontFamily: "basier",
            fontSize: 20,
            lineHeight: 2,
            onClick: function () { },
            callback: function (pointLabel, index) {
              if (
                _self.$route.params.sector == "WASH_UTILIT" ||
                _self.$route.params.sector == "INFR_TRANSP" ||
                _self.$route.params.sector == "ENRG_SMTECH" ||
                _self.$route.params.sector == "FNCE_GENERL" ||
                _self.$route.params.sector == "INFR_DETENT" ||
                _self.$route.params.sector == "INFR_EDUCAT" ||
                _self.$route.params.sector == "INFR_HEALTH" ||
                _self.$route.params.sector == "INFR_MARKET"
              ) {
                if (index == 20 || index == 21) {
                  var words = pointLabel.split(" ");
                  var testLine = [];
                  testLine.push(" ");
                  for (var n = 0; n < words.length; n++) {
                    testLine.push(words[n]);
                  }
                  testLine.push(" ");
                  return testLine;
                } else {
                  return pointLabel;
                }
              } else if (
                _self.$route.params.sector == "CVID_GENERL" ||
                _self.$route.params.sector == "FNCE_MCRINF"
              ) {
                if (
                  index == 21 ||
                  index == 42 ||
                  index == 1 ||
                  index == 0 ||
                  index == 22
                ) {
                  var words2 = pointLabel.split(" ");
                  var testLine2 = [];
                  testLine2.push(" ");
                  for (var n2 = 0; n2 < words2.length; n2++) {
                    testLine2.push(words2[n2]);
                  }
                  testLine2.push(" ");
                  return testLine2;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_MATNEW") {
                if (
                  index == 1 ||
                  index == 21 ||
                  index == 22 ||
                  index == 43 ||
                  index == 23
                ) {
                  var words3 = pointLabel.split(" ");
                  var testLine3 = [];
                  testLine3.push(" ");
                  for (var n3 = 0; n3 < words3.length; n3++) {
                    testLine3.push(words3[n3]);
                  }
                  testLine3.push(" ");
                  return testLine3;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "INFR_GENERL") {
                if (
                  index == 0 ||
                  index == 1 ||
                  index == 20 ||
                  index == 21 ||
                  index == 40
                ) {
                  var words4 = pointLabel.split(" ");
                  var testLine4 = [];
                  testLine4.push(" ");
                  for (var n4 = 0; n4 < words4.length; n4++) {
                    testLine4.push(words4[n4]);
                  }
                  testLine4.push(" ");
                  return testLine4;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "ENRG_GOVPOL") {
                if (index == 1 || index == 21) {
                  var words5 = pointLabel.split(" ");
                  var testLine5 = [];
                  testLine5.push(" ");
                  for (var n5 = 0; n5 < words5.length; n5++) {
                    testLine5.push(words5[n5]);
                  }
                  return testLine5;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_HIVHLT") {
                if (index == 19) {
                  var words6 = pointLabel.split(" ");
                  var testLine6 = [];
                  testLine6.push("");
                  for (var n6 = 0; n6 < words6.length; n6++) {
                    testLine6.push(words6[n6]);
                  }
                  return testLine6;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_SEXREP") {
                if (index == 20 || index == 21 || index == 0 || index == 1) {
                  var words7 = pointLabel.split(" ");
                  var testLine7 = [];
                  testLine7.push(" ");
                  for (var n7 = 0; n7 < words7.length; n7++) {
                    testLine7.push(words7[n7]);
                  }
                  return testLine7;
                } else {
                  return pointLabel;
                }
              } else if (
                _self.$route.params.sector == "AGRI_COCOA" ||
                _self.$route.params.sector == "AGRI_COFFEE"
              ) {
                if (
                  index == 21 ||
                  index == 22 ||
                  index == 23 ||
                  index == 1 ||
                  index == 0
                ) {
                  var words8 = pointLabel.split(" ");
                  var testLine8 = [];

                  for (var n8 = 0; n8 < words8.length; n8++) {
                    testLine8.push(words8[n8]);
                  }
                  //                            testLine8.push(" ");
                  return testLine8;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_MAIZE") {
                if (
                  index == 0 ||
                  index == 1 ||
                  index == 21 ||
                  index == 42 ||
                  index == 43
                ) {
                  var words9 = pointLabel.split(" ");
                  var testLine9 = [];
                  testLine9.push(" ");
                  for (var n9 = 0; n9 < words9.length; n9++) {
                    testLine9.push(words9[n9]);
                  }

                  return testLine9;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_LEGUME") {
                if (index == 22 || index == 23 || index == 1 || index == 0) {
                  var words10 = pointLabel.split(" ");
                  var testLine10 = [];
                  testLine10.push(" ");
                  for (var n10 = 0; n10 < words10.length; n10++) {
                    testLine10.push(words10[n10]);
                  }
                  testLine10.push(" ");
                  return testLine10;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_GENERL") {
                if (index == 21) {
                  var words11 = pointLabel.split(" ");
                  var testLine11 = [];
                  testLine11.push(" ");
                  for (var n11 = 0; n11 < words11.length; n11++) {
                    testLine11.push(words11[n11]);
                  }

                  return testLine11;
                } else {
                  return pointLabel;
                }
              } else {
                var sec = ["HLTH_ADOLES", "HLTH_MATNEW"];
                if (sec.includes(_self.$route.params.sector)) {
                  if (index == 22 || index == 41 || index == 1) {
                    var words1 = pointLabel.split(" ");

                    var testLine1 = [];
                    testLine1.push(" ");
                    for (var n1 = 0; n1 < words1.length; n1++) {
                      testLine1.push(words1[n1]);
                    }
                    return testLine1;
                  } else {
                    return pointLabel;
                  }
                } else {
                  return pointLabel;
                }
              }
              // Return string here. String must be defined
            },
          },
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
            stepSize: 20,
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          onHover: function (e) {
            // return false;
            var point = this.getElementAtEvent(e);
            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
              e.target.style.cursor = "pointer";
              // Prevent from flashing.
              if (
                point[0]._index !==
                _self.hoverIndex + "_" + point[0]._datasetIndex
              ) {
                _self.hoverIndex =
                  point[0]._index + "_" + point[0]._datasetIndex;
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX + "px";
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-name").innerText =
                  _self.taabs[point[0]._index];
                document.getElementById("intersection-domain").style.display =
                  "none";

                // Current data
                document.getElementById(
                  "div-intersection-region"
                ).style.display = "block";
                document.getElementById("intersection-region").style =
                  "color: " + "#0077C8";
                document.getElementById("intersection-region").innerText =
                  "Current: " +
                  Math.round(_self.latestData[point[0]._index]) +
                  "%" +
                  "\n" +
                  "Current Year: " +
                  _self.details[0].dateOfSurvey.split("-")[0];

                // Oldest data
                document.getElementById(
                  "div-intersection-country"
                ).style.display = "block";
                document.getElementById("intersection-country").style =
                  "color: " + "#9D9D9C ";
                document.getElementById("intersection-country").innerText =
                  _self.details[1]
                    ? "First Historical: " +
                    Math.round(_self.oldestData[point[0]._index]) +
                    "%" +
                    "\n" +
                    "First Historical Year: " +
                    _self.details[1].dateOfSurvey.split("-")[0]
                    : "";

                //Target Data
                document.getElementById("intersection-income").style =
                  "color: " + "#2BD4C4";
                document.getElementById("lmic-avg").style.display = "none";
                document.getElementById("intersection-income").innerText =
                  "Target : " +
                  (_self._data.targetData[point[0]._index]
                    ? Math.round(_self._data.targetData[point[0]._index]) + "%"
                    : _self._data.targetData[point[0]._index] == 0
                      ? 0 + "%"
                      : "NA");

                document.getElementById(
                  "intersection-lmic-value"
                ).style.display = "none";

                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-region-value"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-income"
                ).style.display = "block";
                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
              e.target.style.cursor = "default";
            }
          },
        },
      }
    );

    const _self2 = this;
    window.addEventListener("beforeprint", function () {
      const Chart = _self2.$data._chart;
      Chart.resize();
    });

    setTimeout(function () {
      _self2.$data._chart.update();
    }, 1000);
  },
};
</script>

<style scoped>
.chartjs-render-monitor {
  margin-left: 78px;
  height: 530px !important;
}
</style>