<template>
  <div>
    <div class="flex flex-col my-5 items-center" v-for="dataset in datasets" v-bind:key="dataset.label">
      <p class="py-5">{{ dataset.label }}</p>
      <div :class="datasets[0].data.length < 5 ? 'flex flex-row' : ''">
        <div
          v-for="(datapoint, index) in dataset.data"
          v-bind:key="index"
          class="flex flex-col items-center justify-center"
          :class="datasets[0].data.length < 5 ? '' : 'my-4'"
        >
          <div
            class="
              tooltip
              rounded-full
              flex
              items-center
              justify-center
              mx-3
              text-sm
            "
            :style="
              'height:' +
              datapoint.px / 2 +
              'px; width: ' +
              datapoint.px / 2 +
              'px; background-color: ' +
              (datapoint.datapoint !== 'Missing'
                ? dataset.color || datapoint.color
                : 'white')
            "
          >
            <div v-if="datapoint.datapoint !== 'Unknown'" id="fittingBubble">
              <center v-if="indicator.dataType === 'MONEY'">
                <p class="tooltiptext">
                  {{ labels[index] }}: <br />
                  {{ toMoney(datapoint.datapoint) }}
                </p>
              </center>
              <center v-else-if="indicator.dataType === 'PEOPLE'">
                <p class="tooltiptext">
                  {{ labels[index] }}: <br />
                  {{ toPersons(datapoint.datapoint) }}
                </p>
              </center>
              <center v-else-if="indicator.dataType === 'MINUTES'">
                <p class="tooltiptext">
                  {{ labels[index] }}: <br />
                  {{ toPersons(datapoint.datapoint) }} minutes
                </p>
              </center>
              <center v-else-if="indicator.dataType === 'NUMBER'">
                <p class="tooltiptext">
                  {{ labels[index] }}: <br />
                  {{ toNumber(datapoint.datapoint) }}
                </p>
              </center>

              <center v-else>
                <p class="tooltiptext">
                  {{ labels[index] }}: {{ (datapoint.datapoint == 'Missing' || datapoint.datapoint == 'Unknown') ?  datapoint.datapoint : datapoint.datapoint.toFixed(2) }}
                </p>
              </center>
            </div>
          </div>

          <div v-if="datapoint.datapoint !== 'Unknown'" id="fittingBubble2" class="mt-1">
            <center v-if="indicator.dataType === 'MONEY'">
              <p style="font-size: 90%">
                {{ labels[index] }}: <br />
                {{ toMoney(datapoint.datapoint) }}
              </p>
            </center>
            <center v-else-if="indicator.dataType === 'PEOPLE'">
              <p style="font-size: 90%">
                {{ labels[index] }}: <br />
                {{ toPersons(datapoint.datapoint) }}
              </p>
            </center>
            <center v-else-if="indicator.dataType === 'MINUTES'">
              <p style="font-size: 90%">
                {{ labels[index] }}: <br />
                {{ toPersons(datapoint.datapoint) }} minutes
              </p>
            </center>
            <center v-else-if="indicator.dataType === 'NUMBER'">
              <p style="font-size: 90%">
                {{ labels[index] }}: <br />
                {{ toNumber(datapoint.datapoint) }}
              </p>
            </center>

            <center v-else>
              <p style="font-size: 90%">
                {{ labels[index] }}: {{ (datapoint.datapoint == 'Missing' || datapoint.datapoint == 'Unknown') ? datapoint.datapoint : datapoint.datapoint.toFixed(2) }}
              </p>
            </center>
          </div>

          <div v-if="datapoint.datapoint == 'Unknown'">
            <center>
              <p
                class="tooltiptext"
                style="width: 50% !important;"
              >
                {{ labels[index] }}: <br />
                <i>Data is missing for this country.</i>
              </p>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/../env.js";

export default {
  name: "bubbleChart",
  props: [
    "chartData",
    "chartLabels",
    "countryD",
    "indicator",
    "chartLabelsForCharts",
  ],
  data: function () {
    return {
      labels: [],
      data: [],
      datasets: [],
      maxPixels: "",
      previousDataPoint: "",
      colors: [env.brandingColors.navy, env.chartColors.gammaColor, env.chartColors.alfaColor, env.brandingColors.gray, env.chartColors.jotaColor, env.chartColors.muColor, env.chartColors.piColor, env.chartColors.rhoColor],
    };
  },
  methods: {
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    changeText: function (text, index) {
      if (index < this.countryD.length) {
        this.country = [];
        this.country = this.countryD[index];
        this.country.values["country"] = this.country.humanName;
        this.country.values["region"] =
          this.country.region == "Eastern Europe & Central Asia"
            ? "Europe & Central Asia"
            : this.country.region + " Region";
        this.country.values["income"] = this.toTitleCase(
          this.country.income + " Group"
        );

        const keys = Object.keys(this.country.values);
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k];
          let value = this.country.values[key];

          text = text
            .toString()
            .split("{{" + key + "}}")
            .join(value);
          // let's try to convert to a number
          if (!isNaN(Number(text))) {
            text = Number(text);
          }
        }
        return text;
      } else {
          this.country = [];
          this.country = this.countryD[0];
          this.country.values["country"] = this.country.humanName;
          this.country.values["region"] =
            this.country.region == "Eastern Europe & Central Asia"
              ? "Europe & Central Asia"
              : this.country.region + " Region";
          this.country.values["income"] = this.toTitleCase(
            this.country.income + " Group"
          );

          const keys = Object.keys(this.country.values);
          for (let k = 0; k < keys.length; k++) {
            const key = keys[k];
            let value = this.country.values[key];
            /*
            if(key.indexOf('WRK_WAGEGP_M_158') > -1){
            }
            */
            text = text
              .toString()
              .split("{{" + key + "}}")
              .join(value);
            // let's try to convert to a number
            if (!isNaN(Number(text))) {
              text = Number(text);
            }
          }

          return text;
      }
    },
    toMoney: function (input) {
      if (isNaN(input)) {
        return input;

        // 1000
        // 1.000.000.000 => 9 nullen right?
      } else if (input >= 1000000000) {
        return (
          "$" +
          Number(input.toFixed("2")).toLocaleString("en-US").split(",")[0] +
          "." +
          Number(input.toFixed("2"))
            .toLocaleString("en-US")
            .split(",")[1]
            .substr(0, 2) +
          " BN"
        );
      } else {
        if (this.indicator.name == "MSME Finance Gap") {
          // A hacky solution as the Data team calculate in billions instead of full numbers.
          return (
            "$" + Number(input.toFixed("2")).toLocaleString("en-US") + " BN"
          );
        } else {
          return "$" + Number(input.toFixed("2")).toLocaleString("en-US");
        }
      }
    },
    toPersons: function (input) {
      if (isNaN(input)) {
        return input.toString().split(".")[0];
      } else {
        return Math.round(input).toLocaleString();
      }
    },
    toNumber: function (input) {
      if (isNaN(input)) {
        return input.toString();
      } else {
        return Number(Math.round(input * 10) / 10 || 0).toLocaleString();
      }
    },
    fillIn: function (array) {
      if (array && array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeText(array[i], i);
        }

        return array;
      } else {
        return [];
      }
    },
    fillInForLabels: function (array) {
      if (array && array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeText(array[i], i);
        }
        return array;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.chartLabels.push('Global')
    // const WTFLabels = JSON.parse(JSON.stringify(this.chartLabels));
    this.chartData.forEach(item => {
      item.data.push(item.data[0].toString().split("}}")[0] + '_global}}')
    })
    this.labels = this.fillInForLabels(this.chartLabels);
    this.data = JSON.parse(JSON.stringify(this.chartData)) || [];
    
    const colorShemes = {
      country: env.geoColors.countrybar,
      lmic: env.geoColors.lmicbar,
      income: env.geoColors.incomebar,
      region: env.geoColors.regionbar,
      female: env.genderColors.female,
      male: env.genderColors.male,
    };

    //this.maxPixels = 300
    this.maxPixels = window.innerWidth / 10;

    let maximum = 0;
    for (var m = 0; m < this.data.length; m++) {
      for (var x = 0; x < this.data[m].data.length; x++) {
        const value = this.changeText(this.data[m].data[x], x);
        if (value > maximum) {
          maximum = value;
        }
      }
    }

    const max = maximum;
    for (var i = 0; i < this.data.length; i++) {
      const data = this.fillIn(this.data[i].data, i);
      //const max = Math.max(...this.data[i].data); // this is 400px
      for (var d = 0; d < this.data[i].data.length; d++) {
        var col = this.colors[d];
        if (this.data[i].data[d] == "Missing") {
          this.data[i].data[d] = { datapoint: "Unknown", px: NaN };
        } else {
          const perc = (100 / max) * this.data[i].data[d];
          this.data[i].data[d] = {
            datapoint: this.data[i].data[d],
            px: ((this.maxPixels / 100 * perc) < 20) ? 20 : (this.maxPixels / 100 * perc),
            color: col,
          };
        }
      }
      if (this.data[i].label.includes(" {{country}}")) {
        this.data[i].label = this.data[i].label.split("in {{")[0];
      }
      this.datasets.push({
        label: this.data[i].label,
        data: data,
        color:
          colorShemes[
          this.data[i].label
            .toLowerCase()
            .split("{")
            .join("")
            .split("}")
            .join("")
          ] || false,
      });
    }
  },
};
</script>

<style scoped>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltiptext {
  width: max-content !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content !important;
  background-color: #201747;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

#fittingBubble {
  color: white;
}
</style>
