<template>
  <div class="flex flex-col justify-center items-center h-[80vh]">
    <div class="bg-white p-8 rounded shadow-md w-full max-w-sm text-center">
      <button
        @click="acceptInteraction"
        class="bg-[#201747] hover:bg-[#201747] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Accept Interaction Request
      </button>
    </div>
    <div class="h-10">
      <p v-if="error" class="mt-4 text-red-500">{{ error }}</p>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";

export default {
  name: "AcceptMatchMakingInteractions",
  data() {
    return {
      error: null,
    };
  },
  methods: {
    async acceptInteraction() {
      this.error = null;
      const token = this.$route.query.token;
      try {
        const response = await axios.get(
          env.apiUrl + "/match-making-interactions/accept",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
              verification: token,
            },
          }
        );
        if (response.status === 200) {
          this.$router.push("/2X-Ecosystem/2X-Matchmaking");
        } else {
          this.error = "Something went wrong. Please try again later.";
        }
      } catch (error) {
        this.error = "Something went wrong. Please try again later.";
      }
    },
  },
};
</script>

<style scoped>
.bg-gray-100 {
  background-color: #f7fafc;
}
</style>
