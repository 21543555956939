<template>
    <twoXDataTable :data="data" title="2X Investor Profile Disclosures"
     />
</template>

<script>
import twoXDataTable from './2xDataTable.vue'
export default {
  name: "twoxInvestorProfileDisclosures",
  components:{
    twoXDataTable
  },
  props: {
    data: {
      type: Array,
      required: true
    },
  },
}
</script>
<style scoped>
.sticky {
  position: sticky;
  right: 0;
}
</style>
