<template>
  <div class="static">
    <!-- <img
      src="@/assets/images/background-image.jpg"
      alt="background-image"
      class="w-full z-0 -mt-20"
    /> -->
    <div class="w-full container mx-auto mt-20 px-4 lg:px-0">
      <div class="flex flex-row mt-32 lg:mt-0">
        <div class="flex flex-col w-4/12 lg:w-4/12">
          <h1
            class="text-5xl print:text-4xl tracking-tighter font-semibold lowercase capitalize"
            style="color: #201747"
          >
            Hi {{ this.$store.state.user.firstname }},
          </h1>
        </div>
        <div class="flex flex-col w-3/12 lg:w-3/12" v-if="false">
          <router-link
            to="/global-analysis"
            class="flex flex-row items-center pl-3 py-3 mt-1 mx-12 leading-5 rounded-lg hover:bg-gray-200 transition duration-300 ease-in-out cursor-pointer"
          >
            <img class="mr-3 w-12" src="@/assets/images/globe.svg" />
            <p class="text-gray-600 text-sm font-medium">
              Check Out <br />Global Analysis
            </p>
          </router-link>
        </div>
        <div class="flex flex-col w-3/12 lg:w-3/12" v-if="false">
          <div
            class="flex flex-row items-center pl-3 py-3 mt-1 mx-12 leading-5 rounded-lg hover:bg-gray-200 transition duration-300 ease-in-out cursor-pointer"
            @click="redirectEQ()"
          >
            <img class="mr-3 w-16" :src="this.equiloland.flagUrl" />
            <p class="text-gray-600 text-sm font-medium">
              {{ this.equiloland.humanName }}
            </p>
          </div>
        </div>
        <div class="flex flex-col ml-auto mt-auto w-3/12 lg:w-3/12">
          <div
            class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4"
          >
            <svg
              class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Artboard 329</title>
              <circle
                cx="11.059"
                cy="11.059"
                r="7.059"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                x1="20"
                y1="20"
                x2="16.05"
                y2="16.05"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <input
              type="text"
              class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
              v-model="q"
              placeholder="Search a country"
            />
          </div>
        </div>
      </div>
      <div v-if="count === -1">
        <router-link
          to="/settings/configure-subscription"
          style="
            background-color: #fe5000;
            color: white !important;
            width: 300px;
          "
          class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center"
        >
          <span>You can choose your countries here</span>
          <svg
            class="w-5 h-5"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g
              stroke-linecap="round"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linejoin="round"
            >
              <path d="M16.95,7.05l-9.9,9.9"></path>
              <path d="M10,7l6.95,0.049l0.05,6.951"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
        </router-link>
      </div>
    </div>
    <transition name="fadeIn" mode="out-in">
      <div
        id="notLoaded"
        key="loading"
        v-if="countries.length === 0 && q.length === 0 && count !== -1"
      >
        <page-loader></page-loader>
      </div>
      <div
        id="noCountries"
        key="countreis"
        v-if="countries.length <= 0 && q.length !== 0"
      >
        <div
          class="flex flex-col items-center justify-center w-full print:h-screen mt-32"
        >
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3">
              <div class="flex flex-col item-center justify-center">
                <h1 class="text-2xl text-gray-500 font-medium">
                  No Countries Found
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="loaded" key="loaded" v-if="countries.length > 0">
        <div
          class="w-full container mx-auto px-4 lg:px-0"
          :class="$route.params.gbv ? 'mt-20' : 'mt-2'"
        >
          <div
            class="sectors_button flex mt-10 mb-12"
            v-if="!$route.params.gbv && !isBasic"
          >
            <div
              class="leading-7 font-medium gap-10 text-justify leading-7 font-medium text-gray-600 p-2"
              style="width: 60%"
            >
              <span>
                Select 1 country to view a single country analysis with regional
                and global comparisons, or select multiple countries (up to 5)
                to view a multi-country analysis.
              </span>
            </div>
            <div style="text-align: right; width: 32%">
              <button
                @click="goToSectors"
                style="background-color: #201747"
                :style="
                  selectedCountries && selectedCountries.length
                    ? ''
                    : 'background-color:#898989;pointer-events: none;'
                "
              >
                Select a Sector
              </button>
            </div>
          </div>
          <div
            class="flex mb-20"
            :class="$route.params.gbv ? 'mt-20' : 'mt-1'"
            v-for="(subregion, index) in subregions"
            v-bind:key="subregion + index"
          >
            <div class="w-4/12">
              <p
                class="font-medium pt-6 text-xl"
                :style="'color: ' + env.brandingColors.navy"
              >
                {{
                  subregion == "Eastern Europe & Central Asia"
                    ? "Europe & Central Asia"
                    : subregion
                }}
              </p>
            </div>
            <!--<div style="padding-right: 100px;">
                        <button style="height: 70px;" class="px-3 py-2 text-xs bg-blue-100 hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-indigo-500 hover:text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                            <span>{{subregion}}</span>
                        </button>
                        </div>-->
            <div class="w-8/12">
              <transition-group
                name="slide-in"
                tag="div"
                class="grid grid-cols-2 lg:grid-cols-3 gap-2"
                appear
                :style="{ '--total': 5 }"
              >
                <template
                  class="col-span-1"
                  v-for="(country, index2) in countries"
                >
                  <!-- country is active -->
                  <div
                    v-if="
                      subregion === country.region &&
                      country.active &&
                      !country.disableCountryOnPowerScore
                    "
                    :style="{ '--i': (index += 1) }"
                    class="flex flex-row items-center px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300 ease-in-out cursor-pointer"
                    :class="
                      country.isCountrySelected ? 'country_selected_style' : ''
                    "
                    v-bind:key="country + index2"
                    @click="
                      isBasic ? saveCountry(country) : openCountry(country)
                    "
                  >
                    <img class="mr-3 w-16" :src="country.flagUrl" />
                    <p class="text-gray-600 text-sm font-medium">
                      {{ country.humanName }}
                    </p>
                  </div>

                  <div
                    v-else-if="subregion === country.region"
                    :style="{ '--i': (index += 1) }"
                    class="flex flex-row items-center px-5 py-3 rounded-lg transition duration-300 ease-in-out cursor-not-allowed relative"
                    v-bind:key="country + '' + index2"
                    @mouseover="mouseOver(index2)"
                    @mouseleave="mouseOver(false)"
                  >
                    <img class="mr-3 w-16" :src="country.flagUrl" />
                    <p class="text-gray-400" label="test">
                      {{ country.humanName }} 🔐
                    </p>
                    <transition name="slide-fade">
                      <div
                        v-if="active === index2"
                        class="absolute top-0 mt-20 bg-gray-100 p-4 rounded-lg shadow-xl text-xs z-10 text-gray-800"
                      >
                        Not enough data is available for this country right now,
                        but we are working on finding alternative data sources.
                      </div>
                    </transition>
                  </div>
                </template>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Modal Start -->
    <div id="consentModal" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <div style="margin: 8px 0">
          <h2 style="font-weight: 600">
            Proceed to select a sector by clicking "Sector" or continue
            selecting more countries, up to 5.
          </h2>
        </div>
        <div class="save-opportunity-button">
          <button
            class="text-white text-sm font-medium"
            style="cursor: pointer"
            @click="closeModal()"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
    <!-- End Modal -->
    <complex-modal :isActive="showModal" :setModal="setModal" v-if="showModal">
      <div class="flex flex-col p-[1.5rem] text-black h-full justify-between">
        <div class="font-bold text-[1.5rem]">Subscription</div>
        <div class="text-[1rem] leading-[24px]">
          Your subscription provides access to one country for the duration of
          your subscription. Once you make a country selection, you may not
          change the country for the remainder of your subscription unless you
          upgrade to a Pro or Premium subscription.
        </div>
        <div class="flex w-full">
          <button
            class="basis-[16rem] w-[16.125rem] h-[2.5rem] bg-white text-[#191b45] border border-[#191b45] border-solid font-bold text-[1rem] rounded-[8px] active:translate-y-[1px] mr-[0.5rem]"
            @click="setModal()"
          >
            Confirm country selection
          </button>
          <button
            class="basis-[16rem] w-[16.125rem] h-[2.5rem] bg-[#191B45] text-[#FFFFFF] font-bold text-[1rem] rounded-[8px] active:translate-y-[1px] ml-[0.5rem]"
            @click="redirectUpgrade()"
          >
            Upgrade to access more
          </button>
        </div>
      </div>
    </complex-modal>
  </div>
</template>

<script>
const axios = require("axios");
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import { useUserStore } from "@/stores/user";
import complexModal from "@/components/complex-modal.vue";
import { SUBSCRIPTION_TYPE } from "@/security/subscriptionTypes";
import Users from "@/helpers/users";

export default {
  name: "Countries-choose",
  components: {
    "complex-modal": complexModal,
    pageLoader,
  },
  data: function () {
    return {
      q: "",
      data: [],
      count: 0,
      active: false,
      equiloland: "",
      env,
      user: {},
      selectedCountries: [],
      showModal: false,
      isBasic: false,
      selectable: [],
      //subregions: []
    };
  },
  methods: {
    async closeModal() {
      var modal = document.getElementById("consentModal");
      modal.style.display = "none";
    },
    goToSectors() {
      let iso = [];
      this.selectedCountries.forEach((data) => {
        iso.push(data.ISO2);
      });
      this.$router.push({
        path: "/gesi-analysis/" + iso + "/choose-sector",
        // params: {'iso': iso}
      });
    },
    saveCountry: function (country) {
      this.openCountry(country);
      Users.updateCountry({
        countryName: country.name,
        type: this.$route.params.gbv ? "GBV" : "GESI",
      })
        .then(() => {
          this.openCountry(country);
        })
        .catch((err) => {
          console.log(`Error saving country ${err}`);
        });
    },
    openCountry: function (country) {
      if (this.$route.params.gbv) {
        this.$router.push({
          path: "/gbv-risk/" + country.ISO2 + "/choose-sector/true",
        });
      } else if (this.isBasic) {
        this.$router.push({
          path: "/gesi-analysis/" + country.ISO2 + "/choose-sector",
        });
      } else {
        if (this.user.organization) {
          // this.$router.push({
          //   path: "/gesi-analysis/" + country.ISO2 + "/choose-sector",
          // });
          if (
            country.isCountrySelected == false &&
            this.selectedCountries.length < 5
          ) {
            country.isCountrySelected = true;
            this.selectedCountries.push(country);
            if (this.selectedCountries.length == 1) {
              var modal = document.getElementById("consentModal");
              modal.style.display = "block";
            }
          } else {
            if (country.isCountrySelected) {
              var index = "";
              country.isCountrySelected = false;
              this.selectedCountries.forEach((data, i) => {
                if (data.ISO2 == country.ISO2) {
                  index = i;
                }
              });
              this.selectedCountries.splice(index, 1);
            }
          }
          this.data.map((d, ind) => {
            if (d.ISO2 == country.ISO2) {
              this.data.splice(ind, 1, country);
            }
          });
        } else {
          this.$router.push({
            path: "/gbv-risk/" + country.ISO2 + "/country/",
          });
        }
      }
    },
    redirectEQ: function () {
      this.$router.push({
        path: "/gbv-risk/" + this.equiloland.ISO2 + "/country/",
      });
    },
    mouseOver: function (index) {
      this.active = index;
    },
    sortCountries: function (countries) {
      countries.sort(function (a, b) {
        var x = a.humanName?.toLowerCase();
        var y = b.humanName?.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      return countries;
    },
    getCountries: function () {
      const searchResults = [];
      if (this.q) {
        for (var i = 0; i < this.data.length; i++) {
          if (
            searchResults.indexOf(this.data[i]) == -1 &&
            this.data[i] &&
            this.data[i]["humanName"] &&
            this.data[i]["humanName"]
              .toLowerCase()
              .indexOf(this.q.toLowerCase()) > -1
          ) {
            searchResults.push(this.data[i]);
            //break;
          }
        }
        return searchResults;
      } else {
        return this.data;
      }
    },
    handleSubscription(countries) {
      const userStore = useUserStore();
      if (userStore.subscriptionType === SUBSCRIPTION_TYPE.BASIC) {
        if (
          this.$route.params.gbv &&
          Array.isArray(
            this.user?.organization?.userSelectedCountriesGbvRiskScore
          ) &&
          this.user?.organization?.userSelectedCountriesGbvRiskScore.length ===
            1
        ) {
          const country = countries.find(
            (country) =>
              country.name ===
              this.user?.organization?.userSelectedCountriesGbvRiskScore[0]
          );
          if (country) {
            this.openCountry(country);
          }
        }
        if (
          !this.$route.params.gbv &&
          Array.isArray(
            this.user?.organization?.userSelectedCountriesGesiAnalysis
          ) &&
          this.user?.organization?.userSelectedCountriesGesiAnalysis.length ===
            1
        ) {
          const country = countries.find(
            (country) =>
              country.name ===
              this.user?.organization?.userSelectedCountriesGesiAnalysis[0]
          );
          if (country) {
            this.openCountry(country);
          }
        }
        this.isBasic = true;
        this.showModal = true;
      }
    },
    setModal() {
      this.showModal = false;
    },
    redirectUpgrade() {
      this.$router.push({
        path: "/upgrade",
      });
    },
  },
  computed: {
    countries: function () {
      const searchResults = [];
      if (this.q) {
        for (var i = 0; i < this.data.length; i++) {
          if (
            searchResults.indexOf(this.data[i]) == -1 &&
            this.data[i] &&
            this.data[i]["humanName"] &&
            this.data[i]["humanName"]
              .toLowerCase()
              .indexOf(this.q.toLowerCase()) > -1
          ) {
            searchResults.push(this.data[i]);
            //break;
          }
        }
        return searchResults;
      } else {
        return this.data;
      }
    },
    selectedCountrie: function () {
      return this.selectedCountries;
    },
    subregions: function () {
      //get subregions
      const regions = [];
      const subregions = [];
      for (var index in this.countries) {
        if (regions.indexOf(this.countries[index].region) === -1) {
          regions.push(this.countries[index].region);
        }
      }

      /*
                  As we want to apply our own sorting.
                */
      const array = [
        "Sub-Saharan Africa",
        "Latin America & Caribbean",
        "South Asia",
        "Southern Asia",
        "East Asia & Pacific",
        "Middle East & North Africa",
        "Eastern Europe & Central Asia",
        "North America",
      ];

      for (var i = 0; i < array.length; i++) {
        if (regions.indexOf(array[i]) > -1) {
          subregions.push(array[i]);
        }
      }

      return subregions;
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      this.$store.commit("resetCitations");
      this.user = await auth.me().catch(() => {
        console.log("Error login");
      });

      var grabAll = false;
      if (!this.$route.params.gbv) {
        if (
          !this.user.organization.accessGesi ||
          document.location.href.indexOf("print=") > -1
        ) {
          this.$router.push({ name: "notAllowed" });
        }
      }
      if (this.$route.params.gbv) {
        grabAll = true;
      }

      var citationPath = "/gbv-risk/choose-country/true";
      var citationPath2 = "/gesi-analysis";
      var citation3 = "GESI Analysis";
      if (this.$route.params.gbv) {
        citationPath = "/gbv-risk/choose-country/true";
        citationPath2 = "/gbv-risk";
        citation3 = "GBV Risk";
      } else {
        citationPath = "/gesi-analysis/choose-country";
        citationPath2 = "/gesi-analysis";
        citation3 = "GESI Analysis";
      }
      const _self = this;
      const url =
        env.apiUrl +
        "/organizations/" +
        this.user.organization._id +
        "/" +
        grabAll +
        "/countries";
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.handleSubscription(res.data?.data);
          this.count = res.data.count;
          this.data = this.sortCountries(
            res.data.data.filter((c) => c.ISO2 != "EQ")
          );
          this.data.filter((item) => {
            item["isCountrySelected"] = false;
          });
          this.$store.commit("downloadable", false);
          this.$store.commit("isFreeState", false);
          this.$store.commit("setBreadcrumbs", [
            { name: citation3, url: citationPath2 },
            { name: "Choose Country", url: citationPath },
          ]);
        });

      const equilolandData = env.apiUrl + "/countries/countriesequiloland";
      axios
        .get(equilolandData, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(function (res) {
          _self.equiloland = res.data.data;
        });
    });
  },
};
</script>
<style scoped>
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 15%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  border-radius: 20px;
}

.save-opportunity-button {
  text-align: center;
}
.save-opportunity-button button {
  background: #fe5000;
  color: #fff;
  border-color: #fe5000;
  border: 1px solid #fe5000;
  height: 36px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 9px 23px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  /* width: 70px; */
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}
.sectors_button {
  /* text-align: right; */
}
.sectors_button button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
}
.country_selected_style {
  border: 1px solid green;
  background: #e9faea;
}
.pointer-event-none {
  /* pointer-events: none; */
  opacity: 0.7;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
