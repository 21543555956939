<template>
  <!-- Modal -->
  <div class="fixed z-50 inset-0 overflow-y-auto" id="dealsPrivacy">
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div class="fixed inset-0 transition-opacity cursor-pointer">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle
        "
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style="max-width: 630px; width: 100%"
      >
        <div class="Modal_Readmorestyle">
          <div class="ModalInnerStyle">
            <h1
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              2X Assessment Privacy Policy
            </h1>
            <!-- <p>
              <input
                type="checkbox"
                id="terms"
                name="terms"
                value="terms"
                v-model="checkedTerms"
              />Access and use of the 2X Gender Smart Investment Ecosystem is
              subject to these
              <router-link
                to="/dealsTermsOfUse"
                style="color: rgb(0, 119, 200)"
                target="_blank"
                >terms of use</router-link
              >. Please
              <span style="color: rgb(0, 119, 200)" @click="termsCheck()"
                >click here</span
              >
              to confirm your acceptance of them.
            </p> -->
            <!-- <br /> -->
            <p>
              <input
                type="checkbox"
                id="privacy"
                name="privacy"
                value="privacy"
                v-model="checkedPrivacy"
              />
              Details of how your personal data is collected and used by the 2X
              Gender Smart Investment Ecosystem is set out in our
              <router-link
                to="/dataprivacy"
                style="color: rgb(0, 119, 200)"
                target="_blank"
                >privacy notice.</router-link
              >. Please
              <span style="color: rgb(0, 119, 200)" @click="privacyCheck()"
                >click here</span
              >
              to acknowledge that you have read it.
            </p>
            <button
              style="background-color: #fe5000; color: white !important"
              class="
                text-white
                px-3
                py-2
                text-xs
                gap-1
                font-bold
                rounded-lg
                text-white
                transition
                duration-200
                shadow
                flex flex-row
                items-center
                justify-center
                mr-4
                ml-4
              "
              :class="
                checkedPrivacy ? '' : 'pointer-events-none'
              "
              @click="savePolicyConsents()"
            >
              Confirm
            </button>
          </div>
          <!-- <div class="cross_Icon" @click="closeModal()">
            <img src="../assets/images/cros-icon.svg" alt="cross_Icon" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";
export default {
  name: "2xAssessPrivacyModal",
  data() {
    return {
      count: 0,
      checkedPrivacy: false,
      checkedTerms: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    privacyCheck() {
      this.count++;
      if (this.checkedPrivacy == true) {
        this.checkedPrivacy = false;
      } else {
        this.checkedPrivacy = true;
      }
    },
    termsCheck() {
      this.count++;
      if (this.checkedTerms) {
        this.checkedTerms = false;
      } else {
        this.checkedTerms = true;
      }
    },
    savePolicyConsents() {
      let data = {
        // twoXTermsOfUse: this.checkedTerms,
        twoXPrivacyPolicy: this.checkedPrivacy,
      };
      return axios
        .put(env.apiUrl + "/users/me", data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          if (res) {
            document.getElementById("dealsPrivacy").style.display = "none";
            this.$emit("pageReload");
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}
span {
  cursor: pointer;
}
input {
  margin: 6px;
}
.Modal_Readmorestyle {
  padding: 30px 0 10px 35px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: center;
      line-height: 18px;
      color: #1b1b1b;
    }
    button {
      margin: 30px auto;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }
  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}
</style>