<template>
  <div class="flex flex-col items-center justify-center min-h-screen">
    <div v-if="loading" class="text-lg font-semibold">Loading...</div>
    <div v-else class="text-center">
      <div v-if="error" class="text-red-500">
        Your token is invalid. Please contact support
      </div>
      <div v-else-if="!accepted">
        <p class="text-gray-700 text-xl mb-10">
          Accept your invite clicking in the button below
        </p>
        <a
          href="https://app.equilo.io/"
          target="_blank"
          class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
          @click="acceptInvite"
        >Accept invite</a
        >
      </div>
      <div v-else>
        <p class="text-gray-700 text-xl mb-10">Your invite has been accepted</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env";

export default {
  name: "IMMInvite",
  data() {
    return {
      accepted: false,
      loading: true,
      error: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const token = this.$route.params.token;
      axios
        .get(`${env.apiUrl}/imm-preferences/investee-invites/verify/${token}`)
        .then(({ data, status }) => {
          this.loading = false;
          if (status === 200) {
            this.accepted = data.data.accepted;
          } else {
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    acceptInvite() {
      const token = this.$route.params.token;
      axios
        .put(
          `${env.apiUrl}/imm-preferences/investee-invites/verify/${token}/accept`
        )
        .then(({ status }) => {
          this.loading = false;
          if (status === 200) {
            this.accepted = true;
          } else {
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>

<style scoped></style>