<template>
  <div class="static">
    <div class="w-full container mx-auto mt-32 px-4 lg:px-0">
      <div class="flex flex-row mt-32 lg:mt-0">
        <div class="flex flex-col w-6/12 lg:w-9/12">
          <h1
            class="text-5xl print:text-4xl tracking-tighter font-semibold lowercase capitalize"
            style="color: #201747;"
          >
          Forgive the oversight {{ this.$store.state.user.firstname }},
          </h1>
          <p class="tracking-tight font-medium" style="color: #201747;">
            Please excuse the mistake,You landed on a page you don’t have
            access to. If you think this is not correct, let us know via
            <a href="mailto:support@equilo.io" style="color:blue;">support@equilo.io</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";

export default {
  name: "not-allowed",
  data: function() {
    return {
      user: {}
    };
  },
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
  }
};
</script>
<style>
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}
</style>
