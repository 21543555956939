<template>
  <div>
    <div v-if="loading"><page-loader></page-loader></div>
    <div v-else>
      <div
        v-if="isComplete"
        class="mt-80 mx-10 text-4xl flex flex-col items-center"
      >
        <span> You already answered this survey. </span>
        <button
          @click="toggleCompletion"
          class="mt-20 bg-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-2 px-4 rounded-lg w-1/4"
        >
          Retake the survey
        </button>
      </div>
      <div v-else>
        <div v-if="investeeId">
          <div class="flex justify-between">
            <div class="w-4/6">
              <Survey
                :survey-data="surveyData"
                :survey-json="surveyJson"
                :user="user"
                :survey-id="surveyId"
                :answer-again="answerAgain"
                :id="id"
                :isComplete="isComplete"
                :userType="userType"
              />
            </div>
            <div
              class="w-2/6 mt-[510px] h-[610px] overflow-y-auto shadow-lg p-3 rounded-lg mr-20"
            >
              <div v-if="investeeSurveyResponse.length" class="px-3 py-2">
                <h2 class="text-2xl font-bold">Company survey responses</h2>
                <div
                  v-for="response in investeeSurveyResponse"
                  :key="response.questionId"
                  class="flex flex-col mt-5 gap-1"
                >
                  <span class="text-lg font-bold">{{ response.title }}</span>
                  <span>{{ response.answer }}</span>
                </div>
              </div>
              <div v-else>
                <h2 class="text-xl font-bold px-7 py-5">
                  The company has not answered the survey yet.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <Survey
            :survey-data="surveyData"
            :survey-json="surveyJson"
            :user="user"
            :survey-id="surveyId"
            :answer-again="answerAgain"
            :id="id"
            :isComplete="isComplete"
            :userType="userType"
            @surveyComplete="surveyComplete"
            @survyeEdited="surveyEdited"
          />
        </div>
      </div>
      <div class="flex justify-end items-center mt-20">
        <button
          class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex items-center float-right mb-10 mr-8"
          @click="saveCurrentProgress"
          :disabled="isCurrentButtonActive < 1"
          :class="{ 'opacity-50 cursor-not-allowed': isCurrentButtonActive < 1,}"
        >
          Save current progress
        </button>
      </div>
      <div
        v-if="showFinishedMessage"
        class="flex justify-center items-center mt-20"
      >
        <h2 class="text-2xl font-bold">Survey completed. Redirecting...</h2>
      </div>
    </div>
  </div>
</template>

<script>
import Survey from "../../components/surveys/survey.vue";
import auth from "@/helpers/auth";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import { getSurveyData } from "@/components/imm-components/IMMhelpers.js";

export default {
  components: {
    Survey,
    pageLoader,
  },
  data() {
    return {
      user: {},
      surveyData: null,
      surveyJson: {},
      loading: false,
      surveyId: "",
      isComplete: false,
      answerAgain: false,
      id: null,
      userType: null,
      investeeId: null,
      investeeUser: null,
      investeeSurveyId: null,
      investeeSurveyResponse: [],
      showFinishedMessage: false,
      isCurrentButtonActive: "",
    };
  },
  async mounted() {
    this.loading = true;

    this.investeeId = this.$route.query.id;
    this.investeeSurveyId = this.$route.query.surveyId;

    if (this.investeeId) {
      this.investeeUser = await this.fetchInvesteeUser();
      const { surveyResponse } = await getSurveyData(
        this.investeeSurveyId,
        this.investeeUser._id
      );
      this.investeeSurveyResponse = surveyResponse;
    }

    try {
      // load user data, creates survey id from params and checks if survey has been answered already
      this.user = await auth.me();

      this.surveyId = this.$route.params.survey;
      // check the url query and assign tag to variable

      // TO DO
      // set user type from back-end (Fund, Startup..) so that it matches with our current survey tags: fund and company
      // something like if this.user.organizationType === 'Startup' then this.userType = 'company'
      // setting userType here just to test
      // THIS IS FOR TESTING ONLY
      /* if (this.$route.query.tag) {
        this.userType = this.$route.query.tag;
      } */
      //
      this.userType = await this.transformEntityType(this.user.entityType);

      if (this.user.surveyData) {
        this.surveyData = this.user.surveyData.find(
          (survey) =>
            survey.surveyId === this.surveyId && survey.tag === this.userType
        );
      }

      // check if survey has been answered already or saved
      if (this.surveyData) {
        this.id = this.surveyData?._id;
        this.isComplete = this.surveyData?.isComplete;

        // transform surveyData from array to object with key values
        let surveyDataObject = {};
        this.surveyData.surveyResponse.forEach((survey) => {
          surveyDataObject[survey.questionId] = survey.answer;
        });
        this.surveyData = surveyDataObject;
      }
      await this.fetchSurvey(this.userType);
    } catch (error) {
      console.log("Login error ", error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    saveCurrentProgress() {
      this.$root.$emit("saveCurrentProgressFromParent");
    },
    surveyEdited(val) {
      this.isCurrentButtonActive = val;
    },
    toggleCompletion() {
      this.isComplete = false;
      this.answerAgain = true;
    },
    async fetchSurvey(userType) {
      try {
        // fetch survey
        const {
          data: { data },
          status,
        } = await axios.get(
          `${env.apiUrl}/surveys/${this.surveyId}?tag=${userType}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        if (status == 200) {
          this.surveyJson = data.json;
        }
      } catch (error) {
        console.log("Error fetching survey", error);
      }
    },
    async transformEntityType(entityType) {
      let userType;
      const entityMap = {
        "start-up": "start-up",
        sme: "sme",
        corporate: "corporate",
        "fund-manager": "fund",
        "financial-institution": "financial-institution",
        "On-lending facility": "financial-institution",
        "Institutional investor": "institutional-investor",
        "Corporate (large)": "corporate",
        "Small or medium enterprise": "sme",
        "Micro-enterprise": "micro",
        SME: "sme",
        Corporate: "corporate",
        "Financial Institution": "financial-institution",
        "Institutional Investor": "institutional-investor",
        Fund: "fund",
        Micro: "micro",
      };
      userType = entityMap[entityType];
      return userType;
    },
    async fetchInvesteeUser() {
      const {
        data: { user },
      } = await axios.get(
        `${env.apiUrl}/imm-preferences/user/${this.investeeId}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );

      return user;
    },
    surveyComplete() {
      this.isCurrentButtonActive = false;
      this.showFinishedMessage = true;
      setTimeout(() => {
        this.$router.push("/imm/dashboard");
      }, 2000);
    },
  },
};
</script>
