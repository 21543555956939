import { render, staticRenderFns } from "./HeroSection2xChallange.vue?vue&type=template&id=832d6eb8&scoped=true"
import script from "./HeroSection2xChallange.vue?vue&type=script&lang=js"
export * from "./HeroSection2xChallange.vue?vue&type=script&lang=js"
import style0 from "./HeroSection2xChallange.vue?vue&type=style&index=0&id=832d6eb8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "832d6eb8",
  null
  
)

export default component.exports