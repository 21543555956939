<template>
  <div class="mb-20" style="width: 960px">
    <div class="flex flex-col">
      <div class="flex flex-row">
        <div
          class="flex flex-col mx-auto mt-10 w-8/12 lg:w-8/12"
          id="domain-search"
        >
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <svg
              class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle
                cx="11.059"
                cy="11.059"
                r="7.059"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                x1="20"
                y1="20"
                x2="16.05"
                y2="16.05"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <v-select
              @input="this.selectValue"
              :value="this.selectedValue"
              :options="this.category"
              class="
                border-2 border-gray-200
                rounded-lg
                px-3
                py-2
                text-sm
                w-full
                pl-10
                font-medium
                focus:outline-none focus:shadow-outline
              "
              placeholder="Select a Category"
              label="name"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12"></div>
        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-world">
          <div
            class="
              text-sm text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion(null)"
            >
              World
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-africa">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('002')"
            >
              Africa
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-asia">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('142')"
            >
              Asia
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-europe">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('150')"
            >
              Europe
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="region-south-america">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('005')"
            >
              South America
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="region-central-america">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('013')"
            >
              Central America
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="region-caribbean">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('029')"
            >
              Caribbean
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="region-caribbean">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('021')"
            >
              North America
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12"></div>
      </div>
    </div>

    <transition name="fadeIn" mode="out-in">
      <!-- <div id="notLoaded" v-if="this.loaded == false" key="loading">
        <page-loader></page-loader>
      </div> -->

      <div id="loaded" v-if="chartOptions && chartData" key="content">
        <!-- map -->
        <div id="outer" style="position: relative; height: 500px">
          <GChart
            :settings="{
              packages: ['geochart'],
              mapsApiKey: 'AIzaSyDuDv2cdmK0nNUTCrtrIrgy9PtOlBXeW84',
            }"
            type="GeoChart"
            :data="this.chartData"
            :options="this.chartOptions"
            id="global-heatmap"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
// import pageLoader from "@/components/page-loader.vue";

export default {
  components: {
    GChart,
    // pageLoader,
  },

  data() {
    return {
      selectedItem: null,
      domainWiseThemes: [],
      dataset: [],
      //   scores: [],
      intersections: [],
      themes: [],
      domains: [],
      selected: "",
      selectedTheme: "",
      selectedValue: "",
      loaded: false,
      chartData: [],
      chartOptions: {
        chart: {
          title: this.selectedValue,
          subtitle: "",
        },
        // width: '1000',
        height: '700',
        colorAxis: {},
        tooltip: {
          isHtml: true,
          trigger: "visible",
          textStyle: { fontSize: 14, fontName: "basier" },
        },
        legend: {
          numberFormat: ".##",
          textStyle: { fontSize: 12, fontName: "basier" },
        },
        region: null,
      },
      category: [
        // "Value of portfolio that is gender smart",
        "% of portfolio companies that are 2X eligible",
        "% of portfolio companies that meet 2X leadership criteria",
        "% of portfolio companies that meet 2X entrepreneurship criteria",
        "% of portfolio companies that meet 2X employment criteria",
        "% of portfolio companies that meet 2X consumption criteria",
      ],
    };
  },
  methods: {
    async selectValue(value) {
      this.selectedValue = value;
      await this.updateMap();
    },
    updateMap: function () {
      this.chartData = [
        [
          "Country",
          this.selectedValue,
          { role: "tooltip", type: "string", p: { html: true } },
        ],
      ];

      this.chartOptions.colorAxis = {
        values: [0, 19, 30, 39, 50, 65, 80, 100],
        colors: [
          "#FE5000",
          "#FED8B1",
          "#EDA804",
          "#FAE053",
          "#90ee90",
          "#90ee90",
          "#2BD4C4",
          "#2BD4C4",
        ],
      };
      if (
        this.selectedValue == "% of portfolio companies that are 2X eligible"
      ) {
        var amountFormat = new Intl.NumberFormat("en-US");
        for (let i = 0; i < this.scores.length; i++) {
          if (
            this.scores[i].eligible2X !== "Missing" &&
            this.selectedValue != ""
          ) {
            this.chartData.push([
              { v: this.scores[i].iso, f: this.scores[i].countryName },
              parseFloat(this.scores[i].eligible2X),
              "<b>" +
                this.selectedValue +
                ": </b>" +
                parseFloat(this.scores[i].eligible2X) +
              "% <br>" +
              "<b>Value of portfolio that is gender smart: </b>" +
              amountFormat.format(
                this.scores[i].totalAmountInvestedInCompanies
              ) +
              "$"
            ]);
            this.loaded = true;
          }
        }
      }
      if (this.selectedValue == "Value of portfolio that is gender smart") {
        for (let i = 0; i < this.scores.length; i++) {
          if (
            (this.scores[i].totalAmountInvestedInCompaniesInPercentage !==
              "Missing" ||
              this.scores[i].totalAmountInvestedInCompanies !== "Missing") &&
            this.selectedValue != ""
          ) {
            var numFormat = new Intl.NumberFormat("en-US");
            // return numFormat.format(x);
            this.chartData.push([
              { v: this.scores[i].iso, f: this.scores[i].countryName },
              parseFloat(
                this.scores[i].totalAmountInvestedInCompaniesInPercentage
              ),
              "<b>" +
                this.selectedValue +
                ": </b>" +
                numFormat.format(
                  this.scores[i].totalAmountInvestedInCompanies
                ) +
                "$" +
                "<br>" +
                "<b>" +
                "% of portfolio that is gender smart" +
                ": </b>" +
                this.scores[
                  i
                ].totalAmountInvestedInCompaniesInPercentage.toFixed(2) +
                "%",
            ]);
            this.loaded = true;
          }
        }
      }
      if (
        this.selectedValue ==
        "% of portfolio companies that meet 2X leadership criteria"
      ) {
        for (let i = 0; i < this.scores.length; i++) {
          if (
            this.scores[i].leadershipCriteriaMet !== "Missing" &&
            this.selectedValue != ""
          ) {
            this.chartData.push([
              { v: this.scores[i].iso, f: this.scores[i].countryName },
              parseFloat(this.scores[i].leadershipCriteriaMet),
              "<b>" +
                this.selectedValue +
                ": </b>" +
                parseFloat(this.scores[i].leadershipCriteriaMet) +
                "%",
            ]);
            this.loaded = true;
          }
        }
      }
      if (
        this.selectedValue ==
        "% of portfolio companies that meet 2X entrepreneurship criteria"
      ) {
        for (let i = 0; i < this.scores.length; i++) {
          if (
            this.scores[i].entrepreneurshipCriteriaMet !== "Missing" &&
            this.selectedValue != ""
          ) {
            this.chartData.push([
              { v: this.scores[i].iso, f: this.scores[i].countryName },
              parseFloat(this.scores[i].entrepreneurshipCriteriaMet),
              "<b>" +
                this.selectedValue +
                ": </b>" +
                parseFloat(this.scores[i].entrepreneurshipCriteriaMet) +
                "%",
            ]);
            this.loaded = true;
          }
        }
      }
      if (
        this.selectedValue ==
        "% of portfolio companies that meet 2X employment criteria"
      ) {
        for (let i = 0; i < this.scores.length; i++) {
          if (
            this.scores[i].employmentCriteriaMet !== "Missing" &&
            this.selectedValue != ""
          ) {
            this.chartData.push([
              { v: this.scores[i].iso, f: this.scores[i].countryName },
              parseFloat(this.scores[i].employmentCriteriaMet),
              "<b>" +
                this.selectedValue +
                ": </b>" +
                parseFloat(this.scores[i].employmentCriteriaMet) +
                "%",
            ]);
            this.loaded = true;
          }
        }
      }
      if (
        this.selectedValue ==
        "% of portfolio companies that meet 2X consumption criteria"
      ) {
        for (let i = 0; i < this.scores.length; i++) {
          if (
            this.scores[i].consumptionCriteriaMet !== "Missing" &&
            this.selectedValue != ""
          ) {
            this.chartData.push([
              { v: this.scores[i].iso, f: this.scores[i].countryName },
              parseFloat(this.scores[i].consumptionCriteriaMet),
              "<b>" +
                this.selectedValue +
                ": </b>" +
                parseFloat(this.scores[i].consumptionCriteriaMet) +
                "%",
            ]);
            this.loaded = true;
          }
        }
      }
    },
    updateRegion: function (code) {
      this.chartOptions.region = code;
      this.updateMap();
    },
    
     removeGoogleErrors() {
      var id_root = "google-visualization-errors-all-";
        var index = 1;
        while (document.getElementById(id_root + index.toString()) != null) {
          document.getElementById(id_root + index.toString()).style.display = 'none';
          index += 2;
        } 
    }
  },
  props: ["sector", "scores"],
  async created() {
    this.updateMap();
  },mounted(){
    this.removeGoogleErrors();
  }
};
</script>

<style>
.region {
  background-color: rgba(0, 119, 200, 0.1);
}

.region:focus {
  background-color: rgba(0, 119, 200, 0.3);
  border-color: #555555;

  outline: none;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  padding: 8px 40px 0 40px;
}
.multiselect__option--highlight {
  background-color: #5897fb;
}
.multiselect__option--highlight::after {
  display: none;
}
.multiselect__option--highlight.multiselect__option--selected {
  background-color: #5897fb;
}
.multiselect__option--selected::after {
  display: none;
}
.multiselect__option--selected {
  font-weight: 400;
  background-color: white;
}
.multiselect__option {
  min-height: auto;
  padding: 5px 12px;
}
.multiselect__content {
  font-size: 12px;
}
.custom-multiselect-div {
  min-height: 48px;
  position: relative;
}
.custom-multiselect-svg {
  position: absolute;
  z-index: 1;
}
.custom-pointer {
  cursor: pointer;
}
.multiselect__option--disabled {
  font-weight: 900;
  font-size: 14px;
}
</style>