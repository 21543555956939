<template>
  <div class="flex flex-row w-full bg-gray-100">


    <sidebar></sidebar>

    <!--TABLE-->
    <div class="flex flex-col w-full overflow-hidden" style="padding-top: 100px; padding-left: 30px; padding-bottom: 30px padding-right: 30px;">
      
    </div>
  </div>
</template>

<script>
  import sidebar from '@/components/sidebar.vue'
  import auth from '@/helpers/auth';
  const axios = require('axios');
  import Cookies from '@/helpers/cookies'
  import env from '@/../env.js';


  export default {
    name: 'Overview',
    data: function () {
      return {
        user: {},
        organization: {},
        subscription: {},
        payment: {}
      }
    },
    components: {
      sidebar,
    },
    methods: {
      open: function(href){
        var win = window.open(href, '_blank');
        win.focus();
      },
      changePlan: function (subscription) {
        if (confirm('Are you sure you want to change your plan? We will redirect you to our payment page.')) {
          const url = env.apiUrl + '/organizations/' + this.organization._id + '/subscriptions/' +subscription._id;
          document.location.href = url;
        }
      },
      getPayment: function () {
        const _self = this;
        return new Promise(function (resolve) {
          axios.post(env.apiUrl + '/payments/offsession/'+_self.user.organization.subscription.id, {
            orgid:  _self.user.organization._id,
            customerId:  _self.user.organization.customerId
            },{
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data);
          });
        })
      },
      updatePlan: function(result){
        const _self = this;
        return new Promise(function () {
          axios.put(env.apiUrl+'/payments/'+_self.user.organization._id, {
            subscription: _self.user.organization.subscription.id,
            currentUser: {"first_name":_self.user.firstname,"email":_self.user.email},
            paymentId: result.status.id
          }, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(async function () {
             await _self.$swal.fire(
                        'Congratulations!',
                        'Your subscription has been renewed',
                        'success'
                      )
            _self.$router.replace({ path: "/" });
           
          });
        })
      },
      load: function () {
        const _self = this;
        return new Promise(function (resolve) {
          axios.get(env.apiUrl + '/subscriptions/'+_self.$route.params.planId, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data.data);
          });
        })
      }
    },
    async created() {

      const _self = this;
       this.user = await auth.me().catch(() => {});
       this.organization = this.user.organization;
       
       if(typeof this.user.organization.customerId == "undefined"){
           await _self.$swal.fire({
                    icon: "error",
                    title: "Something went wrong!",
                    text:"We don't have your previous card information, please enter your card details."
                  });
                  _self.$router.replace({ path: "/settings/plans" });
       }else{
       this.subscription = await _self.load();
      this.$store.commit('downloadable', false);
                this.$store.commit('setBreadcrumbs', [{
                    'name': 'Settings',
                    'url': '/settings/account'
                  },
                  {
                    'name': this.organization.name,
                    'url': '/settings/team'
                  },
                  {
                    'name': 'Auto Renew',
                    'url': '/settings/autorenew'
                  },
                ]);
       await _self.$swal.fire({
                title: 'Are you sure?',
                text: "It will charge your previous card",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
        }).then(async function (result) {
            if (result.isConfirmed) {
                const result = await _self.getPayment();
                if(result.status.status == 'succeeded'){
                // Success will not land on this page.
                    await _self.updatePlan(result);
                  
                }else{
                   await _self.$swal.fire({
                    icon: "error",
                    title: "Something went wrong!",
                    text:"We got an error, please try again!"
                  });
                  _self.$router.replace({ path: "/settings/plans" });
                }
              
            }else{
                _self.$router.replace({ path: "/settings/plans" });
            }
          });
      
            
       
    }





    }
  }
</script>
