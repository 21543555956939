<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: 'View Evaluation Results'
    },
    isPaid: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ['onClickEvaluationResults', 'onPayEvaluationResults'],
};
</script>

<template>
  <div>
    <button
      v-if="isPaid"
      class="[ full-assessment-button ][ mx-auto mr-0 px-3 py-2 ][ flex flex-row items-center justify-center gap-1 ][ text-xs ][ rounded-lg transition duration-200 ]"
      @click="$emit('onClickEvaluationResults')"
    >
      <span class="pr-1">{{text}}</span>
      <svg
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="white"/>
      </svg>
    </button>
    <button
      v-else
      class="[ full-assessment-button ][ mx-auto mr-0 px-3 py-2 ][ text-xs ][ flex flex-row items-center justify-center gap-1 ][ rounded-lg transition duration-200 ][ opacity-70 ]"
      @click="$emit('onPayEvaluationResults')"
    >
      <span class="pr-1">View Evaluation Results</span>
      <img src="@/assets/images/lock_white.svg" alt="lock-white">
    </button>
  </div>
</template>

<style scoped lang="scss">
.full-assessment-button {
  font-weight: bold;
  color: white;
  background: #19486a;
  padding: 12px 24px 12px 24px;
  font-size: 16px;
}
</style>