<template>
  <div class="container_foto container_foto-2x-gender long-text" :style="{opacity: isDisabled() ? '0.60' : '1'}" style="margin-top: 22px;" @click="moreInfo()">
   <template v-if="title == 'Take Action' && $route.params.iso2 == 'EQ'">
      <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
      <img class="lock" src="@/assets/images/lock-dark.png" alt="" />
   </template>
   <div class="ver_mas text-center">
      <img
        src="@/assets/images/eye.png"
        alt="visibility-icon"
        class="visibility-icon"
      />
    </div>
    <article class="text-left">
      <img :src="icon" class="card-icon" />
      <h2>{{ title }}</h2>
      <h4>
        <item style="line-height: 1.5;" :text="text" :country="country"></item>
      </h4>
    </article>
    <img class="bg-shade" :src="icon" alt="" style="width: 50%;"/>
  </div>
</template>

<script>
 import item from '@/components/text.vue';
 import env from '@/../env.js';
 import { canAccess } from '../security/acl';
 import { useUserStore } from '@/stores/user';

  export default {
    name: 'gbv-card',
    props: ['title','text','icon','url','propsCountry'],
    data: function () {
      return {
        env,
        country: {
          income: "", // let's define it. Otherwise we cannot use toLowerCase in the sourcecode
        },
        targetUrl: `/gbv-risk/${this.$route.params.iso2}/${this.$route.params.sector}/${this.url}`,
      }
    },
    components: {
      item
    },
    methods: {
      isDisabled () {
        const userStore = useUserStore();
        return canAccess(this.targetUrl, userStore.subscriptionType) !== true
      },
      moreInfo: function () {
        this.$router.push({
          path: this.targetUrl
        });
      },
      toFixed: function(input, howMuch){
        if(input && input.indexOf('Missing') == -1){
          try {
            return Number(input).toFixed(howMuch)
          } catch(e){
            return 0
          }
        } else {
          return 'Missing'
        }
      },
      opacity: function (hex) {

        if(!hex){
          return 'white'
        }

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const rgb = result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
        return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.25)'
      },

    },
    async created() {
      this.country = this.propsCountry; // lets do an await before rendering other items.
    }
  }
</script>
<style>
    .lockedstyle :hover{
            cursor: no-drop;
    }
    </style>