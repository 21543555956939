<template>
    <div>
        <LandingPage :investor=false></LandingPage>
    </div>
</template>
<script>
import LandingPage from "./IMMLandingPage.vue"
export default {
    name: "IMMCompanyLandingPage",
    components: {
        LandingPage
    }
}
</script>