<template>
  <div class="static">
    <div class="main-container pt-10 m-auto">
      <div class="top-heading-section">
        <div class="inner-top-heading-section flex justify-between">
          <h1 class="heading">Set up your 2X Portfolio</h1>
        </div>
      </div>
      <div class="settings-field-section">
        <PortfolioSection
        />
      </div>
    </div>
  </div>
</template>
<script>
import PortfolioSection from "@/components/2x-certification/2x-portfolio-setup-section.vue";

export default {
  name: "SetUpPreferences",
  data() {
    return {
    };
  },
  components: {
    PortfolioSection
  },
  methods: {
  },
  async mounted() {
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Portfolio Setup", url: "/2X-Ecosystem/2x-portfolio-setup" },
    ]);
  },
};
</script>
<style lang="scss" scoped>
.static {
    background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans !important;
}
.main-container {
  width: 616px;
  gap: 44px;

  .top-heading-section {
    margin-top: 128px;

    .inner-top-heading-section {
      .heading {
        font-family: Montserrat;
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }

      .step-status {
        font-size: 20px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea4ba;
      }
    }
  }

  .settings-field-section {
    margin-top: 16px;
  }
}

.bottom-step-section {
  width: 100%;
  height: 120px;
  padding: 36px 80px 36px 80px;
  border-top: 1px solid #e1e3e9;
  box-shadow: 0px -2px 20px 0px #636e801a;

  .label-text {
    max-width: 595px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #717686;
    margin-top: 8px;
  }

  .next-step-button {
    button {
      width: 252px;
      height: 48px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      gap: 8px;
      background: #191b45;
      color: #ffffff;
    }

    .in-active-button {
      background: #e1e3e9;
      cursor: auto;
      pointer-events: none;
    }
  }
}
</style>
