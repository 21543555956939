<template>
    <div class="static">
        <div class="main-container mb-20">
            <BreadCrumbs class="breadcrumbs-main-container"></BreadCrumbs>
            <div class="page-title-section flex justify-between mt-6">
                <div class="page-title">Climate</div>
                <div></div>
            </div>

            <div class="page-title-section flex justify-between mt-6">
                <div class="bar-chart-section flex justify-between items-center">
                    <div class="filter-options-section flex">
                        <div class="filter-options flex items-center">
                            <div>By quarters</div>
                            <div class="active">All time</div>
                        </div>
                        <div class="select-year">
                            <select name="year" id="year">
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="download-button">
                    <button class="flex">Customize and download the report
                        <img src="@/assets/images/2x_certify/download-navy-blue.svg" />
                    </button>
                </div>
            </div>



            <!-- Progress Bar Charts -->
            <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-6 gap-8">

                <div>
                    <div class="flex justify-between items-center">
                        <div class="text-[#2C2E35] font-bold text-base leading-6">
                            Impact rating
                        </div>
                        <div></div>
                    </div>
                    <div class="mt-8">
                        <ProgressBarChart v-for="(item, index) in progressDataOne" :key="index"
                            :companyName="item.companyName" :percentage="item.percentage" :color="item.color" />
                    </div>
                </div>
                <div>
                    <div class="flex justify-between items-center">
                        <div class="text-[#2C2E35] font-bold text-base leading-6">
                            Energy Emission Calculations
                        </div>
                        <div></div>
                    </div>
                    <div class="mt-8">
                        <ProgressBarChart v-for="(item, index) in progressDataTwo" :key="index"
                            :companyName="item.companyName" :percentage="item.percentage" :color="item.color" />
                    </div>
                </div>
            </div>

            <!-- AssessmentClimateTable -->

            <div class="flex justify-between items-center mt-8">
                <h1 class="text-2xl font-bold" :class="{ 'is-fixed fixed-header': fixedComprehensiveSection }">
                    Climate Assessment Results
                </h1>
                <img v-if="isComprehensiveTableVisible"
                    @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
                    src="@/assets/images/arrow-up.svg" />
                <img v-if="!isComprehensiveTableVisible"
                    @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
                    src="@/assets/images/arrow-down.svg" />
            </div>

            <div class="mt-6" ref="comprehensiveSection" v-if="isComprehensiveTableVisible">
                <AssessmentClimateTable />
            </div>
            <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-6 gap-8">
                <div class="border rounded-lg p-8">
                    <h3 class="chart-title py-2">
                        Greenhouse gas emissions
                    </h3>
                    <h4 class="chart-subtitle pb-6">
                        <img class=" text-white rounded-lg" src="@/assets/images/Rightcircle.svg" /> Does the company
                        have target(s) for greenhouse gas emissions?
                    </h4>
                    <StackBarChart :chart-data="chartData" :chart-labels="chartLabels" />
                </div>
                <div class="border rounded-lg p-8">
                    <h3 class="chart-title pb-6">
                        What percentage of female & male students complete / graduate from the education program?
                    </h3>
                    <StackBarChart :chart-data="chartDataStream" :chart-labels="chartLabelsStream" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import ProgressBarChart from "../../../components/imm-components/ProgressBarChart.vue";
import AssessmentClimateTable from "../../../components/imm-components/tables/assessment-climate-table.vue";
import StackBarChart from "../../../components/charts/stack-bar-chart.vue";

export default {
    name: "CompanyPageClimate",
    data() {
        return {
            progressDataOne: [
                { companyName: 'Company name', percentage: 60, color: '#FFDE60' },
            ],
            progressDataTwo: [
                { companyName: 'Company name', percentage: 10, color: '#FFDE60' },
            ],
            isComprehensiveTableVisible: true,
            fixedComprehensiveSection: false,
            chartData: [
                {
                    label: 'Scope 1',
                    backgroundColor: '#3284CB',
                    stack: 'emissions',
                    data: [40, 20, 20, 10],
                    barThickness: 32,
                    borderRadius: 10,
                },
                {
                    label: 'Scope 2',
                    backgroundColor: '#FFDE60',
                    stack: 'emissions',
                    data: [10, 20, 20, 45],
                    barThickness: 32,
                    borderRadius: 10
                },
                {
                    label: 'Scope 3',
                    backgroundColor: '#4DC9BE',
                    stack: 'emissions',
                    data: [10, 15, 10, 25],
                    barThickness: 32,
                    borderRadius: 10
                },
                {
                    label: 'Targets',
                    backgroundColor: '#fff',
                    stack: 'emissions',
                    data: [10, 10, 20, 10],
                    barThickness: 32,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderDash: [2, 2],
                    borderColor: 'rgba(0, 0, 0, 0.5)'
                }
            ],
            chartLabels: ['2020', '2021', '2022', '2023'],
            chartDataStream: [
                {
                    label: 'Graduated',
                    backgroundColor: '#43467C',
                    stack: 'emissions',
                    data: [60, 50, 40, 30],
                    barThickness: 20,
                    borderRadius: 50,
                },
                {
                    label: 'Quitted',
                    backgroundColor: '#FFDE60',
                    stack: 'emissions',
                    data: [30, 35, 40, 45],
                    barThickness: 20,
                    borderRadius: 50
                },
            ],
            chartLabelsStream: ['2020', '2021', '2022', '2023'],
        }
    },
    components: {
        BreadCrumbs,
        ProgressBarChart,
        AssessmentClimateTable,
        StackBarChart,
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.$store.commit("setNewBreadcrumbs", [
            { name: "Dashboard", url: "/imm/imm-landing-page" },
            { name: "Investor page", url: "/imm/Investor-page" },
            { name: "Company profile", url: "/imm/Investor-page/company-profile" },
            { name: "Climate", url: "/imm/Investor-page/company-profile/climate" },
        ]);
    },
    methods: {
        handleScroll() {
            if (this.$refs.comprehensiveSection.getBoundingClientRect().top <= 122) {
                this.fixedComprehensiveSection = true;
            } else {
                this.fixedComprehensiveSection = false;
            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style lang="scss" scoped>
.main-container {
    .fixed-header {
        position: fixed;
        width: 91.6%;
        margin-top: 64px;
        padding: 20px 0;
        z-index: 10;
        transition: top 0.3s ease;
        display: flex;
        justify-content: space-between;
        height: 90px;
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 68%, rgba(255, 255, 255, 0) 83%);
    }

    .is-fixed {
        top: 0;
    }

    margin-left: 80px;
    margin-right: 80px;

    .breadcrumbs-main-container {
        margin-top: 100px;
    }

    .page-title-section {
        height: 45px;

        .page-title {
            font-family: Montserrat;
            font-size: 36px;
            font-weight: 700;
            line-height: 45px;
            letter-spacing: 0em;
            text-align: left;
            color: #2C2E35;
        }

        .page-figures {
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #717686;
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }

    .sales-table-section {}

    .statement-table-section {
        .statement-content-section {
            .statement-heading {
                font-family: Montserrat;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #2C2E35;

                img {
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
            }

            .metrices-section {
                .charts-section {
                    .line-chart-section {
                        border: 1px solid #E1E3E9;
                        border-radius: 16px;

                        .line-chart-heading {
                            //styleName: Body2 Bold;
                            font-family: Arial;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #2C2E35;
                        }
                    }
                }
            }
        }
    }
}

.fixed-header {
    position: fixed;
    width: 91.6%;
    margin-top: 64px;
    padding: 20px 0;
    z-index: 10;
    transition: top 0.3s ease;
    display: flex;
    justify-content: space-between;
    height: 90px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 68%, rgba(255, 255, 255, 0) 83%);
}

.is-fixed {
    top: 0;
}

.download-button {
    button {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        border: 1px solid #191B45;
        gap: 6px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #191B45;
    }
}

.bar-chart-section {
    .filter-options-section {
        gap: 12px;

        .filter-options {
            height: 32px;
            background: #F4F6F9;
            border-radius: 8px;
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #9EA4BA;

            div {
                padding: 6px 12px 6px 12px;
            }

            .active {
                background: #2177C1;
                border-radius: 8px;
                color: #FFFFFF;
                padding: 5px 20px;
            }
        }

        .select-year {
            select {
                height: 32px;
                padding: 0 12px;
                border-radius: 8px;
                border: 1px solid #E1E3E9;
                gap: 8px;
                color: #2C2E35;
            }
        }
    }
}

.chart-title {
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

}

.chart-subtitle {
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

}
</style>