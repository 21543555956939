<template>
    
  <div class="static">
     <!-- <img
      src="@/assets/images/background-image-overview.jpg"
      alt="background-image"
      class="object-contain h-64 no-print"
    /> -->
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full mt-20">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <img
                    class="h-16 print:h-auto print:w-64"
                    src="@/assets/images/Demo.png"
                  />
                  
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5">
                    Tutorials
                  </h1>

                </div>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center">
                Watch our demo video below to see how Equilo can provide deep-dive information for all countries globally across over 50
                sectors, with the ability to explore the indicators, deep-dive into domain and thematic areas, and get tips and ideas on
                what works to address gaps.
                </p>
              </div>
            </div>
          </div>
        </div>
          <main style="grid-template-columns:none !important">
              
            <div style="width:100%; text-align: center;">

                <section class="w-full container mx-auto margintop3 px-4 lg:px-0 border-t border-gray-300" style="max-width: 960px">

                    <div class="flex flex-col mt-5 mb-5 ml-5" v-scroll-reveal>

                       <iframe title="vimeo-player" src="https://player.vimeo.com/video/500414524?title=0&byline=0&portrait=0&sidedock=0" width="1000" height="600" frameborder="0" allowfullscreen></iframe>

                    </div>


                </section>   

            </div>
          </main>
      </div>
    </transition>
  </div>
</template>

<script>
import pageLoader from "@/components/page-loader.vue";
export default {
    name: "tutorials",
    data: function() {
     return {
         active:false,
         endofcreated:false
     }
    },
    components: {
      pageLoader
    },
    methods: {
        mouseOver: function(index) {
            this.active = index;
        },
    },
    async created(){
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
                  { name: "Tutorials", url: "/tutorials" },
            ]);
        this.endofcreated=true;
    },  
    async mounted() {

    },
};
</script>
<style>
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}
</style>
