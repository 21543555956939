<template>
  <div class="flex flex-row w-full bg-gray-100">
    <sidebar></sidebar>
    <!--TABLE-->
    <div
      class="flex flex-col px-10 py-10 w-full"
      v-if="user && user._id"
      style="padding-top: 100px;overflow-y: hidden;"
    >
      <iframe v-if = access_url :src="access_url" id="drop_in_portal" style="height:100%"></iframe>

    </div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div v-if="endofcreated && user == []" style="margin-top: 4rem">
        <div class="flex flex-row justify-center items-center mt-3 ml-20">
          <div class="flex flex-col item-center justify-center">
            <div
              class="flex flex-row items-center justify-center print:flex-col"
            >
              <h1
                class="
                  text-6xl
                  tracking-tighter
                  font-medium
                  text-center
                  print:text-7xl
                  ml-5
                "
              >
                No data found
              </h1>
            </div>
            <div
              class="
                text-gray-600
                font-medium
                flex flex-row
                items-center
                justify-center
                print:flex-col
              "
            ></div>
          </div>
        </div>
      </div>
    </transition>
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="invite">
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Invite your colleagues 🎉
                </h3>

                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Invite your colleagues to join your team so they can also
                    access this content, learn from it, and work with you to
                    improve gender equality!
                  </p>
                </div>

                <div class="mt-5">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                    First Name
                  </p>
                  <input
                    type="text"
                    class="
                      border-2 border-gray-200
                      mb-4
                      rounded-lg
                      px-3
                      py-2
                      text-sm
                    "
                    style="width: 100%"
                    placeholder="First Name"
                    v-model="object.firstname"
                  />
                </div>

                <div class="mt-5">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                    Last Name
                  </p>
                  <input
                    type="text"
                    class="
                      border-2 border-gray-200
                      mb-4
                      rounded-lg
                      px-3
                      py-2
                      text-sm
                    "
                    style="width: 100%"
                    placeholder="Last Name"
                    v-model="object.lastname"
                  />
                </div>

                <div class="mt-2">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                    Email
                  </p>
                  <input
                    type="text"
                    class="
                      border-2 border-gray-200
                      mb-4
                      rounded-lg
                      px-3
                      py-2
                      text-sm
                    "
                    style="width: 100%"
                    placeholder="john@example.com"
                    v-model="object.email"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                type="button"
                @click="create()"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-transparent
                  px-4
                  py-2
                  bg-green-600
                  text-base
                  leading-6
                  font-medium
                  text-white
                  shadow-sm
                  hover:bg-green-500
                  focus:outline-none
                  focus:border-green-700
                  focus:shadow-outline-green
                  transition
                  ease-in-out
                  duration-150
                  sm:text-sm sm:leading-5
                "
              >
                Invite that person!
              </button>
            </span>
            <span
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
              <button
                @click="invite = false"
                type="button"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-gray-300
                  px-4
                  py-2
                  bg-white
                  text-base
                  leading-6
                  font-medium
                  text-gray-700
                  shadow-sm
                  hover:text-gray-500
                  focus:outline-none
                  focus:border-blue-300
                  focus:shadow-outline-blue
                  transition
                  ease-in-out
                  duration-150
                  sm:text-sm sm:leading-5
                "
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import auth from "@/helpers/auth";
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";

export default {
  name: "subscriptionNew",
  data: function () {
    return {
      user: {},
      endofcreated: false,
      object: {
        email: "",
        firstname: "",
        lastname: "",
      },
      invite: false,
      organization: [],
      access_url: '',
    };
  },
  components: {
    sidebar,
    pageLoader,
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    this.organization = this.user.organization;
    // if (!this.user.access2x) {
    //   this.$router.push({ name: "settings" });
    // }
    // this.$store.commit("downloadable", false);
    this.$store.commit("setBreadcrumbs", [
      { name: "Settings", url: "/settings/account" },
      { name: "Subscriptions", url: "/settings/subscriptions" },
    ]);
    this.endofcreated = true;
  },
  methods: {
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    create: function () {
      const _self = this;
      axios
        .post(
          env.apiUrl + "/organizations/" + this.organization._id + "/members",
          this.object,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          if (res.data.user) {
            _self.invite = false;
            alert(res.data.message);
          } else if (res.data.error) {
            alert(res.data.error);
          }
        }).catch((err) => {
            this.invite = false;
            this.$swal.fire({
              icon: "error",
                html: `<span id="dynamicButton" style="color: rgb(0, 119, 200);">${err.response.data.message}</span>`,  

              text: err.response.data.message,
              showConfirmButton: true,
            });
            setTimeout(() => {
              const button = document.getElementById('dynamicButton')
              if (button) {
                button.addEventListener('click', this.redirecttoupgrade)
              }
            }, 100);
        });
    },
    redirecttoupgrade() {
      this.$swal.close()
      this.$router.push({ path: "/upgrade" });
    },
    add: function () {
      if (
        this.user &&
        this.user.organization &&
        this.user.organization.gesiRemainingUsers &&
        this.user.organization.gesiRemainingUsers == 0
      ) {
        window.open(env.apiUrl + env.productPages.gesi, "_blank");
      } else {
        this.object = {};
        this.invite = true;
      }
    },
    //open self serve portal
    async openSelfServePortal(){


      let _self = this
      _self.cbInstance = window.Chargebee.init({ site: env.chargebeeSite, publishableKey: env.chargebeeApikey });

        await this.cbInstance.setPortalSession(() => {
            // we have used axios for performing http requests
            // Hit your end point that returns portal session object as response
            // This sample end point will call the below api
            // https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session
            return this.getPortalSession();
        });

        _self.cbInstance.createChargebeePortal().open({
            loaded: function() {

            },
            close: function() {
              _self.$router.push({ name: "settings" });
              
            },
            paymentSourceAdd: function(status) {
              console.log("payment source add" + status);
            },
            paymentSourceUpdate: function(status) {
              console.log("payment source update" + status);
            },
            paymentSourceRemove: function(status) {
              console.log("payment source removed"+ status);
            }
          })

    },
    getPortalSession(){

    return new Promise(function(resolve){

    axios
      .get(
        env.apiUrl + "/subscriptions/get-portal-session",
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      )
      .then((res) => {

        resolve(res.data.data);
      });
    });
    },
  },
  async created() {
    this.openSelfServePortal();  
    // axios
      //   .get(
      //     env.apiUrl + "/subscriptions/get-portal-session",
      //     {
      //       headers: {
      //         Authorization: "Bearer " + Cookies.get("session"),
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     if (res.data) {
      //       // window.location.href = res.data.data.access_url;
      //       this.access_url = res.data.access_url;

      //     } else if (res.data.error) {
      //       alert(res.data.error);
      //     }
      //   });
    this.$store.commit("isFreeState", true);
  },
  directives: {
    filterDate: {
      update(el) {
        el.value = new Date(el.value).toLocaleString().split(",")[0];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.web-integration {
  display: none;
}
.EmailInvitemember {
  display: flex;
  // margin-top: 33px;
  .EmailInput {
    input {
      background: #fff;
      border: 0.9px solid #d0d0d0;
      padding: 10px 14px;
      border-radius: 4px;
      color: #1b1b1b;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
      height: 36px;
      margin-right: 10px;
      ::placeholder {
        color: #d0d0d0;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  .invite-tooltip {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;
      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }
  }
  .invite-tooltip .invite-tooltip-text {
    visibility: hidden;
    width: 200px;
    // right: 220px;
    margin: 5px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  .invite-tooltip:hover .invite-tooltip-text {
    visibility: visible;
  }
}
.my-profile-button {
  max-width: 95%;
}
.my-profile-button button {
  float: right;
  background: #fff;
  color: #1b1b1b;
  border-color: #201747;
  border: 1px solid #1b1b1b;
  height: 36px;
  color: #1b1b1b;
  border-radius: 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 9px 11px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.my-profile-button button:hover {
  background: #201747;
  color: #fff;
}
.pointer_event_none {
  pointer-events: none;
  height: 40px;
}
.selected-class {
  background-color: green;
}
/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    width: 40px;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 50%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 4px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #0097d1;
    box-shadow: inset 0 0 0 30px #0097d1;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
  /* Switch Sizes */
  .toggleSwitch.large {
    width: 70px;
    height: 30px;
    top: 5px;
  }
  .toggleSwitch.large a {
    width: 17px;
    height: 17px;
    top: 1.8px;
  }
  .toggleSwitch.large > span {
    height: 25px;
    line-height: 35px;
  }
  .toggleSwitch.large input:checked ~ a {
    left: 36px;
  }
  .toggleSwitch.large > span span {
    font-size: 1.1em;
  }
  .toggleSwitch.large > span span:first-of-type {
    left: 50%;
  }
}

/*  End Toggle Switch  */
</style>