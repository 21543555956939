<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-left: 285px"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px"
      />
    </div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <h4
                    class="text-3xl tracking-tighter font-medium text-center print:text-4xl ml-5"
                  >
                    Send a request to an entity to complete a new or repeat 2X
                    Assessment
                  </h4>
                </div>
                <p
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center"
                >
                  Enter limitless number of emails to send a request to 1 or
                  more person to complete the assessment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <main style="grid-template-columns: none !important">
          <div style="width: 100%; text-align: center">
            <section class="w-full container mx-auto px-4 lg:px-0">
              <div class="flex flex-col ml-auto mt-auto w-8/12 lg:w-8/12">
                <div
                  class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-3 mt-1"
                >
                  <!-- <input-tag v-model="tags" :add-tag-on-keys=allowedKeys validate="email" style="width:500px;" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" placeholder="Enter Emails" ></input-tag> -->
                  <textarea
                    type="text"
                    v-model="tags"
                    class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-3 mt-1 multiple-email"
                    placeholder="Enter Emails"
                  ></textarea>
                  <div class="tooltip TooltipImgText">
                    <span class="tooltiptext">
                      To input more than one email, use a comma to separate each
                      email.<br />
                      For example: investore@example.com, investore2@example.com
                    </span>
                    <img
                      src="@/assets/images/help_white.svg"
                      alt="background-image"
                    />
                  </div>
                </div>

                <div
                  class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-3 mt-1"
                >
                  <div
                    @click="emailAssessmentReq()"
                    style="
                      background-color: #201747;
                      color: white !important;
                      cursor: pointer;
                    "
                    class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center"
                  >
                    <button class="text-white text-sm font-medium">
                      Email 2X Assessment Request
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";

export default {
  name: "newtwoxassesment",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      tags: "",
      requestAdminModal: false,
      sharedAssesmentData: [],
      allowedKeys: [188, 32, 9],
    };
  },
  components: {
    pageLoader,
    // InputTag
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },

    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    async emailAssessmentReq() {
      let validatemail = [];
      let emails = this.tags != "" ? this.tags.split(",") : [];

      validatemail = await emails.map((email) => this.validEmail(email));

      // let validate = this.validEmail(this.tags)
      if (validatemail.length == 0) {
        this.$swal.fire({
          icon: "error",
          text: "Please Enter Email Address!",
          showConfirmButton: true,
        });
      } else if (!validatemail.includes(false)) {
        this.$swal.fire({
          html: '<div class="flex flex-col item-center justify-center" style="align-items:center;"><div class="loader"></div><h1  class="text-2xl tracking-tighter font-semibold text-center mt-10"  style=" color: #2BD4C4 !important;"> Just a moment... </h1> <h1 class="text-md tracking-tighter font-medium text-center"  style=" color: #2BD4C4 !important;" > We are hacking equality. </h1> </div>',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
        let usersAry = emails;
        // usersAry.push(this.tags)
        let body = {
          sendEmails: usersAry,
          type: "invite",
        };
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData) {
          this.$swal.fire({
            icon: "success",
            text: "Success! Your invitation was sent!",
            showConfirmButton: true,
          });
          this.tags = "";
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Please try again!",
            showConfirmButton: true,
          });
        }
        this.userEmail = null;
      } else {
        this.$swal.fire({
          icon: "error",
          text: "Please Enter Valid Email Address!",
          showConfirmButton: true,
        });
      }
    },
    sendInviteToUser(body) {
      return new Promise((resolve) => {
        axios
          .post(env.apiUrl + "/assesment2x/send-survey", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },

    async closeRequestModal() {
      this.requestAdminModal = false;
    },

    checkAssesmentIsShared() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/assessment-count", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },

    async checkRequestPermission() {
      axios
        .get(env.apiUrl + "/assesment2x/share-request-to-admin", {
          params: {
            type: this.sharedAssesmentData.type,
            recordId: [this.sharedAssesmentData.assesmnetRecordIds],
          },
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    viewAssessmentPermission(type) {
      if (!this.user.twoXAssessment) {
        this.requestAdminModal = true;
      } else {
        this.$router.push({ path: "/2X-Ecosystem/New-2XAssessment/" + type });
      }
    },
  },
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "Request Assessment",
        url: "/2X-Ecosystem/Request-Assessment",
      },
    ]);
    this.endofcreated = true;
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    this.sharedAssesmentData = await this.checkAssesmentIsShared();
  },
};
</script>

<style scoped lang="scss">
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

span {
  cursor: pointer;
}
input {
  margin: 6px;
}
.Modal_Readmorestyle {
  padding: 30px 0 10px 35px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: left;
      line-height: 18px;
      color: #1b1b1b;
    }
    button {
      margin: 30px auto;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }
  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}
.multiple-email {
  width: 500px;
  border: 2px solid;
  border-radius: 4px;
  height: 50px;
  padding: 4px;
}
.TooltipImgText {
  display: flex;
  align-items: center;

  img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1b1b1b;
  }

  .tooltiptext {
    max-width: 400px;
    top: 20px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #290047;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 10px;
  // top: -160px;
}
.tooltip:hover {
  visibility: visible;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
