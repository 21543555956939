<template>
  <div class="static">
    <div style="height: 4rem"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <br />
                  <h1
                    class="text-5xl tracking-tighter font-semibold text-center print:text-7xl ml-5"
                  >
                    2X GLOBAL LIMITED
                  </h1>
                </div>
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <h1
                    class="text-5xl tracking-tighter font-semibold text-center print:text-7xl ml-5"
                  >
                    2X CERTIFICATION
                  </h1>
                </div>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                ></h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2X Global Limited (“<b>2X</b>”) are committed to protecting
                  the privacy and security of your personal data. This Privacy
                  Notice provides information on how we process your personal
                  data as part of the 2X Certification Service.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  This Privacy Notice applies to and sets out how we use the
                  personal data of our visitors to the 2X Certification Service.
                  It is important that you read these additional details so that
                  you are aware of how and why we are using the personal data we
                  are collecting, storing, and using about you.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  For the purposes of data protection laws, 2X is the controller
                  of the processing of your personal data that is described in
                  this Privacy Notice. This means that we decide why and how
                  your personal data is processed. It also means that we are
                  legally responsible to you for that processing.
                </p>
                <br />
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  Who are we?
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  "<b>2X</b>" is 2X Global Limited, a company registered in
                  England and Wales under company registration number 13697512.
                  Our registered office is at Crowe U.K. LLP, 55 Ludgate Hill,
                  London, England, EC4M 7JW.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Equilo is Equilo Inc. Our address is: 1658 N. Milwaukee Ave.
                  Suite 100-45, Chicago, IL 60647, USA.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  "<b>We</b>", "<b>our</b>" or "<b>us</b>" refers both to 2X and
                  Equilo.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  If you have any questions about this Privacy Notice, please
                  contact
                  <a href="mailto: certification@2xglobal.org" target="_blank">
                    certification@2xglobal.org</a
                  >.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  What are the processing roles of 2X?
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2X makes decisions regarding certain processing of your
                  personal data in respect of delivery as part of the delivery
                  of our 2X Certification Service to you.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We have responsibility for the day-to-day handling of your
                  personal data including:
                </p>
                <br />
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc; margin-left: 40px"
                >
                  <li>
                    acting as your main point of contact for any queries you may
                    have relating to this Privacy Notice;
                  </li>
                  <li>
                    responding to your requests when you exercise your data
                    protection rights (see further below regarding these
                    rights);
                  </li>
                  <li>
                    making sure it has the legal grounds to use your personal
                    data in the ways described in this notice;
                  </li>
                  <li>
                    making sure we have the legal grounds to use your personal
                    data in the ways described in this Privacy Notice; and
                  </li>
                  <li>
                    ensuring that the personal data it stores is secured and
                    retained in accordance with data protection laws.
                  </li>
                </ul>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  What personal data do we collect and how do we use it?
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  When you visit the 2X Certification Service we will collect
                  your name, organisation, country, email details, and password
                  in order to create an account for you and to deliver our
                  Certification services to you.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We will only send you marketing emails or contact you about
                  our services and other information we think might interest you
                  if you have explicitly opted in to receive such communications
                  from us. You have the right to withdraw your consent at any
                  time by [clicking the 'unsubscribe' link at the bottom of our
                  emails or contacting us at
                  <a href="mailto: certification@2xglobal.org" target="_blank">
                    certification@2xglobal.org</a
                  >.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We will process slightly different personal data about you
                  depending upon whether you represent a corporate entity or a
                  fund. The information processed for each category is set out
                  below.
                </p>
                <br />
                <li class="heading-li" style="list-style-type: none">
                  Corporates
                  <ul
                    class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                    style="list-style-type: disc; margin-left: 40px"
                  >
                    <li>
                      Contact name, title, email address, and phone number for
                      the organisation;
                    </li>
                    <li>
                      Contact name, title, email address, and phone number of
                      the person authorised to enter into agreements on behalf
                      of the organisation;
                    </li>
                    <li>Country of registration, countries of operations;</li>
                    <li>Documents verifying women’s shares;</li>
                    <li>Documents verifying founding by women;</li>
                    <li>
                      Documents verifying the number of women senior managers at
                      the organisation;
                    </li>
                    <li>
                      Documents verifying active women Board or Investment
                      Committee Members;
                    </li>
                    <li>Documents verifying number of women employees;</li>
                    <li>Details of supply chain management;</li>
                    <li>
                      Documents verifying racial or ethnic origin, health
                      information, sexual orientation;
                    </li>
                    <li>
                      The name, employer, and email address of anyone that
                      requested that you fill in the form;
                    </li>
                    <li>
                      Company logo and website (in the case of successful
                      certification), for entity to be added to the 2X Certified
                      entities directory.
                    </li>
                  </ul>
                </li>
                <br />
                <li class="heading-li" style="list-style-type: none">
                  Funds
                  <ul
                    class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                    style="list-style-type: disc; margin-left: 40px"
                  >
                    <li>
                      Contact name, title, email address, and phone number for
                      the organisation;
                    </li>
                    <li>
                      Contact name, title, email address, and phone number of
                      the person authorised to enter into agreements on behalf
                      of the organisation;
                    </li>
                    <li>Country of registration, countries of operations;</li>
                    <li>Documents verifying women’s shares;</li>
                    <li>Documents verifying founding by women;</li>
                    <li>
                      Documents verifying the number of women senior managers at
                      the organisation;
                    </li>
                    <li>
                      Documents verifying active women Board or Investment
                      Committee Members;
                    </li>
                    <li>Documents verifying number of women employees;</li>
                    <li>Details of supply chain management;</li>
                    <li>
                      Documents verifying racial or ethnic origin, health
                      information, sexual orientation;
                    </li>
                    <li>
                      The name, employer, and email address of anyone that
                      requested that you fill in the form;
                    </li>
                    <li>
                      Company logo and website (in the case of successful
                      certification), for entity to be added to the 2X Certified
                      entities directory.
                    </li>
                    <li>
                      company logo and website (in the case of successful
                      certification), for entity to be added to the 2X Certified
                      entities directory.
                    </li>
                  </ul>
                </li>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  Processing your personal data
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  The purpose of processing your personal data is to perform our
                  evaluation, verification, and certification services as part
                  of the 2X Certification Service. We process this information
                  on the basis of our legitimate interests in operating the 2X
                  Certification Service to increase credibility, provide global
                  peer benchmarking, promote transparency and accountability
                  within the gender finance ecosystem.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We process special category data on the basis of our
                  legitimate interests and for reasons of substantial public
                  interest on the basis in law particularly the Data Protection
                  Act 2018 Schedule 1 paragraph 9 in relation to racial and
                  ethnic diversity at senior levels.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  During the verification process, some documents which may
                  contain personal data are processed using machine learning
                  techniques.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  How we share your personal data
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We may share your personal data between 2X and third-party
                  organisations:
                </p>
                <br />
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc; margin-left: 40px"
                >
                  <li>Equilo, our technology partner;</li>
                  <li>third party verifiers; and</li>
                  <li>
                    other potential investors, including funds, grant makers,
                    and other third-party technical advisory service providers
                    supporting investors, funds, grant makers.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We may also share your personal data with third parties who
                  provide services to us to the extent that it is necessary for
                  us to do so.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  How long we keep your personal data
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We will store your data for no longer than six years.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  For re-certification, the six-year retention period will
                  restart from the date of such re-certification. This is to
                  ensure that information from the original certification is
                  available to support the re-certification process. After this
                  time period, we will only store anonymised data for the
                  purposes of research and learning that will contribute to
                  field building of gender lens investment.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  How we transfer your personal data outside Europe
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2X is an English company, headquartered in the United Kingdom.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Where necessary, we may transfer personal data outside of the
                  UK. When doing so, we comply with the UK GDPR, making sure
                  appropriate safeguards are in place.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  In the case of transfers out of the UK subject to UK data
                  protection law, the UK government has decided that particular
                  countries ensure an adequate level of protection of personal
                  data (known as an ‘adequacy regulation’). Where a country
                  benefits from an adequacy regulation, then we will rely on it
                  for transfers from the UK to that country. Where a country
                  does not, then we will rely on legally-approved standard data
                  protection clauses. If we cannot or choose not to continue to
                  rely on either of those mechanisms at any time, we will not
                  transfer your personal data outside the UK unless we can do so
                  on the basis of an alternative mechanism or exception provided
                  by UK data protection law.
                </p>

                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  <br />
                  <h1
                    class="text-3xl tracking-tighter font-medium print:text-7xl"
                  >
                    Automated decisions we take about you
                  </h1>
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We do not make automated decisions using your personal data.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  Your rights
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  You have a number of rights over your personal data, which
                  are:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc; margin-left: 40px"
                >
                  <li>
                    The right to ask us what personal data about you we are
                    holding and to have access to a copy of your personal data;
                  </li>
                  <li>
                    The right to ask us to correct any errors in your personal
                    data;
                  </li>
                  <li>
                    The right, in certain circumstances such as where our use of
                    your personal data is based on your consent and we have no
                    other legal basis to use your personal data, to ask us to
                    delete your personal data;
                  </li>
                  <li>
                    The right, in certain circumstances such as where we no
                    longer need your personal data, to request that we restrict
                    the use that we are making of your personal data;
                  </li>
                  <li>
                    The right, in certain circumstances, to ask us to review and
                    explain our legitimate interests to you; and
                  </li>
                  <li>
                    The right, where our use of your personal data is carried
                    out for the purposes of an agreement with us and is carried
                    out by automated means, to ask us to provide you with a copy
                    of your personal data in a structured, commonly-used,
                    machine-readable format.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Providing your personal data to 2X is necessary as part of the
                  2X Certification Service. Failure to provide such data will
                  impair the ability of 2X to perform the 2X Certification
                  Service.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  How to complain
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  If you have any concerns about our use of your personal data,
                  you can make a complaint to us using the contact details at
                  the top of this Privacy Notice.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  If you remain unhappy with how we’ve used your data after
                  raising a complaint with us, you can also complain to the ICO.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  The ICO’s address:
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Information Commissioner’s Office
                </p>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Wycliffe House
                </p>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Water Lane
                </p>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Wilmslow
                </p>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Cheshire
                </p>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  SK9 5AF
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Helpline number: 0303 123 1113
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Website: https://www.ico.org.uk/make-a-complaint
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  Changes to this Privacy Notice
                </h1>
                <br />
                <p
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium"
                  style="margin-bottom: 2rem"
                >
                  We keep our Privacy Notice under regular review and will
                  update it from time to time to make sure it remains up-to-date
                  and accurate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "CertificationPrivacyNotice",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      userEmail: null,
      isReadMore: false,
    };
  },
  components: {
    pageLoader,
  },
  methods: {},
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "2X Certification Privacy Notice",
        url: "/2X-Certification-Privacy-Notice",
      },
    ]);
    this.endofcreated = true;
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
  },
};
</script>

<style scoped>
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}
</style>
<style scoped>
.heading-li {
  font-size: 20px;
  font-weight: 700;
}

li {
  font-size: 16px;
}

a {
  color: #40a9ff;
}
</style>
