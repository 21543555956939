<template>
  <div>
    <div class="flex items-center gap-8">
      <router-link v-for="(item, index) in filteredTwoxGridList" :key="index"
                   :to="item.route" class="px-6 rounded-lg shadow-sm border border-gray-200 font-medium h-32 w-full flex items-center max-w-sm">
        <div class="text-center">
          <img :src="getImgUrl(item.icon)" alt="" class="h-6 mr-3"/>
          {{ item.name }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "twoxGlobalGridList",
  computed: {
    filteredTwoxGridList() {
      const baseList = [
        {
          name: '2X-Certification',
          route: '/2x-global/2X-Certification',
          icon: 'graduation',
          isAccess: this.$store.state.user.hasOwnProperty("twoXGlobal") && this.$store.state.user.twoXGlobal.includes('2XCertification')
        },
        {
          name: '2X Challenge',
          route: '/2x-global/2X-Challenge',
          icon: '2xglobal',
          isAccess: this.$store.state.user.hasOwnProperty("twoXGlobal") && this.$store.state.user.twoXGlobal.includes('2XChallenge')
        }
      ];
      return baseList.filter(item => item.isAccess === true);
    }
  },
  methods: {
    getImgUrl(icon) {
      if (icon) {
        try {
          return require(`../../assets/images/dashboard/${icon}.svg`);
        } catch (e) {
          console.error(`Failed to load icon: ${icon}`, e);
        }
      }
      return 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect width="100%" height="100%" fill="%23cccccc"/><text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" font-family="Arial" font-size="12" fill="%23000">Icon</text></svg>';
    },
  }
};
</script>
