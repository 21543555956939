<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 pt-24">
    <div>
    <!-- <EquiloModal
      :isVisible="showModal"
      :is-draggable="false"
      :is-resizable="false"
      :custom-transition="true"
      enter-active-class="transition-opacity duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      :prevent-backdrop-click="true"
      :is-scrollable="false"
      :nested-level="0"
      title="Invite a 2X Global"
      size="3xl"
      maxHeight="80vh"
      position="center"
      overlayColor="gray-800"
      rounded="xl"
      padding="lg"
      gridLayout=""
      :showFooter="false"
      @close="showModal = false"
      @confirm="handleConfirm"
    >
    </EquiloModal> -->
    </div>
    <div class="flex justify-between items-center">
      <h1>
      <span class="mt-1 block text-2xl tracking-tight font-extrabold">
        <span class="block text-[#191b45]">Welcome to the 2X Global Portal!</span>
      </span>
    </h1>
    <div>
      <span class="text-gray-700 font-medium mr-2">Powered by:</span>
      <img class="h-8" src="@/assets/images/logo-navy.png" alt="background-image" />
    </div>
    </div>
  <!-- Grid List -->
    <h3 class="mt-8 mb-4">
      <span class="mt-1 block text-lg tracking-tight font-medium">
        <span class="block text-[#191b45] text-base">As a user of the 2X Global Portal, you can seamlessly navigate between the 2X Certification and 2X Challenge areas. Choose 2X Certification to review the results of all submitted 2X Certification Self-Assessments, track verification statuses, receive notifications from verifiers, and approve/deny certification levels/badges. Select 2X Challenge to review and approve investor disclosures and deal submissions.</span>
      </span>
    </h3>
    <towxGlobalGridList />
    <twoxglobalInvite @form-submitted="handleFormSubmission" class="mt-6"  />
    <!-- <div class="flex items-center justify-between pt-1 mt-6">
      <button @click="showModal = true" class="px-4 py-2 bg-[#191b45] text-white rounded-md mt-4">
      Invite
    </button>
    <EquiloEmailInviteForm :portal="'TwoX Global'" />
    </div> -->
  </div>
</template>

<script>
import twoxglobalInvite from '@/components/2x-Global/twoxglobalInvite.vue';
import towxGlobalGridList from '@/components/2x-Global/towxGlobalGridList.vue';
import Cookies from "@/helpers/cookies";
import env from "@/../env";

export default {

  name: "twoxGlobalHomePage",
  components : {
    twoxglobalInvite,
    towxGlobalGridList,
  },
  data() {
    return {
      showModal: false
    }
  },
  async mounted() {
    this.$store.commit("setBreadcrumbs", [
            { name: "2X Global", url: "/2x-global/Home" },
      ]);
  },
  methods: {
    async handleFormSubmission(formData) {
      if(formData){
        try {
          const response = await fetch(`${env.apiUrl}/certify2X/2XGlobal/invite2XGlobalMember`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${Cookies.get("session")}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
          const data = await response.json();
          if (!response.ok) {
            this.$swal.fire({
                icon: "error",
                text: `${data.message ? data.message : "Something went wrong!"}`,
                showConfirmButton: true,
          });
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          if (response.ok) {
            this.$swal.fire({
                icon: "success",
                text: data?.data,
                showConfirmButton: true,
          });
          }
        } catch (error) {
          if (error instanceof TypeError) {
            console.error('Network error:', error.message);
          } else if (error instanceof SyntaxError) {
            console.error('Invalid JSON:', error.message);
          } else {
            console.error('Error:', error.message);
          }
          this.$emit('error', error.message);
        }
      }
    },
    handleConfirm() {
      console.log('Confirmed')
      this.showModal = false
    }
  },
}
</script>

<style></style>
