<template>
  <v-card class="text-card">
    <v-card-text>
      <p class="body-text">
      Equilo uses the TAAP framework, which stands for Transforming Agency, Access, and Power.
      Comprised of six domains, the TAAP framework provides a structure to analyze and amplify the
      voices, opportunities, and dignity of all people, especially those from socially excluded
      groups. More information about this approach can be found at taapinclusion.org. <br><br>
      Equilo Dash uses 15 themes that cut across two or more of the TAAP domains, for a total of 32 unique TAAP
      domain/theme intersections for analysis and scoring. Each gender equality score is based on a
      specific set of indicators within a given intersection.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "methodology",
}
</script>

<style scoped>

.body-text{ 
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  padding-top: 1rem; 
  padding-bottom: 1rem;
  padding-right: 1rem; 
  padding-left: 1rem;
  width: auto;
  background-color: #290047;
  font-family: "Montserrat", arial;
  font-size: medium;
  letter-spacing: normal;
}
.text-card {
  max-width: 100px;
  display: relative;
}
</style>



