import { render, staticRenderFns } from "./statement-comments-modal.vue?vue&type=template&id=d6ea61c4&scoped=true"
import script from "./statement-comments-modal.vue?vue&type=script&lang=js"
export * from "./statement-comments-modal.vue?vue&type=script&lang=js"
import style0 from "./statement-comments-modal.vue?vue&type=style&index=0&id=d6ea61c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6ea61c4",
  null
  
)

export default component.exports