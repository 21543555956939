<template>
    <div>
        <button @click="logout()"
            class="cursor-pointer w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 hover:text-indigo-900 transition duration-200">
            Logout
        </button>
    </div>
</template>
<script>
import axios from "axios";
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
export default {
    name: "logoutPage",
    data: function () {
        return {}
    },
    mounted() {
        // this.logout();
    },
    methods: {
        logout: function () {
            localStorage.removeItem('previousURL');
            localStorage.removeItem('lastActive');
            let sessionID = Cookies.get("session")
            let deviceID = localStorage.getItem("deviceToken")
            return axios.post(
                env.apiUrl + "/users/me/logout",
                { sessionId: sessionID, deviceId: deviceID },
                {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("session"),
                    },
                }
            ).then(() => {
                if (!localStorage.getItem("isLogout")) {
                    localStorage.setItem("isLogout", true);
                }
                this.$store.commit("logout");

            })
        },
    }
}
</script>