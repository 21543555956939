<template>
  <div class="static mt-20">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!(gbvrisk.selected_country && country._id)" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="(gbvrisk.selected_country && country._id && gbvrisk.selected_country._id)" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <img
                    v-if="dispalyFlagAndOtherInfo"
                    class="mr-5 h-16 print:h-auto print:w-64"
                    :src="country.flagUrl"
                  />
                  <h1
                    class="text-5xl tracking-tighter font-medium text-center print:text-7xl"
                  v-if="current_step == 0">
                     GBV Risk Score
                  </h1>
                  <h1
                    class="text-5xl tracking-tighter font-medium text-center print:text-7xl"
                  v-else>
                     GBV Risk {{ activeHeadName }}
                  </h1>
                </div>
                <p
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center"
                  v-if="dispalyFlagAndOtherInfo"
                >
                  Region: {{ country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' :country.region  }} | Income Group:
                  {{ toTitle(country.income || "") }}
                  <span v-if="sector && sector.id"
                    >| Sector: {{ sector.name }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container mx-auto">
          <div class="flex flex-col mx-auto w-11/12 mt-12 mb-16">
            <a-steps progress-dot :current="this.current_step" size="small" @change="progressClick">
              <a-step title="Home"/>
              <a-step title="Risk score"/>
              <a-step title="Indicators" />
              <a-step title="Take action" />
            </a-steps>
          </div>
        </div>
        
                
        <div class="flex flex-col items-center justify-center w-full" v-scroll-reveal>
          <div id="GBVIndex" class="maincontent"><span style="visibility: hidden;">GBVIndex1</span></div>
          <section
            class="w-full container mx-auto px-4 lg:px-0 mb-5"
            style="max-width: 960px"
          >
            
             <h1
              class="print:text-6xl tracking-tighter font-medium flex flex-row items-center justify-center mb-3"
              style="font-size: 80px;"
              
            >
              <div class="flex items-center justify-center p-1 h-20 w-20 mr-3">
                <img v-if="risk_level == 'Extreme Risk'" class="h-20" src="../../assets/images/GBV-red.png" />
                <img v-if="risk_level == 'Severe Risk'" class="h-20" src="../../assets/images/GBV-orange.png" />
                <img v-if="risk_level == 'Significant Risk'" class="h-20" src="../../assets/images/GBV-light-orange.png" />
                <img v-if="risk_level == 'Ubiquitous Risk'" class="h-20" src="../../assets/images/GBV-yellow.png" />
              </div>

              <div v-if="risk_level == 'Extreme Risk'" style="color:#ED0C04;" class="risk-score">Extreme Risk</div>
              <div v-if="risk_level == 'Severe Risk'" style="color:#FE5000;" class="">Severe Risk</div>
              <div v-if="risk_level == 'Significant Risk'" style="color:#EDA804;" class="risk-score">Significant Risk</div>
              <div v-if="risk_level == 'Ubiquitous Risk'" style="color:#FAE053;" class="risk-score">Ubiquitous Risk</div>

            </h1>

           <div class="text-gray-600 gap-5 text-center font-medium text-lg print:leading-relaxed print:text-sm leading-5 custom-mb-3">
              <div v-if="risk_level == 'Extreme Risk'" style="color:#ED0C04; font-size: 30px;" class="risk-score custom-display-flex"><span class="gbvrisk-report-country" >{{ country.name }} </span> <span class="gbvrisk-report-country-wise-score custom-pt-14" style="background-color:#ED0C04;">{{Math.round(gbvrisk.selected_country.value)}}</span></div>
              <div v-if="risk_level == 'Severe Risk'" style="color:#FE5000; font-size: 30px;" class="risk-score custom-display-flex"><span class="gbvrisk-report-country">{{ country.name }} </span> <span class="gbvrisk-report-country-wise-score custom-pt-14" style="background-color:#FE5000;">{{Math.round(gbvrisk.selected_country.value)}}</span></div>
              <div v-if="risk_level == 'Significant Risk'" style="color:#EDA804; font-size: 30px;" class="risk-score custom-display-flex"><span class="gbvrisk-report-country">{{ country.name }} </span> <span class="gbvrisk-report-country-wise-score custom-pt-14" style="background-color:#EDA804;">{{Math.round(gbvrisk.selected_country.value)}}</span></div>
              <div v-if="risk_level == 'Ubiquitous Risk'" style="color:#FAE053; font-size: 30px;" class="risk-score custom-display-flex"><span class="gbvrisk-report-country">{{ country.name }} </span> <span class="gbvrisk-report-country-wise-score custom-pt-14" style="background-color:#FAE053;">{{Math.round(gbvrisk.selected_country.value)}}</span></div>
            </div>
            <div class="mb-10 text-gray-600" style="font-size: 15px;">
              <div class="flex items-center justify-center mx-auto mb-1">
                <img class="h-8 mr-2" src="../../assets/images/GBV-yellow.png" />
                <span>Ubiquitous Risk (&#60;20)</span>
                <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-light-orange.png" />
                <span>Significant Risk (20-39)</span>
                <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-orange.png" />
                <span>Severe Risk (40-59)</span>
                <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-red.png" />
                <span>Extreme Risk (60+)</span>
              </div>
              
            </div>
            <p class="text-gray-600 gap-5 text-center font-medium text-xs leading-5 mb-10" style="line-height: 1.5">
              <em>The GBV Risk Score is calculated specifically for {{country.name}}. 
                The <b>confidence score is {{(Math.round(gbvrisk.selected_country.confidence*100)/100).toFixed(2)}}</b> and <b>power score is {{(Math.round(gbvrisk.selected_country.power*100)/100).toFixed(2)}}</b> for this GBV Risk Score. <br>
                The Power Score tells us, out of all possible indicators, the data available for that country, while the Confidence Score tells us, out of all available indicators, the extent to which the data utilizes recent, standardized international sources (Read more about the <router-link :to="'/reports/' + this.$route.params.iso2 +'/'+this.$route.params.sector+ '/gbv-risk/methodology'" style='color: #201747'>Methodology here</router-link>).
                The sections below are not country-specific, but provide information that is relevant to understanding GBV risk.</em>
            </p>
            <hr/>

          </section>
          <section class="w-12/12 container mx-auto px-4 mb-20 lg:px-0">
            <div class="flex flex-row grid grid-cols-3 gap-4 gap-y-10" v-if="this.gbvrisk.text">
              <template v-if="$route.params.iso2 == 'EQ'">
                  <home-card title="GBV Risk Score in Equiloland" :text="formatIntroBlurb(this.gbvrisk.text.intro_blurb)" 
                    :icon="'https://api.equilo.io/ICONS/Theme icons/gender-based-violence.svg'"
                    url="risk-score" :propsCountry="country"  v-scroll-reveal></home-card>
                  <home-card title="GBV Risk Indicators in Equiloland" :text="formatIntroBlurb(this.gbvrisk.text.indicators_blurb)" 
                    :icon="'https://api.equilo.io/ICONS/Theme icons/workforce.svg'"
                    url="indicators" :propsCountry="country" v-scroll-reveal></home-card>  
                  <home-card title="Take Action" :text="this.gbvrisk.text.whatworks_blurb" 
                    :icon="'https://api.equilo.io/ICONS/Theme icons/political-leadership.svg'"
                    url="take-action" :propsCountry="country" v-scroll-reveal></home-card>
              </template>
              <template v-else>
                  <home-card :title="(sector && sector.name != undefined) ? 'GBV Risk Score in '+ country.humanName +' in '+ sector.name : 'GBV Risk Score in '+country.humanName" :text="formatIntroBlurb(this.gbvrisk.text.intro_blurb)" 
                  :icon="'https://api.equilo.io/ICONS/Theme icons/gender-based-violence.svg'"
                  url="risk-score" :propsCountry="country"  v-scroll-reveal></home-card>
                <home-card :title="(sector && sector.name != undefined) ? 'GBV Risk Indicators in '+ country.humanName +' in '+ sector.name : 'GBV Risk Indicators in '+country.humanName" :text="formatIntroBlurb(this.gbvrisk.text.indicators_blurb)" 
                  :icon="'https://api.equilo.io/ICONS/Theme icons/workforce.svg'"
                  url="indicators" :propsCountry="country" v-scroll-reveal></home-card>  
                <home-card title="Take Action" :text="this.gbvrisk.text.whatworks_blurb" 
                  :icon="'https://api.equilo.io/ICONS/Theme icons/political-leadership.svg'"
                  url="take-action" :propsCountry="country" v-scroll-reveal></home-card>
              </template>
            </div>
          </section>


          <section
            class="w-full container mx-auto mt-20 mb-5 px-4 lg:px-0"
            style="max-width: 960px"
            v-scroll-reveal
          >
            <div class="flex flex-row mt-10 items-center justify-center">
              <div class="flex flex-col items-center justify-center p-3 bg-white">
                <a href="https://www.equilo.io/" target="_blank">
                  <img class="w-64" src="@/assets/images/logo-navy.png" />
                </a>
              </div>
              <div class="flex flex-col items-center justify-center p-3 mx-5 bg-white">
                <a href="https://criterioninstitute.org/" target="_blank">
                  <img class="w-64" src="@/assets/images/criterion.png" />
                </a>
              </div>
              <div class="flex flex-col items-center justify-center p-3 bg-white">
                <a href="https://www.unicef.org/" target="_blank">
                  <img class="w-56" src="@/assets/images/UNICEF_logo.png" />
                </a>
              </div>
            </div>
            <div class="flex flex-row mt-10 items-center justify-center">
              <p v-if="country.ISO2 == 'EQ'" class="text-gray-600 font-medium text-center"><em>The GBV Risk Score methodology was co-created by Equilo and Criterion Institute with support from UNICEF. The methodology, indicators, and information regarding materiality of GBV Risk can be found here in this open-source resource. To access the GBV Risk Score advanced analytics tool to receive country-specific, analyzed data sets and risk score, downloadable raw data sets, global heat maps, and ideas for action. </em></p>   

              <p v-else class="text-gray-600 font-medium text-center"><em>The GBV Risk Score methodology was co-created by Equilo and Criterion Institute with support from UNICEF.</em></p>
              
            </div>
          </section>

          <section
            class="w-full container mx-auto mb-32 px-4 lg:px-0"
            style="max-width: 960px"
          >
            <div class="flex flex-col mt-10 justify-start">
              <p class="text-xs text-gray-500">
                Data Use and Citation: Equilo grants full permission to its users to copy and paste information that appears in the dashboard for their own internal use, directly citing the original data sources and citing Equilo for analysis and scoring. Please request permission from Equilo before utilizing our content for external publications.
<br/><br/>
Equilo uses the TAAP framework, which stands for Transforming Agency, Access, and Power. Comprised of six domains, the TAAP framework provides a structure to analyze and amplify the voices, opportunities, and dignity of all people, especially those from socially excluded groups. The GBV Risk Score utilities five of these domains as sub-indices. More information about this approach can be found at taapinclusion.org. 
              </p>
              
              
            </div>
          </section>
          </div>
        
      </div>
      <div v-else>
          <div class="flex flex-row justify-center items-center mt-3 ml-20">
            <div class="flex flex-col item-center justify-center">
              <div
                class="flex flex-row items-center justify-center print:flex-col"
              >
                <!-- <img class="h-16 print:h-auto print:w-64"  src="@/assets/images/2X-assessment/My2XAssessments3b.png" alt="" /> -->
                <h1
                  class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
                >
                  No Data Found
                </h1>
              </div>
            </div>
          </div>
        </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env.js";
//import item from "@/components/text.vue";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
import homeCard from "@/components/gbv-home-card.vue";

export default {
  name: "gbv-risk",
  data: function() {
    return {
      country: {},
      theme: {},
      gbvrisk: {},
      print: false,
      panel: false,
      chartLabels: {},
      openPanels: [],
      indicators: [],

      risk_level: "",
      current_step: 0,
      env,
      taabColors: {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
      },
      appearItems: [],
      sector: { name: "" },
    };
  },
  components: {
    //item,
    //roundedItem,

    pageLoader,
    homeCard,

  },
  methods: {
    // We go for an array so we can have multiple panels open at the same time (eg. download PDF)
    togglePanel: function(panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
    moreInfo: function () {
      this.$router.push({
        path: "/reports/" + this.country.ISO2 +'/'+this.$route.params.sector+ "/themes/" + this.intersection.theme
      });
    },
    toFixed: function(n, x) {
      if (n == "Missing") {
        return "Missing";
      } else {
        return n.toFixed(x || 2);
      }
    },
    getPercentage(number) {
      return Math.round((number * 100) / 2);
    },
    value: function(input) {
      return this.format("{{" + input + "}}");
    },
    opacity: function(hex) {
      if (!hex) {
        return "#black";
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    getCountry: function() {
      const _self = this;
      return new Promise(function(resolve) { 
        axios
          .get(
            env.apiUrl +
              "/countries/" +
              _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
    getTheme: function() {
      const _self = this;
      return new Promise(function(resolve) {
        axios
          .get(
            env.apiUrl +
              "/reports/" +
              _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector +
              "/themes/Gender-Based%20Violence",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data);
          });
      });
    },
    getGBVRisk: function() {
      const _self = this;
      return new Promise(function(resolve) {
        axios
          .get(
            env.apiUrl +
              "/reports/" +
              _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector +
              "/gbvrisk",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
    scrollTo(id) {
        //document.getElementById(id).scrollIntoView();
      
        const element = document.getElementById(id);
        const offset = -100;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
        
    },
    toTitle(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    show(el) {
      if (this.appearItems.indexOf(el.id) < 0) {
        this.appearItems.push(el.id);
      }
    },
    getSector: function() {
      const _self = this;
      return new Promise(function(resolve) {
        // Validate if you want general country information (count/base) or a specific sector
        if (["BASE", "country"].indexOf(_self.$route.params.sector) > -1) {
          resolve(false);
        } else {
          axios
            .get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso : _self.$route.params.iso2 }
            })
            .then(function(res) {
              resolve(res.data.data);
            });
        }
      });
    },
    indicatorColor(taab) {
      if (taab === "Access to Resources") {
        return env.taabColors.accessToResources;
      } else if (taab === "Law & Policy") {
        return env.taabColors.lawAndPolicy;
      } else if (taab === "Power & Decision-Making") {
        return env.taabColors.powerAndDecisio;
      } else if (taab === "Knowledge & Beliefs") {
        return env.taabColors.knowledgeAndBeliefs;
      } else if (taab === "Human Dignity") {
        return env.taabColors.humanDignity;
      } else if (taab === "Roles & Responsibilities") {
        return env.taabColors.rolesAndResponsibilities;
      }
      return true;
    },
    async progressClick(step) {
      this.current_step = step;
      
      if (this.current_step == 1) {
        this.$router.push({path: "/gbv-risk/" + this.$route.params.iso2 +'/'+this.$route.params.sector+ "/risk-score"});
      } else if (this.current_step == 2) {
        this.$router.push({path: "/gbv-risk/" + this.$route.params.iso2 +'/'+this.$route.params.sector+ "/indicators"});
      } else if (this.current_step == 3) {
          this.$router.push({path: "/gbv-risk/" + this.$route.params.iso2 +'/'+this.$route.params.sector+ "/take-action"});
      }
    },
    formatIntroBlurb(text) {
      return text.replace("{{COUNTRY}}", this.country.humanName);
    }
  },
  computed: {
    citations() {
      const compare = (a, b) => {
        if (a.rank < b.rank) {
          return -1;
        }
        if (a.rank > b.rank) {
          return 1;
        }
        return 0;
      };
      const x = this.$store.state.citations;
      return x.sort(compare);
    },
    myStyles() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      };
    },
    myStyles3Cases() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        marginBottom: "-100px",
        justifyContent: "center",
      };
    },
    myStylesLine() {
      return {
        height: "600px",
        position: "relative",
        width: "50%",
        display: "flex",
        justifyContent: "center",
      };
    },
    
    activeHeadName(){
      let headName = '';
      if(this.$route.params.iso2 == 'EQ'){
        headName = "in " + this.country.humanName
      }else{
        headName = (this.sector && this.sector.name != undefined) ? "in " + this.country.humanName+" in "+ this.sector.name : "in " + this.country.humanName ;
      }
      return headName;
    },
    
    dispalyFlagAndOtherInfo(){
      let showFlagAndInfo = true;
      return showFlagAndInfo;
    }
  },
  async created() {
    this.$store.commit("downloadable", false);
    this.$store.commit("resetCitations");

    this.country = await this.getCountry(); // lets do an await before rendering other items.
    this.sector = await this.getSector(); // lets do an await before rendering other items.
    this.gbvrisk = await this.getGBVRisk(); // lets do an await before rendering other items.

    // validate if you have access to this country.
    this.user = await auth.me().catch(() => {});
    
    // RISK CATEGORIES
    if (Math.round(this.gbvrisk.selected_country.value) < 20) {
      this.risk_level = "Ubiquitous Risk";
    } else if ((Math.round(this.gbvrisk.selected_country.value) >= 20) & (Math.round(this.gbvrisk.selected_country.value) < 40)) {
      this.risk_level = "Significant Risk";
    } else if ((Math.round(this.gbvrisk.selected_country.value) >= 40) & (Math.round(this.gbvrisk.selected_country.value) < 60)) {
      this.risk_level = "Severe Risk";
    } else if (Math.round(this.gbvrisk.selected_country.value) >= 60) {
      this.risk_level = "Extreme Risk";
    }

    this.chartLabels[this.country.humanName] = {
      color: env.geoColors.countryspider,
      font: env.geoColors.countryspider,
      background: this.opacity(env.geoColors.countryspider),
    };
    this.chartLabels[this.country.region] = {
      color: env.geoColors.regionspider,
      font: env.geoColors.regionspider,
      background: this.opacity(env.geoColors.regionspider),
    };
    this.chartLabels[this.country.income] = {
      color: env.geoColors.incomespider,
      font: env.geoColors.incomespider,
      background: this.opacity(env.geoColors.incomespider),
    };
    this.chartLabels["LMIC"] = {
      color: env.geoColors.lmicspider,
      font: env.geoColors.lmicspider,
      background: this.opacity(env.geoColors.lmicspider),
    };
    this.chartLabels["Perfect Equality"] = {
      color: "#3F37C9",
      font: "#3F37C9",
      background: "#3F37C9",
    };

    if (document.location.href.indexOf("print=true") > -1) {
      this.print = true;
    }
    this.$store.commit("downloadable", false);
    
    if (this.$route.params.iso2 == "EQ") {
       this.$store.commit("setBreadcrumbs", [
      
        {
          name: "Home-Demo",
          url:"/gbv-risk/EQ/INFR_GENERL/home",
        },
    ]);

    } else {
      let breadCrumbName = [
        {
          name: this.country.humanName,
          url: "/gbv-risk/choose-country/true",
        },
        {
            name: this.sector.name,
            url: "/gbv-risk/" + this.$route.params.iso2 + "/choose-sector/true",
        },
        {
          name: "Home",
          url:
            "/gbv-risk/" +
            this.country.ISO2 +
            "/" +
            this.$route.params.sector +
            "/home",
        },
      ]
      if(!this.sector){
          breadCrumbName = breadCrumbName.filter(function(e,index) {
            return index !== 2
          })
      }

      this.$store.commit("setBreadcrumbs", breadCrumbName);
    }

  },updated() {
      this.activeHeadName;
      this.dispalyFlagAndOtherInfo;
  },
};
</script>
<style>


.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
    .slide-fade-leave-to

        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateY(10px);
  opacity: 0;
}

@media print {
  .radarchart {
    max-height: 400px !important;
  }
}
#buttonContainer {
  text-align: center;
}

#sideNav {
  position: fixed;
  margin-top: 300px;
  left: -65px;
  border-radius: 0 5px 5px 0;
}

#sideNav button {
  background-color: #201747;
  padding: 8px;
  width: 120px;
  height: 38px;
  font-size: 10px;
  color: #201747;
}

#sideNav button:hover {
  background-color: #e2e8f0;
  color: #201747;
}

.navigateButton {
  color: red;
  border: 2px solid red;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.hoverTrigger .hoverMethodology {
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 50;
  width: 400px;
}

.hoverTrigger {
  position: relative;
  display: inline-block;
}

.triggerElement {
  border-bottom: 1px dotted black;
}

.hoverTrigger:hover .hoverMethodology {
  visibility: visible;
  width: 550px;
}
.margintop1{
    margin-top: 1em;
}
.margintop2{
    margin-top: 2em;
}
.margintop3{
    margin-top: 3em;
}
.custom-mb-3{
    margin-bottom: 4em;
}
/* Enable smooth scrolling */
html {
  scroll-behavior: smooth;
}
.gbvrisk-report-country{
    color: #201747 !important;
    font-size: 50px;
    padding-right: 20px;
}
.gbvrisk-report-country-wise-score{
    background-color: #fe5000;
    padding: 13px 11px;
    border-radius: 50%;
    color: #fff;
    font-size: 26px;
    font-weight: 700;
}
.custom-display-flex {
  align-items: center;
  display: flex;
  justify-content: center;
}
.custom-pt-14 {
  padding-top:14px;
}
</style>
