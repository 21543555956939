<template>
  <div class="static mt-40">
    <div id="notLoaded" key="loading" v-if="!loaded">
      <page-loader></page-loader>
    </div>
    <!-- Popup -->
    <div class="fixed z-10 inset-0 overflow-y-auto" v-show="modalVisible">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="absolute top-0 left-0 w-full h-full"
            style="z-index: 9; background-color: rgba(255, 255, 255, 0.6)"
            v-if="stepLoading"
          >
            <clip-loader
              :color="env.brandingColors.teal"
              :size="'60px'"
              class="absolute top-50 left-50"
              style="transform: translate(-50%, -50%)"
            ></clip-loader>
          </div>
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-12 sm:pb-6 overflow-y-auto max-h-screen-70"
          >
            <div class="block">
              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepAddon"
              >
                <div class="popup-plan mb-12" v-if="!popupPlan.is_subscribe">
                  <div class="flex gap-5 items-center">
                    <h3 class="text-2xl leading-6 font-bold text-gray-900">
                      {{ popupPlan.productOrSubscription }}
                    </h3>
                    <div
                      class="division h-0 border border-grey-70 flex-1"
                    ></div>
                  </div>

                  <div class="mt-4 short_description">
                    <p
                      class="text-sm leading-5 text-gray-600"
                      v-html="popupPlan.shortCardDescriptionHumanLangauge"
                    ></p>
                  </div>

                  <div class="mt-5 mb-4 items-center">
                    <p
                      class="text-sm text-right leading-5 font-bold text-gray-900"
                    >
                      {{
                        (popupPlan.price ? popupPlan.price : 0).toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )
                      }}
                    </p>
                    <p
                      class="flex gap-5 mt-1 items-center text-xs leading-5 text-gray-900"
                      v-if="getProratedPrice(popupPlan.stripePriceId)"
                    >
                      <span>Prorated price</span>
                      <span class="text-right flex-1">{{
                        getProratedPrice(
                          popupPlan.stripePriceId
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}</span>
                    </p>
                  </div>
                </div>

                <div
                  class="division h-0 border-t border-grey-60 my-8"
                  v-if="!popupPlan.is_subscribe && addons.length"
                ></div>

                <div
                  class="addon mb-12"
                  v-for="addon in addons"
                  :key="addon._id"
                >
                  <div class="flex gap-5 items-center">
                    <h3
                      class="leading-6 font-bold text-gray-900"
                      :class="[
                        !popupPlan.is_subscribe ? 'text-lg' : 'text-2xl',
                      ]"
                    >
                      {{ addon.productOrSubscription }}
                    </h3>
                    <div
                      class="division h-0 border border-grey-70 flex-1"
                      v-if="popupPlan.is_subscribe"
                    ></div>
                  </div>

                  <div class="mt-4">
                    <p
                      class="text-sm leading-5 text-gray-600"
                      v-html="addon.shortCardDescriptionHumanLangauge"
                    ></p>
                  </div>

                  <div class="flex mt-5 mb-4 items-center">
                    <input
                      type="number"
                      class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                      style="width: 70px"
                      min="0"
                      v-model="addon.quantity"
                    />
                    <p class="text-sm leading-5 font-bold ml-4 text-gray-900">
                      x ${{ addon.price }} / {{ addon.productUnit }}
                    </p>
                    <p
                      class="text-sm text-right leading-5 font-bold text-gray-900 flex-1"
                    >
                      {{
                        (addon.price * addon.quantity).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </p>
                  </div>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="
                      getProratedPrice(addon.stripePriceId) &&
                      Number(addon.quantity)
                    "
                  >
                    <span>Prorated price</span>
                    <span class="text-right flex-1">{{
                      getProratedPrice(addon.stripePriceId).toLocaleString(
                        "en-US",
                        { style: "currency", currency: "USD" }
                      )
                    }}</span>
                  </p>
                </div>

                <div class="division h-0 border-t border-grey-60 my-8"></div>

                <div class="total-price mt-6">
                  <div class="flex gap-5 mb-1 items-center">
                    <p class="text-lg leading-5 font-bold text-gray-900">
                      Total
                    </p>
                    <p
                      class="text-lg text-right leading-5 font-bold text-gray-900 flex-1"
                    >
                      {{
                        totalPrice.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </p>
                  </div>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="getProratedDeduction() < 0 && !popupPlan.is_subscribe"
                  >
                    <span
                      >Prorated deduction (from your current paid
                      subscription)</span
                    >
                    <span class="text-right flex-1">{{
                      getProratedDeduction().toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}</span>
                  </p>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="getProratedTotal() != 0 && totalPrice"
                  >
                    <span>Prorated total</span>
                    <span class="text-right flex-1">{{
                      getProratedTotal().toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}</span>
                  </p>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="preInvoice && totalPrice"
                  >
                    <span>Current balance</span>
                    <span class="text-right flex-1">{{
                      (preInvoice.starting_balance / 100).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )
                    }}</span>
                  </p>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="preInvoice && totalPrice"
                  >
                    <span>Amount due</span>
                    <span class="text-right flex-1">{{
                      (preInvoice.amount_due / 100).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}</span>
                  </p>
                </div>

                <div class="flex mt-10 mb-4 items-center">
                  <input
                    type="text"
                    class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                    placeholder="Enter coupon code"
                    style="width: 200px"
                    v-model="couponCode"
                    @blur="applyCoupon()"
                    @keyup.enter="applyCoupon()"
                  />
                  <p
                    class="text-xs text-right leading-5 text-red-400 flex-1"
                    v-if="couponError"
                  >
                    {{ couponError }}
                  </p>
                  <p
                    class="text-xs text-right leading-5 text-gray-900 flex-1"
                    v-if="couponSuccess"
                  >
                    {{ couponSuccess }}
                  </p>
                </div>

                <p
                  class="mt-6 text-xs leading-5 text-gray-900 font-medium"
                  v-if="popupPlan.is_subscribe"
                >
                  Adding
                  <span v-for="(addon, i) in addons" :key="addon.productUnit">
                    <span class="font-bold"
                      >{{ addon.quantity }}
                      {{
                        addon.quantity == 1
                          ? addon.productUnit
                          : addon.productUnitPlural
                      }}</span
                    >
                    <span v-if="i < addons.length - 1"> and </span>
                  </span>

                  will add
                  <span class="font-bold">{{
                    totalPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  }}</span>
                  to your bill starting
                  <span class="font-bold" v-if="stripeSubscription">{{
                    getHumanReadableDate(
                      stripeSubscription.current_period_end * 1000
                    )
                  }}</span>
                  Equilo will charge you for the seats even if you haven't
                  assigned them.
                </p>
                <p
                  class="mt-6 text-xs leading-5 text-gray-900 font-medium"
                  v-else
                >
                  Equilo will charge you for the seats even if you haven't
                  assigned them.
                </p>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepBillingRegister"
              >
                <div class="mt-2">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-700">
                    Email
                  </p>
                  <input
                    type="text"
                    class="border-2 border-gray-400 mb-2 rounded-md px-3 py-2 text-sm"
                    id="registerBillingEmail"
                    style="width: 100%"
                    placeholder="Email Address"
                  />
                </div>

                <div ref="registerBillingAddressElement" class="mt-2">
                  <!-- Elements will create form elements here -->
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepBillingEdit"
              >
                <div class="mt-2">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-700">
                    Email
                  </p>
                  <input
                    type="text"
                    class="border-2 border-gray-400 mb-2 rounded-md px-3 py-2 text-sm"
                    id="updateBillingEmail"
                    style="width: 100%"
                    placeholder="Email Address"
                  />
                </div>

                <div ref="updateBillingAddressElement" class="mt-2">
                  <!-- Elements will create form elements here -->
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepBillingList"
              >
                <div
                  class="mt-2 border border-gray-400 hover:border-gray-600 rounded-md p-4 relative"
                  v-if="stripeCustomer"
                >
                  <a
                    class="inline-block absolute text-xs"
                    style="top: 8px; right: 10px"
                    @click="editBillingDetail()"
                    >Edit</a
                  >
                  <h6 class="font-bold">Account details</h6>
                  <ul>
                    <li class="text-sm">
                      <span class="text-md font-bold">Name </span
                      >{{ stripeCustomer.name }}
                    </li>
                    <li class="text-sm">
                      <span class="text-md font-bold">Billing email </span
                      >{{ stripeCustomer.email }}
                    </li>
                    <li class="text-sm" v-if="stripeCustomer.address">
                      <span class="text-md font-bold">Billing details </span>
                      {{ stripeCustomer.address.line1 }},
                      {{ stripeCustomer.address.city }},
                      {{ stripeCustomer.address.state }},
                      {{ stripeCustomer.address.postal_code }},
                      {{ stripeCustomer.address.country }}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepPaymentList"
              >
                <div
                  class="mt-2 hover:border-gray-600 rounded-md p-4 cursor-pointer relative"
                  :class="[
                    paymentMethod.id == selectedPaymentMethodId
                      ? 'border-gray-600 border-2'
                      : 'border-gray-400 border',
                  ]"
                  @click="selectedPaymentMethodId = paymentMethod.id"
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.id"
                >
                  <a
                    class="inline-block absolute text-xs"
                    style="top: 8px; right: 10px"
                    @click="deletePayment(paymentMethod.id)"
                    >Delete</a
                  >
                  <a
                    class="inline-block absolute text-xs"
                    style="top: 8px; right: 70px"
                    @click="editPayment(paymentMethod)"
                    >Edit</a
                  >
                  <ul>
                    <li class="text-sm">
                      <span class="text-md font-bold capitalize"
                        >{{ paymentMethod.card.brand }} </span
                      >****{{ paymentMethod.card.last4 }}
                    </li>
                    <li class="text-sm">
                      <span class="text-md font-bold">Expires </span
                      >{{ getMonthName(paymentMethod.card.exp_month) }}
                      {{ paymentMethod.card.exp_year }}
                    </li>
                  </ul>
                </div>
                <a class="mt-4 inline-block font-bold" @click="addPayment()"
                  >Add</a
                >
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepPaymentRegister"
              >
                <div ref="cardElement">
                  <!-- Elements will create form elements here -->
                </div>
                <!-- We'll put the error messages in this element -->
                <div
                  ref="cardElementErrors"
                  role="alert"
                  class="text-sm mt-2"
                  style="color: #fa755a"
                ></div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-if="isStepPaymentEdit"
              >
                <div class="flex mt-5 mb-4 items-center gap-5">
                  <select
                    class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                    style="width: 60%"
                    v-model="editedExpiryMonth"
                  >
                    <option :value="n" :key="n" v-for="n in 12">
                      {{ getMonthName(n) }}
                    </option>
                  </select>
                  <label class="font-bold">Expiry month</label>
                </div>
                <div class="flex mt-5 mb-4 items-center gap-5">
                  <select
                    class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                    style="width: 60%"
                    v-model="editedExpiryYear"
                  >
                    <option :value="n" :key="n" v-for="n in rangeExpiryYear">
                      {{ n }}
                    </option>
                  </select>
                  <label class="font-bold">Expiry year</label>
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepSuccess"
              >
                Purchased successfully.
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepError"
              >
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:p-12 sm:pt-4 sm:flex sm:flex-row-reverse"
          >
            <span
              class="flex gap-2 w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
            >
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-red-400 hover:border-red-700 px-4 py-2 bg-red-600 text-base leading-6 font-bold text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="closePopup()"
              >
                Close
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="back()"
                v-if="isBackPossible()"
              >
                {{
                  isStepBillingEdit ||
                  isStepPaymentEdit ||
                  isStepPaymentRegister
                    ? "Cancel"
                    : "Back"
                }}
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="proceedToCheckout()"
                :disabled="couponError"
                v-if="isStepAddon"
              >
                Proceed to Checkout
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="registerCustomer()"
                v-if="isStepBillingRegister"
              >
                Register
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="updateCustomer()"
                v-if="isStepBillingEdit"
              >
                Update
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="checkout()"
                v-if="isStepBillingList"
              >
                Next
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="registerPayment()"
                v-if="isStepPaymentRegister"
              >
                Register
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="updatePayment()"
                v-if="isStepPaymentEdit"
              >
                Update
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                id="btn_subscribe"
                @click="subscribe()"
                v-if="isStepPaymentList"
              >
                Subscribe
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- / Popup -->

    <!-- Additional information, Discounts, Benefits -->
    <div
      class="fixed z-10 inset-0 overflow-y-auto info-modal"
      v-show="infoModalVisible"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closeInfoPopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-12 overflow-y-auto max-h-screen-70"
          >
            <div class="mt-3 sm:mt-0 sm:text-left">
              <div class="flex gap-5 items-center">
                <h3 class="text-2xl leading-6 font-bold text-gray-900">
                  {{ infoModalTitle }}
                </h3>
                <div class="division h-0 border border-grey-70 flex-1"></div>
              </div>

              <p
                class="text-sm leading-5 text-gray-600 mt-4 info-description short_description"
                v-html="infoModalContent"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Additional information, Discounts, Benefits -->

    <div class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0 pb-32">
      <div class="flex flex-row mt-32 lg:mt-0 justify-center">
        <div class="flex flex-col w-12/12 justify-center">
          <h3
            class="text-2xl tracking-tighter font-medium print:text-7xl margin-b-14 w-full"
          >
            Equilo's Tailored Subscription Packages
          </h3>
          <h1
            class="text-5xl font-bold leading-tight mb-4"
            style="letter-spacing: -0.05rem"
          >
            Level Up Your Gender Analysis Intelligence with an Upgrade
          </h1>
          <h6
            class="text-xl leading-tight mb-8"
            style="letter-spacing: -0.05rem"
          >
            Choose the right subscription packages for you. Access automated
            gender analysis and in-depth gender assessments at your fingertips,
            revolutionizing your path to an inclusive and more just world.
          </h6>
          <div class="w-full">
            <ul
              class="grid grid-cols-3 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 font-medium"
            >
              <li
                class="margin-l-0 border border-grey-20 relative"
                :class="{
                  'rounded-tl-lg': i == 0,
                  'rounded-tr-lg': i == plan_list.length - 1,
                  'border-r-0': i < plan_list.length - 1,
                }"
                v-for="(plan, i) in plan_list"
                v-bind:key="plan._id"
              >
                <div
                  class="inline-block text-center text-white text-xs font-medium w-full absolute top-0 left-0 leading-7 bg-orange-100"
                  v-if="plan.isMostPopular && !plan.is_subscribe"
                >
                  Most Popular
                </div>
                <div
                  class="inline-block text-center text-grey-100 text-xs font-medium w-full absolute top-0 left-0 leading-7 bg-grey-10"
                  v-else-if="plan.is_subscribe"
                >
                  Current Subscription
                </div>

                <div
                  class="padding-7 padding-t-7 w-full inline-block rounded-tr-5xl rounded-tl-5xl"
                >
                  <span class="text-grey-100 text-lg block mb-4">{{
                    plan.productFamily
                  }}</span>
                  <span
                    class="text-grey-100 text-3xl inline-block font-bold margin-b-14"
                    >{{
                      maskedProduct(cleanInfo(plan))
                    }}</span
                  >
                  <div class="flex mb-3 items-baseline" v-if="cleanInfo(plan)=== 'Basic'">
                    <h2 class="text-2xl text-grey-100 leading-8 font-bold">
                      <span>${{ plan.price.toLocaleString() }}</span>
                    </h2>
                    <span class="inline-block text-xs ml-2 text-grey-100"
                      >/
                      {{
                        plan.maxUsers == 1
                          ? `${plan.maxUsers} user / year`
                          : `${plan.maxUsers} users / year`
                      }}</span
                    >
                  </div>
                  <div class="flex mb-3 items-baseline" v-else>
                    <h2 class="text-xl text-grey-100 leading-8 font-bold">
                      <span>Customized plan for you</span>
                    </h2>
                  </div>
                  <label
                    class="w-full inline-block border padding-14 text-center text-white text-sm font-medium rounded-5xl"
                    @click="
                      plan.sequence < sequence
                        ? null
                        : plan.price == ''
                        ? bookConsultation()
                        : checkOperation(plan._id, cleanInfo(plan))
                    "
                    :class="[
                      plan.sequence < sequence
                        ? 'bg-grey-50 border-grey-20'
                        : 'bg-orange-100 hover:bg-orange-80 border-orange-20 cursor-pointer',
                    ]"
                  >
                    <!-- book a consultation -->
                    <span v-if="plan.price == ''">Request more information</span>
                    <!-- add addon to plan -->
                    <span v-else-if="plan.is_subscribe && plan.addons.length"
                      >{{ checkPlan(cleanInfo(plan)) }}</span
                    >
                    <!-- downgrade to new plan -->
                    <span
                      v-else-if="
                        subscribe_any == true && plan.sequence < sequence
                      "
                      >Purchase</span
                    >
                    <!-- upgrade to new plan -->
                    <span
                      v-else-if="
                        subscribe_any == true && plan.sequence > sequence
                      "
                      >Request more information</span
                    >
                    <!-- purchase new plan -->
                    <span v-else>{{ checkPlan(plan.productOrSubscription
                        .replace(plan.productFamily, "")
                        .trim()) }}</span>
                  </label>
                </div>

                <div
                  class="padding-7 padding-t-0 w-full inline-block short_description text-left"
                >
                  <div
                    class="text-sm margin-b-14 text-grey-100"
                    v-html="plan.shortCardDescriptionHumanLangauge"
                  ></div>
                </div>
              </li>
            </ul>
            <div
              class="border border-grey-20 rounded-bl-lg rounded-br-lg all-plan-description"
            >
              <h6
                class="mb-6 pt-6 font-bold text-center"
                v-if="subscribe_any == true"
              >
                All plans include Equilo Open features:
              </h6>
              <h6 class="mb-6 py-2 font-bold text-center bg-grey-10" v-else>
                Current Subscription: Equilo Open
              </h6>
              <div
                class="px-10 pt-2 pb-6 text-sm margin-b-14 text-grey-100"
                style="
                  display: grid;
                  grid-template-columns: 30% calc(70% - 30px);
                  column-gap: 30px;
                "
                v-html="openPlan.shortCardDescriptionHumanLangauge"
              ></div>
            </div>
          </div>
          <div class="mt-5 grid grid-cols-2 gap-4">
            <div class="flex product_container">
              <div class="relative w-full">
                <img
                  src="@/assets/images/orange_analysis.png"
                  alt="orange_man_global_analysis"
                  class="product-img w-full"
                />
                <div class="absolute product-desc">
                  <p class="text-white">
                    <span class="font-bold"
                      >Just grab the data with our API Data Access Plans</span
                    >
                  </p>
                  <button class="flex mt-4 font-bold text-sm text-white">
                    <img
                      src="@/assets/images/arrow_forward_white_without_bg.svg"
                    />
                    <span class="ml-2">Integrate Equilo with your systems</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex product_container">
              <div class="relative w-full">
                <img
                  src="@/assets/images/support_feedback.png"
                  alt="support_feedback"
                  class="product-img w-full"
                />
                <div class="absolute product-desc">
                  <p class="text-white">
                    <span class="font-bold"
                      >Not sure which package is right for you?</span
                    >
                  </p>
                  <a
                    class="flex mt-4 font-bold text-sm text-white"
                    href="https://www.equilo.io/consult"
                    target="_blank"
                  >
                    <img
                      src="@/assets/images/arrow_forward_white_without_bg.svg"
                    />
                    <span class="ml-2 text-white">Book a consultation</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="learn-more px-12 border border-grey-20 rounded-lg"
              v-if="subscriptionContent"
            >
              <div class="flex gap-5 items-center mt-10">
                <h3 class="text-2xl leading-6 font-bold text-gray-900">
                  Learn more
                </h3>
                <div class="division h-0 border border-grey-70 flex-1"></div>
              </div>

              <ul
                class="grid grid-cols-1 md:grid-cols-2 font-bold text-xs text-black mt-6"
              >
                <li
                  class="mb-4 cursor-pointer"
                  @click="
                    showInfoPopup(
                      'Additional Information',
                      subscriptionContent.longDescriptionHumanLanguage
                    )
                  "
                  v-if="subscriptionContent.longDescriptionHumanLanguage"
                >
                  Additional Information
                  <img
                    class="inline-block ml-1"
                    src="@/assets/images/plus-sign-new.svg"
                  />
                </li>
                <li
                  class="mb-4 cursor-pointer"
                  @click="
                    showInfoPopup(
                      'Benefits',
                      subscriptionContent.BenefitsHumanLanguage
                    )
                  "
                  v-if="subscriptionContent.BenefitsHumanLanguage"
                >
                  Benefits
                  <img
                    class="inline-block ml-1"
                    src="@/assets/images/plus-sign-new.svg"
                  />
                </li>
                <li
                  class="mb-4 cursor-pointer"
                  @click="
                    showInfoPopup(
                      'Available Discounts',
                      subscriptionContent.availableDiscountsHumanLanguage
                    )
                  "
                  v-if="subscriptionContent.availableDiscountsHumanLanguage"
                >
                  Available Discounts
                  <img
                    class="inline-block ml-1"
                    src="@/assets/images/plus-sign-new.svg"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

.short_description ul {
  list-style: none;
  margin-top: 16px;
}

.short_description ul li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 28px;
}

.short_description ul li::before {
  content: " ";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  border: solid 9px #2bd4c4;
  border-radius: 9px;
  height: 0;
  width: 0;
}

.short_description ul li::after {
  content: " ";
  display: block;
  position: absolute;
  top: 8px;
  left: 7px;
  transform: rotate(45deg);
  width: 0.3em;
  height: 0.6em;
  border: solid #fff;
  border-width: 0 0.2em 0.2em 0;
}

.all-plan-description ul {
  list-style: none;
  display: grid;
  grid-template-columns: auto auto;
}

.all-plan-description ul li {
  position: relative;
  margin-bottom: 8px;
  padding: 0 28px;
}

.all-plan-description ul li::before {
  content: " ";
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  border: solid 9px #2bd4c4;
  border-radius: 9px;
  height: 0;
  width: 0;
}

.all-plan-description ul li::after {
  content: " ";
  display: block;
  position: absolute;
  top: 8px;
  left: 7px;
  transform: rotate(45deg);
  width: 0.3em;
  height: 0.6em;
  border: solid #fff;
  border-width: 0 0.2em 0.2em 0;
}

.schedule_time:hover {
  color: white;
}

.additional_info ul {
  list-style: disc !important;
}

details div {
  border-left: 2px solid #290047;
  border-right: 2px solid #290047;
  border-bottom: 2px solid #290047;
  border-radius: 6px;
  padding: 1.5em;
}

details div > * + * {
  margin-top: 1.5em;
}

details + details {
  margin-top: 0.5rem;
}

summary {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

summary {
  border: 2px solid #290047;
  border-radius: 6px;
  padding: 0.75em 1em;
  cursor: pointer;
  position: relative;
  padding-left: calc(1.75rem + 0.75rem + 0.75rem);
}

summary:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.75rem;
  content: "↓";
  width: 1.75rem;
  height: 1.75rem;
  background-color: #290047;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

details[open] summary {
  background-color: #eee;
}

details[open] summary:before {
  content: "↑";
}

summary:hover {
  background-color: #eee;
}

.product-desc {
  top: 20%;
  left: 8%;
  width: 60%;
  font-size: 1.5rem;
}

.info-modal .info-description ul li {
  margin-top: 24px;
}
</style>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import axios from "axios";
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import { pause } from "@/helpers/utils";

const stripe = window.Stripe(env.stripePublicKey);

export default {
  name: "pricing",
  data: function () {
    return {
      env,
      plan_list: [],
      openPlan: {},
      active: false,
      paid: false,
      gbvpaid: false,
      loaded: false,
      subscribe_any: false,
      redirect_url: "/members",
      plan_type: "",
      checkout_status: "",
      subscriptionContent: "",
      subscriptionId: "",
      api_data_access: env.productFamilyIds.api_data_access,
      sequence: 0,
      gesi_old: "",
      addons: [],
      totalPrice: 0,
      modalVisible: false,
      stepLoading: false,
      isStepAddon: false,
      isStepBillingRegister: false,
      isStepBillingList: false,
      isStepBillingEdit: false,
      isStepPaymentList: false,
      isStepPaymentEdit: false,
      isStepPaymentRegister: false,
      isStepSuccess: false,
      isStepError: false,
      popupPlan: {},
      stripeElementOptions: {},
      elements: {},
      registerBillingAddressElement: {},
      updateBillingAddressElement: {},
      paymentMethods: [],
      cardElement: {},
      clientSecret: "",
      infoModalVisible: false,
      infoModalTitle: "",
      infoModalContent: "",
      stripeCustomer: null,
      stripeSubscription: null,
      selectedPaymentMethodId: "",
      editedPaymentMethodId: "",
      editedExpiryMonth: 1,
      editedExpiryYear: 1977,
      rangeExpiryYear: [],
      errorMessage: "",
      couponCode: null,
      couponError: null,
      couponSuccess: null,
      preInvoice: null,
    };
  },
  components: {
    pageLoader,
    ClipLoader,
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },
    isBackPossible() {
      return !this.isStepAddon && !this.isStepSuccess && !this.isStepError;
    },
    closePopup() {
      this.modalVisible = false;
      this.closeAllStep();
    },
    showPopup() {
      this.modalVisible = true;
    },
    showInfoPopup(title, content) {
      this.infoModalVisible = true;
      this.infoModalTitle = title;
      this.infoModalContent = content;
    },
    closeInfoPopup() {
      this.infoModalVisible = false;
    },
    back() {
      if (this.isStepBillingList) {
        this.isStepBillingList = false;
        this.isStepAddon = true;
      } else if (this.isStepBillingRegister) {
        this.isStepBillingRegister = false;
        this.isStepAddon = true;
      } else if (this.isStepBillingEdit) {
        this.isStepBillingEdit = false;
        this.isStepBillingList = true;
      } else if (this.isStepPaymentList) {
        this.isStepPaymentList = false;
        this.isStepBillingList = true;
      } else if (this.isStepPaymentRegister) {
        this.isStepPaymentRegister = false;
        this.isStepPaymentList = true;
      } else if (this.isStepPaymentEdit) {
        this.isStepPaymentEdit = false;
        this.isStepPaymentList = true;
      }
    },
    showLoading() {
      let _self = this;

      _self.$swal
        .fire({
          title: "Just a moment..",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 3000,
          onOpen: () => {
            _self.$swal.showLoading();
          },
        })
        .then(() => {
          // if (dismiss === 'timer') {
          console.log("closed by timer!!!!");
          _self.$swal.fire({
            title: "Thank you !",
            icon: "success",
            type: "success",
            // timer: 2000,
            showConfirmButton: true,
          });
          // }
        });
    },
    closeAllStep() {
      this.isStepAddon = false;
      this.isStepBillingRegister = false;
      this.isStepBillingList = false;
      this.isStepBillingEdit = false;
      this.isStepPaymentList = false;
      this.isStepPaymentRegister = false;
      this.isStepPaymentEdit = false;
      this.isStepSuccess = false;
      this.isStepError = false;
    },
    proceedToCheckout() {
      this.closeAllStep();

      if (this.user.stripeCustomerId) {
        this.isStepBillingList = true;
        this.stepLoading = true;
        axios
          .post(
            env.apiUrl + "/subscriptions/get-customer",
            {
              customerId: this.user.stripeCustomerId,
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.stripeCustomer = res.data.customer;
              this.stepLoading = false;
            } else if (res.data.error) {
              console.log(res.data.error);
            }
          });
      } else {
        this.isStepBillingRegister = true;
        this.elements = stripe.elements(this.stripeElementOptions);
        // Create and mount the Address Element in billing mode
        this.registerBillingAddressElement = this.elements.create("address", {
          mode: "billing",
        });
        this.registerBillingAddressElement.mount(
          this.$refs.registerBillingAddressElement
        );
      }
    },
    async registerCustomer() {
      const emailInput = document.querySelector("#registerBillingEmail");
      const { complete, value } =
        await this.registerBillingAddressElement.getValue();

      let _self = this;

      if (complete) {
        value.email = emailInput.value;
        this.stepLoading = true;
        axios
          .post(env.apiUrl + "/subscriptions/create-customer", value, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then((res) => {
            if (res.data) {
              _self.user.stripeCustomerId = res.data.customer.id;
              _self.stripeCustomer = res.data.customer;
              _self.closeAllStep();
              _self.isStepBillingList = true;
              _self.stepLoading = false;
            } else if (res.data.error) {
              console.log(res.data.error);
            }
          });
      }
    },
    editBillingDetail() {
      this.closeAllStep();
      this.isStepBillingEdit = true;

      document.querySelector("#updateBillingEmail").value =
        this.stripeCustomer.email;

      let billingAddress = this.stripeCustomer.address
        ? this.stripeCustomer.address
        : {
            line1: "",
            line2: "",
            city: "",
            state: "",
            postal_code: "",
            country: "",
          };

      this.elements = stripe.elements(this.stripeElementOptions);
      // Create and mount the Address Element in billing mode
      this.updateBillingAddressElement = this.elements.create("address", {
        mode: "billing",
        defaultValues: {
          name: this.stripeCustomer.name,
          address: billingAddress,
        },
      });
      this.updateBillingAddressElement.mount(
        this.$refs.updateBillingAddressElement
      );
    },
    async updateCustomer() {
      const emailInput = document.querySelector("#updateBillingEmail");
      const { complete, value } =
        await this.updateBillingAddressElement.getValue();

      let _self = this;

      if (complete) {
        value.customerId = this.user.stripeCustomerId;
        value.email = emailInput.value;
        this.stepLoading = true;
        axios
          .post(env.apiUrl + "/subscriptions/update-customer", value, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then((res) => {
            if (res.data) {
              _self.stripeCustomer = res.data.customer;
              _self.closeAllStep();
              _self.isStepBillingList = true;
              _self.stepLoading = false;
            } else if (res.data.error) {
              console.log(res.data.error);
            }
          });
      }
    },
    async checkout() {
      this.stepLoading = true;
      this.closeAllStep();

      axios
        .post(
          env.apiUrl + "/subscriptions/get-payment-methods",
          {
            customerId: this.user.stripeCustomerId,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.paymentMethods = res.data.paymentMethods.data;

            if (this.paymentMethods.length) {
              this.selectedPaymentMethodId = this.paymentMethods[0].id;
              this.isStepPaymentList = true;
              this.stepLoading = false;
            } else {
              this.addPayment();
            }
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    addPayment() {
      this.closeAllStep();
      this.isStepPaymentRegister = true;
      this.stepLoading = false;
      this.elements = stripe.elements(this.stripeElementOptions);
      this.cardElement = this.elements.create("card", {
        hidePostalCode: true,
        style: {
          base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d",
            },
          },
          invalid: {
            fontFamily: "Arial, sans-serif",
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
        rules: {
          ".Tab": {
            border: "1px solid #E0E6EB",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
          },
          ".Tab:hover": {
            color: "var(--colorText)",
          },
          ".Tab--selected": {
            borderColor: "#E0E6EB",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
          },
          ".Input": {
            borderColor: "#E0E6EB",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
          },
          ".Input--invalid": {
            boxShadow:
              "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
          },
          // See all supported class names and selector syntax below
        },
      });
      this.cardElement.mount(this.$refs.cardElement);
    },
    async registerPayment() {
      this.stepLoading = false;
      await stripe
        .createPaymentMethod({
          type: "card",
          card: this.cardElement,
        })
        .then((result) => {
          if (result.error) {
            this.$refs.cardElementErrors.textContent = result.error.message;
            this.stepLoading = false;
          } else {
            axios
              .post(
                env.apiUrl + "/subscriptions/attach-payment-method",
                {
                  customerId: this.user.stripeCustomerId,
                  paymentMethodId: result.paymentMethod.id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + Cookies.get("session"),
                  },
                }
              )
              .then((res) => {
                if (res.data) {
                  this.closeAllStep();
                  this.stepLoading = false;
                  this.isStepPaymentList = true;
                  this.paymentMethods.unshift(result.paymentMethod);
                  this.selectedPaymentMethodId = result.paymentMethod.id;
                } else if (res.data.error) {
                  console.log(res.data.error);
                }
              });
          }
        });
    },
    async createSubscription() {
      this.stepLoading = true;
      let items = [
        {
          price: this.popupPlan.stripePriceId,
          quantity: 1,
          metadata: {
            type: "plan",
          },
        },
      ];
      this.addons.map((addon) => {
        items.push({
          price: addon.stripePriceId,
          quantity: addon.quantity,
          metadata: {
            type: "addon",
          },
        });
      });

      items = items.filter((item) => item.quantity > 0);

      axios
        .post(
          env.apiUrl + "/subscriptions/create-subscription",
          {
            customerId: this.user.stripeCustomerId,
            items: items,
            promoCode: this.couponCode,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          if (res.data) {
            this.subscriptionId = res.data.subscriptionId;
            this.clientSecret = res.data.clientSecret;
            this.closeAllStep();
            if (this.clientSecret) {
              await this.pay();
            } else {
              this.isStepSuccess = true;
              this.stepLoading = false;
            }
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    async updateSubscription() {
      this.stepLoading = true;
      let items = [
        {
          price: this.popupPlan.stripePriceId,
          quantity: 1,
          metadata: {
            type: "plan",
          },
        },
      ];
      this.addons.map((addon) => {
        items.push({
          price: addon.stripePriceId,
          quantity: addon.quantity,
          metadata: {
            type: "addon",
          },
        });
      });

      items = items.filter((item) => item.quantity > 0);

      axios
        .post(
          env.apiUrl + "/subscriptions/update-subscription",
          {
            subscriptionId: this.subscriptionId,
            items: items,
            promoCode: this.couponCode,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          if (res.data) {
            this.clientSecret = res.data.clientSecret;
            this.closeAllStep();

            if (this.clientSecret) {
              await this.pay();
            } else {
              this.plan_list = this.plan_list.map((d) => {
                d.is_subscribe = d._id == this.popupPlan._id ? true : false;
                this.sequence =
                  d._id == this.popupPlan._id ? d.sequence : this.sequence;
                return d;
              });
              this.stepLoading = false;
              this.subscribe_any = true;
              this.isStepSuccess = true;
            }
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    async pay() {
      await stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: this.selectedPaymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            this.stepLoading = false;
            this.closeAllStep();
            this.isStepError = true;
            this.errorMessage = result.error.message;
          } else {
            // Successful subscription payment
            window.rewardful("convert", { email: this.stripeCustomer.email });

            this.plan_list = this.plan_list.map((d) => {
              d.is_subscribe = d._id == this.popupPlan._id ? true : false;
              this.sequence =
                d._id == this.popupPlan._id ? d.sequence : this.sequence;
              return d;
            });
            this.subscribe_any = true;
            this.closeAllStep();
            this.isStepSuccess = true;
            this.stepLoading = false;
          }
        });
    },
    async subscribe() {
      this.stepLoading = true;
      this.closeAllStep();

      if (this.subscriptionId) {
        await this.updateSubscription();
      } else {
        await this.createSubscription();
      }
    },
    async getStripeSubscription(subscriptionId) {
      this.stepLoading = true;
      axios
        .post(
          env.apiUrl + "/subscriptions/get-subscription",
          {
            subscriptionId: subscriptionId,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          this.stepLoading = false;
          if (res.data) {
            this.stripeSubscription = res.data.subscription;
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    openCheckout(planId) {
      this.popupPlan = this.plan_list.find((d) => d._id == planId);
      this.addons = this.popupPlan.addons;
      this.addons = this.addons.map((addon) => {
        addon.quantity = 0;
        return addon;
      });
      this.addons = JSON.parse(JSON.stringify(this.addons));
      this.couponCode = null;
      this.couponError = null;
      this.couponSuccess = null;
      this.isStepAddon = true;
      this.showPopup();
    },
    bookConsultation() {
      window.open("https://www.equilo.io/consult", "_blank");
    },
    invoicePreview() {
      let items = [
        {
          price: this.popupPlan.stripePriceId,
          quantity: 1,
          metadata: {
            type: "plan",
          },
        },
      ];
      this.addons.map((addon) => {
        items.push({
          price: addon.stripePriceId,
          quantity: addon.quantity,
          metadata: {
            type: "addon",
          },
        });
      });

      items = items.filter((item) => item.quantity > 0);

      this.stepLoading = true;
      this.preInvoice = null;

      axios
        .post(
          env.apiUrl + "/subscriptions/invoice-preview",
          {
            subscriptionId: this.subscriptionId,
            items: items,
            promoCode: this.couponCode,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          this.stepLoading = false;
          if (res.data) {
            this.preInvoice = res.data.invoice;
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    load: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios({
          url: env.apiUrl + "/subscriptions/get-planlist/equilo",
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
          method: "GET",
        }).then(function (res) {
          _self.subscribe_any = res.data.subscribe_any;
          _self.redirect_url = res.data.redirect_url;
          _self.plan_type = res.data.plan_type;
          _self.subscriptionContent = res.data.subscriptionContent;
          _self.subscriptionId = res.data.subscriptionId;
          _self.sequence = res.data.sequence;
          _self.gesi_old = res.data.gesi_old;

          if (_self.subscriptionId)
            _self.getStripeSubscription(_self.subscriptionId);

          resolve(res.data.data);
        });
      });
    },
    getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-GB", { month: "short" });
    },
    getHumanReadableDate(timestamp) {
      const date = new Date();
      date.setTime(timestamp);

      return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
    async deletePayment(paymentMethodId) {
      this.stepLoading = true;
      axios
        .post(
          env.apiUrl + "/subscriptions/detach-payment-method",
          {
            paymentMethodId: paymentMethodId,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.paymentMethods = this.paymentMethods.filter(
              (paymentMethod) => paymentMethod.id != paymentMethodId
            );
            this.stepLoading = false;
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    editPayment(paymentMethod) {
      this.editedExpiryMonth = paymentMethod.card.exp_month;
      this.editedExpiryYear = paymentMethod.card.exp_year;
      this.editedPaymentMethodId = paymentMethod.id;
      this.closeAllStep();
      this.isStepPaymentEdit = true;
    },
    async updatePayment() {
      this.stepLoading = true;
      axios
        .post(
          env.apiUrl + "/subscriptions/update-payment-method",
          {
            paymentMethodId: this.editedPaymentMethodId,
            expMonth: this.editedExpiryMonth,
            expYear: this.editedExpiryYear,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.paymentMethods = this.paymentMethods.map((paymentMethod) => {
              if (paymentMethod.id == this.editedPaymentMethodId) {
                paymentMethod.card.exp_month = this.editedExpiryMonth;
                paymentMethod.card.exp_year = this.editedExpiryYear;
              }
              return paymentMethod;
            });
            this.closeAllStep();
            this.isStepPaymentList = true;
            this.stepLoading = false;
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    async applyCoupon() {
      await pause();
      if (!this.modalVisible) return;

      this.stepLoading = true;
      this.couponSuccess = null;
      this.couponError = null;

      if (!this.couponCode) {
        if (this.totalPrice && this.subscriptionId) {
          this.invoicePreview();
        } else {
          this.preInvoice = null;
          this.stepLoading = false;
        }
        return;
      }

      axios
        .post(env.apiUrl + "/stripe/getCoupon", {
          promoCode: this.couponCode,
        })
        .then((res) => {
          const coupon = res.data;
          if (coupon && coupon.valid) {
            this.couponSuccess = coupon.amount_off
              ? (coupon.amount_off / 100).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : `${coupon.percent_off}%`;
            this.couponSuccess += " discount";

            if (this.totalPrice) {
              this.invoicePreview();
            } else {
              this.stepLoading = false;
            }
          } else {
            this.couponError = "This coupon code is invalid.";
            this.stepLoading = false;
          }
        });
    },
    getProratedPrice(priceId) {
      if (!this.preInvoice) return 0;

      let proratedPrice = 0;
      for (const data of this.preInvoice.lines.data) {
        if (data.price.id == priceId) proratedPrice += Number(data.amount);
      }

      return proratedPrice / 100;
    },
    getProratedDeduction() {
      if (!this.preInvoice) return 0;

      let proratedDeduction = 0;
      for (const data of this.preInvoice.lines.data) {
        if (Number(data.amount) < 0) proratedDeduction += Number(data.amount);
      }

      return proratedDeduction / 100;
    },
    getProratedTotal() {
      if (!this.preInvoice) return 0;

      return Number(this.preInvoice.total) / 100;
    },
    maskedProduct(product) {
      if (product === "Basic") return "Essentials"
      return product
    },
    checkPlan(product) {
      if (product === "Basic") return "Purchase Add-On"
      return "Request more information"
    },
    checkOperation(id, product) {
      if (product === "Basic") this.openCheckout(id)
      else window.open("https://share-eu1.hsforms.com/1Lhkak7bVQKK5MiHdunzP5g2dh9b4", '_blank');
    },
    cleanInfo(plan) {
      return plan.productOrSubscription.replace(plan.productFamily, "").trim()
    }
  },
  computed: {},
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    if (!this.user.admin) {
      this.$router.push({ name: "notAllowed" });
    }

    this.plan_list = await this.load();
    this.openPlan = this.plan_list.find(
      (data) => data.productId == env.productIds.open
    );
    this.plan_list = this.plan_list.filter(
      (data) => data.productId != env.productIds.open
    );
    this.loaded = true;

    this.$store.commit("setBreadcrumbs", [{ name: "Plans", url: "" }]);

    let currentYear = new Date().getFullYear();
    for (let year = currentYear; year < currentYear + 20; year++) {
      this.rangeExpiryYear.push(year);
    }
  },
  watch: {
    "$route.path": async function () {
      this.loaded = false;
      this.plan_list = await this.load();
      this.loaded = true;
    },
    addons: {
      handler() {
        this.totalPrice = this.popupPlan.is_subscribe
          ? 0
          : this.popupPlan.price;
        this.addons.map((addon) => {
          this.totalPrice += addon.price * addon.quantity;
        });

        if (this.totalPrice && this.subscriptionId) this.invoicePreview();
      },
      deep: true,
    },
  },
};
</script>
