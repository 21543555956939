<template>
    <div class="dimension_inner_name flex">
        <div class="dimension_inner_left_side">
            <div class="dimension_survey_question mt-4 mb-4 flex" :style="(getScoredataLength > 1 && (score.subpageData[0]?.score != 'NA' && score.subpageData[0]?.score != '' && score.subpageData[0]?.Oldscore != 'NA' && score.subpageData[0]?.Oldscore != '')) ? 'height: 250px;' : ''">
                <div class="survey_question_inner">
                    <span>{{ score.subpageData[0].verbiageForPercentageFields }}
                    </span>
                </div>
                <div class="barchart_inner text-center">
                    <div v-if="getScoredataLength == 1 && score.subpageData[0]?.score != 'NA' && score.subpageData[0]?.score != ''">
                        <div class="text-center">
                            <div style="color: #FE5000">{{
                                financialYear[0].split(" ")[0] + " " +
                                financialYear[0].split(" ")[1]
                            }}</div>
                        </div>
                        <DoughnutChart :chartData="score.subpageData[0]?.score" :created="financialYear">
                        </DoughnutChart>
                    </div>
                    <div v-if="getScoredataLength == 1 && score.subpageData[0]?.score == ''">
                        <div class="text-center">
                            <div style="color: #FE5000">{{
                                financialYear[0].split(" ")[0] + " " +
                                financialYear[0].split(" ")[1]
                            }}</div>
                            <span>Unknown</span>
                        </div>
                    </div>
                    <DoughnutChartMultiple
                        v-if="getScoredataLength > 1 && (score.subpageData[0]?.score != 'NA' && score.subpageData[0]?.score != '' && score.subpageData[0]?.Oldscore != 'NA' && score.subpageData[0]?.Oldscore != '')"
                        :chartData="score.subpageData[0]?.score" :OldchartData="score.subpageData[0]?.Oldscore"
                        :created="financialYear">
                    </DoughnutChartMultiple>
                    <div v-if="getScoredataLength > 1 && (score.subpageData[0]?.score == '' || score.subpageData[0]?.Oldscore == '')">
                        <div class="text-center" v-if="score.subpageData[0]?.score != ''">
                            <div class="text-center">
                                <div style="color: #FE5000">{{
                                    financialYear[0].split(" ")[0] + " " +
                                    financialYear[0].split(" ")[1]
                                }}</div>
                            </div>
                            <DoughnutChart :chartData="score.subpageData[0]?.score"
                                :created="financialYear">
                            </DoughnutChart>
                        </div>
                        <div class="text-center" v-else>
                            <div style="color: #FE5000">{{
                                financialYear[0].split(" ")[0] + " " +
                                financialYear[0].split(" ")[1]
                            }}</div>
                            <span>Unknown</span>
                        </div>
                        <br />
                        <div class="text-center" v-if="score.subpageData[0]?.Oldscore != ''">
                            <div class="text-center">
                                <div style="color: #201747">{{
                                    financialYear[1].split(" ")[0] + " " +
                                    financialYear[1].split(" ")[1]
                                }}</div>
                            </div>
                            <DoughnutChart :chartData="score.subpageData[0]?.Oldscore" color="#201747"
                                :created="financialYear">
                            </DoughnutChart>
                        </div>
                        <div class="text-center" v-else>
                            <div style="color: #201747">{{
                                financialYear[1].split(" ")[0] + " " +
                                financialYear[1].split(" ")[1]
                            }}</div>
                            <span>Unknown</span>
                        </div>
                    </div>
                    <div class="text-center" v-if="score.subpageData[0]?.score == 'NA'">
                        <div style="color: #FE5000">{{
                            financialYear[0].split(" ")[0] + " " +
                            financialYear[0].split(" ")[1]
                        }}</div>
                        <span>Not Applicable</span>
                    </div>
                    <div class="text-center" v-if="score.subpageData[0]?.Oldscore == 'NA'">
                        <div style="color: #201747">{{
                            financialYear[1].split(" ")[0] + " " +
                            financialYear[1].split(" ")[1]
                        }}</div>
                        <span>Not Applicable</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DoughnutChart from "./pillarDoughnutChart.vue";
import DoughnutChartMultiple from "./pillarDoughnutChartMultiple.vue";
export default {
    name: "ScaleZeroThreeChart",
    props: ["score", "getScoredataLength", "roiPageIntro", "financialYear"],
    components: {
        DoughnutChart,
        DoughnutChartMultiple,
    },
    filters: {
        textWithCommaRemoveOther(value) {
            value.forEach((data, d) => {
                if (data == 'Other') {
                    value.splice(d, 1);
                }
            })
            var data = Object.values(value).join(", ");
            return data;
        },
        removeHiddenFieldText(value) {
            if (value.includes('Formula (Hidden) Field:')) {
                return value.split('Formula (Hidden) Field:')[1];
            } else {
                return value;
            }
        },
        removePerchantageSign(str) {
            if (str?.startsWith('%')) {
                return str.slice(1);
            } else {
                return str;
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.dimension_inner_name {
    .dimension_inner_left_side {
        width: 100%;

        .dimension_survey_question {
            font-weight: 700;
            color: black;
            word-break: break-word;
            min-height: 70px;

            .survey_question_inner {
                width: 60%;
            }

            .barchart_inner {
                width: 40%;
            }

            .multi_values_inner {
                width: 30%;
                margin-left: 40px;
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }

    .dimension_inner_right_side {
        // width: 40%;
    }
}
</style>