<template>
  <div class="create-investor-container" :style="user?.membership2xData ? 'border-color: #E1E3E9' : 'border-color: #2c2e35'">
    <div class="inner-create-investor-container">
      <div class="step-section">
        <p>Step 1</p>
      </div>
      <div class="content-section">
        <div class="flex justify-between">
          <div class="text-content-heading">Create your investor profile</div>
          <div class="estimated-time" v-if="!user?.membership2xData">
            <EstimatedTime
              text="5 minutes"
            ></EstimatedTime>
          </div>
          <div class="done-green-button" v-else>
            <div class="inner-button flex">
              <img src="./../../assets/images/IMM/white-check.svg" />
              Done
            </div>
          </div>
        </div>
        <div class="text-content-paragraph">
          Unlock the true potential of your investments with Equilo and say
          goodbye to the hassle of juggling multiple systems or tedious manual
          methods for data management. With Equilo's IMM solution you can focus
          on what really matters – growing your portfolio companies and
          maximizing impact.
        </div>
      </div>
      <div class="button-section" v-if="!user?.membership2xData">
        <button
          class="flex justify-center"
          @click="$router.push({ path: '/imm/create-investor-profile' })"
        >
          Create now
          <img src="./../../assets/images/IMM/Vector.svg" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EstimatedTime from "./../../components/imm-components/estimated-time.vue";
export default {
  name: "CreateInvestorProfile",
  components: {
    EstimatedTime,
  },
  props: {
    user: Object,
    isInvestor: Boolean
  },
};
</script>
<style lang="scss" scoped>
.create-investor-container {
  width: 948px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #2c2e35;
  gap: 16px;

  .inner-create-investor-container {
    .step-section {
      p {
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #717686;
      }
    }

    .content-section {
      gap: 8px;
      margin-top: 8px;

      .text-content-heading {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
      }

      .text-content-paragraph {
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 16px;
      }
      .done-green-button {
        .inner-button {
          font-family: Arial;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: right;
          color: #ffffff;
          background: #4dc9be;
          width: 92px;
          height: 44px;
          padding: 12px 16px 12px 16px;
          border-radius: 28px;
          gap: 4px;
        }
      }
    }

    .button-section {
      margin-top: 32px;
      gap: 8px;

      button {
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        width: 202px;
        height: 48px;
        background: #191b45;
        color: #ffffff;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }

      img {
        margin-top: 8px;
        margin-left: 8px;
      }
    }
  }
}
</style>
