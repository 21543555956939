<template>
  <div>
    <p class="mb-3 text-gray-700 font-medium leading-normal text-sm pt-2">
      {{ this.portal == 'Verifier' ? 'Invite your colleague to work on 2X Certification Self-Assessment' : 'Invite Verifier' }}
    </p>
    <form class="sm:flex sm:max-w-md" @submit.prevent="submitForm">
      <label for="email-address" class="sr-only">Enter Verifier Email</label>
      <input
        v-model.trim="email"
        type="email"
        name="email-address"
        id="email-address"
        autocomplete="email"
        required
         class="appearance-none w-full px-4 py-2 border border-gray-300 text-base rounded-md text-[#191b45] bg-white placeholder-gray-500 focus:outline-none focus:ring-[#191b45] focus:border-[#191b45] lg:max-w-xs"
        placeholder="Enter Email Address"
      />
      <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button
          type="submit"
          :disabled="!isFormValid"
          :class="[
            'w-full border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white focus:outline-none',
            isFormValid ? 'bg-[#191b45] hover:bg-[#191b45]/90' : 'bg-gray-300 cursor-not-allowed'
          ]"
        >
          Invite
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Cookies from "@/helpers/cookies";
import env from "@/../env";

export default {
  name: 'EquiloEmailInviteForm',
  props: {
    portal: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      email: ''
    };
  },
  computed: {
    isFormValid() {
      return this.isValidEmail(this.email);
    }
  },
  methods: {
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    async submitForm() {
      if (this.isFormValid) {
        const payload = {
          email: this.email,
          portal: this.portal
        };
        try {
          const response = await fetch(`${env.apiUrl}/verifiers/invite`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${Cookies.get("session")}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
          const data = await response.json();
          if (!response.ok) {
            this.$swal.fire({
                icon: "error",
                text: `${data.message ? data.message : "Something went wrong!"}`,
                showConfirmButton: true,
          });
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          if (response.ok) {
            this.$swal.fire({
                icon: "success",
                text: data?.data,
                showConfirmButton: true,
          });
          }
        } catch (error) {
          if (error instanceof TypeError) {
            console.error('Network error:', error.message);
          } else if (error instanceof SyntaxError) {
            console.error('Invalid JSON:', error.message);
          } else {
            console.error('Error:', error.message);
          }
          this.$emit('error', error.message);
        }
        this.resetForm();
      }
    },
    resetForm() {
      this.email = '';
    }
  }
};
</script>
