<template>
    <div class="static ecosystem-landing pb-20">
        <div class="container mx-auto -mt-20 px-4 lg:px-0">
            <My2xBreadcrumbsVue class="pt-8 pb-8" />
            <div class="PoweredbyTitle_logo">
                <div class="PoweredbyContentWrepper">
                    <img src="@/assets/images/2X-Challenge-logo.png" alt="background-image" style="height: 50px;" />
                </div>
                <div style="display: flex; justify-content: flex-end">
                    <div class="Poweredby_logoImg mt-2">
                        <div class="powered-text flex">
                            <span>Powered by:</span>
                        </div>
                        <img src="@/assets/images/logo-navy.png" alt="background-image" />
                    </div>
                </div>
            </div>
            <div class="PoweredbyTitle_logo bg-image" style="padding: 0;">
                <div class="PoweredbyContentWrepper bg-text-style">
                    <div class="PoweredbyTitleWrepper mt-5">
                        <h1 class="text-6xl tracking-tighter font-medium print:text-7xl" style="color: #fff;">
                            <span>2X Challenge Investor Disclosures</span>
                        </h1>
                    </div>
                    <div class="flex flex-col mt-10 mx-auto items-center justify-center" style="max-width: 845px">
                        <p class="text-lg leading-7 font-medium gap-10 text-justify leading-7" style="color: #fff;">
                            The 2X Challenge Investor Disclosures is a published directory of investors participating in the
                            2X Challenge with disclosures.
                        </p>
                    </div>
                </div>
            </div>
            <div class="py-8 text-base">
                To improve your viewing experience, you can expand the width of each cell in the table below. Additionally, click "View Larger Version" in the bottom right corner to enlarge the entire table. This expanded view allows you to filter and group results as needed. You can also download a CSV file of the Investor Disclosures if your prefer to review on your desktop.
            </div>
            <transition name="fadeIn" mode="out-in">
                <div id="notLoaded" v-if="!endofcreated" key="loading">
                    <page-loader></page-loader>
                </div>
                <div id="loaded" v-if="endofcreated" key="content">
                    <div style="width:100%; text-align: center;">
                        <section class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300 mb-0">
                            <p>
                                <iframe class="airtable-embed"
                                    src="https://airtable.com/embed/appnY3HTFthCpb2y8/shrS6oF7lnUKtWlxy" frameborder="0"
                                    onmousewheel="" width="100%" height="1000"
                                    style="background: transparent; border: 1px solid #ccc;"></iframe>
                            </p>
                        </section>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import My2xBreadcrumbsVue from '../../components/My2xBreadcrumbs.vue';
export default {
    name: "twoXInvestorDisclosures",
    data: function () {
        return {
            user: [],
            endofcreated: false,
            languagePref: '&language=en'
        }
    },
    components: {
        pageLoader,
        My2xBreadcrumbsVue
    },
    async created() {
        this.user = await auth.me().catch(() => {
            console.log("Error login");
        });
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
            { name: "2X Challenge", url: "/2X-Challenge" },
            {
                name: "Investor Disclosures",
                url: "/investor-disclosures",
            },

        ]);
        this.endofcreated = true;
    },
};
</script>
<style lang="scss" scoped>
.ecosystem-landing {
    background-color: #F4F1ED;
    color: #21191D !important;
    font-family: Work Sans;

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 45px;
        color: #000;
    }

    .PoweredbyTitle_logo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .bg-text-style {
            margin-left: 9rem;
            position: relative;
            top: 20rem;
            color: #F4F1ED;
            width: 570px;
        }

        .PoweredbyTitleWrepper {
            max-width: 847px;
            display: flex;

            // align-items: center;
            img {
                width: 100px;
                height: 100px !important;
                object-fit: contain;
                display: none;
            }


        }

        .Poweredby_logoImg {
            display: flex;
            justify-content: flex-end;

            .powered-text {
                span {
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 35px;
                    font-weight: 400;
                    color: #2C2E35;
                }
            }

            img {
                width: 100px;
                height: 33px;
                object-fit: contain;
                margin-right: 35px;
                max-width: 100px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .bg-image {
        background-image:
            linear-gradient(201deg,
                rgba(0, 0, 0, 0.1) 55%,
                rgba(221, 166, 58, 0.8) 100%),
            url("./../../assets/images/2x-deal-directory-bg.png");
        height: 700px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }
}</style>