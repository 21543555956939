<script>
import axios from "axios";
import { Bar } from "vue-chartjs";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import IntersectionScore from "./IntersectionScore";
// function to configure charts
import { getTaabColors } from "./ChartFunction";

export default {
  name: "themeInterseections2",
  extends: Bar,
  data: function () {
    return {
      country: [],
      countryData1: [],
      countryData5: [],
      countryData4: [],
      theme: {},
      regionalData: [],
      incomeData: [],
      globalData: [],
      axes: [],
      axeColors: [],
      max: [],
      intersections: [],
      one: [],
      yLabel: 0,
      countryIntersection: [],
      regionalIntersection: [],
      incomeIntersection: [],
      globalIntersection: [],
      fullAxes: [],
      domains: [],
    };
  },
  methods: {
    calc: function (input) {
      if (typeof input !== undefined && input !== "" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    getCountry: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
              "/countries/" +
              _self.iso +
              "/sectors/" +
              _self.$route.params.sector,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getTheme: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
              "/reports/" +
              _self.iso +
              "/sectors/" +
              _self.$route.params.sector +
              "/themes/" +
              _self.themeId,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
  },
  props: ["iso", "themeId", "themeData", "countryD", "chartCategory"],
  async created() {
    // We will request the country OURSELF because of asyncness...
    this.country = await this.getCountry(); // lets do an await before rendering other items.
    this.country = [];
    this.theme = await this.getTheme(); // lets do an await before rendering other items.
    this.theme = [];
    this.country = this.countryD.countries[0];
    this.theme = this.themeData.countries[0];

    this.intersections = this.theme.intersections;
    for (var i = 0; i < this.intersections.length; i++) {
      this.one.push(1);

      this.countryIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality,
          this.intersections[i].gender_empowerment,
          this.intersections[i].gender_parity
        )
      );
      this.regionalIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_region,
          this.intersections[i].gender_empowerment_region,
          this.intersections[i].gender_parity_region
        )
      );
      this.incomeIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_income,
          this.intersections[i].gender_empowerment_income,
          this.intersections[i].gender_parity_income
        )
      );
      this.globalIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_global,
          this.intersections[i].gender_empowerment_global,
          this.intersections[i].gender_parity_global
        )
      );

      this.countryData1.push(
        this.calc(this.intersections[i].gender_equality || 0)
      );
      if (this.countryD.countries[1]) {
        this.regionalData.push(
          this.calc(
            this.countryD.countries[1].intersections[i].gender_equality || 0
          )
        );
      }
      if (this.countryD.countries[2]) {
        this.incomeData.push(
          this.calc(
            this.countryD.countries[2].intersections[i].gender_equality || 0
          )
        );
      }
      if (this.countryD.countries[3]) {
        this.countryData4.push(
          this.calc(this.countryD.countries[3].intersections[i].gender_equality)
        );
      }
      if (this.countryD.countries[4]) {
        this.countryData5.push(
          this.calc(this.countryD.countries[4].intersections[i].gender_equality)
        );
      }
      this.globalData.push(
        this.calc(this.intersections[i].gender_equality_global || 0)
      );

      this.fullAxes.push(this.intersections[i].theme);

      this.domains.push(this.intersections[i].taab);

      if(this.chartCategory == 'theme') {
        this.axes.push(this.intersections[i].taab);
      }
      if(this.chartCategory == 'domain') {
        this.axes.push(this.intersections[i].theme);
      }
      this.max.push(1.4);

      // get taab colors from function
      const taabColors = getTaabColors();
      this.axeColors.push(taabColors[this.intersections[i].taab]);
    }

    const _self = this;
    this.renderChart(
      {
        labels: this.axes,
        datasets: [
          {
            data: this.countryData1,
            label: this.country.humanName,
            borderColor: env.geoColors.countryspider,
            fill: true,
            backgroundColor: env.geoColors.countryspider,
            type: "bar",
          },
          {
            data: this.regionalData,
            label:
              this.countryD.countries[1] && this.countryD.countries[1].humanName
                ? this.countryD.countries[1].humanName
                : "hide",
            borderColor: env.geoColors.regionspider,
            pointBackgroundColor: env.geoColors.regionspider,
            fill: true,
            backgroundColor: env.geoColors.regionspider,
            type: "bar",
          },
          {
            data: this.incomeData,
            label:
              this.countryD.countries[2] && this.countryD.countries[2].humanName
                ? this.countryD.countries[2].humanName
                : "hide",
            borderColor: env.geoColors.incomespider,
            pointBackgroundColor: env.geoColors.incomespider,
            fill: true,
            backgroundColor: env.geoColors.incomespider,
            type: "bar",
          },
          {
            data: this.countryData4 ? this.countryData4 : [],
            label:
              this.countryD.countries[3] && this.countryD.countries[3].humanName
                ? this.countryD.countries[3].humanName
                : "hide",
            borderColor: env.chartColors.betaColor,
            pointBackgroundColor: env.chartColors.betaColor,
            fill: true,
            backgroundColor: env.chartColors.betaColor,
            type: "bar",
          },
          {
            data: this.countryData5 ? this.countryData5 : [],
            label:
              this.countryD.countries[4] && this.countryD.countries[4].humanName
                ? this.countryD.countries[4].humanName
                : "hide",
            borderColor: env.taabColors.accessToResources,
            pointBackgroundColor: env.taabColors.accessToResources,
            fill: true,
            backgroundColor: env.taabColors.accessToResources,
            type: "bar",
          },
          {
            data: this.globalData,
            label: "Global",
            borderColor: env.geoColors.lmicspider,
            pointBackgroundColor: env.geoColors.lmicspider,
            fill: true,
            backgroundColor: env.geoColors.lmicspider,
            type: "bar",
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        fontFamily: "basier",
        legend: {
          display: true,
          position: "bottom",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("hide");
            },
            fontSize: 13,
            fontFamily: "basier",
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 1.4,
                fontFamily: "basier",
              },
              scaleLabel: {
                display: true,
                labelString: "Gender Equality Score",
                fontStyle: "bold",
                fontSize: "16",
                fontFamily: "basier",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontSize: "13",
                fontFamily: "basier",
              },
            },
          ],
        },
        tooltips: {
          titleFontFamily: "basier",
          bodyFontFamily: "basier",
          callbacks: {
            title: function (tooltipItems) {
              const tt = JSON.parse(JSON.stringify(tooltipItems[0]));
              const xLabel = tt.xLabel;
              return (
                xLabel.toString().split(",").join(" ") +
                " / " +
                (tooltipItems[0].datasetIndex == 5
                  ? "Global"
                  : _self.countryD.countries[tooltipItems[0].index].humanName)
              );
            },
            label: function (tooltipItem) {
              return Number(tooltipItem.yLabel || 0).toFixed(1);
            },
          },
        },
      }
    );
  },
};
</script>

<style>
</style>
