<template>
  <div class="static">
    <!--      <div class="donatebutton mytooltip">
            <span class="mytext">Please consider donating to <a href="https://www.equilo.io/equilo-foundation" target="_blank" style="color:blue;">Equilo Foundation</a>, a 501(c)3, to support the growth and maintenance of the open source tools. Interested in sponsorship with your organization's logo? <a href = "mailto: hello@equilo.org" style="color:blue;">Reach out!</a></span>
         <form action="https://www.paypal.com/donate" method="post" target="_top">
<input type="hidden" name="hosted_button_id" value="P22ZH2YDV2CNW" />
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />

</form>
        </div>-->
    <!-- <img
      src="@/assets/images/background-image-overview.jpg"
      alt="background-image"
      class="object-contain h-64 no-print"
    /> -->
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full mt-20">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <img class="h-16 print:h-auto print:w-64" src="@/assets/images/SuperGIRL.png" />
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5">
                    SuperGIRL
                  </h1>
                </div>
                <div class="flex ml-[3.4%] mb-[2rem]">
                  <div class="gender_smart_button">
                    <div class="inner_section_gender_smart_button">
                      <router-link to="/submitGirlSource">
                        <button class="text-white text-sm font-medium" style="width: max-content">
                          Submit a GESI source
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center">
                  Use Equilo's curated Super Gender & Inclusion Resource Library (SuperGIRL) to help you in conducting a
                  literature review and deep-diving into specific GESI topics and geographies!
                </p>
              </div>
            </div>

          </div>
        </div>
        <main style="grid-template-columns:none !important">

          <div style="width:100%; text-align: center;">

            <section class="w-full container mx-auto margintop3 px-4 lg:px-0 border-t border-gray-300"
              style="max-width: 960px">

              <div class="flex flex-col mt-5 mb-5" v-scroll-reveal>
                <b>To use SuperGIRL:</b>
                <div class="text-gray-600 w-full text-justify mt-2 font-medium leading-7">
                  <p>

                  <ul style="list-style: disc">
                    <li>View a larger version in a separate page (Click the bottom)</li>
                    <li>Download the entire library in .CSV, or filter and download just what you need (Click the Download
                      .CSV button at the bottom</li>
                    <li>Search by key words, author names, and more (See the magnifying glass in the top right corner)
                    </li>
                    <li>Filter (See Filter button on top) by:
                      <ul style="list-style: disc">
                        <li>Sector</li>
                        <li>Thematic Area</li>
                        <li>Country</li>
                        <li>Resource type (e.g. study, article, video, case study, etc.)</li>
                      </ul>
                    </li>

                  </ul>
                  </p>

                </div>
              </div>
              <p>
                <b>Happy hacking!!</b>
              </p>
              <br />
              <p>
                <iframe class="airtable-embed"
                  src="https://airtable.com/embed/shr4JnngfURZtF478?backgroundColor=teal&layout=card&viewControls=on"
                  frameborder="0" onmousewheel="" width="100%" height="533"
                  style="background: transparent; border: 1px solid #ccc;"></iframe>
              </p>
              <br />
            </section>

          </div>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "supergirl",
  data: function () {
    return {
      active: false,
      endofcreated: false
    }
  },
  components: {
    pageLoader
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },

  },
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      { name: "SuperGIRL", url: "/supergirl" },
    ]);
    this.endofcreated = true;
  },
  async mounted() {

  },
};
</script>
<style lang="scss">
.gender_smart_button {
  .inner_section_gender_smart_button {
    text-align: right;

    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }
  }
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}
</style>
