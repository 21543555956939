<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-left: 285px"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px"
      />
    </div>
    <div style="height: 8rem"></div>
    <div class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0 pb-32">
      <div class="flex flex-row mt-32 lg:mt-0 justify-center">
        <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
          <h1
            class="
              text-6xl
              tracking-tighter
              font-medium
              text-center
              print:text-7xl
            "
            style="color: #201747"
          >
            <span>Assess</span>
          </h1>
          <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
            <ul
              class="
                leading-7
                font-medium
                gap-10
                text-justify
                leading-7
                font-medium
                text-gray-600
              "
              style="list-style-type: disc"
            >
              <li>
                Complete survey tailored to MSMEs corporates, funds, Fls, and
                institutional investors to assess 2x challenge criteria and
                eligibility, in addition to due diligence and institutional
                investor process standards
              </li>
              <li>
                Instantly view 2X eligibility, set targets and intentions, and
                upload Verification documents
              </li>
              <li>
                Optional: MSMEs, corporates, funds, and Fls can join the
                pipeline/ get listed in 2XC directory viewable by investors
              </li>
              <li>
                Optional: Institutional investors can make transparent
                commitments to strengthening gender smart process standards
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <div class="mt-8 grid grid-cols-3 gap-4">
        <router-link to="/2X-Ecosystem/My-2X-Assessments">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/world-in-hand.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>View Your Assessment</h2>
              <h4>
                View the results of your organization's 2X assessment or
                assessment results of organizations you have been granted
                permission to view, alongside relevant contextual gender
                equality data from the country where the organization operates.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/My2XAssessments3b.png"
              alt=""
            />
          </div>
        </router-link>

        <router-link to="/2X-Ecosystem/New-2XAssessment">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/notebook-pencil.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Start a New Assessment</h2>
              <h4>
                Ready to find out if your organization or investee is 2X
                eligible? Get started with the survey and view your assessment
                results instantly, alongside bonus contextual data.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/new2X-assessment.png"
              alt=""
            />
          </div>
        </router-link>

        <!-- 2x methodology -->
        <router-link to="/2X-Ecosystem/Methodology">
          <div class="container_foto container_foto-2x-gender long-text container_foto container_foto-2x-gender long-text-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/equilo eye purple background.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>View the Assessment Methodology</h2>
              <h4>
                View the methodology and criteria 2X utilizes to assess
                potential deals and current investments. Equilo utilizes the 2X
                Criteria, in combination
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/2X-methodology.png"
              alt=""
            />
          </div>
        </router-link>
        <!-- End 2x methodology -->

        <!-- Favorite Card -->
        <router-link to="/2X-Ecosystem/FavoriteAssessments">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/magnifying.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>View Favorite Assessments Shared with you</h2>
              <h4>
                Quickly access your favorite 2X Assessment results for your
                current or high potential investees. Whether an investee shared
                their results with you or you saved them as a favorite, this is
                your curated library of 2X assessments. You may search for a
                specific organization and jump to their 2X Assessment results.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/2X-myFavAssessment.png"
              alt=""
            />
          </div>
        </router-link>
        <!-- Favorite Card End -->
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
export default {
  name: "twoXAssessSubLanding",
  data: function () {
    return {
      user: [],
      active: false,
      paid: false,
      gbvpaid: false,
      access2X: false,
      dealPipelineStatus: [],
    };
  },
  async created() {
    this.dealPipelineStatus = await this.checkDealPipelineStatus();
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },
    checkDealPipelineStatus() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/check-deal-pipeline-status", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  },
  computed: {},
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if (this.user.access2x == false) {
      this.$router.push({ path: "/2X-Ecosystem" });
    }
    if (this.user.organization.subscription.price < 1) {
      this.paid = false;
    } else {
      this.paid = true;
    }
    if (this.user.access2x) {
      this.access2X = true;
    } else {
      this.access2X = false;
    }
    if (env.gbvteams.includes(this.user.organization.name)) {
      this.gbvpaid = true;
    } else {
      this.gbvpaid = false;
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      // { name: "2X Assessment", url: "/2X-Ecosystem" },
      { name: "Assess", url: "/2X-Ecosystem/twoXAssess" },
    ]);
  },
};
</script>

