<template>
  <div class="static">
    <div style="height: 4rem"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  "
                >
                  <!-- <img
                  class="h-16 print:h-auto print:w-64"
                  src="@/assets/images/2X-assessment/new2X-assessment.png"
                  /> -->
                  <br />
                  <h1
                    class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    "
                  >
                    2X Global and Equilo
                  </h1>
                </div>
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  "
                >
                  <h1
                    class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    "
                  >
                    2x match making Privacy Notice
                  </h1>
                </div>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                ></h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2X Global Limited (“<b>2X</b>”) and Equilo Inc.
                  (“<b>Equilo</b>”) are committed to protecting the privacy and
                  security of your personal information. This privacy notice
                  provides information on how we process your personal data as
                  part of the Match Making part of this website (“<b
                    >Match Making Pages</b
                  >”). Other parts of this website will be covered by separate
                  privacy notices.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  For the purposes of data protection laws, 2X and Equilo are
                  “joint controllers” of the processing of your personal data
                  that is described in your notice. This means that we both work
                  together to decide why and how your personal data is
                  processed. It also means that we are jointly legally
                  responsible to you for that processing.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We do not perform all processing of your personal data as
                  joint controllers. 2X makes decisions regarding certain
                  processing of your personal data in respect of your general
                  membership of 2X and Equilo processes the personal data of
                  representatives of organisations listed on the Match Making
                  Pages by collecting it, before sharing it with 2X for the
                  purposes of the 2X Match Making Pages. Both 2X and Equilo have
                  separate privacy notices explaining how they process personal
                  data independently, which are available at:
                  <a
                    href="https://airtable.com/shrPHiikjM1eP75cm"
                    target="_blank"
                  >
                    here</a
                  >.
                </p>
                <br />
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  Who are we?
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2X is 2X Global Limited, a company registered in
                  England and Wales under company registration number 13697512.
                  Our registered office is at Crowe U.K. LLP, 55 Ludgate Hill,
                  London, England, EC4M 7JW.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Equilo is Equilo Inc. Our address is: 1658 N. Milwaukee Ave.
                  Suite 100-45, Chicago, IL 60647, USA.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  "<b>We</b>", "<b>our</b>" or "<b>us</b>" refers both to 2X and
                  Equilo.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  If you have any questions about this privacy notice, please
                  contact
                  <a href="mailto: dataprotection@equilo.io" target="_blank">
                    dataprotection@equilo.io</a
                  >.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  What are the processing roles of 2X and Equilo?
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  As mentioned above, 2X makes decision regarding certain
                  processing of your personal data in respect of your general
                  membership of 2X and Equilo processes the personal data of
                  representatives of organisations listed on the Match Making
                  Pages by collecting it, before sharing it with 2X for the
                  purposes of the 2X Matchmaking Assessment.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Equilo has primary responsibility for the day-to-day handling
                  of your personal data as the Match Making Pages are provided
                  by it as a service to its members. As a result, Equilo is
                  responsible for the following activities relating to the
                  processing of your personal data for this purpose:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc; margin-left: 10px"
                >
                  <li>
                    acting as your main point of contact for any queries you may
                    have relating to this notice;
                  </li>
                  <li>
                    responding to your requests when you exercise your data
                    protection rights and submit such requests to Equilo (see
                    further below regarding these rights);
                  </li>
                  <li>
                    making sure it has the legal grounds to use your personal
                    data in the ways described in this notice;
                  </li>
                  <li>producing this notice jointly with 2X; and</li>
                  <li>
                    ensuring that the personal data it stores is secured and
                    retained in accordance with data protection laws.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2X provides strategic guidance in connection with the use and
                  development of the Match Making Pages and arranges for the
                  hosting of the Match Making Pages. As a result, 2X is
                  responsible for the following activities relating to the
                  processing of your personal data for this purpose:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc; margin-left: 10px"
                >
                  <li>
                    responding to your requests when you exercise your data
                    protection rights and submit such requests to 2X (see
                    further below regarding these rights);
                  </li>
                  <li>
                    making sure it has the legal grounds to use your personal
                    data in the ways described in this notice;
                  </li>
                  <li>producing this notice jointly with Equilo; and</li>
                  <li>
                    ensuring that the personal data it stores is secured and
                    retained in accordance with data protection laws.
                  </li>
                </ul>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  What does this Privacy Notice relate to?
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  This Privacy Notice applies to and sets out how we use the
                  personal data of our visitors to the Match Making Pages and
                  also of persons at the organisations listed on the Match
                  Making Pages. It is important that you read these additional
                  details so that you are aware of how and why we are using the
                  personal data we are collecting, storing and using about you.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  What personal information do we collect and how do we use it?
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  When you visit the Match Making Pages we will collect your
                  name, organisation, email details and password in order to
                  establish if you are a member of 2X with rights to view the
                  Match Making Pages. We will use this information to establish
                  whether you should be permitted to view the Match Making
                  Pages.
                </p>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  In addition, if you are a representative of one of the
                  organisations listed on the Match Making Pages we will also
                  process your personal data. However, we will process slightly
                  different personal data about you depending upon whether you
                  represent a corporate entity, a fund, or a financial
                  institution. The information processed for each category is
                  set out below.
                </p>
                <br />
                <li class="heading-li" style="list-style-type: none">
                  Corporates
                  <ul
                    class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                    style="list-style-type: disc; margin-left: 10px"
                  >
                    <li>
                      contact name, title, email address, and phone number for
                      the organization;
                    </li>
                    <li>
                      contact name, title, email address, and phone number of
                      the person authorized to enter into agreements on behalf
                      of the organization;
                    </li>
                    <li>documents validating women’s shares;</li>
                    <li>documents validating founding by women;</li>
                    <li>
                      documents validating the number of women senior managers
                      at the organization;
                    </li>
                    <li>
                      documents validating active women Board or Investment
                      Committee Members;
                    </li>
                    <li>documents validating number of women employees;</li>
                    <li>details of investors; and</li>
                    <li>
                      the name, employer and email address of anyone that
                      requested that you fill in the form.
                    </li>
                  </ul>
                </li>
                <br />
                <li class="heading-li" style="list-style-type: none">
                  Funds
                  <ul
                    class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                    style="list-style-type: disc; margin-left: 10px"
                  >
                    <li>
                      contact name, title, email address, and phone number for
                      the fund;
                    </li>
                    <li>
                      contact name, title, email address, and phone number of
                      the person authorized to enter into agreements on behalf
                      of the fund;
                    </li>
                    <li>documents validating women’s shares;</li>
                    <li>documents validating founding by women;</li>
                    <li>
                      documents validating the number of women with investment
                      responsibility at the fund;
                    </li>
                    <li>
                      documents validating active women Investment Committee
                      Members;
                    </li>
                    <li>
                      documents validating the number of women employees; and
                    </li>
                    <li>
                      the name, employer and email address of anyone that
                      requested that you fill in the form.
                    </li>
                  </ul>
                </li>
                <br />
                <li class="heading-li" style="list-style-type: none">
                  Financial Institution (FI)
                  <ul
                    class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                    style="list-style-type: disc; margin-left: 10px"
                  >
                    <li>
                      contact name, title, email address, and phone number for
                      the FI;
                    </li>
                    <li>
                      contact name, title, email address, and phone number of
                      the person authorized to enter into agreements on behalf
                      of the FI;
                    </li>
                    <li>documents validating women’s shares;</li>
                    <li>documents validating founding by women;</li>
                    <li>
                      documents validating the number of women senior managers
                      at the FI;
                    </li>
                    <li>
                      documents validating active women Board Members and
                      Investment Committee Members;
                    </li>
                    <li>documents validating the number of women employees;</li>
                    <li>details of investors; and</li>
                    <li>
                      the name, employer and email address of anyone that
                      requested that you fill in the form.
                    </li>
                  </ul>
                </li>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  The legal basis on which we collect and use your personal
                  information
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We process this information on the basis of our legitimate
                  interests:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc; margin-left: 10px"
                >
                  <li>
                    we have a legitimate interest in operating the Match Making
                    Pages to further the development of gender equality and
                    social inclusion;
                  </li>
                  <li>
                    we are only using your information in order to ensure 2X
                    members may view potential transactions, and contact
                    organisations, by means of the information published on the
                    Match Making Pages; and
                  </li>
                  <li>
                    accordingly, the processing that we undertake is limited and
                    is only for the purpose of displaying information about your
                    potential transactions and providing your contact details so
                    that you may be contacted by a potential investor.
                  </li>
                </ul>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  How we share your personal information
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We may share your personal data between 2X and Equilo and also
                  with the following organisations (or types of organisation)
                  for the following purposes: other potential investors,
                  including funds, grant makers, and other third-party technical
                  advisory service providers supporting investors, funds, grant
                  makers.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We may also share your personal information with third parties
                  who provide services to us to the extent that it is necessary
                  for us to do so.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  How long we keep your personal information
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We will store your data for approximately six years. Specific
                  personal and organizational identifying data will be erased
                  after that time period, unless you explicitly opt into further
                  annual listing renewals for the subject of maintaining
                  information within the database for exposure to investors, or
                  for your own or investor’s long-term monitoring. After this
                  time period, we will only store anonymized data for the
                  purposes of research and learning that will contribute to
                  field building of gender lens investment.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  How we transfer your personal information outside Europe
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2X is an English company, headquartered in the United Kingdom.
                  Equilo is an United States company, headquartered in the
                  United States.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  In the case of transfers out of the UK subject to UK data
                  protection law, the UK government has decided that particular
                  countries ensure an adequate level of protection of personal
                  data (known as an ‘adequacy regulation’). Where a country
                  benefits from an adequacy regulation, then we will rely on it
                  for transfers from the UK to that country. Where a country
                  does not, then we will rely on legally-approved standard data
                  protection clauses. If we cannot or choose not to continue to
                  rely on either of those mechanisms at any time, we will not
                  transfer your personal data outside the UK unless we can do so
                  on the basis of an alternative mechanism or exception provided
                  by UK data protection law and reflected in an update to this
                  notice.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  In the case of transfers out of the EEA subject to EEA data
                  protection laws, the European Commission has decided that
                  particular countries ensure an adequate level of protection of
                  personal data (known as an ‘adequacy decision’). Where a
                  country benefits from an adequacy regulation, then we will
                  rely on it for transfers from the EEA to that country. Where a
                  country does not, then we will rely on legally-approved
                  standard data protection clauses. If we cannot or choose not
                  to continue to rely on either of those mechanisms at any time,
                  we will not transfer your personal data outside the EEA unless
                  we can do so on the basis of an alternative mechanism or
                  exception provided by EEA data protection law and reflected in
                  an update to this notice.
                </p>

                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  <br />
                  <h1
                    class="text-3xl tracking-tighter font-medium print:text-7xl"
                  >
                    Automated decisions we take about you
                  </h1>
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We do not make automated decisions using this information.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  YOUR RIGHTS
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  You have a number of rights over your personal information,
                  which are:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    • the right to make a complaint to a supervisory authority
                    if you are unhappy about the way your personal data is being
                    used – in the UK please refer to the ICO’s website for
                    further information about this
                    <a href="https://ico.org.uk/" target="_blank"
                      >(https://ico.org.uk/)</a
                    >
                    or to that of one of the supervisory authorities in the EEA
                    (see:
                    <a
                      href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
                      target="_blank"
                      >Our Members | European Data Protection Board
                      (europa.eu)</a
                    >);
                  </li>
                  <li>
                    • the right to ask us what personal information about you we
                    are holding and to have access to a copy of your personal
                    information;
                  </li>
                  <li>
                    • the right to ask us to correct any errors in your personal
                    information;
                  </li>
                  <li>
                    • the right, in certain circumstances such as where our use
                    of your personal information is based on your consent and we
                    have no other legal basis to use your personal information,
                    to ask us to delete your personal information;
                  </li>
                  <li>
                    • the right, in certain circumstances such as where we no
                    longer need your personal information, to request that we
                    restrict the use that we are making of your personal
                    information;
                  </li>
                  <li>
                    • the right, in certain circumstances, to ask us to review
                    and explain our legitimate interests to you; and
                  </li>
                  <li>
                    • the right, where our use of your personal information is
                    carried out for the purposes of an agreement with us and is
                    carried out by automated means, to ask us to provide you
                    with a copy of your personal information in a structured,
                    commonly-used, machine-readable format.
                  </li>
                </ul>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  CHANGES TO OUR PRIVACY POLICY
                </h1>
                <br />
                <p
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium"
                  style="margin-bottom: 2rem"
                >
                  We keep our privacy notice under regular review and will
                  update it from time to time to make sure it remains up-to-date
                  and accurate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "dealsPrivacyPolicy",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      userEmail: null,
      isReadMore: false,
    };
  },
  components: {
    pageLoader,
  },
  methods: {},
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "2X Global Data Privacy",
        url: "/dataprivacy",
      },
    ]);
    this.endofcreated = true;
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
  },
};
</script>

<style scoped>
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}
</style>
<style scoped>
.heading-li {
  font-size: 20px;
}
li {
  font-size: 16px;
}
a {
  color: #40a9ff;
}
</style>
