<template>
  <div class="table-container">
    <table class="inner-table">
      <tr class="table-heading-row">
        <th v-for="(head, i) in tableData.heading" :key="'head' + i">
          {{ head }}
        </th>
      </tr>
      <tr v-if="!tableData.data.length" class="table-content-row text-center py-2 h-full">
        <td colspan="100%" class="py-8 align-middle">Nothing to show</td>
      </tr>
      <tr
        class="table-content-row"
        v-for="data in tableData.data"
        :key="data.rowHeading"
      >
        <td class="w-1/12">{{ data.indicator }}</td>
        <td class="w-1/12">{{ data.dimension }}</td>
        <td class="text-left font-bold w-3/12">
          <span>{{ data.question }}</span>
        </td>

        <td class="text-left w-2/12" v-if="typeof data.changedFrom == 'object'" style="color: #FF3A21">
          <ul style="list-style: disc;">
            <li v-for="change in data.changedFrom" :key="change">
              {{ change }}
            </li>
          </ul>
        </td>
        <td v-else class="text-left w-2/12" style="color: #FF3A21">{{ }} {{ data.changedFrom }}</td>

        <td class="text-left w-2/12" v-if="typeof data.changedTo == 'object'" style="color: #4C9F38">
          <ul style="list-style: disc;">
            <li v-for="change in data.changedTo" :key="change">
              {{ change }}
            </li>
          </ul>
        </td>
        <td v-else class="text-center w-2/12" style="color: #4C9F38">{{ data.changedTo }}</td>

        <td class="text-center font-bold w-1/12">{{ moment(data.latestUpdatedDate).format("DD-MM-YYYY") }}</td>
        <td class="w-2/12">
          <div class="flex justify-center items-center gap-2">
            <img
            style="margin: 0"
            src="@/assets/images/Rightcircle.svg"
            alt="background-image"
            v-if="
                data?.verificationStatus === true"
            />
            <img
            style="border-radius: 0; margin: 0;"
            class="mr-2"
            src="@/assets/images/3rdPartyvalidated.svg"
            alt="background-image"
            v-if="data?.verificationStatus === false"
            />
            <div
              v-if="data?.verificationStatus === ''"
              class="checkbox-label flex items-center justify-center w-6 h-6 bg-gray-300 border-1 border-gray-500 rounded-full cursor-pointer"
            ></div>
            {{ data?.verificationStatus === true ? 'Verified ' : (data?.verificationStatus === false ? 'Unverified ' : 'Pending') }}
            <span v-if="data?.verificationDate"><b>({{ `on ${moment(data.verificationDate).format("DD-MM-YYYY")}`  }})</b></span>
          </div>
        </td>
      </tr>
    </table>
    <ValidationRejectModal
      v-if="isValidationModal"
      @close="(isValidationModal = false), (selectedBadge = null)"
      :selectedBadge="selectedBadge"
      @reject="$emit('validationDataRefresh')"
    />
  </div>
</template>
<script>
import ValidationRejectModal from "@/components/2x-certification/validationRejectionModal.vue";
import apiService from "@/helpers/apiServices.js";
export default {
  name: "ValidationRecommendationTable",
  props: ["tableData"],
  data() {
    return {
      isValidationModal: false,
      selectedBadge: null,
      status: null,
    };
  },
  components: {
    ValidationRejectModal,
  },
  methods: {
    updateValidationStatus(data) {
      this.selectedBadge = data;
      apiService
        .putValidationRecommendsStatusByVerifier(
          this.$route.query.teamId,
          data,
          "Accepted"
        )
        .then(() => {
          this.$emit("validationDataRefresh");
          this.$swal.fire({
            icon: "success",
            text: "Approved successfully!",
            showConfirmButton: true,
          });
          this.documentComment = "";
          this.selectedBadge = null;
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong!",
            showConfirmButton: true,
          });
          this.selectedBadge = null;
        });
    },
    openRejectModal(data) {
      this.isValidationModal = true;
      this.selectedBadge = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #e1e3e9;

  .red-button {
    font-weight: bold;
    font-size: 14px;
    color: #ff3a21;
    border-style: solid;
    border-color: #ff3a21;
    border-width: 1px;
  }

  .green-button {
    font-weight: bold;
    font-size: 14px;
    color: #3f7e44;
    border-style: solid;
    border-color: #3f7e44;
    border-width: 1px;
  }

  .disabled {
    border-color: rgb(204, 204, 204);
    color: rgb(204, 204, 204);
    cursor: text;
  }

  .rejected-status {
    color: #ff3a21;
    padding: 5px 8px;
    border: 1px solid #ff3a21;
    border-radius: 7px;
    cursor: default;
    font-size: 14px;
    font-weight: bold;
  }

  .accepted-status {
    color: #3f7e44;
    padding: 5px 8px;
    border: 1px solid #3f7e44;
    border-radius: 7px;
    cursor: default;
    font-size: 14px;
    font-weight: bold;
  }

  .inner-table {
    width: 100%;

    th,
    td {
      border: 1px solid #e1e3e9;
    }

    .table-heading-row {
      th {
        padding: 16px;
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
          linear-gradient(0deg, #e1e3e9, #e1e3e9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }

      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }

      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }

    .table-content-row {
      img {
        width: 24px;
        height: 24px;
        margin: auto;
      }

      td {
        border-bottom: none;
        height: 85px;
        padding: 16px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2c2e35;
      }

      td:first-child {
        border-left: none;
      }

      td:last-child {
        border-right: none;
      }
    }
  }

  .red {
    color: #cb2e00;
  }

  .green {
    color: #13a094;
  }
}
</style>
