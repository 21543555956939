<template>
  <div class="static">
    <!-- <img
      src="@/assets/images/background-image.jpg"
      alt="background-image"
      class="w-full z-0 -mt-20"
    /> -->
    <div class="w-full container mx-auto mt-20 px-4 lg:px-0">
      <div class="flex flex-row mt-32 lg:mt-0">
        <div class="flex flex-col w-6/12 lg:w-9/12">
          <p
            class="tracking-tight font-medium text-2xl"
            style="color: #201747;"
          >
            Now choose a sector
          </p>
        </div>
        <div class="flex ml-auto mt-auto w-6/12 lg:w-3/12">
          <div
            class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4"
          >
            <svg
              class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Artboard 329</title>
              <circle
                cx="11.059"
                cy="11.059"
                r="7.059"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                x1="20"
                y1="20"
                x2="16.05"
                y2="16.05"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <input
              type="text"
              class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
              v-model="q"
              placeholder="Search for a sector"
            />
          </div>
        </div>
      </div>
      <div v-if="count === -1">
        <router-link
          to="/settings/configure-subscription-sector"
          style="background-color: #FE5000; color: white !important; width: 300px; position: absolute; "
          class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg  transition duration-200 shadow border flex flex-row items-center justify-center"
        >
          <span>You can choose your sectors here</span>
          <svg
            class="w-5 h-5"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g
              stroke-linecap="round"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linejoin="round"
            >
              <path d="M16.95,7.05l-9.9,9.9"></path>
              <path d="M10,7l6.95,0.049l0.05,6.951"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
        </router-link>
      </div>
    </div>
    <transition name="fadeIn" mode="out-in">
      <div
        id="notLoaded"
        key="loading"
        v-if="data.length <= 0 && q.length === 0 && industries.length <= 0"
      >
        <page-loader></page-loader>
      </div>
      <div
        id="noCountries"
        key="countreis"
        v-if="data.length <= 0 && q.length !== 0"
      >
        <div
          class="flex flex-col items-center justify-center w-full print:h-screen mt-32"
        >
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3">
              <div class="flex flex-col item-center justify-center">
                <h1 class="text-2xl text-gray-500 font-medium">
                  No Sectors Found
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div id="loaded" key="loaded" v-if="data.length > 0 && industries.length > 0">
        <div class="w-full container mx-auto mt-20 px-4 lg:px-0">
          <div class="flex mt-20 mb-20">
            <div class="w-4/12 flex flex-row ">
              <img
                class="mr-3 mt-5 w-3/12 h-10 icon-size"
                src="https://api.equilo.io/ICONS/Theme icons/personal-agency.svg"
              />
              <div
                class="w-9/12 font-medium pt-6 text-l capitalize"
                :style="'color: ' + env.brandingColors.navy"
              >
                No sector
              </div>
            </div>

            <div class="w-8/12">
              <transition-group
                name="slide-in"
                tag="div"
                class="grid grid-cols-2 lg:grid-cols-3 gap-2"
                appear
                :style="{ '--total': 5 }"
              >
                <template class="col-span-1">
                  <!-- country is active -->
                  <div
                    class="flex flex-row items-center px-5 py-3 hover:bg-gray-200 rounded-lg transition duration-300 ease-in-out cursor-pointer relative"
                    v-bind:key="'xxxxx'"
                    @click="openSector('country')"
                  >
                    <p
                      class="text-gray-600 text-sm font-medium"
                      style="margin-top:7px; margin-bottom:7px;"
                    >Proceed without a sector
                    </p>
                  </div>
                </template>
              </transition-group>
            </div>
          </div>

          <div
            class="flex mt-20 mb-20"
            :id="mainSector.id"
            v-for="(mainSector, index3) in filteredIndustries"
            v-bind:key="mainSector._id + index3"
          >
            <div class="w-4/12 flex flex-row ">
              <img class="mr-3 mt-5 w-3/12 h-10 icon-size" :src="mainSector.icon" />
              <div
                class="w-9/12 font-medium pt-6 text-l capitalize"
                :style="'color: ' + env.brandingColors.navy"
              >
                {{ mainSector.name.replace("General", "").trim() }}
              </div>
            </div>
            <!--<div style="padding-right: 100px;">
                        <button style="height: 70px;" class="px-3 py-2 text-xs bg-blue-100 hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-indigo-500 hover:text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                            <span>{{subregion}}</span>
                        </button>
                        </div>-->
            <div class="w-8/12">
              <transition-group
                name="slide-in"
                tag="div"
                class="grid grid-cols-2 lg:grid-cols-3 gap-2"
                appear
                :style="{ '--total': 5 }"
              >
                <template
                  class="col-span-1"
                  v-for="(sector, index2) in sectors"
                >
                  <!-- country is active -->
                  <div
                    v-if="
                      mainSector.id === sector.parent &&
                        sector.status === 'ACTIVE'
                    "
                    style="background-color: #201747; color: white !important; cursor: pointer;"
                    class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center  text-center justify-center"
                    v-bind:key="sector + index2"
                    @click="openSector(sector)"
                  >
                    <p class="text-white text-sm font-medium">
                      {{ sector.name }}
                    </p>
                  </div>

                  <div
                    v-else-if="
                      mainSector.id === sector.parent &&
                        sector.status === 'LOCKED'
                    "
                    :style="{ '--i': 5 }"
                    style="background-color: #666666; color: white !important; cursor: pointer;"
                    class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center text-center justify-center"
                    v-bind:key="sector+'' + index2"
                    @mouseover="mouseOver(index2)"
                    @mouseleave="mouseOver(false)"
                  >
                    <p class="text-white" label="test">{{ sector.name }} 🔐</p>
                    <transition name="slide-fade">
                      <div
                        v-if="active === index2"
                        class="absolute top-100 mt-20 bg-gray-100 p-4 rounded-lg shadow-xl text-xs z-10 text-gray-800"
                      >
                        Coming soon!
                      </div>
                    </transition>
                  </div>
                </template>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const axios = require("axios");
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";

export default {
  name: "Sectors",
  data: function() {
    return {
      q: "",
      country: {},
      data: [],
      count: 0,
      industries: [],
      active: false,
      env,
      //subregions: []
      countryData: []
    };
  },
  methods: {
    openSector: function(sector) {
      if((sector != "country" && sector?.id != "BASE") && !sector?.subsector) {
        this.$router.push({ name: "notAllowed" });
        return;
      }
      sector = sector?.id ? sector.id : sector;
      if(this.$route.params.gbv) {
          if(sector == "country" || sector.includes("INFR_") || sector.includes("AGRI_")
            || sector.includes("ENRG_") || sector.includes("FNCE_") || sector.includes("WASH_")
            || sector.includes("HLTH_") || sector.includes("MANF_GENERL") || sector.includes("ENVR_GENERL")
            || sector.includes("ECON_GENERL") || sector.includes("CVID_GENERL")) {
            this.$router.push({
              path: "/gbv-risk/" + this.$route.params.iso2 + "/" + sector+"/home",
            });
          } else {
            this.$router.push({
              path: "/gbv-risk/" + this.$route.params.iso2 + "/" + sector,
            });
          }
      }else{
        this.$router.push({
          path: "/gesi-analysis/" + this.$route.params.iso2 + "/" + sector + "/snapshot",
        });
      }
    },
    mouseOver: function(index) {
      this.active = index;
    },
    getCountry: function() {
      const _self = this;
      const isoCodes = this.$route.params.iso2.split(',');
      return new Promise(function (resolve) {
        let iso = { isoCodes }
        axios
          .post(
            env.apiUrl +
              "/countries" +
              "/sector-summary/" +
              _self.$route.params.sector,iso,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
    getIndustries: function() {
      return new Promise(function(resolve) {
        axios
          .get(env.apiUrl + "/industries", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
  },
  components: {
    pageLoader,
  },
  computed: {
    sectors: function() {
      const searchResults = [];
      if (this.q) {
        for (var i = 0; i < this.data.length; i++) {
          if (
            searchResults.indexOf(this.data[i]) == -1 &&
            this.data[i] &&
            this.data[i]["name"] &&
            this.data[i]["name"].toLowerCase().indexOf(this.q.toLowerCase()) >
              -1
          ) {
            searchResults.push(this.data[i]);
            //break;
          }
        }
        return searchResults;
      } else {
        const _self = this;
        for (let i = 0; i < this.data.length; i++) {
//          if (this.data[i].status === "ACTIVE") {
//            _self.data[i].rank = 1;
//          } else {
//            _self.data[i].rank = 2;
//          }
       
         
          //  _self.data[i].rank = this.data[i].rank;
           
//            if(this.data[i].parent == "AGRI"){
////                
//            }
            
        }
        _self.data.sort((a, b) => {
          return a.rank - b.rank;
        });
        return this.data;
      }
    },
    filteredIndustries: function() {
      const searchResults = [];
      if (this.q) {
        for (var i = 0; i < this.data.length; i++) {
          if (
            searchResults.indexOf(this.data[i]) == -1 &&
            this.data[i] &&
            this.data[i]["name"] &&
            this.data[i]["name"].toLowerCase().indexOf(this.q.toLowerCase()) >
              -1
          ) {
            
            for (var j= 0; j < this.industries.length; j++) {
              if (this.data[i]["parent"] == this.industries[j]['id']) {
                if(!searchResults.includes(this.industries[j]))  {
                    searchResults.push(this.industries[j]);
                }
              }
            }
          }
        }
        return searchResults;
      } else {
        return this.industries;
                    }
                }
  },
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    var grabAll = false;
    if(!this.$route.params.gbv){
        // if (!this.user.organization.accessGesi) {
        //   this.$router.push({ path: "/" });
        // }
        if (
          !this.user.organization.accessGesi || 
          document.location.href.indexOf("print=") > -1
        ) {
          this.$router.push({ name: "notAllowed" });
        }
        if (!this.user.organization) {
            this.$router.push({
              path: "/reports/" + this.$route.params.iso2 + "/country/",
            });
          }
    }

    if (this.$route.params.iso2 == "EQ") {
      this.$router.push({
        path: "/reports/" + this.$route.params.iso2 + "/AGRI_GENERL/",
      });
    }

    this.countryData = await this.getCountry(); // lets do an await before rendering other items.
    this.country = this.countryData.countries[0];

    //this.industries = await this.getIndustries(); // lets do an await before rendering other items.
    var citationPath = this.$route.path;
    var citationPath2 = "/gesi-analysis"
    var citation3 = "GESI Analysis"
    if(this.$route.params.gbv){
        //  citationPath="/gbv-risk/choose-sector/true"
         citationPath2 = "/gbv-risk"
         citation3 = "GBV Risk"
         grabAll= true;
    }else{
        //  citationPath="/gesi-analysis/choose-sector"
         citationPath2 = "/gesi-analysis"
         citation3 = "GESI Analysis"
    }
    const _self = this;
    const url =
      env.apiUrl + "/organizations/" + this.user.organization._id + "/sectors";
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
        params: { iso : this.$route.params.iso2 }
      })
      .then(function(res) {
        _self.data = res.data.data;
        _self.$store.commit("downloadable", false);
        _self.$store.commit("isFreeState", false);
        _self.$store.commit("setBreadcrumbs", [
          { name: citation3, url: citationPath2 },
          {
            name: _self.$route.params.iso2.split(",").length == 1 ? _self.country.humanName : "Multiple Countries",
            url: _self.$route.params && _self.$route.params.gbv ? "/gbv-risk/choose-country/true" : "/gesi-analysis/choose-country",
          },
          {
            name: "Choose Sector",
            url: citationPath,
          },
        ]);
      });

    const urlindustries =
      env.apiUrl +
      "/organizations/" +
      this.user.organization._id + "/" +
      grabAll+
      "/industries";
    axios
      .get(urlindustries, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      })
      .then(function(res) {
        _self.count = res.data.count;
        _self.industries = res.data.data;
        //_self.filteredIndustries = JSON.parse(JSON.stringify(_self.industries));
      });
    
  },
};
</script>
<style>
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}
.icon-size{
    width: 70px !important;
    height: 70px !important;
    margin-top: 0px !important;
}
</style>
