import { SUBSCRIPTION_TYPE } from "./subscriptionTypes";

export function canAccess(path, subscriptionType) {
  const permits = getPermits(path);
  // change this to false when all routes are implemented
  if (!permits) {
    return true;
  }
  return permits.subscriptions.includes(subscriptionType);
}

export function canAction(path, action, subscriptionType) {
  const actions = getActions(path);
  if (!actions) {
    return true;
  }
  return actions[action].includes(subscriptionType);
}

export function getActions(path) {
  return actions[path];
}

export function getPermits(path) {
  return permits[path];
}

// routes that are not in the permits object are accessible to all subscription types
const permits = {
  "gbv/materiality": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/gbv/risk-models": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/gbv/heat-map": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "gbv/methodology": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/supergirl": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/partner-directory": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/gbv-risk/EQ/INFR_GENERL/take-action": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/roi/roiPortfolio": {
    subscriptions: [SUBSCRIPTION_TYPE.PREMIUM, SUBSCRIPTION_TYPE.ENTERPRISE],
  },
  "/2X-Ecosystem/My-2X-Assessments/contextual-analysis": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/GenderSmartTools" :{
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/submitGenderSmartTools" :{
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/submitDirectory" : {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "2X-Ecosystem/submitTSP": {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/submitGirlSource": {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
};

const actions = {
  "/roi/roiAssessment": {
    downloadPDF: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
    downloadExcel: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
};
