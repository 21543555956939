<template>
  <div
    class="relative w-[150px] mb-1 block"
    :key="'verificationdropdown' + value.isVerified"
    ref="dropdown"
  >
    <div
      @click="toggleDropdown"
      class="flex items-center justify-between px-2 py-1 border border-gray-300 rounded-md cursor-pointer"
    >
      <div
        v-if="value.isVerified === 'Verified'"
        class="flex items-center gap-2 p-1 cursor-pointer hover:bg-gray-100"
      >
        <img
          src="@/assets/images/rightsign.svg"
          alt="Verified"
          class="w-5 h-5"
        />
        <div>Verified</div>
      </div>
      <div
        v-else-if="value.isVerified === 'Unverified'"
        class="flex items-center gap-2 p-1 cursor-pointer hover:bg-gray-100"
      >
        <img
          src="@/assets/images/Crosscircle.svg"
          alt="Unverified"
          class="w-5 h-5"
        />
        <div>Unverified</div>
      </div>
      <div
        v-else
        class="flex items-center gap-2 p-1 cursor-pointer hover:bg-gray-100"
      >
        <div
          class="flex items-center justify-center mr-1 w-4 h-4 bg-gray-300 border-1 border-gray-500 rounded-full cursor-pointer"
        ></div>
        <div>Pending</div>
      </div>
      <svg
        :class="{ 'rotate-180': isVerificationDropdownOpen }"
        class="w-4 h-4 text-gray-400 transform duration-200"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </div>

    <div
      v-if="isVerificationDropdownOpen"
      class="absolute left-0 pb-1 z-10 w-full bg-white border border-gray-300 rounded-md shadow-sm"
    >
      <div
        @click="setVerifiedStatus(value, 'Pending')"
        class="flex items-center gap-2 p-2 cursor-pointer border-b-[1px] hover:bg-gray-100"
      >
        <div
          class="flex items-center justify-center mx-[2px] w-4 h-4 bg-gray-300 border-1 border-gray-500 rounded-full cursor-pointer"
        ></div>

        <span>Pending</span>
      </div>
      <div
        @click="setVerifiedStatus(value, 'Verified')"
        class="flex items-center gap-2 p-2 cursor-pointer border-b-[1px] hover:bg-gray-100"
      >
        <img
          src="@/assets/images/rightsign.svg"
          alt="Verified"
          class="w-5 h-5"
        />
        <span>Verified</span>
      </div>
      <div
        @click="setVerifiedStatus(value, 'Unverified')"
        class="flex items-center gap-2 p-2 cursor-pointer hover:bg-gray-100"
      >
        <img
          src="@/assets/images/Crosscircle.svg"
          alt="Unverified"
          class="w-5 h-5"
        />
        <span>Unverified</span>
      </div>
    </div>
  </div>
</template>
<script>
import apiService from "@/helpers/apiServices.js";
export default {
  name: "VerificationDropdown",
  data() {
    return {
      isVerificationDropdownOpen: false,
    };
  },
  props: {
    value: Object,
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.isVerificationDropdownOpen = !this.isVerificationDropdownOpen;
    },
    async setVerifiedStatus(value, status) {
      this.isVerificationDropdownOpen = false;
      const teamId = this.$route?.query?.teamId;
      const keyVal =
        value.surveyQType === "Calculated" ? value.oldKey : value.key;

      const oldStatus = this.value.isVerified;
      if (oldStatus !== status) {
        this.$set(this.value, "isVerified", status);
        await apiService.putVerifiedStatus(teamId, keyVal, status);
      }
    },
    handleClickOutside(event) {
      if (
        this.isVerificationDropdownOpen &&
        !this.$refs.dropdown.contains(event.target)
      ) {
        this.isVerificationDropdownOpen = false;
      }
    },
  },
};
</script>
