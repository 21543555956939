<template>
  <div class="flex my-[1rem]">
    <h3 v-if="!clustered" class="font-bold max-w-[32rem]">{{ title }}:</h3>
    <div class="flex ml-2" v-if="!noInfo">
      <div v-if="value">
        <div v-if="typeof value == 'object'">
          <span v-for="item in value" :key="item">{{ item }}</span>
        </div>
        <span v-else>{{ value }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "openText",
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    clustered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: this.info.title,
      value: this.info.value,
      noInfo: false,
    };
  },
};
</script>
