<template>
  <div class="static print:text-xs">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated && !country.income" key="loading">
        <page-loader></page-loader>
      </div>
      <div
        id="loaded"
        v-if="endofcreated && country.income"
        key="content"
        class="mx-12 mt-6"
      >
        <!--NO DASHBOARDS-->
        <div
          v-if="!showDashboard"
          class="flex flex-col mt-5 w-full print:h-screen"
        >
          <!--sticky top-0 z-50 -->
          <div class="bg-white pt-12 pb-2">
            <div>
              <!--TITLE & FILTERS-->
              <div class="flex flex-col my-3">
                <!--TITLE-->
                <div class="flex flex-row justify-between">
                  <h1
                    class="text-2xl font-black text-left print:text-7xl"
                    :style="{ color: '#201747', fontWeight: 'bold' }"
                  >
                    GESI Data Explorer
                  </h1>
                  <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                    @click="toggleDashboard"
                  >
                    Dashboards
                  </button>
                </div>

                <!--MAIN FILTERS-->
                <div class="flex flex-row mt-2">
                  <dropdown
                    :data="countries"
                    :label="'filter'"
                    v-model="currentCountry"
                    :title="'Geography'"
                  ></dropdown>
                  <dropdown
                    :data="sectorsList"
                    :label="'filter'"
                    v-model="selectedSector"
                    :title="'Industry'"
                  ></dropdown>
                  <dropdown
                    :data="domainNames"
                    :label="'filter'"
                    v-model="currentDomain"
                    :title="'Domain'"
                  ></dropdown>
                  <dropdown
                    :data="themeNames"
                    :label="'filter'"
                    v-model="currentTheme"
                    :title="'Theme'"
                  ></dropdown>
                </div>

                <!--OVERVIEW & SEARCH-->
                <div class="mt-2 mb-1">
                  <!--SUB FILTERS-->
                  <div class="flex mt-3" v-if="!isOverviewSelected">
                    <!--SEARCH-->
                    <div class="relative w-1/3 mr-5">
                      <label for="search">Indicator</label>
                      <input
                        type="text"
                        v-model="searchTerm"
                        @input="debounceSearch"
                        placeholder="Search by key words"
                        class="w-full px-2 py-4 mt-2 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline border-2 border-gray-500"
                        :style="{ height: '52%' }"
                      />
                      <span
                        class="absolute inset-y-0 right-0 flex items-center pr-1 pt-5"
                      >
                        <svg
                          class="h-6 w-6 text-gray-700"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 28 28"
                          stroke="currentColor"
                          @click="searchTerm = ''"
                          :class="{ hidden: searchTerm === '' }"
                        >
                          <path
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <h1
                  v-if="!isOverviewSelected"
                  class="text-2xl font-black text-left print:text-7xl mt-4 mx-1"
                  :style="{ color: '#201747', fontWeight: 'bold' }"
                >
                  ({{
                    filteredIndicators.length
                      ? filteredIndicators.length
                      : "Here are a few indicators to get you started"
                  }}
                  indicators)
                </h1>
                <span class="mx-1"
                  >Use the filters above to search for specific data!</span
                >
              </div>
            </div>
          </div>

          <!--CONTENT-->
          <div>
            <!--LOADING-->
            <div
              v-if="loading"
              class="flex items-center justify-center h-full mt-24"
            >
              <div class="flex flex-col item-center justify-center">
                <div class="flex flex-row items-center justify-center">
                  <clip-loader
                    :color="env.brandingColors.teal"
                    :size="'120px'"
                  ></clip-loader>
                </div>
                <h1
                  :style="'color:' + env.brandingColors.teal"
                  class="text-2xl tracking-tighter font-semibold text-center mt-10"
                >
                  Just a moment...
                </h1>
                <h1
                  :style="'color:' + env.brandingColors.teal"
                  class="text-md tracking-tighter font-medium text-center"
                >
                  We are hacking equality.
                </h1>
              </div>
            </div>

            <!--INDICATORS-->
            <div
              v-if="
                filteredIndicators.length && !isOverviewSelected && !loading
              "
              class="mt-4 px-1 pb-10"
            >
              <!-- <div>
                <button
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-5 rounded-lg"
                  @click="sorted = !sorted"
                >
                  Sort by {{ !sorted ? "rank" : "name" }}
                </button>
              </div> -->
              <div
                class="indicators-list grid xl:grid-cols-2 grid-cols-1 gap-10 overflow-y-scroll"
              >
                <indicator
                  v-for="(indicator, index) in filteredIndicators"
                  :key="indicator.indicatorId"
                  :index="index"
                  :indicator="indicator"
                  :country="country"
                  :country-data="countryData"
                  :currency="currency"
                  :chart-labels-for-charts="chartLabelsForCharts"
                  :domain-data-unfiltered="countryData"
                  :userId="user._id"
                  :is-dashboard="false"
                  @user-fetch-required="fetchUser"
                />
              </div>
            </div>
          </div>
        </div>
        <!--DASHBOARDS-->
        <div v-else class="flex flex-col mt-5 w-full print:h-screen">
          <!--sticky top-0 z-50 -->
          <div class="bg-white pt-12 pb-2">
            <div>
              <!--TITLE & FILTERS-->
              <div class="flex flex-col my-3">
                <!--TITLE-->
                <div class="flex flex-row justify-between">
                  <h1
                    class="text-2xl font-black text-left print:text-7xl"
                    :style="{ color: '#201747', fontWeight: 'bold' }"
                  >
                    GESI Data Explorer Dashboards
                  </h1>
                  <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                    @click="showDashboard = false"
                  >
                    Return to main view
                  </button>
                </div>
                <div>
                  <div class="flex">
                    <div
                      v-for="(dashboard, index) in this.user
                        .dataExplorerDashboards"
                      :key="index"
                      class="cursor-pointer py-2 px-4 hover:bg-blue-700 bg-blue-500 mx-2 text-white rounded-lg"
                      @click="currentDashboardIndex = index"
                    >
                      {{ dashboard.name }}
                    </div>
                  </div>
                </div>

                <!--MAIN FILTERS-->
                <div class="flex flex-row mt-2">
                  <!--            <dropdown
                    :data="countries"
                    :label="'filter'"
                    v-model="currentCountry"
                    :title="'Geography'"
                  ></dropdown> -->
                </div>
                <h1
                  v-if="!isOverviewSelected"
                  class="text-2xl font-black text-left print:text-7xl mt-4 mx-1"
                  :style="{ color: '#201747', fontWeight: 'bold' }"
                >
                  {{
                    this.user.dataExplorerDashboards[currentDashboardIndex]
                      .items.length
                  }}
                  indicators
                </h1>
              </div>

              <!--CONTENT-->
              <div>
                <!--LOADING-->
                <div
                  v-if="loading"
                  class="flex items-center justify-center h-full mt-24"
                >
                  <div class="flex flex-col item-center justify-center">
                    <div class="flex flex-row items-center justify-center">
                      <clip-loader
                        :color="env.brandingColors.teal"
                        :size="'120px'"
                      ></clip-loader>
                    </div>
                    <h1
                      :style="'color:' + env.brandingColors.teal"
                      class="text-2xl tracking-tighter font-semibold text-center mt-10"
                    >
                      Just a moment...
                    </h1>
                    <h1
                      :style="'color:' + env.brandingColors.teal"
                      class="text-md tracking-tighter font-medium text-center"
                    >
                      We are hacking equality.
                    </h1>
                  </div>
                </div>

                <!--INDICATORS-->
                <div
                  v-if="
                    this.user.dataExplorerDashboards.length && !this.loading
                  "
                  class="mt-4 px-1 pb-10"
                >
                  <div>
                    <button
                      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-5 rounded-lg"
                      @click="sorted = !sorted"
                    >
                      Sort by {{ !sorted ? "rank" : "name" }}
                    </button>
                  </div>
                  <div
                    class="indicators-list grid xl:grid-cols-2 grid-cols-1 gap-10 overflow-y-scroll"
                  >
                    <indicator
                      v-for="(indicator, index) in this.user
                        .dataExplorerDashboards[currentDashboardIndex].items"
                      :key="indicator.indicatorId"
                      :index="index"
                      :indicator="indicator.indicator"
                      :country="indicator.country"
                      :country-data="indicator.countryData"
                      :currency="indicator.currency"
                      :chart-labels-for-charts="indicator.chartLabelsForCharts"
                      :domain-data-unfiltered="indicator.countryData"
                      :userId="user._id"
                      :is-dashboard="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--MAP-->
        <div class="flex flex-row justify-between" v-if="isOverviewSelected">
          <!--MAIN SECTION-->
          <div>
            <!--TITLE && DROPDOWN-->
            <div class="flex flex-row justify-between">
              <h1
                class="text-2xl font-black text-left print:text-7xl mt-4"
                :style="{ color: '#201747', fontWeight: 'bold' }"
              >
                {{ this.currentCountry }}
              </h1>
              <div>
                <dropdown
                  :data="regions"
                  :label="'filter'"
                  v-model="currentRegion"
                  :title="'Regions'"
                ></dropdown>
              </div>
            </div>
            <d3-map
              :country="currentCountry"
              v-if="isOverviewSelected"
            ></d3-map>
          </div>
          <!--SIDE MENU-->
          <div
            class="flex flex-col rounded-md bg-gray-200 shadow-md p-4 w-3/12"
          >
            <h2 class="text-lg font-bold mb-2">Filter by</h2>
            <dropdown
              :data="populationTypes"
              :label="'filter'"
              v-model="currentPopulationType"
              :title="'Population type'"
            ></dropdown>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import _ from "underscore";
import indicator from "@/components/QDV-GESI/indicator.vue";
import ClipLoader from "vue-spinner/src/ClipLoader";
import dropdown from "../../components/QDV-GESI/dropdown.vue";
import d3Map from "@/components/QDV-GESI/map.vue";
import auth from "@/helpers/auth";

export default {
  name: "gesi-search",
  components: {
    pageLoader,
    indicator,
    ClipLoader,
    dropdown,
    d3Map,
  },
  data() {
    return {
      user: {},
      countryData: [],
      country: {
        income: "",
      },
      sector: {},
      endofcreated: false,
      env,
      openPanels: [],
      searchTerm: "",
      indicators: [],
      intersections: [],
      themes: [],
      themeNames: [],
      domains: [],
      domainNames: [],
      loading: true,
      currentTheme: "",
      currentDomain: "",
      currency: {},
      chartLabelsForCharts: [],
      sorted: false,
      chartsByType: [],
      countries: [],
      populationTypes: [],
      currentPopulationType: "",
      currentCountry: "",
      currentSector: "",
      isOverviewSelected: false,
      regions: [],
      currentRegion: "",
      showDashboard: false,
      currentDashboardIndex: 0,
      selectedSector: "",
      sectorsList: [],
      countriesISOS: [],
      allCountry: [],
      subscriptionDetails: [],
    };
  },
  computed: {
    filteredIndicators() {
      let filtered = this.indicators;
      if (this.currentTheme && this.currentTheme !== "ALL") {
        filtered = filtered.filter(
          (indicator) => indicator.theme === this.currentTheme
        );
      }
      if (this.currentDomain && this.currentDomain !== "ALL") {
        filtered = filtered.filter(
          (indicator) => indicator.domain === this.currentDomain
        );
      }
      if (this.sorted) {
        filtered = filtered.filter(
          (indicator) =>
            indicator.hasOwnProperty("rank") && indicator.rank !== ""
        );
        return filtered.sort((a, b) => a.rank - b.rank);
      }
      return filtered;
    },
  },
  methods: {
    async fetchUser() {
      this.user = await auth.me().catch(() => {
        console.log("Error login");
      });
    },
    async getCountry() {
      try {
        const isoCodes = this.countriesISOS
          .filter((country) => country.humanName === this.currentCountry)
          .map((country) => country.ISO2);
        const iso = { isoCodes };
        const url = this.$route.params.gbv
          ? `${env.apiUrl}/countries/${this.$route.params.iso2}/sectors/country`
          : `${env.apiUrl}/countries/sectors/${this.selectedSector}`;
        const response = await axios.post(url, iso, {
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
          },
        });
        this.countryData = response.data.data;
        this.country = this.countryData.countries[0];
      } catch (error) {
        console.error("Error fetching country data", error);
        throw new Error("Failed to fetch country data");
      }
    },
    async getSector() {
      // validate if you want general country information (count/base) or a specific sector
      if (["BASE", "country"].includes(this.$route.params.sector)) {
        return false;
      } else {
        try {
          const response = await axios.get(
            `${env.apiUrl}/sectors/${this.$route.params.sector}`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("session")}`,
              },
              params: { iso: this.$route.params.iso2 },
            }
          );
          this.sector = response.data.data;
        } catch (error) {
          console.error("Error fetching sector data", error);
          throw new Error("Failed to fetch sector data");
        }
      }
    },
    async performSearch() {
      this.loading = true;
      try {
        this.indicators = [];
        const {
          data: { countries, themes },
        } = await axios.post(
          `${this.env.apiUrl}/search`,
          {
            search: this.searchTerm,
            countriesNames: [this.currentCountry],
            sector: this.selectedSector,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        this.indicators = countries[0].indicators;
        this.chartsByType = this.indicators.map((indicator) => {
          return {
            chartType: indicator.chartType,
            chartData: indicator.chartData,
            chartLabels: indicator.chartLabels,
            dataType: indicator.dataType,
          };
        });
        this.themeNames = countries[0].themeNames;
        const newValues = { ...this.country.values, ...countries[0].values };
        this.country.values = Object.keys(newValues).reduce((unique, key) => {
          if (!unique[key]) {
            unique[key] = newValues[key];
          }
          return unique;
        }, {});
        this.domainNames = [
          ...new Set(
            countries[0].domainNames.flat().filter((name) => name?.trim() !== "")
          ),
        ];
        this.themes = themes;
      } catch (error) {
        console.error("Error performing search:", error);
        throw new Error("Failed to perform search");
      } finally {
        this.loading = false;
      }
    },
    debounceSearch: _.debounce(async function () {
      await this.performSearch();
    }, 1000),
    async clearSearch() {
      if (this.searchTerm.trim() === "") {
        this.indicators = [];
        this.themeNames = [];
        this.currentTheme = "";
        this.domainNames = [];
        this.currentDomain = "";
        await this.performSearch();
      }
    },
    async getCurrency() {
      try {
        const filter = `AND({ISO} = '${this.country.ISO2}')`;
        const params = { filterByFormula: filter };
        const headers = {
          Authorization: `Bearer ${env.airTableAccessToken}`,
          "Content-Type": "application/json",
        };

        const res = await axios.get(
          "https://api.airtable.com/v0/appXebWdllnMq540w/Countries?maxRecords=1&view=Grid%20view",
          { params, headers }
        );
        return res.data.records;
      } catch (error) {
        console.error("Failed to fetch currency data:", error);
        throw new Error("Error fetching currency data");
      }
    },
    async getCountries() {
      try {
        const { data } = await axios.get(`${this.env.apiUrl}/search/countries`);
        this.countriesISOS = data;
        this.countries = data.map(({ humanName }) => humanName);
        this.currentCountry = this.countries[0];
      } catch (error) {
        console.error("Failed to fetch countries data:", error);
        throw new Error("Error fetching countries data");
      }
    },
    toggleDashboard() {
      this.loading = true;
      this.showDashboard = !this.showDashboard;
      this.loading = false;
    },
    async getSectors() {
      const {
        data: { data },
      } = await axios.get(`${this.env.apiUrl}/sectors`);
      this.sectorsList = [...new Set(data.flatMap(({ name }) => name))].filter(
        (name) => !name.includes("2X")
      );
      this.selectedSector = this.sectorsList[0];
    },
    async updateData(country = false, sector = false) {
      this.indicators = [];
      if (country) {
        await this.getCountry();
      }
      if (sector) {
        await this.getSector();
      }
      await this.performSearch();

      this.countryData.countries.forEach((data) => {
        this.allCountry.push(data.humanName);
      });

      const currencyData = await this.getCurrency();
      if (typeof currencyData[0].fields.Currency_Plural !== "undefined") {
        this.currency = currencyData[0].fields.Currency_Plural;
      } else {
        this.currency = "in local currency";
      }

      this.countryData.countries.forEach((data) => {
        this.chartLabelsForCharts.push(data.humanName);
      });
      this.chartLabelsForCharts.push("Global");
    },
    getSubscriptions() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/subscriptions/get-subscription-info", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  },

  async created() {
    // initialize data
    const subscriptionInfo = await this.getSubscriptions();
    this.subscriptionDetails = subscriptionInfo?.productArr[0];
    if (!this.subscriptionDetails ||
      this?.subscriptionDetails?.subscription ==
        "Equilo Open" ||
      this?.subscriptionDetails?.subscription ==
        "Equilo Basic") {
      this.$router.push({path: "/"})
    }
    await this.getCountries();
    await this.getSectors();

    // update data
    await this.updateData(true, true);

    setTimeout(async () => {
      let data = {
        data: null,
        country: this.allCountry,
        sector: null,
      };
      await this.$store.commit("setBibliographyData", data);
    }, 2000);
    this.endofcreated = true;
  },

  async mounted() {
    await this.fetchUser();
  },

  watch: {
    searchTerm() {
      this.clearSearch();
    },
    async currentCountry(newVal, oldVal) {
      if (oldVal !== "") {
        await this.updateData(true, false);
      }
    },
    async selectedSector(newVal, oldVal) {
      if (oldVal !== "") {
        await this.updateData(false, true);
      }
    },
  },
};
</script>

<style scoped>
.global_analysis_side_bar_list li {
  margin-left: 1rem !important;
}
.DownloadBtn p {
  display: flex;
  align-items: center;
  border: 1px solid #1b1b1b;
  border-radius: 20px;
  color: #1b1b1b;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 11px 17px;
  transition: 0.3s ease;
}
.DownloadBtn p img {
  margin-right: 25px;
  width: 15px;
  height: 15px;
}
.DownloadBtn p:hover {
  background: rgb(32, 23, 71);
  color: #fff;
  border-color: rgb(32, 23, 71);
}

.DownloadBtn a {
  display: flex;
  align-items: center;
  border: 1px solid #1b1b1b;
  border-radius: 20px;
  color: #1b1b1b;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 11px 10px;
  transition: 0.3s ease;
}

.DownloadBtn a img {
  margin-right: 8px;
  width: 15px;
  height: 15px;
}

.DownloadBtn a:hover {
  background: rgb(32, 23, 71);
  color: #fff;
  border-color: rgb(32, 23, 71);
}
.background_green {
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}
.country_select_image {
  width: 30px;
  height: 30px;
  margin: auto;
}
.country_select_inner {
  width: 100px;
  text-align: center;
  border: 1px solid green;
  border-radius: 8px;
  margin: 0 5px;
}
.country_select_inner div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.country_select {
  cursor: pointer;
  margin: 0 25px;
  font-size: 18px;
  border-radius: 8px;
  font-weight: 600;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
    .slide-fade-leave-to

        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
.col {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
}

.fadeIn-enter-active {
  transition: all 0.7s ease;
}
.fadeIn-leave-active {
  transition: all 0.7s ease;
}
.fadeIn-enter {
  transform: translateY(10px);
  opacity: 0;
}

.fadeIn-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fadeIn-faster-enter-active {
  transition: all 0.3s ease;
}
.fadeIn-faster-leave-active {
  transition: all 0.3s ease;
}
.fadeIn-faster-enter,
.fadeIn-faster-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.fadeQuick-enter-active {
  transition: all 0.2s ease-in-out;
}
.fadeQuick-leave-active {
  transition: all 0.2s ease-in-out;
}
.fadeQuick-enter {
  opacity: 0;
}

.fadeQuick-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#buttonContainer {
  text-align: center;
}
.navigateButton {
  color: red;
  border: 2px solid red;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
h1 {
  color: #201747;
}
button {
  background-color: #201747;
}

#sideNav {
  position: fixed;
  margin-top: 300px;
  left: -65px;
  border-radius: 0 5px 5px 0;
}

#sideNav button {
  background-color: #201747;
  padding: 8px;
  width: 120px;
  height: 38px;
  font-size: 10px;
  color: #201747;
}

#sideNav button:hover {
  background-color: #e2e8f0;
  color: #201747;
}

#overview {
  top: 280px;
}

#sector-overview {
  top: 320px;
}

#section-indices {
  top: 360px;
}

#section-chart {
  top: 400px;
}

.stupid-container {
  width: 780px;
  height: 780px !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .stupid-container {
    width: 753px;
    height: 600px;
  }
}
hover.Trigger .hoverMethodology {
  visibility: hidden;
  position: absolute;
  z-index: 50;
  width: 400px;
}

.triggerElement {
  border-bottom: 1px dotted #201747;
}

.hoverTrigger:hover .hoverMethodology {
  visibility: visible;
  width: 550px;
  bottom: 25px;
}
/* 1. Enable smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* 2. Make nav sticky */
.navsticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 20em;
  align-self: start !important;
  margin-left: -17px;
  color: #201747;
}

.navsticky ol {
  border-left: 1px solid #efefef;
}

/* 3. ScrollSpy active styles (see JS tab for activation) */
.navsticky li.active > button {
  color: #fe5000;
  font-weight: 500;
}

.navsticky button,
.navsticky a {
  text-decoration: none;
  display: block;
  padding: 0.125rem 0;
  color: #79879c;
  transition: all 50ms ease-in-out; /* 💡 This small transition makes setting of the active state smooth */
}

.navsticky button:hover,
.navsticky button:focus {
  color: rgba(45, 55, 72, var(--text-opacity));
  border: none;
  outline: none;
}

/** Poor man's reset **/
* {
  box-sizing: border-box;
}

html,
body {
  background: #fff;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-left: 1rem;
}

h1 {
  font-weight: 300;
}

/** page layout **/
main {
  display: grid !important;
  grid-template-columns: 10em 1fr;
  max-width: 100em;
  width: 90%;
  margin: 0 auto;
}

/** enlarge the sections for this demo, so that we have a long scrollable page **/
section {
  margin-bottom: 5rem;
}
.buttonlink {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer !important;
  color: #898989;
}
.buttonlink:hover,
.buttonlink:focus {
  color: rgba(45, 55, 72, var(--text-opacity)) !important;
}

@media only screen and (max-width: 768px) {
  .navsticky {
    display: none;
  }
  main {
    display: block !important;
  }
}

@media only screen and (min-width: 1024px) {
  #buttonContainer {
    display: none;
  }
}
.custom-indicator-div {
  display: flex;
  width: 100%;
}
.custom-w-100 {
  width: 100% !important;
}
.custom-w-180 {
  min-width: 181px;
}
</style>
<style lang="scss" scoped>
.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 25px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }

  a {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 10px;
    transition: 0.3s ease;

    img {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}
</style>
