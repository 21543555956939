<template>
  <transition
    :enter-active-class="customTransition ? enterActiveClass : 'ease-out duration-300'"
    :enter-from-class="customTransition ? enterFromClass : 'opacity-0'"
    :enter-to-class="customTransition ? enterToClass : 'opacity-100'"
    :leave-active-class="customTransition ? leaveActiveClass : 'ease-in duration-200'"
    :leave-from-class="customTransition ? leaveFromClass : 'opacity-100'"
    :leave-to-class="customTransition ? leaveToClass : 'opacity-0'"
  >
    <div v-if="isVisible" :class="[
      'fixed inset-0 transition-opacity flex z-50',
      positionClasses,
      overlayColorClass
    ]" @click.self="handleBackdropClick"
         @keydown.esc="closeModal"
         tabindex="-1"
         ref="modalOverlay">
      <div :class="[
        'bg-white overflow-hidden shadow-xl transform transition-all',
        sizeClasses,
        roundedClass,
        paddingClass,
        modalClass,
        { 'cursor-move': isDraggable }
      ]" :style="[maxHeightStyle, modalStyle]" tabindex="0" ref="modalContent"
          @mousedown="startDrag" @touchstart="startDrag">
        <div :class="{ 'resize-handle': isResizable }" @mousedown="startResize" @touchstart="startResize"></div>
        <div :class="{ 'h-full flex flex-col': isScrollable }">
          <h3 v-if="title" class="text-lg font-medium text-gray-900 text-left mb-4 border-b pb-2">{{ title }}</h3>
          <div :class="[gridClasses, { 'flex-grow overflow-auto': isScrollable }]">
            <slot></slot>
          </div>
        </div>
        <div v-if="showFooter" :class="['mt-5 sm:mt-6 border-t pt-2', { 'sticky bottom-0 bg-white': isScrollable }]">
          <slot name="footer">
            <div class="flex justify-end space-x-3">
              <button @click="confirmAction" :class="confirmButtonClasses">
                {{ confirmText }}
              </button>
              <button @click="closeModal" :class="cancelButtonClasses">
                {{ cancelText }}
              </button>
            </div>
          </slot>
        </div>
        <button v-if="showCloseButton" @click="closeModal" class="absolute top-0 right-0 p-4" ref="closeButton">
          <svg class="h-6 w-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'EquiloModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    modalClass: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md', 'lg', 'xl', '2xl', '3xl', 'full'].includes(value)
    },
    maxHeight: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'center',
      validator: (value) => ['center', 'top', 'right', 'bottom', 'left'].includes(value)
    },
    overlayColor: {
      type: String,
      default: 'gray-500'
    },
    rounded: {
      type: String,
      default: 'lg',
      validator: (value) => ['none', 'sm', 'md', 'lg', 'xl', 'full'].includes(value)
    },
    padding: {
      type: String,
      default: 'md',
      validator: (value) => ['none', 'sm', 'md', 'lg', 'xl'].includes(value)
    },
    gridLayout: {
      type: String,
      default: '',
      validator: (value) => ['', '2x2', '3x3', '4x4'].includes(value)
    },
    confirmButtonClass: {
      type: String,
      default: 'bg-[#201747]'
    },
    cancelButtonClass: {
      type: String,
      default: 'bg-white hover:bg-gray-50'
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    isResizable: {
      type: Boolean,
      default: false
    },
    customTransition: {
      type: Boolean,
      default: false
    },
    enterActiveClass: String,
    enterFromClass: String,
    enterToClass: String,
    leaveActiveClass: String,
    leaveFromClass: String,
    leaveToClass: String,
    preventBackdropClick: {
      type: Boolean,
      default: false
    },
    isScrollable: {
      type: Boolean,
      default: false
    },
    nestedLevel: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isDragging: false,
      isResizing: false,
      dragOffset: { x: 0, y: 0 },
      modalPosition: { x: 0, y: 0 },
      modalSize: { width: 0, height: 0 }
    };
  },
  computed: {
    sizeClasses() {
      const baseClasses = 'w-full';
      const sizes = {
        sm: 'sm:max-w-sm',
        md: 'sm:max-w-md',
        lg: 'sm:max-w-lg',
        xl: 'sm:max-w-xl',
        '2xl': 'sm:max-w-2xl',
        '3xl': 'sm:max-w-3xl',
        full: 'sm:max-w-full'
      };
      return `${baseClasses} ${sizes[this.size] || sizes.md}`;
    },
    maxHeightStyle() {
      return this.maxHeight ? { maxHeight: this.maxHeight } : {};
    },
    positionClasses() {
      const positions = {
        top: 'items-start justify-center',
        right: 'items-center justify-end',
        bottom0: 'items-end justify-center',
        left: 'items-center justify-start',
        center: 'items-center justify-center'
      };
      return positions[this.position] || positions.center;
    },
    overlayColorClass() {
      return `bg-${this.overlayColor} bg-opacity-75`;
    },
    roundedClass() {
      return `rounded-${this.rounded}`;
    },
    paddingClass() {
      const paddings = {
        none: 'p-0',
        sm: 'p-2',
        md: 'p-4',
        lg: 'p-6',
        xl: 'p-8'
      };
      return paddings[this.padding] || paddings.md;
    },
    gridClasses() {
      const layouts = {
        '2x2': 'grid grid-cols-1 sm:grid-cols-2 gap-4',
        '3x3': 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4',
        '4x4': 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'
      };
      return layouts[this.gridLayout] || '';
    },
    confirmButtonClasses() {
      return `px-4 py-2 ${this.confirmButtonClass} text-white text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 uppercase`;
    },
    cancelButtonClasses() {
      return `px-4 py-2 ${this.cancelButtonClass} text-base font-medium text-gray-700 rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`;
    },
    modalStyle() {
      return {
        transform: `translate(${this.modalPosition.x}px, ${this.modalPosition.y}px)`,
        width: this.modalSize.width ? `${this.modalSize.width}px` : undefined,
        height: this.modalSize.height ? `${this.modalSize.height}px` : undefined,
        zIndex: 50 + this.nestedLevel
      };
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmAction() {
      this.$emit('confirm');
    },
    focusTrap(e) {
      const focusableElements = this.$refs.modalContent.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    },
    handleKeyDown(e) {
      if (e.key === 'Tab') {
        this.focusTrap(e);
      } else if (e.key === 'Escape') {
        this.closeModal();
      }
    },
    handleBackdropClick() {
      if (!this.preventBackdropClick) {
        this.closeModal();
      }
    },
    startDrag(event) {
      if (!this.isDraggable) return;
      this.isDragging = true;
      const clientX = event.clientX || event.touches[0].clientX;
      const clientY = event.clientY || event.touches[0].clientY;
      this.dragOffset = {
        x: clientX - this.modalPosition.x,
        y: clientY - this.modalPosition.y
      };
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('touchmove', this.drag);
      document.addEventListener('mouseup', this.stopDrag);
      document.addEventListener('touchend', this.stopDrag);
    },
    drag(event) {
      if (!this.isDragging) return;
      const clientX = event.clientX || event.touches[0].clientX;
      const clientY = event.clientY || event.touches[0].clientY;
      this.modalPosition = {
        x: clientX - this.dragOffset.x,
        y: clientY - this.dragOffset.y
      };
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('touchmove', this.drag);
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('touchend', this.stopDrag);
    },
    startResize(event) {
      if (!this.isResizable) return;
      this.isResizing = true;
      const clientX = event.clientX || event.touches[0].clientX;
      const clientY = event.clientY || event.touches[0].clientY;
      this.dragOffset = {
        x: clientX - this.modalSize.width,
        y: clientY - this.modalSize.height
      };
      document.addEventListener('mousemove', this.resize);
      document.addEventListener('touchmove', this.resize);
      document.addEventListener('mouseup', this.stopResize);
      document.addEventListener('touchend', this.stopResize);
    },
    resize(event) {
      if (!this.isResizing) return;
      const clientX = event.clientX || event.touches[0].clientX;
      const clientY = event.clientY || event.touches[0].clientY;
      this.modalSize = {
        width: Math.max(200, clientX - this.dragOffset.x),
        height: Math.max(100, clientY - this.dragOffset.y)
      };
    },
    stopResize() {
      this.isResizing = false;
      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('touchmove', this.resize);
      document.removeEventListener('mouseup', this.stopResize);
      document.removeEventListener('touchend', this.stopResize);
    }
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          (this.$refs.closeButton || this.$refs.modalContent).focus();
          document.addEventListener('keydown', this.handleKeyDown);
        });
      } else {
        document.removeEventListener('keydown', this.handleKeyDown);
      }
    }
    
  },
  mounted() {
    if (this.isVisible) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
    if (this.isResizable || this.isDraggable) {
      const rect = this.$refs.modalContent.getBoundingClientRect();
      this.modalSize = {
        width: rect.width,
        height: rect.height
      };
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.stopDrag();
    this.stopResize();
  }
};
</script>
<style scoped>
/* .resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  cursor: se-resize;
} */
</style>
