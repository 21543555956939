<template>
  <div class="static print:text-xs">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated && !country.income" key="loading">
        <page-loader></page-loader>
      </div>

      <div id="loaded" v-if="endofcreated && country.income" key="content">
        <div
          class="flex flex-col items-center justify-center mt-top-5 w-full print:h-screen"
        >
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <img
                    v-if="countryData.countries.length == 1"
                    class="mr-5 h-16 print:h-auto print:w-64"
                    :src="country.flagUrl"
                  />
                  <h1
                    v-if="sector == false && countryData.countries.length == 1"
                    class="text-6xl tracking-tighter font-medium text-center print:text-7xl"
                  >
                    {{ country.humanName }}
                  </h1>
                  <h1
                    v-if="sector"
                    class="text-4xl tracking-tighter font-medium text-center print:text-7xl"
                  >
                    {{ sector.name }}
                    <span v-if="countryData.countries.length == 1"
                      >in {{ country.humanName }}</span
                    >
                  </h1>
                  <h1
                    v-if="sector == false && countryData.countries.length > 1"
                    class="text-4xl tracking-tighter font-medium text-center print:text-7xl"
                  >
                    Multiple Countries
                  </h1>
                </div>
                <p
                  v-if="countryData.countries.length == 1"
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center"
                >
                  Region:
                  {{
                    country.region == "Eastern Europe & Central Asia"
                      ? "Europe & Central Asia"
                      : country.region
                  }}
                  | Income Group:
                  {{ toTitle(country.income || "") }}
                  <span v-if="sector && sector.id"
                    >| Sector: {{ sector.name }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <main>
          <div class="navsticky">
            <span>Scroll to...</span>
            <ol class="global_analysis_side_bar_list">
              <li>
                <button
                  @click="scrollTo('SectionOverview')"
                  id="SectionOverview1"
                  class="buttonlink"
                  style="margin-left: -10px"
                >
                  <span>Country Overview</span>
                </button>
              </li>
              <li v-if="sector">
                <button
                  v-if="sector"
                  @click="scrollTo('sector-overview')"
                  id="sector-overview1"
                  class="buttonlink"
                  style="margin-left: -10px"
                >
                  <span
                    ><span v-if="sector.subsector">Subsector Overview </span
                    ><span v-else>Sector Overview</span></span
                  >
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('SectionChart')"
                  id="SectionChart1"
                  class="buttonlink"
                  style="margin-left: -10px"
                >
                  <span>Snapshot</span>
                </button>
              </li>
              <li>
                <button
                  @click="scrollTo('SectionIndices')"
                  id="SectionIndices1"
                  class="buttonlink"
                  style="margin-left: -10px"
                >
                  <span>Global Indices</span>
                </button>
              </li>
            </ol>
            <br />
            <span>Go to...</span>
            <ol class="global_analysis_side_bar_list">
              <li>
                <router-link
                  :to="
                    '/reports/' +
                    $route.params.iso2 +
                    '/' +
                    $route.params.sector +
                    '/domains'
                  "
                  @click="scrollTo('chart')"
                  class="buttonlink"
                  style="margin-left: -10px"
                  ><span>Domains</span></router-link
                >
              </li>
              <li v-if="countryData.countries.length == 1">
                <router-link
                  :to="
                    '/reports/' +
                    country.ISO2 +
                    '/' +
                    $route.params.sector +
                    '/themes'
                  "
                  @click="scrollTo('chart')"
                  class="buttonlink"
                  style="margin-left: -10px"
                  ><span>Themes</span></router-link
                >
              </li>
              <li>
                <router-link
                  :to="
                    '/reports/' +
                    $route.params.iso2 +
                    '/' +
                    $route.params.sector +
                    '/what-works'
                  "
                  class="buttonlink"
                  style="margin-left: -10px"
                  ><span>What Works</span></router-link
                >
              </li>
            </ol>
          </div>
          <div>
            <div id="SectionOverview" class="maincontent">
              <span style="visibility: hidden">SectionOverview</span>
            </div>
            <section
              class="w-full container mx-auto mt-10 px-4 lg:px-0"
              style="max-width: 960px"
            >
              <!--center>
                <div
                  class="text-5xl print:text-4xl tracking-tighter font-medium flex flex-row items-center justify-center"
                >
                  <h1 class="font-medium">Country Overview</h1>
                </div>
              </center-->

              <div
                class="shadow overflow-hidden rounded-lg mx-2 my-1 mt-10 flex flex-col mx-auto"
                :style="
                  'max-width: 960px; border-style: solid; border-width: 2px; border-color:' +
                  env.brandingColors.navy
                "
              >
                <div
                  class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                  :style="
                    'border-style: solid; border-width: 0px 0px 1px; border-color:' +
                    env.brandingColors.navy
                  "
                >
                  <h3
                    @click="togglePanel('country-overview')"
                    class="text-medium leading-6 font-medium"
                    :style="'margin:auto 0;color:' + env.brandingColors.navy"
                  >
                    Country Overview
                  </h3>
                  <div
                    v-if="countryData.countries.length > 1"
                    class="country_select flex text-medium leading-6 font-medium"
                  >
                    <div
                      class="country_select_inner"
                      :class="
                        cont.isSelectedCountryForOverview
                          ? 'background_green'
                          : ''
                      "
                      v-for="(cont, contIndex) in countryData.countries"
                      :key="contIndex"
                    >
                      <div class="" @click="changeCountry(contIndex)">
                        <img class="country_select_image" :src="cont.flagUrl" />
                        <span>{{ cont.humanName }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="
                      'margin-left: auto; cursor:pointer; color:' +
                      env.brandingColors.navy
                    "
                  >
                    <span
                      v-if="openPanels.indexOf('country-overview') > -1"
                      @click="togglePanel('country-overview')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-down no-print"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </span>
                    <span v-else @click="togglePanel('country-overview')">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-right"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </span>
                  </div>
                </div>
                <transition name="slide-fade">
                  <div
                    :class="
                      openPanels.indexOf('country-overview') > -1
                        ? 'block'
                        : 'hidden'
                    "
                    class="flex flex-col px-5 py-5"
                  >
                    <div
                      class="text-gray-600 w-full"
                      v-for="cont in countryData.countries"
                      :key="cont.humanName"
                      v-show="cont.humanName == countryOverviewData.humanName"
                    >
                      <item
                        :text="cont.intro"
                        :country="cont"
                        class="gap-10 text-justify leading-7 font-medium text-gray-600"
                        style="font-size: 15px; line-height: 1.6"
                      ></item>
                    </div>
                  </div>
                </transition>
              </div>

              <div id="sector-overview" class="maincontent" v-if="sector">
                <span style="visibility: hidden">sector-overview</span>
                <div
                  v-if="sector"
                  id="sector-overview"
                  class="shadow overflow-hidden rounded-lg mx-2 my-1 flex flex-col mx-auto"
                  :style="
                    'max-width: 960px; border-style: solid; border-width: 2px; border-color:' +
                    env.brandingColors.navy
                  "
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                    :style="
                      'border-style: solid; border-width: 0px 0px 1px; border-color:' +
                      env.brandingColors.navy
                    "
                    @click="togglePanel('sector-overview')"
                  >
                    <h3
                      class="text-medium leading-6 font-medium"
                      :style="'color:' + env.brandingColors.navy"
                    >
                      {{ sector.name }} Overview
                    </h3>
                    <div
                      :style="
                        'margin-left: auto; cursor:pointer; color:' +
                        env.brandingColors.navy
                      "
                    >
                      <span v-if="openPanels.indexOf('sector-overview') > -1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-down no-print"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-right"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <transition name="slide-fade">
                    <div
                      :class="
                        openPanels.indexOf('sector-overview') > -1
                          ? 'block'
                          : 'hidden'
                      "
                      class="flex flex-col px-5 py-5"
                    >
                      <div class="text-gray-600 w-full">
                        <item
                          :text="sector.intro"
                          :country="country"
                          class="gap-10 text-justify leading-7 font-medium text-gray-600"
                          style="font-size: 15px; line-height: 1.6"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </section>
            <div id="SectionChart" class="maincontent">
              <span style="visibility: hidden">SectionChart</span>
            </div>
            <div
              class="w-full container mx-auto px-4 lg:px-0"
              style="page-break-before: always"
              v-scroll-reveal
            >
              <center>
                <div
                  class="text-5xl print:text-4xl text-indigo-500 tracking-tighter font-medium flex flex-row items-center justify-center"
                >
                  <h1 class="tracking-tighter font-medium">
                    Gender Equality Snapshot
                  </h1>
                </div>
              </center>
              <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
                <p class="text-justify leading-7 font-medium text-gray-600">
                  This diagram displays gender equality scores across
                  {{ country.themes }} themes across
                  <span class="triggerElement hoverTrigger"
                    >6 TAAP domains.
                    <span
                      class="hoverMethodology w-customWidth absolutetop-0 right-0 bg-white rounded-lg shadow-xl border w-auto text-sm border-gray-200"
                    >
                      <methodology />
                    </span>
                  </span>
                  Each point comprises indicators measuring both gender parity
                  and women's empowerment that represent a unique intersection
                  of a theme and domain. A score of 1 (outer circle) indicates
                  perfect gender equality, while a score of 0 (inner circle)
                  indicates complete gender inequality. Any gender equality
                  score above 1 indicates gender inequality to the advantage of
                  women.
                </p>
                <br />
              </div>
            </div>
            <section
              class="w-full md:w-full lg:container mx-auto flex flex-col items-center justify-center px-4 lg:px-0"
              style="page-break-before: always"
            >
              <div
                class="w-full lg:hidden md:flex flex-row mt-10"
                v-scroll-reveal
              >
                <div
                  class="flex flex-wrap w-8/12 mx-auto items-center"
                  style="justify-content: center"
                >
                  <router-link
                    :to="
                      '/reports/' +
                      $route.params.iso2 +
                      '/' +
                      $route.params.sector +
                      '/domains/' +
                      taab
                    "
                    v-for="(color, taab) in taabs"
                    v-bind:key="taab.name"
                    class="bg-red-600 rounded-full p-1 px-2 m-1 text-white flex flex-row items-center text-sm"
                    :style="'cursor: pointer; background-color:' + color"
                  >
                    <div>{{ taab }}</div>
                  </router-link>
                </div>
              </div>

              <div
                class="relative lg:container md:w-full lg:px-0 mx-auto mt-0 lg:mt-10 print:mt-0 flex flex-row items-center justify-center"
                v-scroll-reveal
                id="chart"
              >
                <div
                  class="z-20 relative flex items-center justify-center stupid-container"
                  :key="1"
                >
                  <spiderchart
                    v-if="countryData.countries.length == 1"
                    :iso="_self.$route.params.iso2"
                    :paddingL="20"
                    :paddingR="20"
                    class="z-10 overflow-visible"
                    style="position: relative; height: 100%; width: 100%"
                  ></spiderchart>
                  <spiderchart-multiple
                    v-else
                    :countryD="countryData"
                    :paddingL="20"
                    :paddingR="20"
                    class="z-10 overflow-visible"
                    style="position: relative; height: 100%; width: 100%"
                  ></spiderchart-multiple>
                  <template v-for="(color, taab) in taabs">
                    <!--ACCESS-->
                    <router-link
                      :to="
                        '/reports/' +
                        $route.params.iso2 +
                        '/' +
                        $route.params.sector +
                        '/domains/' +
                        taab
                      "
                      v-if="color === env.brandingColors.orange"
                      class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden"
                      style="right: -6%; top: 8%"
                      id="2uur"
                      v-bind:key="taab.name"
                      :style="
                        'cursor: pointer; background-color:' +
                        color +
                        ';color: white'
                      "
                    >
                      <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color+';color: white'"></div>-->
                      {{ taab }}
                    </router-link>

                    <!--LAW-->
                    <router-link
                      :to="
                        '/reports/' +
                        $route.params.iso2 +
                        '/' +
                        $route.params.sector +
                        '/domains/' +
                        taab
                      "
                      v-if="color === env.brandingColors.navy"
                      class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold lg:mb-0 -mb-16 z-50 print:hidden"
                      id="8uur"
                      style="left: -16%; bottom: 30%"
                      v-bind:key="taab.name"
                      :style="
                        'cursor: pointer; background-color:' +
                        color +
                        ';color: white'
                      "
                    >
                      <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color   +';color: white'" ></div>-->
                      {{ taab }}
                    </router-link>

                    <!--POWER-->
                    <router-link
                      :to="
                        '/reports/' +
                        $route.params.iso2 +
                        '/' +
                        $route.params.sector +
                        '/domains/' +
                        taab
                      "
                      v-if="color === '#F5D10F'"
                      class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold -mt-6 lg:mt-0 print:hidden"
                      id="10uur"
                      style="left: -28%; top: 20%"
                      v-bind:key="taab.name"
                      :style="
                        'cursor: pointer; background-color:' +
                        color +
                        ';color: white'
                      "
                    >
                      <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color+';color: white'"></div>-->
                      {{ taab }}
                    </router-link>

                    <!--knowledge-->
                    <router-link
                      :to="
                        '/reports/' +
                        $route.params.iso2 +
                        '/' +
                        $route.params.sector +
                        '/domains/' +
                        taab
                      "
                      v-if="color === env.brandingColors.teal"
                      class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden"
                      id="12uur"
                      style="bottom: 10%"
                      v-bind:key="taab.name"
                      :style="
                        'cursor: pointer; background-color:' +
                        color +
                        ';color: white'
                      "
                    >
                      <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color   +';color: white'"></div>-->
                      {{ taab }}
                    </router-link>

                    <!--human-->
                    <router-link
                      :to="
                        '/reports/' +
                        $route.params.iso2 +
                        '/' +
                        $route.params.sector +
                        '/domains/' +
                        taab
                      "
                      v-if="color === env.brandingColors.blue"
                      class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold mb-10 z-50 print:hidden"
                      id="9uur"
                      style="right: -18%; bottom: 40%"
                      v-bind:key="taab.name"
                      :style="
                        'cursor: pointer; background-color:' +
                        color +
                        ';color: white'
                      "
                    >
                      <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color   +';color: white'"></div>-->
                      {{ taab }}
                    </router-link>

                    <!--roles-->
                    <router-link
                      :to="
                        '/reports/' +
                        $route.params.iso2 +
                        '/' +
                        $route.params.sector +
                        '/domains/' +
                        taab
                      "
                      v-if="color == '#666666'"
                      class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden"
                      id="11uur"
                      style="left: 8%; top: 3%"
                      v-bind:key="taab.name"
                      :style="
                        'cursor: pointer; background-color:' +
                        color +
                        ';color: white'
                      "
                    >
                      <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color+';color: white'"></div>-->
                      {{ taab }}
                    </router-link>
                  </template>
                </div>
                <!--<spiderchart :iso="_self.$route.params.iso2" :styles="myStyles"
                        class="print:scale-75 transform print:ml-4"
                        style='page-break-inside:avoid;'></spiderchart>-->
              </div>
            </section>

            <section
              class="w-full container mx-auto mt-20 mb-5 print:hidden px-4 lg:px-0 flex flex-col items-end justify-end gap-4"
            >
              <div class="custom-indicator-div">
                <p>Are you looking for a certain indicator?</p>
              </div>

              <div
                class="flex flex-col ml-auto mt-auto w-4/12 lg:w-3/12 custom-w-100"
              >
                <div
                  class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4"
                >
                  <svg
                    class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
                    id="Layer_3"
                    data-name="Layer 3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <title>Artboard 329</title>
                    <circle
                      cx="11.059"
                      cy="11.059"
                      r="7.059"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <line
                      x1="20"
                      y1="20"
                      x2="16.05"
                      y2="16.05"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <rect width="24" height="24" fill="none" />
                  </svg>
                  <v-select
                    @input="redirectIndicator"
                    :options="indicators"
                    class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
                    placeholder="Select Indicator"
                    label="name"
                  ></v-select>
                </div>
              </div>
              <router-link
                v-if="countryData.countries.length == 1"
                :to="
                  '/reports/' +
                  country.ISO2 +
                  '/' +
                  $route.params.sector +
                  '/themes'
                "
                @click="scrollTo('chart')"
                :style="'background-color:' + env.brandingColors.navy"
                class="custom-w-180 flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
              >
                <span>View All Themes</span>
                <svg
                  class="feather feather-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </router-link>
              <v-select
                v-else
                class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
                placeholder="Select Themes"
                label="theme"
                @input="redirectTheme"
                :options="themesOptions"
              >
              </v-select>

              <router-link
                :to="
                  '/reports/' +
                  $route.params.iso2 +
                  '/' +
                  $route.params.sector +
                  '/domains'
                "
                @click="scrollTo('chart')"
                :style="'background-color:' + env.brandingColors.navy"
                class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
              >
                <span>View All Domains</span>
                <svg
                  class="feather feather-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </router-link>
            </section>

            <div id="SectionIndices" class="maincontent">
              <span style="visibility: hidden">SectionIndices</span>
            </div>
            <div
              class="flex flex-col p-20 print:p-2 bg-gray-100 border-2 border-gray-200 mt-20 shadow-2xl print:pb-10 syle"
              style="border-radius: 20px; page-break-inside: avoid"
            >
              <div
                v-if="countryData.countries.length > 1"
                style="margin-bottom: 15px"
                class="country_select flex text-medium leading-6 font-medium"
              >
                <div style="margin: auto 75px">Select Country:</div>
                <div
                  class="country_select_inner"
                  :class="
                    cont.isSelectedCountryForScores ? 'background_green' : ''
                  "
                  v-for="(cont, contIndex) in countryData.countries"
                  :key="contIndex"
                >
                  <div class="" @click="changeCountryForScores(contIndex)">
                    <img class="country_select_image" :src="cont.flagUrl" />
                    <span>{{ cont.humanName }}</span>
                  </div>
                </div>
              </div>
              <div
                v-for="cont in countryData.countries"
                :key="cont.humanName + '_score'"
                v-show="cont.humanName == countryScoresData.humanName"
              >
                <div class="flex flex-row items-center">
                  <div
                    class="flex flex-row mt-5 w-3/12 items-center justify-center"
                  >
                    <div
                      class="text-l print:text-l font-medium tracking-tight text-white rounded-full h-32 w-32 print:h-24 print:w-24 flex items-center justify-center border-8"
                      :style="
                        'background-color:' +
                        env.taabColors.accessToResources +
                        ';' +
                        'border-color:' +
                        env.taabColors.accessToResourcesBackground +
                        ';'
                      "
                    >
                      <item
                        class="index-score"
                        :text="'%INC_SIGISC_F_1021%'"
                        :country="cont"
                      >
                      </item>
                    </div>
                  </div>
                  <div class="flex flex-col mt-5 w-8/12">
                    <h3
                      class="text-2xl font-semibold tracking-tight mb-2"
                      style="color: rgb(254, 80, 0)"
                    >
                      Social Institutions and Gender Index
                    </h3>
                    <p class="tracking-tight text-gray-700">
                      <item
                        :text="cms.social_institution_and_gender_index_text"
                        :country="cont"
                      >
                      </item>
                    </p>
                  </div>
                </div>
                <div class="flex flex-row mt-10 print:mt-5 items-center">
                  <div
                    class="flex flex-row mt-5 w-3/12 items-center justify-center"
                  >
                    <div
                      class="text-l print:text-l font-medium tracking-tight text-white rounded-full h-32 w-32 print:h-24 print:w-24 flex items-center justify-center border-8 bg-teal-400"
                      :style="
                        'background-color:' +
                        env.taabColors.knowledgeAndBeliefs +
                        ';' +
                        'border-color:' +
                        env.taabColors.knowledgeAndBeliefsBackground +
                        ';'
                      "
                    >
                      <item
                        class="index-score"
                        :text="'%IND_WBLSCR_F_1019%'"
                        :country="cont"
                      >
                      </item>
                    </div>
                  </div>
                  <div class="flex flex-col mt-5 w-8/12">
                    <h3
                      class="text-2xl font-semibold tracking-tight mb-2"
                      style="color: #2bd4c4"
                    >
                      Women, Business, and the Law Index
                    </h3>
                    <p class="tracking-tight text-gray-700">
                      <item
                        :text="cms.woman_business_law_index"
                        :country="cont"
                      ></item>
                    </p>
                  </div>
                </div>
                <div class="flex flex-row mt-10 print:mt-5 items-center">
                  <div
                    class="flex flex-row mt-5 w-3/12 items-center justify-center"
                  >
                    <div
                      class="print:h-24 print:w-24 text-l print:text-l font-medium tracking-tight text-white rounded-full h-32 w-32 flex items-center justify-center border-8 border-blue-400"
                      :style="
                        'background-color:' +
                        env.taabColors.humanDignity +
                        ';' +
                        'border-color:' +
                        env.taabColors.humanDignityBackground +
                        ';'
                      "
                    >
                      <item
                        class="index-score"
                        :text="'%IND_EM2030_F_1020%'"
                        :country="cont"
                      >
                      </item>
                    </div>
                  </div>
                  <div class="flex flex-col mt-5 w-8/12">
                    <h3
                      class="text-2xl font-semibold tracking-tight mb-2"
                      style="color: #0077c8"
                    >
                      Equal Measures SDG Gender Index
                    </h3>
                    <p class="tracking-tight text-gray-700">
                      <item
                        :text="cms.equal_mesaures_sdg_index"
                        :country="cont"
                      ></item>
                    </p>
                  </div>
                </div>
                <div class="flex flex-row mt-10 print:mt-5 items-center">
                  <div
                    class="flex flex-row mt-5 w-3/12 items-center justify-center"
                  >
                    <div
                      class="print:h-24 print:w-24 text-l print:text-l font-medium tracking-tight text-white rounded-full h-32 w-32 flex items-center justify-center border-8 border-blue-400"
                      :style="
                        'background-color:' +
                        env.taabColors.lawAndPolicy +
                        ';' +
                        'border-color:' +
                        env.taabColors.lawAndPolicyBackground +
                        ';'
                      "
                    >
                      <item
                        class="index-score"
                        :text="'%IND_WPSSCR_F_1044%'"
                        :country="cont"
                      >
                      </item>
                    </div>
                  </div>
                  <div class="flex flex-col mt-5 w-8/12">
                    <h3
                      class="text-2xl font-semibold tracking-tight mb-2"
                      style="color: #201747"
                    >
                      Women, Peace, and Security Index
                    </h3>
                    <p class="tracking-tight text-gray-700">
                      <item
                        :text="cms.women_peace_security_index"
                        :country="cont"
                      ></item>
                    </p>
                  </div>
                </div>
                <div class="flex flex-row mt-10 print:mt-5 items-center">
                  <div
                    class="flex flex-row mt-5 w-3/12 items-center justify-center"
                  >
                    <div
                      class="print:h-24 print:w-24 text-l print:text-l font-medium tracking-tight text-white rounded-full h-32 w-32 flex items-center justify-center border-8 border-blue-400"
                      :style="
                        'background-color:' +
                        env.taabColors.accessToResources +
                        ';' +
                        'border-color:' +
                        env.taabColors.accessToResourcesBackground +
                        ';'
                      "
                    >
                      <item
                        class="index-score"
                        :text="'%IND_UNDGDI_F_1060%'"
                        :country="cont"
                      >
                      </item>
                    </div>
                  </div>
                  <div class="flex flex-col mt-5 w-8/12">
                    <h3
                      class="text-2xl font-semibold tracking-tight mb-2"
                      style="color: rgb(254, 80, 0)"
                    >
                      Gender Development Index
                    </h3>
                    <p class="tracking-tight text-gray-700">
                      <item :text="cms.undp_gdi" :country="cont"></item>
                    </p>
                  </div>
                </div>
                <div class="flex flex-row mt-10 print:mt-5 items-center">
                  <div
                    class="flex flex-row mt-5 w-3/12 items-center justify-center"
                  >
                    <div
                      class="print:h-24 print:w-24 text-l print:text-l font-medium tracking-tight text-white rounded-full h-32 w-32 flex items-center justify-center border-8 border-blue-400"
                      :style="
                        'background-color:' +
                        env.taabColors.knowledgeAndBeliefs +
                        ';' +
                        'border-color:' +
                        env.taabColors.knowledgeAndBeliefsBackground +
                        ';'
                      "
                    >
                      <item
                        class="index-score"
                        :text="'%IND_UNDGII_F_1061%'"
                        :country="cont"
                      >
                      </item>
                    </div>
                  </div>
                  <div class="flex flex-col mt-5 w-8/12">
                    <h3
                      class="text-2xl font-semibold tracking-tight mb-2"
                      style="color: #2bd4c4"
                    >
                      Gender Inequality Index
                    </h3>
                    <p class="tracking-tight text-gray-700">
                      <item :text="cms.undp_gii" :country="cont"></item>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <section
              class="w-full container mx-auto mt-32 mb-32 px-4 lg:px-0"
              style="max-width: 960px"
            >
              <div class="flex flex-col mt-10 justify-start">
                <p class="text-xs text-gray-500">
                  Data Use and Citation: Equilo grants full permission to its
                  users to copy and paste information that appears in the
                  dashboard for their own internal use, directly citing the
                  original data sources and citing Equilo for analysis and
                  scoring. Please request permission from Equilo before
                  utilizing our content for external publications.
                </p>
                <p
                  class="text-xs text-gray-500 mt-5 mb-1"
                  style="max-width: 960px"
                >
                  Sources on this page:
                </p>
                <ol class="list-decimal mx-5 print:text-sm">
                  <li
                    v-for="(citation, index) in allBibliographyData"
                    v-bind:key="'cd_' + index"
                  >
                    {{
                      ((citation || {}).text || "")
                        .toString()
                        .replace("n.d.", citation.year + ". ")
                    }}
                    {{
                      citation && citation.text ? "" : "Source not found "
                    }}(<a
                      target="_blank"
                      :href="citation.url"
                      style="word-wrap: break-word"
                      >{{ citation.url }}</a
                    >)
                  </li>
                </ol>
                <p class="text-xs text-gray-500 mt-5" style="max-width: 960px">
                  Equilo uses the TAAP framework, which stands for Transforming
                  Agency, Access, and Power. Comprised of six domains, the TAAP
                  framework provides a structure to analyze and amplify the
                  voices, opportunities, and dignity of all people, especially
                  those from socially excluded groups. More information about
                  this approach can be found at taapinclusion.org. Equilo Dash
                  uses 15 themes that cut across two or more of the TAAP
                  domains, for a total of 32 unique TAAP domain/theme
                  intersections for analysis and scoring. Each gender equality
                  score is based on a specific set of indicators within a given
                  intersection.
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import cms from "@/helpers/cms";
import item from "@/components/text.vue";
import spiderchart from "@/components/charts/country-intersections.vue";
import spiderchartMultiple from "@/components/charts/country-intersections-multiple-countries.vue";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
import methodology from "@/components/methodology";

export default {
  name: "country-detail",
  data: function () {
    return {
      indicators: null,
      country: {
        income: "", // let's define it. Otherwise we cannot use toLowerCase in the sourcecode
      },
      intersections: [],
      cms,
      env,
      chartLabels: {},
      taabs: {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
      },
      appearItems: [],
      sector: {},
      openPanels: [],
      countryData: [],
      countryOverviewData: [],
      countryScoresData: [],
      themesOptions: [],
      keyAreasAll: [],
      updateKey: 0,
      sourceData: [],
      json_data: [],
      endofcreated: false,
      allBibliographyData: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  components: {
    item,
    spiderchart,
    spiderchartMultiple,
    pageLoader,
    methodology,
  },
  methods: {
    generateBibliographyCSV() {
      this.allBibliographyData.forEach((data, index) => {
        if (
          data.text &&
          data.url &&
          data.url != "Missing" &&
          data.text != "Missing"
        ) {
          let arr = [];
          arr.push(index + 1);
          arr.push(data.text);
          arr.push(data.url);
          this.json_data.push(arr);
        } else {
          let arr = [];
          arr.push(index + 1);
          arr.push("Source not found ()");
          this.json_data.push(arr);
        }
      });
    },
    async getBibliographyData() {
      if (this.bibliographySourceIds && this.bibliographySourceIds.length) {
        let arr = [];
        this.bibliographySourceIds.forEach(async (data) => {
          let val = data.tag.replace(/[[\]]/g, "");
          arr.push(val);
        });
        await this.getSource(arr);
      }
    },
    async getSource(sourceId) {
      let body = { ids: sourceId };
      return axios
        .post(env.apiUrl + "/sources/all-citation", body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.sourceData = res.data;
          // let item = { "index": index, "data": this.sourceData };
          this.sourceData = res.data;
          this.allBibliographyData = this.sourceData;
          // if (this.bibliographySourceIds.length == this.allBibliographyData.length) {
          await this.generateBibliographyCSV();
          // }
        });
    },
    getThemes() {
      const _self = this;
      const isoCodes = this.$route.params.iso2.split(",");
      return new Promise(function (resolve) {
        let iso = { isoCodes };
        axios
          .post(
            env.apiUrl + "/themes/sector/" + _self.$route.params.sector,
            iso,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    redirectTheme(event) {
      this.$router.push({
        path:
          "/reports/" +
          this.$route.params.iso2 +
          "/" +
          this.$route.params.sector +
          "/themes/" +
          event,
      });
    },
    changeCountryForScores(index) {
      this.countryScoresData = [];
      this.countryData.countries.forEach((data) => {
        data.isSelectedCountryForScores = false;
      });
      this.countryData.countries[index].isSelectedCountryForScores = true;
      this.countryScoresData = this.countryData.countries[index];
      this.updateKey++;
    },
    async changeCountry(index) {
      this.togglePanel("country-overview");
      this.countryOverviewData = [];
      this.countryData.countries.forEach((data) => {
        data.isSelectedCountryForOverview = false;
      });
      this.countryData.countries[index].isSelectedCountryForOverview = true;

      this.countryOverviewData = this.countryData.countries[index];
    },
    togglePanel: function (panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
    redirectIndicator(event) {
      var indicatorName = event.name
        .toLowerCase()
        .trim()
        .split(/\s+/)
        .join("-");
      if (
        event.theme != "NaN" &&
        this.$route.params.iso2 &&
        this.$route.params.sector &&
        indicatorName
      ) {
        this.$router.push({
          path:
            "/reports/" +
            this.$route.params.iso2 +
            "/" +
            this.$route.params.sector +
            "/themes/" +
            event.theme +
            "#" +
            indicatorName,
        });
      }
    },
    getCountry: function () {
      const _self = this;
      const isoCodes = this.$route.params.iso2.split(",");
      return new Promise(function (resolve) {
        let iso = { isoCodes };
        if (!_self.$route.params.gbv) {
          var urlGESI =
            env.apiUrl + "/countries/sectors/" + _self.$route.params.sector;
          axios
            .post(urlGESI, iso, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        } else {
          var urlGBV =
            env.apiUrl +
            "/countries/" +
            _self.$route.params.iso2 +
            "/sectors/country";
          axios
            .post(urlGBV, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        }
      });
    },
    getSector: function () {
      const _self = this;
      return new Promise(function (resolve) {
        // Validate if you want general country information (count/base) or a specific sector
        if (["BASE", "country"].indexOf(_self.$route.params.sector) > -1) {
          resolve(false);
        } else {
          axios
            .get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso: _self.$route.params.iso2 },
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        }
      });
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    scrollTo(id) {
      const element = document.getElementById(id);
      const offset = -35;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      var lis = document
        .getElementsByClassName("navsticky")[0]
        .getElementsByTagName("ol")[0]
        .getElementsByTagName("li");
      for (
        let i = 0;
        i <
        document.getElementsByTagName("ol")[0].getElementsByTagName("li")
          .length;
        i++
      ) {
        lis[i].classList.remove("active");
      }
      document.getElementById(id + "1").parentElement.classList.add("active");
    },
    toTitle(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    show(el) {
      if (this.appearItems.indexOf(el.id) < 0) {
        this.appearItems.push(el.id);
      }
    },
    getIndicators() {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/indicators/", {
            headers: {
              sector: _self.$route.params.sector,
              Authorization: "Bearer " + Cookies.get("session"),
            },
            params: { iso: _self.$route.params.iso2 },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  },
  computed: {
    bibliographySourceIds() {
      return this.$store.state.bibliographyData;
    },
    citations() {
      const compare = (a, b) => {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      };
      const x = this.allBibliographyData;
      return x.sort(compare);
    },
    countryScoresDataFiltered() {
      return this.countryScoresData;
    },
  },
  async created() {
    // validate if you have access to this country.
    this.user = await auth.me().catch(() => {});
    this.$store.dispatch("emptyBibliographyData");
    if (
      (this.$route.params.iso2 != "EQ" && !this.user.organization.accessGesi) ||
      document.location.href.indexOf("print=") > -1
    ) {
      this.$router.push({ name: "notAllowed" });
    }
    this.indicators = await this.getIndicators();
    this.indicators = this.indicators.filter(
      (ind) => ind.theme != "GBV Risk Score"
    );

    this.$store.commit("resetCitations");
    this.countryData = await this.getCountry(); // lets do an await before rendering other items.
    this.countryData.countries.forEach((data) => {
      data.isSelectedCountryForOverview = false;
    });
    this.countryData.countries[0].isSelectedCountryForOverview = true;
    this.countryData.countries.forEach((data) => {
      data.isSelectedCountryForScores = false;
    });
    this.countryData.countries[0].isSelectedCountryForScores = true;
    this.country = this.countryData.countries[0];
    this.intersections = this.country.intersections;
    this.countryOverviewData = this.countryData.countries[0];
    this.countryScoresData = this.countryData.countries[0];
    this.themesOptions = await this.getThemes();
    this.sector = await this.getSector(); // lets do an await before rendering other items.
    this.countryData.countries.forEach((obj, index) => {
      this.countryData.countries[index].values = {
        ...this.countryData.countries[index].values,
        ...this.sector.values,
      };
    });

    if (
      this.$route.params.sector != "country" &&
      this.$route.params.sector != "BASE"
    ) {
      if (!this.sector?.subsector) {
        this.$router.push({ path: this.prevRoute.path });
        return;
      }
    }

    if (env.savechildorg == this.user.organization._id) {
      if (
        this.$route.params.iso2 != "NP" &&
        this.$route.params.sector == "HLTH_SEXREP"
      ) {
        this.$router.push({ name: "notAllowed" });
      }
    }
    this.chartLabels[this.country.humanName] = {
      color: "#2F2665",
      font: "#2F2665",
      background: this.opacity("#2F2665"),
    };
    this.chartLabels[this.country.region] = {
      color: "#f56565",
      font: "#f56565",
      background: this.opacity("#f56565"),
    };
    this.chartLabels[this.country.income] = {
      color: "#f5e063",
      font: "#2F2665",
      background: this.opacity("#f5e063"),
    };
    this.$store.commit("downloadable", true);

    var citationPath = this.$route.path;
    var citationPath2 = "/gesi-analysis";
    if (this.$route.params.gbv) {
      citationPath2 = "/gbv-risk";
    } else {
      citationPath2 = "/gesi-analysis";
    }

    if (this.$route.params.iso2 == "EQ") {
      this.$store.commit("setBreadcrumbs", [
        {
          name: "Country Contextual Analysis Demo",
          url: "/gesi-analysis/EQ/AGRI_GENERL/snapshot",
        },
      ]);
      this.$store.commit("isFreeState", true);
    } else {
      this.$store.commit("setBreadcrumbs", [
        {
          name:
            this.$route.params.iso2.split(",").length == 1
              ? this.country.humanName || this.country.name
              : "Multiple Countries",
          url: citationPath2 + "/choose-country",
        },
        {
          name: (this.sector || { name: "country" }).name,
          url: citationPath2 + "/" + this.$route.params.iso2 + "/choose-sector",
        },
        {
          name: "Snapshot",
          url: citationPath,
        },
      ]);
      this.$store.commit("isFreeState", false);
    }
    let allCountry = [];
    this.countryData.countries.forEach((data) => {
      allCountry.push(data.humanName);
    });
    // await this.togglePanel('sector-overview');
    this.$gtag.event("sectors", {
      domain_name: "",
      theme_name: "",
      page_name: this.$route.name,
      userId: this.user._id,
      sector_name: this.sector.name,
      country_name: allCountry,
      team_id: this.user.organization._id,
      organizationName: this.user.organization.name,
    });
    this.$gtag.event("countries", {
      domain_name: "",
      theme_name: "",
      page_name: this.$route.name,
      userId: this.user._id,
      sector_name: this.sector.name,
      country_name: allCountry,
      team_id: this.user.organization._id,
      organizationName: this.user.organization.name,
    });
    this.endofcreated = true;
    setTimeout(async () => {
      await this.getBibliographyData();
      // await this.togglePanel('sector-overview');
      let data = {
        data: this.json_data,
        country: allCountry,
        sector: this.sector.name,
      };
      await this.$store.commit("setBibliographyData", data);
    }, 2000);
  },
  async mounted() {},
  beforeDestroy() {
    this.$store.commit("setBibliographyData", []);
  },
};
import $ from "jquery";
$(window)
  .scroll(function () {
    var scrollDistance = $(window).scrollTop();

    // Assign active class to nav links while scolling
    $(".maincontent").each(function (i) {
      if ($(this).position().top <= scrollDistance) {
        var lis = document
          .getElementsByClassName("navsticky")[0]
          .getElementsByTagName("ol")[0]
          .getElementsByTagName("li");
        for (
          let i = 0;
          i <
          document.getElementsByTagName("ol")[0].getElementsByTagName("li")
            .length;
          i++
        ) {
          lis[i].classList.remove("active");
        }

        $(".navsticky ol li").eq(i).addClass("active");
      }
    });
  })
  .scroll();
</script>

<style>
.global_analysis_side_bar_list li {
  margin-left: 1rem !important;
}
.DownloadBtn p {
  display: flex;
  align-items: center;
  border: 1px solid #1b1b1b;
  border-radius: 20px;
  color: #1b1b1b;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 11px 17px;
  transition: 0.3s ease;
}
.DownloadBtn p img {
  margin-right: 25px;
  width: 15px;
  height: 15px;
}
.DownloadBtn p:hover {
  background: rgb(32, 23, 71);
  color: #fff;
  border-color: rgb(32, 23, 71);
}

.DownloadBtn a {
  display: flex;
  align-items: center;
  border: 1px solid #1b1b1b;
  border-radius: 20px;
  color: #1b1b1b;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 11px 10px;
  transition: 0.3s ease;
}

.DownloadBtn a img {
  margin-right: 8px;
  width: 15px;
  height: 15px;
}

.DownloadBtn a:hover {
  background: rgb(32, 23, 71);
  color: #fff;
  border-color: rgb(32, 23, 71);
}
.background_green {
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}
.country_select_image {
  width: 70px;
  height: 70px;
  margin: auto;
}
.country_select_inner {
  width: 100px;
  text-align: center;
  border: 1px solid green;
  border-radius: 8px;
  margin: 0 5px;
}
.country_select_inner div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.country_select {
  cursor: pointer;
  margin: 0 25px;
  font-size: 18px;
  border-radius: 8px;
  font-weight: 600;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
    .slide-fade-leave-to

        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
.col {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
}

.fadeIn-enter-active {
  transition: all 0.7s ease;
}
.fadeIn-leave-active {
  transition: all 0.7s ease;
}
.fadeIn-enter {
  transform: translateY(10px);
  opacity: 0;
}

.fadeIn-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fadeIn-faster-enter-active {
  transition: all 0.3s ease;
}
.fadeIn-faster-leave-active {
  transition: all 0.3s ease;
}
.fadeIn-faster-enter,
.fadeIn-faster-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.fadeQuick-enter-active {
  transition: all 0.2s ease-in-out;
}
.fadeQuick-leave-active {
  transition: all 0.2s ease-in-out;
}
.fadeQuick-enter {
  opacity: 0;
}

.fadeQuick-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#buttonContainer {
  text-align: center;
}
.navigateButton {
  color: red;
  border: 2px solid red;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
h1 {
  color: #201747;
}
button {
  background-color: #201747;
}

#sideNav {
  position: fixed;
  margin-top: 300px;
  left: -65px;
  border-radius: 0 5px 5px 0;
}

#sideNav button {
  background-color: #201747;
  padding: 8px;
  width: 120px;
  height: 38px;
  font-size: 10px;
  color: #201747;
}

#sideNav button:hover {
  background-color: #e2e8f0;
  color: #201747;
}

#overview {
  top: 280px;
}

#sector-overview {
  top: 320px;
}

#section-indices {
  top: 360px;
}

#section-chart {
  top: 400px;
}

.stupid-container {
  width: 780px;
  height: 780px !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .stupid-container {
    width: 753px;
    height: 600px;
  }
}
hover.Trigger .hoverMethodology {
  visibility: hidden;
  position: absolute;
  z-index: 50;
  width: 400px;
}

.triggerElement {
  border-bottom: 1px dotted #201747;
}

.hoverTrigger:hover .hoverMethodology {
  visibility: visible;
  width: 550px;
  bottom: 25px;
}
/* 1. Enable smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* 2. Make nav sticky */
.navsticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 20em;
  align-self: start !important;
  margin-left: -17px;
  color: #201747;
}

.navsticky ol {
  border-left: 1px solid #efefef;
}

/* 3. ScrollSpy active styles (see JS tab for activation) */
.navsticky li.active > button {
  color: #fe5000;
  font-weight: 500;
}

.navsticky button,
.navsticky a {
  text-decoration: none;
  display: block;
  padding: 0.125rem 0;
  color: #79879c;
  transition: all 50ms ease-in-out; /* 💡 This small transition makes setting of the active state smooth */
}

.navsticky button:hover,
.navsticky button:focus {
  color: rgba(45, 55, 72, var(--text-opacity));
  border: none;
  outline: none;
}

/** Poor man's reset **/
* {
  box-sizing: border-box;
}

html,
body {
  background: #fff;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-left: 1rem;
}

h1 {
  font-weight: 300;
}

/** page layout **/
main {
  display: grid !important;
  grid-template-columns: 10em 1fr;
  max-width: 100em;
  width: 90%;
  margin: 0 auto;
}

/** enlarge the sections for this demo, so that we have a long scrollable page **/
section {
  margin-bottom: 5rem;
}
.buttonlink {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer !important;
  color: #898989;
}
.buttonlink:hover,
.buttonlink:focus {
  color: rgba(45, 55, 72, var(--text-opacity)) !important;
}

@media only screen and (max-width: 768px) {
  .navsticky {
    display: none;
  }
  main {
    display: block !important;
  }
}

@media only screen and (min-width: 1024px) {
  #buttonContainer {
    display: none;
  }
}
.custom-indicator-div {
  display: flex;
  width: 100%;
}
.custom-w-100 {
  width: 100% !important;
}
.custom-w-180 {
  min-width: 181px;
}
</style>
<style lang="scss" scoped>
.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 25px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }

  a {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 10px;
    transition: 0.3s ease;

    img {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}
</style>
