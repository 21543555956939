<script>
export default {
  name: "Modal",
  emits: ["on-close"],
}
</script>

<template>
  <div class="[ modal ]" @click="$emit('on-close')">
    <div class="[ modal-content ]" @click.stop>
      <slot/>
    </div>
  </div>
</template>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 20;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    border-radius: 20px;
  }
}
</style>