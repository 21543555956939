<template>
  <div class="flex flex-row w-full bg-gray-100 overflow-hidden">


    <sidebar></sidebar>
    <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
    <div class="flex flex-col px-5 py-5" style="padding-top: 100px;">
      <div class="" v-if="user.organization && user.organization.invoice">
        <div class="py-2 flex flex-row items-center justify-between w-full sm:px-6 lg:px-8 mb-6">
          <h1 class="text-2xl text-black font-semibold">{{user.organization.invoice.length}} invoices found</h1>
          <!-- <div class="flex flex-row items-center justify-end">
            <input type="text" class="border-2 border-gray-200 rounded-lg px-4 py-2 text-sm mr-2" placeholder="Search invoice" v-model="q">
          </div> -->
        </div>

        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <!-- <table class="divide-y divide-gray-200  bg-white overflow-scroll" style="min-width: 1100px">
              <thead>
                <tr>
                  <th v-for="header in headers" v-bind:key="header.id" class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{header.name}}
                  </th>
                  
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="row in results" v-bind:key="row._id">

                  <td class="px-6 py-4 whitespace-no-wrap" v-for="header in headers" v-bind:key="row._id + '_' + header.id">

                      

                    
                     <div v-if="header.type == 'date'">
                      <div class="text-sm leading-5 text-gray-900">{{moment(row[header.id]).format('DD MMM YYYY')}}</div>
                    </div>
                    
                    <div v-if="header.type == 'string'">
                      <div class="text-sm leading-5 text-gray-900">{{row[header.id]}}</div>
                    </div>
                    
                    

                    <div v-if="header.type == 'price'">
                      <div class="text-sm leading-5 text-gray-900">${{row[header.id].toFixed(2)}}</div>
                    </div>

                    <div v-if="header.type == 'attachment'">
                        <span style="cursor: pointer;" v-if="row[header.id] == 'To be attached by Admin'">{{row[header.id]}}</span>
                        <span @click="downloadAttachment(row)" style="cursor: pointer;" v-else>Download</span>
                    </div>

                  </td>

                
                </tr>

              </tbody>
            </table> -->

            <table class="divide-y divide-gray-200  bg-white overflow-scroll" style="min-width: 1100px">
              <thead>
                <tr>
                  <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    LOGO
                  </th>
                  <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    ATTACHMENT
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="row in user.organization.invoice" v-bind:key="row._id">
                  <td class="py-4 whitespace-no-wrap" style="display: -webkit-box;">
                   <div style="width: 100%">
                      <div class="text-sm leading-5 text-gray-900" style="width: 100px;">
                        <img :src="row.thumbnails.large.url" />
                      </div>
                    </div>
                    <div class="px-6 py-3">
                        <div class="InviteBtn tooltip">
                          <div @click="openInvoice(row.url)">
                            <button class="text-white text-sm font-medium">
                              Download
                            </button>
                          </div>
                        </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
          <div class="flex flex-row justify-center items-center mt-3 ml-20">
            <div class="flex flex-col item-center justify-center">
              <div
                class="flex flex-row items-center justify-center print:flex-col"
              >
                <h1
                  class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
                >
                  No Data Found
                </h1>
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import env from '@/../env.js';
import Cookies from '@/helpers/cookies'
import sidebar from '@/components/sidebar.vue'
import auth from '@/helpers/auth';
import pageLoader from "@/components/page-loader.vue";
 

const axios = require('axios');


export default {
  data: function () {
    return {
      headers: [],
      searchFields: [],
      editableFields: [],
      user: this.$store.state.user,
      data: [],
      popup: false,
      askConfirmation: false,
      object: {},
      endofcreated: false,
      q: '',
      // possible types are: text, textarea, select (add values:[]), boolean
      fields: [
        {'id': 'createdAt', name: 'Date', header: true, searchable: false, editable: false, type: 'date' },  
        {'id': 'subscriptionName', name: 'Subscription Name', header: true, searchable: true, editable: false, type: 'string' },
        {'id': 'invoiceId', name: 'Invoice Number', header: true, searchable: false, editable: true, type: 'string' },
        {'id': 'price', name: 'Amount', header: true, searchable: false, editable: false, type: 'price'},
        {'id': 'attachment', name: 'Attachment', header: true, searchable: false, editable: false, type:'attachment'}
      ],
    }
  },
  components: {
      sidebar,
      pageLoader
  },
  methods: {
    openInvoice(url) {
      window.open(url, '_blank');
    },
    load: function(){
      const _self = this;
      return new Promise(function(resolve){
        axios.get(env.apiUrl+'/organizations/'+_self.organization._id+'/invoices', {
          headers: {
            'Authorization': 'Bearer '+Cookies.get('session')
          }
        }).then(function (res) {
          resolve(res.data.data);
        });
      })
    },
    downloadAttachment:function(rowData){
        axios({
                    url: env.apiUrlWithoutVersion + '/invoiceUpload/'+rowData.attachment,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');

                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', rowData.attachment);
                     document.body.appendChild(fileLink);

                     fileLink.click();
                });
    }
  },
  computed: {
    results: function(){
      const searchResults = []
      if(this.q){
        for (var i = 0; i < this.data.length; i++) {
            for (var f = 0; f < this.searchFields.length; f++) {
              const field = this.searchFields[f];
              if(searchResults.indexOf(this.data[i]) == -1 && this.data[i] && this.data[i][field] && this.data[i][field].toLowerCase().indexOf(this.q.toLowerCase()) > -1){
                searchResults.push(this.data[i]);
                //break;
              }
            }
        }
        return searchResults;
      } else {
        return this.data;
      }
    }
  },
  async mounted() {

    this.user = await auth.me().catch(() => {});
    this.organization = this.user.organization;
    this.endofcreated = true;

    for (var i = 0; i < this.fields.length; i++) {
      if(this.fields[i].searchable){
        this.searchFields.push(this.fields[i].id)
      }

      if(this.fields[i].header){
        this.headers.push(this.fields[i])
      }

      if(this.fields[i].editable){
        this.editableFields.push(this.fields[i])
      }
    }


    this.data = await this.load();

    this.$store.commit('downloadable', false);
    this.$store.commit("isFreeState", true);
    this.$store.commit('setBreadcrumbs', [
      {'name': 'Settings', 'url': '/settings/account'},
      {'name': this.organization.name, 'url': '/settings/team' },
      {'name': 'Invoices', 'url': '/settings/invoices'},
    ])


  }
}
</script>
<style scoped>
.InviteBtn button{
    border: 1px solid #1b1b1b;
    height: 36px;
    color: #1b1b1b;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;
}
.InviteBtn button :hover {
  background: rgb(32, 23, 71);
  color: #fff;
  border-color: rgb(32, 23, 71);
}
</style>