<script>
/*
  IMPORTANT NOTE:

  - Do not use stereotype colors. but use turqoise for women & navy blue for males.
  - Make sure the Y axe is always 100%
  - Fix the label


*/


import { PolarArea } from 'vue-chartjs'
import env from '@/../env.js';

export default {
  name: 'radarChart',
  extends: PolarArea,
  props: ['chartData', 'chartLabels', 'country', 'index', 'indicator'],
  data: function () {
      return {
        labels: [],
        data: [],
        datasets: [],
          colors: [env.geoColors.countryspider, env.geoColors.regionspider, "#898989", env.geoColors.incomespider, env.chartColors.jotaColor, env.chartColors.muColor, env.chartColors.piColor, env.chartColors.rhoColor]
      }
  },
  methods: {
    toTitleCase: function(phrase){
        return (phrase || '').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    changeText: function(text){

      this.country.values['country'] = this.country.humanName;
      this.country.values['region'] = this.country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' : this.country.region+ ' Region';
      this.country.values['income'] = this.toTitleCase(this.country.income)+ ' Group';

      const keys = Object.keys(this.country.values);
      for (var k = 0; k < keys.length; k++) {
        const key = keys[k];
        let value = this.country.values[key];
        text = text.toString().split('{{'+key+'}}').join(value);
        // let's try to convert to a number
        if(!isNaN(Number(text))){
          text = Number(text);
        }
      }

      return text;
    },
    fillIn: function(array){
      if(array && array.length > 0){
        for (var i = 0; i < array.length; i++) {

          // Replace Global
          if(array[i] == 'LMIC'){
            array[i] = 'Global'
          }

          array[i] = this.changeText(array[i]);
        }

        return array;
      } else {
        return []
      }
    }
  },
  mounted () {

    const WFLabels = JSON.parse(JSON.stringify(this.chartLabels));

    const colorShemes = {
      country: env.geoColors.countrybar,
      lmic: env.geoColors.lmicbar,
      global: env.geoColors.lmicbar,
      income: env.geoColors.incomebar,
      region: env.geoColors.regionbar,
      female: env.genderColors.female,
      male: env.genderColors.male,
    }

    this.labels = this.fillIn(this.chartLabels);
    this.data =  JSON.parse(JSON.stringify(this.chartData)) || [];


    for (var i = 0; i < this.data.length; i++) {
      const colors = []
      for (var x = 0; x < this.data[i].data.length; x++) {

        const id = WFLabels[x].toLowerCase().split('{').join('').split('}').join('');
        const color = colorShemes[id] || this.colors[i];
        colors.push(color);
      }

      this.datasets.push({
        label: this.changeText(this.data[i].label),
        data: this.fillIn(this.data[i].data),
        backgroundColor: colors
      });
    }

    const _self = this;

    this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
    }, {
      // legend: {
      //   display: true,
      //   labels: {
      //     "fontSize": 13,
      //     fontFamily: "basier"
      //   }
      // },
      scale: {
          angleLines: {
              display: false
          },
          ticks: {
              suggestedMax: 5,
              fontFamily: "basier",
              callback: function (value) {
                if (_self.indicator.dataType === 'PERCENT') {
                  return value.toFixed(0) + '%'; // convert it to percentage
                }
                else {
                  return value;
                }
              },
          }
      },
        tooltips: {
          titleFontFamily: "basier",
          bodyFontFamily: "basier",
          callbacks: {
              label: function(tooltipItem) {
                if(_self.indicator && _self.indicator.dataType === 'PEOPLE'){
                  return Number( Math.round(tooltipItem.yLabel) || 0).toLocaleString();
                } else if (_self.indicator && _self.indicator.dataType === 'NUMBER') {
                  return Number((Math.round(tooltipItem.yLabel * 10)/10) || 0).toLocaleString();
                } else {
                  if (tooltipItem.datasetIndex == 0) {
                    return Number(tooltipItem.yLabel || 0).toFixed(1) + "%";
                  }
                }
              }
          }
      }
    })
  }
}
</script>

<style>
</style>
