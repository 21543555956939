<template>
    <div class="cookies-permission" :class="{ open: this.openCookiePermission }">
        <div class="container">
            <p>We would like to set performance, analytics and advertisements cookies to help us to improve our website. Please click accept to allow us to do so. For more information, please see the <a class="a-tag-decorate" href="/#/cookies-policy" target="_blank">Cookie Policy</a> section of our website.</p>
            <button type="button" class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow  flex flex-row items-center justify-center mr-4 ml-4" style="background-color: rgb(254, 80, 0); color: white !important;" @click="allowedAllClicked"><span data-v-7ba5bd90="">Accept</span></button>
        </div>
    </div>
</template>
<script>
import Cookies from '@/helpers/cookies'


export default {
  name: "cookiePermission",
  data: () => {
    return {
      openCookiePermission: false,
    };
  },
  methods: {
      allowedAllClicked() {
          Cookies.set('equilo-cookie-consent', true, 180);
          this.openCookiePermission = false;
      }
  },
  mounted() {
      if(!Cookies.get("equilo-cookie-consent")) {
          this.openCookiePermission = true;
      }
  }
};
</script>
<style scoped>
.cookies-permission {
  position: fixed;
  bottom: 0;
  opacity: 0;
  left: 0;
  width: 100%;
  padding: 15px 0px;
  background-color: #201747;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  transform: scaleY(0);
  transform-origin: bottom;
}
.cookies-permission.open {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transform: scaleY(1);
}
.cookies-permission .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.cookies-permission .container p {
  color: white;
  padding: 0 20px 0 0;
}
.cookies-permission .container button {
  white-space: nowrap;
}
.a-tag-decorate {
    text-decoration: underline;
}
</style>
