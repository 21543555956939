<template>
  <div class="static assementPageStyle">
    <div class="container">
      <div class="PoweredbyTitle_logo" v-if="portfolioData && portfolioData.id">
        <div class="PoweredbyTitleWrepper">
          <h1 class="text-6xl tracking-tighter font-medium print:text-7xl">
            {{ portfolioData.investorOrganizationName + "'s" }} Portfolio
          </h1>
        </div>
        <div class="org_profile_image" v-if="
          portfolioData.organizationProfile &&
          portfolioData.organizationProfile.logo &&
          portfolioData.organizationProfile.logo[0].url
        ">
          <img :src="portfolioData.organizationProfile.logo[0].url" alt="org image" />
        </div>
        <div>
          <div class="Poweredby_logoImg">
            <span class="powerd-logo">Powered by:</span>
            <img src="@/assets/images/2X.png" alt="background-image" style="width: 80px;"/>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="flex my-10">
        <div class="portfolio_toggle flex w-2/4" v-if="toggleOptions && toggleOptions.length">
          <div v-for="(tog, index) in toggleOptions" :key="index" v-show="toggleOptions.length">
            <div class="flex mr-1" style=""
              :class="(tog.isSelected && tog.status == portfolioData.portfolioCompanyStatus) ? 'selected-toggle' : 'unselected-toggle'"
              @click="switchPortfolioData(tog)">
              <span style="margin: auto">{{ tog.status }}</span>
            </div>
          </div>
        </div>
        <div class="favorite-shared-button-section w-2/4" v-if="portfolioData && portfolioData.investor">
          <p>
            <span class="
                      text-2xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    ">Company</span>
            <label class="toggleSwitch large">
              <input type="checkbox" id="favChecked" @click="togglePortfolio()" />
              <span>
                <span></span>
                <span></span>
              </span>
              <a></a>
            </label>
            <span class="
              text-2xl
              tracking-tighter
              font-medium
              text-center
              print:text-7xl
              ml-4
            ">Funds/FIs</span>
          </p>
        </div>
      </div>
      <transition name="fadeIn" mode="out-in">
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>

        <div id="loaded" v-if="endofcreated && portfolioData && portfolioData.id" key="content">
          <!-- Assement Page Wrepper -->
          <!-- for company -->
          <div class="AssementPageWrepper" v-if="
            investor == false &&
            portfolioData.company &&
            portfolioData.company.length > 0
          ">
            <!-- Overview Block -->
            <div class="OverviewBlock">
              <div class="OverviewTitle">
                <h2>Overview of Portfolio</h2>
                <span></span>
              </div>

              <div class="companies_and_fund_count">
                <div :key="updateKey">
                  Number of companies invested in :
                  {{
                      portfolioData.company && portfolioData.company.length
                        ? portfolioData.company.length
                        : "NA"
                  }}
                </div>
              </div>
              <div class="spiderchartEligibleWrepper">
                <div class="Xeligibleconten">
                  <div class="EligibleListWrepper bigMargin" style="margin-left: 0;" v-if="portfolioData.companyPercentTwoXEligible == 'Unknown'">
                    <div class="EligibleListImg bigImg" style="margin-left: -4px;">
                      <img src="@/assets/images/help_white.svg" class="background-image" alt="background-image"
                      style="margin-left: 5px !important;width: 33px !important;height:33px  !important;">
                    </div>
                    <div class="EligibleTitle tooltip">
                      <h3 class="gray-color">
                        2X Eligible
                        <span>
                          - {{portfolioData.companyPercentTwoXEligible}} 
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div v-else>
                    <div class="EligibleListWrepper bigMargin" v-if="
                      portfolioData.companiesAssessmentScore
                    " style="margin-left: 0;">
                      <div class="EligibleListImg bigImg" style="margin-left: -4px;">
                        <img src="@/assets/images/Rightcircle.svg" class="background-image" alt="background-image"
                        style="margin-right: 5px !important;"
                          v-if="(myPortfolioType == 'investor' ? +portfolioData.companyPercentTwoXEligible : +portfolioData.percentTwoXEligible) > 30">
                        <img src="@/assets/images/3rdPartyvalidated.svg" class="background-image" alt="background-image" v-else
                        style="margin-left: 5px !important;width: 33px !important;height:33px  !important;" />
                      </div>
                      <div class="EligibleTitle tooltip">
                        <h3>
                          2X Eligible
                          <span>
                            -
                            {{
                                (myPortfolioType == 'investor' ? +portfolioData.companyPercentTwoXEligible :
                                  +portfolioData.percentTwoXEligible)
                                | mathOnlyRound
                            }}%</span>
                        </h3>
                        <span class="twoXEligibleHoverText">
                          This percentage is based on {{portfolioData.twoXeligibleCompanies}} portfolio <span v-if="(portfolioData.twoXeligibleCompanies) > 1">companies who are</span><span v-else>company who is</span> 2X Eligible out of a total of  {{portfolioData.totalNumberOfCompanies}} portfolio <span v-if="(portfolioData.totalNumberOfCompanies) > 1">companies</span><span v-else>company</span>
                        </span>
                       
                       
                      </div>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.companyentrepreneurshipCriteriaMet">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/EntrepreneurshipIcon.svg" alt="" />
                      <p>
                        Entrepreneurship
                        <span v-if="entrepreneurship == 'NA'">
                        - Not Applicable
                        </span>
                        <span v-else>
                          -
                          {{
                              entrepreneurship
                              | mathRound
                          }}%
                        </span>
                      </p>
                      <span class="entrepreneurshipHoverText">
                        <template v-if="entrepreneurship == 'NA'">
                          The average value of Entrepreneurship criteria is not applicable across the portfolio.<br/><br/>
                        </template>
                        <template v-else>
                          The average value of Entrepreneurship criteria is {{ entrepreneurship | mathRound }}% across the portfolio.<br/><br/>
                        </template>
                        Entrepreneurship Benchmark Threshold: 51% women ownership
                        OR the business is
                        founded by a woman
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.companyleadershipCriteriaMet">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/LeadershipIcon.svg" alt="" />
                      <p>
                        Leadership
                        <span v-if="leadership == 'NA'">
                        - Not Applicable
                        </span>
                        <span v-else>
                          -
                          {{
                              leadership
                              | mathRound
                          }}%</span>
                        
                      </p>
                      <span class="leadershipHoverText">
                        <template v-if="leadership == 'NA'">
                          The average value of Leadership criteria is not applicable across the portfolio.<br/><br/>
                        </template>
                        <template v-else>
                          The average value of Leadership criteria is {{ leadership| mathRound }}% across the portfolio.<br/><br/>
                        </template>
                        Leadership Benchmark Threshold: 30% women in senior leadership
                        OR 30% women on the
                        Board or Investment Committee
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.companyemploymentCriteriaMet">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/EmploymentIcon.svg" alt="" />
                      <p>
                        Employment
                        <span v-if="employment == 'NA'">
                        - Not Applicable
                        </span>
                        <span v-else>
                          -
                          {{
                              employment
                              | mathRound
                          }}%</span>
                      </p>
                      <span class="employmentHoverText">
                        <template v-if="employment == 'NA'">
                          The average value of Employment criteria is not applicable across the portfolio.<br><br>
                        </template>
                        <template v-else>
                          The average value of Employment criteria is {{ employment | mathRound }}% across the portfolio.<br><br>
                        </template>
                        Employment Benchmark Threshold: 30-50% share of women in the
                        workforce (depending on
                        sector) AND one "quality" indicator
                        beyond compliance
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/ConsumptionIcon.svg" alt="" />
                      <p>
                        Consumption
                        <span>
                          -
                          {{
                              consumption
                              | mathRound
                          }}%</span>
                      
                      </p>
                      <span class="consumptionHoverText">
                        The average value of Consumption criteria is {{ consumption | mathRound }}% across the portfolio.<br/><br/>
                        Consumption Benchmark Threshold: One practice in place that result in products or services that specifically benefit women
                      </span>
                    </div>
                  </div>
                  <div class="BorderLine"></div>
                  <div class="PartyValidatedToolTip">
                    <div class="PartyTooltipImg"></div>
                    <div class="tooltip TooltipImgText">
                      <p>
                        Plans to improve 2X Criteria
                        <span v-if="
                          portfolioData.companiesAssessmentScore &&
                          portfolioData.companiesAssessmentScore
                            .twoXIntentions
                        ">
                          -
                          {{
                              portfolioData.companiesAssessmentScore
                                .twoXIntentions | mathRound
                          }}%</span>
                      </p>
                      <span class="tooltiptext">
                        Regardless of the 2X eligibility criteria being met, an
                        organization is marked as having plans to improve 2X
                        Criteria if the organization either: <br />1) Does not
                        currently meet 2X Criteria but has robust plans and
                        targets to meet the criteria, or <br />2) Does currently
                        meet 2X Criteria and has robust plans and targets to
                        continue improving 2X performance.
                      </span>
                      <img src="../../assets/images/helpblack.png" alt="background-image" />
                    </div>
                  </div>
                  <div class="BorderLine"></div>
                  <div class="PartyValidatedToolTip">
                    <div class="PartyTooltipImg"></div>
                    <div class="tooltip TooltipImgText">
                      <p v-if="
                        portfolioData.companiesAssessmentScore
                      ">
                        3rd Party Validated
                        <span>
                          -
                          {{
                              portfolioData.companiesAssessmentScore
                                .thirdPartyValidated | mathRound
                          }}%</span>
                      </p>
                      <span class="tooltiptext">
                        These 2X Assessment results are the product of an
                        unverified self-assessment. Although your organization
                        may qualify for 2X funding, eligibility must be
                        determined by a potential investor or a 3rd party.
                        Contact Equilo at
                        <span style="text-decoration: underline; color: #599bd8">2XCValidation@equilo.io</span>
                        to inquire about 3rd party validation.
                      </span>
                      <img src="../../assets/images/helpblack.png" alt="background-image" />
                    </div>
                  </div>
                  <div class="flex" v-if="isPageRefreshed" :key="updateKeyAfterRefresh">
                    <div class="mt-5" id="DownloadCSVBtn" v-if="csvData">
                      <div class="DownloadBtn">
                        <div class="tooltip">
                          <div @click="onexport(portfolioData)">
                            <p style="cursor: pointer; margin-top: 3px">
                              <img src="../../assets/images/DownloadBtn.png" alt="" />
                              Download XLSX
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 ml-10" id="DownloadPDFBtn">
                      <div class="DownloadBtn">
                        <div class="tooltip">
                          <div @click="exportToPDF()">
                            <p style="cursor: pointer; margin-top: 3px">
                              <img src="../../assets/images/DownloadBtn.png" alt="" />
                              <span>{{ downloadPDF ? "Downloading..." : "Download PDF" }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- spiderchart -->
                <div class="spiderchartContent" id="chart">
                  <div class="z-20 relative" :key="1">
                    <spiderchart :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40"
                      :portfolioData="portfolioData" :key="spiderChartUpdateKey" class="z-10 overflow-visible"
                      style="position: relative; height: 100%; width: 100%"></spiderchart>
                    <template v-for="(color, taab) in taabs">
                      <!--Leadership-->
                      <div v-if="color === '#2BD4C4'" class="Ind_Inv_Icon leadership-taab-company" id="2uur"
                        v-bind:key="taab.name" style="color: #2bd4c4" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/LeadershipIcon.svg" alt="Indirect Investment" />
                        <span> {{ taab }}</span>
                      </div>

                    <!--Entrepreneurship-->
                      <div v-if="color === '#0077C8'" class="Ind_Inv_Icon entrepreneurship-taab-company"
                        v-bind:key="taab.name" style="color: #0077c8" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/EntrepreneurshipIcon.svg" alt="Indirect Investment" />
                        <span> {{ taab }}</span>
                      </div>

                      <!--Consumption-->
                      <div v-if="color === '#F5D10F'" id="10uur" class="Ind_Inv_Icon consumption-taab-company"
                        v-bind:key="taab.name" style="color: #f5d10f" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/ConsumptionIcon.svg" alt="Indirect Investment" />
                        <span> {{ taab }}</span>
                      </div>
                      <!--Employment-->
                      <div v-if="color === '#FE5000'" id="12uur" class="Ind_Inv_Icon employment-taab-company"
                        v-bind:key="taab.name" style="color: #fe5000" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/EmploymentIcon.svg" alt="Indirect Investment" />
                        <span>{{ taab }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="heatmap_container" v-if="
                portfolioData && portfolioData.countryWise2xEligibleCompanies
              ">
                <div class="OverviewTitle">
                  <h2>Portfolio Heat Map</h2>
                  <span></span>
                </div>
                <section id="HeatMap" class="w-full container mx-auto px-4 lg:px-0" style="max-width: 960px; margin-bottom: 120px;">
                  <div class="flex flex-col">
                    <div class="text-gray-600 w-full mt-3">
                      <heatmap :sector="$route.params.sector" :scores="portfolioData.countryWise2xEligibleCompanies"
                        :key="spiderChartUpdateKey" />
                    </div>
                  </div>
                </section>
              </div>
              <div class="investement_sector_container flex mt-20">
                <div class="sectors_container" v-if="
                  portfolioData &&
                  portfolioData.totalAmountInvestedInCompanies &&
                  portfolioData.totalAmountInvestedInTwoXEligibleCompanies
                ">
                  <div>Portfolio Investment in 2X Eligible Companies:</div>
                  <div class="">
                    <div style="margin-top: 30px">
                      <portfolioInvestementPiechart style="margin-left: -33px" :width="500" :height="500"
                        :sectorLabels="['Non-2X Investment', '2X Investment']" :totalValue="[
                          Math.round(
                            portfolioData.totalAmountInvestedInCompanies -
                            portfolioData.totalAmountInvestedInTwoXEligibleCompanies
                          ),
                          Math.round(
                            portfolioData.totalAmountInvestedInTwoXEligibleCompanies
                          ),
                        ]"></portfolioInvestementPiechart>
                    </div>
                    <div class="" style="margin: 2rem auto">
                      <span style="font-weight: 600">Total Portfolio :
                        <span style="font-weight: 400">${{
                            portfolioData.totalAmountInvestedInCompanies
                            | numberWithCommas
                        }}</span></span>
                      <br />
                      <span style="font-weight: 600">2X Investment :
                        <span style="font-weight: 400">${{
                            portfolioData.totalAmountInvestedInTwoXEligibleCompanies
                            | numberWithCommas
                        }}</span></span>
                    </div>
                  </div>
                </div>
                <div class="sectors_container" v-if="
                  portfolioData.companiesAssessmentScore &&
                  portfolioData.companiesAssessmentScore.sectors
                ">
                  <div>Sectors in Which Portfolio Companies Operate:</div>
                  <div class="sectors_container_inner">
                    <portfolioPiechart style="margin-left: -33px" :width="500" :height="500"
                      :sectorLabels="filteredSectorsLabels" :totalValue="filteredSectorsPercent"></portfolioPiechart>
                    <!-- <div
                    class="sectors_content"
                    v-for="(sector, index) in portfolioData
                      .companiesAssessmentScore.sectors"
                    :key="index"
                    v-show="sector.percentage > 0"
                  >
                    <span
                      >{{ sector.name }} :
                      {{ sector.percentage | mathRound }}%</span
                    >
                  </div> -->
                  </div>
                </div>
              </div>
              <!-- <div style="padding-left: 160px;">
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                    Entrepreneurship
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                  Leadership
                  <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                    Employment
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                    Consumption
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
              </div> -->
            </div>
            <!-- Profile Block -->
            <div class="Profile_Criteria_Wrepper">
              <div class="Profile_CountryWrepper">
                <div class="Profile_Block">
                  <div class="OverviewTitle">
                    <h2 style="width:120px;">Profile</h2>
                    <span></span>
                  </div>
                  <!-- details in list -->
                  <div class="Profile_blockListText">
                    <div class="About_CompanyListText" v-if="
                      portfolioData &&
                      portfolioData.organizationProfile &&
                      portfolioData.organizationProfile.description
                    ">
                      <b>About the Organization:</b>
                      <br />
                      {{ portfolioData.organizationProfile.description }}
                    </div>
                    <div class="About_CompanyListText" v-if="
                      portfolioData &&
                      portfolioData.organizationProfile &&
                      portfolioData.organizationProfile.aboutTheCompany
                    ">
                      <b>About the Organization:</b>
                      <br />
                      {{ portfolioData.organizationProfile.aboutTheCompany }}
                    </div>
                    <!-- <div
                      class="About_CompanyListText"
                      v-if="
                        portfolioData &&
                        
                        portfolioData.objectivesonGenderEqualityandWomenEmpowerment
                      "
                    >
                      <b>Gender Equality Objectives:</b><br />
                      {{
                        portfolioData.objectivesonGenderEqualityandWomenEmpowerment
                      }}
                    </div> -->
                    <div class="About_CompanyListText" style="display: flex" v-if="
                      portfolioData &&
                    
                      (portfolioData.organizationProfile.website ||
                        portfolioData.organizationProfile.linkedin ||
                        portfolioData.organizationProfile.twitterHandle)
                    ">
                      <a :href="portfolioData.organizationProfile.website" target="_blank"
                        v-if="portfolioData.organizationProfile.website">
                        <img src="../../assets/images/Earth.png" alt="Earth" />
                      </a>
                      <a :href="portfolioData.organizationProfile.linkedin" target="_blank"
                        v-if="portfolioData.organizationProfile.linkedin">
                        <img src="../../assets/images/linkedin.png" alt="Linkedin" />
                      </a>
                      <a :href="portfolioData.organizationProfile.twitterHandle" target="_blank"
                        v-if="portfolioData.organizationProfile.twitterHandle">
                        <img src="../../assets/images/twitter.png" alt="Twitter" />
                      </a>
                    </div>
                    <ul>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.organizationType
                      ">
                        <b>Organization Type:</b>
                        {{
                            portfolioData.organizationProfile.organizationType
                            | isArrayOrNot
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.productsOffered
                      ">
                        <b style="word-break: break-word">Products Offered:</b>
                        {{
                            portfolioData.organizationProfile.productsOffered
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.whatTypesOfProductsAreOffered
                      ">
                        <b style="word-break: break-word">Products Offered:</b>
                        {{
                            portfolioData.organizationProfile.whatTypesOfProductsAreOffered
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile
                          .investeeTypeRoutesToMarket
                      ">
                        <b style="word-break: break-word">Investee Type / Routes to Market:</b>
                        {{
                            portfolioData.organizationProfile
                              .investeeTypeRoutesToMarket | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.sectorsOfInterest
                      ">
                        <b style="word-break: break-word">Sectors of Interest:</b>
                        {{
                            portfolioData.organizationProfile.sectorsOfInterest
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.sector
                      ">
                        <b style="word-break: break-word">Sectors of Interest:</b>
                        {{
                            portfolioData.organizationProfile.sector
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.themesOfInterest
                      ">
                        <b style="word-break: break-word">Themes of Interest:</b>
                        {{
                            portfolioData.organizationProfile.themesOfInterest
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.thematicFocus
                      ">
                        <b style="word-break: break-word">Themes of Interest:</b>
                        {{
                            portfolioData.organizationProfile.thematicFocus
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile
                          .geographiesOfInterest
                      ">
                        <b>Geographies of Interest:</b>
                        {{
                            portfolioData.organizationProfile
                              .geographiesOfInterest | changeArrayIntoString
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="Profile_Block">
                  <div class="OverviewTitle">
                    <h2 style="width: 590px;">ESG Pre-screening</h2>
                    <span></span>
                  </div>
                  <div class="GBVdetailsContent">
                    <div class="overOverviewDeitail">
                      <h3 style="font-weight: bold;">Overview of Portfolio</h3>
                      <p>
                        As part of any due diligence, in-depth environmental,
                        social, and governance (ESG) is best practice. The below
                        portrays the percentage of companies in the portfolio
                        responding yes or no to the due diligence questions.
                      </p>
                    </div>
                    <div class="gbvh-progress-content">
                      <div class="gbvh-progress-content--left flex mr-2 mt-3">
                        <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.eSClaimsTotal != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.eSClaimsTotal == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.eSClaimsTotal}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.eSClaimsTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                        </div>
                        Does have existing ESG claims, including sexual harassment.
                      </div>
                      <div class="gbvh-progress-content--right" v-if="portfolioData.companiesAssessmentScore.eSClaimsTotal > 0">
                        <div class="flex bar-div">
                          <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b" :percent="
                            portfolioData.companiesAssessmentScore.eSClaims
                            | mathRound
                          " color="#00dec5"></k-progress>
                        </div>

                      </div>
                      <div class="gbvh-progress-content--left flex mr-2 mt-3">
                        <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.genderInProcurementStrategyTotal != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.genderInProcurementStrategyTotal == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.genderInProcurementStrategyTotal}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.genderInProcurementStrategyTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                        </div>
                        Gender & procurement strategy.
                      </div>
                      <div class="gbvh-progress-content--right" v-if="portfolioData.companiesAssessmentScore.genderInProcurementStrategyTotal > 0">
                        <div class="flex bar-div">
                          <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                            portfolioData.companiesAssessmentScore
                              .genderInProcurementStrategy | mathRound
                          " color="#00dec5"></k-progress>
                        </div>

                      </div>
                      <div class="gbvh-progress-content--left flex mr-2 mt-3">
                        <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                        </div>
                        Gender & value chain strategy.
                      </div>
                      <div class="gbvh-progress-content--right"  v-if="portfolioData.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal > 0">
                        <div class="flex bar-div">
                          <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                            portfolioData.companiesAssessmentScore
                              .valueChainStrategiesThatPromoteEquality | mathRound
                          " color="#00dec5"></k-progress>
                        </div>

                      </div>
                      <div class="gbvh-progress-content--left flex mr-2 mt-3">
                        <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                        </div>
                        Active initiatives to include disadvantaged groups.
                      </div>
                      <div class="gbvh-progress-content--right"  v-if="portfolioData.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal > 0">
                        <div class="flex bar-div">
                          <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                            portfolioData.companiesAssessmentScore
                              .initiativesToIncludeDisadvantagedGroups | mathRound
                          " color="#00dec5"></k-progress>
                        </div>
                      </div>

                      <div class="gbvh-progress-content--left flex mr-2 mt-3">
                        <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.genderedApproachToCovid19Total != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.genderedApproachToCovid19Total == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.genderedApproachToCovid19Total}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.genderedApproachToCovid19Total) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                        </div>
                        Gendered approach to COVID-19.
                      </div>
                      <div class="gbvh-progress-content--right"  v-if="portfolioData.companiesAssessmentScore.genderedApproachToCovid19Total > 0">
                        <div class="flex bar-div">
                          <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                            portfolioData.companiesAssessmentScore
                              .genderedApproachToCovid19 | mathRound
                          " color="#00dec5"></k-progress>
                        </div>
                      </div>
                    </div>
                    <div class="gbvh-progress-content--left flex mr-2 mt-3" v-if="portfolioData.investor">
                      <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                        </div>
                        Adopt practices that improve agency, decision-making  <br>and empowerwomen in their communities/households.
                    </div>
                    <div class="gbvh-progress-content--right" v-if="portfolioData.investor && portfolioData.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal > 0">
                      <div class="flex bar-div">
                        <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                          portfolioData.companiesAssessmentScore
                            .doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds
                          | mathRound
                        " color="#00dec5"></k-progress>
                      </div>
                    </div>

                    <div class="gbvh-progress-content--left flex mr-2 mt-3" v-if="portfolioData.investor">
                      <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image" style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                      </div>
                      Work with financing or implementation partners<br> who work with
                      women value chain partners.
                    </div>
                    <div class="gbvh-progress-content--right" v-if="portfolioData.investor && portfolioData.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal > 0">
                      <div class="flex bar-div">
                        <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                          portfolioData.companiesAssessmentScore
                            .workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners
                          | mathRound
                        " color="#00dec5"></k-progress>
                      </div>
                    </div>

                    <div class="gbvh-progress-content--left flex mr-2 mt-3" v-if="portfolioData.investor">
                      <div class="div_tooltip" v-if="portfolioData.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal != portfolioData.companiesAssessmentScore.total">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width: 24px;padding: 2px;" />
                          <span class="div_tooltiptext" v-if="portfolioData.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal == 0" >Unknown</span>
                          <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal}} portfolio <span v-if="(portfolioData.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.companiesAssessmentScore.total}} portfolio companies</span>
                      </div>
                      Engage women in paid income opportunities<br> in supply chain.
                    </div>
                    <div class="gbvh-progress-content--right" v-if="portfolioData.investor && portfolioData.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal > 0">
                      <div class="flex bar-div">
                        <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                          portfolioData.companiesAssessmentScore
                            .engageWomenInPaidIncomeOpportunitiesInSupplyChain
                          | mathRound
                        " color="#00dec5"></k-progress>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 2xCriteria Block -->
              <div class="barchart2xCriteria_Wrepper">
                <!-- entrepreneurship barchart start -->
                <div class="OverviewTitle">
                  <h2 style="width:1150px;">2X Criteria Across the Portfolio</h2>
                  <span></span>
                </div>
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon1.png" alt="" />
                  <p>Entrepreneurship</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Average % of Shares Held by Women in Portfolio Companies
                    </div>
                    <b v-if="!portfolioData.companyPercentShares || portfolioData.companyPercentShares == ''">
                      Not Applicable 
                    </b>

                    <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="300"
                      :height="400" label1="Women" label2="Men" :totalValue="[Number(100)]" :filledValue="[
                        Number(
                          (portfolioData.companyPercentShares * 100)
                        ),
                      ]">
                    </barChartVertical>
                  </div>
                  <!-- companies  -->
                  <div class="ChartBarContent">
                    <div>
                      <div>
                        <div class="Founded_WomanText">
                          <span>Average % of Portfolio Companies Founded by a Woman</span>
                          <k-progress v-if="
                            portfolioData.companiesAssessmentScore &&
                            (portfolioData.companiesAssessmentScore.foundedByAWoman ||
                              portfolioData.companiesAssessmentScore.foundedByAWoman == 0)
                          " style="width: 100%" :percent="
                            portfolioData.companiesAssessmentScore
                              .foundedByAWoman | mathRound
                          " color="#00dec5"></k-progress>
                          <b v-else> Not Applicable</b>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <!-- leadership barchart start -->
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon2.png" alt="" />
                  <p>Leadership</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Average % Senior Managers Who are Women in Portfolio
                      Companies
                    </div>
                    <b v-if="!portfolioData.companyWhatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners || isNaN(portfolioData.companyWhatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners /
                            portfolioData.companyWhatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners)">
                      Not Applicable
                    </b>
                    <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="300"
                      :height="400" type="leadership" label1="Women" label2="Men" :totalValue="[Number(100)]"
                      :filledValue="[
                        Number(
                          (portfolioData.companyWhatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners /
                            portfolioData.companyWhatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners) *
                          100
                        ),
                      ]">
                    </barChartVertical>
                  </div>
                  <div class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Average % of Board Members Who are Women in Portfolio
                      Companies
                    </div>
                    <b v-if="!portfolioData.companyHowManyBoardMembersOrInvestmentCommitteeMembersAreThere || isNaN(portfolioData.companyHowManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere /
                            portfolioData.companyHowManyBoardMembersOrInvestmentCommitteeMembersAreThere)">
                      Not Applicable
                    </b>
                    <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="430"
                      :height="400" type="leadership" label1="Women" label2="Men" :totalValue="[Number(100)]"
                      :filledValue="[
                        Number(
                          (portfolioData.companyHowManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere /
                            portfolioData.companyHowManyBoardMembersOrInvestmentCommitteeMembersAreThere) *
                          100
                        ),
                      ]">
                    </barChartVertical>
                  </div>
                </div>

                <!-- employment barchart start -->
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon.3.png" alt="" />
                  <p>Employment</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div class="ChartBarContent" style="max-width:625px;margin:auto;">
                    <div class="ChartBarPercentageText">
                      Average % of Workforce that are Women in Portfolio
                      Companies
                    </div>
                    <b v-if="(portfolioData.companyTotalPtFtEmployees == '0' ||  portfolioData.companyTotalPtFtEmployees == '0.0') &&
                    (portfolioData.companyMidManagerTot == '0' ||  portfolioData.companyMidManagerTot == '0.0') &&
                    (portfolioData.companyFtEmployees == '0' ||  portfolioData.companyFtEmployees == '0.0') &&
                    (portfolioData.companyPtEmployees == '0' ||  portfolioData.companyPtEmployees == '0.0') &&
                    (portfolioData.companyContractorEmployees == '0' ||  portfolioData.companyContractorEmployees == '0.0') &&
                    (portfolioData.companySupplierEmployees == '0' ||  portfolioData.companySupplierEmployees == '0.0')">
                      Not Applicable
                    </b>
                    <portfolioEmploymentBarChart
                      v-else
                      :key="spiderChartUpdateKey"
                      :type="investor"
                      :chartData="portfolioData"
                      :assessmentYear="assessmentYear"
                      :chartLabels="['Total','Middle Managers','FT employees','PT employees','Contractual','Supplier']"
                    ></portfolioEmploymentBarChart>
                  </div>
                </div>
                <div class="employee_policies">
                  <div v-if="
                    portfolioData.companiesAssessmentScore &&
                    portfolioData.companiesAssessmentScore.policies &&
                    portfolioData.companiesAssessmentScore.policies.length >
                    0
                  " class="ChartBarContent">
                    <div class="Quality_EmploymentText">
                      <p>
                        Average % of Portfolio Companies with Employment Quality
                        Policies & Programs
                      </p>
                      <!-- <p>{{ assessmentYear[0] }}</p> -->
                      <div class="gbvh-progress-wrapper">
                        <div class="gbvh-progress-content mt-2 flex bar-div"
                          v-for="(employment_data, index) in portfolioData.companiesAssessmentScore.policies"
                          :key="index">
                          <div style="width: 50%;">
                            {{ employment_data.name }}
                          </div>
                          <div style="width: 50%;" class="gbvh-progress-content--right">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b" :percent="
                              employment_data.percentage | mathRound
                            " color="#00dec5"></k-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="
                    portfolioData[1] &&
                    portfolioData[1]
                      .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                    portfolioData[1]
                      .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization
                      .length > 0
                  " class="ChartBarContent" style="margin-top: 2.5rem !important">
                    <div class="Quality_EmploymentText">
                      <div class="gbvh-progress-wrapper">
                        <div class="gbvh-progress-content mt-2"
                          v-for="(employment_data, index) in portfolioData[1]
                          .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization" :key="index">
                          <div class="gbvh-progress-content--right">
                            <div class="flex bar-div">
                              <span>{{ employment_data }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Consumption barchart start -->
                <div class="CriteriaTitleText mt-5">
                  <img src="../../assets/images/EligibleIcon.4.png" alt="" />
                  <p>Consumption</p>
                </div>

                <div class="flex ChartBarContentWrepper" v-if="
                  portfolioData.companyHowManyPeopleCurrentlyUseYourProduct && portfolioData.companyHowManyDirectCustomersOrClientsDoYouHave
                ">
                  <div class="ChartBarContent" v-if="
                    portfolioData.companyHowManyDirectCustomersOrClientsDoYouHave
                  ">
                    <div class="ChartBarPercentageText">Average % of Women and Men Direct Customers within Portfolio Companies
                    </div>
                    <span v-if="
                      !portfolioData.companyHowManyDirectCustomersOrClientsDoYouHave || isNaN(
                          portfolioData.companyHowManyOfYourDirectCustomersOrClientsAreWomen
                        /portfolioData.companyHowManyDirectCustomersOrClientsDoYouHave)" >
                      Not Applicable
                    </span>

                    <barChartVertical  v-else :chartLabels="assessmentYear" style="margin-left: -33px" type="consumption" label1="Women" label2="Men" :width="300"
                      :height="400" :totalValue="[
                        Number(
                          100
                        ),
                      ]" :filledValue="[
                        (Number(
                          portfolioData.companyHowManyOfYourDirectCustomersOrClientsAreWomen
                        )/Number(portfolioData.companyHowManyDirectCustomersOrClientsDoYouHave)) * 100,
                      ]">
                    </barChartVertical>
                  </div>
                  <div class="ChartBarContent" v-if="
                    portfolioData.companyHowManyPeopleCurrentlyUseYourProduct
                  ">
                    <div class="ChartBarPercentageText">
                      Average % of Women and Men Product/ Service Users within Portfolio Companies
                    </div>
                    <span v-if="!portfolioData.companyHowManyPeopleCurrentlyUseYourProduct || isNaN(
                          portfolioData.companyHowManyPeopleCurrentlyUseYourProductOrServiceWhoAreWomen
                        /portfolioData.companyHowManyPeopleCurrentlyUseYourProduct)" >
                      Not Applicable
                    </span>
                    <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" type="consumption" label1="Women" label2="Men" :width="300"
                      :height="400" :totalValue="[
                        Number(
                          100
                        ),
                      ]" :filledValue="[
                        (Number(
                          portfolioData.companyHowManyPeopleCurrentlyUseYourProductOrServiceWhoAreWomen
                        )/Number(portfolioData.companyHowManyPeopleCurrentlyUseYourProduct)) * 100,
                      ]">
                    </barChartVertical>

                  </div>
                </div>

                <div class="employee_policies">
                  <div v-if="
                    portfolioData.companiesAssessmentScore &&
                    portfolioData.companiesAssessmentScore.genderLensConsumption &&
                    portfolioData.companiesAssessmentScore.genderLensConsumption.length >
                    0
                  " class="ChartBarContent">
                    <div class="Quality_EmploymentText">
                      <p>
                        Average % of Portfolio Companies with Consumption Practice
                      </p>
                      <div class="gbvh-progress-wrapper">
                        <div class="gbvh-progress-content mt-2 flex bar-div"
                          v-for="(employment_data, index) in portfolioData.companiesAssessmentScore.genderLensConsumption"
                          :key="index">
                          <div style="width: 50%;">
                            {{ employment_data.name }}
                          </div>
                          <div style="width: 50%;" class="gbvh-progress-content--right">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b" :percent="
                              employment_data.percentage | mathRound
                            " color="#00dec5"></k-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Company Section-->
            <div class="OverviewBlock">
              <div class="CompanyTitle" :key="updateKey">
                Number of companies invested in : {{
                    portfolioData.company && portfolioData.company.length
                      ? portfolioData.company.length
                      : "NA"
                }}
              </div>
              <div class="companies_and_fund_table">
                <table class="divide-y divide-gray-200 bg-white w-full">
                  <thead>
                    <tr>
                      <th class="px-3 py-2" v-for="(header, index) in companyHeader" :key="index"
                        :style="index == 0 ? 'width: 16rem' : ''">
                        <div class="flex" @click="changeOrder('company', header.orderBy, index)">
                          <span :class="{ 'mr-3': index == 0 }">{{ header.title }}</span>
                          <img
                            :src="header.defalutOrderTypeASC ? require(`@/assets/images/arrows/arrow-up.svg`) : require(`@/assets/images/arrows/arrow-down.svg`)" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr v-for="(company, index) in companyDetails.companyData" :key="index">
                      <td class="px-3 py-8">
                        <div class="flex image-center">
                          <img class="organization-img" :src="company.logo[0].url" crossorigin="anonymous" v-if="company &&
                          company.logo &&
                          company.logo[0] &&
                          company.logo[0].url" />
                          <h6 @click="goToTwoXAssess(company, 'companies')" style="cursor: pointer">
                            {{ company.organizationName }}
                          </h6>
                          <!-- <a class="organization-name" href="javascript:void(0);">{{ company.organizationName }}</a> -->
                        </div>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <div class="flex justify-between">
                          <div>{{ company.twoXeligibilityPercentage | mathOnlyRound }}%</div>
                          <div>
                            <img
                                src="@/assets/images/Rightcircle.svg"
                                class="background-image"
                                alt="background-image"
                                v-if="company.eligible2X === 'Yes' || company.eligible2X === 'true' || company.eligible2X == 'TRUE'"
                              />
                              <img v-else style="border-radius: 0;top: 3px; position: relative;"
                                class="background-image"
                                src="@/assets/images/3rdPartyvalidated.svg"
                                alt="background-image"
                              />
                          </div>
                        </div>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="company.percentShares == ''">N/A</span> 
                        <span v-else> {{ company.percentShares * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8">
                        <img src="@/assets/images/Rightcircle.svg" class="background-image" alt="background-image"
                          v-if="company.foundedByAWoman == 'Yes'">
                        <img src="@/assets/images/Crosscircle.svg" class="background-image" alt="background-image"
                          v-else>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="company.percentOfWomenSeniorManagers  == ''">N/A</span> 
                        <span v-else> {{ company.percentOfWomenSeniorManagers * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="company.percentWomenOnBoardIc  == ''">N/A</span> 
                        <span v-else> {{ company.percentWomenOnBoardIc * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="company.percentWomenInOverallWorkforce  == ''">N/A</span> 
                        <span v-else> {{ company.percentWomenInOverallWorkforce * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <div class="tooltip TooltipImgText">
                          <p>
                            {{ company.employmentQualityAndPolicesPrograms * 100 | mathOnlyRound }}%
                          </p>
                          <span class="tooltiptext tooltiptext_aligned" style="top: -76px !important;"
                            :class="company.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization.length > 0 ? 'fund_company_list_top_multiple' : 'fund_company_list_top_single'">
                            <ul class="fund_company_tooltip"
                              :class="{ 'fund_company_list': company.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization.length > 2 }"
                              v-if="company.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization.length > 0">
                              <li
                                v-for="(tooltio_content, index) in company.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization"
                                :key="index">
                                <span v-if = "tooltio_content == 'Other'">
                                {{ index+1 }}.  {{ company.ifOtherWhatIsIt }}
                                </span>
                                <span v-else>
                                {{ index+1 }}.  {{ tooltio_content }}
                                </span>
                              </li>
                            </ul>
                            <span v-else>No Data Available</span>
                          </span>
                        </div>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <!-- <span v-if="!company.modTwo">Unknown</span> -->
                        <span v-if="company.percentDirectCustomersWhoAreWomen != ''">  {{ company.percentDirectCustomersWhoAreWomen * 100 | mathOnlyRound }}%
                          </span>
                          <span v-else-if="company.percentDirectCustomersWhoAreWomen == '0' || company.percentDirectCustomersWhoAreWomen == '0.0'">N/A</span>
                          <span v-else>Unknown</span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        {{ company.ConsumptionPractice * 100 | mathOnlyRound }}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button class="
                      loadmore-btn
                      flex
                      m-auto
                      justify-center
                      border border-gray-300
                      px-4
                      py-2
                      bg-white
                      text-base
                      leading-6
                      font-bold
                      text-gray-800
                      hover:text-gray-500
                      focus:outline-none
                      focus:border-blue-300
                      focus:shadow-outline-blue
                      transition
                      ease-in-out
                      duration-150
                      sm:text-sm sm:leading-5
                    " @click="loadMore('company')" v-if="
                      companyDetails.companyData && companyDetails.toolsDataLength < companyDetails.totalCount && companyDetails.toolsDataLength > 0
                    ">Load more</Button>
            </div>
          </div>
          <div v-if="
            investor == false &&
            portfolioData.company == [] &&
            portfolioData.company.length == 0
          ">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  ">
                  <img class="h-16 print:h-auto print:w-64" src="@/assets/images/2X-assessment/My2XAssessments3b.png"
                    alt="" />
                  <h1 class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    ">
                    No Data Found
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <!-- for company end -->
          <!-- for investor -->
          <div class="AssementPageWrepper" v-if="
            investor == true &&
            portfolioData.investor &&
            portfolioData.funds &&
            portfolioData.funds.length > 0
          ">
            <!-- Overview Block -->
            <div class="OverviewBlock">
              <div class="OverviewTitle">
                <h2>Overview of Portfolio</h2>
                <span></span>
              </div>

              <div class="companies_and_fund_count">
                <div :key="updateKey">
                  Number of Funds/FIs invested in :
                  {{
                      portfolioData.funds && portfolioData.funds.length
                        ? portfolioData.funds.length
                        : "NA"
                  }}
                </div>
              </div>
              <div class="spiderchartEligibleWrepper">
                <div class="Xeligibleconten">
                  <div class="EligibleListWrepper bigMargin" style="margin-left: 0;" v-if="portfolioData.fundPercentTwoXEligible == 'Unknown' || (portfolioData.fundsIndirectInvestmentCriteriaMet == '' || isNaN(portfolioData.fundsIndirectInvestmentCriteriaMet))">
                    <div class="EligibleListImg bigImg" style="margin-left: -4px;">
                      <img src="@/assets/images/help_white.svg" class="background-image" alt="background-image"
                      style="margin-left: 5px !important;width: 33px !important;height:33px  !important;">
                    </div>
                    <div class="EligibleTitle tooltip">
                      <h3 class="gray-color">
                        2X Eligible
                        <span>
                          - Unknown 
                        </span>
                      </h3>
                      <span class="twoXEligibleHoverText">
                        2X eligibility requires any one of your institution's entrepreneurship, leadership, consumption,
                        or employment
                        criteria to be met AND you must meet the 30% threshold for investment through financial intermediaries
                        (e.g. 30% of your
                        institution's loan proceeds or portfolio companies must meet one of the 2X Criteria).
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper bigMargin" style="margin-left: 0;" v-else>
                    <div class="EligibleListImg bigImg" style="margin-left: -4px;">
                      <img src="@/assets/images/Rightcircle.svg" class="background-image" alt="background-image" style="margin-right: 5px !important;" 
                        v-if="(myPortfolioType == 'investor' ? +portfolioData.fundPercentTwoXEligible : +portfolioData.percentTwoXEligible) > 30">
                      <img src="@/assets/images/3rdPartyvalidated.svg" class="background-image" alt="background-image"
                      style="margin-left: 5px !important;width: 33px !important;height:33px  !important;"  v-else>
                    </div>
                    <div class="EligibleTitle tooltip">
                      <h3>
                        2X Eligible
                        <span>
                          -
                          {{
                              myPortfolioType == 'investor' ? +portfolioData.fundPercentTwoXEligible :
                                +portfolioData.percentTwoXEligible
                                | mathOnlyRound
                          }}%</span>
                      </h3>
                      <span class="twoXEligibleHoverText">
                          This percentage is based on {{portfolioData.twoXeligibleFunds}} <span v-if="(portfolioData.twoXeligibleFunds) > 1"> funds/fis who are</span><span v-else> fund/fi who is</span> 2X Eligible out of a total of  {{portfolioData.totalNumberOfFunds}} <span v-if="(portfolioData.totalNumberOfFunds) > 1">funds/fis.</span><span v-else>fund/fi.</span>
                          <span v-if="portfolioData.twoXFundsUnknown > 0"> Data is currently missing from some of your funds/fis.</span>
                      </span>
                       
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.fundsentrepreneurshipCriteriaMet">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/EntrepreneurshipIcon.svg" alt="" />
                      <p>
                        Entrepreneurship
                        <span>
                          -
                          {{
                              entrepreneurship
                              | mathRound
                          }}%</span>
                      </p>
                      <span class="entrepreneurshipHoverText">
                        The average value of Entrepreneurship criteria is {{ entrepreneurship | mathRound }}% across the portfolio.<br/><br/>
                        Entrepreneurship Benchmark Threshold: 51% women ownership
                        OR the business is
                        founded by a woman
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.fundsleadershipCriteriaMet">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/LeadershipIcon.svg" alt="" />
                      <p>
                        Leadership
                        <span v-if="leadership == 'NA'">
                        - Not Applicable
                        </span>
                        <span v-else>
                          -
                          {{
                              leadership
                              | mathRound
                          }}%</span>
                    
                      </p>
                      <span class="leadershipHoverText">
                        <template v-if="leadership == 'NA'">
                          The average value of Leadership criteria is not applicable across the portfolio.<br/><br/>
                        </template>
                        <template v-else>
                          The average value of Leadership criteria is {{ leadership| mathRound }}% across the portfolio.<br/><br/>
                        </template>                        
                        Leadership Benchmark Threshold: 30% women in senior leadership
                        OR 30% women on the
                        Board or Investment Committee
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.fundsemploymentCriteriaMet">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/EmploymentIcon.svg" alt="" />
                      <p>
                        Employment
                        <span v-if="employment == 'NA'">
                        - Not Applicable
                        </span>
                        <span v-else>
                          -
                          {{
                              employment
                              | mathRound
                          }}%</span>
                      </p>
                      <span class="employmentHoverText">
                      <template v-if="employment == 'NA'">
                          The average value of Employment criteria is not applicable across the portfolio.<br><br>
                        </template>
                        <template v-else>
                          The average value of Employment criteria is {{ employment | mathRound }}% across the portfolio.<br><br>
                        </template>
                        Employment Benchmark Threshold: 30-50% share of women in the
                        workforce (depending on
                        sector) AND one "quality" indicator
                        beyond compliance
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.fundsconsumptionCriteriaMet">
                    <div class="EligibleListImg"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/ConsumptionIcon.svg" alt="" />
                      <p>
                        Consumption
                        <span>
                          -
                          {{
                              consumption
                              | mathRound
                          }}%</span>
                      </p>
                      <span class="consumptionHoverText">
                        The average value of Consumption criteria is {{consumption | mathRound }}% across the portfolio.<br><br>
                        Consumption Benchmark Threshold: One practice in place that result in products or services that specifically benefit women
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-if="portfolioData.fundsIndirectInvestmentCriteriaMet == '' || isNaN(portfolioData.fundsIndirectInvestmentCriteriaMet) ">
                    <div class="EligibleListImg lastAffter"></div>
                    <div class="EligibleTitle tooltip">
                      <img src="@/assets/images/help_white.svg" class="width-30" alt="background-image">
                      <p class="gray-color">
                        Indirect Investment <span> - Unknown</span>
                      </p>
                    </div>
                  </div>
                  <div class="EligibleListWrepper" v-else>
                    <div class="EligibleListImg lastAffter"></div>
                    <div class="EligibleTitle tooltip">
                      <img class="width-30" src="../../assets/images/2X-assessment/IndirectInvestmentA.svg" alt="" />
                      <p>
                        Indirect Investment <span> - {{ portfolioData.fundsIndirectInvestmentCriteriaMet | mathRound
                        }}%</span>
                      </p>
                      <span class="indirectInvestmentHoverText">
                        The average value of Indirect Investment criteria is {{ portfolioData.fundsIndirectInvestmentCriteriaMet | mathRound
                        }}% across the portfolio.<br/><br/>
                        Indirect Investment Benchmark Threshold: 30% of loan
                        proceeds or portfolio
                        companies meet the 2X Criteria
                      </span>
                    </div>
                  </div>
                  <div class="BorderLine"></div>
                  <div class="PartyValidatedToolTip">
                    <div class="PartyTooltipImg"></div>
                    <div class="tooltip TooltipImgText">
                      <p>
                        Plans to improve 2X Criteria
                        <span v-if="
                          portfolioData.fundsAssessmentScore &&
                          portfolioData.fundsAssessmentScore.twoXIntentions
                        "> -
                          {{
                              portfolioData.fundsAssessmentScore.twoXIntentions
                              | mathRound
                          }}%</span>
                      </p>
                      <span class="tooltiptext">
                        Regardless of the 2X eligibility criteria being met, an
                        organization is marked as having plans to improve 2X
                        Criteria if the organization either: <br />1) Does not
                        currently meet 2X Criteria but has robust plans and
                        targets to meet the criteria, or <br />2) Does currently
                        meet 2X Criteria and has robust plans and targets to
                        continue improving 2X performance.
                      </span>
                      <img src="../../assets/images/helpblack.png" alt="background-image" />
                    </div>
                  </div>
                  <div class="BorderLine"></div>
                  <div class="PartyValidatedToolTip">
                    <div class="PartyTooltipImg"></div>
                    <div class="tooltip TooltipImgText">
                      <p>
                        3rd Party Validated
                        <span v-if="
                          portfolioData.fundsAssessmentScore
                        ">
                          -
                          {{
                              portfolioData.fundsAssessmentScore
                                .thirdPartyValidated | mathRound
                          }}%</span>
                      </p>
                      <span class="tooltiptext">
                        These 2X Assessment results are the product of an
                        unverified self-assessment. Although your organization
                        may qualify for 2X funding, eligibility must be
                        determined by a potential investor or a 3rd party.
                        Contact Equilo at
                        <span style="text-decoration: underline; color: #599bd8">2XCValidation@equilo.io</span>
                        to inquire about 3rd party validation.
                      </span>
                      <img src="../../assets/images/helpblack.png" alt="background-image" />
                    </div>
                  </div>
                  <div class="flex" v-if="isPageRefreshed" :key="updateKeyAfterRefresh">
                    <div class="mt-5" id="DownloadCSVBtn" v-if="csvData">
                      <div class="DownloadBtn">
                        <div class="tooltip">
                          <div @click="onexport(portfolioData)">
                            <p style="cursor: pointer; margin-top: 3px">
                              <img src="../../assets/images/DownloadBtn.png" alt="" />
                              Download XLSX
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 ml-10" id="DownloadPDFBtn">
                      <div class="DownloadBtn">
                        <div class="tooltip">
                          <div @click="exportToPDF()">
                            <p style="cursor: pointer; margin-top: 3px">
                              <img src="../../assets/images/DownloadBtn.png" alt="" />
                              <span>{{ downloadPDF ? "Downloading..." : "Download PDF" }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- spiderchart -->
                <div class="spiderchartContent" id="chart">
                  <div class="z-20 relative" :key="1">
                    <spiderchartFunds :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40"
                      :portfolioData="portfolioData" :key="spiderChartUpdateKey" class="z-10 overflow-visible"
                      style="position: relative; height: 100%; width: 100%"></spiderchartFunds>
                    <template v-for="(color, taab) in taabs">
                      <!--Leadership-->
                      <div v-if="color === '#2BD4C4'" class="Ind_Inv_Icon leadership-taab-fund" id="2uur"
                        v-bind:key="taab.name" style="color: #2bd4c4" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/LeadershipIcon.svg" alt="Indirect Investment" />
                        <span> {{ taab }}</span>
                      </div>

                      <!--Entrepreneurship-->
                      <div v-if="color === '#0077C8'" class="Ind_Inv_Icon entrepreneurship-taab-fund"
                        v-bind:key="taab.name" style="color: #0077c8" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/EntrepreneurshipIcon.svg" alt="Indirect Investment" />
                        <span> {{ taab }}</span>
                      </div>

                      <!--Consumption-->
                      <div v-if="color === '#F5D10F'" id="10uur" class="Ind_Inv_Icon consumption-taab-fund"
                        v-bind:key="taab.name" style="color: #f5d10f" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/ConsumptionIcon.svg" alt="Indirect Investment" />
                        <span> {{ taab }}</span>
                      </div>
                      <!--Employment-->
                      <div v-if="color === '#FE5000'" id="12uur" class="Ind_Inv_Icon employment-taab-fund"
                        v-bind:key="taab.name" style="color: #fe5000" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/EmploymentIcon.svg" alt="Indirect Investment" />
                        <span>{{ taab }}</span>
                      </div>

                      <!--Indirect Investment-->
                      <div v-if="color === 'red'" class="Ind_Inv_Icon Indirect_Investment" id="12uur"
                        v-bind:key="taab.name" style="color: #201747" @click="openContextualAnalysis(taab)">
                        <img src="../../assets/images/2X-assessment/IndirectInvestmentA.svg"
                          alt="Indirect Investment" />
                        <span>{{ taab }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="investement_sector_container flex">
                <div class="sectors_container" v-if="
                  portfolioData &&
                  portfolioData.totalAmountInvestedInFunds &&
                  portfolioData.totalAmountInvestedInTwoXEligibleFunds
                ">
                  <div>Portfolio Investment in 2X Eligible Funds/FIs:</div>
                  <div class="">
                    <div style="margin-top: 30px">
                      <portfolioInvestementPiechart style="margin-left: -33px" :width="500" :height="500"
                        :sectorLabels="['Non-2X Investment', '2X Investment']" :totalValue="[
                          Math.round(
                            portfolioData.totalAmountInvestedInFunds -
                            portfolioData.totalAmountInvestedInTwoXEligibleFunds
                          ),
                          Math.round(
                            portfolioData.totalAmountInvestedInTwoXEligibleFunds
                          ),
                        ]"></portfolioInvestementPiechart>
                    </div>
                    <div class="" style="margin: 2rem auto">
                      <span style="font-weight: 600">Total Portfolio :
                        <span style="font-weight: 400">${{
                            portfolioData.totalAmountInvestedInFunds
                            | numberWithCommas
                        }}</span></span>
                      <br />
                      <span style="font-weight: 600">2X Investment :
                        <span style="font-weight: 400">${{
                            portfolioData.totalAmountInvestedInTwoXEligibleFunds
                            | numberWithCommas
                        }}</span></span>
                    </div>
                  </div>
                </div>
                <div class="sectors_container" v-if="
                  portfolioData.fundsAssessmentScore &&
                  portfolioData.fundsAssessmentScore.sectors
                ">
                  <div>Sectors in Which Portfolio Funds/FIs Invested In:</div>
                  <div class="sectors_container_inner">
                    <portfolioPiechart style="margin-left: -33px" :width="500" :height="500"
                      :sectorLabels="filteredSectorsLabelsFunds" :totalValue="filteredSectorsPercentFunds">
                    </portfolioPiechart>
                    <!-- <div
                    class="sectors_content"
                    v-for="(sector, index) in portfolioData
                      .fundsAssessmentScore.sectors"
                    :key="index"
                    v-show="sector.percentage > 0"
                  >
                    <span
                      >{{ sector.name }} :
                      {{ sector.percentage | mathRound }}%</span
                    >
                  </div> -->
                  </div>
                </div>
              </div>
              <!-- <div style="padding-left: 160px;">
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                    Entrepreneurship
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                  Leadership
                  <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                    Employment
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <p class="text-2xl tracking-tighter font-small text-center print:text-7xl ml-5">
                    Consumption
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                    or
                    <img
                      src="@/assets/images/right.png"
                      alt="background-image"
                      style="width:5%; display:inline-flex;"
                    />
                  </p> 
                </div>
              </div> -->
            </div>

            <div class="Profile_Criteria_Wrepper">
              <!-- profile Block -->
              <div class="Profile_CountryWrepper">
                <div class="Profile_Block">
                  <div class="OverviewTitle">
                    <h2 style="width:120px;">Profile</h2>
                    <span></span>
                  </div>
                  <!-- details in list -->
                  <div class="Profile_blockListText">
                    <div class="About_CompanyListText" v-if="
                      portfolioData &&
                      portfolioData.organizationProfile &&
                      portfolioData.organizationProfile.description
                    ">
                      <b>About the Organization:</b>
                      <br />
                      {{ portfolioData.organizationProfile.description }}
                    </div>
                    <div class="About_CompanyListText" v-if="
                      portfolioData &&
                      portfolioData.organizationProfile &&
                      portfolioData.organizationProfile.aboutTheCompany
                    ">
                      <b>About the Organization:</b>
                      <br />
                      {{ portfolioData.organizationProfile.aboutTheCompany }}
                    </div>
                    <!-- <div
                      class="About_CompanyListText"
                      v-if="
                        portfolioData &&
                        
                        portfolioData.objectivesonGenderEqualityandWomenEmpowerment
                      "
                    >
                      <b>Gender Equality Objectives:</b><br />
                      {{
                        portfolioData.objectivesonGenderEqualityandWomenEmpowerment
                      }}
                    </div> -->
                    <div class="About_CompanyListText" style="display: flex" v-if="
                      portfolioData &&
                    
                      (portfolioData.organizationProfile.website ||
                        portfolioData.organizationProfile.linkedin ||
                        portfolioData.organizationProfile.twitterHandle)
                    ">
                      <a :href="portfolioData.organizationProfile.website" target="_blank"
                        v-if="portfolioData.organizationProfile.website">
                        <img src="../../assets/images/Earth.png" alt="Earth" />
                      </a>
                      <a :href="portfolioData.organizationProfile.linkedin" target="_blank"
                        v-if="portfolioData.organizationProfile.linkedin">
                        <img src="../../assets/images/linkedin.png" alt="Linkedin" />
                      </a>
                      <a :href="portfolioData.organizationProfile.twitterHandle" target="_blank"
                        v-if="portfolioData.organizationProfile.twitterHandle">
                        <img src="../../assets/images/twitter.png" alt="Twitter" />
                      </a>
                    </div>
                    <ul>
                      <li style="word-break: break-word"  v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.organizationType
                      ">
                        <b>Organization Type:</b>
                        {{
                            portfolioData.organizationProfile.organizationType
                            | isArrayOrNot
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.productsOffered
                      ">
                        <b style="word-break: break-word">Products Offered:</b>
                        {{
                            portfolioData.organizationProfile.productsOffered
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.whatTypesOfProductsAreOffered
                      ">
                        <b style="word-break: break-word">Products Offered:</b>
                        {{
                            portfolioData.organizationProfile.whatTypesOfProductsAreOffered
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile
                          .investeeTypeRoutesToMarket
                      ">
                        <b style="word-break: break-word">Investee Type / Routes to Market:</b>
                        {{
                            portfolioData.organizationProfile
                              .investeeTypeRoutesToMarket | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.sectorsOfInterest
                      ">
                        <b style="word-break: break-word">Sectors of Interest:</b>
                        {{
                            portfolioData.organizationProfile.sectorsOfInterest
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.sector
                      ">
                        <b style="word-break: break-word">Sectors of Interest:</b>
                        {{
                            portfolioData.organizationProfile.sector
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.themesOfInterest
                      ">
                        <b style="word-break: break-word">Themes of Interest:</b>
                        {{
                            portfolioData.organizationProfile.themesOfInterest
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile.thematicFocus
                      ">
                        <b style="word-break: break-word">Themes of Interest:</b>
                        {{
                            portfolioData.organizationProfile.thematicFocus
                            | changeArrayIntoString
                        }}
                      </li>
                      <li style="word-break: break-word" v-if="
                        portfolioData &&
                        portfolioData.organizationProfile &&
                        portfolioData.organizationProfile
                          .geographiesOfInterest
                      ">
                        <b>Geographies of Interest:</b>
                        {{
                            portfolioData.organizationProfile
                              .geographiesOfInterest | changeArrayIntoString
                        }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="Profile_Block">
                  <div class="OverviewTitle">
                    <h2 style="width:600px;">ESG Pre-screening</h2>
                    <span></span>
                  </div>
                  <div class="GBVdetailsContent">
                    <div class="overOverviewDeitail">
                      <span>Overview of Portfolio</span>
                      <p>
                        As part of any due diligence, in-depth environmental,
                        social, and governance (ESG) is best practice. The below
                        portrays the percentage of companies in the portfolio
                        responding yes or no to the due diligence questions.
                      </p>
                    </div>
                    <div class="gbvh-progress-content">
                        <div class="gbvh-progress-content--left flex mr-2 mt-3">
                          <div class="div_tooltip" v-if="portfolioData.fundsAssessmentScore.eSClaimsTotal != portfolioData.fundsAssessmentScore.total">
                            <img src="@/assets/images/help_white.svg" alt="background-image"
                              style="width: 24px;padding: 2px;" />
                            <span class="div_tooltiptext" v-if="portfolioData.fundsAssessmentScore.eSClaimsTotal == 0" >Unknown</span>
                            <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.fundsAssessmentScore.eSClaimsTotal}} portfolio <span v-if="(portfolioData.fundsAssessmentScore.eSClaimsTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.fundsAssessmentScore.total}} portfolio companies</span>
                          </div>
                          Does have existing ESG claims, including sexual harassment.
                        </div>
                        <div class="gbvh-progress-content--right" v-if="portfolioData.fundsAssessmentScore.eSClaimsTotal > 0">
                          <div class="flex bar-div">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b" :percent="
                              portfolioData.fundsAssessmentScore.eSClaims
                              | mathRound
                            " color="#00dec5"></k-progress>
                          </div>

                        </div>
                        <div class="gbvh-progress-content--left flex mr-2 mt-3">
                          <div class="div_tooltip" v-if="portfolioData.fundsAssessmentScore.genderInProcurementStrategyTotal != portfolioData.fundsAssessmentScore.total">
                            <img src="@/assets/images/help_white.svg" alt="background-image"
                              style="width: 24px;padding: 2px;" />
                            <span class="div_tooltiptext" v-if="portfolioData.fundsAssessmentScore.genderInProcurementStrategyTotal == 0" >Unknown</span>
                            <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.fundsAssessmentScore.genderInProcurementStrategyTotal}} portfolio <span v-if="(portfolioData.fundsAssessmentScore.genderInProcurementStrategyTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.fundsAssessmentScore.total}} portfolio companies</span>
                          </div>
                          Gender & procurement strategy.
                        </div>
                        <div class="gbvh-progress-content--right" v-if="portfolioData.fundsAssessmentScore.genderInProcurementStrategyTotal > 0">
                          <div class="flex bar-div">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                              portfolioData.fundsAssessmentScore
                                .genderInProcurementStrategy | mathRound
                            " color="#00dec5"></k-progress>
                          </div>

                        </div>
                        <div class="gbvh-progress-content--left flex mr-2 mt-3">
                          <div class="div_tooltip" v-if="portfolioData.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal != portfolioData.fundsAssessmentScore.total">
                            <img src="@/assets/images/help_white.svg" alt="background-image"
                              style="width: 24px;padding: 2px;" />
                            <span class="div_tooltiptext" v-if="portfolioData.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal == 0" >Unknown</span>
                            <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal}} portfolio <span v-if="(portfolioData.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.fundsAssessmentScore.total}} portfolio companies</span>
                          </div>
                          Gender & value chain strategy.
                        </div>
                        <div class="gbvh-progress-content--right"  v-if="portfolioData.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal > 0">
                          <div class="flex bar-div">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                              portfolioData.fundsAssessmentScore
                                .valueChainStrategiesThatPromoteEquality | mathRound
                            " color="#00dec5"></k-progress>
                          </div>

                        </div>
                        <div class="gbvh-progress-content--left flex mr-2 mt-3">
                          <div class="div_tooltip" v-if="portfolioData.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal != portfolioData.fundsAssessmentScore.total">
                            <img src="@/assets/images/help_white.svg" alt="background-image"
                              style="width: 24px;padding: 2px;" />
                            <span class="div_tooltiptext" v-if="portfolioData.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal == 0" >Unknown</span>
                            <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal}} portfolio <span v-if="(portfolioData.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.fundsAssessmentScore.total}} portfolio companies</span>
                          </div>
                          Active initiatives to include disadvantaged groups.
                        </div>
                        <div class="gbvh-progress-content--right"  v-if="portfolioData.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal > 0">
                          <div class="flex bar-div">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                              portfolioData.fundsAssessmentScore
                                .initiativesToIncludeDisadvantagedGroups | mathRound
                            " color="#00dec5"></k-progress>
                          </div>
                        </div>

                        <div class="gbvh-progress-content--left flex mr-2 mt-3">
                          <div class="div_tooltip" v-if="portfolioData.fundsAssessmentScore.genderedApproachToCovid19Total != portfolioData.fundsAssessmentScore.total">
                            <img src="@/assets/images/help_white.svg" alt="background-image"
                              style="width: 24px;padding: 2px;" />
                            <span class="div_tooltiptext" v-if="portfolioData.fundsAssessmentScore.genderedApproachToCovid19Total == 0" >Unknown</span>
                            <span class="div_tooltiptext_partial" v-else>Partially completed - This percentage is based on {{portfolioData.fundsAssessmentScore.genderedApproachToCovid19Total}} portfolio <span v-if="(portfolioData.fundsAssessmentScore.genderedApproachToCovid19Total) > 1">companies</span><span v-else>company</span> who have submitted their data out of a total of  {{portfolioData.fundsAssessmentScore.total}} portfolio companies</span>
                          </div>
                          Gendered approach to COVID-19.
                        </div>
                        <div class="gbvh-progress-content--right"  v-if="portfolioData.fundsAssessmentScore.genderedApproachToCovid19Total > 0">
                          <div class="flex bar-div">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b;" :percent="
                              portfolioData.fundsAssessmentScore
                                .genderedApproachToCovid19 | mathRound
                            " color="#00dec5"></k-progress>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 2xCriteria Block -->
              <div class="barchart2xCriteria_Wrepper">
                <!-- entrepreneurship barchart start -->
                <div class="OverviewTitle">
                  <h2 style="width:1150px;">2X Criteria Across the Portfolio</h2>
                  <span></span>
                </div>
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon1.png" alt="" />
                  <p>Entrepreneurship</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Average % of Shares Held by Women in Portfolio Companies
                    </div>
                    <b v-if="!portfolioData.fundsPercentShares || portfolioData.fundsPercentShares == ''">
                      Not Applicable
                    </b>

                    <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="300"
                      :height="400" label1="Women" label2="Men" :totalValue="[Number(100)]" :filledValue="[
                        Number(
                          portfolioData.fundsPercentShares * 100) ,
                      ]">
                    </barChartVertical>
                  </div>
                  <!-- companies  -->
                  <div class="ChartBarContent">
                    <div>
                      <div>
                        <div class="Founded_WomanText">
                          <span>Average % of Portfolio Companies Founded by a Woman</span>
                          <k-progress v-if="
                            portfolioData.fundsAssessmentScore &&
                            (portfolioData.fundsAssessmentScore.foundedByAWoman ||
                              portfolioData.fundsAssessmentScore.foundedByAWoman == 0)
                          " style="width: 100%" :percent="
                            portfolioData.fundsAssessmentScore.foundedByAWoman
                            | mathRound
                          " color="#00dec5"></k-progress>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <!-- leadership barchart start -->
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon2.png" alt="" />
                  <p>Leadership</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Average % Senior Managers Who are Women in Portfolio
                      Companies
                    </div> 
                    <b v-if="!portfolioData.fundsWhatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners || isNaN(portfolioData.fundsWhatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners/portfolioData.fundsWhatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners)">
                      Not Applicable
                    </b>
                    <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="300"
                      :height="400" type="leadership" label1="Women" label2="Men" :totalValue="[Number(100)]"
                      :filledValue="[
                        Number(
                          (portfolioData.fundsWhatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners /
                            portfolioData.fundsWhatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners) *
                          100
                        ),
                      ]">
                    </barChartVertical>
                  </div>
                  <div class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Average % of Board Members Who are Women in Portfolio
                      Companies
                    </div>
                    <b  v-if="!portfolioData.fundsHowManyBoardMembersOrInvestmentCommitteeMembersAreThere || isNaN(portfolioData.fundsHowManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere/portfolioData.fundsHowManyBoardMembersOrInvestmentCommitteeMembersAreThere)">
                      Not Applicable
                    </b>
                    <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="430"
                      :height="400" type="leadership" label1="Women" label2="Men" :totalValue="[Number(100)]"
                      :filledValue="[
                        Number(
                          (portfolioData.fundsHowManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere /
                            portfolioData.fundsHowManyBoardMembersOrInvestmentCommitteeMembersAreThere) *
                          100
                        ),
                      ]">
                    </barChartVertical>
                  </div>
                </div>

                <!-- employment barchart start -->
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon.3.png" alt="" />
                  <p>Employment</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div class="ChartBarContent" style="max-width:625px;margin:auto;">
                    <div class="ChartBarPercentageText">
                      Average % of Workforce that are Women in Portfolio
                      companies
                    </div>
                    
                    <b v-if="(portfolioData.fundsTotalPtFtEmployees == '0' ||  portfolioData.fundsTotalPtFtEmployees == '0.0') &&
                    (portfolioData.fundsMidManagerTot == '0' ||  portfolioData.fundsMidManagerTot == '0.0') &&
                    (portfolioData.fundsFtEmployees == '0' ||  portfolioData.fundsFtEmployees == '0.0') &&
                    (portfolioData.fundsPtEmployees == '0' ||  portfolioData.fundsPtEmployees == '0.0') &&
                    (portfolioData.fundsContractorEmployees == '0' ||  portfolioData.fundsContractorEmployees == '0.0') &&
                    (portfolioData.fundsSupplierEmployees == '0' ||  portfolioData.fundsSupplierEmployees == '0.0')">
                      Not Applicable
                    </b>
                    <portfolioEmploymentBarChart
                      v-else
                      :key="spiderChartUpdateKey"
                      :type="investor"
                      :chartData="portfolioData"
                      :assessmentYear="assessmentYear"
                      :chartLabels="['Total','Middle Managers','FT employees','PT employees', 'Contractual','Supplier']"
                    ></portfolioEmploymentBarChart>
                  </div>
                </div>
                <div class="employee_policies">
                  <div
                    v-if="portfolioData.fundsAssessmentScore && portfolioData.fundsAssessmentScore.policies && portfolioData.fundsAssessmentScore.policies.length > 0"
                    class="ChartBarContent">
                    <div class="Quality_EmploymentText">
                      <p>
                        Average % of Portfolio Companies with Employment Quality
                        Policies & Programs
                      </p>
                      <div class="gbvh-progress-wrapper">
                        <div class="gbvh-progress-content mt-2 flex bar-div"
                          v-for="(employment_data, index) in portfolioData.fundsAssessmentScore.policies" :key="index">
                          <div style="width: 50%;">
                            {{ employment_data.name }}
                          </div>
                          <div style="width: 50%;" class="gbvh-progress-content--right">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b" :percent="
                              employment_data.percentage | mathRound
                            " color="#00dec5"></k-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="portfolioData[1] && portfolioData[1].whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                    portfolioData[1].whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization.length > 0
                  " class="ChartBarContent" style="margin-top: 2.5rem !important">
                    <div class="Quality_EmploymentText">
                      <!-- <p>{{ assessmentYear[1] }}</p> -->
                      <div class="gbvh-progress-wrapper">
                        <div class="gbvh-progress-content mt-2 flex bar-div"
                          v-for="(employment_data, index) in portfolioData[1]
                          .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization" :key="index">
                          <div style="width: 50%;">
                            {{ employment_data }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Consumption barchart start -->
                <div class="CriteriaTitleText mt-5">
                  <img src="../../assets/images/EligibleIcon.4.png" alt="" />
                  <p>Gender Lens</p>
                </div>

                <div class="employee_policies">
                  <div v-if="
                  portfolioData.fundsAssessmentScore &&
                  (portfolioData.fundsAssessmentScore.genderLensConsumptionFieldsFIS &&
                  portfolioData.fundsAssessmentScore.genderLensConsumptionFieldsFIS.length > 0) ||
                  (portfolioData.fundsAssessmentScore.genderLensConsumptionFieldsFunds &&
                  portfolioData.fundsAssessmentScore.genderLensConsumptionFieldsFunds.length > 0)
                  " class="ChartBarContent">
                    <div class="Quality_EmploymentText">
                      <!-- for funds -->
                      <div class="gbvh-progress-wrapper" v-if="portfolioData.fundsAssessmentScore.genderLensConsumptionFunds && portfolioData.fundsAssessmentScore.genderLensConsumptionFunds.length">
                        <div class="mt-5">
                          <span class="text-xl font-bold text-black">Funds</span>
                        </div>
                        <div class="gbvh-progress-content mt-2 flex bar-div"
                          v-for="(employment_data, index) in portfolioData.fundsAssessmentScore.genderLensConsumptionFunds"
                          :key="index">
                          <div style="width: 50%;">
                            {{ employment_data.name }}
                          </div>
                          <div style="width: 50%;" class="gbvh-progress-content--right">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b" :percent="
                              employment_data.percentage | mathRound
                            " color="#00dec5"></k-progress>
                          </div>
                        </div>
                      </div>
                      <!-- for FI's -->
                      <div class="gbvh-progress-wrapper mt-5" v-if="portfolioData.fundsAssessmentScore.genderLensConsumptionFIS && portfolioData.fundsAssessmentScore.genderLensConsumptionFIS.length">
                        <div class="">
                          <span class="text-xl font-bold text-black">FIs</span>
                        </div>
                        <div class="gbvh-progress-content mt-2 flex bar-div"
                          v-for="(employment_data, index) in portfolioData.fundsAssessmentScore.genderLensConsumptionFIS" :key="index">
                          <div style="width: 50%;">
                            {{ employment_data.name }}
                          </div>
                          <div style="width: 50%;" class="gbvh-progress-content--right">
                            <k-progress style="width: 100%; font-weight: bold;color:#1b1b1b" :percent="
                              employment_data.percentage | mathRound
                            " color="#00dec5"></k-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Company Section-->
            <div class="OverviewBlock">
              <div class="CompanyTitle" :key="updateKey">
                Number of Funds/FIs invested in : {{
                    portfolioData.funds && portfolioData.funds.length
                      ? portfolioData.funds.length
                      : "NA"
                }}
              </div>
              <div class="companies_and_fund_table">
                <table class="divide-y divide-gray-200 bg-white w-full">
                  <thead>
                    <tr>
                      <th class="px-3 py-2" v-for="(header, index) in fundsHeader" :key="index"
                        :style="index == 0 ? 'width: 16rem' : (index == 1 ? 'width: 9rem' : '')">
                        <div class="flex" @click="changeOrder('funds', header.orderBy, index)">
                          <span :class="{ 'mr-3': index == 0 }">{{ header.title }}</span>
                          <img
                            :src="header.defalutOrderTypeASC ? require(`@/assets/images/arrows/arrow-up.svg`) : require(`@/assets/images/arrows/arrow-down.svg`)" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200">
                    <tr v-for="(funds, index) in fundsDetails.fundsData" :key="index">
                      <td class="px-3 py-8">
                        <div class="flex image-center">
                          <img class="organization-img" :src="funds.logo[0].url" crossorigin="anonymous" v-if="funds &&
                          funds.logo &&
                          funds.logo[0] &&
                          funds.logo[0].url" />
                          <h6 @click="goToTwoXAssess(funds, 'funds')" style="cursor: pointer">
                            {{ funds.organizationName }}
                          </h6>
                          <!-- <a class="organization-name" href="javascript:void(0);">{{ funds.organizationName }}</a> -->
                        </div>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="(funds.myPortfolio !=  'I will directly input aggregated information now')">
                        <div class="flex justify-between">
                          <div class="mr-1">Unknown</div>
                          <div class="tooltip" style="width:24px;">
                            <img
                              class="background-image"
                              src="@/assets/images/help_white.svg"
                              alt="background-image"
                            />
                            <span class="twoXEligibleInvestedHoverText">Unknown</span>
                          </div>
                        </div>
                        </span>
                        <span v-else>
                          <div class="flex justify-center">
                            <div class="mr-2">{{ funds.twoXeligibilityPercentage | mathOnlyRound }}%</div>
                            <div>
                              <img
                                  src="@/assets/images/Rightcircle.svg"
                                  class="background-image"
                                  alt="background-image"
                                  v-if="funds.eligible2X === 'Yes' || funds.eligible2X === 'true' || funds.eligible2X == 'TRUE'"
                                />
                                <img v-else style="border-radius: 0;top: 3px; position: relative;"
                                  class="background-image"
                                  src="@/assets/images/3rdPartyvalidated.svg"
                                  alt="background-image"
                                />
                            </div>
                          </div>
                        </span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="funds.percentShares == ''">N/A</span>
                        <span v-else> {{ funds.percentShares * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8">
                        <img src="@/assets/images/Rightcircle.svg" class="background-image" alt="background-image"
                          v-if="funds.foundedByAWoman == 'Yes'">
                        <img src="@/assets/images/Crosscircle.svg" class="background-image" alt="background-image"
                          v-else>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="funds.percentOfWomenSeniorManagers  == ''">N/A</span> 
                        <span v-else> {{ funds.percentOfWomenSeniorManagers * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="funds.percentWomenOnBoardIc  == ''">N/A</span> 
                        <span v-else> {{ funds.percentWomenOnBoardIc * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <span v-if="funds.percentWomenInOverallWorkforce  == ''">N/A</span> 
                        <span v-else> {{ funds.percentWomenInOverallWorkforce * 100 | mathOnlyRound }}% </span>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <div class="tooltip TooltipImgText">
                          <p>
                            {{ funds.employmentQualityAndPolicesPrograms * 100 | mathOnlyRound }}%
                          </p>
                          <span class="tooltiptext tooltiptext_aligned" style="top: -76px !important;"
                            :class="funds.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                            funds.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization.length > 0 ? 'fund_company_list_top_multiple' : 'fund_company_list_top_single'">
                            <ul class="fund_company_tooltip"
                              :class="{ 'fund_company_list': funds.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                              funds.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization.length > 2 }"
                              v-if="funds.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                              funds.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization.length > 0">
                              <li
                                v-for="(tooltio_content, index) in funds.whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization"
                                :key="index">
                                <span v-if = "tooltio_content == 'Other'">
                                  {{ index+1 }}. {{ funds.ifOtherWhatIsIt }}
                                </span>
                                <span v-else>
                                  {{ index+1 }}. {{ tooltio_content }}
                                </span>
                              </li>
                            </ul>
                            <span v-else>No Data Available</span>
                          </span>
                        </div>
                      </td>
                      <td class="px-3 py-8 center-text">
                        <!-- <span v-if="!funds.modTwo">Unknown</span> -->
                        <span v-if="funds.ofCustomersClientsWomen != ''">{{ funds.ofCustomersClientsWomen * 100 | mathOnlyRound }}%</span>
                        <span v-else-if="funds.howManyDirectCustomersOrClientsDoYouHave == '0' || funds.howManyDirectCustomersOrClientsDoYouHave == '0.0'">N/A</span>
                        <span v-else>Unknown</span>
                        
                      </td>
                      <td class="px-3 py-8 center-text">
                        {{ funds.ConsumptionPractice * 100 | mathOnlyRound }}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button class="
                      loadmore-btn
                      flex
                      m-auto
                      justify-center
                      border border-gray-300
                      px-4
                      py-2
                      bg-white
                      text-base
                      leading-6
                      font-bold
                      text-gray-800
                      hover:text-gray-500
                      focus:outline-none
                      focus:border-blue-300
                      focus:shadow-outline-blue
                      transition
                      ease-in-out
                      duration-150
                      sm:text-sm sm:leading-5
                    " @click="loadMore('funds')" v-if="
                      fundsDetails.fundsData && fundsDetails.toolsDataLength < fundsDetails.totalCount && fundsDetails.toolsDataLength > 0
                    ">Load more</Button>
            </div>
          </div>
          <div v-if="
            investor == true &&
            portfolioData.investor &&
            portfolioData.funds == [] &&
            portfolioData.funds.length == 0
          ">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  ">
                  <img class="h-16 print:h-auto print:w-64" src="@/assets/images/2X-assessment/My2XAssessments3b.png"
                    alt="" />
                  <h1 class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    ">
                    No Data Found
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <!-- for investor end -->
        </div>

        <div v-else key="else_div">
          <div class="flex flex-row justify-left items-center mt-10 ml-20">
            <div class="flex flex-col item-center justify-left">
              <div class="text-gray-600 font-medium flex flex-row items-center justify-center print:flex-col">
                Uh-oh! You have not given Equilo the data required to populate this assessment!.
              </div>
              <div class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2">
                <a href="/#/2X-Ecosystem/New-2XAssessment" class="text-white text-sm font-medium mr-3"
                  style="width: max-content;">
                  Start your assessment now
                </a>
              </div>
              <div class="text-gray-600 font-medium flex flex-row items-center print:flex-col mt-5">
                You gave us the data but it seems missing?
              </div>
              <div class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2">
                <a href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red"
                  class="text-white text-sm font-medium mr-3" style="width: max-content;">
                  Contact support now
                </a>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="pdfexportbtn" v-if="endofcreated && myPortfolioData">
        <myPortfolio-print id="my_portfolio_print"
          :key="updateKey"
          :userFromParent="user"
          :investorFromParent="investor"
          :portfolioSwitchTog="portfolioData.portfolioCompanyStatus"
          :criteriaCalcType="criteriaCalcType"
          @pageRefreshed="pageRefreshed"
          :myPortfolioDataFromParent="myPortfolioData"
          :typeFromParent="type"
          :myPortfolioTypeFromParent="myPortfolioType"
          :companyDetailsFromParent="companyDetails"
          :countDataFromParent="countData"
          :fundsDetailsFromParent="fundsDetails"
          :assessmentYearFromParent="assessmentYear"
          :portfolioDataFromParent="portfolioData" />
      </div>
    </div>
  </div>
</template>
<script>
import heatmap from "@/components/charts/myPortfolioHeatmap.vue";
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import spiderchart from "@/components/charts/myPortfolioSpiderChart.vue";
import spiderchartFunds from "@/components/charts/myPortfolioSpiderChartFunds.vue";
import barChartVertical from "@/components/charts/barChartVerticalPortfolio.vue";
import portfolioEmploymentBarChart from "@/components/charts/portfolioEmploymentBarChart.vue";
import portfolioPiechart from "@/components/charts/portfolioPieChart.vue";
import portfolioInvestementPiechart from "@/components/charts/portfolioInvestementPiechart.vue";
import myPortfolioPrint from "@/pages/reports/myPortfolio-print.vue"
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import Vue from "vue";
import JsonCSV from "vue-json-csv";
import * as XLSX from 'xlsx/xlsx.mjs';
import html2pdf from "html2pdf.js"

Vue.component("downloadCsv", JsonCSV);
export default {
  name: "myPortfolio",
  data: function () {
    return {
      myPortfolioData: [],
      portfolioData: [],
      spiderChartUpdateKey: 1,
      esclaim_desc:'',
      genderInProcurementStrategyTotal_desc:'',
      valueChainStrategiesThatPromoteEqualityTotal_desc:'',
      initiativesToIncludeDisadvantagedGroupsTotal_desc:'',
      genderedApproachToCovid19Total_desc:'',
      doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal_desc:'',
      workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal_desc:'',
      engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal_desc:'',
      companyDetails: {
        companyData: [],
        companiesIds: [],
        page: 1,
        limit: 10,
        orderBy: "ASC",
        orderByColumnName: 'organizationName',
        toolsDataLength: 0,
        totalCount: 0
      },

      fundsDetails: {
        fundsData: [],
        fundsIds: [],
        page: 1,
        limit: 10,
        orderBy: "ASC",
        orderByColumnName: 'organizationName',
        toolsDataLength: 0,
        totalCount: 0
      },
      downloadPDF: false,

      companyHeader: [
        {
          title: 'Companies',
          orderBy: 'organizationName',
          defalutOrderTypeASC: true
        },
        {
          title: '2X Eligible',
          orderBy: 'twoXeligibilityPercentage',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Shares ',
          orderBy: 'percentShares',
          defalutOrderTypeASC: false
        },
        {
          title: 'Founded by women ',
          orderBy: 'foundedByAWoman',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Senior Manager ',
          orderBy: 'percentOfWomenSeniorManagers',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Board Members',
          orderBy: 'percentWomenOnBoardIc',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women in the Workforce',
          orderBy: 'percentWomenInOverallWorkforce',
          defalutOrderTypeASC: false
        },
        {
          title: 'Employment Quality Policies & Programs',
          orderBy: 'employmentQualityAndPolicesPrograms',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Customers',
          orderBy: 'percentDirectCustomersWhoAreWomen',
          defalutOrderTypeASC: false
        },
        {
          title: 'Consumption Practices ',
          orderBy: 'ConsumptionPractice',
          defalutOrderTypeASC: false
        }
      ],

      fundsHeader: [
        {
          title: 'Funds/FIs',
          orderBy: 'organizationName',
          defalutOrderTypeASC: true
        },
        {
          title: '2X Eligible',
          orderBy: 'twoXeligibilityPercentage',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Shares ',
          orderBy: 'percentShares',
          defalutOrderTypeASC: false
        },
        {
          title: 'Founded by women ',
          orderBy: 'foundedByAWoman',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Senior Manager ',
          orderBy: 'percentOfWomenSeniorManagers',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Board Members',
          orderBy: 'percentWomenOnBoardIc',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women in the Workforce',
          orderBy: 'percentWomenInOverallWorkforce',
          defalutOrderTypeASC: false
        },
        {
          title: 'Employment Quality Policies & Programs',
          orderBy: 'employmentQualityAndPolicesPrograms',
          defalutOrderTypeASC: false
        },
        {
          title: 'Women Customers ',
          orderBy: 'ofCustomersClientsWomen',
          defalutOrderTypeASC: false
        },
        {
          title: 'Consumption Practices ',
          orderBy: 'ConsumptionPractice',
          defalutOrderTypeASC: false
        }
      ],
      env,
      isReadMore: false,
      readMoreInfo: null,
      title: null,
      user: [],
      endofcreated: false,
      showData: false,
      taabs: {
        Entrepreneurship: env.taabColors.humanDignity,
        Leadership: env.taabColors.knowledgeAndBeliefs,
        Employment: env.taabColors.accessToResources,
        Consumption: env.taabColors.powerAndDecision,
        "Indirect Investment": env.taabColors.indirectInvestement,
      },
      options: {
        text: {
          color: "#0077C8",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#0077C8",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 50,
          verticalTextAlign: 20,
          horizontalTextAlign: 11,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "circle",
        },
      },
      assessmentYear: [],
      investor: false,
      toggleOptions: [],
      countData: [],
      criteriaValue: '',
      leadership: -1,
      employment: 0,
      entrepreneurship: 0,
      consumption: 0,
      twoxEligible: 0,
      indirectInvestment: 0,
      type: '',
      csvData: [],
      updateKey: 0,
      pdfPrintPage: false,
      portfolioSwitchTog: '',
      myPortfolioType: '',
      myCsvData: [],
      criteriaCalcType: 'company',
      isPageRefreshed: false,
      updateKeyAfterRefresh: 0,
      allCsvData: [],
    };
  },
  updated() { },
  components: {
    pageLoader,
    spiderchart,
    spiderchartFunds,
    portfolioPiechart,
    barChartVertical,
    portfolioEmploymentBarChart,
    portfolioInvestementPiechart,
    heatmap,
    myPortfolioPrint
  },
  filters: {
    isArrayOrNot(data) {
      if (typeof data == "object") {
        return Object.values(data).join(", ");
      } else {
        return data;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    numberWithCommas(x) {
      var numFormat = new Intl.NumberFormat("en-US");
      return numFormat.format(x);
    },
    mathOnlyRound(value) {
      return Math.round(value);
    },
    mathRound(value) {
      value = value * 100;
      return Math.round(value);
    },
    changeArrayIntoString: function (value) {
      if (!value || value.length == 0) return "";
      return value.join(", ");
    },
  },
  async created() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if (this.user.accessPortfolio == false) {
      this.$router.push("/")
    }
    this.$store.commit("setBreadcrumbs", [
      {
        name: "My Portfolio",
        url: "/2X-Ecosystem/my-portfolio",
      },
    ]);

    await this.getPortfolioData();
    if (this.companyDetails.companiesIds.length > 50) {
      this.companyDetails.limit = 20
    }

    await this.getCompanyData(this.companyDetails.orderByColumnName, this.companyDetails.orderBy);
    this.criteriaCalc("company");
    this.csvData = await this.getDownloadCSV();
  },
  methods: {
    pageRefreshed(val) {
      this.updateKeyAfterRefresh++;
      this.isPageRefreshed = val;
      this.updateKeyAfterRefresh--;
    },
    exportToPDF() {
      this.downloadPDF = true;
      var pagebreak =
        { before: '.before_page' };
      setTimeout(async () => {
        let element = document.querySelector('#my_portfolio_print');
        await html2pdf().set({
          margin: 1,
          filename: 'myPortfolio.pdf',
          pagebreak: pagebreak,
          html2canvas: {
            logging: false,
            dpi: 192, 
            letterRendering: true,
            useCORS: true,
            allowTaint: true,
          },
          jsPDF: {
            orientation: 'portrait',
            unit: 'cm',
            format: [55, 35]
          }
        }).from(element).save();
        this.downloadPDF = false;
        return;
      }, 1000);
    },
    ec(r, c) {
      return XLSX.utils.encode_cell({ r: r, c: c });
    },
    deleteRow(ws, row_index) {
      var variable = XLSX.utils.decode_range(ws["!ref"])
      for (var R = row_index; R < variable.e.r; ++R) {
        for (var C = variable.s.c; C <= variable.e.c; ++C) {
          ws[this.ec(R, C)] = ws[this.ec(R + 1, C)];
        }
      }
      variable.e.r--
      ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
    },
    onexport(data) {
      let type = this.investor ? 'fund' : 'company';
      let calculateData = [];

      this.esgDescription(data)
      this.myPortfolioData.forEach(data => {
        calculateData[data.portfolioCompanyStatus] = this.criteriaCalculateForAll(type, data)
      })
      
      this.myCsvData = [];
      let alumniData = [];
      let pipelineData = [];
      let portfolioData = [];

      let alumniProfileData = [];
      let pipelineProfileData = [];
      let portfolioProfileData = [];

      let obj = [];
      obj = Object.entries(this.allCsvData);
      // same for all organization
      obj.forEach(item => {
        item[1].map(data => {
          if (data[0].includes('%') && !data[1].includes('%')) {

            if(data[1] == "Not Applicable" || data[1] == "Unknown")
            data[1] = data[1] ? data[1] : '';
            
            else
            data[1] = data[1] ? data[1] + '%' : '';

          }
          
          if (data[0].includes('Target:') && !data[1].includes('%') && !data[1].includes('NaN')) {
            if (data[1] && data[1] != null && data[1] != 'null' && data[1] != 'NA') {
              if (data[0].includes('Target:') && data[1] == 0){
                data[1] = 'Unknown';
              }else{
                data[1] =  Math.round(data[1]) + '%';
              }
            }
            

            if (data[0] == 'Target: Women Founder' && data[1] == 'null') {
              data[1] = 'Unknown';
            }
            

            if (data[0] == 'Target: Indirect Investment' && data[1] == 'NA') {
              data[1] = 'Unknown';
            }
            
            
            if(data[1] == 'NaN%' || data[1] == 'Unknown%'){
               data[1] = 'Unknown';
            }
          }

          if(data[0].startsWith('%') && data[1] == ""){
               data[1] = 'Not Applicable';
          }
        })
      })



      obj.forEach(item => {
        let cd = [];

        if (item[0].includes('Alumni')) {
          cd = calculateData.Alumni;
        }
        if (item[0].includes('Pipeline')) {
          cd = calculateData.Pipeline;
        }
        if (item[0].includes('Portfolio')) {
          cd = calculateData.Portfolio;
        }
        item[1].map(data => {
          if (data[0] == '2X Eligible') {
            if(cd.twoxEligible  == 'Unknown'){
              data[1] =  cd.twoxEligible;
            }
            else{
              data[1] =  Math.round(cd.twoxEligible > 0 ? cd.twoxEligiblePercent : '') + '%';
            }
          }
          if(data[0].includes('Target:') && (data[1] == 'NaN' || data[1] == 0)){
            data[1] = 'Unknown';
          }
          if (data[0] == 'Entrepreneurship Criteria Met') {
            data[1] = Math.round(cd.entrepreneurship * 100) + '%';
          }
          if (data[0] == 'Leadership Criteria Met') {
            data[1] = Math.round(cd.leadership * 100) + '%';
          }
          if (data[0] == 'Employment Criteria Met') {
            data[1] = Math.round(cd.employment * 100) + '%';
          }
          if (data[0] == 'Consumption Criteria Met') {
            data[1] = Math.round(cd.consumption * 100) + '%';
          }
          if (data[0] == 'Indirect Investment Criteria Met') {
            
            if(cd.twoxEligible  == 'Unknown'){
              data[1] = cd.twoxEligible;
            }
            else{
              data[1] = Math.round(cd.indirectInvestment * 100) + '%';
            }
          }
          
          if (data[0] == 'Plans to improve 2X Criteria' && !data[1].includes('%')) {
            data[1] = data[1] + '%';
          }
          if (data[0] == '3rd Party Validated' && !data[1].includes('%')) {
            data[1] = data[1] + '%';
          }
          if (data[0] == 'Portfolio Investment in 2X Eligible Companies' && !data[1].includes('$')) {
            data[1] = '$' + Math.round(data[1]);
          }
          if (data[0] == 'Total Amount Invested In TwoX Eligible Companies' && !data[1].includes('$')) {
            data[1] = '$' + Math.round(data[1].split('$')[1]);
          }
          if (data[0] == 'Total Amount Invested In TwoX Eligible Funds' && !data[1].includes('$')) {
            data[1] = '$' + Math.round(data[1].split('$')[1]);
          }
          if (data[0] == 'Does have existing ESG claims, including sexual harassment.' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] : data[1] + '%'+ this.esclaim_desc;
          }

          if (data[0] == 'Gender & procurement strategy' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] : data[1] + '%'+ this.genderInProcurementStrategyTotal_desc;

          }
          if (data[0] == 'Gender & value chain strategy' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] :  data[1] + '%'+ this.valueChainStrategiesThatPromoteEqualityTotal_desc;
          }
          if (data[0] == 'Active initiatives to include disadvantaged groups' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] :  data[1] + '%'+ this.initiativesToIncludeDisadvantagedGroupsTotal_desc;
          }
          if (data[0] == 'Gendered Approach to COVID-19' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] :  data[1] + '%'+ this.genderedApproachToCovid19Total_desc;
          }
          if (data[0] == 'Adopt practices that improve agency, decision-making and empower women in their communities/households' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] : data[1] + '%'+ this.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal_desc;
          }
          if (data[0] == 'Work with financing or implementation partners who work with women value chain partners' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] : data[1] + '%'+ this.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal_desc;
            
          }
          if (data[0] == 'Engage women in paid income opportunities in supply chain' && !data[1].includes('%')) {
            data[1] = (data[1] == 'Unknown') ? data[1] : data[1] + '%'+ this.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal_desc;
          }

          if (data[0] == 'Organization Type') {
              data[1] = this.portfolioData.organizationProfile.organizationType?.toString();
          }

          if(data[0] == 'Sectors of Interest'){
            data[1] = this.portfolioData.organizationProfile.sectorsOfInterest?.toString();
          }

          if(data[0] == 'Themes of Interest'){

            if(this.portfolioData.organizationProfile.themesOfInterest){
              data[1] = this.portfolioData.organizationProfile.themesOfInterest?.toString();
            }
            else if(this.portfolioData.organizationProfile.thematicFocus){
              data[1] = this.portfolioData.organizationProfile.thematicFocus?.toString();

            }
          }

          if (data[0] == '% Women Founder') {

            if(type == "company")
            {
              if(cd.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield == 1)
              {
                data[1] = (data[1] == "Not Applicable") ? '0%' : data[1];
              }
            }
          }


          if(data[1] == "NaN%" || data[1] == "NaN") {
            data[1] = "Not Applicable";
          }
        })
      })
      // same for all organization
      if (this.myPortfolioType == 'fund') {
        obj.forEach(item => {
          item[1].map((data, i) => {
            if (data[1] == 'undefined' || data[1] == undefined) {
              data[1] = '';
            }
            if (data[0] == 'About the Organization') {
              data[1] = this.portfolioData.organizationProfile.aboutTheCompany
            }
            if (data[0] == 'Organization Title') {
              data[1] = this.portfolioData.organizationProfile.organizationName
            }
            if (data[0] == 'Organization Type') {
              data[1] = this.portfolioData.organizationProfile.organizationType
            }
            if (data[0] == 'Sectors of Interest') {
              data[1] = this.portfolioData.organizationProfile.sector.join(", ");
            }
            if (data[0] == 'Products Offered') {
              data[1] = this.portfolioData.organizationProfile.whatTypesOfProductsAreOffered.join(", ");
            }
            if (data[0] == 'Themes of Interest') {
              data[1] = this.portfolioData.organizationProfile.thematicFocus.join(", ");
            }
            if (data[0] == 'Investee Type / Routes to Market') {
              this.companiesCSVData.splice(i, 1);
            }
            if (data[0] == 'Geographies of Interest') {
              this.companiesCSVData.splice(i, 1);
            }
          })
        })
      }

      this.csvData.forEach(data => {
        let obj = Object.entries(data)[0][0]
        if(this.investor && data[obj] != '') {
          let len = data[obj][0].length - 1;
          let arr = ['Organization Sector'];
          for(let a=0; a < len; a++) {
            arr.push('Financial Services');
          }
          data[obj].push(arr);
        }


        data[obj].map((item) => {
          if (item[0] == 'Sector Focus') {
            item[0] = 'Sectors Invested In';
          }
          if (item[0] == 'Contact Phone') {
            item.filter((i, index) => {
              i = String(i[index])
            })
          }
          if (item[0] == 'Amount of current funding desired') {
            item.map((i, index) => {
              if (index > 0 && item[index] != 'Unknown') {
                item[index] = item[index] ? String('$' + item[index]) : '';
              }
            })
          }
          if (item[0] == 'Total amount of current or past 2X qualified funds') {
            item.map((i, index) => {
              if (index > 0 && item[index] != 'Unknown') {
                item[index] = item[index] ? String('$' + item[index]) : '';
              }
            })
          }

          if (item[0] == "Budgeted or are looking for resources to implement a gender plan/strategy?" || item[0] == "Have you budgeted or are looking for resources to implement a gender plan/strategy?") {
            item.map((i, index) => {
              if(index > 0 && item[index] == ''){
                item[index] = "Unknown"
              }
            })
          }

          

          if (item[0] == 'Do you have time-bound, measurable gender goals and targets in the company strategy?' || item[0] == 'Time-bound, measurable gender equality goals and targets in the company strategy?') {
            item.map((i, index) => {
              if(index > 0 && item[index] == ''){
                item[index] = "Unknown"
              }
            })
          }
           if (item[0] == 'What is the total value of loan proceeds or funds committed in the future?' ||
            item[0] == 'What is the total value of loan proceeds or funds committed in the future to supporting businesses that meet direct 2X criteria?' ||
            item[0] == 'What is the total current value of FI loan or proceeds or fund portfolio?' ||
            item[0] == 'What is the total current value of FI loan proceeds or fund portfolio that support businesses that meet direct 2X criteria?') {
            item.map((i, index) => {
              if (index > 0 && item[index] != 'Unknown') {
                item[index] = item[index] ? String('$' + item[index]) : '';
              }
            })
          }
          if (item[0] == 'Average ticket size for investors') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index] ? String('$' + item[index]) : '';
              }
            })
          }
          if (item[0] == '2X Intentions' && (item[1] == 1 || item[1] == 0)) {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] == 1) ? 'Yes' : 'No';
              }
            })
          }
          if (item[0] == 'Submission Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }

          if (item[0] == '% 2X Criteria Met') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = Math.round(item[index]) + '%';
              }
            })
          }
        
          if (item[0] == 'Leadership Criteria Met' && (item[1] == 1 || item[1] == 0)) {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] == 1) ? 'Yes' : 'No';
              }
            })
          }
          if (item[0] == '% of women senior managers') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = Math.round(item[index]) + '%';
              }
            })
          }
          if (item[0] == '% women on board/ IC') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] =  Math.round(item[index] * 100) + '%';
              }
            })
          }

          if(item[0] == "% FI loan proceeds or investment funds that meet 2X Criteria"){
            item.map((i, index) => {
              if (index > 0) {
                item[index] =  Math.round(item[index] * 100) + '%';
              }
            })
          }
          if (item[0] == 'Target: Women Senior Managers') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] * 100 == 0) ? 'Unknown' : Math.round(item[index] * 100) + '%';
              }
            })
          }
          if (item[0] == 'Women Senior Managers Target Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Target: Women on Board') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] * 100 == 0) ? 'Unknown' : item[index] * 100 + '%';
              }
            })
          }
          if (item[0] == 'Women on Board Target Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Target: Female Ownership Shares') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] * 100 == 0) ? 'Unknown' : Math.round(item[index] * 100) + '%';
              }
            })
          }
          if (item[0] == 'Female Shares Target Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Employment Criteria Met' && (item[1] == 1 || item[1] == 0)) {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] == 1) ? 'Yes' : 'No';
              }
            })
          }
          if (item[0] == 'Percent shares') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = Math.round(item[index]) + '%';
              }
            })
          }

          if (item[0] == 'Carry allocation') {
            item.map((i, index) => {
              if (index > 0) {
                if(item[index] != null)
                item[index] = Math.round(item[index]) + '%';
              }
            })
          }

          if (item[0] == '% women in overall workforce') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = Math.round(item[index]) + '%';
              }
            })
          }
          if (item[0] == 'Target: Women direct PT/ FT employees') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = Math.round(item[index]) == 0 ? 'Unknown' : Math.round(item[index]) + '%';
              }
            })
          }
          if (item[0] == 'Women direct employee Target Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Planned Products Target Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Indirect Investment Criteria Met' && (item[1] == 1 || item[1] == 0)) {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] == 1) ? 'Yes' : 'No';
              }
            })
          }
          if (item[0] == 'Entrepreneurship Criteria Met' && (item[1] == 1 || item[1] == 0)) {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] == 1) ? 'Yes' : 'No';
              }
            })
          }
          if (item[0] == 'Planned Policies and Programs Target Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Consumption Criteria Met' && (item[1] == 1 || item[1] == 0)) {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] == 1) ? 'Yes' : 'No';
              }
            })
          }
          if (item[0] == 'Investee 2X Criteria Target Date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Supply chain women workers target date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Target: Women PT/FT workers at companies in supply chain') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] * 100 == 0) ? 'Unknown' : item[index] * 100 + '%';
              }
            })
          }
          if (item[0] == '3rd party women workers target date') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = item[index].split(',')[0];
              }
            })
          }
          if (item[0] == 'Target: Women 3rd party workers') {
            item.map((i, index) => {
              if (index > 0) {
                item[index] = (item[index] * 100 == 0) ? 'Unknown' : Math.round(item[index] * 100) + '%';
              }
            })
          }

          if (item[0] == 'Sector') {
            item.map((i, index) => {
              if (index > 0) {
                if(item[index].search('Other') > -1){
                  let otherSector = ''
                  item[index] = item[index].replace('Other',otherSector);
                }
              }
            })
          }


          if (item[0].includes('%') && !item[0].includes('30%')) {
            item.map((i, index) => {
              if (index > 0) {
                if (item[index] && !String(item[index]).includes('%') && item[index] != 'NaN' && item[index] != 'NA') {
                  item[index] = (item[index] == 1) ? 'true' : item[index] ?  item[index] * 100 + '%' : '';
                }
              }
            })
          }
          if (item[0].includes('Target:') && item[0] != 'Target: Planned Policies and Programs' &&
            !item[0].includes('Target: Planned Product or service that benefits women (FIs)') &&
            !item[0].includes('Target: Planned Product or service that benefits women (Funds)') &&
            !item[0].includes('Target: Planned Product or service that benefits women')) {
            item.map((i, index) => {
              if (index > 0) {
                if (item[index] && !String(item[index]).includes('%') && item[index] != 'NaN' && item[index] != 'NA') {
                  item[index] = (item[index] * 100 == 0) ? 'Unknown' :  item[index] * 100 + '%';
                }
              }
            })
          }

          

          
          
          if (item[0].includes('Target:') || item[0].includes('%')) {
 
            item.map((i, index) => {
              if (index > 0) {

                if(item[index] == 'NaN%' || item[index] == 'Unknown%'){
                   item[index] = 'Unknown';
                }
              }
            })
          }
          
        }
        )
      })

      this.csvData.forEach(data => {
        if (data.alumni) {
          alumniData = data.alumni;
        }
        if (data.pipeline) {
          pipelineData = data.pipeline;
        }
        if (data.portfolio) {
          portfolioData = data.portfolio;
        }
      });
      
      
      if (this.investor && this.myPortfolioType == "investor") {
        alumniProfileData = this.allCsvData.AlumniFundsCSVData;
        pipelineProfileData = this.allCsvData.PipelineFundsCSVData;
        portfolioProfileData = this.allCsvData.PortfolioFundsCSVData;
      }
      
      if (!this.investor && this.myPortfolioType == "investor") {
        alumniProfileData = this.allCsvData.AlumniCompaniesCSVData;
        pipelineProfileData = this.allCsvData.PipelineCompaniesCSVData;
        portfolioProfileData = this.allCsvData.PortfolioCompaniesCSVData;
      }
      
      if (this.myPortfolioType == "fund") {
        alumniProfileData = this.allCsvData.Alumni;
        pipelineProfileData = this.allCsvData.Pipeline;
        portfolioProfileData = this.allCsvData.Portfolio;
      }


      if (alumniData && alumniData.length) {
        var alumniCSV = XLSX.utils.json_to_sheet(alumniData)
        this.deleteRow(alumniCSV, 0)
      }
      if (pipelineData && pipelineData.length) {
        var pipelineCSV = XLSX.utils.json_to_sheet(pipelineData)
        this.deleteRow(pipelineCSV, 0)
      }
      if (portfolioData && portfolioData.length) {
        var portfolioCSV = XLSX.utils.json_to_sheet(portfolioData)
        this.deleteRow(portfolioCSV, 0)
      }
      if (alumniProfileData && alumniProfileData.length) {
        var alumniProfileCSV = XLSX.utils.json_to_sheet(alumniProfileData)
        this.deleteRow(alumniProfileCSV, 0)
      }
      if (pipelineProfileData && pipelineProfileData.length) {
        var pipelineProfileCSV = XLSX.utils.json_to_sheet(pipelineProfileData)
        this.deleteRow(pipelineProfileCSV, 0)
      }
      if (portfolioProfileData && portfolioProfileData.length) {
        var portfolioProfileCSV = XLSX.utils.json_to_sheet(portfolioProfileData)
        this.deleteRow(portfolioProfileCSV, 0)
      }

      var wb = XLSX.utils.book_new() // make Workbook of Excel

      if ((alumniProfileData && alumniProfileData.length) && (alumniData && alumniData.length)) {
        XLSX.utils.book_append_sheet(wb, alumniProfileCSV, 'Aggregate Alumni Profile')
      }
      if ((pipelineProfileData && pipelineProfileData.length) && (pipelineData && pipelineData.length)) {
        XLSX.utils.book_append_sheet(wb, pipelineProfileCSV, 'Aggregate Pipeline Profile')
      }
      if ((portfolioProfileData && portfolioProfileData.length) && (portfolioData && portfolioData.length)) {
        XLSX.utils.book_append_sheet(wb, portfolioProfileCSV, 'Aggregate Portfolio Profile') // sheetAName is name of Worksheet
      }
      
      if (alumniData && alumniData.length) {
        XLSX.utils.book_append_sheet(wb, alumniCSV, (this.investor ? 'Alumni Funds-FIs' : 'Alumni Companies'))
      }
      if (pipelineData && pipelineData.length) {
        XLSX.utils.book_append_sheet(wb, pipelineCSV, (this.investor ? 'Pipeline Funds-FIs' : 'Pipeline Companies'))
      }
      if (portfolioData && portfolioData.length) {
        XLSX.utils.book_append_sheet(wb, portfolioCSV, (this.investor ? 'Portfolio Funds-FIs' : 'Portfolio Companies')) // sheetAName is name of Worksheet
      }

      // export Excel file
      let wbName = this.myPortfolioType == 'fund' ? this.portfolioData.organizationProfile.organizationName : this.portfolioData.organizationProfile.member2xc
      XLSX.writeFile(wb, wbName + '.XLSX') // name of the file is 'book.XLSX'
    },
    getDownloadCSV: function () {
      const _self = this;
      let type = '';
      let recordIds = [];
      let alumniIds = [];
      let pipelineIds = [];
      let portfolioIds = [];

      if (_self.myPortfolioType == 'fund') {
        type = 'companies';
      } else {
        if (this.investor) {
          type = 'funds';
        }
        if (!this.investor) {
          type = 'companies';
        }
      }
      if (_self.myPortfolioType == 'fund' && this.myPortfolioData) {
        this.myPortfolioData.forEach(data => {
          if (data.portfolioCompanyStatus == 'Alumni') {
            alumniIds = data.company;
          }
          if (data.portfolioCompanyStatus == 'Pipeline') {
            pipelineIds = data.company;
          }
          if (data.portfolioCompanyStatus == 'Portfolio') {
            portfolioIds = data.company;
          }
        })
      } else {
        if (this.investor && this.portfolioData) {
          this.myPortfolioData.forEach(data => {
            if (data.portfolioCompanyStatus == 'Alumni') {
              alumniIds = data.funds;
            }
            if (data.portfolioCompanyStatus == 'Pipeline') {
              pipelineIds = data.funds;
            }
            if (data.portfolioCompanyStatus == 'Portfolio') {
              portfolioIds = data.funds;
            }
          })
        }
        if (!this.investor && this.portfolioData) {
          this.myPortfolioData.forEach(data => {
            if (data.portfolioCompanyStatus == 'Alumni') {
              alumniIds = data.company;
            }
            if (data.portfolioCompanyStatus == 'Pipeline') {
              pipelineIds = data.company;
            }
            if (data.portfolioCompanyStatus == 'Portfolio') {
              portfolioIds = data.company;
            }
          })
        }
        recordIds.alumni = alumniIds;
        recordIds.pipeline = pipelineIds;
        recordIds.portfolio = portfolioIds;
      }
      let body = {
        tableName: type,
        recordId: {
          'alumni': alumniIds,
          'pipeline': pipelineIds,
          'portfolio': portfolioIds,
        }
      }
      return new Promise(function (resolve) {
        axios
          .post(
            env.apiUrl +
            "/assesment2x/download-companies-csv", body,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    criteriaCalc(type = '') {
      let companyDetail = this.portfolioData
      let twoxEligibleDivision = 0;
      let twoxEligiblesSum = 0;
      this.consumption = 0
      let entrepreneurshipdata


      if (companyDetail) {
        //company
        if (type == "company" && companyDetail.companiesAssessmentScore) {

       

          this.employment = Number(companyDetail.companypercentWomenInOverallWorkforce) + Number(companyDetail.companiesAssessmentScore.policiesAvg)

          entrepreneurshipdata = Number(companyDetail
            .companyPercentShares) + Number(companyDetail.companiesAssessmentScore
              .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization)
     
              
          if(companyDetail.companypercentOfWomenSeniorManagers == '' && companyDetail.companypercentWomenOnBoardIc == ''){
            this.leadership = 'NA';

          }
          else{
            this.leadership =  Number(companyDetail.companypercentOfWomenSeniorManagers) + Number(companyDetail.companypercentWomenOnBoardIc)
            if (companyDetail.companypercentOfWomenSeniorManagers != '' && companyDetail.companypercentWomenOnBoardIc != '') {
              this.leadership = this.leadership / 2
            }
          }

          if (companyDetail.companypercentWomenInOverallWorkforce == '' && companyDetail.companiesAssessmentScore.policiesAvg == '') {
            this.employment ='NA';
          }
          if (companyDetail.companypercentWomenInOverallWorkforce == '') {
            this.employment = this.employment
          }
          else{

            this.employment = this.employment / 2
          }
          let wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;

          if(this.portfolioData.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('Yes') || this.portfolioData.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('No')){
              wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 1;
            }
            else{
              wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;
            }

          if(companyDetail.companyPercentShares == '' && wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield == 0){
            entrepreneurshipdata = 'NA';
          }
          else{

            let wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;
    
            if(this.portfolioData.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('Yes') || this.portfolioData.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('No')){
              wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 1;
            }
            else{
              wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;
            }

            if (companyDetail.companyPercentShares != '' && wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield  != 0) {

                entrepreneurshipdata = (entrepreneurshipdata / 2)
            }
          }
          


          if (
            companyDetail.companiesAssessmentScore
              .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength
          ) {
            let length =
              companyDetail.companiesAssessmentScore
                .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength;

            this.consumption = length

          }

        }
        else if (type == "fund" && companyDetail.fundsAssessmentScore) {

        

          entrepreneurshipdata = Number(companyDetail
          .fundsPercentShares) * 100 + Number(companyDetail.fundsAssessmentScore
            .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization)

          this.indirectInvestment = Number(companyDetail.fundsIndirectInvestmentCriteriaMet)


          if (companyDetail.fundspercentOfWomenSeniorManagers == '' && companyDetail.fundspercentWomenOnBoardIc == '') {
            this.leadership = 'NA'
          }
          else {

            
            this.leadership = Number(companyDetail.fundspercentOfWomenSeniorManagers) + Number(companyDetail.fundspercentWomenOnBoardIc)
            if (companyDetail.fundspercentOfWomenSeniorManagers != '' && companyDetail.fundspercentWomenOnBoardIc != '') {
              this.leadership = this.leadership / 2
            }
        
          }
          if (companyDetail.fundspercentWomenInOverallWorkforce == '' && companyDetail.fundsAssessmentScore.policiesAvg  == '') {
            this.employment = 'NA'
          }
          else{

            this.employment = Number(companyDetail.fundspercentWomenInOverallWorkforce) + Number(companyDetail.fundsAssessmentScore.policiesAvg)

            if (companyDetail.fundspercentWomenInOverallWorkforce == '' ) {

            this.employment = this.employment

            }
            else{
            this.employment = this.employment / 2
            }


          }
      
          // if (companyDetail.fundspercentWomenInOverallWorkforce != 0 && companyDetail.fundsAssessmentScore.policiesAvg != 0) {
            // this.employment = this.employment / 2
          // }

        

          if(companyDetail
            .fundsPercentShares == '' && companyDetail.fundsAssessmentScore
            .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization == ''){
            entrepreneurshipdata = 'NA';

          }
          // else if (companyDetail.fundsPercentShares != 0 && companyDetail.fundsAssessmentScore
          //     .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization != 0) {
          else{


            if (companyDetail.fundsPercentShares != '' && companyDetail.fundsAssessmentScore
            .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization  != '') {
                entrepreneurshipdata = (entrepreneurshipdata / 2)
              }
            
          }


          if (
            companyDetail.fundsAssessmentScore
              .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength
          ) {
            let length =
              companyDetail.fundsAssessmentScore
                .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength;

            this.consumption = length
          }

        }

        
        if(this.leadership == 'NA')
        this.leadership = "NA"
        
        else
        this.leadership = Number(this.leadership)
        
        if(this.employment == 'NA')
        this.employment = "NA"

        else
        this.employment = Number(this.employment)

        if(entrepreneurshipdata == 'NA'){
          this.entrepreneurship = entrepreneurshipdata;
        }
        
        else
        {
          if (type == "company" && companyDetail.companiesAssessmentScore) 
          this.entrepreneurship = Number(entrepreneurshipdata);

          else
          this.entrepreneurship = Number(entrepreneurshipdata)/100;

        }


        if (this.consumption > 0)
          twoxEligibleDivision += 1;

        if (this.leadership > 0)
          twoxEligibleDivision += 1;

        if (this.employment > 0)
          twoxEligibleDivision += 1;

        if (this.entrepreneurship > 0)
          twoxEligibleDivision += 1;

        if (this.indirectInvestment != 0 && type == 'fund')
          twoxEligibleDivision += 1;

        twoxEligiblesSum = this.consumption + this.leadership + this.employment + this.entrepreneurship + (type == 'fund' ? this.indirectInvestment : 0);

        this.twoxEligible = twoxEligiblesSum / twoxEligibleDivision
      }

    },
    criteriaCalculateForAll(type = '', array) {
    let totalData = [];
    let companyDetail = array
    let entrepreneurshipdata;

    let twoxEligible;
    let twoxEligiblePercent;
    let leadership;
    let employment;
    let consumption = 0;
    let entrepreneurship;
    let indirectInvestment;
    let wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;

    if (companyDetail) {
      //company
      if (type == "company" && companyDetail.companiesAssessmentScore) {

       
        employment = Number(companyDetail.companypercentWomenInOverallWorkforce) + Number(companyDetail.companiesAssessmentScore.policiesAvg)

        entrepreneurshipdata = Number(companyDetail
          .companyPercentShares) + Number(companyDetail.companiesAssessmentScore
            .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization)
   
            
        if(companyDetail.companypercentOfWomenSeniorManagers == '' && companyDetail.companypercentWomenOnBoardIc == ''){
          leadership = 'NA';

        }
        else{
          leadership =  Number(companyDetail.companypercentOfWomenSeniorManagers) + Number(companyDetail.companypercentWomenOnBoardIc)
          if (companyDetail.companypercentOfWomenSeniorManagers != '' && companyDetail.companypercentWomenOnBoardIc != '') {
            leadership = leadership / 2
          }
        }

        if (companyDetail.companypercentWomenInOverallWorkforce == '' && companyDetail.companiesAssessmentScore.policiesAvg == '') {
          employment ='NA';
        }
        if (companyDetail.companypercentWomenInOverallWorkforce == '' ) {
          // employment = employment
        }
        else{

          employment = employment / 2
        }

        if(companyDetail.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('Yes') || companyDetail.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('No')){
            wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 1;
          }
          else{
            wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;
          }

        if(companyDetail.companyPercentShares == '' && wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield == 0){
          entrepreneurshipdata = 'NA';
        }
        else{

          let wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;
  
          if(companyDetail.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('Yes') || companyDetail.companiesAssessmentScore.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationArr.includes('No')){
            wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 1;
          }
          else{
            wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = 0;
          }

          if (companyDetail.companyPercentShares != '' && wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield  != 0) {

              entrepreneurshipdata = (entrepreneurshipdata / 2)
          }
        }
        


        if (
          companyDetail.companiesAssessmentScore
            .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength
        ) {
          let length =
            companyDetail.companiesAssessmentScore
              .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength;

          consumption = length

        }

        if(this.portfolioData.companyPercentTwoXEligible == 'Unknown'){

         twoxEligible = "Unknown";
        }
        else{
          twoxEligible = this.portfolioData.companyPercentTwoXEligible ;

        }

      }
      else if (type == "fund" && companyDetail.fundsAssessmentScore) {

        entrepreneurshipdata = Number(companyDetail
        .fundsPercentShares) * 100 + Number(companyDetail.fundsAssessmentScore
          .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization)


        if (companyDetail.fundsIndirectInvestmentCriteriaMet == '' || isNaN(companyDetail.fundsIndirectInvestmentCriteriaMet)){
          
          indirectInvestment = "Unknown";
        } 
        else{

          indirectInvestment = Number(companyDetail.fundsIndirectInvestmentCriteriaMet)
        }


        if (companyDetail.fundspercentOfWomenSeniorManagers == '' && companyDetail.fundspercentWomenOnBoardIc == '') {
          leadership = 'NA'
        }
        else {

          
          leadership = Number(companyDetail.fundspercentOfWomenSeniorManagers) + Number(companyDetail.fundspercentWomenOnBoardIc)
          if (companyDetail.fundspercentOfWomenSeniorManagers != '' && companyDetail.fundspercentWomenOnBoardIc != '') {
            leadership = leadership / 2
          }
      
        }
        if (companyDetail.fundspercentWomenInOverallWorkforce == '' && companyDetail.fundsAssessmentScore.policiesAvg  == '') {
          employment = 'NA'
        }
        else{

          employment = Number(companyDetail.fundspercentWomenInOverallWorkforce) + Number(companyDetail.fundsAssessmentScore.policiesAvg)

          if (companyDetail.fundspercentWomenInOverallWorkforce == '' ) {

          // employment = employment

          }
          else{
          employment = employment / 2
          }


        }
    
        if(companyDetail
          .fundsPercentShares == '' && companyDetail.fundsAssessmentScore
          .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization == ''){
          entrepreneurshipdata = 'NA';

        }
       else{

          if (companyDetail.fundsPercentShares != '' && companyDetail.fundsAssessmentScore
          .wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganization  != '') {
              entrepreneurshipdata = (entrepreneurshipdata / 2)
            }
          
        }


        if (
          companyDetail.fundsAssessmentScore
            .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength
        ) {
          let length =
            companyDetail.fundsAssessmentScore
              .howDoesTheOrganizationSProductOrServiceSpecificallyOrDisproportionatelyBenefitWomenLength;

          consumption = length
        }
        
        if(companyDetail.fundPercentTwoXEligible == 'Unknown' || (companyDetail.fundsIndirectInvestmentCriteriaMet == '' || isNaN(companyDetail.fundsIndirectInvestmentCriteriaMet))){

          twoxEligible = "Unknown";
        }
        else{
          twoxEligible = companyDetail.fundPercentTwoXEligible ;

        }

      }


      if(leadership == 'NA')
      leadership = "NA"
      
      else
      leadership = Number(leadership)
      
      if(employment == 'NA')
      employment = "NA"

      else
      employment = Number(employment)

      if(entrepreneurshipdata == 'NA'){
        entrepreneurship = entrepreneurshipdata;
      }

      else
      {
        if (type == "company" && companyDetail.companiesAssessmentScore) 
        entrepreneurship = Number(entrepreneurshipdata);

        else
        entrepreneurship = Number(entrepreneurshipdata)/100;

      }
      
      if(this.investor){
        twoxEligiblePercent =  this.myPortfolioType == 'investor' ? this.portfolioData.fundPercentTwoXEligible : this.portfolioData.percentTwoXEligible;
      }else{
        twoxEligiblePercent = this.myPortfolioType == 'investor' ? this.portfolioData.companyPercentTwoXEligible : this.portfolioData.percentTwoXEligible;
      }

      totalData.twoxEligible = twoxEligible
      totalData.twoxEligiblePercent = twoxEligiblePercent;
      totalData.consumption = consumption
      totalData.leadership = leadership
      totalData.employment = employment
      totalData.entrepreneurship = entrepreneurship
      totalData.wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield = wasTheOrganizationFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrganizationfield
      if (type == 'fund') {
        totalData.indirectInvestment = indirectInvestment
      }
    }
    return totalData;
    },
    async switchPortfolioData(tog) {
      this.updateKey++;
      this.portfolioSwitchTog = tog.status;

      this.criteriaCalcType = this.investor ? "fund" : "company";
      this.portfolioData = [];
      this.toggleOptions.forEach((data) => {
        data.isSelected = false;
      });
      let index = "";
      this.myPortfolioData.forEach((data, i) => {
        if (data.portfolioCompanyStatus == tog.status) {
          index = i;
        }
      });
      tog.isSelected = true;
      this.portfolioData = this.myPortfolioData[index];

      this.criteriaCalc(this.criteriaCalcType);
      this.spiderChartUpdateKey++;

      //call company data api us per options
      if (this.investor) {
        this.fundsDetails.fundsIds = this.myPortfolioData[index].funds;
        this.getFundsData(this.fundsDetails.orderByColumnName, this.fundsDetails.orderBy);
      }
      if (!this.investor) {
        this.companyDetails.companiesIds = this.myPortfolioData[index].company;
        this.getCompanyData(this.companyDetails.orderByColumnName, this.companyDetails.orderBy);
      }
      this.companyHeader.map((item, i) => {
        if (i == 0) {
          item.defalutOrderTypeASC = true
        } else {
          item.defalutOrderTypeASC = false
        }
      })

      this.assessmentYear = [];
      this.assessmentYear.push(
        new Date(this.portfolioData.createdTime).getFullYear() +
        " / " +
        new Date(this.portfolioData.createdTime).toLocaleString("en-US", {
          month: "short",
        })
      );

      this.csvData = await this.getDownloadCSV();
    },

    async togglePortfolio() {
      this.updateKey++;
      document.getElementById("favChecked").checked ? (this.investor = true) : (this.investor = false);
      if (this.investor == true && this.portfolioData && this.portfolioData.investor && this.portfolioData.funds && this.portfolioData.funds.length > 0) {
        this.fundsDetails.fundsIds = this.portfolioData.funds;
        if (this.fundsDetails.fundsIds.length > 50) {
          this.fundsDetails.limit = 20
        }
        this.criteriaCalcType = "fund";
        this.criteriaCalc('fund');
        await this.getFundsData(this.fundsDetails.orderByColumnName, this.fundsDetails.orderBy);
      }
      else {
        this.criteriaCalcType = "company";
        this.criteriaCalc('company');
        this.companyDetails.companiesIds = this.portfolioData.company;
        this.getCompanyData(this.companyDetails.orderByColumnName, this.companyDetails.orderBy);
      }
      this.csvData = await this.getDownloadCSV();
    },

    async getPortfolioData() {
      let url = "";
      if (this.$route.query.id) {
        url = "/assesment2x/assessment-match?teamId=" + this.$route.query.id
      } else {
        url = "/assesment2x/assessment-match"
      }
      await axios
        .get(env.apiUrl + url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.myPortfolioData = res.data.data.data;
          this.type = res.data.data.type;
          this.myPortfolioType = res.data.data.type;
          this.allCsvData = res.data.csvData;

          this.myPortfolioData.forEach((data) => {
            if (data.portfolioCompanyStatus && (data.company.length || data.funds.length)) {
              this.toggleOptions.push({
                status: data.portfolioCompanyStatus,
                isSelected: false,
              });
            }
          });
          this.toggleOptions.reverse();
          this.toggleOptions[0].isSelected = true;

          //******************//
          let index = "";
          this.myPortfolioData.forEach((data, i) => {
            if (data.portfolioCompanyStatus == this.toggleOptions[0].status) {
              index = i;
            }
          });

          this.portfolioData = this.myPortfolioData[index];
          let index1 = "";
          this.myPortfolioData.forEach((data, i) => {
            if (data.portfolioCompanyStatus == this.toggleOptions[0].status) {
              index1 = i;
            }
          });
          this.portfolioData = this.myPortfolioData[index1];

          this.companyDetails.companiesIds = this.myPortfolioData[index1].company;

          this.countData = res.data.countData;

          this.assessmentYear.push(
            new Date(this.portfolioData.createdTime).getFullYear() +
            " / " +
            new Date(this.portfolioData.createdTime).toLocaleString("en-US", {
              month: "short",
            })
          );
          this.endofcreated = true;
        })
        .catch((err) => {
          console.log(err);
          this.endofcreated = true;
        });
    },

    async getCompanyData(defalutOrderTypeColumn, defalutOrderType) {
      if(this.companyDetails.companyData && this.companyDetails.companyData.length > 0){
        this.companyDetails.companyData.map((item)=>{
          if(item && item.logo && item.logo[0] && item.logo[0].url){
            item.logo[0].url = ""
          }
        })
      }
      let body = {
        companiesIds: this.companyDetails.companiesIds,
        sortBy: {
          [defalutOrderTypeColumn]: defalutOrderType
        }
      }
      await axios.post(env.apiUrl + "/assesment2x/get-companiesData", body, {
        params: {
          page: this.companyDetails.page,
          limit: this.companyDetails.limit
        },
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      })
        .then((res) => {
          this.companyDetails.companyData = res.data.data.companyData
          this.companyDetails.toolsDataLength = res.data.data.total;
          this.companyDetails.totalCount = res.data.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeOrder(type, orderByColumn, orderIndex) {
      if (type == 'company') {
        this.companyDetails.orderByColumnName = orderByColumn;
        this.companyHeader.map((item, index) => {
          if (index == orderIndex) {
            item.defalutOrderTypeASC = !item.defalutOrderTypeASC
            if (item.defalutOrderTypeASC) {
              this.companyDetails.orderBy = "ASC"
            } else {
              this.companyDetails.orderBy = "DESC"
            }
          } else {
            item.defalutOrderTypeASC = false
          }
        })
        this.getCompanyData(orderByColumn, this.companyDetails.orderBy);
      } else {
        this.fundsDetails.orderByColumnName = orderByColumn
        this.fundsHeader.map((item, index) => {
          if (index == orderIndex) {
            item.defalutOrderTypeASC = !item.defalutOrderTypeASC
            if (item.defalutOrderTypeASC) {
              this.fundsDetails.orderBy = "ASC"
            } else {
              this.fundsDetails.orderBy = "DESC"
            }
          } else {
            item.defalutOrderTypeASC = false
          }
        })
        this.getFundsData(orderByColumn, this.fundsDetails.orderBy);
      }
    },

    loadMore(type) {
      if (type == 'company') {
        this.companyDetails.page++;
        this.companyDetails.limit = this.companyDetails.limit + 10;
        this.getCompanyData(this.companyDetails.orderByColumnName, this.companyDetails.orderBy);
      } else {
        this.fundsDetails.page++;
        this.fundsDetails.limit = this.fundsDetails.limit + 10;
        this.getFundsData(this.fundsDetails.orderByColumnName, this.fundsDetails.orderBy);
      }
    },

    async getFundsData(defalutOrderTypeColumn, defalutOrderType) {
      if(this.fundsDetails.fundsData && this.fundsDetails.fundsData.length > 0){
        this.fundsDetails.fundsData.map((item)=>{
          if(item && item.logo && item.logo[0] && item.logo[0].url){
            item.logo[0].url = ""
          }
        })
      }
      let body = {
        fundsIds: this.fundsDetails.fundsIds,
        sortBy: {
          [defalutOrderTypeColumn]: defalutOrderType
        }
      }
      await axios.post(env.apiUrl + "/assesment2x/get-fundsData", body, {
        params: {
          page: this.fundsDetails.page,
          limit: this.fundsDetails.limit
        },
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      })
        .then((res) => {
          this.fundsDetails.fundsData = res.data.data.fundsData
          this.fundsDetails.toolsDataLength = res.data.data.total;
          this.fundsDetails.totalCount = res.data.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openContextualAnalysis(taab) {
      if (taab == "Indirect Investment") {
        taab = "Investment through Financial Intermediaries";
      }
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/My-2X-Assessments/context",
        query: {
          indicator: taab,
          email: this.portfolioData.equiloEmailId,
          sector: this.portfolioData.sector,
          countryId: this.portfolioData.countryHq.country_id,
        },
      });
      window.open(routeData.href, "_blank");
    },

    goToTwoXAssess(data, type) {
      let queryData = {
        recordId: data.recordId,
        type: type,
      };
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/My-2X-Assessments",
        query: queryData,
      });
      window.open(routeData.href, "_blank");
    },
    esgDescription(data){
      if(data.fundsAssessmentScore){

        if(data.fundsAssessmentScore.eSClaimsTotal != data.fundsAssessmentScore.total && data.fundsAssessmentScore.eSClaimsTotal != 0){

          let company_text = (data.fundsAssessmentScore.eSClaimsTotal > 1) ? "companies": "company";

          this.esclaim_desc = "(Partially completed - This percentage is based on "+data.fundsAssessmentScore.eSClaimsTotal+ " portfolio "+  company_text +" who have submitted their data out of a total of "+ data.fundsAssessmentScore.total + " portfolio companies)";
        }
    
        if(data.fundsAssessmentScore.genderInProcurementStrategyTotal != data.fundsAssessmentScore.total && data.fundsAssessmentScore.genderInProcurementStrategyTotal != 0){

          let company_text = (data.fundsAssessmentScore.genderInProcurementStrategyTotal > 1) ? "companies": "company";

          this.genderInProcurementStrategyTotal_desc = "(Partially completed - This percentage is based on "+data.fundsAssessmentScore.genderInProcurementStrategyTotal+ " portfolio "+  company_text +" who have submitted their data out of a total of "+ data.fundsAssessmentScore.total + " portfolio companies)";
        }

        if (data.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal != data.fundsAssessmentScore.total && data.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal != 0) {

          let company_text = (data.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal > 1) ? "companies" : "company";

          this.valueChainStrategiesThatPromoteEqualityTotal_desc = "(Partially completed - This percentage is based on " + data.fundsAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.fundsAssessmentScore.total + " portfolio companies)";
        }

        if (data.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal != data.fundsAssessmentScore.total && data.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal != 0) {

          let company_text = (data.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal > 1) ? "companies" : "company";

          this.initiativesToIncludeDisadvantagedGroupsTotal_desc = "(Partially completed - This percentage is based on " + data.fundsAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.fundsAssessmentScore.total + " portfolio companies)";
        }
 
        if (data.fundsAssessmentScore.genderedApproachToCovid19Total != data.fundsAssessmentScore.total && data.fundsAssessmentScore.genderedApproachToCovid19Total != 0) {

          let company_text = (data.fundsAssessmentScore.genderedApproachToCovid19Total > 1) ? "companies" : "company";

          this.genderedApproachToCovid19Total_desc = "(Partially completed - This percentage is based on " + data.fundsAssessmentScore.genderedApproachToCovid19Total + " portfolio " + company_text + " who have submitted their data out of a total of " + data.fundsAssessmentScore.total + " portfolio companies)";
        }       
      }
      if(data.companiesAssessmentScore){

        if(data.companiesAssessmentScore.eSClaimsTotal != data.companiesAssessmentScore.total && data.companiesAssessmentScore.eSClaimsTotal != 0){

          let company_text = (data.companiesAssessmentScore.eSClaimsTotal > 1) ? "companies": "company";

          this.esclaim_desc = "(Partially completed - This percentage is based on "+data.companiesAssessmentScore.eSClaimsTotal+ " portfolio "+  company_text +" who have submitted their data out of a total of "+ data.companiesAssessmentScore.total + " portfolio companies)";

        }

        if (data.companiesAssessmentScore.genderInProcurementStrategyTotal != data.companiesAssessmentScore.total && data.companiesAssessmentScore.genderInProcurementStrategyTotal != 0) {

          let company_text = (data.companiesAssessmentScore.genderInProcurementStrategyTotal > 1) ? "companies" : "company";

          this.genderInProcurementStrategyTotal_desc = "(Partially completed - This percentage is based on " + data.companiesAssessmentScore.genderInProcurementStrategyTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.companiesAssessmentScore.total + " portfolio companies)";
        }

        if (data.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal != data.companiesAssessmentScore.total && data.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal != 0) {

          let company_text = (data.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal > 1) ? "companies" : "company";

          this.valueChainStrategiesThatPromoteEqualityTotal_desc = "(Partially completed - This percentage is based on " + data.companiesAssessmentScore.valueChainStrategiesThatPromoteEqualityTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.companiesAssessmentScore.total + " portfolio companies)";
        }

        if (data.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal != data.companiesAssessmentScore.total && data.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal != 0) {

          let company_text = (data.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal > 1) ? "companies" : "company";

          this.initiativesToIncludeDisadvantagedGroupsTotal_desc = "(Partially completed - This percentage is based on " + data.companiesAssessmentScore.initiativesToIncludeDisadvantagedGroupsTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.companiesAssessmentScore.total + " portfolio companies)";
        }

        if (data.companiesAssessmentScore.genderedApproachToCovid19Total != data.companiesAssessmentScore.total && data.companiesAssessmentScore.genderedApproachToCovid19Total != 0) {

          let company_text = (data.companiesAssessmentScore.genderedApproachToCovid19Total > 1) ? "companies" : "company";

          this.genderedApproachToCovid19Total_desc = "(Partially completed - This percentage is based on " + data.companiesAssessmentScore.genderedApproachToCovid19Total + " portfolio " + company_text + " who have submitted their data out of a total of " + data.companiesAssessmentScore.total + " portfolio companies)";
        } 


        if (data.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal != data.companiesAssessmentScore.total && data.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal != 0) {

          let company_text = (data.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal > 1) ? "companies" : "company";

          this.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal_desc = "(Partially completed - This percentage is based on " + data.companiesAssessmentScore.doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholdsTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.companiesAssessmentScore.total + " portfolio companies)";
        } 


        if (data.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal != data.companiesAssessmentScore.total && data.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal != 0) {

          let company_text = (data.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal > 1) ? "companies" : "company";

          this.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal_desc = "(Partially completed - This percentage is based on " + data.companiesAssessmentScore.workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartnersTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.companiesAssessmentScore.total + " portfolio companies)";
        }
        
        if (data.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal != data.companiesAssessmentScore.total && data.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal != 0) {

          let company_text = (data.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal > 1) ? "companies" : "company";

          this.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal_desc = "(Partially completed - This percentage is based on " + data.companiesAssessmentScore.engageWomenInPaidIncomeOpportunitiesInSupplyChainTotal + " portfolio " + company_text + " who have submitted their data out of a total of " + data.companiesAssessmentScore.total + " portfolio companies)";
        } 
          
 
      }

    }

  },
  watch: {
  },
  computed: {
    filteredSectorsPercent() {
      let arr = [];

      const sum = this.portfolioData.companiesAssessmentScore.sectors.reduce((accumulator, object) => {
        return accumulator + object.percentage;
      }, 0);

      this.portfolioData.companiesAssessmentScore.sectors.forEach((data) => {
        if (data.percentage > 0) {
          arr.push(((data.percentage / sum) * 100).toFixed(0));
        }
      });
      return arr;
    },
    filteredSectorsLabels() {
      let arr = [];
      this.portfolioData.companiesAssessmentScore.sectors.forEach((data) => {
        if (data.percentage > 0) {
          arr.push(data.name);
        }
      });
      return arr;
    },
    filteredSectorsPercentFunds() {
      let arr = [];

      const sum = this.portfolioData.fundsAssessmentScore.sectors.reduce((accumulator, object) => {
        return accumulator + object.percentage;
      }, 0);
      this.portfolioData.fundsAssessmentScore.sectors.forEach((data) => {
        if (data.percentage > 0) {
          arr.push((data.percentage / sum * 100).toFixed(0));
        }
      });
      return arr;
    },
    filteredSectorsLabelsFunds() {
      let arr = [];
      this.portfolioData.fundsAssessmentScore.sectors.forEach((data) => {
        if (data.percentage > 0) {
          arr.push(data.name);
        }
      });
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>

.OverviewBlock {
  .companies_and_fund_table {
    overflow-x: auto;
    overflow-y: hidden;

    table {
      thead {
        th {
          font-size: 13px;
          line-height: 16px;
          color: #9d9d9c;
          font-weight: 700;
        }
      }

      th,
      td {
        border: 1px solid #E9E9E9;
      }

      th:first-child,
      td:first-child {
        border-left: none;
      }

      th:last-child,
      td:last-child {
        border-right: none;
      }
    }

    .image-center {
      align-items: center;
    }

    .organization-img {
      border-radius: 50% !important;
      margin: 0 !important;
      margin-right: 15px !important;
      height: 50px;
      width: 50px;
      object-fit: contain;
      min-width: 50px;
    }

    .organization-name {
      color: black;
      font-size: 15px;
      line-height: 16px;
      font-weight: 700;
    }

    .background-image {
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .center-text {
      text-align: center;
    }
  }

  .loadmore-btn {
    border-radius: 20px;
    margin-top: 15px;
  }
}

.favorite-shared-button-section {
  text-align: right;
  // padding: 20px 0 0;
}

/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 15px;
    cursor: pointer;
    width: 40px;
  }

  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .toggleSwitch label,
  .toggleSwitch>span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .toggleSwitch input:focus~a,
  .toggleSwitch input:focus+label {
    outline: none;
  }

  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }

  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }

  .toggleSwitch>span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }

  .toggleSwitch>span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }

  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 4px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .toggleSwitch>span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }

  .toggleSwitch>span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  .toggleSwitch input:checked~a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }

  .toggleSwitch input:checked~span:before {
    border-color: #0097d1;
    box-shadow: inset 0 0 0 30px #0097d1;
  }

  .toggleSwitch input:checked~span span:first-of-type {
    opacity: 0;
  }

  .toggleSwitch input:checked~span span:last-of-type {
    opacity: 1;
    color: #fff;
  }

  /* Switch Sizes */
  .toggleSwitch.large {
    width: 70px;
    height: 30px;
    top: 5px;
  }

  .toggleSwitch.large a {
    width: 29px;
    height: 28px;
    top: 0.8px;
  }

  .toggleSwitch.large>span {
    height: 34px;
    line-height: 35px;
  }

  .toggleSwitch.large input:checked~a {
    left: 46px;
  }

  .toggleSwitch.large>span span {
    font-size: 1.1em;
  }

  .toggleSwitch.large>span span:first-of-type {
    left: 50%;
  }
}

/*  End Toggle Switch  */

.width-30 {
  width: 30px !important;
  height: 30px !important;
}

.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

  .container {
    margin: 0 auto;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .Ind_Inv_Icon {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer !important;

    img {
      width: 30px !important;
      height: 30px;
      object-fit: contain;
      margin-right: 0 !important;
      margin-top: 30px !important;
    }

    span {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin-left: 20px !important;
    }
  }

  .Indirect_Investment {
    top: 0;
    left: 0;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .org_profile_image {
      margin: auto;
      width: 45%;

      img {
        width: 100px;
        border-radius: 10px;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    // margin-top: 30px;
    padding-bottom: 60px;

    .OverviewBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 0 40px 40px;
      padding-top: 40px;
      margin-bottom: 18px;

      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          width: 380px;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
        }
      }

      .CompanyTitle {
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 17px;
        position: relative;
        margin-bottom: 30px;
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .EligibleListImg {
              position: relative;
              z-index: 1;
              // &::after {
              //   content: "";
              //   display: inline-block;
              //   border: 1px solid #d0d0d0;
              //   position: absolute;
              //   height: 30px;
              //   top: 18px;
              //   left: 8px;
              //   z-index: -1;
              // }

              img {
                // width: 24px !important;
                // height: 24px;
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 15px !important;
              }
            }

            .bigImg {
              margin-left: -10px;

              &::after {
                top: 40px;
                left: 18px;
                height: 28px;
              }

              img {
                width: 40px !important;
                height: 40px;
              }
            }

            .lastAffter {
              &::after {
                content: none;
              }
            }

            .EligibleTitle {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }

          .bigMargin {
            margin-bottom: 25px;
            margin-left: 10px;
          }

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }

          .PartyValidatedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .PartyTooltipImg {
              img {
                // width: 20px;
                // height: 20px;
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }

            a {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;

              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }

        .spiderchartContent {
          width: 60%;
        }
      }

      .heatmap_container {
        padding-top: 40px;
        margin-bottom: 18px;
      }
    }

    .Profile_Criteria_Wrepper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;

      .Profile_CountryWrepper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .Profile_Block {
          background: #ffffff;
          border-radius: 10px;
          padding: 40px;
          margin-bottom: 18px;
          max-width: 523px;
          width: 100%;

          .Profile_blockListText {
            .About_CompanyListText {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              margin-bottom: 30px;

              b {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 5px;
              }

              a {
                img {
                  width: 20px !important;
                  height: 20px;
                  object-fit: contain;
                }
              }
            }

            ul {
              border-top: 1px solid #d0d0d0;
              padding-top: 30px;
              margin: 0;
              margin-bottom: 30px;
              display: flex;
              flex-wrap: wrap;
              // align-items: center;
              justify-content: space-between;

              li {
                width: 48%;
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #1b1b1b;
                margin-bottom: 15px;
                word-break: break-all;

                b {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 16px;
                  color: #1b1b1b;
                  display: block;
                  margin-bottom: 5px;
                  word-break: break-all;
                }
              }
            }

            .DownloadBtnDeck {
              display: flex;

              a {
                display: flex;
                align-items: center;
                border: 1px solid #1b1b1b;
                border-radius: 20px;
                color: #1b1b1b;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                padding: 11px 10px;
                transition: 0.3s ease;

                img {
                  margin-right: 8px;
                  width: 15px;
                  height: 15px;
                }

                &:hover {
                  background: rgb(32, 23, 71);
                  color: #fff;
                  border-color: rgb(32, 23, 71);
                }
              }
            }
          }

          .CountryOverviewText {
            margin-bottom: 30px;

            h3 {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 5px;
            }

            p,
            .leading-7 {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              display: -webkit-box;
              -webkit-line-clamp: 15;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            a {
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              display: flex;
              align-items: center;

              img {
                margin-left: 5px !important;
                width: 15px !important;
                height: 15px;
                object-fit: contain;
              }
            }
          }

          .WanttoUnderstandHere {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;

            a {
              text-decoration: underline;
            }
          }

          .ContryNameImg {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
              width: 60px;
              height: 40px;
              object-fit: contain;
              margin-right: 15px;
            }

            h1 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }
          }

          .ExploreBtnDeck {
            display: flex;
            margin-top: 30px;

            div {
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;

              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }

      .barchart2xCriteria_Wrepper {
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;
        max-width: 740px;
        width: 100%;
        margin-left: auto;

        .CriteriaTitleText {
          margin-bottom: 30px;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            object-fit: contain;
          }

          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }
        }

        .ChartBarContentWrepper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
          
          .ChartBarContent {
            width: 100%;
            max-width: 310px;

            .Founded_WomanText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              color: #1b1b1b;
              margin-bottom: 15px;
            }

            .Founded_WomanImg {
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                object-fit: contain;
              }
            }

            .ChartBarPercentageText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 10px;
            }
          }
        }

        .Consumption_ListText {
          margin-bottom: 60px;

          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }

        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }

        .IndirectInvestmentListContent {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .IndirectContent {
            max-width: 301px;
            width: 100%;

            .IndirectInvWrepper {
              margin-bottom: 45px;

              .IndirectInvText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 16px;
              }

              .IndirectInvImg {
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  object-fit: contain;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .GBVdetailsBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;

      .GBVdetailsContent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .esg-pre-screening {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      margin-bottom: 20px;

      .OverviewTitle {
        h2 {
          width: 320px;
        }
      }

      .GBVdetailsContent {
        justify-content: space-between;

        ul {
          margin-top: 1.5rem;
        }

        ul li {
          display: flex;
          margin: 0;
        }

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .amount-funded {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      width: 100%;
      margin-top: 20px;

      .funding-status-profile-container {
        margin: 1rem 0 3rem 0;

        ul {
          display: flex;
          justify-content: space-between;
        }
      }

      .funding-status-title {
        h2 {
          width: 250px;
        }
      }

      .funding-bar-chart {
        width: 50%;
      }

      .funding-status-info {
        width: 50%;

        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .CriteriaTitleText {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        p {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #1b1b1b;
        }
      }

      .ChartBarContentWrepper {
        // display: flex;
        justify-content: space-between;
        // margin-bottom: 60px;
        margin: 0 auto 60px auto;

        .ChartBarContent {
          width: 100%;
          margin: auto;
          max-width: 400px;

          .Founded_WomanText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            color: #1b1b1b;
            margin-bottom: 15px;
          }

          .Founded_WomanImg {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }

          .ChartBarPercentageText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 10px;
          }
        }
      }

      .get-it-on-deal-button {
        text-align: right;
        margin-bottom: 20px;
      }

      .get-it-on-deal-button button {
        background: #fff;
        color: #1b1b1b;
        border-color: #201747;
        border: 1px solid #1b1b1b;
        height: 36px;
        color: #1b1b1b;
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        padding: 9px 11px;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
      }

      .get-it-on-deal-button button:hover {
        background: #201747;
        color: #fff;
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 100%;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
      }
    }

    .sectors_container {
      margin: auto;
      text-align: center;
      width: 50%;
      margin: 60px 0px;
      margin-bottom: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #1b1b1b;

      .sectors_container_inner {
        margin-top: 30px;
        // display: flex;
        // flex-wrap: wrap;
      }

      .sectors_content {
        flex: 0 0 25%;
        padding: 10px 0;
      }

      span {
        font-weight: 500;
      }
    }

    .companies_and_fund_count {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #1b1b1b;
    }

    .CountryLine {
      align-items: flex-end;

      h2 {
        width: auto;
      }

      span {
        width: 110px;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.pointer-event-none {
  pointer-events: none;
}

.Modal_Readmorestyle {
  padding: 45px 0 45px 40px;
  position: relative;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h3 {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: #1b1b1b;
    }
  }

  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}

.chartjs-render-monitor {
  margin-left: -33px;
  height: 530px !important;
}

.survey {
  a {
    border: 1px solid #1b1b1b;
    height: 36px;
    color: #1b1b1b !important;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff !important;
      border-color: rgb(32, 23, 71);
    }
  }
}

.consumption-taab-company {
  left: 0;
  top: 0;
}

.consumption-taab-fund {
  left: 0;
  top: 120px;
}

.leadership-taab-company {
  top: 0px;
  right: 10rem;
}

.leadership-taab-fund {
  right: 10rem;
  top: 0;
}

.employment-taab-company {
  bottom: 25%;
  right: 5rem;
}

.employment-taab-fund {
  bottom: 12rem;
  right: 5rem;
}

.entrepreneurship-taab-company {
  left: 0px;
  bottom: 25%;
}

.entrepreneurship-taab-fund {
  left: 0px;
  bottom: 5.6rem;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 80px;
}

img {
  margin: 0 !important;
  margin-right: 15px !important;
}

.bridgeContainer {
  .DownloadBtnDeck {
    display: none !important;
  }

  .readMoreBtn {
    display: none !important;
  }

  .ExploreBtnDeck {
    display: none !important;
  }

  .get-it-on-deal-button {
    display: none !important;
  }

  .leading-7 {
    -webkit-line-clamp: unset !important;
    -webkit-line-clamp: none;
    overflow: visible;
    padding-bottom: 10px;
  }

  .DownloadBtn {
    display: none !important;
  }
}
</style>

<style scoped>

.tooltip .twoXEligibleInvestedHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 99;
  left: 35px;
  top: -20px;
}
.tooltip .twoXEligibleHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 225px;
  top: -40px;
}

.tooltip .entrepreneurshipHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 280px;
  top: -40px;
}

.tooltip .leadershipHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 210px;
  top: -54px;
}

.tooltip .employmentHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 225px;
  top: -62px;
}

.tooltip .consumptionHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 223px;
  top: -40px;
}

.tooltip .indirectInvestmentHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 300px;
  top: -52px;
}

.tooltip:hover .twoXEligibleHoverText {
  visibility: visible;
  text-align: left;
}

.tooltip:hover .twoXEligibleInvestedHoverText {
  visibility: visible;
  text-align: left;
}

.tooltip:hover .entrepreneurshipHoverText {
  visibility: visible;
  text-align: left;

}

.tooltip:hover .leadershipHoverText {
  visibility: visible;
  text-align: left;

}

.tooltip:hover .employmentHoverText {
  visibility: visible;
  text-align: left;

}

.tooltip:hover .consumptionHoverText {
  visibility: visible;
  text-align: left;

}

.tooltip:hover .indirectInvestmentHoverText {
  visibility: visible;
  text-align: left;

}

.tooltip .twoXEligibleHoverText::after {
  right: 95%;
}
.tooltip .twoXEligibleInvestedHoverText::after {
  right: 95%;
}

.tooltip .entrepreneurshipHoverText::after {
  right: 95%;
}

.tooltip .leadershipHoverText::after {
  right: 95%;
}

.tooltip .employmentHoverText::after {
  right: 95%;
}

.tooltip .consumptionHoverText::after {
  right: 95%;
}

.tooltip .indirectInvestmentHoverText::after {
  right: 95%;
}

.pdfexportbtn {
  height: 0px;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
}

.portfolio_toggle {
  /* margin-top: 20px; */
}

.selected-country {
  pointer-events: none;
  padding: 0 1rem;
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}

.unselected-country {
  cursor: pointer;
  padding: 0 1rem;
  border: solid black 1pt;
  border-radius: 10px;
}

.selected-toggle {
  pointer-events: none;
  padding: 5px 25px;
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}

.unselected-toggle {
  cursor: pointer;
  padding: 5px 25px;
  border: solid black 1pt;
  border-radius: 10px;
  font-weight: 500;
}

.favorite-button-section {
  margin-top: 20px;
  display: flex;
}

.favourite-tooltip {
  width: 32px;
  height: 32px;
  margin: auto !important;
  margin-right: 15px !important;
  padding: 0px;
  cursor: pointer;
}

.favourite-tooltip .favourite-tooltip-text {
  visibility: hidden;
  width: 180px;
  /* right: 90px; */
  margin: 5px;
  background-color: #a0aec0;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.favourite-tooltip:hover .favourite-tooltip-text {
  visibility: visible;
  text-align: left;

}

.gbv-country-tooltip {
  width: 32px;
  height: 32px;
  margin: auto !important;
  margin-right: 15px !important;
  padding: 0px;
  cursor: pointer;
}

.gbv-country-tooltip-text {
  visibility: hidden;
  width: 180px;
  margin: 5px;
  background-color: black;
  color: #fff !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;

  font-family: "Montserrat", arial !important;
  padding: 20px;
  font-size: 12px !important;
  letter-spacing: normal;
}

.gbv-country-tooltip:hover .gbv-country-tooltip-text {
  visibility: visible;
  text-align: left;
  
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .download-csv-tooltip {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 290px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -86px;
  bottom: 100%;
  white-space: normal;
  word-wrap: break-word;
}

.tooltip .tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 20px;
  top: -160px;
}

.tooltip:hover .download-csv-tooltip {
  visibility: visible;
  text-align: left;

}

.tooltip:hover .tooltiptext {
  visibility: visible;
  text-align: left;

}

.tooltip .download-csv-tooltip::after {
  right: 95%;
}

.tooltip .tooltiptext::after {
  right: 95%;
}

.disable-csv-download {
  pointer-events: none;
}

.tooltiptext_aligned {
  left: -400px !important;
  width: 400px !important;
}

.fund_company_list_top_multiple {
  top: -50px !important;
}

.fund_company_list_top_single {
  top: -18px !important;
}

.fund_company_tooltip {
  text-align: left;
}

.fund_company_list {
  overflow: hidden;
  overflow-y: scroll;
  height: 100px;
}
.gray-color{
  color: #898989 !important;
}
/* Tooltip container */
.div_tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.div_tooltip .div_tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.div_tooltip .div_tooltiptext_partial {
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  padding: 6px 2px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.div_tooltip:hover .div_tooltiptext {
  visibility: visible;
  text-align: center;

}
.div_tooltip:hover .div_tooltiptext_partial {
  visibility: visible;
  text-align: center;

}
</style>
