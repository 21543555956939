import Vue from "vue";

Vue.filter("mathOnlyRound", function(value) {
  return Math.round(value);
});

Vue.filter("numberWithCommas", function(x) {
  var numFormat = new Intl.NumberFormat("en-US");
  return numFormat.format(x);
});

Vue.filter("textWithSlash", function(value) {
  var data = Object.values(value).join("/ ");
  return data;
});

Vue.filter("textWithComma", function(value) {
  var data = Object.values(value).join(", ");
  return data;
});

Vue.filter("toFixedTwo", function(value) {
  return Number(value).toFixed(2);
});

Vue.filter("calculateRating", function(value) {
  return Number((value*5)/100).toFixed(1);
});

Vue.filter("getFullYear", function(value) {
  return new Date(value).getFullYear();
});

Vue.filter("getShortMonthYear", function(value) {
  return new Date(value).toLocaleString("en-US", { month: "short" }) + " " + new Date(value).getFullYear()
});