<template>
    <div>
        <li v-for="value in response" :key="value.id"><p v-html="value"></p></li>
        
    </div>
</template>

<script>
import axios from 'axios';
import env from '@/../env.js';

export default {
  name: 'VueAirtable',
  props: [
    'filter',
    'sector',
    'type'
  ],
  data: function () {
            return {
                apiUrl: 'https://api.airtable.com/v0/appXebWdllnMq540w/Master%20Indicator%20Framework?fields%5B%5D=indicator&fields%5B%5D=std_source&fields%5B%5D=std_source_url&fields%5B%5D=scored_Alpha&fields%5B%5D=scored_Beta&fields%5B%5D=scored_Gamma&fields%5B%5D=scored_Delta&fields%5B%5D=scored_Zeta&fields%5B%5D=subsector_Alpha&fields%5B%5D=subsector_Beta&fields%5B%5D=subsector_Delta&fields%5B%5D=subsector_Gamma&fields%5B%5D=subsector_Zeta',
                apiKey: env.airTableAccessToken, 
                response:['Loading']
            };
        },
  mounted: function () {
    this.getData();
  },
  methods: {
    compareOrder(a, b) {
      if (a.fields.var_order < b.fields.var_order) {
          return -1;
        }
        if (a.fields.var_order > b.fields.var_order) {
          return 1;
        }
        return 0;
    },
    getData: async function () {
        const _self = this;
        await axios({
        url: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.apiKey}`
        },
        params: {
          filterByFormula: this.filter || ''
        }
      }).then((res) => {
          this.response = [];
          res.data.records.sort(this.compareOrder);
          res.data.records.forEach((value) => { 
                if(_self.sector != 'BASE'){
                    
                    Object.keys(value.fields).forEach((key)=> {
                       if(key == _self.type){
                            const checkSectorExist = value.fields[key].some((subSector)=>{
                                if(subSector.substr(0, subSector.indexOf('_')) == _self.sector){
                                    return true;
                                }
                            })
                            if(checkSectorExist){
                                
                                let showAsterik = false;
                                if(_self.type == "subsector_Alpha"){
                                   if(value.fields['scored_Alpha'] == "non-scored"){
                                        showAsterik = true;
                                    }
                                }else if(_self.type == "subsector_Beta"){
                                    if(value.fields['scored_Beta'] == "non-scored"){
                                        showAsterik = true;
                                    }
                                }else if(_self.type == "subsector_Delta"){
                                    if(value.fields['scored_Delta'] == "non-scored"){
                                        showAsterik = true;
                                    }
                                }else if(_self.type == "subsector_Gamma"){
                                    if(value.fields['scored_Gamma'] == "non-scored"){
                                        showAsterik = true;
                                    }
                                }else if(_self.type == "subsector_Zeta"){
                                    if(value.fields['scored_Zeta'] == "non-scored"){
                                        showAsterik = true;
                                    }
                                }
                                
                                var std_source = ''
                                if(value.fields['std_source']) {
                                    std_source = value.fields['std_source'].substr(0,  value.fields['std_source'].indexOf('.')); 
                                }
                            
                                if(showAsterik){
                                    this.response.push(value.fields.indicator+" ("+std_source+")*")
                                }else{
                                    this.response.push(value.fields.indicator+" ("+std_source+")")
                                }
                            }
                        }
                    });
                    
                }else{
                        let showAsterik = false;
                        if(_self.type == "subsector_Alpha"){
                            if(value.fields.scored_Alpha == "non-scored"){
                                showAsterik = true;
                            }
                        }else if(_self.type == "subsector_Beta"){
                            if(value.fields.scored_Beta == "non-scored"){
                                showAsterik = true;
                            }
                        }else if(_self.type == "subsector_Delta"){
                            if(value.fields.scored_Delta == "non-scored"){
                                showAsterik = true;
                            }
                        }else if(_self.type == "subsector_Gamma"){
                            if(value.fields.scored_Gamma == "non-scored"){
                                showAsterik = true;
                            }
                        }else if(_self.type == "subsector_Zeta"){
                            if(value.fields.scored_Zeta == "non-scored"){
                                showAsterik = true;
                            }
                        }     
                    var std_source1 = value.fields.std_source.substr(0,  value.fields.std_source.indexOf('.')); 
                    if(showAsterik){
                        this.response.push(value.fields.indicator+" (<a href="+value.fields.std_source_url+" target='_blank'>"+std_source1+"</a>)*")
                    }else{
                        this.response.push(value.fields.indicator+" (<a href="+value.fields.std_source_url+" target='_blank'>"+std_source1+"</a>)")
                    }
                }
          });
          if(this.response.length <= 0){
              this.response = [];
          }

      })
      .catch((err) => {
          if(err.response.status == 429) {
            this.getData();
          }
        });
  }
}
}
</script>