<template>
    <div class="breadcrumbs-container flex flex-row items-center justify-start flex-shrink-0">
        <div v-for="(bread, ind) in breadcrumbs" :key="bread.name" class="inner-breadcrumb">
            <router-link :to="bread.url">{{ bread.name + " "}}</router-link>
            <span v-if="breadcrumbs.length != ind + 1">/</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BreadCrumbs',
    computed: {
        breadcrumbs() {
            return this.$store.state.newBreadcrumbs;
        },
    }
}
</script>
<style lang="scss" scoped>
.breadcrumbs-container {
    height: 20px;
    gap: 4px;
    margin-top: 9rem;
    .inner-breadcrumb {
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #717686;
        gap: 8px;
        margin-top: 1rem;
        .router-link-exact-active {
            color: #191B45 !important;
            font-weight: 400;
        }
    }
}
</style>