<template>
  <div
    class="circle-chart flex flex-col items-center"
    style="min-height: 16.5625rem"
    :key="'updateDonutComponents'+updateKey"
  >
    <div class="flex items-center gap-3 justify-end w-full mb-2" v-if="$route.query.fromVerifier && info.surveyQType != 'Calculated'">
      <img
        src="@/assets/images/edit-dark.svg"
        alt="edit-icon"
        class="h-6 w-6 cursor-pointer"
        @click="$emit('openEditModalFromCustomComponent', info)"
      />
      <VerificationDropdown :value="info" />
    </div>
    <div
      class="max-w-[26rem] max-h-[2rem] h-[2rem] w-[26rem] text-center font-arial font-medium"
    >
      {{ info?.title }}
    </div>
    <div class="blue-circle mt-12">
      <svg
        class="m-auto"
        width="200"
        height="200"
        viewBox="-18.75 -18.75 187.5 187.5"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style="transform: rotate(-90deg)"
      >
        <circle
          r="65"
          cx="75"
          cy="75"
          fill="transparent"
          stroke="#f4f6f9"
          stroke-width="27"
          stroke-dasharray="408.2px"
          stroke-dashoffset="0"
        ></circle>
        <circle
          r="65"
          cx="75"
          cy="75"
          stroke="#19486A"
          stroke-width="27"
          stroke-linecap="round"
          :stroke-dashoffset="blueChartStrokeValue"
          fill="transparent"
          stroke-dasharray="408.2px"
        ></circle>
        <text
          x="32%"
          y="40%"
          fill="#21191D"
          font-size="0.875rem"
          font-weight="bold"
          style="transform: rotate(90deg) translate(-4%, -77%)"
        >
          {{ value }}%
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
import VerificationDropdown from "../my2xCertificationFullAssessmentResult/verificationDropdown.vue";

export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      updateKey: 0,
    }
  },
  components: { VerificationDropdown },
  computed: {
    blueChartStrokeValue() {
      let value;
      const stroke = 408.2;
      value = (stroke / 100) * (100 - this.info?.value);
      return value;
    },
    value() {
      if (typeof this.info?.value === "number") {
        return Math.floor(this.info?.value * 100) / 100;
      } else {
        return this.info?.value;
      }
    },
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.multi-pie-chart-container {
  width: fit-content;
  .square-legend {
    width: 16px;
    height: 16px;
    background: #19486A;
    border-radius: 2px;
  }
}
</style>
