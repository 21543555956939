<script>
import { Doughnut } from "vue-chartjs";
import env from "@/../env.js";

export default {
  name: "doughnutChart",
  extends: Doughnut,
  props: ["chartData", "chartLabels", "country", "index"],
  data: function () {
    return {
      labels: [],
      data: [],
      datasets: [],
      colors: [
        env.chartColors.alfaColor,
        env.chartColors.betaColor,
        env.chartColors.gammaColor,
        env.chartColors.deltaColor,
        env.chartColors.jotaColor,
        env.chartColors.muColor,
        env.chartColors.piColor,
        env.chartColors.rhoColor,
      ],
      fixedColors: [
        env.chartColors.alfaColor,
        env.chartColors.betaColor,
        env.chartColors.gammaColor,
        env.chartColors.deltaColor,
        env.chartColors.jotaColor,
        env.chartColors.muColor,
        env.chartColors.piColor,
        env.chartColors.rhoColor,
      ],
    };
  },
  methods: {
    changeText: function (text) {
      this.country.values["country"] = this.country.humanName;
      this.country.values["region"] =
        this.country.region == "Eastern Europe & Central Asia"
          ? "Europe & Central Asia"
          : this.country.region + " Region";
      this.country.values["income"] = this.country.income + " Group";

      const keys = Object.keys(this.country.values);
      for (var k = 0; k < keys.length; k++) {
        const key = keys[k];
        let value = this.country.values[key];
        text = text
          .toString()
          .split("{{" + key + "}}")
          .join(value);
        // let's try to convert to a number
        if (!isNaN(Number(text))) {
          text = Number(text);
        }
      }

      return text;
    },
    fillIn: function (array) {
      if (array && array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeText(array[i]);
        }

        return array;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.labels = this.fillIn(this.chartLabels);
    this.data = this.chartData || [];

    const _self = this;

    for (var i = 0; i < this.data.length; i++) {
      this.datasets.push({
        label: this.changeText(this.data[i].label),
        data: this.fillIn(this.data[i].data),
        backgroundColor: this.colors,
      });
    }

    this.renderChart(
      {
        labels: this.labels,
        datasets: this.datasets,
      },
      {
        fontFamily: "basier",
        tooltips: {
          titleFontFamily: "basier",
          bodyFontFamily: "basier",
          callbacks: {
            label: function (tooltipItem) {
              // return true;
              return Number(
                _self.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              ).toFixed(2);
            },
          },
        },
        legend: {
          labels: {
            fontSize: 13,
            fontFamily: "basier",
          },
          onClick(event, legendItem) {
            if (
              _self.datasets[0].backgroundColor[legendItem.index] !== "white"
            ) {
              _self.datasets[0].backgroundColor[legendItem.index] = "white";
            } else {
              _self.datasets[0].backgroundColor[legendItem.index] =
                _self.fixedColors[legendItem.index];
            }
            this.chart.update();
            this.chart.legend.legendItems[legendItem.index].fillStyle =
              _self.fixedColors[legendItem.index];
          },
        },
        scales: {
          ticks: {
            fontFamily: "basier",
          },
        },
      }
    );
  },
};
</script>

<style>
center {
}
</style>
