<template>
    
  <div class="static">

     <div style="display:flex; justify-content:space-between;">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-left: 285px;"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-right: 120px;"
      />
    </div>
    <div style="height:12rem;"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        
          <main style="grid-template-columns:none !important">
              
              <div style="width:100%; text-align: center;">
                 
                <section class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300" style="max-width: 960px">
                   
                  <p>
                      
                      <iframe class="airtable-embed" :src="url" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
                  </p>
                </section>   
                  
              </div>
          </main>
      </div>
    </transition>
  </div>
</template>

<script>
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
import googleCode from "@/google_translate_languages_code.json";
import jotFormCode from "@/jotForm_languages_code.json";
export default {
    name: "newtwoxassesmentfunds",
    data: function() {
     return {
      user: [],
      endofcreated: false,
      languagePref: '&language=en'
     }
    },
    components: {
      pageLoader
    },
    async created(){
      if(this.$route.path === '/2X-Ecosystem/New-2XAssessment/Fundsd'){
        this.$router.push('/')
      }
        this.user = await auth.me().catch(() => {
          console.log("Error login");
        });
        var googleCodeData = "";
        var jotFormCodeData = "";
        googleCode.code.forEach(data => {
          if (data[this.user.languagePreference]) {
            googleCodeData = data;
          }
        })
        jotFormCode.code.forEach(data => {
          if (googleCodeData && Object.entries(googleCodeData)[0][1] === Object.entries(data)[0][1]) {
            jotFormCodeData = data;
          }
        })
        if (jotFormCodeData) {
          this.languagePref = '&language=' + Object.entries(jotFormCodeData)[0][1]
        }
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
        {
          name: "New 2X Assessments",
          url:"/2X-Ecosystem/New-2XAssessment",
        },
        
        {
          name: "New 2X Assessments Funds",
          url:"/2X-Ecosystem/New-2XAssessment/Funds",
        },
        
    ]);
        this.endofcreated=true;
    },  
    computed: {
      url(){
        if(this.$route.query.recordId){
            return 'https://airtable.com/embed/shrojhMNZFSCKzSUy?backgroundColor=tealLight&prefill_LinkRecordId='+ this.$route.query.recordId +'&hide_LinkRecordId=true';
          }else{
          return 'https://form.jotform.com/230153496057153?equiloEmail=' + this.user.email + this.languagePref;
          }
        }
      }
    };

</script>
