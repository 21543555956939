<template>
  <div class="flex justify-between">
    <router-link
      class="p-2 whitespace-nowrap rounded-lg text-white font-bold no-underline hover:text-white"
      :class="linkClass"
      :to="linkTo"
    >
      {{ linkText }}
    </router-link>
    <div class="cursor-pointer" @click="openModal()">
      <span v-if="value?.citations?.length">📁</span>
    </div>
    <!-- Citations modal -->
    <div
      v-if="isModalVisible"
      class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      @click.self="closeModal"
    >
      <div
        class="bg-white p-6 rounded-lg shadow-lg w-3/4 max-h-[75vh] overflow-auto relative"
      >
        <div
          class="absolute top-0 right-10 mt-2 mr-2 cursor-pointer text-gray-500 hover:text-gray-700 text-4xl"
          @click="closeModal"
        >
          &times;
        </div>
        <div
          v-for="(citation, index) in value?.citations"
          :key="index"
          class="mb-4"
        >
          <h3
            class="text-2xl font-bold cursor-pointer bg-blue-200 p-2 rounded-md"
            @click="openPdfViewer(citation)"
          >
            {{ citation.document_title }} ➡️
          </h3>
          <span class="text-md text-gray-600 font-semibold">Section: </span>
          <p class="text-sm text-gray-600">{{ citation.section }}</p>
          <div
            v-for="(passage, index) in citation?.relevant_passages"
            :key="index"
            class="ml-4 mt-2"
          >
            <p class="text-sm">{{ passage }}</p>
          </div>
        </div>

        <button
          class="bg-red-500 text-white px-4 py-2 rounded-md mt-4"
          @click="closeModal"
        >
          Close
        </button>
      </div>
    </div>
    <!-- PDF viewer modal -->
    <div
      v-if="showPdfModal"
      class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      @click.self="closePdfViewer"
    >
      <div
        class="bg-white p-6 rounded-lg shadow-lg w-3/4 h-[90vh] overflow-auto relative"
      >
        <div
          class="absolute top-0 right-10 mt-2 mr-2 cursor-pointer text-gray-500 hover:text-gray-700 text-4xl"
          @click="closePdfViewer"
        >
          &times;
        </div>
        <h2 class="text-xl font-bold mb-4">{{ pdfData.title }}</h2>
        <vue-pdf-embed :source="pdfData.url" />
        <button
          class="bg-red-500 text-white px-4 py-2 rounded-md mt-4"
          @click="closePdfViewer"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";

export default {
  name: "MLVerifierLink",
  props: {
    value: Object,
    teamId: String,
  },
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      isModalVisible: false,
      showPdfModal: false,
      pdfData: null,
    };
  },
  computed: {
    linkClass() {
      const statusClassMap = {
        ERROR: "bg-gray-500",
        UNKNOWN: "bg-yellow-500",
        YES: "bg-green-500",
        NO: "bg-red-500",
      };
      return statusClassMap[this.value.MLVerifiedData] || "bg-gray-500";
    },
    linkTo() {
      return {
        path: `/2X-Ecosystem/third-party-verification?companyId=${this.teamId}`,
      };
    },
    linkText() {
      switch (this.value.MLVerifiedData) {
        case "YES":
          return "Data Found";
        case "NO":
          return "Data Not Found";
        case "UNKNOWN":
          return "Needs Revision";
        default:
        case "ERROR":
          return "Not Processed";
      }
    },
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async openPdfViewer(citation) {
      try {
        const response = await axios.get(
          `${env.apiUrl}/certify2X/fetch-file-from-s3?fileName=${citation.document_name}&teamId=${this.teamId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
            responseType: "arraybuffer",
          }
        );
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        this.pdfData = { url: pdfUrl, title: citation.document_title };
        this.showPdfModal = true;
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    },
    closePdfViewer() {
      this.showPdfModal = false;
      this.pdfData = null;
    },
  },
};
</script>
