<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen px-4">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-6"
      >
        <div class="flex justify-between items-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Important Notice
          </h3>
          <button
            @click="closeModal"
            class="text-gray-400 hover:text-gray-600 focus:outline-none"
          >
            <svg
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>

        <div class="mt-4">
          <p class="text-sm text-gray-700 mb-4">
            This 2X assessment tool is intended to be used as a self-assessment
            only. Once submitting these results, this self-assessment cannot be
            edited or revised. Repeat assessments may be done once a year or
            sooner with a fee.
          </p>
          <p class="text-sm text-gray-700 mb-4">
            This tool is not intended as an investor tool to screen an investee.
            If you wish to screen an investee for 2X eligibility, please use the
            <a
              href="https://app.equilo.io/#/2X-Ecosystem/Thresholds"
              target="_blank"
              class="text-blue-500 underline"
              >2X Thresholds</a
            >, which may be edited and cleared multiple times for screening
            purposes.
          </p>
        </div>

        <!-- Confirm button -->
        <div class="mt-6 flex justify-end">
          <button
            @click="confirmUnderstanding"
            class="bg-[#201747] text-white px-4 py-2 rounded-md hover:bg-[#171035] focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Confirm understanding
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirmUnderstanding() {
      this.$emit("confirm");
    }
  }
};
</script>