<template>
  <div
    v-if="
      indicator.chartData[0].data[0] !== 'Missing' ||
      indicator.chartData[0].data[0] == 1
    "
  >
    <!--MODAL-->
    <indicatorModal v-if="showModal">
      <div class="overflow-x-scroll mx-5 max-w-screen-xl">
        <!--BG FOR MODAL-->
        <div
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
          @click="showModal = false"
        >
          <div class="absolute inset-0 opacity-75"></div>
        </div>

        <!--CONTENT-->
        <div
          class="p-5 bg-white rounded-lg text-left transform transition-all sm:my-8 sm:align-middle sm:w-full py-4 overflow-x-scroll"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 100%; width: 100%; height: 100%"
        >
          <!--TITLE-->
          <div class="flex">
            <!--ICON TO EXIT MODAL-->
            <div
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg
                class="h-6 w-6 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                @click="showModal = false"
                :style="{ cursor: 'pointer' }"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </div>

            <!--TEXT-->
            <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg leading-6 font-medium text-gray-900 mt-2"
                id="modal-headline"
              >
                {{ indicator.name }}
              </h3>
              <p class="flex flex-row items-center justify-start mt-2">
                <svg
                  class="w-5 h-5 mx-2"
                  :style="'color: ' + indicatorColor(indicator.taab)"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    stroke-linecap="round"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linejoin="round"
                  >
                    <path d="M19,12h-14"></path>
                    <path d="M14,17l5,-5"></path>
                    <path d="M14,7l5,5"></path>
                  </g>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
                <a
                  class="my-2"
                  :href="
                    '/#/reports/' +
                    $route.params.iso2 +
                    '/' +
                    $route.params.sector +
                    '/domains/' +
                    indicator.taab
                  "
                  :style="'color: ' + indicatorColor(indicator.taab)"
                  >Domain: {{ indicator.taab }}</a
                >
              </p>
            </div>
          </div>

          <!--CHARTS-->
          <div class="flex justify-center mx-auto py-4">
            <indicator-charts
              :country="country"
              :country-data="countryData"
              :index="index"
              :indicator="indicator"
              :chart-labels-for-charts="chartLabelsForCharts"
              :domain-data-unfiltered="domainDataUnfiltered"
              :modal="true"
              @chartChanged="currentChart = $event"
            ></indicator-charts>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              @click="showModal = false"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
            <!--  <button @click="logIndicator">Log</button> -->
          </div>
        </div>
      </div>
    </indicatorModal>

    <!-- Dashboard Modal -->
    <div v-if="showDashboardModal" class="fullscreen-modal">
      <div class="bg-white p-5 rounded-lg">
        <h3 class="text-lg font-medium">Add to Dashboard</h3>
        <input
          v-model="dashboardName"
          placeholder="Dashboard Name"
          class="border p-2 rounded w-full my-2"
        />
        <div class="flex justify-end space-x-2">
          <button
            @click="showDashboardModal = false"
            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
          <button
            @click="saveDashboard"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Save & Add
          </button>
        </div>
      </div>
    </div>

    <div
      class="bg-white shadow-md rounded-lg p-6 mt-2"
      :style="{
        borderColor: '#cfcfcf',
        borderWidth: '2px',
        borderStyle: 'solid',
      }"
    >
      <!-- <button @click="logIndicator">LOG</button> -->
      <div class="flex flex-row justify-between">
        <h2 class="text-xl font-bold mb-4">{{ indicator.name }}</h2>
        <button
          v-if="!isDashboard"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-md inline-flex items-center w-5 h-5 flex justify-center py-4 px-4 text-2xl"
          @click="addToDashboard"
        >
          +
        </button>
      </div>
      <div class="text-gray-700"></div>
      <!-- <p class="text-md text-gray-500">Sector: {{ indicator.sector }}</p> -->
      <p class="text-md" :style="{ color: indicatorColor(indicator.taab) }">
        Domain: {{ indicator.domain }}
      </p>
      <p class="text-md text-gray-700 pb-2">Theme: {{ indicator.theme }}</p>
      <hr class="py-1" />
      <!--   <p class="text-md text-gray-7 00">Rank: {{ indicator.rank }}</p> -->
      <!--   <p>{{ indicator.chartType }}</p> -->
      <div class="flex justify-center">
        <indicator-charts
          :country="country"
          :country-data="countryData"
          :index="index"
          :indicator="indicator"
          :chart-labels-for-charts="chartLabelsForCharts"
          :domain-data-unfiltered="domainDataUnfiltered"
          @chartChanged="currentChart = $event"
          :modal="false"
        ></indicator-charts>
      </div>
      <div class="text-gray-700 flex justify-end">
        <button
          @click="showModal = true"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
        >
          <span>Expand</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env";
import indicatorModal from "./indicatorModal.vue";
import indicatorCharts from "@/components/QDV-GESI/indicatorCharts.vue";
import Cookies from "@/helpers/cookies";

export default {
  name: "indicator-card",
  components: {
    indicatorModal,
    indicatorCharts,
  },
  props: {
    indicator: {
      type: Object,
      required: true,
      default: () => ({
        _id: "",
        chartLabels: [],
        recordId: "",
        primaryField: "",
        name: "",
        sector: "",
        text: "",
        chartText: [],
        taab: "",
        theme: "",
        rank: 0,
        chartConfigured: false,
        chartType: "",
        chartData: [],
        domain: "",
        where_used: "",
        ISO2: "",
        meta: {},
      }),
    },
    country: {
      type: Object,
      required: true,
    },
    countryData: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    chartLabelsForCharts: {
      type: Array,
      required: true,
    },
    domainDataUnfiltered: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    isDashboard: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
      showModal: false,
      colorArray: [
        { name: "Power & Decision-Making", color: "yellow" },
        { name: "Knowledge & Beliefs", color: "aqua" },
        { name: "Access to Resources", color: "yellow" },
        { name: "Law & Policy", color: "black" },
        { name: "Human Dignity", color: "blue" },
        { name: "Roles & Responsibilities", color: "gray" },
        { name: "Access to Resources", color: "lightgray" },
      ],
      env,
      currentChart: "",
      showDashboardModal: false,
      dashboardName: "",
    };
  },
  methods: {
    getColor(taab) {
      let colorObj = this.colorArray.find((obj) => obj.name === taab);
      return colorObj ? colorObj.color : "white";
    },
    indicatorColor(taab) {
      if (taab === "Access to Resources") {
        return env.taabColors.accessToResources;
      } else if (taab === "Law & Policy") {
        return env.taabColors.lawAndPolicy;
      } else if (taab === "Power & Decision-Making") {
        return env.taabColors.powerAndDecisio;
      } else if (taab === "Knowledge & Beliefs") {
        return env.taabColors.knowledgeAndBeliefs;
      } else if (taab === "Human Dignity") {
        return env.taabColors.humanDignity;
      } else if (taab === "Roles & Responsibilities") {
        return env.taabColors.rolesAndResponsibilities;
      }
      return true;
    },
    logIndicator() {
      console.info("Indicator", this.indicator);
      console.info("Country", this.country);
      console.info("CountryData", this.countryData);
      console.info("Currency", this.currency);
      console.info("Index", this.index);
      console.info("ChartLabelsForCharts", this.chartLabelsForCharts);
      console.info("DomainDataUnfiltered", this.domainDataUnfiltered);
    },
    addToDashboard() {
      this.showDashboardModal = true;
    },
    async saveDashboard() {
      const dashboardData = {
        dashboard: {
          name: this.dashboardName,
          userId: this.userId,
        },
        item: {
          indicatorId: this.indicator._id,
          country: this.country,
          countryData: this.countryData,
          currency: this.currency,
          index: this.index,
          chartLabelsForCharts: this.chartLabelsForCharts,
          domainDataUnfiltered: this.domainDataUnfiltered,
        },
      };
      try {
        const response = await axios.post(
          `${env.apiUrl}/dataExplorerDashboards`,
          dashboardData,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        this.$emit("user-fetch-required");
        this.showDashboardModal = false;
        return response;
      } catch (error) {
        console.error("Error saving dashboard:", error);
      }
    },
  },
};
</script>

<style scoped>
.bg-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.hover\:shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Adjust this value as needed */
}
</style>
