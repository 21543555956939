<template>
    <div class="static">
        <div class="main-container mb-20">
            <BreadCrumbs class="breadcrumbs-main-container"></BreadCrumbs>
            <div class="page-title-section flex justify-between mt-6">
                <div class="page-title">Finance Insights</div>
                <div class="page-figures">All figures are in thousands of USD</div>
            </div>
            <div class="sales-table-section mt-6">
                <SalesTable></SalesTable>
            </div>
            <div class="sales-table-section mt-6">
                <SalesTable></SalesTable>
            </div>
            <div class="statement-table-section mt-10">
                <div class="time-filter-section flex justify-between">
                    <div class="filter-options-section flex">
                        <div class="filter-options flex">
                            <div>By months</div>
                            <div class="active">By quarters</div>
                            <div>By year</div>
                        </div>
                        <div class="select-year">
                            <select name="year" id="year">
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                            </select>
                        </div>
                    </div>
                    <div class="download-button">
                        <button class="flex">Customize and download the report
                            <img src="@/assets/images/2x_certify/download-navy-blue.svg" />
                        </button>
                    </div>
                </div>
                <div class="statement-content-section mt-6">
                    <div>
                        <div class="statement-heading flex justify-between"
                            :class="{ 'is-fixed fixed-header': fixedComprehensiveSection }">
                            Statement of comprehensive income
                            <img v-if="isComprehensiveTableVisible"
                                @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
                                src="@/assets/images/arrow-up.svg" />
                            <img v-if="!isComprehensiveTableVisible"
                                @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
                                src="@/assets/images/arrow-down.svg" />
                        </div>
                        <div ref="comprehensiveSection">
                            <StatementTable class="mt-4" v-if="isComprehensiveTableVisible" />
                        </div>
                    </div>
                    <div class="mt-11">
                        <div class="statement-heading flex justify-between"
                            :class="{ 'is-fixed fixed-header': fixedFinancialSection }">
                            Statement of financial position
                            <img v-if="isFinancialTableVisible" @click="isFinancialTableVisible = !isFinancialTableVisible"
                                src="@/assets/images/arrow-up.svg" />
                            <img v-if="!isFinancialTableVisible" @click="isFinancialTableVisible = !isFinancialTableVisible"
                                src="@/assets/images/arrow-down.svg" />
                        </div>
                        <div ref="financialSection">
                            <StatementTable class="mt-4" v-if="isFinancialTableVisible" />
                        </div>
                    </div>
                    <div class="mt-11">
                        <div class="statement-heading flex justify-between"
                            :class="{ 'is-fixed fixed-header': fixedCashFlowSection }">
                            Statement of cash flow
                            <img v-if="isCashFlowTableVisible" @click="isCashFlowTableVisible = !isCashFlowTableVisible"
                                src="@/assets/images/arrow-up.svg" />
                            <img v-if="!isCashFlowTableVisible" @click="isCashFlowTableVisible = !isCashFlowTableVisible"
                                src="@/assets/images/arrow-down.svg" />
                        </div>
                        <div ref="cashFlowSection">
                            <StatementTable class="mt-4" v-if="isCashFlowTableVisible" />
                        </div>
                    </div>
                    <div class="mt-11">
                        <div class="statement-heading flex justify-between"
                            :class="{ 'is-fixed fixed-header': fixedMetricesSection }">
                            Financial metrics
                            <img v-if="isMetricesSectionVisible"
                                @click="isMetricesSectionVisible = !isMetricesSectionVisible"
                                src="@/assets/images/arrow-up.svg" />
                            <img v-if="!isMetricesSectionVisible"
                                @click="isMetricesSectionVisible = !isMetricesSectionVisible"
                                src="@/assets/images/arrow-down.svg" />
                        </div>
                        <div ref="metricesSection">
                            <div class="metrices-section mt-6" v-if="isMetricesSectionVisible">
                                <div class="charts-section flex justify-between gap-5">
                                    <div class="line-chart-section p-5 gap-4 w-1/3">
                                        <div class="mb-5 line-chart-heading">Total tonnes of hazardous waste discharged
                                        </div>
                                        <LineChart></LineChart>
                                    </div>
                                    <div class="line-chart-section p-5 gap-4 w-1/3">
                                        <div class="mb-5 line-chart-heading">Gross margin</div>
                                        <LineChart></LineChart>
                                    </div>
                                    <div class="line-chart-section p-5 gap-4 w-1/3">
                                        <div class="mb-5 line-chart-heading">Total debt</div>
                                        <LineChart></LineChart>
                                    </div>
                                </div>
                                <StatementTable class="mt-4" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-11">
                        <div class="statement-heading flex justify-between"
                            :class="{ 'is-fixed fixed-header': fixedProjectionSection }">
                            Initial projections vs. actual
                            <img v-if="isProjectionSectionVisible"
                                @click="isProjectionSectionVisible = !isProjectionSectionVisible"
                                src="@/assets/images/arrow-up.svg" />
                            <img v-if="!isProjectionSectionVisible"
                                @click="isProjectionSectionVisible = !isProjectionSectionVisible"
                                src="@/assets/images/arrow-down.svg" />
                        </div>
                        <div ref="projectionSection">
                            <div class="metrices-section mt-6" v-if="isProjectionSectionVisible">
                                <div class="charts-section flex justify-between gap-5">
                                    <div class="line-chart-section p-5 gap-4 w-1/3">
                                        <div class="mb-5 line-chart-heading">Total tonnes of hazardous waste discharged
                                        </div>
                                        <LineChart></LineChart>
                                    </div>
                                    <div class="line-chart-section p-5 gap-4 w-1/3">
                                        <div class="mb-5 line-chart-heading">Gross margin</div>
                                        <LineChart></LineChart>
                                    </div>
                                    <div class="line-chart-section p-5 gap-4 w-1/3">
                                        <div class="mb-5 line-chart-heading">Total debt</div>
                                        <LineChart></LineChart>
                                    </div>
                                </div>
                                <StatementTable class="mt-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SalesTable from "@/components/imm-components/tables/sales-table.vue"
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import StatementTable from "@/components/imm-components/tables/statement-table.vue"
import LineChart from "@/components/charts/PortfolioLineChart.vue"

export default {
    name: "AggregatedPortfolioFinance",
    data() {
        return {
            isComprehensiveTableVisible: true,
            isFinancialTableVisible: true,
            isCashFlowTableVisible: true,
            isMetricesSectionVisible: true,
            isProjectionSectionVisible: true,
            fixedComprehensiveSection: false,
            fixedFinancialSection: false,
            fixedCashFlowSection: false,
            fixedMetricesSection: false,
            fixedProjectionSection: false,
        }
    },
    components: {
        SalesTable,
        BreadCrumbs,
        StatementTable,
        LineChart
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.$store.commit("setNewBreadcrumbs", [
            { name: "Dashboard", url: "/imm/imm-landing-page" },
            { name: "Aggregated portfolio", url: "/imm/aggregate-portfolio" },
            { name: "Finance", url: "/imm/aggregate-portfolio/finance" },
        ]);
    },
    methods: {
        handleScroll() {
            if (this.$refs.comprehensiveSection.getBoundingClientRect().top <= 122) {
                this.fixedComprehensiveSection = true;
                this.fixedFinancialSection = false;
                this.fixedCashFlowSection = false;
                this.fixedMetricesSection = false;
                this.fixedProjectionSection = false;
            } else {
                this.fixedComprehensiveSection = false;
            }
            if (this.$refs.financialSection.getBoundingClientRect().top <= 122) {
                this.fixedComprehensiveSection = false;
                this.fixedFinancialSection = true;
                this.fixedCashFlowSection = false;
                this.fixedMetricesSection = false;
                this.fixedProjectionSection = false;
            } else {
                this.fixedFinancialSection = false;
            }
            if (this.$refs.cashFlowSection.getBoundingClientRect().top <= 122) {
                this.fixedComprehensiveSection = false;
                this.fixedFinancialSection = false;
                this.fixedCashFlowSection = true;
                this.fixedMetricesSection = false;
                this.fixedProjectionSection = false;
            } else {
                this.fixedCashFlowSection = false;
            }
            if (this.$refs.metricesSection.getBoundingClientRect().top <= 122) {
                this.fixedComprehensiveSection = false;
                this.fixedFinancialSection = false;
                this.fixedCashFlowSection = false;
                this.fixedMetricesSection = true;
                this.fixedProjectionSection = false;
            } else {
                this.fixedMetricesSection = false;
            }
            if (this.$refs.projectionSection.getBoundingClientRect().top <= 122) {
                this.fixedComprehensiveSection = false;
                this.fixedFinancialSection = false;
                this.fixedCashFlowSection = false;
                this.fixedMetricesSection = false;
                this.fixedProjectionSection = true;
            } else {
                this.fixedProjectionSection = false;
            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style lang="scss" scoped>
.main-container {
    .fixed-header {
        position: fixed;
        width: 91.6%;
        margin-top: 64px;
        padding: 20px 0;
        z-index: 1000;
        transition: top 0.3s ease;
        display: flex;
        justify-content: space-between;
        height: 90px;
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 68%, rgba(255, 255, 255, 0) 83%);
    }

    .is-fixed {
        top: 0;
    }

    margin-left: 80px;
    margin-right: 80px;

    .breadcrumbs-main-container {
        margin-top: 100px;
    }

    .page-title-section {
        height: 45px;

        .page-title {
            font-family: Montserrat;
            font-size: 36px;
            font-weight: 700;
            line-height: 45px;
            letter-spacing: 0em;
            text-align: left;
            color: #2C2E35;
        }

        .page-figures {
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #717686;
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }

    .sales-table-section {}

    .statement-table-section {
        .time-filter-section {
            .filter-options-section {
                gap: 32px;

                .filter-options {
                    height: 32px;
                    background: #F4F6F9;
                    border-radius: 8px;
                    font-family: Arial;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #9EA4BA;

                    div {
                        padding: 6px 12px 6px 12px;
                    }

                    .active {
                        background: #2177C1;
                        border-radius: 8px;
                        color: #FFFFFF;
                    }
                }

                .select-year {
                    select {
                        height: 32px;
                        padding: 0 12px;
                        border-radius: 8px;
                        border: 1px solid #E1E3E9;
                        gap: 8px;
                        color: #2C2E35;
                    }
                }
            }

            .download-button {
                button {
                    padding: 8px 16px 8px 16px;
                    border-radius: 8px;
                    border: 1px solid #191B45;
                    gap: 6px;
                    font-family: Arial;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #191B45;
                }
            }
        }

        .statement-content-section {
            .statement-heading {
                font-family: Montserrat;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #2C2E35;

                img {
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
            }

            .metrices-section {
                .charts-section {
                    .line-chart-section {
                        border: 1px solid #E1E3E9;
                        border-radius: 16px;

                        .line-chart-heading {
                            //styleName: Body2 Bold;
                            font-family: Arial;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #2C2E35;
                        }
                    }
                }
            }
        }
    }
}</style>