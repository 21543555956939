const cms = {
  'social_institution_and_gender_index_text': "On a scale of 0% (low) to 100% (high), {{COUNTRY}} scores {{INC_SIGISC_F_1021}}. SIGI measures discrimination across four dimensions: discrimination in the family, restricted physical integrity, restricted access to productive and financial resources, and restricted civil liberties.",
  'woman_business_law_index': "On a scale of 0 (total inequality) to 100 (full equality), {{COUNTRY}} scores {{IND_WBLSCR_F_1019}}. The WBL Index measures gender inequality in the law across mobility, workplace, pay, marriage, parenthood, entrepreneurship, assets, and pensions.",
  'equal_mesaures_sdg_index': "On a scale of 0 (total inequality) to 100 (full equality), {{COUNTRY}} scores {{IND_EM2030_F_1020}}. This index measures gender equality in relation to targets set for each indicator in 14 of the 17 Sustainable Development Goals.",
  'women_peace_security_index': "On a scale of 0 (low) to 1 (high), {{COUNTRY}} scores {{IND_WPSSCR_F_1044}}. The WPS Index measures women's wellbeing and their empowerment in homes, communities, and societies more broadly across three dimensions: inclusion, justice, and security.",
  'undp_gdi': "On a scale of 0 (total inequality) to 1 (gender parity), {{COUNTRY}} scores {{IND_UNDGDI_F_1060}}. The UNDP Gender Development Index (GDI) measures gender inequalities in achievement in three basic dimensions of human development: health, education, and command over economic resources.",
  'undp_gii': "On a scale of 0 (men and women fair equally) to 1 (one gender fares as poorly as possible), {{COUNTRY}} scores {{IND_UNDGII_F_1061}}. The UNDP Gender Inequality Index (GII) reflects gender-based disadvantage in three dimensions: reproductive health, empowerment, and the labour market.",
}


export default cms
