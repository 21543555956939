<template>
  <div class="flex my-[1rem] flex-col">
    <h3 class="font-bold max-w-[32rem]">{{ title }}:</h3>
    <div class="flex ml-2 flex-col" v-if="!noInfo">
      <dynamic-render
        v-for="(item, k) in values"
        :key="k"
        :type="item.chartType"
        :info="item"
        :clustered="true"
        :certificationScoreData="certificationScoreData"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "clustered",
  components: {
    DynamicRender: () => import("./dynamic-render.vue"),
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    certificationScoreData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: this.info.title,
      values: this.info.values,
      noInfo: false,
    };
  },
};
</script>
