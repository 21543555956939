<template>
  <div class="flex items-center justify-between">
    <div>
      <p class="mt-8 text-base leading-relaxed font-normal text-[#666461] max-w-5xl">
      The information provided is for general informational purposes only and is not intended to constitute legal, financial, or investment advice, with any reliance placed on it strictly at your own risk. We do not guarantee the accuracy, relevance, timeliness, or completeness of information from external sources linked to this site, and the views expressed do not necessarily reflect the official policy of 2X Global or its members; we disclaim all liability for damages arising from the use of or reliance on this information.
    </p>
    <h3 class="text-xl font-medium tracking-wide text-gray-900 space-y-8 mt-4">
      Contact Information
    </h3>
    For any questions regarding this disclaimer, please contact us at <a
    class="underline"
    target="_blank"
    href="https://certification@2xglobal.org"
    >
      certification@2xglobal.org
    </a>
    <p class="font-medium tracking-wide text-gray-900">
      This disclaimer is subject to change without notice. Please our website periodically for updates.
    </p>
    </div>
    <div class="flex  flex-col p-3">
      <div class="font-medium text-xl py-2 text-gray-900">
        Platform powered by:
      </div>
      <img src="@/assets/images/logo-navy.png" alt="Certification Tier"  class="h-14" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TowxScoreFooter',
}
</script>
