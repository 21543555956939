const sectors = [
  {
    name: "Financial Services",
    label: "2X_FNCE",
  },
  {
    name: "Financial and Insurance Activities",
    label: "2X_FNCE",
  },
  {
    name: "Agribusiness & Food",
    label: "2X_AGFOOD",
  },
  {
    name: "Agriculture",
    label: "2X_AGFOOD",
  },
  {
    name: "Consumer Services",
    label: "2X_CONSUMER",
  },
  {
    name: "Education",
    label: "2X_EDUC",
  },
  {
    name: "Healthcare",
    label: "2X_HLTH",
  },
  {
    name: "Healthcare and Social work",
    label: "2X_HLTH",
  },
  {
    name: "Infrastructure",
    label: "2X_INFR",
  },
  {
    name: "Transportation and Storage Heavy",
    label: "2X_INFR",
  },
  {
    name: "Water and Waste Management", // use heavy
    label: "2X_INFRHVY",
  },
  {
    name: "Construction", // use heavy
    label: "2X_INFRHVY",
  },
  {
    name: "Manufacturing- Heavy",
    label: "2X_MANFHVY",
  },
  {
    name: "Manufacturing",
    label: "2X_MANFHVY",
  },
  {
    name: "Mining and Quarrying",
    label: "2X_MANFHVY",
  },
  {
    name: "Power",
    label: "2X_POWER",
  },
  {
    name: "Energy and Power",
    label: "2X_POWER",
  },
  {
    name: "Professional Services",
    label: "2X_PROF",
  },
  {
    name: "Professional, Scientific, and Technical activities",
    label: "2X_PROF",
  },
  {
    name: "Telecoms",
    label: "2X_TELECOMS",
  },
  {
    name: "Manufacturing- Light",
    label: "2X_MANFLT",
  },
  {
    name: "Wholesale and Retail Trade",
    label: "2X_MANFLT",
  },
];

export default {
  sectors,
};
