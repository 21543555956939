<template>
  <div class="static">
    <!-- <img
      src="@/assets/images/background-image.jpg"
      alt="background-image"
      class="w-full z-0 -mt-20"
    /> -->
    <div class="w-full container mx-auto mt-20 px-4 mb-20 lg:px-0 pb-32">
      <div class="flex flex-row mt-32 lg:mt-0 justify-center">
        <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
          <h1
            class="
              text-6xl
              tracking-tighter
              font-medium
              text-center
              print:text-7xl
            "
            style="color: #201747"
          >
            Welcome to Equiloland Demo!
          </h1>
        </div>
      </div>
      <div class="mt-16 grid grid-cols-3 gap-4">
        <router-link to="/reports/EQ/AGRI_GENERL">
          <div class="container_foto container_foto-2x-gender long-text" style="height:103%">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/Global-Equality.png"
                alt="visibility-icon"
                class="card-icon"
              />
              
              <h2 style="wor">Equiloland Country & Sector GESI Analysis Demo</h2>
              <h4>
                Used by anyone to conduct a GESI analysis to understand the context/ landscape for project planning, due diligence, advocacy efforts, and more. Explore GESI analysis in Equiloland's agriculture sector, a fictitious country with dummy data, to view how Equilo provides GESI analysis, synthesizing hundreds of data points with accompanied qualitative analysis and ideas for action.
              </h4>
            </article>
            <img class="bg-shade" src="@/assets/images/Global-Equality.png" alt="" />
          </div>
        </router-link>
        <router-link to="/reports/EQ/INFR_GENERL/gbv-risk">
          <div class="container_foto container_foto-2x-gender long-text" style="height:103%">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              
              <h2>Equiloland GBV Risk Score Demo</h2>
              <h4>
                Used by financial system actors to integrate GBV into financial models to inform investment decisions. Explore the GBV risk in Equiloland, a fictitious country with dummy data, to understand the GBV risk score, global GBV heat map, materiality of GBV to finance within the infrastructure sector, case studies, and ideas for action.
              </h4>
            </article>
            <img class="bg-shade" style="width: 25%" src="https://api.equilo.io/ICONS/Theme icons/gender-based-violence.svg" alt="" />
          </div>
        </router-link>
        
      </div>
 
            
         
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
export default {
  name: "equilolandDemo",
  data: function () {
    return {
      active:false
    };
  },
  methods: {
    mouseOver: function(index) {
      this.active = index;
    },
  },
  computed: {},
  async mounted() {
    this.$store.commit("resetCitations");

    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

//    if (this.user.organization.subscription.price < 1) {
//      this.$router.push({ path: "/welcome" });
//    }
    this.$store.commit("downloadable", false);
    this.$store.commit("setBreadcrumbs", [{ name: "Equiloland", url: "/equiloland-demo" }]);
  },
};
</script>

