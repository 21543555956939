<script>
import { PolarArea } from "vue-chartjs";
import env from "@/../env.js";

export default {
  name: "radarChart",
  extends: PolarArea,
  props: [
    "chartData",
    "chartLabels",
    "countryD",
    "indicator",
    "chartLabelsForCharts",
  ],
  data: function () {
    return {
      labels: [],
      data: [],
      datasets: [],
      colors: [
        env.geoColors.countryspider,
        env.geoColors.regionspider,
        env.taabColors.humanDignity,
        env.geoColors.incomespider,
        env.chartColors.jotaColor,
        env.chartColors.muColor,
        env.chartColors.piColor,
        env.chartColors.rhoColor,
      ],
      country: [],
    };
  },
  methods: {
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    changeText: function (text, index) {
      if (index < this.countryD.length) {
        this.country = [];
        this.country = this.countryD[index];
        this.country.values["country"] = this.country.humanName;
        this.country.values["region"] =
          this.country.region == "Eastern Europe & Central Asia"
            ? "Europe & Central Asia"
            : this.country.region + " Region";
        this.country.values["income"] =
          this.toTitleCase(this.country.income) + " Group";

        const keys = Object.keys(this.country.values);
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k];
          let value = this.country.values[key];
          text = text
            .toString()
            .split("{{" + key + "}}")
            .join(value);
          // let's try to convert to a number
          if (!isNaN(Number(text))) {
            text = Number(text);
          }
        }

        return text;
      }
      else {
        this.country = [];
        this.country = this.countryD[0];
        this.country.values["country"] = this.country.humanName;
        this.country.values["region"] =
          this.country.region == "Eastern Europe & Central Asia"
            ? "Europe & Central Asia"
            : this.country.region + " Region";
        this.country.values["income"] =
          this.toTitleCase(this.country.income) + " Group";

        const keys = Object.keys(this.country.values);
        for (let k = 0; k < keys.length; k++) {
          const key = keys[k];
          let value = this.country.values[key];
          text = text
            .toString()
            .split("{{" + key + "}}")
            .join(value);
          // let's try to convert to a number
          if (!isNaN(Number(text))) {
            text = Number(text);
          }
        }

        return text;
      }
    },
    fillIn: function (array, index) {
      if (array && array.length > 0 && index == 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeText(array[i], i);
        }
        return array;
      } else {
        return [];
      }
    },
    fillInForLabels: function (array) {
      if (array && array.length > 0) {
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if (array[i] == "LMIC") {
            array[i] = "Global";
          }

          array[i] = this.changeText(array[i], i);
        }
        return array;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.labels = this.fillInForLabels(this.chartLabels);
    this.labels.push("Global");
    this.data.push(this.chartData[0] || []);

    for (var i = 0; i < this.countryD.length; i++) {
      this.datasets.push({
        label: this.data[0].label,
        data: this.fillIn(this.data[0].data, i),
        backgroundColor: this.colors,
      });
    }

    let allDataSets = this.datasets[0].data;
    this.datasets.forEach((data, d) => {
      allDataSets.forEach((item, i) => {
        if (d > 0 && d == i) {
          data.data.push(item);
        }
      });
    });

    const _self = this;

    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.data[0].label,
            data: this.datasets[0].data,
            backgroundColor: this.colors,
          },
        ],
      },
      {
        legend: {
          display: true,
          labels: {
            fontSize: 13,
            fontFamily: "basier",
          },
        },
        scale: {
          angleLines: {
            display: false,
          },
          ticks: {
            suggestedMax: 5,
            fontFamily: "basier",
            callback: function (value) {
              if (_self.indicator.dataType === "PERCENT") {
                return value.toFixed(0) + "%"; // convert it to percentage
              } else {
                return value;
              }
            },
          },
        },
        tooltips: {
          titleFontFamily: "basier",
          bodyFontFamily: "basier",
          callbacks: {
            label: function (tooltipItem) {
              if (_self.indicator && _self.indicator.dataType === "PEOPLE") {
                return Number(
                  Math.round(tooltipItem.yLabel) || 0
                ).toLocaleString();
              } else if (
                _self.indicator &&
                _self.indicator.dataType === "NUMBER"
              ) {
                return Number(
                  Math.round(tooltipItem.yLabel * 10) / 10 || 0
                ).toLocaleString();
              } else {
                return Number(tooltipItem.yLabel || 0).toFixed(1) + "%";
              }

              //return Number(tooltipItem.yLabel).toFixed(2)+"%";
            },
          },
        },
      }
    );
  },
};
</script>

<style>
</style>
