<template>
    <div class="dimension_inner_name flex">
        <div class="dimension_inner_left_side">
            <div class="dimension_survey_question mt-4 mb-4 flex">
                <div class="survey_question_inner">
                    <span>{{ score.subpageData[0].verbiageForPercentageFields }}
                    </span>
                </div>
                <div class="multi_values_inner text-center">
                    <div style="color:#FE5000" class="font-bold">{{
                        financialYear[0].split(" ")[0] + " " +
                        financialYear[0].split(" ")[1]
                    }}</div>
                    <span v-if="score.subpageData[0]?.score != '' && score.subpageData[0]?.score != 'NA'">{{
                        score.subpageData[0]?.score }}</span>
                    <span class="font-bold" v-if="score.subpageData[0]?.score == ''">Unknown</span>
                    <span class="font-bold" v-if="score.subpageData[0]?.score == 'NA'">Not Applicable</span>
                    <br /><br />
                    <span v-if="getScoredataLength > 1 && score.subpageData[0]?.Oldscore != '' && score.subpageData[0]?.Oldscore != 'NA'">
                        <span style="color:#201747" class="font-bold">{{
                            financialYear[1].split(" ")[0] + " " +
                            financialYear[1].split(" ")[1]
                        }}</span>
                        <br />
                        {{ score.subpageData[0]?.Oldscore }}
                    </span>
                    <div v-if="getScoredataLength > 1 && score.subpageData[0]?.Oldscore == ''" class="font-bold">
                        <span style="color:#201747">{{
                            financialYear[1].split(" ")[0] + " " +
                            financialYear[1].split(" ")[1]
                        }}</span>
                        <br />
                        Unknown
                    </div>
                    <div v-if="getScoredataLength > 1 && score.subpageData[0]?.Oldscore == 'NA'" class="font-bold">
                        <span style="color:#201747">{{
                            financialYear[1].split(" ")[0] + " " +
                            financialYear[1].split(" ")[1]
                        }}</span>
                        <br />
                        Not Applicable
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "StringsChart",
    props: ["score", "getScoredataLength", "roiPageIntro", "financialYear"],
    components: {
    },
    filters: {
        textWithCommaRemoveOther(value) {
            value.forEach((data, d) => {
                if (data == 'Other') {
                    value.splice(d, 1);
                }
            })
            var data = Object.values(value).join(", ");
            return data;
        },
        removeHiddenFieldText(value) {
            if (value.includes('Formula (Hidden) Field:')) {
                return value.split('Formula (Hidden) Field:')[1];
            } else {
                return value;
            }
        },
        removePerchantageSign(str) {
            if (str?.startsWith('%')) {
                return str.slice(1);
            } else {
                return str;
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.dimension_inner_name {
    .dimension_inner_left_side {
        width: 100%;

        .dimension_survey_question {
            font-weight: 700;
            color: black;
            word-break: break-word;
            min-height: 70px;

            .survey_question_inner {
                width: 60%;
            }

            .barchart_inner {
                width: 40%;
            }

            .multi_values_inner {
                width: 30%;
                margin-left: 40px;
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }

    .dimension_inner_right_side {
        // width: 40%;
    }
}
</style>