<template>
  <div>
    <div class="filter flex flex-row items-center gap-3 mb-4">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M14 6H12V0H14V2H18V4H14V6ZM0 4V2H10V4H0ZM0 16V14H6V16H0ZM10 18V16H18V14H10V12H8V18H10ZM4 8V6H6V12H4V10H0V8H4ZM18 10V8H8V10H18Z"
          fill="#1B1B1B" />
      </svg>
      <h5 class="text-lg font-bold leading-5">Filter by</h5>
      <div style="width: 48%;text-align: right">
        <!-- <span class="cursor-pointer font-semibold" @click="resetFilters()">Reset</span> -->
        <Button class="loadmore-btn flex justify-center ml-auto" @click="resetFilters()">
          Reset</Button>
        <!-- <Button class="loadmore-btn flex justify-center ml-auto"
            :style="(checkedFiltersWithoutShowAll) ? '' : 'opacity:0.7;pointer-events: none'" @click="resetFilters()">
            Reset</Button> -->
      </div>
    </div>
    <div style="
        max-height: 52vh;
        height: 50vh;
        overflow-y: auto;
        padding-right: 10px;
      " id="scrollbar_style" v-if="filters && filters.length">
      <div class="collapse" v-for="(filter, index) in filters" :key="index">
        <div v-for="(arr, keyName, arrIndex) in filter" :key="arrIndex">
          <div class="collapsible flex justify-between items-center pb-4" v-if="arrIndex == 0">
            <h3 v-if="arrIndex == 0">{{ keyName | removeHyphen }}</h3>
            <div
              @click="toggleFilters(index)"
              style="height: 15px; cursor: pointer"
            >
              <img
                v-if="collapsed[index]"
                src="@/assets/images/plus.png"
                alt="background-image"
              />
              <img
                v-else
                src="@/assets/images/minus.png"
                alt="background-image"
              />
            </div>
          </div>
          <div class="expand" v-show="!collapsed[index]">
            <span v-for="(inn, i) in arr" :key="i">
              <input :value="inn" :id="inn" type="checkbox" :checked="inn == 'Show All'" v-if="inn == 'Show All'"
                @click="isChecked(inn, keyName, index)" :class="inn + '&' + keyName" />
              <input v-if="inn !== 'Show All'" type="checkbox" :id="inn" :value="inn" name="filterCheckbox"
                @click="isChecked(inn, keyName, index)" :class="inn + '&' + keyName" />
              <label for="data" class="pl-3 font-light text-sm" style="font-weight: 500;">{{ inn }}</label><br />
            </span>
          </div>
        </div>
      </div>
    </div>
    <Button class="loadmore-btn flex justify-center mx-auto mt-8" @click="updateResults()">
      Update results</Button>
  </div>
</template>
<script>
export default {
  name: "genderSmartToolsFilterSection",
  props: {
    filters: Array,
    query: String,
  },
  data: function () {
    return {
      updateKey: 0,
      checkedFilters: [],
      selected: true,
      otherCheckedClassname: [],
      checkedFiltersWithoutShowAll: false,
      collapsed: [],
    };
  },
  filters: {
    removeHyphen(value) {
      if (value == "Thematic_area") {
        return "Thematic Area";
      } else if (value == "Use_Cases") {
        return "Use Cases";
      } else if (value == "Asset_Class") {
        return "Asset Class";
      } else {
        return value;
      }
    },
  },
  methods: {
    resetFilters() {
      this.filters.forEach((item) => {
        let obj = Object.entries(item)[0][0];
        let key = obj;
        item[obj].forEach((o) => {
          if (key == obj) {
            let doc = document.getElementsByClassName(o + "&" + key);
            if (doc && doc[0] && o == "Show All") {
              doc[0].checked = true;
            }
            if (doc && doc[0] && o != "Show All") {
              doc[0].checked = false;
              if (this.checkedFilters.includes(o + "&" + key)) {
                let ind = this.checkedFilters.indexOf(o + "&" + key);
                this.checkedFilters.splice(ind, 1);
              }
            }
          }
        });
      });
      this.checkedFilters = [];
      this.checkedFiltersWithoutShowAll = false;
      this.$emit("updateResults", this.checkedFilters);
       this.$emit('resetSearchBox')
    },
    toggleFilters(index) {
      this.$set(this.collapsed, index, !this.collapsed[index]);
    },
    isChecked(value, key) {

      if (value == "Show All") {
        this.filters.forEach((item) => {
          let obj = Object.entries(item)[0][0];
          item[obj].forEach((o) => {
            if (key == obj) {
              let doc = document.getElementsByClassName(o + "&" + key);
              if (doc && doc[0] && o == "Show All") {
                doc[0].checked = true;
              }
              if (doc && doc[0] && o != "Show All") {
                doc[0].checked = false;
                if (this.checkedFilters.includes(o + "&" + key)) {
                  let ind = this.checkedFilters.indexOf(o + "&" + key);
                  this.checkedFilters.splice(ind, 1);
                }
              }
            }
          });
        });
      }

      if (
        document.getElementsByClassName(value + "&" + key)[0].checked == true
      ) {
        if (
          value != "Show All" &&
          document.getElementsByClassName("Show All&" + key) &&
          document.getElementsByClassName("Show All&" + key)[0]
        ) {
          document.getElementsByClassName("Show All&" + key)[0].checked = false;
        }

        this.checkedFilters.push(value + "&" + key);
      }
      if (
        value != "Show All" &&
        document.getElementsByClassName(value + "&" + key)[0].checked == false
      ) {
        let ind = this.checkedFilters.indexOf(value + "&" + key);
        this.checkedFilters.splice(ind, 1);
      }
    },
    updateResults() {
      console.log("this.checkedFilters", this.checkedFilters);
      this.$emit("updateResults", this.checkedFilters);
    },
    correctCheckboxes() {
      var checkedBoxes = document.querySelectorAll("input[name=filterCheckbox]");

      for (let i = 0; i < checkedBoxes.length; i++) {
        checkedBoxes[i].checked = false;
        this.checkedFilters.forEach((data) => {
          let doc = document.getElementsByClassName(data);
          if (doc && doc[0]) {
            doc.checked = true;
          }
        });
      }
      if (this.checkedFilters.length > 0) {
        this.checkedFilters.forEach((data) => {
          this.filters.forEach((item) => {
            let obj = Object.entries(item)[0][0];
            item[obj].forEach((o) => {
              const lastIndex = data.lastIndexOf("&");
              const beforeText = data.slice(0, lastIndex);
              const afterText = data.slice(lastIndex + 1);
              if (beforeText == o) {
                let doc = document.getElementsByClassName(
                  "Show All" + "&" + afterText
                );
                let doc1 = document.getElementsByClassName(data);
                if (doc1 && doc1[0] && o != "Show All") {
                  doc1[0].checked = true;
                }
                if (doc && doc[0]) {
                  doc[0].checked = false;
                }
              }
            });
          });
        });
      }
    },
    arrayWithoutShowAll() {
      if (this.checkedFilters && this.checkedFilters.length && this.checkedFilters.length > 0) {
        let arr = [];
        this.checkedFilters.forEach(data => {
          if (!data.includes('Show All')) {
            let arr1 = [];
            arr1 = (data.split('&')[0]);
            arr = arr.concat(arr1);
          }
        })
        if (arr.length > 0) {
          this.checkedFiltersWithoutShowAll = true;
        } else {
          this.checkedFiltersWithoutShowAll = false;
        }
      }
    }
  },
  mounted() {
    this.collapsed = new Array(this.filters.length).fill(true);
  },
  updated() {
    var checkedBoxes = document.querySelectorAll("input[name=filterCheckbox]");

    for (let i = 0; i < checkedBoxes.length; i++) {
      checkedBoxes[i].checked = false;
      this.checkedFilters.forEach((data) => {
        let doc = document.getElementsByClassName(data);
        if (doc && doc[0]) {
          doc.checked = true;
        }
      });
    }
    if (this.checkedFilters.length > 0) {
      this.checkedFilters.forEach((data) => {
        this.filters.forEach((item) => {
          let obj = Object.entries(item)[0][0];
          item[obj].forEach((o) => {
            const lastIndex = data.lastIndexOf("&");
            const beforeText = data.slice(0, lastIndex);
            const afterText = data.slice(lastIndex + 1);
            if (beforeText == o) {
              let doc = document.getElementsByClassName(
                "Show All" + "&" + afterText
              );
              let doc1 = document.getElementsByClassName(data);
              if (doc1 && doc1[0] && o != "Show All") {
                doc1[0].checked = true;
              }
              if (doc && doc[0]) {
                doc[0].checked = false;
              }
            }
          });
        });
      });
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
#scrollbar_style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffff;
  border-radius: 10px;
  background-color: #ffff;
}

#scrollbar_style::-webkit-scrollbar {
  width: 6px;
  background-color: #ffff;
}

#scrollbar_style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #c0c0c1;
  background-color: #c0c0c1;
}

.collapse {
  border-bottom: 1px solid #d0d0d0;
  padding-top: 15px;

  .collapsible {
    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #1b1b1b;
      text-align: left;
    }

    width: 100%;
    border: none;
    outline: none;
  }

  .expand {
    padding-bottom: 15px;
  }

  .content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
  }

  // div {

  // }
}

.loadmore-btn {
  border: 0.9px solid #1b1b1b;
  border-radius: 18px;
  background: rgba(245, 246, 249, 0.5);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #1b1b1b;
  padding: 9px 12px 10px 15px;
  height: 36px;
  // position: absolute;
  //   top: 13%;
  //   text-align: center;
  //   align-items: center;
  //   z-index: 99999;
  //   left: 1;
  //   margin-left: 57px;
}
</style>
