<template>
    <div class="static">
        <div style="display:flex; justify-content:right;" v-if="sharedOrgData && sharedOrgData.length > 0">
            <!-- <img src="@/assets/images/roi/sweef-large.png" alt="background-image" class="object-contain no-print"
                style="height:6rem; margin-top: 72px; margin-left: 285px;" /> -->
            <img src="@/assets/images/logo-navy.png" alt="background-image" class="object-contain no-print"
                style="height:6rem; margin-top: 72px; margin-right: 120px;" />
        </div>
        <transition name="fadeIn" mode="out-in">
            <div id="notLoaded" v-if="!endofcreated" key="loading">
                <page-loader></page-loader>
            </div>
            <div id="loaded" v-if="endofcreated" key="content">
                <div class="flex flex-col items-center justify-center w-full">
                    <div class="container" style="margin-bottom: 6rem;">
                        <div class="flex flex-row justify-center items-center mt-3 ml-20"
                            v-if="sharedOrgData && sharedOrgData.length > 0">
                            <div class="flex flex-col item-center justify-center">
                                <div class="flex flex-row items-center justify-center print:flex-col">
                                    <img class="h-16 print:h-auto print:w-64"
                                        src="@/assets/images/2X-assessment/2X-myFavAssessment.png" />
                                    <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5">
                                        GGSF Shared Assessments
                                    </h1>
                                </div>
                                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center">
                                    Click below to view the GGSF results for a specific company, fund, or
                                    financial institution.
                                </p>
                            </div>
                        </div>
                        <!-- new content start-->
                        <div class="mt-16 grid grid-cols-3 gap-4" v-if="sharedOrgData && sharedOrgData.length > 0">
                            <div v-for="(orgData, index) in sharedOrgData" :key="index" v-show="orgData.recordId">
                                <div class="container_foto container_foto-2x-gender long-text"
                                    @click="onAssessmentClick(orgData)">
                                    <div class="ver_mas text-center">
                                        <img src="@/assets/images/eye.png" alt="visibility-icon"
                                            class="visibility-icon" />
                                    </div>
                                    <article class="text-left">
                                        <img src="@/assets/images/Global-Equality.png" alt="visibility-icon"
                                            class="card-icon" />
                                        <h2 style="text-align:center;">{{ orgData.organizationName }}</h2>
                                        <h4>
                                            View this organization's GGSF assessment results.
                                        </h4>
                                    </article>
                                    <img v-if="orgData.organizationsLogo" class="bg-shade"
                                        :src="orgData.organizationsLogo ? orgData.organizationsLogo[0].url : ''"
                                        alt="" />
                                    <div v-else style="min-height:411px;"></div>
                                </div>
                            </div>
                        </div>


                        <div class="flex flex-row justify-left items-center mt-20 ml-20" v-if="isSharedEmpty">
                            <div class="flex flex-col item-center justify-left">
                                <div class="text-gray-600 font-medium flex flex-row items-center print:flex-col mt-5">
                                    Are you trying to find your own organization's results? If so, click here
                                </div>
                                <div class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2">
                                    <a href="/#/ggsf" class="text-white text-sm font-medium mr-3"
                                        style="width: max-content;">
                                        GGSF
                                    </a>
                                </div>
                                <div class="text-gray-600 font-medium flex flex-row items-center print:flex-col mt-5">
                                    Currently, no one has shared their GGSF results with you. Do you want to
                                    request that someone share their results with you? If so, invite them here to
                                    complete an assessment and share.
                                </div>
                                <div class="survey flex mt-3">
                                    <dd class="text-sm leading-5 text-gray-900" style="margin-right:10px;">
                                        <input type="email"
                                            class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm "
                                            placeholder="Enter Email Address" v-model="userEmail">
                                    </dd>
                                    <div class="text-sm leading-5 text-gray-900">
                                        <div @click="emailAssessmentReq()"
                                            style="background-color: #201747; color: white !important; cursor: pointer;"
                                            class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center">
                                            <button class="text-white text-sm font-medium">Email GGSF assessment
                                                request</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- new content end-->
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Cookies from "@/helpers/cookies";
import axios from "axios";
import env from '@/../env';
import pageLoader from "@/components/page-loader.vue";
export default {
    name: "ggsfSharedAssessments",
    data: function () {
        return {
            active: false,
            endofcreated: false,
            sharedOrgData: [],
            userEmail: null,
        }
    },
    components: {
        pageLoader
    },

    async created() {
        this.sharedOrgData = await this.getSharedOrgData();
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
            {
                name: "GGSF",
                url: "/ggsf",
            },
            {
                name: "GGSF Shared Assessments",
                url: "/ggsf/ggsfSharedAssessments",
            },

        ]);
        this.endofcreated = true;
    },
    methods: {
        getSharedOrgData() {
            return new Promise(function (resolve) {
                axios.get(env.apiUrl + '/roi/shared-assessments?type=ggsf',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function (res) {
                        resolve(res.data.data.data);
                    });
            })
        },
        onAssessmentClick(data) {

            let queryData = {
                recordId: data.recordId,
            };
            this.$router.push({ name: "roiInnpactAssessment", query: queryData })
        },
        validEmail(email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(email)) {
                return true;
            } else {
                return false;
            }
        },
        async emailAssessmentReq() {
            let validate = this.validEmail(this.userEmail)
            if (validate) {
                let usersAry = []
                usersAry.push(this.userEmail)
                let body = {
                    "sendEmails": usersAry,
                    "type": "invite"
                }
                let getROISurvey = await this.sendROISurveyEmail(body);
                if (getROISurvey) {
                    this.$swal.fire({
                        icon: 'success',
                        html:
                            'You will be notified once they complete it and can check back here then!<br><br>' +
                            '<b>Happy equality hacking!</b>',
                        showConfirmButton: true
                    });
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please try again!",
                        showConfirmButton: true,
                    });
                }
                this.userEmail = null
            } else {
                alert('Please enter valid email address!')
            }
        },

        sendROISurveyEmail(body) {
            return new Promise((resolve) => {
                axios.post(env.apiUrl + '/roi/send-survey', body,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function (res) {
                        resolve(res.data.data);
                    });
            })
        },
    },
    computed: {
        isSharedEmpty() {
            return this.sharedOrgData.length == 0 ? true : false;
        },
    }
};
</script>
<style scoped lang="scss">
.survey {
    a {
        border: 1px solid #1b1b1b;
        height: 36px;
        color: #1b1b1b !important;
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        padding: 9px 11px;
        transition: 0.3s ease;

        &:hover {
            background: rgb(32, 23, 71);
            color: #fff !important;
            border-color: rgb(32, 23, 71);
        }
    }
}
</style>
<style scoped>
.favorite-shared-button-section {
    text-align: right;
    padding: 20px 0 0;
}

/*  Toggle Switch  */

.toggleSwitch span span {
    display: none;
}

@media only screen {
    .toggleSwitch {
        display: inline-block;
        height: 18px;
        position: relative;
        overflow: visible;
        padding: 0;
        margin-left: 15px;
        cursor: pointer;
        width: 40px
    }

    .toggleSwitch * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .toggleSwitch label,
    .toggleSwitch>span {
        line-height: 20px;
        height: 20px;
        vertical-align: middle;
    }

    .toggleSwitch input:focus~a,
    .toggleSwitch input:focus+label {
        outline: none;
    }

    .toggleSwitch label {
        position: relative;
        z-index: 3;
        display: block;
        width: 100%;
    }

    .toggleSwitch input {
        position: absolute;
        opacity: 0;
        z-index: 5;
    }

    .toggleSwitch>span {
        position: absolute;
        left: -50px;
        width: 100%;
        margin: 0;
        padding-right: 50px;
        text-align: left;
        white-space: nowrap;
    }

    .toggleSwitch>span span {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        display: block;
        width: 50%;
        margin-left: 50px;
        text-align: left;
        font-size: 0.9em;
        width: 100%;
        left: 15%;
        top: -1px;
        opacity: 0;
    }

    .toggleSwitch a {
        position: absolute;
        right: 50%;
        z-index: 4;
        display: block;
        height: 100%;
        padding: 0;
        left: 4px;
        width: 18px;
        background-color: #fff;
        border: 1px solid #CCC;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }

    .toggleSwitch>span span:first-of-type {
        color: #ccc;
        opacity: 1;
        left: 45%;
    }

    .toggleSwitch>span:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50px;
        top: -2px;
        background-color: #fafafa;
        border: 1px solid #ccc;
        border-radius: 30px;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }

    .toggleSwitch input:checked~a {
        border-color: #fff;
        left: 100%;
        margin-left: -8px;
    }

    .toggleSwitch input:checked~span:before {
        border-color: #0097D1;
        box-shadow: inset 0 0 0 30px #0097D1;
    }

    .toggleSwitch input:checked~span span:first-of-type {
        opacity: 0;
    }

    .toggleSwitch input:checked~span span:last-of-type {
        opacity: 1;
        color: #fff;
    }

    /* Switch Sizes */
    .toggleSwitch.large {
        width: 70px;
        height: 30px;
        top: 5px;
    }

    .toggleSwitch.large a {
        width: 29px;
        height: 28px;
        top: 0.8px;
    }

    .toggleSwitch.large>span {
        height: 34px;
        line-height: 35px;
    }

    .toggleSwitch.large input:checked~a {
        left: 46px;
    }

    .toggleSwitch.large>span span {
        font-size: 1.1em;
    }

    .toggleSwitch.large>span span:first-of-type {
        left: 50%;
    }
}


/*  End Toggle Switch  */

.list-item {
    display: inline-block;
    margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
    transition: all 1s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */
    {
    opacity: 0;
    transform: translateY(30px);
}

.slide-in-move {
    transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
    transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
    transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
    transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
    transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
    opacity: 0;
}

.slide-in-enter {
    transform: translateY(2em);
}

.slide-in-leave-to {
    transform: translateY(-1em);
}

.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.tooltip .coming-soon-tooltip {
    font-family: "Montserrat", arial;
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 160px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: -19px;
    top: -70px;
}

.tooltip:hover .coming-soon-tooltip {
    visibility: visible;
}

.tooltip .coming-soon-tooltip::after {
    right: 95%;
}
</style>