<template>
  <div class="static ecosystem-landing pb-20">
    <div class="container mx-auto -mt-20 px-4 lg:px-0">
      <My2xBreadcrumbsVue class="pt-8 pb-8" />
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img src="@/assets/images/2XG-Logo-2023.png" alt="background-image" style="height: 50px;" />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="PoweredbyTitle_logo bg-image" style="padding: 0;">
        <div class="PoweredbyContentWrepper bg-text-style">
          <div class="PoweredbyTitleWrepper mt-5">
            <h1 class="text-6xl tracking-tighter font-medium print:text-7xl" style="color: #fff;">
              <span>2X Global Member Profile</span>
            </h1>
          </div>
          <div class="flex flex-col mt-10 mx-auto items-center justify-center" style="max-width: 845px">
            <p class="text-lg leading-7 font-medium gap-10 text-justify leading-7" style="color: #fff;">
              Use this form to submit information to create a profile for tailored 2X ecosystem tool access and
              intelligence. This form should be completed by all 2X Global investors who want access to 2X Challenge deal
              qualification, deal matchmaking platform, as well as members who are TA service providers.
            </p>
          </div>
        </div>
      </div>
      <div style="height:12rem;"></div>
      <transition name="fadeIn" mode="out-in">
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
        <div id="loaded" v-if="endofcreated" key="content">
          <div style="width:100%; text-align: center;">
            <section class="w-full container mx-auto px-[9%] lg:px-0 border-t border-gray-300 mb-0">
              <p>
                <iframe class="airtable-embed" :src="url"
                  frameborder="0" onmousewheel="" width="100%" height="1000"
                  style="background: transparent; border: 1px solid #ccc;"></iframe>
              </p>
              <div class="flex mt-[1rem] items-center justify-center">
              <tooltipWrapper text="Creating my investor profile is the first step to unlocking the benefits of tools for investors, including portfolio analytics for gender lens investment and impact measurement.">
                <button class="cancel-button flex justify-center mr-[.5rem]"
                  @click="$router.push({ path: '/?investorProfile=true' })">
                  Investor Profile
                </button>
              </tooltipWrapper>
              <tooltipWrapper text="Head over to the 2X Challenge page to find the tools needed to submit your 2X-aligned investment to qualify under the 2X Challenge.">
                <button class="cancel-button flex justify-center mx-[.5rem]"
                  @click="$router.push({ path: '/2X-Challenge' })">
                  2X Challenge
                </button>
              </tooltipWrapper>
              <tooltipWrapper text="Having set up your investor profile ensures that you are now ready to submit a deal for the 2X Challenge qualification.">
                <button class="cancel-button flex justify-center mx-[.5rem]"
                  @click="$router.push({ path: '/submit-deal' })">
                  Submit a Deal
                </button>
              </tooltipWrapper>
              <tooltipWrapper text="Are you interested in submitting multiple deals at once? Go here to use the bulk upload.">
                <button class="cancel-button flex justify-center mx-[.5rem]"
                  @click="$router.push({ path: '/2X-Challenge?open=true' })">
                  Bulk Upload
                </button>
              </tooltipWrapper>
              <tooltipWrapper text="Adding someone to your Equilo team ensures that they can see your investor profile. Go to your settings and invite your colleagues to Equilo to start collaborating!">
                <button class="cancel-button flex justify-center mx-[.5rem]"
                  @click="$router.push({ path: '/settings/members' })">
                  Share Profile
                </button>
              </tooltipWrapper>
              </div>
            </section>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import tooltipWrapper from "../../components/tooltipWrapper.vue";

import My2xBreadcrumbsVue from '../../components/My2xBreadcrumbs.vue';
export default {
  name: "SubmitDeals",
  data: function () {
    return {
      dealPipelineStatus: [],
      user: [],
      endofcreated: false,
      url: '',
    }
  },
  components: {
    pageLoader,
    tooltipWrapper,
    My2xBreadcrumbsVue
  },
  async created() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    this.url = `https://airtable.com/embed/appnY3HTFthCpb2y8/pagKYJ8GvhMC0Gmyo/form?prefill_LoggedInUserEmailId=${this.user?.email}`;
    if(this.user?.membership2xData) {
      this.$router.push({path: `/2X-Ecosystem/2XC-Membership/${this.user?.organization?._id}`})
    }
    this.dealPipelineStatus = await this.checkDealPipelineStatus();
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Challenge", url: "/2X-Challenge" },
      { name: "Create 2X Profile", url: "/2X-Ecosystem/create2Xprofile" },
    ]);
    this.endofcreated = true;
  },
  methods: {
    checkDealPipelineStatus() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/check-deal-pipeline-status", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.ecosystem-landing {
  background-color: #F4F1ED;
  color: #21191D !important;
  font-family: Work Sans;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 45px;
    color: #000;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .bg-text-style {
      margin-left: 9rem;
      position: relative;
      top: 20rem;
      color: #F4F1ED;
      width: 570px;
    }

    .PoweredbyTitleWrepper {
      max-width: 847px;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }


    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2C2E35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bg-image {
    background-image:
      linear-gradient(201deg,
        rgba(0, 0, 0, 0.1) 55%,
        rgba(221, 166, 58, 0.8) 100%),
      url("./../../assets/images/2x-investor-disclosure-bg.png");
    height: 700px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }
}

.cancel-button {
  width: auto;
  height: 2.5rem;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  border-radius: 8px;
  gap: 8px;
  background: #DDA63A;
  border: 1px solid #DDA63A;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: center;
}

</style>
