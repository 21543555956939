import { render, staticRenderFns } from "./country-domain.vue?vue&type=template&id=710f8fa7"
import script from "./country-domain.vue?vue&type=script&lang=js"
export * from "./country-domain.vue?vue&type=script&lang=js"
import style0 from "./country-domain.vue?vue&type=style&index=0&id=710f8fa7&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports