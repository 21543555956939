<template>
  <div class="flex flex-col items-center">
    <component
      :is="currentComponent"
      :info="info"
      :certificationScoreData="certificationScoreData"
      :clustered="clustered"
      :COM_Commitment="COM_Commitment"
    ></component>
    <span
      v-if="info.textForChartInfo"
      class="bg-blue-500 rounded-md p-2 text-md text-white text-center max-w-[25rem]"
      v-html="formattedTextForChartInfo"
    ></span>
    <div
      class="flex items-center gap-3 justify-end"
      v-if="$route.path.includes('/admin') && info?.surveyQType != 'Calculated'"
    >
      <img
        src="@/assets/images/edit-dark.svg"
        alt="edit-icon"
        class="h-6 w-6 cursor-pointer"
        @click="openEditModal(info)"
      />
      <verification-dropdown :value="info" />
      <MLVerifierValue :value="info" :teamId="$route.query?.teamId" />
    </div>
  </div>
</template>

<script>
import donut from "./donut.vue";
import table from "./table.vue";
import list from "./list.vue";
import barChart from "./barChart.vue";
import number from "./number.vue";
import openText from "./openText.vue";
import clustered from "./ClusteredChart.vue";
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
    certificationScoreData: {
      type: [Object, Array],
      default: null,
    },
    clustered: {
      type: Boolean,
      default: false,
    },
    COM_Commitment: {
      type: [Array],
      default: null,
    },
  },
  components: {
    "donut-chart": donut,
    "custom-table": table,
    list: list,
    barChart,
    number: number,
    openText: openText,
    clustered: clustered,
    MLVerifierValue,
    VerificationDropdown,
  },
  data() {
    return {};
  },
  computed: {
    currentComponent() {
      switch (this.type?.trim()) {
        case "Donut chart":
          return "donut-chart";
        case "CustomTable":
          return "custom-table";
        case "List":
          return "list";
        case "Bar graph":
          return "barChart";
        case "Number":
          return "number";
        case "Text":
          return "openText";
        case "Clustered":
          return "clustered";
        case "Open Text":
          return "openText";
        default:
          if (this.type && this.type !== "2X Spider Chart Score Value")
            console.warn(`Missing Component: ${this.type}`);
          return null;
      }
    },
    formattedTextForChartInfo() {
      if (!this.info || !this.info.textForChartInfo) {
        return "";
      }
      return this.info.textForChartInfo.replace(
        /(\d+)/g,
        "<strong>$1</strong>"
      );
    },
  },
};
</script>
