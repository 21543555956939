var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row w-full h-screen"},[_c('div',{staticClass:"w-4/12 h-screen flex flex-col items-center justify-center relative px-12",style:('background-color:' + _vm.env.brandingColors.navy)},[_c('img',{staticClass:"absolute top-0 left-0 mt-10 ml-10 h-10",attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo equilo"}}),_vm._m(0)]),_c('div',{staticClass:"w-8/12 h-screen bg-white flex flex-col items-center justify-center"},[_c('div',{staticClass:"w-8/12"},[_vm._m(1),(_vm.loading)?_c('div',[_c('div',{staticClass:"flex flex-col items-center justify-center"},[_c('local-loader')],1)]):_c('div',[_c('div',{staticClass:"flex flex-col justify-start"},[_c('h1',{staticClass:"font-medium"},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.email),expression:"input.email"}],staticClass:"border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm",class:{
              'border-red-600': _vm.error,
              'animate__animated animate__shakeX': _vm.error_animation,
            },attrs:{"type":"text","autocomplete":"email","id":"email","placeholder":"E-mail"},domProps:{"value":(_vm.input.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "email", $event.target.value)}}}),_c('div',{staticClass:"pwd-image"},[_c('h1',{staticClass:"mt-5 font-medium"},[_vm._v("Password")]),_c('span',{staticClass:"pwd-text",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[(_vm.showPassword)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/pwd-eye.png"),"height":"30","width":"30"}}):_vm._e(),(!_vm.showPassword)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/eye-slash.svg"),"height":"30","width":"30"}}):_vm._e()]),((_vm.showPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.password),expression:"input.password"}],staticClass:"border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm pwd-input",class:{
                'border-red-600': _vm.error,
                'animate__animated animate__shakeX': _vm.error_animation,
              },attrs:{"placeholder":"Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.input.password)?_vm._i(_vm.input.password,null)>-1:(_vm.input.password)},on:{"change":function($event){var $$a=_vm.input.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.input, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.input, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.input, "password", $$c)}}}}):((_vm.showPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.password),expression:"input.password"}],staticClass:"border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm pwd-input",class:{
                'border-red-600': _vm.error,
                'animate__animated animate__shakeX': _vm.error_animation,
              },attrs:{"placeholder":"Password","type":"radio"},domProps:{"checked":_vm._q(_vm.input.password,null)},on:{"change":function($event){return _vm.$set(_vm.input, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.password),expression:"input.password"}],staticClass:"border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm pwd-input",class:{
                'border-red-600': _vm.error,
                'animate__animated animate__shakeX': _vm.error_animation,
              },attrs:{"placeholder":"Password","type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.input.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "password", $event.target.value)}}})]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-row items-center justify-start"}),_c('div',[_c('router-link',{staticClass:"text-sm-b",staticStyle:{"color":"#0077c8"},attrs:{"to":"/reset-password","href":""}},[_vm._v("Reset Password")])],1)]),_c('button',{staticClass:"w-full py-5 rounded-lg mt-10 text-white font-semibold",style:('background-color: ' + _vm.env.brandingColors.teal),on:{"click":_vm.login}},[_c('span',{staticClass:"btn-text",staticStyle:{"font-size":"20px"}},[_vm._v("Submit")])]),(_vm.error)?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-red-500 text-xs animate__animated animate__fadeIn"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e()]),_c('div',{staticClass:"mt-10 text-gray-600"},[_c('p',{staticClass:"text-sm-b"},[_vm._v(" No account yet? "),_c('router-link',{staticClass:"text-sm-b",style:('color:' + _vm.env.brandingColors.blue),attrs:{"to":"/signup","href":""}},[_vm._v("Create an account")])],1)]),_c('div',{staticClass:"mt-5 text-gray-600"},[_c('p',{staticClass:"text-sm-b"},[_vm._v(" Trouble logging in? "),_c('a',{style:('color:' + _vm.env.brandingColors.blue),attrs:{"href":"https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red","target":"_blank"}},[_vm._v("Contact us for support!")])])])]),_c('div',{staticClass:"pt-10"},[_vm._v("v1.0.0")]),_c('div')])]),_c('translate-modal',{attrs:{"languagePreference":"English"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-4xl font-medium"},[_c('h2',{staticClass:"tracking-tighter hacking",staticStyle:{"color":"#fff"}},[_vm._v(" Hacking gender equality & social inclusion for a happier, more just world. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10"},[_c('h1',{staticClass:"text-5xl print:text-4xl font-bold tracking-tight"},[_vm._v(" Welcome to Equilo ")])])
}]

export { render, staticRenderFns }