<template>
  <div>
    <div class="flex items-center justify-between pt-4">
      <div class="flex flex-col">
        <p class="pt-1 text-sm leading-3 font-normal text-[#717686]">
          {{ companyName }}
        </p>
      </div>
      <div>
        <p class="text-base font-bold leading-6 text-[#2C2E35]">
          {{ percentage }}%
        </p>
      </div>
    </div>
    <div class="w-full bg-[#F4F6F9] h-5 mr-1 relative rounded-md mt-3">
      <div
        :style="{ width: `${percentage}%`, backgroundColor: color }"
        class="rounded-md h-5"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EsgProgressBarChart",
  props: {
    companyName: {
      type: String,
      required: true,
    },
    percentage: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
