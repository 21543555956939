<script>
import { Bar } from 'vue-chartjs'

export default {
    name: 'StackBarChart',
    extends: Bar,
    props: {
        chartData: {
            type: Array,
            required: true
        },
        chartLabels: {
            type: Array,
            required: true
        }
    },
    mounted() {
        this.renderMyChart();
    },
    methods: {
        renderMyChart() {
            this.renderChart({
                labels: this.chartLabels,
                datasets: this.chartData
            }, {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                      gridLines: {
                            drawBorder: false
                        },
                       
                    }],
                    xAxes: [{ 
                        gridLines: {
                            drawOnChartArea: false
                        }
                    }]
                }
            });
        }
    },
    watch: {
        chartData: {
            deep: true,
            handler() {
                this.renderMyChart();
            }
        },
        chartLabels() {
            this.renderMyChart();
        }
    }
}
</script>

