<template>
  <twoXDataTable :data="data" title="2X Deal Submissions" />
</template>

<script>
import twoXDataTable from './2xDataTable.vue'
export default {
  name: "twoxDealSubmissions",
  components: {
    twoXDataTable
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
}

</script>
