import { render, staticRenderFns } from "./country-detail.vue?vue&type=template&id=8df9c496&scoped=true"
import script from "./country-detail.vue?vue&type=script&lang=js"
export * from "./country-detail.vue?vue&type=script&lang=js"
import style0 from "./country-detail.vue?vue&type=style&index=0&id=8df9c496&prod&lang=css"
import style1 from "./country-detail.vue?vue&type=style&index=1&id=8df9c496&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8df9c496",
  null
  
)

export default component.exports