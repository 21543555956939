<template>
  <div>
    <h2 class="text-2xl font-bold leading-8 mb-8">Actual vs Budget YTD </h2>
    <div v-if="JSON.stringify(ytdData) !== '{}'" class="table-container">
      <table class="inner-table">
        <thead>
          <tr class="table-heading-row">
            <th>Item</th>
            <th>Actual</th>
            <th>Budget</th>
            <th>Variance</th>
            <th>Variance (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-content-row" v-for="(item, key) in ytdData" :key="key">
            <td :class="isBoldItem(item.name) ? 'font-bold' : ''">{{ item.name }}</td>
            <td>{{ item.Actual }}</td>
            <td>{{ item.Budget }}</td>
            <td>{{ item.Variance }}</td>
            <td>{{ item['Variance (%)'] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div class="py-2">
        <div>
          <p class="text-base font-semibold leading-normal text-gray-400 mb-1">
            Actual vs Budget YTD Data is not present!
          </p>
        </div>
      </div>
    </div>
    <h2 class="text-2xl font-bold leading-8 my-8">Actual vs Budget Monthly</h2>
    <div class="table-container mb-8" v-if="JSON.stringify(monthlyData) !== '{}'">
      <table class="inner-table">
        <thead>
          <tr class="table-heading-row">
            <th>Item</th>
            <th>Actual</th>
            <th>Budget</th>
            <th>Variance</th>
            <th>Variance (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-content-row" v-for="(item, key) in monthlyData" :key="key">
            <td :class="isBoldItem(item.name) ? 'font-bold' : ''">{{ item.name }}</td>
            <td>{{ item.Actual }}</td>
            <td>{{ item.Budget }}</td>
            <td>{{ item.Variance }}</td>
            <td>{{ item['Variance (%)'] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div class="py-2">
        <div>
          <p class="text-base font-semibold leading-normal text-gray-400 mb-1">
            Actual vs Budget Monthly Data is not present!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SalesTableUpdated",
  props: {
    ytdData: {
      type: Object,
      required: true,
    },
    monthlyData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    isBoldItem(item) {
      const boldItems = ["Net Revenue", "Gross Profit", "Net Income from Operating Activities (EBITDA)", "Net revenue YTD", "Gross Profit YTD", "Net Income from Operating Activities (EBITDA) YTD", 'Net revenue'];
      return boldItems.includes(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #E1E3E9;

  .inner-table {
    width: 100%;

    th,
    td {
      border: 1px solid #E1E3E9;
    }

    .table-heading-row th {
      padding: 16px;
      gap: 32px;
      border-top: none;
      background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #2C2E35;

      &:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }

      &:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }

    .table-content-row td {
      border-bottom: none;
      height: 68px;
      padding: 16px 12px;
      font-family: Arial;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: #2C2E35;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .red {
    color: #CB2E00;
  }

  .green {
    color: #13A094;
  }
}

.font-bold {
  font-weight: 700 !important;
}
</style>
