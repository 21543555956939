<template>
    
  <div class="static">
     <!-- <img
      src="@/assets/images/background-image-overview.jpg"
      alt="background-image"
      class="object-contain h-64 no-print"
    /> -->
    <div style="display:flex; justify-content:space-between;">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-left: 285px;"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-right: 120px;"
      />
    </div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20" style="">
              <div class="flex flex-col item-center justify-center">
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <img
                    class="h-16 print:h-auto print:w-64"
                    src="@/assets/images/2X-assessment/2X-methodology.png"
                  />
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5">
                    2X Assessment Methodology
                  </h1>
                </div>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-left" style="margin:10px 25px;">
                  The <a href="https://www.2xcollaborative.org" target="_blank" style="color:#2d7ff9"><b>2X Global</b></a> is a global industry body that convenes the entire spectrum of capital providers and mobilizers to promote gender lens investing. The 2X Global is supporting the investment goals of the  <a href="https://www.2xchallenge.org" target="_blank" style="color:#2d7ff9"><b>2X Challenge</b></a> to increase the volume of impact of capital flowing towards gender-smart businesses.

                  <br><br>

                  The <a href="https://www.2xchallenge.org/2xcriteria" target="_blank" style="color:#2d7ff9"><b>2X Criteria</b></a> have quickly evolved as an industry standard for gender lens investing, providing clear and practical guidance and thresholds, some of which are sector-specific, for investments. To be eligible under the 2X Challenge, at least one criteria must be met by an investee within Leadership, Entrepreneurship, Employment, and Consumption. If an investee is a financial intermediary, the 2X Criteria apply to the institution itself and its portfolio. This 2X Assessment platform has been co-created by the 2X Global and Equilo and is directly aligned with the 2X Criteria.

                  <br><br>

                  As a 2X Global partner, the <a href="https://www.equilo.io/" style="color:#2d7ff9"><b>Equilo</b></a> platform provides a mechanism to streamline the 2X Assessment process for investors and investees, with an additional additive value of providing country- and sector-specific contextual analysis. The free, easy to complete self-assessment survey can be completed any time or requested by an investor for an investee to complete. All survey questions are utilized to determine if a potential investee is eligible to receive 2X qualified funding by meeting any of the criteria.

                  <br><br>

                  All assessment results for an individual investee are stored indefinitely and displayed only to users with a matching email ID. Investees who complete the survey may opt-in to having their details shared with potential investors and entered into a deal matchmaking database where potential investors may view the assessment results as they consider potential 2X investments. Opting in to become listed with 2X Global members' searchable 2X deal database to be viewed by potential investors is included as a paid upgrade feature with either <router-link to="/pricing/Gender-Smart-Company" style="color:#2d7ff9"><b>Gender Smart Company Pro</b></router-link> or <router-link to="/pricing/GLI-Investor" style="color:#2d7ff9"><b>Impact Investor Pro</b></router-link> subscription packages.  
                  <br><br>

                  Those who complete assessments may also choose to invite anyone from their organization, or investors, to view their 2X assessment results via email.

                  <br><br>

                  Assessment results should be utilized to understand potential 2X funding/ investment eligibility, learn about what thresholds are met or not, and can be utilized to set targets to improve or increase 2X eligibility. In addition, users may repeat the 2X assessment at any time, and view changes on the investee's personalized 2X assessment results dashboard in meeting 2X Criteria over time.

                  <br><br>

                  Because this is a self-assessment, without 3rd party validation, neither investor nor investee can automatically assume that they qualify for the 2X Challenge, as further considerations – especially intentionality and gender-lens ESG compliance – influence the final decision on 2X Challenge qualification.

                  <br><br>

                  To enhance the credibility and reliability of the 2X Assessment, third-party validation is offered to verify the responses to the self-assessment, which may be provided by Equilo or another credible validation service or technical service provider. Contact us at <a style="color: #201747;" href="mailto:hello@equilo.io">hello@equilo.io</a> to request validation services.
                </p>
              </div>
            </div>
            <div class="user-journey ml-20">
              <span>Assess your 2X investment eligibility</span>
              <img src="@/assets/images/1-Assess Your 2X Investment Eligibility.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import auth from "@/helpers/auth";   
import pageLoader from "@/components/page-loader.vue";
export default {
    name: "newtwoxmethodology",
    data: function() {
     return {
         active:false,
         endofcreated:false
     }
    },
    components: {
      pageLoader
    },
    
    async created(){
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
        {
          name: "2X Assessment Methodology",
          url:"/2X-Ecosystem/Methodology",
        },
        
    ]);
        this.endofcreated=true;
    },  
    async mounted() {
        this.user = await auth.me().catch(() => {
            console.log("Error login");
        });
        // if (!this.user.organization.access2X) {
        //   this.$router.push({ path: "/" });
        // }
    },
};
</script>
<style>
.user-journey {
  padding: 25px;
}
.user-journey img {
  border-radius: 20px;
  margin-top: 20px;
}
.user-journey span {
  font-size: 25px;
  font-weight: 600;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}
</style>
