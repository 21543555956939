<template>
  <div
    class="theme-badge flex items-center gap-2"
    :style="
      'border-color: ' +
      ((score != 'Failed' && score) ? cert_categories[score]?.darkColor : '#ccc') +
      '; background: ' +
      ((score != 'Failed' && score) ? cert_categories[score]?.lightColor : '')
    "
  >
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z"
        :fill="(score != 'Failed' && score) ? cert_categories[score]?.darkColor : '#ccc'"
      />
    </svg>
    <div>
      <p
        class="font-bold"
        v-if="(score != 'Failed' && score)"
        :style="
          'color:' + ((score != 'Failed' && score) ? cert_categories[score]?.darkColor : '#999')
        "
      >
        {{ cert_categories[score]?.name }}
      </p>
      <p class="font-black" :style="'color:' + ((score != 'Failed' && score) ? '' : '#999')">
        {{ badgeName }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ThemeBadgesBox",
  data() {
    return {
      cert_categories: {
        Good: {
          name: "Good",
          darkColor: "#FF501C",
          lightColor: "#FFECE8",
        },
        Advanced: {
          name: "Advanced",
          darkColor: "#E8AD17",
          lightColor: "#FFF8E0",
        },
        "Best in Class": {
          name: "Best in Class",
          darkColor: "#4DC9BE",
          lightColor: "#E5FAFA",
        },
      },
    };
  },
  props: {
    score: {
      type: [String],
    },
    badgeName: {
      type: [String],
    },
  },
};
</script>
<style lang="scss" scoped>
.theme-badge {
  border: 2px solid;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  font-size: 14px;
  width: fit-content;

  .font-black {
    color: #2c2e35;
    font-weight: 400;
  }

  .light-grey {
    color: #ccc;
  }

  .dark-grey {
    color: #999;
  }
}

.tooltip {
  position: relative;
}

.tooltip .climate-tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  background-color: #201747;
  width: 250px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  right: -72px;
  top: 70px;
}

.tooltip:hover .climate-tooltiptext {
  visibility: visible;
}

.download-right-arrow-button {
  margin-top: 45px;
}
</style>
