<template>
  <div class="flex flex-row w-full bg-gray-100">
    <sidebar></sidebar>
    <div class="flex flex-col px-10 py-10 w-full main_container" v-if="user">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" style="padding: 0 1rem;">
        <div style="width: 1000px;margin: auto;">
          <h1>GESI API Documentation Overview</h1>
          <br />

          <!-- Steps to follow -->

          <h2>Steps to follow</h2>
          <br />
          <p>1. Open <a href="https://www.postman.com/" target="_blank" style="color: blue"
              class="cursor-pointer">Postman</a></p>
          <p>2. Select api method from dropdown and use api url as given in the description</p>
          <p>3. Open Headers tab, pass your organization’s api key as given in images</p>
          <p>4. Open Authorization tab,
          <ul style="padding: 0">
            <li style="padding: 0">Select Basic Auth as type</li>
            <li style="padding: 0">Enter equilo account email in username and password in password tab as given in
              images</li>
          </ul>
          </p>
          <p>5. Click on Send</p>
          <br /><br />

          <!-- Country Wise Data for GBV -->
          <h2>1. GESI Contextual Score.</h2>
          <ul>
            <li><span>Description:</span> Get GESI Scores Sector and Country wise </li>
            <li><span>Method:</span> GET</li>
            <li><span>Request Headers:</span> apikey: '{{ user.organization.secretKey ? user.organization.secretKey :
            'secXXXXXXX'}}'</li>
            <li>
              <span>Authorization: </span>
              <ul class="inner_ul">
                <li>
                  <span>Type:</span> ‘select <strong> Basic Auth </strong> as authorization type’
                </li>
                <li><span>Username:</span> ‘enter your equilo account email’</li>
                <li>
                  <span>Password:</span> ‘enter your equilo account password’
                </li>
              </ul>
            </li>
            <li>
              <span>API URL:</span> https://api.equilo.io/v1/gesi/country/:iso2
            </li>
            <li>
              <span>Example:</span>
              <ul class="inner_ul">
                <li><span></span>If country is Angola then its iso2 is AO</li>
                <li>
                  <span></span>https://api.equilo.io/v1/gesi/country/AO
                </li>
              </ul>
            </li>
            <li>
              <span>Request Query Parameters</span>
              <ul class="inner_ul">
                <li>sectors</li>
                <li>Skip (pagination)</li>
                <li>Limit (pagination)</li>
                <li>
                  <span>Example -</span> If we want particular sector for gesi score.
                </li>
                <li>
                  https://api.equilo.io/v1/gesi/country/BI?skip=1&limit=5{{`&`}}sector=BASE
                </li>
              </ul>
              <br />
            </li>
            <li>
              <h5>All Country wise with sector</h5>
              <img src="@/assets/images/Gesi_Api_Settings/country-with-sector.png" />
              <br />
            </li>
            <li>
              <h5>Country wise with all sector: </h5>
              <br />
              <img src="@/assets/images/Gesi_Api_Settings/country-with-all-sector.png" />
              <br />
            </li>
            <li>
              <h5>Country wise with specific sector: </h5>
              <br />
              <img src="@/assets/images/Gesi_Api_Settings/country-with-specific-sector.png" />
              <br />
            </li>
          </ul>
          <br /><br />
          <br />


          <!-- Authorization -->

          <h1>Authorization</h1>
          <br /><br />
          <img
            src="@/assets/images/Gbv_Api_Settings/authorization-postman.png"
          />
          <br />

          <!-- API Authentication Errors -->

          <h1>API Authentication Errors</h1>
          <br /><br />
          <div class="error_message_example mb-10">
            <div class="code_text">
              <p>{</p>
              <p class="ml-5">status: false,</p>
              <p class="ml-5">message: 'Something went wrong please try again later',</p>
              <p class="ml-5">code: "INVALID_REQUEST"</p>
              <p>}</p>
            </div>
          </div>

          <h3>HTTP STATUS CODES</h3>
          <br />
          <p>The standard http status codes used are:</p>
          <br />
          <ul>
            <li><span>2XX</span> The response code in this range implies the API call has succeeded.</li>
            <li><span>3XX</span> This range is not used currently.</li>
            <li><span>4XX</span> response codes indicate failure due to wrong input (i.e, client) side. As the usable
              list of http codes in the 4xx range is limited we predominantly use the 400 ( BAD REQUEST ) error code for
              client-side errors.</li>
            <li><span>5XX</span> response codes indicate API request was valid but failed due to issues on the Equilo
              server.</li>
          </ul>

          <!-- <p>1.	Invalid Request or Server Timeout (500):
          <br/><span>a.	{status: false, message: 'Something went wrong please try again later', code: "INVALID_REQUEST"}</span></p>

          <p>2.	Invalid API Key (404):
            <br/><span>a.	{status: false, message: 'Please Provide API Key', code: "INVALID_API_KEY"}</span></p>

          <p>3.	API Access denied (401):
            <br/><span>a.	{status: false, message: 'API Access denied', code: "ACCESS_DENIED"};</span></p>

          <p>4.	Unauthorized Secret Key (401):
            <br/><span>a.	{status: false, message: 'Unauthorized Key', code: "UNAUTHORIZED_KEY"}</span></p>

          <p>5.	Authorization not provided (401):
            <br/><span>a.	{status: false, message: 'Please provide auth credentials', code: "AUTHORIZATION_NOT_FOUND"}</span></p>

          <p>6.	Username/Password not provided (401):
            <br/><span>a.	{status: false, message: 'Username or Password not provided', code: "USERNAME_PASSWORD_NOT_FOUND"}</span></p>

          <p>7.	User not available (401):
            <br/><span>a.	{status: false, message: 'User not available under given organization', code: "USER_NOT_FOUND"}</span></p>

          <p>8.	Invalid password (401):
            <br/><span>a.	{status: false, message: 'Please provide valid password', code: "INVALID_PASSWORD"}</span>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import sidebar from "@/components/sidebar.vue";
export default {
  data() {
    return {
      user: [],
    };
  },
  components: {
    sidebar,
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    this.$store.commit("isFreeState", true);
    if (!this.user.organization.secretKey || !this.user.organization.productApiAccess.includes('GESI')) {
      this.$router.push({ path: "/settings/account" })
    }else{
      this.$store.commit("setBreadcrumbs", [
        { name: "Settings", url: "/settings/account" },
        { name: "GESI API Settings", url: "/settings/gesi_api_settings" },
      ]);
    }
  },
}; 
</script>

<style lang="scss" scoped>
.main_container {
  padding-top: 100px;

  h1 {
    color: #201747;
    font-size: 3em;
    font-weight: 600;
    text-align: center;
  }

  h2 {
    color: #201747;
    font-size: 2em;
    font-weight: 500;
  }

  h3 {
    color: #201747;
    font-size: 1.5em;
    font-weight: 500;
  }

  h5 {
    color: #201747;
    font-size: 1.5em;
    font-weight: 500;
  }

  img {
    width: 900px;
  }

  ul {
    list-style-type: disc;
    padding: 1rem;
    font-size: 1em;
    margin-left: 1rem;

    li {
      span {
        font-weight: bold;
      }

      padding: 5px;
    }

    .inner_ul {
      padding: 0 1rem;
    }
  }

  p {
    span {
      background: yellow;
      margin-left: 1rem;
    }
  }

  .error_message_example {
    background: black;
    color: red;
    font-weight: 600;

    .code_text {
      padding: 6% 8%;
    }
  }
}
</style>
