<template>
    <div class="table-container rounded-lg">
        <div v-for="(section, sIndex) in tableData" :key="sIndex">
            <table class="inner-table">
                <thead class="bg-[#F4F6F9] h-14 p-4">
                    <tr class="table-content-row">
                        <th class="p-4 cursor-pointer w-[880px]">
                            <div class="flex justify-between items-center">
                                <div>
                                    {{ section.rowHeading }}
                                </div>
                                <div></div>
                            </div>
                        </th>
                        <th class="text-center" v-for="column in section.columns" :key="column">{{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody v-if="['Governance', 'Risk & Opportunities'].includes(section.rowHeading)">
                    <tr class="text-[#2C2E35] text-base leading-6" v-for="(company, cIndex) in section.companies"
                        :key="cIndex">
                        <td class="p-4">{{ company.name }}
                        </td>
                        <td class="p-4 text-center" v-for="(data, dIndex) in company.data" :key="dIndex">
                            <img class=" text-white rounded-lg h-6 w-6"
                                :src="data ? require('@/assets/images/Rightcircle.svg') : require('@/assets/images/Crosscircle.svg')" />
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="['Targets & Performance'].includes(section.rowHeading)">
                    <tr class="text-[#2C2E35] text-base leading-6" v-for="(company, cIndex) in section.companies"
                        :key="cIndex">
                        <td class="p-4">{{ company.name }}
                        </td>
                        <td class="p-4 text-center" v-for="(data, dIndex) in company.data" :key="dIndex">
                            <img v-if="typeof (data) == 'boolean'" class=" text-white rounded-lg h-6 w-6"
                                :src="data ? require('@/assets/images/Rightcircle.svg') : require('@/assets/images/Crosscircle.svg')" />
                            <p v-if="typeof (data) == 'string'">
                                {{ data }}
                            </p>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="['Emissions Methodology,Data, Breakdown'].includes(section.rowHeading)">
                    <tr class="text-[#2C2E35] text-base leading-6" v-for="(company, cIndex) in section.companies"
                        :key="cIndex">
                        <td class="p-4">{{ company.name }}
                        </td>
                        <td class="p-4 text-center" v-for="(data, dIndex) in company.data" :key="dIndex">
                            <img v-if="typeof (data) == 'boolean'" class=" text-white rounded-lg h-6 w-6"
                                :src="data ? require('@/assets/images/Rightcircle.svg') : require('@/assets/images/Crosscircle.svg')" />
                            <p
                                v-if="typeof (data) == 'string' && data !== 'What standard, proposal, or methodologies was/were used to guide data collection and measurement?'">
                                {{ data }}
                            </p>
                            <div class="text-left"
                                v-if="typeof (data) == 'string' && data === 'What standard, proposal, or methodologies was/were used to guide data collection and measurement?'">
                                <div v-if="data && data.split(' ').length > 7">{{ data.slice(0, 50) }}...</div>
                                <div v-else>{{ data }}</div>
                                <button
                                    class="text-[#2177C1] outline-none text-sm font-bold leading-5 flex items-center mt-2"
                                    v-if="data">Read More
                                    <span>
                                        <svg class="ml-1 w-4 h-2" viewBox="0 0 14 8" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.3404 3.16602H0.332031V4.83268H10.3404V7.33268L13.6654 3.99935L10.3404 0.666016V3.16602Z"
                                                fill="#2177C1" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>


                <tbody v-if="['General Education, Gender & Climate'].includes(section.rowHeading)">
                    <tr class="text-[#2C2E35] text-base leading-6" v-for="(company, cIndex) in section.companies"
                        :key="cIndex">
                        <td class="p-4">{{ company.name }}
                        </td>
                        <td class="p-4 text-center" v-for="(data, dIndex) in company.data" :key="dIndex">
                            <img v-if="typeof (data) == 'boolean'" class=" text-white rounded-lg h-6 w-6"
                                :src="data ? require('@/assets/images/Rightcircle.svg') : require('@/assets/images/Crosscircle.svg')" />
                            <p
                                v-if="typeof (data) == 'string' && data !== 'What standard, proposal, or methodologies was/were used to guide data collection and measurement?'">
                                {{ data }}
                            </p>
                            <div class="text-left"
                                v-if="typeof (data) == 'string' && data === 'What standard, proposal, or methodologies was/were used to guide data collection and measurement?'">
                                <div v-if="data && data.split(' ').length > 7">{{ data.slice(0, 50) }}...</div>
                                <div v-else>{{ data }}</div>
                                <button
                                    class="text-[#2177C1] outline-none text-sm font-bold leading-5 flex items-center mt-2"
                                    v-if="data">Read More
                                    <span>
                                        <svg class="ml-1 w-4 h-2" viewBox="0 0 14 8" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.3404 3.16602H0.332031V4.83268H10.3404V7.33268L13.6654 3.99935L10.3404 0.666016V3.16602Z"
                                                fill="#2177C1" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>



                <tbody v-if="['Engagement'].includes(section.rowHeading)">
                    <tr class="text-[#2C2E35] text-base leading-6" v-for="(company, cIndex) in section.companies"
                        :key="cIndex">
                        <td class="p-4">{{ company.name }}
                        </td>
                        <td class="p-4 text-center" v-for="(data, dIndex) in company.data" :key="dIndex">
                            <img v-if="typeof (data) == 'boolean'" class=" text-white rounded-lg h-6 w-6"
                                :src="data ? require('@/assets/images/Rightcircle.svg') : require('@/assets/images/Crosscircle.svg')" />
                            <p v-if="typeof (data) == 'string'">
                                {{ data }}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssessmentClimateTable',
    data() {
        return {
            tableData: [
                {
                    rowHeading: 'Governance',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: 'Your organization has set up governance, at any level, with responsibility for climate-related issues', data: [true, true, true, true] },
                        { name: 'The highest management level or committee with responsibility for climate-related issues should be identified within the organizational structure', data: [true, true, true, true] },
                        { name: 'Incentives are provided to employees and/or company leadership for the management of climate-related activities, including attainment of targets', data: [false, false, false, false] },
                    ]
                },
                {
                    rowHeading: 'Risk & Opportunities',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: 'Your organization has a process for identifying, assessing, and responding to climate-related risks and opportunities', data: [true, true, true, true] },
                        { name: 'You have identified inherent climate-related opportunities that could potentially have a substantial financial or strategic impact on your business', data: [true, true, true, true] },
                        { name: 'You have identified inherent climate-related opportunities that could potentially have a substantial financial or strategic impact on your business', data: [true, true, true, true] },
                    ]
                },
                {
                    rowHeading: 'Targets & Performance',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: "What was your organization's energy consumption (in MWh) this year?", data: ['6,123', '6,123', '6,123', '6,123'] },
                        { name: 'Has your organization set a target(s) for greenhouse gas emissions?', data: [false, false, false, false] },
                        { name: 'Do you have plans to increase low-carbon energy consumption or production?', data: ['6,123', '6,123', '6,123', '6,123'] },
                        { name: 'Did you have emissions reduction initiatives that were active within the reporting year?', data: [false, false, false, false] },
                        { name: "What was your organization's consumption or production of renewable energy (in MWh) this year?", data: [false, false, false, false] },
                    ]
                },
                {
                    rowHeading: 'Emissions Methodology,Data, Breakdown',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: "Have you calculated your organization's (direct or indirect) greenhouse gas emissions? (Scope 1, 2 or 3)", data: ['6,123', '6,123', '6,123', '6,123'] },
                        { name: "What were your Scope 1 emissions (in CO2eq)?", data: ['6,123', '6,123', '6,123', '6,123'] },
                        { name: "What was the base year of the calculation, and what was the total emissions (in CO2eq)?", data: ['6,123', '6,123', '6,123', '6,123'] },
                        { name: "What were your Scope 2 emissions (in CO2eq)?", data: ['6,123', '6,123', '6,123', '6,123'] },
                        { name: "What were your Scope 3 emissions (in CO2eq)?", data: ['6,123', '6,123', '6,123', '6,123'] },
                        { name: "What standard, proposal, or methodologies was/were used to guide data collection and measurement?", data: ['What standard, proposal, or methodologies was/were used to guide data collection and measurement?', 'What standard, proposal, or methodologies was/were used to guide data collection and measurement?', 'What standard, proposal, or methodologies was/were used to guide data collection and measurement?', 'What standard, proposal, or methodologies was/were used to guide data collection and measurement?'] },
                        { name: "How does global emissions (Scope 1 and 2) from this year compare to previous reporting year?", data: ['6,123', '6,123', '6,123', '6,123'] },
                    ]
                },
                {
                    rowHeading: 'Engagement',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: "Do you engage with any members of your value chain (for instance, your suppliers, partners, or customers) on climate change related issues?", data: [false, false, false, false] },
                    ]
                },
                {
                    rowHeading: 'General Education, Gender & Climate',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: "Does any of your education cover leadership, problem-solving, and financial literacy topics?", data: [true, true, true, true] },
                        { name: 'Does any of your education cover climate change-related topics or learnings?', data: [true, true, true, true] },
                        { name: 'Do you have plans to increase low-carbon energy consumption or production?', data: ['68%', '68%', '68%', '68%'] },
                        { name: 'What percentage of students complete / graduate from your education program, based on those who enrolled?', data: ['98%', '98%', '98%', '98%'] },
                        { name: "What percentage of female & male students complete / graduate from the education program?", data: ['4', '4', '4', '4'] },
                    ]
                },

            ]
        };
    },
    methods: {
        toggleVisibility(index) {
            this.tableData[index].isVisible = !this.tableData[index].isVisible;
        }
    }
};
</script>
<style lang="scss" scoped>
.table-container {
    gap: 20px;

    .red {
        color: #CB2E00;
    }

    .green {
        color: #13A094;
    }
}

.inner-table {
    width: 100%;

    th,
    td {
        border: 1px solid #E1E3E9;
    }

    .table-heading-row {
        th {
            padding: 16px;
            gap: 32px;
            border-top: none;
            background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
            font-family: Arial;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #2C2E35;
        }

        th:first-child {
            border-top-left-radius: 16px;
            border-left: none;
        }

        th:last-child {
            border-top-right-radius: 16px;
            border-right: none;
        }
    }

    .table-content-row {
        td {
            height: 85px;
            // padding: 16px 12px 16px 12px;
            gap: 1px;
            font-family: Arial;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #2C2E35;

            img {
                width: 32px;
                height: 32px;
            }
        }

        td:first-child {
            border-left: none;
        }

        td:last-child {
            border-right: none;
        }

        .unaudit-section {
            span {
                gap: 8px;
            }
        }
    }
}

.table-content-row {
    td {
        border-bottom: none;
        height: 68px;
        // padding: 16px 12px 16px 12px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2C2E35;

        img {
            width: 32px;
            height: 32px;
        }
    }

    td:first-child {
        border-left: none;
    }

    td:last-child {
        border-right: none;
    }

    .unaudit-section {
        span {
            gap: 8px;
        }
    }
}
</style>