<template>
   <div v-if="isOpen" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="fixed inset-0 bg-[#19486A] bg-opacity-50 transition-opacity"></div>
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 -mt-3 sm:align-middle sm:w-full max-w-[50%] h-full max-h-[95vh] sm:p-4 border">
          <p class="font-medium px-4 pt-4">
            2X Global requires you to only publicly disclose the 2X Criteria you meet. However, you may opt-in to
            publicly disclose your full results, including those you do not currently meet. Please select the option you wish to
            publicly disclose here:
          </p>
          <ul class="list-disc px-4 pt-2 mb-4 space-y-2 mt-2">
            <li class="flex items-center font-semibold list-disc mb-1 text-sm">
              Only disclose 2X Criteria met 
              <div class="rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative ml-2">
                <input v-model="selectedOption" type="radio" name="radio"
                  class="checkbox appearance-none focus:outline-none border rounded-full border-gray-500 absolute cursor-pointer w-full h-full checked:bg-gray-400"
                  value="redacted" />
              </div>
            </li>
            <li class="flex items-center font-semibold text-sm">
              Disclose full results, including 2X Criteria not met
              <div class="rounded-full w-4 h-4 flex flex-shrink-0 justify-center items-center relative ml-2">
                <input v-model="selectedOption" type="radio" name="radio"
                  class="checkbox appearance-none focus:outline-none border rounded-full border-gray-500 absolute cursor-pointer w-full h-full checked:bg-gray-400"
                  value="detailed" />
              </div>
            </li>
          </ul>
          <div class="flex justify-between items-center border-t">
            <div></div>
            <button class="bg-[#19486A] font-bold text-white px-4 py-2 rounded-lg shadow mt-3"
              @click="closeModal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ScorecardModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedOption: 'redacted'
    }
  },
  watch: {
    selectedOption(newValue) {
      this.$emit('option-selected', newValue)
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  },
}
</script>
