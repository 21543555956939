<template>
  <div class="static">
    <div style="height:4rem;"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  "
                >
                  <!-- <img
                  class="h-16 print:h-auto print:w-64"
                  src="@/assets/images/2X-assessment/new2X-assessment.png"
                  /> -->
                  <h1
                    class="
                      text-5xl
                      tracking-tighter
                      font-semibold
                      text-center
                      print:text-7xl
                      ml-5
                    "
                  >
                    General Privacy Notice
                  </h1>
                </div>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  Notice about how we use your personal information
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We are the data controller of personal information about you.
                  We are: Equilo Inc. Our address is: 1658 N. Milwaukee Ave.
                  Suite 100-45, Chicago, IL 60647, USA.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  If you have any questions about this policy or the ways in
                  which we use your personal information, please contact our
                  team at dataprotection@equilo.io.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  You have certain rights, including how to get a copy of your
                  data, how to get it corrected or deleted, and how to complain.
                  <b>These rights are set out in more detail below.</b>
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We store and use your information for the purposes of managing
                  our website and the funding forms on it.
                </p>
                <br />
                <h2
                  class="
                    text-gray-600 text-2xl
                    tracking-tighter
                    font-medium
                    print:text-7xl
                  "
                >
                  The information we collect about you and why we collect it
                </h2>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Details of cookies that we use on our website may be found in
                  our <a href="/#/cookies-policy" target="_blank"><b>Cookies Policy</b></a
                  >.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  As part of your visit to our website, you may choose to
                  complete a 2X assessment form to establish whether you are
                  eligible for funding. In completing this form, you will
                  provide us with certain information about your organisation,
                  and individuals within it. If you do, we collect slightly
                  different information from you depending upon whether you are
                  filling the form in on behalf of a corporate entity, a fund,
                  or a financial institution. In each case, we do so to
                  establish whether you are eligible for funding. The
                  information collected for each category is set out below.
                </p>
                <br />
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc"
                >
                  <li class="heading-li">
                    Corporates
                    <ul
                      class="
                        text-gray-600
                        print:text-4xl print:mt-10
                        font-medium
                      "
                    >
                      <li>
                        ◦ contact name, title, email address, and phone number
                        for the organization;
                      </li>
                      <li>
                        ◦ contact name, title, email address, and phone number
                        of the person authorized to enter into agreements on
                        behalf of the organization;
                      </li>
                      <li>◦ documents validating women’s shares;</li>
                      <li>◦ documents validating founding by women;</li>
                      <li>
                        ◦ documents validating the number of women senior
                        managers at the organization;
                      </li>
                      <li>
                        ◦ documents validating active women Board or Investment
                        Committee Members;
                      </li>
                      <li>◦ documents validating number of women employees;</li>
                      <li>◦ details of investors; and</li>
                      <li>
                        ◦ the name, employer and email address of anyone that
                        requested that you fill in the form.
                      </li>
                      <li></li>
                      <li></li>
                    </ul>
                  </li>
                  <br />
                  <li class="heading-li">
                    Funds
                    <ul
                      class="
                        text-gray-600
                        print:text-4xl print:mt-10
                        font-medium
                      "
                    >
                      <li>
                        ◦ contact name, title, email address, and phone number
                        for the fund;
                      </li>
                      <li>
                        ◦ contact name, title, email address, and phone number
                        of the person authorized to enter into agreements on
                        behalf of the fund;
                      </li>
                      <li>◦ documents validating women’s shares;</li>
                      <li>◦ documents validating founding by women;</li>
                      <li>
                        ◦ documents validating the number of women with
                        investment responsibility at the fund;
                      </li>
                      <li>
                        ◦ documents validating active women Investment Committee
                        Members;
                      </li>
                      <li>
                        ◦ documents validating the number of women employees;
                        and
                      </li>
                      <li>
                        ◦ the name, employer and email address of anyone that
                        requested that you fill in the form.
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li class="heading-li">
                    Financial Institution (FI)
                    <ul
                      class="
                        text-gray-600
                        print:text-4xl print:mt-10
                        font-medium
                      "
                    >
                      <li>
                        ◦ contact name, title, email address, and phone number
                        for the FI;
                      </li>
                      <li>
                        ◦ contact name, title, email address, and phone number
                        of the person authorized to enter into agreements on
                        behalf of the FI;
                      </li>
                      <li>◦ documents validating women’s shares;</li>
                      <li>◦ documents validating founding by women;</li>
                      <li>
                        ◦ documents validating the number of women senior
                        managers at the FI;
                      </li>
                      <li>
                        ◦ documents validating active women Board Members and
                        Investment Committee Members;
                      </li>
                      <li>
                        ◦ documents validating the number of women employees;
                      </li>
                      <li>◦ details of investors; and</li>
                      <li>
                        ◦ the name, employer and email address of anyone that
                        requested that you fill in the form.
                      </li>
                    </ul>
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  In addition, where you create an account with us as well, we
                  will also collect and process the following information:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: disc"
                >
                  <li>
                    your name, email address, and a password for your account.
                    We use this information for account management purposes.
                  </li>
                </ul>
                <br />
                <h2
                  class="
                    text-gray-600 text-2xl
                    tracking-tighter
                    font-medium
                    print:text-7xl
                  "
                >
                  The legal basis on which we collect and use your personal
                  information
                </h2>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We process this information on the basis of our legitimate
                  interests:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    • we have a legitimate interest in promoting and supporting
                    the distribution of “2X Challenge” funding to further the
                    development of gender equality and social inclusion;
                  </li>
                  <li>
                    • to be able to do so, we need to obtain this information in
                    order to assess whether your organisation would be eligible
                    for “2X Challenge” funding; and
                  </li>
                  <li>
                    • the processing that we undertake is limited and is only
                    for the purpose of assessing such eligibility.
                  </li>
                </ul>
                <br />
                <h2
                  class="
                    text-gray-600 text-2xl
                    tracking-tighter
                    font-medium
                    print:text-7xl
                  "
                >
                  How we share your personal information
                </h2>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We may share the personal information that you give us in the
                  voluntary 2X form with the following organisations (or types
                  of organisation) for the following purposes.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Organisation / type of organisation:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    • 2X Global Limited and other potential investors,
                    including funds, grant makers, and other third-party
                    technical advisory service providers supporting investors,
                    funds, grant makers.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Purpose:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    • to be included in a searchable database that potential
                    investors may view and search;
                  </li>
                  <li>
                    • to be contacted by investors regarding investment or
                    partnership opportunities; and/or
                  </li>
                  <li>
                    • your data will be used on an anonymous basis for the
                    purposes of aggregated studies, research, and benchmarking.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Each of the above three uses is set out in our 2X form, and
                  there is an “opt-in” if you wish to allow us to use your data
                  in the ways described. If you wish to change these preferences
                  at any time, please visit your account settings within
                  app.equilo.io to change these permissions or contact us at
                  dataprotection@equilo.io and we can amend the settings on your
                  behalf.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We may also share your personal information with third parties
                  who provide services to us to the extent that it is necessary
                  for us to do so.
                </p>
                <br />
                <h2
                  class="
                    text-gray-600 text-2xl
                    tracking-tighter
                    font-medium
                    print:text-7xl
                  "
                >
                  How long we keep your personal information
                </h2>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We will store your data for approximately six years. Specific
                  personal and organizational identifying data will be erased
                  after that time period, unless you explicitly opt into further
                  annual listing renewals for the subject of maintaining
                  information within the database for exposure to investors, or
                  for your own or investor’s long-term monitoring. After this
                  time period, Equilo will only store anonymized data for the
                  purposes of research and learning that will contribute to
                  field building of gender lens investment.
                </p>
                <br />
                <h2
                  class="
                    text-gray-600 text-2xl
                    tracking-tighter
                    font-medium
                    print:text-7xl
                  "
                >
                  How we transfer your personal information outside Europe
                </h2>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We are a United States company, headquartered in the United
                  States. If you are located outside the United States, your
                  information is collected in your country and then transferred
                  to the United States or to another country in which we (or our
                  affiliates or service providers) operate.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  In the case of transfers out of the UK subject to UK data
                  protection law, the UK government has decided that particular
                  countries ensure an adequate level of protection of personal
                  data (known as an ‘adequacy regulation’). Where a country
                  benefits from an adequacy regulation, then we will rely on it
                  for transfers from the UK to that country. Where a country
                  does not, then we will rely on legally-approved standard data
                  protection clauses. If we cannot or choose not to continue to
                  rely on either of those mechanisms at any time, we will not
                  transfer your personal data outside the UK unless we can do so
                  on the basis of an alternative mechanism or exception provided
                  by UK data protection law and reflected in an update to this
                  notice.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  In the case of transfers out of the EEA subject to EEA data
                  protection laws, the European Commission has decided that
                  particular countries ensure an adequate level of protection of
                  personal data (known as an ‘adequacy decision’). Where a
                  country benefits from an adequacy regulation, then we will
                  rely on it for transfers from the EEA to that country. Where a
                  country does not, then we will rely on legally-approved
                  standard data protection clauses. If we cannot or choose not
                  to continue to rely on either of those mechanisms at any time,
                  we will not transfer your personal data outside the EEA unless
                  we can do so on the basis of an alternative mechanism or
                  exception provided by EEA data protection law and reflected in
                  an update to this policy.
                </p>

                <h2
                  class="
                    text-gray-600 text-2xl
                    tracking-tighter
                    font-medium
                    print:text-7xl
                  "
                >
                  <br />
                  <p
                    class="text-gray-600 print:text-2xl print:mt-10 font-medium"
                  >
                    Automated decisions we take about you
                  </p>
                </h2>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We do not make automated decisions using this information.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  YOUR RIGHTS
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  You have a number of rights over your personal information,
                  which are:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    • the right to make a complaint to a supervisory authority
                    if you are unhappy about the way your personal data is being
                    used – in the UK please refer to the ICO’s website for
                    further information about this
                    <a href="https://ico.org.uk/" target="_blank"
                      >(https://ico.org.uk/)</a
                    >
                    or to that of one of the supervisory authorities in the EEA
                    (see: Our Members | European Data Protection Board
                    (europa.eu));
                  </li>
                  <li>
                    • the right to ask us what personal information about you we
                    are holding and to have access to a copy of your personal
                    information;
                  </li>
                  <li>
                    • the right to ask us to correct any errors in your personal
                    information;
                  </li>
                  <li>
                    • the right, in certain circumstances such as where our use
                    of your personal information is based on your consent and we
                    have no other legal basis to use your personal information,
                    to ask us to delete your personal information;
                  </li>
                  <li>
                    • the right, in certain circumstances such as where we no
                    longer need your personal information, to request that we
                    restrict the use that we are making of your personal
                    information;
                  </li>
                  <li>
                    • the right, in certain circumstances, to ask us to review
                    and explain our legitimate interests to you; and
                  </li>
                  <li>
                    • the right, where our use of your personal information is
                    carried out for the purposes of an agreement with us and is
                    carried out by automated means, to ask us to provide you
                    with a copy of your personal information in a structured,
                    commonly-used, machine-readable format.
                  </li>
                </ul>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  CHANGES TO OUR PRIVACY POLICY
                </h1>
                <br />
                <p
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium"
                  style="margin-bottom: 2rem"
                >
                  We keep our privacy policy under regular review and will
                  update it from time to time to make sure it remains up-to-date
                  and accurate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "dataPrivacy",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      userEmail: null,
      isReadMore: false,
    };
  },
  components: {
    pageLoader,
  },
  methods: {},
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "Data Privacy",
        url: "/dataprivacy",
      },
    ]);
    this.endofcreated = true;
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
  },
};
</script>

<style scoped>
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}
</style>
<style scoped>
.heading-li {
  font-size: 20px;
}
li {
  font-size: 16px;
}
a{
  color: #40a9ff;
}
</style>
