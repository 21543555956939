<template>
  <div class="static assementPageStyle" id="contentForm">
    <transition name="fadeIn" mode="out-in">
      <div id="loaded" class="container" v-if="roiDataFromParent && roiDataFromParent != ''">
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyTitleWrepper">
            <img v-if="roiData.logo && roiData.logo[0] && roiData.logo[0].url" class="h-16 print:h-auto print:w-64"
              :src="roiData.logo[0].url" style="height: 10rem" crossorigin="anonymous" />
            <h1 class="text-6xl ml-10 tracking-tighter font-medium print:text-7xl">
              {{ roiData.organizationName }}'s Gender ROI™ Assessment
            </h1>
          </div>
        </div>
        <transition name="fadeIn" mode="out-in">
          <div id="loaded" key="content">
            <!-- Assement Page Wrepper -->
            <div class="AssementPageWrepper" style="float:none !important">
              <div class="OverviewBlock flex">
                <div class="OverviewBlock_left_side mr-5">
                  <div class="equal_strength">
                    <div class="OverviewTitle">
                      <h3 class="font-bold">{{ roiData.organizationName }}</h3>
                    </div>
                    <ul>
                      <li v-if="roiData.organizationType">
                        <span>Enterprise Type:
                          <b> {{ roiData.organizationType }} </b></span>
                      </li>
                      <li v-if="roiData.organizationType && roiData.enterpriseSize">
                        <span>Enterprise Size: <b>{{ roiData.enterpriseSize.split('(')[0] }}</b></span>
                      </li>
                      <li v-if="roiData.womworkforceTwo">
                        <span>Number of Employees :
                          <b>
                            {{ roiData.womworkforceTwo | mathOnlyRound }}</b></span>
                      </li>
                      <li v-if="roiData.countryHqText">
                        <span>Headquarters:
                          <b>{{ roiData.countryHqText }}</b></span>
                      </li>
                      <li v-if="roiSector">
                        <span>Sector: <b>{{ roiSector }}</b></span>
                      </li>
                    </ul>
                    <div class="twoxEligible_button flex twoxEligible_button_tooltip mb-2 mt-6" v-if="roiData.percentage == 100 || roiData.mostRecentSurveyData">
                      <div class="flex items-center">
                        <!-- <div style="position:relative;top:4px;" v-if="((roiData.organizationType && roiData.organizationType == 'On-lending facility'
                          || roiData.organizationType == 'Fund'
                          || roiData.organizationType == 'Institutional Investor')
                          && roiData.twoXMyPortfolio && roiData.twoXMyPortfolio != 'I will directly input aggregated information now')
                        ">
                          <img style="margin-left:-3px !important; width: 30px" src="@/assets/images/help_white.svg"
                            alt="background-image" />
                        </div> -->
                        <div>
                          <img src="@/assets/images/Rightcircle.svg" alt="background-image"
                            style="margin-top: 3px;margin-left:-3px !important; width: 30px"
                            v-if="roiData.twoXEligible == 'Yes' || roiData.twoXEligible" />
                          <img style="margin-top: 3px;border-radius:0; width: 30px"
                            src="@/assets/images/3rdPartyvalidated.svg" alt="background-image"
                            v-else />
                        </div>
                        <h2 class="font-bold text-2xl ml-4 cursor-pointer"
                          v-if="(roiData.twoXEligible == 'Yes' || roiData.twoXEligible)"
                          @click="redirectToTwoXAssess()">
                          2X Aligned</h2>
                        <h2 class="font-bold text-2xl ml-4" v-if="
                          (roiData.twoXEligible == 'No' || !roiData.twoXEligible)
                        ">Not 2X
                          Aligned</h2>
                      </div>
                      <!-- <span class="twoxEligible_button_hover_text"
                        v-if="(roiData.organizationType && roiData.organizationType == 'On-lending facility'
                        || roiData.organizationType == 'Fund'
                        || roiData.organizationType == 'Institutional Investor')
                        && roiData.twoXMyPortfolio && roiData.twoXMyPortfolio != 'I will directly input aggregated information now'">
                        We still need information about your portfolio companies in order to assess your 2X eligibility.
                        Our team will be in
                        touch with you to complete this, or you can contact us at <a href="mailto:support@equilo.io"
                          style="color:#599bd8;">support@equilo.io</a>.
                      </span> -->
                    </div>
                  </div>
                </div>
                <div class="OverviewBlock_right_side ml-5">
                  <div class="gender_roi_score">
                    <div class="OverviewTitle">
                      <h2 style="width: 530px">Gender ROI™ Score</h2>
                      <span></span>
                    </div>
                    <div class="mb-8">
                      <h1 class="font-bold text-5xl">{{ genderRoiScore }}</h1>
                    </div>
                    <div class="gender_roi_score_chart">
                      <k-progress style="width: 100%;margin: 0 !important;position: relative;top: 7px;"
                        :percent="((genderRoiScore * 100) / 6) | mathOnlyRound" :show-text="false" color="#2BD4C4">
                      </k-progress>
                      <img src="@/assets/images/roi/chart-bar.png" style="position: relative; width: 100%" />
                    </div>
                    <div class="finance_average_score mt-5" v-if="roiData.organizationType && roiData.organizationType == 'On-lending facility'
                    || roiData.organizationType == 'Fund'
                    || roiData.organizationType == 'Institutional Investor'">
                      <div class="OverviewTitle">
                        <h2 style="width: 700px" class="cursor-pointer" @click="redirectToFinancePillar">Financial
                          Processes, Products, & Services</h2>
                      </div>
                      <div class="mb-8">
                        <h1 class="font-bold text-5xl">{{ averageFinanceScore }}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-10 mb-10 spiderchart_main_container" id="country-sector-overview" style="float:none !important">
                <div class="OverviewBlock_left_side" v-if="country">
                  <div class="equal_strength">
                    <div class="OverviewTitle">
                      <h2 style="width: 335px">Country Overview</h2>
                      <span></span>
                    </div>
                    <div class="OverviewTitle" v-if="country.intro">
                      <h1 class="title-bold">{{ country.humanName }}</h1>
                    </div>
                    <div class="CountryOverviewText" v-if="country && Object.keys(country).length > 0">
                      <transition name="slide-fade" v-if="country.intro">
                        <item :text="country.intro" :country="country"></item>
                      </transition>
                    </div>
                  </div>
                </div>
                <div class="OverviewBlock_right_side before_page"
                  v-if="sector && sector.intro && Object.keys(sector).length > 0">
                  <div class="equal_strength">
                    <div class="CountryOverviewText">
                      <div class="OverviewTitle">
                        <h2 style="width: 290px">Sector Overview</h2>
                        <span></span>
                      </div>
                      <div class="CountryOverviewText">
                        <transition name="slide-fade">
                          <item :text="sector.intro" :country="sector" type="pdf_file"></item>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spiderchart_main_container mt-10 mb-10 before_page">
                <div class="OverviewTitle">
                  <h2 style="width: 960px">
                    Gender ROI™ Pillar Assessment Results
                  </h2>
                  <span></span>
                </div>
                <div class="spiderchart_content flex">
                  <div class="right_side">
                    <div class="spiderchartContent" id="chart">
                      <div class="relative">
                        <spiderchart v-if="roiData.scoreData.length == 1" :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40"
                          :details="roiData.scoreData[0]" :gesiScore="roiData.roiGesiScoreData"
                          :financialYear="roiData.scoreData[0].financialReportingYear ? roiData.scoreData[0].financialReportingYear : 'Latest Quarter'"
                          :gesiLabel="'GESI Contextual Score - ' + roiData.countryHqText" class="overflow-visible"
                          style="position: relative; height: 100%; width: 100%; right: 13%;"
                          :organizationType="roiData.organizationType"
                          :roiData="roiData.scoreData[0]">
                        </spiderchart>
                        <spiderchart-multiple v-else :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40"
                          :financialYear="roiData.scoreData[0].financialReportingYear ? roiData.scoreData[0].financialReportingYear : 'Latest Quarter'"
                          :financialYear1="roiData.scoreData[1].financialReportingYear ? roiData.scoreData[1].financialReportingYear : 'First Historical Year'"
                          :details="roiData.scoreData[0]" :detailsOld="roiData.scoreData[1]"
                          :gesiLabel="'GESI Contextual Score - ' + roiData.countryHqText" :gesiScore="roiData.roiGesiScoreData"
                          class="overflow-visible" style="position: relative; height: 100%; width: 100%; right: 13%;"
                          :organizationType="roiData.organizationType"
                          :roiData="roiData.scoreData[0]">
                        </spiderchart-multiple>
                        <template>
                          <!--Opportunity-->
                          <div class="Ind_Inv_Icon opportunity-icon" id="2uur">
                            <img src="../../assets/images/roi/opportunity.svg" alt="Indirect Investment" />
                            <span style="color:#2BB900"> Opportunity </span>
                          </div>

                          <!--Inclusion-->
                          <div class="Ind_Inv_Icon inclusion-icon" id="8uur">
                            <img src="../../assets/images/roi/inclusion.svg" alt="Indirect Investment" />
                            <span style="color:#00A269"> Inclusion </span>
                          </div>

                          <!--Resilince-->
                          <div class="Ind_Inv_Icon resilince-icon" id="10uur">
                            <img src="../../assets/images/roi/resilience.svg" alt="Indirect Investment" />
                            <span style="color:#0068CD;"> Resilience </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Domain Assessment Results -->
              <div class="domain_assessment_results_section">
                <div class="OverviewTitle">
                  <h2 style="width: 600px">
                    Domain Assessment Results
                  </h2>
                  <span></span>
                </div>
                <div class="domain_assessment_results_container flex">
                  <div class="spiderchart_container">
                    <div class="spiderchart_content">
                      <div>
                        <div class="left_side flex">
                          <div class="orange-dot mr-5"></div>
                          <!-- <template v-for="(year, index) in roiData.scoreData[0]" v-once> -->
                          <span class="font-bold"
                            v-if="roiData.scoreData[0].financialReportingYear">{{
                              roiData.scoreData[0].financialReportingYear
                            }}</span>
                          <span class="font-bold"
                            v-if="roiData.scoreData[0].financialReportingYear == ''">Latest Quarter</span>
                          <!-- </template> -->
                        </div>
                        <div class="flex dot_with_text" v-if="roiData.scoreData.length > 1">
                          <div class="blue-dot mr-5"></div>
                          <!-- <template v-for="(year, index) in roiData.scoreData[1]" v-once> -->
                          <span class="font-bold"
                            v-if="roiData.scoreData[1].financialReportingYear">{{
                              roiData.scoreData[1].financialReportingYear
                            }}</span>
                          <span class="font-bold"
                            v-if="roiData.scoreData[1].financialReportingYear == ''">First Historical Year</span>
                          <!-- </template> -->
                        </div>
                      </div>
                      <div class="" style="width:100%">
                        <div class="spiderchartContent" id="chart">
                          <div class="relative">
                            <domain-spiderchart v-if="roiData.domainAssesmnet.length == 1" :paddingL="40" :paddingR="40"
                              :paddingT="40" :paddingB="40" :details="roiData.domainAssesmnet[0]"
                              class="overflow-visible"
                              style="position: relative; height: 100%; width: 100%; right: 13%;"></domain-spiderchart>
                            <domain-spiderchart-multiple v-else :paddingL="40" :paddingR="40" :paddingT="40"
                              :paddingB="40" :details="roiData.domainAssesmnet[0]"
                              :detailsOld="roiData.domainAssesmnet[1]" class="overflow-visible"
                              style="position: relative; height: 100%; width: 100%; right: 13%;"></domain-spiderchart-multiple>
                            <template>
                              <!--Leadership-->
                              <div class="Ind_Inv_Icon leadership-icon" id="2uur">
                                <img src="../../assets/images/leadership.svg" alt="Indirect Investment" />
                                <span style="color:black;"> Leadership </span>
                              </div>

                              <!--Workforce-->
                              <div class="Ind_Inv_Icon workforce-icon" id="2uur">
                                <img src="../../assets/images/workforce.svg" alt="Indirect Investment" />
                                <span style="color:black;"> Workforce </span>
                              </div>

                              <!--Value Chain-->
                              <div class="Ind_Inv_Icon value-chain-icon" id="8uur">
                                <img src="../../assets/images/value_chain.svg" alt="Indirect Investment" />
                                <span style="color:black;"> Value Chain </span>
                              </div>

                              <!--Society-->
                              <div class="Ind_Inv_Icon society-icon" id="10uur">
                                <img src="../../assets/images/society.svg" alt="Indirect Investment" />
                                <span style="color:black"> Society </span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gender_roi_domain">
                    <div class="mb-5">
                      <h1 class="font-bold text-2xl">Gender ROI™ Domain</h1>
                    </div>
                    <div class="gender_roi_domain_taabs_container">
                      <div class="flex my-4 items-center" v-for="(dom) in roiData.domainAssesmnet[0]"
                        :key="'Leadership' + dom.name" v-show="dom.name == 'Leadership'">
                        <progress-bar :options="options" :value="
                          ((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                          | mathOnlyRound
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/leadership.svg" />
                        <h2 class="font-bold text-xl" style="position:relative;bottom:10px;">Leadership</h2>
                      </div>

                      <div class="flex my-4 items-center" v-for="dom in roiData.domainAssesmnet[0]"
                        :key="'Workforce' + dom.name" v-show="dom.name == 'Workforce'">
                        <progress-bar :options="options" :value="
                          ((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                          | mathOnlyRound
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/workforce.svg" />
                        <h2 class="font-bold text-xl" style="position:relative;bottom:10px;">Workforce</h2>
                      </div>

                      <div class="flex my-4 items-center" v-for="dom in roiData.domainAssesmnet[0]"
                        :key="'Chains' + dom.name" v-show="dom.name == 'Value Chains'">
                        <progress-bar :options="options" :value="
                          ((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                          | mathOnlyRound
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/value_chain.svg" />
                        <h2 class="font-bold text-xl" style="position:relative;bottom:10px;">Value Chain</h2>
                      </div>
                      <div class="flex my-4 items-center" v-for="dom in roiData.domainAssesmnet[0]"
                        :key="'Society' + dom.name" v-show="dom.name == 'Society'">
                        <progress-bar :options="options" :value="
                          ((Number(dom.BonusScore) + Number(dom.ThresholdScore)) / 6 * 100)
                          | mathOnlyRound
                        " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        <img class="mx-4" src="@/assets/images/society.svg" />
                        <h2 class="font-bold text-xl" style="position:relative;bottom:10px;">Society</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Parameters -->
              <div class="parameters_block before_page">
                <div class="parameters_section_first">
                  <div class="OverviewTitle">
                    <h2 style="width: 200px">Parameters</h2>
                    <span></span>

                  </div>
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/resilience.png" />
                          <h3 class="font-bold text-2xl">Resilience</h3>
                          <progress-bar :options="options" :value="
                            (roiData.resiliencePercentage * 100)
                            | mathOnlyRound
                          " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        </div>
                        <div class="flex right_side_flex"
                          :style="{ 'font-size': '13px', 'margin-right': user.languagePreference == 'Spanish' ? '-68px' : '' }">

                          <div class="flex dot_with_text">
                            <div class="orange-dot mr-2"></div>
                            <!-- <template v-for="(year, index) in roiData.scoreData[0]" v-once> -->
                            <span class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear">{{
                                roiData.scoreData[0].financialReportingYear
                              }}</span>
                            <span class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear == ''">Latest Quarter</span>
                            <!-- </template> -->
                          </div>
                          <div class="flex dot_with_text" v-if="roiData.scoreData.length > 1">
                            <div class="blue-dot mr-5"></div>
                            <!-- <template v-for="(year, index) in roiData.scoreData[1]" v-once> -->
                            <span class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear">{{
                                roiData.scoreData[1].financialReportingYear
                              }}</span>
                            <span class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear == ''">First Historical Year</span>
                            <!-- </template> -->
                          </div>
                          <div class="flex teal_dot_with_text tooltip" style="display: flex !important;">
                            <div class="teal-dot  mr-5"></div>
                            <template>
                              <span class="font-bold" style="width: 250px;">GESI Contextual Score - {{
                                roiData.countryHqText
                              }}</span>
                            </template>
                          </div>
                          <div class="flex dot_with_text">
                            <div class="yellow-dot mr-2 tooltip">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional activities
                                above and beyond what companies their size
                                normally do, bonus points are awarded, up to a
                                total of 1 whole point, on top of the score
                                calculated for the actions that are taken that are
                                expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section">
                      <RoiProgressBarsSectionPrint v-if="roiData.domainAssesmnet.length == 1"
                        :scoreData="roiData.scoreData[0].Resilience" :roiData="roiData"
                        :gesiScore="roiData.roiGesiScoreData.Resilience" />
                      <RoiProgressBarsSectionMultiplePrint v-else :scoreData="roiData.scoreData[0].Resilience"
                        :gesiScore="roiData.roiGesiScoreData.Resilience" :scoreDataOld="roiData.scoreData[1].Resilience"
                        :roiData="roiData" />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/opportunity.svg" />
                          <h3 class="font-bold text-2xl">Opportunity</h3>
                          <progress-bar :options="options" :value="
                            (roiData.opportunityPercentage * 100)
                            | mathOnlyRound
                          " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        </div>
                        <div class="flex right_side_flex"
                          :style="{ 'font-size': '13px', 'margin-right': user.languagePreference == 'Spanish' ? '-95px' : '' }">

                          <div class="flex dot_with_text">
                            <div class="orange-dot mr-2"></div>
                            <!-- <template v-for="(year, index) in roiData.scoreData[0]" v-once> -->
                            <span class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear">{{
                                roiData.scoreData[0].financialReportingYear
                              }}</span>
                            <span class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear == ''">Latest Quarter</span>
                            <!-- </template> -->
                          </div>
                          <div class="flex dot_with_text" v-if="roiData.scoreData.length > 1">
                            <div class="blue-dot mr-5"></div>
                            <!-- <template v-for="(year, index) in roiData.scoreData[1]" v-once> -->
                            <span class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear">{{
                                roiData.scoreData[1].financialReportingYear
                              }}</span>
                            <span class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear == ''">First Historical Year</span>
                            <!-- </template> -->
                          </div>
                          <div class="flex teal_dot_with_text tooltip" style="display: flex !important;">
                            <div class="teal-dot  mr-5"></div>
                            <template>
                              <span class="font-bold" style="width: 250px;">GESI Contextual Score - {{
                                roiData.countryHqText
                              }}</span>
                            </template>
                          </div>
                          <div class="flex dot_with_text">
                            <div class="yellow-dot mr-2 tooltip">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional activities
                                above and beyond what companies their size
                                normally do, bonus points are awarded, up to a
                                total of 1 whole point, on top of the score
                                calculated for the actions that are taken that are
                                expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section">
                      <RoiProgressBarsSectionPrint v-if="roiData.domainAssesmnet.length == 1"
                        :scoreData="roiData.scoreData[0].Opportunities" :roiData="roiData"
                        :gesiScore="roiData.roiGesiScoreData.Opportunities" />
                      <RoiProgressBarsSectionMultiplePrint v-else :scoreData="roiData.scoreData[0].Opportunities"
                        :gesiScore="roiData.roiGesiScoreData.Opportunities"
                        :scoreDataOld="roiData.scoreData[1].Opportunities" :roiData="roiData" />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section" style="">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/inclusion.svg" />
                          <h3 class="font-bold text-2xl">Inclusion</h3>
                          <progress-bar :options="options" :value="
                            (roiData.inclusionPercentage * 100)
                            | mathOnlyRound
                          " style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            " />
                        </div>
                        <div class="flex right_side_flex"
                          :style="{ 'font-size': '13px', 'margin-right': user.languagePreference == 'Spanish' ? '-47px' : '' }">
                          <div class="flex dot_with_text">
                            <div class="orange-dot mr-2"></div>
                            <!-- <template v-for="(year, index) in roiData.scoreData[0]" v-once> -->
                            <span class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear">{{
                                roiData.scoreData[0].financialReportingYear
                              }}</span>
                            <span class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear == ''">Latest Quarter</span>
                            <!-- </template> -->
                          </div>
                          <div class="flex dot_with_text" v-if="roiData.scoreData.length > 1">
                            <div class="blue-dot mr-5"></div>
                            <!-- <template v-for="(year, index) in roiData.scoreData[1]" v-once> -->
                            <span class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear">{{
                                roiData.scoreData[1].financialReportingYear
                              }}</span>
                            <span class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear == ''">First Historical Year</span>
                            <!-- </template> -->
                          </div>
                          <div class="flex teal_dot_with_text tooltip" style="display: flex !important;">
                            <div class="teal-dot  mr-5"></div>
                            <template>
                              <span class="font-bold" style="width: 250px;">GESI Contextual Score - {{
                                roiData.countryHqText
                              }}</span>
                            </template>
                          </div>
                          <div class="flex dot_with_text">
                            <div class="yellow-dot mr-2 tooltip">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional activities
                                above and beyond what companies their size
                                normally do, bonus points are awarded, up to a
                                total of 1 whole point, on top of the score
                                calculated for the actions that are taken that are
                                expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                      <div class="progress_bar_section">
                        <RoiProgressBarsSectionPrint v-if="roiData.domainAssesmnet.length == 1"
                          :scoreData="roiData.scoreData[0].Inclusion" :roiData="roiData"
                          :gesiScore="roiData.roiGesiScoreData.Inclusion" />
                        <RoiProgressBarsSectionMultiplePrint v-else :scoreData="roiData.scoreData[0].Inclusion"
                          :gesiScore="roiData.roiGesiScoreData.Inclusion" :scoreDataOld="roiData.scoreData[1].Inclusion"
                          :roiData="roiData" />
                        <div class="left_side_progress_bar"></div>
                        <div class="right_side_progress_bar"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import RoiProgressBarsSectionPrint from "@/components/roiProgressBarsSection-print.vue";
import RoiProgressBarsSectionMultiplePrint from "@/components/roiProgressBarsSectionMultiple-print.vue";
import spiderchart from "@/components/charts/roiSpiderchart.vue";
import spiderchartMultiple from "@/components/charts/roiSpiderchartMultiple.vue";
import domainSpiderchart from "@/components/charts/roiDomainSpiderchart.vue";
import domainSpiderchartMultiple from "@/components/charts/roiDomainSpiderchartMultiple.vue";
import item from "@/components/text.vue";
export default {
  name: "roiAssessmentPrint",
  data: function () {
    return {
      percentValue: "80%",
      user: [],
      roiData: [],
      endofcreated: false,
      invitedUserEmail: "",
      selectedSurvey: [],
      completeSurveyModal: false,
      isShareVisible: false,
      isMyselfRadioChecked: false,
      isOtherRadioChecked: false,
      options: {
        text: {
          color: "#0077C8",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#0077C8",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 50,
          verticalTextAlign: 20,
          horizontalTextAlign: 10,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "circle",
        },
      },
      json_data: [],
      csvData: [],

      comapnyDetails: [],
      country: {
        income: "",
      },
      sector: { name: "" },
      title: null,

      fill1: { gradient: ["#0077C8", "#2BD4C4"] },
      fill2: { gradient: ["#FE5000", "#FAE053"] },
    };
  },
  props: {
    userFromParent: [Array, Object],
    roiDataFromParent: [Array, Object],
    sectorDataFromParent: [Array, Object],
    countryDataFromParent: [Array, Object],
  },
  components: {
    spiderchart,
    item,
    RoiProgressBarsSectionPrint,
    domainSpiderchart,
    domainSpiderchartMultiple,
    RoiProgressBarsSectionMultiplePrint,
    spiderchartMultiple
  },
  async mounted() {
  },
  methods: {
    redirectToPillar(score) {
      this.$router.push({
        name: "roiPillar",
        params: {

        },
        query: {
          survey: score.parameter,
          recordId: this.roiData.recordId,
        },
      });
    },

    redirectToFinancePillar() {
      this.$router.push({
        name: "roiPillar",
        params: {

        },
        query: {
          survey: 'Financial Processes, Products, & Services',
          recordId: this.roiData.recordId,
        },
      });
    },

    fillMyself() {
      this.redirectToSurvey(this.selectedSurvey);
    },
    isMyselfChecked() {
      if (document.getElementById("myself").checked == true) {
        this.isShareVisible = false;
        this.isMyselfRadioChecked = true;
      }
      if (document.getElementById("other").checked == true) {
        this.isOtherRadioChecked = true;
        this.isShareVisible = true;
      }
    },
    moduleClicked(event) {
      let modVal = event.target.alt.split("/")[1];
      if (event.target.alt.split("/")[0] == "edit-image-icon") {
        this.selectedSurvey = [];
        this.modules.forEach((data) => {
          if (data.module == modVal) {
            this.selectedSurvey = data;
          }
        });
        if (this.roiData.shareAccess) {
          Object.entries(this.roiData).forEach((item) => {
            if (item[0] == this.selectedSurvey.var) {
              
              if (item[1] == false) {
                this.completeSurveyModal = true;
              } else {
                this.redirectToSurvey(this.selectedSurvey);
              }
            }
          });
        } else {
          this.redirectToSurvey(this.selectedSurvey);
        }
      }
    },
    redirectToSurvey(data) {
      this.closePopup();
      let routeData = this.$router.resolve({
        name: "roiSurvey",
        params: { surveyId: data.module },
      });
      window.open(routeData.href, "_blank");
    },

    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    sectorAry() {
      return this.$store.state.sectors.sectors;
    },
    roiSector() {
      return this.roiData.sector == 'Other' ? this.roiData.otherSector : this.roiData.sector;
    },
    genderRoiScore() {
      let val = '';
      if (this.roiData.genderRoiScore) {
        val = Number(this.roiData.genderRoiScore).toFixed(2);
      }
      if (this.roiData.genderRoiScore == 0 && this.roiData.genderRoiScore !== null) {
        val = 0;
      }
      if ((this.roiData.genderRoiScore == null || this.roiData.genderRoiScore == '') && this.roiData.genderRoiScore !== 0) {
        val = '';
      }
      return val;
    },
    averageFinanceScore() {
      let val = '';
      if (this.roiData.averageFinanceScore) {
        val = Number(this.roiData.averageFinanceScore).toFixed(2);
      }
      if (this.roiData.averageFinanceScore == 0 && this.roiData.averageFinanceScore !== null) {
        val = 0;
      }
      if ((this.roiData.averageFinanceScore == null || this.roiData.averageFinanceScore == '') && this.roiData.genderRoiScore !== 0) {
        val = '';
      }
      return val;
    },
    companySize() {
      let state = "";
      let val = Math.round(Number(this.roiData.womworkforceTwo));
      if (val <= 10) {
        state = "Micro";
      } else if (val > 10 && val <= 250) {
        state = "SME";
      } else if (val > 250) {
        state = "Large";
      }
      return state;
    },
  },
  async created() {
    this.user = this.userFromParent;
    this.roiData = this.roiDataFromParent;
    this.country = this.countryDataFromParent;
    this.sector = this.sectorDataFromParent;
    this.endofcreated = true;
  },
};
</script>
<style lang="scss" scoped>
.before_page {
  page-break-before: always;
  overflow:hidden
}

.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.k-progress {
  margin: 0 !important;
}

.grey-dot {
  width: 10px !important;
  height: 10px;
  background-color: #9d9d9c;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

.orange-dot {
  width: 10px !important;
  height: 10px;
  background-color: #fe5000;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

.blue-dot {
  width: 10px !important;
  height: 10px;
  background-color: #201747;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

.teal-dot {
  width: 10px !important;
  height: 10px;
  background-color: #2BD4C4;
  border-radius: 10px;
  top: 5px;
  position: relative;
  cursor: pointer;
}

.yellow-dot {
  width: 10px !important;
  height: 10px;
  background-color: #fae053;
  border-radius: 10px;
  top: 6px;
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .bonus_score_tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -250px;
  top: 20px;
}

.tooltip:hover .bonus_score_tooltiptext {
  visibility: visible;
}

.tooltip .bonus_score_tooltiptext::after {
  right: 95%;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffff;
  border-radius: 10px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #c0c0c1;
  background-color: #c0c0c1;
}

.pointer_event_none {
  pointer-events: none;
}

.opacity_light {
  opacity: 0.5;
}


.assementPageStyle {
  background: #ffffff !important;

  .EmailInput {
    padding: auto 0;
    // border: 1px solid gainsboro;
    border-radius: 5px;
    color: black;

    input {
      background: #fff;
      border: 0.9px solid #d0d0d0;
      padding: 10px 14px;
      border-radius: 4px;
      color: #1b1b1b;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
      height: 36px;
      margin-right: 10px;
      height: 100%;

      ::placeholder {
        color: #d0d0d0;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .container {
    margin: 0 auto;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 100%;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        margin-left: 34px;
        // display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        max-width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .invite_members {
      input {
        background: #fff;
        border: 1px solid #d0d0d0;
        height: 36px;
        border-radius: 4px;
        padding: 5px;

        .invite-member-btn {}

        &::placeholder {
          font-size: 13px;
          color: #d0d0d0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        padding: auto 0;
        border: 1px solid gainsboro;
        border-radius: 5px;

        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;
          height: 100%;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .InviteBtn {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;
      opacity: 0.7;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }

    .tooltip {
      position: relative;
      display: inline-block;
    }

    .tooltip .tooltiptext {
      font-family: "Montserrat", arial;
      padding: 20px;
      font-size: medium;
      letter-spacing: normal;
      visibility: hidden;
      width: 600px;
      background-color: #201747;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      left: 20px;
      top: -160px;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }

    .tooltip .tooltiptext::after {
      right: 95%;
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;

    .OverviewBlock {

      // background: #ffffff;
      // border-radius: 10px;
      // padding: 0 40px 40px;
      // padding-top: 40px;
      // margin-bottom: 18px;
      .OverviewBlock_left_side {
        width: 50%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .equal_strength {
          li {
            margin: 0;
            padding: 5px 0;
          }
        }
      }

      .OverviewBlock_right_side {
        width: 50%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .gender_roi_score {
          .gender_roi_score_chart {
            width: 100%;
          }
        }
      }

      .take_survey_container {
        .take_survey_inner {
          h2 {
            display: flex;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              object-fit: contain;
            }
          }

          width: 50%;
          padding: 5px;

          ul {
            margin-top: 20px;

            li {
              display: flex;
              font-size: 13px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: left;
              margin-left: 0;
              padding-bottom: 10px;

              .edit_button {
                width: 16px;
                height: 16px;
              }

              span {
                width: 70%;
              }

              img {
                height: 20px;
                padding-left: 40px;
              }
            }
          }
        }
      }

      .grey_border {
        display: inline-block;
        border-bottom: 2px solid #d0d0d0;
        width: 100%;
        height: 2px;
        margin: 35px 0;
      }

      .progress_bar_container {
        h2 {
          margin: auto 20px auto 0;
          width: 40%;
        }

        .span_text {
          padding-left: 68px;
          width: 100%;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: right;
        }
      }

      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
          // top: 15px;
          position: relative;
        }
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .EligibleListImg {
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 30px;
                top: 18px;
                left: 8px;
                z-index: -1;
              }

              img {
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 15px !important;
              }
            }

            .bigImg {
              margin-left: -10px;

              &::after {
                top: 40px;
                left: 18px;
                height: 28px;
              }

              img {
                width: 40px !important;
                height: 40px;
              }
            }

            .lastAffter {
              &::after {
                content: none;
              }
            }

            .EligibleTitle {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }

          .bigMargin {
            margin-bottom: 25px;
          }

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }

          .PartyValidatedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .PartyTooltipImg {
              img {
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }
    }

    .spiderchart_main_container {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 80px 40px;

      .spiderchart_content {
        .left_side {
          width: 25%;
        }

        .dot_with_text {
          position: absolute;
          margin-top: 30px;
        }

        .teal_dot_with_text {
          position: absolute;
          margin-top: 50px;
        }

        .right_side {
          width: 100%;

          .spiderchartContent {
            // padding: 0 20px;
            // width: 85%;

            .chartjs-render-monitor {
              // margin-left: -33px;
              // height: 530px !important;
            }

            .resilince-icon {
              max-width: 200px;
              left: 200px;
              top: 50px;
            }

            .opportunity-icon {
              max-width: 200px;
              top: 50px;
              right: 200px;
            }

            .inclusion-icon {
              max-width: 200px;
              right: 46%;
              bottom: -40px;
            }

            .spiderchartContent {
              width: 60%;
            }

            .Ind_Inv_Icon {
              // z-index: 9999;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              cursor: pointer !important;

              img {
                // width: 30px !important;
                // height: 30px;
                object-fit: contain;
                margin-right: 0 !important;
                margin-top: 30px !important;
              }

              span {
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                margin-top: 6px;
                // margin-left: 20px !important;
              }
            }

            .Indirect_Investment {
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .domain_assessment_results_section {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 20px 40px;
      margin-bottom: 40px;

      .spiderchartContent {
        // padding: 0 20px;
        width: 100%;

        .chartjs-render-monitor {
          // margin-left: -33px;
          // height: 530px !important;
        }

        .workforce-icon {
          max-width: 200px;
          left: 50px;
          top: 39%;
        }

        .value-chain-icon {
          max-width: 200px;
          right: 43%;
          bottom: -16px;
        }

        .leadership-icon {
          max-width: 200px;
          top: -11%;
          right: 43%;
        }

        .society-icon {
          max-width: 200px;
          top: 39%;
          right: 50px;
        }

        .spiderchartContent {
          width: 60%;
        }

        .Ind_Inv_Icon {
          // z-index: 9999;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          cursor: pointer !important;

          img {
            // width: 30px !important;
            // height: 30px;
            object-fit: contain;
            margin-right: 0 !important;
            margin-top: 30px !important;
          }

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            margin-top: 6px;
            // margin-left: 20px !important;
          }
        }

        .Indirect_Investment {
          top: 0;
          left: 0;
        }
      }

      .domain_assessment_results_container {
        // color: black;

        .gender_roi_domain_taabs_container {
          img {
            width: 35px;
          }
        }

        .spiderchart_container {
          width: 60%;
        }
      }
    }

    .parameters_block {
      background: #ffff;
      padding: 0 40px;

      .parameters_section_first {
        padding: 0 10px 60px 10px;
      }

      .parameters_section {
        padding: 40px 10px 60px 10px;
        border-top: 1px solid #d0d0d0;
      }

      .inner_parameter_block {
        .score_results_section {
          .parameter_percent {
            h3 {
              margin: auto 0;
              padding: 0 20px;
              position: relative;
              // bottom: 11px;
            }

            .right_side_flex {
              margin: auto 0 auto auto;

              .dot_with_text {
                padding: 0 15px;
              }
            }
          }
        }

        .progress_bar_section {
          padding: 2rem 0;

          .left_side_progress_bar {
            width: 50%;
          }

          .right_side_progress_bar {
            width: 50%;
          }

          .roi-progress-content {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            &--left,
            &--right {
              font-size: 13px;
              width: 45%;
              font-weight: 600;
            }

            .roi-progress-content--left {
              color: #1b1b1b;
            }

            .roi-progress-content--right {
              width: 60%;
              line-height: 1;

              .bar-div {
                span {
                  color: #646363;
                  width: 50px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 320px;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
        // top: 15px;
        position: relative;
      }
    }

    .CountryLine {
      align-items: flex-end;

      h2 {
        width: auto;
      }

      span {
        width: 110px;
      }
    }
  }

  .download-csv {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);

        img {
          filter: invert(1);
        }
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #1b1b1b;
}

.CountryOverviewText {
  margin-bottom: 30px;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #1b1b1b;
    margin-bottom: 5px;
  }

  p,
  .leading-7 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #1b1b1b;
  }

  a {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #1b1b1b;
    display: flex;
    align-items: center;

    img {
      margin-left: 5px !important;
      width: 15px !important;
      height: 15px;
      object-fit: contain;
    }
  }
}


.ExploreBtnDeck {
  display: flex;
  margin-top: 30px;

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 10px;
    transition: 0.3s ease;

    img {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.TooltipImgText {
  display: flex;
  align-items: center;

  img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1b1b1b;
  }

  .tooltiptext {
    max-width: 400px;
    top: 20px;
  }
}

.title-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
</style>