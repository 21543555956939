<template>
  <div class="flex flex-row w-full h-screen bg-gray-100 overflow-hidden">
      <div class="border-t-8 border-gray-700 h-2"></div>
      <div class="container mx-auto py-6 px-4" style="margin-top: 100px;">
          <div class="flex justify-between">
              <h2 class="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">Invoice #{{invoice.id}}</h2>
              <div>
                  <div class="relative mr-4 inline-block">
                    <!--
                      <div
                          class="text-gray-500 cursor-pointer w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center"
                          @mouseenter="showTooltip = true"
                          @mouseleave="showTooltip = false"
                          @click="printInvoice()"
                      >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-printer"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                          >
                              <rect x="0" y="0" width="24" height="24" stroke="none"></rect>
                              <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />
                              <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />
                              <rect x="7" y="13" width="10" height="8" rx="2" />
                          </svg>


                      </div>
                      <div v-if="showTooltip" class="z-40 shadow-lg text-center w-32 block absolute right-0 top-0 p-2 mt-12 rounded-lg bg-gray-800 text-white text-xs">
                          Print this invoice!
                      </div>
                    -->
                  </div>
              </div>
          </div>

          <div class="flex mb-8 justify-between">
              <div class="w-2/4">
                  <div class="mb-2 md:mb-1 md:flex items-center">
                      <label class="w-32 text-gray-800 block font-bold text-sm uppercase tracking-wide">Invoice No.</label>
                      <span class="mr-4 inline-block hidden md:block">:</span>
                      <div class="flex-1">
                          <input disabled
                              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                              id="inline-full-name"
                              type="text"
                              :value="'#'+(invoice.id || '')"
                              placeholder="eg. #INV-100001"
                          />
                      </div>
                  </div>

                  <div class="mb-2 md:mb-1 md:flex items-center">
                      <label class="w-32 text-gray-800 block font-bold text-sm uppercase tracking-wide">Invoice Date</label>
                      <span class="mr-4 inline-block hidden md:block">:</span>
                      <div class="flex-1">
                          <input disabled
                              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 js-datepicker"
                              type="text"
                              id="datepicker1"
                              placeholder="eg. 17 Feb, 2020"
                              autocomplete="off"


                              :value="moment(invoice.createdAt).format('DD/MM/YYY')"
                          />
                      </div>
                  </div>

              </div>
              <div>
                  <div class="w-32 h-32 mb-1 border rounded-lg overflow-hidden relative bg-gray-100">
                      <img id="image" class="object-cover w-full h-32" :src="invoice.equilo.logo" />
                  </div>
              </div>
          </div>

          <div class="flex flex-wrap justify-between mb-8">
              <div class="w-full md:w-1/3 mb-2 md:mb-0">
                  <label class="text-gray-800 block mb-1 font-bold text-sm uppercase tracking-wide">Bill/Ship To:</label>
                  <input disabled
                      class="mb-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      id="inline-full-name"
                      type="text"
                      :value="invoice.team.name"
                      placeholder="Billing company name"
                  />
                  <input disabled
                      class="mb-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      id="inline-full-name"
                      type="text"
                      :value="(invoice.team.street || '')+' '+(invoice.team.nr || '')"
                      placeholder="Billing company address"
                  />

              </div>
              <div class="w-full md:w-1/3">
                  <label class="text-gray-800 block mb-1 font-bold text-sm uppercase tracking-wide">From:</label>
                  <input disabled
                      class="mb-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      id="inline-full-name"
                      type="text"
                      :value="invoice.equilo.name"
                      placeholder="Your company name"
                  />

                  <input disabled
                      class="mb-1 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      id="inline-full-name"
                      type="text"
                      :value="invoice.equilo.address"
                      placeholder="Your company address"
                  />

              </div>
          </div>

          <div class="flex -mx-1 border-b py-2 items-start">
              <div class="flex-1 px-1">
                  <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">Description</p>
              </div>

              <div class="px-1 w-20 text-right">
                  <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">Units</p>
              </div>

              <div class="px-1 w-32 text-right">
                  <p class="leading-none">
                      <span class="block uppercase tracking-wide text-sm font-bold text-gray-800">Unit Price</span>
                  </p>
              </div>

              <div class="px-1 w-32 text-right">
                  <p class="leading-none">
                      <span class="block uppercase tracking-wide text-sm font-bold text-gray-800">Amount</span>
                  </p>
              </div>

          </div>

        <div>
        <div class="flex -mx-1 py-2 border-b">
        <div class="flex-1 px-1">
        <p class="text-gray-800">{{invoice.subscription.name}}</p>
        </div>

        <div class="px-1 w-20 text-right">
        <p class="text-gray-800">1</p>
        </div>

        <div class="px-1 w-32 text-right">
        <p class="text-gray-800">${{invoice.subscription.price}}</p>
        </div>

        <div class="px-1 w-32 text-right">
        <p class="text-gray-800">${{invoice.subscription.price}}</p>
        </div>

        </div>

        </div>


          <div class="py-2 ml-auto mt-5 w-full sm:w-2/4 lg:w-1/4">
              <div class="flex justify-between mb-3">
                  <div class="text-gray-800 text-right flex-1">Total</div>
                  <div class="text-right w-40">
                      <div class="text-gray-800 font-medium">${{invoice.subscription.price}}</div>
                  </div>
              </div>

              <!--
              <div class="flex justify-between mb-4">
                  <div class="text-sm text-gray-600 text-right flex-1">GST(18%) incl. in Total</div>
                  <div class="text-right w-40">
                      <div class="text-sm text-gray-600">20</div>
                  </div>
              </div>
            -->

              <div class="py-2 border-t border-b">
                  <div class="flex justify-between">
                      <div class="text-xl text-gray-600 text-right flex-1">Amount paid</div>
                      <div class="text-right w-40">
                          <div class="text-xl text-gray-800 font-bold">${{invoice.subscription.price}}</div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="py-20 text-center">
              <p class="text-gray-600">
                  ❤️ Thank you for your payment. <br/> Gender equality brought to you by <a class="text-blue-600 hover:text-blue-500 border-b-2 border-blue-200 hover:border-blue-300" href="https://equilo.io">Equilo</a>

              </p>
          </div>

      </div>
  </div>

</template>

<script>
import env from '@/../env.js';
import Cookies from '@/helpers/cookies'
import auth from '@/helpers/auth';


const axios = require('axios');


export default {
  data: function () {
    return {
      invoice: {},
      showTooltip: false,
    }
  },
  methods: {
    load: function(){
      const _self = this;
      return new Promise(function(resolve){
        axios.get(env.apiUrl+'/invoices/'+_self.$route.params.invoiceId, {
          headers: {
            'Authorization': 'Bearer '+Cookies.get('session')
          }
        }).then(function (res) {
          resolve(res.data);
        });
      })
    }
  },
  computed: {
    results: function(){
      const searchResults = []
      if(this.q){
        for (var i = 0; i < this.data.length; i++) {
            for (var f = 0; f < this.searchFields.length; f++) {
              const field = this.searchFields[f];
              if(searchResults.indexOf(this.data[i]) == -1 && this.data[i] && this.data[i][field] && this.data[i][field].toLowerCase().indexOf(this.q.toLowerCase()) > -1){
                searchResults.push(this.data[i]);
                //break;
              }
            }
        }
        return searchResults;
      } else {
        return this.data;
      }
    }
  },
  async mounted() {

    this.user = await auth.me().catch(() => {});
    this.organization = this.user.organization;
    this.invoice = await this.load()

    for (var i = 0; i < this.fields.length; i++) {
      if(this.fields[i].searchable){
        this.searchFields.push(this.fields[i].id)
      }

      if(this.fields[i].header){
        this.headers.push(this.fields[i])
      }

      if(this.fields[i].editable){
        this.editableFields.push(this.fields[i])
      }
    }
    this.$store.commit('downloadable', false);

    this.$store.commit('setBreadcrumbs', [
      {'name': 'Settings', 'url': '/settings/account'},
      {'name': this.organization.name, 'url': '/settings/ream' },
      {'name': 'Invoices', 'url': '/settings/invoices'},
    ])


  }
}
</script>

<style>

  @media print {
    .no-printme  {
      display: none;
    }
    .printme  {
      display: block;
    }
    body {
      line-height: 1.2;
    }
  }

  @page {
    size: A4 portrait;
    counter-increment: page;
  }

  /* Datepicker */
  .date-input {
    background-color: #fff;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    z-index: 2000;
    margin: 3px 0 0 0;
    border-top: 1px solid #eee;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .date-input.is-hidden {
    display: none;
  }
  .date-input .pika-title {
    padding: 0.5rem;
    width: 100%;
    text-align: center;
  }
  .date-input .pika-prev,
  .date-input .pika-next {
    margin-top: 0;
    /* margin-top: 0.5rem; */
    padding: 0.2rem 0;
    cursor: pointer;
    color: #4299e1;
    text-transform: uppercase;
    font-size: 0.85rem;
  }
  .date-input .pika-prev:hover,
  .date-input .pika-next:hover {
    text-decoration: underline;
  }
  .date-input .pika-prev {
    float: left;
  }
  .date-input .pika-next {
    float: right;
  }
  .date-input .pika-label {
    display: inline-block;
    font-size: 0;
  }
  .date-input .pika-select-month,
  .date-input .pika-select-year {
    display: inline-block;
    border: 1px solid #ddd;
    color: #444;
    background-color: #fff;
    border-radius: 10px;
    font-size: 0.9rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    appearance: none;
  }
  .date-input .pika-select-month:focus,
  .date-input .pika-select-year:focus {
    border-color: #cbd5e0;
    outline: none;
  }
  .date-input .pika-select-month {
    margin-right: 0.25em;
  }
  .date-input table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0.2rem;
  }
  .date-input table th {
    width: 2em;
    height: 2em;
    font-weight: normal;
    color: #718096;
    text-align: center;
  }
  .date-input table th abbr {
    text-decoration: none;
  }
  .date-input table td {
    padding: 2px;
  }
  .date-input table td button {
    /* border: 1px solid #e2e8f0; */
    width: 1.8em;
    height: 1.8em;
    text-align: center;
    color: #555;
    border-radius: 10px;
  }
  .date-input table td button:hover {
    background-color: #bee3f8;
  }
  .date-input table td.is-today button {
    background-color: #ebf8ff;
  }
  .date-input table td.is-selected button {
    background-color: #3182ce;
  }
  .date-input table td.is-selected button {
    color: white;
  }
  .date-input table td.is-selected button:hover {
    color: white;
  }
</style>
