<script>
/*

  Input should look like this:


  data = [
    {name: 'running', value1: }
  ]




  More information: https://www.chartjs.org/docs/latest/charts/radar.html

*/

import axios from "axios";
import { Radar } from "vue-chartjs";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import IntersectionScore from "./IntersectionScore";
// function to configure charts
import { getTaabColors, getLabelValues, initHoverBox } from "./ChartFunction";
const _ = require("underscore");

export default {
  name: "domainIntersection",
  extends: Radar,
  data: function () {
    return {
      countryData: [],
      // domain: {},
      regionalData: [],
      incomeData: [],
      globalData: [],
      axes: [],
      axeColors: [],
      one: [],
      max: [],
      intersections: [],
      countryIntersection: [],
      regionalIntersection: [],
      incomeIntersection: [],
      globalIntersection: [],
    };
  },
  methods: {
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    toTitleCase: function (phrase) {
      return phrase
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    getCountry: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
            "/countries/" +
            _self.iso +
            "/sectors/" +
            _self.$route.params.sector,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getDomain: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
            "/reports/" +
            _self.iso +
            "/sectors/" +
            _self.$route.params.sector +
            "/domains/" +
            _self.domainId,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
  },
  props: ["iso", "domainId", "country", "domain"],
  async created() {
    // We will request the country OURSELF because of asyncness...
    await this.getCountry(); // lets do an await before rendering other items.
    await this.getDomain(); // lets do an await before rendering other items.
    this.intersections = this.domain.intersections;
    for (var i = 0; i < this.intersections.length; i++) {
      this.countryIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality,
          this.intersections[i].gender_empowerment,
          this.intersections[i].gender_parity
        )
      );
      this.regionalIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_region,
          this.intersections[i].gender_empowerment_region,
          this.intersections[i].gender_parity_region
        )
      );
      this.incomeIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_income,
          this.intersections[i].gender_empowerment_income,
          this.intersections[i].gender_parity_income
        )
      );
      this.globalIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_global,
          this.intersections[i].gender_empowerment_global,
          this.intersections[i].gender_parity_global
        )
      );

      this.countryData.push(this.intersections[i].gender_equality || 0);
      this.regionalData.push(this.intersections[i].gender_equality_region || 0);
      this.incomeData.push(this.intersections[i].gender_equality_income || 0);
      this.globalData.push(this.intersections[i].gender_equality_global);
      this.axes.push(this.intersections[i].theme);
      this.max.push(1.4);
      this.one.push(1);

      const taabColors = getTaabColors();

      this.axeColors.push(taabColors[this.intersections[i].taab]);
    }
    const _self = this;
    this.renderChart(
      {
        labels: this.axes,
        datasets: [
          {
            data: this.countryData,
            label: this.country.humanName,
            borderColor: env.geoColors.countryspider,
            pointBackgroundColor: env.geoColors.countryspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.countryspider),
            borderWidth: 4,
            pointHitRadius: 8,
          },
          {
            data: this.regionalData,
            label:
              this.country.region == "Eastern Europe & Central Asia"
                ? "Europe & Central Asia"
                : this.country.region + " Region",
            borderColor: env.geoColors.regionspider,
            pointBackgroundColor: env.geoColors.regionspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.regionspider),
            pointHitRadius: 8,
          },
          {
            data: this.incomeData,
            label: this.toTitleCase(this.country.income) + " Group",
            borderColor: env.geoColors.incomespider,
            pointBackgroundColor: env.geoColors.incomespider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.lmicspider),
            pointHitRadius: 8,
          },
          {
            data: this.globalData,
            label: "Global",
            borderColor: env.geoColors.lmicspider,
            pointBackgroundColor: env.geoColors.lmicspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.lmicspider),
            pointHitRadius: 8,
          },
          {
            data: this.one,
            label: "Perfect Equality",
            borderColor: "#3F37C9",
            fill: false,
            //pointBackgroundColor: env.geoColors.perfectEquality,
            pointRadius: 0,
            borderWidth: 3,
          },
          {
            data: this.max,
            label: "hide",
            fill: false,
            pointBackgroundColor: this.axeColors,
          },
        ],
      },
      {
        legend: {
          position: "bottom",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("hide");
            },
            fontSize: 13,
            fontFamily: "basier",
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
            }
          },
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }

            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items

            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);

            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;

            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });

              if (!someShown) return;
            }

            meta.hidden = hidden;

            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },
        hover: {
          onHover: function (e) {
            // return false;
            var point = this.getElementAtEvent(e);
            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
              e.target.style.cursor = "pointer";
              // Prevent from flashing.
              if (
                point[0]._index !==
                _self.hoverIndex + "_" + point[0]._datasetIndex
              ) {
                _self.hoverIndex =
                  point[0]._index + "_" + point[0]._datasetIndex;
                // initialise hoverbox
                initHoverBox(e, _self, point);
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY - 105 + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX - 10 + "px";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById("div-intersection-validity-power").style.display = "none";
                document.getElementById("div-intersection-country-four").style.display = "none";
                document.getElementById("div-intersection-country-five").style.display = "none";
                document.getElementById("div-intersection-country-six").style.display = "none";
                document.getElementById("div-intersection-country-seven").style.display = "none";
                // GIVE LABELS VALUES
                getLabelValues(_self, point);
                document.getElementById("intersection-theme-link").href =
                  "/#/reports/" +
                  _self.country.ISO2 +
                  "/" +
                  _self.$route.params.sector +
                  "/themes/" +
                  _self.axes[point[0]._index];
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
              e.target.style.cursor = "default";
            }
            //if (point.length) e.target.style.cursor = 'pointer';
            //else e.target.style.cursor = 'default';

            //e.target.style.cursor = 'pointer';
          },
        },
        scale: {
          angleLines: {
            display: true,
            //color: "#2F2665",
            lineWidth: 1,
          },
          gridLines: {
            display: true,
            //color: "#2F2665",
            lineWidth: 1,
          },
          pointLabels: {
            fontColor: this.axeColors,
            fontSize: 14,
            fontFamily: "basier",
            lineHeight: 2,
            onClick: function () { },
          },
          ticks: {
            //suggestedMin: 50,
            suggestedMax: 1.24,
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
          backgroundColor: "rgb(253, 253, 253)",
          xPadding: 20,
          yPadding: 20,

          titleFontStyle: "semi-bold",
          titleFontColor: "#0066ff",
          titleFontFamily: "basier",
          titleFontSize: 10,
          titleSpacing: 20,
          titleMarginBottom: 10,

          bodyFontColor: "#333333",
          bodyFontSize: 14,
          bodyFontFamily: "basier",
          displayColors: false,

          cornerRadius: 2,
          borderColor: "rgb(240, 240, 240)",
          borderWidth: 3,
        },
      }
    );
  },
};
</script>

<style>

</style>
