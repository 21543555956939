<template>
  <div class="flex flex-row w-full bg-gray-100">
    <sidebar></sidebar>
    <!--TABLE-->
    <div
      class="flex flex-col w-full overflow-scroll"
      style="padding-top: 100px; padding-left: 30px; padding-bottom: 30px; padding-right: 30px;"
    >


    <div class="w-6/12 mb-10">

      <h1 class="text-3xl font-semibold">Equilo subscriptions</h1>
      <p>Select from the following subscription options below to get started! If you have questions, visit our <a href="https://www.equilo.io/faq" target="_blank" style="text-decoration: underline; color: blue;">FAQ</a> or drop us a note at <a href="mailto:hello@equilo.io" style="text-decoration: underline; color: blue;">Hello@equilo.io</a> to create a customized subscription that works for you. View all available country and sector options here.</p>
    </div>
      <h1 class="text-xl font-semibold" id="sub0">{{subTitle1}}</h1>
      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 justify-start items-start"
      >
        <div
          v-for="subscription in countrySubscriptions"
          v-bind:key="subscription._id"
          class="flex-0 rounded-lg shadow-sm bg-white mt-4 flex flex-col w-full mr-10 border-gray-200 border h-auto"
        >
          <h1 class="text-lg text-center py-2 px-4 pt-10">
            {{ subscription.name }}
          </h1>
          <div class="text-center text-3xl font-bold text-indigo-800">
            <span v-if="subscription.discount > 0">
              <p class="text-3xl" style="text-decoration: line-through">
                ${{
                  subscription.price
                .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
              ${{ reductionprice(subscription.price, subscription.discount) }}
            </span>
            <span v-else>
              <span v-if="subscription.price > 0">
              <p class="text-3xl">
                ${{
                  subscription.price
                .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
            </span>
                   <span v-else>FREE</span>
            </span>
          </div>
          <div class="text-center text-3xl font-bold text-indigo-800 pb-10">
            <span v-if="subscription.price > 0">
              <p
                v-if="subscription.days === 365"
                class="text-gray-600 text-sm font-medium"
              >
                yearly
              </p>
              <p
                v-else-if="subscription.days === 62"
                class="text-gray-600 text-sm font-medium"
              >
                2 months
              </p>
              <p v-else class="text-gray-600 text-sm font-medium">1 month</p>
            </span>
          </div>
          <div
            class="text-sm py-2 border-t border-gray-200 pl-10 text-gray-600"
          >

            <div class="text-left py-2" v-if="subscription.usersVisible">
              <p v-if="subscription.users === 1" class="flex flex-row items-center justify-start">
                <svg class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20,6.5l-11,11l-5,-5"></path>
                  </g>
                </svg>
                <span>{{ subscription.users }} User</span>
              </p>
              <p v-else-if="subscription.users >= 9999" class="flex flex-row items-center justify-start">
                <svg class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                Unlimited users
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.users }} Users</span>
              </p>
            </div>
                        <div class="text-left py-2" v-if="subscription.countriesVisible">
              <p
                v-if="subscription.countries === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.countries }} Country</span>
              </p>
              <p
                v-else-if="subscription.countries >= 9999"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited countries</span>
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.countries }} Countries</span>
              </p>
            </div>
            <div class="text-left py-2" v-if="subscription.sectorsVisible">
              <p
                v-if="subscription.sectors === 0"
                class="text-gray-400 flex flex-row items-center justify-start"
              >
                <svg
                  class="h-6 w-6 mr-2 text-red-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M8,8l8,8"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M16,8l-8,8"
                    ></path>
                  </g>
                </svg>
                <span>No Sectors</span>
              </p>
              <p
                v-else-if="subscription.sectors === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.sectors }} Sector</span>
              </p>
              <p
                v-else-if="subscription.sectors >= 9999"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited sectors</span>
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.sectors }} Sectors</span>
              </p>
            </div>

            <div
              v-if="subscription.orgsVisible"
              class="text-left py-2"
            >


              <!--<p v-if="subscription.orgs === 0" class="text-gray-400 flex flex-row items-center justify-start">-->
              <!--<svg class="h-6 w-6 mr-2 text-red-500" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">&lt;!&ndash;Generated by IJSVG (https://github.com/iconjar/IJSVG)&ndash;&gt;<g fill="none"><path d="M0,0h24v24h-24Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,8l8,8"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16,8l-8,8"></path></g></svg>-->
              <!--<span>No organizational analysis</span>-->
              <!--</p>-->

              <div v-if="!subscription.orgs" class="text-gray-400 flex flex-row items-center justify-start">
                <svg
                  class="h-6 w-6 mr-1 text-red-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" >
                  <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M8,8l8,8"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M16,8l-8,8"
                    ></path>
                  </g>
                </svg>
                <p>No organizational analysis</p>
              </div>

              <p
                v-if="subscription.orgs === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{
                  subscription.orgs > 1
                    ? `${subscription.orgs} Organizational analyses`
                    : `${subscription.orgs} Organizational analysis`
                }}</span>
              </p>
              <p
                v-else-if="subscription.orgs > 1 && subscription.orgs <= 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{
                  subscription.orgs > 1
                    ? `${subscription.orgs} Organizational analyses`
                    : `${subscription.orgs} Organizational analysis`
                }}</span>
              </p>
              <p
                v-else-if="subscription.orgs >= 9999"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited analyses</span>
              </p>
            </div>
          </div>

          <div class="m-auto mt-2 mb-6 flex flex-row">
            <button
              v-if="subscription._id !== organization.subscription._id"
              @click="changePlan(subscription)"
              class="text-white px-5 py-2 text-sm rounded-lg hover:bg-green-darkere"
              :style="'background-color:' + env.brandingColors.navy"
            >
              More info
            </button>
            <button
              v-else
              class="text-black px-5 py-2 text-sm rounded-lg hover:bg-green-darkere"
            >
              Current plan
            </button>
          </div>
        </div>
      </div>
      <h1 class="text-xl font-semibold mt-20" id="sub1">
        {{subTitle2}}
      </h1>
      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 justify-start items-start"
      >
        <div
          v-for="subscription in sectorSubscriptions"
          v-bind:key="subscription._id"
          class="flex-0 rounded-lg shadow-sm bg-white mt-4 flex flex-col w-full mr-10 border-gray-200 border h-auto"
        >
          <div class="text-lg text-center py-2 px-4 pt-10">
            {{ subscription.name }}
          </div>
          <div class="text-center text-3xl font-bold text-indigo-800">
            <span v-if="subscription.discount > 0">
              <p class="text-3xl" style="text-decoration: line-through">
                ${{
                  subscription.price
                .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
              ${{ reductionprice(subscription.price, subscription.discount) }}
            </span>
            <span v-else>
              <span v-if="subscription.price > 0">
              <p class="text-3xl">
                ${{
                  subscription.price
                .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
            </span>
            </span>
          </div>
          <div class="text-center text-3xl font-bold text-indigo-800 pb-10">
            <span v-if="subscription.price > 0">
              <p
                v-if="subscription.days === 365"
                class="text-gray-600 text-sm font-medium"
              >
                yearly
              </p>
              <p
                v-else-if="subscription.days === 62"
                class="text-gray-600 text-sm font-medium"
              >
                2 months
              </p>
              <p v-else class="text-gray-600 text-sm font-medium">1 month</p>
            </span>
            <span v-else>FREE</span>
          </div>
          <div
            class="text-sm py-2 border-t border-gray-200 pl-10 text-gray-600">
            <div class="text-left py-2" v-if="subscription.usersVisible">
              <p
                v-if="subscription.users === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.users }} User</span>
              </p>
              <p
                v-else-if="subscription.users > 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                Unlimited users
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.users }} Users</span>
              </p>
            </div>
                        <div class="text-left py-2" v-if="subscription.countriesVisible">
              <p
                v-if="subscription.countries === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.countries }} Country</span>
              </p>
              <p
                v-else-if="subscription.countries > 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited countries</span>
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.countries }} Countries</span>
              </p>
            </div>
                        <div class="text-left py-2" v-if="subscription.sectorsVisible">
              <p
                v-if="subscription.sectors === 0"
                class="text-gray-400 flex flex-row items-center justify-start"
              >
                <svg
                  class="h-6 w-6 mr-2 text-red-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M8,8l8,8"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M16,8l-8,8"
                    ></path>
                  </g>
                </svg>
                <span>No Sectors</span>
              </p>
              <p
                v-else-if="subscription.sectors === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.sectors }} Sector</span>
              </p>
              <p
                v-else-if="subscription.sectors > 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited sectors</span>
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.sectors }} Sectors</span>
              </p>
            </div>
            <div
              v-if="subscription.orgsVisible"
              class="text-left"
            >
              <!--<p v-if="subscription.orgs === 0" class="text-gray-400 flex flex-row items-center justify-start">-->
              <!--<svg class="h-6 w-6 mr-2 text-red-500" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">&lt;!&ndash;Generated by IJSVG (https://github.com/iconjar/IJSVG)&ndash;&gt;<g fill="none"><path d="M0,0h24v24h-24Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,8l8,8"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16,8l-8,8"></path></g></svg>-->
              <!--<span>No organizational analysis</span>-->
              <!--</p>-->

                            <div v-if="!subscription.orgs" class="text-gray-400 flex flex-row items-center justify-start">
                <svg
                  class="h-6 w-6 mr-1 text-red-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" >
                  <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M8,8l8,8"
                    ></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M16,8l-8,8"
                    ></path>
                  </g>
                </svg>
                <p>No organizational analysis</p>
              </div>
              <p
                v-if="subscription.orgs === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{
                  subscription.orgs > 1
                    ? `${subscription.orgs} Organizational analyses`
                    : `${subscription.orgs} Organizational analysis`
                }}</span>
              </p>
              <p
                v-else-if="subscription.orgs > 1 && subscription.orgs <= 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{
                  subscription.orgs > 1
                    ? `${subscription.orgs} Organizational analyses`
                    : `${subscription.orgs} Organizational analysis`
                }}</span>
              </p>
              <p
                v-else-if="subscription.orgs > 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited analyses</span>
              </p>
            </div>
          </div>

          <div class="m-auto mt-2 mb-6 flex flex-row">
            <button
              v-if="subscription._id !== organization.subscription._id"
              @click="changePlan(subscription)"
              class="text-white px-5 py-2 text-sm rounded-lg hover:bg-green-darkere"
              style="background-color: rgb(32, 23, 71);"
            >
              More info
            </button>
            <button
              v-else
              class="text-black px-5 py-2 text-sm rounded-lg hover:bg-green-darkere"
            >
              Current plan
            </button>
          </div>
        </div>
      </div>
      <h1 class="text-xl font-semibold mt-20" id="sub2">
        {{subTitle2}}
      </h1>
      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 items-start"
      >
        <div
          v-for="subscription in otherSubscriptions"
          v-bind:key="subscription._id"
          class="flex-0 rounded-lg shadow-sm bg-white mt-4 flex flex-col w-full mr-10 border-gray-200 border h-auto"
        >
          <div class="text-lg text-center py-2 px-4 pt-10">
            {{ subscription.name }}
          </div>
          <div class="text-center text-3xl font-bold text-indigo-800 pb-10">
            <span v-if="subscription.discount > 0">
              <p class="text-3xl" style="text-decoration: line-through">
                ${{
                  subscription.price
                .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
              ${{ reductionprice(subscription.price, subscription.discount) }}
            </span>
            <span v-else>
              <span v-if="subscription.price > 0">
              <p class="text-3xl">
                ${{
                  subscription.price
                .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </p>
            </span>
                   <span v-else>FREE</span>
            </span>
          </div>
          <div
            class="text-sm py-2 border-t border-gray-200 pl-10 text-gray-600"
          >
            <div class="text-left py-2" v-if="subscription.usersVisible">
              <p
                v-if="subscription.users === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.users }} User</span>
              </p>
              <p
                v-else-if="subscription.users > 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                Unlimited users
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.users }} Users</span>
              </p>
            </div>
            <div class="text-left py-2" v-if="subscription.countriesVisible">
              <p
                      v-if="subscription.countries === 1"
                      class="flex flex-row items-center justify-start"
              >
                <svg
                        class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.countries }} Country</span>
              </p>
              <p
                      v-else-if="subscription.countries > 100"
                      class="flex flex-row items-center justify-start"
              >
                <svg
                        class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited countries</span>
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                        class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.countries }} Countries</span>
              </p>
            </div>
            <div class="text-left py-2" v-if="subscription.sectorsVisible">
              <p
                      v-if="!subscription.sectors"
                      class="text-gray-400 flex flex-row items-center justify-start"
              >
                <svg
                        class="h-6 w-6 mr-2 text-red-500 flex-shrink-0"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M8,8l8,8"
                    ></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M16,8l-8,8"
                    ></path>
                  </g>
                </svg>
                <span>No Sectors</span>
              </p>
              <p
                      v-else-if="subscription.sectors === 1"
                      class="flex flex-row items-center justify-start"
              >
                <svg
                        class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.sectors }} Sector</span>
              </p>
              <p
                      v-else-if="subscription.sectors > 100"
                      class="flex flex-row items-center justify-start"
              >
                <svg
                        class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited sectors</span>
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                        class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{ subscription.sectors }} Sectors</span>
              </p>
            </div>
            <div
              v-if="subscription.orgs !== '' || subscription.orgs !== 0"
              class="text-left"
            >
              <!--<p v-if="subscription.orgs === 0" class="text-gray-400 flex flex-row items-center justify-start">-->
              <!--<svg class="h-6 w-6 mr-2 text-red-500" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">&lt;!&ndash;Generated by IJSVG (https://github.com/iconjar/IJSVG)&ndash;&gt;<g fill="none"><path d="M0,0h24v24h-24Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,8l8,8"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16,8l-8,8"></path></g></svg>-->
              <!--<span>No organizational analysis</span>-->
              <!--</p>-->
              <p
                v-if="subscription.orgs === 1"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{
                  subscription.orgs > 1
                    ? `${subscription.orgs} Organizational analyses`
                    : `${subscription.orgs} Organizational analysis`
                }}</span>
              </p>
              <p
                v-else-if="subscription.orgs > 1 && subscription.orgs <= 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>{{
                  subscription.orgs > 1
                    ? `${subscription.orgs} Organizational analyses`
                    : `${subscription.orgs} Organizational analysis`
                }}</span>
              </p>
              <p
                v-else-if="subscription.orgs > 100"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>Unlimited analyses</span>
              </p>
            </div>

            <div class="text-left py-2">
              <p
                v-if="subscription.days === 365"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>12-month subscription</span>
              </p>
              <p
                v-else-if="subscription.days === 62"
                class="flex flex-row items-center justify-start"
              >
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>2-month subscription</span>
              </p>
              <p v-else class="flex flex-row items-center justify-start">
                <svg
                  class="h-5 w-5 mr-2 text-blue-500 flex-shrink-0"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g fill="none">
                    <path d="M0,0h24v24h-24Z"></path>
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M20,6.5l-11,11l-5,-5"
                    ></path>
                  </g>
                </svg>
                <span>1-month subscription</span>
              </p>
            </div>
          </div>

          <div class="m-auto mt-2 mb-6 flex flex-row">
            <button
              v-if="subscription._id !== organization.subscription._id"
              @click="changePlan(subscription)"
              class="text-white px-5 py-2 text-sm rounded-lg hover:bg-green-darkere"
              style="background-color: rgb(32, 23, 71);"
            >
              More info
            </button>
            <button
              v-else
              class="text-black px-5 py-2 text-sm rounded-lg hover:bg-green-darkere"
            >
              Current plan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import auth from "@/helpers/auth";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";

export default {
  name: "Overview",
  data: function() {
    return {
      user: {},
      organization: {},
      subscriptions: [],
      env,
      countrySubscriptions: [],
      sectorSubscriptions: [],
      otherSubscriptions: [],
      subTitle1: '',
      subTitle2: '',
      subTitle3: '',
    };
  },
  components: {
    sidebar
  },
  methods: {
    open: function(href) {
      var win = window.open(href, "_blank");
      win.focus();
    },
    changePlan: function(subscription) {
      //if (confirm('Are you sure you want to change your plan? We will redirect you to our payment page.')) {
      // alert(env.apiUrl+'/organizations/'+this.organization._id+'/subscriptions/'+subscription._id)
      this.$router.push({ path: "/settings/plans/" + subscription.id });
      // document.location.href = env.apiUrl + '/organizations/' + this.organization._id + '/subscriptions/' + subscription._id
      //}
    },
    update: function() {
      axios
        .put(
          env.apiUrl + "/organizations/" + this.organization._id,
          {
            name: this.organization.name
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          }
        )
        .then(async function() {
          alert("Your organization has been saved.");
          document.location.reload();
        });
    },
    load: function() {
      return new Promise(function(resolve) {
        axios
          .get(env.apiUrl + "/subscriptions", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          })
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
    getSubscriptionTitles: function () {
        const _self = this;
        axios.get(env.apiUrl + '/cms/subscriptionTitles', {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('session')
          }
        }).then(function (res) {
            _self.subTitle1 = res.data[0].subscriptionTitle1;
            document.getElementById('sub0').innerText = _self.subTitle1;
            _self.subTitle2 = res.data[0].subscriptionTitle2;
            document.getElementById('sub1').innerText = _self.subTitle2;
            _self.subTitle3 = res.data[0].subscriptionTitle3;
            document.getElementById('sub2').innerText = _self.subTitle3;
        });
    },
    reductionprice(originalPrice, discountPrice) {
      const realDiscount = 1 - (discountPrice/100);
      const redprice = originalPrice * realDiscount;
      return Math.floor(redprice)
              .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  async created() {
    this.user = await auth.me().catch(() => {});
    this.organization = this.user.organization;

    if (!this.organization.subscription) {
      this.organization.subscription = {};
    }

    this.subscriptions = await this.load();
    this.getSubscriptionTitles();
    // Divide the subscription per section
    for (var i = 0; i < this.subscriptions.length; i++) {
      if (
        this.subscriptions[i].order === 1
      ) {
        this.countrySubscriptions.push(this.subscriptions[i]);
      } else if (
              this.subscriptions[i].order === 2
      ) {
        this.sectorSubscriptions.push(this.subscriptions[i]);
      } else if (
              this.subscriptions[i].order === 3
      ) {
        this.otherSubscriptions.push(this.subscriptions[i]);
      }
    }
    // order de subscriptions of each section
    this.countrySubscriptions.sort((a, b) => { return a.orderColumn - b.orderColumn});
    this.sectorSubscriptions.sort((a, b) => { return a.orderColumn - b.orderColumn});
    this.otherSubscriptions.sort((a, b) => { return a.orderColumn - b.orderColumn});
    this.$store.commit("downloadable", false);

    this.$store.commit("setBreadcrumbs", [
      {
        name: "Settings",
        url: "/settings/account"
      },
      {
        name: this.organization.name,
        url: "/settings/team"
      },
      {
        name: "Subscriptions",
        url: "/settings/plans"
      }
    ]);
  }
};
</script>
