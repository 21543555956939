<template>
  <div
    class="fixed w-screen h-screen bg-white flex flex-col items-center justify-center top-0 left-0 z-50"
  >
    <div class="container">
      <div class="flex flex-row justify-center items-center mt-3">
        <div class="flex flex-col item-center justify-center">
          <div class="flex flex-row items-center justify-center">
            <clip-loader
              :color="env.brandingColors.teal"
              :size="'120px'"
            ></clip-loader>
          </div>
          <h1
            :style="'color:' + env.brandingColors.teal"
            class="text-2xl tracking-tighter font-semibold text-center mt-10"
          >
            Just a moment...
          </h1>
          <h1
            :style="'color:' + env.brandingColors.teal"
            class="text-md tracking-tighter font-medium text-center"
          >
            We are hacking equality.
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
import env from "@/../env";
export default {
  name: "page-loader",
  components: {
    ClipLoader,
  },
  data: function() {
    return {
      env,
    };
  },
};
</script>

<style scoped></style>
