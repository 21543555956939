<template>
  <div
    class="set-up-preferences-container"
    :style="
      user?.membership2xData ? 'border-color: #E1E3E9' : 'border-color: #2c2e35'
    "
  >
    <div class="inner-set-up-preferences-container">
      <div class="step-section">
        <p>Step 2</p>
      </div>
      <div class="content-section">
        <div class="flex justify-between">
          <div class="text-content-heading">Set up preferences</div>
          <div v-if="!preferences.length" class="estimated-time">
            <EstimatedTime text="5 minutes"></EstimatedTime>
          </div>
          <div class="done-green-button" v-else>
            <div class="inner-button flex">
              <img src="./../../assets/images/IMM/white-check.svg" />
              Done
            </div>
          </div>
        </div>
      </div>
      <div v-if="!preferences.length" class="button-section">
        <GetStarted :text="getStartedText" bgColor="#E5FAFA"></GetStarted>
      </div>
    </div>
  </div>
</template>
<script>
import EstimatedTime from "./../../components/imm-components/estimated-time.vue";
import GetStarted from "./../../components/imm-components/get-started-imm.vue";

export default {
  name: "CreateInvestorProfile",
  props: ["user", "preferences"],
  data() {
    return {
      getStartedText:
        "Choose which data you would like your portfolio companies to report,and at which frequency.",
    };
  },
  components: {
    EstimatedTime,
    GetStarted,
  },
};
</script>
<style lang="scss" scoped>
.set-up-preferences-container {
  width: 948px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #e1e3e9;
  gap: 16px;
  .inner-set-up-preferences-container {
    .step-section {
      p {
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #717686;
      }
    }

    .content-section {
      gap: 8px;
      margin-top: 8px;

      .text-content-heading {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
      }

      .text-content-paragraph {
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 16px;
      }
    }

    .button-section {
      margin-top: 32px;
      gap: 8px;

      button {
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        width: 202px;
        height: 48px;
        background: #191b45;
        color: #ffffff;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }

      img {
        margin-top: 8px;
        margin-left: 8px;
      }
    }
  }
  .done-green-button {
    .inner-button {
      font-family: Arial;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: right;
      color: #ffffff;
      background: #4dc9be;
      width: 92px;
      height: 44px;
      padding: 12px 16px 12px 16px;
      border-radius: 28px;
      gap: 4px;
    }
  }
}
</style>
