<template>
  <div class="create-investor-container">
    <div class="inner-create-investor-container">
      <div class="step-section">
        <p>Step 2</p>
      </div>
      <div class="content-section">
        <div class="flex justify-between">
          <div class="text-content-heading">Take assessments</div>
        </div>
        <div class="text-content-paragraph">
          Fill out the assessments that are requested by your investor to
          showcase your company's performance and track progress over time.
        </div>
      </div>
      <div class="button-section flex justify-between">
        <button
          class="flex justify-center gap-2"
          @click="isAssessModalOpened = true"
        >
          View assessments to complete
          <svg
            width="17"
            height="8"
            viewBox="0 0 17 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.51 3H0.5V5H12.51V8L16.5 4L12.51 0V3Z" fill="#2177C1" />
          </svg>
        </button>
        <router-link
          to="/imm/dashboard"
          class="bg-[#191b45] text-white text-xl p-3 px-5 rounded-lg font-semibold flex justify-center items-center"
        >
          Get started
        </router-link>
      </div>
    </div>
    <!-- Assessments Modal Start -->
    <div class="modal" v-if="isAssessModalOpened">
      <!-- Modal content -->
      <div class="modal-content">
        <div>
          <h2 class="font-bold text-2xl" style="">Assessments</h2>
        </div>
        <div class="download-kit-details my-6">
          <div class="inner-download-kit-details">
            <div class="heading">2X Certification Self-Assessment</div>
            <div class="text">
              Assess your company's gender equality practices from governance,
              ownership, leadership, employment and diversity practices, supply
              chain, products and value chain.
            </div>
          </div>
          <div class="inner-download-kit-details">
            <div
              class="heading cursor-pointer"
              @click="$router.push({ path: '/roi' })"
            >
              Gender ROI™
            </div>
            <div class="text">
              Assess your company's commitment to and integration of diversity,
              equity and inclusion in your leadership, workforce, value chain,
              and society where you operate.
            </div>
          </div>
          <div class="inner-download-kit-details">
            <div
              class="heading cursor-pointer"
              @click="$router.push({ path: '/imm/impact-survey' })"
            >
              Impact
            </div>
            <div class="text">
              Assess the long-term effects or benefits brought about for the
              intended beneficiaries, stakeholders or the natural environment
              through your company's daily business.
            </div>
          </div>
          <div class="inner-download-kit-details">
            <div
              class="heading cursor-pointer"
              @click="$router.push({ path: '/imm/esg-survey' })"
            >
              ESG
            </div>
            <div class="text">
              Assess your company's management of environmental, social and
              governance risks and opportunities.
            </div>
          </div>
          <div class="inner-download-kit-details">
            <div
              class="heading cursor-pointer"
              @click="$router.push({ path: '/imm/climate-survey' })"
            >
              Climate
            </div>
            <div class="text">
              Assess your company's strategies to address and mitigate climate
              change, including a commitment to diminish your greenhouse gas
              emissions, embrace sustainable practices, and allocate resources
              toward environmentally friendly, renewable or low-carbon products
              and services.
            </div>
          </div>
          <div class="inner-download-kit-details">
            <div
              class="heading cursor-pointer"
              @click="$router.push({ path: '/imm/finance-survey' })"
            >
              Finance
            </div>
            <div class="text">
              Assess your company's overall standing in categories such as
              assets, liabilities, equity, expenses, revenue,and overall
              profitability.
            </div>
          </div>
        </div>
        <div class="action-button-section">
          <div class="flex justify-end gap-4">
            <button
              class="p-3 px-7 text-gray-900 rounded-lg font-semibold flex justify-center items-center border border-[#191b45]"
              @click="isAssessModalOpened = false"
            >
              Close
            </button>
            <router-link
              to="/imm/dashboard"
              class="bg-[#191b45] text-white p-3 rounded-lg font-semibold flex justify-center items-center"
            >
              Get started
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Assessments Modal End -->
  </div>
</template>
<script>
export default {
  name: "TakeAssessmentsIMM",
  data() {
    return {
      isAssessModalOpened: false,
    };
  },
  components: {},
  props: {
    user: Object,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.create-investor-container {
  width: 948px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #2c2e35;
  gap: 16px;

  .inner-create-investor-container {
    .step-section {
      p {
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #717686;
      }
    }

    .content-section {
      gap: 8px;
      margin-top: 8px;

      .text-content-heading {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
      }

      .text-content-paragraph {
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 16px;
      }

      .done-green-button {
        .inner-button {
          font-family: Arial;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: right;
          color: #ffffff;
          background: #4dc9be;
          width: 92px;
          height: 44px;
          padding: 12px 16px 12px 16px;
          border-radius: 28px;
          gap: 4px;
        }
      }
    }

    .button-section {
      margin-top: 32px;
      gap: 8px;

      button {
        background: #fff;
        color: #2177c1;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        align-items: center;
      }

      img {
        margin-top: 8px;
        margin-left: 8px;
      }
    }
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 24px;
      border: 1px solid #888;
      width: 600px;
      border-radius: 20px;

      .download-kit-details {
        .inner-download-kit-details {
          width: 552px;
          padding: 12px 0px 12px 0px;

          img {
            width: 36px;
            height: 36px;
          }

          .heading {
            font-family: Arial;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #2c2e35;
          }

          .text {
            font-family: Arial;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #2c2e35;
          }
        }
      }

      .action-button-section {
        padding: 16px 0 0 0;
        gap: 16px;
        align-self: stretch;

        .button-section {
          button {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            margin-right: 5px;
          }

          .cancel-button {
            color: var(--primary-100, #191b45);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 5px;
            border: 1px solid var(--primary-100, #191b45);
          }

          .save-button {
            color: var(--white, #fff);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            background: var(--primary-100, #191b45);
          }
        }
      }
    }
  }
}
</style>
