<template>
  <div>
    <color-dropdown
      v-model="currentColorScheme"
      :plot-color-schemes="plotColorSchemes"
      :current-color="currentColorScheme"
      v-if="modal"
    ></color-dropdown>
    <div :id="`chart-${this.id}`"></div>
  </div>
</template>

<script>
import * as Plot from "@observablehq/plot";
import { processDataset, colorSchemes, colors } from "./utils";
import ColorDropdown from "./colorDropdown.vue";

export default {
  name: "plot-stacked-chart",
  components: {
    ColorDropdown,
  },
  props: [
    "id",
    "chartData",
    "chartLabels",
    "color",
    "country",
    "indicator",
    "plotColorSchemes",
    "modal",
  ],
  data() {
    return {
      data: [],
      labels: [],
      currentColorScheme: "",
    };
  },
  methods: {
    createChart(color) {
      return Plot.plot({
        x: { axis: null },
        fx: {
          label: "Regions",
        },
        y: { tickFormat: (d) => `${d}%`, grid: true, label: "Percentage" },
        color: {
          legend: true,
          scheme: color,
        },
        marks: [
          Plot.barY(this.data, {
            x: "gender",
            y: "value",
            fill: "gender",
            fx: "label",
            //sort: { x: null, color: null, fx: { value: "-y", reduce: "sum" } },
          }),
          Plot.ruleY([0]),
        ],
      });
    },
  },
  mounted() {
    this.currentColorScheme = this.plotColorSchemes[5].value;
    const [newData, labels] = processDataset(
      this.chartData,
      this.chartLabels,
      colorSchemes,
      colors,
      this.country,
      this.indicator
    );
    this.labels = labels;
    this.data = newData.reduce((acc, d) => {
      return acc.concat(
        d.data.map((i, index) => ({
          label: this.labels[index],
          gender: d.label,
          value: i,
        }))
      );
    }, []);

    const chart = this.createChart(this.currentColorScheme);

    document.getElementById(`chart-${this.id}`).appendChild(chart);
  },
  watch: {
    currentColorScheme(newVal) {
      const newChart = this.createChart(newVal);
      // remove the old chart
      const chartContainer = document.getElementById(`chart-${this.id}`);
      chartContainer.innerHTML = "";

      // append the updated chart
      chartContainer.appendChild(newChart);
    },
  },
};
</script>

<style scoped>
#chart {
  width: 100%;
  height: 400px;
}
</style>
