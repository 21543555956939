<script>
import { Bar } from "vue-chartjs";

export default {
  name: "EsgMultipleBarChart",
  extends: Bar,
  components: {
    Bar,
  },
  data() {
    return {
      chartData: {
        labels: ["2020", "2021", "2022"],
        datasets: [
          {
            label: "Environmental",
            data: ["20", "30", "40", "50"],
            backgroundColor: "#43467C",
            barThickness: 32,
            borderRadius: 10,
            maxBarThickness: 26,
          },
          {
            label: "Social",
            data: ["30", "40", "50", "60"],
            backgroundColor: "#FFDE60",
            barThickness: 32,
            borderRadius: 10,
            maxBarThickness: 26,
          },
          {
            label: "Governance",
            data: ["60", "70", "80", "90"],
            backgroundColor: "#4DC9BE",
            barThickness: 32,
            borderRadius: 10,
            maxBarThickness: 26,
          },
          {
            label: "Total",
            data: ["70", "80", "90", "95"],
            backgroundColor: "#FF501C",
            barThickness: 32,
            borderRadius: 10,
            maxBarThickness: 26,
          },
        ],
      },
    };
  },
  async mounted() {
    this.renderChart(
      {
        labels: this.chartData.labels,
        datasets: this.chartData.datasets,
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 100,
                stepSize: 20,
                suggestedMax: this.max,
                fontFamily: "basier",
                fontSize: 12,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
              fontSize: 13,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontFamily: "basier",
            },
          ],
        },
        plugins: {
          legend: {
            position: "bottom",
          },
        },
      }
    );
  },
};
</script>
