<template>
  <div ref="map" class="pt-10"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "Map",
  props: ["country"],
  data() {
    return {
      worldData: [],
      countryData: [],
    };
  },
  mounted() {
    this.country = "Ukraine";
    this.renderMap();
  },
  watch: {
    country: {
      immediate: true,
      handler() {
        console.log(this.country);
        this.renderMap();
      },
    },
  },
  methods: {
    async renderMap() {
      d3.select(this.$refs.map).selectAll("*").remove();
      const width = 960 / 1.5;
      const height = 540 / 1.5;
      const svg = d3
        .select(this.$refs.map)
        .append("svg")
        .attr("width", width)
        .attr("height", height);
      try {
        this.worldData = await d3.json(
          "https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson"
        );
      } catch (error) {
        console.error(error);
      }
      this.countryData = this.worldData.features.find(
        (feature) => feature.properties.name === this.country
      );

      const projection = d3
        .geoNaturalEarth1()
        .fitSize([width, height], this.countryData);
      const path = d3.geoPath(projection);

      svg
        .selectAll("path")
        .data([this.countryData])
        .enter()
        .append("path")
        .attr("d", path)
        .style("fill", "steelblue")
        .attr("transform", `translate(${0},${0})`);
    },
  },
};
</script>
