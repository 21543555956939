<template>
  <div class="flex flex-row w-full bg-gray-100">
    <!-- Confirmation popup -->
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="confirmation">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Confirm your selection!</h3>
                <div class="mt-2" v-if="list.length != user.organization.subscription.countries">
                  <p class="text-sm leading-5 text-gray-500">
                    You have currently selected {{list.length}}. Your subscription allows you to select {{ user.organization.subscription.countries }}.
                  </p>
                  <p v-if="list.length - user.organization.subscription.countries < 0" class="text-sm leading-5 text-gray-500 mt-2 font-bold">
                    You can select
                    {{ user.organization.subscription.countries - list.length }}
                    more.
                  </p>
                  <p v-else-if="list.length - user.organization.subscription.countries > 0" class="text-sm leading-5 text-gray-500 mt-2 font-bold">
                      Please 
                  <router-link :to="{ name: 'plans'}" target="_blank"><u>Upgrade</u></router-link>
                  or unselect a few.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button @click="confirmation=false;" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Cancel
              </button>
              <button v-if="list.length <= user.organization.subscription.countries" @click="save()" class="text-white ml-5 py-2 px-4 rounded-lg text-sm float-right" style="background-color: rgb(32, 23, 71); width: 50%;">
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <sidebar></sidebar>

    <!--.organization.subConfigured-->
    <div
      class="flex flex-col px-10 py-10 w-full"
      style="padding-top: 100px;"
      v-if="!user.organization.subConfigured && user.admin"
    >
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          style="min-height: 50px;"
        >
          <div style="float: left">
            <h1 class="text-2xl mb-6 text-black font-semibold">
              Choose your countries
            </h1>
            <p>
              You can pick
              {{ user.organization.subscription.countries }} country/countries
              from the list below. Once you're ready, you can press the "save"
              button. Don't worry, we show you a popup to confirm your selection!<br />
              You currently have {{ list.length }} countries selected.

              <span
                v-if="
                  list.length - user.organization.subscription.countries < 0
                "
                >You can select
                {{ user.organization.subscription.countries - list.length }}
                more.</span
              >
              <span v-else
                >This/These is/are
                {{ list.length - user.organization.subscription.countries }} to
                much. Please unselect a few.</span
              >
              <br />
            </p>
          </div>
          <div style="float: right; width: 40%;" class="flex flex-row">
            <input
              type="text"
              class="mr-2 border-2 border-gray-200 mb-4 rounded-lg px-4 py-2 text-sm"
              style="width: 65%"
              placeholder="Search..."
              v-model="q"
            />
            <button
              @click="selectAll()"
              class="text-white mr-2 rounded"
              style="background-color: rgb(32, 23, 71); width: 25%; height: 40px; font-size: smaller;"
            >
              Select all
            </button>
            <button
              @click="unselectAll()"
              class="text-white mr-2 rounded"
              style="background-color: rgb(32, 23, 71); width: 25%; height: 40px; font-size: smaller;"
            >
              Unselect all
            </button>
            <button
              @click="confirmSelection()"
              class="text-white mr-2 rounded"
              style="background-color: rgb(32, 23, 71); width: 25%; height: 40px; font-size: smaller;"
            >
              Save
            </button>
          </div>
        </div>

        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200  bg-white">
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    v-bind:key="header.id"
                    class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ header.name }}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="row in results"
                  v-bind:key="row._id"
                  :style="
                    list.indexOf(row._id) > -1 ? 'background-color: grey;' : ''
                  "
                  style="cursor:pointer;"
                  @click="select(row)"
                >
                  <td
                    class="px-6 py-4 whitespace-no-wrap"
                    v-for="header in headers"
                    v-bind:key="row._id + '_' + header.id"
                  >
                    <!-- default field -->
                    <div v-if="header.type === 'text'">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ row[header.id] }}
                      </div>
                    </div>
                    <div v-if="header.type === 'image'">
                      <div class="flex-shrink-0 h-10 w-10">
                        <img class="h-10 w-10" :src="row[header.id]" alt="" />
                      </div>
                    </div>
                  </td>
                </tr>
                <!-- More rows... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="px-10 py-10 w-full" style="padding-top: 100px;" v-else>
      Your admin still needs to configure this!
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import auth from "@/helpers/auth";

import env from "@/../env.js";
import Cookies from "@/helpers/cookies";

const axios = require("axios");

export default {
  name: "godpage",
  data: function() {
    return {
      headers: [],
      searchFields: [],
      editableFields: [],
      user: {},
      countries: [],
      list: [],
      organization: {},
      confirmation: false,
      sortList: 10000,
      q: "",
      // set the name of this item (used in the for example the endpoint.)
      id: "countries",
      title: "Organization countries",
      // possible types are: text, textarea, select (add values:[]), boolean
      fields: [
        {
          id: "flagUrl",
          name: "Flag",
          header: true,
          searchable: false,
          editable: false,
          type: "image",
          placeholder: ""
        },
        {
          id: "name",
          name: "Name",
          header: true,
          searchable: true,
          editable: true,
          type: "text",
          placeholder: "Country"
        },
        {
          id: "region",
          name: "region",
          header: true,
          searchable: true,
          editable: true,
          type: "text",
          placeholder: "Eastern Europe"
        },
        {
          id: "income",
          name: "income",
          header: true,
          searchable: true,
          editable: true,
          type: "text",
          placeholder: "Low income"
        },
        {
          id: "ISO2",
          name: "ISO2",
          header: true,
          searchable: true,
          editable: true,
          type: "text",
          placeholder: ""
        },
        {
          id: "intro",
          name: "Intro",
          header: false,
          searchable: false,
          editable: true,
          type: "textarea",
          placeholder: ""
        }
      ]
    };
  },
  methods: {
    select: function(row) {
      if (this.list.indexOf(row._id) > -1) {
        this.list.splice(this.list.indexOf(row._id), 1);
      } else {
        this.list.push(row._id);
        this.countries.find(c => c._id === row._id).sortScore = this.sortList;
        this.sortList--;
        this.sortCountries();
      }
    },

    selectAll: function() {
      for (var i = 0; i < this.results.length; i++) {
        if (this.list.indexOf(this.results[i]._id) === -1) {
          this.list.push(this.results[i]._id);
        }
      }
    },
    sortCountries: function() {
      this.countries.sort((a, b) => {
        !a.sortScore ? (a.sortScore = 0) : "";
        !b.sortScore ? (b.sortScore = 0) : "";
        return b.sortScore - a.sortScore;
      });
    },
    unselectAll: function() {
      this.list = [];
    },

    message: function(text) {
      alert(text);
    },

    confirmSelection: function () {
      this.confirmation = true;
    },

    closePopup: function() {
      this.confirmation = false;
    },

    save: function() {
        var self = this;
       
      axios
        .put(
          env.apiUrl +
            "/organizations/" +
            this.user.organization._id +
            "/countries",
          {
            countries: this.list
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          }
        )
        .then(function() {
            if(self.user.organization.sectorsEnabled){
                document.location = '/#/settings/configure-subscription-sector';
            }else{
                document.location = '/';
            }
        });
    },

    getCountries: function() {
      const _self = this;
      axios
        .get(env.apiUrl + "/countries", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          _self.countries = res.data.data;
        });
    },
    sortCountryOnSelect: function(row) {
      // this.countries[0].order = 1;
      this.countries.splice(0, 0, row);
      this.countries.splice(row.id, 1);
      // this.countries[0].order = 0;
    }
  },
  components: {
    sidebar
  },
  computed: {
    results: function() {
      const searchResults = [];
      if (this.q) {
        for (var i = 0; i < this.countries.length; i++) {
          for (var f = 0; f < this.searchFields.length; f++) {
            const field = this.searchFields[f];
            if (
              searchResults.indexOf(this.countries[i]) == -1 &&
              this.countries[i] &&
              this.countries[i][field] &&
              this.countries[i][field]
                .toLowerCase()
                .indexOf(this.q.toLowerCase()) > -1
            ) {
              searchResults.push(this.countries[i]);
              //break;
            }
          }
        }
        return searchResults;
      } else {
        return this.countries;
      }
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    // if(this.user.organization.subConfigured){
    //   // You cannot change your countries, if you did not purchase a new subscription
    //   document.location.href = '/';
    // }

    for (var i = 0; i < this.fields.length; i++) {
      if (this.fields[i].searchable) {
        this.searchFields.push(this.fields[i].id);
      }

      if (this.fields[i].header) {
        this.headers.push(this.fields[i]);
      }

      if (this.fields[i].editable) {
        this.editableFields.push(this.fields[i]);
      }
    }

    this.getCountries();
    this.list = this.user.organization.countries;
    this.$store.commit("setBreadcrumbs", [
      { name: "Settings", url: "/settings" },
      {
        name: "Configure subscription",
        url: "/settings/configure-subscription"
      }
    ]);
  }
};
</script>
