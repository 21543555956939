<template>
  <div>
    <div class="pt-8 pb-4">
      <div class="flex justify-between items-center pt-2">
        <div class="text-2xl text-[#21191D] font-bold">
          Number of companies invested in: 8
        </div>
        <div class="flex items-center"></div>
      </div>
      <div class="flex justify-between items-center">
        <select
          name="type"
          id="type"
          class="focus:outline-none cursor-pointer w-72 p-4 rounded bg-white text-sm font-medium leading-none text-[#21191D] flex items-center justify-between border border-[#E1E3E9] rounded-lg"
        >
          <option value="" disabled selected>Select portfolio type</option>
          <option value="Alumni">Alumni</option>
          <option value="Pipeline">Pipeline</option>
          <option value="Portfolio">Portfolio</option>
        </select>
        <button
          class="focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg"
          @click="inviteCompanies = true"
        >
          Invite new investee
        </button>
      </div>
    </div>
    <div class="border-2 rounded-large">
      <table class="min-w-full divide-y divide-[#E1E3E9]">
        <thead class="bg-[#F4F6F9] h-14">
          <tr class="divide-x divide-gray-200">
            <th
              v-for="column in columns"
              :key="column.accessor"
              class="px-4 py-3.5 text-left text-sm font-semibold leading-6 text-[#2C2E35]"
              :style="{
                maxWidth: `${20}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }"
            >
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody
          class="divide-y divide-[#E1E3E9] bg-white"
          v-for="(record, index) in records"
          :key="record.id"
        >
          <tr class="divide-x divide-[#E1E3E9]">
            <td
              v-for="column in columns"
              :key="column.accessor"
              class="p-4 text-base text-[#2C2E35] font-normal"
              :style="{
                maxWidth: `${120}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }"
            >
              <span v-if="column.accessor !== 'companies'">
                {{ record[column.accessor] }}
              </span>

              <router-link
                v-if="column.accessor == 'companies'"
                :to="
                  '/2X-Ecosystem/2X-Certification/PreliminaryResults?teamId=' +
                  record.teamId
                "
                class="text-gray-700 underline cursor-pointer mt-2"
              >
                {{ record[column.accessor] }}
              </router-link>
              <input
                v-if="column.accessor == 'companies'"
                class="h-4 w-4 text-blue-500 ml-2 cursor-pointer mt-2"
                type="checkbox"
                :id="'row-' + record.id"
                v-model="selectedRows[index]"
                :disabled="!record.certificationStatus"
              />
              <div v-if="column.accessor == 'companies'" class="mt-3">
                <img
                  src="@/assets/images/2x_certify/Rightcircle.svg"
                  style="
                    width: 24px;
                    height: 24px;
                    margin: auto 0;
                    position: relative;
                    margin-left: 5px !important;
                  "
                  class="mt-2"
                  alt="background-image"
                />

                <img
                  src="@/assets/images/certificate.svg"
                  style="
                    width: 24px;
                    height: 24px;
                    margin: auto 0;
                    position: relative;
                    margin-left: 5px !important;
                  "
                  class="mt-2 bg-[#4C9F38] rounded-full"
                  alt="background-image"
                />
              </div>
            </td>
          </tr>
          <tr
            v-if="isSelected(record.id) && record.certificationStatus"
            :key="`details-${record.id}-${index}`"
            class="bg-[#F4F6F9] px-10 py-4 h-40"
          >
            <td :colspan="columns.length + 1">
              <div class="flex justify-start items-center">
                <div class="w-full pl-10 flex items-center">
                  <div
                    class="bg-white p-3 rounded-lg h-36 flex items-center w-full max-w-md flex-col"
                  >
                    <div class="flex items-center">
                      <div class="flex items-center">
                        <img
                          class="w-11 mx-auto"
                        />
                        <div class="ml-2">
                          <p class="font-semibold whitespace-nowrap">
                            {{ record.certificationStatus }}
                          </p>
                          <p class="font-normal text-[#717686]">
                            {{ record.certificationDate }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="bg-[#E8AD17] px-3 py-2 font-bold text-white rounded-[24px] w-full max-w-[195px] flex items-center ml-16"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5 text-white mr-1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                        {{ record.validationStatus }}
                      </div>
                    </div>
                    <div
                      class="border-2 border-[#191B45] px-3 py-2 font-bold text-[#191B45] rounded-lg w-full text-center mt-4"
                    >
                      {{ record.certificationRequest }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Invite Companies -->
      <div v-if="inviteCompanies" class="fixed z-50 inset-0 overflow-y-auto">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow transform transition-all -mt-3 sm:align-middle sm:w-full h-full sm:p-4 border max-w-5xl"
          >
          <h2 class="uppercase font-bold text-xl py-2 border-b">Invite your portfolio companies</h2>
          <div>
            <p class="text-gray-600 text-lg leading-normal pt-2">
              Invite an entity to take the 2X Certification Self-Assessment. You may invite your portfolio companies, your pipeline
              as well as your alumni. The results of their Certification Self-Assessment results are then rolled up and aggregated
              into your 2X Portfolio dashboard.
            </p>
          </div>
            <twoxInviteCompanies :isTwoxPortofolio="true" />
            <div class="flex justify-between items-center border-t">
           <div></div>
           <button
           @click="inviteCompanies = false"
           class="bg-[#19486A] font-bold text-white px-4 py-2 rounded-lg shadow mt-3">
           Close
         </button>
         </div>
          </div>
        </div>
      </div>
      <div v-if="inviteCompanies"
                  class="fixed inset-0 transition-opacity z-10 cursor-pointer">
                  <div
                    class="absolute inset-0 bg-black opacity-25"></div>
        </div>
    </div>
  </div>
</template>

<script>
import twoxInviteCompanies from './twox-invite-companies.vue';
export default {
  components: {
    twoxInviteCompanies
  },
  data() {
    return {
      columns: [
        { accessor: "companies", label: "Companies" },
        { accessor: "governanceAccountability", label: "Governance" },
        { accessor: "ESG", label: "ESG" },
        { accessor: "GBVH", label: "GBVH" },
        { accessor: "entrepreneurshipOwnership", label: "Ownership" },
        { accessor: "leadership", label: "Leadership" },
        { accessor: "employment", label: "Employment" },
        { accessor: "supplyChain", label: "Supply Chain" },
        { accessor: "productsServices", label: "Products & Services" },
        { accessor: "portfolio", label: "Portfolio" },
      ],
      records: [
        {
          id: 1,
          companies: "EP_NewAssessTest_Org1",
          teamId: "65fae2352c7ef7d8c5df20cb",
          leadership: "35%",
          GBVH: "35%",
          ESG: "35%",
          portfolio: "35%",
          entrepreneurshipOwnership: "35%",
          employment: "35%",
          productsServices: "35%",
          supplyChain: "35%",
          governanceAccountability: "35%",
          women: "35%",
          certificationImage: "@/assets/images/2x_certification_tier_good.svg",
          certificationStatus: "2X Certified",
          certificationDate: "05.05.2023",
          validationStatus: "Pending verification",
          certificationRequest: "Request to repeat 2X Certification Self-Assessment",
        },
        {
          id: 2,
          companies: "EP_NewAssessTest_Org2",
          teamId: "65fb027b2c7ef7d8c5df84cc",
          GBVH: "35%",
          ESG: "35%",
          leadership: "40%",
          portfolio: "45%",
          entrepreneurshipOwnership: "50%",
          employment: "55%",
          productsServices: "60%",
          supplyChain: "65%",
          governanceAccountability: "70%",
          women: "75%",
          certificationImage: "@/assets/images/2x_certification_tier_good.svg",
          certificationStatus: "2X Certified",
          certificationDate: "05.05.2024",
          validationStatus: "Pending verification",
          certificationRequest: "Request to repeat 2X Certification Self-Assessment",
        },
        {
          id: 3,
          companies: "EP_NewAssessTest_Org3",
          teamId: "6609964014cdc08f264bbad9",
          GBVH: "35%",
          ESG: "35%",
          leadership: "40%",
          portfolio: "45%",
          entrepreneurshipOwnership: "50%",
          employment: "55%",
          productsServices: "60%",
          supplyChain: "65%",
          governanceAccountability: "70%",
          women: "75%",
        },
        {
          id: 4,
          companies: "EP_NewAssessTest_Org4",
          teamId: "660ef11f209a0f0bbfdf09e8",
          GBVH: "35%",
          ESG: "35%",
          leadership: "40%",
          portfolio: "45%",
          entrepreneurshipOwnership: "50%",
          employment: "55%",
          productsServices: "60%",
          supplyChain: "65%",
          governanceAccountability: "70%",
          women: "75%",
        },
      ],
      inviteCompanies: false,
      selectedRows: Array(0).fill(false),
    };
  },
  methods: {
    isSelected(recordId) {
      return this.selectedRows[
        this.records.findIndex((record) => record.id === recordId)
      ];
    },
  },
};
</script>
