<script>
import { Bar } from 'vue-chartjs'
import env from '@/../env.js';

export default {
  name: 'barChartVerticalFund',
  extends: Bar,
  props: ['chartLabels', 'totalValue', 'filledValue', 'label1', 'label2', 'mainTitle', 'type'],
  data: function () {
      return {
        max: 100,
        beginAtZero: true,
        labels: [],
        datasets: [],
        totalValue1: '',
        totalValue2: ''
      }
  },
  mounted() {
    this.renderMyChart();
  },
  methods:{
    renderMyChart(){
      this.totalValue1 = this.totalValue[0];
      this.totalValue2 = this.totalValue[1];
      this.labels= [];
      this.labels = this.chartLabels;
      this.datasets= [
        {
          label: this.label1,
          backgroundColor: this.type === 'leadership' ? '#2BD4C4' : env.genderColors.male,
          data: [this.filledValue[0], this.filledValue[1]]
        },
        {
          label: this.label2 ? this.label2 : null,
          backgroundColor: this.type === 'leadership' ? '#FAE053' : env.genderColors.female,
          data: [(this.totalValue1 - this.filledValue[0]), (this.totalValue2 - this.filledValue[1])]
        }
      ]
      const _self = this;
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      }, {
        responsive: true,
        maintainAspectRatio: false,
          fontFamily: "basier",
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              "fontSize": 13,
              fontFamily: "basier"
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                max: this.max,
                min: 0,
                stepSize: this.max / 5,
                callback: function (value) {
                    return value.toFixed(0) + '%'; // convert it to percentage
                }
              },
              scaleLabel: {
                  display: true,
                  labelString: this.mainTitle ? this.mainTitle : '',
                  fontStyle: 'bold',
                  fontSize: '16',
                  fontFamily: "basier"
              },
              beginAtZero: this.beginAtZero,
              fontFamily: "basier",
              stacked: true,
              fontSize: 12,
            }],
            xAxes: [{   
              fontSize: 13,
              autoSkip: false,               
              maxRotation: 0,
              minRotation: 0,
              fontFamily: "basier",
              stacked: true,        
            }]
          },
          tooltips: {
            titleFontFamily: "basier",
            bodyFontFamily: "basier",
            callbacks: {
              title: function(tooltipItems) {
                  const tt = JSON.parse(JSON.stringify(tooltipItems[0]));
                  const xLabel = tt.xLabel;
                  return xLabel.toString().split(',').join(' ') + '/'+_self.datasets[tooltipItems[0].datasetIndex].label;
              },
              label: function (tooltipItem) {
                return tooltipItem.yLabel+ '%';
              }
            }
        }
      })
    },
  },
  watch: {
    totalValue: function () {
      this.renderMyChart();
    }
  },
}
</script>