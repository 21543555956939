<script>
import { Bar } from "vue-chartjs";
// import env from "@/../env.js";

export default {
    name: "roiPillarBarChartForSingular",
    extends: Bar,
    props: [
        "chartLabels",
        "chartData",
        "created",
        "name"
    ],
    data: function () {
        return {
            max: 100,
            beginAtZero: true,
            labels: [],
            datasets: [],
            hoverLabels: [],
            hoverScores: []
        };
    },

    options: {
        maintainAspectRatio: false,
    },

    mounted() {
        this.renderMyChart();
    },
    methods: {
        renderMyChart() {
            this.labels = [];
            this.labels = this.chartLabels;
            this.hoverScores.push(this.chartData[1].score)
            this.hoverScores.push(this.chartData[0].score)
            this.labels = ["Women", "Total"];
            this.datasets = [
                {
                    label: "hide",
                    borderColor: '#FE5000',
                    backgroundColor: '#FE5000',
                    data: [Number(this.chartData[1].score)],
                    barPercentage: 0.15,
                },
                {
                    label: "hide",
                    borderColor: '#FE500042',
                    backgroundColor: '#FE500042',
                    data: [Number(this.chartData[0].score) - Number(this.chartData[1].score)],
                    barPercentage: 0.15,
                },
            ]
            const _self = this;
            let year = this.created[0].split(" ");
            this.max = Math.max(Number(this.chartData[0].score), (Number(this.chartData[0].score) / 5));

            let length = (String(Math.round(this.max))).split("").length;
            let num, roundNum;
            roundNum = Math.pow(10, length - 1)
            let onesValue = (String(this.max)).split("")[0];
            num = roundNum * (String(this.max)).split("")[0];
            if (this.max < (num + (roundNum/2))) {
                this.max = num + (roundNum/2);
            } else {
                this.max = num + roundNum;
            }
            let stepRatio = this.max / 5;
            let stepDividend = 5 * Math.pow(10, length - 2);
            if (stepRatio % stepDividend != 0 && onesValue > 1 && length > 1) {
                let diff = (stepDividend - (stepRatio % stepDividend))
                this.max = this.max + (diff * 5);
            }
            if(this.max < 10) {
                this.max = 10;
            }

            this.renderChart(
                {
                    labels: [year[0] + ' ' + year[1]],
                    datasets: this.datasets,
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    fontFamily: "basier",
                    legend: {
                        position: "bottom",
                        display: true,
                        labels: {
                            filter: function (item) {
                                // Logic to remove a particular legend item goes here
                                return !item.text.includes("hide");
                            },
                            fontSize: 13,
                            fontFamily: "basier",
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    max: this.max,
                                    min: 0,
                                    stepSize: this.max / 5,
                                    callback: function (value) {
                                        var numFormat = new Intl.NumberFormat("en-US");
                                        return (_self._props.name == "Compensation and Wage Equity" && _self._props.chartLabels[0].includes('total compensation')) ? '$' + (numFormat.format(value)) : '' + (numFormat.format(value));
                                    }
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: "",
                                    fontStyle: "bold",
                                    fontSize: "16",
                                    fontFamily: "basier",
                                },
                                beginAtZero: this.beginAtZero,
                                fontFamily: "basier",
                                stacked: true,
                                fontSize: 16,
                            },
                        ],
                        xAxes: [
                            {
                                fontSize: 13,
                                autoSkip: false,
                                maxRotation: 0,
                                minRotation: 0,
                                fontFamily: "basier",
                                stacked: true,

                            },
                        ],
                    },
                    tooltips: {
                        enabled: false
                    },
                    hover: {
                        onHover: function (e) {
                            // return false;
                            var point = this.getElementAtEvent(e);
                            e.target.style.cursor = 'pointer';
                            var numFormat = new Intl.NumberFormat("en-US");
                            // Prevent from flashing.
                            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
                                if (point[0]._index !== _self.hoverIndex + "_" + point[0]._datasetIndex) {
                                    _self.hoverIndex = point[0]._index + "_" + point[0]._datasetIndex;
                                    document.getElementById("intersection-info").position = "fixed";
                                    document.getElementById("intersection-info").style.zIndex = "99999";
                                    document.getElementById("intersection-info").style.top = e.pageY + "px";
                                    document.getElementById("intersection-info").style.left = e.pageX + "px";
                                    document.getElementById("intersection-info").style.display = "block";
                                    document.getElementById("intersection-name").style.textTransform = 'none';
                                    document.getElementById("intersection-name").innerText = (_self._props.name == "Compensation and Wage Equity" && _self._props.chartLabels[0].includes('total compensation') ? '$' : '') + numFormat.format(Math.round(_self.hoverScores[point[0]._datasetIndex])) + ' ' + _self.labels[point[0]._datasetIndex];
                                    document.getElementById("intersection-domain").style.display = "none";
                                    document.getElementById("div-intersection-country").style.display = "none";
                                    document.getElementById("div-intersection-region").style.display = "none";
                                    document.getElementById("div-intersection-income").style.display = "none";
                                    document.getElementById("div-intersection-country-four").style.display = "none";
                                    document.getElementById("div-intersection-country-five").style.display = "none";
                                    document.getElementById("lmic-avg").style.display = "none";
                                    document.getElementById("div-intersection-validity-power").style.display = "none";
                                    document.getElementById("intersection-theme-link").style.display = "none";
                                    document.getElementById("intersection-domain-link").style.display = "none";
                                }
                            } else {
                                document.getElementById('intersection-info').style.display = 'none';
                                e.target.style.cursor = 'default';
                            }
                        }
                    },

                },
            );
        },
    },
    watch: {
        totalValue: function () {
            this.renderMyChart();
        },
    },
};
</script>
<style scoped>
.chartjs-render-monitor {
    height: 270px !important;
}
</style>