<template>
  <div class="static print:text-sm">
    

    <transition name="fadeIn" mode="out-in">
    <div id="notLoaded" v-if="!country.income" key="loading">
        <page-loader></page-loader>
    </div>

    <div id="loaded" v-if="country.income" key="content">
    <div class="flex flex-col items-center justify-center mt-top-5 w-full">
      <div class="container">

          <div class="flex flex-row justify-center items-center mt-3">
              <div class="flex flex-col item-center justify-center">
                <div class="flex flex-row items-center justify-center print:flex-col">
                    <img class="mr-5 h-16 print:h-auto print:w-64" :src="country.flagUrl"/>
                    <h1 v-if="sector == false" class="text-6xl tracking-tighter font-medium text-center print:text-7xl">{{country.humanName }}</h1>
                    <h1 v-else class="text-4xl tracking-tighter font-medium text-center print:text-7xl">{{sector.name }} in {{country.humanName }}</h1>
                </div>
                  <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center">
                      Region: {{country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' :country.region }} | Income Group: {{toTitle(country.income || '')}}
                      <span v-if="sector && sector.id">| Sector: {{sector.name }}</span>
                  </p>
              </div>
          </div>
      </div>
    </div>
    <section class="w-full lg:w-6/12 container mx-auto mt-32 text-center px-4 lg:px-0" style="max-width: 960px">
      <h1 class="text-4xl print:text-3xl tracking-tighter font-semibold">Key Areas of Improvement</h1>
      <div class="flex flex-col print:mt-10 mx-auto mt-6" style="max-width: 600px">
        <p class="text-gray-700 print:text-sm font-medium">While there is room for improvement to improve gender parity and women's empowerment
          across all analyzed topics in {{country.humanName}}, the following are the areas where there are the most significant
          gaps—and therefore opportunity for improvement.</p>
      </div>
    </section>
    <section class="w-full container mx-auto mt-20 mb-20 px-4 lg:px-0" style="max-width: 1100px">
      <div v-for="intersection in improvements" v-bind:key="intersection.name" v-scroll-reveal>
        <intersection :country="country" :intersection="intersection"></intersection>
      </div>
    </section>

    <section class="w-full container mx-auto mt-32 mb-32 print:hidden px-4 lg:px-0 flex flex-col items-end justify-end gap-4">
       <router-link :to="'/reports/'+country.ISO2+'/'+$route.params.sector+'/themes'" @click="scrollTo('chart')" :style="'background-color:' + env.brandingColors.navy" class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
          <span>View all Themes</span>
          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
      </router-link>
    </section>


    <section class="w-full container mx-auto mt-32 mb-32 px-4 lg:px-0">
      <div class="flex flex-col mt-10 justify-start">
        <p class="text-xs text-gray-500">
          Data Use and Citation: Equilo grants full permission to its users to copy and paste information that appears in the dashboard for their own internal use, directly citing the original data sources and citing Equilo for analysis and scoring. Please request permission from Equilo before utilizing our content for external publications.
        </p>
        <p class="text-xs text-gray-500 my-5">Sources on this page:<br/>
          <ol class="list-decimal mx-5">
            <li v-for="citation in citations" v-bind:key="citation.id">{{((citation || {}).text || '').toString().replace('n.d.', citation.year + '. ')}} (<a target="_blank" :href="citation.url" style="word-wrap: break-word;">{{citation.url}}</a>)</li>

      </ol>
        <p class="text-xs text-gray-500">
          Equilo uses the TAAP framework, which stands for Transforming Agency, Access, and Power. Comprised of six domains, the TAAP framework provides a structure to analyze and amplify the voices, opportunities, and dignity of all people, especially those from socially excluded groups. More information about this approach can be found at taapinclusion.org. Equilo Dash uses 15 themes that cut across two or more of the TAAP domains, for a total of 32 unique TAAP domain/theme intersections for analysis and scoring. Each gender equality score is based on a specific set of indicators within a given intersection.
        </p>


      </div>
    </section>
    </div>
    </transition>
  </div>
</template>

<script>
  import axios from 'axios';
  import env from '@/../env.js';
  import text from '@/helpers/texts';
  import cms from '@/helpers/cms';
  import Cookies from '@/helpers/cookies'
  import pageLoader from '@/components/page-loader.vue';

  import intersection from '@/components/theme-intersection';
  import auth from '@/helpers/auth';


  export default {
    name: 'country-improvements',
    components: {
      intersection,
      pageLoader
    },
    data: function () {
      return {
        country: {},
        improvements: [],
        cms,
        env,
        sector: { name: ''},
      };
    },
    methods: {
      format: function (input) {
        return text.format(input, this.country, this.country.values);
      },
      value: function (input) {
        return this.format('{{' + input + '}}')
      },
      getSource: async function(sourceId){
        await axios.get(env.apiUrl+"/sources/"+sourceId, {
          headers: {
            'Authorization': 'Bearer '+Cookies.get('session')
          }
        });
        return 1
      },
      getSector: function () {
          const _self = this;
          return new Promise(function (resolve) {

            // Validate if you want general country information (count/base) or a specific sector
            if(['BASE', 'country'].indexOf(_self.$route.params.sector) > -1){
              resolve(false);
            } else {
              axios.get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
                  headers: {
                      'Authorization': 'Bearer ' + Cookies.get('session')
                  }
              }).then(function (res) {
                resolve(res.data.data)
              });
            }
          })
      },
      getCountry: function () {
          const _self = this;
          return new Promise(function (resolve) {
              axios.get(env.apiUrl + "/reports/" + _self.$route.params.iso2+ "/sectors/" + _self.$route.params.sector, {
                  headers: {
                      'Authorization': 'Bearer ' + Cookies.get('session')
                  }
              }).then(function (res) {
                  resolve(res.data.data)
              });
          })
      },
      toTitle(str) {
          return str.replace(/\w\S*/g, function(txt){
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
      },
    },
    computed: {
      citations() {
        const compare = ( a, b ) => {
          if ( a.rank < b.rank ){
            return -1;
          }
          if ( a.rank > b.rank ){
            return 1;
          }
          return 0;
        }
        const x = this.$store.state.citations;
      return x.sort(compare)
      },
    },
  async created() {
      // validate if you have access to this country.
      this.user = await auth.me().catch(() => {});
      if (this.$route.params.iso2 != 'EQ' && !this.user.organization.accessGesi ||  document.location.href.indexOf('print=') > -1){
        this.$router.push({ name: 'notAllowed' })
      }

      this.$store.commit('resetCitations');

      this.country = await this.getCountry(); // lets do an await before rendering other items.
      this.sector = await this.getSector(); // lets do an await before rendering other items.

      this.improvements = this.country.intersections.slice(0, 5)
      this.$store.commit('downloadable', true);
      this.$store.commit('setBreadcrumbs', [{
          'name': 'Countries',
          'url': '/'
        },
        {
          'name': this.country.humanName,
          'url': '/reports/' + this.country.ISO2+'/choose-sector'
        },
        {'name': (this.sector || { name: 'country'}).name, 'url': '/reports/'+ this.$route.params.iso2+'/'+this.$route.params.sector},
        {
          'name': 'Key Areas of Improvement',
          'url': '/reports/' + this.country.ISO2+'/'+this.$route.params.sector + '/improvements'
        },
      ])




    }
  };
</script>
