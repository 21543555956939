<template>
  <div>
    <color-dropdown
      v-model="currentColorScheme"
      :plot-color-schemes="plotColorSchemes"
      :current-color="currentColorScheme"
      v-if="modal"
    ></color-dropdown>
    <div :id="`chart-${this.id}`"></div>
  </div>
</template>

<script>
import * as Plot from "@observablehq/plot";
import { processDataset, colorSchemes, colors, getMaxValue } from "./utils";
import ColorDropdown from "./colorDropdown.vue";

export default {
  name: "plot-bubble-chart",
  components: {
    ColorDropdown,
  },
  props: [
    "id",
    "chartData",
    "chartLabels",
    "color",
    "country",
    "indicator",
    "plotColorSchemes",
    "modal",
  ],
  data() {
    return {
      data: [],
      labels: [],
      currentColorScheme: "",
    };
  },
  methods: {
    createChart(color) {
      const maxValue = getMaxValue(this.data);
      return Plot.plot({
        height: 400,
        grid: true,
        y: {
          label: "Gender",
        },
        r: {
          type: "linear",
          domain: [0, maxValue],
        },
        color: {
          scheme: color,
          legend: true,
        },
        marks: [
          Plot.axisY({ tickRotate: -90 }),
          Plot.dot(this.data, {
            x: { value: "label", label: "Regions" },
            r: (d) => +d.value * 25,
            y: { value: "gender" },
            fill: "gender",
            fillOpacity: 0.5,
            stroke: "gender",
            strokeWidth: 2,
            tip: true,
          }),
        ],
      });
    },
  },
  mounted() {
    this.currentColorScheme = this.plotColorSchemes[4].value;
    const [newData, labels] = processDataset(
      this.chartData,
      this.chartLabels,
      colorSchemes,
      colors,
      this.country,
      this.indicator
    );
    // check for mismatched axis
    if (labels.length === 2) {
      this.labels = newData.map((d) => d.label);
      this.data = [].concat(
        ...newData.map((x, xIndex) => {
          return x.data.map((y, yIndex) => {
            console.log(y);
            return {
              label: this.labels[xIndex],
              gender: labels[yIndex],
              value: y === "Missing" ? 0 : Number(y).toFixed(2),
            };
          });
        })
      );
    } else {
      this.labels = labels;

      this.data = newData.reduce((acc, d) => {
        return acc.concat(
          d.data.map((i, index) => ({
            label: this.labels[index],
            gender: d.label,
            value: i === "Missing" ? 0 : Number(i).toFixed(2),
          }))
        );
      }, []);
    }

    const chart = this.createChart(this.currentColorScheme);

    document.getElementById(`chart-${this.id}`).appendChild(chart);
  },
  watch: {
    currentColorScheme(newVal) {
      const newChart = this.createChart(newVal);

      // remove the old chart
      const chartContainer = document.getElementById(`chart-${this.id}`);
      chartContainer.innerHTML = "";

      // append the updated chart
      chartContainer.appendChild(newChart);
    },
  },
};
</script>

<style scoped>
#chart {
  width: 100%;
  height: 400px;
}
</style>
