<template>
  <div class="static">
      <div id="notLoaded" v-if="!endofcreated && !domain.intro">
          <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated && domain.intro">
          <div class="flex flex-col items-center justify-center mt-top-5 w-full" v-if="initHtml()">
            <div class="container">

                <div class="flex flex-row justify-center items-center mt-3">
                    <div class="flex flex-col item-center justify-center">
                      <div class="flex flex-row items-center justify-center print:flex-col">
                          <img class="mr-5 h-16 print:h-auto print:w-64" v-if="countryData.countries.length == 1" :src="country.flagUrl"/>
                          <h1 v-if="sector == false && (countryData.countries.length == 1)" class="text-6xl tracking-tighter font-medium text-center print:text-7xl">{{country.humanName }}</h1>
                          <h1
                            v-if="sector"
                            class="text-4xl tracking-tighter font-medium text-center print:text-7xl"
                          >
                            {{ sector.name }} <span v-if="countryData.countries.length == 1">in {{ country.humanName }}</span>
                          </h1>
                          <h1
                            v-if="sector == false && countryData.countries.length > 1"
                            class="text-4xl tracking-tighter font-medium text-center print:text-7xl"
                          >
                            Multiple Countries
                          </h1>
                      </div>
                        <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center" v-if="countryData.countries.length == 1">
                            Region: {{country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' :country.region }} | Income Group: {{toTitle(country.income || '')}}
                            <span v-if="sector && sector.id">| Sector: {{sector.name }}</span>
                        </p>
                    </div>
                </div>
            </div>
              <div id="buttonContainer" class="flex flex-row items-center justify-center gap-2 mt-10">
                  <button @click="scrollTo('SectionIntroduction')"
                          :style="'background-color:' + env.brandingColors.navy"
                          class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                      <span>Scroll to Introduction</span>
                  </button>
                  <button @click="scrollTo('SectionGenderEquality')"
                          :style="'background-color:' + env.brandingColors.navy"
                          class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                      <span>Scroll to Gender Equality Scores</span>
                  </button>
                  <button @click="scrollTo('SectionIndicators')"
                          :style="'background-color:' + env.brandingColors.navy"
                          class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                      <span>Scroll to Indicators</span>
                  </button>
                  <button @click="scrollTo('SectionWhatWorks')" :style="'background-color:' + env.brandingColors.navy"
                          class="px-3 py-2 text-xs hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-40indigo-500 hover:text-0 flex flex-row items-center justify-center gap-2">
                      <span>Scroll to What works</span>
                  </button>
              </div>
              <div
          class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4" style="width: 50%;  margin-top: 13px;"
        >
          <svg
            class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
            id="Layer_3"
            data-name="Layer 3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>Artboard 329</title>
            <circle
              cx="11.059"
              cy="11.059"
              r="7.059"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <line
              x1="20"
              y1="20"
              x2="16.05"
              y2="16.05"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <rect width="24" height="24" fill="none" />
          </svg>
          <v-select 
           class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
              
            placeholder="Select Indicators"
            label="indicator"
            @input="redirectIndicator"
          :options="themesOptions"></v-select>

    
        </div>
              <!-- <div id="sideNav" class="sideNavMenu gap-2">
                  <button @click="scrollTo('SectionIntroduction')"                          
                          class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 flex flex-row items-center justify-center gap-2 mt-5">
                      <span>Introduction</span>
                  </button>
                  <button @click="scrollTo('SectionGenderEquality')"                         
                          class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 flex flex-row items-center justify-center gap-2 mt-5">
                      <span>Gender Equality Scores</span>
                  </button>
                  <button @click="scrollTo('SectionIndicators')"
                          class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 flex flex-row items-center justify-center gap-2 mt-5">
                      <span>Indicators</span>
                  </button>
                  <button @click="scrollTo('SectionWhatWorks')" 
                          class="px-3 py-2 text-xs font-normal rounded-lg focus:outline-none focus:shadow-outline-indigo text-gray-600 transition duration-200 flex flex-row items-center justify-center gap-2 mt-5">
                      <span>What works</span>
                  </button> 
              </div> -->
          </div>
          <main>
              <div class="navsticky">
                  <span>Scroll to...</span>
                  <ol class="global_analysis_side_bar_list">
                      <li><button @click="scrollTo('SectionIntroduction')" id="SectionIntroduction1" class="buttonlink" style="margin-left:-10px;"><span>Introduction</span></button></li>
                      <li><button @click="scrollTo('SectionGenderEquality')" id="SectionGenderEquality1" class="buttonlink" style="margin-left:-10px;"><span>Snapshot<span style="visibility: hidden;">extra</span></span></button></li>
                      <li ><button  @click="scrollTo('SectionIndicators')" id="SectionIndicators1" class="buttonlink" style="margin-left:-10px;"><span>Indicators</span></button></li>
                  </ol>
                  <br>
                  <span>Go to...</span>
                  <ol class="global_analysis_side_bar_list">
                      <li><router-link :to="'/reports/'+$route.params.iso2+'/'+$route.params.sector+'/what-works'" class="buttonlink" style="margin-left:-10px;"><span>What Works</span></router-link></li>
                      <li><router-link :to="'/reports/'+$route.params.iso2+'/'+$route.params.sector+'/domains'" class="buttonlink" style="margin-left:-10px;"><span>Domains</span></router-link></li>
                      <li><router-link :to="'/reports/'+country.ISO2+'/'+$route.params.sector+'/themes'" class="buttonlink" style="margin-left:-10px;" v-if="countryData.countries.length == 1"><span>Themes</span></router-link></li>
                  </ol>
              </div>
          <div>  
              
          <div id="SectionIntroduction" class="maincontent"><span style="visibility: hidden;">SectionIntroduction</span></div>
          <section class="w-full container mx-auto mt-20" style="max-width: 960px">
              <h1 class="text-4xl print:text-4xl text-black tracking-tighter font-medium text-center"
                  :style="'color:' + env.brandingColors.navy"
                  >{{domainData.name}}</h1>
              <div class="flex flex-col mt-10">
                  <div class="flex">
                    <p class="text-2xl leading-6 font-medium text-blue-500 mb-2"
                    style="margin: auto 0;">1. Introduction</p>
                  </div>
                  <div class="text-gray-600 w-full mt-3">
                      <item :text="domainData.intro" :country="country"
                            class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"></item>
                  </div>
              </div>
          </section>

          <div id="SectionGenderEquality" class="maincontent"><span style="visibility: hidden;">SectionGenderEquality</span></div>
          <section class="w-full mx-auto mt-20 block overflow-hidden" style="max-width: 960px" v-scroll-reveal>
              <div class="flex flex-col mt-10">
                  <p class="text-2xl leading-6 font-medium text-blue-500 mb-2">2. Overview of Gender
                      Equality Scores for {{domainData.name}} by Theme
                  </p>
                  <div class="text-gray-600 w-full">
                      <p>
                          The following chart depicts how
                          <span v-for="(cont, i) in domainData.countries" :key="'cont'+i">
                            <span v-if="i < domainData.countries.length - 1"> {{cont.humanName + ", "}}</span>
                            <span v-else> {{cont.humanName}}</span>
                          </span>
                          measures up to other countries in the
                          region and to
                          all
                          {{country.income}} countries globally. Each point represents the Gender Equality score at
                          the intersection
                          of the theme and <span class="triggerElement hoverTrigger">TAAP domain.
                          <span class="hoverMethodology absolutetop-0 right-5 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200">
                              <methodology />
                          </span>
                      </span>
                      </p>
                  </div>
                  <div class="block pb-i-avoid">
                      <div class="flex flex-col items-center mt-16">
                          <h1 class="text-lg leading-6 font-medium">Gender Equality Scores for {{domainData.name}} by Theme</h1>
                          <p class="text-gray-600">Hover on the chart to get more information about the
                              <span class="triggerElement hoverTrigger">domains
                              <span class="hoverMethodology absolutetop-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200">
                                  <methodology />
                              </span>
                              </span></p>
                          <p class="text-gray-500 text-sm font-medium mt-2">1.0 = Perfect gender equality</p>
                          <br/><br/>
                          <div class="relative container mx-auto flex flex-row items-center justify-center">
                              <spiderchart-multiple
                              chartCategory="domain"
                              :styles="myStyles"
                              :themeData="domainData"
                              :countryD="countryData"
                              :iso="$route.params.iso2"
                              key="1"
                              v-if="domain.intersections.length > 2 && countryData.countries.length > 1"
                              id="spiderchart"
                              ></spiderchart-multiple>

                              <spiderchart
                              v-if="domain.intersections.length > 2 && countryData.countries.length == 1"
                              :domainId="$route.params.domainId"
                              :iso="$route.params.iso2"
                              :country="country"
                              :domain="domain"
                              :styles="myStyles">
                              </spiderchart>

                              <lineChart-multiple
                              chartCategory="domain"
                              :styles="myStylesLine"
                              :themeId="$route.params.themeId"
                              :themeData="domainData"
                              :countryD="countryData"
                              key="3"
                              :iso="$route.params.iso2"
                              id="intersection-bar-chart"
                              v-if="domain.intersections.length < 3 && countryData.countries.length > 1"
                              >
                              </lineChart-multiple>

                              <lineChart
                              :domainId="$route.params.domainId"
                              :iso="$route.params.iso2" class="mb-10"
                              :country="country" :domain="domain"
                              :styles="myStyles"
                              v-if="domain.intersections.length < 3 && countryData.countries.length == 1">
                              </lineChart>
                          </div>

                      </div>
                  </div>
              </div>
          </section>
          <div id="SectionIndicators" class="maincontent"><span style="visibility: hidden;">SectionIndicators</span></div>
          <section
                  class="w-full container mx-auto mt-20 px-4 lg:px-20 py-10 bg-gray-100 border border-gray-200 rounded-lg shadow-2xl">
              <div class="flex flex-col mt-10" v-scroll-reveal>
                  <p class="text-2xl leading-6 font-medium mb-2">3. Explore The Indicators</p>
                  <div class="text-gray-600 w-full text-justify font-medium leading-7 mt-3">
                      <p>
                          Scroll down to explore data related to this specific TAAP domain. Each indicator includes
                          country-specific
                          data, accompanied by regional and global averages for comparison. A short descriptive
                          narrative accompanies
                          each statistic to help understand the "so what." Most of these indicators power the gender
                          equality score
                          for this TAAP domain, although some supplementary descriptive statistics may appear.
                      </p>
                  </div>
              </div>

              <!-- FOR EACH INDICATOR -->

              <div v-for="(indicator, index) in chartDataForCharts[0].indicators"
                   v-bind:key="indicator._id">
                  <div class="flex flex-row w-full mt-32" :id="'indicator' + indicator.name.toLowerCase().trim().split(/\s+/).join('-')">
                      <div class="flex flex-col w-6/12 pr-5">
                          <p class="tracking-tight font-medium mb-2">3.{{index+1}}. {{indicator.name}}</p>
                          <div>
                              <div class="rounded-lg text-black flex flex-col text-md font-medium gap-2">
                                  <p class="flex flex-row items-center justify-start text-blue-600">
                                      <svg class="w-5 h-5  mx-2" :style="'color: ' + indicatorColor(indicator.taab)"
                                          version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink">
                                          <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                                          <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor"
                                          fill="none" stroke-linejoin="round">
                                              <path d="M19,12h-14"></path>
                                              <path d="M14,17l5,-5"></path>
                                              <path d="M14,7l5,5"></path>
                                          </g>
                                          <path fill="none" d="M0,0h24v24h-24Z"></path>
                                      </svg>
                                      <a :href="'/#/reports/' + $route.params.iso2 + '/'+$route.params.sector+'/themes/' + indicator.theme"
                                      :style="'color: ' + indicatorColor(indicator.taab)">Theme:
                                          {{indicator.theme}} </a>
                                  </p>
                                  <p class="flex flex-row items-center justify-start text-blue-600" v-if="indicator.meta.scored || indicator.meta.scored === 'true'" :style="'color: ' + env.brandingColors.navy">
                                      <i>Contributes to the Gender Equality Score</i></p>
                                  <p v-if="indicator.meta.scored===false || indicator.meta.scored === 'false'" class="flex flex-row items-center justify-start" :style="'color: ' + env.brandingColors.navy">
                                      <i>Contextual Indicator (Non-Scored)</i></p>
                                  <p class="flex flex-row items-center justify-start text-blue-600" v-if="indicator.meta.proxyUsed == 'No' || indicator.meta.proxyUsed === 'Missing'" :style="'color: ' + env.brandingColors.navy">
                                      <i>Internationally Standardized Indicator</i></p>
                                  <p class="flex flex-row items-center justify-start text-blue-600" v-if="indicator.meta.proxyUsed == 'Alt'" :style="'color: ' + env.brandingColors.navy">
                                      <i>Proxy Data Used (Non-Standard)</i></p>
                                  <p class="flex flex-row items-center justify-start text-blue-600" v-if="indicator.meta.proxyUsed == 'Old'" :style="'color: ' + env.brandingColors.navy">
                                      <i>Indicator with Outdated Data</i></p>
                                  <p v-if="indicator.sectorSpecific == true" class="flex flex-row items-center justify-start" :style="'color: ' + env.brandingColors.navy">
                                      <i>Sector-Specific Indicator</i>
                                      <img class="ml-1 w-1/12 h-5" v-if="$route.params.sector != 'country'" :src="currentIndustry.icon" /></p>
                              </div>


                          </div>
                          <p class="text-gray-600 mt-5 text-justify">
                              <rounded-item :text="indicator.text" :country="country" :currency="currency"></rounded-item>
                          </p>

                          <p class="text-gray-600 mt-5 text-justify" v-if="countryData.countries.length == 1">
                              <rounded-item :text="indicator.chartText" :country="country" :currency="currency"></rounded-item>
                          </p>

                      </div>
                      <div class="flex flex-col items-center w-6/12">
                          <div class="margin-left: 20px;">

                              <doughnutChart :index="index+1" :country="country" :chartData="indicator.chartData"
                                             :chartLabels="indicator.chartLabels" :indicator="indicator"
                                             v-if="indicator.chartType === 'doughnutChart' && countryData.countries.length == 1">
                              </doughnutChart>
                              <doughnutChart-multiple
                                  class="print:scale-75 transform radarchart"
                                  :index="index + 1"
                                  :countryD="countryData.countries"
                                  :chartData="indicator.chartData"
                                  :chartLabels="indicator.chartLabels"
                                  :chartLabelsForCharts="chartLabelsForCharts"
                                  :indicator="indicator"
                                  v-if="indicator.chartType == 'doughnutChart' && countryData.countries.length > 1"
                              >
                              </doughnutChart-multiple>

                              <barChartVertical :index="index+1" :country="country" :chartData="indicator.chartData"
                                                :chartLabels="indicator.chartLabels" :indicator="indicator"
                                                v-else-if="indicator.chartType === 'barChartVertical' && countryData.countries.length == 1">
                              </barChartVertical>
                              <div v-if="indicator.chartData.length > 5 && indicator.chartType == 'barChartVertical'">
                                  <div v-for="(countr, contIndex) in domainDataUnfiltered.countries" :key="'countr'+contIndex">
                                  <div style="font-size: 20px;font-weight: 600;text-align: center;margin: 15px;">{{countr.humanName}}</div>
                                      <barChartVertical
                                      class="print:scale-75 transform radarchart"
                                      :index="index + 1"
                                      :country="countryData.countries[contIndex]"
                                      :chartData="countr.indicators[index].chartData"
                                      :chartLabels="countr.indicators[index].chartLabels"
                                      :indicator="countr.indicators[index]"
                                      v-if="countr.indicators[index].chartType == 'barChartVertical' && countryData.countries.length > 1"
                                  >
                                  </barChartVertical>
                                  </div>
                              </div>
                              <barChartVertical-multiple
                                  class="print:scale-75 transform radarchart"
                                  :index="index + 1"
                                  :countryD="countryData.countries"
                                  :chartData="indicator.chartData"
                                  :chartLabels="indicator.chartLabels"
                                  :chartLabelsForCharts="chartLabelsForCharts"
                                  :indicator="indicator"
                                  v-if="indicator.chartType == 'barChartVertical' && countryData.countries.length > 1 && indicator.chartData.length < 6 && indicator.chartLabels[0] == '{{country}}'"
                              >
                              </barChartVertical-multiple>
                              <barChartVertical-multiple-for-one-variable
                                  class="print:scale-75 transform radarchart"
                                  :index="index + 1"
                                  :countryD="countryData.countries"
                                  :chartData="indicator.chartData"
                                  :chartLabels="indicator.chartLabels"
                                  :chartLabelsForCharts="chartLabelsForCharts"
                                  :indicator="indicator"
                                  v-else-if="indicator.chartType == 'barChartVertical' && countryData.countries.length > 1 && indicator.chartData.length < 6 && indicator.chartLabels[0] != '{{country}}' && (indicator.chartLabels.length == 1 || indicator.chartLabels.length == 2)"
                              >
                              </barChartVertical-multiple-for-one-variable>
                              <div v-else>
                                  <div v-if="indicator.chartType == 'barChartVertical' && countryData.countries.length > 1 && indicator.chartData.length < 6">
                                  <div v-for="(countr, contIndex) in domainDataUnfiltered.countries" :key="'countr1'+contIndex">
                                      <div style="font-size: 20px;font-weight: 600;text-align: center;margin: 15px;">{{countr.humanName}}</div>
                                      <barChartVertical
                                      class="print:scale-75 transform radarchart"
                                      :index="index + 1"
                                      :country="countryData.countries[contIndex]"
                                      :chartData="countr.indicators[index] ? countr.indicators[index].chartData : ''"
                                      :chartLabels="countr.indicators[index] ? countr.indicators[index].chartLabels : ''"
                                      :indicator="countr.indicators[index]"
                                      >
                                      </barChartVertical>
                                  </div>
                                  </div>
                              </div>

                              <radarChart :index="index+1" :country="domainDataUnfiltered.countries[0]" :chartData="indicator.chartData"
                                          :chartLabels="indicator.chartLabels" :indicator="indicator"
                                          v-if="indicator.chartType === 'radarChart' && countryData.countries.length == 1">
                              </radarChart>
                              <radarChart-multiple
                                  class="print:scale-75 transform radarchart"
                                  :index="index + 1"
                                  :countryD="countryData.countries"
                                  :chartData="indicator.chartData"
                                  :chartLabels="indicator.chartLabels"
                                  :chartLabelsForCharts="chartLabelsForCharts"
                                  :indicator="indicator"
                                  v-if="indicator.chartType == 'radarChart' && countryData.countries.length > 1"
                              >
                              </radarChart-multiple>

                              <bubbleChart :index="index+1" :country="country" :chartData="indicator.chartData"
                                           :chartLabels="indicator.chartLabels" :indicator="indicator"
                                           v-else-if="indicator.chartType === 'bubbleChart' && countryData.countries.length == 1">
                              </bubbleChart>
                              <div v-if="indicator.chartData.length > 1 && indicator.chartType == 'bubbleChart'">
                                <div v-for="(countr, contIndex) in domainDataUnfiltered.countries" :key="'countr2'+contIndex">
                                <div style="font-size: 20px;font-weight: 600;text-align: center;margin: 15px;">{{countr.humanName}}</div>
                                  <bubbleChart
                                    class="print:scale-75 transform radarchart"
                                    :index="index + 1"
                                    :country="countryData.countries[contIndex]"
                                    :chartData="countr.indicators[index].chartData"
                                    :chartLabels="countr.indicators[index].chartLabels"
                                    :indicator="countr.indicators[index]"
                                    v-if="indicator.chartType == 'bubbleChart' && countryData.countries.length > 1"
                                  >
                                  </bubbleChart>
                                </div>
                              </div>
                              <bubbleChart-multiple
                                  class="print:scale-75 transform radarchart"
                                  :index="index + 1"
                                  :countryD="countryData.countries"
                                  :chartData="indicator.chartData"
                                  :chartLabels="indicator.chartLabels"
                                  :chartLabelsForCharts="chartLabelsForCharts"
                                  :indicator="indicator"
                                  v-if="indicator.chartType == 'bubbleChart' && countryData.countries.length > 1 && indicator.chartData.length == 1"
                              >
                              </bubbleChart-multiple>

                              <checkCrossChart :index="index+1" :country="country" :chartData="indicator.chartData"
                                               :chartLabels="indicator.chartLabels" :indicator="indicator"
                                               v-else-if="indicator.chartType === 'checkcross' && countryData.countries.length == 1">
                              </checkCrossChart>
                              <div v-if="indicator.chartData.length > 1 && indicator.chartType == 'checkcross'">
                                  <div v-for="(countr, contIndex) in domainData.countries" :key="'countr3'+contIndex">
                                  <div style="font-size: 20px;font-weight: 600;text-align: center;margin: 15px;">{{countr.humanName}}</div>
                                  <checkCrossChart
                                      class="print:scale-75 transform radarchart"
                                      :index="index + 1"
                                      :country="countryData.countries[contIndex]"
                                      :chartData="countr.indicators[index].chartData"
                                      :chartLabels="countr.indicators[index].chartLabels"
                                      :indicator="countr.indicators[index]"
                                      v-if="indicator.chartType == 'checkcross' && countryData.countries.length > 1"
                                  >
                                  </checkCrossChart>
                                  </div>
                              </div>
                              <checkCrossChart-multiple
                                  class="print:scale-75 transform radarchart"
                                  :index="index + 1"
                                  :countryD="countryData.countries"
                                  :chartData="indicator.chartData"
                                  :chartLabels="indicator.chartLabels"
                                  :chartLabelsForCharts="chartLabelsForCharts"
                                  :indicator="indicator"
                                  v-if="indicator.chartType == 'checkcross' && countryData.countries.length > 1 && indicator.chartData.length == 1"
                              >
                              </checkCrossChart-multiple>

                              <tierChart :index="index+1" :country="domainDataUnfiltered.countries[0]" :chartData="indicator.chartData" class=""
                                         :chartLabels="indicator.chartLabels" :indicator="indicator"
                                         v-else-if="indicator.chartType === 'tierChart' && countryData.countries.length == 1">
                              </tierChart>
                              <tierChart-multiple
                                  class="print:scale-75 transform radarchart"
                                  :index="index + 1"
                                  :countryD="countryData.countries"
                                  :chartData="indicator.chartData"
                                  :chartLabels="indicator.chartLabels"
                                  :chartLabelsForCharts="chartLabelsForCharts"
                                  :indicator="indicator"
                                  v-if="indicator.chartType == 'tierChart' && countryData.countries.length > 1"
                              >
                              </tierChart-multiple>

                              <!-- <div v-else>
                                  {{indicator.chartType}}
                              </div> -->

                              <label class="recentlyUpdatedLabel" v-if="indicator.meta && checkRecentlyUpdated(indicator.meta.createdTime)">
                                  Recently updated!
                              </label>
                              
                              <div class="histData w-full">
                                  <historicalData
                                      v-if="countryData.countries.length == 1"
                                      :indicator="indicator"
                                      :country="country"
                                  ></historicalData>
                                  <!-- <p v-if="(indicator.unknownHist > 0) & (indicator.unknownHist == indicator.variables.length)" class="text-sm"><i>No historical data available for these indicators.</i></p> -->
                                  <p v-if="(indicator.unknownHist > 0) & (indicator.unknownHist != indicator.variables.length)" class="text-sm mt-4"><i>No historical data available for the other indicators.</i></p>
                              </div>

                              <!-- <img src="@/assets/images/rwanda3.jpg" class="h-64" alt=""> -->
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <div id="SectionWhatWorks" class="maincontent"><span style="visibility: hidden;">SectionWhatWorks</span></div>
          <section class="w-full container mx-auto mt-32 px-4 lg:px:0" style="max-width: 960px">
              <div class="flex flex-col mt-10 mb-10" v-scroll-reveal>
                  <p class="text-lg leading-6 font-medium text-gray-900 mb-2">4. What Works?</p>
                  <div class="text-gray-600 w-full text-justify">
                      <p>
                          It's time for action! Understanding the challenges and opportunities to improve gender
                          equality is the
                          important first step to support action and change. The next step is taking that information
                          and doing
                          something about it. Click the button below to read information
                          about what works to harness gender equality opportunities and
                          address challenges for this theme, including resources and tools
                          to support action.
                      </p>
                  </div>
                  <div class="w-full container mx-auto print:hidden px-4 lg:px-0 flex flex-col items-start justify-start gap-4">
                      <router-link
                          :to="'/reports/' + $route.params.iso2 + '/' + $route.params.sector + '/what-works'"
                          :style="'background-color:' + env.brandingColors.navy"
                          class="px-3 py-2 my-4 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2"
                      >
                          <p class="font-medium capitalize ml-3">See What Works!</p>
                          <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-arrow-right"
                          >
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                          <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                      </router-link>
                      </div>
              </div>

          </section>
          
          <section
                  class="w-full container mx-auto mt-32 mb-32 print:hidden px-4 lg:px-0 flex flex-col items-end justify-start gap-4"
                  v-if="!print">
              <router-link :to="'/reports/'+$route.params.iso2+'/'+$route.params.sector+'/domains'"
                           :style="'background-color:' + env.brandingColors.navy"
                           class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                  <p class="font-medium capitalize ml-3">Back to All Domains</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="feather feather-arrow-right">
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                  
              </router-link>
              <router-link :to="'/reports/'+$route.params.iso2+'/'+$route.params.sector+'/themes'"
                            v-if="countryData.countries.length == 1"
                           :style="'background-color:' + env.brandingColors.navy"
                           class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                  <p class="font-medium capitalize ml-3">Back to All themes</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="feather feather-arrow-right">
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                  
              </router-link>
              <router-link :to="'/gesi-analysis/'+$route.params.iso2+'/'+$route.params.sector+'/snapshot'" :style="'background-color:' + env.brandingColors.navy"
                           class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                  <p class="font-medium capitalize ml-3" v-if="sector && sector.id">Back to sector overview</p>
                  <p class="font-medium capitalize ml-3" v-else>Back to country overview</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="feather feather-arrow-right">
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                  


              </router-link>



          </section>

          <section class="w-full container mx-auto mt-32 mb-32" style="max-width: 960px">
              <div class="flex flex-col mt-10 justify-start">
                  <p class="text-xs text-gray-500">
                      Data Use and Citation: Equilo grants full permission to its users to copy and paste information
                      that appears in the dashboard for their own internal use, directly citing the original data
                      sources and citing Equilo for analysis and scoring. Please request permission from Equilo before
                      utilizing our content for external publications.
                  </p>
                  <p class="text-xs text-gray-500 mt-5 mb-1" style="max-width: 960px">Sources on this page:<br/>
                  <ol class="list-decimal mx-5">
                    <li v-for="citation in allBibliographyData" v-bind:key="citation.id + String(Math.floor(Math.random() * 1000))">{{((citation || {}).text || '').toString().replace('n.d.', citation.year + '. ')}}
                      {{ citation && citation.text ? "" : "Source not found "}}
                       (<a target="_blank" :href="citation.url" style="word-wrap: break-word;">{{citation.url}}</a>)</li>

                  </ol>
                  </p>

                  <p class="text-xs text-gray-500 mt-5" style="max-width: 960px">
                      Equilo uses the TAAP framework, which stands for Transforming Agency, Access, and Power.
                      Comprised of six domains, the TAAP framework provides a structure to analyze and amplify the
                      voices, opportunities, and dignity of all people, especially those from socially excluded
                      groups. More information about this approach can be found at taapinclusion.org. Equilo Dash uses
                      15 themes that cut across two or more of the TAAP domains, for a total of 32 unique TAAP
                      domain/theme intersections for analysis and scoring. Each gender equality score is based on a
                      specific set of indicators within a given intersection.
                  </p>


              </div>
          </section>
      </div>
          </main>
     </div>
     <div v-else style="margin-top: 10%;">
        <div class="flex flex-row justify-center items-center mt-3 ml-20">
          <div class="flex flex-col item-center justify-center">
            <div
              class="flex flex-row items-center justify-center print:flex-col"
            >
              <!-- <img class="h-16 print:h-auto print:w-64"  src="@/assets/images/2X-assessment/My2XAssessments3b.png" alt="" /> -->
              <h1
                class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
              >
                No Data Found
              </h1>
            </div>
          </div>
        </div>
      </div>
  
      </div>
</template>

<script>
import axios from "axios";
import env from "@/../env.js";
import item from "@/components/text.vue";
import roundedItem from "@/components/text-roundnumbers.vue";

import spiderchart from "@/components/charts/domain-intersections.vue";
import lineChart from "@/components/charts/domain-intersections-only-2.vue";

import spiderchartMultiple from "@/components/charts/theme-intersections-multiple-countries.vue";
import lineChartMultiple from "@/components/charts/theme-intersections-only-2-multiple-countries.vue";

import barChartVerticalMultipleForOneVariable from "@/components/charts/barChartVertical-multiple-for-one.vue";
import barChartVerticalMultiple from "@/components/charts/barChartVertical-multiple.vue";
import doughnutChartMultiple from "@/components/charts/doughnutChart-multiple.vue";
import radarChartMultiple from "@/components/charts/radarChart-multiple.vue";
import bubbleChartMultiple from "@/components/charts/bubbleChart-multiple.vue";
import checkCrossChartMultiple from "@/components/charts/checkCrossChart-multiple.vue";
import tierChartMultiple from "@/components/charts/tierChart-multiple.vue";

import barChartVertical from "@/components/charts/barChartVertical.vue";
import doughnutChart from "@/components/charts/doughnutChart.vue";
import radarChart from "@/components/charts/radarChart.vue";
import bubbleChart from "@/components/charts/bubbleChart.vue";
import checkCrossChart from "@/components/charts/checkCrossChart.vue";
import Cookies from "@/helpers/cookies";
import tierChart from "@/components/charts/tierChart.vue";
import pageLoader from "@/components/page-loader.vue";
import methodology from "@/components/methodology";
//import scoreChart from '@/components/charts/intersection-score.vue';
import auth from "@/helpers/auth";
import historicalData from "@/components/historical-data.vue";

import moment from "moment";

export default {
name: "country-domain",
data: function () {
  return {
    indicators: [],
    endofcreated: false,
    country: {},
    domain: {},
    print: false,
    panel: false,
    env,
    chartLabels: {},
    openPanels: [],
    themesOptions: [],
    taabColors: {
      "Access to Resources": env.taabColors.accessToResources,
      "Law & Policy": env.taabColors.lawAndPolicy,
      "Power & Decision-Making": env.taabColors.powerAndDecision,
      "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
      "Human Dignity": env.taabColors.humanDignity,
      "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
    },
    sector: { name: "" },
    currency: {},
    countryData: [],
    domainData: [],
    chartLabelsForCharts: [],
    chartDataForCharts: [],
    domainDataUnfiltered: [],
    sourceData: [],
    json_data: [],
    allBibliographyData: []
  };
},
components: {
  item,
  roundedItem,
  spiderchart,
  lineChart,
  spiderchartMultiple,
  lineChartMultiple,

  barChartVerticalMultipleForOneVariable,
  barChartVerticalMultiple,
  doughnutChartMultiple,
  radarChartMultiple,
  bubbleChartMultiple,
  checkCrossChartMultiple,
  tierChartMultiple,

  barChartVertical,
  doughnutChart,
  radarChart,
  bubbleChart,
  checkCrossChart,
  tierChart,
  pageLoader,
  methodology,
  //scoreChart

  historicalData,
},
methods: {
  generateBibliographyCSV() {
    this.allBibliographyData.forEach((data, index) => {
      if (data.text && data.url && data.url != 'Missing' && data.text != 'Missing') {
        let arr = [];
        arr.push(index + 1);
        arr.push(data.text);
        arr.push(data.url);
        this.json_data.push(arr)
      } else {
        let arr = [];
        arr.push(index + 1);
        arr.push("Source not found ()");
        this.json_data.push(arr)
      }
    })
  },
  async getBibliographyData() {
    if (this.bibliographySourceIds && this.bibliographySourceIds.length) {
      let arr = [];
      this.bibliographySourceIds.forEach(async (data) => {
        let val = data.tag.replace(/[[\]]/g, '');
        arr.push(val);
      })
      await this.getSource(arr);
    }
  },
  async getSource(sourceId) {
    let body = { "ids": sourceId };
    return axios.post(env.apiUrl + "/sources/all-citation", body, {
      headers: {
        'Authorization': 'Bearer ' + Cookies.get('session')
      }
    }).then(async (res) => {
      this.sourceData = res.data;
      // let item = { "index": index, "data": this.sourceData };
      this.sourceData = res.data;
      this.allBibliographyData = this.sourceData;
      // if (this.bibliographySourceIds.length == this.allBibliographyData.length) {
      await this.generateBibliographyCSV();
      // }
    })
  },
  redirectIndicator(name) {
    if (name) {
      var indicatorId = name.toLowerCase().trim().split(/\s+/).join("-");
      const element = document.getElementById("indicator" + indicatorId);
      const offset = 0;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition + offset;
      window.scrollTo({
        top: offsetPosition - 80,
        behavior: "smooth",
      });
    }
  },
  // We go for an array so we can have multiple panels open at the same time (eg. download PDF)
  togglePanel: function (panelid) {
    if (this.openPanels.indexOf(panelid) > -1) {
      this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
    } else {
      this.openPanels.push(panelid);
    }
  },
  getPercentage(number) {
    return Math.round((number * 100) / 2);
  },
  value: function (input) {
    return this.format("{{" + input + "}}");
  },
  toFixed: function (n, x) {
    if (n == "Missing") {
      return "Missing";
    } else {
      return n.toFixed(x || 2);
    }
  },
  getSector: function () {
    const _self = this;
    return new Promise(function (resolve) {
      // Validate if you want general country information (count/base) or a specific sector
      if (["BASE", "country"].indexOf(_self.$route.params.sector) > -1) {
        resolve(false);
      } else {
        axios
          .get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
            params: { iso : _self.$route.params.iso2 }
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      }
    });
  },
  opacity: function (hex) {
    if (!hex) {
      return "#black";
    }
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
    return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
  },
  getCountry: function () {
    const _self = this;
    const isoCodes = this.$route.params.iso2.split(",");
    return new Promise(function (resolve) {
      let iso = { isoCodes };
      axios
        .post(
          env.apiUrl +
            "/countries" +
            // _self.$route.params.iso2 +
            "/sectors/" +
            _self.$route.params.sector,
          iso,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          resolve(res.data.data);
        });
    });
  },
  indicatorColor(taab) {
    if (taab === "Access to Resources") {
      return env.taabColors.accessToResources;
    } else if (taab === "Law & Policy") {
      return env.taabColors.lawAndPolicy;
    } else if (taab === "Power & Decision-Making") {
      return env.taabColors.powerAndDecisio;
    } else if (taab === "Knowledge & Beliefs") {
      return env.taabColors.knowledgeAndBeliefs;
    } else if (taab === "Human Dignity") {
      return env.taabColors.humanDignity;
    } else if (taab === "Roles & Responsibilities") {
      return env.taabColors.rolesAndResponsibilities;
    }
    return true;
  },
  getDomain: function () {
    const _self = this;
    const isoCodes = this.$route.params.iso2.split(",");
    let iso = { isoCodes };
    return new Promise(function (resolve) {
      axios
        .post(
          env.apiUrl +
            "/reports" +
            "/sectors/" +
            _self.$route.params.sector +
            "/domains/" +
            _self.$route.params.domainId,
          iso,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          resolve(res.data.data);
        });
    });
  },
  scrollTo(id) {
    const element = document.getElementById(id);
    const offset = 0;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    var lis = document
      .getElementsByClassName("navsticky")[0]
      .getElementsByTagName("ol")[0]
      .getElementsByTagName("li");
    for (
      let i = 0;
      i <
      document.getElementsByTagName("ol")[0].getElementsByTagName("li")
        .length;
      i++
    ) {
      lis[i].classList.remove("active");
    }
    document.getElementById(id + "1").parentElement.classList.add("active");
  },
  toTitle(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  initHtml() {
    var headings = document.getElementsByClassName("mb-2");
    for (var h = 0; h < headings.length; h++) {
      headings.item(h).style.color = env.brandingColors.navy;
    }
    return true;
  },
  getCurrency: function () {
    const _self = this;
    return new Promise(function (resolve) {
      const filter = "AND({ISO} = '" + _self.country.ISO2 + "')";
      const params = { filterByFormula: filter || "" };
      const headers = {
        Authorization: `Bearer ${env.airTableAccessToken}`,
        "Content-Type": "application/json",
      };

      axios
        .get(
          "https://api.airtable.com/v0/appXebWdllnMq540w/Countries?maxRecords=1&view=Grid%20view",
          { params: params, headers: headers }
        )
        .then((res) => {
          resolve(res.data.records);
        });
    });
  },
  extractVars: function (text) {
    let arrStr = text.split(/[{{}}]/);
    arrStr = arrStr.filter(
      (a) =>
        a.includes("_") &
        !a.includes("income") &
        !a.includes("global") &
        !a.includes("region")
    );
    return arrStr;
  },
  checkRecentlyUpdated(createdTime) {
    if (!createdTime) return false;
    return moment().diff(moment(createdTime), "months", true) <= 3;
  },
},
computed: {
  bibliographySourceIds() {
    return this.$store.state.bibliographyData;
  },
  citations() {
    const compare = (a, b) => {
      if (a.rank < b.rank) {
        return -1;
      }
      if (a.rank > b.rank) {
        return 1;
      }
      return 0;
    };
    const x = this.$store.state.citations;
    return x.sort(compare);
  },
  myStyles() {
    return {
      height: "700px",
      position: "relative",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    };
  },
},
async created() {
  // validate if you have access to this country.
  this.user = await auth.me().catch(() => { });
  this.$store.dispatch("emptyBibliographyData");
  if (this.$route.params.iso2 != 'EQ' &&
    !this.user.organization.accessGesi ||
    document.location.href.indexOf("print=") > -1
  ) {
    this.$router.push({ name: "notAllowed" });
  }

  this.$store.commit("resetCitations");

  this.countryData = await this.getCountry();
  this.country = this.countryData.countries[0]; // lets do an await before rendering other items.
  this.sector = await this.getSector(); // lets do an await before rendering other items.
  const _self = this;
  const urlindustries = env.apiUrl + "/industries/";
  axios
    .get(urlindustries, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    })
    .then(function (res) {
      _self.count = res.data.count;
      _self.industries = res.data.data;
      _self.currentIndustry = _self.industries.filter(
        (a) => a.id == _self.sector.parent
      )[0];
      //_self.filteredIndustries = JSON.parse(JSON.stringify(_self.industries));
    });

  const currencyData = await this.getCurrency();
  if (typeof currencyData[0].fields.Currency_Plural !== "undefined") {
    this.currency = currencyData[0].fields.Currency_Plural;
  } else {
    this.currency = "in local currency";
  }
  this.chartLabels[this.country.humanName] = {
    color: env.geoColors.countryspider,
    font: env.geoColors.countryspider,
    background: this.opacity(env.geoColors.countryspider),
  };
  this.chartLabels[this.country.region] = {
    color: env.geoColors.regionspider,
    font: env.geoColors.regionspider,
    background: this.opacity(env.geoColors.regionspider),
  };
  this.chartLabels[this.country.income] = {
    color: env.geoColors.incomespider,
    font: env.geoColors.incomespider,
    background: this.opacity(env.geoColors.incomespider),
  };
  this.chartLabels["LMIC"] = {
    color: env.geoColors.lmicspider,
    font: env.geoColors.lmicspider,
    background: this.opacity(env.geoColors.lmicspider),
  };
  this.chartLabels["Perfect Equality"] = {
    color: "#3F37C9",
    font: "#3F37C9",
    background: "#3F37C9",
  };
  this.domainData = await this.getDomain(); // lets do an await before rendering other items.
  this.domainDataUnfiltered = await this.getDomain();
 
  if(this.domainData.countries){

    this.domain = this.domainData.countries[0]; // lets do an await before rendering other items.
    if(this.domainData.countries.length > 1) {
      let fgmIndicatorIndex;
      this.domain.indicators.forEach((data, d) => {
        env.fgmIndicators.forEach(item => {
          if(data.primaryField == item) {
            fgmIndicatorIndex = d
          }
        })
      })
      this.domainData.countries.forEach((data, d) => {
        this.domainData.countries[d].indicators.splice(fgmIndicatorIndex, 1)
      })
      this.domainDataUnfiltered.countries.forEach((data, d) => {
        this.domainDataUnfiltered.countries[d].indicators.splice(fgmIndicatorIndex, 1)
      })
    }
    // this.country.values = this.domain.values;
    this.domainData.countries.forEach((obj,index) => {
      this.countryData.countries[index].values = obj.values;       
    });

    this.chartDataForCharts = [...this.domainData.countries];
    this.domainData.countries.forEach((data) => {
      this.chartLabelsForCharts.push(data.humanName);
    });

  }
  else{

    this.domain.intro = "";
    this.endofcreated = true;

  }

  this.chartLabelsForCharts.push("Global");
  for (var i = 0; i < this.domain.indicators.length; i++) {
    if (
      this.domain.indicators[i].meta.value == "Missing" &&
      this.domain.indicators[i].name.indexOf("Genital Cutting") > -1
    ) {
      continue;
    } else if (this.domain.indicators[i].theme == "GBV Risk Score") {
      continue;
    } else if (this.domain.indicators[i].meta == null) {
      continue;
    } else {
      //create flag if there is an indicator with unknown historical data
      const variables = this.extractVars(this.domain.indicators[i].chartText);
      let unknownHistorical = 0;
      for (let j = 0; j < variables.length; j++) {
        if (
          (this.country.values[`${variables[j]}_hist_change`] == "") |
          (this.country.values[`${variables[j]}_hist_change`] == "Missing") |
          (this.country.values[`${variables[j]}_hist_change`] == "Unknown")
        ) {
          unknownHistorical += 1;
        }
      }
      this.domain.indicators[i].unknownHist = unknownHistorical;
      this.domain.indicators[i].variables = variables;

      this.indicators.push(this.domain.indicators[i]);
      this.themesOptions.push(this.domain.indicators[i].name);
    }
  }
  if (document.location.href.indexOf("print=true") > -1) {
    this.print = true;
  }
  this.$store.commit("downloadable", true);

  this.$store.commit("setBreadcrumbs", [
    {
      name: "Countries",
      url: "/",
    },
    {
      name:
        this.$route.params.iso2.split(",").length == 1
          ? this.country.humanName || this.country.name
          : "Multiple Countries",
      url: "/gesi-analysis/" + this.$route.params.iso2 + "/choose-sector",
    },
    {
      name: (this.sector || { name: "country" }).name,
      url:
        "/gesi-analysis/" +
        this.$route.params.iso2 +
        "/" +
        this.$route.params.sector +
        "/snapshot",
    },
    {
      name: "Domains",
      url:
        "/reports/" +
        this.$route.params.iso2 +
        "/" +
        this.$route.params.sector +
        "/domains",
    },
    {
      name: this.domainData.name,
      url:
        "/reports/" +
        this.$route.params.iso2 +
        "/" +
        this.$route.params.sector +
        "/domains/" +
        this.$route.params.domainId,
    },
  ]);
  if (this.countryData.countries.length > 1) {
    this.chartDataForCharts[0].indicators.forEach((data, d) => {
      if(data.chartType == 'barChartVertical' && data.chartData.length < 6 && data.chartLabels[0] != '{{country}}' && (data.chartLabels.length == 1 || data.chartLabels.length == 2)) {
        let a = "";
        let a2 = "";
        let a_global = "";
        let a2_global = "";
        a = this.domainDataUnfiltered.countries[0].indicators[d].chartData[0].data[0];
        if (this.domainDataUnfiltered.countries[0].indicators[d].chartData[0].data[1]) {
          a2 = this.domainDataUnfiltered.countries[0].indicators[d].chartData[0].data[1];
          a2_global = this.domainDataUnfiltered.countries[0].indicators[d].chartData[this.domainDataUnfiltered.countries[0].indicators[d].chartData.length - 1].data[1];
        }
        a_global = this.domainDataUnfiltered.countries[0].indicators[d].chartData[this.domainDataUnfiltered.countries[0].indicators[d].chartData.length - 1].data[0];
        this.chartDataForCharts[0].indicators[d].chartData = [];
        for (let z = 0; z < this.countryData.countries.length; z++) {
          if (a2) {
            this.chartDataForCharts[0].indicators[d].chartData.push({ 'data': [a, a2], 'label': "{{country}}" });
          } else {
            this.chartDataForCharts[0].indicators[d].chartData.push({ 'data': [a], 'label': "{{country}}" });
          }
        }
        if (a2_global) {
          this.chartDataForCharts[0].indicators[d].chartData.push({ 'data': [a_global, a2_global], 'label': "Global" });
        } else {
          this.chartDataForCharts[0].indicators[d].chartData.push({ 'data': [a_global], 'label': "Global" });
        }
      } else {
      data.chartData.forEach((item, i) => {
        let a = "";
        a = this.chartDataForCharts[0].indicators[d].chartData[i].data[0];
        this.chartDataForCharts[0].indicators[d].chartData[i].data = [];
        for (let z = 0; z < this.countryData.countries.length; z++) {
          this.chartDataForCharts[0].indicators[d].chartData[i].data.push(a);
        }
        if (
          this.chartDataForCharts[0].indicators[d].chartData[i].label ==
            "{{region}}" ||
          this.chartDataForCharts[0].indicators[d].chartData[i].label ==
            "LMIC" ||
          this.chartDataForCharts[0].indicators[d].chartData[i].label ==
            "{{income}}"
        ) {
          this.chartDataForCharts[0].indicators[d].chartData[i].label = "";
          this.chartDataForCharts[0].indicators[d].chartData[i].label =
            "{{country}}";
        }
        if (
          this.chartDataForCharts[0].indicators[d].chartData[
            i
          ].data[0].includes("_region")
        ) {
          this.chartDataForCharts[0].indicators[d].chartData.push(
            this.chartDataForCharts[0].indicators[d].chartData[0]
          );
        }
        if (
          this.chartDataForCharts[0].indicators[d].chartType ==
            "barChartVertical" ||
          this.chartDataForCharts[0].indicators[d].chartType == "radarChart"
        ) {
          let len =
            this.domainDataUnfiltered.countries[0].indicators[d].chartData[i]
              .data.length;
          if (
            this.domainDataUnfiltered.countries[0].indicators[d].chartData[
              i
            ].data[len - 1].includes("_global")
          ) {
            this.chartDataForCharts[0].indicators[d].chartData[i].data.push(
              this.domainDataUnfiltered.countries[0].indicators[d].chartData[
                i
              ].data[len - 1]
            );
          }
        }
      });
      if (data.chartLabels[0] == "{{country}}") {
        data.chartLabels = [];
        for (let z = 0; z < this.countryData.countries.length; z++) {
          this.chartDataForCharts[0].indicators[d].chartLabels.push(
            "{{country}}"
          );
        }
      }
      }
    });
  }
  let allCountry = [];
  this.countryData.countries.forEach(data => {
    allCountry.push(data.humanName);
  })
  this.$gtag.event("domains", {
    'domain_name': this.domainData.name,
    'theme_name': "",
    "sector_name": this.sector.name,
    "country_name": allCountry,
    'page_name': this.$route.name,
    'userId': this.user._id,
    "team_id": this.user.organization._id,
    "organizationName": this.user.organization.name,
  })
  this.$gtag.event("sectors", {
    'domain_name': this.domainData.name,
    'theme_name': "",
    "sector_name": this.sector.name,
    "country_name": allCountry,
    'page_name': this.$route.name,
    'userId': this.user._id,
    "team_id": this.user.organization._id,
    "organizationName": this.user.organization.name,
  })
  this.$gtag.event("countries", {
    'domain_name': this.domainData.name,
    'theme_name': "",
    "sector_name": this.sector.name,
    "country_name": allCountry,
    'page_name': this.$route.name,
    'userId': this.user._id,
    "team_id": this.user.organization._id,
    "organizationName": this.user.organization.name,
  })
  this.endofcreated = true;
  setTimeout(async () => {
    await this.getBibliographyData();
    let data = { "data": this.json_data, "country": allCountry, "sector": this.sector.name }
    await this.$store.commit("setBibliographyData", data);
  }, 2000);
},
beforeDestroy() {
  this.$store.commit("setBibliographyData", []);
}
};
import $ from "jquery";
$(window)
.scroll(function () {
  var scrollDistance = $(window).scrollTop();
  // Show/hide menu on scroll
  //if (scrollDistance >= 850) {
  //		$('nav').fadeIn("fast");
  //} else {
  //		$('nav').fadeOut("fast");
  //}

  // Assign active class to nav links while scolling
  $(".maincontent").each(function (i) {
    if ($(this).position().top <= scrollDistance) {
      var lis = document
        .getElementsByClassName("navsticky")[0]
        .getElementsByTagName("ol")[0]
        .getElementsByTagName("li");
      for (
        let i = 0;
        i <
        document.getElementsByTagName("ol")[0].getElementsByTagName("li")
          .length;
        i++
      ) {
        lis[i].classList.remove("active");
      }

      $(".navsticky ol li").eq(i).addClass("active");
    }
  });
})
.scroll();
</script>
<style>
.global_analysis_side_bar_list li {
margin-left: 1rem !important;
}
.DownloadBtn p {
display: flex;
align-items: center;
border: 1px solid #1b1b1b;
border-radius: 20px;
color: #1b1b1b;
font-weight: bold;
font-size: 13px;
line-height: 16px;
padding: 11px 17px;
transition: 0.3s ease;
}

.DownloadBtn p img {
margin-right: 25px;
width: 15px;
height: 15px;
}

.DownloadBtn p:hover {
background: rgb(32, 23, 71);
color: #fff;
border-color: rgb(32, 23, 71);
}

.DownloadBtn a {
display: flex;
align-items: center;
border: 1px solid #1b1b1b;
border-radius: 20px;
color: #1b1b1b;
font-weight: bold;
font-size: 13px;
line-height: 16px;
padding: 11px 10px;
transition: 0.3s ease;
}

.DownloadBtn a img {
margin-right: 8px;
width: 15px;
height: 15px;
}

.DownloadBtn a:hover {
background: rgb(32, 23, 71);
color: #fff;
border-color: rgb(32, 23, 71);
}
#spiderchart {
width: 100%;
max-height: 100%;
page-break-inside: avoid;
}

@media print {
#spiderchart {
  display: block;
  width: 100%;
  height: 100%;
}

#spiderchart div {
  position: relative;
  height: 700px;
  width: 700px;
}

#spiderchart div canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 700px !important;
  height: 700px !important;
}
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
transition: all 0.5s ease;
}

.slide-fade-leave-active {
transition: all 0.5s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

      /* .slide-fade-leave-active below version 2.1.8 */ {
transform: translateY(10px);
opacity: 0;
}

.histData {
max-width: 400px !important;
}

#buttonContainer {
text-align: center;
}

#sideNav {
position: fixed;
margin-top: 300px;
left: -65px;
border-radius: 0 5px 5px 0;
}

#sideNav button {
background-color: #201747;
padding: 8px;
width: 120px;
height: 38px;
font-size: 10px;
color: #201747;
}

#sideNav button:hover {
background-color: #e2e8f0;
color: #201747;
}

.hoverMethodology {
visibility: hidden;
/* Position the tooltip */
position: absolute;
z-index: 50;
width: 400px;
}

.hoverTrigger {
position: relative;
display: inline-block;
}

.triggerElement {
border-bottom: 1px dotted #201747;
}

.hoverTrigger:hover .hoverMethodology {
visibility: visible;
position: absolute;
z-index: 50;
max-width: 400px;
}

.themeticArea {
text-decoration: underline;
cursor: pointer;
}
.themeticArea:hover {
color: rgba(45, 55, 72, var(--text-opacity));
}
.recentlyUpdatedLabel {
color: darkorange;
padding-top: 30px;
display: block;
}
</style>