<template>
  <div class="absolute top-0 right-0 flex flex-col w-1/3">
    <label>Color Scheme:</label>
    <select
      v-model="currentColorScheme"
      class="w-full px-2 py-1 placeholder-gray-700 text-gray-900 rounded-md focus:outline-none focus:shadow-outline border-2 border-gray-500"
    >
      <option
        v-for="scheme in plotColorSchemes"
        :value="scheme.value"
        :key="scheme.value"
      >
        {{ scheme.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "ColorDropdown",
  props: ["plotColorSchemes", "currentColor"],
  computed: {
    currentColorScheme: {
      get() {
        return this.currentColor;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
