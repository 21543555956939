var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"static mx-20 py-20"},[(_vm.isLoading)?_c('div',{key:"loading",attrs:{"id":"notLoaded"}},[_c('page-loader')],1):_vm._e(),_c('h1',{staticClass:"text-2xl font-bold"},[_vm._v("Third Party Verification")]),_c('div',[_vm._m(0),_c('div',{staticClass:"mb-20"},[_c('label',{staticClass:"inline-block montserrat font-bold text-1xl text-white cursor-pointer bg-blue-500 hover:bg-blue-700 border-blue-500 border-2 rounded-[16px] p-3",attrs:{"for":"file-upload"}},[_vm._v(" Upload Files ")]),_c('input',{ref:"inputFileRef",staticClass:"hidden",attrs:{"id":"file-upload","type":"file","title":"Upload","multiple":""},on:{"change":_vm.handleInputFilesOnChange}}),_c('p',{staticClass:"mt-2 text-sm text-gray-600"},[_vm._v("Maximum file size: 1 MB")])])]),(!_vm.isLoading)?_c('div',[(_vm.twoXVerifiedData.length === 0)?_c('div',[_c('h2',{staticClass:"text-xl font-bold"},[_vm._v("No data found")]),_c('span',[_vm._v(" Please upload the files to start the verification process. ")])]):_c('div',[_c('div',{staticClass:"bg-purple-100 border border-purple-400 text-purple-700 px-4 py-3 rounded-lg relative my-5 w-1/3"},[_c('strong',{staticClass:"font-bold"},[_vm._v("Remaining answers to be verified: ")]),_c('span',{staticClass:"block sm:inline"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.remainingSurveyAnswers))]),_vm._v(" out of "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.surveyAnswersLength))])])]),_vm._m(1),_vm._l((_vm.twoXVerifiedData),function(data){return _c('div',{key:data._id,staticClass:"border p-4 mb-4 rounded-lg shadow-md",class:{
          'bg-green-100': data.answer === 'YES',
          'bg-red-100': data.answer === 'NO',
          'bg-yellow-100': data.answer === 'UNKNOWN',
        }},[_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Question:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(data.question))])]),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Data found?:")]),_c('span',{staticClass:"ml-2 rounded-md px-2 py-1 text-white font-bold",class:{
                'bg-green-500': data.answer === 'YES',
                'bg-red-500': data.answer === 'NO',
                'bg-yellow-500': data.answer === 'UNKNOWN',
                'bg-gray-500': data.answer === 'ERROR',
              }},[_vm._v(_vm._s(data.answer))])]),_c('div',[_c('button',{staticClass:"text-blue-500 hover:underline",on:{"click":function($event){return _vm.toggleCitations(data._id)}}},[_vm._v(" "+_vm._s(data.showCitations ? "Hide Citations" : "Show Citations")+" ")]),(data.showCitations)?_c('div',[_c('span',{staticClass:"font-semibold"},[_vm._v("Citations:")]),_vm._l((data.citations),function(citation){return _c('div',{key:citation._id,staticClass:"ml-4 mt-2 bg-white p-2 rounded-md"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Document Title:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(citation.document_title))])]),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Section:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(citation.section))])]),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Page:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(citation.page))])]),_c('div',[_c('span',{staticClass:"font-semibold"},[_vm._v("Relevant passages:")]),_vm._l((citation.relevant_passages),function(passage){return _c('div',{key:passage,staticClass:"ml-4 mt-1"},[_c('span',[_vm._v(_vm._s(passage))])])})],2)])})],2):_vm._e()])])])})],2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded-lg relative my-5",attrs:{"role":"alert"}},[_c('strong',{staticClass:"font-bold"},[_vm._v("Note: ")]),_c('span',{staticClass:"block sm:inline"},[_vm._v("Upon uploading the files, the system will process them in the background to extract the pertinent information. The page will automatically fetch the most recent data.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative my-5"},[_c('span',{staticClass:"font-bold"},[_vm._v("❗")]),_vm._v(" For the answers marked as "),_c('span',{staticClass:"font-bold bg-red-500 rounded-md px-2 py-1 text-white"},[_vm._v("NO")]),_vm._v(", please provide the relevant information and documents to support your answer. You can upload the documents with the "),_c('span',{staticClass:"font-bold"},[_vm._v("Upload Files")]),_vm._v(" button. ")])
}]

export { render, staticRenderFns }