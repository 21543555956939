var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"enter-active-class":_vm.customTransition ? _vm.enterActiveClass : 'ease-out duration-300',"enter-from-class":_vm.customTransition ? _vm.enterFromClass : 'opacity-0',"enter-to-class":_vm.customTransition ? _vm.enterToClass : 'opacity-100',"leave-active-class":_vm.customTransition ? _vm.leaveActiveClass : 'ease-in duration-200',"leave-from-class":_vm.customTransition ? _vm.leaveFromClass : 'opacity-100',"leave-to-class":_vm.customTransition ? _vm.leaveToClass : 'opacity-0'}},[(_vm.isVisible)?_c('div',{ref:"modalOverlay",class:[
    'fixed inset-0 transition-opacity flex z-50',
    _vm.positionClasses,
    _vm.overlayColorClass
  ],attrs:{"tabindex":"-1"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleBackdropClick.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeModal.apply(null, arguments)}}},[_c('div',{ref:"modalContent",class:[
      'bg-white overflow-hidden shadow-xl transform transition-all',
      _vm.sizeClasses,
      _vm.roundedClass,
      _vm.paddingClass,
      _vm.modalClass,
      { 'cursor-move': _vm.isDraggable }
    ],style:([_vm.maxHeightStyle, _vm.modalStyle]),attrs:{"tabindex":"0"},on:{"mousedown":_vm.startDrag,"touchstart":_vm.startDrag}},[_c('div',{class:{ 'resize-handle': _vm.isResizable },on:{"mousedown":_vm.startResize,"touchstart":_vm.startResize}}),_c('div',{class:{ 'h-full flex flex-col': _vm.isScrollable }},[(_vm.title)?_c('h3',{staticClass:"text-lg font-medium text-gray-900 text-left mb-4 border-b pb-2"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{class:[_vm.gridClasses, { 'flex-grow overflow-auto': _vm.isScrollable }]},[_vm._t("default")],2)]),(_vm.showFooter)?_c('div',{class:['mt-5 sm:mt-6 border-t pt-2', { 'sticky bottom-0 bg-white': _vm.isScrollable }]},[_vm._t("footer",function(){return [_c('div',{staticClass:"flex justify-end space-x-3"},[_c('button',{class:_vm.confirmButtonClasses,on:{"click":_vm.confirmAction}},[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]),_c('button',{class:_vm.cancelButtonClasses,on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")])])]})],2):_vm._e(),(_vm.showCloseButton)?_c('button',{ref:"closeButton",staticClass:"absolute top-0 right-0 p-4",on:{"click":_vm.closeModal}},[_c('svg',{staticClass:"h-6 w-6 text-gray-600",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }