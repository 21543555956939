<template>
  <div class="relative w-full">
    <div
        ref="tagInput"
      class="w-full p-2 border rounded flex flex-wrap gap-2 cursor-pointer"
      @click="toggleDropdown"
    >
      <span
        v-for="tag in localValue.selectedTags"
        :key="tag"
        class="bg-[#0077C8] text-white px-2 py-1 rounded-lg flex items-center gap-2"
      >
        {{ tag }}
        <button @click.stop="removeTag(tag)" class="ml-1 text-xs text-white">
          <img
            src="@/assets/images/cros-icon.svg"
            alt="close icon"
            class="w-2"
          />
        </button>
      </span>
      <span v-if="!localValue.selectedTags.length" class="text-gray-500">
        Select tags...
      </span>
    </div>

    <div
      v-if="isOpen"
      :style="{
        top: dropdownPosition.top + 'px',
        left: dropdownPosition.left + 'px',
      }"
      class="fixed z-50 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto"
    >
      <ul class="py-1">
        <li
          v-for="choice in filteredChoices"
          :key="choice.value"
          @click="addTag(choice.value)"
          class="px-3 py-1 hover:bg-[#0077C8] hover:text-white cursor-pointer"
        >
          {{ choice.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question", "value"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", { selectedTags: newValue.selectedTags });
      },
    },
    filteredChoices() {
      return this.question.choices.filter(
        (choice) => !this.localValue.selectedTags.includes(choice.value)
      );
    },
  },
  methods: {
    toggleDropdown() {
      if (!this.isOpen) {
        this.calculateDropdownPosition();
      }
      this.isOpen = !this.isOpen;
    },
    calculateDropdownPosition() {
      const rect = this.$refs.tagInput.getBoundingClientRect();
      this.dropdownPosition = {
        top: rect.bottom,
        left: rect.left,
      };
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    addTag(tag) {
      if (!this.localValue.selectedTags.includes(tag)) {
        this.localValue.selectedTags.push(tag);
        this.isOpen = false;
      }
    },
    removeTag(tag) {
      this.localValue.selectedTags = this.localValue.selectedTags.filter(
        (t) => t !== tag
      );
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        document.addEventListener("click", this.closeDropdown);
      } else {
        document.removeEventListener("click", this.closeDropdown);
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  },
};
</script>
