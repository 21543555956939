<script>
const _ = require("underscore");
import { Radar } from "vue-chartjs";
export default {
  extends: Radar,
  data: function () {
    return {
      companyOrFund: "",
      axes: [
        "Entrepreneurship",
        "Leadership",
        "Employment",
        "Products & Services",
        "Supply Chain",
      ],
      axeColors: [],
      max: [],
      one: [],
      hoverIndex: false,
    };
  },
  methods: {
    formatLabel(str, maxwidth) {
      var sections = [];
      var words = str.split(" ");
      var temp = "";
      words.forEach(function (item, index) {
        if (temp.length > 0) {
          var concat = temp + " " + item;
          if (concat.length > maxwidth) {
            sections.push(temp);
            temp = "";
          } else {
            if (index == words.length - 1) {
              sections.push(concat);
              return;
            } else {
              temp = concat;
              return;
            }
          }
        }
        if (index == words.length - 1) {
          sections.push(item);
          return;
        }
        if (item.length < maxwidth) {
          temp = item;
        } else {
          sections.push(item);
        }
      });
      return sections;
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "r>gba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    calc: function (input) {
      if (typeof input !== undefined && input !== "Missing" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    abrev: function (input) {
      let abrevString = "";
      for (let i = 0; i < input.toString().split(" ").length; i++) {
        if (input.toString().split(" ")[i] !== "&") {
          abrevString += input.toString().split(" ")[i].substr(0, 1);
        }
      }
      return abrevString;
    },
  },
  props: [
    "assessmentYear",
    "paddingL",
    "paddingR",
    "paddingT",
    "paddingB",
    "chartData",
    "entityType",
    "srManagerThreshold",
    "workforceThreshold",
    "boardThreshold",
  ],
  async mounted() {
    this.entityType === "fund-manager" || this.entityType === "financial-institution"
      ? (this.max = [120, 120, 120, 120, 120, 120])
      : (this.max = [120, 120, 120, 120, 120]);
    this.entityType === "fund-manager" || this.entityType === "financial-institution"
      ? (this.one = [100, 100, 100, 100, 100, 100])
      : (this.one = [100, 100, 100, 100, 100]);
    this.axeColors = [
      "#395AFF",
      "#4C9F38",
      "#DDA63A",
      "#A21942",
      "#19486A",
      "#666461",
    ];
   
    if (this.entityType == "fund-manager" || this.entityType === "financial-institution") {
      this.axes.push("Portfolio");
    }
    const labels =
      this.entityType == "fund-manager" || this.entityType === "financial-institution"
        ? ["", "", "", "", "", ""]
        : ["", "", "", "", ""];
    const _self = this;
    this.renderChart(
      {
        labels: labels, // this.axes,
        datasets: [
          {
            data: this.chartData,
            label: [this.assessmentYear],
            borderColor: "#FCC30B",
            pointBackgroundColor: this.axeColors,
            pointBorderColor: this.axeColors,
            fill: false,
            backgroundColor: this.opacity("#FCC30B"),
            pointHitRadius: 18,
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        spanGaps: false,
        layout: {
          padding: {
            left: this.paddingL,
            right: this.paddingR,
            top: this.paddingT,
            bottom: this.paddingB,
          },
        },
        legend: {
          position: "bottom",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("hide");
            },
            fontSize: 13,
            fontFamily: "basier",
            padding: 40,
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
            }
          },
          // Function to only hide Perfect geneder equality
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }
            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);
            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;
            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });
              if (!someShown) return;
            }
            meta.hidden = hidden;
            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },
        scale: {
          ticks: {
            display: false,
            beginAtZero: true,
            max: 120,
            min: 0,
            stepSize: 20,
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          onHover: function (e) {
            // return false;
            var point = this.getElementAtEvent(e);
            let threshold = [];
            if (_self.entityType == "fund-manager" || this.entityType === "financial-institution") {
              threshold = [
                "Entrepreneurship Benchmark Threshold: 51% of shares owned by women OR it was founded by a woman who remains an active owner",
                `Leadership Benchmark Threshold: ${_self.srManagerThreshold}% of senior managers are women OR ${_self.boardThreshold}% of investment committee members are women`,
                `Employment Benchmark Threshold: ${_self.workforceThreshold}% of women in the workforce OR at least one quality workforce indicator in place beyond what is legally required`,
                `Products & Services Benchmark Threshold: At least 1 practice in place that results in products or services that specifically benefit women`,
                `Supply Chain Benchmark Threshold: 30% of businesses in supply chain are women owned; OR ${_self.workforceThreshold}% of the supply chain workforce are women AND 30% of suppliers have at least one quality workforce indicator in place beyond what is legally required`,
                "Portfolio Benchmark Threshold: At least 30% of portfolio companies meet the 2X Criteria, OR in the case of a new fund, commits 30% of funding to be directed to investees that meet 2X Criteria",
              ];
            } else {
              threshold = [
                "Entrepreneurship Benchmark Threshold: 51% of shares owned by women OR it was founded by a woman who remains an active owner",
                `Leadership Benchmark Threshold: ${_self.srManagerThreshold}% of senior managers are women OR ${_self.boardThreshold}% of board members are women`,
                `Employment Benchmark Threshold: ${_self.workforceThreshold}% of women in the workforce OR at least one quality workforce indicator in place beyond what is legally required`,
                `Products & Services Benchmark Threshold: At least 1 practice in place that results in products or services that specifically benefit women`,
                `Supply Chain Benchmark Threshold: 30% of businesses in supply chain are women owned; OR ${_self.workforceThreshold}% of the supply chain workforce are women AND 30% of suppliers have at least one quality workforce indicator in place beyond what is legally required`,
              ];
            }
            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
              e.target.style.cursor = "pointer";
              // Prevent from flashing.
              if (
                point[0]._index !==
                _self.hoverIndex + "_" + point[0]._datasetIndex
              ) {
                _self.hoverIndex =
                  point[0]._index + "_" + point[0]._datasetIndex;
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX + "px";
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-name").innerText =
                  _self.axes[point[0]._index].toString().replace(",", " ");
                document.getElementById("intersection-name").style =
                  "color: " +
                  _self.axeColors[point[0]._index] +
                  "; font-size: 16px;";
                document.getElementById("intersection-domain").style.display =
                  "none";
                document.getElementById("div-intersection-country").style =
                  "color: " + _self.axeColors[point[0]._index];
                document.getElementById("div-intersection-region").style =
                  "color: black";
                document.getElementById("div-intersection-income").style =
                  "color: gray; font-style: italic; font-size: 12px;";
                document.getElementById("lmic-avg").style.display = "none";
                document.getElementById(
                  "div-intersection-income"
                ).style.display = "block";
                document.getElementById(
                  "div-intersection-country-four"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-five"
                ).style.display = "none";
                document.getElementById(
                  "intersection-lmic-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-country-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-region-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-income-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
                document.getElementById("intersection-country").innerText =
                  "Score: " +
                  Math.round(_self.chartData[point[0]._index]) +
                  "%";
                // + '\n' +
                //   "Year: " + (( _self._data.orgLevelDataForFunds[point[0]._index] == 1) ? 'Unknown' : _self.details.submissionDate.split('-')[0])
                //   "Year: " + (( _self._data.orgLevelDataForFunds[point[0]._index] == 1) ? 'Unknown' : _self.details.submissionDate.split('-')[0])
                //   "Year: " + (( _self._data.orgLevelDataForFunds[point[0]._index] == 1) ? 'Unknown' : _self.details.submissionDate.split('-')[0])
                document.getElementById(
                  "div-intersection-region"
                ).style.display = "none";
                document.getElementById("intersection-income").innerText =
                  threshold[point[0]._index];
                document.getElementById("intersection-income").style.display =
                  "block";
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
              e.target.style.cursor = "default";
            }
          },
        },
      }
    );
    const _self2 = this;
    window.addEventListener("beforeprint", function () {
      const Chart = _self2.$data._chart;
      Chart.resize();
    });
    setTimeout(function () {
      _self2.$data._chart.update();
    }, 10000);
  },
};
</script>

<style scoped>
.chartjs-render-monitor {
  margin-left: -33px;
  height: 530px !important;
}
</style>
