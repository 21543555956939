<template>
  <div class="filters max-w-md flex justify-start relative -mb-5">
    <div class="flex justify-between mt-1 pb-2">
      <button 
        v-for="option in timeFrameOptions" 
        :key="option.value" 
        @click="selectTimeFrame(option.value)" 
        :class="['btn' , { 'btn-active': selectedTimeFrame === option.value }]">
        {{ option.text }}
      </button>
    </div>
    <div class="absolute top-0 z-50 left-0 right-0 mt-14">
      <div v-show="selectedTimeFrame !== ''" class="time-frame-options bg-white p-4 shadow-sm">
      <div v-if="selectedTimeFrame === 'years' || selectedTimeFrame === 'allTime'" class="mb-4">
        <label for="yearFrom" class="block mb-1 font-semibold">Year From:</label>
        <input type="number" v-model="yearFrom" id="yearFrom" min="1900" :max="currentYear" @change="validateYearRange" class="input">

        <label for="yearTo" class="block mb-1 font-semibold">Year To:</label>
        <input type="number" v-model="yearTo" id="yearTo" min="1900" :max="currentYear" @change="validateYearRange" class="input">

        <p v-if="yearError" class="text-red-500 text-sm mt-1">{{ yearErrorMessage }}</p>
      </div>

      <div v-else>
        <div v-if="selectedTimeFrame === 'months'">
          <label for="month" class="block mb-1 font-semibold">Month:</label>
          <select v-model="selectedMonth" id="month" class="input">
            <option value="">Select Month</option>
            <option v-for="(month, index) in months" :key="index" :value="month">{{ month }}</option>
          </select>
        </div>
        <div v-if="selectedTimeFrame === 'quarters'">
          <label for="quarter" class="block mb-1 font-semibold">Quarter:</label>
          <select v-model="selectedQuarter" id="quarter" class="input">
            <option value="">Select Quarter</option>
            <option v-for="(quarter, index) in quarters" :key="index" :value="quarter">{{ quarter }}</option>
          </select>
        </div>
      </div>
      <div class="flex">
        <button @click="applyFilters" class="w-full mt-4 bg-[#2177c1] text-white font-medium p-2 rounded-lg mr-4">Apply Filters</button>
        <button @click="clearFilters" class="w-full mt-4 bg-[#e2f1ff] text-gray-600 font-medium p-2 rounded-lg">Clear Filters</button>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "TwoxTimeFilter",
  data() {
    return {
      selectedTimeFrame: '',
      selectedMonth: '',
      selectedQuarter: '',
      yearFrom: null,
      yearTo: null,
      yearError: false,
      yearErrorMessage: '',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      quarters: ['Q1', 'Q2', 'Q3', 'Q4'],
      timeFrameOptions: [
        { value: 'months', text: 'Months' },
        { value: 'quarters', text: 'Quarters' },
        { value: 'years', text: 'Years' },
        { value: 'allTime', text: 'All Time' }
      ]
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  methods: {
    selectTimeFrame(timeFrame) {
      this.selectedTimeFrame = timeFrame;

      if (timeFrame === 'allTime') {
        this.yearFrom = 1900;
        this.yearTo = this.currentYear;
      } else {
        this.resetFields();
      }
    },
    resetFields() {
      this.selectedMonth = '';
      this.selectedQuarter = '';
      this.yearFrom = null;
      this.yearTo = null;
      this.yearError = false;
      this.yearErrorMessage = '';
    },
    validateYearRange() {
      if (this.yearFrom > this.yearTo) {
        this.yearError = true;
        this.yearErrorMessage = 'Year From should not be greater than Year To';
      } else {
        this.yearError = false;
        this.yearErrorMessage = '';
      }
    },
    applyFilters() {
      if (this.yearError) {
        alert("Please fix the errors before applying filters.");
        return;
      }

      console.log('Selected Time Frame:', this.selectedTimeFrame);
      console.log('Selected Month:', this.selectedMonth);
      console.log('Selected Quarter:', this.selectedQuarter);
      console.log('Year Range:', { from: this.yearFrom, to: this.yearTo });
      // Add any other functionality as necessary
    },
    clearFilters(){
      this.selectedTimeFrame = '',
      this.selectedMonth =  '',
      this.selectedQuarter = '',
      this.yearFrom = null,
      this.yearTo = null,
      this.yearError = false,
      this.yearErrorMessage = ''
    }
  }
};
</script>

<style scoped>
.btn {
      background: #f4f6f9;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #9ea4ba;
      padding: 12px;
      margin-bottom: 6px;
}

.btn-active {
  background: #2177c1;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
}

.btn-primary {
  background-color: #007bff;
}

.input {
  padding: 6px 8px;
  border: 1px solid #999;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 8px;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
