<template>
  <div class="mt-10 w-full flex flex-col items-start justify-start bg-gray-200 px-6 py-6 ease-in-out shadow-xl border-2" :style="'page-break-inside:avoid; background-color: white' + '; border-color:' + domain.color + '; border-width: 14px'" style="border-radius: 25px;">
    <div class="flex justify-between items-center w-full flex-col lg:flex-row">
      <div class="flex flex-col justify-start mb-auto lg:w-10/12 w-full ">
        <div class="flex flex-row justify-start items-center">
         <div class="flex items-center justify-center p-3 h-20 w-20 print:h-10 print:w-10 mr-5 bg-white border border-gray-200" style="border-radius: 25px;">
              <svg class="w-10 h-10 justify-center items-center flex flex-row">
                <image class="w-10 h-10 flex flex-row items-center" :href="domain.icon" />
              </svg>
          </div>
          <div>
            <h3 class="text-2xl font-medium tracking-tight" :style="'color:'+domain.color">{{domain.name}}</h3>
            <p class="text-black tracking-tight">{{domain.theme}}</p>
          </div>
        </div>
        <div class="flex flex-row justify-start items-center">
          <p class="mt-10 text-gray-600 text-justify">
            <rounded-item :text="domain.intro.substring(0, 500)+'...'" :country="country"></rounded-item>
          </p>
        </div>
        <div v-if="!isPrint" class="flex flex-row justify-start items-center mt-10 print:mt-6 pointer print:hidden " @click="moreInfo()">
          <button class="flex flex-row items-center justify-start text-white px-4 py-3 border transition duration-200" :style="'background-color: ' + env.brandingColors.navy + '; border-radius: 12px;'">
            <p class="capitalize mr-3 cursor-pointer font-semibold">Explore Domain</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-arrow-right">
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <polyline points="12 5 19 12 12 19"></polyline>
            </svg>
          </button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
  import roundedItem from '@/components/text-roundnumbers.vue';
  import env from '@/../env.js';

  export default {
    name: 'intersection',
    props: ['domain', 'country', 'isPrint', 'iso2', 'countryData'],
    data: function () {
      return {
        genderEqualityInfo: false,
        powerScoreInfo: false,
        validityScoreInfo: false,
        env,
      }
    },
    components: {
      roundedItem
    },
    methods: {
      moreInfo: function () {
          this.$router.push({
            path: "/reports/" + this.iso2  +'/'+this.$route.params.sector+ "/domains/" + this.domain.name
          }); 
      },
      opacity: function (hex) {
        if(!hex) return ''
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const rgb = result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
        return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.25)'
      },

    },
    created() {
    }
  }
</script>

<style>
  .topicCard:hover {
    transform: scale(1.02);
  }
</style>
