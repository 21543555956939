<template>
    <div class="dimension_inner_name flex">
        <div class="dimension_inner_left_side">
            <div class="dimension_survey_question mt-4 mb-4 flex">
                <div class="survey_question_inner">
                    <span>{{ score.subpageData[1].verbiageForPercentageFields }}
                    </span>
                </div>
                <div class="barchart_inner" v-if="!score.isNotApplicable">
                    <BarChartMenWomen
                        v-if="getScoredataLength == 1 && (score.subpageData[0]?.score !== '' || score.subpageData[1]?.score !== '')"
                        :chartData="score.subpageData" :name="roiPageIntro.name"
                        :chartLabels="score.subpageData[1].chartHoverLabels" :created="financialYear">
                    </BarChartMenWomen>
                    <div class="text-center"
                        v-if="getScoredataLength == 1 && (score.subpageData[0]?.score == '' && score.subpageData[1]?.score == '')">
                        <div style="color: #FE5000">{{ financialYear[0].split(" ")[0] + " " +
                            financialYear[0].split(" ")[1] }}</div>
                        <span>Unknown</span>
                    </div>
                    <BarChartMenWomenMultiple
                        v-if="getScoredataLength > 1 && (score.subpageData[0]?.score != '' || score.subpageData[1]?.score != '') && (score.subpageData[0]?.Oldscore != '' || score.subpageData[1]?.Oldscore != '')"
                        :chartData="score.subpageData" :name="roiPageIntro.name"
                        :chartLabels="score.subpageData[1].chartHoverLabels" :created="financialYear">
                    </BarChartMenWomenMultiple>
                    <div v-if="getScoredataLength > 1 && (score.subpageData[0]?.score == '' || score.subpageData[1]?.score == '') || (score.subpageData[0]?.Oldscore == '' ||
                        score.subpageData[1]?.Oldscore == '')">
                        <BarChartMenWomen
                            v-if="(score.subpageData[0]?.score != '' || score.subpageData[1]?.score != '')"
                            :chartData="score.subpageData" :name="roiPageIntro.name"
                            :chartLabels="score.subpageData[1].chartHoverLabels" :created="financialYear">
                        </BarChartMenWomen>
                        <div class="text-center"
                            v-if="(score.subpageData[0]?.score == '' && score.subpageData[1]?.score == '')">
                            <div style="color: #FE5000">{{
                                financialYear[0].split(" ")[0] + " " +
                                financialYear[0].split(" ")[1]
                            }}</div>
                            <span>Unknown</span>
                        </div>
                        <br /><br />
                        <BarChartMenWomenOldest
                            v-if="(score.subpageData[0]?.Oldscore != '' || score.subpageData[1]?.Oldscore != '')"
                            :chartData="score.subpageData" :name="roiPageIntro.name"
                            :chartLabels="score.subpageData[1].chartHoverLabels" :created="financialYear">
                        </BarChartMenWomenOldest>
                        <div class="text-center"
                            v-if="(score.subpageData[0]?.Oldscore == '' && score.subpageData[1]?.Oldscore == '')">
                            <div style="color: #201747">{{
                                financialYear[1].split(" ")[0] + " " +
                                financialYear[1].split(" ")[1]
                            }}</div>
                            <span>Unknown</span>
                        </div>
                    </div>
                </div>
                <!-- for not applicable charts -->
                <div class="barchart_inner text-center"
                    v-if="score.isNotApplicable && (((score.subpageData[0]?.score == '0.0' || score.subpageData[0]?.score == '0') && (score.subpageData[1]?.score == '0.0' || score.subpageData[1]?.score == '0')) ||
                        ((score.subpageData[0]?.Oldscore == '0.0' || score.subpageData[0]?.Oldscore == '0') && (score.subpageData[1]?.Oldscore == '0.0' || score.subpageData[1]?.Oldscore == '0')))">
                    <!-- for latest charts -->
                    <span class="mb-2"
                        v-if="((score.subpageData[0]?.score == '0.0' || score.subpageData[0]?.score == '0') && (score.subpageData[1]?.score == '0.0' || score.subpageData[1]?.score == '0')) || (score.subpageData[0]?.score == '' && score.subpageData[1]?.score == '')"
                        style="position:relative;left:25px;">
                        <span style="color: #FE5000">{{ financialYear[0].split(" ")[0] + " " +
                            financialYear[0].split(" ")[1] }}</span>
                        <br />
                        <span>{{(score.subpageData[0]?.score == '' && score.subpageData[1]?.score == '') ? 'Unknown' : 'Not Applicable'}}</span>
                    </span>
                    <BarChartMenWomen v-else
                        :chartData="score.subpageData" :name="roiPageIntro.name"
                        :chartLabels="score.subpageData[1].chartHoverLabels" :created="financialYear">
                    </BarChartMenWomen>
                    <br /><br />
                    <!-- for oldest charts -->
                    <span
                        v-if="getScoredataLength > 1 && (score.subpageData[0]?.Oldscore == '0.0' || score.subpageData[0]?.Oldscore == '0') && (score.subpageData[1]?.Oldscore == '0.0' || score.subpageData[1]?.Oldscore == '0') || (score.subpageData[0]?.Oldscore == '' && score.subpageData[1]?.Oldscore == '')"
                        style="position:relative;left:25px;">
                        <span v-if="financialYear.length > 1" style="color: #201747">{{ financialYear[1].split(" ")[0] +
                            " " + financialYear[1].split(" ")[1] }}</span>
                        <br />
                        <span v-if="financialYear.length > 1">{{(score.subpageData[0]?.Oldscore == '' && score.subpageData[1]?.Oldscore == '') ? 'Unknown' : 'Not Applicable'}}</span></span>
                    <BarChartMenWomenOldest v-else
                        v-show="getScoredataLength > 1"
                        :chartData="score.subpageData"
                        :name="roiPageIntro.name" :chartLabels="score.subpageData[1].chartHoverLabels"
                        :created="financialYear">
                    </BarChartMenWomenOldest>
                </div>
                <!-- for not applicable with multiple charts -->
                <div class="barchart_inner text-center"
                    v-if="score.isNotApplicable && ((score.subpageData[0]?.score != '0.0' && score.subpageData[0]?.score != '0') && (score.subpageData[1]?.score != '0.0' && score.subpageData[1]?.score != '0') && (score.subpageData[0]?.Oldscore != '0.0' && score.subpageData[0]?.Oldscore != '0') && (score.subpageData[1]?.Oldscore != '0.0' && score.subpageData[1]?.Oldscore != '0'))">
                    <BarChartMenWomen
                        v-if="getScoredataLength == 1 && ((score.subpageData[0]?.score != '0.0' && score.subpageData[0]?.score != '0') && (score.subpageData[1]?.score != '0.0' && score.subpageData[1]?.score != '0'))"
                        :chartData="score.subpageData" :name="roiPageIntro.name"
                        :chartLabels="score.subpageData[1].chartHoverLabels" :created="financialYear">
                    </BarChartMenWomen>
                    <BarChartMenWomenMultiple
                        v-if="getScoredataLength > 1 && ((score.subpageData[0]?.score != '0.0' && score.subpageData[0]?.score != '0') && (score.subpageData[1]?.score != '0.0' && score.subpageData[1]?.score != '0') && (score.subpageData[0]?.Oldscore != '0.0' && score.subpageData[0]?.Oldscore != '0') && (score.subpageData[1]?.Oldscore != '0.0' && score.subpageData[1]?.Oldscore != '0'))"
                        :chartData="score.subpageData" :name="roiPageIntro.name"
                        :chartLabels="score.subpageData[1].chartHoverLabels" :created="financialYear">
                    </BarChartMenWomenMultiple>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BarChartMenWomen from "@/components/charts/roiPillarBarChartForMenWomen.vue";
import BarChartMenWomenOldest from "@/components/charts/roiPillarBarChartForMenWomenOldest.vue";
import BarChartMenWomenMultiple from "@/components/charts/roiPillarBarChartForMenWomenMultiple.vue";
export default {
    name: "MenWomenBarChart",
    props: ["score", "getScoredataLength", "roiPageIntro", "financialYear"],
    components: {
        BarChartMenWomen,
        BarChartMenWomenOldest,
        BarChartMenWomenMultiple,
    },
    filters: {
        textWithCommaRemoveOther(value) {
            value.forEach((data, d) => {
                if (data == 'Other') {
                    value.splice(d, 1);
                }
            })
            var data = Object.values(value).join(", ");
            return data;
        },
        removeHiddenFieldText(value) {
            if (value.includes('Formula (Hidden) Field:')) {
                return value.split('Formula (Hidden) Field:')[1];
            } else {
                return value;
            }
        },
        removePerchantageSign(str) {
            if (str?.startsWith('%')) {
                return str.slice(1);
            } else {
                return str;
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.dimension_inner_name {
    .dimension_inner_left_side {
        width: 100%;

        .dimension_survey_question {
            font-weight: 700;
            color: black;
            word-break: break-word;
            min-height: 70px;

            .survey_question_inner {
                width: 60%;
            }

            .barchart_inner {
                width: 40%;
            }

            .multi_values_inner {
                width: 30%;
                margin-left: 40px;
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }

    .dimension_inner_right_side {
        // width: 40%;
    }
}
</style>