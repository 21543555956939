<template>
  <div class="static">
    <!-- <img
      src="@/assets/images/background-image-overview.jpg"
      alt="background-image"
      class="object-contain h-64 no-print"
    /> -->
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" key="content" v-if="endofcreated">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <img
                    class="mr-5 h-16 print:h-auto print:w-64"
                    :src="country.flagUrl"
                  />
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl" >
                    GBV Risk {{ headName }}
                  </h1>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="container mx-auto">
          <div class="flex flex-col mx-auto w-11/12 mt-12 mb-20">
            <a-steps progress-dot :current="this.current_step" size="small" @change="progressClick">
              
              <a-step title="Materiality" />
              <a-step title="Risk models" />
              <a-step title="Heat map" />
              <a-step title="Methodology" />
            </a-steps>
          </div>
        </div>
        
    


        <!-- MATERIALITY -->
        <transition name="fadeIn">
          <div v-if="current_step == 0" class="flex flex-col items-center justify-center w-full">
            <section
              id="Practice"
              class="w-full container mx-auto px-4 lg:px-0"
              style="max-width: 960px"
            >
                <div class="flex flex-col">
                  <h1 class="text-2xl leading-6 font-medium mb-2">What Does GBV Risk Look Like in Practice for Investments?</h1>
                  <div class="text-gray-600 w-full mt-3">
                    <item
                    :text="gbvrisk.text.in_practice"
                    :country="country"
                    class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                  </div>
                </div>

                <div
                  class="shadow overflow-hidden rounded-lg mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; background-color:' + env.brandingColors.navy"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer text-white"
                    :style="'border-style: solid; border-width: 0px 0px 1px;'"
                    @click="togglePanel('case-uganda')"
                  >
                    <h3 class="text-medium leading-6 font-medium text-white">
                      {{ gbvrisk.text.casestudy_title }}
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color: white;'"
                    >
                      <span v-if="openPanels.indexOf('case-uganda') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('case-uganda') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-white w-full">
                        <item
                          :text="gbvrisk.text.casestudy_text"
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>

            </section>

            <section
              id="Materiality"
              class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
              style="max-width: 960px"
            >
                <div class="flex flex-col mt-10">
                  <h1 class="text-2xl leading-6 font-medium">Understanding Material Risk of GBV for Financial Actors</h1>
                  <div class="text-gray-600 w-full mt-3">
                    <item
                    :text="'Before investing in a specific country, company, or project, it is critical for investment officers and financial analysts to carefully evaluate different areas of risk that will affect their expected outcomes. One approach to calculating overall financial risk is to examine the following four types of risk, based on Criterion’s Institute’s Mitigating the Risks of Gender-Based Violence: A Due Diligence Guide for Investing:[s44935] <b>operational risk, reputational risk, regulatory risk, </b>and<b> political risk.</b>[s44935] <br><br>' +
                    'However, within this framework or their own organization-specific framework, investors typically do not include measures of gender-based violence. This oversight has serious implications: by failing to measure GBV and understand its associated risks, their risk calculations are inaccurate and their expected investment outcomes suffer. Established global evidence illustrates specifically how GBV intersects with and amplifies these four types of risk, presented below.<br><br>' + 
                    'This deeper understanding of GBV\'s material impacts globally not only allows investors to more accurately measure and mitigate overall risk, but also leverage their knowledge to maximize the impact of their investment and join the growing ranks of actors within the financial community who are leading the way on gender equality and social inclusion.'"
                    :country="country"
                    class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                  </div>
                </div>            


              <div
                class="w-full container mx-auto px-4 mt-10 lg:px-0"
                style="max-width: 960px"
              >
                <div
                  class="shadow overflow-hidden rounded-lg border mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; border-color:' + env.brandingColors.navy"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                    :style="'border-style: solid; border-width: 0px 0px 1px; border-color:' + env.brandingColors.navy"
                    @click="togglePanel('operational')"
                  >
                    <h3 class="text-medium leading-6 font-medium" :style="'color:' + env.brandingColors.navy">
                      Operational Risk
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color:' + env.brandingColors.navy"
                    >
                      <span v-if="openPanels.indexOf('operational') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('operational') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-gray-600 w-full">
                        <item
                          :text="gbvrisk.text.operational_risk"
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>

                <div
                  class="shadow overflow-auto border rounded-lg mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; border-color:' + env.brandingColors.blue"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                    :style="'border-style: solid; border-width: 0px 0px 1px; border-color:' + env.brandingColors.blue"
                    @click="togglePanel('reputational')"
                  >
                    <h3 class="text-medium leading-6 font-medium" :style="'color:' + env.brandingColors.navy">
                      Reputational Risk
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color:' + env.brandingColors.blue"
                    >
                      <span v-if="openPanels.indexOf('reputational') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('reputational') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-gray-600 w-full">
                        <item
                          :text="gbvrisk.text.reputational_risk"
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                    
                  </transition>
                </div>

                <div
                  class="shadow overflow-hidden rounded-lg border mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; border-color:' + env.brandingColors.orange"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                    :style="'border-style: solid; border-width: 0px 0px 1px; border-color:' + env.brandingColors.orange"
                    @click="togglePanel('regulatory')"
                  >
                    <h3 class="text-medium leading-6 font-medium" :style="'color:' + env.brandingColors.navy">
                      Regulatory Risk
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color:' + env.brandingColors.orange"
                    >
                      <span v-if="openPanels.indexOf('regulatory') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('regulatory') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-gray-600 w-full">
                        <item
                          :text="gbvrisk.text.regulatory_risk"
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>

                <div
                  class="shadow overflow-hidden rounded-lg mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; border-color:' + env.brandingColors.teal"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                    :style="'border-style: solid; border-width: 0px 0px 1px; border-color:' + env.brandingColors.teal"
                    @click="togglePanel('political')"
                  >
                    <h3 class="text-medium leading-6 font-medium" :style="'color:' + env.brandingColors.navy">
                      Political Risk
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color:' + env.brandingColors.teal"
                    >
                      <span v-if="openPanels.indexOf('political') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('political') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-gray-600 w-full">
                        <item
                          :text="gbvrisk.text.political_risk"
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>

              </div>
            </section>


            <section
              id="Practice"
              class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
              style="max-width: 960px"
            >
              <div class="flex flex-col mt-10">
                <h1 class="text-2xl leading-6 font-medium">Correlating GBV and Financial Risk</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                    :text="'As an additional tool to understand the relationship among GBV indicators and different dimensions of financial risk—i.e., the materiality of GBV—we performed a correlation analysis. We used the World Bank’s Worldwide Governance Indicators (WGI) as proxies for political, regulatory, operational, and reputational risk.[s45059]<br><br>' + 
                    '<em>Table 1. World Bank Worldwide Governance Indicators Utilized for Correlation Analysis with GBV Indicators</em>'"
                    :country="country"
                    class="text-justify"
                  ></item>
                </div>
              </div>

              <div class="flex flex-col py-5">

                <div class="flex flex-row border">
                    <div class="flex flex-row w-2/12 border p-1" style="color: #201747">
                        <b>Risk Dimension</b>
                    </div>
                    <div class="flex flex-row w-3/12 border p-1" style="color: #201747">
                        <b>Financial Risk Indicator</b>
                    </div>
                    <div class="flex flex-row w-7/12 border p-1" style="color: #201747">
                        <b>Indicator Description</b>
                    </div>
                </div>

                <div class="flex flex-row">
                    <div class="flex flex-row w-2/12 border p-2 text-gray-600 font-medium border-b-0">
                        Political Risk
                    </div>
                    <div class="flex flex-row w-3/12 border p-2 text-gray-600 font-medium">
                        <i>Control of Corruption</i>
                    </div>
                    <div class="flex flex-row w-7/12 border p-2 text-sm text-gray-600">
                        Perceptions of the extent to which public power is exercised for private gain, including both petty and grand forms of corruption, as well as 'capture' of the state by elites and private interests.
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-row w-2/12 border p-2 text-gray-600 font-medium border-t-0">
                        
                    </div>
                    <div class="flex flex-row w-3/12 border p-2 text-gray-600 font-medium">
                        <i>Political Stability and Absence of Violence</i>
                    </div>
                    <div class="flex flex-row w-7/12 border p-2 text-sm text-gray-600">
                        Perceptions of the likelihood of political instability and/or politically motivated violence, including terrorism.
                    </div>
                </div>

                <div class="flex flex-row">
                    <div class="flex flex-row w-2/12 border p-2 text-gray-600 font-medium border-b-0">
                        Regulatory Risk
                    </div>
                    <div class="flex flex-row w-3/12 border p-2 text-gray-600 font-medium">
                        <i>Regulatory Quality</i>
                    </div>
                    <div class="flex flex-row w-7/12 border p-2 text-sm text-gray-600">
                        Perceptions of the ability of the government to formulate and implement sound policies and regulations that permit and promote private sector development.
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-row w-2/12 border p-2 text-gray-600 font-medium border-t-0">
                        
                    </div>
                    <div class="flex flex-row w-3/12 border p-2 text-gray-600 font-medium">
                        <i>Rule of Law</i>
                    </div>
                    <div class="flex flex-row w-7/12 border p-2 text-sm text-gray-600">
                        Perceptions of the extent to which agents have confidence in and abide by the rules of society, and in particular the quality of contract enforcement, property rights, the police, and the courts, as well as the likelihood of crime and violence.
                    </div>
                </div>

                <div class="flex flex-row">
                    <div class="flex flex-row w-2/12 border p-2 text-gray-600 font-medium">
                        Operational Risk
                    </div>
                    <div class="flex flex-row w-3/12 border p-2 text-gray-600 font-medium">
                        <i>Government Effectiveness</i>
                    </div>
                    <div class="flex flex-row w-7/12 border p-2 text-sm text-gray-600">
                        Perceptions of the quality of public services, the quality of the civil service and the degree of its independence from political pressures, the quality of policy formulation and implementation, and the credibility of the government's commitment to such policies.
                    </div>
                </div>

                <div class="flex flex-row">
                    <div class="flex flex-row w-2/12 border p-2 text-gray-600 font-medium">
                        Reputational Risk
                    </div>
                    <div class="flex flex-row w-3/12 border p-2 text-gray-600 font-medium">
                        <i>Voice and Accountability</i>
                    </div>
                    <div class="flex flex-row w-7/12 border p-2 text-sm text-gray-600">
                        Perceptions of the extent to which a country's citizens are able to participate in selecting their government, as well as freedom of expression, freedom of association, and a free media.
                    </div>
                </div>

              </div>

              <div class="flex flex-col">
                <div class="text-gray-600 w-full mt-3">
                  <item
                    :text="'When analyzing the results, we uncovered the following statistically significant, moderate to strong correlations between each of the following GBV indicators and the WGIs. This is a sample of the over 30 GBV risk indicators utilized in the GBVR, and which may be utilized within financial model calculations detailed in the proceeding sections.<br><br>' + 
                    '<em>Table 2. GBV Indicators with Moderate to Strong Correlations with WGIs</em>'"
                    :country="country"
                    class="text-justify"
                  ></item>
                </div>
              </div>

              <div class="flex flex-col py-5">

                <div class="flex flex-row border">
                    <div class="flex flex-row w-6/12 border p-1" style="color: #201747">
                        <b>GBV Indicator</b>
                    </div>
                    <div class="flex flex-row w-6/12 border p-1" style="color: #201747">
                        <b>World Governance Indicators</b>
                    </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Trafficking in Persons (TIP) Tier Ranking[s2480]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Control of Corruption</li>
                      <li>Political Stability and Absence of Violence</li>
                      <li>Regulatory Quality</li>
                      <li>Rule of Law</li>
                      <li>Government Effectiveness</li>
                      <li>Voice and Accountability</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Rate of police recorded offenses of sexual violence (per 100,000 population)[s45582]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Control of Corruption</li>
                      <li>Political Stability and Absence of Violence</li>
                      <li>Rule of Law</li>
                      <li>Government Effectiveness</li>
                      <li>Voice and Accountability</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Estimated prevalence of modern slavery (victims per 1,000 population)[s7611]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Regulatory Quality</li>
                      <li>Control of Corruption</li>
                      <li>Rule of Law</li>
                      <li>Voice and Accountability</li>
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Is there legislation specifically addressing domestic violence?[s45583]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Control of Corruption</li>
                      <li>Political Stability and Absence of Violence</li>
                      <li>Regulatory Quality</li>
                      <li>Rule of Law</li>
                      <li>Voice and Accountability</li>
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Is there no legal provision that requires a married woman to obey her husband?[s1981]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Voice and Accountability</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Female homicide rate (victims of intentional homicide per 100,000 females)[44511]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Control of Corruption</li>
                      <li>Regulatory Quality</li>
                      <li>Rule of Law</li>
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'% of women age 20-24 that report being married before age 15[s2952]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Control of Corruption</li>
                      <li>Political Stability and Absence of Violence</li>
                      <li>Regulatory Quality</li>
                      <li>Rule of Law</li>
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'% of men or women that agree that a husband is justified in beating his wife under certain circumstances[s2007][s3041]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Political Stability and Absence of Violence</li>
                      <li>Regulatory Quality</li>
                      <li>Rule of Law</li>
                      <li>Voice and Accountability</li>
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'% of women ages 15+ who report that they feel safe walking alone at night in the city or area where they live[s36098]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Control of Corruption</li>
                      <li>Regulatory Quality</li>
                      <li>Rule of Law</li>
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'% of female workers who worry about violence and harassment as a source of risk to their personal safety while working[s44218]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Extent of implementation of mental health services for victims of all types of violence[s45585]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Sex ratio at birth (male births per female births)[s2014]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

                <div class="flex flex-row">
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 font-medium">
                      <item
                        :text="'Is there legislation on sexual harassment in employment?[s2603]'"
                        :country="country"
                      ></item>
                  </div>
                  <div class="flex flex-row w-6/12 border p-6 text-gray-600 text-sm -pt-5">
                    <ul class="list-disc">
                      <li>Government Effectiveness</li>
                    </ul>
                  </div>
                </div>

              </div>
            </section>

          </div>
        </transition>


        <!-- RISK MODELS -->
        <transition name="fadeIn">
          <div v-if="current_step == 1" class="flex flex-col items-center justify-center w-full">
            <section
              id="RiskModels"
              class="w-full container mx-auto px-4 lg:px-0"
              style="max-width: 960px"
            >
                <div class="flex flex-col">
                  <h1 class="text-2xl leading-6 font-medium">Selecting a Model to Utilize a Risk Score or Discrete Indicators</h1>
                  <div class="text-gray-600 w-full mt-3">
                    <item
                    text="To incorporate GBV data in their decision-making, investors and financial analysts may choose between two different options: 1) to integrate the GBVR as an additional risk category or 2) to conversely integrate discrete GBV indicators into existing risk categories related to financial, economic, and political risk. (We use the risk categories of operational, reputational, regulatory, and political risk.) There is no right or wrong option—what matters is selecting a model that most closely aligns with financial models and calculation processes already used by the investor.  
    "
                    :country="country"
                    class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                  </div>
                  
                </div>

                <div
                  class="shadow overflow-hidden rounded-lg mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; border-color:' + env.brandingColors.navy"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                    :style="'border-style: solid; border-width: 0px 0px 1px; border-color:' + env.brandingColors.navy"
                    @click="togglePanel('investor-1')"
                  >
                    <h3 class="text-medium leading-6 font-medium" :style="'color:' + env.brandingColors.navy">
                      Option 1: Integrate the GBV Risk Score as an Additional Risk Category
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color:' + env.brandingColors.navy"
                    >
                      <span v-if="openPanels.indexOf('investor-1') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('investor-1') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-gray-600 w-full">
                        <item
                          text="One way to measure overall financial risk of an investment within a particular country is to use a weighted average of different dimensions of risk, adding a GBV risk dimension (GBR) as its own risk category. <br><br>
                          Country Risk (country X) = (PR+OR+REPR+REGR+<b>GBVR</b>) / 5 <br/><br/>
                            * PR = Total political risk indicators<br/>
                            * OR = Total operational risk indicators<br/>
                            * REPR = Total reputational risk indicators<br/>
                            * REGR = Total regulatory risk indicators<br/>
                            * <b>GBVR = Total GBV Risk indicators</b>
                            "
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>


                <div
                  class="shadow overflow-hidden rounded-lg mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; border-color:' + env.brandingColors.navy"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer"
                    :style="'border-style: solid; border-width: 0px 0px 1px; border-color:' + env.brandingColors.navy"
                    @click="togglePanel('investor-2')"
                  >
                    <h3 class="text-medium leading-6 font-medium" :style="'color:' + env.brandingColors.navy">
                      Option 2: Integrate GBV Indicators into Existing Risk Categories
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color:' + env.brandingColors.navy"
                    >
                      <span v-if="openPanels.indexOf('investor-2') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('investor-2') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-gray-600 w-full">
                        <item
                          text="Another way to measure the overall financial risk of an investment within a particular country is to use a weighted average of different dimensions of risk, adding GBV indicators into the formulas for each specific risk category. The GBV indicators that may be used are identified in the proceeding section. <br><br>
                          Country Risk(country X) = (PR+OR+REPR+REGR) / 4<br/><br/>

                            * PR = Total political risk indicators + <b>GBV indicators</b><br/>
                            * OR = Total operational risk indicators + <b>GBV indicators</b><br/>
                            * REPR = Total reputational risk indicators + <b>GBV indicators</b><br/>
                            * REGR = Total regulatory risk indicators + <b>GBV indicators</b>
                            "
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>

              <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                The proceeding section on the calculation of the GBVR supports analysts and decision-makers to understand the GBV risk of a particular investment, and places that risk into four different levels: ubiquitous, significant, severe, and extreme. These levels may be used to guide investment decision-making processes and the way in which GBV reduction is financed as part of the investment.
              </p>
              
              <h1 class="text-lg leading-6 font-medium mt-10">Considerations</h1>
              <div class="text-gray-600 w-full">
                <item
                  text="
                    * Some institutions use scales of 0-10, others 0-6, others 0-100, and so on. Therefore, when utilizing either the GBVR (presented on a scale of 0-100) or discrete GBV indicators, care must be taken in scaling to align with the institution’s scale while maintaining the integrity of the GBV Risk integration.
                    * In both risk model options, weighting may be applied to each distinct risk category at the user’s discretion, as long as the GBVR weight is at least equal with one other risk category.  
                    * GBV is always an additive risk; therefore, depending on the institution’s risk model calculations, there should never be a circumstance where GBV brings down the overall country risk. If it does, this indicates an error in scaling and requires correction via re-scaling of the GBVR.
                    "
                  :country="country"
                  class="leading-7"
                ></item>
              </div>

            </section>
            
            <section
              id="LeveragingModels"
              class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
              style="max-width: 960px"
            >
              <h1 class="text-2xl leading-6 font-medium mt-10">Leveraging GBV Risk Models to Make Sound Investment Decisions</h1>
              <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                The complexity of GBV (and gender inequity broadly) is often cited as a reason not to incorporate it into fundamental financial decision making. But as this tool and methodology demonstrate, the data and models to integrate GBV into financial models exist. While GBV can be challenging to quantify, it is no more so than the processes for deciding how any number of economic, political, and financial indicators should be quantified and be weighted within financial models. 
              </p>
              <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                In finance, predicting the risk of an investment begins not with data but with a conversation—a qualitative assessment of what matters and how much it matters. The data, models, and math come after decision-makers determine what is material to investments. The data are leveraged to justify what investment decision-makers and analysts believe to be true, to accurately compute risk. 
              </p>
              <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                The choice to integrate GBV into financial models should and can be made following the same guidelines and processes as any other social and environmental factors. Decision-makers at financial institutions simply need to ensure that gender equity and GBV specialists have a seat at the decision-making table, in addition to instituting measures to ensure diverse voices are included in decision-making, such as at least 40% women and/or gender-diverse individuals on investment committees. The decision point on whether to integrate GBV into financial models centers around decision-makers asking themselves these two questions: 
              </p>
              <div class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                <ol class="px-2">
                  <li>1.  Do we believe that GBV is a material risk to our investments?</li>
                  <li>2.  If we do not integrate GBV into our financial models, are our models less accurate?</li>
                </ol>
              </div>
              <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                If the answer to both of these questions is “Yes,” then the next step is implementation: selecting to either use the GBVR or discrete GBV indicators, such as those laid out below, and applying them to the risk model the investor already uses. 
              </p>
              <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                If the answer to either question is “No”, then it is important to assess:
              </p>
              <div class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                <ul class="list-disc px-5">
                  <li>The breadth of gender equality and GBV expertise on the investment decision-making team; and </li>
                  <li>How GBV and gender equality information and expertise are valued and weighted as a ubiquitous risk in decision-making related to financial model inputs and overarching decisions.</li>
                </ul>
              </div>
              <!--div>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                  Investing in a re-imagined future free of GBV requires decision-makers at financial institutions to choose to equally value GBV data as a material risk to investment as the starting point to decision-making, modeling, and forecasting. Once a GBV Risk Score is calculated and a decision is made to use it...then what? Decision-makers must choose how to use this information, similar to how a decision-maker will choose how to use any other type of risk information assessed—from political instability to climate change. 
                </p>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                  Depending on the risk level, guidelines are provided in the proceeding section. Ultimately, investors need to de-risk the investment. GBV risk reduction is not only a risk mitigation strategy but may also present investment opportunity that increases the ROI. Illustrative actions that may be taken by investors as a result of understanding the GBV Risk Score could include the following:
                </p>
                <div class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify leading-7">
                  <ul class="list-disc px-5">
                    <li>Create conditional investment terms and conditions, either as pre-conditions or as conditions that must be met, with discrete GBV-specific milestones and targets to be met over the course of an investment </li>
                    <li>Invest in organizations or individuals who are experts in GBV and gender equality within the geographic risk area that influences the investment to implement GBV reduction activities</li>
                    <li>Work with regulatory bodies in a specific country to:</li>
                    <ul class="px-5" style="list-style-type: circle;">
                      <li>Integrate GBV into disclosure and regulatory requirements </li>
                      <li>Create bonds for GBV </li>
                      <li>Write conditions to change regulatory disclosures in annual reports where investments are made</li>
                    </ul>
                  </ul>
                </div>
              </div-->

            </section>
            <section id="LeveragingModels" class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300" style="max-width: 960px" >
                <h1 class="text-2xl leading-6 font-medium mt-10">Use Case: Utilizing the GBV Risk Model for an Infrastructure Investment in Country X</h1>
                    <div class="text-gray-600 w-full mt-3">
                    <item text="There is a wide spectrum of use cases for the GBVR methodology, varied by the type of user and financial actor. The use case presented here and below is one illustrative example of how the GBVR may be applied to investment decision-making of a development finance institution (DFI). Additionally use cases, case studies, and examples will be developed and published on a rolling basis. <br/><br/>
                              A DFI of Country X has a mandate to establish private partnerships for joint investment in the amount of US$210 million in infrastructure in Country X over a 25-year period, specific to road improvements, light rail, and urban market and transport hubs to facilitate increased trade, opportunity for entrepreneurs, and job creation. Prior to the investment, a full country risk analysis is required, including quantification of the country risk.[s47282] DFIs look at many factors to understand the risk of a particular country, including corruption, economic stability, government effectiveness, accountability, etc. These are categorized into political, operational, reputational, and regulatory risks. Before considering GBV risk the return on investment (ROI) is estimated to be 8.3%.
                          
                          <p style='margin-top: 10px; text-align: center;margin-bottom:5px;'><b>ROI = Net Return / (Planned Costs + Expected Loss) = $23.8M / ($210 + $77M) = 8.3%</b></p>
                          
                          The DFI has adopted best practices in gender assessment, action planning, and gender mainstreaming. This includes integrating gender equality indicators into its risk management framework. For country risk, GBV is incorporated as a material risk to projects or investments based on the methodology outlined here. Just as the other country risk categories measure the probability of loss and/or increased costs, ultimately resulting in a reduced ROI, so too does GBV risk. Therefore, the DFI has integrated GBV risk into its country risk rating using the model below.
                          
                          <p style='margin-top: 10px; text-align: center;'><b>Country Risk (Country X) = (wPR + wOR + wREPR + wREGR + wGBVR) / 5</b></p>
                          <p style='margin-top: 5px; text-align: justify; margin-left:33%;'>PR = Political Risk<br/>
                             OR = Operational Risk<br/>
                             REPR = Reputational Risk<br/>
                             REGR = Regulatory Risk<br/>
                             GBVR = GBV Risk<br/>
                             <i>w = weight assigned to each risk category </i></p><br/>
                             
                             To assess the overall country risk, the analyst uses various indicators for each risk category. For GBV risk, one GBVR is calculated for Country X according to the GBV Risk Score methodology, a composite score of over 30 GBV indicators. In this case, the analyst navigates to the Equilo platform (<a href='app.equilo.io' target='_blank' style='color: #0077C8'>app.equilo.io</a>) where the GBVR calculation is automated, pulling from the most recent data available for Country X. The GBVR for Country X is 50, which falls into the Severe GBV Risk category. Incorporating this score into the overall Country Risk score increases Country X’s risk from 39 to 41 (See Table 1).
                             <br/><br/>
                             <i>Table 1: Country Risk for Country X</i>
                             
                        <table style='border: none;border-collapse:collapse;'>
    <tbody>
        <tr>
            <td style='width:233.65pt;border:solid black 1.0pt;background:#002060;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='color:white;'>Risk categories</span></strong></p>
            </td>
            <td style='width:77.95pt;border:solid black 1.0pt;border-left:none;background:#002060;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>Risk Score</span></strong></p>
            </td>
            <td style='width: 2cm;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;background: rgb(0, 32, 96);padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>ROI</span></strong></p>
            </td>
        </tr>
        <tr>
            <td style='width:233.65pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Political Risk (PR)</span></p>
            </td>
            <td style='width:77.95pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>39</span></p>
            </td>
            <td rowspan='4' style='width: 2cm;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>&nbsp;</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:233.65pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Operational Risk (OR)</span></p>
            </td>
            <td style='width:77.95pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>44</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:233.65pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Reputational Risk (REPR)</span></p>
            </td>
            <td style='width:77.95pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>42</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:233.65pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Regulatory Risk (REGR)</span></p>
            </td>
            <td style='width:77.95pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>31</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:233.65pt;border-top:none;border-left:solid black 1.0pt;border-bottom:solid red 1.5pt;border-right:solid black 1.0pt;background:#BFBFBF;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='color:black;'>Country Risk w/o GBV Risk Score</span></strong></p>
            </td>
            <td style='width:77.95pt;border-top:none;border-left:none;border-bottom:solid red 1.5pt;border-right:solid black 1.0pt;background:#BFBFBF;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:black;'>39</span></strong></p>
            </td>
            <td style='width: 2cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid black;background: rgb(191, 191, 191);padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:black;'>8.3%</span></strong></p>
            </td>
        </tr>
        <tr>
            <td style='width:233.65pt;border-top:none;border-left:solid red 1.5pt;border-bottom:solid red 1.5pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>GBV Risk Score (GBVR)</span></p>
            </td>
            <td style='width:77.95pt;border-top:none;border-left:none;border-bottom:solid red 1.5pt;border-right:solid red 1.5pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>50</span></p>
            </td>
            <td style='width: 2cm;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>&nbsp;</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:233.65pt;border:solid black 1.0pt;border-top:none;background:#0070C0;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='color:white;'>GBV Risk-Adjusted Country Risk for Country X</span></strong></p>
            </td>
            <td style='width:77.95pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;background:#0070C0;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>41</span></strong></p>
            </td>
            <td style='width: 2cm;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(0, 112, 192);padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>7.8%</span></strong></p>
            </td>
        </tr>
    </tbody>
    </table>     <br/>
    When the expected loss (e.g., potential costs) associated with GBV risk was added to the other risk categories, the ROI fell to 7.8%, which is below the target hurdle rate of 8% and threatens the likelihood of this investment receiving approval from the Investment Committee. 
    <br/>
    <p style='margin-top: 10px; text-align: center;'><b>ROI = Net Return / (Planned Costs + Expected Loss) = $23.8M / ($210M + $80M + $15M) = 7.8%</b></p><br/>
    <b>What does this mean for the DFI’s investment?</b><br/><br/>
    For countries with a Severe GBVR, an additional level of gender assessment is required by this DFI, along with a remedial gender action plan. This will be included for consideration by the Investment Committee, which could also help the investment get over the hurdle rate of 8%. This may include investment pre-conditions and milestones over the course of the investment. The DFI can work with development agencies, private partners, and other stakeholders to invest in targeted transformational GBV risk reduction to decrease the material risk and create a more stable investment landscape. <br/><br/>
    
    Risk increases the potential costs and loss of an investment, which ultimately decreases the expected ROI. To quantify this, the risk analyst must estimate the potential costs/loss due to each risk and the probability of that risk materializing. For example, if there was a violent government insurgence resulting in a dictatorial takeover, there would be costs for evacuation of personnel and partners, and the DFI would be forced to abandon the investment altogether. To calculate the expected loss, the risk analyst estimates the total costs associated with this event, multiplied by the probability of the event occurring. As expected loss increases, the estimated cost of the investment increases, and thus brings down the overall return on the investment.<br/><br/>
    <p style='margin-top: 10px; text-align: center;'><b>Expected Loss = Probability of a Specific Risk Materializing x Loss Given a Specific Risk Materializing</b></p>
    <p style='margin-top: 10px; text-align: center;'><b>Cost of Investment = Planned Investment Expenses + Unplanned Expected Loss</b></p>
    <p style='margin-top: 10px; text-align: center;'><b>ROI = Net Return / Cost of Investment</b></p><br/>
    
    To understand where the greatest GBV risk lies in Country X, the analyst uses the Equilo platform to view the score and underlying indicators. Reviewing the GBV Risk Snapshot, the analyst quickly identifies which of the five domains has the most severe and significant GBV risk. Each domain is scored based on a weighted average of the GBV indicators associated with that domain. The image below visually illustrates where the relative GBV risk lies in Country X. It shows that four out of five domain scores for Country X present a severe risk, with Knowledge & Beliefs (80) representing the highest area of GBV risk, followed by Access to Resources (53), Power & Decision-Making (47), and Human Dignity (40). Law & Policy, although not severe, is still a significant risk with a score of 33, and not to be ignored.<br/><br/>
    
    <i>Figure 1: Country X GBV Risk Score by Domain</i>"
                    :country="country"
                    class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                <img src="../../assets/images/xCoutrySpider.png" />
                <div class="text-gray-600 w-full mt-3">
                    <item text="To better understand the specific risks within each domain, the analyst navigates the Equilo platform to assess the underlying indicators associated with each domain. See some examples in Figure 3. We can quickly see some of the underlying challenges—nearly 60% of women and 40% of men agree that men are justified beating their wives in certain circumstances—pointing to ingrained cultural belief and acceptance, including by women, of violence.<br/><br/>
                    <i>Figure 2. Assessing Specific Risk by Exploring Each Indicator</i><br/><br/>
                          
                          "></item>
                </div>
                          
                <img src="../../assets/images/xCountryData1.png" />
                <img src="../../assets/images/xCountryData2.png" /><br/>
                <div class="text-gray-600 w-full mt-3">
                    <item text="<b>What can the DFI do to de-risk the infrastructure investment and increase the ROI over the hurdle rate in Country X?</b><br/><br/>
                          To de-risk GBV from the investment, the expected loss associated with these GBV risks in Country X must be decreased. <br/>
                          <ul>
                          <li style='list-style-type: circle;'>At bare minimum compliance, all investments, beginning with those categorized as Ubiquitous and including those categorized as Severe Risk for GBV, must <b>put in place appropriate safeguards to mitigate GBV risk.</b> They will need to conduct a GBV Risk Assessment and put in place a GBV Risk Mitigation Plan specific to potential impacts of the specific investment. This addresses potential GBV risk presented by the investee because it is operating in this risk environment in Country X. The GBV Risk Mitigation Plan includes, for example, a regular GBV audit checking for warning signs or cases of sexual harassment and trafficking, as well as the implementation of grievance mechanisms to handle reported cases from employees, staff, and community members. </li><br/>
                          <li style='list-style-type: circle;'>However, since this investment is categorized as a Significant Risk or more, <b>opportunities must also be identified within the investment sphere of influence to take a proactive approach to reduce GBV.</b> For example, streetlighting is strategically placed not only along roads and surrounding public transport stops, but also around walking paths to the main roads, and an emergency call box placed along walking paths and at stops. Public toilets placed near public transport stops and in market areas are well lit, inside and out, with secured, gender-segregated spaces, including washing facilities. Posters are displayed with numbers to GBV helplines and behavior change communication in both male and female toilets. Public transport personnel are trained on how to recognize and respond to potential cases of GBV.  
                          </li><br/>
                           <li style='list-style-type: circle;'>·	Lastly, because Country X is classified as Severe Risk, the DFI must go beyond to <b>invest in transformative actions as a pre-condition, or as part of the investment terms and conditions, to ensure Country X is taking necessary action to reduce GBV risk writ large,</b> with a strategic focus on domains that either present the highest risk or domains that, with some investment, could significantly improve and de-risk across other domains. The risk management team works with stakeholders (e.g., investment team, ESG team, donors, and public and private partners, including government representatives and civil society), in consultation with GBV and gender equality experts in Country X. With local civil society and GBV experts guiding the process, a gender equality action plan and GBV prevention and response plan is developed and financed to be implemented nationally. The plan is accompanied by the investment required and clear goals, targets, and timelines. This particular DFI has a GBV Prevention and Response Policy in place, stipulating that, across all investments made in countries categorized Severe, they will invest, at minimum, an additional 5% of the investment value into a GBV Prevention and Response Plan to be implemented within the first 4 years of the investment, requiring the DFI to partner with others to co-fund a $10.5 million GBV Prevention and Response Plan.
                          </li>
                          
                          </ul><br/>
                          In this instance in Country X, given that most domains are Severe and only one is Significant, investing in GBV risk reduction requires a multi-pronged investment approach that will not only increase access to services for survivors and strengthen the legal framework, but also shift beliefs and social norms as a prerequisite for investment, or as a term and condition that is part of the investment. This will be led by a gender equality expert within Country X, with significant consultation of three civil society groups focused on GBV reduction and increasing gender equality. Illustrative systems-level transformative activities that were included in the plan and implemented by a range of government, civil society, and non-governmental organizations within the country include, but are not limited to:<br/><br/>
                          
                          <ul>
                           <li style='list-style-type: circle;'>Adopt and implement a regulatory framework for criminalizing domestic violence.</li>
                           <li style='list-style-type: circle;'>Adopt and implement a regulatory framework for child marriage, including provision for legal enforcement of the law.</li>
                           <li style='list-style-type: circle;'>Increase government allocation to GBV multi-sector (e.g., of medico-legal, social, economic services, etc.) response for survivors.</li>
                           <li style='list-style-type: circle;'>Target men employed in transport and police departments with male engagement strategies and associated behavior change communication with a goal to shift norms away from equating girls and women as property to valuing them as equal partners, decision-makers, and co-workers. </li>
                          </ul><br/>
                          Each of these actions are expected to contribute to reducing the probability of GBV risk within Country X, based on well-documented research in the country and region, thereby decreasing the expected loss. For each GBV indicator, the DFI establishes a new target outcome level to be achieved during the first four years and beyond in years 10, 15, 20, and at the investment in year 25. These targets are then plugged into the GBV Risk Model to calculate the reduced GBVR. <br/><br/>
                          There is, of course, a cost to the implementation of the GBV risk reduction actions via the GBV Prevention and Response and Plan. Per the DFI policy, if a country falls into the Severe GBV Risk Category, at least 10% of the investment total must spent on GBV risk reduction actions. Given that Country X is Severe for GBV risk, an additional $10.5M must be invested in GBV risk mitigation and reduction.<br/><br/>
                          Based on those risk reduction actions, there is a decreased probability of the costs associated with the GBV risk being realized, bringing down the expected loss. Not only that, but considering improved social outcomes, the projected return also increases. After making the adjustments, a pro forma[s47283] Country Risk Score is calculated; the projected ROI, taking into account improved outcomes and decreased risk, now increases past the 8% hurdle rate to 9%, which is also an increase from the original ROI estimated of 8.3% before considering GBV (See Table 2). 
                          <p style='margin-top: 10px; text-align: center;'><b>ROI = Net Return / (Planned Costs + Expected Loss) = $27.5M / ($210M + $10.5 + $73M + $12M) = 9%</b></p> <br/>
                          <i>Table 2:  GBV Risk-Adjusted Country Risk for Country X</i><br/>
                          <table style='border: none;border-collapse:collapse;'>
    <tbody>
        <tr>
            <td style='width:226.55pt;border:solid black 1.0pt;background:#002060;padding:0cm 5.4pt 0cm 5.4pt;height:27.75pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='color:white;'>Risk categories</span></strong></p>
            </td>
            <td style='width:2.0cm;border:solid black 1.0pt;border-left:none;background:#002060;padding:0cm 5.4pt 0cm 5.4pt;height:27.75pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>Risk Scores</span></strong></p>
            </td>
            <td style='width:46.75pt;border:solid black 1.0pt;border-left:none;background:#002060;padding:.75pt .75pt .75pt .75pt;height:27.75pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>ROI</span></strong></p>
            </td>
            <td style='width:71.2pt;border:solid black 1.0pt;border-left:none;background:#002060;padding:0cm 5.4pt 0cm 5.4pt;height:27.75pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='color:white;'>Pro forma <br>&nbsp;Risk Scores</span></strong></p>
            </td>
            <td style='width:66.3pt;border:solid black 1.0pt;border-left:none;background:#002060;padding:.75pt .75pt .75pt .75pt;height:27.75pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>Pro forma ROI</span></strong></p>
            </td>
        </tr>
        <tr>
            <td style='width:226.55pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Political Risk (PR)</span></p>
            </td>
            <td style='width:2.0cm;border-top:none;border-left:none;border-bottom:  solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>39</span></p>
            </td>
            <td rowspan='4' style='width: 46.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>&nbsp;</span></p>
            </td>
            <td style='width:71.2pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>38</span></p>
            </td>
            <td rowspan='5' style='width: 66.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>&nbsp;</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:226.55pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Operational Risk (OR)</span></p>
            </td>
            <td style='width:2.0cm;border-top:none;border-left:none;border-bottom:  solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>44</span></p>
            </td>
            <td style='width:71.2pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>36</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:226.55pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Reputational Risk (REPR)</span></p>
            </td>
            <td style='width:2.0cm;border-top:none;border-left:none;border-bottom:  solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>42</span></p>
            </td>
            <td style='width:71.2pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>32</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:226.55pt;border:solid black 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>Regulatory Risk (REGR)</span></p>
            </td>
            <td style='width:2.0cm;border-top:none;border-left:none;border-bottom:  solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>31</span></p>
            </td>
            <td style='width:71.2pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>27</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:226.55pt;border-top:none;border-left:solid red 1.5pt;border-bottom:solid red 1.5pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><span style='color:black;'>GBV Risk Score (GBVR)</span></p>
            </td>
            <td style='width:2.0cm;border-top:none;border-left:none;border-bottom:  solid red 1.5pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>50</span></p>
            </td>
            <td style='width: 46.75pt;border-top: none;border-left: none;border-bottom: 1.5pt solid red;border-right: 1pt solid black;padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>&nbsp;</span></p>
            </td>
            <td style='width:71.2pt;border-top:none;border-left:none;border-bottom:solid red 1.5pt;border-right:solid red 1.5pt;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><span style='color:black;'>42</span></p>
            </td>
        </tr>
        <tr>
            <td style='width:226.55pt;border:solid black 1.0pt;border-top:none;background:#0070C0;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:  normal;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='color:white;'>GBV Risk-Adjusted Country Risk for Country X&nbsp;</span></strong></p>
            </td>
            <td style='width:2.0cm;border-top:none;border-left:none;border-bottom:  solid black 1.0pt;border-right:solid black 1.0pt;background:#0070C0;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>41</span></strong></p>
            </td>
            <td style='width: 46.75pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(0, 112, 192);padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>7.8%</span></strong></p>
            </td>
            <td style='width:71.2pt;border-top:none;border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;background:#0070C0;padding:0cm 5.4pt 0cm 5.4pt;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>35</span></strong></p>
            </td>
            <td style='width: 66.3pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;background: rgb(0, 112, 192);padding: 0.75pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:'Calibri','sans-serif';text-align:center;'><strong><span style='color:white;'>9%</span></strong></p>
            </td>
        </tr>
    </tbody>
    </table><br/>
<b>However, costs of these actions should not be purely allocated to a specific investment when calculating ROI.</b> Rather, some of these costs could be absorbed by a partnering development agency as part of its overall mission, further reducing cost and increasing ROI past 9%. Even if:<br/>
<ul><li style='list-style-type: circle;'>The DFI assumes the full cost, then the cost should be spread across the DFI’s portfolio for Country X, as it will decrease the expected loss across its portfolio. </li>
<li style='list-style-type: circle;'>This single investment were to assume the entire cost of the actions, the ROI is still improved in this case due to a) the improved outcomes of the project and b) the cost of risk reduction being less than the expected loss.</li>
</ul><br/>
This improved ROI also does not account for the benefit to any follow-on investments by this DFI and other stakeholders that will also benefit from the GBV risk reduction, which would <b><i>further increase the project-specific ROI</i></b> and enhance the social rate of return. Thus, the ROI has now increased not only for this specific investment but also improved the GBVR for Country X overall. In addition, it has contributed to de-risking other risk categories, including political and economic risk. This investment in GBV risk reduction has rippling effects for Country X, positioning it as an attractive place for private investors and positive economic growth projections. 
                          ">
                        </item>
                </div>
            </section>
          </div>
        </transition>

        <!-- METHODOLOGY -->
        <transition name="fadeIn">
          <div v-if="current_step == 3" class="flex flex-col items-center justify-center w-full">
            <section
              id="Practice"
              class="w-full container mx-auto px-4 lg:px-0"
              style="max-width: 960px"
            >
              <div class="flex flex-col">

                <div class="flex flex-row mb-10 items-center justify-center">
                  <div class="flex flex-col items-center justify-center p-3 bg-white">
                    <a href="https://www.equilo.io/" target="_blank">
                      <img class="w-64" src="@/assets/images/logo-navy.png" />
                    </a>
                  </div>
                  <div class="flex flex-col items-center justify-center p-3 mx-5 bg-white">
                    <a href="https://criterioninstitute.org/" target="_blank">
                      <img class="w-64" src="@/assets/images/criterion.png" />
                    </a>
                  </div>
                  <div class="flex flex-col items-center justify-center p-3 bg-white">
                    <a href="https://www.unicef.org/" target="_blank">
                      <img class="w-56" src="@/assets/images/UNICEF_logo.png" />
                    </a>
                  </div>
<!--                  <div class="flex flex-col items-center justify-center p-3 bg-white">
                    <a href="https://www.unicef.org/" target="_blank">
                      <img class="w-64" src="@/assets/images/unicef.png" />
                    </a>
                  </div>-->
                </div>

                <h1 class="text-2xl leading-6 font-medium mb-2">Background</h1>
                <p class="text-gray-600 w-full mt-3 font-medium gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                  <item
                  text="Gender-based violence (GBV) is a material risk to financial investments, including within infrastructure, energy, manufacturing, tourism, agriculture, and health industries. Approximately 1 in 3 women and girls experience physical and sexual violence during their lifetime, [s45125] and the cost of GBV to the global economy estimated to be 2% of global GDP, or US$1.5 trillion.[s3204] Addressing GBV across investments is imperative from impact, outcomes, and risk perspectives.<br><br>
                  This paper lays out why GBV is a material risk to investors across geographies, asset classes, and investment types. It demonstrates the materiality of GBV to investment analysis across multiple dimensions of risk and then provides a tool and methodology for how investors of all types to incorporate GBV risk analysis to get to better outcomes."
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </p>
                <h1 class="text-2xl leading-6 font-medium mb-2 mt-12">The Challenge: Reframing a Social Factor as Material</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                      text="Broadly speaking, GBV is not seen as a material risk to investments. The investors most likely to be addressing GBV in their investments are government investors, such as Development Finance Institutions (DFIs) and Multilateral Development Banks (MDBs); for the most part these investors are treating it as a social rather than a material risk. <br/><br/>
                      Applying a materiality approach entails including GBV data in financial calculations in the same way as standard macroeconomic indicators. The crises of the last two decades in financial systems have demonstrated that systems of finance have been most vulnerable to factors left outside the pricing calculation.[s47195] In 2008, the exclusion of real risk calculations in mortgage portfolios led to the building up of incredible volumes of investments accumulated in mispriced assets. When those assets incorporated the information that had been excluded, the repricing of the assets to their true value resulted in the largest financial crisis since the Great Depression. The incorporation of the previously unseen is of enormous significance to investments of all types, and there is growing acceptance that “social” issues—such as inequity based on race, gender, caste, etc.—are among the most consistently overlooked material factors. "
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                <h1 class="text-2xl leading-6 font-medium mb-2 mt-12">The Solution: Value GBV Risk in Financial Models</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                      text="Similar to the way in which climate change concerns have increasingly shaped risk/return analyses and become embedded into investment decision-making over the last two decades, GBV must be measured and comprehensively integrated into risk calculations and decisions.<br/><br/>
                      The lens of violence prevention should be integrated into the core investment and risk processes and teams, rather than being limited to gender and compliance processes and teams. Governments, in their role as financing institutions, play a critical role in establishing precedents and benchmarks for investments. The Criterion Institute’s research has demonstrated the importance of leveraging these institutions in order to lead and accelerate change in the assessment of risk. <br/><br/>
                      <span style='color: #0077C8'><b>If the financial institutions directing investments in all industries, and in particular infrastructure and other male-dominated sectors, coordinate to recognize and seek responses to GBV as a ubiquitous risk, similar to the risks posed by the climate crisis, then the significant budgetary allocations to investment can dramatically move the needle in reducing GBV.</b></span>"
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                <h1 class="text-2xl leading-6 font-medium mb-2 mt-12">A Tool to Measure the Material Risk of GBV</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                      text="To this end, the Criterion Institute partnered with UNICEF and Equilo to develop this GBV Risk Score (GBVR) methodology. The purpose of the GBVR is to enable investors of all kinds to assess the risk GBV poses to different types of investments, similar to assessing political, economic, or other financial risks, within financial models. The GBVR, similar to other risk types considered in financial models, may be used for financial investments across different geographies, sectors, and time frames.<br/><br/>
                      The GBVR leverages Criterion’s extensive research on the complex topic of financial investments and GBV. It also builds upon Equilo’s existing gender equality scoring methodology, database, and platform to operationalize the GBVR for users in the form of an online tool powered with real-time data. This collaborative work to develop the methodology was co-created by Criterion and Equilo with support from UNICEF. Equilo developed and maintains the platform, tool, and regularly updated database with a cleaned dataset and automated calculations. Various DFIs and MDBs were also consulted during the iteration of the methodology and tool development. <br/><br/>
                      Using the GBVR creates a virtuous cycle of reduced GBV, increased safety in workplaces, learning spaces, homes, and communities, which also decreases investment risk and increases investment opportunity:<br><br>
                  <ul>
                  <li style='list-style-type: circle;'>It allows investors to make safer investments in the short term by acknowledging GBV risk, and opportunities to address it, from the very first stage of the investment decision-making process.</li>
                  <li style='list-style-type: circle;'>Using the GBVR tool, investors are able to classify GBV risk as “ubiquitous,” “significant,” “severe,” or “extreme” and implement effective actions to move beyond risk mitigation to identify opportunities to invest in transformative GBV prevention and response activities. </li>
                  </ul>
                  <br/>
                  This GBVR may also be used by other actors, such as policymakers, development and humanitarian organizations, and civil society who can influence systems of finance through policy, technical support and collaboration, and advocacy efforts. This contributes to critical long-term efforts to combat the root causes of GBV, lower prevalence, and increase impact, thus improving people’s lives, especially the lives of women and girls who are disproportionately at risk of GBV, while also improving investment decision-making and outcomes.<br/><br/>
                  This GBVR methodology and dynamic tool is intended to support financing institutions and governments to have informed conversations regarding the materiality of GBV to investments toward the end goal of financing the reduction of GBV globally. Users may visit <a href='https://app.equilo.io' target='_blank' style='color: #0077C8'>app.equilo.io</a> to access this GBVR tool to support financial analysis, with customized tools, data, and insights for countries and sectors."
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                
                <div
                  class="shadow overflow-hidden rounded-lg mx-2 my-4"
                  :style="'border-style: solid; border-width: 2px; background-color:' + env.brandingColors.navy"
                >
                  <div
                    class="px-4 py-4 border-b sm:px-6 flex row cursor-pointer text-white"
                    :style="'border-style: solid; border-width: 0px 0px 1px;'"
                    @click="togglePanel('challenge-dfi')"
                  >
                    <h3 class="text-medium leading-6 font-medium text-white">
                      The Challenge for DFIs: Safeguarding vs. Material Risk
                    </h3>
                    <div
                    :style="'margin-left: auto; cursor:pointer; color: white;'"
                    >
                      <span v-if="openPanels.indexOf('challenge-dfi') > -1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  
                  </div>
                  <transition name="slide-fade">
                    <div
                      v-if="openPanels.indexOf('challenge-dfi') > -1"
                      class="flex flex-col mt-5 px-5 pb-5"
                    >
                      <div class="text-white w-full">
                        <item
                          text="Currently, most investors address GBV in their investments from a safeguarding perspective rather than regarding it as material. Safeguarding is an approach that protects an investment or project from harm or damage with appropriate measures, with a focus on risk mitigation for reducing potential harm an investment could have on people or the environment. Materiality, on the other hand, refers to information that is considered significant to financial decision-making—that is, to an assessment of a potential investment’s upside and risk over a period of time. As such, information that is considered material becomes a part of an institution’s financial risk analysis. Over the past decade, there has been tremendous progress in incorporating a safeguarding perspective to reduce the risk of gender-based violence within investments, reflected in the “Do No Harm” approach. In this approach, responding to GBV appears as an increased cost of compliance (e.g., following specific standards or rules set by institutions or regulatory bodies)—for example, the costs associated with hiring a gender specialist, implementing an independent reporting mechanism, providing training for contractors, designing codes of conduct, etc. <br/><br/>
                          While critically important, the implementation of this approach has also highlighted its limitations. While safeguarding is vital, it is typically siloed within Environmental and Social (E&S) teams and has resulted in a compliance-led approach with limited repercussions in cases of noncompliance. This means GBV prevention and response is largely not integrated into risk/investment teams’ core work, and often means that addressing GBV often continues to be a “tick-the-box” activity after an investment decision is already made. Investments that apply safeguards after the project has already been selected and approved both miss an opportunity to integrate GBV prevention into the project’s design and can have unintended consequences that penalize survivors of violence. <br/><br/>
                          In contrast to safeguarding, a materiality approach underscores the importance of integrating GBV from the very beginning so that it is built into the financial models that investors use to evaluate the viability of a given investment and price risk. GBV is thus reframed—it is no longer limited to risk mitigation activities, although those continue to be an important element, but also a critical element of decision-making. It unlocks the way we view opportunities, not limiting investment to risk mitigation and minimum compliance, but pushing further to identify opportunities to invest in lasting transformative change. While safeguarding is largely limited to mitigating negative issue impact, a materiality approach has the potential for three types of positive outcomes: issue impact (prevention of violence and better support for survivors), better outcomes at the project level, and better investment outcomes."
                          :country="country"
                          class="print:leading-relaxed print:text-xs text-sm leading-7"
                          style="line-height: 1.5;"
                        ></item>
                      </div>
                    </div>
                  </transition>
                </div>

                
                <h1 class="text-2xl leading-6 font-medium mb-2 mt-12">GBV Risk Score Sub-Domains</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                      text="GBV is rooted in power imbalances and structural inequalities. It is characterized by the abuse of physical, emotional, or financial power and control. Central to the construction of a composite score is the need to combine indicators in a meaningful way that helps users to understand and assess GBV risk overall for a country, as well as risk within the sub-domains of GBV risk, such as laws and policies or knowledge and beliefs surrounding GBV.<br><br>"></item>

                    <item
                      text="
The five indices below are drawn directly from the Transforming Agency, Access, and Power (TAAP) analytical framework.[s38821] The Transforming Agency, Access, and Power (TAAP) Framework includes six domains; one domain, Roles & Responsibilities, is excluded from the GBV Score due the lack of relevant GBV data to create a meaningful sub-index. Each of the following sub-domains represents a distinct and unique dimension of the GBVR in aggregate, comprised of several unique indicators. Analyzing risk within these sub-domains is critically important to decision-makers in order to appropriately price risk, target investment criteria, and to fund the right GBV risk reduction.<br><br/>
* <b>HUMAN DIGNITY</b><br>This sub-domain captures the actual measured experience of various types of GBV, such as physical or emotional violence or child marriage. Data on GBV experienced is often under-reported; therefore, indicators measuring formally reported GBV incidences are not used. Instead, indicators measuring self-reported experiences of GBV within anonymized household or workplace surveys are used. 
* <b>LAW & POLICY</b><br>This sub-domain measures to what extent policies and laws are in place to appropriately prevent and respond to GBV, as indication of the enabling environment; it does not measure the extent to which the laws and policies are implemented. Discriminatory laws and a lack of legal protections affect women and girls globally, impacting their mobility, workplace experiences, remuneration, marriage, parenthood, entrepreneurship opportunities, asset accumulation, and access to pensions, among other aspects of their lives.
* <b>KNOWLEDGE & BELIEFS</b><br>This sub-domain measures the acceptance, by women and men, of GBV, with higher social acceptance of GBV correlating to increased risk of GBV. Gender, social, and cultural norms and beliefs shape individuals’ identities, social experiences, and opportunities.
* <b>ACCESS TO RESOURCES</b><br>This sub-domain measures the extent to which GBV survivors have access to appropriate medical, legal, and mental health services, and to what extent they seek help after experiencing GBV. Lower access to resources and help-seeking behavior increases risk of GBV as perpetrators are aware of impunity, and exacerbate consequences for GBV survivors. Gender gaps persist in women and girls’ access to education, income, property, financial services, technology, social protection, healthcare, and other resources.
* <b>POWER & DECISION-MAKING</b><br>This sub-domain measures the extent to which individuals have autonomy and agency in making decisions for themselves; higher levels of personal agency and autonomy reduce GBV risk. It also includes the decision-making power and personal agency to meaningfully participate in the workplace, education, politics, social protest, and other activities; gain control over assets such as land and financial resources; and live healthy and productive lives free from violence.<br/><br/>
The methodology to compute the GBVR is similar to that of other well-known composite index scores such as the Women, Peace, and Security Index[s1978] (see Figure 1 below). We compute the GBVR for each country in two steps: <br/><br/>
1. Compute a GBV score for each indicator; and <br/>
2. Aggregate the scores by domain.
"
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                <h1 class="text-2xl leading-6 font-medium mt-10">
                  Criteria for GBV Indicators 
                </h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                  text="Specific indicators are used to calculate the GBVR sub-domain scores. These indicators are selected based on the following criteria: "
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
<!--                <img src="../../assets/images/gbv methodology.png" />-->
                <div class="text-gray-600 w-full mt-3">
                  <item
                  text="<ul><li style='list-style-type: circle;'>Provides an accurate representation of the GBV risk within each domain based on extensive qualitative literature and research.</li>
                    <li style='list-style-type: circle;'>Represents the best available indicator to measure the domain (e.g., self-reported experience of violence in anonymous surveys rather than formally reported experience of violence).</li>
                    <li style='list-style-type: circle;'>Drawn from publicly available, internationally standardized and recognized data sets backed by a valid survey methodology and instrument.</li>
                    <li style='list-style-type: circle;'>Data are regularly updated and made available for the indicator.</li>
                    </ul>
                    <br>
                  The indicators are intended to be dynamic, such that as new data sets are released with brand new indicators, those may be incorporated into these calculations. New indicators and data will increase the robustness and strength of the GBVR. Although the indicators identified within this methodological paper remain static, the Equilo GBV Risk Score tool available at <a href='https://app.equilo.io' target='_blank' style='color: #0077C8'>app.equilo.io</a> will incorporate new indicators into the framework as they become available.<br/><br/>
                  The GBV Indicators reflected above, and that are currently utilized to calculate the GBVR tool, may be found in Annex A."
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                <img src="../../assets/images/gbv methodology.png" />
                
                <h1 class="text-2xl leading-6 font-medium mt-10 mb-3">
                  GBV Risk Indicator Sources
                </h1>
                <div class="text-gray-600 w-full mb-3">
                  <item
                  text="The following indicators are utilized to calculate each GBV Risk sub-domain Score. Notes are made in instances where indicators are not always used, or are provided for contextual data only and are not calculated in scores."
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-row w-3/12 border p-2 text-medium">
                        <b>Human Dignity</b>
                    </div>
                    <div class="flex flex-row w-10/12 border p-2 ">
                        <vue-airtable-gbv filter="AND(AND({domain_Alpha} = 'Human Dignity'), AND(FIND('GBV Risk Score', where_used_Alpha) > 0))"></vue-airtable-gbv>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-row w-3/12 border p-2 text-medium">
                        <b>Law & Policy</b>
                    </div>
                    <div class="flex flex-row w-10/12 border p-2 ">
                        <vue-airtable-gbv filter="AND(AND({domain_Alpha} = 'Law & Policy'), AND(FIND('GBV Risk Score', where_used_Alpha) > 0))"></vue-airtable-gbv>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-row w-3/12 border p-2 text-medium">
                        <b>Knowledge & Beliefs</b>
                    </div>
                    <div class="flex flex-row w-10/12 border p-2 ">
                        <vue-airtable-gbv filter="AND(AND({domain_Alpha} = 'Knowledge & Beliefs'), AND(FIND('GBV Risk Score', where_used_Alpha) > 0))"></vue-airtable-gbv>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-row w-3/12 border p-2 text-medium">
                        <b>Access to Resources</b>
                    </div>
                    <div class="flex flex-row w-10/12 border p-2 ">
                        <vue-airtable-gbv filter="AND(AND({domain_Alpha} = 'Access to Resources'), AND(FIND('GBV Risk Score', where_used_Alpha) > 0))"></vue-airtable-gbv>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-row w-3/12 border p-2 text-medium">
                        <b>Power & Decision-Making</b>
                    </div>
                    <div class="flex flex-row w-10/12 border p-2 ">
                        <vue-airtable-gbv filter="AND(AND({domain_Alpha} = 'Power & Decision-Making'), AND(FIND('GBV Risk Score', where_used_Alpha) > 0))"></vue-airtable-gbv>
                    </div>
                </div>
                <div class="text-gray-600 w-full mt-3">
                  <item
                  text="* <i>FGM indicators are only used in countries where FGM is a cultural practice. In countries where it is not a cultural practice, FGM indicators are excluded from the GBV Risk Score calculation.</i>
                  * <i>Female homicide rate is an important indicator to assess violence against women. However, due to known challenges in formal reports or classification of violence, this indicator remains a non-scored contextual indicator.</i>
                  * <i>Police recorded offenses of sexual violence are explicitly not used for scoring, nor is this used to identify actual cases of violence as a Human Dignity indicator. This is because police reported cases are notoriously underreported and very poor sources of data to understand actual rates of sexual violence. However, it is important contextual information to be understood and carefully interpreted (e.g., higher rate of recorded offenses could indicate a healthy, well-functioning social and justice system, and is therefore placed in Access to Resources as a contextual indicator to assess access to justice.</i>"
                  :country="country"
                  class="gap-10 text-justify text-xs leading-7"
                  style="line-height: 1.5"
                  ></item>
                </div>
                <h1 class="text-2xl leading-6 font-medium mt-10 mb-3">
                  GBV Risk Level
                </h1>
                <div class="text-gray-600 w-full mb-3">
                  <item
                      text="GBV risk levels help us assess for a specific country the level of risk GBV presents to a specific investment. The GBVR expresses the risk of GBV occurring within a specific country, based on a composite score of five sub-domain scores on a scale of 0 to 100, where movement closer to 0 represents reduction of risk and movement toward 100 represents heightened risk.<br/><br/>
                      The table below outlines four GBV risk levels on a scale of 0-100, accompanied by scoring bands, definitions to understand each risk level, and investment implications to be considered to apply to investments within countries at each risk level. <br/><br/>
                      The risk level bands between 0-100 are carefully selected to consider the following:
                      <ul>
                      <li style='list-style-type: circle;'>Because GBV risk is ubiquitous (i.e., there is no place in the world that is not affected by GBV), any country with a score of less than 20 is identified as a country with ubiquitous risk. There is intentionally no “low” risk level, so as not to inaccurately indicate that there is low GBV risk anywhere in the world, or to inaccurately signal that GBV is not a material risk to investments in any specific country in the world.</li>
                      <li style='list-style-type: circle;'>The scale is adjusted such that any country with a score of 60 or higher is considered an Extreme Risk. This is intentionally chosen, since, at the time of creating this methodology no single country was found to have an overall GBV Risk Score higher than 72, necessitating adjustment for this category to account for the lack of any scores that fall between 73-100.</li>
                      <br/>
                      Each risk level specified in the table below recognizes that investments made at any GBV risk level should always include a GBV Risk Assessment and GBV Risk Mitigation Plan as a minimum compliance safeguarding approach following international standards and best practice. GBV risk mitigation alone is insufficient to protect the investment from ubiquitous GBV risk that may negatively influence the ROI at significant, severe, and catastrophic risk levels. As risk levels increase, the material risk of GBV necessitates that action be taken to invest in GBV risk reduction to address ubiquitous risk in increasing intensity. <br/><br/>
                      <span style='color: #0077C8'><b>GBV is ubiquitous. There is no place in the world where GBV does not impact people, families, organizations, economies, and, thereby, investments. Recognizing this, we need a GBV Risk Score Framework that is grounded in the reality of ubiquitous risk. In practice, we need to systematically assess the material risk of GBV to investments. And then we need to do something to reduce the material risk of GBV, identifying appropriate investment response relative to the scale of the identified risk. </b></span><br/><br/>

                      Table 3 GBV Risk Levels<br/>
                      
                      <table style='width: 8.2e+2pt;border-collapse:collapse;border:none;'>
    <tbody>
        <tr>
            <td style='width: 53.75pt;border: 1pt solid black;background: rgb(68, 114, 196);padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:white;'>Risk Level</span></strong></p>
            </td>
            <td style='width: 229.5pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;background: rgb(68, 114, 196);padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:white;'>Definition</span></strong></p>
            </td>
            <td style='width: 373.5pt;border-top: 1pt solid black;border-right: 1pt solid black;border-bottom: 1pt solid black;border-image: initial;border-left: none;background: rgb(68, 114, 196);padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:white;'>Investment Implications</span></strong></p>
            </td>
        </tr>
        <tr>
            <td style='width: 53.75pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(192, 0, 0);padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:white;'>Extreme</span></strong></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:white;'>(60+)</span></strong></p>
            </td>
            <td style='width: 229.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>Countries where the overall GBV risk score is extreme typically have dangerously fragile or hostile enabling environments for preventing and responding to GBV. In addition, knowledge and beliefs typically reinforce social norms that threaten the safety, security, and lived experience of large masses of people, disproportionately women, girls, and those with non-conforming sexual and gender identities. The lived experiences of these populations are often marked by GBV, or the threat thereof, with few options to access help.&nbsp;</span></p>
            </td>
            <td style='width: 373.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>GBV risk is so high that investment is tenuous; extreme overall GBV in a country may predict untenable political and economic risk or may reflect crisis or collapse. Depending on the investment type/sector/scope, nation-state action may be required to significantly redress GBV risk before an investment may be made, as an explicit pre-condition to investment. Investments could otherwise, even with safeguarding and risk mitigation efforts, exacerbate GBV due to entrenched norms and harmful practices. This could require collaborative partnerships with civil society organizations, development agencies, development finance institutions, and government stakeholders to identify, design, and invest in strategic and critical GBV reduction that aims to reduce entrenched harmful gender norms and GBV.</span></p>
            </td>
        </tr>
        <tr>
            <td style='width: 53.75pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(255, 102, 0);padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><b><span style='font-size:12px;line-height:107%;color:black'>Severe</span></b></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:black'>(40-59)</span></strong></p>
            </td>
            <td style='width: 229.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>Countries where the overall GBV risk score is severe likely have pervasive, systemic problems at all levels. Although there may be areas of strength, such as relatively strong enabling environment with appropriate laws and policies, there are likely at least two areas that present significant risk of GBV, such as harmful knowledge and beliefs held by women and men that perpetuate cycles of violence, poor access to services and safety nets, or alarming lived experience of GBV.&nbsp;</span></p>
            </td>
            <td style='width: 373.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>The severity of GBV risk triggers additional levels of risk assessment and consideration of investment committees. In some cases, it may entail making investments conditional upon improvement in the GBV Risk Score prior to the investment being made. If pre-conditions are deemed unwarranted, then, at minimum, clear investment terms are included that require either risk mitigation or transformational GBV risk reduction and increased stability of the investment landscape. Gender/GBV experts on the investment team and in-country advise on terms that mitigate GBV risk and/or priority investment areas to make systemic, transformational changes that address entrenched social norms, harmful beliefs and practices, and other identified risk areas. Specific GBV indicators are set, alongside a clear action plan and milestones to meet GBV indicator goals; investment disbursements are tied to successfully meeting these GBV terms and conditions, with specific thresholds that must be met or passed or continue disbursement of funds.</span></p>
            </td>
        </tr>
        <tr>
            <td style='width: 53.75pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: rgb(255, 192, 0);padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:black'>Significant</span></strong></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:black'>(20-39)</span></strong></p>
            </td>
            <td style='width: 229.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>Countries with an overall significant GBV risk score are those where there may be relatively stronger legal and policy frameworks in place to prevent and respond to GBV or may have adequate access to resources and safety nets for survivors of GBV. However, countries with significant risk, despite a strong enabling environment to prevent and respond to GBV, may be threatened by power dynamics and knowledge and beliefs that perpetuate harmful norms, and thereby have unacceptable rates of broad types of GBV in homes, communities, and places of work.</span></p>
            </td>
            <td style='width: 373.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>Countries where the overall GBV risk is significant require consideration in investment in the enabling environment (policy, practices, and norms) to protect investments. For private investors, assessment of the operational, regulatory, political, and reputational risk of GBV should be a fundamental component of the analysis of opportunity and risk. For governments and blended finance, investment terms or clauses should identify a specific amount or proportion of the investment that is expected to be directed towards GBV prevention and response that go beyond risk reduction measures. A GBV Prevention and Response Plan, or a comprehensive Gender Equality Action Plan, should be attached to the terms that details concrete actions and timelines to be taken over the life of the investment. Opportunities to invest in actions within the investee’s sphere of influence should be detailed for action. </em></span></p>
            </td>
        </tr>
        <tr>
            <td style='width: 53.75pt;border-right: 1pt solid black;border-bottom: 1pt solid black;border-left: 1pt solid black;border-image: initial;border-top: none;background: yellow;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:black'>Ubiquitous</span></strong></p>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><strong><span style='font-size:12px;line-height:107%;color:black'>(&lt;20)</span></strong></p>
            </td>
            <td style='width: 229.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>No country in the world is free of GBV or the threat of GBV; GBV is ubiquitous. Countries with ubiquitous risk may have measures in place to appropriately prevent and respond to GBV, yet still have a population that experiences, and is threatened by, GBV and ongoing norms that perpetuate it.</span></p>
            </td>
            <td style='width: 373.5pt;border-top: none;border-left: none;border-bottom: 1pt solid black;border-right: 1pt solid black;padding: 0cm 5.4pt;vertical-align: top;'>
                <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:'Calibri','sans-serif';'><span style='font-size:12px;line-height:107%;'>Safeguarding and do-no-harm lenses should always be utilized as a minimum to assess and mitigate GBV risk over the course of the investment. Opportunities for investing in GBV risk reduction, in addition to safeguarding, should be assessed.</span></p>
            </td>
        </tr>
    </tbody>
</table>
                      "
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                <h1 class="text-2xl leading-6 font-medium mb-2 mt-12">Sub-Domain and GBV Risk Score Calculation Methodology</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                      text="
* <b>NORMALIZING INDICATOR VALUES</b><br>All discrete indicators, regardless of the data reporting format, are first harmonized so that they are measured in the same direction and utilizing the same unit of measurement. Thus, each indicator is scaled between 0 and 100, ensuring the direction of each indicator is scaled where 100 represents the highest GBV risk and 0 represents the lowest. For example, in the instance of indicators that measure the percentage of women who experience violence, these values are flipped so the value is no longer X, but 100 - X.
* <b>SCALING INDICATOR VALUES</b><br>Next, prior to computing sub-domain scores the indicator values are scaled so that an indicator value of 0 represents the worst value in the world, out of all available data values from all global countries. This ensures that data is comparable across indicators. Additionally, as a common and established methodology to identify outliers, the 1.5IQR rule[s46573] is used to determine the threshold for outliers that represent highest GBV risk. As a reminder, the lowest GBV risk of 0 nearly always represents a desired universal standard (e.g., 0% of women experience intimate partner violence). If outliers exist on the other end of the scale (representing high risk of GBV), these outliers receive a GBV indicator score of 100 for highest GBV risk, and all other values are scaled to that outlier threshold (1.5IQR from the quartile). If outliers don't exist, all values are scaled so that a GBV indicator score of 100 represents the worst value in the world. This methodology ensures that a single outlier that is far away from most other values does not skew the scaling of GBV scores for all other countries.
* <b>AGGREGATING INDICATOR VALUES TO CALCULATE SCORES</b><br>Lastly, arithmetic averages are used at two aggregation levels to calculate GBV risk scores for each country: 1- indicator values aggregated to calculate each sub-domain risk score and 2- Sub-domain risk scores aggregated to calculate the overall GBVR.<br/>
<ul>
<li style='list-style-type: circle;'>To compute each GBV Risk sub-domain score for a country, a weighted average is computed with all indicator values within each domain (arithmetic mean). Weighting allows for the inclusion of indicators that are less critical to GBV risk, but still important for a full picture. For example, the indicator that measures the extent of implementation of mental health services for victims of all types of violence has a lower weight because the measurement units are broad and open to quality interpretation.</li>
<li style='list-style-type: circle;'>To compute the overall GBVR for a country, all five sub-domain scores are then summed together and divided by the number of domains considered (in this case, five). This means that the relative weight of each sub-domain score is equal across the five sub-domains (e.g., no sub-domain is more important than the other in terms of measuring GBV risk. Each sub-domain is weighted evenly because there is no clear evidence quantifying the proportional impact of each domain on GBV risk; weighting is subjective.
</li>
"
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                
                <h1 class="text-2xl leading-6 font-medium mb-2 mt-12">Power and Confidence Scores</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                      text="Two scores are calculated that complement the GBV Risk scores at both the sub-domain and overall GBV Risk Score level for users to assess the strength of the scoring calculations:<br/>
* <b>POWER SCORE</b><br>The Power Score measures the robustness of each GBV Risk sub-domain score and the overall GBVR for each country on a scale of 0-1. A higher Power Score indicates greater data availability, including data from any year sourced from both internationally standardized and alternative sources. The Power Score is calculated by dividing the total number of possible indicators for a specific sub-domain by the total number of indicators with data available for a country.
* <b>CONFIDENCE SCORE</b><br>The Confidence Score measures the reliability of the GBV Risk Sub-Domain scores and GBVRs for each country on a scale of 0-1. A higher Confidence Score indicates greater data quality (e.g., data from internationally standardized sources, collected within the last 10 years) and comparability across countries. The confidence score is calculated by dividing the total number of available indicators for a country by the number of indicators with data available that are within the past 10 years and come from the standardized data source (rather than from a proxy source in the case of missing data)."
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                
                <h1 class="text-2xl leading-6 font-medium mb-2 mt-12">Handling Missing Data</h1>
                <div class="text-gray-600 w-full mt-3">
                  <item
                      text="Not all countries have data available from the identified standardized data source for a particular indicator. In this instance, proxy indicators may be used by replacing, for a specific country, a data point with an alternative data point from another source. The Equilo platform regularly updates its backend database with both standardized and proxy data, based on manual research, to ensure as robust a data set as possible to calculate scores. Standards that Equilo uses to for proxy data are, in order of priority, to:<br/><br/>
1. Utilize an alternative source, such as a country-level study or national statistics office data, to fill in data for the same indicator (referred to as an “alternative proxy”)<br/>
2. Utilize data from a similar indicator to be used as a proxy (also referred to as an “alternative proxy”)<br/>
3. Utilize a regional mean/median as a proxy (referred to as a “regional proxy”)<br/><br/>
If a sub-domain does not have any data available for a country (standard or proxy), it is not included in the overall GBVR calculation for that country. In this case, the GBVR is computed as the average of available sub-domains within that country.                      
                      "
                  :country="country"
                  class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
                
                <!-- <div class="flex flex-row mt-10">
                    <span>
                        <svg class="w-5 h-5 mx-1 text-blue-600 inline" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                    <path d="M19,12h-14"></path>
                    <path d="M14,17l5,-5"></path>
                    <path d="M14,7l5,5"></path>
                  </g>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
                        <router-link :to="'/reports/EQ/INFR_GENERL/gbv-risk/methodology'" style='color: #0077C8'>Demo of GBV Risk Score in Equiloland</router-link>
                    </span>
                </div> -->
                <div class="flex flex-row mt-10">
                    <span>
                        <svg class="w-5 h-5 mx-1 text-blue-600 inline" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                    <path d="M19,12h-14"></path>
                    <path d="M14,17l5,-5"></path>
                    <path d="M14,7l5,5"></path>
                  </g>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
                        <router-link :to="gesiIdUrl" style='color: #0077C8'>Upgrade to access the full country contextual GESI analysis for a country and sector</router-link>
                    </span>
                </div>
                <div class="flex flex-row" v-if="gbvpaid">
                    <span>
                        <svg class="w-5 h-5 mx-1 text-blue-600 inline" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                    <path d="M19,12h-14"></path>
                    <path d="M14,17l5,-5"></path>
                    <path d="M14,7l5,5"></path>
                  </g>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
                        <router-link to="/gbv-risk/choose-country/true" style='color: #0077C8'>Access your GBV Risk Score account</router-link>
                    </span>
                </div>

              </div>
            </section>
          </div>
        </transition>
        
        <!-- HEAT MAP -->
        <transition name="fadeIn">
          <div v-if="current_step == 2" class="flex flex-col items-center justify-center w-full">
            <section
              id="HeatMap"
              class="w-full container mx-auto px-4 lg:px-0"
              style="max-width: 960px"
            >
                <div class="flex flex-col">
                  <h1 class="text-2xl leading-6 font-medium">GBV Risk Score Heat Map</h1>
                    <div class="text-gray-600 w-full mt-3">
                    
                        <heatmap :startTheme="'GBV Risk Score'" :startDomain="'Overall GBV Risk Score'" :lockedTheme="'GBV Risk Score'" :gbvrisk="true" :sector="$route.params.sector"/>

                        <div class="flex flex-col mt-30 mx-auto" style="max-width: 960px">
                          
                          <div class="mt-10 text-gray-600 text-sm">
                            <div class="flex items-center justify-center mx-auto mb-1">
                              <img class="h-8 mr-2" src="../../assets/images/GBV-yellow.png" />
                              <span>Ubiquitous Risk (&#60;20)</span>
                              <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-light-orange.png" />
                              <span>Significant Risk (20-39)</span>
                              <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-orange.png" />
                              <span>Severe Risk (40-59)</span>
                              <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-red.png" />
                              <span>Extreme Risk (60+)</span>
                            </div>
                          </div>
                          <p class="leading-7 gap-10 text-justify leading-7 text-gray-600" style="font-size: 14px; line-height: 1.5;">
                              <br/><br/>
                              Use Equilo's GBV Risk Score heat map to explore GBV risk levels across all countries globally. This is a tool to help users to:
                              <ol class="ml-10">
                                  <li>1) Understand relative GBV risk across countries and regions</li>
                                  <li>2) Target and prioritize investment to mitigate GBV risk</li>
                                  <li>3) Benchmark countries with their peers for learning and advocacy purposes</li>
                              </ol>
                              <br/>
                              The map above displays the GBV risk level for each applicable domain selected. See the Methodology to read more about the following risk levels: Ubiquitous, Significant, Severe, and Extreme, where Ubiquitous is shaded in yellow, and Extreme in red.
                          </p>
                        </div>

                    </div>
                </div>
              </section>

          </div>
        </transition>
        <section
          class="w-full container mx-auto mb-32 pb-32 px-4 lg:px-0"
          style="max-width: 960px"
        >
          <div class="flex flex-col mt-10 justify-start">
            <p class="text-xs text-gray-500">
              Data Use and Citation: Equilo grants full permission to its users
              to copy and paste information that appears in the dashboard for
              their own internal use, directly citing the original data sources
              and citing Equilo for analysis and scoring. Please request
              permission from Equilo before utilizing our content for external
              publications.
          
            <br/><br/>
              Equilo uses the TAAP framework, which stands for Transforming
              Agency, Access, and Power. Comprised of six domains, the TAAP
              framework provides a structure to analyze and amplify the voices,
              opportunities, and dignity of all people, especially those from
              socially excluded groups. The GBV Risk Score utilities five of these domains as sub-indices.
              More information about this approach can
              be found at taapinclusion.org. 
            </p>
          </div>
        </section>
        
        
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env.js";
import item from "@/components/text.vue";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import VueAirtableGbv from '@/components/VueAirtableGbv'
import heatmap from '@/components/charts/heatmap.vue';
import auth from "@/helpers/auth";
import { useUserStore } from '@/stores/user';
import { canAccess } from '@/security/acl';
export default {
  name: "gbvRiskMateriality",
  data: function() {
    return {
      country: {},
      theme: {},
      gbvrisk: {},
      print: false,
      panel: false,
      openPanels: [],
      indicators: [],
      firstIndi: [],
      secondIndi: [],
      thrdIndi: [],
      fourthIndi: [],
      fifthIndi: [],
      ready: false,
      gesiIdUrl:'/pricing/'+env.productFamilyIds.gesi_aid,

     
      current_step: 0,
      

      firstIntro: "",
      secondIntro: "",
      thirdIntro: "",

      impact_levels: ["All Impact Levels", "Minimum Compliance", "Empowerment", "Transformation"],
      risk_categories: ["All Categories", "Political Risk", "Operational Risk", "Regulatory Risk", "Reputational Risk"],
      actors: ["All Actors", "Development", "Financial", "Private sector", "Humanitarian"],
      subactors: [],
      
      selectedImpact: "",
      selectedCategory: "",
      selectedActor: "",
      selectedSubActor: "",

      filteredTips: {},
      minComplianceTips: {},
      empowermentTips: {},
      transformationTips: {},

      projectTips: {},
      companyTips: {},
      systemTips: {},
      processTips: {},

      actorInfo: false,
      minComplianceInfo: false,
      empowermentInfo: false,
      transformationInfo: false,
      impactHoverOffsetY: '1000px',
      impactHoverOffsetX: '10px',
      
      env,
      taabColors: {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
      },
      appearItems: [],
      sector: { name: "" },
      endofcreated:false,
      step5:false,
      step6:false,
      themefunvar:false,
      token:"",
      gbvpaid:false,
      headName: '',
    };
  },
  components: {
    item,
    pageLoader,
    VueAirtableGbv,
    heatmap
  },
  methods: {
   // We go for an array so we can have multiple panels open at the same time (eg. download PDF)
    togglePanel: function(panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
   
    value: function(input) {
      return this.format("{{" + input + "}}");
    },
    opacity: function(hex) {
      if (!hex) {
        return "#black";
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    getCountry: function() {
      return new Promise(function(resolve) { 
        axios
          .get(
            env.apiUrl +
              "/countries/EQ/sectors/INFR_GENERL",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
     getSector: function() {
      return new Promise(function(resolve) {
        axios
          .get(env.apiUrl + "/sectors/INFR_GENERL", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
            params: { iso : 'EQ' }
          })
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
    getGBVRisk: function() {
      return new Promise(function(resolve) { 
        axios
          .get(
            env.apiUrl +
              "/reports/EQ/sectors/INFR_GENERL/gbvrisk",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
   
    toTitle(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  
    async progressClick(step) {
      this.current_step = step;
      if (this.current_step == 0) {
         window.history.pushState("Risk Score", "Risk Score", "/#/gbv/materiality"); 
         this.headName = 'Materiality';
      } else if (this.current_step == 1) {
        window.history.pushState("Risk Score", "Risk Score", "/#/gbv/risk-models");
        this.headName = 'Models';
      } else if (this.current_step == 2) {
        window.history.pushState("Risk Score", "Risk Score", "/#/gbv/heat-map");
        this.headName = 'Heat Map';
      } else if (this.current_step == 3) {
        window.history.pushState("Risk Score", "Risk Score", "/#/gbv/methodology");
        this.headName = 'Methodology';
      }
    },  
  },
  watch: {
    indicators: function(inds){
      this.firstIndi = inds.filter(function(ind){return ind.taab == "Human Dignity"})
      this.secondIndi = inds.filter(function(ind){ return ind.taab == "Law & Policy"})
      this.thrdIndi = inds.filter(function(ind){ return ind.taab == "Knowledge & Beliefs"})
      this.fourthIndi = inds.filter(function(ind){ return ind.taab== "Access to Resources"})
      this.fifthIndi = inds.filter(function(ind){ return ind.taab== "Power & Decision-Making"})
    }
  },
  computed: {
    modifiedIndex() {
      var mInde = []
      if (this.firstIndi.length > 0){
        mInde = [...mInde, ...this.firstIndi]
      }
      if (this.secondIndi.length > 0){
        mInde = [...mInde, ...this.secondIndi]
      }
      if (this.thrdIndi.length > 0){
        mInde = [...mInde, ...this.thrdIndi]
      }
      if (this.fourthIndi.length > 0){
        mInde = [...mInde, ...this.fourthIndi]
      }
      if (this.fifthIndi.length > 0){
        mInde = [...mInde, ...this.fifthIndi]
      }
      return mInde
    },
    citations() {
      const compare = (a, b) => {
        if (a.rank < b.rank) {
          return -1;
        }
        if (a.rank > b.rank) {
          return 1;
        }
        return 0;
      };
//      const removeDuplicates =(data, key) => {
//  
//            return [
//              ...new Map(data.map(item => [key(item), item])).values()
//            ]
//
//          };
      const x = this.$store.state.citations;
//      const removedDuplicates = removeDuplicates(x, item => item._id)
      return x.sort(compare);
    },
    myStyles() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      };
    },
    myStyles3Cases() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        marginBottom: "-100px",
        justifyContent: "center",
      };
    },
    myStylesLine() {
      return {
        height: "600px",
        position: "relative",
        width: "50%",
        display: "flex",
        justifyContent: "center",
      };
    },
    
  },
  async created() {
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("resetCitations");
    
    // SET PROGRESS BAR
    if (this.$route.path.includes('materiality')) {
      this.current_step = 0;
      this.headName = 'Materiality';
    } else if (this.$route.path.includes('risk-models')) {
      this.current_step = 1;
      this.headName = 'Models';
    }else if (this.$route.path.includes('heat-map')) {
      this.current_step = 2;
      this.headName = 'Heat Map';
    }else if (this.$route.path.includes('methodology')) {
      this.current_step = 3;
      this.headName = 'Methodology';
    }
    

    this.country = await this.getCountry(); // lets do an await before rendering other items.
    this.sector = await this.getSector(); // lets do an await before rendering other items.
    this.gbvrisk = await this.getGBVRisk(); // lets do an await before rendering other items.

    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if(env.gbvteams.includes(this.user.organization.name)){
        this.gbvpaid = true;
    }else{
        this.gbvpaid = false;
    }
   
    if (document.location.href.indexOf("print=true") > -1) {
      this.print = true;
    }

    this.$store.commit("downloadable", false);

    
    this.$store.commit("setBreadcrumbs", [
        {
          name: "GBV Risk Methodology",
          url:"/gbv/methodology",
        },
        
    ]);

    this.token = Cookies.get('session');
    this.endofcreated = true;

  },
  mounted() {
    this.ready = true;
    const userStore = useUserStore();
    if(!canAccess('/gbv/materiality', userStore.subscriptionType)) {
      this.$router.push({path: "/upgrade"})
    }
  },updated() {
    var breadCrumbName = '';
    var breadCrumbUrl = '';
      if(this.current_step == 0){
        breadCrumbName = "Materiality";
        breadCrumbUrl = "materiality";
      } else if(this.current_step == 1){
        breadCrumbName = "Risk Models";
         breadCrumbUrl = "risk-models";
      } else if(this.current_step == 3){
        breadCrumbName = "Methodology";
        breadCrumbUrl = "methodology";
      } else if(this.current_step == 2){
        breadCrumbName = "Heat Map";
        breadCrumbUrl = "heat-map";
      }
     
      this.$store.commit("setBreadcrumbs", [
         {
          name: breadCrumbName,
          url:
            "/gbv/" + breadCrumbUrl,
        },
    ]);
  }
};





</script>
<style>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
    .slide-fade-leave-to

        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateY(10px);
  opacity: 0;
}

@media print {
  .radarchart {
    max-height: 400px !important;
  }
}

div.indicator-chart > div > canvas#polar-chart {
  width: 400px !important;
  height: 400px !important;
}

div.indicator-chart > div > canvas#bar-chart {
  width: 400px !important;
  height: 400px !important;
}

div#global-heatmap > div > div > div > svg {
  height: 500 !important;
}

#buttonContainer {
  text-align: center;
}

#sideNav {
  position: fixed;
  margin-top: 300px;
  left: -65px;
  border-radius: 0 5px 5px 0;
}

#sideNav button {
  background-color: #201747;
  padding: 8px;
  width: 120px;
  height: 38px;
  font-size: 10px;
  color: #201747;
}

#sideNav button:hover {
  background-color: #e2e8f0;
  color: #201747;
}

.navigateButton {
  color: red;
  border: 2px solid red;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.hoverTrigger .hoverMethodology {
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 50;
  width: 400px;
}

.hoverTrigger {
  position: relative;
  display: inline-block;
}

.triggerElement {
  border-bottom: 1px dotted black;
}

.hoverTrigger:hover .hoverMethodology {
  visibility: visible;
  width: 550px;
}
.margintop1{
    margin-top: 1em;
}
.margintop2{
    margin-top: 2em;
}
.margintop3{
    margin-top: 3em;
}
.marginbottom3{
    margin-bottom: 3em;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 800px;
  background-color: #201747;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#gbv-impact-hover {
  position: absolute;
  width: 14%;
}

/* Make impact key sticky */
#gbv-impact-key {
  position: -webkit-sticky;
  position: sticky !important;
  top: 28em;
  width: 190px;
  float: right;
  margin-right: -215px;
  color: #201747;
}
</style>
