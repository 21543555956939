<template>
  <transition name="fadeIn" mode="out-in">
    <div class="static ecosystem-landing">
      <div id="notLoaded" v-if="isSurveyLoading" key="loading">
        <page-loader></page-loader>
      </div>
      <div class="container mx-auto mt-20 pt-10">
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyContentWrepper">
            <img
              src="@/assets/images/2X-Global_logo_transparent.svg"
              alt="background-image"
              style="height: 50px"
            />
          </div>
          <div style="display: flex; justify-content: flex-end" class="mb-5">
            <div class="Poweredby_logoImg mt-2">
              <div class="powered-text flex">
                <span>Powered by:</span>
              </div>
              <img src="@/assets/images/logo-navy.png" alt="background-image" />
            </div>
          </div>
        </div>
        <div class="container flex items-center border-b">
          <div class="flex">
            <h1
              class="text-6xl tracking-tighter font-medium print:text-7xl"
              style="color: #201747; max-width: 845px"
            >
              <span style="font-family: Work Sans"> ESG Survey</span>
            </h1>
          </div>
        </div>
        <div class="mx-auto" style="border: 1px solid rgb(204, 204, 204)">
          <Survey
            class="m-auto"
            :survey-data="surveyData"
            :survey-json="surveyJson"
            :user="user"
            :survey-id="surveyId"
            :answer-again="answerAgain"
            :id="userSurveyDataId"
            :isComplete="isComplete"
            :userType="entityChosen"
            :entityType="entityChosen"
            @surveyComplete="surveyComplete"
            @survyeEdited="surveyEdited"
          />
        </div>
        <div class="flex justify-end items-center mt-20">
          <button
            class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex items-center float-right mb-10 mr-8"
            @click="saveCurrentProgress"
            :disabled="isCurrentButtonActive < 1"
            :class="{ 'opacity-50 cursor-not-allowed': isCurrentButtonActive < 1,}"
          >
            Save current progress
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import Survey from "@/components/surveys/survey.vue";

export default {
  name: "IIMEsgSurvey",
  data() {
    return {
      surveyData: [],
      surveyJson: {},
      user: [],
      surveyId: "",
      answerAgain: false,
      userSurveyDataId: "",
      isComplete: false,
      entityChosen: "",
      isSurveyLoading: true,
      isCurrentButtonActive: "",
    };
  },
  components: {
    Survey,
    pageLoader,
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    // const surveyData = this.user.surveyData[0]
    const surveyData = "corporate";
    await this.fetchSurvey(surveyData);

    const footerWrapper = document.getElementById("SITE_FOOTER_WRAPPER");
    if (footerWrapper) {
      footerWrapper.style.position = "fixed";
      footerWrapper.style.bottom = "0";
      footerWrapper.style.left = "0";
      footerWrapper.style.right = "0";
    }
  },
  methods: {
    saveCurrentProgress() {
      this.$root.$emit("saveCurrentProgressFromParent");
    },
    surveyEdited(val) {
      this.isCurrentButtonActive = val;
    },
    async fetchSurvey(tag) {
      try {
        this.isSurveyLoading = true;
        const {
          data: { data },
          status,
        } = await axios.get(`${env.apiUrl}/surveys/esg?tag=${tag}`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        if (status == 200) {
          this.surveyJson = data.json;
          this.isSurveyLoading = false;
        }
      } catch (error) {
        console.log("Login error ", error);
      } finally {
        this.isSurveyLoading = false;
      }
    },
    surveyComplete() {
      this.isCurrentButtonActive = false;
      setTimeout(() => {
        this.$router.push({ path: "/imm/imm-landing-page/company" });
      }, "3000");
    },
  },
};
</script>
<style scoped lang="scss">
.ContentWrapper {
  margin-top: 10px;

  .SelectionBlock {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 40px 20px;
    max-width: 1000px;

    .column-wrapper {
      flex-direction: column;
    }
  }

  .standard-button {
    font-weight: bold;
    font-size: 10px;
    color: #191b45;
    border-style: solid;
    border-color: #191b45;
    border-width: 1px;
  }

  p {
    color: #717686;
  }
}

h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #fff;
  color: #21191d !important;
  font-family: Work Sans !important;

  .breadcrumbs-main-container {
    margin-top: 0;
    padding-top: 7em;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      width: 100%;
      display: flex;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
