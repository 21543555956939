<template>
  <div class="fullscreen-modal">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "indicator-modal",
};
</script>
