<template>
    <div class="static">
        <div>
            <div style="height:6rem; margin-top: 72px; float: right; margin-right: 240px;">
                <span style="color: black;margin-left: 10px;">Managed by:</span>
                <img src="@/assets/images/2X.png" alt="background-image" class="object-contain no-print"
                  style="height:6rem" />
            </div>
        </div>
        <div style="height:13rem;"></div>
        <transition name="fadeIn" mode="out-in">
            <div id="notLoaded" v-if="!endofcreated" key="loading">
                <page-loader></page-loader>
            </div>
            <div id="loaded" v-if="endofcreated" key="content">
                <div class="mb-5">
                    <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl mx-20">Climate Gender Equity Fund (CGEF)</h1>
                    <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl mx-20">Proposal Application</h1>
                </div>
                <main style="grid-template-columns:none !important">
                    <div style="width:100%; text-align: center;">
                        <section class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
                            style="max-width: 960px">
                            <p>
                                <iframe class="airtable-embed" :src="url" frameborder="0" onmousewheel="" width="100%"
                                    height="1000" style="background: transparent; border: 1px solid #ccc;"></iframe>
                            </p>
                        </section>
                    </div>
                </main>
            </div>
        </transition>
    </div>
</template>
<script>
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
import googleCode from "@/google_translate_languages_code.json";
import jotFormCode from "@/jotForm_languages_code.json";
export default {
    name: "newtwoxassesmentfunds",
    data: function () {
        return {
            user: [],
            endofcreated: false,
            languagePref: '&language=en'
        }
    },
    components: {
        pageLoader
    },
    async created() {
        this.user = await auth.me().catch(() => {
            console.log("Error login");
        });
        var googleCodeData = "";
        var jotFormCodeData = "";
        googleCode.code.forEach(data => {
            if (data[this.user.languagePreference]) {
                googleCodeData = data;
            }
        })
        jotFormCode.code.forEach(data => {
            if (googleCodeData && Object.entries(googleCodeData)[0][1] === Object.entries(data)[0][1]) {
                jotFormCodeData = data;
            }
        })
        if (jotFormCodeData) {
            this.languagePref = '&language=' + Object.entries(jotFormCodeData)[0][1]
        }
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [

            {
                name: "CGEF",
                url: "/CGEF/application",
            },
        ]);
        this.endofcreated = true;
    },
    computed: {
        url() {
            return 'https://www.jotform.com/232462060286452'
        }
    }
};
</script>