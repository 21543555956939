<template>
  <nav class="flex items-center text-sm text-gray-500 mt-4">
    <div v-for="(bread, ind) in breadcrumbs" :key="bread.name" class="flex items-center">
      <router-link :to="bread.url" class="hover:text-gray-700 transition-colors duration-200" :class="{ '!text-gray-600 !font-normal': ind === breadcrumbs.length - 1 }">
        {{ bread.name }}
      </router-link>
      <span v-if="ind < breadcrumbs.length - 1" class="mx-2">/</span>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'EquiloBreadcrums',
  computed: {
    breadcrumbs() {
      return this.$store.state.newBreadcrumbs;
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        this.updateBreadcrumbs(to);
      }
    }
  },
  methods: {
    updateBreadcrumbs(route) {
      const pathArray = route.path.split('/').filter(Boolean);
      const newBreadcrumbs = pathArray.map((path, index) => {
        return {
          name: this.formatBreadcrumbName(path),
          url: '/' + pathArray.slice(0, index + 1).join('/')
        };
      });
      this.$store.commit('setNewBreadcrumbs', newBreadcrumbs);
    },
    formatBreadcrumbName(path) {
      return path.charAt(0).toUpperCase() + path.slice(1);
    }
  }
}
</script>
<style scoped>
.router-link-exact-active {
    font-weight: 500 !important;
    color: #191b45 !important;

}
</style>
