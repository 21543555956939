<template>
  <div class="py-24 container mx-auto px-4 lg:px-0">
    <div class="PoweredbyTitle_logo">
          <div class="PoweredbyContentWrepper">
            <img src="@/assets/images/2X-Global_logo_transparent.svg" alt="background-image" style="height: 50px" />
          </div>
          <div style="display: flex; justify-content: flex-end">
            <div class="Poweredby_logoImg mt-2">
              <div class="powered-text flex">
                <span>Powered by:</span>
              </div>
              <img src="@/assets/images/logo-navy.png" alt="background-image" />
            </div>
          </div>
    </div>
    <EquiloBreadcrums></EquiloBreadcrums>
    <div class="container mx-auto">
          <steps-component-twox :currentStep="current_step" :userStatus="user.status2xCertification" />
    </div>
    <div class="flex justify-between items-center mb-4 mx-auto container max-w-7xl">
        <div></div>
        <div class="flex items-center gap-4">
          <button class="bg-[#19486A] font-bold text-white px-4 py-2 rounded-lg shadow mt-3" @click="toggleModal">
            Full Scorecard Results
          </button>
          <scorecard-modal :is-open="isModalOpen" @close="toggleModal" @option-selected="handleOptionSelected" />
          <div id="element-to-hide" data-html2canvas-ignore="true">
            <div>
              <div @click="exportPDF()" class="border-2 border-[#19486A] font-bold text-[#19486A] px-4 py-2 rounded-lg shadow mt-3 cursor-pointer">
                <p class="flex items-center">
                  <img src="../../assets/images/DownloadBtn.png" alt="Download" class="mr-2" />
                  <span class="font-work-sans font-medium" :key="downloadPDF">
                    {{ downloadPDF ? "Downloading..." : "Download PDF" }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
    </div>
  <div class="pdfcontainer">
    <div class="max-w-7xl mx-auto bg-white shadow-lg">
      <div class="p-4 sm:p-6 lg:p-8 lg:flex lg:items-center lg:justify-between items-center">
        <div  class="flex flex-col items-start">
          <div v-if="isValidBase64Image" class="w-auto object-cover rounded-lg mr-8 py-6">
            <img 
              v-if="base64Img[0].content"
              :src="base64Img[0].content" class="w-full max-w-[250px]"
               />
              <div v-else class="w-full max-w-[250px]" />
          </div>
            <div v-else class="w-full max-w-[250px]">
          </div>
          <div>
            <h2 class="text-5xl font-medium leading-normal text-gray-900 sm:text-6xl">
            <span class="block" :title="certificationScoreData?.team_id?.name">{{ certificationScoreData?.team_id?.name
              }}</span>
          </h2>
        <h3
          class="mt-2 text-xl font-medium text-gray-900 sm:text-2xl mb-1"
          >
          Entity Type : <span class="text-gray-800">{{ formattedEntity }}</span> 
        </h3>
          <a v-if="isWebsite"
            :href="isWebsite"
            target="_blank"
            rel="noopener noreferrer"
            class="mt-2 text-xl font-medium text-gray-900"
          >
           Website: <span class="text-gray-800">{{ isWebsite }}</span>
        </a>
          </div>
        </div>
        <div class="mt-8 lg:mt-0"> 
          <img v-if="certificationScoreData.level == 'Good'"
            src="@/assets/images/2x_certify/2x_certification_tier_good.png" class="w-full max-w-[250px]" />
          <img v-if="certificationScoreData.level == 'Advanced'"
            src="@/assets/images/2x_certify/2x_certification_tier_advanced.png" class="w-full max-w-[250px]"  />
          <img v-if="certificationScoreData.level == 'Best in Class'"
            src="@/assets/images/2x_certify/2x_certification_tier_bestinclass.png" class="w-full max-w-[250px]"  />
        </div>
      </div>
    </div>

    <div class="max-w-7xl mx-auto bg-[#F4F1ED] shadow-lg border-b">
      <div class="p-4 sm:p-6 lg:p-8">
        <p class="text-lg font-normal tracking-wide text-gray-900 mb-12">
          <span> {{ certificationScoreData["GEN_General_19"] }} </span>
        </p>
        <p class="text-lg font-normal tracking-wide text-gray-900 mb-12">
          {{ certificationScoreData?.team_id?.name }} has achieved <span
            class="border-b-2 inline-block border-gray-700">2X Certified
            {{ certificationScoreData.level }}</span> status demonstrating the breadth and depth of their gender-smart
          actions, and meeting
          the minimum requirements at this level for Basic 2X ESG and Governance & Accountability requirements.
        </p>

        <div class="grid md:grid-cols-2 gap-8">
          <CertificationCard title="DIMENSIONS" :items="twoxTabledata" :selectedOption="selectedOption"
            :dimensionsEligible="isAllSubDimensionsEligible" />
          <div v-if="eligibileThemes.length > 0">
              <CertificationCard  title="THEMES" :items="eligibileThemes"
              :selectedOption="selectedOption"
               />
          </div>
        </div>

        <div class="mt-12">
          <h3 class="text-2xl text-gray-900 font-medium mb-6">Additional commitments made to meet/maintain
            {{ certificationScoreData['level'] }}:</h3>
          <AdditionalCommitments :certificationScoreData="certificationScoreData" :entityChosen="entityChosen"
            :isEntOwnEligible="isEntOwnEligible" :isLeadershipEligible="isLeadershipEligible"
            :isEmploymentEligible="isEmploymentEligible" :isSupplyChainEligible="isSupplyChainEligible"
            :isProductsEligible="isProductsEligible" :isPortfolioEligible="isPortfolioEligible"
            :selectedOption="selectedOption" />
        </div>
        <!-- Footer -->
        <TowxScoreFooter />
      </div>
      <div class="bg-[#666461] text-white font-bold italic p-4">
        2X Certification Verified by: <span>{{ getStatus(certificationScoreData?.team_id) }}</span>. This is valid for two years from September 17, 2024. Learn more:
        <a
        href="https://2xcertification.org"
        target="_blank"
        class="underline"
        >
        www.2xcertification.org.
        </a>
      </div>
    </div>
  </div>  

    <div class="max-w-7xl mx-auto bg-[#F4F1ED] shadow-lg pt-12 mt-20 additional-element">
      <div class="p-4 sm:p-6 lg:p-8">
        <h2 class="text-2xl font-medium tracking-wide text-gray-900 mb-4">About 2X Certification</h2>
        <p class="text-lg font-normal tracking-wide text-gray-900 mb-12">
          2X Certification offers a mechanism to evaluate and independently verify the gender efforts of a company or
          fund (and in the future financial Institutions, bonds and loans) against the robust 2X Certification
          methodology. It recognises the quality and quantity of an entity's gender efforts through differentiated
          performance levels - Good, Advanced, and Best-in-Class - and encourages further progress accordingly.
          Importantly, it also includes independent, third-party verification of these efforts, seeking to drive greater
          transparency, accountability and credibility in the field of gender lens investing.
        </p>

        <h2 class="text-2xl font-medium tracking-wide text-gray-900 mb-4">Levels of 2X Certification</h2>
        <p class="text-lg font-normal tracking-wide text-gray-900 mb-4">
          2X Certification has identified 3 distinct levels of Certification: Good, Advanced and Best-in-Class (Table
          2). These levels differentiate among entities that demonstrate fundamental gender-focused efforts and those
          whose efforts and practices are more robust. They also invite approaches that vary in their breadth (“Broad”)
          and depth (“Targeted”) across and/or within different aspects of the value chain and core gender issues.
        </p>

        <p class="text-lg font-normal tracking-wide text-gray-900">
          2X Certification levels are designed to provide a clear, progressive framework for measuring an entity's
          performance in implementing gender efforts. Entities are encouraged to continuously improve their gender
          efforts over time, inviting opportunities to identify, adopt and implement further gender action. As 2X
          Certification becomes more mainstream and sufficient data is aggregated, it will offer entities the ability to
          track their progress over time as well as benchmark against other entities.
        </p>

        <h3 class="text-xl font-normal tracking-wide text-gray-900 mt-8">
          Learn more about 2X Certification, its underlying methodology, and how to get 2X Certified here:
          www.2xcertification.org
        </h3>

        <TowxScoreFooter class="mt-24" />
      </div>
      <div class="bg-[#666461] text-white font-bold italic p-4">
        2X Certification Verified by: <span>{{ getStatus(certificationScoreData?.team_id) }}</span>. This is valid for two years from September 17, 2024. Learn more:
        <a
        href="https://2xcertification.org"
        target="_blank"
        class="underline"
        >
        www.2xcertification.org.
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CertificationCard from '@/components/2x-scorcard/TowxCertificationCard.vue';
import apiService from "@/helpers/apiServices.js";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import AdditionalCommitments from '@/components/2x-scorcard/AdditionalCommitments.vue';
import TowxScoreFooter from '@/components/2x-scorcard/TowxScoreFooter.vue';
import ScorecardModal from '@/components/2x-scorcard/ScorecardModal.vue';
import StepsComponentTwox from '@/components/StepsComponentTwox.vue';
import html2pdf from "html2pdf.js";
import EquiloBreadcrums from '@/components/common/EquiloBreadcrums.vue';


export default {
  name: "My2xCertificationNewScoreCard",
  components: {
    CertificationCard,
    AdditionalCommitments,
    TowxScoreFooter,
    ScorecardModal,
    StepsComponentTwox,
    EquiloBreadcrums
  },
  data() {
    return {
      certificationScoreData: {},
      certificationSurveyData: [],
      certification_result: null,
      cert_categories: {
        Good: 'Good',
        BestInClass: 'Best in Class',
        Advanced: 'Advanced'
      },
      entrepreneurshipPercent: 0,
      leadershipPercent: 0,
      employmentPercent: 0,
      productServicePercent: 0,
      supplyChainPercent: 0,
      portfolioPercent: 0,
      govStrategicAction: 0,
      govManagementSystem: 0,
      govData: 0,
      twoxTabledata: [],
      twoxEsgdata: [],
      isEntOwnEligible: false,
      isLeadershipEligible: false,
      isEmploymentEligible: false,
      isSupplyChainEligible: false,
      isProductsEligible: false,
      isPortfolioEligible: false,
      endofcreated: false,
      dimensionData: [
        { label: "Entrepreneurship & Ownership", value: "100% share of women ownership", color: "#19486A" },
        { label: "Leadership", value: "> 40% women in senior management", color: "#3F7E44" },
        { label: "Products & Services", value: "Product offered enhances the wellbeing of women/girls and/or drive gender equality", color: "#A21942" },
        { label: "Portfolio", value: "50% of portfolio companies to meet the 2X Criteria upon exit.", color: "#C85206" }
      ],
      themes: [
        { id: '523', label: 'GBVH', value: 'GBVH Improves womens\' safety and implements measures to prevent and address incidents of gender-based violence and harassment (GBVH)' },
        { id: '520', label: 'JEDI', value: 'Emphasises the importance of fair, equitable, and inclusive organisations.' },
        { id: '519', label: 'Pay', value: 'Ensures equitable compensation for people of all genders.' },
        { id: '521', label: 'Health & well-being', value: 'Supports unique gender-specific health needs of employees and workers.' },
        { id: '522', label: 'Care', value: '' }
      ],
      additionalData: [
        { label: "Entrepreneurship & Ownership", color: "#19486A" },
        { label: "Leadership", color: "#3F7E44" },
      ],
      isAllSubDimensionsEligible: [],
      isModalOpen: false,
      selectedOption: "redacted",
      downloadPDF: false,
      user: [],
      current_step: 4,
      entityChosen: "",
      thresholds: [],
      workforceThreshold: null,
      srManagerThreshold: null,
      boardThreshold: null,
      industrySearchPlaceholder: '',
      statusMap: {
        '65fb027b2c7ef7d8c5df84cc': 'Sagana',
        '65fae2352c7ef7d8c5df20cb': 'PWC',
        '6609964014cdc08f264bbad9': 'Value for Women',
        '660ef11f209a0f0bbfdf09e8': 'Value for Women',
        '661d66ad9df47a870b28779e': 'Value for Women'
      }
    }
  },
  mounted() {
    this.$store.commit("setNewBreadcrumbs", [
      { name: "2X Certification", url: "/2X-Ecosystem/2X-Certification" },
      {
        name: "2X Global Certification",
        url: "/2X-Ecosystem/2X-Certification/GlobalCertification",
      },
      { name: "2X Score Card", url: "/2X-Ecosystem/2X-Certification/updated-score-card" },
    ]);
    this.initializeData();
  },
  computed: {
    themeData() {
    return this.themes.map(theme => ({
      ...theme,
      status: this.getThemeStatus(theme.id),
      color: this.getThemeColor(this.getThemeStatus(theme.id))
    }));
  },
  eligibileThemes() {
    return this.themeData.filter(item => item.status !== "Failed");
  },
  isAllThemeEligible() {
      return this.themes.every(theme => this.isThemeEligible(theme.id));
  },
  base64Img() {
    return this.certificationScoreData['GEN_2X_11'] ?? "";
  },
  isValidBase64Image() {
      if (!this.base64Img[0] || !this.base64Img[0].content) {
        return false;
      }
      const base64Regex = /^data:image\/(png|jpg|jpeg|gif);base64,/;
      if (!base64Regex.test(this.base64Img[0].content)) {
        return false;
      }
      const contentAfterPrefix = this.base64Img[0].content.split(',')[1];
      return contentAfterPrefix && contentAfterPrefix.trim().length > 0;
    },
  isWebsite() {
  let website = this.certificationScoreData['GEN_2X_6'];
  if (website && !website.startsWith('https://')) {
    website = 'https://' + website;
  }
  return website;
  },
  formattedEntity() {
      return this.entityChosen.replace(/(\w+)-(\w+)/g, (match, p1, p2) => {
        return p1.charAt(0).toUpperCase() + p1.slice(1) + ' ' + 
               p2.charAt(0).toUpperCase() + p2.slice(1);
      });
  }
},
  methods: {
    async initializeData() {
      try {
        await this.fetch2xUserSurvey();
        await this.fetchCertificationData();
        await this.calculateThreshold();
        await this.calculateCertLevel();
        this.setCertificationResult();
        this.setEntitySpecificData();
        this.setTwoxTableData();
        this.setTwoxEsgData();
        this.user = this.$store.state.user;
      } catch (error) {
        console.error("Error initializing data:", error);
      } finally {
        this.endofcreated = true;
      }
    },
    getStatus(teamId) {
      if(teamId){
        return this.statusMap[teamId._id] || 'No Verifier!';
      }
      
    },
    async calculateThreshold() {
      const thresholdsUrl = `${env.apiUrl}/thresholds2x/all`;
      const { data } = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: `Bearer ${Cookies.get('session')}`,
        },
      });
      
      this.thresholds = data.data.filter(item => item.threshold !== '');
      
      if (this.certificationScoreData?.ISO && this.certificationScoreData?.GEN_General_13) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData.GEN_General_13
        );
      }
      
      this.industrySearchPlaceholder = this.certificationScoreData?.GEN_General_13;
      
      const filterThresholds = (indicator) => {
        return this.thresholds.filter(item => 
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator === indicator &&
          (item.industry === this.certificationScoreData?.GEN_General_13 || item.industry === 'Overall')
        );
      };
      
      const setThreshold = (thresholds) => {
        if (thresholds.length === 1) return thresholds[0]?.threshold;
        return thresholds.find(item => item.industry === this.certificationScoreData?.GEN_General_13)?.threshold;
      };
      
      this.workforceThreshold = setThreshold(filterThresholds('Employees'));
      this.srManagerThreshold = setThreshold(filterThresholds('Senior Manager'));
      this.boardThreshold = setThreshold(filterThresholds('Board Member'));
      
      this.endofcreated = true;
    },
    calculateCertLevel() {
      if (this.entityChosen == "start-up") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "sme") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "corporate") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (
        this.entityChosen == "Fund Manager" ||
        this.entityChosen == "fund-manager"
      ) {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_403"];
      } else if (this.entityChosen == "financial-institution") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_404"];
      }
    },
    async getThresholdsData(recordId, sector) {
      await apiService.fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
      });
    },
    async exportPDF() {
      this.downloadPDF = true;
      try {
        const style = document.createElement('style');
        style.innerHTML = `
          .pagebreak { page-break-before: always; }
          @media print {
            .pagebreak { page-break-before: always; }
          }
        `;
        document.head.appendChild(style);

        const pdfContainer = document.querySelector('.pdfcontainer');
        const additionalElements = document.querySelectorAll('.additional-element');

        const container = document.createElement('div');

        if (pdfContainer) {
          container.appendChild(pdfContainer.cloneNode(true));
        }

        additionalElements.forEach((el) => {
          if (el) {
            const clonedElement = el.cloneNode(true);
            const pageBreakDiv = document.createElement('div');
            pageBreakDiv.className = 'pagebreak';
            container.appendChild(pageBreakDiv);
            container.appendChild(clonedElement);
          }
        });
        const opt = {
          margin: 0,
          filename: this.isFullDetailedScoreCard(),
          pagebreak: { before: '.pagebreak' },
          html2canvas: {
            logging: false,
            dpi: 300,
            letterRendering: true,
            useCORS: true,
            allowTaint: true,
            scale: 2, 
            removeContainer: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "cm",
            format: [60, 35],
            compressPDF: true,
          },
        };
        await html2pdf().set(opt).from(container).save();
        console.log('PDF exported successfully');
      } catch (error) {
        console.error('Error exporting PDF:', error);
      } finally {
        this.downloadPDF = false;
      }
},

     async fetch2xUserSurvey() {
      const response = await apiService.fetch2xUserSurvey('2x' , this.$route.query.teamId);
      this.certificationSurveyData = response.data.data;
      const intakeSurvey = this.certificationSurveyData.find(item => item.surveyId === "2x-intake");
      this.entityChosen = intakeSurvey?.tag || "";
    },
    async fetchCertificationData() {
      const res = await apiService.fetch2xCertificationData(
        this.entityChosen,
        this.$route?.query?.teamId,
        this.$store.state.certPageAccess.isFullSurveyFill || this.$route?.query?.teamId ? "Full" : "Min"
      );
      this.certificationScoreData = res.data.data;
    },
    setCertificationResult() {
      this.certification_result = this.cert_categories[this.certificationScoreData.level] || null;
    },
    setEntitySpecificData() {
      const isManager = this.entityChosen === "fund-manager";
      const suffix = isManager ? "_104" : "_101";
      const govSuffix = isManager ? "_103" : "_102";
      const eligibilitySuffix = isManager ? "_304" : "_300";

      this.entrepreneurshipPercent = this.certificationScoreData[`ENTOWN_2X${suffix}`];
      this.leadershipPercent = this.certificationScoreData[`LEAD_2X${suffix}`];
      this.employmentPercent = this.certificationScoreData[`EMPLOY_2X${suffix}`];
      this.productServicePercent = this.certificationScoreData[`PROD_2X${suffix}`];
      this.supplyChainPercent = this.certificationScoreData[`SUPCH_2X${suffix}`];
      this.portfolioPercent = this.certificationScoreData['PORT_Portfolio_1000'];

      this.govStrategicAction = this.certificationScoreData[`GOVACC_Strategic_action${govSuffix}`];
      this.govManagementSystem = this.certificationScoreData[`GOVACC_Management_systems_re_gender${govSuffix}`];
      this.govData = this.certificationScoreData[`GOVACC_Gender_disaggregated_data_11${isManager ? '4' : '3'}`];

      this.isSupplyChainEligible = this.certificationScoreData[`SUPCH_2X${eligibilitySuffix}`] && this.certificationScoreData[`SUPCH_2X${eligibilitySuffix}`] === 'Yes';
      this.isProductsEligible = this.certificationScoreData[`PROD_2X${eligibilitySuffix}`] && this.certificationScoreData[`PROD_2X${eligibilitySuffix}`] === 'Yes';
      this.isEmploymentEligible = this.certificationScoreData[`EMPLOY_2X${eligibilitySuffix}`] && this.certificationScoreData[`EMPLOY_2X${eligibilitySuffix}`] === 'Yes';
      this.isLeadershipEligible = this.certificationScoreData[`LEAD_2X${eligibilitySuffix}`] && this.certificationScoreData[`LEAD_2X${eligibilitySuffix}`] === 'Yes';
      this.isEntOwnEligible = this.certificationScoreData[`ENTOWN_2X${eligibilitySuffix}`] && this.certificationScoreData[`ENTOWN_2X${eligibilitySuffix}`] === 'Yes';
      this.isPortfolioEligible = this.certificationScoreData[`PORT_2X_1000`] && this.certificationScoreData[`PORT_2X_1000`] === 'Yes' && ['fund-manager'].includes(this.entityChosen);
    },
    setTwoxTableData() {
      const dimensionData = [
        {
          name: "Governance & Accountability",
          color: "#666461",
          isAllSubDimension: [this.govStrategicAction, this.govData, this.govManagementSystem].every(val => this.isAboveThreshold(val, 1)),
          twoxData: this.getGovAccData()
        },
        {
          name: "Entrepreneurship & Ownership",
          color: "#19486A",
          isAllSubDimension: this.isAboveThreshold(this.entrepreneurshipPercent, "50%"),
          twoxData: this.getEntOwnershipData()
        },
        {
          name: "Leadership",
          color: "#3F7E44",
          isAllSubDimension: this.isAboveThreshold(this.leadershipPercent, this.boardThreshold),
          twoxData: this.getLeadershipData()
        },
        {
          name: "Employment",
          color: "#DDA63A",
          isAllSubDimension: this.isAboveThreshold(this.employmentPercent, this.workforceThreshold),
          twoxData: this.getEmploymentData()
        },
        {
          name: "Supply Chain",
          color: "#381B5B",
          isAllSubDimension: this.isAboveThreshold(this.supplyChainPercent, this.workforceThreshold),
          twoxData: this.getSupplyChainData()
        },
        {
          name: "Products & Services",
          color: "#A21942",
          isAllSubDimension: this.isAboveThreshold(this.productServicePercent, "50%"),
          twoxData: this.getProductsServicesData()
        },
        {
          name: "Portfolio",
          color: "#8F210D",
          isAllSubDimension: this.isAboveThreshold(this.portfolioPercent, "30%"),
          twoxData: this.getPortfolioData()
        }
      ];
      if(this.entityChosen === 'fund-manager' || this.entityChosen === 'financial-institution'){
        this.twoxTabledata = dimensionData;
      } else {
        this.twoxTabledata = dimensionData.filter(item => item.name !== 'Portfolio')
      }
      this.isAllSubDimensionsEligible = this.twoxTabledata.filter(item => item.isAllSubDimension)
    },
    setTwoxEsgData() {
      this.twoxEsgdata = [{
        name: "Basic 2X ESG",
        twoxData: [
          {
            esgCriteria: "Exclusion criteria adherence",
            compliant: this.certificationScoreData["ESG_ESG_100"],
            esgBound: "N/A"
          },
          {
            esgCriteria: "Commitment respecting human rights",
            compliant: this.certificationScoreData["ESG_ESG_100"],
            esgBound: "N/A"
          },
          {
            esgCriteria: "GBVH safeguarding",
            compliant: this.certificationScoreData?.EMPLOY_Workplace_diversity_practices_3000 > 0,
            esgBound: "N/A"
          }
        ]
      }];
    },
    isThemeEligible(id) {
      const score = this.certificationScoreData[`2X_2X_Score_Overall_${id}`];
      return score && score !== 'Failed';
    },
    getThemeStatus(id) {
      const score = this.certificationScoreData[`2X_2X_Score_Overall_${id}`];
      return this.isThemeEligible(id) ? score : 'Failed';
    },
    getThemeColor(status) {
      const colorMap = {
        'Failed': '#999999',
        'Best in class': '#DDA63A',
        'Best in Class': '#DDA63A',
        'Advanced': '#395AFF',
        'Good': '#4C9F38'
      };
      return colorMap[status] || '#999999';
    },
    isAboveThreshold(scorePercent, scoreThreshold) {
      function cleanInput(value) {
        if (value === null || value === 'NA' || value === 'N/A' || value === undefined) {
          return 0;
        }
        return parseFloat(value) || 0;
      }
      const cleanedScorePercent = cleanInput(scorePercent);
      const cleanedScoreThreshold = cleanInput(scoreThreshold);
      return Math.round(cleanedScorePercent) >= cleanedScoreThreshold;
    },
    getGovAccData() {
      return [
        {
          subDimension: "Strategic action",
          result: "N/A",
          threshold: ["<b>Strategic Action</b> : >= <b>1</b> practice in place demonstrating intentional efforts to drive gender equality"],
          expectedthreshold: ["1"],
          dimensionthreshold: 1,
          minimum: this.govStrategicAction > 0 ? 'Yes' : 'No',
          isSubDimension: this.isAboveThreshold(this.govStrategicAction, 1)
        },
        {
          subDimension: "Data",
          result: "N/A",
          threshold: ["<b>Data</b> : >= <b>1</b>  practice in place demonstrating intentional efforts to drive gender equality"],
          expectedthreshold: ["1"],
          dimensionthreshold: 1,
          minimum: this.govData > 0 ? 'Yes' : 'No',
          isSubDimension: this.isAboveThreshold(this.govData, 1)
        },
        {
          subDimension: "Management Systems",
          result: "N/A",
          threshold: ["<b>Management Systems</b> : >= <b>1</b>  practice in place demonstrating intentional efforts to drive gender equality"],
          expectedthreshold: ["1"],
          dimensionthreshold: 1,
          minimum: this.govManagementSystem > 0 ? 'Yes' : 'No',
          isSubDimension: this.isAboveThreshold(this.govManagementSystem, 1)
        },
      ]
    },
    getEntOwnershipData() {
      const isFundManager = ["fund-manager"].includes(this.entityChosen);
      return [{
        subDimension: "Entrepreneurship & Ownership",
        result: "N/A",
        threshold: isFundManager
          ? ["<b> >=51% </b> carry allocated to women AND Founded by a woman (or group of women) that retain an active role", "<b> >=50% </b> of shares owned by women"]
          : ["Founded by a woman (or group of women) that retain an active role", "<b> >=50%</b> of shares owned by women"],
        expectedthreshold: isFundManager ? ["51%"] : ["50%"],
        dimensionthreshold: "50%",
        minimum: this.isEntOwnEligible,
        isSubDimension: this.isAboveThreshold(this.entrepreneurshipPercent, "50%")
      }];
    },

    getLeadershipData() {
      const isFundManager = ["fund-manager"].includes(this.entityChosen);
      return [{
        subDimension: "Investment committee diversity",
        result: "N/A",
        threshold: isFundManager
          ? [`<b> >=${this.srManagerThreshold}</b> of Managing Partners and Partners are women`, `<b> >=${this.boardThreshold} </b> of investment committee members are women`]
          : [`<b> >=${this.srManagerThreshold}</b> of senior managers are women`, `<b> >=${this.boardThreshold} </b> of board members are women`],
        expectedthreshold: ["30%", "30%"],
        dimensionthreshold: "30%",
        minimum: this.isLeadershipEligible,
        isSubDimension: this.isAboveThreshold(this.leadershipPercent, "30%")
      }];
    },

    getEmploymentData() {
      return [{
        subDimension: "Quality Employment indicator(s)",
        result: "N/A",
        threshold: [`<b> >=${this.workforceThreshold}</b> of employees/ workers are women`, "<b>AND >= 1</b> quality employment indicator in place beyond what is legally required"],
        expectedthreshold: ["50%", "1"],
        dimensionthreshold: "50%",
        minimum: this.isEmploymentEligible,
        isSubDimension: this.isAboveThreshold(this.employmentPercent, "50%")
      }];
    },
    getSupplyChainData() {
      return [
        {
          subDimension: "Commitment to women in supply chain",
          result: "N/A",
          threshold: ["Explicit commitment to women in supply chain is demonstrated"],
          expectedthreshold: ["50%", "1%"],
          dimensionthreshold: "50%",
          minimum: this.isSupplyChainEligible,
          isSubDimension: this.isAboveThreshold(this.certificationScoreData["SUPCH_Supply_chain_commitment_102"], 1)
        },
        {
          subDimension: "Quality Supply chain indicator(s)",
          result: "N/A",
          threshold: ["<b> AND >= 1 </b> quality supply chain indicator in place beyond what is legally required"],
          expectedthreshold: ["1"],
          dimensionthreshold: "1",
          minimum: this.isSupplyChainEligible,
          isSubDimension: this.isAboveThreshold(this.certificationScoreData["SUPCH_Supply_chain_quality_102"], 1)
        }
      ];
    },
    getProductsServicesData() {
      const isFundManager = ["fund-manager"].includes(this.entityChosen);
      return [{
        subDimension: "Product/ services",
        result: "N/A",
        threshold: isFundManager
          ? ["Investment thesis explicitly includes a gender lens"]
          : ["Products/ services are offered that enhance the well-being of women/ girls and/or drive gender equity"],
        expectedthreshold: ["50%", "1%"],
        dimensionthreshold: "50%",
        minimum: this.isProductsEligible,
        isSubDimension: this.isAboveThreshold(this.certificationScoreData?.PROD_2X_101, "50%")
      }];
    },
    getPortfolioData() {
      if (["fund-manager", "financial-institution"].includes(this.entityChosen)) {
        return [{
          subDimension: "Portfolio",
          result: "N/A",
          threshold: ["fund-manager"].includes(this.entityChosen) ? [`>= <b>30%</b> of portfolio companies meet the 2X Criteria, or there is a commitment in place`] : [">= <b>30%</b> of FI loan proceeds or <b>30%</b> of portfolio companies meet the 2X Criteria", "<b>OR</b> if customers are only individuals >= <b>50%</b> must be women"],
          expectedthreshold: ["50%"],
          dimensionthreshold: "50%",
          minimum: this.isProductsEligible,
          isSubDimension: this.isAboveThreshold(this.portfolioPercent, "50%")
      }];
      }
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen
    },
    handleOptionSelected(option) {
      this.selectedOption = option
    },
    isFullDetailedScoreCard(){
      if(this.selectedOption == 'detailed'){
        return `${this.certificationScoreData?.team_id?.name}_2X Scorecard_detailed`
      } else {
        return `${this.certificationScoreData?.team_id?.name}_2X Scorecard`
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  font-family: 'Work Sans';
}
.PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .bg-text-style {
      margin-left: 9rem;
      position: relative;
      top: 20rem;
      color: #f4f1ed;
      width: 570px;
    }

    .PoweredbyTitleWrepper {
      max-width: 847px;
      display: flex;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #19486a;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

</style>
