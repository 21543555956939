<template>
  <div class="static">
     <div style="display:flex; justify-content:space-between;">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-left: 285px;"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-right: 120px;"
      />
    </div>
    <div style="height:12rem;"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
          <main style="grid-template-columns:none !important">             
              <div style="width:100%; text-align: center;">                
                <section class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300" style="max-width: 960px">                  
                  <p>
                      <iframe class="airtable-embed" src="https://airtable.com/embed/shrr17P6ZGTVR4ndp?backgroundColor=greenLight" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
                  </p>
                </section>                
              </div>
          </main>
      </div>
    </transition>
  </div>
</template>
<script>
import pageLoader from "@/components/page-loader.vue";
export default {
    name: "SubmitTSP",
    data: function() {
     return {
      endofcreated: false
     }
    },
    components: {
      pageLoader
    },
    async created(){
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
      
        { name: "Technical Service Provider", url: "/2X-Ecosystem/technical-service-list" },
        { name: "Join our TA Service Directory", url: "/2X-Ecosystem/submitTSP" },
        
    ]);
        this.endofcreated=true;
    }
};
</script>
