<template>
  <div class="flex flex-row w-full bg-gray-100">


    <sidebar></sidebar>

    <!--TABLE-->
    <div class="flex flex-col w-full overflow-hidden" style="padding-top: 100px; padding-left: 30px; padding-bottom: 30px padding-right: 30px;">
      <div class="flex flex-col justify-start items-start overflow-x-scroll h-full">


      <div style="width: 100%">
        <router-link :to="'/settings/plans/'+_self.$route.params.planId" class="text-sm cursor-pointer">Go back</router-link>

        <p class="text-2xl">{{subscription.name}}</p>
        <p class="text-gray-900">{{subscription.desc}}</p>

        <div id="verifying" style="display: none;"
             class="bg-black bg-opacity-25 z-0 mb-4 rounded-lg flex flex-col items-start justify-start px-6 py-4 text-white blur">
             <h2 class="text-3xl font-semibold leading-none">Verifying your payment...</h2>
           </div>



        <div id="payment-details" class="mt-10 bg-white border border-gray-200 shadow-md z-10 rounded-lg" style="width: 40%;">
          <div class="w-full py-4 text-center text-xs uppercase text-gray-600 border-b border-indigo-100">
            <p class="bb">
              <span>Enter your card details</span>
            </p>
          </div>
          <div class="overflow-scroll px-4 py-4" style="height: 450px">
            <div>
              <div id="payment-form" class="flex flex-col items-start justify-start w-full mb-20">



                <div class="flex relative flex-row items-center w-full px-4 mt-5">
                  <label for="ccn" class="block text-sm font-medium leading-5 text-gray-700 w-full">
                    Name on card
                    <input id="name"
                      class="form-input mt-2 flex-1 block w-full rounded-none transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                      type="text" placeholder="Your name">
                  </label>
                </div>


                <div class="flex relative flex-row items-center w-full px-4 mt-10">
                  <label for="ccn" class="block text-sm font-medium leading-5 text-gray-700 w-full">
                    Card number
                    <div id="card-number"
                      class="form-input mt-2 flex-1 block w-full rounded-none transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                      type="text" placeholder="Your card number"></div>
                  </label>
                </div>

                <div class="flex relative flex-row items-center w-full px-4 mt-10">
                  <label for="ccn" class="block text-sm font-medium leading-5 text-gray-700 w-8/12 pr-2">
                    Card expiration date
                    <div id="card-expiry"
                      class="form-input mt-2 flex-1 block w-full rounded-none transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                      type="text" placeholder="Expiration date"></div>
                  </label>

                  <label for="ccn" class="block text-sm font-medium leading-5 text-gray-700 w-4/12">
                    CVC code
                    <div id="card-cvc"
                      class="form-input mt-2 flex-1 block w-full rounded-none transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                      type="number" min="0" max="999" placeholder="CVC code"></div>
                    
                  </label>
                </div>



              </div>

              <!-- Used to display form errors. -->
              <div id="card-errors" role="alert"></div>


              <div class="px-8 pt-1 border-t border-indigo-100 special-shadow">
                <button id="submit-button"
                  class="w-full flex flex-row items-center justify-center bg-indigo-500 text-sm mt-6 py-3 text-white font-medium rounded-md shadow-md focus:outline-none cc"

                  >
                  Pay
                  <svg class="text-white ml-2 w-5 h-5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                        d="M4.48,11.98h14.99"></path>
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                        d="M13.483,5.988l6.037,6.012l-6.037,6.012"></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>


</div>
      </div>



      </div>
    </div>
  </div>
</template>

<script>
  import sidebar from '@/components/sidebar.vue'
  import auth from '@/helpers/auth';
  const axios = require('axios');
  import Cookies from '@/helpers/cookies'
  import env from '@/../env.js';


  export default {
    name: 'Overview',
    data: function () {
      return {
        user: {},
        organization: {},
        subscription: {},
        payment: {}
      }
    },
    components: {
      sidebar,
    },
    methods: {
      open: function(href){
        var win = window.open(href, '_blank');
        win.focus();
      },
      changePlan: function (subscription) {
        if (confirm('Are you sure you want to change your plan? We will redirect you to our payment page.')) {
          const url = env.apiUrl + '/organizations/' + this.organization._id + '/subscriptions/' +subscription._id;
          document.location.href = url;
        }
      },
      getPayment: function () {
        const _self = this;
        return new Promise(function (resolve) {
          axios.get(env.apiUrl + '/payments/'+_self.$route.params.planId, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data);
          });
        })
      },
      validatePayment: function(result){
        return new Promise(function (resolve) {
          axios.get(env.apiUrl+'/payments?payment_intent=' + result.paymentIntent.id + '&payment_intent_client_secret=' + result.paymentIntent.client_secret, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data);
          });
        })
      },
      updatePlan: function(result){
        const _self = this;
        return new Promise(function () {
          axios.put(env.apiUrl+'/payments/'+_self.user.organization._id, {
            subscription: _self.$route.params.planId,
            currentUser: {"first_name":_self.user.firstname,"email":_self.user.email},
            paymentId: result.paymentIntent.id,
            customerId:_self.payment.customerId
          }, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function () {
            alert('Your plan is updated!');
            document.location = '/';
          });
        })
      },
      load: function () {
        const _self = this;
        return new Promise(function (resolve) {
          axios.get(env.apiUrl + '/subscriptions/'+_self.$route.params.planId, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data.data);
          });
        })
      }
    },
    async created() {

      const _self = this;

      this.user = await auth.me().catch(() => {});
      this.organization = this.user.organization;
      this.subscription = await this.load();
      this.$store.commit('downloadable', false);
      this.$store.commit('setBreadcrumbs', [{
          'name': 'Settings',
          'url': '/settings/account'
        },
        {
          'name': this.organization.name,
          'url': '/settings/team'
        },
        {
          'name': 'Subscriptions',
          'url': '/settings/plans'
        },
      ]);

      this.payment = await this.getPayment();

      // TODO: GET call to create a stripe intent
      // TODO: once paid, check the payment endpoint

      var stripe = window.Stripe(env.stripePublicKey,{ stripeAccount: "acct_1Hhby2C3Hz1HZJ6S" });
       // Set up Stripe.js and Elements to use in checkout form
       var elements = stripe.elements();
       var style = {
         base: {
           //color: '#32325D',
           //fontWeight: 500,
           //fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
           //fontSize: '16px',
           //fontSmoothing: 'antialiased',

           '::placeholder': {
             color: '#CFD7DF',
           },
           ':-webkit-autofill': {
             color: '#e39f48',
           },
         },
         invalid: {
           color: '#E25950',

           '::placeholder': {
             color: '#FFCCA5',
           },
         },
       };

       var cardNumber = elements.create("cardNumber", { style: style });
       cardNumber.mount("#card-number");

       var cardExpiry = elements.create("cardExpiry", { style: style });
       cardExpiry.mount("#card-expiry");

       var cardCvc = elements.create("cardCvc", { style: style });
       cardCvc.mount("#card-cvc");
       

       cardNumber.on('change', function (event) {
         var displayError = document.getElementById('card-errors');
         if (event.error) {
           displayError.textContent = event.error.message;
         } else {
           displayError.textContent = '';
         }
       });

       /*
       Note that stripe.confirmCardPayment may take several seconds to complete.
       During that time, you should disable your form from being resubmitted and show a waiting indicator like a spinner.
       If you receive an error result, you should be sure to show that error to the customer,
       re-enable the form, and hide the waiting indicator.
       */



       document.querySelector('#submit-button').addEventListener("click", function () {

         var name = document.querySelector('#name').value;
         if (!name) {
           alert('Make sure all fields are set!');
           return 'Missing data'
         }

         document.querySelector('#payment-details').style.display = 'none';
         document.querySelector('#verifying').style.display = 'block';

         stripe.confirmCardPayment(_self.payment.clientSecret, {
           payment_method: {
             card: cardNumber,
             billing_details: {
               name,
             },
             
           },
           receipt_email:_self.user.email
         }).then(async function (result) {
           if (result.paymentIntent.status === 'succeeded') {
             // Success will not land on this page.
            await _self.validatePayment(result);
            await _self.updatePlan(result);
            
            // TODO: udpate your subscription

           }

           if (result.error) {
             // Inform the customer that there was an error.
             alert('something unexpected happened. Please try again.')
           }

           if (result.error) {
             // Show error to your customer (e.g., insufficient funds)
             console.log("Error: ", result.error);
           } else {
             // The payment has been processed!
             if (result.paymentIntent.status === 'succeeded') {
               // Success will not land on this page.
             }
           }
         }).catch(function (e) {
           console.log("Error: ", e);
           // TODO: Should we push the backend to say that it's failed?
           alert(e)
         });
       });






    }
  }
</script>
