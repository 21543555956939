<template>
    <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div
        class="bg-white p-4 px-6 rounded-md shadow-lg w-[750px] max-h-[80vh] overflow-y-auto transition-all duration-300 ease-in-out">
        <div v-if="isLoading || showLoader"
          class="flex items-center justify-center min-h-[200px] transition-all duration-300 ease-in-out">
          <div class="flex space-x-2 animate-pulse">
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
          </div>
        </div>
        <div v-else>
          <h3 class="font-semibold text-lg pb-2">{{ question?.title }}</h3>
          <div v-if="question">
            <component :is="getComponentType(question.type)" :question="question" :value="formData"
              @change="changeFormData" />
          </div>
          <div class="flex justify-end gap-2 mt-4">
            <button @click.prevent="$emit('close')" class="px-4 py-2 bg-gray-300 rounded-sm">
              Cancel
            </button>
            <button type="submit" class="px-4 py-2 bg-[#0077C8] text-white rounded-sm" @click.prevent="submitEdit">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import apiService from "@/helpers/apiServices.js";
  import Text from "./editInputs/text.vue";
  import Checkbox from "./editInputs/checkbox.vue";
  import Dropdown from "./editInputs/dropdown.vue";
  import TagInput from "./editInputs/tagInput.vue";
  
  export default {
    props: {
      item: {
        type: Object,
        required: true,
      },
      entity: {
        type: String,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      COM_Commitment: {
        type: [Array],
        default: null
      },
      showLoader: {
        type: Boolean,
        required: true,
      }
    },
    components: {
      Text,
      Checkbox,
      Dropdown,
      TagInput,
    },
    data() {
      return {
        question: null,
        isLoading: false,
        formData: {},
      };
    },
    async mounted() {
      try {
        this.isLoading = true;
        const response = await apiService.fetchEditableQuestionAnswer(
          this.item?.surveyQType == 'Calculated' ? this.item.key : this.item.oldKey,
          this.entity
        );
        this.question = response.data.data;
        if (
          this.question?.choices?.some((element) =>
            element.text.includes("[Varies]")
          ) &&
          this.COM_Commitment
        ) {
          this.question.choices = this.question.choices.map(
            (choice) => {
              if (
                choice.value.includes("women in senior management") ||
                choice.value.includes(
                  "women partners and managing partners"
                )
              ) {
                choice.text = choice.text.replace(
                  "[Varies]",
                  this.COM_Commitment[1]
                );
                choice.value = choice.text.replace(
                  "[Varies]",
                  this.COM_Commitment[1]
                );
              }
              if (
                choice.value.includes("women on IC") ||
                choice.value.includes("women on Board")
              ) {
                choice.text = choice.text.replace(
                  "[Varies]",
                  this.COM_Commitment[2]
                );
                choice.value = choice.text.replace(
                  "[Varies]",
                  this.COM_Commitment[2]
                );
              }
              if (choice.value.includes("women in workforce")) {
                choice.text = choice.text.replace(
                  "[Varies]",
                  this.COM_Commitment[0]
                );
                choice.value = choice.text.replace(
                  "[Varies]",
                  this.COM_Commitment[0]
                );
              }
  
              return choice;
            }
          );
        }
        this.initializeFormData();
      } catch (error) {
        console.error("Error fetching question:", error);
      } finally {
        this.isLoading = false;
      }
    },
    methods: {
      getComponentType(type) {
        const componentMap = {
          text: Text,
          checkbox: Checkbox,
          dropdown: Dropdown,
          radiogroup: Dropdown,
          tagbox: TagInput,
        };
        return componentMap[type] || Text;
      },
      initializeFormData() {
        const defaultValues = {
          text: { answer: "" },
          checkbox: { selectedChoices: [] },
          dropdown: { selectedOption: "" },
          radiogroup: { selectedOption: "" },
          tagbox: { selectedTags: [] },
        };
  
        this.formData = defaultValues[this.question.type] || {};
        if (this.item.value) {
          const key = Object.keys(this.formData)[0];
          this.formData[key] = this.item.value;
        }
        if (
            this.formData?.selectedChoices?.some((element) =>
              element.includes("[Varies]")
            ) &&
            this.COM_Commitment
          ) {
            this.formData.selectedChoices = this.formData?.selectedChoices.map(
              (choice) => {
                if (
                  choice.includes("women in senior management") ||
                  choice.includes(
                    "women partners and managing partners"
                  )
                ) {
                  choice = choice.replace(
                    "[Varies]",
                    this.COM_Commitment[1]
                  );
                }
                if (
                  choice.includes("women on IC") ||
                  choice.includes("women on Board")
                ) {
                  choice = choice.replace(
                    "[Varies]",
                    this.COM_Commitment[2]
                  );
                }
                if (choice.includes("women in workforce")) {
                  choice = choice.replace(
                    "[Varies]",
                    this.COM_Commitment[0]
                  );
                }
  
                return choice;
              }
            );
          }
      },
      changeFormData(data) {
        this.formData = data;
      },
      async submitEdit() {
        try {
          await apiService.updateQuestionAnswer(
            this.$route.query.teamId,
            this.item?.surveyQType == 'Calculated' ? this.item.key : this.item.oldKey,
            this.formData[Object.keys(this.formData)[0]],
            this.uuid
          ).then(() => {
            this.$emit('closeAndUpdate');
          }).catch(() => {
            this.$emit("close");
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          });
        } catch (error) {
          console.error("Error updating question:", error);
        }
      },
    },
  };
  </script>
  