<template>
  <div>
    <select
      v-model="localValue.selectedOption"
      class="w-full p-2 border rounded"
    >
      <option v-for="choice in question.choices" :selected="choice.value === localValue.selectedOption" :key="choice.value" :value="choice.value">
        {{ choice.value }}
      </option>
    </select>
  </div>
</template>

<script>


export default {
  name: "Dropdown",
  props: ["question", "value"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", { selectedOption: newValue.selectedOption });
      },
    },
  },
};
</script>
