<template>
    <div class="static">
        <div style="display:flex; justify-content:space-between;">
            <img src="@/assets/images/2X.png" alt="background-image" class="object-contain no-print"
                style="height:6rem; margin-top: 72px; margin-left: 285px;" />
            <img src="@/assets/images/logo-navy.png" alt="background-image" class="object-contain no-print"
                style="height:6rem; margin-top: 72px; margin-right: 120px;" />
        </div>
        <div style="height:5rem;"></div>
        <transition name="fadeIn" mode="out-in">
            <div id="loaded" key="content">
                <main style="grid-template-columns:none !important">
                    <div style="width:100%; text-align: center;">
                        <section class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
                            style="max-width: 980px">
                            <p>
                                <iframe class="airtable-embed"
                                    src="https://airtable.com/embed/shrPHiikjM1eP75cm?backgroundColor=greenLight"
                                    frameborder="0" onmousewheel="" width="100%" height="1100"
                                    style="background: transparent; border: 1px solid #ccc;"></iframe>
                            </p>
                            <div class="bottom-section flex justify-between">
                                <button class="come-back" @click="$router.push({path: '/imm/imm-landing-page/investor'})">Come back later</button>
                                <button class="set-up" :class="{ 'not-active' : !isSetupPrefActive }">Set up preferences!</button>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    name: "CreateInvestorProfile",
    data: function () {
        return {
            isSetupPrefActive: false,
        }
    },
    mounted() {
        window.addEventListener('message', message => {
            if (message.data.action) {
                if (message.data.action.includes('submission') & message.data.action.includes('completed')) {
                    this.isSetupPrefActive = true;
                }
            }
        })
    }
};
</script>
<style lang="scss" scoped>
.bottom-section {
    margin-top: 28px;
    .come-back {
        width: 300px;
        height: 48px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        border: 1px solid #191B45;
        color: #191B45;

        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
    }
    .set-up {
        width: 300px;
        height: 48px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #191B45;
        color: #FFFFFF;

        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
    }
    .not-active {
        background: #E1E3E9;
        pointer-events: none;
    }
}
</style>