<template>
  <div class="static">
    <div style="height: 4rem"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  "
                >
                  <!-- <img
                  class="h-16 print:h-auto print:w-64"
                  src="@/assets/images/2X-assessment/new2X-assessment.png"
                  /> -->
                  <h1
                    class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    "
                  >
                    2X Global
                  </h1>
                </div>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  1. SCOPE and background
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  You have instructed us to provide a simple “no investment
                  advice” disclaimer for your website
                  <a
                    href="https://www.2xcollaborative.org/"
                    style="color: rgb(0, 119, 200)"
                    target="_blank"
                    >https://www.2xcollaborative.org/</a
                  >
                  and a brief summary of the scope of the UK financial
                  promotions regime, so you are aware as to what types of
                  material are likely to be infringing.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Your purpose is to promote gender lens investing to investors.
                  We understand that you are not and do not currently plan on
                  becoming authorised and regulated by the UK Financial Conduct
                  Authority (FCA) to give investment advice. You will use your
                  main website to promote your organisation and activities, but
                  not to promote specific investments.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  This advice focusses on your main website and not your
                  proposed match-making platform, on which we would advise
                  further separately.
                </p>
                <br />
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  2. Disclaimer
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  The disclaimer should be communicated clearly to visitors to
                  your website. We suggest you add the following wording to the
                  footer of your website:
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  <i
                    >“The content of this website is intended to be for general
                    information purposes only. Nothing on this website
                    constitutes or should be construed to be: (i) investment,
                    tax, legal or financial advice; or (ii) a recommendation to
                    buy, sell, or otherwise transact in any investment or a
                    solicitation to engage in any investment activity. We do not
                    provide investment, tax or financial advice, either
                    generally or within the meaning of The Financial Services
                    and Markets Act 2000, related or subordinate legislation. We
                    are not regulated by the Financial Conduct Authority or any
                    other financial services regulator. Where relevant, you must
                    rely on your own independent advice in respect of
                    investments, finance, law and tax.”</i
                  >
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Whilst a disclaimer is helpful, regulators would normally look
                  to the substance of the communications, so we set out below a
                  short note on the UK financial promotions regime to help guide
                  you as to the appropriate content for the website.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  3. Note on the UK financial promotions regime
                </h1>
                <br />
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                  style="list-style-type: none"
                >
                  <li class="heading-li">
                    3.1 Key concepts
                    <ul
                      class="
                        text-gray-600
                        print:text-4xl print:mt-10
                        font-medium
                      "
                    >
                      <li>
                        ◦ For the purposes of this note, the types of activities
                        that are regulated under the UK financial promotions
                        regime (“controlled activities” under the Financial
                        Services and Markets Act 2000 (Financial Promotion)
                        Order 2005 (FPO)) include advising on, dealing in,
                        arranging deals in, and managing, investments.
                      </li>
                      <li>
                        ◦ The types of investments falling under this regime
                        (“controlled investments” under the FPO) include
                        investment instruments, instruments creating or
                        acknowledging indebtedness, and shares or stock in
                        companies.
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li class="heading-li">
                    3.2 Prohibition
                    <ul
                      class="
                        text-gray-600
                        print:text-4xl print:mt-10
                        font-medium
                      "
                    >
                      <li>
                        There is a general prohibition (with exemptions) in
                        English law that a person must not make a financial
                        promotion <u>in the course of business</u> unless
                        authorised to do so (or the communication is approved by
                        an authorised person). Specifically, a person must not
                        “<u>communicate</u> an
                        <u>invitation or inducement</u> to
                        <u>engage in investment activity</u>” (s21(1) Financial
                        Services and Markets Act 2000).
                      </li>
                      <br />
                      <li>
                        The four key elements which need to be satisfied for the
                        above prohibition to apply are that:
                      </li>
                      <ul
                        class="
                          text-gray-600
                          print:text-4xl print:mt-10
                          font-medium
                        "
                        style="list-style-type: disc; margin-left: 25px"
                      >
                        <li>there is an invitation or inducement;</li>
                        <li>it has been communicated;</li>
                        <li>
                          it has been made during the course of business; and
                        </li>
                        <li>
                          the purpose or effect of the communication is that a
                          person will engage in investment activity.
                        </li>
                      </ul>
                      <br />
                      <li>
                        Breaking down the detail of the prohibition, the terms
                        “in the course of business”, and “communicate” have wide
                        scope and cover any relevant communications you will be
                        making or sharing on your website or Circle member
                        platform, or via any other means of communication
                        (newsletters, social media, etc.).
                      </li>
                      <br />
                      <li>
                        An “invitation or inducement” is a communication which
                        is promotional in nature, having the purpose or intent
                        of persuading or inciting a person to engage in
                        investment activity. If the communication does not have
                        any element of persuasion or incitement then it will not
                        be an “invitation or inducement”, but note that factual
                        statements can be interpreted as having a persuasive
                        effect.
                      </li>
                      <br />
                      <li>“Engaging in investment activity” means either:</li>
                      <ul
                        class="
                          text-gray-600
                          print:text-4xl print:mt-10
                          font-medium
                        "
                        style="list-style-type: disc; margin-left: 25px"
                      >
                        <li>
                          “entering or offering to enter into an agreement the
                          making or performance of which by either party
                          constitutes a controlled activity; or
                        </li>
                        <li>
                          exercising any rights conferred by a controlled
                          investment to acquire, dispose of, underwrite or
                          convert a controlled investment.”
                        </li>
                      </ul>
                      <br />
                      <li>
                        (s21(8) Financial Services and Markets Act 2000). Please
                        see the key concepts set out at 3.1 above for the
                        meanings of “controlled activity” and “controlled
                        investment”.
                      </li>
                      <br />
                      <li>
                        If all four of these elements are satisfied then the
                        prohibition will apply, and the communication can only
                        be made where it falls within an exemption (see below).
                        We advise that on your main website, Circle platform and
                        related communications, you should be careful to avoid
                        inviting or inducing the recipients to make investments.
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li class="heading-li">
                    3.3 Exemptions
                    <ul
                      class="
                        text-gray-600
                        print:text-4xl print:mt-10
                        font-medium
                      "
                    >
                      <li>
                        There are exemptions to the prohibition stated above,
                        which can be used by those who are not authorised to
                        give investment advice. These include where
                        communications are made only to investment
                        professionals, to certified or self-certified
                        sophisticated investors, or where the communications are
                        merely introductions to authorised advisors.
                      </li>
                      <li>
                        Of course, if you become authorised to make such
                        statements or you ensure that the communications are
                        approved by an authorised person, the prohibition will
                        also not apply.
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li class="heading-li">
                    3.4 Summary of Advice
                    <ul
                      class="
                        text-gray-600
                        print:text-4xl print:mt-10
                        font-medium
                      "
                    >
                      <li>
                        For your website and other communications, we advise
                        that you should avoid publishing information which can
                        be interpreted as persuading or inducing the recipients
                        into taking any steps to make investments. You should
                        avoid referencing specific investment opportunities,
                        including by sharing communications made by other
                        parties. Based on our discussions to date, we expect
                        this to be achievable for your main (not match-making)
                        activities.
                      </li>
                      <br />
                      <li>
                        Where you do intend to send or publish communications
                        which would be considered to fall within the
                        prohibition, you should ensure that one (or more) of the
                        exemptions apply. We would be happy to advise on this
                        further where the situation arises.
                      </li>
                    </ul>
                  </li>
                </ul>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "twoXCollabrativePrivacy",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      userEmail: null,
      isReadMore: false,
    };
  },
  components: {
    pageLoader,
  },
  methods: {},
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "2X Global Data Privacy",
        url: "/dataprivacy",
      },
    ]);
    this.endofcreated = true;
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
  },
};
</script>

<style scoped>
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}
</style>
<style scoped>
.heading-li {
  font-size: 20px;
}
li {
  font-size: 16px;
}
a {
  color: #40a9ff;
}
</style>
