<template>
    <div class="static">
      <transition name="fadeIn" mode="out-in">
        <div id="notLoaded" v-if="!endofcreated" key="loading">
            <page-loader></page-loader>
        </div>
        <div id="loaded" v-if="endofcreated" key="content">
          <div class="flex flex-col items-center justify-center w-full mt-20">
            <div class="container">
              <div class="flex flex-row justify-center items-center mt-3 ml-20">
                <div class="flex flex-col item-center justify-center">
                  <div class="flex flex-row items-center justify-center print:flex-col">
                    <img
                      class="h-16 print:h-auto print:w-64"
                      src="@/assets/images/SuperGIRL.png"
                    />
                    <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5">
                      Gender Smart Tools for Investments and Practices
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <main style="grid-template-columns:none !important">
                <div style="width:100%; text-align: center;">
                  <section class="w-full container mx-auto margintop3 px-4 lg:px-0 border-gray-300" style="max-width: 960px">
                    <p class="mt-10">
                        This is a living database with resources of gender smart tools for investments and practices that are constantly added. Complete the form below to submit a new tool for inclusion.
                    </p>
                    <br/>
                    <p>
                        <iframe class="airtable-embed" src="https://airtable.com/embed/shrOyZKlBELVP8KOG?backgroundColor=greenLight" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>
                    </p>
                  </section>
                </div>
            </main>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "SubmitGenderSmartTools",
  data: function () {
    return {
      active: false,
      endofcreated: false,
    };
  },
  components: {
    pageLoader,
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },
  },
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      { name: "Gender Smart Tools", url: "/GenderSmartTools" },
      { name: "Submit Gender Smart Tools", url: "/submitGenderSmartTools" },
    ]);
    this.endofcreated = true;
  },
  async mounted() {},
};
</script>
  <style>
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
          /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
  