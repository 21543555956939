<template>
  <div class="static">
    <div class="main-container pb-20 mx-20">
      <BreadCrumbs></BreadCrumbs>
      <div class="page-title-section flex justify-between mt-6">
        <div class="page-title">My IMM Dashboard</div>
        <div
          v-if="user?.investor && selectedPortfolio"
          class="page-figures cursor-pointer"
          @click="showPortfolioDetails = !showPortfolioDetails"
        >
          <img src="@/assets/images/IMM/setting-grey.svg" />
        </div>
      </div>
      <!--COMPANIES-->
      <div v-if="!user?.investor || investeeId">
        <!--Menu-->
        <div class="mt-8">
          <div class="time-filter-section flex justify-between">
            <div class="filter-options-section flex">
              <div class="filter-options flex">
                <div>Quarters</div>
                <div>Annual</div>
              </div>
              <div class="select-year">
                <select name="year" id="year" disabled>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                </select>
              </div>
            </div>
            <div class="download-button">
              <button class="flex opacity-50 cursor-not-allowed" disabled>
                Download
                <img src="@/assets/images/download-white.svg" />
              </button>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <local-loader v-if="loading"></local-loader>
          <div v-if="user && !loading" class="flex flex-col gap-6">
            <div v-if="error" class="text-red-500">
              {{ error.message }}
            </div>
            <!--From Prefs-->
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <component
                :is="getComponentName(module)"
                v-for="module in modulesFromPreferences"
                :key="module"
                :user="user"
                :investeeId="investeeId ? user._id : null"
              ></component>
              <component
                v-if="user.featureFlags.includes('sweef')"
                :is="getComponentName('Energy')"
                :user="user"
                :investeeId="user._id"
              ></component>
            </div>
            <!--Due Dilligence-->
            <div v-if="user.featureFlags.includes('sweef')">
              <span class="text-2xl font-bold">Due Dilligence:</span>
              <div
                class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-6 my-10"
              >
                <component
                  :is="getComponentName(module)"
                  v-for="module in ['Climate', 'E&S','EnvExclusions']"
                  :key="module"
                  :user="user"
                  :investeeId="investeeId ? user._id : null"
                ></component>
              </div>
            </div>
            <!-- Not prefs-->
            <div class="mt-20" v-if="extraModules.length > 0">
              <span class="text-2xl font-bold"
                >You may also be interested in:</span
              >
              <div
                class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-6 my-10"
              >
                <component
                  :is="getComponentName(module)"
                  v-for="module in extraModules"
                  :key="module"
                  :user="user"
                  :investeeId="investeeId ? user._id : null"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--INVESTOR-->
      <div v-else>
        <!--Menu-->

        <div class="mt-8">
          <div class="time-filter-section flex justify-between">
            <div class="filter-options-section flex">
              <div class="filter-options flex">
                <div>Quarters</div>
                <div class="active">Annual</div>
              </div>
              <div class="select-year">
                <select name="year" id="year">
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                </select>
              </div>
            </div>
            <div class="download-button">
              <button class="flex opacity-50 cursor-not-allowed" disabled>
                Download
                <img src="@/assets/images/download-white.svg" />
              </button>
            </div>
          </div>
        </div>

        <!--Portfolios Dropdown-->
        <div class="mt-10 w-1/4">
          <div class="flex flex-col space-y-4">
            <label for="portfolio" class="text-2xl font-semibold"
              >Select Portfolio</label
            >
            <select
              name="portfolio"
              id="portfolio"
              v-model="selectedPortfolio"
              class="p-2 border border-gray-300 rounded-md"
              @change="fetchByPortfolio"
            >
              <option value="" disabled selected>Select a portfolio</option>
              <option
                v-for="portfolio in investorPreferences"
                :key="portfolio._id"
                :value="portfolio"
              >
                {{ portfolio.portfolioName }}
              </option>
            </select>
          </div>
        </div>

        <!--Aggregation Results-->
        <div
          v-if="selectedPortfolio"
          class="mt-10 py-3 bg-yellow-100 rounded-xl px-5"
        >
          <h3 class="text-xl font-semibold mb-4">
            Aggregated Portfolio Performance
          </h3>
          <span>This module is under construction.</span>
        </div>

        <!--Investees Details-->
        <div
          v-if="portfolioDetails.length && selectedPortfolio"
          class="bg-gray-100 rounded-xl mt-4 px-5"
        >
          <!--Investees-->
          <div class="mt-10 py-3">
            <h3 class="text-xl font-semibold mb-4">
              Investees ({{ portfolioDetails.length }})
            </h3>
            <table class="min-w-full bg-white border rounded-lg shadow-md">
              <thead class="bg-gray-200">
                <tr>
                  <th class="py-3 px-5 border-b">Company Name</th>
                  <th class="py-3 px-5 border-b">Actions</th>
                  <th class="py-3 px-5 border-b">Email</th>
                  <th class="py-3 px-5 border-b">Portfolio Type</th>
                  <th class="py-3 px-5 border-b">Status</th>
                  <th class="py-3 px-5 border-b">User created?</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="investee in portfolioDetails"
                  :key="investee._id"
                  class="hover:bg-gray-100"
                >
                  <td class="py-3 px-5 border-b">
                    {{ investee.companyName }}
                  </td>
                  <td class="py-3 px-5 border-b">
                    <div class="grid grid-cols-2 gap-2">
                      <router-link
                        :to="`/imm/dashboard?id=${investee._id}`"
                        class="bg-[#2177c1] text-white p-2 rounded-lg mb-2 font-semibold"
                        target="_blank"
                        :disabled="!investee.userId"
                      >
                        Dashboard
                      </router-link>
                      <router-link
                        :to="`/imm/surveys/e&s-rating?id=${investee._id}&surveyId=e&s`"
                        class="bg-[#2177c1] text-white p-2 rounded-lg mb-2 font-semibold"
                        target="_blank"
                        :disabled="!investee.userId"
                      >
                        E&S
                      </router-link>
                      <router-link
                        :to="`/imm/surveys/climate-rating?id=${investee._id}&surveyId=climate-dd`"
                        class="bg-[#2177c1] text-white p-2 rounded-lg mb-2 font-semibold"
                        target="_blank"
                        :disabled="!investee.userId"
                      >
                        Climate
                      </router-link>
                    </div>
                  </td>
                  <td class="py-3 px-5 border-b">
                    {{ investee.email }}
                  </td>
                  <td class="py-3 px-5 border-b">
                    <select
                      v-model="investee.portfolioType"
                      class="p-2 border border-gray-300 rounded-md"
                      @change="saveCompanyChanges(investee)"
                    >
                      <option value="Portfolio">Portfolio</option>
                      <option value="Alumni">Alumni</option>
                      <option value="Pipeline">Pipeline</option>
                    </select>
                  </td>
                  <td class="py-3 px-5 border-b">
                    {{ investee.status }}
                  </td>
                  <td class="py-3 px-5 border-b">
                    {{ investee.userId ? "✅" : "❌" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!--Invite Company Button-->
            <div v-if="selectedPortfolio">
              <button
                class="bg-[#2177c1] text-white p-2 rounded-lg mt-5 font-semibold"
                @click="showAddInvesteesModal = true"
              >
                Invite Company
              </button>
            </div>
          </div>
        </div>

        <!--No Investee Details-->
        <div
          v-if="selectedPortfolio && !portfolioDetails.length"
          class="mt-10 flex flex-col w-1/4 py-3 bg-red-100 rounded-xl px-5"
        >
          There's no data available for this portfolio yet. Invite a company to
          your portfolio to get started.
          <button
            class="bg-[#191B45] text-white p-2 rounded-lg mt-5 font-semibold"
            @click="showAddInvesteesModal = true"
          >
            Invite Company
          </button>
        </div>

        <!--Add investees modal-->
        <div
          v-if="showAddInvesteesModal"
          class="fixed inset-0 flex items-center justify-center z-50"
        >
          <div class="bg-white p-6 rounded-xl shadow-lg w-1/3 z-50">
            <h2 class="text-xl font-semibold mb-4">Add New Investee</h2>
            <div class="flex flex-col gap-4">
              <div>
                <label for="company" class="my-2 block">Company Name</label>
                <input
                  v-model="newInvesteeCompany"
                  class="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label for="email" class="my-2 block">Email</label>
                <input
                  v-model="newInvesteeEmail"
                  class="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label for="portfolioType" class="my-2 block"
                  >Portfolio Type</label
                >
                <select
                  v-model="newInvesteePortfolioType"
                  class="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="" disabled selected>
                    Select a portfolio type
                  </option>
                  <option value="Portfolio">Portfolio</option>
                  <option value="Alumni">Alumni</option>
                  <option value="Pipeline">Pipeline</option>
                </select>
              </div>
            </div>
            <div class="flex justify-end mt-5">
              <button
                @click="showAddInvesteesModal = false"
                class="bg-gray-500 text-white px-3 py-2 rounded-md mr-2 font-semibold"
              >
                Cancel
              </button>
              <button
                @click="addInvestee"
                class="bg-blue-500 text-white px-3 py-2 rounded-md font-semibold"
              >
                Send invite
              </button>
            </div>
          </div>
          <div class="fixed inset-0 bg-black opacity-50 z-40"></div>
        </div>

        <!--Portfolio Details-->
        <div
          v-if="showPortfolioDetails && selectedPortfolio"
          class="fixed inset-0 flex items-center justify-center z-50"
        >
          <div class="bg-white p-6 rounded-xl shadow-lg w-3/4 z-50 relative">
            <div
              class="absolute right-10 cursor-pointer"
              @click="showPortfolioDetails = false"
            >
              <img
                src="@/assets/images/close-icon.svg"
                alt="Close"
                width="20px"
                height="20px"
              />
            </div>
            <div class="bg-gray-100 rounded-xl mt-4 px-5">
              <div class="mt-10 py-5">
                <div class="flex gap-20">
                  <h3 class="text-xl font-semibold mb-4">Portfolio Details</h3>
                </div>
                <!--Portfolio Name and Level-->
                <div
                  class="flex flex-col justify-center gap-4 bg-gray-200 rounded-lg px-3 w-2/6 h-44 mb-4 shadow-md"
                >
                  <div
                    v-if="!editPorftolioDetails"
                    class="flex flex-col justify-center gap-4"
                  >
                    <h2 class="text-lg font-semibold">
                      Name:
                      <span class="font-normal">{{
                        selectedPortfolio.portfolioName
                      }}</span>
                    </h2>
                    <h2 class="text-lg font-semibold">
                      Level:
                      <span class="font-normal">{{
                        selectedPortfolio.level
                      }}</span>
                    </h2>
                  </div>
                  <div v-else class="flex flex-col justify-center gap-2">
                    <input
                      v-model="selectedPortfolio.portfolioName"
                      class="p-2 border border-gray-300 rounded-md"
                      placeholder="Portfolio Name"
                    />
                    <select
                      v-model="selectedPortfolio.level"
                      class="p-2 border border-gray-300 rounded-md"
                    >
                      <option value="Compliance">Compliance</option>
                      <option value="Risk">Risk</option>
                      <option value="Performance">Performance</option>
                      <option value="Impact">Impact</option>
                    </select>
                  </div>
                  <!--Edit Button-->
                  <div class="flex gap-4">
                    <button
                      v-if="!editPorftolioDetails"
                      @click="editPorftolioDetails = true"
                      class="bg-[#2177c1] text-white p-2 rounded-lg mb-2 font-semibold"
                    >
                      Edit details
                    </button>
                    <button
                      v-else
                      @click="updatePortfolio"
                      class="bg-green-500 text-white p-2 rounded-lg mb-2 font-semibold"
                    >
                      Save details
                    </button>
                    <button
                      v-if="editPorftolioDetails"
                      @click="editPorftolioDetails = false"
                      class="bg-red-500 text-white p-2 rounded-lg mb-2 font-semibold"
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <table class="min-w-full bg-white border rounded-xl shadow-lg">
                  <thead class="bg-gray-200">
                    <tr>
                      <th class="py-3 px-5 border-b">Module</th>
                      <th class="py-3 px-5 border-b">Frequency</th>
                      <!--  <th class="py-3 px-5 border-b">Delete?</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="portfolio in selectedPortfolio.preferences"
                      :key="portfolio._id"
                      class="hover:bg-gray-100"
                    >
                      <td class="py-3 px-5 border-b">{{ portfolio.title }}</td>
                      <td class="py-3 px-5 border-b">
                        {{ portfolio.frequency }}
                      </td>
                      <!--  <td class="py-3 px-5 border-b">
                    <button
                      class="bg-red-500 text-white py-2 px-4 rounded-lg font-semibold"
                    >
                      Delete
                    </button>
                  </td> -->
                    </tr>
                  </tbody>
                </table>
                <button
                  v-if="computeVariable().length"
                  @click="showAddModulesModal = true"
                  class="mt-4 bg-[#2177c1] text-white p-2 rounded-lg font-semibold"
                >
                  Add new module
                </button>
                <!--Modules Modal-->
                <div
                  v-if="showAddModulesModal"
                  class="fixed inset-0 flex items-center justify-center z-50"
                >
                  <div class="bg-white p-6 rounded-xl shadow-lg w-1/3 z-50">
                    <h2 class="text-xl font-semibold mb-4">Add New Module</h2>
                    <div class="mb-4">
                      <label class="block text-gray-700">Module Name</label>
                      <select
                        v-model="newModuleName"
                        class="w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="" disabled selected>
                          Select a module
                        </option>
                        <option
                          v-for="variable in computeVariable()"
                          :key="variable"
                          :value="variable"
                        >
                          {{ variable }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-4">
                      <label class="block text-gray-700">Frequency</label>
                      <select
                        v-model="newModuleFrequency"
                        class="w-full p-2 border border-gray-300 rounded-md"
                      >
                        <option value="" disabled selected>
                          Select a frequency
                        </option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Annually">Annually</option>
                      </select>
                    </div>
                    <div class="flex justify-end">
                      <button
                        @click="showAddModulesModal = false"
                        class="bg-gray-500 text-white p-2 rounded-md mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        @click="addModule"
                        class="bg-blue-500 text-white p-2 rounded-md"
                      >
                        Add Module
                      </button>
                    </div>
                  </div>
                  <div class="fixed inset-0 bg-black opacity-50 z-40"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="fixed inset-0 bg-black opacity-50 z-40"
            @click="showPortfolioDetails = false"
          ></div>
        </div>

        <!--TOAST-->
        <div
          v-if="showToast"
          class="fixed top-20 right-10 bg-green-500 text-white p-4 rounded-md shadow-md transition ease-in-out"
        >
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import axios from "axios";
import env from "@/../env";
import auth from "@/helpers/auth";
import Cookies from "@/helpers/cookies";
import twoXCard from "@/components/imm-components/dashboard/2XCard.vue";
import genderROICard from "@/components/imm-components/dashboard/GenderROICard.vue";
import eAndSCard from "@/components/imm-components/dashboard/E&SCard.vue";
import financeCard from "@/components/imm-components/dashboard/FinancialCard.vue";
import climateCard from "@/components/imm-components/dashboard/ClimateCard.vue";
import impactCard from "@/components/imm-components/dashboard/ImpactCard.vue";
import localLoader from "@/components/local-loader.vue";
import energyCard from "@/components/imm-components/dashboard/EnergyCard.vue";
import EnvExclusions from "@/components/imm-components/dashboard/EnvExclusions.vue";

export default {
  name: "IMMDashboardPage",
  components: {
    BreadCrumbs,
    twoXCard,
    genderROICard,
    eAndSCard,
    financeCard,
    climateCard,
    impactCard,
    localLoader,
    energyCard,
    EnvExclusions
  },
  data() {
    return {
      user: {},
      entityType: "",
      immInvestorPreferences: [],
      allowedLevels: [],
      modulesFromPreferences: [],
      extraModules: [],
      loading: true,
      totalModules: [
        "E&S",
        "ESG",
        "Finance",
        "Climate",
        "Impact",
        "2X Certification",
        "Gender ROI™",
      ],
      selectedPortfolio: "",
      investorPreferences: [],
      portfolioDetails: [],
      showToast: false,
      toastMessage: "",
      showAddModulesModal: false,
      newModuleName: "",
      newModuleFrequency: "",
      editPorftolioDetails: false,
      showAddInvesteesModal: false,
      newInvesteeEmail: "",
      newInvesteePortfolioType: "",
      newInvesteeCompany: "",
      showPortfolioDetails: false,
      investeeId: null,
      error: null,
    };
  },

  async mounted() {
    const investeeId = this.$route.query.id;
    if (investeeId) {
      this.investeeId = investeeId;
      this.user = await this.fetchInvesteeUser();
    } else {
      this.user = await auth.me();
    }

    // PREFERENCES
    if (!this.user?.investor || this.investeeId) {
      await this.fetchCompanyPreferences();
    } else {
      await this.fetchInvestorPreferences();
    }

    this.$store.commit("setNewBreadcrumbs", [
      { name: "Equilo IMM", url: "/imm/imm-landing-page" },
      { name: "Dashboard", url: "/imm/dashboard" },
    ]);
  },
  methods: {
    async fetchCompanyPreferences() {
      try {
        const url = this.investeeId
          ? `${env.apiUrl}/imm-preferences/investee-preferences?email=${this.user.email}`
          : `${env.apiUrl}/imm-preferences/investee-preferences`;

        const {
          data: {
            data: { immInvestorPreferences, allowedLevels },
          },
        } = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });

        const climateFrequency = immInvestorPreferences[0]?.preferences?.find(
          (pref) => pref.title === "Climate"
        )?.frequency;

        this.totalModules = !this.user.featureFlags?.includes("sweef")
          ? this.totalModules.filter((module) => module !== "Energy")
          : climateFrequency === "Quarterly"
          ? this.totalModules.filter(
              (module) =>
                module !== "Climate" && module !== "E&S" && module !== "ESG"
            )
          : this.totalModules.filter(
              (module) =>
                module !== "Climate" &&
                module !== "E&S" &&
                module !== "ESG" &&
                module !== "Energy"
            );

        this.immInvestorPreferences =
          immInvestorPreferences[0].preferences || [];

        this.modulesFromPreferences = this.totalModules.filter((module) =>
          this.immInvestorPreferences.map((pref) => pref.title).includes(module)
        );
        this.allowedLevels = allowedLevels;
        this.extraModules = this.totalModules.filter(
          (module) => !this.modulesFromPreferences.includes(module)
        );
        this.loading = false;
      } catch (error) {
        console.error("Error fetching company preferences:", error);
        this.error = {
          message: "We couldn't find your preferences. Please contact support.",
          error: true,
        };
        this.modulesFromPreferences = this.totalModules;
        this.loading = false;
      }
    },
    getComponentName(module) {
      const componentMap = {
        "2X Certification": "twoXCard",
        "Gender ROI™": "genderROICard",
        Finance: "financeCard",
        Climate: "climateCard",
        Impact: "impactCard",
        Energy: "energyCard",
        "E&S": "eAndSCard",
        EnvExclusions : "EnvExclusions"
      };
      return componentMap[module] || null;
    },
    async fetchInvestorPreferences() {
      try {
        const {
          data: { data },
        } = await axios.get(`${env.apiUrl}/imm-preferences/`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        this.investorPreferences = data;
      } catch (error) {
        console.error("Error fetching investor preferences:", error);
        this.error = {
          message:
            "We couldn't fetch investor preferences. Please try again later.",
          error: true,
        };
      }
    },
    async fetchByPortfolio() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/portfolio/${this.selectedPortfolio.portfolioName}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.portfolioDetails = data;
      } catch (error) {
        console.error("Error fetching portfolio preferences:", error);
        this.error = {
          message:
            "We couldn't fetch portfolio preferences. Please try again later.",
          error: true,
        };
      }
    },
    async saveCompanyChanges(investee) {
      try {
        await axios.put(
          `${env.apiUrl}/imm-preferences/investee-details/${investee._id}`,
          {
            portfolioType: investee.portfolioType,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.showToast = true;
        this.toastMessage = "Changes saved successfully";
        setTimeout(() => {
          this.showToast = false;
        }, 2000);
      } catch (error) {
        console.error("Error saving investee changes:", error);
        this.error = {
          message: "We couldn't save your changes. Please try again later.",
          error: true,
        };
      }
    },
    computeVariable() {
      // define the list of variables
      const variables = [
        "2X Certification",
        "Gender ROI™",
        "Impact",
        "ESG",
        "Climate",
        "Finance",
        "E&S",
        "EnvExclusions"
      ];
      // filter out the variables present in selectedPortfolio preferences
      const filteredVariables = variables.filter(
        (variable) =>
          !this.selectedPortfolio.preferences.some(
            (pref) => pref.title === variable
          )
      );
      // return the new array with the filtered values
      return filteredVariables;
    },
    async addModule() {
      try {
        const {
          data: { data },
        } = await axios.put(
          `${env.apiUrl}/imm-preferences/${this.selectedPortfolio._id}`,
          {
            portfolioName: this.selectedPortfolio.portfolioName,
            preferences: [
              {
                title: this.newModuleName,
                frequency: this.newModuleFrequency,
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );

        this.selectedPortfolio = data;
        this.showAddModulesModal = false;
      } catch (error) {
        console.error("Error adding module:", error);
      }
    },
    async updatePortfolio() {
      try {
        const {
          data: { data },
        } = await axios.put(
          `${env.apiUrl}/imm-preferences/${this.selectedPortfolio._id}`,
          {
            portfolioName: this.selectedPortfolio.portfolioName,
            level: this.selectedPortfolio.level,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.selectedPortfolio = data;
        await this.fetchInvestorPreferences();
        this.editPorftolioDetails = false;
      } catch (error) {
        console.error("Error updating portfolio:", error);
      }
    },
    async addInvestee() {
      const payload = {
        emails: [this.newInvesteeEmail],
        companyName: this.newInvesteeCompany,
        portfolioName: this.selectedPortfolio.portfolioName,
      };
      if (this.newInvesteePortfolioType) {
        payload.portfolioType = this.newInvesteePortfolioType;
      }
      await axios.post(
        `${env.apiUrl}/imm-preferences/investee-invites`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );

      await this.fetchByPortfolio();
      this.showAddInvesteesModal = false;
    },
    async fetchInvesteeUser() {
      try {
        const {
          data: { user },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/user/${this.investeeId}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        return user;
      } catch (error) {
        console.error("Error fetching investee user:", error);
        this.error = {
          message:
            "We couldn't fetch the investee user. Please contact support.",
          error: true,
        };
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  color: #2c2e35;

  .page-title-section {
    height: 45px;

    .page-title {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      color: #2c2e35;
    }

    .page-figures {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  .time-filter-section {
    .filter-options-section {
      gap: 32px;

      .filter-options {
        height: 32px;
        background: #f4f6f9;
        border-radius: 8px;
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea4ba;

        div {
          padding: 6px 12px 6px 12px;
        }

        .active {
          background: #2177c1;
          border-radius: 8px;
          color: #ffffff;
        }
      }

      .select-year {
        select {
          height: 32px;
          padding: 0 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 8px;
          color: #2c2e35;
        }
      }
    }

    .download-button {
      button {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        background-color: #2177c1;
        gap: 8px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
