<template>
  <div class="container mx-auto p-4">
    <h2 class="text-2xl font-semibold mb-4">{{ title }}</h2>
    <div v-if="title == '2X Investor Profile Disclosures'"
      class="overflow-x-auto rounded-lg border border-gray-200 relative">
      <table class="min-w-full bg-white" v-if="data.length > 0">
        <thead>
          <tr>
            <th v-for="(header) in disclosureHeaders" :key="header" :class="['px-6 py-4 bg-gradient-to-b from-gray-100 to-gray-200 text-left text-xs font-bold text-gray-700  tracking-wider cursor-pointer hover:bg-gradient-to-b hover:from-gray-200 hover:to-gray-300 transition duration-150 ease-in-out border-b border-gray-300 whitespace-nowrap',
              { 'sticky right-0 z-10': header === '2X Global Approved Investor Disclosures' }]">{{ header === '2X Global Approved Investor Disclosures' ? '2X APPROVAL' : header }}</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, index) in tableDataset" :key="index">
            <td v-for="(header) in disclosureHeaders" :key="header" :class="['px-6 py-4 text-sm text-gray-900 whitespace-nowrap cell-content',
              { 'sticky right-0 z-10 bg-blue-50 p-2': header === '2X Global Approved Investor Disclosures' }]"
              :title="Array.isArray(row[header]) ? row[header].join(', ') : row[header]">
              <template v-if="header == '2X Global Approved Investor Disclosures'">
                <select :value="row[header]" @change="updateApprovalStatus(index, header, $event)"
                  class="form-select block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 !w-48">
                  <option value="Approved">Approved</option>
                  <option value="Denied">Denied</option>
                </select>
              </template>
              <template v-else-if="isValidUrl(row[header])">
                <a :href="row[header]" target="_blank" rel="noopener noreferrer">{{ row[header] }}</a>
              </template>
              <template v-else>
                {{ Array.isArray(row[header]) ? row[header].join(', ') : row[header] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>No data available.</p>
      </div>
    </div>
    <div v-if="title == '2X Deal Submissions'" class="overflow-x-auto rounded-lg border border-gray-200 relative">
      <table class="min-w-full bg-white"  v-if="data.length > 0">
        <thead>
          <tr>
            <th v-for="(header) in dealsHeaders" :key="header" :class="['px-6 py-4 bg-gradient-to-b from-gray-100 to-gray-200 text-left text-xs font-bold text-gray-700  tracking-wider cursor-pointer hover:bg-gradient-to-b hover:from-gray-200 hover:to-gray-300 transition duration-150 ease-in-out border-b border-gray-300 whitespace-nowrap',
              { 'sticky right-0 z-10': header === '2X Approval' }]">{{ header }}</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, index) in tableDataset" :key="index">
            <td v-for="(header) in dealsHeaders" :key="header" :class="['px-6 py-4 text-sm text-gray-900 whitespace-nowrap cell-content',
              { 'sticky right-0 z-10 bg-blue-50 p-2': header === '2X Approval' }]"
              :title="Array.isArray(row[header]) ? row[header].join(', ') : row[header]">
              <template v-if="header == '2X Approval'">
                <select :value="row[header]" @change="updateApprovalStatus(index, header, $event)"
                  class="form-select block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 !w-48">
                  <option value="Under Review">Under Review</option>
                  <option value="Approved">Approved</option>
                  <option value="Denied">Denied</option>
                </select>
              </template>
              <template v-else-if="isValidUrl(row[header])">
                <a :href="row[header]" target="_blank" rel="noopener noreferrer">{{ row[header] }}</a>
              </template>
              <template v-else>
                {{ Array.isArray(row[header]) ? row[header].join(', ') : row[header] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>No data available.</p>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/../env";
import Cookies from "@/helpers/cookies";
export default {
  name: "twoXDataTable",
  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: "Data Table"
    },
  },
  data() {
    return {
      excludeColumns: ["_id", "id"],
      expectedFieldData:{
          "Investee/ Client": "investeeName",
          "Investee Type": "investeeType",
          "Institution (Investor)": "nameOfInstitution",
          "Investment/ Project identifier": "uniqueIdentifier",
          "Project Title": "projectTitle",
          "Project description": "projectDescription",
          "Primary country of operation": "primaryCountry",
          "Countries of Operation": "countryOfOperations",
          "Sector": "sectorOfActivity",
          "Other sector": "otherSectorOfActivity",
          "Commitment date": "commitmentDate",
          "Investment instrument": "wayOfInvestment",
          "Investment value (US $)": "totalInvestmentCommitted",
          "2X Challenge Co-investment": "isCoInvestorPresentInThisDeal",
          "2X Challenge Co-investor": "coInvestorInstitution",
          "2X Challenge Co-investor Lead": "leadInvestmentInstitution",
          "Opt-out of public disclosure": "optOutOfPublicDisclosure",
          "Justification": "justification",
          "Climate Finance": "dealConsideredClimateFinanceByOrganization",
          "G&A Criteria Met": "gACriteriaMet",
          "E&S Criteria Met": "eSCriteriaMet",
          "Qualifying Criteria": "qualifyingCriteria",
          "Commitment Criteria": "commitmentCriteria",
          "% Women Ownership": "percentageWomenOwnership",
          "% Women Founders/ Partners": "percentageWomenFoundersPartners",
          "% Women in Senior Management": "percentageWomenSeniorManagement",
          "% Women on Board or IC": "percentageWomenOnBoardOrIC",
          "% Women in Workforce": "percentageWomenInWorkforce",
          "Employment Quality Indicator": "employmentQualityIndicator",
          "Commitment to women in supply chain": "explicitCommitmentToSupportWomen",
          "Supply-chain quality efforts": "supplyChainQualityEfforts",
          "Gender Products or Services": "genderSpecificServicesForWomen",
          "Products or Services Quality Indicator": "productsOrServicesQualityIndicator",
          "Commitment to women customers (FIs)": "commitmentToWomenCustomers",
          "Gender Lens Investment Thesis": "genderLensInvestmentThesis",
          "Investee portfolio commitment (Funds) reported": "investeePortfolioCommitment",
          "Investee Portfolio Commitment (FIs)": "investeePortfolioCommitmentFIs",
          "_id": "_id",
          "Last Modified": "lastModified",
          "2X Approval": "2XApproval",
      },
      expectedDisclosureData:{
        "2XC Member": "member2xc",
        "Primary Contact Title": "primaryContactTitle",
        "Organization Type": "organizationType",
        "E&S Policies / Standards Disclosed": "esPolicyStandardsDisclosed",
        "Link to the E&S Policies / Standards": "linkToESPolicyStandards",
        "Exception List Disclosed": "exceptionListDisclosed",
        "Link to Exception List": "linkToExceptionList",
        "Gender Impact Method Disclosed": "genderImpactMethodDisclosed",
        "Link to Gender Impact Method": "linkToGenderImpactMethod",
        "Gender Indicators Disclosed": "genderIndicatorsDisclosed",
        "Link to Gender Indicators": "linkToGenderIndicators",
        "Gender or Gender Lens Investment Strategy Disclosed": "genderOrGenderLensInvestmentStrategyDisclosed",
        "Link to Gender or Gender Lens Investment Strategy": "linkToGenderOrGenderLensInvestmentStrategy",
        "If yes, please provide a relevant link.": "ifYesPleaseProvideARelevantLink",
        "Publish deals online publicly": "publishDealsOnlinePublicly",
        "Link to Published Deals": "linkToPublishedDeals",
        "_id": "_id",
        "Last Modified": "lastModified",
        "2X Global Approved Investor Disclosures": "twoXGlobalApprovedInvestorDisclosures",
        }
    };
  },
  computed: {
    allHeaders() {
      const allKeys = new Set();
      this.data.forEach(item => {
        Object.keys(item).forEach(key => allKeys.add(key));
      });
      return Array.from(allKeys);
    },
    disclosureHeaders() {
      return Object.keys(this.expectedDisclosureData).filter(header => header !== '_id');
    },
    dealsHeaders() {
      return Object.keys(this.expectedFieldData).filter(header => header !== '_id');
    },
    tableDataset() {
      return this.createOrderedTableDataset(this.data, this.title === '2X Deal Submissions' ? this.expectedFieldData : this.expectedDisclosureData);
    }
  },
  methods: {
    isDate(value) {
      return value instanceof Date && !isNaN(value) ||
        (typeof value === 'string' && !isNaN(Date.parse(value)));
    },
    formatDate(value) {
      const date = new Date(value);
      if (isNaN(date)) return 'Invalid Date';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
    formatCellValue(value) {
      if (value === null || value === undefined || value === '') return '';
      if (Array.isArray(value)) return value.join(', ');
      if (typeof value === 'object') return JSON.stringify(value);
      if (this.isDate(value)) return this.formatDate(value);
      return String(value);
    },
    generateUniqueKey(item) {
      return Object.values(item).join('_');
    },
    createOrderedTableDataset(backendData, expectedFields) {
      return backendData.map(item => {
        const rowData = {};
        Object.entries(expectedFields).forEach(([displayName, backendField]) => {
          rowData[displayName] = item[backendField] || '';
        });
        return rowData;
      });
    },
    async updateApprovalStatus(rowIndex, header, event) {
      const newValue = event.target.value;
      const _id = this.tableDataset[rowIndex]['_id'];
      const payload = {
        id: _id,
        status: newValue
      };
      this.tableDataset[rowIndex][header] = newValue;

      let urlPath;
      if (this.title === '2X Deal Submissions') {
        urlPath = '/twox-global/status/Deals';
      } else if (this.title === '2X Investor Profile Disclosures') {
        urlPath = '/twox-global/status/Investor-disclosure';
      }

      if (urlPath) {
        try {
          const response = await fetch(`${env.apiUrl}${urlPath}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${Cookies.get("session")}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
          const data = await response.json();
          if(response.ok){
            this.$swal.fire({
              icon: "success",
              text: data.data,
              showConfirmButton: true,
            });
          }
          if (!response.ok) {
            throw new Error(data.message || 'Something went wrong!');
          }
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            text: error.message,
            showConfirmButton: true,
        });
          console.error('Error fetching data:', error);
        }
      }
  },
  isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
  }
}
</script>

<style scoped>
td {
  border: 1px solid #ddd;
}
.cell-content {
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}
.sticky {
  position: sticky;
  right: 0;
}
</style>
