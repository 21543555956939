<script>
import axios from "axios";
import Cookies from "@/helpers/cookies";
const _ = require("underscore");
import { Radar } from "vue-chartjs";
import env from "@/../env";

export default {
  extends: Radar,
  data: function () {
    return {
      country: {},
      countryData1: [],
      countryData4: [],
      countryData5: [],
      regionalData: [],
      incomeData: [],
      axes: [],
      fullAxes: [],
      globalData: [],
      axeColors: [],
      max: [],
      one: [],
      domains: [],
      intersections: [],
      hoverIndex: false,
    };
  },
  methods: {
    swapElements(arr, i1, i2) {
      let temp = arr[i1];
      arr[i1] = arr[i2];
      arr[i2] = temp;
    },
    formatLabel(str, maxwidth) {
      var sections = [];
      var words = str.split(" ");
      var temp = "";
      words.forEach(function (item, index) {
        if (temp.length > 0) {
          var concat = temp + " " + item;

          if (concat.length > maxwidth) {
            sections.push(temp);
            temp = "";
          } else {
            if (index == words.length - 1) {
              sections.push(concat);
              return;
            } else {
              temp = concat;
              return;
            }
          }
        }

        if (index == words.length - 1) {
          sections.push(item);
          return;
        }

        if (item.length < maxwidth) {
          temp = item;
        } else {
          sections.push(item);
        }
      });

      return sections;
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    getCountry: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
              "/countries/" +
              _self.iso +
              "/sectors/" +
              _self.$route.params.sector,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    calc: function (input) {
      if (typeof input !== undefined && input !== "" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    abrev: function (input) {
      let abrevString = "";
      for (let i = 0; i < input.toString().split(" ").length; i++) {
        if (input.toString().split(" ")[i] !== "&") {
          abrevString += input.toString().split(" ")[i].substr(0, 1);
        }
      }
      return abrevString;
    },
  },
  props: ["countryD", "paddingL", "paddingR", "paddingT", "paddingB"],
  async created() {
    // We will request the country OURSELF because of asyncness...
    this.country = await this.getCountry();
    this.country = [];
    this.country = this.countryD.countries[0]; // lets do an await before rendering other items.
    this.intersections = this.country.intersections;

    this.intersections.sort(function (a, b) {
      var keyA = a.taab,
        keyB = b.taab;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    for (var i = 0; i < this.intersections.length; i++) {
      this.countryData1.push(this.calc(this.intersections[i].gender_equality)); //0
      if (this.countryD.countries[1]) {
        this.regionalData.push(
          this.calc(this.countryD.countries[1].intersections[i].gender_equality) //1
        );
      }
      if (this.countryD.countries[2]) {
        this.incomeData.push(
          this.calc(this.countryD.countries[2].intersections[i].gender_equality) //2
        );
      }
      if (this.countryD.countries[3]) {
        this.countryData4.push(
          this.calc(this.countryD.countries[3].intersections[i].gender_equality) //3
        );
      }
      if (this.countryD.countries[4]) {
        this.countryData5.push(
          this.calc(this.countryD.countries[4].intersections[i].gender_equality) //4
        );
      }
      this.globalData.push(
        this.calc(
          this.countryD.countries[0].intersections[i].gender_equality_global //Global data
        )
      );
      this.intersections[i].abrev = this.intersections[i].theme;
      if (
        this.intersections[i].theme.indexOf(
          "Information & Communication Technology"
        ) > -1
      ) {
        this.intersections[i].abrev = this.abrev(this.intersections[i].theme);
      }

      if (
        this.intersections[i].theme.indexOf(
          "Political Participation & Leadership"
        ) > -1
      ) {
        this.intersections[i].abrev = "Political Leadership";
      }

      if (
        this.intersections[i].theme.indexOf("Sexual & Reproductive Health") > -1
      ) {
        this.intersections[i].abrev = this.abrev(this.intersections[i].theme);
      }

      if (this.intersections[i].theme.indexOf("Gender-Based Violence") > -1) {
        this.intersections[i].abrev = "GBV";
      }

      this.axes.push(this.intersections[i].abrev);
      this.fullAxes.push(this.intersections[i].theme);

      this.domains.push(this.intersections[i].taab);

      this.max.push(1.4);
      this.one.push(1);

      const taabColors = {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
      };

      this.axeColors.push(taabColors[this.intersections[i].taab]);
    }
    if(this.$route.params.sector == "INFR_GENERL") {
      this.swapElements(this.axes, 40, 41);
      this.swapElements(this.fullAxes, 40, 41);
      this.swapElements(this.countryData1, 40, 41);
      this.swapElements(this.regionalData, 40, 41);
      this.swapElements(this.incomeData, 40, 41);
      this.swapElements(this.countryData4, 40, 41);
      this.swapElements(this.countryData5, 40, 41);
      this.swapElements(this.globalData, 40, 41);
    }

    /**
     *
     * --------------------------------------
     *   UNCOMMENT THIS TO GET OLD HOVERBOX
     * -------------------------------------
     *
     */

    const _self = this;

    this.renderChart(
      {
        labels: this.axes,
        datasets: [
          {
            data: this.countryData1,
            label: this.country.humanName,
            borderColor: env.geoColors.countryspider,
            pointBackgroundColor: env.geoColors.countryspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.countryspider),
            borderWidth: 4,
            pointHitRadius: 8,
          },
          {
            data: this.regionalData,
            label:
              this.countryD.countries[1] && this.countryD.countries[1].humanName
                ? this.countryD.countries[1].humanName
                : "hide",
            borderColor: env.geoColors.regionspider,
            pointBackgroundColor: env.geoColors.regionspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.regionspider),
            pointHitRadius: 8,
          },
          {
            data: this.incomeData,
            label:
              this.countryD.countries[2] && this.countryD.countries[2].humanName
                ? this.countryD.countries[2].humanName
                : "hide",
            borderColor: env.geoColors.incomespider,
            pointBackgroundColor: env.geoColors.incomespider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.incomespider),
            pointHitRadius: 8,
          },
          {
            data: this.countryData4,
            label:
              this.countryD.countries[3] && this.countryD.countries[3].humanName
                ? this.countryD.countries[3].humanName
                : "hide",
            borderColor: env.chartColors.muColor,
            pointBackgroundColor: env.chartColors.muColor,
            fill: false,
            backgroundColor: this.opacity(env.chartColors.muColor),
            borderWidth: 4,
            pointHitRadius: 8,
          },
          {
            data: this.countryData5,
            label:
              this.countryD.countries[4] && this.countryD.countries[4].humanName
                ? this.countryD.countries[4].humanName
                : "hide",
            borderColor: env.taabColors.accessToResources,
            pointBackgroundColor: env.taabColors.accessToResources,
            fill: false,
            borderWidth: 3,
            backgroundColor: this.opacity(env.taabColors.accessToResources),

            pointHitRadius: 8,
          },
          {
            data: this.globalData,
            label: "Global",
            borderColor: env.geoColors.lmicspider,
            pointBackgroundColor: env.geoColors.lmicspider,
            fill: false,
            borderWidth: 3,
            backgroundColor: this.opacity(env.geoColors.lmicspider),
            pointHitRadius: 8,
          },
          {
            data: this.max,
            fill: false,
            label: "hide",
            pointBackgroundColor: this.axeColors,
            pointHitRadius: 8,
          },
          {
            data: this.one,
            label: "Perfect Equality",
            fill: false,
            pointBackgroundColor: "#3F37C9",
            pointRadius: 0,
            backgroundColor: "#3F37C9",
            borderColor: "#3F37C9",
          },
        ],
      },
      {
        maintainAspectRatio: false,
        //aspectRatio: 1,
        responsive: true,
        spanGaps: false,
        layout: {
          padding: {
            left: this.paddingL,
            right: this.paddingR,
            top: this.paddingT,
            bottom: this.paddingB,
          },
        },

        legend: {
          position: "bottom",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("hide");
            },
            fontSize: 13,
            fontFamily: "basier",
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
            }
          },

          // Function to only hide Perfect geneder equality
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }

            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items

            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);

            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;

            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });

              if (!someShown) return;
            }

            meta.hidden = hidden;

            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },

        hover: {
          onHover: function (e) {
            // return false;
            var point = this.getElementAtEvent(e);
            if (point.length > 0) {
              // Prevent from flashing.
              if (
                point[0]._index !==
                _self.hoverIndex + "_" + point[0]._datasetIndex
              ) {
                _self.hoverIndex =
                  point[0]._index + "_" + point[0]._datasetIndex;
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY -105 + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX -10 + "px";
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-name").innerText =
                  _self.fullAxes[point[0]._index].toString().replace(",", " ");
                document.getElementById("intersection-domain").innerText =
                  _self.domains[point[0]._index];
                document.getElementById(
                  "intersection-domain"
                ).style.backgroundColor = _self.axeColors[point[0]._index];
                document.getElementById("div-intersection-country-six").style.display = "none";
                document.getElementById("div-intersection-country-seven").style.display = "none";
                document.getElementById("div-intersection-country").style =
                  "color: " + env.geoColors.countryspider;
                document.getElementById("div-intersection-region").style =
                  "color: " + env.geoColors.regionspider;
                document.getElementById("div-intersection-income").style =
                  "color: " + env.geoColors.incomespider;
                document.getElementById("lmic-avg").style =
                  "color: " + env.geoColors.lmicspider;
                document.getElementById("div-intersection-country-four").style =
                  "color: " + env.chartColors.muColor;
                document.getElementById("div-intersection-country-five").style =
                  "color: " + env.taabColors.accessToResources;
                document.getElementById("intersection-country").innerText =
                  _self.country.humanName;
                document.getElementById("intersection-region").innerText = _self
                  .countryD.countries[1]
                  ? _self.countryD.countries[1].humanName
                  : "Missing";
                document.getElementById("intersection-income").innerText = _self
                  .countryD.countries[2]
                  ? _self.countryD.countries[2].humanName
                  : "Missing";
                document.getElementById("intersection-country-five").innerText =
                  _self.countryD.countries[4]
                    ? _self.countryD.countries[4].humanName
                    : "Missing";
                document.getElementById("intersection-country-four").innerText =
                  _self.countryD.countries[3]
                    ? _self.countryD.countries[3].humanName
                    : "Missing";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country"
                ).style.display = "flex";
                document.getElementById(
                  "div-intersection-country-four"
                ).style.display = _self.countryD.countries[3] && _self.countryD.countries[3].humanName ? "flex" : "none";
                document.getElementById(
                  "div-intersection-country-five"
                ).style.display = _self.countryD.countries[4] && _self.countryD.countries[4].humanName ? "flex" : "none";
                document.getElementById(
                  "div-intersection-region"
                ).style.display = _self.countryD.countries[1] && _self.countryD.countries[1].humanName ? "flex" : "none";
                document.getElementById(
                  "div-intersection-income"
                ).style.display = _self.countryD.countries[2] && _self.countryD.countries[2].humanName ? "flex" : "none";
                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
                document.getElementById("intersection-lmic-text").innerText =
                  "";
                document.getElementById("intersection-lmic-text").innerText =
                  "Global";
                document.getElementById(
                  "intersection-country-value"
                ).innerText =
                  _self.countryData1[point[0]._index] == null
                    ? _self.countryData1[point[0]._index] == 0
                      ? 0
                      : "Missing"
                    : _self.countryData1[point[0]._index]; // || "";
                document.getElementById(
                  "intersection-country-value-four"
                ).innerText =
                  _self.countryData4[point[0]._index] == null
                    ? _self.countryData4[point[0]._index] == 0
                      ? 0
                      : "Missing"
                    : _self.countryData4[point[0]._index];
                document.getElementById(
                  "intersection-country-value-five"
                ).innerText =
                  _self.countryData5[point[0]._index] == null
                    ? _self.countryData5[point[0]._index] == 0
                      ? 0
                      : "Missing"
                    : _self.countryData5[point[0]._index];
                document.getElementById("intersection-region-value").innerText =
                  _self.regionalData[point[0]._index] == null
                    ? _self.regionalData[point[0]._index] == 0
                      ? 0
                      : "Missing"
                    : _self.regionalData[point[0]._index]; // || "";
                document.getElementById("intersection-income-value").innerText =
                  _self.incomeData[point[0]._index] == null
                    ? _self.incomeData[point[0]._index] == 0
                      ? 0
                      : "Missing"
                    : _self.incomeData[point[0]._index]; // || "";
                document.getElementById("intersection-lmic-value").innerText =
                  _self.globalData[point[0]._index] == null
                    ? _self.globalData[point[0]._index] == 0
                      ? 0
                      : "Missing"
                    : _self.globalData[point[0]._index];

                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "none";

                if (
                  document.getElementById("intersection-country-value")
                    .innerText == ""
                ) {
                  document.getElementById(
                    "intersection-scores-validity"
                  ).innerText = "";
                  document.getElementById(
                    "intersection-scores-power"
                  ).innerText = "";
                } else {
                  document.getElementById(
                    "intersection-scores-validity"
                  ).innerText = Number(
                    _self.intersections[point[0]._index].validity || 0
                  ).toFixed(2);
                  document.getElementById(
                    "intersection-scores-power"
                  ).innerText = Number(
                    _self.intersections[point[0]._index].power || 0
                  ).toFixed(2);
                }
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
            }

            // var label = this.data.labels[point._index];
            //var value = this.data.datasets[point._datasetIndex].data[point._index];

            if (e.fromElement == undefined) {
              document.getElementById("intersection-info").style.opacity = "";
            } else {
              document.getElementById("intersection-info").style.opacity = "1";
            }

            if (point.length) e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";

            //e.target.style.cursor = 'pointer';
          },
        },
        scale: {
          angleLines: {
            display: true,
            color: "rgb(243, 243, 243)",
            lineWidth: 2,
          },
          gridLines: {
            display: true,
            color: "rgb(243, 243, 243)",
            lineWidth: 2,
          },
          pointLabels: {
            fontColor: this.axeColors,
            fontSize: 9.9, // 12
            fontFamily: "basier",
            lineHeight: 1,
            onClick: function () {},
            callback: function (pointLabel, index) {
              if (
                _self.$route.params.sector == "WASH_UTILIT" ||
                _self.$route.params.sector == "INFR_TRANSP" ||
                _self.$route.params.sector == "ENRG_SMTECH" ||
                _self.$route.params.sector == "FNCE_GENERL" ||
                _self.$route.params.sector == "INFR_DETENT" ||
                _self.$route.params.sector == "INFR_EDUCAT" ||
                _self.$route.params.sector == "INFR_HEALTH" ||
                _self.$route.params.sector == "INFR_MARKET"
              ) {
                if (index == 20 || index == 21 || index == 22) {
                  var words = pointLabel.split(" ");
                  var testLine = [];
                  testLine.push(" ");
                  for (var n = 0; n < words.length; n++) {
                    testLine.push(words[n]);
                  }
                  testLine.push(" ");
                  return testLine;
                } else {
                  return pointLabel;
                }
              } else if (
                _self.$route.params.sector == "CVID_GENERL" ||
                _self.$route.params.sector == "FNCE_MCRINF"
              ) {
                if (
                  index == 21 ||
                  index == 42 ||
                  index == 1 ||
                  index == 0 ||
                  index == 22
                ) {
                  var words2 = pointLabel.split(" ");
                  var testLine2 = [];
                  testLine2.push(" ");
                  for (var n2 = 0; n2 < words2.length; n2++) {
                    testLine2.push(words2[n2]);
                  }
                  testLine2.push(" ");
                  return testLine2;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_MATNEW") {
                if (
                  index == 1 ||
                  index == 21 ||
                  index == 22 ||
                  index == 43 ||
                  index == 23
                ) {
                  var words3 = pointLabel.split(" ");
                  var testLine3 = [];
                  testLine3.push(" ");
                  for (var n3 = 0; n3 < words3.length; n3++) {
                    testLine3.push(words3[n3]);
                  }
                  testLine3.push(" ");
                  return testLine3;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "INFR_GENERL") {
                if (
                  index == 0 ||
                  index == 1 ||
                  index == 20 ||
                  index == 21 ||
                  index == 40 ||
                  index == 41
                ) {
                  var words4 = pointLabel.split(" ");
                  var testLine4 = [];
                  testLine4.push(" ");
                  for (var n4 = 0; n4 < words4.length; n4++) {
                    testLine4.push(words4[n4]);
                  }
                  testLine4.push(" ");
                  return testLine4;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "ENRG_GOVPOL") {
                if (index == 1 || index == 21) {
                  var words5 = pointLabel.split(" ");
                  var testLine5 = [];
                  testLine5.push(" ");
                  for (var n5 = 0; n5 < words5.length; n5++) {
                    testLine5.push(words5[n5]);
                  }
                  return testLine5;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_HIVHLT") {
                if (index == 19) {
                  var words6 = pointLabel.split(" ");
                  var testLine6 = [];
                  testLine6.push("");
                  for (var n6 = 0; n6 < words6.length; n6++) {
                    testLine6.push(words6[n6]);
                  }
                  return testLine6;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_SEXREP") {
                if (index == 20 || index == 21 || index == 0 || index == 1) {
                  var words7 = pointLabel.split(" ");
                  var testLine7 = [];
                  testLine7.push(" ");
                  for (var n7 = 0; n7 < words7.length; n7++) {
                    testLine7.push(words7[n7]);
                  }
                  return testLine7;
                } else {
                  return pointLabel;
                }
              } else if (
                _self.$route.params.sector == "AGRI_COCOA" ||
                _self.$route.params.sector == "AGRI_COFFEE"
              ) {
                if (
                  index == 21 ||
                  index == 22 ||
                  index == 23 ||
                  index == 1 ||
                  index == 0
                ) {
                  var words8 = pointLabel.split(" ");
                  var testLine8 = [];

                  for (var n8 = 0; n8 < words8.length; n8++) {
                    testLine8.push(words8[n8]);
                  }
                  //                            testLine8.push(" ");
                  return testLine8;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_MAIZE") {
                if (
                  index == 0 ||
                  index == 1 ||
                  index == 21 ||
                  index == 42 ||
                  index == 43
                ) {
                  var words9 = pointLabel.split(" ");
                  var testLine9 = [];
                  testLine9.push(" ");
                  for (var n9 = 0; n9 < words9.length; n9++) {
                    testLine9.push(words9[n9]);
                  }

                  return testLine9;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_LEGUME") {
                if (index == 22 || index == 23 || index == 1 || index == 0) {
                  var words10 = pointLabel.split(" ");
                  var testLine10 = [];
                  testLine10.push(" ");
                  for (var n10 = 0; n10 < words10.length; n10++) {
                    testLine10.push(words10[n10]);
                  }
                  testLine10.push(" ");
                  return testLine10;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_GENERL") {
                if (index == 21) {
                  var words11 = pointLabel.split(" ");
                  var testLine11 = [];
                  testLine11.push(" ");
                  for (var n11 = 0; n11 < words11.length; n11++) {
                    testLine11.push(words11[n11]);
                  }

                  return testLine11;
                } else {
                  return pointLabel;
                }
              } else {
                var sec = ["HLTH_ADOLES", "HLTH_MATNEW"];
                if (sec.includes(_self.$route.params.sector)) {
                  if (index == 22 || index == 41 || index == 1) {
                    var words1 = pointLabel.split(" ");

                    var testLine1 = [];
                    testLine1.push(" ");
                    for (var n1 = 0; n1 < words1.length; n1++) {
                      testLine1.push(words1[n1]);
                    }
                    return testLine1;
                  } else {
                    return pointLabel;
                  }
                } else {
                  return pointLabel;
                }
              }
              // Return string here. String must be defined
            },
          },
          ticks: {
            beginAtZero: true,
            max: 1.4,
            min: 0,
            stepSize: 0.2,
          },
        },
        tooltips: {
          enabled: false,
        },
        /*
        animation: {
           onComplete: function() {
              _self2.$data._chart.update();
           }
        },
        */

        /*callbacks: {
            label: ((tooltipItems, data) => {
              var html = '<div style="font-size: 300px">mqlskjdfmlkqjsdf</div>'
              return tooltipItems.yLabel = html
              //return tooltipItems.yLabel + '£'
            })
          }*/
      }
    );

    const _self2 = this;
    window.addEventListener("beforeprint", function () {
      const Chart = _self2.$data._chart;
      Chart.resize();
    });

    //const Chart = _self2.$data._chart;
    //Chart.resize();

    /*
    window.addEventListener("onload", function() {
    });

    */

    setTimeout(function () {
      _self2.$data._chart.update();
    }, 1000);
  },
};
</script>

<style></style>
