<template>
  <div>
    <p class="block text-[#191b45] leading-6 mb-4 text-base tracking-tight font-medium">
      As a 2X Global Portal admin, you can invite a team member to review, approve or deny submissions and/or deals for 2X Challenge and/or 2X Certification.
    </p>
    <fieldset class="gap-2 flex flex-col items-start">
      <div v-for="option in options" :key="option.id" class="relative flex items-start flex-col">
        <div class="flex items-center h-5">
          <input
            :id="option.id"
            v-model="selectedOptions"
            :value="option.value"
            type="checkbox"
            class="focus:ring-blue-400 h-4 w-4 text-indigo-[#191b45] border-gray-300 rounded"
          />
        <div class="ml-3 text-sm font-medium">
          <label :for="option.id" class="font-semibold text-gray-700">{{ option.label }}</label>
        </div>
        </div>
      </div>
    </fieldset>
    <form class="mt-4 sm:flex sm:max-w-md" @submit.prevent="submitForm">
      <label for="email-address" class="sr-only">Email address</label>
      <input
        v-model.trim="email"
        type="email"
        name="email-address"
        id="email-address"
        autocomplete="email"
        required
        class="appearance-none w-full px-4 py-2 border border-gray-300 text-base rounded-md text-[#191b45] bg-white placeholder-gray-500 focus:outline-none focus:ring-[#191b45] focus:border-[#191b45] lg:max-w-xs"
        placeholder="Enter Email Address"
      />
      <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button
          type="submit"
          :disabled="!isFormValid"
          :class="[
            'w-full border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white focus:outline-none',
            isFormValid ? 'bg-[#191b45] hover:bg-[#191b45]/90' : 'bg-gray-300 cursor-not-allowed'
          ]"
        >
          Invite
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "TwoxglobalInvite",
  data() {
    return {
      options: [
        { id: "2x-Challenge", value: "2X Challenge", label: "2X Challenge" , description: "Take part in the 2X Challenge to drive gender equality and women’s empowerment through impactful business practices and investments." },
        { id: "2x-Certification", value: "2X Certification", label: "2X Certification", description : "Enhance your skills with our prestigious certification programs. Gain recognition, boost your expertise, and stand out in your field." },
      ],
      selectedOptions: [],
      email: '',
    };
  },
  computed: {
    isFormValid() {
      return this.selectedOptions.length > 0 && this.isValidEmail(this.email);
    },
  },
  methods: {
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    submitForm() {
      if (this.isFormValid) {
        this.$emit('form-submitted', {
          email: this.email,
          twoXChallenge : this.selectedOptions.includes("2X Challenge"),
          twoXCertification: this.selectedOptions.includes("2X Certification"),
        });
        this.resetForm();
      }
    },
    resetForm() {
      this.email = '';
      this.selectedOptions = [];
    },
  },
};
</script>
