<template>
  <div class="flex flex-row w-full bg-gray-100">
    <div id="notLoaded" v-if="endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <sidebar></sidebar>

    <div class="flex flex-col px-5 pb-5 main-contentwrap">
      <div>
        <div
          class="py-2 flex flex-row items-center justify-between w-full sm:px-6 lg:px-8 mb-6"
        >
          <h1
            class="text-2xl text-black font-medium leading-7"
            v-if="subscriptionCont.totalUserCount >= 1"
          >
            Your organization has
            {{ subscriptionCont.totalUserCount }} member<span
              v-if="subscriptionCont.totalUserCount > 1"
              >s</span
            >
            on the account <br />
          </h1>
          <div v-if="isadmin" class="flex flex-row items-center justify-end">
            <input
              type="text"
              class="border-2 border-gray-200 rounded-lg px-4 py-2 text-sm mr-2"
              placeholder="Search a user"
              v-model="q"
            />
            <button
              v-if="isadmin"
              @click="add()"
              class="text-white mr-5 py-2 px-4 rounded-lg text-sm float-right"
              style="background-color: rgb(32, 23, 71); width: 25%"
            >
              Invite
            </button>
          </div>
        </div>
        <div
          class="flex flex-row ustify-end sm:px-6 lg:px-8 inline-block w-full"
        >
          <!-- verbaige  -->
          <ul v-if="organizationDetail">
            <li class="EligibleTitle tooltip">
              <svg
                class="w-5 h-5 mx-1 text-blue-600 inline"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  stroke-linecap="round"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linejoin="round"
                >
                  <path d="M19,12h-14"></path>
                  <path d="M14,17l5,-5"></path>
                  <path d="M14,7l5,5"></path>
                </g>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
              <b>{{ subscriptionCont.totalUserCount }}</b> member<span
                v-if="subscriptionCont.totalUserCount > 1"
                >s</span
              >
              have access to Equilo's open-source tools
            </li>

            <div
              v-for="subscriptionInfo in subscriptionInfo?.productArr"
              v-bind:key="subscriptionInfo._id"
            >
              <li
                v-for="(product, index) in subscriptionInfo.featureFlag"
                v-bind:key="product._id + index"
                class="EligibleTitle tooltip"
              >
                <svg
                  class="w-5 h-5 mx-1 text-blue-600 inline"
                  version="1.1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    stroke-linecap="round"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linejoin="round"
                  >
                    <path d="M19,12h-14"></path>
                    <path d="M14,17l5,-5"></path>
                    <path d="M14,7l5,5"></path>
                  </g>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
                <span
                  v-if="
                    subscriptionInfo.productFamilyId == api_access ||
                    product.flag == 'productapiaccess'
                  "
                >
                  Your Organization has access to paid
                  <b>{{ product.name }}</b> with
                  <b>{{ subscriptionInfo.subscription }}</b> Subscription
                  <span class="consumptionHoverText"
                    >To upgrade your account
                    <a
                      :href="'#/pricing/' + subscriptionInfo.productFamilyId"
                      style="text-decoration: underline; color: #0077c8"
                      >click here</a
                    ></span
                  >
                </span>
                <span v-else>
                  <span v-if="product.users <= 1">
                    There is <b>{{ product.users | formatNumber }}</b> out of
                    <b>{{ subscriptionInfo.maxUsers | formatNumber }}</b> users
                    with access to your paid <b>{{ product.name }}</b> with
                    <b>{{ subscriptionInfo.subscription }}</b> Subscription
                  </span>
                  <span v-else>
                    There are <b>{{ product.users | formatNumber }}</b> out of
                    <b>{{ subscriptionInfo.maxUsers | formatNumber }}</b> users
                    with access to your paid <b>{{ product.name }}</b> with
                    <b>{{ subscriptionInfo.subscription }}</b> Subscription
                    <!-- <img v-if="(subscriptionInfo.maxUsers - product.users) == 0" src="../../assets/images/helpblack.png" alt="background-image" style="display:inline;"/> -->
                  </span>
                  <span class="consumptionHoverText"
                    >To upgrade your account to allow more users,
                    <a
                      :href="'#/pricing/' + subscriptionInfo.productFamilyId"
                      style="text-decoration: underline; color: #0077c8"
                      >click here</a
                    ></span
                  >
                </span>
              </li>
            </div>
          </ul>
        </div>

        <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <b>User Access To Subscription Features</b>
          <div
            class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg"
          >
            <table
              class="divide-y divide-gray-200 w-full bg-white text-center"
              style="min-width: 1100px"
            >
              <thead>
                <tr>
                  <th
                    v-for="header in headers"
                    v-bind:key="header.id"
                    class="px-4 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ header.name }}
                  </th>
                  <th
                    class="px-6 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    v-if="isadmin"
                  >
                    Options
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="row in accessFeature" v-bind:key="row._id">
                  <td
                    class="px-6 whitespace-no-wrap"
                    v-for="(header, index) in headers"
                    v-bind:key="row._id + '_' + header.id"
                  >
                    <!-- default field -->
                    <div v-if="header.type == 'text'">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ row[header.id] }}
                      </div>
                    </div>

                    <div v-if="header.type == 'boolean'">
                      <div class="text-sm leading-5 text-gray-900 text-center">
                        <label class="check-container">
                          <input
                            type="checkbox"
                            v-model="row[header.id]"
                            :disabled="
                              header.editable == false || row._id == user._id
                                ? true
                                : false
                            "
                            @change="
                              updateFeaturePermisison(
                                row._id,
                                header.id,
                                row[header.id]
                              )
                            "
                            :id="row._id + index"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <!-- {{ row[header.id] ? "Yes" : "No" }} -->
                      </div>
                    </div>

                    <div v-if="header.type == 'image'">
                      <div
                        class="flex-shrink-0 h-10 w-10 overflow-hidden rounded-full"
                      >
                        <img class="" :src="row[header.id]" alt="" />
                      </div>
                    </div>
                  </td>

                  <!-- Options -->
                  <td
                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium"
                    v-if="isadmin"
                  >
                    <button
                      v-if="row._id !== user._id"
                      @click="remove(row)"
                      class="text-white mr-1 py-2 px-3 rounded-lg bg-red-600 text-sm"
                    >
                      Remove
                    </button>
                    <!-- <button  @click="edit(row)" class="text-white mr-1 py-2 px-3 rounded-lg float-right text-sm" style="background-color: rgb(32, 23, 71);">Edit</button> -->
                  </td>
                </tr>

                <!-- More rows... -->
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  v-if="page != 1"
                  @click="page--"
                >
                  Previous
                </button>
              </li>
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  v-for="pageNumber in pages.slice(page - 1, page + 5)"
                  @click="page = pageNumber"
                  v-bind:key="pageNumber"
                >
                  {{ pageNumber }}
                </button>
              </li>
              <li class="page-item">
                <button
                  type="button"
                  @click="page++"
                  v-if="page < pages.length"
                  class="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <b>User Access To Assessments</b>
          <div
            class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg"
          >
            <table
              class="divide-y divide-gray-200 bg-white w-full text-center"
              style="min-width: 1100px"
            >
              <thead>
                <tr>
                  <th
                    v-for="header in assementheaders"
                    v-bind:key="header.id"
                    class="px-4 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ header.name }}
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="row in accessAssesment" v-bind:key="row._id">
                  <td
                    class="px-6 py-4 whitespace-no-wrap"
                    v-for="header in assementheaders"
                    v-bind:key="row._id + '_' + header.id"
                  >
                    <div v-if="header.type == 'text'">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ row[header.id] }}
                      </div>
                    </div>

                    <div v-if="header.type == 'boolean'">
                      <div class="text-sm leading-5 text-gray-900 text-center">
                        <label class="check-container">
                          <input
                            type="checkbox"
                            v-model="row[header.id]"
                            :disabled="
                              header.editable == false || row._id == user._id
                                ? true
                                : false
                            "
                            @change="
                              editAssesmentPermission(
                                row._id,
                                header.id,
                                row[header.id],
                                $event
                              )
                            "
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div v-if="header.type == 'checkbox_text'">
                      <span v-if="row[header.id]">Yes</span>
                      <span v-else>No</span>
                    </div>

                    <div v-if="header.type == 'image'">
                      <div
                        class="flex-shrink-0 h-10 w-10 overflow-hidden rounded-full"
                      >
                        <img class="" :src="row[header.id]" alt="" />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  v-if="assesmentpage != 1"
                  @click="assesmentpage--"
                >
                  Previous
                </button>
              </li>
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  v-for="pageNumber in assesmentpages.slice(
                    assesmentpage - 1,
                    assesmentpage + 5
                  )"
                  @click="assesmentpage = pageNumber"
                  v-bind:key="pageNumber"
                >
                  {{ pageNumber }}
                </button>
              </li>
              <li class="page-item">
                <button
                  type="button"
                  @click="assesmentpage++"
                  v-if="assesmentpage < assesmentpages.length"
                  class="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="invite">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Invite your colleagues 🎉
                </h3>

                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Invite your colleagues to join your team so they can also
                    access this content, learn from it, and work with you to
                    improve gender equality!
                  </p>
                </div>

                <div class="mt-5">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                    First Name
                  </p>
                  <input
                    type="text"
                    class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                    style="width: 100%"
                    placeholder="First Name"
                    v-model="object.firstname"
                  />
                </div>

                <div class="mt-5">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                    Last Name
                  </p>
                  <input
                    type="text"
                    class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                    style="width: 100%"
                    placeholder="Last Name"
                    v-model="object.lastname"
                  />
                </div>

                <div class="mt-2">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-500">
                    Email
                  </p>
                  <input
                    type="text"
                    class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                    style="width: 100%"
                    placeholder="john@example.com"
                    v-model="object.email"
                    @keydown.space.prevent
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                type="button"
                @click="create()"
                class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Invite that person!
              </button>
            </span>
            <span
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
              <button
                @click="invite = false"
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="editUser">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Edit permissions
                </h3>

                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Edit user permissions.
                  </p>
                </div>

                <div class="mt-4">
                  <div v-for="field in editableFields" v-bind:key="field.id">
                    <p
                      class="text-sm leading-5 font-medium mb-1 text-gray-500"
                      v-if="field.type != 'boolean'"
                    >
                      {{ field.name }}
                    </p>

                    <!-- if text -->
                    <input
                      v-if="field.type == 'text'"
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                      style="width: 100%"
                      :placeholder="field.placeholder"
                      v-model="object[field.id]"
                    />

                    <!-- if text -->
                    <input
                      v-else-if="field.type == 'image'"
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                      style="width: 100%"
                      :placeholder="field.placeholder"
                      v-model="object[field.id]"
                    />

                    <!-- if textarea -->
                    <textarea
                      v-else-if="field.type == 'textarea'"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                      rows="5"
                      style="width: 100%"
                      :placeholder="field.placeholder"
                      v-model="object[field.id]"
                    ></textarea>

                    <!-- if select -->
                    <dd
                      v-else-if="field.type == 'select'"
                      class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      <select
                        v-model="object[field.id]"
                        class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm"
                        style="width: 100%"
                      >
                        <option value="undefined" disabled>Choose value</option>
                        <option
                          selected
                          v-for="value in field.values"
                          v-bind:key="value"
                          :value="value"
                        >
                          {{ value }}
                        </option>
                      </select>
                    </dd>

                    <!-- if boolean -->

                    <dd
                      v-else-if="field.type == 'boolean'"
                      class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      <span
                        v-if="field.name === 'Admin' && object._id == user._id"
                      ></span>
                      <label class="check-container" v-else
                        >{{ field.name }}
                        <input type="checkbox" v-model="object[field.id]" />
                        <span class="checkmark"></span>
                      </label>

                      <!-- <select v-model="object[field.id]" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;">
                          <option value="undefined" disabled>Choose value</option>
                          <option :value="true">True</option>
                          <option :value="false">False</option>
                        </select> -->
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                v-if="object._id"
                type="button"
                @click="update()"
                class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Update
              </button>
            </span>
            <span
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
              <button
                @click="editUser = false"
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="askConfirmation">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <svg
                  class="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Are you sure?
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    Are you sure you want to remove
                    <b>{{ object.firstname }} {{ object.lastname }}</b> ? This
                    will permanently remove the person from your team.<br />
                    This action cannot be undone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                @click="confirmed()"
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Remove
              </button>
            </span>
            <span
              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
              <button
                @click="canceled()"
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Popup -->
    <div class="fixed z-10 inset-0 overflow-y-auto" v-show="modalVisible">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePaymentPopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="absolute top-0 left-0 w-full h-full"
            style="z-index: 9; background-color: rgba(255, 255, 255, 0.6)"
            v-if="stepLoading"
          >
            <clip-loader
              :color="env.brandingColors.teal"
              :size="'60px'"
              class="absolute top-50 left-50"
              style="transform: translate(-50%, -50%)"
            ></clip-loader>
          </div>
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-12 sm:pb-6 overflow-y-auto max-h-screen-70"
          >
            <div class="block">
              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepAddon"
              >
                <div class="popup-plan mb-12" v-if="!popupPlan.is_subscribe">
                  <div class="flex gap-5 items-center">
                    <h3 class="text-2xl leading-6 font-bold text-gray-900">
                      {{ popupPlan.productOrSubscription }}
                    </h3>
                    <div
                      class="division h-0 border border-grey-70 flex-1"
                    ></div>
                  </div>

                  <div class="mt-4 short_description">
                    <p
                      class="text-sm leading-5 text-gray-600"
                      v-html="popupPlan.shortCardDescriptionHumanLangauge"
                    ></p>
                  </div>

                  <div class="mt-5 mb-4 items-center">
                    <p
                      class="text-sm text-right leading-5 font-bold text-gray-900"
                    >
                      {{
                        (popupPlan.price ? popupPlan.price : 0).toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )
                      }}
                    </p>
                    <p
                      class="flex gap-5 mt-1 items-center text-xs leading-5 text-gray-900"
                      v-if="getProratedPrice(popupPlan.stripePriceId)"
                    >
                      <span>Prorated price</span>
                      <span class="text-right flex-1">{{
                        getProratedPrice(
                          popupPlan.stripePriceId
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}</span>
                    </p>
                  </div>
                </div>

                <div
                  class="division h-0 border-t border-grey-60 my-8"
                  v-if="!popupPlan.is_subscribe && addons.length"
                ></div>

                <div
                  class="addon mb-12"
                  v-for="addon in addons"
                  :key="addon._id"
                >
                  <div class="flex gap-5 items-center">
                    <h3
                      class="leading-6 font-bold text-gray-900"
                      :class="[
                        !popupPlan.is_subscribe ? 'text-lg' : 'text-2xl',
                      ]"
                    >
                      {{ addon.productOrSubscription }}
                    </h3>
                    <div
                      class="division h-0 border border-grey-70 flex-1"
                      v-if="popupPlan.is_subscribe"
                    ></div>
                  </div>

                  <div class="mt-4">
                    <p
                      class="text-sm leading-5 text-gray-600"
                      v-html="addon.shortCardDescriptionHumanLangauge"
                    ></p>
                  </div>

                  <div class="flex mt-5 mb-4 items-center">
                    <input
                      type="number"
                      class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                      style="width: 70px"
                      min="0"
                      v-model="addon.quantity"
                    />
                    <p class="text-sm leading-5 font-bold ml-4 text-gray-900">
                      x ${{ addon.price }} / {{ addon.productUnit }}
                    </p>
                    <p
                      class="text-sm text-right leading-5 font-bold text-gray-900 flex-1"
                    >
                      {{
                        (addon.price * addon.quantity).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </p>
                  </div>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="
                      getProratedPrice(addon.stripePriceId) &&
                      Number(addon.quantity)
                    "
                  >
                    <span>Prorated price</span>
                    <span class="text-right flex-1">{{
                      getProratedPrice(addon.stripePriceId).toLocaleString(
                        "en-US",
                        { style: "currency", currency: "USD" }
                      )
                    }}</span>
                  </p>
                </div>

                <div class="division h-0 border-t border-grey-60 my-8"></div>

                <div class="total-price mt-6">
                  <div class="flex gap-5 mb-1 items-center">
                    <p class="text-lg leading-5 font-bold text-gray-900">
                      Total
                    </p>
                    <p
                      class="text-lg text-right leading-5 font-bold text-gray-900 flex-1"
                    >
                      {{
                        totalPrice.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </p>
                  </div>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="getProratedDeduction() < 0 && !popupPlan.is_subscribe"
                  >
                    <span
                      >Prorated deduction (from your current paid
                      subscription)</span
                    >
                    <span class="text-right flex-1">{{
                      getProratedDeduction().toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}</span>
                  </p>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="getProratedTotal() != 0 && totalPrice"
                  >
                    <span>Prorated total</span>
                    <span class="text-right flex-1">{{
                      getProratedTotal().toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}</span>
                  </p>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="preInvoice && totalPrice"
                  >
                    <span>Current balance</span>
                    <span class="text-right flex-1">{{
                      (preInvoice.starting_balance / 100).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )
                    }}</span>
                  </p>
                  <p
                    class="flex gap-5 items-center text-xs leading-5 text-gray-900"
                    v-if="preInvoice && totalPrice"
                  >
                    <span>Amount due</span>
                    <span class="text-right flex-1">{{
                      (preInvoice.amount_due / 100).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}</span>
                  </p>
                </div>

                <div class="flex mt-10 mb-4 items-center">
                  <input
                    type="text"
                    class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                    placeholder="Enter coupon code"
                    style="width: 200px"
                    v-model="couponCode"
                    @blur="applyCoupon()"
                    @keyup.enter="applyCoupon()"
                  />
                  <p
                    class="text-xs text-right leading-5 text-red-400 flex-1"
                    v-if="couponError"
                  >
                    {{ couponError }}
                  </p>
                  <p
                    class="text-xs text-right leading-5 text-gray-900 flex-1"
                    v-if="couponSuccess"
                  >
                    {{ couponSuccess }}
                  </p>
                </div>

                <p
                  class="mt-6 text-xs leading-5 text-gray-900 font-medium"
                  v-if="popupPlan.is_subscribe"
                >
                  Adding
                  <span v-for="(addon, i) in addons" :key="addon.productUnit">
                    <span class="font-bold"
                      >{{ addon.quantity }}
                      {{
                        addon.quantity == 1
                          ? addon.productUnit
                          : addon.productUnitPlural
                      }}</span
                    >
                    <span v-if="i < addons.length - 1"> and </span>
                  </span>

                  will add
                  <span class="font-bold">{{
                    totalPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  }}</span>
                  to your bill starting
                  <span class="font-bold" v-if="stripeSubscription">{{
                    getHumanReadableDate(
                      stripeSubscription.current_period_end * 1000
                    )
                  }}</span>
                  Equilo will charge you for the seats even if you haven't
                  assigned them.
                </p>
                <p
                  class="mt-6 text-xs leading-5 text-gray-900 font-medium"
                  v-else
                >
                  Equilo will charge you for the seats even if you haven't
                  assigned them.
                </p>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepBillingRegister"
              >
                <div class="mt-2">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-700">
                    Email
                  </p>
                  <input
                    type="text"
                    class="border-2 border-gray-400 mb-2 rounded-md px-3 py-2 text-sm"
                    id="registerBillingEmail"
                    style="width: 100%"
                    placeholder="Email Address"
                  />
                </div>

                <div ref="registerBillingAddressElement" class="mt-2">
                  <!-- Elements will create form elements here -->
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepBillingEdit"
              >
                <div class="mt-2">
                  <p class="text-sm leading-5 font-medium mb-1 text-gray-700">
                    Email
                  </p>
                  <input
                    type="text"
                    class="border-2 border-gray-400 mb-2 rounded-md px-3 py-2 text-sm"
                    id="updateBillingEmail"
                    style="width: 100%"
                    placeholder="Email Address"
                  />
                </div>

                <div ref="updateBillingAddressElement" class="mt-2">
                  <!-- Elements will create form elements here -->
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepBillingList"
              >
                <div
                  class="mt-2 border border-gray-400 hover:border-gray-600 rounded-md p-4 relative"
                  v-if="stripeCustomer"
                >
                  <a
                    class="inline-block absolute text-xs"
                    style="top: 8px; right: 10px"
                    @click="editBillingDetail()"
                    >Edit</a
                  >
                  <h6 class="font-bold">Account details</h6>
                  <ul>
                    <li class="text-sm">
                      <span class="text-md font-bold">Name </span
                      >{{ stripeCustomer.name }}
                    </li>
                    <li class="text-sm">
                      <span class="text-md font-bold">Billing email </span
                      >{{ stripeCustomer.email }}
                    </li>
                    <li class="text-sm" v-if="stripeCustomer.address">
                      <span class="text-md font-bold">Billing details </span>
                      {{ stripeCustomer.address.line1 }},
                      {{ stripeCustomer.address.city }},
                      {{ stripeCustomer.address.state }},
                      {{ stripeCustomer.address.postal_code }},
                      {{ stripeCustomer.address.country }}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepPaymentList"
              >
                <div
                  class="mt-2 hover:border-gray-600 rounded-md p-4 cursor-pointer relative"
                  :class="[
                    paymentMethod.id == selectedPaymentMethodId
                      ? 'border-gray-600 border-2'
                      : 'border-gray-400 border',
                  ]"
                  @click="selectedPaymentMethodId = paymentMethod.id"
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.id"
                >
                  <a
                    class="inline-block absolute text-xs"
                    style="top: 8px; right: 10px"
                    @click="deletePayment(paymentMethod.id)"
                    >Delete</a
                  >
                  <a
                    class="inline-block absolute text-xs"
                    style="top: 8px; right: 70px"
                    @click="editPayment(paymentMethod)"
                    >Edit</a
                  >
                  <ul>
                    <li class="text-sm">
                      <span class="text-md font-bold capitalize"
                        >{{ paymentMethod.card.brand }} </span
                      >****{{ paymentMethod.card.last4 }}
                    </li>
                    <li class="text-sm">
                      <span class="text-md font-bold">Expires </span
                      >{{ getMonthName(paymentMethod.card.exp_month) }}
                      {{ paymentMethod.card.exp_year }}
                    </li>
                  </ul>
                </div>
                <a class="mt-4 inline-block font-bold" @click="addPayment()"
                  >Add</a
                >
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepPaymentRegister"
              >
                <div ref="cardElement">
                  <!-- Elements will create form elements here -->
                </div>
                <!-- We'll put the error messages in this element -->
                <div
                  ref="cardElementErrors"
                  role="alert"
                  class="text-sm mt-2"
                  style="color: #fa755a"
                ></div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-if="isStepPaymentEdit"
              >
                <div class="flex mt-5 mb-4 items-center gap-5">
                  <select
                    class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                    style="width: 60%"
                    v-model="editedExpiryMonth"
                  >
                    <option :value="n" :key="n" v-for="n in 12">
                      {{ getMonthName(n) }}
                    </option>
                  </select>
                  <label class="font-bold">Expiry month</label>
                </div>
                <div class="flex mt-5 mb-4 items-center gap-5">
                  <select
                    class="border-2 border-gray-400 rounded-sm px-3 py-2 text-sm text-gray-900"
                    style="width: 60%"
                    v-model="editedExpiryYear"
                  >
                    <option :value="n" :key="n" v-for="n in rangeExpiryYear">
                      {{ n }}
                    </option>
                  </select>
                  <label class="font-bold">Expiry year</label>
                </div>
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepSuccess"
              >
                Purchased successfully.
              </div>

              <div
                class="mt-3 text-center sm:mt-0 sm:text-left"
                v-show="isStepError"
              >
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div
            class="bg-gray-50 px-4 py-3 sm:p-12 sm:pt-4 sm:flex sm:flex-row-reverse"
          >
            <span
              class="flex gap-2 w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
            >
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-red-400 hover:border-red-700 px-4 py-2 bg-red-600 text-base leading-6 font-bold text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="closePaymentPopup()"
              >
                Close
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="back()"
                v-if="isBackPossible()"
              >
                {{
                  isStepBillingEdit ||
                  isStepPaymentEdit ||
                  isStepPaymentRegister
                    ? "Cancel"
                    : "Back"
                }}
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="proceedToCheckout()"
                :disabled="couponError"
                v-if="isStepAddon"
              >
                Proceed to Checkout
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="registerCustomer()"
                v-if="isStepBillingRegister"
              >
                Register
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="updateCustomer()"
                v-if="isStepBillingEdit"
              >
                Update
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="checkout()"
                v-if="isStepBillingList"
              >
                Next
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="registerPayment()"
                v-if="isStepPaymentRegister"
              >
                Register
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                @click="updatePayment()"
                v-if="isStepPaymentEdit"
              >
                Update
              </button>
              <button
                type="button"
                class="inline-flex justify-center w-auto rounded-md border border-grey-100 hover:border-green-700 px-4 py-2 text-base leading-6 font-bold text-grey-100 shadow-sm focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 rounded-5xl"
                id="btn_subscribe"
                @click="subscribe()"
                v-if="isStepPaymentList"
              >
                Subscribe
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- / Popup -->
  </div>
</template>

<style>
.main-contentwrap {
  padding-top: 100px;
  width: calc(100% - 258px);
}
nav {
  margin-top: 10px;
}
li {
  margin-left: 0px !important;
}
.EligibleTitle {
  display: flex;
  align-items: center;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .consumptionHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #290047;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  right: 40px;
  /* left: 165px; */
  /* top: -40px; */
}

.tooltip:hover .consumptionHoverText {
  visibility: visible;
}
.tooltip .consumptionHoverText::after {
  right: 95%;
}

/* Customize the label (the container) */
.check-container {
  display: inline-block;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid black;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #290047;
}

.check-container input:disabled ~ .checkmark {
  background-color: #84888c;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

button.page-link {
  display: inline-block;
}
button.page-link {
  color: #290047;
  font-weight: 500;
}
.pagination {
  height: 36px;
}

.pagination ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.pagination li {
  display: inline;
}

.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  border: 1px solid #ddd;
  border-left-width: 0;
}

.pagination a:hover,
.active a {
  background-color: whiteSmoke;
}

.disabled a,
.disabled a:hover,
.active a,
.active a:hover {
  color: #999;
  cursor: default;
}

.disabled a:hover {
  background-color: transparent;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #2bd4c4;
  border-bottom: 6px solid #2bd4c4;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.swal2-content {
  display: flex !important;
  justify-content: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import sidebar from "@/components/sidebar.vue";
import auth from "@/helpers/auth";
import ClipLoader from "vue-spinner/src/ClipLoader";

import pageLoader from "@/components/page-loader.vue";

const axios = require("axios");
const stripe = window.Stripe(env.stripePublicKey);

export default {
  name: "godpage",
  data: function () {
    return {
      env,

      endofcreated: false,
      headers: [],
      assementheaders: [],
      searchFields: [],
      editableFields: [],
      user: this.$store.state.user,
      data: [],
      // usercount:'',
      organization: "",
      popup: false,
      askConfirmation: false,
      object: {
        email: "",
        firstname: "",
        lastname: "",
      },
      q: "",
      editUser: false,
      invite: false,
      id: "users",
      isadmin: false,
      subscriptionInfo: [],
      subscriptionCont: [],
      organizationDetail: [],
      gesi_url: "#/pricing/" + env.productFamilyIds.gesi_aid,
      api_access: env.productFamilyIds.api_data_access,

      // possible types are: text, textarea, select (add values:[]), boolean
      fields: [],
      assesmentfields: [],
      commonfields: [],
      page: 1,
      perPage: 5,
      pages: [],
      assesmentpage: 1,
      assesmentperPage: 5,
      assesmentpages: [],

      plan_list: [],
      openPlan: {},
      active: false,
      paid: false,
      gbvpaid: false,
      loaded: false,
      subscribe_any: false,
      redirect_url: "/members",
      plan_type: "",
      checkout_status: "",
      subscriptionContent: "",
      subscriptionId: "",
      api_data_access: env.productFamilyIds.api_data_access,
      sequence: 0,
      gesi_old: "",
      addons: [],
      totalPrice: 0,
      modalVisible: false,
      stepLoading: false,
      isStepAddon: false,
      isStepBillingRegister: false,
      isStepBillingList: false,
      isStepBillingEdit: false,
      isStepPaymentList: false,
      isStepPaymentEdit: false,
      isStepPaymentRegister: false,
      isStepSuccess: false,
      isStepError: false,
      popupPlan: {},
      stripeElementOptions: {},
      elements: {},
      registerBillingAddressElement: {},
      updateBillingAddressElement: {},
      paymentMethods: [],
      cardElement: {},
      clientSecret: "",
      infoModalVisible: false,
      infoModalTitle: "",
      infoModalContent: "",
      stripeCustomer: null,
      stripeSubscription: null,
      selectedPaymentMethodId: "",
      editedPaymentMethodId: "",
      editedExpiryMonth: 1,
      editedExpiryYear: 1977,
      rangeExpiryYear: [],
      errorMessage: "",
      couponCode: null,
      couponError: null,
      couponSuccess: null,
      preInvoice: null,
    };
  },
  components: {
    sidebar,
    pageLoader,
    ClipLoader,
  },
  methods: {
    remove: function (item) {
      this.object = item;
      this.askConfirmation = true;
    },
    canceled: function () {
      this.object = {};
      this.askConfirmation = false;
    },
    closePopup: function () {
      this.editUser = false;
      this.invite = false;
    },
    confirmed: function () {
      const _self = this;
      _self.$swal.fire({
        html: '<div class="flex flex-col item-center justify-center" style="align-items:center;"><div class="loader"></div><h1  class="text-2xl tracking-tighter font-semibold text-center mt-10"  style=" color: #2BD4C4 !important;"> Just a moment... </h1> <h1 class="text-md tracking-tighter font-medium text-center"  style=" color: #2BD4C4 !important;" > We are hacking equality. </h1> </div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      axios
        .delete(
          env.apiUrl +
            "/organizations/" +
            this.organization._id +
            "/members" +
            "/" +
            this.object._id,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async function () {
          _self.data.splice(_self.data.indexOf(_self.object), 1);
          _self.object = {};
          _self.q = "";
          _self.askConfirmation = false;
          _self.subscriptionInfo = await _self.getSubscriptions();
          _self.$swal.fire({
            icon: "success",
            text: "User is removed from organization.",
            showConfirmButton: true,
          });
        });
    },
    getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-GB", { month: "short" });
    },
    getHumanReadableDate(timestamp) {
      const date = new Date();
      date.setTime(timestamp);

      return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
    updateFeaturePermisison: function (user_id, field, permission) {
      const _self = this;

      _self.$swal.fire({
        html: '<div class="flex flex-col item-center justify-center" style="align-items:center;"><div class="loader"></div><h1  class="text-2xl tracking-tighter font-semibold text-center mt-10"  style=" color: #2BD4C4 !important;"> Just a moment... </h1> <h1 class="text-md tracking-tighter font-medium text-center"  style=" color: #2BD4C4 !important;" > We are hacking equality. </h1> </div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      let update_object = {
        [field]: permission,
      };

      axios
        .put(env.apiUrl + "/users/" + user_id + "/permission", update_object, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async function (res) {
          _self.$swal.close();
          if (res.data.status == false) {
            _self.editUser = false;
            _self.$swal
              .fire({
                icon: "warning",
                html: res.data.message,
                showConfirmButton: true,
                confirmButtonText: "Close",
              })
              .then(function () {
                window.location.reload();
              });
          } else {
            _self.editUser = false;
            _self.$swal.fire({
              icon: "success",
              text: "Permission updated successfully.",
              showConfirmButton: true,
            });
          }
          _self.subscriptionInfo = await _self.getSubscriptions();
        });
    },
    editAssesmentPermission: function (user_id, field, permission) {
      // const _self = this;

      let update_object = {
        [field]: permission,
      };
      axios
        .put(
          env.apiUrl + "/users/" + user_id + "/permission/assesment",
          update_object,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async function (res) {
          console.log(res);
          // if(res.data.status == false){
          //   _self.editUser = false;
          //   _self.$swal.fire({
          //     icon: 'warning',
          //     html:res.data.message,
          //     showConfirmButton: true,
          //     confirmButtonText:'Close',
          //   })
          // }
          // else{
          //     _self.editUser = false;
          //     _self.$swal.fire({
          //       icon: "success",
          //       text: "Permission updated successfully.",
          //       showConfirmButton: true,
          //     });
          // }
        });
    },
    checkSubscribeLimit: function () {
      const _self = this;
      axios
        .get(env.apiUrl + "/subscriptions/check-subscribe-limit", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async function (res) {
          if (res.data.status == false) {
            _self.editUser = false;
            _self.$swal.fire({
              icon: "warning",
              html: res.data.message,
              showConfirmButton: true,
              confirmButtonText: "Close",
            });
          }
        });
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    create: function () {
      if (this.object.firstname && this.object.lastname && this.object.email) {
        let validate = this.validEmail(this.object.email);
        if (validate) {
          const _self = this;
          this.object.email = this.object.email.trim();
          axios
            .post(
              env.apiUrl +
                "/organizations/" +
                this.organization._id +
                "/members",
              this.object,
              {
                headers: {
                  Authorization: "Bearer " + Cookies.get("session"),
                },
              }
            )
            .then(async function (res) {
              if (res.data.user) {
                _self.data.push(res.data.user);
                _self.invite = false;
                alert(res.data.message);
                _self.q = "";
                _self.subscriptionInfo = await _self.getSubscriptions();
              } else if (res.data.error) {
                alert(res.data.error);
              }
            })
            .catch((err) => {
              this.invite = false;
              this.$swal
                .fire({
                  html: `<strong style="font-size: 16px;">You've reached your subscription's user limit, but don't worry! <span id="dynamicButton" style="color: rgb(0, 119, 200);cursor: pointer;">Upgrade now </span> and welcome your team member to Equilo in just one click.</strong>`,

                  text: err.response.data.message,
                  showConfirmButton: true,
                  confirmButtonText: "Add more users now",
                })
                .then(() => {
                  this.subscriptionInfo?.productArr.map((subscriptionInfo) => {
                    if (subscriptionInfo.subscription != "Equilo Open") {
                      this.openCheckout(subscriptionInfo.subscription);
                    }
                  });
                });
              setTimeout(() => {
                const button = document.getElementById("dynamicButton");
                if (button) {
                  button.addEventListener("click", this.redirecttoupgrade);
                }
              }, 100);
            });
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Valid Email",
            text: "Please enter valid email address!",
            showConfirmButton: true,
          });
        }
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Valid Fields",
          text: "Not all fields are set.",
          showConfirmButton: true,
        });
      }
    },
    redirecttoupgrade() {
      this.$swal.close();
      this.$router.push({ path: "/upgrade" });
    },
    add: function () {
      this.object = {};
      this.invite = true;
    },
    edit: function (obj) {
      this.object = obj;
      this.editUser = true;
    },
    load: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
              "/organizations/" +
              _self.organization._id +
              "/members",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getOrganizationDetail(organization_id) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/organizations/" + organization_id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getSubscriptions() {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/subscriptions/get-subscription-info", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            _self.subscriptionCont = res.data.data.subscriptionCont;
            resolve(res.data.data);
          });
      });
    },
    setPages() {
      this.pages = [];

      let numberOfPages = Math.ceil(this.data.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    assesmentsetPages() {
      this.assesmentpages = [];
      let numberOfPages = Math.ceil(this.data.length / this.assesmentperPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.assesmentpages.push(index);
      }
    },
    assesmentpaginate(data) {
      let page = this.assesmentpage;
      let perPage = this.assesmentperPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    openCheckout(planName) {
      this.popupPlan = this.plan_list.find(
        (d) => d.productOrSubscription == planName
      );
      console.log(this.plan_list);
      this.addons = this.popupPlan.addons;
      this.addons = this.addons.map((addon) => {
        addon.quantity = 0;
        return addon;
      });
      this.addons = JSON.parse(JSON.stringify(this.addons));
      this.couponCode = null;
      this.couponError = null;
      this.couponSuccess = null;
      this.isStepAddon = true;
      this.showPopup();
    },
    showPopup() {
      this.modalVisible = true;
    },
    // async getStripeSubscription(subscriptionId) {
    //   this.stepLoading = true;
    //   axios
    //     .post(
    //       env.apiUrl + "/subscriptions/get-subscription",
    //       {
    //         subscriptionId: subscriptionId,
    //       },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + Cookies.get("session"),
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       this.stepLoading = false;
    //       if (res.data) {
    //         this.stripeSubscription = res.data.subscription;
    //       } else if (res.data.error) {
    //         console.log(res.data.error);
    //       }
    //     });
    // },
    getPlanlist: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios({
          url: env.apiUrl + "/subscriptions/get-planlist/equilo",
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
          method: "GET",
        }).then(function (res) {
          _self.subscribe_any = res.data.subscribe_any;
          _self.redirect_url = res.data.redirect_url;
          _self.plan_type = res.data.plan_type;
          _self.subscriptionContent = res.data.subscriptionContent;
          _self.subscriptionId = res.data.subscriptionId;
          _self.sequence = res.data.sequence;
          _self.gesi_old = res.data.gesi_old;

          // if (_self.subscriptionId)
          //   _self.getStripeSubscription(_self.subscriptionId);

          resolve(res.data.data);
        });
      });
    },
    closeAllStep() {
      this.isStepAddon = false;
      this.isStepBillingRegister = false;
      this.isStepBillingList = false;
      this.isStepBillingEdit = false;
      this.isStepPaymentList = false;
      this.isStepPaymentRegister = false;
      this.isStepPaymentEdit = false;
      this.isStepSuccess = false;
      this.isStepError = false;
    },
    proceedToCheckout() {
      this.closeAllStep();

      if (this.user.stripeCustomerId) {
        this.isStepBillingList = true;
        this.stepLoading = true;
        axios
          .post(
            env.apiUrl + "/subscriptions/get-customer",
            {
              customerId: this.user.stripeCustomerId,
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.stripeCustomer = res.data.customer;
              this.stepLoading = false;
            } else if (res.data.error) {
              console.log(res.data.error);
            }
          });
      } else {
        this.isStepBillingRegister = true;
        this.elements = stripe.elements(this.stripeElementOptions);
        // Create and mount the Address Element in billing mode
        this.registerBillingAddressElement = this.elements.create("address", {
          mode: "billing",
        });
        this.registerBillingAddressElement.mount(
          this.$refs.registerBillingAddressElement
        );
      }
    },
    async checkout() {
      this.stepLoading = true;
      this.closeAllStep();

      axios
        .post(
          env.apiUrl + "/subscriptions/get-payment-methods",
          {
            customerId: this.user.stripeCustomerId,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.paymentMethods = res.data.paymentMethods.data;

            if (this.paymentMethods.length) {
              this.selectedPaymentMethodId = this.paymentMethods[0].id;
              this.isStepPaymentList = true;
              this.stepLoading = false;
            } else {
              this.addPayment();
            }
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    addPayment() {
      this.closeAllStep();
      this.isStepPaymentRegister = true;
      this.stepLoading = false;
      this.elements = stripe.elements(this.stripeElementOptions);
      this.cardElement = this.elements.create("card", {
        hidePostalCode: true,
        style: {
          base: {
            color: "#32325d",
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#32325d",
            },
          },
          invalid: {
            fontFamily: "Arial, sans-serif",
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
        rules: {
          ".Tab": {
            border: "1px solid #E0E6EB",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
          },
          ".Tab:hover": {
            color: "var(--colorText)",
          },
          ".Tab--selected": {
            borderColor: "#E0E6EB",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
          },
          ".Input": {
            borderColor: "#E0E6EB",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
          },
          ".Input--invalid": {
            boxShadow:
              "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
          },
          // See all supported class names and selector syntax below
        },
      });
      this.cardElement.mount(this.$refs.cardElement);
    },
    async registerPayment() {
      this.stepLoading = false;
      await stripe
        .createPaymentMethod({
          type: "card",
          card: this.cardElement,
        })
        .then((result) => {
          if (result.error) {
            this.$refs.cardElementErrors.textContent = result.error.message;
            this.stepLoading = false;
          } else {
            axios
              .post(
                env.apiUrl + "/subscriptions/attach-payment-method",
                {
                  customerId: this.user.stripeCustomerId,
                  paymentMethodId: result.paymentMethod.id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + Cookies.get("session"),
                  },
                }
              )
              .then((res) => {
                if (res.data) {
                  this.closeAllStep();
                  this.stepLoading = false;
                  this.isStepPaymentList = true;
                  this.paymentMethods.unshift(result.paymentMethod);
                  this.selectedPaymentMethodId = result.paymentMethod.id;
                } else if (res.data.error) {
                  console.log(res.data.error);
                }
              });
          }
        });
    },
    async createSubscription() {
      this.stepLoading = true;
      let items = [
        {
          price: this.popupPlan.stripePriceId,
          quantity: 1,
          metadata: {
            type: "plan",
          },
        },
      ];
      this.addons.map((addon) => {
        items.push({
          price: addon.stripePriceId,
          quantity: addon.quantity,
          metadata: {
            type: "addon",
          },
        });
      });

      items = items.filter((item) => item.quantity > 0);

      axios
        .post(
          env.apiUrl + "/subscriptions/create-subscription",
          {
            customerId: this.user.stripeCustomerId,
            items: items,
            promoCode: this.couponCode,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          if (res.data) {
            this.subscriptionId = res.data.subscriptionId;
            this.clientSecret = res.data.clientSecret;
            this.closeAllStep();
            if (this.clientSecret) {
              await this.pay();
            } else {
              this.isStepSuccess = true;
              this.stepLoading = false;
            }
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    async updateSubscription() {
      this.stepLoading = true;
      let items = [
        {
          price: this.popupPlan.stripePriceId,
          quantity: 1,
          metadata: {
            type: "plan",
          },
        },
      ];
      this.addons.map((addon) => {
        items.push({
          price: addon.stripePriceId,
          quantity: addon.quantity,
          metadata: {
            type: "addon",
          },
        });
      });

      items = items.filter((item) => item.quantity > 0);

      axios
        .post(
          env.apiUrl + "/subscriptions/update-subscription",
          {
            subscriptionId: this.subscriptionId,
            items: items,
            promoCode: this.couponCode,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          if (res.data) {
            this.clientSecret = res.data.clientSecret;
            this.closeAllStep();

            if (this.clientSecret) {
              await this.pay();
            } else {
              this.plan_list = this.plan_list.map((d) => {
                d.is_subscribe = d._id == this.popupPlan._id ? true : false;
                this.sequence =
                  d._id == this.popupPlan._id ? d.sequence : this.sequence;
                return d;
              });
              this.stepLoading = false;
              this.subscribe_any = true;
              this.isStepSuccess = true;
            }
          } else if (res.data.error) {
            console.log(res.data.error);
          }
        });
    },
    async pay() {
      await stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: this.selectedPaymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            this.stepLoading = false;
            this.closeAllStep();
            this.isStepError = true;
            this.errorMessage = result.error.message;
          } else {
            // Successful subscription payment
            window.rewardful("convert", { email: this.stripeCustomer.email });

            this.plan_list = this.plan_list.map((d) => {
              d.is_subscribe = d._id == this.popupPlan._id ? true : false;
              this.sequence =
                d._id == this.popupPlan._id ? d.sequence : this.sequence;
              return d;
            });
            this.subscribe_any = true;
            this.closeAllStep();
            this.isStepSuccess = true;
            this.stepLoading = false;
          }
        });
    },
    async subscribe() {
      this.stepLoading = true;
      this.closeAllStep();

      if (this.subscriptionId) {
        await this.updateSubscription();
      } else {
        await this.createSubscription();
      }
    },
    getProratedPrice(priceId) {
      if (!this.preInvoice) return 0;

      let proratedPrice = 0;
      for (const data of this.preInvoice.lines.data) {
        if (data.price.id == priceId) proratedPrice += Number(data.amount);
      }

      return proratedPrice / 100;
    },
    getProratedDeduction() {
      if (!this.preInvoice) return 0;

      let proratedDeduction = 0;
      for (const data of this.preInvoice.lines.data) {
        if (Number(data.amount) < 0) proratedDeduction += Number(data.amount);
      }

      return proratedDeduction / 100;
    },
    getProratedTotal() {
      if (!this.preInvoice) return 0;

      return Number(this.preInvoice.total) / 100;
    },
    isBackPossible() {
      return !this.isStepAddon && !this.isStepSuccess && !this.isStepError;
    },
    closePaymentPopup() {
      this.modalVisible = false;
      this.closeAllStep();
    },
    back() {
      if (this.isStepBillingList) {
        this.isStepBillingList = false;
        this.isStepAddon = true;
      } else if (this.isStepBillingRegister) {
        this.isStepBillingRegister = false;
        this.isStepAddon = true;
      } else if (this.isStepBillingEdit) {
        this.isStepBillingEdit = false;
        this.isStepBillingList = true;
      } else if (this.isStepPaymentList) {
        this.isStepPaymentList = false;
        this.isStepBillingList = true;
      } else if (this.isStepPaymentRegister) {
        this.isStepPaymentRegister = false;
        this.isStepPaymentList = true;
      } else if (this.isStepPaymentEdit) {
        this.isStepPaymentEdit = false;
        this.isStepPaymentList = true;
      }
    },
  },
  computed: {
    accessFeature() {
      const searchResults = [];
      if (this.q) {
        for (var i = 0; i < this.data.length; i++) {
          for (var f = 0; f < this.searchFields.length; f++) {
            const field = this.searchFields[f];
            if (
              searchResults.indexOf(this.data[i]) == -1 &&
              this.data[i] &&
              this.data[i][field] &&
              this.data[i][field].toLowerCase().indexOf(this.q.toLowerCase()) >
                -1
            ) {
              searchResults.push(this.data[i]);
              //break;
            }
          }
        }
        return this.paginate(searchResults);
      } else {
        return this.paginate(this.data);
      }
    },
    accessAssesment() {
      const searchResults = [];
      if (this.q) {
        for (var i = 0; i < this.data.length; i++) {
          for (var f = 0; f < this.searchFields.length; f++) {
            const field = this.searchFields[f];
            if (
              searchResults.indexOf(this.data[i]) == -1 &&
              this.data[i] &&
              this.data[i][field] &&
              this.data[i][field].toLowerCase().indexOf(this.q.toLowerCase()) >
                -1
            ) {
              searchResults.push(this.data[i]);
              //break;
            }
          }
        }
        return this.assesmentpaginate(searchResults);
      } else {
        return this.assesmentpaginate(this.data);
      }
    },
  },
  watch: {
    data() {
      this.setPages();
      this.assesmentsetPages();
    },
    addons: {
      handler() {
        this.totalPrice = this.popupPlan.is_subscribe
          ? 0
          : this.popupPlan.price;
        this.addons.map((addon) => {
          this.totalPrice += addon.price * addon.quantity;
        });

        if (this.totalPrice && this.subscriptionId) this.invoicePreview();
      },
      deep: true,
    },
  },
  async mounted() {
    this.endofcreated = true;
    this.user = await auth.me().catch(() => {});
    this.organization = this.user.organization;
    this.organizationDetail = await this.getOrganizationDetail(
      this.user.organization._id
    );
    this.data = await this.load();
    this.subscriptionInfo = await this.getSubscriptions();
    this.checkSubscribeLimit();

    // this.usercount = this.data.length;

    this.endofcreated = false;
    this.plan_list = await this.getPlanlist();

    this.plan_list = this.plan_list.filter(
      (data) => data.productId != env.productIds.open
    );
    let _self = this;
    if (this.$route.query.webhook) {
      this.$swal.fire({
        html: '<div class="flex flex-col item-center justify-center" style="align-items:center;"><div class="loader"></div><h1  class="text-2xl tracking-tighter font-semibold text-center mt-10"  style=" color: #2BD4C4 !important;"> Just a moment... </h1> <h1 class="text-md tracking-tighter font-medium text-center"  style=" color: #2BD4C4 !important;" > Updating Subsciption features. </h1> </div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 5000,
      });

      setTimeout(function () {
        _self.$router.push({ name: "members" });
      }, 5000);
    }

    this.commonfields = [
      {
        id: "picture",
        name: "Picture",
        header: true,
        searchable: false,
        editable: false,
        type: "image",
      },
      {
        id: "firstname",
        name: "Firstname",
        header: true,
        searchable: true,
        editable: false,
        type: "text",
        placeholder: "John",
      },
      {
        id: "lastname",
        name: "Lastname",
        header: true,
        searchable: true,
        editable: false,
        type: "text",
        placeholder: "Doe",
      },
      {
        id: "email",
        name: "Email",
        header: true,
        searchable: true,
        editable: false,
        type: "text",
        placeholder: "John@example.com",
      },
    ];

    this.fields.push({
      id: "admin",
      name: "Admin",
      header: true,
      searchable: false,
      editable: true,
      type: "boolean",
    });
    if (this.organizationDetail.accessGesi) {
      this.fields.push({
        id: "accessGesi",
        name: "GESI Context",
        header: true,
        searchable: false,
        editable: true,
        type: "boolean",
      });
    }

    if (this.organizationDetail.access2X) {
      this.fields.push({
        id: "access2x",
        name: "2X Matchmaking Access",
        header: true,
        searchable: false,
        editable: true,
        type: "boolean",
      });
    }

    if (this.organizationDetail.accessRoi) {
      this.fields.push({
        id: "accessROIPortfolio",
        name: "Gender ROI™ Portfolio",
        header: true,
        searchable: false,
        editable: true,
        type: "boolean",
      });
    }

    if (this.organizationDetail.portfolioAccess) {
      this.fields.push({
        id: "accessPortfolio",
        name: "2X Portfolio Access",
        header: true,
        searchable: false,
        editable: true,
        type: "boolean",
      });
    }

    this.fields = this.commonfields.concat(this.fields);

    for (var i = 0; i < this.fields.length; i++) {
      if (this.fields[i].searchable) {
        this.searchFields.push(this.fields[i].id);
      }

      if (this.fields[i].header) {
        this.headers.push(this.fields[i]);
      }

      if (this.fields[i].editable) {
        this.editableFields.push(this.fields[i]);
      }
    }

    this.assesmentfields = this.commonfields.concat(this.assesmentfields);
    this.assesmentfields.push({
      id: "admin",
      name: "Admin",
      header: true,
      searchable: false,
      editable: false,
      type: "checkbox_text",
    });

    //assment
    if (this.user.twoXAssessment) {
      this.assesmentfields.push({
        id: "twoXAssessment",
        name: "2X Assessment",
        header: true,
        searchable: false,
        editable: true,
        type: "boolean",
      });
    }

    if (this.user.genderRoiAssessment) {
      this.assesmentfields.push({
        id: "genderRoiAssessment",
        name: "Gender ROI™ Assessment",
        header: true,
        searchable: false,
        editable: true,
        type: "boolean",
      });
    }

    if (this.user.gbvhRoadmapAssessment) {
      this.assesmentfields.push({
        id: "gbvhRoadmapAssessment",
        name: "GBVH Assessment",
        header: true,
        searchable: false,
        editable: true,
        type: "boolean",
      });
    }

    for (var j = 0; j < this.assesmentfields.length; j++) {
      if (this.fields[j]?.searchable) {
        this.searchFields.push(this.assesmentfields[j].id);
      }

      if (this.assesmentfields[j].header) {
        this.assementheaders.push(this.assesmentfields[j]);
      }

      if (this.assesmentfields[j].editable) {
        this.editableFields.push(this.assesmentfields[j]);
      }
    }

    if (this.user.admin) {
      this.isadmin = true;
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      { name: "Settings", url: "/settings/account" },
      { name: this.organization.name, url: "/settings/team" },
      { name: "Team members", url: "/settings/team" },
    ]);
  },
};
</script>
