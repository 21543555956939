<template>
  <div class="flex items-center justify-center space-x-2 my-4">
    <button
      @click="$emit('page-change', currentPage - 1)"
      :disabled="!hasPreviousPage"
      class="px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
    >

      Previous
    </button>
    
    <button
      v-for="page in displayedPages"
      :key="page"
      @click="$emit('page-change', page)"
      :class="[
        'px-4 py-2 text-sm font-medium border rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        currentPage === page
          ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
          : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-50'
      ]"
    >
      {{ page }}
    </button>
    
    <button
      @click="$emit('page-change', currentPage + 1)"
      :disabled="!hasNextPage"
      class="px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Next
    </button>
    
    <select
      v-model="selectedPage"
      @change="$emit('page-change', parseInt(selectedPage))"
      class="block w-20 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    >
      <option v-for="page in totalPages" :key="page" :value="page">
        {{ page }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'TailwindPagination',
  props: {
    totalItems: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    maxDisplayedPages: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      selectedPage: this.currentPage
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    displayedPages() {
      const half = Math.floor(this.maxDisplayedPages / 2);
      let start = Math.max(this.currentPage - half, 1);
      let end = Math.min(start + this.maxDisplayedPages - 1, this.totalPages);

      if (end - start + 1 < this.maxDisplayedPages) {
        start = Math.max(end - this.maxDisplayedPages + 1, 1);
      }

      return Array.from({length: end - start + 1}, (_, i) => start + i);
    }
  },
  watch: {
    currentPage(newValue) {
      this.selectedPage = newValue;
    }
  }
}
</script>
