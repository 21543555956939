<template>
  <div class="mb-20" style="width: 960px">
    <div class="flex flex-col">
      <div
        v-if="gbvrisk != true"
        class="flex flex-row"
        style="width: 100%; margin-left: 25%"
      >
        <span>Select a Theme from the dropdown menus</span>
      </div>
      <div v-if="gbvrisk != true" class="flex flex-row">
        <div
          class="flex flex-col mx-auto mt-10 w-5/12 lg:w-5/12"
          id="theme-search"
          style="width: 100%; margin-left: 15px"
        >
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
              custom-multiselect-div
            "
          >
            <svg
              class="
                absolute
                left-0
                ml-3
                h-5
                w-5
                text-gray-600
                custom-multiselect-svg
              "
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle
                cx="11.059"
                cy="11.059"
                r="7.059"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                x1="20"
                y1="20"
                x2="16.05"
                y2="16.05"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <multiselect
              v-model="selectedItem"
              @input="selectTheme"
              :options="domainWiseThemes"
              group-values="theme"
              group-label="domain"
              :group-select="false"
              placeholder="Type to search Themes"
              track-by="theme"
              label="theme"
              class="custom-pointer notranslate"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <div v-if="gbvrisk == true" class="flex flex-row">
        <div
          class="flex flex-col mx-auto mt-10 w-5/12 lg:w-5/12"
          id="domain-search"
        >
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <svg
              class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle
                cx="11.059"
                cy="11.059"
                r="7.059"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <line
                x1="20"
                y1="20"
                x2="16.05"
                y2="16.05"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <v-select
              @input="this.selectDomain"
              :value="this.selectedDomain"
              :options="this.domains"
              class="
                border-2 border-gray-200
                rounded-lg
                px-3
                py-2
                text-sm
                w-full
                pl-10
                font-medium
                focus:outline-none focus:shadow-outline
              "
              placeholder="Select a TAAP Domain"
              label="name"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="flex flex-row">
        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12"></div>
        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-world">
          <div
            class="
              text-sm text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion(null)"
            >
              World
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-africa">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('002')"
            >
              Africa
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-asia">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('142')"
            >
              Asia
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12" id="region-europe">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('150')"
            >
              Europe
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="region-south-america">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('005')"
            >
              South America
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="region-central-america">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('013')"
            >
              Central America
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="region-caribbean">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('029')"
            >
              Caribbean
            </button>
          </div>
        </div>

        <div class="flex flex-col mx-auto" id="north-america">
          <div
            class="
              text-sm
              leading-5
              text-gray-900
              w-full
              relative
              flex flex-row
              items-center
              mb-4
            "
          >
            <button
              class="
                border-2 border-gray-400
                rounded-lg
                text-gray-900
                px-3
                py-1
                text-xs
                w-full
                font-medium
                region
              "
              @click="updateRegion('021')"
            >
              North America
            </button>
          </div>
        </div>
        <div class="flex flex-col mx-auto w-1/12 lg:w-1/12"></div>
      </div>
    </div>

    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="this.loaded == false" key="loading">
        <page-loader></page-loader>
      </div>

      <div id="loaded" v-if="this.loaded == true" key="content">
        <!-- map -->
        <div id="outer" style="position: relative; height: 500px">
          <GChart
            :settings="{
              packages: ['geochart'],
              mapsApiKey: 'AIzaSyDuDv2cdmK0nNUTCrtrIrgy9PtOlBXeW84',
            }"
            type="GeoChart"
            :data="this.chartData"
            :options="this.chartOptions"
            id="global-heatmap"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import { GChart } from "vue-google-charts";
import pageLoader from "@/components/page-loader.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
    GChart,
    pageLoader,
  },

  data() {
    return {
      selectedItem: null,
      domainWiseThemes: [],
      dataset: [],
      scores: [],
      intersections: [],
      themes: [],
      domains: [],
      selected: "",
      selectedTheme: "",
      selectedDomain: "",
      loaded: false,
      chartData: [],
      chartOptions: {
        chart: {
          title: "Gender Equality Score Heatmap",
          subtitle: "",
        },
        width: '1000',
        height: '700',
        colorAxis: {},
        //height: 550,
        tooltip: {
          isHtml: true,
          trigger: "visible",
          textStyle: { fontSize: 14, fontName: "basier" },
        },
        legend: {
          numberFormat: ".##",
          textStyle: { fontSize: 12, fontName: "basier" },
        },
        region: null,
      },
    };
  },
  methods: {
    getData: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/scores/sectors/gesi/all", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
            setTimeout(function () {
              _self.loaded = true;
            }, 200);
          });
      });
    },
    getIntersections: function () {
      //const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/intersections", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getDomainWiseThemes: function () {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/intersections/domains/themes", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
    selectTheme: function () {
      this.selectedDomain = this.selectedItem.taab;
      this.selectedTheme = this.selectedItem.theme;
      this.updateDomainList(this.selectedItem.theme);
      this.selectIntersection();
    },
    selectDomain: function (value) {
      if (this.gbvrisk == true) {
        this.selectedDomain = value;
      } else {
        this.selectedDomain = value.name;
        this.updateThemeList(value.name);
      }

      this.selectIntersection();
    },
    selectIntersection: function () {
      if ((this.selectedTheme != "") & (this.selectedDomain != "")) {
        this.updateMap(this.selectedTheme, this.selectedDomain);
      }
    },
    updateThemeList: function (domain) {
      if (domain === undefined) {
        this.themes = this.intersections.filter((obj, pos, arr) => {
          return arr.map((mapObj) => mapObj.theme).indexOf(obj.theme) === pos;
        });
      } else {
        this.themes = this.intersections.filter((a) => a.taab == domain);
      }

      // deep copy
      this.themes = JSON.parse(JSON.stringify(this.themes));

      for (let i = 0; i < this.themes.length; i++) {
        this.themes[i].name = this.themes[i].theme;
      }

      this.themes.sort(function (a, b) {
        let x = a.name.toUpperCase(),
          y = b.name.toUpperCase();
        return x == y ? 0 : x > y ? 1 : -1;
      });
    },
    updateDomainList: function (theme) {
      if (this.gbvrisk == true) {
        //this.domains.unshift("Overall GBV Risk Score");
        this.domains = [
          "Overall GBV Risk Score",
          "Access to Resources",
          "Human Dignity",
          "Knowledge & Beliefs",
          "Law & Policy",
          "Power & Decision-Making",
        ];
      } else {
        if (theme === undefined) {
          this.domains = this.intersections.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj.taab).indexOf(obj.taab) === pos;
          });
        } else {
          this.domains = this.intersections.filter((a) => a.theme == theme);
        }

        // deep copy
        this.domains = JSON.parse(JSON.stringify(this.domains));

        for (let i = 0; i < this.domains.length; i++) {
          this.domains[i].name = this.domains[i].taab;
        }

        this.domains.sort(function (a, b) {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      }
    },
    resetIntersections: function () {
      this.updateDomainList();
      this.updateThemeList();
      this.selectedTheme = "";
      this.selectedDomain = "";
      this.selectedItem = "";
      this.chartData = [
        [
          "Country",
          "Gender Equality Score",
          { role: "tooltip", type: "string", p: { html: true } },
        ],
      ];
    },
    updateMap: function (theme, taab) {
      this.scores = [];
      this.dataset.forEach((a) => {
        a.intersections.filter((data) => {
          if (
            data.theme == theme &&
            data.taab == taab &&
            data.gender_equality >= 0 &&
            a.income &&
            a.ISO2
          ) {
            data["countryName"] = a.countryName;
            data["region"] = a.ISO2;
            data["income"] = a.income;
            data["value"] = data.gender_equality;
            this.scores.push(data);
          }
        });
      });
      this.chartData = [
        [
          "Country",
          "Gender Equality Score",
          { role: "tooltip", type: "string", p: { html: true } },
        ],
      ];
      this.chartOptions.colorAxis = {
        values: [0, 0.95, 1.0],
        colors: ["#FE5000", "#FAE053", "#85BB36"],
      };

      for (var i = 0; i < this.scores.length - 1; i++) {
        if (this.scores[i].value !== "Missing") {
          this.chartData.push([
            { v: this.scores[i].region, f: this.scores[i].countryName },
            parseFloat(this.scores[i].value),
            "<b>Domain: </b>" +
              this.scores[i].taab +
              "<br>" +
              "<b>Theme: </b>" +
              this.scores[i].theme +
              "<br>" +
              "<b>Gender Equality Score: </b>" +
              parseFloat(this.scores[i].value).toFixed(2),
          ]);
        }
      }
    },
    updateRegion: function (code) {
      this.chartOptions.region = code;
      this.updateMap(this.selectedTheme, this.selectedDomain);
    },
    
    removeGoogleErrors() {
      var id_root = "google-visualization-errors-all-";
        var index = 1;
        while (document.getElementById(id_root + index.toString()) != null) {
          document.getElementById(id_root + index.toString()).style.display = 'none';
          index += 2;
        } 
    }
  },
  props: [
    "intersection",
    "startDomain",
    "startTheme",
    "lockedDomain",
    "lockedTheme",
    "gbvrisk",
    "sector",
  ],
  async created() {
    // getting list of intersections for dropdown menu
    this.dataset = await this.getData();
    this.intersections = await this.getIntersections();
    this.domainWiseThemes = await this.getDomainWiseThemes();

    this.resetIntersections();

    if (this.startDomain != undefined) {
      this.selectedDomain = this.startDomain;
      this.updateThemeList(this.startDomain);
      this.selectIntersection();
    }

    if (this.startTheme != undefined) {
      this.selectedTheme = this.startTheme;
      this.updateDomainList(this.startTheme);
      this.selectIntersection();
    }

    if (this.lockedTheme != undefined) {
      this.intersections = this.intersections.filter(
        (a) => a.theme == this.lockedTheme
      );
      this.updateThemeList();
      this.updateDomainList(this.lockedTheme);
    }

    if (this.lockedDomain != undefined) {
      this.intersections = this.intersections.filter(
        (a) => a.taab == this.lockedDomain
      );
      this.updateDomainList();
      this.updateThemeList(this.lockedDomain);
    }

    //TOP, RIGHT, BOTTOM, LEFT
    //document.getElementById('global-heatmap').style = "position: absolute; width: 100%; top: -120px; clip: rect(150px, 950px, 686px, 45px);";
    //document.getElementById('global-heatmap').style = "position: absolute; width: 120%; left: -15%; top: -140px; clip: rect(160px, 1150px, 750px, 100px);";
  },mounted(){
    this.removeGoogleErrors();
  }
};
</script>

<style>
.region {
  background-color: rgba(0, 119, 200, 0.1);
}

.region:focus {
  background-color: rgba(0, 119, 200, 0.3);
  border-color: #555555;

  outline: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  padding: 8px 40px 0 40px;
}
.multiselect__option--highlight {
  background-color: #5897fb;
}
.multiselect__option--highlight::after {
  display: none;
}
.multiselect__option--highlight.multiselect__option--selected {
  background-color: #5897fb;
}
.multiselect__option--selected::after {
  display: none;
}
.multiselect__option--selected {
  font-weight: 400;
  background-color: white;
}
.multiselect__option {
  min-height: auto;
  padding: 5px 12px;
}
.multiselect__content {
  font-size: 12px;
}
.custom-multiselect-div {
  min-height: 48px;
  position: relative;
}
.custom-multiselect-svg {
  position: absolute;
  z-index: 1;
}
.custom-pointer {
  cursor: pointer;
}
.multiselect__option--disabled {
  font-weight: 900;
  font-size: 14px;
}
</style>