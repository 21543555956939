<template>
  <div class="step1-section">
    <div class="step-heading">
      <h2>Insert your portfolio/fund name</h2>
    </div>
    <div class="">
      <!-- <p class="label">Portfolio name</p> -->
      <input
        placeholder="Type a name"
        v-model="portfolioName"
        @input="emitValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Step1",
  props : ['fieldValue'],  
  data() {
    return {
      portfolioName: "",
    };
  },
  methods: {
    emitValue() {
      this.$emit("input-typed", {
        isStepActive: this.portfolioName ? true : false,
        portfolioName: this.portfolioName,
      });
    },
  },
  mounted() {
    if (this.fieldValue && this.fieldValue.portfolioName) {
      this.portfolioName = this.fieldValue.portfolioName
      this.$emit("input-typed", {
        isStepActive: true,
        portfolioName: this.portfolioName
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.step1-section {
  margin-bottom: 420px;
  .step-heading {
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #2c2e35;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .label {
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #181818;
  }
  input {
    margin-top: 8px;
    width: 616px;
    height: 48px;
    padding: 12px;
    border-radius: 8px;
    border: 1px;
    gap: 8px;
    border: 1px solid #e1e3e9;
  }
  input::placeholder {
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9ea4ba;
  }
}
</style>
