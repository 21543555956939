<template>
  <div class="map">
    <div class="map__img">
      <img src="@/assets/images/map.png" alt="" />
    </div>

    <div v-for="cont in data" :key="cont">
    <div class="tag tag--na" v-if="cont == 'North America'">
      <span class="tag__name">North America</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--lac" v-if="cont == 'Latin America & Caribbean'">
      <span class="tag__name">Latin America & Caribbean</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--we" v-if="cont == 'Western Europe'">
      <span class="tag__name">Western Europe</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--mena" v-if="cont == 'Middle East & North Africa'">
      <span class="tag__name">Middle East & North Africa</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--ssa" v-if="cont == 'Sub-Saharan Africa'">
      <span class="tag__name">Sub-Saharan Africa</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--eca" v-if="cont == 'Europe & Central Asia'">
      <span class="tag__name">Europe & Central Asia</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--eap" v-if="cont == 'East Asia & Pacific'">
      <span class="tag__name">East Asia & Pacific</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--sa" v-if="cont == 'South Asia'">
      <span class="tag__name">South Asia</span>
      <div class="arrow"></div>
    </div>
    <div class="tag tag--au" v-if="cont == 'Australia'">
      <span class="tag__name">Australia</span>
      <div class="arrow"></div>
    </div>
  </div>

  </div>
</template>

<script>
export default {
  props: {
    data: Array
  }
};
</script>

<style scoped>
.map {
  position: relative;
}

.map__img img {
  width: 100%;
}

.tag {
  position: absolute;
  padding: 2px 8px;
  border-radius: 100px;
  font-size: 13px;
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 1);
  transform: translate(-50%);
  white-space: nowrap;
}

.tag .arrow {
  width: 0;
  height: 0;
  left: 50%;
  transform: translate(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  filter: drop-shadow(0px 2px 0px rgba(255, 255, 255, 1));
}

.tag--na {
  top: 30%;
  left: 20%;
  background-color: #fed8e0;
}

.tag--na .arrow {
  border-top: 10px solid #fed8e0;
}

.tag--lac {
  top: 55%;
  left: 27%;
  background-color: #baf3e5;
}

.tag--lac .arrow {
  border-top: 10px solid #baf3e5;
}

.tag--we {
  top: 25%;
  left: 47%;
  background-color: #fed5f3;
}

.tag--we .arrow {
  border-top: 10px solid #fed5f3;
}

.tag--mena {
  top: 35%;
  left: 55%;
  background-color: #ffe5b2;
}

.tag--mena .arrow {
  border-top: 10px solid #ffe5b2;
}

.tag--ssa {
  top: 50%;
  left: 50%;
  background-color: #cbeefd;
}

.tag--ssa .arrow {
  border-top: 10px solid #cbeefd;
}

.tag--eca {
  top: 17%;
  left: 60%;
  background-color: #ffdcd1;
}

.tag--eca .arrow {
  border-top: 10px solid #ffdcd1;
}

.tag--eap {
  top: 32%;
  left: 85%;
  background-color: #ccf6be;
}

.tag--eap .arrow {
  border-top: 10px solid #ccf6be;
}

.tag--sa {
  top: 53%;
  left: 75%;
  background-color: #c8dbfd;
}

.tag--sa .arrow {
  border-top: 10px solid #c8dbfd;
}

.tag--au {
  top: 69%;
  left: 84%;
  background-color: #eadefc;
}

.tag--au .arrow {
  border-top: 10px solid #eadefc;
}
</style>
