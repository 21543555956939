<template>
  <div class="static">
    <div style="height: 4rem"></div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  "
                >
                  <!-- <img
                  class="h-16 print:h-auto print:w-64"
                  src="@/assets/images/2X-assessment/new2X-assessment.png"
                  /> -->
                  <h1
                    class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    "
                  >
                    MATCH MAKING PageS terms of use
                  </h1>
                </div>
                <div
                  class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  "
                ></div>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  1. About our terms
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  1.1. These terms and conditions of use (<b>Terms</b>) explain
                  how you may use the “Match Making” part of this website and
                  any of the content of those pages (<b>Match Making Pages</b>).
                  These Terms apply between <b>Equilo Inc</b>. (<b
                    >we, us, our</b
                  >) and you, the person accessing or using the Match Making
                  Pages (<b>you, your</b>) and shall take precedence over any
                  other agreement between us relating to the Match Making Pages.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  1.2. You should read these Terms carefully before using the
                  Match Making Pages. We will also seek your agreement to these
                  Terms before permitting you to access the Match Making Pages.
                  By using the Match Making Pages or otherwise indicating your
                  agreement to these Terms you agree to be bound by these Terms.
                  If you do not agree with any of these Terms, you should stop
                  using the Match Making Pages immediately.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  1.3. The Match Making Pages are provided by us to you free of
                  charge for information purposes only.
                </p>
                <br />
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  2. About us
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2.1. We are <b>Equilo Inc.</b> Our address is 1658 N.
                  Milwaukee Ave. Suite 100-45, Chicago, IL 60647, USA.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2.2. If you have any questions about the Match Making Pages,
                  please contact us by:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    2.2.1. sending an email to
                    <a href="mailto:dataprotection@equilo.io" target="_blank">
                      dataprotection@equilo.io</a
                    >; or
                  </li>
                  <li>
                    2.2.2. filling out and submitting the online form available
                    <a
                      href="https://airtable.com/shrPHiikjM1eP75cm"
                      target="_blank"
                    >
                      here</a
                    >.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  2.3. The Match Making Pages have been developed by us in
                  collaboration with <b>2X Global Limited</b>, a company
                  registered in England and Wales under company registration
                  number 13697512. Their registered office is at Crowe U.K. LLP,
                  55 Ludgate Hill, London, England, EC4M 7JW (2X). However, the
                  Match Making Pages are provided by us and your contract to use
                  them is with us and not 2X. 2X shall have no liability to you
                  in respect of your use of the Match Making Pages or the
                  content of them.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  3. Using the deals pages
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  3.1. We permit you to use the Match Making Pages for your own
                  (or your organisation’s own internal) research purposes. Use
                  of the Match Making Pages, or the information contained in
                  them, in any other way (including any unacceptable use, as set
                  out below) is not permitted.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  3.2. You agree that you are solely responsible for all costs
                  and expenses you may incur in relation to your use of the
                  Match Making Pages.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  3.3. If you choose to access the Match Making Pages from
                  locations outside the UK, you acknowledge that you do so at
                  your own initiative and are responsible for compliance with
                  local laws where they apply.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  3.4. We try to make the Match Making Pages as accessible as
                  possible. If you have any difficulties using the Match Making
                  Pages, please contact us using the contact details at the top
                  of this page.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  4. Unacceptable use
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  4.1. As a condition of your use of the Match Making Pages, you
                  agree not to:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    4.1.1. misuse or attack our Match Making Pages by knowingly
                    introducing viruses, trojans, worms, logic bombs or any
                    other material which is malicious or technologically harmful
                    (such as by way of a denial-of-service attack), or
                  </li>
                  <li>
                    4.1.2. attempt to gain unauthorised access to our Match
                    Making Pages, the server on which our Match Making Pages are
                    stored or any server, computer or database connected to our
                    Match Making Pages.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  4.2. As a condition of your use of the Match Making Pages, you
                  agree not to use the Match Making Pages:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    4.2.1. for any purpose that is unlawful under any applicable
                    law or prohibited by these Terms;
                  </li>
                  <li>4.2.2.to commit any act of fraud;</li>
                  <li>
                    4.2.3. to distribute viruses or malware or other similar
                    harmful software code;
                  </li>
                  <li>
                    4.2.4. for purposes of promoting unsolicited advertising or
                    sending spam;
                  </li>
                  <li>
                    4.2.5. to simulate communications from us or another service
                    or entity in order to collect identity information,
                    authentication credentials, or other information
                    (‘phishing’);
                  </li>
                  <li>
                    4.2.6. in any manner that disrupts the operation of our
                    Deals Pages or business or the website or business of any
                    other entity;
                  </li>
                  <li>
                    4.2.7. to represent or suggest that we endorse any other
                    business, product or service unless we have separately
                    agreed to do so in writing;
                  </li>
                  <li>
                    4.2.8. to gain unauthorised access to or use of computers,
                    data, systems, accounts or networks; or
                  </li>
                  <li>
                    4.2.9. to attempt to circumvent password or user
                    authentication methods.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  4.3. We may prevent or suspend your access to the Match Making
                  Pages if you do not comply with these Terms or any applicable
                  law.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  5. Your privacy and personal information
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  Your privacy and personal information are important to us. Any
                  personal information that you provide to us will be dealt with
                  in line with our Privacy Policy available at
                  <a href="/#/investorsPrivacyPolicy" target="_blank">
                    app.equilo.io/#/investorsPrivacyPolicy</a
                  >, which explains what personal information we collect from
                  you, how and why we collect, store, use and share such
                  information, your rights in relation to your personal
                  information and how to contact us and supervisory authorities
                  in the event you have a query or complaint about the use of
                  your personal information.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  6. Ownership, use and intellectual property rights
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  6.1. The intellectual property rights in the Match Making
                  Pages and in any text, images, video, audio or other
                  multimedia content, software or other information or material
                  accessible from the Match Making Pages (<b>Content</b>) are
                  owned by us and our licensors.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  6.2. We and our licensors reserve all our intellectual
                  property rights (including, but not limited to, all copyright,
                  trade marks, domain names, design rights, database rights,
                  patents and all other intellectual property rights of any
                  kind) whether registered or unregistered anywhere in the
                  world. This means, for example, that we remain owners of them
                  and are free to use them as we see fit.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  6.3. Nothing in these Terms grants you any legal rights in the
                  Match Making Pages or the Content other than as necessary for
                  you to access it. You agree not to adjust, try to circumvent
                  or delete any notices contained on the Match Making Pages or
                  the Content (including any intellectual property notices) and
                  in particular, in any digital rights or other security
                  technology embedded or contained within the Match Making Pages
                  or the Content.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  6.4. The Equilo logos are our trademarks. The 2X logos are
                  2X's trademarks. Other trade marks and trade names may also be
                  used on the Match Making Pages or in the Content. Use by you
                  of any trade marks, logos or trade names on the Match Making
                  Pages or in the Content is strictly prohibited unless you have
                  our prior written permission.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  7. Accuracy of information and availability of the site
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  7.1. You acknowledge and agree that:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    7.1.1. the Match Making Pages include information about
                    organisations (<b>Organisation Information</b>);
                  </li>
                  <li>
                    7.1.2. such Organisation Information is provided by the
                    organisations themselves and is not verified in any way by
                    either 2X or Equilo;
                  </li>
                  <li>
                    7.1.3. similarly, should such Organisation Information be
                    stated or indicated to be verified by a third party, then
                    such third party has also not been verified by either 2X or
                    Equilo;
                  </li>
                  <li>
                    7.1.4. the Organisation Information does not constitute
                    technical, financial or legal advice or any other type of
                    advice and should not be relied on for any purposes; and
                  </li>
                  <li>
                    7.1.5. you will undertake your own verification of any
                    Organisation Information and before making any investment
                    decision regarding any Investee you shall make such
                    enquiries and carry out such investigations as you consider
                    appropriate.
                  </li>
                </ul>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  7.2. We may suspend or terminate access or operation of the
                  Match Making Pages at any time as we see fit.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  7.3. While we try to make sure that the Match Making Pages are
                  available for your use, we do not promise that the Match
                  Making Pages will be available at all times or that your use
                  of the Match Making Pages will be uninterrupted.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  7.4. You acknowledge that the Match Making Pages have not been
                  approved by an authorised person. Accordingly, they are made
                  available to, and directed only at: (i) investment
                  professionals; (ii) high net worth companies, unincorporated
                  associations or other persons; or (iii) any other person to
                  whom they may otherwise be lawfully communicated (all such
                  persons together being referred to as (<b>Relevant Persons</b
                  >) and should not be relied or acted upon by any other
                  persons. Any investment or investment activity to which these
                  2X Assessment Deals relate is available only to Relevant
                  Persons or will be engaged in only with such persons. If you
                  are not a Relevant Person please contact
                  <a href="mailto: dataprotection@equilo.io" target="_blank">
                    dataprotection@equilo.io</a
                  >
                  and do not act upon the information contained in the Deal
                  Pages.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  8. Limitation on our liability
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  8.1. The Match Making Pages and the material and information
                  provided on the Match Making Pages is provided on an "as-is"
                  and "as available" basis without warranty of any kind. All
                  users are responsible for checking the accuracy, completeness
                  and fitness for purpose of all advice and other information
                  provided on the Match Making Pages before relying on it.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  8.2. To the maximum extent permitted by applicable law, we and
                  the third parties connected to us exclude:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    8.2.1. all representations, warranties or conditions,
                    express or implied, statutory or otherwise that may apply to
                    the Match Making Pages including, but not limited to,
                    representations, warranties or conditions about the
                    information or other content published on the Match Making
                    Pages (including, but not limited to, the Organisation
                    Information); and
                  </li>
                  <li>
                    8.2.2. all liability and responsibility for any amount or
                    kind of loss or damage resulting from or related to the use
                    of or inability to use the Match Making Pages (including,
                    but not limited to, the Organisation Information). In
                    particular, we will not be liable for any loss of data,
                    income, profit or opportunity, or any indirect or
                    consequential loss or special damages even if such loss or
                    damages were reasonably foreseeable.
                  </li>
                </ul>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  8.3. It is agreed that our maximum aggregate liability under
                  or in connection with your use of the Deal Pages (including,
                  but not limited to, the Organisation Information), whether for
                  negligence, breach of contract, misrepresentation or
                  otherwise, shall in no event exceed £10,000.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  8.4. Nothing in this Agreement shall operate to exclude or
                  limit any person's liability for:
                </p>
                <ul
                  class="text-gray-600 print:text-4xl print:mt-10 font-medium"
                >
                  <li>
                    8.4.1. death or personal injury resulting from its
                    negligence within the meaning of section 1(1) of the Unfair
                    Agreement Terms Act 1977;
                  </li>
                  <li>
                    8.4.2. its fraud of the fraud of any of its employees or
                    agents; or
                  </li>
                  <li>
                    8.4.3. any liability that cannot be excluded or restricted
                    under applicable law.
                  </li>
                </ul>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  9. Events beyond our control
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  We are not liable to you if we fail to comply with these Terms
                  because of circumstances beyond our reasonable control.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  10. Rights of third parties
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  No one other than 2X, or a party to these Terms, has any right
                  to enforce any of these Terms. The parties will not require
                  the consent of 2X to rescind or vary this agreement.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  1. Variation
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  11.1. No changes to these Terms are valid or have any effect
                  unless agreed by us in writing or made in accordance with this
                  clause <span style="background-color: #dcdcdc">11</span>.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  11.2. We reserve the right to vary these Terms from time to
                  time. Our updated Terms will be displayed on the Match Making
                  Pages and by continuing to use and access the Match Making
                  Pages following such changes, you agree to be bound by any
                  variation made by us. It is your responsibility to check these
                  Terms from time to time to verify such variations.
                </p>
                <br />
                <h1
                  class="text-3xl tracking-tighter font-medium print:text-7xl"
                >
                  12. Disputes
                </h1>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  12.1. We will try to resolve any disputes with you quickly and
                  efficiently. If you are unhappy with us, please contact us as
                  soon as possible using the contact details set out at the top
                  of this page.
                </p>
                <br />
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
                  12.2. The laws of England and Wales apply to these Terms. Any
                  disputes will be subject to the non-exclusive jurisdiction of
                  the courts of England and Wales.
                </p>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "dealsTermsOfUse",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      userEmail: null,
      isReadMore: false,
    };
  },
  components: {
    pageLoader,
  },
  methods: {},
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "2X Global Data Privacy",
        url: "/dataprivacy",
      },
    ]);
    this.endofcreated = true;
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
  },
};
</script>

<style scoped>
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}
</style>
<style scoped>
.heading-li {
  font-size: 20px;
}
li {
  font-size: 16px;
}
a {
  color: #40a9ff;
}
</style>
