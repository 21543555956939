<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <p
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-left: 285px"
      ></p>
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px"
      />
    </div>
    <transition name="fadeIn" mode="out-in">
      <div id="loaded" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <CookiePolicyContent />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CookiePolicyContent from "@/components/CookiePolicyContent.vue";
export default {
  name: "cookiesPolicy",
  data: function () {
    return {};
  },
  components: {
    CookiePolicyContent,
  },
  async created() {
    this.$store.commit("setBreadcrumbs", [
      {
        name: "Cookies Policy",
        url: "/cookies-policy",
      },
    ]);
  },
};
</script>

<style scoped>
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}
.heading-li {
  font-size: 20px;
}
li {
  font-size: 16px;
}
.table-space td {
  padding: 10px 80px 10px 0px;
}
.a-tag-decorate {
  color: #40a9ff;
}
</style>
