<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    :style="'background-color:' + env.brandingColors.navy"
  >
    <div class="max-w-md w-full">
      <div>
        <h2
          class="mt-6 text-center text-3xl leading-9 font-extrabold text-white"
        >
          <img
            class="h-20 m-auto my-10"
            src="@/assets/images/logo.png"
            alt="logo equilo"
          />
          Reset password
        </h2>
        <p class="mt-2 text-center text-sm leading-5 text-white">
          Share your e-mail and a reset link will be sent to you.
        </p>
      </div>
      <div class="mt-8">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm">
          <div>
            <input
              aria-label="Email address"
              name="email"
              type="email"
              required
              class="appearance-none rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="E-mail"
              v-model="input.email"
              :class="{
                'border-red-600': error,
                'animate__animated animate__shakeX': error_animation,
              }"
            />
          </div>
        </div>

        <div class="mt-6">
          <button
            type="submit"
            @click="requestNewPassword"
            :style="'background-color: ' + env.brandingColors.teal"
            :disabled="isDisabled"
            :class="{
              'cursor-not-allowed': isDisabled,
            }"
            class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md leading-5 font-medium rounded-md text-white hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          >
            <span class="absolute right-0 inset-y-0 flex items-center pr-3">
              <svg
                class="h-8 w-8 text-indigo-700 group-hover:text-indigo-400 transition ease-in-out duration-150"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M10,16l4,-4l-4,-4"
                  stroke-linecap="round"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linejoin="round"
                ></path>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </span>
            Send reset link
          </button>

          <center class="mt-10 text-white">
            <router-link to="/login"> Back to login</router-link>
          </center>
        </div>
        <div class="mt-3" v-if="error">
          <div class="text-red-500 text-xs animate__animated animate__fadeIn">
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import users from "@/helpers/users";
import env from "@/../env.js";
export default {
  name: "reset_password",
  data() {
    return {
      error: false,
      env,
      error_animation: false,
      input: {
        email: "",
      },
    };
  },
  methods: {
    async requestNewPassword() {
      const validate = this.validEmail(this.input.email);
      if (this.input.email && validate) {
        try {
          const res = await users.requestNewPassword(this.input.email);
          if (res && res.data) {
            if (res.data.error) {
              this.$swal.fire({
                icon: "warning",
                text: res.data.message,
                showConfirmButton: true,
              });
            }
            if (res.data.success) {
              this.$swal.fire({
                icon: "success",
                text: "Please check your inbox.",
                showConfirmButton: true,
              });
            }
          }
        } catch (e) {
          this.e = e;
          this.$swal.fire({
            icon: "warning",
            text: "This Email Address is not found in our database.",
            showConfirmButton: true,
          });
        }
      } else {
        this.error = "Please enter a valid email address.";
        this.error_animation = true;
        setTimeout(() => {
          this.error = "";
        }, 2000);
      }
    },
    validEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  computed: {
    isDisabled() {
      return !this.input.email;
    },
  },
};
</script>
