var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8",style:('background-color:' + _vm.env.brandingColors.navy)},[_c('div',{staticClass:"max-w-md w-full"},[_vm._m(0),_c('div',{staticClass:"mt-8"},[_c('input',{attrs:{"type":"hidden","name":"remember","value":"true"}}),_c('div',{staticClass:"rounded-md shadow-sm"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.email),expression:"input.email"}],staticClass:"appearance-none rounded-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5",class:{
              'border-red-600': _vm.error,
              'animate__animated animate__shakeX': _vm.error_animation,
            },attrs:{"aria-label":"Email address","name":"email","type":"email","required":"","placeholder":"E-mail"},domProps:{"value":(_vm.input.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.input, "email", $event.target.value)}}})])]),_c('div',{staticClass:"mt-6"},[_c('button',{staticClass:"group relative w-full flex justify-center py-3 px-4 border border-transparent text-md leading-5 font-medium rounded-md text-white hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out",class:{
            'cursor-not-allowed': _vm.isDisabled,
          },style:('background-color: ' + _vm.env.brandingColors.teal),attrs:{"type":"submit","disabled":_vm.isDisabled},on:{"click":_vm.requestNewPassword}},[_c('span',{staticClass:"absolute right-0 inset-y-0 flex items-center pr-3"},[_c('svg',{staticClass:"h-8 w-8 text-indigo-700 group-hover:text-indigo-400 transition ease-in-out duration-150",attrs:{"version":"1.1","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('path',{attrs:{"d":"M10,16l4,-4l-4,-4","stroke-linecap":"round","stroke-width":"1.5","stroke":"currentColor","fill":"none","stroke-linejoin":"round"}}),_c('path',{attrs:{"fill":"none","d":"M0,0h24v24h-24Z"}})])]),_vm._v(" Send reset link ")]),_c('center',{staticClass:"mt-10 text-white"},[_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" Back to login")])],1)],1),(_vm.error)?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"text-red-500 text-xs animate__animated animate__fadeIn"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"mt-6 text-center text-3xl leading-9 font-extrabold text-white"},[_c('img',{staticClass:"h-20 m-auto my-10",attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo equilo"}}),_vm._v(" Reset password ")]),_c('p',{staticClass:"mt-2 text-center text-sm leading-5 text-white"},[_vm._v(" Share your e-mail and a reset link will be sent to you. ")])])
}]

export { render, staticRenderFns }