<template>
    <div class="impact-level-container">
        <div class="inner-impact-level-container">
            <div class="impact-top-section">
                <div class="heading">
                    <h3>Impact levels</h3>
                </div>
                <div class="text-content">
                    <p>By choosing one of the four possible assessment levels, you define and shape your own journey towards impact. For each level, Equilo offers tailored assessments to evaluate your portfolio's performance in gender equality, ESG, climate, impact or finance.</p>
                </div>
            </div>
            <div class="levels-of-impact">
                <div class="impact-level-inner" v-for="level in levels" :key="level.text" :style="'background:' + level.background+ '; border: 1px solid'+level.border">
                    <div class="image-heading flex">
                        <img :src="require('./../../assets/images/IMM/'+level.icon)"/>
                        <h3>{{ level.heading }}</h3>
                    </div>
                    <div class="inner-text-content">
                        <p>{{ level.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ImpactLevels",
    data() {
        return {
            levels: [
                {
                    heading: "Compliance",
                    text: "Assess the extent to which the investment target or portfolio aligns with minimum standards or regulations",
                    icon: "checklist.svg",
                    background: "#FFECE8",
                    border: "#CB2E00"
                },
                {
                    heading: "Risk",
                    text: "Assess the risks associated with the investment target or portfolio including but not limited to the negative externalities caused",
                    icon: "filled.svg",
                    background: "#FFF8E0",
                    border: "#E8AD17"
                },
                {
                    heading: "Performance",
                    text: "Assess how well an investment target or portfolio performs based on it's actions, policies, strategies, targets, practices or initiatives ",
                    icon: "arrow-up.svg",
                    background: "#E5FAFA",
                    border: "#13A094"
                },
                {
                    heading: "Impact",
                    text: "Assess whether an investment target or portfolio has achieved the intended long-term effects or benefits brought about for beneficiaries, stakeholders or the environment",
                    icon: "target.svg",
                    background: "#EAF6FF",
                    border: "#2177C1"
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.impact-level-container {
    .inner-impact-level-container {
        .impact-top-section {
            width: 948px;
            height: 132px;
            gap: 16px;
            .heading {
                h3 {
                    font-family: Montserrat;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #2C2E35;
                }
            }
            .text-content {
                margin-top: 16px;
                p {
                    font-family: Arial;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }
        .levels-of-impact {
            width: 948px;
            gap: 24px;
            margin-top: 24px;
            .impact-level-inner {
                width: 948px;
                padding: 12px 17px 12px 17px;
                border-radius: 16px;
                gap: 4px;
                margin-bottom: 24px;
                .image-heading {
                    gap: 12px;
                    h3 {
                        font-family: Arial;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #2C2E35;
                    }
                }
                .inner-text-content {
                    margin-top: 4px;
                    p {
                        font-family: Arial;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #2C2E35;
                    }
                }
            }
        }
    }
}
</style>