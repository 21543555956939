<template>
  <div class="static assementPageStyle">
    <div class="container">
       <div class="PoweredbyTitle_logo">
        <div class="PoweredbyTitleWrepper">
          <h1 class="text-6xl tracking-tighter font-medium print:text-7xl">
            Support and Feedback
          </h1>
        </div>
        <div class="powerd_invite-wrapper">
          <div class="powerdby_logo_wrapper">
            <div class="Poweredby_logoImg">
              <span class="powerd-logo">Powered by:</span>
              <img src="@/assets/images/logo-navy.png" alt="background-image" />
            </div>
          </div>
        </div>
      </div>
      <transition name="fadeIn" mode="out-in">
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
        <div id="loaded" key="content">
          <div class="support-feedback-wrapper">
              <div class="Profile_Block">
                 <div class="user_block">
                    <img src="@/assets/images/support_feedback/user.svg" />
                </div>
                <div class="overviewtitle-left">
                  <h2>
                   Get Help
                  </h2>
                  <span></span>
                </div>
                <div class="get-help">
                  <div class="get-help-content">
                    <b>Having troubles with the Equilo platform?</b>
                    <br /><br />
                    <b>We are sorry about this. The Equilo team is here to help!</b>
                    <br /><br />
                    <b>Please tell us as much detail about your problem as possible so we can help. Whether you have an issue with logging in, subscription management, access to content, data or other issues, please let us know so we can solve your problem.</b>
                    <br /><br/>
                    <a href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red" target="_blank">
                      <div class="flex send-user">
                          <img src="@/assets/images/support_feedback/Path.svg" class="mr-2"/>
                          <b>Send User Support Ticket</b>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="profile-feedback">
                <div class="overviewtitle-right">
                  <h2>Provide Feedback on Equilo's Tools</h2>
                  <span></span>
                </div>
                <div class="profile-body">
                    <b>We appreciate you taking a moment of your time to provide feedback on Equilo's tools to support gender equality and social inclusion. Your feedback and insights will help us to improve! </b>
                    <br /><br />
                    <b>Feel free to enter your details if you would like us to get in touch with you, or skip those to remain anonymous.</b>
                    <br /><br />
                    <b>Please also let us know what you might be interested in for advanced analytics tools, and check out some of our offerings for those tools at equilo.io!</b>
                    <br /><br/>
                </div>
                <div class="tool-block grid grid-cols-3 gap-6">
                    <a href="https://airtable.com/embed/shrWy5dAIwfTnSBVx?backgroundColor=purple" target="_blank" class="tool-block-content">
                        <img src="@/assets/images/support_feedback/open-source-tool.svg" class="tool-img"/>
                        <span>Provide Feedback for Open-source Tools</span>
                    </a>
                    <a href="https://airtable.com/embed/shrZKKxEv31pKr31x?backgroundColor=purple" target="_blank" class="tool-block-content">
                        <img src="@/assets/images/support_feedback/context-analysis-tool.svg"/>
                        <span>Provide Feedback for GESI Contextual Analysis Tools</span>
                    </a>
                    <a href="https://airtable.com/embed/shrLs6ZA6tdZsyHsJ?backgroundColor=purpleLight" target="_blank" class="tool-block-content">
                        <img src="@/assets/images/support_feedback/gender-lens-tool.svg"/>
                        <span>Provide Feedback for 2X Gender Lens Investment Tools</span>
                    </a>
                    <a href="https://airtable.com/shrwLpbGTYawS4fYj" target="_blank" class="tool-block-content">
                        <img src="@/assets/images/support_feedback/ROI-tool.svg"/>
                        <span>Provide Feedback for Gender ROI™ Assessment Tools</span>
                    </a>
                </div>
              </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import pageLoader from "@/components/page-loader.vue";
export default {
    name: "partner-directory",
    data: function() {
     return {
         endofcreated:false
     }
    },
    components: {
      pageLoader
    },
    async created(){
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
                  { name: "Support Feedback", url: "/support-feedback" },
            ]);
        this.endofcreated=true;
    }
};
</script>

<style lang="scss" scoped>
.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

  .container {
    margin: 0 auto;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;
      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }
    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;
      
      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .support-feedback-wrapper {
    margin-top: 30px;
    padding-bottom: 60px;
    display: flex;
    
      .Profile_Block {
        background: rgb(32, 23, 71);
        border-radius: 10px;
        padding: 40px;
        max-width: 430px;
        width: 100%;
          
        .user_block {
          width: 30%;
          margin: auto;
          display: block;
          margin-bottom: 10px;
        }
        .overviewtitle-left {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
      
            h2 {
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fff;
              margin-right: 10px;
              position: relative;
              width: 250px;
            }
      
            span {
              display: inline-block;
              border-bottom: 2px solid #fff;
              width: 485px;
              height: 2px;
            }
          }
        .get-help {
          .get-help-content {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #fff;
            margin-bottom: 30px;

            b {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 16px;
              color: #fff;
            }
            
            .send-user b{
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 16px;
              color: #fff;
            }
          }
        }
      }

      .profile-feedback {
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;
        max-width: 830px;
        width: 100%;
        margin-left: auto;
        border: 1px solid #D0D0D0;
        .overviewtitle-right {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
      
            h2 {
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #1b1b1b;
              margin-right: 10px;
              position: relative;
              width: 1264px;
            }
      
            span {
              display: inline-block;
              border-bottom: 2px solid #1b1b1b;
              width: 100%;
              height: 2px;
            }
        }
        
        .profile-body{
            b {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 16px;
            color: #1b1b1b;
          }
        }
        
        .tool-block{
          .tool-block-content{
            .tool-img{
              margin-top: 30px;
            }
            
            img {
              margin: auto;
              display: block;
              margin-bottom: 10px;
            }
            
            span {
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 16px;
              color: #1B1B1B;
              display: block;
              text-align: center;
            }
          }
        }
      }
  }
}

</style>
