<template>
  <footer class="footer w-full bg-[#261d44] bottom-0 text-white">
    <div class="footer-content py-4 flex justify-between mx-10 items-center">
      <span class="copyright w-1/2">© Equilo {{ currentYear }}</span>
      <div class="flex w-1/2 justify-end gap-5">
        <div class="social-links items-center ">
          <p class="cursor-pointer text-[#fff] text-lg font-bold"
          v-if="$route.path.includes('/2X-Certification')"
          @click="$emit('open2xDisclaimer')">
            <u>Disclaimer</u>
          </p>
          <a
            v-for="(link, index) in socialLinks"
            :key="index"
            :href="link.url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="link.icon" :alt="link.name" class="social-icon" />
          </a>
        </div>
        <a
          href="https://airtable.com/shryJ1jKTDYU9vQcG"
          target="_blank"
          rel="noopener noreferrer"
          class="subscribe-button"
        >
          Subscribe to Newsletter
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      socialLinks: [
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com/company/equilo/",
          icon: "https://static.wixstatic.com/media/48a2a42b19814efaa824450f23e8a253.png/v1/fill/w_42,h_42,al_c,usm_0.66_1.00_0.01,enc_auto/48a2a42b19814efaa824450f23e8a253.png",
        },
        {
          name: "Facebook",
          url: "https://www.facebook.com/equilo.io",
          icon: "https://static.wixstatic.com/media/e316f544f9094143b9eac01f1f19e697.png/v1/fill/w_42,h_42,al_c,usm_0.66_1.00_0.01,enc_auto/e316f544f9094143b9eac01f1f19e697.png",
        },
        {
          name: "Twitter",
          url: "https://twitter.com/equilo_io",
          icon: "https://static.wixstatic.com/media/59687ffffc2042f885062ce2b0744381.png/v1/fill/w_42,h_42,al_c,usm_0.66_1.00_0.01,enc_auto/59687ffffc2042f885062ce2b0744381.png",
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/equilo.io",
          icon: "https://static.wixstatic.com/media/9f9c321c774844b793180620472aa4f1.png/v1/fill/w_42,h_42,al_c,usm_0.66_1.00_0.01,enc_auto/9f9c321c774844b793180620472aa4f1.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
.copyright {
  font-size: 20px;
}

.social-links {
  display: flex;
  gap: 10px;
}

.social-icon {
  width: 42px;
  height: 42px;
  object-fit: contain;
  object-position: 50% 50%;
}

.subscribe-button {
  background-color: #0076bb;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
}
</style>
