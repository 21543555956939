<template>
  <div class="flex flex-col relative">
    <div
      class="w-1/3 pb-4"
      :style="{
        cursor: chartTypeArray.length === 0 ? 'not-allowed' : 'pointer',
        opacity: chartTypeArray.length === 0 ? '0.5' : '1',
      }"
      v-if="this.indicator.chartData[0].data.length > 1 && modal"
    >
      <label for="chartTypes">Chart Types</label>
      <select
        :disabled="chartTypeArray.length === 0"
        v-model="chart"
        class="w-full px-2 py-1 placeholder-gray-700 text-gray-900 rounded-md focus:outline-none focus:shadow-outline border-2 border-gray-500"
        @change="logDataModel"
      >
        <option
          v-for="chartType in chartTypeArray"
          :key="chartType.value"
          :value="chartType.value"
        >
          {{ chartType.name }}
        </option>
      </select>
    </div>

    <div>
      <doughnutChart
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        v-if="
          indicator.chartType === 'doughnutChart' &&
          countryData.countries.length == 1
        "
      >
      </doughnutChart>
      <doughnutChart-multiple
        class="print:scale-75 transform radarchart"
        :index="index + 1"
        :countryD="countryData.countries"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :chartLabelsForCharts="chartLabelsForCharts"
        :indicator="indicator"
        v-if="
          indicator.chartType == 'doughnutChart' &&
          countryData.countries.length > 1
        "
      >
      </doughnutChart-multiple>

      <checkCrossChart
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        v-else-if="
          indicator.chartType === 'checkcross' &&
          countryData.countries.length == 1
        "
      >
      </checkCrossChart>
      <!--  <bubbleChart
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        v-else-if="
          indicator.chartType === 'bubbleChart' &&
          countryData.countries.length == 1
        "
      >
      </bubbleChart> -->
      <!--  <barChartVertical
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        :button="false"
        v-else-if="
          indicator.chartType === 'barChartVertical' &&
          countryData.countries.length == 1
        "
      >
      </barChartVertical> -->

      <!--NEW-->
      <d3BarChartVertical
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        :button="false"
        :modal="modal"
        v-else-if="
          this.chart == 'barVertical' && countryData.countries.length == 1
        "
      >
      </d3BarChartVertical>

      <plot-bar-chart
        :id="indicator._id"
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        :button="false"
        :plot-color-schemes="plotColorSchemes"
        :modal="modal"
        v-if="
          this.chart === 'plotBarVertical' && countryData.countries.length == 1
        "
      ></plot-bar-chart>
      <plot-radial-chart
        :id="indicator._id"
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        :button="false"
        :plot-color-schemes="plotColorSchemes"
        :modal="modal"
        v-if="this.chart == 'plotRadar' && countryData.countries.length == 1"
      ></plot-radial-chart>

      <plot-scatter-chart
        :id="indicator._id"
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        :button="false"
        :plot-color-schemes="plotColorSchemes"
        :modal="modal"
        v-if="this.chart == 'plotScatter' && countryData.countries.length == 1"
      ></plot-scatter-chart>

      <plot-bubble-chart
        :id="indicator._id"
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        :button="false"
        :plot-color-schemes="plotColorSchemes"
        :modal="modal"
        v-if="this.chart == 'plotBubble' && countryData.countries.length == 1"
      ></plot-bubble-chart>

      <!-- <div
        v-if="
          indicator.chartData.length > 5 &&
          indicator.chartType == 'barChartVertical'
        "
      >
        <div
          v-for="(countr, contIndex) in domainDataUnfiltered.countries"
          :key="'countr' + contIndex"
        >
          <div
            style="
              font-size: 20px;
              font-weight: 600;
              text-align: center;
              margin: 15px;
            "
          >
            {{ countr.humanName }}
          </div>
          <barChartVertical
            class="print:scale-75 transform radarchart"
            :index="index + 1"
            :country="countryData.countries[contIndex]"
            :chartData="countr.indicators[index].chartData"
            :chartLabels="countr.indicators[index].chartLabels"
            :indicator="countr.indicators[index]"
            v-if="
              countr.indicators[index].chartType == 'barChartVertical' &&
              countryData.countries.length > 1
            "
          >
          </barChartVertical>
        </div>
      </div>
      <barChartVertical-multiple
        class="print:scale-75 transform radarchart"
        :index="index + 1"
        :countryD="countryData.countries"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :chartLabelsForCharts="chartLabelsForCharts"
        :indicator="indicator"
        v-if="
          indicator.chartType == 'barChartVertical' &&
          countryData.countries.length > 1 &&
          indicator.chartData.length < 6 &&
          indicator.chartLabels[0] == '{{country}}'
        "
      >
      </barChartVertical-multiple>
      <barChartVertical-multiple-for-one-variable
        class="print:scale-75 transform radarchart"
        :index="index + 1"
        :countryD="countryData.countries"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :chartLabelsForCharts="chartLabelsForCharts"
        :indicator="indicator"
        v-else-if="
          indicator.chartType == 'barChartVertical' &&
          countryData.countries.length > 1 &&
          indicator.chartData.length < 6 &&
          indicator.chartLabels[0] != '{{country}}' &&
          (indicator.chartLabels.length == 1 ||
            indicator.chartLabels.length == 2)
        "
      >
      </barChartVertical-multiple-for-one-variable>
      <div v-else>
        <div
          v-if="
            indicator.chartType == 'barChartVertical' &&
            countryData.countries.length > 1 &&
            indicator.chartData.length < 6
          "
        >
          <div
            v-for="(countr, contIndex) in domainDataUnfiltered.countries"
            :key="'countr1' + contIndex"
          >
            <div
              style="
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                margin: 15px;
              "
            >
              {{ countr.humanName }}
            </div>
            <barChartVertical
              class="print:scale-75 transform radarchart"
              :index="index + 1"
              :country="countryData.countries[contIndex]"
              :chartData="
                countr.indicators[index]
                  ? countr.indicators[index].chartData
                  : ''
              "
              :chartLabels="
                countr.indicators[index]
                  ? countr.indicators[index].chartLabels
                  : ''
              "
              :indicator="countr.indicators[index]"
            >
            </barChartVertical>
          </div>
        </div>
      </div> -->

      <!--     <radarChart
        :index="index + 1"
        :country="domainDataUnfiltered.countries[0]"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        v-if="
          indicator.chartType === 'radarChart' &&
          countryData.countries.length == 1
        "
      >
      </radarChart> -->
      <!-- <radarChart-multiple
        class="print:scale-75 transform radarchart"
        :index="index + 1"
        :countryD="countryData.countries"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :chartLabelsForCharts="chartLabelsForCharts"
        :indicator="indicator"
        v-if="
          indicator.chartType == 'radarChart' &&
          countryData.countries.length > 1
        "
      >
      </radarChart-multiple>

      <bubbleChart
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        v-else-if="
          indicator.chartType === 'bubbleChart' &&
          countryData.countries.length == 1
        "
      >
      </bubbleChart>
      <div
        v-if="
          indicator.chartData.length > 1 && indicator.chartType == 'bubbleChart'
        "
      >
        <div
          v-for="(countr, contIndex) in domainDataUnfiltered.countries"
          :key="'countr2' + contIndex"
        >
          <div
            style="
              font-size: 20px;
              font-weight: 600;
              text-align: center;
              margin: 15px;
            "
          >
            {{ countr.humanName }}
          </div>
          <bubbleChart
            class="print:scale-75 transform radarchart"
            :index="index + 1"
            :country="countryData.countries[contIndex]"
            :chartData="countr.indicators[index].chartData"
            :chartLabels="countr.indicators[index].chartLabels"
            :indicator="countr.indicators[index]"
            v-if="
              indicator.chartType == 'bubbleChart' &&
              countryData.countries.length > 1
            "
          >
          </bubbleChart>
        </div>
      </div>
      <bubbleChart-multiple
        class="print:scale-75 transform radarchart"
        :index="index + 1"
        :countryD="countryData.countries"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :chartLabelsForCharts="chartLabelsForCharts"
        :indicator="indicator"
        v-if="
          indicator.chartType == 'bubbleChart' &&
          countryData.countries.length > 1 &&
          indicator.chartData.length == 1
        "
      >
      </bubbleChart-multiple>

      <checkCrossChart
        :index="index + 1"
        :country="country"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        v-else-if="
          indicator.chartType === 'checkcross' &&
          countryData.countries.length == 1
        "
      >
      </checkCrossChart>
      <div
        v-if="
          indicator.chartData.length > 1 && indicator.chartType == 'checkcross'
        "
      >
        <div
          v-for="(countr, contIndex) in domainData.countries"
          :key="'countr3' + contIndex"
        >
          <div
            style="
              font-size: 20px;
              font-weight: 600;
              text-align: center;
              margin: 15px;
            "
          >
            {{ countr.humanName }}
          </div>
          <checkCrossChart
            class="print:scale-75 transform radarchart"
            :index="index + 1"
            :country="countryData.countries[contIndex]"
            :chartData="countr.indicators[index].chartData"
            :chartLabels="countr.indicators[index].chartLabels"
            :indicator="countr.indicators[index]"
            v-if="
              indicator.chartType == 'checkcross' &&
              countryData.countries.length > 1
            "
          >
          </checkCrossChart>
        </div>
      </div>
      <checkCrossChart-multiple
        class="print:scale-75 transform radarchart"
        :index="index + 1"
        :countryD="countryData.countries"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :chartLabelsForCharts="chartLabelsForCharts"
        :indicator="indicator"
        v-if="
          indicator.chartType == 'checkcross' &&
          countryData.countries.length > 1 &&
          indicator.chartData.length == 1
        "
      >
      </checkCrossChart-multiple>

      <tierChart
        :index="index + 1"
        :country="domainDataUnfiltered.countries[0]"
        :chartData="indicator.chartData"
        class=""
        :chartLabels="indicator.chartLabels"
        :indicator="indicator"
        v-else-if="
          indicator.chartType === 'tierChart' &&
          countryData.countries.length == 1
        "
      >
      </tierChart>
      <tierChart-multiple
        class="print:scale-75 transform radarchart"
        :index="index + 1"
        :countryD="countryData.countries"
        :chartData="indicator.chartData"
        :chartLabels="indicator.chartLabels"
        :chartLabelsForCharts="chartLabelsForCharts"
        :indicator="indicator"
        v-if="
          indicator.chartType == 'tierChart' && countryData.countries.length > 1
        "
      >
      </tierChart-multiple> -->

      <!-- <label
        class="recentlyUpdatedLabel"
        v-if="
          indicator.meta && checkRecentlyUpdated(indicator.meta.createdTime)
        "
      >
        Recently updated!
      </label>

      <div class="histData w-full">
        <historicalData
          v-if="countryData.countries.length == 1"
          :indicator="indicator"
          :country="country"
        ></historicalData>
        <p
          v-if="
            (indicator.unknownHist > 0) &
            (indicator.unknownHist != indicator?.variables?.length)
          "
          class="text-sm mt-4"
        >
          <i>No historical data available for the other indicators.</i>
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";

//import barChartVerticalMultipleForOneVariable from "@/components/charts/barChartVertical-multiple-for-one.vue";
//import barChartVerticalMultiple from "@/components/charts/barChartVertical-multiple.vue";
import doughnutChartMultiple from "@/components/charts/doughnutChart-multiple.vue";
//import radarChartMultiple from "@/components/charts/radarChart-multiple.vue";
//import bubbleChartMultiple from "@/components/charts/bubbleChart-multiple.vue";
//import checkCrossChartMultiple from "@/components/charts/checkCrossChart-multiple.vue";
//import tierChartMultiple from "@/components/charts/tierChart-multiple.vue";
//import tierChart from "@/components/charts/tierChart.vue";
//import barChartVertical from "@/components/charts/barChartVertical.vue";
import doughnutChart from "@/components/charts/doughnutChart.vue";
//import radarChart from "@/components/charts/radarChart.vue";
//import bubbleChart from "@/components/charts/bubbleChart.vue";
import checkCrossChart from "@/components/charts/checkCrossChart.vue";
//import historicalData from "@/components/historical-data.vue";

import d3BarChartVertical from "@/components/D3-Charts/d3-barChartVertical.vue";
import plotBarChart from "@/components/D3-Charts/plot-barChartVertical.vue";
import plotRadialChart from "@/components/D3-Charts/plot-radialChart.vue";
import plotScatterChart from "@/components/D3-Charts/plot-scatterChart.vue";
import plotBubbleChart from "@/components/D3-Charts/plot-bubbleChart.vue";

export default {
  name: "indicator-charts",
  components: {
    d3BarChartVertical,
    //barChartVertical,
    doughnutChart,
    //radarChart,
    //bubbleChart,
    checkCrossChart,
    //tierChart,
    //barChartVerticalMultipleForOneVariable,
    //barChartVerticalMultiple,
    doughnutChartMultiple,
    //radarChartMultiple,
    //bubbleChartMultiple,
    //checkCrossChartMultiple,
    //tierChartMultiple,
    //historicalData,
    plotBarChart,
    plotRadialChart,
    plotScatterChart,
    plotBubbleChart,
  },
  props: [
    "country",
    "countryData",
    "index",
    "indicator",
    "chartLabelsForCharts",
    "domainDataUnfiltered",
    "modal",
  ],
  data() {
    return {
      chartChanged: false,
      chart: "",
      chartTypeArray: [
        { name: "Radar", value: "plotRadar" },
        { name: "Bar - Vertical", value: "plotBarVertical" },
        { name: "Scatter", value: "plotScatter" },
        { name: "Bubble", value: "plotBubble" },
      ],
      plotColorSchemes: [
        { name: "Rainbow", value: "rainbow" },
        { name: "Purples", value: "purples" },
        { name: "Spectral", value: "spectral" },
        { name: "Blue to Red", value: "BuRd" },
        { name: "Pastel 1", value: "pastel1" },
        { name: "Red to Blue", value: "RdBu" },
      ],
    };
  },
  methods: {
    checkRecentlyUpdated(createdTime) {
      if (!createdTime) return false;
      return moment().diff(moment(createdTime), "months", true) <= 3;
    },
    setDefault() {
      switch (this.indicator.chartType) {
        case "barChartVertical":
          this.chart = "plotBarVertical";
          break;
        case "radarChart":
          this.chart = "plotRadar";
          break;
        case "bubbleChart":
          this.chart = "plotBubble";
          break;
        default:
          break;
      }
    },
    logDataModel(newVal) {
      console.log(newVal.target.value);
    },
  },
  mounted() {
    this.setDefault();
  },
  watch: {
    chart(newVal) {
      console.log("CHART", newVal);
      this.$emit("chartChanged", newVal);
    },
  },
};
</script>
