<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-32">
      <!--Loader-->
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <!--Logo-->
      <div class="PoweredbyTitle_logo" v-if="endofcreated">
        <div class="PoweredbyContentWrepper">
          <img
            src="@/assets/images/2X-Global_logo_transparent.svg"
            alt="background-image"
            style="height: 50px"
          />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <!-- Content Wrepper -->
      <div class="ContentWrapper w-12/12" v-if="endofcreated">
        <div class="container mx-auto">
          <steps-component-twox
            :currentStep="current_step"
            :userStatus="status2xCertification"
          />
          <!-- <div class="flex flex-col mx-auto w-11/12 mt-12 mb-12">
            <a-steps
              progress-dot
              :current="this.current_step"
              size="small"
              @change="progressClick"
            >
              <a-step
                title="Step 1"
                description="2X Challenge Self-Assessment"
              />
              <a-step
                title="Step 2"
                description="2X Challenge Self-Assessment Results"
              />
              <a-step
                title="Step 3"
                description="2X Certification Self-Assessment"
              />
              <a-step
                title="Step 4"
                description="2X Certification Self-Assessment Results "
              />
              <a-step title="Step 5" v-if="user.status2xCertification == 'Validation in Process'" description="Upload Documents" />
              <a-step title="Step 5" v-else description="Third Party Verification" />
              <a-step title="Step 6" description="2X Global Certification" />
              <a-step title="Step 7" description="Take Action" />
            </a-steps>
          </div> -->
        </div>
        <div class="container items-center mb-8">
          <h1
            class="text-6xl tracking-tighter font-medium print:text-7xl"
            style="color: #21191d; max-width: 845px"
          >
            <span>2X Global Certification</span>
          </h1>
          <div class="mt-2 text-lg font-semibold text-gray-500" v-if="dateOfSubmission">
            Submission Date: {{ moment(dateOfSubmission).format("DD MMMM, YYYY") }}
          </div>
          <div
            v-if="false"
            class="flex gap-4 items-center mt-2 text-sm"
            style="color: #21191d"
          >
            <span style="font-weight: bold"
              >Entity Type:
              <span style="font-weight: normal">Start-Up</span></span
            >
            <span style="font-weight: bold"
              >Country:
              <span style="font-weight: normal">{{
                this.organizationDetail.countryName
              }}</span></span
            >
            <span style="font-weight: bold"
              >Sector:
              <span style="font-weight: normal">Infrastructure</span></span
            >
          </div>
        </div>
        <div class="congrats-box-global p-8 mb-6 mt-8 relative">
          <div class="flex justify-between">
            <div class="flex gap-4 items-center">
              <h3 class="text-xl tracking-tighter font-bold">
                Entity Type:
              </h3>
              <p class="text-lg font-medium">
                {{ entityDisplay[entityChosen] }}
              </p>
            </div>
            <div class="flex gap-4 items-center">
              <h3 class="text-xl tracking-tighter font-bold">
                Country:
              </h3>
              <p class="text-lg font-medium">
                {{ certificationScoreData?.GEN_General_11 }}
              </p>
            </div>
            <div class="flex gap-4 items-center">
              <h3 class="text-xl tracking-tighter font-bold">
                Sector:
              </h3>
              <p class="text-lg font-medium">
                {{ certificationScoreData?.GEN_General_13 }}
              </p>
            </div>
          </div>
        </div>
        <div class="congrats-box-global flex mb-6 items-center mt-8">
          <div
            class="w-6/12 p-10"
            :style="'background-color: ' + certification_result.lightColor + '; min-height: 420px'"
          >
            <h3 class="text-3xl tracking-tighter font-bold">
              Congratulations! <br />
              Your certification is {{ certification_result.name }}
            </h3>
            <img
              v-if="certification_result.name == 'Good'"
              class="w-48 mx-auto mt-9"
              src="@/assets/images/2x_certify/2x_certification_tier_good.svg"
              width="40%"
            />
            <img
              v-if="certification_result.name == 'Advanced'"
              class="w-48 mx-auto mt-9"
              src="@/assets/images/2x_certify/2x_certification_tier_advanced.svg"
              width="40%"
            />
            <img
              v-if="certification_result.name == 'Best in Class'"
              class="w-48 mx-auto mt-9"
              src="@/assets/images/2x_certify/2x_certification_tier_bestinclass.svg"
              width="40%"
            />
          </div>
          <div class="w-6/12 p-10">
            <div class="flex justify-between">
              <img src="@/assets/images/2x_certify/2xglobal_logo.svg" />
              <img
                class="cursor-pointer"
                src="@/assets/images/2x_certify/share-button.svg"
                @click="isShareModalOpen = true"
              />
            </div>
            <h3 class="text-xl tracking-tighter font-bold mt-4 mb-6">
              What does this mean?
            </h3>
            <p class="text-sm" style="color: #2c2e35">
              <span v-if="certification_result.name == 'Best in Class'">
                The Best in Class level certification recognizes that the entity
                is taking broad and deep action to comprehensively drive gender
                equality impact across 2X Dimensions.Explore your full results
                and action plan to identify strategic ways you can deepen your
                impact even more.
              </span>
              <span v-if="certification_result.name == 'Advanced'">
                The Advanced Level certification recognizes that you are
                exceeding the minimum 2X Certification Thresholds and advancing
                in your gender equality impact maturity. Explore your full
                results and action plan to identify strategic ways you can
                deepen your impact and reach the next and highest level of Best
                in Class.
              </span>
              <span v-if="certification_result.name == 'Good'">
                The Good Level certification recognizes that you are meeting the
                minimum 2X Certification Thresholds in Place. Explore your full
                results and action plan to identify strategic ways you can
                deepen your impact and advance to higher certification levels.
              </span>
              <br />
              <span style="font-weight: bold"
                >For the Best in Class certification the following is
                required:</span
              ><br />
            </p>

            <ul style="list-style-type: disc; margin-left: 16px">
              <li>
                Meet all Basic 2X ESG Screen requirements, AND meet all 2X
                governance threshold criteria, AND meet all thresholds across
                all 2X Dimensions and all themes.
              </li>
              <li>
                Funds will be further assessed on the proportion of portfolio
                companies that meet these requirements by year, with a minimum
                threshold of 30%.
              </li>
            </ul>

            <div class="download-buttons mt-8 flex items-center">
              <img
                class="cursor-pointer"
                src="@/assets/images/2x_certify/blue-download-icon.svg"
                @click="isDonwloadModalOpen = true"
              />
              <!-- <button
                class="gesi-contextual-button text-sm lg:text-base"
                @click="
                  $router.push({ path: '2X-Ecosystem/My-2X-Assessments' })
                "
              >
                2X GESI Contextual analysis
              </button> -->
              <button
                class="gender-action-plan-button text-sm lg:text-base ml-3"
                @click="
                  $router.push({
                    path: '/2X-Ecosystem/2X-Certification/score-card',
                  })
                "
              >
                See your 2X Certification Scorecard
              </button>
            </div>
          </div>
        </div>
        <ThemeBadgeBox
          class="mb-11"
          :cert_categories="cert_categories"
          :certificationScoreData="certificationScoreData"
          :displayArrowButton="true"
        >
        </ThemeBadgeBox>

        <OverviewBoxCertification
          :domainChartData="domainChartData"
          :dimensions="dimensions"
          :srManagerThreshold="srManagerThreshold"
          :entityChosen="entityChosen"
          :workforceThreshold="workforceThreshold"
          :boardThreshold="boardThreshold"
          :certificationScoreData="certificationScoreData"
          :entrepreneurshipPercent="entrepreneurshipPercent"
          :leadershipPercent="leadershipPercent"
          :employmentPercent="employmentPercent"
          :productServicePercent="productServicePercent"
          :supplyChainPercent="supplyChainPercent"
          :portfolioPercent="portfolioPercent"
          :govStrategicAction="govStrategicAction"
          :govManagementSystem="govManagementSystem"
          :govData="govData"
          :govChartData="govChartData"
          :assessmentYear="certificationScoreData.assessmentYear"
          :isEntOwnEligible="isEntOwnEligible"
          :isSupplyChainEligible="isSupplyChainEligible"
          :isProductsEligible="isProductsEligible"
          :isEmploymentEligible="isEmploymentEligible"
          :isLeadershipEligible="isLeadershipEligible"
        >
        </OverviewBoxCertification>
      </div>
      <br />
    </div>
    <!-- Download Modal Start -->
    <div class="modal" v-if="isDonwloadModalOpen">
      <!-- Modal content -->
      <div class="modal-content">
        <div>
          <h2 class="font-bold text-2xl" style="">Download</h2>
        </div>
        <div class="download-kit-details my-6">
          <div
            class="inner-download-kit-details flex justify-between items-center"
          >
            <div class="text">Social media material kit</div>
            <div>Coming soon...</div>
            <!-- <img
              class=""
              src="@/assets/images/2x_certify/blue-download-icon.svg"
            /> -->
          </div>
          <div
            class="inner-download-kit-details flex justify-between items-center"
          >
            <div class="text">Certification Scorecard</div>
            <div v-if="downloadPDF">Downloading...</div>
            <img v-else @click="downloadScoreCardPDF()"
              class="cursor-pointer"
              src="@/assets/images/2x_certify/blue-download-icon.svg"
            />
          </div>
          <!-- <div
            class="inner-download-kit-details flex justify-between items-center"
          >
            <div class="text">Certificate in PNG</div>
            <img
              class=""
              src="@/assets/images/2x_certify/blue-download-icon.svg"
            />
          </div> -->
          <!-- <div
            class="inner-download-kit-details flex justify-between items-center"
          >
            <div class="text">Certificate in PDF</div>
            <img
              class=""
              src="@/assets/images/2x_certify/blue-download-icon.svg"
            />
          </div> -->
          <div
            class="inner-download-kit-details flex justify-between items-center"
          >
            <div class="text">Badge in JPEG</div>
            <img @click="downloadBadgePng()"
              class="cursor-pointer"
              src="@/assets/images/2x_certify/blue-download-icon.svg"
            />
          </div>
          <div
            class="inner-download-kit-details flex justify-between items-center"
          >
            <div class="text">Badge in PDF</div>
            <a :href="levelPdfUrl" target="_blank" download="filename.extension">
              <img
                class="cursor-pointer"
                src="@/assets/images/2x_certify/blue-download-icon.svg"
              />
            </a>
          </div>
          <div
            class="inner-download-kit-details flex justify-between items-center"
          >
            <div class="text">Download data in Excel</div>
            <img @click="downloadXlsx()"
              class="cursor-pointer"
              src="@/assets/images/2x_certify/blue-download-icon.svg"
            />
          </div>
        </div>
        <div class="action-button-section">
          <div class="flex button-section">
            <button class="cancel-button" @click="isDonwloadModalOpen = false">
              Cancel
            </button>
            <button class="save-button" @click="downloadZipFile()">
              {{ downloadZIP ? 'Downloading...' : 'Download all (ZIP)' }}
              <img v-if="!downloadZIP" src="@/assets/images/download-white.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Download Modal End -->

    <!-- Share Modal Start -->
    <div v-if="isShareModalOpen">
      <ShareModal @close="isShareModalOpen = false"></ShareModal>
    </div>
    <!-- Share Modal End -->

    <!-- Accredited Modal Start -->
    <div v-if="isAccreditedModalOpen">
      <AccreditedCertificationPartnerMOdal
        @close="isAccreditedModalOpen = false"
      ></AccreditedCertificationPartnerMOdal>
    </div>
    <!-- Accredited Modal End -->
    <div class="pdfexportbtn">
      <ScoreCardPrint
        v-if="endofcreated"
        :user="user"
        :srManagerThreshold="srManagerThreshold"
        :boardThreshold="boardThreshold"
        :workforceThreshold="workforceThreshold"
        :thresholdsData="thresholdsData"
        :thresholds="thresholds"
        :entityChosen="entityChosen"
        :certificationSurveyData="certificationSurveyData"
        :certificationScoreData="certificationScoreData"
        :cert_categories="cert_categories"
        :dimensions="dimensions"
        :certification_result="certification_result"
        :governanceColors="governanceColors"
        :themes="themes"
        :domainChartData="domainChartData"
        :entrepreneurshipPercent="entrepreneurshipPercent"
        :leadershipPercent="leadershipPercent"
        :employmentPercent="employmentPercent"
        :productServicePercent="productServicePercent"
        :supplyChainPercent="supplyChainPercent"
        :portfolioPercent="portfolioPercent"
        :govStrategicAction="govStrategicAction"
        :govManagementSystem="govManagementSystem"
        :govData="govData"
        :govChartData="govChartData"
        :endofcreated="endofcreated"
        :themesScores="themesScores"
        :isSupplyChainEligible="isSupplyChainEligible"
        :isProductsEligible="isProductsEligible"
        :isEmploymentEligible="isEmploymentEligible"
        :isLeadershipEligible="isLeadershipEligible"
        :isEntOwnEligible="isEntOwnEligible"
        :downloadPDF="downloadPDF"
        :selectedOption="selectedOption"
        :status2xCertification="status2xCertification"
        :entity="entity"
        id="scoreCardPdf"
      ></ScoreCardPrint>
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import ShareModal from "@/components/ShareModal.vue";
import AccreditedCertificationPartnerMOdal from "@/components/AccreditedCertificationPartnerMOdal.vue";
import OverviewBoxCertification from "@/components/overviewBoxCertification.vue";
import ThemeBadgeBox from "@/components/themeBadgesBox.vue";
import StepsComponentTwox from '../../components/StepsComponentTwox.vue';
import apiService from '@/helpers/apiServices.js';
import { saveAs } from 'file-saver';
import ScoreCardPrint from "@/pages/reports/my2xCertification-2XScoreCard-print.vue";
import html2pdf from "html2pdf.js";
import JSZip from 'jszip';

export default {
  name: "twoxCertifyGlobalCertification",
  data: function () {
    return {
      current_step: 3,
      certification_result: {},
      cert_categories: {
        Good: {
          name: "Good",
          darkColor: "#FF3A21",
          lightColor: "#FFECE8",
        },
        Advanced: {
          name: "Advanced",
          darkColor: "#FCC30B",
          lightColor: "#FFF8E0",
        },
        "Best in Class": {
          name: "Best in Class",
          darkColor: "#4C9F38",
          lightColor: "#E5FAFA",
        },
      },
      governanceColors: {
        strategicAction: "#4A742C",
        managementSystems: "#2574BB",
        data: "#F05323",
      },
      themes: [
        { name: "GBVH", percent: 13, color: "#19486A", left: 48, top: 3 },
        { name: "Health & Well-being", percent: 56, color: "#666461", left: 76, top: 68 },
        {
          name: "JEDI",
          percent: 85,
          color: "#2574BB",
          left: 65,
          top: 85,
        },
        { name: "Care", percent: 55, color: "#FF3A21", left: 30, top: 85 },
        { name: "Pay", percent: 30, color: "#8F210D", left: 20, top: 35 },
      ],
      themesScores: [],
      downloadPDF: false,
      selectedOption : "redacted",
      entity: "",
      downloadZIP: false,
      dimensions: {
        Entrepreneurship: {
          color: "#395AFF",
          icon: "@/assets/images/2x_certify/entrepreneurship.svg",
          score: 0,
          threshold: 100,
        },
        Leadership: {
          color: "#3F7E44",
          icon: "@/assets/images/2x_certify/leadership.svg",
          score: 0,
          threshold: 100,
        },
        Employment: {
          color: "#DDA63A",
          icon: "@/assets/images/2x_certify/employment.svg",
          score: 0,
          threshold: 100,
        },
        Products: {
          color: "#8F210D",
          icon: "@/assets/images/2x_certify/products.svg",
          score: 0,
          threshold: 100,
        },
        SupplyChain: {
          color: "#19486A",
          icon: "@/assets/images/2x_certify/supplychain.svg",
          score: 0,
          threshold: 100,
        },
        Governance: {
          color: "#DDA63A",
          icon: "@/assets/images/2x_certify/governance.svg",
          score: 0,
          threshold: 100,
        },
        Portfolio: {
          color: "#7C7C7C",
          icon: "@/assets/images/2x_certify/portfolio.svg",
          score: 0,
          threshold: 100,
        },
      },
      srManagerThreshold: "",
      boardThreshold: "",
      workforceThreshold: "",
      thresholdsData: [],
      thresholds: [],
      industrySearchPlaceholder: "",
      selectedIndustries: [],
      selectedCountries: [],
      entityChosen: "",
      isDonwloadModalOpen: false,
      isShareModalOpen: false,
      isAccreditedModalOpen: false,
      certificationSurveyData: [],
      certificationScoreData: [],
      badgeLevelColors: {
        Good: "#FFECE8",
        "Best in class": "#E5FAFA",
        Advanced: "#FFF8E0",
      },
      domainChartData: [],
      entrepreneurshipPercent: "",
      leadershipPercent: "",
      employmentPercent: "",
      productServicePercent: "",
      supplyChainPercent: "",
      portfolioPercent: "",
      govStrategicAction: "",
      govManagementSystem: "",
      govData: "",
      govChartData: [],
      endofcreated: false,
      user: [],
      isSupplyChainEligible: "",
      isProductsEligible: "",
      isEmploymentEligible: "",
      isLeadershipEligible: "",
      isEntOwnEligible: "",
      status2xCertification: "",
      csvData: [],
      levelPdfUrl: "",
      levelImageUrl: "",
      entityDisplay: {
        'start-up': 'Start up',
        'corporate': 'Corporate',
        'sme': 'SME',
        'fund-manager': 'Fund Manager',
        'financial-institution': 'Financial Institution',
      },
      dateOfSubmission: null,
    };
  },
  components: {
    pageLoader,
    ShareModal,
    AccreditedCertificationPartnerMOdal,
    OverviewBoxCertification,
    ThemeBadgeBox,
    StepsComponentTwox,
    ScoreCardPrint,
  },
  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    numberWithCommas(x) {
      var numFormat = new Intl.NumberFormat("en-US");
      return numFormat.format(x);
    },
  },
  methods: {
    async downloadZipFile() {
      this.downloadZIP = true;
      const zip = new JSZip();

      // Add images to zip
      const imageResponse = await axios.get(this.levelImageUrl, {
          responseType: 'arraybuffer',
        });

      const imageBlob = new Blob([imageResponse.data], { type: 'image/jpeg' });
      zip.file('badge-image.jpg', imageBlob);

      // Add pdf to zip
      const pdfUrl = this.levelPdfUrl;
      const pdfUrlresponse = await axios.get(pdfUrl, {
          responseType: 'blob',
        });

      const pdfBlob = new Blob([pdfUrlresponse.data], { type: 'application/pdf' });
      zip.file('badge-pdf.pdf', pdfBlob);

      // Convert HTML to PDF and add to zip
      var pagebreak = { before: ".before_page" };
        let htmlElement = document.getElementById("scoreCardPdf");
        const scoreCardBlob = await html2pdf()
          .set({
            margin: 1,
            filename: "score-card.pdf",
            pagebreak: pagebreak,
            html2canvas: {
              logging: false,
              dpi: 192,
              letterRendering: true,
              useCORS: true,
              allowTaint: true,
            },
            jsPDF: {
              orientation: "portrait",
              unit: "cm",
              format: [60, 35],
            },
          })
          .from(htmlElement)
          .outputPdf('blob');
        this.downloadPDF = false;

      zip.file('score-card.pdf', scoreCardBlob);

      // Add xlsx file
      zip.file('data.xlsx', this.csvData);

      // Generate zip file
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      saveAs(zipBlob, this.certificationScoreData?.team_id?.name +'.zip');
      this.downloadZIP = false;
    },
    async downloadScoreCardPDF() {
      this.downloadPDF = true;
      var pagebreak = { before: ".before_page" };
      setTimeout(async () => {
        let htmlElement = document.getElementById("scoreCardPdf");
        await html2pdf()
          .set({
            margin: 1,
            filename: "score-card.pdf",
            pagebreak: pagebreak,
            html2canvas: {
              logging: false,
              dpi: 192,
              letterRendering: true,
              useCORS: true,
              allowTaint: true,
            },
            jsPDF: {
              orientation: "portrait",
              unit: "cm",
              format: [60, 35],
            },
          })
          .from(htmlElement)
          .save();
        this.downloadPDF = false;
        return;
      }, 1000);
    },
    downloadBadgePng() {
      let imagePath;
      if(this.certificationScoreData.level == 'Good') {
        imagePath = require('@/assets/images/2X_Certification_Good_Badges-01.jpg');
      }
      if(this.certificationScoreData.level == 'Best in Class') {
        imagePath = require('@/assets/images/2X_Certification_BestInClass_Badges-03.jpg');
      }
      if(this.certificationScoreData.level == 'Advanced') {
        imagePath = require('@/assets/images/2X_Certification_Advanced_Badges-02.jpg');
      }
      const link = document.createElement('a');
      link.href = imagePath;
      link.download = 'badge.jpg';
      link.click();
      if(link) {
        document.body?.removeChild(link);
      }
    },
    fetchCsvData() {
      let url = "";
      const _self = this;
      if (this.$route.query?.teamId) {
        url =
          "/certify2x/export-data?teamId=" +
          this.$route.query?.teamId +
          "&assessmentType=" +
          (this.$store.state.certPageAccess.isFullSurveyFill
            ? "Full"
            : "Min") +
          "&tag=" +
          this.entityChosen;
      } else {
        url =
          "/certify2x/export-data?teamId=" +
          this.user?.organization?._id +
          "&assessmentType=" +
          (this.$store.state.certPageAccess.isFullSurveyFill
            ? "Full"
            : "Min") +
          "&tag=" +
          this.entityChosen;
      }
      return axios
        .get(env.apiUrl + url, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(function (res) {
          _self.csvData = res.data;
        }).catch(function (res) {
          console.error("Error fetching CSV data:", res);
        });
    },
    downloadXlsx() {
      saveAs(this.csvData, this.certificationScoreData?.team_id?.name + '_2x' +' Assessment Results.XLSX');
    },
    calculateCertLevel() {
      if (this.entityChosen == "start-up") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "sme") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "corporate") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (
        this.entityChosen == "Fund Manager" ||
        this.entityChosen == "fund-manager"
      ) {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_403"];
      } else if (this.entityChosen == "financial-institution") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_404"];
      }
    },
    async fetchCertificationData() {
      await apiService.fetch2xCertificationData(this.entityChosen, this.$route?.query?.teamId, this.$store.state.certPageAccess.isFullSurveyFill ? 'Full' : 'Min')
        .then(async (res) => {
          this.certificationScoreData = res.data.data;
          await this.calculateCertLevel();
          await this.calculateThreshold();
          if (this.certificationScoreData.level == "Good") {
            this.certification_result = this.cert_categories.Good;
          } else if (
            this.certificationScoreData.level == "Best in class" ||
            this.certificationScoreData.level == "Best in Class"
          ) {
            this.certification_result = this.cert_categories["Best in Class"];
          } else if (this.certificationScoreData.level == "Advanced") {
            this.certification_result = this.cert_categories.Advanced;
          }
          this.themesScores = [
            Math.round(this.certificationScoreData['2X_2X_Score_Overall_511']),
            Math.round(this.certificationScoreData['2X_2X_Score_Overall_508']),
            Math.round(this.certificationScoreData['2X_2X_Score_Overall_507']),
            Math.round(this.certificationScoreData['2X_2X_Score_Overall_510']),
            Math.round(this.certificationScoreData['2X_2X_Score_Overall_506'])
          ]
          if (this.entityChosen == "fund-manager") {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_104 == 'NA' ? 0 : this.certificationScoreData?.ENTOWN_2X_104;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_104;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_104;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_104 == 'NA' ? 0 : this.certificationScoreData?.PROD_2X_104;
            this.supplyChainPercent = this.certificationScoreData?.SUPCH_2X_104 == 'NA' ? 0 : this.certificationScoreData?.SUPCH_2X_104;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == 'NA' ? 0 : this.certificationScoreData?.PORT_Portfolio_1000;

            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_103;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_103;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_114;
          } else {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_101 == 'NA' ? 0 : this.certificationScoreData?.ENTOWN_2X_101;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_101;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_101;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_101 == 'NA' ? 0 : this.certificationScoreData?.PROD_2X_101;
            this.supplyChainPercent = this.certificationScoreData?.SUPCH_2X_101 == 'NA' ? 0 : this.certificationScoreData?.SUPCH_2X_101;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == 'NA' ? 0 : this.certificationScoreData?.PORT_Portfolio_1000;
            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_102;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_102;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_113;
          }
          if (this.entityChosen == "fund-manager" || this.entityChosen == "financial-institution") {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
              this.portfolioPercent,
            ];
          } else {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
            ];
          }
          this.govChartData = [
            this.govStrategicAction,
            this.govManagementSystem,
            this.govData,
          ];
          if (this.entityChosen == "fund-manager") {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_304"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_304"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_304"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_304"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_304"];
          } else {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_300"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_300"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_300"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_300"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_300"];
          }
        });
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if(this?.certificationScoreData?.ISO && this?.certificationScoreData?.GEN_General_13) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.endofcreated = true;
    },
    async getThresholdsData(recordId, sector) {
      await apiService.fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
      });
    },
    progressClick(step) {
      this.current_step = step;
      switch (step) {
        case 0:
          this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
          break;
        case 1:
          this.$router.push({
            path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
          });
          break;
        case 2:
          this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
          break;
        case 3:
          this.$router.push({
            path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
          });
          break;
        case 4:
          if(this.user.status2xCertification == 'Validation in Process') {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
            });
          } else {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/Verification",
            });
          }
          break;
        case 6:
          this.$router.push({ path: "/2X-Ecosystem/2X-Certification/score-card" });
          break;
        default:
          break;
      }
    },
    getOrganizationDetail(organization_id) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/organizations/" + organization_id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  },
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if(this.$route.query.teamId) {
      await apiService.fetch2xUserSurvey('2x', this.$route.query.teamId)
      .then(async response => {
        this.certificationSurveyData = await response.data.data;
      })
      await apiService.fetch2xCertRequest(this.$route.query.teamId)
      .then(async response => {
        this.status2xCertification = response?.data?.data?.status;
        this.dateOfSubmission = response.data.data.fullSurveySubmittedDate || response.data.data.minSurveySubmittedDate;
      })
    }
    else {
      this.status2xCertification = this.user?.status2xCertification;
      if(this.user.surveyData){
        this.certificationSurveyData = this.user.surveyData;
        await apiService
          .fetch2xCertRequest(this.user.organization._id)
          .then(async (response) => {
            this.dateOfSubmission = response.data.data.fullSurveySubmittedDate || response.data.data.minSurveySubmittedDate;
        });
      } else {
        this.$router.push({ path: '/2X-Ecosystem/2X-Certification' })
      }
    }
    await this.$store.commit("setCertPageStatus", this?.status2xCertification);
    if(!this.$store.state.certPageAccess.isVerificationPage) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
      });
    }
    if (this.certificationSurveyData) {
      await this.certificationSurveyData.forEach((data) => {
        if (data.surveyId == "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    await this.fetchCertificationData();
    if(this.entityChosen) {
      await this.fetchCsvData();
    }
    if(this.certificationScoreData.level == 'Good') {
      this.levelPdfUrl = 'https://storage.equilo.io/api-assets/2X_Certification_Good_Badges.pdf';
    }
    if(this.certificationScoreData.level == 'Best in Class') {
      this.levelPdfUrl = 'https://storage.equilo.io/api-assets/2X_Certification_BestInClass_Badges.pdf';
    }
    if(this.certificationScoreData.level == 'Advanced') {
      this.levelPdfUrl = 'https://storage.equilo.io/api-assets/2X_Certification_Advanced_Badges.pdf';
    }

    if(this.certificationScoreData.level == 'Good') {
      this.levelImageUrl = 'https://storage.equilo.io/api-assets/2X_Certified_Good_Badge.jpeg';
    }
    if(this.certificationScoreData.level == 'Best in Class') {
      this.levelImageUrl = 'https://storage.equilo.io/api-assets/2X_Certified_BestInClass_Badge.jpeg';
    }
    if(this.certificationScoreData.level == 'Advanced') {
      this.levelImageUrl = 'https://storage.equilo.io/api-assets/2X_Certified_Advanced_Badge.jpeg';
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setNewBreadcrumbs", [
      // { name: "Dashboard", url: "/" },
      { name: "2X Certification", url: "/2X-Ecosystem/2X-Certification" },
      {
        name: "2X Global Certification",
        url: "/2X-Ecosystem/2X-Certification/GlobalCertification",
      },
    ]);
  },
};
</script>

<style lang="scss" scoped>
.ContentWrapper {
  margin-top: 10px;

  .progress-steps-section {
    color: #21191d;

    .outer {
      min-width: 20vw;
      flex: 1;
    }

    .progress {
      $gap: 20px;
      $line-height: 20px;
      $bullet-radius: 5px;
      $line-thick: 5px;
      $strip-color: #666461;
      $next-color: #c2beba;
      $current-color: #666461;
      $prev-color: #666461;

      display: inline-flex;
      height: 100%;
      padding: 5vh 10%;

      > div {
        display: flex;
        flex-direction: column;
        color: $prev-color;

        &.left {
          padding-right: $gap;
          text-align: right;

          // Line
          div {
            &:last-of-type:after {
              display: none;
            }

            &:after {
              content: "";
              background: fade_out($strip-color, 0.9); //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              right: -$gap;
              top: $line-height/2;
              height: 101%;
              width: 1px;
              transform: translateX(50%);
            }
          }
        }

        &.right {
          padding-left: $gap;

          div {
            span {
              color: #21191d;
            }

            button {
              span {
                color: #fff;
              }
            }

            &.prev {
              &:after {
                transition: none;
              }
            }

            &.current {
              color: $current-color;
              font-weight: bold;
              z-index: 1;

              &:before {
                background: $current-color;
                padding: 15px;
                transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
                z-index: 1;
              }

              &:last-child:after {
                height: 0%;
                transition: height 0.2s ease-out;
              }

              &:after {
                content: "";
                background: $next-color; //rgba(0, 0, 0, 0.6);
                border-radius: 2px;
                position: absolute;
                left: -40px;
                top: $line-height/2;
                height: 101%;
                width: $line-thick;
                transform: translateX(-50%);
                transition: height 0.2s ease;
              }

              ~ div {
                color: $next-color;

                &:before {
                  background: $next-color;
                  padding: 15px;
                }

                &:after {
                  content: "";
                  background: $next-color; //rgba(0, 0, 0, 0.6);
                  border-radius: 2px;
                  position: absolute;
                  left: -40px;
                  top: $line-height/2;
                  height: 101%;
                  width: $line-thick;
                  transform: translateX(-50%);
                  transition: height 0.2s ease;
                }

                &:last-child:after {
                  height: 0%;
                  transition: none;
                }
              }
            }

            // Dot
            &:before {
              content: "";
              background: $strip-color;
              padding: 15px;
              border-radius: 50%;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              transform: translateX(-50%) translateY(-50%);
              transition: padding 0.2s ease;
            }

            // Line
            &:after {
              content: "";
              background: $strip-color; //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              height: 101%;
              width: $line-thick;
              transform: translateX(-50%);
              transition: height 0.2s ease;
            }
          }
        }

        div {
          flex: 1;
          //outline: 1px solid rgba(0, 0, 0, 0.1);
          position: relative;
          line-height: $line-height;
          cursor: default;
          min-height: 150px;

          &:last-of-type {
            flex: 0;
          }
        }
      }
    }

    .done.current {
      color: #62af0b !important;

      &:before {
        background: #62af0b !important;
      }
    }
  }

  .standard-button {
    font-weight: bold;
    font-size: 12px;
    color: #19486a;
    border-style: solid;
    border-color: #19486a;
    border-width: 1px;
  }

  .certification-button {
    font-weight: 500;
    color: white;
    background: #19486a;
    font-size: 16px;
  }
  .congrats-box-global {
      border: 1px solid #ccc;
      border-radius: 15px;
      overflow: hidden;
      // height: 420px;
      .download-buttons {
        .gesi-contextual-button {
          padding: 8px 16px 8px 16px;
          border-radius: 8px;
          gap: 8px;
          color: #191B45;
          border: 1px solid #191B45;
          font-family: Arial;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;

        }
        .gender-action-plan-button {
          padding: 8px 16px 8px 16px;
          border-radius: 8px;
          gap: 8px;
          background: #191B45;
          font-family: Arial;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
}

.asset_text {
  font-size: 12px;
  font-weight: bold;
  color: #1f1747;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.ecosystem-landing {
  .pdfexportbtn {
    height: 0px;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
  }
  background-color: #f4f1ed !important;
  color: #21191d !important;
  font-family: Work Sans !important;
  padding-top: 12em;

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .bg-text-style {
      margin-left: 9rem;
      position: relative;
      top: 20rem;
      color: #f4f1ed;
      width: 570px;
    }

    .PoweredbyTitleWrepper {
      max-width: 847px;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #19486a;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bg-image {
    background-image: linear-gradient(
        201deg,
        rgba(0, 0, 0, 0.1) 55%,
        rgba(221, 166, 58, 0.8) 100%
      ),
      url("./../../assets/images/2x-challenge-bg.png");
    height: 700px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0;
    position: relative;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .Modal_Readmorestyle {
    position: relative;
    display: flex;

    .ModalInnerStyle {
      background: #fff;

      .heading-section {
        gap: 28px;
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #dda63a;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .textarea-section {
        width: 552px;
        gap: 12px;

        textarea {
          margin: 0;
          min-width: 576px;
          min-height: 120px;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 16px;
        }

        textarea::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .cancel-button {
        width: auto;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #dda63a;
        border: 1px solid #dda63a;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .save-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #191b45;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .congrats-box-doc {
        border: 1px solid #2177c1;
        border-radius: 16px;
        padding: 32px, 0px, 32px, 0px;
        background: #eaf6ff;
        border-style: dashed;
        height: 160px;
      }
    }
  }

  .what-modal {
    padding: 24px 0 24px 24px;

    .content {
    }

    .modal-link-button {
      font-weight: bold;
      color: #19486a;
      border-style: solid;
      border-color: #19486a;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      background-color: #3f7e44;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }

    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #191b45;
    }

    .upload-documents-button {
      font-weight: bold;
      color: white;
      background: #2177c1;
      // width: 150px;
      padding: 8px 16px 8px 16px;
    }

    .center-button {
      position: relative;
      top: 27px;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

input {
  margin: 6px;
}

.download-buttons {
  .gesi-contextual-button {
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    gap: 8px;
    color: #19486a;
    border: 1px solid #19486a;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 1em;
    margin-right: 1em;
  }

  .gender-action-plan-button {
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    gap: 8px;
    background: #19486a;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
  }
}

.modal {
  display: block;
  position: fixed;
  z-index: 20;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 24px;
    border: 1px solid #888;
    width: 600px;
    border-radius: 20px;

    .download-kit-details {
      .inner-download-kit-details {
        width: 552px;
        height: 88px;
        padding: 24px 0px 24px 0px;
        border-radius: 4px;
        border-bottom: 1px solid #e1e3e9;

        img {
          width: 36px;
          height: 36px;
        }

        .text {
          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #717686;
        }
      }
    }

    .action-button-section {
      padding: 16px 0 0 0;
      gap: 16px;
      align-self: stretch;

      .button-section {
        button {
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          border-radius: 8px;
          margin-right: 5px;
        }

        .cancel-button {
          color: var(--primary-100, #191b45);
          text-align: center;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin-left: 5px;
          border: 1px solid var(--primary-100, #191b45);
        }

        .save-button {
          color: var(--white, #fff);
          text-align: center;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          background: #2177c1;
        }
      }
    }
  }
}

#SPAN_1:hover {
  color: #40a9ff;
}
</style>
