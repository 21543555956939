<template>
    <div class="fixed z-50 inset-0 overflow-y-auto">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity cursor-pointer">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 624px;">
                <div class="Modal_Readmorestyle p-6">
                    <div class="ModalInnerStyle">
                        <div class="heading-section flex justify-between items-center">
                            <h1 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                               Comment
                            </h1>
                        </div>
                        <div class="textarea-section mt-7 flex justify-between">
                            <textarea
                             v-model="commentData"
                             placeholder="Type something..."
                             @input="isEdit = true"
                            />
                        </div>
                        <div class="mt-7 flex justify-between gap-4">
                            <button class="cancel-button flex justify-center" @click="closeModal()">
                                Cancel
                            </button>
                            <button class="save-button flex justify-center"
                            @click="handleSaveComment"
                            :disabled="!isEdit"
                            :class="{'opacity-20' : !isEdit}"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "StatementCommentsModal",
    props: ['comment'],
    data() {
        return {
            commentData : this.comment.commentContent,
            isEdit: false
        }
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        handleSaveComment(){
            const commentPayload = {
                commentId : this.comment.commentId,
                commentContent : this.commentData
            }
            this.$emit('saveComment' , commentPayload)
        }
    }
}
</script>
<style lang="scss" scoped>

.Modal_Readmorestyle {
    position: relative;
    display: flex;
    .ModalInnerStyle {
        background: #fff;
        .heading-section {
            gap: 28px;
        }

        h1 {
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #2C2E35;
        }

        p {
            font-family: Arial;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #717686;
        }
        .textarea-section {
            width: 552px;
            gap: 12px;
            textarea {
                margin: 0;
                min-width: 576px;
                min-height: 120px;
                padding: 12px;
                border-radius: 8px;
                border: 1px solid #E1E3E9;
                gap: 16px;
            }
            textarea::placeholder {
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #717686;
            }
        }
        .cancel-button {
            width: 280px;
            height: 40px;
            padding: 12px 24px 12px 24px;
            border-radius: 8px;
            gap: 8px;
            border: 1px solid #191B45;
            color: #191B45;
            font-family: Arial;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
        }
        .save-button {
            width: 280px;
            height: 40px;
            padding: 12px 24px 12px 24px;
            border-radius: 8px;
            gap: 8px;
            background: #191B45;
            color: #fff;
            font-family: Arial;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
        }
    }
}
</style>