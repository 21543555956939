<template>
    
  <div class="static">
    <div style="display:flex; justify-content:space-between;">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-left: 285px;"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-right: 120px;"
      />
    </div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container" style="margin-bottom: 6rem;">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <img
                    class="h-16 print:h-auto print:w-64"
                    src="@/assets/images/2X-assessment/2X-myFavAssessment.png"
                  />
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5">
                    My Favorite 2X Assessments
                  </h1>
                </div>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center">
                  Click below to view the 2X Assess results for a specific company, fund, or financial institution.                
                </p>
              </div>
            </div>
            <!-- will use in 2.0 -->
            <div class="favorite-shared-button-section" v-if="(sharedOrgData || favouriteOrgData)">
              <p>
                <span class="text-2xl tracking-tighter font-medium text-center print:text-7xl ml-5">Shared</span>
                <label class="toggleSwitch large">
                      <input type="checkbox" id="favChecked" @click="showFavorite()" />
                      <span>
                          <span></span>
                          <span></span>
                      </span>
                      <a></a>
                  </label>
                <span class="text-2xl tracking-tighter font-medium text-center print:text-7xl ml-4">Favorites</span>
              </p>
            </div>
            <!-- new content start-->
            <div class="mt-16 grid grid-cols-3 gap-4" v-if="!isSharedChecked && sharedOrgData && sharedOrgData.length > 0">
              <div v-for="(orgData, index) in sharedOrgData" :key="index">
                <div class="container_foto container_foto-2x-gender long-text" @click="onAssessmentClick(orgData.type,orgData.assesments.recordId)">  
                    <div class="ver_mas text-center">
                      <img
                          src="@/assets/images/eye.png"
                          alt="visibility-icon"
                          class="visibility-icon"
                      />
                    </div>
                    <article class="text-left">
                      <img
                          src="@/assets/images/Global-Equality.png"
                          alt="visibility-icon"
                          class="card-icon"
                      />
                      <h2 style="text-align:center;">{{ orgData.assesments.organizationName }}</h2>
                      <h4>
                        View this organization's 2X assessment results.
                      </h4>
                    </article>
                    <img
                      v-if="orgData.assesments"
                      class="bg-shade"
                      :src="orgData.assesments.logo ? orgData.assesments.logo[0].url : 'https://s1g.s3.amazonaws.com/8b2097f2417c53dfc20803d83ca5b603.png'" 
                      alt="" />
                  </div>
              </div>
            </div>

            <!-- favorite data -->
            <div class="mt-16 grid grid-cols-3 gap-4" v-if="isSharedChecked && favouriteOrgData && favouriteOrgData.length > 0">
              <div v-for="(orgData, index) in favouriteOrgData" :key="index">
                <div class="container_foto container_foto-2x-gender long-text" @click="onAssessmentClick(orgData.type,orgData.savedAssesments)">   
                    <div class="ver_mas text-center">
                      <img
                          src="@/assets/images/eye.png"
                          alt="visibility-icon"
                          class="visibility-icon"
                      />
                    </div>
                    <article class="text-left">
                      <img
                          src="@/assets/images/Global-Equality.png"
                          alt="visibility-icon"
                          class="card-icon"
                      />
                      <h2 style="text-align:center;">{{ orgData.assesments.organizationName }}</h2>
                      <h4>
                        View this organization's 2X assessment results.
                      </h4>
                    </article>
                    <img
                      v-if="orgData.assesments"
                      class="bg-shade"
                      :src="orgData.assesments.logo ? orgData.assesments.logo[0].url : 'https://s1g.s3.amazonaws.com/8b2097f2417c53dfc20803d83ca5b603.png'" 
                      alt="" />
                  </div>
              </div>
            </div>
            <!-- favorite data end -->
            <div v-if="!isSharedChecked && isSharedEmpty" class="mt-16 grid grid-cols-3 gap-4" style="display:flex; justify-content: center; margin-left:115px;">
              <!-- <p>
                Go to the 
                <a href="/#/2X-Ecosystem/2XDirectory">
                  <b style="text-decoration:underline; color:#0077C8; margin: 0 6px;">Investee Directory</b>
                </a>
                or
                <span class=tooltip>
                  <a href="javascript:void(0)">
                    <b style="text-decoration:underline; color:#0077C8; margin: 0 6px;">Deal Pipeline</b>
                  </a>
                  <span class="coming-soon-tooltip">
                    Coming soon!
                  </span>
                </span>
                to mark your favorites that will then be displayed here.
              </p> -->
              <span class="text-xl">No data found</span>
            </div>
            <div v-if="isSharedChecked && isFavEmpty" class="mt-16 grid grid-cols-3 gap-4" style="display:flex; justify-content: center; margin-left:115px;">
              <!-- <p>
                Go to the
                <a href="/#/2X-Ecosystem/2XDirectory">
                  <b style="text-decoration:underline; color:#0077C8; margin: 0 6px;">Investee Directory</b>
                </a>
                or
                <span class=tooltip>
                  <a href="javascript:void(0)">
                    <b style="text-decoration:underline; color:#0077C8; margin: 0 6px;">Deal Pipeline</b>
                  </a>
                  <span class="coming-soon-tooltip">
                    Coming soon!
                  </span>
                </span> 
                to mark your favorites that will then be displayed here. 
              </p> -->
              <span class="text-xl">No data found</span>
            </div>
            <!-- new content end-->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Cookies from "@/helpers/cookies";
import auth from "@/helpers/auth";
import axios from "axios";
import env from '@/../env'; 
import pageLoader from "@/components/page-loader.vue";
export default {
    name: "myFavAssessments",
    data: function() {
     return {
         active:false,
         endofcreated:false,
         sharedOrgData: [],
         favouriteOrgData: [],
         isSharedChecked: '',
         user: []
     }
    },
    components: {
      pageLoader
    },
    
    created(){
        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
        {
          name: "Favorite Assessments",
          url:"/2X-Ecosystem/FavoriteAssessments",
        },
        
    ]);
        this.endofcreated=true;
    },  
    async mounted() {
      this.user = await auth.me().catch(() => {
          console.log("Error login");
      });
      this.sharedOrgData = await this.getSharedOrgData();
      this.favouriteOrgData = await this.getFavouriteData();
    },
    methods: {
      async showFavorite() {
        document.getElementById("favChecked").checked ? this.isSharedChecked = true : this.isSharedChecked = false;
      },
      getFavouriteData() {
        return new Promise(function (resolve) {
          axios.get(env.apiUrl + '/assesment2x/get-save-assesments',
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data.data);
          });
        })
      },
      getSharedOrgData() {
        const _self = this;
        return new Promise(function (resolve) {
          axios.post(env.apiUrl + '/assesment2x/shared-assesment', { "email": _self.user.email },
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data.data);
          });
        })
      },
      onOrgClick(orgName, userId) {
        let queryData = {
          org: orgName,
          id: userId
        }
        this.$router.push({ path: '/2X-Ecosystem/My-2X-Assessments', query: queryData })
      },
      onAssessmentClick(type,recordId) {

        let queryData = {
          recordId: recordId,
          type: type,
        };
        this.$router.push({ path: '/2X-Ecosystem/My-2X-Assessments', query: queryData })
      }
    },
    computed: {
      isSharedEmpty() {
        return this.sharedOrgData.length == 0 ? true : false;
      },
      isFavEmpty() {
        return this.favouriteOrgData.length == 0 ? true : false;
      }
    }
};
</script>
<style scoped>
.favorite-shared-button-section {
  text-align: right;
  padding: 20px 0 0;
}
/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 15px;
    cursor: pointer;
    width: 40px
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 4px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #CCC;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #0097D1;
    box-shadow: inset 0 0 0 30px #0097D1;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
  /* Switch Sizes */
  .toggleSwitch.large {
    width: 70px;
    height: 30px;
    top: 5px;
  }
  .toggleSwitch.large a {
    width: 29px;
    height: 28px;
    top: 0.8px;
  }
  .toggleSwitch.large > span {
    height: 34px;
    line-height: 35px;
  }
  .toggleSwitch.large input:checked ~ a {
    left: 46px;
  }
  .toggleSwitch.large > span span {
    font-size: 1.1em;
  }
  .toggleSwitch.large > span span:first-of-type {
    left: 50%;
  }
}


/*  End Toggle Switch  */

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(30px);
}

.slide-in-move {
  transition: opacity 0.6s linear, transform 0.6s ease-in-out;
}

.slide-in-leave-active {
  transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
  transition-delay: calc(0.1s * (var(--total) - var(--i)));
}

.slide-in-enter-active {
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  transition-delay: calc(0.05s * var(--i));
}

.slide-in-enter,
.slide-in-leave-to {
  opacity: 0;
}

.slide-in-enter {
  transform: translateY(2em);
}

.slide-in-leave-to {
  transform: translateY(-1em);
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}

.tooltip .coming-soon-tooltip {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 160px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -19px;
  top: -70px;
}
.tooltip:hover .coming-soon-tooltip {
  visibility: visible;
}
.tooltip .coming-soon-tooltip::after {
  right: 95%;
}
</style>
