import { render, staticRenderFns } from "./get-started-imm.vue?vue&type=template&id=4626fde0&scoped=true"
import script from "./get-started-imm.vue?vue&type=script&lang=js"
export * from "./get-started-imm.vue?vue&type=script&lang=js"
import style0 from "./get-started-imm.vue?vue&type=style&index=0&id=4626fde0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4626fde0",
  null
  
)

export default component.exports