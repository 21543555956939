<template>
  <div class="flex flex-row w-full h-screen">
    <div
      class="w-4/12 h-screen flex flex-col items-center justify-center relative px-12"
      :style="'background-color:' + env.brandingColors.navy"
    >
      <!--Equilo logo-->
      <img
        class="absolute top-0 left-0 mt-10 ml-10 h-10"
        src="@/assets/images/logo.png"
        alt="logo equilo"
      />
      <div class="text-4xl font-medium">
        <h2 class="tracking-tighter hacking" style="color: #fff">
          Hacking gender equality &amp; social inclusion for a happier, more
          just world.
        </h2>
      </div>
    </div>
    <div
      class="w-8/12 h-screen bg-white flex flex-col items-center justify-center"
    >
      <div class="w-8/12">
        <div class="mb-10">
          <h1 class="text-5xl print:text-4xl font-bold tracking-tight">
            Welcome to Equilo
          </h1>
        </div>
        <div v-if="loading">
          <div class="flex flex-col items-center justify-center">
            <local-loader />
          </div>
        </div>
        <div v-else>
          <div class="flex flex-col justify-start">
            <h1 class="font-medium">E-mail</h1>
            <input
              type="text"
              class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm"
              autocomplete="email"
              id="email"
              placeholder="E-mail"
              v-model="input.email"
              :class="{
                'border-red-600': error,
                'animate__animated animate__shakeX': error_animation,
              }"
            />
            <div class="pwd-image">
              <h1 class="mt-5 font-medium">Password</h1>
              <span class="pwd-text" @click="showPassword = !showPassword">
                <img
                  src="@/assets/images/pwd-eye.png"
                  height="30"
                  width="30"
                  v-if="showPassword"
                  style="cursor: pointer"
                />
                <img
                  src="@/assets/images/eye-slash.svg"
                  height="30"
                  width="30"
                  v-if="!showPassword"
                  style="cursor: pointer"
                />
              </span>

              <input
                :type="showPassword ? 'text' : 'password'"
                class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm pwd-input"
                placeholder="Password"
                v-model="input.password"
                :class="{
                  'border-red-600': error,
                  'animate__animated animate__shakeX': error_animation,
                }"
              />
            </div>
            <div class="flex flex-row justify-between">
              <div class="flex flex-row items-center justify-start"></div>
              <div>
                <router-link
                  to="/reset-password"
                  class="text-sm-b"
                  href=""
                  style="color: #0077c8"
                  >Reset Password</router-link
                >
              </div>
            </div>
            <button
              :style="'background-color: ' + env.brandingColors.teal"
              class="w-full py-5 rounded-lg mt-10 text-white font-semibold"
              @click="login"
            >
              <span class="btn-text" style="font-size: 20px">Submit</span>
            </button>
            <div class="mt-3" v-if="error">
              <div
                class="text-red-500 text-xs animate__animated animate__fadeIn"
              >
                {{ error }}
              </div>
            </div>
          </div>
          <div class="mt-10 text-gray-600">
            <p class="text-sm-b">
              No account yet?
              <router-link
                to="/signup"
                :style="'color:' + env.brandingColors.blue"
                class="text-sm-b"
                href=""
                >Create an account</router-link
              >
            </p>
          </div>
          <div class="mt-5 text-gray-600">
            <p class="text-sm-b">
              Trouble logging in?
              <a
                href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red"
                target="_blank"
                :style="'color:' + env.brandingColors.blue"
                >Contact us for support!</a
              >
            </p>
          </div>
        </div>
        <div class="pt-10">v1.0.0</div>
        <div></div>
      </div>
    </div>
    <translate-modal languagePreference="English"></translate-modal>
  </div>
</template>
<script>
import users from "@/helpers/users";
import { useUserStore } from "@/stores/user";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import axios from "axios";
import TranslateModal from "@/pages/backups/LangTranslator";
import localLoader from "@/components/local-loader.vue";

export default {
  name: "login",
  components: {
    TranslateModal,
    localLoader,
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      error: false,
      env,
      error_animation: false,
      showPassword: false,
      input: {
        email: "",
        password: "",
      },
      user: null,
      loading: false,
    };
  },
  methods: {
    async login() {
      if (this.input.email.includes(" ") || this.input.password.includes(" ")) {
        this.setError("Spaces are not allowed in the email or password");
        return;
      }
      if (this.input.email && this.input.password) {
        this.loading = true;
        try {
          localStorage.removeItem("deviceToken");
          Cookies.remove("session");
          const res = await users.login(
            this.input.email,
            this.input.password,
            this.detectBrowser
          );
          if (res?.data?._id) {
            await this.$store.commit("setLocalStorage", res.data.deviceId);
            await this.$store.commit("setSession", res.data._id);
            this.$router.push("/").then(() => {
              this.pageReload();
            });
          } else if (res?.data?.success === "warning") {
            await this.$swal.fire({
              icon: "warning",
              text: res.data.message,
              showConfirmButton: true,
            });
            this.loading = false;
          }
        } catch (error) {
          this.handleError(error);
          this.loading = false;
        }
      } else {
        this.setError("A username and password must be present");
        this.loading = false;
      }
    },
    setError(message) {
      this.error = message;
      this.error_animation = true;
      setTimeout(() => {
        this.error_animation = false;
      }, 2000);
    },
    handleError(error) {
      const errorMessage = error.message || "An error occurred";
      const statusCode = error.response?.status;
      const errorMessages = {
        400: "Bad Request. Your request could not be understood or was missing required parameters.",
        401: "Unauthorized. You need to be logged in to access this resource.",
        403: "Incorrect username or password. Please try again.",
        404: "The requested resource could not be found. Please check the URL.",
        408: "Request Timeout. The server timed out waiting for the request.",
        500: "Internal Server Error. Something went wrong on the server.",
        502: "Bad Gateway. The server, while acting as a gateway or proxy, received an invalid response from the upstream server.",
        503: "Service Unavailable. The server is currently unable to handle the request due to a temporary overload or scheduled maintenance.",
        504: "Gateway Timeout. The server, while acting as a gateway or proxy, did not receive a timely response from the upstream server.",
      };
      this.setError(errorMessages[statusCode] || errorMessage);
    },
    async checkIdentificationCode() {
      try {
        const res = await axios.get(
          `${env.apiUrl}/users/checkIdentificationCode/${this.$route.query.code}?oldDeviceId=${this.$route.query.deviceId}`
        );
        if (res.data.success) {
          await this.$store.commit("setLocalStorage", res.data.deviceId);
          await this.$swal.fire({
            icon: "success",
            title: "Verified!",
            text: res.data.message,
          });
          await new Promise((resolve) => setTimeout(resolve, 3000));
          const successRes = await axios.get(
            `${env.apiUrl}/users/identificationSuccess/${this.$route.query.code}?deviceId=${res.data.deviceId}`
          );
          if (successRes.data.success) {
            await this.$store.commit("setSession", successRes.data.id);
            document.location.reload();
          } else {
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: successRes.data.message,
            });
          }
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: res.data.message,
          });
        }
      } catch (error) {
        this.setError(
          "An error occurred while checking the identification code."
        );
      }
    },
    pageReload() {
      window.location.reload();
    },
  },
  async mounted() {
    try {
      this.user = this.userStore.user;
      localStorage.removeItem("isLogout");
      if (this.user?._id) {
        const previousURL = localStorage.getItem("previousURL") || "/";
        const url = previousURL?.includes("reset-password") ? "/" : previousURL;
        this.$router.push(url).then(() => {
          this.pageReload();
        });
      }
      if (this.$route.query.code) {
        await this.checkIdentificationCode();
      }
    } catch {
      this.setError("An error occurred while fetching user data.");
    }
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.login();
      }
    });
  },
  computed: {
    detectBrowser() {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        return "Opera";
      } else if (userAgent.includes("Chrome")) {
        return "Chrome";
      } else if (userAgent.includes("Safari")) {
        return "Safari";
      } else if (userAgent.includes("Firefox")) {
        return "Firefox";
      } else if (userAgent.includes("MSIE") || !!document.documentMode) {
        return "IE";
      } else {
        return "Unknown";
      }
    },
  },
};
</script>
<style>
.text-sm-b {
  font-size: 0.95rem;
}
.btn-text {
  font-weight: 900;
}
.pwd-image {
  width: 100%;
  position: relative;
}
.pwd-text {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 12px;
  right: 15px;
  padding-right: 1px;
}
.pwd-input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 5px;
  border-radius: 2px;
}
</style>
