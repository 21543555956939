<template>
  <div>
    <color-dropdown
      v-model="currentColorScheme"
      :plot-color-schemes="plotColorSchemes"
      :current-color="currentColorScheme"
      v-if="modal"
    ></color-dropdown>
    <div :id="`chart-${this.id}`"></div>
  </div>
</template>

<script>
import * as Plot from "@observablehq/plot";
import { processDataset, colorSchemes, colors, getMaxValue } from "./utils";
import ColorDropdown from "./colorDropdown.vue";

export default {
  name: "plot-scatter-chart",
  components: {
    ColorDropdown,
  },
  props: [
    "id",
    "chartData",
    "chartLabels",
    "color",
    "country",
    "indicator",
    "plotColorSchemes",
    "modal",
  ],
  data() {
    return {
      data: [],
      labels: [],
      currentColorScheme: "",
    };
  },
  methods: {
    createChart(color) {
      const maxValue = getMaxValue(this.data);
      return Plot.plot({
        r: {
          type: "linear",
          domain: [0, maxValue],
        },
        grid: true,
        y: {
          tickFormat: (d) => `${d}%`,
          label: "Percentage",
          domain: [0, maxValue],
        },
        color: {
          scheme: color,
          legend: true,
        },
        marks: [
          Plot.dot(this.data, {
            x: { value: "label", label: "Regions" },
            y: "value",
            stroke: { value: "gender" },
            strokeWidth: 3,
            fill: "gender",
            r: (d) => d.value * 10,
            tip: true,
          }),
        ],
      });
    },
  },
  mounted() {
    this.currentColorScheme = this.plotColorSchemes[4].value;
    const [newData, labels] = processDataset(
      this.chartData,
      this.chartLabels,
      colorSchemes,
      colors,
      this.country,
      this.indicator
    );
    this.labels = labels;
    this.data = newData.reduce((acc, d) => {
      return acc.concat(
        d.data.map((i, index) => ({
          label: this.labels[index],
          gender: d.label,
          value: i.toFixed(2),
        }))
      );
    }, []);

    const chart = this.createChart(this.currentColorScheme);

    document.getElementById(`chart-${this.id}`).appendChild(chart);
  },
  watch: {
    currentColorScheme(newVal) {
      const newChart = this.createChart(newVal);
      // remove the old chart
      const chartContainer = document.getElementById(`chart-${this.id}`);
      chartContainer.innerHTML = "";

      // append the updated chart
      chartContainer.appendChild(newChart);
    },
  },
};
</script>

<style scoped>
#chart {
  width: 100%;
  height: 400px;
}
</style>
