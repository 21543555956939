<template>
  <div class="step2-section">
    <div class="">
      <p class="label">
        Select all modules you want your portfolio companies to report on, and
        indicate the preferred frequency for receiving updated data.
      </p>
      <div class="modules-section">
        <div
          class="modules-list"
          v-for="(module, index) in modulesList"
          :key="module.title + index"
          :class="module.checked ? 'blue-border' : 'grey-border'"
        >
          <div class="heading flex justify-between">
            <div class="flex justify-between">
              <p>{{ module.title }}</p>
              <div class="info-icon tooltip">
                <img src="./../../../assets/images/IMM/info-filled.svg" />
                <span class="tooltiptext">{{ module.hoverText }}</span>
              </div>
            </div>
            <input
              class=""
              type="checkbox"
              :id="module.title"
              v-model="module.checked"
              @click="moduleChecked(index)"
            />
          </div>
          <div class="option-text" v-if="module.checked">
            Choose preferred reporting frequency of your portfolio companies
          </div>
          <div class="flex options-list-main" v-if="module.checked">
            <div
              class="options-list flex"
              v-for="frequency in frequencyOptions"
              :key="frequency"
            >
              <input
                type="radio"
                :id="module.title + '_' + frequency"
                :value="frequency"
                v-model="module.frequency"
                @change="frequencyChecked(module, frequency)"
              />
              <label :for="frequency">{{ frequency }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Step2",
  props : ['fieldValue'],  
  data() {
    return {
      selectedFrequency: "",
      frequencyOptions: ["Monthly", "Quarterly", "Annually"],
      modulesList: [
        {
          title: "2X Certification",
          hoverText:
            "Assess a portfolio company's gender equality practices from governance, ownership, leadership, employment and diversity practices, supply chain, products and value chain. ",
          checked: false,
          isOpened: false,
          frequency: "",
        },
        {
          title: "Gender ROI™",
          hoverText:
            "Conduct a gender analysis of a portfolio company's leadership, workforce, value chain, and society where they operate.",
          checked: false,
          isOpened: false,
          frequency: "",
        },
        {
          title: "Impact",
          hoverText:
            "Assess the long-term effects or benefits brought about for the intended beneficiaries or stakeholders through the portfolio company's daily business. This includes impacts on people and the natural environment.",
          checked: false,
          isOpened: false,
          frequency: "",
        },
        {
          title: "ESG",
          hoverText:
            "Assess a portfolio company's management of  environmental, social and governance risks and opportunities. This includes  assessment of environmental and social impacts, as well as how governance structures can be amended to maximize stakeholder well-being.",
          checked: false,
          isOpened: false,
          frequency: "",
        },
        {
          title: "Climate",
          hoverText:
            "Assess a portfolio company's strategies to address and mitigate climate change, including a commitment to diminish its greenhouse gas emissions, embrace sustainable practices, and allocate resources toward environmentally friendly, renewable, or low-carbon products and services.",
          checked: false,
          isOpened: false,
          frequency: "",
        },
        {
          title: "Finance",
          hoverText:
            "Assess a portfolio company's overall standing in categories such as assets, liabilities, equity, expenses, revenue,and overall profitability.",
          checked: false,
          isOpened: false,
          frequency: "",
        },
      ],
      preferencesArray: [],
    };
  },
  mounted() {
    if (this.fieldValue && this.fieldValue.preferencesArray) {
      this.modulesList.forEach((module) => {
        const foundModule = this.fieldValue.preferencesArray.find((item) => item.title === module.title);
        if (foundModule) {
          module.checked = true;
          module.frequency = foundModule.frequency;
        }
      });
      this.preferencesArray = this.fieldValue.preferencesArray;
      this.$emit("input-typed", {
        isStepActive: true,
        preferencesArray: this.preferencesArray,
      });
    }
  },
  methods: {
    moduleChecked(index) {
      const module = this.modulesList[index];
      module.checked = !module.checked;
      if (!module.checked) {
        module.frequency = "";
        const existingIndex = this.preferencesArray.findIndex(
          (item) => item.title === module.title
        );
        if (existingIndex !== -1) {
          this.preferencesArray.splice(existingIndex, 1);
        }
      }
      // let emitValue = this.modulesList.some(
      //   (data) => data.checked && data.frequency
      // );
      this.emitValue(false);
    },
    frequencyChecked(module, frequency) {
      this.preferencesArray.push({
        title: module.title,
        frequency: frequency,
      });
      this.updateEmitValue();
    },
    updateEmitValue() {
      const hasEmpty = this.preferencesArray.some(cert => cert.title == '' || cert.frequency == '');
      this.emitValue(!hasEmpty)
    },
    resetState() {
      this.selectedFrequency = "";
      this.preferencesArray = [];
    },
    emitValue(value) {
      this.$emit("input-typed", {
        isStepActive: value,
        preferencesArray: this.preferencesArray,
      });
    },
  },
  beforeDestroy() {
    this.resetState();
 },
};
</script>
<style lang="scss" scoped>
.step2-section {
  .label {
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #181818;
  }
  .modules-section {
    margin-top: 16px;
    .modules-list {
      width: 616px;
      padding: 24px;
      border-radius: 8px;
      border: 1px;
      gap: 16px;
      margin-bottom: 20px;
      .heading {
        p {
          font-family: Arial;
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
        }
        .info-icon {
          width: 16px;
          height: 16px;
          margin-left: 6px;

          img {
            position: relative;
            top: 4px;
          }
        }
        .tooltip {
          position: relative;
          display: inline-block;
        }
        .tooltip .tooltiptext {
          visibility: hidden;
          background-color: #2c2e35;
          color: #fff;
          position: absolute;
          z-index: 1;
          left: -237px;
          top: 38px;
          width: 480px;

          padding: 16px;
          border-radius: 12px;
          gap: 16px;

          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
        }
        .tooltip:hover .tooltiptext {
          visibility: visible;
        }
        .tooltip .tooltiptext::after {
          content: "";
          position: absolute;
          top: -14px;
          left: 50%;
          margin-left: -5px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent #2c2e35 transparent;
        }
      }
      .option-text {
        margin-top: 16px;
      }
      .options-list-main {
        gap: 24px;
        .options-list {
          margin-top: 8px;
          gap: 8px;
        }
      }
    }
    .blue-border {
      border: 1px solid #2177c1;
    }
    .grey-border {
      border: 1px solid #9ea4ba;
    }
  }
}
</style>
