<template>
  <div class="table-container">
    <table class="inner-table">
      <!-- <tr class="table-heading-row">
        <th class="text-center" v-for="head in mainHeading" :key="head">
          {{ head }}
        </th>
      </tr> -->
      <tr class="table-heading-row">
        <th class="text-center" v-for="head in heading" :key="head">
          {{ head }}
        </th>
      </tr>
      <tr
        class="table-content-row"
        v-for="data in tableData"
        :key="data.rowHeading"
      >
        <td class="text-left w-[940px]">{{ data.rowHeading }}</td>
        <td class="text-center">
          {{ data.unAudited1 }}
        </td>
        <td class="text-center">{{ data.unAudited2 }}</td>
        <td class="text-center">{{ data.unAudited3 }}</td>
        <td class="text-center">{{ data.unAudited4 }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "EsgTable",
  data() {
    return {
      heading: ["", "2020", "2021", "2022", "2023"],
      mainHeading: [
        "Excluded Activity",
        "Overall % of “No” response",
        "",
        "",
        "",
      ],
      colHeading: [
        "Net Sales",
        "Gross Profit",
        "Gross Margin",
        "SG&A",
        "EBITDA",
        "EBITDA Margin",
        "Net Profit",
        "Net Margin",
      ],
      tableData: [
        {
          rowHeading:
            "Forced labour or harmful or exploitative forms of child labour",
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "red",
        },
        {
          rowHeading: `The production of, or trade in, any product or activity deemed illegal under national laws or
regulations of the Member in whose territory the Project is located, or international conventions
and agreements, or subject to international phase out or bans`,
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "green",
        },
        {
          rowHeading: `Trade in wildlife or production of, or trade in, wildlife products regulated under the Convention on International Trade in Endangered Species of Wild Fauna and Flora (CITES)`,
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "",
        },
        {
          rowHeading: "SG&A",
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "green",
        },
        {
          rowHeading:
            "Transboundary movements of waste prohibited under international law (Basel Convention)",
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "green",
        },
        {
          rowHeading:
            "Production of, or trade in, weapons and munitions, including paramilitary materials",
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "",
        },
        {
          rowHeading:
            "Production of, or trade in, alcoholic beverages, excluding beer and wine",
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "red",
        },
        {
          rowHeading: "Production of, or trade in, tobacco",
          unAudited1: "20.8%",
          unAudited2: "20.8%",
          unAudited3: "20.8%",
          unAudited4: "20.8%",
          class: "",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #e1e3e9;
  .inner-table {
    width: 100%;
    th,
    td {
      border: 1px solid #e1e3e9;
    }
    .table-heading-row {
      th {
        padding: 16px;
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
          linear-gradient(0deg, #e1e3e9, #e1e3e9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #2c2e35;
      }
      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }
      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }
    .table-content-row {
      td {
        border-bottom: none;
        height: 68px;
        padding: 16px 12px 16px 12px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2c2e35;
      }
      td:first-child {
        border-left: none;
      }
      td:last-child {
        border-right: none;
      }
      .unaudit-section {
        span {
          gap: 8px;
        }
      }
    }
  }
  .red {
    color: #cb2e00;
  }
  .green {
    color: #13a094;
  }
}
.table-heading-row-main {
  th {
    padding: 16px;
    gap: 32px;
    border-top: none;
    background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
      linear-gradient(0deg, #e1e3e9, #e1e3e9);
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #2c2e35;
  }
  th:first-child {
    border-top-left-radius: 16px;
    border-left: none;
  }
  th:last-child {
    border-top-right-radius: 16px;
    border-right: none;
  }
}
</style>
