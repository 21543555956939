var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spiderchartContent",attrs:{"id":"chart"}},[_c('div',{staticClass:"z-20 relative"},[_c('spiderchart',{key:_vm.supplyChainPercent,staticClass:"z-10 overflow-visible",staticStyle:{"position":"relative","height":"100%","width":"100%"},attrs:{"paddingL":40,"paddingR":40,"paddingT":40,"paddingB":40,"entityType":_vm.entityChosen,"chartData":_vm.domainChartData,"srManagerThreshold":_vm.srManagerThreshold,"workforceThreshold":_vm.workforceThreshold,"boardThreshold":_vm.boardThreshold,"assessmentYear":_vm.assessmentYear}}),(!_vm.$store.state.certPageAccess.isValidationRecommendsCertification && (_vm.$route.path.includes('PreliminaryResults') || _vm.$route.path.includes('2X-Challenge-Self-Assessment-Results')))?_c('div',{staticClass:"absolute inset-0 -z-10"},[_c('p',{staticClass:"text-gray-300 text-4xl mt-44 ml-56 text-left max-w-sm font-medium"},[_vm._v("Pending Verification and Certification")])]):_vm._e(),_c('div',{staticClass:"flex items-center",class:_vm.entityChosen == 'fund-manager' || _vm.entityChosen == 'financial-institution'
          ? 'entrepreneurship-fund'
          : 'entrepreneurship-company',style:('color: ' + _vm.dimensions.Entrepreneurship.color)},[_c('img',{attrs:{"src":require("@/assets/images/2x_certify/2x_challenge/entrepreneurship.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Entrepreneurship")])]),_c('div',{staticClass:"flex items-center",class:_vm.entityChosen == 'fund-manager' || _vm.entityChosen == 'financial-institution'
          ? 'leadership-fund'
          : 'leadership-company',style:('color: ' + _vm.dimensions.Leadership.color)},[_c('img',{attrs:{"src":require("@/assets/images/2x_certify/leadership.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Leadership")])]),_c('div',{staticClass:"flex items-center",class:_vm.entityChosen == 'fund-manager' || _vm.entityChosen == 'financial-institution'
          ? 'employment-fund'
          : 'employment-company',style:('color: ' + _vm.dimensions.Employment.color)},[_c('img',{attrs:{"src":require("@/assets/images/2x_certify/employment.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Employment")])]),_c('div',{staticClass:"flex items-center",class:_vm.entityChosen == 'fund-manager' || _vm.entityChosen == 'financial-institution'
          ? 'product-service-fund'
          : 'product-service-company',style:('color: ' + _vm.dimensions.Products.color)},[_c('img',{attrs:{"src":require("@/assets/images/2x_certify/2x_challenge/products.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Products & Services")])]),_c('div',{staticClass:"flex items-center",class:_vm.entityChosen == 'fund-manager' || _vm.entityChosen == 'financial-institution'
          ? 'supplychain-fund'
          : 'supplychain-company',style:('color: ' + _vm.dimensions.SupplyChain.color)},[_c('img',{attrs:{"src":require("@/assets/images/2x_certify/2x_challenge/supplychain.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Supply Chain")])]),(_vm.entityChosen == 'fund-manager' || _vm.entityChosen == 'financial-institution')?_c('div',{staticClass:"flex items-center",style:('color: ' +
        _vm.dimensions.Portfolio.color +
        '; position: absolute; right: 77%; top: 25%;')},[_c('img',{attrs:{"src":require("@/assets/images/2x_certify/portfolio.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Portfolio")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }