<template>
  <div v-if="loading" class="card-section">
    <local-loader></local-loader>
  </div>
  <div v-else class="card-section relative">
    <div class="flex justify-between">
      <div class="font-bold text-xl">
        Environmental and Social Risks and Impacts
      </div>
      <div>{{ createdAt?.split("T")[0] || null }}</div>
    </div>
    <!--Completed-->
    <div v-if="status === 'completed'" class="mt-5 flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-24 w-24 text-green-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
    <!--In Progress-->
    <div
      v-else-if="status === 'in-progress'"
      class="flex items-center justify-between mt-5 pb-5"
    >
      <div class="w-3/5">
        <div class="text-right mb-1">
          <span class="text-[#4DC9BE] font-bold"> {{ percentage }}% </span>
          Completed
        </div>
        <k-progress
          style="width: 110%"
          :percent="percentage"
          color="#4DC9BE"
          :line-height="16"
          :show-text="false"
        ></k-progress>
      </div>
      <router-link class="primary-navy-button" to="/imm/surveys/e&s">
        Continue
      </router-link>
    </div>
    <div v-else class="w-full mt-10 h-52">
      Identify environmental and social exclusions to align your business
      practices with ethical standards and regulations.
    </div>
    <!--Links-->
    <div
      class="mt-8 flex gap-12 justify-between absolute bottom-0 pb-5 pr-12 w-full"
    >
      <div
        class="flex justify-left items-end gap-4 w-1/2 text-[#2177C1] font-bold text-sm"
      >
        <div>&lt; &gt; API integration</div>
        <div>
          <img src="@/assets/images/dashboard/info.svg" />
          Methodology
        </div>
      </div>
      <div v-if="status === 'completed'" class="flex justify-end gap-4 w-1/2">
        <router-link
          v-if="!user.featureFlags?.includes('sweef')"
          :class="[
            'secondary-navy-button',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
          to="/imm/surveys/e&s"
          :disabled="investeeId || user.featureFlags?.includes('sweef')"
        >
          Repeat
        </router-link>
        <router-link
          :class="[
            'primary-teal-button',
            {
              'opacity-50 cursor-not-allowed':
                investeeId || user.featureFlags?.includes('sweef'),
            },
          ]"
          to="/imm/surveys/e&s"
          :disabled="user.featureFlags?.includes('sweef')"
        >
          View results
        </router-link>
      </div>
      <div v-else-if="status === 'in-progress'"></div>
      <div v-else class="flex justify-end gap-4 w-1/2 items-end mt-40">
        <router-link
          :class="[
            'primary-navy-button',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
          to="/imm/surveys/e&s"
          :disabled="investeeId"
        >
          Launch
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import localLoader from "@/components/local-loader.vue";
import { getSurveyData } from "@/components/imm-components/IMMhelpers.js";

export default {
  name: "E&SCard",
  components: {
    localLoader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    investeeId: {
      type: String || null,
    },
  },
  data() {
    return {
      loading: true,
      percentage: 0,
      status: "not-started",
      score: 0,
      createdAt: "",
      surveyResponse: [],
    };
  },
  async mounted() {
    this.loading = true;
    const { surveyResponse, isComplete } = await getSurveyData(
      "e&s",
      this.investeeId
    );
    this.surveyResponse = surveyResponse;

    this.status = isComplete
      ? "completed"
      : surveyResponse.length
      ? "in-progress"
      : "not-started";

    this.loading = false;
  },
};
</script>

<style scoped>
.card-section {
  padding: 24px;
  gap: 24px;
  border-radius: 24px;
  background: #f4f6f9;
}
.primary-navy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #191b45;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}
.secondary-navy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #fff;
  background-color: #fff;
  color: #191b45;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}
.primary-teal-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #4dc9be;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}
</style>
