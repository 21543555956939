<template>
  <div class="rounded border-2 border-black bg-white">
    <div class="flex items-center justify-center pb-6">
      <div class="inline-flex items-center px-8 py-3 border bg-[#666461] text-white font-bold -mt-6">
        {{ title }}
      </div>
    </div>
    <ul v-if="title === 'DIMENSIONS'" class="space-y-6 px-8 pb-16">
      <li v-for="item in dimensions" :key="item.label" class="flex items-start gap-4">
        <div class="h-12 w-12">
          <CheckIcon v-if="item.isAllSubDimension" :color="item.color" class="h-12 w-12" />
          <XIcon v-else :color="item.color" class="h-12 w-12" />
        </div>
        <div>
          <h3 class="text-xl font-medium mb-0.5" :style="{ color: item.color }">{{ item.name }}</h3>
          <p class="text-lg font-normal">{{ item.value }}</p>
          <div v-for="twoxItem in item.twoxData" :key="twoxItem.name">
            <p v-for="(threshold, index) in twoxItem.threshold" :key="`threshold-${index}`"
              v-html="replaceGreaterThan(threshold)"></p>
          </div>
        </div>
      </li>
    </ul>
    <ul v-if="title === 'THEMES'" class="px-8 pb-4">
      <li v-for="item in items" :key="item.label" class="flex items-center gap-7 mb-8">
        <div class="px-8 py-3  border-4 border-gray-300 rounded-full font-bold text-gray-800 text-lg"
        :style="{ borderColor: item.color }"
        >
          {{ item.status }}
        </div>
        <div>
          <h3 class="text-xl font-medium">{{ item.label }}</h3>
          <p class="text-lg font-normal">{{ item.value }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import CheckIcon from './CheckIcon.vue';
import XIcon from './XIcon.vue';

export default {
  name: 'CertificationCard',
  components: { CheckIcon , XIcon },
  props: {
    title: String,
    items: Array,
    dimensionsEligible: Array,
    selectedOption : String,
  },
  methods: {
    replaceGreaterThan(item) {
      return item.replace('>=', '<span>≥</span>');
    },
  },
  computed: {
    dimensions() {
      if (this.title === 'DIMENSIONS') {
        return this.selectedOption === 'detailed' ? this.items : this.dimensionsEligible;
      }
      return this.items;
    },
  }
}
</script>
