<template>
    <div class="static">
      <gbvhPrivacyModal
        v-if="user.gbvhPrivacyPolicy == false"
        @pageReload="pageReload"
      ></gbvhPrivacyModal>
      <div
        style="display: flex"
        class="justify-center"
        v-if="user.gbvhPrivacyPolicy"
      >
        <img
          src="@/assets/images/2X-assessment/gbvh_roadmap_dark.png"
          alt="background-image"
          class="object-contain no-print w-64"
          style="height: 5rem; margin-top: 72px; margin-left: 80px"
        />
        <img
          src="@/assets/images/2X.png"
          alt="background-image"
          class="object-contain no-print w-64"
          style="height: 5rem; margin-top: 72px; margin-left: 30px"
        />
        <img
          src="@/assets/images/criterion.png"
          alt="background-image"
          class="object-contain no-print w-64"
          style="height: 5rem; margin-top: 72px; margin-left: 80px"
        />
        <img
          src="@/assets/images/logo-navy.png"
          alt="background-image"
          class="object-contain no-print w-64"
          style="
            height: 5rem;
            margin-top: 72px;
            margin-right: 80px;
            margin-left: 80px;
          "
        />
      </div>
      <div style="height: 8rem"></div>
      <div
        v-if="user.gbvhPrivacyPolicy"
        class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0"
      >
        <div class="flex flex-row mt-32 lg:mt-0 justify-center">
          <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
            <h1
              class="
                text-6xl
                tracking-tighter
                font-medium
                text-center
                print:text-7xl
              "
              style="color: #201747"
            >
              <span>GBVH Roadmap</span>
            </h1>
            <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
              <p
                class="
                  leading-7
                  font-medium
                  gap-10
                  text-justify
                  leading-7
                  font-medium
                  text-gray-600
                "
              >
                <span>
                  The GBVH Roadmap Assessment is a self-assessment diagnostic
                  tool. It is used by investors to instantly assess, analyze, and
                  plan for GBVH risk and GBVH reduction strategies. Complete the
                  self-assessment survey below to instantly assess where your
                  institution is on the GBVH Roadmap and plan for action that can
                  be taken to further your institution's strategic approach to
                  GBVH and GBVH risk reduction. Once the survey is complete, you
                  may view your results any time, repeat the assessment to view
                  changes, and download the .CSV file or PDF summary of results.
                </span>
              </p>
            </div>
          </div>
        </div>
        <br />
     
        <main class="pt-32" style="grid-template-columns: none !important">
          <div style="width: 100%; text-align: center">
            <section
              class="
                w-full
                container
                mx-auto
                px-4
                lg:px-0
                border-t border-gray-300
              "
              style="max-width: 960px"
            >
              <p>
                <iframe
                  class="airtable-embed"
                  :src="url"
                  frameborder="0"
                  onmousewheel=""
                  width="100%"
                  height="533"
                  style="background: transparent; border: 1px solid #ccc"
                ></iframe>
              </p>
            </section>
          </div>
        </main>
      </div>
          <!-- new assesment Modal-->
          <div class="fixed z-50 inset-0 overflow-y-auto" v-if="displayNewSharedPopup">
          <div
            class="
              flex
              items-end
              justify-center
              min-h-screen
              pt-4
              px-4
              pb-20
              text-center
              sm:block sm:p-0
            "
          >
            <div class="fixed inset-0 transition-opacity cursor-pointer">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
      
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
            >&#8203;
            <div
              class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                overflow-hidden
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle
              "
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
              style="max-width: 630px; width: 100%"
            >
              <span class="close" @click="closeDisplayNewSharedPopup()" style="right: 8px;">&times;</span>
              <div class="Modal_Readmorestyle">
                <div class="ModalInnerStyle">
                  <p style="font-weight: 600">
                    We are happy to see you back here! It looks like your organization already completed a GBVH Roadmap Assessment.
                    <router-link
                      to="/2X-Ecosystem/My-2X-Assessments"
                      style="color: rgb(0, 119, 200)"
                      >Check it out.</router-link
                    >
                  </p>
                  <p class="mt-3" style="font-weight: 600">You can still proceed to create a new assessment now and as many times in the future as you want. When you do this, the newest assessment survey data submitted will be displayed on your dashboard, and previous surveys will be moved to historical data points.</p>
                  <p class="mt-3" style="font-weight: 600">In addition, your teammates on this Equilo account will receive a new notification when you submit this completed survey so they can check out the updated data.</p>
                  <button
                    style="background-color: #fe5000; color: white"
                    class="
                      text-white
                      px-3
                      py-2
                      text-xs
                      gap-1
                      font-bold
                      rounded-lg
                      text-white
                      transition
                      duration-200
                      shadow
                      flex flex-row
                      items-center
                      justify-center
                      mr-4
                      ml-4
                    "
                    @click="repeatAssessmentLink()"
                  >
                    Ready to repeat your assessment?
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- close new assesment Modal -->
    </div>
  </template>
  
  <script>
  import auth from "@/helpers/auth";
  import env from "@/../env";
  const axios = require("axios");
  import Cookies from "@/helpers/cookies";
  import gbvhPrivacyModal from "@/components/gbvhPrivacyModal.vue";
  export default {
    name: "GBVH",
    data: function () {
      return {
        user: [],
        active: false,
        // paid: false,
        // gbvpaid: false,
        access2X: false,
        dealPipelineStatus: [],
        displayNewSharedPopup:false
      };
    },
    components: {
      gbvhPrivacyModal,
    },
    async created() {
      this.user = await auth.me().catch(() => {
        console.log("Error login");
      });
      if (this.user.gbvhPrivacyPolicy) {
        this.dealPipelineStatus = await this.checkDealPipelineStatus();
      }
    },
    methods: {
      pageReload() {
        window.location.reload();
      },
      mouseOver: function (index) {
        this.active = index;
      },
      checkDealPipelineStatus() {
        return new Promise(function (resolve) {
          axios
            .get(env.apiUrl + "/assesment2x/check-deal-pipeline-status", {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        });
      },
       existingAssesmentCount() {
        return new Promise(function (resolve) {
          axios
            .get(env.apiUrl + "/gbvh/assessment-count", {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        });
      },
      async viewNewAssessmentPermission(){
  
        let data = await this.existingAssesmentCount();
        
        if(!this.user.gbvhRoadmapAssessment){
          this.$router.push({ name: 'GBVH' });
        }else if(data.totalLength > 0){
          this.displayNewSharedPopup = true;
        } else {
          this.$router.push({ name: 'GBVHNewAssesment' });
        }
      },
      async closeDisplayNewSharedPopup() {
        this.displayNewSharedPopup = false;
      },
      repeatAssessmentLink(){
        this.$router.push({ name: 'GBVHNewAssesment' });
  
      }
    },
    computed: {
      url() {
        return (
          "https://airtable.com/embed/shrIFufXVBubFxzue?backgroundColor=orange&prefill_Equilo email ID="+this.user.email
        );
      },
    },
    async mounted() {
      this.$store.commit("resetCitations");
      this.$store.commit("setBreadcrumbs", [
        {
          name: "GBVH",
          url: "/gbvh",
        },
        {
          name: "New Assessment",
          url: "",
        },
      ]);
  
      // if (this.user.organization.subscription.price < 1) {
      //   this.paid = false;
      // } else {
      //   this.paid = true;
      // }
      if (this.user.access2x) {
        this.access2X = true;
      } else {
        this.access2X = false;
      }
      // if (env.gbvteams.includes(this.user.organization.name)) {
      //   this.gbvpaid = true;
      // } else {
      //   this.gbvpaid = false;
      // }
      this.$store.commit("downloadable", false);
      this.$store.commit("isFreeState", true);
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: -8px;
    position: relative;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    border-radius: 20px;
  
    .selected-class {
      background-color: green;
    }
  }
  
  .Modal_Readmorestyle {
    padding: 30px 0 10px 35px;
    position: relative;
    display: flex;
    .ModalInnerStyle {
      max-height: 630px;
      overflow: auto;
      background: #fff;
      padding-right: 30px;
      h1 {
        font-weight: bold;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
        text-align: center;
        padding-bottom: 30px;
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        text-align: left;
        line-height: 18px;
        color: #1b1b1b;
      }
      button {
        margin: 30px auto;
        order: none;
        padding: 15px 32px;
        font-size: 16px;
      }
    }
    .cross_Icon {
      position: absolute;
      top: 15px;
      right: 25px;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin: 0 !important;
        cursor: pointer;
      }
    }
  }
  </style>