<script>
/*
  IMPORTANT NOTE:

  - Do not use stereotype colors. but use turqoise for women & navy blue for males.
  - Make sure the Y axe is always 100%
  - Fix the label



  THIS CHART TYPE IS A PERCENTAGE!!!!!


*/


import { Bar } from 'vue-chartjs'
import env from '@/../env.js';

export default {
  name: 'barChartVertical',
  extends: Bar,
  props: ['chartData', 'chartLabels', 'country', 'indicator'],
  data: function () {
      return {
        max: 100,
        beginAtZero: true,
        labels: [],
        data: [],
        datasets: [],
        colors: [env.geoColors.countrybar, env.geoColors.regionbar, env.geoColors.incomebar, env.geoColors.lmicbar, env.chartColors.jotaColor, env.chartColors.muColor, env.chartColors.piColor, env.chartColors],
        line: [],
      }
  },
  methods: {
    toTitleCase: function(phrase){
        return (phrase || '').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    changeText: function(text){
      this.country.values['country'] = this.country.humanName;
      this.country.values['region'] = this.country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' : this.country.region+ ' Region';
      this.country.values['income'] = this.toTitleCase(this.country.income)+ ' Group';

      if(this.indicator && this.indicator.dataType === 'MAX50'){
        this.max = 50;
      }else if(this.indicator && this.indicator.dataType === 'GBVSEX'){
        this.max = 1.10;
        this.beginAtZero = false;
      }else if(this.indicator && this.indicator.dataType === 'PEOPLE'){
        this.max = undefined; // Undefined, as this will remove the max
      }

      const keys = Object.keys(this.country.values);
      for (var k = 0; k < keys.length; k++) {
        const key = keys[k];
        let value = this.country.values[key];
        text = text.toString().split('{{'+key+'}}').join(value);
        // let's try to convert to a number
        if(!isNaN(Number(text))){
          text = Number(text);
        }
      }
      return text;
    },
    
    fillIn: function(array){
      if(array && array.length > 0){
        for (var i = 0; i < array.length; i++) {

          // Replace Global
          if(array[i] == 'LMIC'){
            array[i] = 'Global'
          }


          array[i] = this.changeText(array[i]);
        }
        return array;
      } else {
        return []
      }
    }
  },
  mounted () {

    // When you edit this. Make sure you've read this:

    // https://stackoverflow.com/questions/28031873/make-x-label-horizontal-in-chartjs
    // https://stackoverflow.com/questions/56283361/how-do-i-place-a-new-line-in-a-label-with-chart-js


    const colorShemes = {
      country: env.geoColors.countrybar,
      lmic: env.geoColors.lmicbar,
      income: env.geoColors.incomebar,
      region: env.geoColors.regionbar,
      female: env.genderColors.female,
      male: env.genderColors.male,
    }

    this.labels = this.fillIn(this.chartLabels);
    this.data = this.chartData || [];
    for (var i = 0; i < this.data.length; i++) {

      let id = this.data[i].label.toLowerCase().split('{').join('').split('}').join('');
      if (id.includes("female & male") || id.includes("male & female")) {
        id = "male"
      } else if (id.includes("female") || id.includes("women") || id.includes("girl")) {
        id = "female"
      } else if (id.includes("male") || id.includes("men") || id.includes("boy")) {
        id = "male"
      }

      const color = colorShemes[id] || this.colors[i];


      this.datasets.push({
        label: this.changeText(this.data[i].label),
        data: this.fillIn(this.data[i].data),
        backgroundColor: color
      });
    }

    for (var l = 0; l < this.labels.length; l++) {
      this.labels[l] = this.labels[l].split(' ')
    }

    if (this.indicator.primaryField == 'ENV_TMPRCH_T_5505') {
      this.line = [{
                  type: 'line',
                  id: 'hLine',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: 2,  // data-value at which the line is drawn
                  borderWidth: 2,
                  borderColor: env.brandingColors.gray,
                  label: {
                      enabled: true,
                      position: "center",
                      content: '2°C Increase',
                      backgroundColor: env.brandingColors.gray,
                      //fontFamily: "basier"
                  }
              }];
        this.max = 3;
    }  else {
      this.max = 1.4; // The value was 5 but changed to 1.4 as mentioned in https://app.asana.com/0/1200022095349364/1200986818388571
    }


    const _self = this;
    this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
    }, {
        // fontFamily: "basier",
        // legend: {
        //   display: true,
        //   labels: {
        //     "fontSize": 13,
        //     fontFamily: "basier"
        //   }
        // },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: this.beginAtZero,
                    suggestedMax: this.max,
                    fontFamily: "basier",
                    fontSize: 12,
                    callback: function (value) {
                      if (_self.indicator.dataType === 'PERCENT') {
                        return (value < 5 && value != 0) ? (value.toFixed(1) + "%") : (value.toFixed(0) + "%"); // convert it to percentage
                      }
                      else {
                        //https://stackoverflow.com/questions/28523394/charts-js-formatting-y-axis-with-both-currency-and-thousands-separator  
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                          } else {
                            return value;
                          }
                      }
                    },
                    // max: this.max,
                },
            }],
            xAxes: [{
                ticks: {
                    fontSize: 13,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    fontFamily: "basier",
                }
            }]
        },
        tooltips: {
          titleFontFamily: "basier",
          bodyFontFamily: "basier",
          callbacks: {
              title: function(tooltipItems) {
                  //Return value for title
                  const tt = JSON.parse(JSON.stringify(tooltipItems[0])); // otherwise we change the title under the chart
                  const xLabel = tt.xLabel;
                  return xLabel.toString().split(',').join(' ') + '/'+_self.datasets[tooltipItems[0].datasetIndex].label;
              },
              label: function(tooltipItem) {

                if(_self.indicator && _self.indicator.dataType === 'PEOPLE'){
                  return Number( Math.round(tooltipItem.yLabel) || 0).toLocaleString();
                } else if (_self.indicator && _self.indicator.dataType === 'NUMBER') {
                  return Number((Math.round(tooltipItem.yLabel * 10)/10) || 0).toLocaleString();
                } else if (_self.indicator && _self.indicator.dataType === 'MONEY') {
                  return Number(Math.round(tooltipItem.yLabel || 0)).toLocaleString();
                } else {
                  return Number(tooltipItem.yLabel || 0).toFixed(1)+"%";
                }

              }
          }
      },
      annotation: {
        annotations: this.line
      }
    })
  }
}
</script>

<style>
</style>
