<template>
  <transition name="fadeIn" mode="out-in">
    <div class="static ecosystem-landing">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div class="container mx-auto px-4 lg:px-0 pb-32" v-if="endofcreated">
        <BreadCrumbs class="breadcrumbs-main-container mb-10"></BreadCrumbs>
        <div class="PoweredbyTitle_logo">
          <!--Logo-->
          <div class="PoweredbyTitle_logo">
            <div class="PoweredbyContentWrepper">
              <img
                src="@/assets/images/2X-Global_logo_transparent.svg"
                alt="background-image"
                style="height: 50px"
              />
            </div>
            <div style="display: flex; justify-content: flex-end" class="mb-5">
              <div class="Poweredby_logoImg mt-2">
                <div class="powered-text flex">
                  <span>Powered by:</span>
                </div>
                <img
                  src="@/assets/images/logo-navy.png"
                  alt="background-image"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Content Wrepper -->
        <div class="ContentWrapper w-12/12">
          <!-- <div class="container mx-auto">
            <div class="flex flex-col mx-auto w-11/12 mt-12 mb-12">
              <a-steps progress-dot :current="this.current_step" size="small" @change="progressClick">
                <a-step title="Step 1" description="2X Assessment" />
                <a-step title="Step 2" description="Assessment results & Action plan" />
                <a-step title="Step 3" description="Upload Documents" />
                <a-step title="Step 4" description="Verification in Progress" />
                <a-step title="Step 5" description="2X Global Certification" />
              </a-steps>
            </div>
          </div> -->
          <!-- <div class="PoweredbyContentWrepper">
            <div class="PoweredbyTitleWrepper">
              <h3
                class="text-3xl tracking-tighter font-bold print:text-7xl"
                style="color: #201747; max-width: 845px"
              >
                <span>Get Your 2X Certification</span>
              </h3>
            </div>
          </div> -->
          <div class="container flex items-center mb-3 mt-5">
            <div class="flex">
              <h1
                class="text-6xl tracking-tighter font-medium print:text-7xl"
                style="color: #201747; max-width: 845px"
              >
                <span style="font-family: Work Sans">
                  {{
                    this.$route.path.includes("full-assessment")
                      ? "Take the 2X Certification Self-Assessment"
                      : "Take the 2X Challenge Self-Assessment"
                  }}</span
                >
              </h1>
            </div>
            <div class="flex mx-auto mr-0 w-12/12">
              <div class="estimated-time flex items-center gap-2">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99175 1.66675C5.39175 1.66675 1.66675 5.40008 1.66675 10.0001C1.66675 14.6001 5.39175 18.3334 9.99175 18.3334C14.6001 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6001 1.66675 9.99175 1.66675ZM10.0001 16.6667C6.31675 16.6667 3.33341 13.6834 3.33341 10.0001C3.33341 6.31675 6.31675 3.33341 10.0001 3.33341C13.6834 3.33341 16.6667 6.31675 16.6667 10.0001C16.6667 13.6834 13.6834 16.6667 10.0001 16.6667Z"
                    fill="#717686"
                  />
                  <path
                    d="M10.4167 5.83325H9.16675V10.8333L13.5417 13.4583L14.1667 12.4333L10.4167 10.2083V5.83325Z"
                    fill="#717686"
                  />
                </svg>
                <span
                  >Est. time to complete:
                  {{ timeToCompleteComputed }} minutes</span
                >
              </div>
            </div>
          </div>
          <div class="container flex items-center mb-3">
            <div class="flex">
              <div
                class="flex gap-2 items-center text-xs font-bold cursor-pointer"
                style="color: #2177c1"
                @click="see2XThresholdCriteria()"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16675 4.83342H9.83342V6.50008H8.16675V4.83342ZM8.16675 8.16675H9.83342V13.1667H8.16675V8.16675ZM9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z"
                    fill="#2177C1"
                  />
                </svg>
                <span>See 2X Threshold Criteria</span>
              </div>
            </div>
            <div class="flex mx-auto mr-0 w-12/12">
              <a
                href="https://storage.equilo.io/api-assets/2X%20Certification_Detailed%20indicator%20questions_vCompanies_April%202024.pdf"
                download="Certification_Detailed_Questions.pdf"
                target="_blank"
                class="coming-soon-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center outline outline-2 outline-offset-2"
              >
                <span class="pr-1">Download questions in advance</span>
              </a>
            </div>
          </div>
          <div id="jotform-box" style="border: 1px solid #ccc">
            <Survey
              v-if="current_module >= 0"
              :survey-data="surveyData"
              :survey-json="surveyJson"
              :user="user"
              :survey-id="surveyId"
              :answer-again="answerAgain"
              :id="
                uuid ==
                certificationSurveyData.filter(
                  (data) => data.surveyId == $route.params.surveyURL
                )?.[0]?.uuid
                  ? userSurveyDataId
                  : null
              "
              :isComplete="isComplete"
              :userType="entityChosen"
              :entityType="entityChosen"
              :COM_Commitment="COM_Commitment"
              :isEmploymentEligible="isEmploymentEligible"
              :key="COM_Commitment + current_module + surveyData"
              :surveyType="surveyType"
              :skipFlags=" this.$route.path.includes('/full-assessment')
                  ? null
                  : ['minimum_requirements']
              "
              :uuid="uuid"
              @surveyComplete="surveyComplete"
              @survyeEdited="survyeEdited"
            />
          </div>
          <div
            v-if="entitySubmit"
            class="w-12/12"
            style="
              background: transparent;
              border-bottom: 1px solid #ccc;
              border-right: 1px solid #ccc;
              border-left: 1px solid #ccc;
            "
          >
            <div style="height: 60px" class="flex items-center mr-6">
              <div
                class="flex items-center gap-5"
                style="margin-left: auto; margin-right: 0"
              >
                <!-- <span class="text-sm font-bold">Chapters remaining: {{ number_of_modules - current_module - 1 }}</span>
                <button class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 items-center hidden"
                  @click="previousModule()">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.99996 3.33325L11.175 4.50825L6.52496 9.16659H16.6666V10.8333H6.52496L11.175 15.4916L9.99996 16.6666L3.33329 9.99992L9.99996 3.33325Z"
                      fill="#fff" />
                  </svg>
                  <span class="pr-1">Edit previous modules</span>
                  <span v-if="this.current_module >= number_of_modules-1" class="pr-1">Next Chapter</span>
                </button> -->
                <button
                  class="all-modules-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex items-center"
                  @click="goToAllModules"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  Go to all modules
                </button>
                <button
                  class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex items-center"
                  @click="saveCurrentProgress"
                  :disabled="isCurrentButtonActive < 1"
                  :class="{
                    'opacity-50 cursor-not-allowed': isCurrentButtonActive < 1,
                  }"
                >
                  Save current progress
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div v-if="$route.query.twoxRepeatAsses === 'true' && allUUIDsEq && !($route.path.includes('2x-intake'))">
        <equilo-modal
       :isVisible="showModal"
       @close="showModal = false"
       title="Repeat 2X Assessment"
       size="3xl"
       :custom-transition="true"
      enter-active-class="transition-opacity duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      :prevent-backdrop-click="true"
      :confirmText="'go to 2X intake survey'"
      @confirm="handleConfirm"
       >
        <p class="font-medium leading-8 text-base text-[#201747]">
          Repeat the <span class="!text-yellow-500 font-semibold">{{ this.$route.path.includes('full-assessment') ? '2X Certification Self-Assessment' : '2X Challenge Self Assessment' }}
          </span>  by going through the General Intake module. Once you've reviewed and are ready to submit your data, simply click Complete.
        </p>
      </equilo-modal>
      </div>
    </div>
  </transition>
</template>
<script>
import auth from "@/helpers/auth";
import { ref } from "vue";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import Survey from "@/components/surveys/survey.vue";
import apiService from "@/helpers/apiServices.js";
import EquiloModal from '@/components/common/EquiloModal.vue';

export default {
  name: "twoxCertifyAssessmentSurveyPage",
  data: function () {
    return {
      user: [],
      endofcreated: false,
      languagePref: "&language=en",
      displayNewSharedPopup: false,
      requestAdminModal: false,
      requestportfoliopermissionModal: false,
      sharedAssesmentData: [],
      current_step: 0,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      divWidth: 0,
      current_module: -1,
      number_of_modules: 0,
      modules: [],
      entityChosen: ref("None"),
      entitySubmit: false,
      timeToComplete: 5,
      uuid: "",
      certificationAssessData: [],
      certAlreadyFilled: false,
      certificationSurveyData: [],
      customSurveys: {
        intake: "2x-intake",
        governance: "2x-governance",
        entrepreneurship: "2x-entrepreneurship",
        leadership: "2x-lead",
        employment: "2x-employment",
        supplyChain: "2x-supply-chain",
        productsServices: "2x-products-services",
        commitment: "2x-commitment",
        esg: "2x-esg",
      },
      surveyData: null,
      isComplete: false,
      surveyJson: {},
      surveyId: "",
      answerAgain: false,
      userSurveyDataId: null,
      COM_Commitment: [],
      certificationScoreData: [],
      thresholds: [],
      thresholdsData: [],
      workforceThreshold: "",
      srManagerThreshold: "",
      boardThreshold: "",
      isCurrentButtonActive: "",
      surveyType: { minReq: false, fullAssess: false },
      isAllMinReqSurveysCompleted: false,
      isAllFullSurveysCompleted: false,
      currentModuleName: "",
      allUUIDsEq:null,
      showModal: true
    };
  },
  components: {
    pageLoader,
    BreadCrumbs,
    Survey,
    EquiloModal,
  },
  async created() {},
  methods: {
    isFullSurveyCompleted(surveyUrl) {
      return this?.certificationSurveyData?.some(
        (survey) =>
          survey.surveyId === surveyUrl &&
          survey?.metadata?.fullAssess &&
          survey.isComplete
      );
    },
    checkSurveysCompleted() {
      let minReqSurveyCount = 0;
      let fullSurveyCount = 0;
      if (this.certificationSurveyData) {
        this.certificationSurveyData.forEach((data) => {
          if (
            (data?.metadata?.minReq && data.isComplete) ||
            this.isFullSurveyCompleted("2x-intake")
          ) {
            minReqSurveyCount++;
          }
          if (data?.metadata?.fullAssess && data.isComplete) {
            fullSurveyCount++;
          }
        });
      }
      if (
        (this.entityChosen == "fund-manager" ||
          this.entityChosen == "financial-institution" ||
          this.entityChosen == "Institutional investor") &&
        minReqSurveyCount == 10
      ) {
        this.isAllMinReqSurveysCompleted = true;
      }
      if (
        (this.entityChosen == "sme" ||
          this.entityChosen == "corporate" ||
          this.entityChosen == "start-up") &&
        minReqSurveyCount == 9
      ) {
        this.isAllMinReqSurveysCompleted = true;
      }
      if (
        (this.entityChosen == "fund-manager" ||
          this.entityChosen == "financial-institution" ||
          this.entityChosen == "Institutional investor") &&
        fullSurveyCount == 10
      ) {
        this.isAllFullSurveysCompleted = true;
      }
      if (
        (this.entityChosen == "sme" ||
          this.entityChosen == "corporate" ||
          this.entityChosen == "start-up") &&
        fullSurveyCount == 9
      ) {
        this.isAllFullSurveysCompleted = true;
      }
    },
    saveCurrentProgress() {
      this.$root.$emit("saveCurrentProgressFromParent");
    },
    survyeEdited(val) {
      this.isCurrentButtonActive = val;
    },
    async fetchCertificationData() {
      await apiService
        .fetch2xCertificationData(
          this.entityChosen,
          this.$route?.query?.teamId,
          (this.$store.state.certPageAccess.isFullSurveyFill || this.$route.path.includes('/full-assessment')) ? "Full" : "Min"
        )
        .then(async (res) => {
          this.certificationScoreData = res.data.data;
          if(this.certificationScoreData && this?.certificationScoreData?.ISO) {
            await this.calculateThreshold();
          }
        });
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.certificationScoreData?.ISO &&
        this?.certificationScoreData?.GEN_General_13
      ) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;
      this.COM_Commitment = [
        this.workforceThreshold?.split('%')?.[0],
        this.srManagerThreshold?.split('%')?.[0],
        this.boardThreshold?.split('%')?.[0],
      ];
      this.endofcreated = true;
    },
    async getThresholdsData(recordId, sector) {
      await apiService.fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
      });
    },
    surveyComplete() {
      this.isCurrentButtonActive = false;
      setTimeout(() => {
        this.nextModule();
      });
    },
    async fetchSurvey() {
      try {
        let userType;
        switch (this.entityChosen) {
          case "start-up":
            userType = "start-up";
            break;
          case "sme":
            userType = "sme";
            break;
          case "corporate":
            userType = "corporate";
            break;
          case "fund-manager":
            userType = "fund-manager";
            break;
          case "Institutional investor":
            userType = "fund-manager";
            break;
          case "financial-institution":
            userType = "financial-institution";
            break;
        }

        const {
          data: { data },
          status,
        } = await axios.get(
          `${env.apiUrl}/surveys/${this.surveyId}?tag=${userType}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        if (status == 200) {
          this.surveyJson = data.json;
        }
      } catch (error) {
        console.log("Login error ", error);
      } finally {
        this.loading = false;
      }
    },
    see2XThresholdCriteria() {
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/Thresholds",
      });
      window.open(routeData.href, "_blank");
    },
    async nextModule() {
        const basePath = this.$route.path.includes("full-assessment") ? 
                        "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" :
                        "/2X-Challenge/2X-Challenge-Self-Assessment";

        const queryParams = {
          isRedirectBack: "fromSurveyModules",
        };

        if (this.$route.query.twoxRepeatAsses) {
          queryParams.twoxRepeatAsses = "true";
        }

        this.$router.push({
          path: basePath,
          query: queryParams,
        });
    },
    checkAlreadySurveyData() {
      if (this.certificationSurveyData && this.entityChosen) {
        this.surveyId = this.modules[this.current_module]?.surveyUrl;
        this.surveyData = this.certificationSurveyData.find(
          (survey) =>
            survey.surveyId === this.surveyId &&
            survey.tag === this.entityChosen
        );
      }
      // if survey has been answered already, mark iz                                                                                                                                                                                                                                                                                       sComplete as true
      if (this.surveyData) {
        this.userSurveyDataId = this.surveyData?._id;
        this.isComplete = this.surveyData?.isComplete;
        // this.answerAgain = true;
        // transform surveyData from array to object with key values
        let surveyDataObject = {};
        this.surveyData.surveyResponse.forEach((survey) => {
          surveyDataObject[survey.questionId] = survey.answer;
        });
        this.surveyData = surveyDataObject;
      }
    },
    async submit() {
      if (this.entityChosen == "Institutional investor") {
        if (
          this.user?.membership2xData ||
          this.$store.state.isDomainValid ||
          this.user.twoXCoInvestorPrivileges
        ) {
          this.$router.push({ path: "/submit-deal" });
        } else {
          this.$router.push({ path: "/2X-Ecosystem/create2Xprofile" });
        }
      }
      // this.current_module = 0;
      this.entitySubmit = true;

      this.modules = [
        {
          name: "General Intake",
          module: "1",
          surveyUrl: "2x-intake",
          var: "GENCertify100",
        },
        {
          name: "Governance & Accountability",
          module: "2",
          surveyUrl: "2x-governance",
          var: "GOVACCCertify100",
        },
        {
          name: "Entrepreneurship & Ownership",
          module: "3",
          surveyUrl: "2x-entrepreneurship",
          var: "ENTOWNCertify100",
        },
        {
          name: "Leadership",
          module: "4",
          surveyUrl: "2x-lead",
          var: "LEADCertify100",
        },
        {
          name: "Employment",
          module: "5",
          surveyUrl: "2x-employment",
          var: "EMPLOYCertify100",
        },
        {
          name: "Supply Chain",
          module: "6",
          surveyUrl: "2x-supply-chain",
          var: "SUPCHCertify100",
        },
        {
          name: "Products & Services",
          module: "7",
          surveyUrl: "2x-products-services",
          var: "PRODCertify100",
        },
      ];
      this.modules.push({
        name: "Commitment",
        module: "8",
        surveyUrl: "2x-commitment",
      });
      if (
        this.entityChosen == "fund-manager" ||
        this.entityChosen == "financial-institution" ||
        this.entityChosen == "Institutional investor"
      ) {
        this.modules.push({
          name: "Portfolio",
          module: "9",
          surveyUrl: "2x-portfolio",
          var: "PORTCertify100",
        });
      }

      this.modules.push({
        name: "Basic 2X ESG",
        module:
          this.entityChosen == "fund-manager" ||
          this.entityChosen == "financial-institution" ||
          this.entityChosen == "Institutional investor"
            ? "10"
            : "9",
        surveyUrl: "2x-esg",
        var: "",
      });

      this.modules.forEach((data) => {
        if (data.surveyUrl == this.$route?.params?.surveyURL) {
          this.current_module = parseInt(data.module) - 1;
        }
      });
      this.number_of_modules = this.modules.length;
      sessionStorage.setItem("entityChosen", this.entityChosen);
      this.surveyId = this.modules[this.current_module]?.surveyUrl;
      await this.fetchSurvey();
    },

    async progressClick(step) {
      this.current_step = step;
      if (this.current_step == 0) {
        this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
      } else if (this.current_step == 1) {
        this.$router.push({
          path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
        });
      } else if (this.current_step == 2) {
        this.$router.push({
          path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
        });
      } else if (this.current_step == 3) {
        this.$router.push({ path: "/2X-Ecosystem/2X-Certification/Verification" });
      } else if (this.current_step == 4) {
        this.$router.push({
          path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
        });
      }
    },
    mouseOver: function (index) {
      this.active = index;
    },
    //     downloadPDF() {
    //     let pdfURL = 'https://storage.equilo.io/api-assets/2X%20Certification_Detailed%20indicator%20questions_vCompanies_April%202024.pdf';
    //     // let link = document.createElement('a');
    //     // link.href = pdfURL;
    //     // link.download = '2X_Certification_Detailed_indicator_questions_vCompanies_April_2024.pdf';
    //     // document.body.appendChild(link);
    //     // link.click();
    //     // document.body.removeChild(link);
    //     fetch(pdfURL)
    //       .then(response => response.blob())
    //       .then(blob => {
    //           var link = document.createElement('a');
    //           var url = URL.createObjectURL(blob);
    //           link.href = url;
    //           link.download = '2X Certification_Detailed indicator questions_vCompanies_April 2024.pdf';
    //           document.body.appendChild(link);
    //           link.click();
    //           document.body.removeChild(link);
    //           URL.revokeObjectURL(url);
    //       })
    //       .catch(error => console.error('Error fetching the PDF:', error));
    // },

    goToAllModules() {
      const basePath = this.$route.path.includes("full-assessment") ? 
                        "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" :
                        "/2X-Challenge/2X-Challenge-Self-Assessment";

        const queryParams = {
        };
        if (this.$route.query.twoxRepeatAsses) {
          queryParams.twoxRepeatAsses = true;
        }
        this.$router.push({
          path: basePath,
          query: queryParams,
        });
    },
    allUUIDsSame(data) {
        if (data.length <= 1) return true;
        const firstUUID = data[0].uuid;
        for (let i = 1; i < data.length; i++) {
          if (data[i].uuid !== firstUUID) {
            return false;
          }
        }
      return true;
    },
    handleConfirm() {
        const routeName = this.$route.path.includes('full-assessment') ? 'twoxCertifyFullAssessmentSurveyPage' : 'twoxCertifyAssessmentSurveyPage';
        const query = this.user?.surveyData?.length && this.$route.path.includes('2X-Certification-Self-Assessment')
          ? { entityType: this.entityChosen }
          : { entityType: this.entityChosen };

        if (this.$route.query.twoxRepeatAsses) {
          query.twoxRepeatAsses = "true";
        }

        this.$router.push({
          name: routeName,
          params: { surveyURL: '2x-intake' },
          query: query
        }).then(() => {
          this.$nextTick(() => {
            window.location.reload();
          });
        });
      }
  },
  computed: {
    progressBarOptions() {
      return {
        text: {
          hideText: true,
        },
        progress: {
          color: "#2BD4C4",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 10,
          width: this.divWidth * 0.95,
          progressPadding: 0,
          type: "line",
        },
      };
    },
    timeToCompleteComputed() {
      if (this.currentModuleName === "General Intake") {
        return 5;
      } else {
        return (
          this.modules.length * 5 - this.certificationSurveyData?.length * 5
        );
      }
    },
    isEmploymentEligible() {
      let val;
      if (this.entityChosen == "fund-manager") {
        val = this.certificationScoreData["EMPLOY_2X_304"];
      } else if (this.entityChosen == "financial-institution") {
        val = this.certificationScoreData["EMPLOY_2X_303"];
      } else if (this.entityChosen == "start-up") {
        val = this.certificationScoreData["EMPLOY_2X_300"];
      } else if (this.entityChosen == "sme") {
        val = this.certificationScoreData["EMPLOY_2X_301"];
      } else if (this.entityChosen == "corporate") {
        val = this.certificationScoreData["EMPLOY_2X_302"];
      }
      return val;
    },
  },
  watch: {
    // async current_module() {
    //   let surveyURL = this.$route.params.surveyURL || this?.modules[this.current_module]?.surveyUrl;
    //   if (!surveyURL) return;
    //   this.surveyId = surveyURL;
    //   if (this.surveyId) {
    //     await this.fetchSurvey();
    //   }
    // }
    modules: {
      handler(newVal) {
        const foundModule = newVal.find(
          (obj) => obj.surveyUrl === this.$route?.params?.surveyURL
        );
        this.currentModuleName = foundModule?.name;
      },
      deep: true,
    },
  },
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.$store.dispatch("fetchValidDomain");
    await this.$store.commit(
      "setCertPageStatus",
      this.user?.status2xCertification
    );
    await apiService.fetch2xUserSurvey("2x").then(async (response) => {
      this.certificationSurveyData = await response.data.data;
    });
    const isIntake = this.certificationSurveyData.filter(
      (data) => data.surveyId === "2x-intake"
    );
    if (!isIntake.length && this.$route?.params?.surveyURL !== "2x-intake") {
      this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
    }
    if (this.certificationSurveyData) {
      this.certificationSurveyData.forEach((data) => {
        if (data.surveyId == "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    
    let surveyData = this.certificationSurveyData || [];
    const { certPageAccess } = this.$store.state;
    const isSurveyFilled = certPageAccess.isMinSurveyFill || certPageAccess.isFullSurveyFill;
    const isRepeatAssessment = this.$route.query.twoxRepeatAsses === "true";

    if (surveyData.length === 0) {
      this.uuid = Date.now();
    } else if (isSurveyFilled && this.allUUIDsSame(surveyData) && isRepeatAssessment && this.$route.path.includes('2x-intake')) {
      this.uuid = Date.now();
    } else {
      this.uuid = surveyData.find(data => data.surveyId === "2x-intake")?.uuid || Date.now();
    }
    this.allUUIDsEq = this.allUUIDsSame(surveyData)
    if (this.entityChosen && this.entityChosen !== 'None' && this.$route?.query?.entityType) {
      await this.fetchCertificationData();
    }
    if (
      this.$route?.query?.entityType &&
      this.$route?.params?.surveyURL &&
      this.$route?.params?.surveyURL == "2x-intake"
    ) {
      this.entityChosen = this.$route.query.entityType;
    }
    await this.submit();
    this.endofcreated = true;
    await this.checkAlreadySurveyData();
    if (this.certificationSurveyData) {
      await this.checkSurveysCompleted();
    }
    if (
      this.$route.path.includes("full-assessment")
    ) {
      this.surveyType = { minReq: true, fullAssess: true };
    } else {
      this.surveyType = { minReq: true, fullAssess: false };
    }
    this.divWidth = document
      .getElementById("jotform-box")
      ?.getBoundingClientRect()?.width;
    if (document.getElementById("line-progress")) {
      document.getElementById("line-progress").classList.add("mx-auto");
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", []);
    let baseQuery = this.$route.query.twoxRepeatAsses ? '?twoxRepeatAsses=true' : '';

      if (this.$route.path.includes("full-assessment")) {
        this.$store.commit("setNewBreadcrumbs", [
          {
            name: "2X Certification Self-Assessment",
            url: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" + baseQuery,
          },
          {
            name: `${this.currentModuleName}`,
            url: `/2X-Ecosystem/2X-Certification/full-assessment/${this.$route?.params?.surveyURL}` + baseQuery,
          },
        ]);
      } else {
        this.$store.commit("setNewBreadcrumbs", [
          { 
            name: "2X Challenge Self-Assessment", 
            url: "/2X-Challenge/2X-Challenge-Self-Assessment" + baseQuery 
          },
          {
            name: `${this.currentModuleName}`,
            url: `/2X-Ecosystem/2X-Certification/Assessment/${this.$route?.params?.surveyURL}` + baseQuery,
          },
        ]);
      }
  },
};
</script>
<style lang="scss" scoped>
.ContentWrapper {
  margin-top: 10px;

  .SelectionBlock {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 40px 20px;
    max-width: 1000px;

    .column-wrapper {
      flex-direction: column;
    }
  }

  .estimated-time {
    background: #e5e9f1;
    border-radius: 30px;
    padding: 8px 15px;
    font-size: 10px;
    font-weight: bold;
    color: #717686;
  }

  .next-button {
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: #191b45;
  }
  .all-modules-button {
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: #191b45;
  }
  .all-modules-button:hover {
    background: #221f52;
  }

  .standard-button {
    font-weight: bold;
    font-size: 10px;
    color: #191b45;
    border-style: solid;
    border-color: #191b45;
    border-width: 1px;
  }

  .coming-soon-button {
    font-weight: bold;
    font-size: 10px;
    color: #717686;
    border-style: solid;
    border-color: #717686;
    border-width: 1px;
  }

  p {
    color: #717686;
  }
}

h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.learn_more_button {
  cursor: pointer;
}

.cross_Icon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans !important;

  .breadcrumbs-main-container {
    margin-top: 0;
    padding-top: 7em;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      /* align-items: center; */
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

input {
  margin: 6px;
}

#SPAN_1:hover {
  color: #40a9ff;
}
</style>
