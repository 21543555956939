<script>
const _ = require("underscore");
import { Radar } from "vue-chartjs";
export default {
  extends: Radar,
  data: function () {
    return {
      axes: [],
      axeColors: [],
      max: [],
      one: [],
      hoverIndex: false,
      dimensionScores: [],
      labels: [],
    };
  },
  methods: {
    processData() {
      if (this.info && Array.isArray(this.info) && this.info.length > 0) {
        this.labels = this.info.map((item) =>
          item.surveyQuestion?.replace("Sub-dimension score", "").trim()
        );
        while (this.labels.length < 3) {
          this.labels.push("");
        }
        this.axes = this.info.map((item) =>
          item.surveyQuestion?.replace("Sub-dimension score", "").trim()
        );
        this.dimensionScores = this.info.map((item) => item.value)
        this.axeColors = this.info.map((item) => item.color);
      }
    },
    getLink(uri) {
      const result = this.info.filter((item) => item.surveyQuestion.replace("Sub-dimension score", "").trim() === uri);
      if (result.length > 0) {
        return encodeURIComponent(result[0].sub);
      }
    },
    formatLabel(str, maxwidth) {
      var sections = [];
      var words = str.split(" ");
      var temp = "";
      words.forEach(function (item, index) {
        if (temp.length > 0) {
          var concat = temp + " " + item;
          if (concat.length > maxwidth) {
            sections.push(temp);
            temp = "";
          } else {
            if (index == words.length - 1) {
              sections.push(concat);
              return;
            } else {
              temp = concat;
              return;
            }
          }
        }
        if (index == words.length - 1) {
          sections.push(item);
          return;
        }
        if (item.length < maxwidth) {
          temp = item;
        } else {
          sections.push(item);
        }
      });
      return sections;
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "r>gba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    calc: function (input) {
      if (typeof input !== undefined && input !== "Missing" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    abrev: function (input) {
      let abrevString = "";
      for (let i = 0; i < input.toString().split(" ").length; i++) {
        if (input.toString().split(" ")[i] !== "&") {
          abrevString += input.toString().split(" ")[i].substr(0, 1);
        }
      }
      return abrevString;
    },
  },
  props: [
    "paddingL",
    "paddingR",
    "paddingT",
    "paddingB",
    "info",
    "certificationScoreData",
  ],
  async mounted() {
    this.processData();
    const _self = this;
    this.max = [
      120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120,
      120, 120, 120, 120, 120, 120,
    ];
    this.one = [
      100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      100, 100, 100, 100, 100, 100,
    ];
    this.thresholdScores = [
      100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
      100, 100, 100, 100, 100, 100,
    ];
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            data: this.dimensionScores,
            label: String(this.certificationScoreData.assessmentYear),
            borderColor: "#E5B449",
            pointBackgroundColor: this.axeColors,
            pointBorderColor: this.axeColors,
            fill: false,
            backgroundColor: this.opacity("#E5B449"),
            pointHitRadius: 8,
            pointRadius: 6,
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        spanGaps: false,
        layout: {
          padding: {
            left: this.paddingL,
            right: this.paddingR,
            top: this.paddingT,
            bottom: this.paddingB,
          },
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item?.text?.includes("hide");
            },
            fontSize: 13,
            fontFamily: "basier",
            padding: 40,
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
            }
          },
          // Function to only hide Perfect geneder equality
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }
            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);
            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;
            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });
              if (!someShown) return;
            }
            meta.hidden = hidden;
            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },
        scale: {
          ticks: {
            display: false,
            beginAtZero: true,
            max: 120,
            min: 0,
            stepSize: 20,
          },
          pointLabels: {
            fontColor: this.axeColors,
            fontSize: 14,
            fontFamily: "basier",
            lineHeight: 2,
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
              e.target.style.cursor = "pointer";
              // Prevent from flashing.
              if (
                point[0]._index !==
                _self.hoverIndex + "_" + point[0]._datasetIndex
              ) {
                _self.hoverIndex =
                  point[0]._index + "_" + point[0]._datasetIndex;
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX + "px";
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-name").innerText =
                  _self.axes[point[0]._index].toString().replace(",", " ");
                document.getElementById("intersection-name").style =
                  "color: " +
                  _self.axeColors[point[0]._index] +
                  "; font-size: 16px;";
                document.getElementById("intersection-domain").style.display =
                  "none";
                document.getElementById("div-intersection-country").style =
                  "color: " + _self.axeColors[point[0]._index];
                document.getElementById("div-intersection-region").style =
                  "color: black";
                document.getElementById("div-intersection-income").style =
                  "color: gray; font-style: italic; font-size: 12px;";
                document.getElementById("lmic-avg").style.display = "none";
                document.getElementById(
                  "div-intersection-income"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-four"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-five"
                ).style.display = "none";
                document.getElementById(
                  "intersection-lmic-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-country-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-region-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-income-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "flex"; document.getElementById("intersection-theme-link").href = `/#/2X-Ecosystem/2X-Certification/sub-dimension?q=?${_self.getLink(_self.axes[point[0]._index].toString().replace(",", " "))}`;
                document.getElementById("intersection-theme-text").innerText = "Sub-dimension";
               
                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
                document.getElementById("intersection-country").innerText =
                  "Score: " +
                  Math.round(_self.dimensionScores[point[0]._index]) +
                  "%" +
                  "\n" +
                  "Year: " + new Date().getFullYear();
                document.getElementById(
                  "div-intersection-region"
                ).style.display = "none";
                document.getElementById("intersection-income").innerText = "";
                document.getElementById("intersection-income").style.display =
                  "block";
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
              e.target.style.cursor = "default";
            }
          },
        },
      }
    );
    const _self2 = this;
    window.addEventListener("beforeprint", function () {
      const Chart = _self2.$data._chart;
      Chart.resize();
    });
    setTimeout(function () {
      _self2.$data._chart.update();
    }, 1000);
  },
};
</script>

<style scoped>
.chartjs-render-monitor {
  height: 40rem !important;
}
</style>
