<template>
  <div>
    <div v-for="choice in question?.choices" :key="choice.value">
      <label class="flex items-center space-x-2">
        <input
          type="checkbox"
          :value="choice.value"
          v-model="localValue.selectedChoices"
          :checked="localValue.selectedChoices.includes(choice.value)"
          class="form-checkbox"
        />
        <span>{{ choice.value }}</span>
      </label>
    </div>

  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: ["question", "value"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("change", { selectedChoices: newValue.selectedChoices });
      },
    },
  },
};
</script>
