<template>
  <div class="flex flex-col gap-2">
    <input
        v-model="localValue"
        type="text"
        placeholder="Enter your answer"
        class="w-full p-2 border rounded"
    />
  </div>
</template>

<script>
export default {
    name: "TextInput",
    props: ["question", "value"],
    computed: {
        localValue: {
          get() {
            return this.value.answer;
          },
          set(newValue) {
            this.$emit("change", { answer: newValue });
          },
        },
    }
};
</script>