<template>
    <div>
        <PrelimsPage></PrelimsPage>
    </div>
</template>
<script>
import PrelimsPage from "./my2xCertification-PreliminaryResults.vue"
export default {
    name: "TwoXSelfAssessmentResults",
    components: {
        PrelimsPage
    }
}
</script>