<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity cursor-pointer">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style="max-width: 624px"
      >
        <div class="Modal_Readmorestyle p-6">
          <div class="ModalInnerStyle">
            <div class="heading-section flex justify-between items-center">
              <h1
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Please select your third party verifier and send them a message
                to get the verification process started.
              </h1>
            </div>
            <div class="select-section mt-5">
              <select
                name="partner"
                id="partner"
                v-model="selectedPartner"
                class="w-full"
                @change="handlePartnerChange"
              >
                <option disabled value="">Select Certification Partner</option>
                <option
                  v-for="(partner, ind) in partnersList"
                  :key="'partner' + ind"
                  :value="partner._id"
                >
                  {{ partner?.organization?.name }}
                </option>
                <option value="Unknown">Unknown</option>
              </select>
            </div>
            <div class="textarea-section mt-7 flex justify-between">
              <textarea v-model="message" placeholder="Type something... " />
            </div>
            <div class="mt-7 flex justify-between gap-4">
              <button
                class="cancel-button flex justify-center"
                @click="closeModal()"
              >
                Cancel
              </button>
              <button
                @click="sendRequestToPartner()"
                class="save-button flex justify-center"
                :class="{ 'pointer-events-none': !selectedPartner || !message }"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showUnknownOptionModal"
      class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
    >
      <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 class="text-lg font-semibold text-[#19486A]">Random Assignment</h2>
        <p class="mt-4 text-gray-600">
          You have selected <strong>Unknown</strong>. A third-party verifier
          will be randomly assigned, and you will be notified when this happens.
        </p>
        <div class="mt-6 flex justify-end space-x-3">
          <button
            @click="cancelUnknownSelection"
            class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            @click="confirmUnknownSelection"
            class="px-4 py-2 bg-[#19486A] text-white rounded-md hover:bg-[#153c58]"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiService from "@/helpers/apiServices.js";

export default {
  name: "AccreditedCertificationPartnerMOdal",
  data() {
    return {
      message: "",
      selectedPartner: "",
      showUnknownOptionModal: false,
    };
  },
  props: {
    user: { type: [Array, Object] },
    partnersList: { type: [Array, Object] },
    certificationId: { type: [String] },
  },
  methods: {
    handlePartnerChange() {
      if (this.selectedPartner === "Unknown") {
        this.showUnknownOptionModal = true;
      }
    },
    cancelUnknownSelection() {
      this.selectedPartner = "";
      this.showUnknownOptionModal = false;
    },
    confirmUnknownSelection() {
      this.showUnknownOptionModal = false;
    },
    closeModal() {
      this.$emit("close");
    },
    async sendRequestToPartner() {
      await apiService
        .sendRequestToThirdPartyPartner(this.selectedPartner, this.message)
        .then(async (response) => {
          if (response.status == 200) {
            this.$swal
              .fire({
                icon: "success",
                text: response.data.data,
                showConfirmButton: true,
              })
              .then(() => {
                document.location.reload();
              });
          } else {
            this.$swal.fire({
              icon: "error",
              text: response.data.data,
              showConfirmButton: true,
            });
          }
          await this.closeModal();
          this.selectedPartner = "";
        })
        .catch(async (error) => {
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message || error.response.data.error,
            showConfirmButton: true,
          });
          await this.closeModal();
          this.selectedPartner = "";
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.Modal_Readmorestyle {
  position: relative;
  display: flex;
  .ModalInnerStyle {
    background: #fff;
    .heading-section {
      gap: 28px;
    }
    .select-section {
      select {
        height: 32px;
        padding: 0 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 8px;
        color: #2c2e35;
      }
    }
    h1 {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #19486a;
    }

    p {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
    }
    .textarea-section {
      width: 552px;
      gap: 12px;
      textarea {
        margin: 0;
        min-width: 576px;
        min-height: 120px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 16px;
      }
      textarea::placeholder {
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #717686;
      }
    }
    .cancel-button {
      width: 280px;
      height: 40px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      gap: 8px;
      border: 1px solid #19486a;
      color: #19486a;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
    .save-button {
      width: 280px;
      height: 40px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      gap: 8px;
      background: #19486a;
      color: #fff;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
</style>
