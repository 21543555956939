<script>
import axios from "axios";
import { Radar } from "vue-chartjs";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import IntersectionScore from "./IntersectionScore";
// function to configure charts
import { getTaabColors } from "./ChartFunction";
const _ = require("underscore");

export default {
  name: "themeInterseection",
  extends: Radar,
  data: function () {
    return {
      country: [],
      countryData1: [],
      countryData5: [],
      countryData4: [],
      theme: {},
      regionalData: [],
      incomeData: [],
      globalData: [],
      axes: [],
      axeColors: [],
      max: [],
      one: [],
      intersections: [],
      countryIntersection: [],
      regionalIntersection: [],
      incomeIntersection: [],
      globalIntersection: [],
      fullAxes: [],
      domains: [],
    };
  },
  methods: {
    calc: function (input) {
      if (typeof input !== undefined && input !== "" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    toTitleCase: function (phrase) {
      return phrase
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    getCountry: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
            "/countries/" +
            _self.iso +
            "/sectors/" +
            _self.$route.params.sector,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getTheme: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
            "/reports/" +
            _self.iso +
            "/sectors/" +
            _self.$route.params.sector +
            "/themes/" +
            _self.themeId,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
  },
  props: ["iso", "themeId", "themeData", "countryD", "chartCategory"],
  //By Vatsal - mounted would be ideal, but keeping created as don't want to change preexisting code.
  async created() {
    // We will request the country OURSELF because of asyncness...
    // this.country = await this.getCountry(); // lets do an await before rendering other items.
    this.country = [];
    this.theme = await this.getTheme(); // lets do an await before rendering other items.
    this.theme = [];
    this.country = this.countryD.countries[0];
    this.theme = this.themeData.countries[0];
    this.intersections = this.theme.intersections;
    for (var i = 0; i < this.intersections.length; i++) {
      this.countryIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality,
          this.intersections[i].gender_empowerment,
          this.intersections[i].gender_parity
        )
      );
      this.regionalIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_region,
          this.intersections[i].gender_empowerment_region,
          this.intersections[i].gender_parity_region
        )
      );
      this.incomeIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_income,
          this.intersections[i].gender_empowerment_income,
          this.intersections[i].gender_parity_income
        )
      );
      this.globalIntersection.push(
        new IntersectionScore(
          this.intersections[i].gender_equality_global,
          this.intersections[i].gender_empowerment_global,
          this.intersections[i].gender_parity_global
        )
      );

      this.countryData1.push(
        this.calc(this.intersections[i].gender_equality || 0)
      );
      if (this.countryD.countries[1]) {
        this.regionalData.push(
          this.calc(
            this.countryD.countries[1].intersections[i].gender_equality || 0
          )
        );
      }
      if (this.countryD.countries[2]) {
        this.incomeData.push(
          this.calc(
            this.countryD.countries[2].intersections[i].gender_equality || 0
          )
        );
      }
      if (this.countryD.countries[3]) {
        this.countryData4.push(
          this.calc(this.countryD.countries[3].intersections[i].gender_equality)
        );
      }
      if (this.countryD.countries[4]) {
        this.countryData5.push(
          this.calc(this.countryD.countries[4].intersections[i].gender_equality)
        );
      }
      this.globalData.push(
        this.calc(this.intersections[i].gender_equality_global || 0)
      );

      this.fullAxes.push(this.intersections[i].theme);

      this.domains.push(this.intersections[i].taab);

      // this.axes.push(this.intersections[i].taab);
      if (this.chartCategory == 'theme') {
        this.axes.push(this.intersections[i].taab);
      }
      if (this.chartCategory == 'domain') {
        this.axes.push(this.intersections[i].theme);
      }

      this.max.push(1.4);
      this.one.push(1);
      const taabColors = getTaabColors();
      this.axeColors.push(taabColors[this.intersections[i].taab]);
    }

    const _self = this;
    this.renderChart(
      {
        labels: this.axes,
        datasets: [
          {
            data: this.countryData1,
            label: this.country.humanName,
            borderColor: env.geoColors.countryspider,
            pointBackgroundColor: env.geoColors.countryspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.countryspider),
            borderWidth: 4,
            pointHitRadius: 8,
          },
          {
            data: this.regionalData,
            label:
              this.countryD.countries[1] && this.countryD.countries[1].humanName
                ? this.countryD.countries[1].humanName
                : "hide",
            borderColor: env.geoColors.regionspider,
            pointBackgroundColor: env.geoColors.regionspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.regionspider),
            pointHitRadius: 8,
          },
          {
            data: this.incomeData,
            label:
              this.countryD.countries[2] && this.countryD.countries[2].humanName
                ? this.countryD.countries[2].humanName
                : "hide",
            borderColor: env.geoColors.incomespider,
            pointBackgroundColor: env.geoColors.incomespider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.lmicspider),
            pointHitRadius: 8,
          },
          {
            data: this.countryData4,
            label:
              this.countryD.countries[3] && this.countryD.countries[3].humanName
                ? this.countryD.countries[3].humanName
                : "hide",
            borderColor: env.chartColors.betaColor,
            pointBackgroundColor: env.chartColors.betaColor,
            fill: false,
            backgroundColor: this.opacity(env.chartColors.betaColor),
            pointHitRadius: 8,
          },
          {
            data: this.countryData5 ? this.countryData5 : [],
            label:
              this.countryD.countries[4] && this.countryD.countries[4].humanName
                ? this.countryD.countries[4].humanName
                : "hide",
            borderColor: env.taabColors.accessToResources,
            pointBackgroundColor: env.taabColors.accessToResources,
            fill: false,
            backgroundColor: this.opacity(env.taabColors.accessToResources),
            pointHitRadius: 8,
          },
          {
            data: this.globalData,
            label: "Global",
            borderColor: env.geoColors.lmicspider,
            pointBackgroundColor: env.geoColors.lmicspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.lmicspider),
            pointHitRadius: 8,
          },
          {
            data: this.one,
            label: "Perfect Equality",
            borderColor: "#3F37C9",
            pointRadius: 0,
            fill: false,
            borderWidth: 3,
          },
          {
            data: this.max,
            label: "hide",
            fill: false,
            pointBackgroundColor: this.axeColors,
          },
        ],
      },
      {
        legend: {
          position: "bottom",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              return !item.text.includes("hide");
            },
            fontSize: 13,
            fontFamily: "basier",
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
            }
          },
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }

            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items

            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);

            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;

            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });

              if (!someShown) return;
            }

            meta.hidden = hidden;

            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },
        hover: {
          onHover: function (e) {
            // return false;
            var point = this.getElementAtEvent(e);
            if (point.length > 0) {
              // Prevent from flashing.
              if (
                point[0]._index !==
                _self.hoverIndex + "_" + point[0]._datasetIndex
              ) {
                _self.hoverIndex =
                  point[0]._index + "_" + point[0]._datasetIndex;
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY - 105 + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX - 10 + "px";
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-name").innerText =
                  _self.fullAxes[point[0]._index].toString().replace(",", " ");
                document.getElementById("intersection-domain").innerText =
                  _self.domains[point[0]._index];
                document.getElementById(
                  "intersection-domain"
                ).style.backgroundColor = _self.axeColors[point[0]._index];
                document.getElementById("div-intersection-country").style =
                  "color: " + env.geoColors.countryspider;
                document.getElementById("div-intersection-region").style =
                  "color: " + env.geoColors.regionspider;
                document.getElementById("div-intersection-region").style.display = (_self.regionalData.length == 0) ? "none" : "flex";
                document.getElementById("div-intersection-income").style.display = (_self.incomeData.length == 0) ? "none" : "flex";
                document.getElementById("div-intersection-income").style =
                  "color: " + env.geoColors.incomespider;
                document.getElementById("lmic-avg").style =
                  "color: " + env.geoColors.lmicspider;
                document.getElementById("div-intersection-country-four").style =
                  "color: " + env.chartColors.betaColor;
                document.getElementById("div-intersection-country-five").style =
                  "color: " + env.taabColors.accessToResources;
                document.getElementById("intersection-country").innerText =
                  _self.country.humanName;
                document.getElementById("intersection-region").innerText = _self
                  .countryD.countries[1]
                  ? _self.countryD.countries[1].humanName
                  : "";
                document.getElementById("intersection-income").innerText = _self
                  .countryD.countries[2]
                  ? _self.countryD.countries[2].humanName
                  : "";
                document.getElementById("intersection-country-five").innerText =
                  _self.countryD.countries[4]
                    ? _self.countryD.countries[4].humanName
                    : "";
                document.getElementById("intersection-country-four").innerText =
                  _self.countryD.countries[3]
                    ? _self.countryD.countries[3].humanName
                    : "";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                // document.getElementById(
                //   "intersection-country-value"
                // ).style.display = "block";
                document.getElementById(
                  "div-intersection-country-four"
                ).style.display = (_self.countryData4.length == 0) ? "none" : "flex";
                document.getElementById(
                  "div-intersection-country-five"
                ).style.display = (_self.countryData5.length == 0) ? "none" : "flex";
                // document.getElementById(
                //   "intersection-region-value"
                // ).style.display = "block";
                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
                document.getElementById("div-intersection-country-six").style.display = "none";
                document.getElementById("div-intersection-country-seven").style.display = "none";
                document.getElementById("intersection-lmic-text").innerText =
                  "Global";
                document.getElementById(
                  "intersection-country-value"
                ).innerText =
                  _self.countryData1[point[0]._index] == null
                    ? _self.countryData1[point[0]._index] == 0
                      ? 0
                      : ""
                    : _self.countryData1[point[0]._index];
                document.getElementById(
                  "intersection-country-value-four"
                ).innerText =
                  _self.countryData4[point[0]._index] == null
                    ? _self.countryData4[point[0]._index] == 0
                      ? 0
                      : ""
                    : _self.countryData4[point[0]._index];
                document.getElementById(
                  "intersection-country-value-five"
                ).innerText =
                  _self.countryData5[point[0]._index] == null
                    ? _self.countryData5[point[0]._index] == 0
                      ? 0
                      : ""
                    : _self.countryData5[point[0]._index];
                document.getElementById("intersection-region-value").innerText =
                  _self.regionalData[point[0]._index] == null
                    ? _self.regionalData[point[0]._index] == 0
                      ? 0
                      : ""
                    : _self.regionalData[point[0]._index]; // || "";
                document.getElementById("intersection-income-value").innerText =
                  _self.incomeData[point[0]._index] == null
                    ? _self.incomeData[point[0]._index] == 0
                      ? 0
                      : ""
                    : _self.incomeData[point[0]._index]; // || "";
                document.getElementById("intersection-lmic-value").innerText =
                  _self.globalData[point[0]._index] == null
                    ? _self.globalData[point[0]._index] == 0
                      ? 0
                      : ""
                    : _self.globalData[point[0]._index];

                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "none";

                if (
                  document.getElementById("intersection-country-value")
                    .innerText == ""
                ) {
                  document.getElementById(
                    "intersection-scores-validity"
                  ).innerText = "";
                  document.getElementById(
                    "intersection-scores-power"
                  ).innerText = "";
                } else {
                  document.getElementById(
                    "intersection-scores-validity"
                  ).innerText = Number(
                    _self.intersections[point[0]._index].validity || 0
                  ).toFixed(2);
                  document.getElementById(
                    "intersection-scores-power"
                  ).innerText = Number(
                    _self.intersections[point[0]._index].power || 0
                  ).toFixed(2);
                }
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
            }
            if (e.fromElement == undefined) {
              document.getElementById("intersection-info").style.opacity = "";
            } else {
              document.getElementById("intersection-info").style.opacity = "1";
            }

            if (point.length) e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";

            //e.target.style.cursor = 'pointer';
          },
        },
        scale: {
          angleLines: {
            display: true,
            lineWidth: 1,
          },
          gridLines: {
            display: true,
            lineWidth: 1,
          },
          pointLabels: {
            fontColor: this.axeColors,
            fontSize: 14,
            fontFamily: "basier",
            lineHeight: 2,
            onClick: function () { },
          },
          ticks: {
            suggestedMax: 1.24,
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
          callbacks: {
            title: function () {
              return null;
            },
            label: function (tooltipItem, data) {
              const tooltip = data.datasets[tooltipItem.datasetIndex];
              const value = tooltip.data[tooltipItem.index];
              return value === 0 ? null : tooltip.label + ": " + value;
            },
          },
          backgroundColor: "rgb(253, 253, 253)",
          xPadding: 20,
          yPadding: 20,

          titleFontStyle: "semi-bold",
          titleFontColor: "#0066ff",
          titleFontFamily: "basier",
          titleFontSize: 10,
          titleSpacing: 20,
          titleMarginBottom: 10,

          bodyFontColor: "#333333",
          bodyFontSize: 14,
          bodyFontFamily: "basier",
          displayColors: false,

          cornerRadius: 2,
          borderColor: "rgb(240, 240, 240)",
          borderWidth: 3,
        },
      }
    );
  },
};
</script>

<style>

</style>
