<template>
  <div>
    <button
      v-if="button"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
      @click="!isAdded ? addChart() : deleteChart()"
    >
      {{ !isAdded ? "Add Chart" : "Delete Chart" }}
    </button>
    <div ref="chart" class="flex flex-row mx-auto"></div>
  </div>
</template>

<script>
import * as d3 from "d3";
import env from "@/../env.js";
import { processDataset, colorSchemes, colors } from "./utils.js";

export default {
  name: "D3BarChartVertical",
  props: [
    "chartData",
    "chartLabels",
    "country",
    "indicator",
    "button",
    "colors",
  ],
  data() {
    const margin = { top: 20, right: 100, bottom: 30, left: 100 };
    return {
      svg: null,
      margin: margin,
      width: 960 - margin.left - margin.right,
      height: 500 - margin.top - margin.bottom,
      max: 100,
      beginAtZero: true,
      labels: [],
      data: [],
      dataSets: [],
      line: [],
      d3Data: [],
      isAdded: false,
    };
  },
  mounted() {
    const [newData, labels] = processDataset(
      this.chartData,
      this.chartLabels,
      colorSchemes,
      colors,
      this.country,
      this.indicator
    );
    this.labels = labels;
    this.dataSets = newData;

    this.labels.map((label, index) => {
      this.d3Data.push({
        label,
        male: this.dataSets[0].data[index],
        female: this.dataSets[1].data[index],
      });
    });

    this.createBarChart(
      this.$refs.chart,
      this.width / 2,
      this.height / 2,
      true,
      120
    );
  },

  methods: {
    createBarChart(
      chartContainer,
      width = this.width,
      height = this.height,
      rotate = false,
      marginBottom = this.margin.bottom
    ) {
      // check for chart data
      if (!this.d3Data || this.d3Data.length === 0) {
        console.error("No data available for the chart.");
        return;
      }

      this.svg = d3
        .select(chartContainer)
        .append("svg")
        .attr("width", width + this.margin.left + this.margin.right)
        .attr("height", height + this.margin.top + marginBottom)
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );

      // set scale on x and y
      const xScale = d3
        .scaleBand()
        .range([0, width])
        .paddingInner(0.2)
        .padding(0.2);
      const yScale = d3.scaleLinear().rangeRound([height, 0]);

      const g = this.svg.append("g");

      // adds domains
      xScale.domain(this.d3Data.map((d) => d.label));
      yScale.domain([0, d3.max(this.d3Data, () => Math.max(0, 100))]);

      // appends chart lines
      g.append("g")
        .attr("class", "axis")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale))
        .selectAll("text")
        .attr("dx", "0.1em")
        .attr("dy", "0.5em")
        .attr("transform", rotate ? "rotate(45)" : "rotate(0)")
        .attr("text-anchor", rotate ? "start" : "center");

      g.append("g")
        .attr("class", "axis")
        .call(d3.axisLeft(yScale))
        .selectAll(".tick text")
        .text((d) => `${d}%`);

      // creates tooltip
      const tooltip = d3
        .select(this.$refs.chart)
        .append("div")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background", "black")
        .style("color", "white")
        .style("border-radius", "5px")
        .style("padding", "10px");

      // appends bars
      this.d3Data.forEach((d) => {
        g.append("rect")
          .attr("x", xScale(d.label))
          .attr("y", height)
          .attr("width", xScale.bandwidth() / 2)
          .attr("height", 0)
          .attr("fill", env.genderColors.male)
          .on("mousemove", function (event) {
            return tooltip
              .style("visibility", "visible")
              .text(`${d.male.toFixed(2)}%`)
              .style("top", `${event.pageY - 65}px`)
              .style("left", `${event.pageX - 200}px`);
          })
          .on("mouseout", function () {
            return tooltip.style("visibility", "hidden");
          })
          .transition()
          .duration(800)
          .attr("y", yScale(d.male))
          .attr("height", height - yScale(d.male));

        g.append("rect")
          .attr("x", xScale(d.label) + xScale.bandwidth() / 2)
          .attr("y", height)
          .attr("width", xScale.bandwidth() / 2)
          .attr("height", 0)
          .attr("fill", env.genderColors.female)
          .on("mousemove", function (event) {
            return tooltip
              .style("visibility", "visible")
              .text(`${d.female.toFixed(2)}%`)
              .style("top", `${event.pageY - 65}px`)
              .style("left", `${event.pageX - 200}px`);
          })
          .on("mouseout", function () {
            return tooltip.style("visibility", "hidden");
          })
          .transition()
          .duration(800)
          .attr("y", yScale(d.female))
          .attr("height", height - yScale(d.female));
      });
    },
    addChart() {
      d3.select(this.$refs.chart).selectAll("*").remove();
      this.createBarChart(
        this.$refs.chart,
        this.width / 2,
        this.height / 2,
        true,
        120
      );
      this.createBarChart(
        this.$refs.chart,
        this.width / 2,
        this.height / 2,
        true,
        120
      );
      this.isAdded = true;
    },
    deleteChart() {
      d3.select(this.$refs.chart).selectAll("*").remove();
      this.createBarChart(this.$refs.chart, this.width);
      this.isAdded = false;
    },
  },
};
</script>
