<template>
  <div class="flex flex-col item-center justify-center">
    <div class="flex flex-row items-center justify-center print:flex-col">
      <!-- <img
                  class="h-16 print:h-auto print:w-64"
                  src="@/assets/images/2X-assessment/new2X-assessment.png"
                  /> -->
      <h1
        class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
      >
        COOKIE POLICY
      </h1>
    </div>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Please read this Cookie Policy carefully as it contains important
      information on who we are and how we use cookies on our website. This
      Policy should be read together with our
      <router-link to="/dataprivacy" target="_blank"
        ><a class="a-tag-decorate" target="_blank"
          >Privacy Notice</a
        ></router-link
      >
      which sets out how and why we collect, store, use and share personal
      information generally, as well as your rights in relation to your personal
      information and details of how to contact us and supervisory authorities
      if you have a complaint.
    </p>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      WHO WE ARE
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      This website
      <a class="a-tag-decorate" href="https://www.equilo.io/" target="_blank"
        >(https://www.equilo.io/)</a
      >
      and the products, services and features we make available to you as part
      of our website from time to time (collectively, the “Service”) are
      provided by Equilo Inc. (“we”, “our” or “us”).
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Our Service may link to other websites and services owned and operated by
      certain third parties to make additional products and services available
      to you. Those other third party websites and services may also gather
      information about you in accordance with their own separate privacy and
      cookie policies. For privacy and cookie information relating to these
      other third party websites, please consult their privacy and cookie
      policies as appropriate.
    </p>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      COOKIES - WHAT ARE THEY?
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Like many other websites, our website uses cookies. Some of the cookies we
      use are essential for the site to work. We also use some non-essential
      cookies to collect information about how visitors use our website so that
      we can make informed decisions about improvements to the site and get a
      good understanding of the kind of content our visitors like to read.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      A cookie is a small text file which is placed onto your device (e.g.
      computer, smartphone or other electronic device) when you use our website.
      They do not affect your device, but are widely used in order to make
      websites work, or work more efficiently, as well as to provide information
      to the owners of the site.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      For example, we may monitor how many times you visit the website, which
      pages you go to, traffic data, location data and the originating domain
      name of your internet service provider. This information helps us to build
      a profile of our users and customise our website to deliver a better
      service and experience. Some of this data will be aggregated or
      statistical, which means that we will not be able to identify you
      individually.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      For further information on our use of cookies, including a detailed list
      of your information which we and others may collect through cookies,
      please see 'Our use of cookies' below.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      For further information on cookies generally, including how to control and
      manage them, visit the
      <a
        class="a-tag-decorate"
        href="https://ico.org.uk/your-data-matters/online/cookies/"
        target="_blank"
        >guidance on cookies</a
      >
      published by the UK Information Commissioner's Office or
      <a class="a-tag-decorate" href="www.aboutcookies.org" target="_blank"
        >www.aboutcookies.org</a
      >.
    </p>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      CONSENT TO USE OF COOKIES AND MANAGING CONSENT
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      We will ask for your consent to place cookies or other similar
      technologies on your device, except where they are essential for us to
      provide you with a service that you have requested or essential for the
      website to work.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      You can withdraw any consent to the use of cookies or manage any other
      cookie preferences through our cookie preference manager. It may be
      necessary to refresh the page for the updated settings to take effect.
    </p>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      OUR USE OF COOKIES
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      We classify and use cookies in the following ways:
    </p>
    <br />
    <ul class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      <li>
        <b>Essential:</b> These cookies are essential for you to browse our
        website and use its features. The information collected relates to the
        operation of the website (e.g. website scripting language and security
        tokens) and are necessary for the website to function correctly. They
        are also necessary for us to keep our website secure.
      </li>
      <br />
      <li>
        <b>Functional:</b> These cookies remember the choices you have made.
        This may include, for example, the country you visit our website from,
        your language preference and any changes you have made to text size and
        other parts of the webpages that you can customise to improve your user
        experience. These cookies make your visits to our website more tailored
        and relevant to you.
      </li>
      <br />
      <li>
        <b>Performance/analytics:</b> These cookies collect information on how
        our visitors use our website. Such information includes, for example,
        which pages you visit most often, whether you receive any error messages
        and how you arrived at our website (such as through a search engine or a
        social media platform). Information collected by these cookies is used
        only to improve your use of our website. These cookies are sometimes
        placed by third party providers of web traffic and analysis services,
        such as Google Analytics. For information on how Google processes and
        collects your information and how you can opt out see 'Third party
        cookies' below.
      </li>
      <br />
      <li>
        <b>Advertisement:</b> These cookies are used to deliver more relevant
        ads to users, but may also limit the number of times an ad is viewed and
        are used to track the effectiveness of an advertising campaign by
        tracking user clicks. They can also provide transaction security. They
        are usually placed by third-party advertising networks with the
        permission of the website operator, but can be placed by the operator
        itself. They can remember that you have visited a website and this
        information can be shared with other organisations, including other
        advertisers. However, they cannot determine who you are, as the data
        collected is never linked to any profile. Without these cookies, the
        online advertisements you encounter will be less relevant to you and
        your interests.
      </li>
      <br />
    </ul>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      The table below provides more information about the cookies we use and
      why:
    </p>
    <br />
    <table
      class="text-gray-600 print:text-2xl print:mt-10 font-medium table-space"
    >
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in cookieTableData" v-bind:key="data._id">
          <td>
            <div>
              <div>{{ data.name }}</div>
            </div>
          </td>
          <td>
            <div>
              <div>{{ data.type }}</div>
            </div>
          </td>
          <td>
            <div>
              <div>{{ data.description }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      THIRD PARTY COOKIES
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Our website uses cookies placed by Google through our use of Google
      Analytics.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Google places cookies on our website to help us report on visitor
      interactions with our website.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Google Analytics cookies also collect your Internet Protocol (IP) address
      to provide and protect the security of the service, and to give us a sense
      of which country, state, or city in the world our users of the website
      come from (also known as "IP geolocation").
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      You can opt out of Google Analytics at any time by visiting
      <a
        class="a-tag-decorate"
        href="https://tools.google.com/dlpage/gaoptout"
        target="_blank"
        >https://tools.google.com/dlpage/gaoptout</a
      >.
    </p>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      HOW TO TURN OFF COOKIES
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      If you do not want to accept any cookies, you may be able to change your
      browser settings so that cookies (including those which are essential to
      the services requested) are not accepted. If you do this, please be aware
      that you may lose some of the functionality of our website.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      For further information about cookies and how to disable them please go to
      the
      <a
        class="a-tag-decorate"
        href="https://ico.org.uk/your-data-matters/online/cookies/"
        target="_blank"
        >guidance on cookies</a
      >
      published by the UK Information Commissioner's Office or
      <a class="a-tag-decorate" href="www.aboutcookies.org" target="_blank"
        >www.aboutcookies.org</a
      >.
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Mobile Opt-out: If you access our website through mobile devices, you can
      enable a "do not track" feature so as to control interest-based
      advertising on an iOS or Android mobile device by selecting the 'Limit Add
      Tracking' option in the privacy section of your Settings on iOS or via
      advertising preferences on Android devices (e.g. in Google Settings). This
      will not prevent the display of advertisements but will mean that they
      will no longer be personalised to your interests.
    </p>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      CHANGES TO THIS COOKIE POLICY
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      We may change this Cookie Policy from time to time. When we make
      significant changes we will take steps to inform you, for example by
      including a prominent link to a description of those changes on our
      website for a reasonable period or by other means, such as email.
    </p>
    <br />
    <h1 class="text-3xl tracking-tighter font-medium print:text-7xl">
      HOW TO CONTACT US
    </h1>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Our contact details are shown below:
    </p>
    <br />
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Postal Address:
    </p>
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Attn. Cookies
    </p>
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      1658 N. Milwaukee Ave. Suite 100-45
    </p>
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Chicago, IL 60647
    </p>
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">USA</p>
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Email: dataprotection@equilo.io
    </p>
    <p class="text-gray-600 print:text-2xl print:mt-10 font-medium">
      Telephone: + (815) 992-4361
    </p>
    <br />
  </div>
</template>
<script>
export default {
  name: "CookiePolicyContent",
  data: function () {
    return {
      cookieTableData: [
        {
          id: 1,
          name: "NID",
          type: "Advertisement",
          description:
            "NID cookie, set by Google, is used for advertising purposes; to limit the number of times the user sees an ad, to mute unwanted ads, and to measure the effectiveness of ads.",
        },
        {
          id: 2,
          name: "1P_JAR",
          type: "Advertisement",
          description:
            "How the end user uses the website and any advertising that the end user may have seen before visiting the said website. Google cookie used to track users for ad targeting purposes.",
        },
        {
          id: 3,
          name: "SEARCH_SAMESITE",
          type: "Advertisement",
          description: "Used for correct sending of data to Google.",
        },
        {
          id: 4,
          name: "OGPC",
          type: "Advertisement",
          description:
            "Used by Google to store user preferences and information while viewing Google mapped pages.",
        },
        {
          id: 5,
          name: "_ga",
          type: "Performance/analytics",
          description:
            "The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.",
        },
        {
          id: 6,
          name: "_gid",
          type: "Performance/analytics",
          description:
            "Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.",
        },
        {
          id: 7,
          name: "_gat_gtag_UA_118384138_3",
          type: "Performance/analytics",
          description: "Set by Google to distinguish users.",
        },
        {
          id: 8,
          name: "_gat_gtag_UA_178545143_1 ",
          type: "Performance/analytics",
          description: "Set by Google to distinguish users.",
        },
        {
          id: 9,
          name: "__stripe_mid",
          type: "Essential",
          description: "Stripe sets this cookie cookie to process payments.",
        },
        {
          id: 10,
          name: "__stripe_sid",
          type: "Essential",
          description: "Stripe sets this cookie cookie to process payments.",
        },
        {
          id: 11,
          name: "M",
          type: "Essential",
          description:
            "Fraud prevention cookies, and similar technologies that we deploy through our Site, help us learn things about computers and web browsers used to access Stripe Services. This information helps us monitor for and detect potentially harmful or illegal use of our Services.",
        },
        {
          id: 12,
          name: "pa-uid",
          type: "Essential",
          description: "Maintains the session for Equilo Website.",
        },
        {
          id: 13,
          name: "session",
          type: "Essential",
          description: "Maintains the session for Equilo Website.",
        },
        {
          id: 14,
          name: "ts_c",
          type: "Essential",
          description: "To provide fraud prevention.",
        },
        {
          id: 15,
          name: "ts",
          type: "Essential",
          description: "To provide fraud prevention.",
        },
        {
          id: 16,
          name: "equilo-cookie-consent",
          type: "Essential",
          description:
            "Stores your cookie preferences (so you won't be asked again)",
        },
      ],
    };
  },
};
</script>
