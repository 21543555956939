<script>
import { Line } from 'vue-chartjs'

export default {
    name: 'PortfolioLineChart',
    extends: Line,
    data: function () {
        return {
            axes: ['Q1', 'Q2', 'Q3', 'Q4'],
            axeColors: [],
            max: [],
            one: [],
            yLabel: 0,
        };
    },
    methods: {
    },
    props: [],
    async mounted() {
        const _self = this;
        this.renderChart({
            labels: this.axes,
            datasets: [
                {
                    data: [230, 450, 550, 600], label: 'Actual', borderColor: '#43467C', backgroundColor: '#43467C', fill: false, pointHitRadius: 6,
                    pointRadius: 4, borderWidth: 3, pointStyle: 'rectRounded', tension: 0
                },
                {
                    data: [120, 80, 500, 900], label: 'Budget', borderColor: '#FFDE60', backgroundColor: '#FFDE60', fill: false, pointHitRadius: 6,
                    pointRadius: 4, borderWidth: 3, pointStyle: 'rectRounded', tension: 0
                },
                {
                    data: [450, 320, 800, 990], label: 'Initial Projection', borderColor: '#13A094', backgroundColor: '#13A094', fill: false, pointHitRadius: 6,
                    pointRadius: 4, borderWidth: 3, pointStyle: 'rectRounded', tension: 0
                },
            ]
        }, {
            maintainAspectRatio: false,
            responsive: true,
            fontFamily: 'basier',
            legend: {
                display: true,
                position: "top",
                labels: {
                    "fontSize": 13,
                    fontFamily: "basier",
                    usePointStyle: true,
                }
            },
            hover: {
                onHover: function (e) {
                    // return false;
                    var point = this.getElementAtEvent(e);
                    if (point && point.length > 0) {
                        // Prevent from flashing.
                        if (point[0]._index !== _self.hoverIndex + "_" + point[0]._datasetIndex) {
                            _self.hoverIndex = point[0]._index + "_" + point[0]._datasetIndex;
                            document.getElementById("intersection-info").position = "fixed";
                            document.getElementById("intersection-info").style.zIndex =
                                "99999";
                            document.getElementById("intersection-info").style.top =
                                e.pageY - 105 + "px";
                            document.getElementById("intersection-info").style.left =
                                e.pageX - 10 + "px";
                            document.getElementById('intersection-theme-link').style.display = 'none';
                        }
                    } else {
                        document.getElementById('intersection-info').style.display = 'none';
                    }
                    if (point.length) e.target.style.cursor = 'pointer';
                    else e.target.style.cursor = 'default';
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        max: 1000,
                        fontFamily: "basier",
                    },
                    scaleLabel: {
                        display: false,
                        labelString: 'Gender Equality Score',
                        fontStyle: 'bold',
                        fontSize: '16',
                        fontFamily: "basier"
                    },

                }],
                xAxes: [{
                    ticks: {
                        fontSize: '13',
                        fontFamily: "basier"
                    },
                    gridLines: {
                        display: false,
                    },
                }]
            },
            tooltips: {
                callbacks: {
                    title: function () {
                        return null
                    },
                    label: {
                        display: false
                    }
                },
                backgroundColor: 'rgb(253, 253, 253)',
                xPadding: 20,
                yPadding: 20,
                titleFontStyle: 'semi-bold',
                titleFontColor: '#0066ff',
                titleFontFamily: 'basier',
                titleFontSize: 10,
                titleSpacing: 20,
                titleMarginBottom: 10,
                bodyFontColor: '#333333',
                bodyFontSize: 14,
                bodyFontFamily: 'basier',
                displayColors: false,
                cornerRadius: 2,
                borderColor: 'rgb(240, 240, 240)',
                borderWidth: 3,
            },

        });
    },
}
</script>

<style></style>
