<template>
    <div>
        <LandingPage :investor=true></LandingPage>
    </div>
</template>
<script>
import LandingPage from "./IMMLandingPage.vue"
export default {
    name: "IMMInvestorLandingPage",
    components: {
        LandingPage
    }
}
</script>