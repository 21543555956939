<template>
  <div class="static">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0 pb-32"
      v-if="endofcreated"
    >
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper"></div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg">
            <div class="powered-text flex mr-8">
              <span>Powered by:</span>
            </div>

            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="flex flex-row mt-32 lg:mt-0 justify-center">
        <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
          <h1
            class="text-5xl tracking-tighter font-medium text-center print:text-7xl"
            style="color: #201747"
          >
            Add New Service Provision Record
          </h1>
        </div>
      </div>
      <div style="height: 5rem"></div>
      <transition name="fadeIn" mode="out-in">
        <div id="loaded" key="content">
          <main style="grid-template-columns: none !important">
            <div style="width: 100%; text-align: center">
              <section
                class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
                style="max-width: 960px"
              >
                <p>
                  <iframe
                    class="airtable-embed"
                    src="https://airtable.com/embed/shran7VoJfaPa5b4f?backgroundColor=greenLight"
                    frameborder="0"
                    onmousewheel=""
                    width="100%"
                    height="700"
                    style="background: transparent; border: 1px solid #ccc"
                  ></iframe>
                </p>
              </section>
            </div>
          </main>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
export default {
  name: "NewServiceProvision",
  data: function () {
    return {
      endofcreated: false,
    };
  },
  components: {
    pageLoader,
  },
  async created() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    // Hide condition according to this task - Unlock/ make public TA service provider tool?
    // if (
    //   !this.user.organization.taServiceProvider ||
    //   this.user.organization.taServiceProvider == ""
    // ) {
    //   this.$router.push({ path: "/" });
    // }

    this.$store.commit("setBreadcrumbs", [
      { name: "TA Service Provision", url: "/ta-service-provision" },
      {
        name: "Add New Service Provision",
        url: "/ta-service-provision/add-new-service-provision",
      },
    ]);
    this.endofcreated = true;
  },
};
</script>
<style scoped lang="scss">
.PoweredbyTitle_logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 170px;
  padding: 170px 0 0;

  .PoweredbyTitleWrepper {
    max-width: 847px;
    widows: 100%;
    display: flex;

    // align-items: center;
    img {
      width: 100px;
      height: 100px !important;
      object-fit: contain;
      display: none;
    }

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 61px;
      color: #000;
    }
  }

  .Poweredby_logoImg {
    display: flex;
    justify-content: flex-end;

    .powered-text {
      span {
        font-size: 14px;
        line-height: 20px;
        margin-right: 35px;
        font-weight: 400;
        color: #2c2e35;
      }
    }

    img {
      width: 100px;
      height: 33px;
      object-fit: contain;
      margin-right: 35px;
      max-width: 100px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
