import axios from "axios";
import Config from "@/../env";
import Cookies from "@/helpers/cookies";

const client = axios.create({
  baseURL: `${Config.apiUrl}`, // contains the version
  json: true,
  headers: {
    authorization: "Bearer " + Cookies.get("session"),
  },
});

const execute = async (method, resource, data) => {
  return new Promise(function (resolve, reject) {
    client.defaults.headers["authorization"] =
      "Bearer " + Cookies.get("session");
    return client({ method, url: resource, data })
      .then((req) => {
        return resolve(req.data);
      })
      .catch(function (err) {
        console.log(err);
        return reject(err);
      });
  });
};

// this library is to bypass Google encryption.
// So we do not send plain passwords to the backend.
const encrypt = (salt, text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

const login = (email, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${Config.apiUrl}/users/login`, {
        email,
        password: encrypt(Config.salt, password),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const getSession = (sessionId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${Config.apiUrl}/sessions/${sessionId}`, {
        headers: {
          session: sessionId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const destroySession = (sessionId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${Config.apiUrl}/sessions/${sessionId}`, {
        headers: {
          session: sessionId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const getUsersByMail = (email) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${Config.apiUrl}/users/search/${email}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const requestNewPassword = (email) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${Config.apiUrl}/users/request-new-password`, {
        email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

const resetPassword = (resetCode, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${Config.apiUrl}/users/request-new-password/${resetCode}`, {
        password: encrypt(Config.salt, password),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });

const checkUpperCase = (string) => {
  for (const char of string) {
    if (char.toUpperCase() == char) {
      return true;
    }
  }
  return false;
};

const checkLowerCase = (string) => {
  for (const char of string) {
    if (char.toLowerCase() == char) {
      return true;
    }
  }
  return false;
};

const checkSpecialChars = (string) => {
  var special_chars = ["&", "!", "$", "-", "_", "?", "@", ">", "<", "*", "�"];
  return special_chars
    .map((special_char) => string.toLowerCase().includes(special_char))
    .includes(true);
};

const checkPasswordRequirements = (string) => {
  return (
    checkSpecialChars(string) &&
    checkUpperCase(string) &&
    checkLowerCase(string)
  );
};

/*
e.g.
  const auth = require('@helpers/auth');

  const account = await auth.login(email, password); // Login
  const account = await auth.me(); // Get your account
  await auth.logout(); // Logout

*/
const me = async (logUser) => {
  const session = Cookies.get("session");
  if (!session) {
    return false; // not logged in
  }

  try {
    const endpoint = logUser ? "/users/me?logUser=true" : "/users/me";
    return await execute("get", endpoint);
  } catch (error) {
    console.error("Error fetching user data:", error);
    return false; // return false on error
  }
};

export default {
  me,
  login,
  getSession,
  destroySession,
  getUsersByMail,
  requestNewPassword,
  resetPassword,
  checkPasswordRequirements,
};
