<template>
  <div class="flex flex-row w-full bg-gray-100">


    <sidebar></sidebar>

    <!--TABLE-->
    <div class="flex flex-col w-full overflow-hidden" style="padding-top: 100px; padding-left: 30px; padding-bottom: 30px padding-right: 30px;">
      <div class="flex flex-col justify-start items-start overflow-x-scroll h-full">


      <div>
        <router-link :to="'/settings/plans/'+_self.$route.params.planId" class="text-sm cursor-pointer">Go back</router-link>

        <p class="text-2xl">{{subscription.name}}</p>
        <p class="text-gray-900">{{subscription.desc}}</p>
        <p class="mt-10">To purchase the <b>{{subscription.name}} subscription</b> via bank transfer, please use the details below to transfer <b>{{subscription.price}} USD</b>. Your subscription will be upgraded once the funds are received.
                </p>
                <p class="mt-10">
                    <b>Bank name</b>: JP Morgan Chase<br/>
                    <b>Account name</b>: Equilo Inc<br/>
                    <b>Account #</b>: 40630127241937156<br/>
                    <b>Routing # for ACH transfer</b>: 121000248<br/>
                    <b>Routing # for wire transfer</b>: 021000021<br/>
                    <b>SWIFT/BIC</b>: CHASUS33XX
                </p>        
        

</div>
      </div>



      </div>
    </div>
</template>

<script>
  import sidebar from '@/components/sidebar.vue'
  import auth from '@/helpers/auth';
  const axios = require('axios');
  import Cookies from '@/helpers/cookies'
  import env from '@/../env.js';


  export default {
    name: 'Overview',
    data: function () {
      return {
        user: {},
        organization: {},
        subscription: {},
        payment: {}
      }
    },
    components: {
      sidebar,
    },
    methods: {
      open: function(href){
        var win = window.open(href, '_blank');
        win.focus();
      },
      getPayment: function () {
        const _self = this;
        return new Promise(function (resolve) {
          axios.post(env.apiUrl + '/payments/request-ach', {
            subscription: _self.$route.params.planId
          }, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data);
          });
        })
      },
      load: function () {
        const _self = this;
        return new Promise(function (resolve) {
          axios.get(env.apiUrl + '/subscriptions/'+_self.$route.params.planId, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data.data);
          });
        })
      }
    },
    async created() {

      this.user = await auth.me().catch(() => {});
      this.organization = this.user.organization;
      this.subscription = await this.load();
      this.$store.commit('downloadable', false);
      this.$store.commit('setBreadcrumbs', [{
          'name': 'Settings',
          'url': '/settings/account'
        },
        {
          'name': this.organization.name,
          'url': '/settings/team'
        },
        {
          'name': 'Subscriptions',
          'url': '/settings/plans'
        },
      ]);

      this.payment = await this.getPayment();
    }
  }
</script>
