module.exports = {
  enviorment: 'staging',
  port: 3000,

  // LOCAL API & PRINT
  // apiUrl: "http://equilo-stage-backend-257644995.us-east-1.elb.amazonaws.com/v1",
  // printUrl: "http://localhost:3000/v1",
  // domain: 'localhost:3000/v1',

  // STAGING API, ADMIN, PRINT
  apiUrl: "https://api-staging.equilo.io/v1",
  printUrl: "https://api-staging.equilo.io/v1",
  adminURL: "https://admin-staging.equilo.io/",
  domain: 'api-staging.equilo.io/v1',

  // PRODUCTION API, ADMIN, PRINT
  // apiUrl: "https://api.equilo.io/v1",
  // printUrl: "https://api.equilo.io/v1",
  // adminURL: "https://a.equilo.io/",
  // domain: 'api.equilo.io/v1',

  brandingColors: {
    navy: "#201747",
    blue: "#0077C8",
    teal: "#2BD4C4",
    orange: "#FE5000",
    yellow: "#FAE053",
    gray: "#898989",
  },
  chartColors: {
    alfaColor: "#2BD4C4",
    betaColor: "#0077C8",
    gammaColor: "#f5e063",
    deltaColor: "#FE5000",
    jotaColor: "#B2281E",
    muColor: "#7B5FCB",
    piColor: "#779ecb",
    rhoColor: "#cfaec6",
  },
  taabColors: {
    Entrepreneurship: "#898989",
    accessToResources: "#FE5000",
    accessToResourcesBackground: "#ff8850",
    lawAndPolicy: "#201747",
    lawAndPolicyBackground: "#696380",
    powerAndDecision: "#F5D10F",
    powerAndDecisionBackground: "#fce87a",
    knowledgeAndBeliefs: "#2BD4C4",
    knowledgeAndBeliefsBackground: "#6BE2D7",
    humanDignity: "#0077C8",
    humanDignityBackground: "#59A0D8",
    rolesAndResponsibilities: "#666666",
    rolesAndResponsibilitiesBackground: "#afafaf",
    indirectInvestement: 'red',	
    employment: 'rgb(245,209,15)',
  },
  genderColors: {
    male: "#0077C8",
    female: "#FE5000",
  },
  geoColors: {
    countryspider: "#201747",
    countrybar: "#201747",
    regionspider: "#F5D10F",
    regionbar: "#F5D10F",
    incomespider: "#2BD4C4",
    incomebar: "#2BD4C4",
    lmicspider: "#898989",
    lmicbar: "#898989",
  },

  gbvurl: "https://downloads.equilo.io",
  fgmIndicators:['GBV_FGCEXP_F_143','GBV_FGCGRL_F_1025','GBV_ENDFGC_F_145','GBV_ENDFGC_M_144'],
  gbvteams: ["Dev User Team Acct", "Criterion", "Equilo"],
  staticToken: 'equiloApiAuthToken',
  chargebeeApikey: 'test_Q4aawf8H8wNmvWlGBL36n8u3IiUh8KR7',
  chargebeeSite: 'equilo-test',
   productFamilyIds:{
      gesi:'GESI-AID',
      twox:'Organizational-GESI-Assessment',
      gbv:'GBV-Risk-Score-GBV-Risk-Score-OM',
      roi:'Gender-ROI-Portfolio',
      myPortfolio:'2X-My-Portfolio',
      gesi_aid:'GESI-AID',
      api_data_access:'API-Data-Access',
      gender_smart_company:'Gender-Smart-Company',
      gli_investore:'GLI-Investor',
    },
     featureFlags:{
      accessgesi:'accessgesi',
      productapiaccess:'productapiaccess',
      accessroiportfolio:'accessroiportfolio',
      accessportfolio:'accessportfolio',
      access2x:'access2x',
      twoxlisted:'2x_listed',
      twoXAssessment:'twoXAssessment',
      genderRoiAssessment:'genderRoiAssessment',
      gbvhRoadmapAssessment:'gbvhRoadmapAssessment',
      apigbv:'apigbv',
      apigesi:'apigesi',
      apiportfolio:'apiportfolio',
      apiroi:'apiroi',
    },
stripeProductTableIds:{
  GESI_AID:'prctbl_1MeW73C3Hz1HZJ6SOfA2YfuZ',
  GLI_Investor:'prctbl_1MgEsyC3Hz1HZJ6SnC6cFn1P',
  Gender_Smart_Company:'prctbl_1MgylGC3Hz1HZJ6SZODxoGhk'

},    
 productIds:{
      open: 'equilo-open'
 },
 consultUrl:'https://www.equilo.io/consult',
 gtag_id: 'G-QF4LEQKEN4',
 stripePublicKey:'pk_test_51Hhby2C3Hz1HZJ6SCXiEgaHz9DoYSz7Qb9oa6Kbj17T9OdmdJSYcKP5wPuIvACXGaASQtw8zMqopPglo0G435gkb00BEI2TxiO',
 productFamilyTitles: {
    "GESI-AID": 'Automated gender analysis at your fingertips',
    "GLI-Investor": 'In-depth gender assessment at your fingertips',
    "Gender-Smart-Company": 'In-depth gender assessment at your fingertips',
    "Api_Data_Access": 'Simplify your data management'
  },
  productFamilyDescriptions: {
    "GESI-AID": 'Find the data you need when you need it with our tailored subscription packages.',
    "GLI-Investor": 'Increase your impact through streamlined data collection, risk assessment, and impact management with our tailored subscription package.',
    "Gender-Smart-Company": 'Improve your business performance and deepen your social impact with your tailored subscription packages.',
    "Api_Data_Access": 'Integrate Equilo with your other systems with a dedicated API'
  },
  airTableAccessToken: 'pateAyyp4XazMGAYJ.464bd820a93df3777c69a5f1d32796e2a092d18d81e6803042eea5b98ff26672',
  SentryDsn: "https://9a1faea2be93f402f03c61d40ae3e02d@o4507656863350784.ingest.us.sentry.io/4507660754747392",
  SentryEnvironment: "Stage" 
};


