var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row w-full bg-gray-100"},[_c('sidebar'),(_vm.user)?_c('div',{staticClass:"flex flex-col px-10 py-10 w-full main_container"},[_c('div',{staticClass:"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8",staticStyle:{"padding":"0 1rem"}},[_c('div',{staticStyle:{"width":"1000px","margin":"auto"}},[_c('h1',[_vm._v("2X Portfolio API Documentation Overview")]),_c('br'),_c('h2',[_vm._v("Steps to follow")]),_c('br'),_vm._m(0),_c('p',[_vm._v("2. Select api method from dropdown and use api url as given in the description")]),_c('p',[_vm._v("3. Open Headers tab, pass your organization’s api key as given in images")]),_vm._m(1),_c('p',[_vm._v("5. Click on Send")]),_c('br'),_c('br'),_c('h2',[_vm._v("1. Investment details for funds and investors. ")]),_c('ul',[_vm._m(2),_vm._m(3),_c('li',[_c('span',[_vm._v("Request Headers:")]),_vm._v(" apikey: '"+_vm._s(_vm.user.organization.secretKey ? _vm.user.organization.secretKey : 'secXXXXXXX')+"'")]),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('br'),_c('br'),_c('br'),_c('h1',[_vm._v("Authorization")]),_c('br'),_c('br'),_c('img',{attrs:{"src":require("@/assets/images/Gbv_Api_Settings/authorization-postman.png")}}),_c('br'),_c('h1',[_vm._v("API Authentication Errors")]),_c('br'),_c('br'),_vm._m(7),_c('h3',[_vm._v("HTTP STATUS CODES")]),_c('br'),_c('p',[_vm._v("The standard http status codes used are:")]),_c('br'),_vm._m(8)])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("1. Open "),_c('a',{staticClass:"cursor-pointer",staticStyle:{"color":"blue"},attrs:{"href":"https://www.postman.com/","target":"_blank"}},[_vm._v("Postman")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("4. Open Authorization tab, "),_c('ul',{staticStyle:{"padding":"0"}},[_c('li',{staticStyle:{"padding":"0"}},[_vm._v("Select Basic Auth as type")]),_c('li',{staticStyle:{"padding":"0"}},[_vm._v("Enter equilo account email in username and password in password tab as given in images")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("Description:")]),_vm._v(" Get investment details ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("Method:")]),_vm._v(" GET")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("Authorization: ")]),_c('ul',{staticClass:"inner_ul"},[_c('li',[_c('span',[_vm._v("Type:")]),_vm._v(" ‘select "),_c('strong',[_vm._v(" Basic Auth ")]),_vm._v(" as authorization type’ ")]),_c('li',[_c('span',[_vm._v("Username:")]),_vm._v(" ‘enter your equilo account email’")]),_c('li',[_c('span',[_vm._v("Password:")]),_vm._v(" ‘enter your equilo account password’ ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("API URL:")]),_vm._v(" https://api.equilo.io/v1/assesment2x/investments ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticStyle:{"list-style":"none"}},[_c('img',{attrs:{"src":require("@/assets/images/investment_portfolio_Api_Settings/investor-portfolio-api.png")}}),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"error_message_example mb-10"},[_c('div',{staticClass:"code_text"},[_c('p',[_vm._v("{")]),_c('p',{staticClass:"ml-5"},[_vm._v("status: false,")]),_c('p',{staticClass:"ml-5"},[_vm._v("message: 'Something went wrong please try again later',")]),_c('p',{staticClass:"ml-5"},[_vm._v("code: \"INVALID_REQUEST\"")]),_c('p',[_vm._v("}")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('span',[_vm._v("2XX")]),_vm._v(" The response code in this range implies the API call has succeeded.")]),_c('li',[_c('span',[_vm._v("3XX")]),_vm._v(" This range is not used currently.")]),_c('li',[_c('span',[_vm._v("4XX")]),_vm._v(" response codes indicate failure due to wrong input (i.e, client) side. As the usable list of http codes in the 4xx range is limited we predominantly use the 400 ( BAD REQUEST ) error code for client-side errors.")]),_c('li',[_c('span',[_vm._v("5XX")]),_vm._v(" response codes indicate API request was valid but failed due to issues on the Equilo server.")])])
}]

export { render, staticRenderFns }