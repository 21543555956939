<script>
/*

Input should look like this:


data = [
  {name: 'running', value1: }
]


Bar: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
Bubble: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
Doughnut: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
HorizontalBar: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
Line: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
Pie: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
PolarArea: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
Radar: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
Scatter: {props: {…}, methods: {…}, render: ƒ, data: ƒ, beforeDestroy: ƒ}
generateChart: ƒ generateChart(chartId, chartType)
mixins: {reactiveData: {…}, reactiveProp: {…}}
render: ƒ render()


More information: https://www.chartjs.org/docs/latest/charts/radar.html

*/

import axios from 'axios';
import { Bar } from 'vue-chartjs'
import env from '@/../env';
import Cookies from '@/helpers/cookies'
import IntersectionScore from './IntersectionScore'
// function to configure charts
import { getTaabColors, getLabelValues, initHoverBox } from './ChartFunction'


import annotation from 'chartjs-plugin-annotation';

export default {
  name: 'themeInterseections2',
  extends: Bar,
  data: function () {
    return {
      countryData: [],
      theme: {},
      regionalData: [],
      incomeData: [],
      globalData: [],
      axes: [],
      axeColors: [],
      max: [],
      intersections: [],
      one: [],
      yLabel: 0,
      countryIntersection: [],
      regionalIntersection: [],
      incomeIntersection: [],
      globalIntersection: []
    };
  },
  methods: {
    /*opacity: function(hex){
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
      return 'rgba('+rgb.r+', '+rgb.g+', '+rgb.b+', 0.25)'
    },*/
    getCountry: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios.get(env.apiUrl + "/countries/" + _self.iso + '/sectors/' + _self.$route.params.sector, {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('session')
          }
        }).then(function (res) {
          resolve(res.data.data);
        });
      })
    },
    getTheme: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios.get(env.apiUrl + "/reports/" + _self.iso + '/sectors/' + _self.$route.params.sector + "/themes/" + _self.themeId, {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('session')
          }
        }).then(function (res) {
          resolve(res.data)
        });
      })
    },
  },
  props: ['iso', 'themeId'],
  async created() {


    this.addPlugin(annotation);
    // We will request the country OURSELF because of asyncness...
    this.country = await this.getCountry(); // lets do an await before rendering other items.
    this.theme = await this.getTheme(); // lets do an await before rendering other items.
    this.intersections = this.theme.intersections;
    for (var i = 0; i < this.intersections.length; i++) {
      this.one.push(1);

      this.countryIntersection.push(new IntersectionScore(this.intersections[i].gender_equality, this.intersections[i].gender_empowerment, this.intersections[i].gender_parity));
      this.regionalIntersection.push(new IntersectionScore(this.intersections[i].gender_equality_region, this.intersections[i].gender_empowerment_region, this.intersections[i].gender_parity_region));
      this.incomeIntersection.push(new IntersectionScore(this.intersections[i].gender_equality_income, this.intersections[i].gender_empowerment_income, this.intersections[i].gender_parity_income));
      this.globalIntersection.push(new IntersectionScore(this.intersections[i].gender_equality_global, this.intersections[i].gender_empowerment_global, this.intersections[i].gender_parity_global));

      this.countryData.push(this.intersections[i].gender_equality || 0);
      this.regionalData.push(this.intersections[i].gender_equality_region || 0);
      this.incomeData.push(this.intersections[i].gender_equality_income || 0);
      this.globalData.push(this.intersections[i].gender_equality_global);

      this.axes.push(this.intersections[i].taab);
      this.max.push(1.4);

      // get taab colors from function
      const taabColors = getTaabColors();
      this.axeColors.push(taabColors[this.intersections[i].taab]);
    }

    const _self = this;
    this.renderChart({
      labels: this.axes,
      datasets: [
        { data: this.countryData, label: this.country.humanName, borderColor: env.geoColors.countrybar, fill: true, backgroundColor: env.geoColors.countrybar, type: 'bar' },
        { data: this.regionalData, label: this.country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' : this.country.region + ' Region', borderColor: env.geoColors.regionbar, fill: true, backgroundColor: env.geoColors.regionbar, type: 'bar' },
        { data: this.incomeData, label: this.country.income + ' Group', borderColor: env.geoColors.incomebar, fill: true, backgroundColor: env.geoColors.incomebar, type: 'bar' },
        {
          data: this.globalData, label: 'Global', borderColor: env.geoColors.lmicbar, fill: false, backgroundColor: env.geoColors.lmicbar, type: 'bar'
        },
      ]
    }, {

      maintainAspectRatio: false,
      responsive: true,
      fontFamily: 'basier',
      legend: {
        display: true,
        position: "bottom",
        labels: {
          "fontSize": 13,
          fontFamily: "basier"
        }
      },
      hover: {
        onHover: function (e) {
          // return false;
          var point = this.getElementAtEvent(e);

          if (point && point.length > 0) {
            // Prevent from flashing.
            if (point[0]._index !== _self.hoverIndex + "_" + point[0]._datasetIndex) {
              _self.hoverIndex = point[0]._index + "_" + point[0]._datasetIndex;
              // initialise hoverbox
              initHoverBox(e, _self, point);
              document.getElementById("intersection-info").position = "fixed";
              document.getElementById("intersection-info").style.zIndex =
                "99999";
              document.getElementById("intersection-info").style.top =
                e.pageY - 105 + "px";
              document.getElementById("intersection-info").style.left =
                e.pageX - 10 + "px";
              document.getElementById('intersection-theme-link').style.display = 'none';
              // GIVE LABELS VALUES
              getLabelValues(_self, point);
              document.getElementById('intersection-domain-link').href = '/#/reports/' + _self.country.ISO2 + '/' + _self.$route.params.sector + "/domains/" + _self.axes[point[0]._index];

            }
          } else {
            document.getElementById('intersection-info').style.display = 'none';
          }
          if (point.length) e.target.style.cursor = 'pointer';
          else e.target.style.cursor = 'default';
        }
      },

      annotation: {
        annotations: [{
          type: 'line',
          id: 'hLine',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 1,  // data-value at which the line is drawn
          borderWidth: 2,
          borderColor: '#3F37C9',
          label: {
            enabled: true,
            position: "center",
            content: 'Perfect Equality',
            backgroundColor: '#3F37C9',
            //fontFamily: "basier"
          }
        }]
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            max: 1.4,
            fontFamily: "basier",
          },
          scaleLabel: {
            display: true,
            labelString: 'Gender Equality Score',
            fontStyle: 'bold',
            fontSize: '16',
            fontFamily: "basier"
          },

        }],
        xAxes: [{
          ticks: {
            fontSize: '13',
            fontFamily: "basier"
          }
        }]
      },
      tooltips: {
        callbacks: {
          title: function () {
            //return datasets[tooltipItem[0].datasetIndex].label
            return null
          },
          label: {
            display: false
          }
        },
        backgroundColor: 'rgb(253, 253, 253)',
        xPadding: 20,
        yPadding: 20,

        titleFontStyle: 'semi-bold',
        titleFontColor: '#0066ff',
        titleFontFamily: 'basier',
        titleFontSize: 10,
        titleSpacing: 20,
        titleMarginBottom: 10,

        bodyFontColor: '#333333',
        bodyFontSize: 14,
        bodyFontFamily: 'basier',
        displayColors: false,

        cornerRadius: 2,
        borderColor: 'rgb(240, 240, 240)',
        borderWidth: 3,
      },

    });
  },
}
</script>

<style>

</style>
