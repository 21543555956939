<template>
  <div :key="'updateOpenTextComponents'+updateKey">
    <div class="flex items-center gap-3 justify-end" v-if="$route.query.fromVerifier && info.surveyQType != 'Calculated'">
      <img
        src="@/assets/images/edit-dark.svg"
        alt="edit-icon"
        class="h-6 w-6 cursor-pointer"
        @click="$emit('openEditModalFromCustomComponent', info)"
      />
      <VerificationDropdown :value="info" />
    </div>
    <div class="flex my-[1rem]">
      <h3 v-if="!clustered" class="font-bold max-w-[32rem]">{{ title }}:</h3>
      <div class="flex ml-2" v-if="!noInfo">
        <div v-if="value">
          <div v-if="typeof value == 'object'">
            <span v-for="item in value" :key="item">{{ item }}</span>
          </div>
          <span v-else>{{ value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import apiService from "@/helpers/apiServices.js";
import VerificationDropdown from "../my2xCertificationFullAssessmentResult/verificationDropdown.vue";

export default {
  name: "openText",
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    clustered: {
      type: Boolean,
      default: false,
    },
  },
  components: { VerificationDropdown },
  data() {
    return {
      title: this.info.title,
      value: this.info.value,
      noInfo: false,
      updateKey: 0,
    };
  },
};
</script>
