<template>
  <div class="overview-box">
    <div class="[ overview-top-section ][ flex items-center justify-between ][ p-8 ]">
      <div>
        <h3 class="text-3xl tracking-tighter font-bold">Overview</h3>
        <div class="flex items-center">
          <div
            class="flex text-white text-xs items-center pl-2 pr-3 my-2 mr-2 py-1 font-bold"
            :style="
              'border-radius: 20px; background-color:' +
              (twoxEligible == 'Yes' ? '#4C9F38' : '#FF3A21')
            "
          >
            <svg
              v-if="twoxEligible == 'Yes'"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.13911 8.06542L1.17578 5.21493L0.166687 6.17876L4.13911 9.99992L12.6667 1.79708L11.6647 0.833252L4.13911 8.06542Z"
                fill="white"
              />
            </svg>
            <img v-else src="@/assets/images/white-x.svg" />
            <span class="ml-2">{{
              twoxEligible == "Yes" ? "2X Eligible" : "Not 2X Eligible"
            }}</span>
          </div>
          <div
            class="flex text-white text-xs items-center pl-2 pr-3 my-2 mr-2 py-1 font-bold"
            :style="
              'border-radius: 20px; background-color:' +
              (certificationScoreData.ESG_ESG_100 == 'Yes'
                ? '#4C9F38'
                : '#FF3A21')
            "
          >
            <svg
              v-if="certificationScoreData.ESG_ESG_100 == 'Yes'"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.13911 8.06542L1.17578 5.21493L0.166687 6.17876L4.13911 9.99992L12.6667 1.79708L11.6647 0.833252L4.13911 8.06542Z"
                fill="white"
              />
            </svg>
            <img v-else src="@/assets/images/white-x.svg" />
            <span class="ml-2">{{
              certificationScoreData?.ESG_ESG_100 == "Yes"
                ? "Basic 2X ESG Screen Passed"
                : "Basic 2X ESG Screen Not Passed"
            }}</span>
          </div>
          <div
            class="flex text-white text-xs items-center pl-2 pr-3 my-2 mr-2 py-1 font-bold"
            :style="
              'border-radius: 20px; background-color:' +
              (COM_Commitment == 'Yes' ? '#4C9F38' : '#FF3A21')
            "
          >
            <svg
              v-if="COM_Commitment == 'Yes'"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.13911 8.06542L1.17578 5.21493L0.166687 6.17876L4.13911 9.99992L12.6667 1.79708L11.6647 0.833252L4.13911 8.06542Z"
                fill="white"
              />
            </svg>
            <img v-else src="@/assets/images/white-x.svg" />
            <span class="ml-2">{{
              COM_Commitment == "Yes"
                ? "Commitment to New Target in Place"
                : "Commitment to New Target Not in Place"
            }}</span>
          </div>
          <div
            class="flex text-white text-xs items-center pl-2 pr-3 my-2 mr-2 py-1 font-bold"
            :style="
              'border-radius: 20px; background-color:' +
              (certificationScoreData?.EMPLOY_Workplace_diversity_practices_3000 >
              0
                ? '#4C9F38'
                : '#FF3A21')
            "
          >
            <svg
              v-if="
                certificationScoreData?.EMPLOY_Workplace_diversity_practices_3000 >
                0
              "
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.13911 8.06542L1.17578 5.21493L0.166687 6.17876L4.13911 9.99992L12.6667 1.79708L11.6647 0.833252L4.13911 8.06542Z"
                fill="white"
              />
            </svg>
            <img v-else src="@/assets/images/white-x.svg" />
            <span class="ml-2">{{
              certificationScoreData?.EMPLOY_Workplace_diversity_practices_3000 >
              0
                ? "GBVH Requirements Met"
                : "GBVH Requirements Not Met"
            }}</span>
          </div>
        </div>
      </div>
      <EvaluationButton
        v-if="isVisibleEvaluationResultsButton"
        :text="'View Detailed Assessment Results'"
        :isPaid="isViewEvaluationsResultsPaid"
        @onClickEvaluationResults="$router.push({ path: '/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results' })"
      />
    </div>
    <div class="spiderchartEligibleWrepper p-8 dimesnion-section relative">
      <Dimension-Progress-Section
        :dimensions="dimensions"
        :isEntOwnEligible="isEntOwnEligible"
        :isSupplyChainEligible="isSupplyChainEligible"
        :isProductsEligible="isProductsEligible"
        :isEmploymentEligible="isEmploymentEligible"
        :isLeadershipEligible="isLeadershipEligible"
        :certificationScoreData="certificationScoreData"
        :entrepreneurshipPercent="entrepreneurshipPercent"
        :leadershipPercent="leadershipPercent"
        :employmentPercent="employmentPercent"
        :productServicePercent="productServicePercent"
        :supplyChainPercent="supplyChainPercent"
        :portfolioPercent="portfolioPercent"
        :options_eligible="options_eligible"
        :options_notEligible="options_notEligible"
        :srManagerThreshold="srManagerThreshold"
        :workforceThreshold="workforceThreshold"
        :boardThreshold="boardThreshold"
        :entityChosen="entityChosen"
      ></Dimension-Progress-Section>
      <!-- spiderchart -->
      <Dimension-Spiderchart
        :domainChartData="domainChartData"
        :dimensions="dimensions"
        :entityChosen="entityChosen"
        :srManagerThreshold="srManagerThreshold"
        :workforceThreshold="workforceThreshold"
        :boardThreshold="boardThreshold"
        :assessmentYear="certificationScoreData.assessmentYear"
        :supplyChainPercent="supplyChainPercent"
      ></Dimension-Spiderchart>
    </div>
    <div
      class="spiderchartEligibleWrepper p-8 governance-section relative"
      :style="isAllFullSurveysCompleted ? '' : 'border-bottom : 0'"
    >
      <Governance-Progress-Section
        :govStrategicAction="govStrategicAction"
        :govManagementSystem="govManagementSystem"
        :govData="govData"
        :govChartData="govChartData"
        :certificationScoreData="certificationScoreData"
        :options_eligible="options_eligible"
        :options_notEligible="options_notEligible"
        :srManagerThreshold="srManagerThreshold"
        :workforceThreshold="workforceThreshold"
        :boardThreshold="boardThreshold"
        :governanceColors="governanceColors"
      ></Governance-Progress-Section>

      <!-- spiderchart -->
      <Governance-Spiderchart
        :govData="govData"
        :govChartData="govChartData"
        :assessmentYear="certificationScoreData.assessmentYear"
        :governanceColors="governanceColors"
      ></Governance-Spiderchart>
    </div>
    <div
      class="view-full-assessment-section p-8"
      v-if="isVisibleEvaluationResultsButton"
    >
      <EvaluationButton
        :text="'View Detailed Assessment Results'"
        :isPaid="isViewEvaluationsResultsPaid"
        @onClickEvaluationResults="$router.push({ path: '/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results' })"
      />
    </div>
  </div>
</template>
<script>
import DimensionProgressSection from "@/components/2x-certification/dimension-progress-section.vue";
import DimensionSpiderchart from "@/components/2x-certification/dimension-spiderchart.vue";
import GovernanceProgressSection from "@/components/2x-certification/governance-progress-section.vue";
import GovernanceSpiderchart from "@/components/2x-certification/governance-spiderchart.vue";
import EvaluationButton from "@/components/EvaluationButton.vue";
export default {
  nameL: "OverviewBoxCertification",
  data() {
    return {
      options_eligible: {
        text: {
          color: "#4C9F38",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#4C9F38",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      options_notEligible: {
        text: {
          color: "#FF3A21",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#FF3A21",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      governanceColors: {
        strategicAction: "#4A742C",
        managementSystems: "#2574BB",
        data: "#F05323",
      },
    };
  },
  props: {
    dimensions: {
      type: [Object, Array],
    },
    srManagerThreshold: {
      type: [String, Number],
    },
    entityChosen: {
      type: [String],
    },
    workforceThreshold: {
      type: [String, Number],
    },
    boardThreshold: {
      type: [String, Number],
    },
    certificationScoreData: {
      type: [Object, Array],
    },
    domainChartData: {
      type: [Array, Object],
    },
    entrepreneurshipPercent: {
      type: [String, Number],
    },
    leadershipPercent: {
      type: [String, Number],
    },
    employmentPercent: {
      type: [String, Number],
    },
    productServicePercent: {
      type: [String, Number],
    },
    supplyChainPercent: {
      type: [String, Number],
    },
    portfolioPercent: {
      type: [String, Number],
    },
    govStrategicAction: {
      type: [String, Number],
    },
    govManagementSystem: {
      type: [String, Number],
    },
    govData: {
      type: [String, Number],
    },
    govChartData: {
      type: [Array, Object],
    },
    assessmentYear: {
      type: [Number, String],
    },
    isEntOwnEligible: {
      type: [Number, String],
    },
    isSupplyChainEligible: {
      type: [Number, String],
    },
    isProductsEligible: {
      type: [Number, String],
    },
    isEmploymentEligible: {
      type: [Number, String],
    },
    isLeadershipEligible: {
      type: [Number, String],
    },
    isAllFullSurveysCompleted: {
      type: [Boolean, null],
    },
    isViewEvaluationsResultsPaid: {
      type: [Boolean, null],
      default: true,
    }
  },
  emits: ['onPayEvaluationResults'],
  components: {
    DimensionProgressSection,
    DimensionSpiderchart,
    GovernanceProgressSection,
    GovernanceSpiderchart,
    EvaluationButton,
  },
  computed: {
    twoxEligible() {
      let val;
      if (this.entityChosen === "fund-manager") {
        val = this.certificationScoreData["2X_2X_Score_Overall_301"];
      } else if (this.entityChosen === "financial-institution") {
        val = this.certificationScoreData["2X_2X_Score_Overall_302"];
      } else if (
        this.entityChosen === "start-up" ||
        this.entityChosen === "sme" ||
        this.entityChosen === "corporate"
      ) {
        val = this.certificationScoreData["2X_2X_Score_Overall_300"];
      }
      return val;
    },
    COM_Commitment() {
      let val;
      if (this.entityChosen === "fund-manager") {
        val = this.certificationScoreData.COM_Commitment_301;
      } else {
        val = this.certificationScoreData.COM_Commitment_300;
      }
      return val;
    },
    isVisibleEvaluationResultsButton() {
      return this.isAllFullSurveysCompleted &&
        this.$route.path === '/2X-Ecosystem/2X-Certification/PreliminaryResults';
    },
  },
};
</script>
<style lang="scss" scoped>
.overview-box {
  border: 1px solid #ccc;
  border-radius: 15px;
  .standard-button {
    font-weight: bold;
    font-size: 12px;
    color: #191b45;
    border-style: solid;
    border-color: #191b45;
    border-width: 1px;
  }

  .certification-button {
    font-weight: bold;
    color: white;
    background: #191b45;
  }

  .overview-top-section,
  .dimesnion-section,
  .governance-section {
    border-bottom: 1px solid #ccc;
  }

  /* FROM my2xassement PAGE */
  .OverviewTitle {
    display: flex;
    align-items: center;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #1b1b1b;
      margin-right: 17px;
      position: relative;
    }

    span {
      display: inline-block;
      border-bottom: 2px solid #1b1b1b;
      width: 100%;
      height: 2px;
    }
  }

  hr {
    margin: 20px 0;
    width: 103.5%;
    text-align: left;
    margin-left: -1.75%;
    border-color: #ccc;
  }

  .spiderchartEligibleWrepper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .spiderchartContent {
      width: 70%;
      .entrepreneurship-fund {
        position: absolute;
        left: 32%;
        top: 5%;
      }
      .entrepreneurship-company {
        position: absolute;
        left: 35%;
        top: 5%;
      }
      .leadership-fund {
        position: absolute;
        left: 68%;
        top: 25%;
      }
      .leadership-company {
        position: absolute;
        left: 68%;
        top: 30%;
      }
      .employment-fund {
        position: absolute;
        left: 68%;
        top: 58%;
      }
      .employment-company {
        position: absolute;
        left: 60%;
        bottom: 20%;
      }
      .product-service-fund {
        position: absolute;
        left: 31%;
        top: 79%;
      }
      .product-service-company {
        position: absolute;
        left: 10%;
        bottom: 20%;
      }
      .supplychain-fund {
        position: absolute;
        right: 77%;
        top: 58%;
      }
      .supplychain-company {
        position: absolute;
        right: 76%;
        top: 30%;
      }
    }

    .Xeligibleconten {
      width: 50%;

      .EligibleListWrepper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        // margin-bottom: 15px;
        .EligibleListImg {
          position: relative;
          z-index: 1;
          margin-right: 30px;
          // &::after {
          //   content: "";
          //   display: inline-block;
          //   border: 1px solid #d0d0d0;
          //   position: absolute;
          //   height: 20px;
          //   top: 60px;
          //   left: 30px;
          //   z-index: -1;
          // }

          img {
            // width: 24px !important;
            // height: 24px;
            border-radius: 50%;
            object-fit: contain;
            margin: 0 !important;
            margin-right: 0 !important;
          }
        }

        .bigImg {
          &::after {
            top: 40px;
            left: 18px;
            height: 20px;
          }

          img {
            width: 40px !important;
            height: 40px;
          }
        }

        .lastAffter {
          &::after {
            content: none;
          }
        }

        .EligibleTitle {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            object-fit: contain;
          }

          h4 {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }

          h3 {
            font-size: 25px;
            font-style: normal;
            font-weight: bold;
            line-height: 30px;
            color: #1b1b1b;
          }
        }
      }

      .BorderLine {
        border: 1px solid #d0d0d0;
        max-width: 375px;
        margin: 30px 0;
      }

      .PartyValidatedToolTip {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .PartyTooltipImg {
          img {
            // width: 20px;
            // height: 20px;
            border-radius: 50%;
          }
        }

        .TooltipImgText {
          display: flex;
          align-items: center;

          img {
            width: 15px;
            height: 15px;
            margin-left: 5px;
          }

          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }

          .tooltiptext {
            max-width: 400px;
            top: 20px;
          }
        }
      }

      .DownloadBtn {
        p {
          display: flex;
          align-items: center;
          border: 1px solid #1b1b1b;
          border-radius: 20px;
          color: #1b1b1b;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 11px 17px;
          transition: 0.3s ease;

          img {
            margin-right: 5px;
            width: 15px;
            height: 15px;
          }

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }

        a {
          display: flex;
          align-items: center;
          border: 1px solid #1b1b1b;
          border-radius: 20px;
          color: #1b1b1b;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 11px 10px;
          transition: 0.3s ease;

          img {
            margin-right: 8px;
            width: 15px;
            height: 15px;
          }

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .view-full-assessment-section {
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    font-family: "Montserrat", arial;
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 600px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 20px;
    top: -160px;
  }

  .tooltip .twoXEligibleHoverText {
    font-family: "Montserrat", arial;
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 141px;
    top: -35px;
  }

  .tooltip .text-style {
    font-family: "Montserrat", arial;
    font-size: 14px;
    letter-spacing: normal;
    line-height: 1 !important;
  }

  .tooltip .header-style {
    font-family: "Montserrat", arial;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 1 !important;
  }

  .tooltip .entrepreneurshipHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 330px;
    top: -40px;
  }

  .tooltip .leadershipHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 139px;
    top: -40px;
  }

  .tooltip .employmentHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .consumptionHoverText {
    padding: 20px;
    visibility: hidden;
    width: 400px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 230px;
    top: -40px;
  }

  .tooltip .supplyChainHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .portfolioHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 120px;
    top: -40px;
  }

  .tooltip .commitmentHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .managementSystemsHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 250px;
    top: -40px;
  }

  .tooltip .dataHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486A;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 80px;
    top: -40px;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip:hover .remaining_companies_tooltiptext {
    visibility: visible;
  }

  .tooltip:hover .twoXEligibleHoverText {
    visibility: visible;
  }

  .tooltip:hover .entrepreneurshipHoverText {
    visibility: visible;
  }

  .tooltip:hover .leadershipHoverText {
    visibility: visible;
  }

  .tooltip:hover .employmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .consumptionHoverText {
    visibility: visible;
  }

  .tooltip:hover .supplyChainHoverText {
    visibility: visible;
  }

  .tooltip:hover .portfolioHoverText {
    visibility: visible;
  }

  .tooltip:hover .commitmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .managementSystemsHoverText {
    visibility: visible;
  }

  .tooltip:hover .dataHoverText {
    visibility: visible;
  }

  .tooltip:hover .indirectInvestmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .indirect_tooltip_text {
    visibility: visible;
  }

  .tooltip:hover .tooltiptextIndirectInvestement {
    visibility: visible;
  }

  .tooltip .tooltiptext::after {
    right: 95%;
  }

  .tooltip .remaining_companies_tooltiptext::after {
    right: 95%;
  }

  .tooltip .twoXEligibleHoverText::after {
    right: 95%;
  }

  .tooltip .entrepreneurshipHoverText::after {
    right: 95%;
  }

  .tooltip .leadershipHoverText::after {
    right: 95%;
  }

  .tooltip .employmentHoverText::after {
    right: 95%;
  }

  .tooltip .consumptionHoverText::after {
    right: 95%;
  }

  .tooltip .supplyChainHoverText::after {
    right: 95%;
  }

  .tooltip .portfolioHoverText::after {
    right: 95%;
  }

  .tooltip .commitmentHoverText::after {
    right: 95%;
  }

  .tooltip .managementSystemsHoverText::after {
    right: 95%;
  }

  .tooltip .dataHoverText::after {
    right: 95%;
  }

  .tooltip .indirectInvestmentHoverText::after {
    right: 95%;
  }

  .tooltip .indirect_tooltip_text::after {
    right: 95%;
  }

  .tooltip .tooltiptextIndirectInvestement::after {
    right: 95%;
  }

  .tooltip .tooltiptext::after {
    display: none;
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
}
</style>
