<template>
    <div class="pillar_domain flex" style="width: 75%;">
        <div class="pillar_container">
            <h2>Pillar</h2>
            <div>
                <div class="flex items-center mb-4" style="height: 50px;">
                    <img v-if="score.pillar == 'Resilience'" class="mr-3" src="@/assets/images/roi/resilience.svg" />
                    <img v-if="score.pillar == 'Opportunity' || score.pillar == 'Opportunities'" class="mr-3"
                        src="@/assets/images/roi/opportunity.svg" />
                    <img v-if="score.pillar == 'Inclusion'" class="mr-3" src="@/assets/images/roi/inclusion.svg" />
                    <span>
                        {{ score.pillar }}
                    </span>
                </div>
            </div>
        </div>
        <div class="domain_container">
            <h2>Domain</h2>
            <div>
                <div class="flex items-center mb-4" style="height: 50px;">
                    <img v-if="score.domain == 'Society'" class="mr-3" style="width: 40px;"
                        src="@/assets/images/society.svg" />
                    <img v-if="score.domain == 'Leadership'" class="mr-3" style="width: 40px;"
                        src="@/assets/images/leadership.svg" />
                    <img v-if="score.domain == 'Workforce'" class="mr-3" style="width: 40px;"
                        src="@/assets/images/workforce.svg" />
                    <img v-if="score.domain == 'Value Chain' || score.domain == 'Value Chains'" class="mr-3"
                        style="width: 40px;" src="@/assets/images/value_chain.svg" />
                    <span>{{ score.domain }}</span>
                </div>
            </div>
        </div>
        <div class="dimension_container">
            <h2 class="font-bold text-xl">Dimension</h2>
            <div class="flex items-center">
                <div>
                    <div class="flex items-center mb-4" style="height: 50px;">
                        <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Commitment'"
                            src="@/assets/images/Commitment.svg" />
                        <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Communication'"
                            src="@/assets/images/Communication.svg" />
                        <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Implementation'"
                            src="@/assets/images/Implementation.svg" />
                        <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Measurement'"
                            src="@/assets/images/Measurement.svg" />
                        <img class="mr-4" style="width: 40px;"
                            v-if="score.dimension == 'Accountability and Transparency'"
                            src="@/assets/images/Accountability_and_Transparency.svg" />
                        <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Singular'"
                            src="@/assets/images/singular.svg" />
                        <span class="font-medium text-xl">{{ score.dimension
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PillarDomainDimensionSection",
    props: ["score",]
}
</script>
<style labg="scss" scoped>
.pillar_domain {
    padding: 2rem 0;

    h2 {
        font-size: 13px;
        font-weight: 700;
        padding: 1rem 0;
    }

    .pillar_container {
        width: 50%;
    }

    .domain_container {
        width: 50%;
    }
}
</style>