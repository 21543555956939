<template>
    <div class="dimension_percentage_comparison flex" v-if="score.subpageData[0]?.score == 'NA'">
        <div class="">
            <span class="text-xl text-black tracking-tighter font-bold print:text-7xl">{{
                score.subpageData[0].notApplicableVerbiage }}</span>
        </div>
    </div>
    <div class="dimension_percentage_comparison flex items-center" v-else>
        <div class="male_female_icons" v-if="score.subpageData[0]?.score">
            <!-- For latest quarter -->
            <div class="flex" v-if="score.subpageData[0]?.score != ''">
                <div class="flex">
                    <img v-for="(female, femaleIndex) in score.femaleIcon" :key="'female3' + femaleIndex"
                        src="@/assets/images/icon-female-red.svg" />
                </div>
                <div class="flex">
                    <img v-for="(male, maleIndex) in score.maleIcon" :key="'male3' + maleIndex"
                        src="@/assets/images/icon-male.svg" />
                </div>
            </div>
            <!-- For oldest quarter -->
            <div class="flex mt-2" v-if="getScoredataLength > 1 && score.subpageData[0]?.Oldscore != ''">
                <div class="flex">
                    <img v-for="(female, femaleIndex) in score.OldfemaleIcon" :key="'Oldfemale3' + femaleIndex"
                        src="@/assets/images/icon-female-navy.svg" />
                </div>
                <div class="flex">
                    <img v-for="(male, maleIndex) in score.OldmaleIcon" :key="'Oldmale3' + maleIndex"
                        src="@/assets/images/icon-male.svg" />
                </div>
            </div>
        </div>
        <div class="comparison_text" :style="score.subpageData[0]?.score != '' ? '' : 'width:100%'">
            <span class="text-xl text-black tracking-tighter font-bold print:text-7xl">
                <span>
                    <span class="text-2xl" style="color: #FE5000">{{
                        score.subpageData[0]?.score != '' ? score.currentPercent+'%' : 'Unknown' }} </span>
                    {{ score?.subpageData[0]?.verbiageForPercentageFields |
                        removePerchantageSign }}
                </span>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "PeopleGraphicChart",
    props: ["score", "getScoredataLength"],
    filters: {
        removePerchantageSign(str) {
            if (str?.startsWith('%')) {
                return str.slice(1);
            } else {
                return str;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.dimension_percentage_comparison {
    .male_female_icons {
        width: 40%;

        img {
            width: 25px;
        }
    }

    .comparison_text {
        width: 60%;
    }
}
</style>