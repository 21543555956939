<template>
  <div class="container mx-auto py-8 px-4">
    <div v-if="isLoading" key="loading">
      <page-loader></page-loader>
    </div>
    <div v-else-if="!this.user.isVerifier">
      <div class="mt-20">
        <h1 class="text-3xl font-bold">
          You are not authorized to access this page. Please contact the
          administrator.
        </h1>
      </div>
    </div>
    <div class="mt-20" v-else>
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">Third Party Verification</h1>
      </div>
      <div class="mb-4 text-black text-xl">
        <select
          v-model="selectedCompany"
          class="p-2 border rounded-md w-1/3"
          @change="getCompanyData(selectedCompany)"
        >
          <option disabled value="">Select a company</option>
          <option
            v-for="company in this.companies"
            :key="company._id"
            :value="company.teamId"
          >
            {{ company.name }}
          </option>
        </select>
      </div>

      <!--Search bar-->
      <div class="mb-4 flex flex-wrap gap-2">
        <input
          type="text"
          placeholder="Search questions or documents..."
          class="flex w-1/3 p-2 border rounded-md"
          v-model="searchQuery"
          @input="filterData"
        />
        <!-- <button class="bg-blue-500 text-white px-4 py-2 rounded-md">
          Filter by Status
        </button>
        <button class="bg-blue-500 text-white px-4 py-2 rounded-md">
          Filter by Dimension
        </button> -->

        <div
          v-if="companyData.length"
          class="flex-grow justify-between items-center pt-2"
        >
          <span>Answers found by the model</span>
          <div class="w-full bg-gray-200 rounded-full h-6">
            <div
              class="bg-green-400 h-6 rounded-full text-center font-bold"
              :style="{ width: foundPercentage + '%' }"
            >
              {{ foundPercentage }}%
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4 rounded-md"
        role="alert"
      >
        <p class="font-bold">Understanding the Results:</p>
        <ul class="list-disc pl-5">
          <li>
            <b>Data Found</b> indicates that the data was found in the document.
          </li>
          <li>
            <b>Data Not Found</b> indicates that the data was not found by the
            model. However, the data may still be in the document.
          </li>
          <li>
            <b>Needs Revision</b> indicates that the results are inconclusive,
            but there may be relevant information in the document.
          </li>
        </ul>
      </div>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr class="bg-gray-100">
              <th class="text-left p-3">Question</th>
              <th class="text-left p-3">Answer</th>
              <th class="text-left p-3">Relevant Documents</th>
              <th class="text-left p-3">Status from Model</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredData" :key="item._id" class="border-b">
              <td class="p-3 w-1/4 max-h-[100px] h-[2rem] overflow-hidden">
                {{ item.question }}
              </td>
              <td class="p-3 w-1/4">
                <span v-if="Array.isArray(item.answersFromUser)">
                  <ul>
                    <li
                      v-for="(answer, index) in item.answersFromUser"
                      :key="index"
                    >
                      - {{ answer }}
                    </li>
                  </ul>
                </span>
                <span v-else>{{ item.answersFromUser }}</span>
              </td>
              <td class="p-3 w-1/4">
                <div
                  v-for="citation in item.citations"
                  :key="citation._id"
                  class="mb-1"
                >
                  <a
                    href="#"
                    @click.prevent="openPdfViewer(citation)"
                    class=""
                    >{{ citation.document_title }}</a
                  >
                </div>
                <button
                  v-if="item.citations.length"
                  class="bg-blue-500 text-white px-2 py-1 rounded-md text-sm mt-2"
                  @click="openCitationsModal(item.citations)"
                >
                  See relevant snippets
                </button>
              </td>
              <!--  <td class="p-3">
                <span
                  :class="getStatusClass(item.status || 'Verification Needed')"
                  >{{ item.status || "Verification Needed" }}</span
                >
              </td> -->
              <td class="p-3">
                <span :class="getAnswerClass(item.answer)">{{
                  item.answer === "YES"
                    ? "Data Found"
                    : item.answer === "NO"
                    ? "Data Not Found"
                    : item.answer === "ERROR"
                    ? "Data Not Found"
                    : "Needs revision"
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- PDF viewer modal -->
      <div
        v-if="showPdfModal"
        class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
        @click.self="closePdfViewer"
      >
        <div
          class="bg-white p-6 rounded-lg shadow-lg w-3/4 h-[90vh] overflow-auto relative"
        >
          <div
            class="absolute top-0 right-10 mt-2 mr-2 cursor-pointer text-gray-500 hover:text-gray-700 text-4xl"
            @click="closePdfViewer"
          >
            &times;
          </div>
          <h2 class="text-xl font-bold mb-4">{{ pdfData.title }}</h2>
          <vue-pdf-embed :source="pdfData.url" @progress="onProgress" />
          <button
            class="bg-red-500 text-white px-4 py-2 rounded-md mt-4"
            @click="closePdfViewer"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <!-- Citations modal -->
    <div
      v-if="showCitationsModal"
      class="fixed inset-0 flex items-center justify-center z-[9999] mx-auto my-auto py-10 bg-black bg-opacity-50"
      @click.self="closeCitationsModal"
    >
      <div
        class="relative bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-2xl h-[75vh] overflow-y-auto"
      >
        <div
          class="absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-gray-500 hover:text-gray-700 text-3xl"
          @click="closeCitationsModal"
        >
          &times;
        </div>
        <h2 class="text-xl font-bold mb-4">Relevant Snippets</h2>
        <div
          v-for="citation in modalCitations"
          :key="citation._id"
          class="mb-4"
        >
          <h3 class="font-semibold">{{ citation.document_title }}</h3>
          <div
            v-for="passage in citation.relevant_passages"
            :key="passage"
            class="ml-4 mt-1"
          >
            <p>{{ passage }}</p>
          </div>
        </div>
        <button
          class="bg-red-500 text-white px-4 py-2 rounded-md mt-4"
          @click="closeCitationsModal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import pageLoader from "@/components/page-loader.vue";
import io from "socket.io-client";
import axios from "axios";
import auth from "@/helpers/auth";
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "twoXThirdPartyVerifier",
  data() {
    return {
      isLoading: true,
      files: [],
      socket: null,
      user: null,
      twoXVerifiedData: [],
      filteredData: [],
      showCitationsModal: false,
      modalCitations: [],
      foundPercentage: 0,
      showPdfModal: false,
      pdfData: null,
      searchQuery: "",
      companies: [],
      selectedCompany: "",
      companyData: [],
      surveyResponsesLength: 0,
    };
  },
  components: {
    pageLoader,
    VuePdfEmbed,
  },
  async mounted() {
    this.user = await auth.me();
    //this.setupWebSocket();
    if (this.$route.query?.companyId) {
      this.companies = await this.listCompanies();
      this.selectedCompany = this.$route.query?.companyId;
      await this.getCompanyData(this.selectedCompany);
    } else {
      this.companies = await this.listCompanies();
    }
    this.isLoading = false;
  },
  methods: {
    setupWebSocket() {
      this.socket = io("ws://localhost:3000");

      this.socket.on("init", (response) => {
        this.twoXVerifiedData = response;
        this.filteredData = response;
      });

      this.socket.on("change", (response) => {
        this.twoXVerifiedData = response;
        this.filterData();
      });
    },
    /*  getStatusClass(status) {
      switch (status) {
        case "Verified":
          return "bg-green-500 text-white px-2 py-1 rounded-md";
        case "Rejected":
          return "bg-red-500 text-white px-2 py-1 rounded-md";
        case "Verification Needed":
          return "bg-yellow-500 text-white px-2 py-1 rounded-md";
        default:
          return "";
      }
    }, */
    getAnswerClass(answer) {
      switch (answer) {
        case "YES":
          return "bg-green-500 text-white px-2 py-1 rounded-md font-bold";
        case "NO":
          return "bg-red-500 text-white px-2 py-1 rounded-md font-bold";
        default:
          return "bg-yellow-500 text-white px-2 py-1 rounded-md font-bold";
      }
    },
    openCitationsModal(citations) {
      this.modalCitations = citations;
      this.showCitationsModal = true;
    },
    closeCitationsModal() {
      this.showCitationsModal = false;
      this.modalCitations = [];
    },
    calculateFoundPercentage() {
      const foundCount = this.companyData.filter(
        (item) => item.answer !== "ERROR"
      ).length;
      const totalCount = this.surveyResponsesLength;
      this.foundPercentage = Math.round((foundCount / totalCount) * 100);
    },
    async openPdfViewer(citation) {
      try {
        const response = await axios.get(
          `${env.apiUrl}/certify2X/fetch-file-from-s3?fileName=${citation.document_name}&teamId=${this.selectedCompany}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
            responseType: "arraybuffer",
          }
        );
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        this.pdfData = { url: pdfUrl, title: citation.document_title };
        this.showPdfModal = true;
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    },

    closePdfViewer() {
      this.showPdfModal = false;
      this.pdfData = null;
    },
    filterData() {
      const query = this.searchQuery.toLowerCase();
      this.filteredData = this.companyData.filter((item) => {
        return (
          item.question.toLowerCase().includes(query) ||
          item.citations.some((citation) =>
            citation.document_title.toLowerCase().includes(query)
          ) ||
          (typeof item.answersFromUser === "string" &&
            item.answersFromUser.toLowerCase().includes(query)) ||
          (Array.isArray(item.answersFromUser) &&
            item.answersFromUser.some((answer) =>
              answer.toLowerCase().includes(query)
            ))
        );
      });
    },
    async listCompanies() {
      const response = await axios.get(
        `${env.apiUrl}/certify2X/list-companies`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
          },
        }
      );
      return response.data.data;
    },
    async getCompanyData(companyId) {
      this.isLoading = true;
      const response = await axios.get(
        `${env.apiUrl}/certify2X/get-company-data?companyId=${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
          },
        }
      );
      this.companyData = response.data.data.companyData;
      this.surveyResponsesLength = response.data.data.surveyResponsesLength;
      this.filteredData = this.companyData;
      this.calculateFoundPercentage();
      this.isLoading = false;
    },
    onProgress(progressData) {
      console.log(progressData); // log the progress of PDF loading
    },
  },
  watch: {
    searchQuery() {
      this.filterData();
    },
  },
};
</script>
