<template>
  <div class="mt-10 w-full flex flex-col items-start justify-start bg-gray-200 px-6 py-6 ease-in-out shadow-xl border-2" :style="'page-break-inside:avoid; background-color: white' + '; border-color:' + intersection.color + '; border-width: 8px'" style="border-radius: 25px;">
    <!--+ opacity(intersection.color) +-->
    <div class="flex justify-between items-center w-full flex-col lg:flex-row">
      <div class="flex flex-col justify-start mb-auto lg:w-8/12">
        <div class="flex flex-row justify-start items-center">
          <!--<div class="flex items-center justify-center rounded-lg p-3 h-20 w-20 print:h-10 print:w-10 mr-5" style="background-color: #2F2665;">-->
          <div class="flex items-center justify-center p-3 h-20 w-20 print:h-10 print:w-10 mr-5 bg-white border-2" :style="'border-color:' + intersection.color" style="border-radius: 25px;">
              <img class="w-10 h-10" :src="intersection.theme_icon" />
          </div>
          <div>
            <h3 class="print:text-xl text-3xl font-semibold tracking-tight" :style="'color:'+ env.brandingColors.navy">{{intersection.theme}}</h3>
            <p class="text-xl text-black tracking-tight print:text-sm font-semibold" :style="'color:'+intersection.color">{{intersection.taab}}</p>
          </div>
        </div>
        <div class="flex flex-row justify-start items-center">
          <p class="mt-10 print:mt-4 text-gray-600 text-justify">
            <rounded-item :text="intersection.text" :country="country" class="print:text-sm"></rounded-item>
          </p>
        </div>
        <div v-if="!isPrint" class="flex flex-row justify-start items-center mt-10 print:mt-6 pointer print:hidden " @click="moreInfo()">
          <button class="flex flex-row items-center justify-start text-white px-4 py-3 border transition duration-200" :style="'background-color: ' + env.brandingColors.navy + '; border-radius: 12px;'">
            <p class="capitalize mr-3 cursor-pointer font-semibold">Explore Entire Theme</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-arrow-right">
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <polyline points="12 5 19 12 12 19"></polyline>
            </svg>
          </button>
        </div>
      </div>


      <div class="flex flex-col lg:w-3/12 print:mt-6 print:flex-row print:justify-between print:w-full">
        <div v-if="intersection.gender_equality !== 999" class="flex flex-row  px-3 py-2 rounded-lg items-center print:mb-0"
             :style="'background-color: ' + env.brandingColors.orange">
          <!--:style="intersection.nonKeyAreas ? 'background-color: #2F2665' : 'background-color: #f56565'"-->
          <div class="flex flex-row text-white pl-2 items-center">
            <span class="pr-2 text-sm font-semibold">Gender Equality Score</span>
            <svg @mouseleave="genderEqualityInfo = false" @mouseover="genderEqualityInfo = true" class="print:hidden cursor-pointer w-8 h-8 relative transform rotate-90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.86 68.4"><path d="M-8.24,57.59A28.51,28.51,0,0,0-36.68,84.1h-8.11V79.46a3.39,3.39,0,0,0-3.39-3.39h-.45A3.39,3.39,0,0,0-52,79.46V84.1h-5.63a3.21,3.21,0,0,0-3.21,3.21,3.22,3.22,0,0,0,3.21,3.22H-52V96a3.38,3.38,0,0,0,3.39,3.38h.45A3.38,3.38,0,0,0-44.79,96V90.53h8.37a28.53,28.53,0,0,0,28.18,24.1A28.52,28.52,0,0,0,20.28,86.11,28.52,28.52,0,0,0-8.24,57.59Z" transform="translate(60.86 -46.23)" style="fill:#1577bd"/><path d="M68.6,46.27,52.46,49.45a2,2,0,0,0-1,3.39L56,57.45l-6.71,6.71a28.58,28.58,0,1,0,4.45,4.64l6.8-6.8,3.81,3.8a2,2,0,0,0,3.39-1L71,48.63A2,2,0,0,0,68.6,46.27Z" transform="translate(60.86 -46.23)" style="fill:#fff"/><ellipse cx="72.3" cy="39.88" rx="8.84" ry="20.63" style="fill:#51c2ba"/></svg>
          </div>

          <div v-if="genderEqualityInfo" class="flex flex-col relative items-start">
            <div class="absolute min-w-full w-64 mt-1 top-0 bg-white rounded border border-gray-300 shadow-lg z-50 right-0">
              <div class="mt-4 px-5 min-w-full bg-white">
                <p class="text-gray-800 text-sm mb-2 font-semibold">Gender Equality Scoreboard</p>
              </div>

              <div v-if="intersection.gender_equality_region" class="pb-2 pt-4 px-5 min-w-full bg-gray-100">
                <p class="text-gray-800 text-sm mb-2 font-semibold">
                  {{country.region}}: <b>{{toFixed(intersection.gender_equality_region, 2)}}</b><br />

                  <!-- Who is the winner -->
                  <small style="color: rgb(254, 80, 0);"
                    v-if="toFixed(intersection.gender_equality, 3) == toFixed(intersection.gender_equality_region, 3)">(that's
                    a tie)</small>
                  <small style="color: green;"
                    v-else-if="intersection.gender_equality > intersection.gender_equality_region">({{country.humanName}}
                    is ahead by
                    <span>+{{ toFixed((intersection.gender_equality - intersection.gender_equality_region), 3) }}</span>)</small>
                  <small style="color: #f56565" v-else>({{country.humanName}} is behind by
                    <span>{{ toFixed((intersection.gender_equality - intersection.gender_equality_region), 3) }}</span>)</small>

                </p>
              </div>
              <hr>
              <div v-if="intersection.gender_equality_income" class="pb-2 pt-4 px-5 min-w-full bg-gray-100">
                <p class="text-gray-800 text-sm mb-2 font-semibold">
                  {{country.income}}: <b>{{toFixed(intersection.gender_equality_income, 2)}}</b><br />
                  <!-- Who is the winner -->
                  <small style="color: rgb(254, 80, 0);"
                    v-if="toFixed(intersection.gender_equality, 3) == toFixed(intersection.gender_equality_income, 3)">(that's
                    a tie)</small>
                  <small style="color: green;"
                    v-else-if="intersection.gender_equality > intersection.gender_equality_income">({{country.humanName}}
                    is ahead by
                    <span>+{{ toFixed((intersection.gender_equality - intersection.gender_equality_income), 3) }}</span>)</small>
                  <small style="color: #f56565" v-else>({{country.humanName}} is behind by
                    <span>{{ toFixed((intersection.gender_equality - intersection.gender_equality_income), 3) }}</span>)</small>
                </p>
              </div>
            </div>
          </div>

          <div class="ml-3 bg-white rounded-full ml-auto">
            <p class="font-bold p-2 print:py-1 print:px-2" :style="intersection.nonKeyAreas ? 'color: #2F2665' : 'color: #f56565'">
              {{toFixed(intersection.gender_equality, 2)}}
            </p>
          </div>

        </div>

        <div v-if="typeof intersection.power !== undefined && intersection.gender_equality !== 999" class="flex print:flex-row flex-col p-3 rounded-lg items-center bg-white print:mb-0">
          <div class="w-full flex flex-row items-center justify-between">
            <div class="flex flex-row text-gray-600 pl-2">
              <span class="pr-2 text-xs print:text-sm font-semibold">Confidence Score</span>
              <div class="relative cursor-pointer print:hidden " @mouseover="validityScoreInfo = true" @mouseleave="validityScoreInfo = false">
                <svg class="w-5 h-5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g fill="none"><path d="M0,0h24v24h-24Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12,21v0c-4.971,0 -9,-4.029 -9,-9v0c0,-4.971 4.029,-9 9,-9v0c4.971,0 9,4.029 9,9v0c0,4.971 -4.029,9 -9,9Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12,17v-5h-1"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.749,8c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25"></path></g></svg>
                <!--validityScoreInfo-->
                <div v-if="validityScoreInfo" class="absolute px-5 p-4 min-w-full w-64 mt-5 top-0 bg-white rounded-lg border border-gray-300 shadow-lg z-50" >
                  <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Confidence Score: </p>
                  <p class="text-gray-500 text-sm">
                    The <b>Confidence Score </b> measures the reliability of the Gender Equality Score on a scale of 0-1. A higher Confidence Score indicates greater data quality (i.e., data from internationally standardized sources, collected within the last ten years) and comparability across countries. </p>
                </div>
              </div>
            </div>
            <div class="text-white rounded-full flex-shrink-0">
              <p class="text-white text-xs font-bold p-2 print:py-1 print:px-2" style="color: #2F2665;">
                {{toFixed(intersection.validity, 2)}}
              </p>
            </div>
          </div>
          <div class=" w-full flex flex-row items-center justify-between">
            <div class="flex flex-row text-gray-600 pl-2">
              <span class="pr-2 text-xs print:text-sm font-semibold">Power Score</span>
              <div class="relative cursor-pointer print:hidden " @mouseover="powerScoreInfo = true" @mouseleave="powerScoreInfo = false">
                <svg class="w-5 h-5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g fill="none"><path d="M0,0h24v24h-24Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12,21v0c-4.971,0 -9,-4.029 -9,-9v0c0,-4.971 4.029,-9 9,-9v0c4.971,0 9,4.029 9,9v0c0,4.971 -4.029,9 -9,9Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12,17v-5h-1"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.749,8c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25"></path></g></svg>
                <!--validityScoreInfo-->
                <div v-if="powerScoreInfo" class="absolute px-5 p-4 min-w-full w-64 mt-5 top-0 bg-white rounded-lg border border-gray-300 shadow-lg z-50" >
                  <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Power Score: </p>
                  <p class="text-gray-500 text-sm">
                    The <b>Power Score </b> measures the robustness of the Gender Equality Score on a scale of 0-1. A higher Power Score indicates greater data availability, including data from any year sourced from both internationally standardized and alternative sources.</p>
                </div>
              </div>
            </div>
            <div class="text-white ml-3 rounded-full ml-auto" >
              <p class="text-white text-xs font-bold p-2 print:py-1 print:px-2" style="color: #2F2665;">
                {{toFixed(intersection.power, 2)}}
              </p>
            </div>
          </div>
          </div>
        <!--<div v-if="typeof intersection.power !== undefined && intersection.gender_equality !== 999" class="flex flex-row p-3 rounded-lg items-center mb-5 bg-white print:mb-0">-->
          <!--<div class="flex flex-row text-gray-600 pl-2">-->
            <!--<span class="pr-2 print:text-sm font-semibold">Power Score</span>-->
            <!--<svg @mouseover="powerScoreInfo = true" @mouseleave="powerScoreInfo = false"-->
              <!--class="cursor-pointer w-5 h-5 relative print:hidden " version="1.1" viewBox="0 0 24 24"-->
              <!--xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
              <!--<g fill="none">-->
                <!--<path d="M0,0h24v24h-24Z"></path>-->
                <!--<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"-->
                  <!--d="M12,21v0c-4.971,0 -9,-4.029 -9,-9v0c0,-4.971 4.029,-9 9,-9v0c4.971,0 9,4.029 9,9v0c0,4.971 -4.029,9 -9,9Z">-->
                <!--</path>-->
                <!--<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"-->
                  <!--d="M12,13.25v-0.25c0,-0.817 0.505,-1.26 1.011,-1.6c0.494,-0.333 0.989,-0.767 0.989,-1.567c0,-1.105 -0.895,-2 -2,-2c-1.105,0 -2,0.895 -2,2">-->
                <!--</path>-->
                <!--<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"-->
                  <!--d="M11.999,16c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25">-->
                <!--</path>-->
              <!--</g>-->
            <!--</svg>-->
          <!--</div>-->
          <!--<div v-if="powerScoreInfo" class="flex flex-col relative items-start">-->
            <!--<div-->
              <!--class="absolute px-5 p-4 min-w-full w-64 mt-1 top-0 bg-white rounded-lg border border-gray-300 shadow-lg z-50">-->
              <!--<p class="text-gray-600 text-sm mb-2 font-semibold">Power Score: </p>-->
              <!--<p class="text-gray-500 text-sm"> The Power Score is the percentage of available indicator values within a-->
                <!--theme. This includes both-->
                <!--values from internationally standardized datasets as well as alternative source proxies.-->
              <!--</p>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="text-white ml-3 rounded-full ml-auto" style="background-color: #2F2665;">-->
            <!--<p class="text-white font-bold p-2 print:py-1 print:px-2">-->
              <!--{{intersection.power.toFixed(2)}}-->
            <!--</p>-->
          <!--</div>-->
        <!--</div>-->
        <div v-else class="flex flex-row print:p-2 p-4 justify-between items-center rounded-lg items-right mb-5 text-gray-800 bg-white print:mb-0">
          <p class="print:mr-3 font-semibold text-gray-600">Unscored</p>
          <div class="text-white rounded-full ml-auto" style="background-color: rgba(0,0,0,0.1)">
            <p class="p-2 print:p-1 print:px-3 px-6 text-black font-semibold">
             /
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 //import item from '@/components/text.vue';
 import roundedItem from '@/components/text-roundnumbers.vue';
 import env from '@/../env.js';

  export default {
    name: 'intersection',
    props: ['intersection', 'country', 'isPrint'],
    data: function () {
      return {
        genderEqualityInfo: false,
        powerScoreInfo: false,
        validityScoreInfo: false,
        env
      }
    },
    components: {
      //item,
      roundedItem
    },
    methods: {
      moreInfo: function () {
        this.$router.push({
          path: "/reports/" + this.$route.params.iso2 +'/'+this.$route.params.sector+ "/themes/" + this.intersection.theme
        });
      },

      toFixed: function(input, howMuch){
        input = input.toString();
        if(input && input.indexOf('Missing') == -1 && input && input.indexOf('N/A') == -1){
          try {
            return Number(input).toFixed(howMuch)
          } catch(e){
            return 0
          }
        } else if(input && input.indexOf('N/A') > -1) {
          return 'N/A';
        }
        else {
          return 'Missing'
        }
      },
      opacity: function (hex) {

        if(!hex){
          return 'white'
        }

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const rgb = result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
        return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.25)'
      },

    }
  }
</script>

<style>
  .topicCard:hover {
    transform: scale(1.02);
  }
</style>
