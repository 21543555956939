<template>
    <div class="estimated-time-inner flex">
        <img src="./../../assets/images/IMM/clock.svg" />
        <p>{{ text }}</p>
    </div>
</template>
<script>
export default {
    name: "EstimatedTime",
    props: {
        text: String
    }
}
</script>
<style lang="scss" scoped>
.estimated-time-inner {
    height: 44px;
    padding: 12px 16px 12px 16px;
    border-radius: 32px;
    gap: 4px;
    background: #F4F6F9;
    p {
        font-family: Arial;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: right;
        color: #717686;
    }
}
</style>