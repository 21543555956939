<template>
  <div class="static">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!whatWorks" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="whatWorks && whatWorks.length > 0 && whatWorks[0]._id" key="content">
        <div class="flex flex-col items-center mt-top-5 justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div class="
                    flex flex-row
                    items-center
                    justify-center
                    print:flex-col
                  ">
                  <svg :style="'color: ' + env.brandingColors.blue" xmlns="http://www.w3.org/2000/svg" width="80"
                    height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                  <h1 class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    ">
                    What Works
                    <span v-if="sector && sector.id">in {{ sector.name }}</span>
                  </h1>
                </div>
                <p class="
                    text-gray-600
                    print:text-2xl print:mt-10
                    font-medium
                    text-center
                  " v-if="countryData.countries.length == 1">
                  Country: {{ country.humanName }} | Region:
                  {{
                      country.region == "Eastern Europe & Central Asia"
                        ? "Europe & Central Asia"
                        : country.region
                  }}
                  | Income Group:
                  {{ toTitle(country.income || "") }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <main>
          <div class="navsticky">
            <span>Go to...</span>
            <ol class="what_works_side_bar_list">
              <li>
                <router-link :to="
                  '/gesi-analysis/' +
                  $route.params.iso2 +
                  '/' +
                  $route.params.sector +
                  '/snapshot'
                " class="buttonlink" style="margin-left: -10px"><span>Country Snapshot</span></router-link>
              </li>
              <li>
                <router-link :to="
                  '/reports/' +
                  $route.params.iso2 +
                  '/' +
                  $route.params.sector +
                  '/domains'
                " class="buttonlink" style="margin-left: -10px"><span>Domains</span></router-link>
              </li>
              <li>
                <router-link :to="
                  '/reports/' +
                  country.ISO2 +
                  '/' +
                  $route.params.sector +
                  '/themes'
                " class="buttonlink" style="margin-left: -10px" v-if="countryData.countries.length == 1">
                  <span>Themes</span></router-link>
              </li>
            </ol>
          </div>

          <div>
            <div id="SectionWhatWorks" class="maincontent">
              <span style="visibility: hidden">SectionWhatWorks1</span>
            </div>
            <section class="
                w-full
                container
                mx-auto
                margintop3
                px-4
                lg:px-0
                border-t border-gray-300
              " style="max-width: 960px">
              <div class="flex flex-col mt-10 mb-10" v-scroll-reveal>
                <div class="
                    text-gray-600
                    w-full
                    text-justify
                    mt-3
                    font-medium
                    leading-7
                  ">
                  <p>
                    It's time for action! Understanding the challenges and
                    opportunities to improve gender equality is the important
                    first step to support action and change. The next step is
                    taking that information and doing something about it. Below
                    is information about what works to harness gender equality
                    opportunities and address challenges for this theme,
                    including resources and tools to support action.
                  </p>
                  <br />
                  <p>
                    When you think about actions you can take, explore all actor
                    types, not only focusing on what you can do but also what
                    others in this ecosystem can do. Think about your own sphere
                    of influence and conversations or opportunities to inspire
                    others to act as well.
                  </p>
                  <br />
                  <p class="text-small">
                    <i><b>Disclaimer</b>: Never implement an action before
                      consulting with a gender equality and social inclusion
                      specialist who can advise on appropriate stakeholder
                      consultation and tailor a bespoke project/investment- and
                      context-specific action plan. Equilo will evolve its
                      intelligence and features to support deeper analysis in
                      the future.</i>
                  </p>
                  <br />
                  <p v-if="$route.params.iso2 == 'EQ'">
                    <b><a :href="env.apiUrl +'/'+ env.productFamilyIds.gesi_aid" target="_blank"
                        style="text-decoration: underline">Subscribe now</a>
                      to get access to the full library of What Works suggested
                      actions available to sort by theme, actor type, and impact
                      level.
                    </b>
                  </p>
                  <p v-else>
                    <item
                      text="<b>Please select theme, actor type, and impact level[s45557] below to view tailored actions.</b>"
                      :country="country"></item>
                  </p>
                </div>
              </div>

              <!-- FILTERS -->

              <div class="flex flex-col mt-5" v-if="$route.params.iso2 != 'EQ'">
                <div class="flex flex-row mb-2">
                  <div class="flex flex-col mx-auto w-4/12 lg:w-4/12 mr-2" id="theme-filter">
                    <div class="
                        text-sm
                        leading-5
                        text-gray-900
                        w-full
                        relative
                        flex flex-row
                        items-center
                      ">
                      <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 4 24 24">
                        <rect class="fltr-line1" x="7" y="10" width="18" height="1" fill="currentColor" />
                        <rect class="fltr-line2" x="7" y="20" width="18" height="1" fill="currentColor" />
                        <circle class="fltr-crcl1" cx="13" cy="20.5" r="2.5" fill="white" stroke="currentColor" />
                        <circle class="fltr-crcl2" cx="19" cy="10.5" r="2.5" fill="white" stroke="currentColor" />
                        <rect width="24" height="24" fill="none" />
                      </svg>
                      <v-select @input="this.selectTheme" :value="this.selectedTheme" :options="this.themes" class="
                          border-2 border-gray-200
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          pl-10
                          font-medium
                          focus:outline-none focus:shadow-outline
                        " placeholder="Select a Theme" label="name" :clearable="false"></v-select>
                    </div>
                  </div>

                  <div class="flex flex-col cursor-pointer print:hidden h-5 w-5" style="transform: translateY(70%)"
                    @mouseover="actorInfo = true" @mouseleave="actorInfo = false">
                    <svg class="absolute h-5 w-5 text-gray-500" id="info" data-name="Info"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <rect width="24" height="24" fill="white" />
                      <g fill="none">
                        <path d="M0,0h24v24h-24Z"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12,17v-5h-1"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M11.749,8c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25">
                        </path>
                      </g>
                    </svg>
                    <div v-if="
                      (this.selectedActor != 'Development') &
                      (this.selectedActor != 'Financial') &
                      actorInfo
                    " class="
                        absolute
                        px-5
                        p-4
                        w-64
                        mt-10
                        ml-5
                        top-0
                        bg-white
                        rounded-lg
                        border border-gray-300
                        shadow-lg
                      " style="background-color: #f9f9f9;box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);z-index: 9999;">
                      <p class="
                          text-gray-800 text-sm
                          print:text-xs
                          mb-2
                          font-medium
                        ">
                        Actor Type:
                      </p>
                      <p class="text-gray-600 text-sm">
                        There are a variety of <b>actors</b> within this
                        ecosystem, from financial institutions and national
                        governments to development actors, and more. Tailored
                        "What Works" are identified so that various actors can
                        direct their own work and influence others’ work.
                      </p>
                    </div>
                    <div v-if="
                      ((this.selectedActor == 'Development') |
                        (this.selectedActor == 'Financial')) &
                      actorInfo
                    " class="
                        absolute
                        px-5
                        p-4
                        w-64
                        ml-5
                        top-0
                        bg-white
                        rounded-lg
                        border border-gray-300
                        shadow-lg
                      "
                      style="margin-top: 98px;background-color: #f9f9f9;box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);z-index: 9999;">
                      <p class="
                          text-gray-800 text-sm
                          print:text-xs
                          mb-2
                          font-medium
                        ">
                        Actor Type:
                      </p>
                      <p class="text-gray-600 text-sm">
                        There are a variety of <b>actors</b> within this
                        ecosystem, from financial institutions and national
                        governments to development actors, and more. Tailored
                        "What Works" are identified so that various actors can
                        direct their own work and influence others’ work.
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-col mx-auto w-4/12 lg:w-4/12 mr-2" id="actor-filter">
                    <div class="
                        text-sm
                        leading-5
                        text-gray-900
                        w-full
                        relative
                        flex flex-row
                        items-center
                      ">
                      <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 4 24 24">
                        <rect class="fltr-line1" x="7" y="10" width="18" height="1" fill="currentColor" />
                        <rect class="fltr-line2" x="7" y="20" width="18" height="1" fill="currentColor" />
                        <circle class="fltr-crcl1" cx="13" cy="20.5" r="2.5" fill="white" stroke="currentColor" />
                        <circle class="fltr-crcl2" cx="19" cy="10.5" r="2.5" fill="white" stroke="currentColor" />
                        <rect width="24" height="24" fill="none" />
                      </svg>
                      <v-select @input="this.selectActor" :value="this.selectedActor" :options="this.actors" class="
                          border-2 border-gray-200
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          pl-10
                          font-medium
                          focus:outline-none focus:shadow-outline
                        " placeholder="Select an Actor" label="name" :clearable="false"></v-select>
                    </div>
                  </div>

                  <div class="
                      flex flex-col
                      cursor-pointer
                      print:hidden
                      h-5
                      w-5
                      ml-1
                    " style="transform: translateY(70%)" @mouseover="impactInfo = true"
                    @mouseleave="impactInfo = false">
                    <svg class="absolute h-5 w-5 text-gray-500" id="info" data-name="Info"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <rect width="24" height="24" fill="white" />
                      <g fill="none">
                        <path d="M0,0h24v24h-24Z"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12,17v-5h-1"></path>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M11.749,8c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25">
                        </path>
                      </g>
                    </svg>
                    <div v-if="impactInfo" class="
                        absolute
                        px-5
                        p-4
                        w-64
                        mt-10
                        ml-5
                        top-0
                        bg-white
                        rounded-lg
                        border border-gray-300
                        shadow-lg
                      " style="background-color: #f9f9f9;box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);z-index: 9999;">
                      <p class="
                          text-gray-800 text-sm
                          print:text-xs
                          mb-2
                          font-medium
                        ">
                        Impact Level:
                      </p>
                      <p class="text-gray-600 text-sm">
                        The <b>impact level</b> tells us if an action is minimum
                        compliance, or if it pushes beyond compliance to empower
                        individuals or transform systems and communities in a
                        positive way.<br /><br />
                      </p>
                      <p class="text-gray-600 text-xs">
                        <b>Minimum compliance: </b>Investments or programs
                        mitigate basic risk and address practical needs and
                        vulnerabilities of women and socially excluded
                        groups.<br />
                        <b>Empowerment: </b>Investments or programs build
                        assets, capabilities, and opportunities for women and
                        socially excluded groups.<br />
                        <b>Transformation: </b>Investments or programs address
                        unequal power relations and seek institutional and
                        societal changes.
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-col mx-auto w-4/12 lg:w-4/12 mr-2" id="impact-filter">
                    <div class="
                        text-sm
                        leading-5
                        text-gray-900
                        w-full
                        relative
                        flex flex-row
                        items-center
                      ">
                      <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 4 24 24">
                        <rect class="fltr-line1" x="7" y="10" width="18" height="1" fill="currentColor" />
                        <rect class="fltr-line2" x="7" y="20" width="18" height="1" fill="currentColor" />
                        <circle class="fltr-crcl1" cx="13" cy="20.5" r="2.5" fill="white" stroke="currentColor" />
                        <circle class="fltr-crcl2" cx="19" cy="10.5" r="2.5" fill="white" stroke="currentColor" />
                        <rect width="24" height="24" fill="none" />
                      </svg>
                      <v-select @input="this.selectImpact" :value="this.selectedImpact" :options="this.impact_levels"
                        class="
                          border-2 border-gray-200
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          pl-10
                          font-medium
                          focus:outline-none focus:shadow-outline
                        " placeholder="Select an Impact Level" label="name" :clearable="false"></v-select>
                    </div>
                  </div>
                </div>

                <div class="flex flex-row mb-4" style="align-items: flex-end">
                  <div class="flex flex-col w-4/12 ml-1"></div>
                  <div v-if="
                    (this.selectedActor == 'Development') |
                    (this.selectedActor == 'Financial')
                  " class="flex flex-col w-4/12 ml-5 mr-5 lg:w-4/12" id="subactor-filter">
                    <div class="
                        text-sm
                        leading-5
                        text-gray-900
                        w-full
                        relative
                        flex flex-row
                        items-center
                      ">
                      <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 4 24 24">
                        <rect class="fltr-line1" x="7" y="10" width="18" height="1" fill="currentColor" />
                        <rect class="fltr-line2" x="7" y="20" width="18" height="1" fill="currentColor" />
                        <circle class="fltr-crcl1" cx="13" cy="20.5" r="2.5" fill="white" stroke="currentColor" />
                        <circle class="fltr-crcl2" cx="19" cy="10.5" r="2.5" fill="white" stroke="currentColor" />
                        <rect width="24" height="24" fill="none" />
                      </svg>
                      <v-select ref="select" @input="this.selectSubActor" :value="this.selectedSubActor"
                        :options="this.subactors" class="
                          border-2 border-gray-200
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          pl-10
                          font-medium
                          focus:outline-none focus:shadow-outline
                        " placeholder="Select a Sub-Actor" label="name" :clearable="false">
                        <span slot="no-options" @click="$refs.select.open = false">
                          There are no sub-actors for this actor!
                        </span>
                      </v-select>
                    </div>
                  </div>
                  <div class="flex flex-col w-4/12 ml-4"></div>
                </div>
              </div>
              <!--          <p class="text-gray-600 w-full text-justify mt-3 font-sm leading-7" style="font-size: 15px">
            <em>There are {{ filteredTips.length }} actions for the above criteria.</em>
          </p>-->

              <!-- IMPACT LEVEL HOVER -->
              <div v-if="minComplianceInfo && $route.params.iso2 != 'EQ'" id="impact-hover" class="
                  px-5
                  p-4
                  mt-10
                  top-0
                  bg-white
                  rounded-lg
                  border border-gray-300
                  shadow-lg
                " :style="
                  'position: absolute; z-index: 999; top: ' +
                  this.impactHoverOffsetY +
                  '; left: ' +
                  this.impactHoverOffsetX
                ">
                <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">
                  Minimum Compliance
                </p>
                <p class="text-gray-600 text-sm">
                  These actions mitigate basic risk and address practical needs
                  and vulnerabilities of women and socially excluded groups.
                </p>
              </div>
              <div v-if="empowermentInfo && $route.params.iso2 != 'EQ'" id="impact-hover" class="
                  px-5
                  p-4
                  mt-10
                  top-0
                  bg-white
                  rounded-lg
                  border border-gray-300
                  shadow-lg
                " :style="
                  'position: absolute; z-index: 999; top: ' +
                  this.impactHoverOffsetY +
                  '; left: ' +
                  this.impactHoverOffsetX
                ">
                <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">
                  Empowerment
                </p>
                <p class="text-gray-600 text-sm">
                  These actions build assets, capabilities, and opportunities
                  for women and socially excluded groups.
                </p>
              </div>
              <div v-if="transformationInfo && $route.params.iso2 != 'EQ'" id="impact-hover" class="
                  px-5
                  p-4
                  mt-10
                  top-0
                  bg-white
                  rounded-lg
                  border border-gray-300
                  shadow-lg
                " :style="
                  'position: absolute; z-index: 999; top: ' +
                  this.impactHoverOffsetY +
                  '; left: ' +
                  this.impactHoverOffsetX
                ">
                <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">
                  Transformation
                </p>
                <p class="text-gray-600 text-sm">
                  These actions address unequal power relations and seek
                  institutional and societal changes.
                </p>
              </div>

              <!-- IMPACT LEVEL KEY -->
              <div class="w-full z-1" style="position: absolute; height: 80%; max-width: 960px"
                v-if="$route.params.iso2 != 'EQ'">
                <div id="impact-key" class="
                    px-5
                    p-4
                    bg-white
                    rounded-lg
                    border border-gray-300
                    shadow-lg
                  ">
                  <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">
                    Impact Level Key
                  </p>
                  <div class="text-gray-600 text-xs flex flex-row">
                    <img class="w-4 h-4 mr-2 my-2 flex flex-col"
                      src="../../assets/images/minimum compliance - purple.png" />
                    <span class="flex flex-col justify-center"><em>Minimum Compliance</em></span>
                  </div>
                  <div class="text-gray-600 text-xs flex flex-row">
                    <img class="w-4 h-4 mr-2 my-2" src="../../assets/images/empowerment - purple.png" />
                    <span class="flex flex-col justify-center"><em>Empowerment</em></span>
                  </div>
                  <div class="text-gray-600 text-xs flex flex-row">
                    <img class="w-4 h-4 mr-2 my-2" src="../../assets/images/transformation - purple.png" />
                    <span class="flex flex-col justify-center"><em>Transformation</em></span>
                  </div>
                  <div class="text-gray-600 text-xs flex flex-row mt-3">
                    <div class="flex flex-col">
                      These categories are drawn from the ICED Gender &
                      Inclusion Framework, developed by Caroline Moser in 2016.
                      Read more on our
                      <router-link to="/gesi-analysis/methodology" style="color: #201747">
                        Methodology page.</router-link>
                    </div>
                  </div>
                </div>
              </div>

              <!-- FOR EACH TIP -->
              <div v-if="$route.params.iso2 != 'EQ'">
                <div :style="{
                  position: (!actorInfo && !impactInfo ? 'relative' : ''),
                  'border-color': env.brandingColors.navy,
                }" class="
                    shadow
                    overflow-hidden
                    rounded-lg
                    border-2
                    mt-3
                    pb-i-avoid
                    z-100
                  " v-for="tip in filteredTips" v-bind:key="tip._id">
                  <div class="
                      px-4
                      py-5
                      border-b border-gray-200
                      sm:px-6
                      flex
                      row
                      cursor-pointer
                    " @click="togglePanel(tip._id)">
                    <div class="flex flex-col w-10" style="display: inline; white-space: nowrap">
                      <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" alt="Minimum Compliance"
                        src="../../assets/images/minimum compliance - purple.png" title="Minimum Compliance"
                        style="display: inline; white-space: nowrap" @mouseover="impactMouseover"
                        @mouseleave="minComplianceInfo = false" />
                      <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" alt="Empowerment"
                        src="../../assets/images/empowerment - purple.png" title="Empowerment"
                        style="display: inline; white-space: nowrap" @mouseover="impactMouseover"
                        @mouseleave="empowermentInfo = false" />
                      <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" alt="Transformation"
                        src="../../assets/images/transformation - purple.png" title="Transformation"
                        style="display: inline; white-space: nowrap" @mouseover="impactMouseover"
                        @mouseleave="transformationInfo = false" />
                    </div>

                    <div class="flex flex-col w-11/12">
                      <span class="text-medium leading-6 font-medium" :style="'color: ' + env.brandingColors.navy">
                        {{ tip.name }}
                      </span>
                    </div>

                    <div style="margin-left: auto; cursor: pointer" :style="'color: ' + env.brandingColors.navy">
                      <span v-if="openPanels.indexOf(tip._id) > -1 || print">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-chevron-down no-print">
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </span>
                      <span v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-chevron-right">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <transition name="slide-fade">
                    <div v-if="openPanels.indexOf(tip._id) > -1 || print" class="flex flex-col mt-5 px-10 pb-5"
                      :style="'color: ' + env.brandingColors.navy">
                      <div class="flex flex-row mb-3">
                        <div class="
                            flex flex-col
                            items-center
                            justify-center
                            h-10
                            w-10
                            print:h-10 print:w-10
                            border-0
                          ">
                          <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5"
                            src="../../assets/images/minimum compliance - purple.png" />
                          <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5"
                            src="../../assets/images/empowerment - purple.png" />
                          <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5"
                            src="../../assets/images/transformation - purple.png" />
                        </div>
                        <div class="
                            flex flex-col
                            text-sm
                            ml-1
                            h-10
                            w-100
                            items-center
                            justify-center
                          ">
                          <item v-if="tip.compliance == 'Minimum Compliance'"
                            text="<em><b>Impact Level: Minimum Compliance </b>- These actions mitigate basic risk and address practical needs and vulnerabilities of women and socially excluded groups.</em>[s45557]"
                            :country="country"></item>
                          <item v-if="tip.compliance == 'Empowerment'"
                            text="<em><b>Impact Level: Empowerment </b>- These actions build assets, capabilities, and opportunities for women and socially excluded groups.</em>[s45557]"
                            :country="country"></item>
                          <item v-if="tip.compliance == 'Transformation'"
                            text="<em><b>Impact Level: Transformation </b>- These actions address unequal power relations and seek institutional and societal changes.</em>[s45557]"
                            :country="country"></item>
                        </div>
                      </div>

                      <item :text="tip.text" :country="country" class="text-sm mt-3"></item>

                      <p class="
                          mt-6
                          text-lg
                          font-medium
                          flex flex-row
                          items-center
                          justify-start
                        ">
                        <span>Learning and implementation resources</span>
                      </p>
                      <div v-for="tip in tip.items" v-bind:key="tip.text" class="
                          flex flex-row
                          items-start
                          justify-start
                          list-decimal
                          mt-5
                        " :style="'color:' + env.brandingColors.navy" style="align-items: baseline">
                        <div class="w-8 flex-shrink-0">
                          <svg class="w-5 h-5 mx-1 text-blue-600 inline" version="1.1" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none"
                              stroke-linejoin="round">
                              <path d="M19,12h-14"></path>
                              <path d="M14,17l5,-5"></path>
                              <path d="M14,7l5,5"></path>
                            </g>
                            <path fill="none" d="M0,0h24v24h-24Z"></path>
                          </svg>
                        </div>
                        <item :text="tip.text" :country="country" class="text-sm"></item>
                        <br />
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </section>

            <section class="
                w-full
                container
                mx-auto
                mt-32
                mb-32
                print:hidden
                px-4
                lg:px-0
                flex flex-col
                items-end
                justify-end
                gap-4
              " v-if="!print && $route.params.iso2 != 'EQ'">
              <router-link :to="
                '/reports/' +
                $route.params.iso2 +
                '/' +
                $route.params.sector +
                '/themes'
              " :style="'background-color:' + env.brandingColors.navy"
                v-if="countryData.countries.length == 1" class="
                  flex-grow-0
                  px-3
                  py-2
                  text-sm
                  hover:bg-blue-400
                  font-semibold
                  rounded-lg
                  focus:outline-none focus:shadow-outline-indigo
                  text-white
                  transition
                  duration-200
                  shadow
                  border border-indigo-200
                  hover:border-indigo-400
                  flex flex-row
                  items-center
                  justify-center
                  gap-2
                ">
                <p class="font-medium capitalize ml-3">Go to All Themes</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </router-link>
              <router-link :to="
                '/reports/' + $route.params.iso2 + '/' + $route.params.sector
              " :style="'background-color:' + env.brandingColors.navy" class="
                  flex-grow-0
                  px-3
                  py-2
                  text-sm
                  hover:bg-blue-400
                  font-semibold
                  rounded-lg
                  focus:outline-none focus:shadow-outline-indigo
                  text-white
                  transition
                  duration-200
                  shadow
                  border border-indigo-200
                  hover:border-indigo-400
                  flex flex-row
                  items-center
                  justify-center
                  gap-2
                ">
                <p class="font-medium capitalize ml-3">
                  Back to Country Overview
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </router-link>
            </section>

            <section class="w-full container mx-auto mt-32 mb-32 px-4 lg:px-0" style="max-width: 960px"
              v-if="$route.params.iso2 != 'EQ'">
              <div class="flex flex-col mt-10 justify-start">
                <p class="text-xs text-gray-500">
                  Data Use and Citation: Equilo grants full permission to its
                  users to copy and paste information that appears in the
                  dashboard for their own internal use, directly citing the
                  original data sources and citing Equilo for analysis and
                  scoring. Please request permission from Equilo before
                  utilizing our content for external publications.
                </p>
                <p class="text-xs text-gray-500 my-5" style="max-width: 960px">
                  Sources on this page:<br />
                </p>
                <ol class="list-decimal mx-5">
                  <li v-for="citation in citations" v-bind:key="citation.id">
                    {{
                        ((citation || {}).text || "")
                          .toString()
                          .replace("n.d.", citation.year + ". ")
                    }}
                    (<a target="_blank" :href="citation.url" style="word-wrap: break-word">{{ citation.url }}</a>)
                  </li>
                </ol>
                <p class="text-xs text-gray-500" style="max-width: 960px">
                  Equilo uses the TAAP framework, which stands for Transforming
                  Agency, Access, and Power. Comprised of six domains, the TAAP
                  framework provides a structure to analyze and amplify the
                  voices, opportunities, and dignity of all people, especially
                  those from socially excluded groups. More information about
                  this approach can be found at taapinclusion.org. Equilo Dash
                  uses 15 themes that cut across two or more of the TAAP
                  domains, for a total of 32 unique TAAP domain/theme
                  intersections for analysis and scoring. Each gender equality
                  score is based on a specific set of indicators within a given
                  intersection.
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env.js";
import item from "@/components/text.vue";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
//import methodology from "@/components/methodology";

export default {
  name: "what-works",
  data: function () {
    return {
      country: {},
      whatWorks: {},
      print: false,
      panel: false,
      openPanels: [],

      themes: ["All Themes"],
      impact_levels: [
        "All Impact Levels",
        "Minimum Compliance",
        "Empowerment",
        "Transformation",
      ],
      actors: [
        "All Actors",
        "Development",
        "Financial",
        "Private sector",
        "Humanitarian",
      ],
      subactors: [],

      selectedTheme: "",
      selectedImpact: "",
      selectedActor: "",
      selectedSubActor: "",

      filteredTips: {},

      impactInfo: false,
      actorInfo: false,
      minComplianceInfo: false,
      empowermentInfo: false,
      transformationInfo: false,
      impactHoverOffsetY: "1000px",
      impactHoverOffsetX: "10px",

      env,
      taabColors: {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
      },
      appearItems: [],
      sector: { name: "" },
      countryData: [],
    };
  },
  components: {
    item,
    pageLoader,
    //methodology,
  },
  methods: {
    compareRank(a, b) {
      if (a.rank < b.rank) {
        return -1;
      }
      if (a.rank > b.rank) {
        return 1;
      }
      return 0;
    },
    impactMouseover(event) {
      this.impactHoverOffsetY = event.pageY - 20 + "px";
      this.impactHoverOffsetX = event.pageX + 20 + "px";

      if (event.srcElement.alt == "Minimum Compliance") {
        this.minComplianceInfo = true;
      } else if (event.srcElement.alt == "Empowerment") {
        this.empowermentInfo = true;
      } else if (event.srcElement.alt == "Transformation") {
        this.transformationInfo = true;
      }
    },
    // We go for an array so we can have multiple panels open at the same time (eg. download PDF)
    togglePanel: function (panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
    toFixed: function (n, x) {
      if (n == "Missing") {
        return "Missing";
      } else {
        return n.toFixed(x || 2);
      }
    },
    getPercentage(number) {
      return Math.round((number * 100) / 2);
    },
    value: function (input) {
      return this.format("{{" + input + "}}");
    },
    opacity: function (hex) {
      if (!hex) {
        return "#black";
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    getCountry: function () {
      const _self = this;
      const isoCodes = this.$route.params.iso2.split(",");
      return new Promise(function (resolve) {
        let iso = { isoCodes };
        axios
          .post(
            env.apiUrl +
            "/countries" +
            // _self.$route.params.iso2 +
            "/sectors/" +
            _self.$route.params.sector,
            iso,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getWhatWorks: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
            "/whatWorks/sectors/" +
            _self.$route.params.sector +
            "/countries/" +
            _self.$route.params.iso2.split(",")[0],
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
    scrollTo(id) {
      //document.getElementById(id).scrollIntoView();

      const element = document.getElementById(id);
      const offset = -100;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    toTitle(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    show(el) {
      if (this.appearItems.indexOf(el.id) < 0) {
        this.appearItems.push(el.id);
      }
    },
    getSector: function () {
      const _self = this;
      return new Promise(function (resolve) {
        // Validate if you want general country information (count/base) or a specific sector
        if (["BASE", "country"].indexOf(_self.$route.params.sector) > -1) {
          resolve(false);
        } else {
          axios
            .get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso : _self.$route.params.iso2 }
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        }
      });
    },
    selectTheme(value) {
      this.selectedTheme = value;

      if (this.selectedTheme.includes("All")) {
        this.filteredTips = this.whatWorks;
      } else {
        //this.filteredTips = this.whatWorks.filter(a => (a.theme));
        this.filteredTips = this.whatWorks.filter(
          (a) => a.theme.indexOf(this.selectedTheme) > -1
        );
      }

      if (
        !(this.selectedImpact === "") & !this.selectedImpact.includes("All")
      ) {
        this.filteredTips = this.filteredTips.filter((a) => a.compliance);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.compliance.includes(this.selectedImpact)
        );
      }
      if (!(this.selectedActor === "") & !this.selectedActor.includes("All")) {
        this.filteredTips = this.filteredTips.filter((a) => a.actor);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.actor.includes(this.selectedActor)
        );
      }
      if (
        !(this.selectedSubActor === "") & !this.selectedSubActor.includes("All")
      ) {
        this.filteredTips = this.filteredTips.filter((a) => a.sub_actor);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.sub_actor.includes(this.selectedSubActor)
        );
      }
    },
    selectImpact(value) {
      this.selectedImpact = value;
      if (this.selectedImpact.includes("All")) {
        this.filteredTips = this.whatWorks;
      } else {
        this.filteredTips = this.whatWorks.filter((a) => a.compliance);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.compliance.includes(this.selectedImpact)
        );
      }

      if (!(this.selectedTheme === "") & !this.selectedTheme.includes("All")) {
        this.filteredTips = this.filteredTips.filter((a) => a.theme);
        this.filteredTips = this.filteredTips.filter(
          (a) => a.theme.indexOf(this.selectedTheme) > -1
        );
      }
      if (!(this.selectedActor === "") & !this.selectedActor.includes("All")) {
        this.filteredTips = this.filteredTips.filter((a) => a.actor);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.actor.includes(this.selectedActor)
        );
      }
      if (
        !(this.selectedSubActor === "") & !this.selectedSubActor.includes("All")
      ) {
        this.filteredTips = this.filteredTips.filter((a) => a.sub_actor);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.sub_actor.includes(this.selectedSubActor)
        );
      }
    },
    selectActor(value) {
      this.selectedActor = value;
      if (this.selectedActor == "Development") {
        this.subactors = [
          "All Sub-Actors",
          "Aid Agencies",
          "Implementing Partners & NGOs",
          "National Governments",
          "CBOs, Networks, & Associations",
        ];
      } else if (this.selectedActor == "Financial") {
        this.subactors = [
          "All Sub-Actors",
          "DFIs & MFIs",
          "Funds",
          "Financial Institutions",
          "Private and Social Impact Investors",
          "National Governments",
          "Companies",
        ];
      } else {
        this.subactors = [];
      }

      this.selectedSubActor = "";
      if (this.selectedActor.includes("All")) {
        this.filteredTips = this.whatWorks;
      } else {
        this.filteredTips = this.whatWorks.filter((a) => a.actor);
        this.filteredTips = this.filteredTips.filter(
          (a) => a.actor.indexOf(this.selectedActor) > -1
        );
      }

      if (!(this.selectedTheme === "") & !this.selectedTheme.includes("All")) {
        this.filteredTips = this.filteredTips.filter((a) => a.theme);
        this.filteredTips = this.filteredTips.filter(
          (a) => a.theme.indexOf(this.selectedTheme) > -1
        );
      }
      if (
        !(this.selectedImpact === "") & !this.selectedImpact.includes("All")
      ) {
        this.filteredTips = this.filteredTips.filter((a) => a.compliance);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.compliance.includes(this.selectedImpact)
        );
      }
    },
    selectSubActor(value) {
      this.selectedSubActor = value;
      if (this.selectedSubActor.includes("All")) {
        this.filteredTips = this.whatWorks;
      } else {
        this.filteredTips = this.whatWorks.filter((a) => a.sub_actor);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.sub_actor.includes(this.selectedSubActor)
        );
      }

      if (!(this.selectedTheme === "") & !this.selectedTheme.includes("All")) {
        this.filteredTips = this.filteredTips.filter((a) => a.theme);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.theme.includes(this.selectedTheme)
        );
      }
      if (
        !(this.selectedImpact === "") & !this.selectedImpact.includes("All")
      ) {
        this.filteredTips = this.filteredTips.filter((a) => a.compliance);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.compliance.includes(this.selectedImpact)
        );
      }
      if (!(this.selectedActor === "") & !this.selectedActor.includes("All")) {
        this.filteredTips = this.filteredTips.filter((a) => a.actor);
        this.filteredTips = this.filteredTips.filter((a) =>
          a.actor.includes(this.selectedActor)
        );
      }
    },
  },
  computed: {
    citations() {
      const compare = (a, b) => {
        if (a.rank < b.rank) {
          return -1;
        }
        if (a.rank > b.rank) {
          return 1;
        }
        return 0;
      };
      const x = this.$store.state.citations;
      return x.sort(compare);
    },
  },
  async created() {
    this.$store.commit("resetCitations");

    this.countryData = await this.getCountry();
    this.country = this.countryData.countries[0]; // lets do an await before rendering other items.
    //this.theme = await this.getTheme(); // lets do an await before rendering other items.
    this.sector = await this.getSector(); // lets do an await before rendering other items.
    this.originalWhatWorks = await this.getWhatWorks(); // lets do an await before rendering other items.

    // WHAT WORKS FILTERING
    this.whatWorks = this.originalWhatWorks.data;
    this.whatWorks.sort(this.compareRank);

    this.filteredTips = this.originalWhatWorks.data;
    this.filteredTips.sort(this.compareRank);
    // validate if you have access to this country.
    this.user = await auth.me().catch(() => { });
    if (this.$route.params.iso2 != 'EQ' &&
      !this.user.organization.accessGesi ||
      document.location.href.indexOf("print=") > -1
    ) {
      this.$router.push({path: '/pricing/'+env.productFamilyIds.gesi_aid });
    }

    for (var i = 0; i < this.whatWorks.length; i++) {
      if (this.themes.indexOf(this.whatWorks[i].theme) > -1) {
        continue;
      } else {
        this.themes.push(this.whatWorks[i].theme);
      }
    }
    this.themes = this.originalWhatWorks.themesOptions;

    if (document.location.href.indexOf("print=true") > -1) {
      this.print = true;
    }
    this.$store.commit("downloadable", true);

    this.$store.commit("setBreadcrumbs", [
      {
        name: "Countries",
        url: "/",
      },
      {
        name:
          this.$route.params.iso2.split(",").length == 1
            ? this.country.humanName || this.country.name
            : "Multiple Countries",
        url: "/gesi-analysis/" + this.$route.params.iso2 + "/choose-sector",
      },
      {
        name: (this.sector || { name: "country" }).name,
        url:
          "/gesi-analysis/" +
          this.$route.params.iso2 +
          "/" +
          this.$route.params.sector +
          "/snapshot",
      },

      {
        name: "What Works",
        url:
          "/reports/" +
          this.$route.params.iso2 +
          "/" +
          this.$route.params.sector +
          "/what-works",
      },
    ]);
    let allCountry = [];
    this.countryData.countries.forEach(data => {
      allCountry.push(data.humanName);
    })
    this.$gtag.event("sectors", {
      'domain_name': "",
      'theme_name': "",
      'page_name': this.$route.name,
      'userId': this.user._id,
      "sector_name": this.sector.name,
      "country_name": allCountry,
      "team_id": this.user.organization._id,
      "organizationName": this.user.organization.name,
    })
    this.$gtag.event("countries", {
      'domain_name': "",
      'theme_name': "",
      'page_name': this.$route.name,
      'userId': this.user._id,
      "sector_name": this.sector.name,
      "country_name": allCountry,
      "team_id": this.user.organization._id,
      "organizationName": this.user.organization.name,
    })
  },
  mounted() { },
};
import $ from "jquery";

$(window)
  .scroll(function () {
    var scrollDistance = $(window).scrollTop();
    // Show/hide menu on scroll
    //if (scrollDistance >= 850) {
    //		$('nav').fadeIn("fast");
    //} else {
    //		$('nav').fadeOut("fast");
    //}

    // Assign active class to nav links while scolling
    $(".maincontent").each(function (i) {
      if ($(this).position().top <= scrollDistance) {
        var lis = document
          .getElementsByClassName("navsticky")[0]
          .getElementsByTagName("ol")[0]
          .getElementsByTagName("li");
        for (
          let i = 0;
          i <
          document.getElementsByTagName("ol")[0].getElementsByTagName("li")
            .length;
          i++
        ) {
          lis[i].classList.remove("active");
        }
        if (i == 0) {
          if (scrollDistance > 600) {
            $(".navsticky ol li")
              .eq(i + 1)
              .addClass("active");
          } else {
            $(".navsticky ol li").eq(i).addClass("active");
          }
        } else {
          $(".navsticky ol li")
            .eq(i + 1)
            .addClass("active");
        }
      }
    });
  })
  .scroll();
</script>
<style>
.what_works_side_bar_list li {
  margin-left: 1rem !important;
}
.fade-enter-active .fade-leave-active {
  transition: all 1s ease;
}

.fade-enter .fade-leave-to

/* .list-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}

@media print {
  .radarchart {
    max-height: 400px !important;
  }
}

#buttonContainer {
  text-align: center;
}

#sideNav {
  position: fixed;
  margin-top: 300px;
  left: -65px;
  border-radius: 0 5px 5px 0;
}

#sideNav button {
  background-color: #201747;
  padding: 8px;
  width: 120px;
  height: 38px;
  font-size: 10px;
  color: #201747;
}

#sideNav button:hover {
  background-color: #e2e8f0;
  color: #201747;
}

.navigateButton {
  color: red;
  border: 2px solid red;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.hoverTrigger .hoverMethodology {
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 50;
  width: 400px;
}

.hoverTrigger {
  position: relative;
  display: inline-block;
}

.triggerElement {
  border-bottom: 1px dotted black;
}

.hoverTrigger:hover .hoverMethodology {
  visibility: visible;
  width: 550px;
}

.margintop1 {
  margin-top: 1em;
}

.margintop2 {
  margin-top: 2em;
}

.margintop3 {
  margin-top: 3em;
}

.marginbottom3 {
  margin-bottom: 3em;
}

#impact-hover {
  position: absolute;
  width: 20%;
}

/* Make impact key sticky */
#impact-key {
  position: -webkit-sticky;
  position: sticky !important;
  top: 29em;
  width: 240px;
  color: #201747;
  margin-left: -265px;
  float: left;
}

.custom-w-1 {
  width: 1rem;
}
</style>
