<script>
const _ = require("underscore");
import { Radar } from "vue-chartjs";
import env from "@/../env";
export default {
  extends: Radar,
  data: function () {
    return {
      orgLevelDataForCompanies: [],
      targetData: [],
      targetDataYear: [],
      country: {},
      axes: [],
      fullAxes: [],
      axeColors: [],
      max: [],
      one: [],
      hoverIndex: false,
      taabs: [],
      bonusValues: [],
      thresholdValues: [],
      detailsData: [],
      roiGesiScore: [],
      roiGesiScoreData: [],
      roiBenchmarkScores: [],
      roiBenchmarkScoresData: [],
      fontSize: 15,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 1024 && this.window.height < 768) {
        this.fontSize = 11;
      }
    },
    formatLabel(str, maxwidth) {
      var sections = [];
      var words = str.split(" ");
      var temp = "";
      words.forEach(function (item, index) {
        if (temp.length > 0) {
          var concat = temp + " " + item;

          if (concat.length > maxwidth) {
            sections.push(temp);
            temp = "";
          } else {
            if (index == words.length - 1) {
              sections.push(concat);
              return;
            } else {
              temp = concat;
              return;
            }
          }
        }

        if (index == words.length - 1) {
          sections.push(item);
          return;
        }

        if (item.length < maxwidth) {
          temp = item;
        } else {
          sections.push(item);
        }
      });

      return sections;
    },
    opacity: function (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    toTitleCase: function (phrase) {
      return (phrase || "")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    calc: function (input) {
      if (typeof input !== undefined && input !== "Missing" && !isNaN(input)) {
        return (Number(input) || 0).toFixed(3);
      } else {
        return null;
      }
    },
    abrev: function (input) {
      let abrevString = "";
      for (let i = 0; i < input.toString().split(" ").length; i++) {
        if (input.toString().split(" ")[i] !== "&") {
          abrevString += input.toString().split(" ")[i].substr(0, 1);
        }
      }
      return abrevString;
    },
    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
  },
  props: [
    "paddingL",
    "paddingR",
    "paddingT",
    "paddingB",
    "details",
    "gesiScore",
    "financialYear",
    "gesiLabel",
    "organizationType",
    "roiData",
  ],
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    var filterData = [];
    var filterDetails = [];
    let colors = ["#2BB900", "#00A269", "#0068CD"];
    let taab = ["Opportunities", "Inclusion", "Resilience"];

    if (this.organizationType == "Fund") {
      this.details.Resilience = this.details.Resilience.filter(
        (obj) => obj.chartTitle !== "Senior Managers"
      );
      this.details.Resilience = this.details.Resilience.filter(
        (obj) => obj.chartTitle !== "Middle Managers"
      );
    } else {
      this.details.Resilience = this.details.Resilience.filter(
        (obj) => obj.chartTitle !== "Managing Partners"
      );
      this.details.Resilience = this.details.Resilience.filter(
        (obj) => obj.chartTitle !== "Partners"
      );
    }

    this.detailsData.splice(0, 0, this.details.Opportunities);
    this.detailsData.splice(1, 0, this.details.Inclusion);
    this.detailsData.splice(2, 0, this.details.Resilience);
    
    if (this.organizationType == "Fund") {
      this.gesiScore.Resilience = this.gesiScore.Resilience.filter(
        (obj) => obj.chartTitle !== "Senior Managers"
      );
      this.gesiScore.Resilience = this.gesiScore.Resilience.filter(
        (obj) => obj.chartTitle !== "Middle Managers"
      );
    } else {
      this.gesiScore.Resilience = this.gesiScore.Resilience.filter(
        (obj) => obj.chartTitle !== "Managing Partners"
      );
      this.gesiScore.Resilience = this.gesiScore.Resilience.filter(
        (obj) => obj.chartTitle !== "Partners"
      );
    }

    const roiDetailsData = [];
    roiDetailsData.splice(0, 0, this.roiData.Opportunities);
    roiDetailsData.splice(1, 0, this.roiData.Inclusion);
    roiDetailsData.splice(2, 0, this.roiData.Resilience);

    roiDetailsData.forEach((data) => {
      data.forEach((item) => {
        let sum = null;
        if (item.bonusScoreAggregated !== "") {
          sum = +item.bonusScoreAggregated;
        }

        if (item.thresholdScoreAggregated !== "") {
          if (sum) {
            sum += +item.thresholdScoreAggregated;
          } else {
            sum = +item.thresholdScoreAggregated;
          }
        }

        this.roiBenchmarkScores.push(sum);

        const benchmark = {}

        if (item.bonusScoreAggregated || item.thresholdScoreAggregated || sum === 0) {
          benchmark.score = sum.toFixed(2);
        }

        if (item.bonusScoreAggregated === "" && item.thresholdScoreAggregated === "") {
          if (item.showNotApplicantFlag) {
            benchmark.score = "Unknown";
          } else {
            benchmark.score = "Not Applicable";
          }
        }

        if (item.bonusScoreAggregated === "") {
          if (item.showNotApplicantFlag) {
            benchmark.bonus = "Unknown";
          } else {
            benchmark.bonus = "Not Applicable";
          }
        } else {
          benchmark.bonus = Number(item.bonusScoreAggregated).toFixed(2);
        }

        if (item.thresholdScoreAggregated === "") {
          if (item.showNotApplicantFlag) {
            benchmark.threshold = "Unknown";
          } else {
            benchmark.threshold = "Not Applicable";
          }
        } else {
          benchmark.threshold = Number(item.thresholdScoreAggregated).toFixed(2);
        }

        this.roiBenchmarkScoresData.push(benchmark);
      });
    });

    this.roiGesiScore.splice(0, 0, this.gesiScore.Opportunities);
    this.roiGesiScore.splice(1, 0, this.gesiScore.Inclusion);
    this.roiGesiScore.splice(2, 0, this.gesiScore.Resilience);

    this.detailsData.forEach((data, d) => {
      filterData = [];
      data.forEach((item) => {
        filterData.push(item);
        this.axeColors.push(colors[d]);
        this.taabs.push(taab[d]);
      });
      filterDetails.push(filterData);
    });

    this.roiGesiScore.forEach((data) => {
      data.forEach((item) => {
        this.roiGesiScoreData.push(item.gesiScore);
      });
    });

    filterDetails.forEach((data) => {
      data.forEach((item) => {
        this.axes.push(item.chartTitle);
        let sum = +item.bonusScore + +item.thresholdScore;
        if (item.bonusScore == "0" && item.thresholdScore == "0") {
          this.orgLevelDataForCompanies.push("0");
        }
        if (item.bonusScore || item.thresholdScore) {
          this.orgLevelDataForCompanies.push(sum.toFixed(2));
        }
        if (item.bonusScore == "" && item.thresholdScore == "") {
          if (item.showNotApplicantFlag) {
            this.orgLevelDataForCompanies.push("Unknown");
          } else {
            this.orgLevelDataForCompanies.push("Not Applicable");
          }
        }
        if (item.bonusScore == "") {
          if (item.showNotApplicantFlag) {
            this.bonusValues.push("Unknown");
          } else {
            this.bonusValues.push("Not Applicable");
          }
        } else {
          this.bonusValues.push(item.bonusScore);
        }
        if (item.thresholdScore == "") {
          if (item.showNotApplicantFlag) {
            this.thresholdValues.push("Unknown");
          } else {
            this.thresholdValues.push("Not Applicable");
          }
        } else {
          this.thresholdValues.push(item.thresholdScore);
        }
        this.max.push(6);
        this.one.push(5);
      });
    });
    this.arraymove(this.axes, 15, 12);
    this.arraymove(this.orgLevelDataForCompanies, 15, 12);
    this.arraymove(this.bonusValues, 15, 12);
    this.arraymove(this.thresholdValues, 15, 12);
    this.arraymove(this.roiGesiScoreData, 15, 12);
    this.roiGesiScoreData.map((data, d) => {
      if (data == "") {
        this.roiGesiScoreData[d] = null;
      }
    });
    const _self = this;

    this.renderChart(
      {
        labels: this.axes,
        datasets: [
          {
            data: this.orgLevelDataForCompanies,
            label: this.financialYear,
            borderColor: "#fe5000",
            pointBackgroundColor: "#fe5000",
            fill: false,
            backgroundColor: this.opacity("#fe5000"),
            pointHitRadius: 1,
          },
          {
            data: this.roiGesiScoreData,
            label: this.gesiLabel,
            borderColor: "#2BD4C4",
            pointBackgroundColor: "#2BD4C4",
            fill: false,
            backgroundColor: this.opacity("#2BD4C4"),
            pointHitRadius: 1,
          },
          {
            data: this.roiBenchmarkScores,
            label: "Gender ROI™ Benchmark",
            borderColor: "#B2281E",
            pointBackgroundColor: "#B2281E",
            fill: false,
            backgroundColor: this.opacity("#B2281E"),
            pointHitRadius: 1,
          },
          {
            data: this.max,
            fill: false,
            label: "hide",
            pointBackgroundColor: this.axeColors,
            pointBorderColor: this.axeColors,
            pointHitRadius: 1,
          },
          {
            data: this.one,
            label: "hide",
            fill: false,
            pointBackgroundColor: "#9D9D9C",
            pointRadius: 0,
            backgroundColor: "#9D9D9C",
            borderColor: "#9D9D9C",
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        spanGaps: false,
        layout: {
          padding: {
            left: this.paddingL,
            right: this.paddingR,
            top: this.paddingT,
            bottom: this.paddingB,
          },
        },

        legend: {
          position: "top",
          labels: {
            filter: function (item) {
              // Logic to remove a particular legend item goes here
              if (item && item.text) {
                return !item.text.includes("hide");
              }
            },
            fontSize: 14,
            fontFamily: "basier",
            pointStyleWidth: 1,
            usePointStyle: true,
            pointStyle: "circle",
          },
          onHover: function (e, legendItem) {
            // Function to only hide Perfect geneder equality
            if (legendItem.text.indexOf("Perfect") == -1) {
              e.target.style.cursor = "pointer";
              if (legendItem.text.includes("GESI Contextual Score")) {
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-info").style.width =
                  "300px";
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY - 50 + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX - 50 + "px";
                document.getElementById("intersection-name").innerText =
                  "This score represents the Contextual Gender Analysis Score specific to this country, which is calculated for each dimension of Gender ROI™ displayed in the chart below. The score is calculated on a scale of 0 to 5, with 0 indicating extreme inequality and 5 denoting perfect equality.\n\n Equilo utilizes its proprietary algorithms and publicly accessible information to determine this score.";
                document.getElementById(
                  "intersection-name"
                ).style.textTransform = "none";
                document.getElementById("intersection-name").style.display =
                  "block";
                document.getElementById(
                  "div-intersection-country"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-region"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-income"
                ).style.display = "none";
                document.getElementById("lmic-avg").style.display = "none";
                // document.getElementById("intersection-lmic-value").style.display = "none";
                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
                document.getElementById("intersection-domain").style.display =
                  "none";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-country-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-region-value"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-four"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-five"
                ).style.display = "none";
                document.getElementById(
                  "intersection-income-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "none";
              }
              else if (legendItem.text.includes("Gender ROI™ Benchmark")) {
                document.getElementById("intersection-info").style.display =
                  "block";
                document.getElementById("intersection-info").style.width =
                  "300px";
                document.getElementById("intersection-info").position = "fixed";
                document.getElementById("intersection-info").style.zIndex =
                  "99999";
                document.getElementById("intersection-info").style.top =
                  e.pageY - 50 + "px";
                document.getElementById("intersection-info").style.left =
                  e.pageX - 50 + "px";
                document.getElementById("intersection-name").innerText =
                  "This line represents the average score of all users who responded to the Gender ROI™ Assessment. This benchmark allows your entity to compare its performance against the collective results of all ROI assessments.";
                document.getElementById(
                  "intersection-name"
                ).style.textTransform = "none";
                document.getElementById("intersection-name").style.display =
                  "block";
                document.getElementById(
                  "div-intersection-country"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-region"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-income"
                ).style.display = "none";
                document.getElementById("lmic-avg").style.display = "none";
                // document.getElementById("intersection-lmic-value").style.display = "none";
                document.getElementById(
                  "div-intersection-validity-power"
                ).style.display = "none";
                document.getElementById("intersection-domain").style.display =
                  "none";
                document.getElementById(
                  "intersection-domain-link"
                ).style.display = "none";
                document.getElementById(
                  "intersection-country-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-region-value"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-four"
                ).style.display = "none";
                document.getElementById(
                  "div-intersection-country-five"
                ).style.display = "none";
                document.getElementById(
                  "intersection-income-value"
                ).style.display = "none";
                document.getElementById(
                  "intersection-theme-link"
                ).style.display = "none";
                document.getElementById("div-intersection-country-six").style.display = "none";
                document.getElementById("div-intersection-country-seven").style.display = "none";
                document.getElementById("div-intersection-country-eight").style.display = "none";
              }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
              e.target.style.cursor = "default";
            }
          },

          // Function to only hide Perfect geneder equality
          onClick: function (e, legendItem) {
            if (legendItem.text.indexOf("Perfect") > -1) {
              return false;
            }

            // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
            // modified to prevent hiding all legend items

            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);

            // See controller.isDatasetVisible comment
            var hidden =
              meta.hidden === null ? !ci.data.datasets[index].hidden : null;

            if (hidden) {
              var someShown = _.some(this.legendItems, function (item) {
                return item !== legendItem && !item.hidden;
              });

              if (!someShown) return;
            }

            meta.hidden = hidden;

            // We hid a dataset ... rerender the chart
            ci.update();
          },
        },

        scale: {
          angleLines: {
            display: true,
            color: "rgb(243, 243, 243)",
            lineWidth: 2,
          },
          gridLines: {
            display: true,
            color: "rgb(243, 243, 243)",
            lineWidth: 2,
          },
          pointLabels: {
            fontColor: this.axeColors,
            fontSize: this.fontSize,
            fontFamily: "basier",
            lineHeight: 1,
            onClick: function () {},
            callback: function (pointLabel, index) {
              if (
                _self.$route.params.sector == "WASH_UTILIT" ||
                _self.$route.params.sector == "INFR_TRANSP" ||
                _self.$route.params.sector == "ENRG_SMTECH" ||
                _self.$route.params.sector == "FNCE_GENERL" ||
                _self.$route.params.sector == "INFR_DETENT" ||
                _self.$route.params.sector == "INFR_EDUCAT" ||
                _self.$route.params.sector == "INFR_HEALTH" ||
                _self.$route.params.sector == "INFR_MARKET"
              ) {
                if (index == 20 || index == 21) {
                  var words = pointLabel.split(" ");
                  var testLine = [];
                  testLine.push(" ");
                  for (var n = 0; n < words.length; n++) {
                    testLine.push(words[n]);
                  }
                  testLine.push(" ");
                  return testLine;
                } else {
                  return pointLabel;
                }
              } else if (
                _self.$route.params.sector == "CVID_GENERL" ||
                _self.$route.params.sector == "FNCE_MCRINF"
              ) {
                if (
                  index == 21 ||
                  index == 42 ||
                  index == 1 ||
                  index == 0 ||
                  index == 22
                ) {
                  var words2 = pointLabel.split(" ");
                  var testLine2 = [];
                  testLine2.push(" ");
                  for (var n2 = 0; n2 < words2.length; n2++) {
                    testLine2.push(words2[n2]);
                  }
                  testLine2.push(" ");
                  return testLine2;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_MATNEW") {
                if (
                  index == 1 ||
                  index == 21 ||
                  index == 22 ||
                  index == 43 ||
                  index == 23
                ) {
                  var words3 = pointLabel.split(" ");
                  var testLine3 = [];
                  testLine3.push(" ");
                  for (var n3 = 0; n3 < words3.length; n3++) {
                    testLine3.push(words3[n3]);
                  }
                  testLine3.push(" ");
                  return testLine3;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "INFR_GENERL") {
                if (
                  index == 0 ||
                  index == 1 ||
                  index == 20 ||
                  index == 21 ||
                  index == 40
                ) {
                  var words4 = pointLabel.split(" ");
                  var testLine4 = [];
                  testLine4.push(" ");
                  for (var n4 = 0; n4 < words4.length; n4++) {
                    testLine4.push(words4[n4]);
                  }
                  testLine4.push(" ");
                  return testLine4;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "ENRG_GOVPOL") {
                if (index == 1 || index == 21) {
                  var words5 = pointLabel.split(" ");
                  var testLine5 = [];
                  testLine5.push(" ");
                  for (var n5 = 0; n5 < words5.length; n5++) {
                    testLine5.push(words5[n5]);
                  }
                  return testLine5;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_HIVHLT") {
                if (index == 19) {
                  var words6 = pointLabel.split(" ");
                  var testLine6 = [];
                  testLine6.push("");
                  for (var n6 = 0; n6 < words6.length; n6++) {
                    testLine6.push(words6[n6]);
                  }
                  return testLine6;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "HLTH_SEXREP") {
                if (index == 20 || index == 21 || index == 0 || index == 1) {
                  var words7 = pointLabel.split(" ");
                  var testLine7 = [];
                  testLine7.push(" ");
                  for (var n7 = 0; n7 < words7.length; n7++) {
                    testLine7.push(words7[n7]);
                  }
                  return testLine7;
                } else {
                  return pointLabel;
                }
              } else if (
                _self.$route.params.sector == "AGRI_COCOA" ||
                _self.$route.params.sector == "AGRI_COFFEE"
              ) {
                if (
                  index == 21 ||
                  index == 22 ||
                  index == 23 ||
                  index == 1 ||
                  index == 0
                ) {
                  var words8 = pointLabel.split(" ");
                  var testLine8 = [];

                  for (var n8 = 0; n8 < words8.length; n8++) {
                    testLine8.push(words8[n8]);
                  }
                  //                            testLine8.push(" ");
                  return testLine8;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_MAIZE") {
                if (
                  index == 0 ||
                  index == 1 ||
                  index == 21 ||
                  index == 42 ||
                  index == 43
                ) {
                  var words9 = pointLabel.split(" ");
                  var testLine9 = [];
                  testLine9.push(" ");
                  for (var n9 = 0; n9 < words9.length; n9++) {
                    testLine9.push(words9[n9]);
                  }

                  return testLine9;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_LEGUME") {
                if (index == 22 || index == 23 || index == 1 || index == 0) {
                  var words10 = pointLabel.split(" ");
                  var testLine10 = [];
                  testLine10.push(" ");
                  for (var n10 = 0; n10 < words10.length; n10++) {
                    testLine10.push(words10[n10]);
                  }
                  testLine10.push(" ");
                  return testLine10;
                } else {
                  return pointLabel;
                }
              } else if (_self.$route.params.sector == "AGRI_GENERL") {
                if (index == 21) {
                  var words11 = pointLabel.split(" ");
                  var testLine11 = [];
                  testLine11.push(" ");
                  for (var n11 = 0; n11 < words11.length; n11++) {
                    testLine11.push(words11[n11]);
                  }

                  return testLine11;
                } else {
                  return pointLabel;
                }
              } else {
                var sec = ["HLTH_ADOLES", "HLTH_MATNEW"];
                if (sec.includes(_self.$route.params.sector)) {
                  if (index == 22 || index == 41 || index == 1) {
                    var words1 = pointLabel.split(" ");

                    var testLine1 = [];
                    testLine1.push(" ");
                    for (var n1 = 0; n1 < words1.length; n1++) {
                      testLine1.push(words1[n1]);
                    }
                    return testLine1;
                  } else {
                    return pointLabel;
                  }
                } else {
                  let axesLength = _self.axes.length;
                  if (
                    index == (axesLength / 2).toFixed(0) ||
                    index == 0 ||
                    index == 1 ||
                    index == (axesLength / 2).toFixed(0) - 1 ||
                    index == (axesLength / 2).toFixed(0) + 1 ||
                    index == axesLength - 1
                  ) {
                    var wordsRoi = pointLabel.split(" ");
                    var testLineRoi = [];
                    testLineRoi.push(" ");
                    for (var nRoi = 0; nRoi < wordsRoi.length; nRoi++) {
                      testLineRoi.push(wordsRoi[nRoi]);
                    }
                    return testLineRoi;
                  } else {
                    return pointLabel;
                  }
                }
              }
              // Return string here. String must be defined
            },
          },
          ticks: {
            beginAtZero: true,
            max: 6,
            min: 0,
            stepSize: 1,
          },
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          onHover: function (e) {
            // return false;
            var point = this.getElementAtEvent(e);
            if (point && point.length > 0 && point[0]._datasetIndex < 4) {
              e.target.style.cursor = "pointer";
              // Prevent from flashing.
                if (
                  point[0]._index !==
                  _self.hoverIndex + "_" + point[0]._datasetIndex
                ) {
                  document.getElementById("intersection-info").style.width =
                    "14rem";
                  _self.hoverIndex =
                    point[0]._index + "_" + point[0]._datasetIndex;
                  document.getElementById("intersection-info").position =
                    "fixed";
                  document.getElementById("intersection-info").style.zIndex =
                    "99999";
                  document.getElementById("intersection-info").style.top =
                    e.pageY + "px";
                  document.getElementById("intersection-info").style.left =
                    e.pageX + "px";
                  document.getElementById("intersection-info").style.display =
                    "block";
                  document.getElementById("intersection-name").style.display =
                    "block";
                  document.getElementById("intersection-name").innerText =
                    _self.axes[point[0]._index].toString().replace(",", " ");
                  document.getElementById("intersection-domain").style.display =
                    "block";
                  document.getElementById(
                    "intersection-domain-link"
                  ).style.display = "block";
                  document.getElementById("intersection-domain").innerText =
                    _self.taabs[point[0]._index];
                  document.getElementById("intersection-domain").style.display =
                    "block";
                  document.getElementById(
                    "intersection-domain"
                  ).style.backgroundColor = _self.axeColors[point[0]._index];
                  document.getElementById("div-intersection-country").style =
                    "color: " + "#fe5000";
                  document.getElementById("div-intersection-region").style =
                    "color: " + "#E4C624";
                  document.getElementById("lmic-avg").style.display = "none";
                  document.getElementById(
                    "intersection-lmic-value"
                  ).style.display = "none";
                  document.getElementById(
                    "div-intersection-validity-power"
                  ).style.display = "block";
                  document.getElementById(
                    "div-intersection-validity-power"
                  ).style = "color: " + "#2BD4C4";
                  document.getElementById("intersection-country").innerText =
                    "Total: " +
                    (_self._data.orgLevelDataForCompanies[point[0]._index] ==
                      "Unknown" ||
                    _self._data.orgLevelDataForCompanies[point[0]._index] ==
                      "Not Applicable"
                      ? _self._data.orgLevelDataForCompanies[point[0]._index]
                      : Number(
                          _self._data.orgLevelDataForCompanies[point[0]._index]
                        ).toFixed(2));
                  document.getElementById("intersection-region").innerText =
                    "Bonus: " +
                    (_self.bonusValues[point[0]._index] == "Unknown" ||
                    _self.bonusValues[point[0]._index] == "Not Applicable"
                      ? _self.bonusValues[point[0]._index]
                      : Number(_self.bonusValues[point[0]._index]).toFixed(2));
                  document.getElementById(
                    "div-intersection-validity-power"
                  ).innerText =
                    "GESI contextual Score: " +
                    (_self.roiGesiScoreData[point[0]._index] == null
                      ? "Unknown"
                      : Number(_self.roiGesiScoreData[point[0]._index]).toFixed(2));
                  document.getElementById("intersection-income").innerText =
                    "Threshold: " +
                    (_self.thresholdValues[point[0]._index] == "Unknown" ||
                    _self.thresholdValues[point[0]._index] == "Not Applicable"
                      ? _self.thresholdValues[point[0]._index]
                      : Number(_self.thresholdValues[point[0]._index]).toFixed(
                          2
                        ));
                                          
                  document.getElementById("div-intersection-country-six").style.display = "block";
                  document.getElementById("div-intersection-country-seven").style.display = "block";
                  document.getElementById("div-intersection-country-eight").style.display = "block";

                  document.getElementById("div-intersection-country-six").style.color = "#E4C624";
                  document.getElementById("div-intersection-country-seven").style.color = "#201747";
                  document.getElementById("div-intersection-country-eight").style.color = "#B2281E";

                  document.getElementById("div-intersection-country-six").innerText =
                      "Aggregated Bonus: " +
                      _self._data.roiBenchmarkScoresData[point[0]._index].bonus;
                  
                  document.getElementById("div-intersection-country-seven").innerText =
                      "Aggregated Threshold: " +
                      _self._data.roiBenchmarkScoresData[point[0]._index].threshold;

                  document.getElementById("div-intersection-country-eight").innerText =
                      "Aggregated Total: " +
                      _self._data.roiBenchmarkScoresData[point[0]._index].score;

                  document.getElementById(
                    "intersection-domain-link"
                  ).style.display = "none";
                  document.getElementById(
                    "intersection-domain-link"
                  ).style.display = "none";
                  document.getElementById(
                    "intersection-country-value"
                  ).style.display = "none";
                  document.getElementById(
                    "intersection-region-value"
                  ).style.display = "none";
                  document.getElementById(
                    "div-intersection-income"
                  ).style.display = "block";
                  document.getElementById(
                    "div-intersection-country-four"
                  ).style.display = "none";
                  document.getElementById(
                    "div-intersection-country-five"
                  ).style.display = "none";
                  document.getElementById("div-intersection-income").style =
                    "color: " + env.geoColors.countryspider;
                  document.getElementById(
                    "intersection-income-value"
                  ).style.display = "block";
                  document.getElementById(
                    "intersection-theme-link"
                  ).style.display = "none";
                }
            } else {
              document.getElementById("intersection-info").style.display =
                "none";
              e.target.style.cursor = "default";
            }
          },
        },
      }
    );

    const _self2 = this;
    window.addEventListener("beforeprint", function () {
      const Chart = _self2.$data._chart;
      Chart.resize();
    });

    setTimeout(function () {
      _self2.$data._chart.update();
    }, 1000);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.chartjs-render-monitor {
  /* margin-left: -33px; */
  height: 750px !important;
}
</style>
