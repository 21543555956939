<script>
import { Bar } from "vue-chartjs";
import env from "@/../env.js";

export default {
  name: "barChartVertical",
  extends: Bar,
  props: [
    "chartLabels",
    "totalValue",
    "filledValue",
    "label1",
    "label2",
    "mainTitle",
    "type",
  ],
  data: function () {
    return {
      max: 100,
      beginAtZero: true,
      labels: [],
      datasets: [],
      totalValue1: "",
      totalValue2: "",
    };
  },
  mounted() {
    this.renderMyChart();
  },
  methods: {
    renderMyChart() {
      this.totalValue1 = this.totalValue[0];
      this.totalValue2 = this.totalValue[1];
      this.labels = [];
      this.labels = this.chartLabels;
      if (isNaN(this.totalValue1)) {
        let totalValue1 = 100;
        let totalValue2 = 100;
        let filledValue = 0;
        this.datasets = [
          {
            label: this.label1,
            backgroundColor:
              this.type === "leadership" || this.type === "consumption" ? "#2BD4C4" : env.genderColors.male,
            data: [filledValue, filledValue],
          },
          {
            label: this.label2 ? this.label2 : null,
            backgroundColor:
              this.type === "leadership" || this.type === "consumption" ? "#FAE053" : env.genderColors.female,
            data: [totalValue1 - filledValue, totalValue2 - filledValue],
          },
        ];

        // if (this.totalValue1 < this.totalValue2) {
        //   this.max = Math.ceil(
        //     this.totalValue2 + (this.totalValue2 * 20) / 100
        //   );
        // } else {
        //   this.max = Math.ceil(
        //     this.totalValue1 + (this.totalValue1 * 20) / 100
        //   );
        // }
      } else {
        this.datasets = [
          {
            label: this.label1,
            backgroundColor:
              this.type === "leadership" || this.type === "consumption" ? "#2BD4C4" : env.genderColors.male,
            data: [this.filledValue[0], this.filledValue[1]],
          },
          {
            label: this.label2 ? this.label2 : null,
            backgroundColor:
              this.type === "leadership" || this.type === "consumption" ? "#FAE053" : env.genderColors.female,
            data: [
              this.totalValue1 - this.filledValue[0],
              this.totalValue2 - this.filledValue[1],
            ],
          },
        ];
      }
      const _self = this;
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          fontFamily: "basier",
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontSize: 13,
              fontFamily: "basier",
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  max: 100,
                  min: 0,
                  stepSize: 100 / 5,
                  callback: function (value) {
                    var numFormat = new Intl.NumberFormat("en-US");
                    return numFormat.format(value)+'%';
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: this.mainTitle ? this.mainTitle : "",
                  fontStyle: "bold",
                  fontSize: "16",
                  fontFamily: "basier",
                },
                beginAtZero: this.beginAtZero,
                fontFamily: "basier",
                stacked: true,
                fontSize: 12,
              },
            ],
            xAxes: [
              {
                fontSize: 13,
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                fontFamily: "basier",
                stacked: true,
              },
            ],
          },
          tooltips: {
            titleFontFamily: "basier",
            bodyFontFamily: "basier",
            callbacks: {
              title: function (tooltipItems) {
                return (
                  _self.datasets[tooltipItems[0].datasetIndex].label
                );
              },
              label: function (tooltipItem) {
                return Number(tooltipItem.yLabel || 0).toFixed(0) + "%";
              },
            },
          },
        }
      );
    },
  },
  watch: {
    totalValue: function () {
      this.renderMyChart();
    },
  },
};
</script>