<template>
    <div class="theme-box p-8 mt-6">
        <h3 class="text-xl tracking-tighter font-bold">Theme Badges</h3>
        <div class="flex justify-between mt-5">
            <div class="flex">
                <div class="theme-badge flex items-center gap-2"
                    :style="'border-color: ' + (certificationScoreData['2X_2X_Score_Overall_523'] && certificationScoreData['2X_2X_Score_Overall_523'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_523']]?.darkColor : '#ccc') + '; background: ' + (certificationScoreData['2X_2X_Score_Overall_523'] && certificationScoreData['2X_2X_Score_Overall_523'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_523']]?.lightColor : '')">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z"
                            :fill="(certificationScoreData['2X_2X_Score_Overall_523'] && certificationScoreData['2X_2X_Score_Overall_523'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_523']]?.darkColor : '#ccc')" />
                    </svg>
                    <div>
                        <p class="font-bold" v-if="certificationScoreData['2X_2X_Score_Overall_523'] && certificationScoreData['2X_2X_Score_Overall_523'] != 'Failed'"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_523'] && certificationScoreData['2X_2X_Score_Overall_523'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_523']]?.darkColor : '#999')">
                            {{ cert_categories[certificationScoreData['2X_2X_Score_Overall_523']]?.name }}</p>
                        <p class="font-black"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_523'] && certificationScoreData['2X_2X_Score_Overall_523'] != 'Failed' ? '' : '#999')">
                            GBVH</p>
                    </div>
                </div>
                <!-- <div class="theme-badge flex items-center gap-2 ml-6 tooltip" style="border-color: #ccc;">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z"
                            fill="#ccc" />
                    </svg>
                    <div>
                        <p class="font-bold" style="color: #999">Better</p> // should be comment out
                        <p style="color:#999">Climate</p>
                    </div>
                    <span class="climate-tooltiptext">
                        Climate scoring methodology is under 2X Global technical review
                    </span>
                </div> -->
                <div class="theme-badge flex items-center gap-2 ml-6"
                    :style="'border-color: ' + (certificationScoreData['2X_2X_Score_Overall_521'] && certificationScoreData['2X_2X_Score_Overall_521'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_521']]?.darkColor : '#ccc') + '; background: ' + (certificationScoreData['2X_2X_Score_Overall_521'] && certificationScoreData['2X_2X_Score_Overall_521'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_521']]?.lightColor : '')">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z"
                            :fill="(certificationScoreData['2X_2X_Score_Overall_521'] && certificationScoreData['2X_2X_Score_Overall_521'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_521']]?.darkColor : '#ccc')" />
                    </svg>
                    <div>
                        <p class="font-bold" v-if="certificationScoreData['2X_2X_Score_Overall_521'] && certificationScoreData['2X_2X_Score_Overall_521'] != 'Failed'"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_521'] && certificationScoreData['2X_2X_Score_Overall_521'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_521']]?.darkColor : '#999')">
                            {{ cert_categories[certificationScoreData['2X_2X_Score_Overall_521']]?.name }}</p>
                        <p class="font-black"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_521'] && certificationScoreData['2X_2X_Score_Overall_521'] != 'Failed' ? '' : '#999')">
                            Health & well-being</p>
                    </div>
                </div>
                <div class="theme-badge flex items-center gap-2 ml-6"
                    :style="'border-color: ' + (certificationScoreData['2X_2X_Score_Overall_520'] && certificationScoreData['2X_2X_Score_Overall_520'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_520']]?.darkColor : '#ccc') + '; background: ' + (certificationScoreData['2X_2X_Score_Overall_520'] && certificationScoreData['2X_2X_Score_Overall_520'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_520']]?.lightColor : '')">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z"
                            :fill="(certificationScoreData['2X_2X_Score_Overall_520'] && certificationScoreData['2X_2X_Score_Overall_520'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_520']]?.darkColor : '#ccc')" />
                    </svg>
                    <div>
                        <p class="font-bold" v-if="certificationScoreData['2X_2X_Score_Overall_520'] && certificationScoreData['2X_2X_Score_Overall_520'] != 'Failed'"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_520'] && certificationScoreData['2X_2X_Score_Overall_520'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_520']]?.darkColor : '#999')">
                            {{ cert_categories[certificationScoreData['2X_2X_Score_Overall_520']]?.name }}</p>
                        <p class="font-black"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_520'] && certificationScoreData['2X_2X_Score_Overall_520'] != 'Failed' ? '' : '#999')">
                            JEDI</p>
                    </div>
                </div>
                <div class="theme-badge flex items-center gap-2 ml-6"
                    :style="'border-color: ' + (certificationScoreData['2X_2X_Score_Overall_522'] && certificationScoreData['2X_2X_Score_Overall_522'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_522']]?.darkColor : '#ccc') + '; background: ' + (certificationScoreData['2X_2X_Score_Overall_522'] && certificationScoreData['2X_2X_Score_Overall_522'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_522']]?.lightColor : '')">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z"
                            :fill="(certificationScoreData['2X_2X_Score_Overall_522'] && certificationScoreData['2X_2X_Score_Overall_522'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_522']]?.darkColor : '#ccc')" />
                    </svg>
                    <div>
                        <p class="font-bold" v-if="certificationScoreData['2X_2X_Score_Overall_522'] && certificationScoreData['2X_2X_Score_Overall_522'] != 'Failed'"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_522'] && certificationScoreData['2X_2X_Score_Overall_522'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_522']]?.darkColor : '#999')">
                            {{ cert_categories[certificationScoreData['2X_2X_Score_Overall_522']]?.name }}</p>
                        <p class="font-black"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_522'] && certificationScoreData['2X_2X_Score_Overall_522'] != 'Failed' ? '' : '#999')">
                            Care</p>
                    </div>
                </div>
                <div class="theme-badge flex items-center gap-2 ml-6"
                    :style="'border-color: ' + (certificationScoreData['2X_2X_Score_Overall_519'] && certificationScoreData['2X_2X_Score_Overall_519'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_519']]?.darkColor : '#ccc') + '; background: ' + (certificationScoreData['2X_2X_Score_Overall_519'] && certificationScoreData['2X_2X_Score_Overall_519'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_519']]?.lightColor : '')">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z"
                            :fill="(certificationScoreData['2X_2X_Score_Overall_519'] && certificationScoreData['2X_2X_Score_Overall_519'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_519']]?.darkColor : '#ccc')" />
                    </svg>
                    <div>
                        <p class="font-bold" v-if="certificationScoreData['2X_2X_Score_Overall_519'] && certificationScoreData['2X_2X_Score_Overall_519'] != 'Failed'"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_519'] && certificationScoreData['2X_2X_Score_Overall_519'] != 'Failed' ? cert_categories[certificationScoreData['2X_2X_Score_Overall_519']]?.darkColor : '#999')">
                            {{ cert_categories[certificationScoreData['2X_2X_Score_Overall_519']]?.name }}</p>
                        <p class="font-black"
                            :style="'color:' + (certificationScoreData['2X_2X_Score_Overall_519'] && certificationScoreData['2X_2X_Score_Overall_519'] != 'Failed' ? '' : '#999')">
                            Pay</p>
                    </div>
                </div>
            </div>
            <div class="download-right-arrow-button relative cursor-pointer" v-if="displayArrowButton">
                <img src="@/assets/images/2x_certify/download-right-arrow.svg"
                    @click="$router.push({ path: '/2X-Ecosystem/2X-Certification/theme-badge' })" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ThemeBadgesBox",
    props: {
        cert_categories: {
            type: [Array, Object]
        },
        displayArrowButton: {
            type: Boolean,
            default: false
        },
        certificationScoreData: {
            type: [Array, Object]
        },
    }
}
</script>
<style lang="scss" scoped>
.theme-box {
    border: 1px solid #ccc;
    border-radius: 15px;

    .theme-badge {
        border: 2px solid;
        border-radius: 10px;
        padding: 10px;
        height: 100%;
        font-size: 14px;

        .font-black {
            color: #2C2E35;
            font-weight: 400;
        }

        .light-grey {
            color: #ccc;
        }

        .dark-grey {
            color: #999;
        }
    }

    .tooltip {
        position: relative;
    }

    .tooltip .climate-tooltiptext {
        font-family: "Montserrat", arial;
        padding: 20px;
        font-size: medium;
        letter-spacing: normal;
        visibility: hidden;
        background-color: #201747;
        width: 250px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        right: -72px;
        top: 70px;
    }

    .tooltip:hover .climate-tooltiptext {
        visibility: visible;
    }

    .download-right-arrow-button {
        margin-top: 45px;
    }
}</style>
