<template>

  <div class="mt-12">
    <div class="flex flex-col mt-5">
      <div
            v-for="(indicator_data, index) in histData"
            v-bind:key="indicator_data.var_name"
          >
        <!--p class="mt-6 text-sm">
          <i>{{indicator_data.description}}</i>
        </p-->
        <div class="mt-1" v-if="indicator_data.hist_val != 'Unknown' && indicator_data.hist_val != 'Missing'">
          <p class="mb-3" v-if="index == 0">
            {{
              (indicator.unknownHist > 0) & (indicator.unknownHist == indicator.variables.length) ? "" : "Historical Trend"
            }}
          </p>
          <!-- LAWS & POLICIES / BINARY -->
          <div v-if="indicator_data.format == 'BINARY'">
            <p class="flex flex-col" style="display:inline;">
              <img v-if="indicator_data.change_posNeg == 'positive' && indicator_data.hist_goodbad == 'Up_Good_Down_Bad'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-up-good.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'negative' && indicator_data.hist_goodbad == 'Up_Good_Down_Bad'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-down-bad.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'positive' && indicator_data.hist_goodbad == 'Up_Bad_Down_Good'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-up-bad.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'negative' && indicator_data.hist_goodbad == 'Up_Bad_Down_Good'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-down-good.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'positive' && indicator_data.hist_goodbad.includes('Neutral')" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/Neutral_Up.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'negative' && indicator_data.hist_goodbad.includes('Neutral')" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/Neutral_Down.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'same'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-equal.png" alt="" />
              <span v-if="indicator_data.hist_change == 1">
                <span class="text-sm"><i>{{indicator_data.description}}</i> - This country did not have this law or policy in {{indicator_data.hist_year}}, but does have one as of {{indicator_data.current_year}}</span>
              </span>
              <span v-else-if="indicator_data.hist_change == 0">
                <span class="text-sm"><i>{{indicator_data.description}}</i> - This country's laws or policies in this category have not changed between {{indicator_data.hist_year}} and {{indicator_data.current_year}}</span>
              </span>
              <span v-else-if="indicator_data.hist_change == -1">
                <span class="text-sm"><i>{{indicator_data.description}}</i> - This country used to have this law or policy in {{indicator_data.hist_year}}, but no longer has one as of {{indicator_data.current_year}}</span>
              </span>
            </p>
          </div>

          <!-- ALL OTHER NUMERICAL INDICATORS -->
          <div v-else-if="Math.abs(numberFormat(indicator_data.hist_change))>=.1">
            <p class="flex flex-col" style="display:inline;">
              <img v-if="indicator_data.change_posNeg == 'positive' && indicator_data.hist_goodbad == 'Up_Good_Down_Bad'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-up-good.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'negative' && indicator_data.hist_goodbad == 'Up_Good_Down_Bad'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-down-bad.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'positive' && indicator_data.hist_goodbad == 'Up_Bad_Down_Good'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-up-bad.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'negative' && indicator_data.hist_goodbad == 'Up_Bad_Down_Good'" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-down-good.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'positive' && indicator_data.hist_goodbad.includes('Neutral')" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/Neutral_Up.png" alt="" />
              <img v-if="indicator_data.change_posNeg == 'negative' && indicator_data.hist_goodbad.includes('Neutral')" class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/Neutral_Down.png" alt="" />
              <span v-if="indicator_data.format == 'PERCENT'">
                <b>{{numberFormat(indicator_data.hist_change)}} {{indicator_data.hist_change_type}} </b>
                <span class="text-sm">from {{numberFormat(indicator_data.hist_val)}}{{indicator_data.description}} in {{indicator_data.hist_year}} to {{numberFormat(indicator_data.current_val)}}% in {{indicator_data.current_year}}</span>
              </span>
              <span v-else>
                <b>{{numberFormat(indicator_data.hist_change)}} {{indicator_data.hist_change_type}} </b>
                <span class="text-sm">from {{numberFormat(indicator_data.hist_val)}} {{indicator_data.description}} in {{indicator_data.hist_year}} to {{numberFormat(indicator_data.current_val)}} in {{indicator_data.current_year}}</span>
              </span>
            </p>
          </div>
          <div v-else-if="Math.abs(numberFormat(indicator_data.hist_change))<.1">
            <p class="flex flex-col" style="display:inline;">
              <img class="h-8 mr-2" style="display:inline; white-space:nowrap;" src="@/assets/images/arrows/round-equal.png" alt="" />
              <span v-if="indicator_data.format == 'PERCENT'">
                <b>about the same </b>
                 <span class="text-sm">from {{numberFormat(indicator_data.hist_val)}}{{indicator_data.description}} in {{indicator_data.hist_year}} to {{numberFormat(indicator_data.current_val)}}% in {{indicator_data.current_year}}</span>
              </span>
              <span v-else>
                <b>about the same </b>
               <span class="text-sm">from {{numberFormat(indicator_data.hist_val)}} {{indicator_data.description}} in {{indicator_data.hist_year}} to {{numberFormat(indicator_data.current_val)}} in {{indicator_data.current_year}}</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'historicalData',
  props: ['country', 'indicator'],
  data: function () {
      return {
        data: [],
        variables: [],
        histData: []
      }
  },
  methods: {
    extractVars: function(text){
      let arrStr = text.split(/[{{}}]/);
      arrStr = arrStr.filter(a => (a.includes('_') & !a.includes('income') & !a.includes('global') & !a.includes('region')));
      return arrStr;
    },
    numberFormat: function(value){
      if(!isNaN(value)){
        
        if(value > 1000){
          value = Math.round(value);
          value = Number(value).toLocaleString();
        } else if((Math.abs(value) < 10) & (Math.abs(value) > 2.1)){
          // if the decimal would be ".0" anyway
          if(value % 1 == 0) {
            value = Number.parseFloat(value).toFixed(0);
          } else {
            value = Number.parseFloat(value).toFixed(1);
          }
        } else if((Math.abs(value) <= 2.1) & (Math.abs(value) != 0)){
          // if the decimal would be ".#0" anyway
          if((value*10) % 1 == 0) {
            value = Number.parseFloat(value).toFixed(1);
          } else {
            value = Number.parseFloat(value).toFixed(2);
          }
        } else {
          value = Math.round(value);
        }

        return value;
      } else {
        return value
      }


    },
  },
  mounted () {
    this.variables = this.extractVars(this.indicator.chartText);
    //const keys = Object.keys(this.country.values);
    
    for (let i = 0; i < this.variables.length; i++) {
      let posNeg = "";
      if (this.country.values[`${this.variables[i]}_hist_change`].includes("-")) {
        posNeg = "negative"
      } else if (this.country.values[`${this.variables[i]}_hist_change`].includes("Infinity") || this.country.values[`${this.variables[i]}_hist_change`].includes("Unknown")){
        posNeg = "";
      } else if (this.country.values[`${this.variables[i]}_hist_change`] == '0') {
        posNeg = "same";
      } else {
        posNeg = "positive"
      }

      this.histData.push({
        var_name: `${this.variables[i]}`,
        description: this.country.values[`${this.variables[i]}_indicator`],
        format: this.country.values[`${this.variables[i]}_format`],
        current_val: this.country.values[`${this.variables[i]}`],
        current_year: this.country.values[`${this.variables[i]}_year`].replace('.0', ''),
        hist_val: this.country.values[`${this.variables[i]}_hist_val`],
        hist_year: this.country.values[`${this.variables[i]}_hist_year`],
        hist_change: this.country.values[`${this.variables[i]}_hist_change`],
        change_posNeg: posNeg,
        hist_goodbad: this.country.values[`${this.variables[i]}_hist_goodbad`],
        hist_change_type: this.country.values[`${this.variables[i]}_hist_change_type`],
      });
    }
  }
}
</script>