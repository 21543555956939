<template>
  <div class="flex flex-row w-full h-screen">
    <div class="w-4/12 h-screen bg-indigo-900 flex flex-col items-center justify-center relative px-12">
      <!--Logo equilo-->
      <img class="absolute top-0 left-0 mt-10 ml-10 h-10" src="@/assets/images/logo.png" alt="logo equilo">
      <div class="text-4xl font-medium">
        <h2 class="tracking-tighter" style="color: #fff">
          Hacking gender
          equality &amp;
          social inclusion
          for a happier,
          more just world. </h2>
      </div>
    </div>
    <div class="w-8/12 h-screen bg-white flex flex-col items-center justify-center">
      <div class="w-8/12" v-if="!enrichProfile">
        <div class="mb-10">
          <h1 class="text-5xl print:text-4xl font-medium tracking-tight">
            Welcome to Equilo Dash!
          </h1>
          <p class="text-gray-500 tracking-tight mt-5 text-justify">
            Our mission is to hack gender equality & social inclusion for a happier, more just world. Your organization has invited you to be a team member. Please claim your seat by validating your email and creating your password here. Ready to get started?
          </p>
        </div>
        <div class="flex flex-col justify-start">

          <p class="text-gray-500">With what email address are you invited?</p>
          <input type="text" autocomplete="email" id="email" class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm" placeholder="Email address" v-model="input.email" v-bind:class="{ 'border-red-600': error, 'animate__animated animate__shakeX': error_animation }">


          <button class="bg-green-500 w-full py-5 rounded-lg mt-10 text-white font-semibold" @click="validate()">Validate email address 💌</button>

          <div class="mt-3" v-if="error">
            <div class="text-red-500 text-xs animate__animated animate__fadeIn">{{error}}</div>
          </div>

        </div>

        <div></div>
      </div>


      <div class="w-8/12" v-else>
        <div class="mb-10">
          <h1 class="text-5xl print:text-4xl font-medium tracking-tight">
            One more thing!
          </h1>
          <p class="text-gray-500 tracking-tight mt-5 text-justify">
            That was your first interaction with our online dashboard! We hope you liked it 😇.<br/>
            Now enrich your profile before we can give you access to our dashboard.
          </p>
        </div>
        <div class="flex flex-col justify-start">
          <p class="text-gray-500 mt-3">Your first name</p>
          <input type="text" autocomplete="firstname" id="firstname" class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm" placeholder="First name" v-model="user.firstname">

          <p class="text-gray-500 mt-3">Your last name</p>
          <input type="text" autocomplete="lastname" id="lastname" class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm" placeholder="Last name" v-model="user.lastname">

          <p class="text-gray-500 mt-3">Set a password</p>
          <input type="password" class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm" placeholder="Password" v-model="user.password">

          <p class="text-gray-500 mt-3">Repeat your password</p>
          <input type="password" class="border-2 border-gray-200 my-2 rounded-lg px-3 py-2 text-sm" placeholder="Password" v-model="user.password2">


          <button class="bg-green-500 w-full py-5 rounded-lg mt-10 text-white font-semibold" @click="createAccount()">Create account</button>

          <div class="mt-3" v-if="error">
            <div class="text-red-500 text-xs animate__animated animate__fadeIn">{{error}}</div>
          </div>

        </div>
        <div></div>
      </div>




    </div>
  </div>
</template>

<script>
  import auth from '@/helpers/auth';

  import env from '@/../env.js';
  const axios = require('axios');

  export default {
    name: 'login',
    data() {
      return {
        error: false,
        error_animation: false,
        enrichProfile: false,
        prevUser: {},
        user: {
          firstname: '',
          lastname: '',
          password: '',
          password2: ''
        },
        userId: false,
        input: {
          email: "",
        }
      }
    },
    methods: {

      createAccount(){
        const _self = this;
        const salt = 'qmdqlskfuypouj';
        // this.user.password
        if(this.user.password && this.user.password2 && this.user.lastname && this.user.firstname){
          if(this.user.password !== this.user.password2){
            alert('Passwords are not equal');
          } else {
            axios.post(env.apiUrl+'/users/create', {
              user: this.userId,
              firstname: this.user.firstname,
              lastname: this.user.lastname,
              password: this.encrypt(salt, this.user.password),
            }).then(function () {
              alert('You can now login');
              _self.$router.replace({ path: "/login" });
              document.location.reload();
            });
          }
        } else {
          alert('Not all fields are set.');
        }
      },
      encrypt: function(salt, text) {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
        return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
      },
      validate() {
        const _self = this;
        if (this.input.email !== "") {
          const _self = this;
          axios.post(env.apiUrl+'/users/validate', {
            email: this.input.email
          }).then(function (res) {
            _self.data = res.data;
            if( _self.data._id){
              _self.enrichProfile = true;
              _self.userId = _self.data._id
            } else {
              _self.error = "Something went wrong. Are you sure this is the right email";
            }
          });
        } else {
          _self.error = "Please provide us the right email";
          _self.error_animation = true;
            setTimeout(() =>{
              _self.error_animation = false;
            }, 2000);
        }
      }
    },
    async mounted() {
      this.prevUser = await auth.me().catch(() => {});
      if (this.prevUser && this.prevUser._id) {
        this.$store.commit('setUser', this.prevUser);
        this.$router.replace({ path: "/" });
      }
    },
    created() {
      window.addEventListener('keydown', (e) => {
        if (e.key == 'Enter') {
          this.login();
        }
      });
    },
  }
</script>
<style>

#hacking {
  color: white !important;
}

</style>
