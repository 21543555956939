<template>
    <div class="table-container">
        <table class="inner-table">
            <tr class="table-heading-row">
                <th v-for="head in heading" :key="head"> {{ head }}</th>
            </tr>
            <tr class="table-content-row" v-for="data in tableData" :key="data.rowHeading">
                <td :class="{
                    'font-bold ': ['Gross Profit', 'EBITDA', 'Net Profit'].includes(data.rowHeading),
                }">{{ data.rowHeading }}</td>
                <td class="text-right flex items-center justify-end unaudit-section">
                    <span class="flex">
                        <img v-if="data.class == 'red'" src="./../../../assets/images/IMM/vector-red.svg" />
                        <img v-if="data.class == 'green'" src="./../../../assets/images/IMM/vector-green.svg" />
                        <span :class="data.class">{{ data.percentChange }}</span>
                    </span>
                    {{ data.unAudited1 }}
                </td>
                <td class="text-right">{{ data.unAudited2 }}</td>
                <td class="text-right">{{ data.budget ? data.budget : '-' }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: "SalesTable",
    data() {
        return {
            heading : [ '', 'Year to Date. December 2020. Unaudited', 'Year to Date. December 2019. Unaudited', 'Year to Date. December 2019. Budget'],
                colHeading: ['Net Sales', 'Gross Profit',  'Gross Margin', 'SG&A', 'EBITDA', 'EBITDA Margin', 'Net Profit', 'Net Margin'],
                tableData: [
                { rowHeading: 'Net Sales', percentChange : '20.8%', unAudited1: '848', unAudited2 : '1,239', budget : '', class: 'red'},
                { rowHeading: 'Gross Profit', percentChange : '33.2%', unAudited1: '742', unAudited2 : '525', budget : '', class: 'green'},
                { rowHeading: 'Gross Margin', percentChange : '61%', unAudited1: '', unAudited2 : '62%', budget : '', class: ''},
                { rowHeading: 'SG&A', percentChange : '33.2%', unAudited1: '776', unAudited2 : '991', budget : '', class: 'green'},
                { rowHeading: 'EBITDA', percentChange : '-44.2%', unAudited1: '260', unAudited2 : '(180)', budget : '', class: 'green'},
                { rowHeading: 'EBITDA Margin', percentChange : '-31%', unAudited1: '', unAudited2 : '-15%', budget : '', class: ''},
                { rowHeading: 'Net Profit', percentChange : '2.5%', unAudited1: '(218)', unAudited2 : '(222)', budget : '', class: 'red'},
                { rowHeading: 'Net Margin', percentChange : '-26%', unAudited1: '', unAudited2 : '-18%', budget : '', class: ''},
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.table-container {
    gap: 20px;
    border-radius: 16px;
    border: 1px solid #E1E3E9;
    .inner-table {
        width: 100%;
        th, td {
            border: 1px solid #E1E3E9;
        }
        .table-heading-row {
            th {
                padding: 16px;
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #F4F6F9, #F4F6F9),linear-gradient(0deg, #E1E3E9, #E1E3E9);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2C2E35;
            }
            th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
            }
            th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
            }
        }
        .table-content-row {
            td {
                border-bottom: none;
                height: 68px;
                padding: 16px 12px 16px 12px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2C2E35;

            }
            td:first-child {
                border-left: none;
            }
            td:last-child {
                border-right: none;
            }
            .unaudit-section {
                span {
                    gap: 8px;
                }
            }
        }
    }
    .red {
        color: #CB2E00;
    }
    .green {
        color: #13A094;
    }
}
.font-bold {
    font-weight: 700 !important;
}
</style>