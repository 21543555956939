<template>

  <div class="static">
    <!--      <div class="donatebutton mytooltip">
            <span class="mytext">Please consider donating to <a href="https://www.equilo.io/equilo-foundation" target="_blank" style="color:blue;">Equilo Foundation</a>, a 501(c)3, to support the growth and maintenance of the open source tools. Interested in sponsorship with your organization's logo? <a href = "mailto: hello@equilo.org" style="color:blue;">Reach out!</a></span>
         <form action="https://www.paypal.com/donate" method="post" target="_top">
<input type="hidden" name="hosted_button_id" value="P22ZH2YDV2CNW" />
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />

</form>
        </div>-->
    <!-- <img
      src="@/assets/images/background-image-overview.jpg"
      alt="background-image"
      class="object-contain h-64 no-print"
    /> -->
    <div style="display:flex; justify-content:space-between;">
      <img src="@/assets/images/2X.png" alt="background-image" class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-left: 285px;" />
      <img src="@/assets/images/logo-navy.png" alt="background-image" class="object-contain no-print"
        style="height:6rem; margin-top: 72px; margin-right: 120px;" />
    </div>
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="endofcreated" key="content">
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3 ml-20">
              <div class="flex flex-col item-center justify-center">
                <div class="flex flex-row items-center justify-center print:flex-col">
                  <img class="h-16 print:h-auto print:w-64" src="@/assets/images/2X-assessment/new2X-assessment.png" />
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5">
                    New 2X Assessment
                  </h1>
                </div>
                <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center">
                  Ready to find out if your organization or investee is 2X eligible? Get started with the survey and
                  view your assessment results instantly, alongside bonus contextual data by navigating to <router-link
                    to="/2X-Ecosystem/My-2X-Assessments" style="color:blue;">My 2X Assessments</router-link> after
                  survey completion .
                </p>
              </div>
            </div>
          </div>
        </div>
        <main style="grid-template-columns:none !important">

          <div style="width:100%; text-align: center;">

            <section class="w-full container mx-auto margintop3 px-4 lg:px-0 border-t border-gray-300">
              <div class="flex flex-col mt-5 mb-5" v-scroll-reveal>
                <div class="mt-16 grid grid-cols-3 gap-4">
                  <div @click="viewAssessmentPermission('Org')">
                    <div class="container_foto container_foto-2x-gender long-text">
                      <div class="ver_mas text-center">
                        <img src="@/assets/images/external-link.png" alt="visibility-icon" class="visibility-icon"
                          style="width: 30px;height:30px;" />
                      </div>
                      <article class="text-left article_cols_two">
                        <img src="@/assets/images/notebook-pencil.png" alt="visibility-icon" class="card-icon" />
                        <h2>Are you a Corporate or SME?</h2>
                        <h4>If your organization is a corporate or SME, use this survey.</h4>
                      </article>
                      <img class="bg-shade" src="@/assets/images/2X-assessment/2X-new-corporate.png" alt="" />
                    </div>
                  </div>
                  <div @click="viewAssessmentPermission('Funds')">
                    <div class="container_foto container_foto-2x-gender long-text">
                      <div class="ver_mas text-center">
                        <img src="@/assets/images/external-link.png" alt="visibility-icon" class="visibility-icon"
                          style="width: 30px;height:30px;" />
                      </div>
                      <article class="text-left article_cols_two">
                        <img src="@/assets/images/notebook-pencil.png" alt="visibility-icon" class="card-icon" />
                        <h2>Are you an Investment Fund?</h2>
                        <h4>If your organization is an investment fund, use this survey</h4>
                      </article>
                      <img class="bg-shade" src="@/assets/images/2X-assessment/2X-new-funds&fis.png" alt="" />
                    </div>
                  </div>
                  <div @click="viewAssessmentPermission('Fis')">
                    <div class="container_foto container_foto-2x-gender long-text">
                      <div class="ver_mas text-center">
                        <img src="@/assets/images/external-link.png" alt="visibility-icon" class="visibility-icon"
                          style="width: 30px;height:30px;" />
                      </div>
                      <article class="text-left article_cols_two custom-pb-0">
                        <img src="@/assets/images/notebook-pencil.png" alt="visibility-icon" class="card-icon" />
                        <h2>Are you a Financial Institution or Financial Intermediary?</h2>
                        <h4>If your organization is a financial institution or financial intermediary, use this survey
                        </h4>
                      </article>
                      <img class="bg-shade" src="@/assets/images/2X-assessment/2X-investee-funds.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center">
          Do you want a potential investee to complete a 2X assessment? Send them a request to complete the survey here.
        </p>
        <div class="flex flex-col ml-auto mt-auto w-6/12 lg:w-6/12">
          <div style="display:flex; margin-left: -150px;">
            <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2" style="margin-right:10px;">
              <input type="email" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm "
                placeholder="Enter Email Address" v-model="userEmail">
            </dd>
            <div class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-3 mt-1">
              <div @click="emailAssessmentReq()"
                style="background-color: #201747; color: white !important; cursor: pointer;"
                class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center">
                <button class="text-white text-sm font-medium">Email 2X assessment request</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Request Admin Modal Start -->
     <div class="fixed z-50 inset-0 overflow-y-auto" v-if="requestAdminModal">
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
    
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
          >&#8203;
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle
            "
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <span class="close" @click="closeRequestModal()" style="right: 8px;">&times;</span>
            <div class="Modal_Readmorestyle">
              <div class="ModalInnerStyle">
                <p style="font-weight: 600">
                  Thanks for visiting the 2X Assess page. Your team's administrator has not shared
                    permissions to allow you to see your
                    organization's 2X Assessment results or to initiate a new 2X Assessment. Please contact your administrator
                    to
                    request
                    permission to conduct a new survey.
                </p>
                <button
                  style="background-color: #fe5000; color: white"
                  class="
                    text-white
                    px-3
                    py-2
                    text-xs
                    gap-1
                    font-bold
                    rounded-lg
                    text-white
                    transition
                    duration-200
                    shadow
                    flex flex-row
                    items-center
                    justify-center
                    mr-4
                    ml-4
                  "
                  @click="checkRequestPermission()"
                >
                  Request permission
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- End Modal -->
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from '@/../env';
export default {
  name: "newtwoxassesment",
  data: function () {
    return {
      active: false,
      endofcreated: false,
      userEmail: null,
      requestAdminModal:false,
      sharedAssesmentData: [],
    }
  },
  components: {
    pageLoader
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true
      } else {
        return false
      }
    },
    async emailAssessmentReq() {
      let validate = this.validEmail(this.userEmail)
      if (validate) {
        let usersAry = []
        usersAry.push(this.userEmail)
        let body = {
          "sendEmails": usersAry,
          "type": "invite"
        }
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData) {
          this.$swal.fire({
            icon: "success",
            text: "Success! Your invitation was sent!",
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Please try again!",
            showConfirmButton: true,
          });
        }
        this.userEmail = null
      } else {
        alert('Please enter valid email address!')
      }
    },
    sendInviteToUser(body) {
      return new Promise((resolve) => {
        axios.post(env.apiUrl + '/assesment2x/send-survey', body,
          {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data.data);
          });
      })
    },
    
    async closeRequestModal() {
      this.requestAdminModal = false
    },
    
    checkAssesmentIsShared() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/assessment-count", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    
    async checkRequestPermission() {
      axios
        .get(env.apiUrl + "/assesment2x/share-request-to-admin", {
          params: {
            "type": this.sharedAssesmentData.type,
            "recordId": [this.sharedAssesmentData.assesmnetRecordIds]
          },
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
      }).catch((err) => {
        console.log(err);
      });
    },
    
    viewAssessmentPermission(type){
      if(!this.user.twoXAssessment){
          this.requestAdminModal = true
      }else{
        this.$router.push({path: '/2X-Ecosystem/New-2XAssessment/'+type});
      }
    },
  },
  async created() {
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      {
        name: "New 2X Assessments",
        url: "/2X-Ecosystem/New-2XAssessment",
      },

    ]);
    this.endofcreated = true;
  },
  async mounted() {
    if(this.$route.path === '/2X-Ecosystem/New-2XAssessment'){
      this.$router.push('/')
    }
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    this.sharedAssesmentData = await this.checkAssesmentIsShared();
  }
};
</script>

<style scoped lang="scss">
.custom-pb-0:hover {
  padding-bottom: 0% !important;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

span {
  cursor: pointer;
}
input {
  margin: 6px;
}
.Modal_Readmorestyle {
  padding: 30px 0 10px 35px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: left;
      line-height: 18px;
      color: #1b1b1b;
    }
    button {
      margin: 30px auto;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }
  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}
</style>
