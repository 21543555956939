<template>
    <div>
        <ul class="list-disc px-5">
            <li v-for="value in response" :key="value.id"><p v-html="value"></p></li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios';
import env from '@/../env.js';

export default {
  name: 'VueAirtableGbv',
  props: [
    'filter'
    
  ],
  data: function () {
            return {
                apiUrl: 'https://api.airtable.com/v0/appXebWdllnMq540w/Master%20Indicator%20Framework',
                apiKey: env.airTableAccessToken, 
                response:['Loading']
            };
        },
  mounted: function () {
    this.getData();
  },
  methods: {
    compareOrder(a, b) {
      if (a.fields.var_order < b.fields.var_order) {
          return -1;
        }
        if (a.fields.var_order > b.fields.var_order) {
          return 1;
        }
        return 0;
    },
    getData: async function () {
        await axios({
        url: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.apiKey}`
        },
        params: {
          filterByFormula: this.filter || ''
        }
      }).then((res) => {
          this.response = [];
          res.data.records.sort(this.compareOrder);
          res.data.records.forEach((value) => { 
              var std_source = value.fields.std_source.substr(0,  value.fields.std_source.indexOf('.')); 
              //this.response.push(value.fields.indicator+" ("+std_source+")")
              this.response.push(value.fields.indicator+" (<a href="+value.fields.std_source_url+" target='_blank'>"+std_source+"</a>)")
          });
          if(this.response.length <= 0){
              this.response = ["No Data"];
          }

      });
                        
                    
  }
}
}
</script>