<template>
  <div class="table-container">
    <table class="inner-table">
      <tr class="table-heading-row">
        <th v-for="head in headings" :key="head">{{ head }}</th>
      </tr>
      <tr v-for="row in tableData" :key="row.SDG" @click="toggleVisibility(row)">
        <td>
          <div class="flex justify-between py-4 px-3 items-center">
            {{ row.SDG }}
          </div>
        </td>
        <td>
          <div class="py-4 px-3" style="height: 64px">{{ row['Positive Impact generated'] }}</div>
        </td>
        <td v-if="row['IRIS']">
          <div class="py-4 px-3" style="height: 64px">{{ row['IRIS'] }}</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ImpactTable',
  props: {
    headings: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    // return {
    //   headings: ['SDG#4: Quality Education', 'Positive Impact generated'],
    //   tableData: [
    //     {
    //       "SDG": "Quality Education",
    //       "Positive Impact generated": "98%",
    //       "isVisible": false
    //     },
    //     {
    //       "SDG": "Achieve gender equality and empower all women and girls",
    //       "Positive Impact generated": "98%",
    //       "isVisible": false
    //     },
    //     {
    //       "SDG": "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.",
    //       "Positive Impact generated": "98%",
    //       "isVisible": false
    //     }
    //   ]
    // };
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #E1E3E9;
  .inner-table {
    width: 100%;
    th, td {
      border: 1px solid #E1E3E9;
    }
    .table-heading-row {
      th {
        padding: 16px;
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #F4F6F9, #F4F6F9),linear-gradient(0deg, #E1E3E9, #E1E3E9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2C2E35;
      }
      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }
      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }
    .table-content-row {
      td {
        border-bottom: none;
        height: 68px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2C2E35;
        .difference-data {
          background: linear-gradient(0deg, #F4F6F9, #F4F6F9),linear-gradient(0deg, #E1E3E9, #E1E3E9);
          border-top: 1px solid #E1E3E9;
        }
        img {
          width: 32px;
          height: 32px;
        }
      }
      td:first-child {
        border-left: none;
      }
      td:last-child {
        border-right: none;
      }
    }
  }
}
</style>