<template>
    <div class="table-container rounded-lg">
        <div v-for="(section, sIndex) in tableData" :key="sIndex">
            <table class="inner-table">
                <thead class="bg-[#F4F6F9] h-14 p-4">
                    <tr class="table-content-row">
                        <th class="p-4 cursor-pointer  w-[432px]" @click="toggleVisibility(sIndex)">
                            <div class="flex justify-between items-center">
                                <div>
                                    {{ section.rowHeading }}
                                </div>
                                <div>
                                    <img
                                        :src="section.isVisible ? require('@/assets/images/arrow-up.svg') : require('@/assets/images/arrow-down.svg')" />
                                </div>
                            </div>
                        </th>
                        <th class="text-center" v-for="column in section.columns" :key="column">{{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody v-if="section.isVisible">
                    <tr v-for="(company, cIndex) in section.companies" :key="cIndex">
                        <td class="p-4">{{ company.name }}</td>
                        <td class="p-4 text-center" v-for="(data, dIndex) in company.data" :key="dIndex">{{ data }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [
                {
                    rowHeading: 'Governance',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: 'Company One', data: ['0/3', '0/3', '0/3', '0/3'] },
                        { name: 'Company Two', data: ['0/3', '0/3', '0/3', '0/3'] },
                    ]
                },
                {
                    rowHeading: 'Risk & Opportunities',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: 'Company One', data: ['0/3', '0/3', '0/3', '0/3'] },
                        { name: 'Company Two', data: ['0/3', '0/3', '0/3', '0/3'] },
                    ]
                },
                {
                    rowHeading: 'Targets & Performance',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: 'Company One', data: ['0/3', '0/3', '0/3', '0/3'] },
                        { name: 'Company Two', data: ['0/3', '0/3', '0/3', '0/3'] },
                    ]
                },
                {
                    rowHeading: 'Emissions Methodology,Data, Breakdown',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: 'Company One', data: ['0/3', '0/3', '0/3', '0/3'] },
                        { name: 'Company Two', data: ['0/3', '0/3', '0/3', '0/3'] },
                    ]
                },
                {
                    rowHeading: 'Engagement',
                    isVisible: true,
                    columns: ['2020', '2021', '2022', '2023'],
                    companies: [
                        { name: 'Company One', data: ['0/3', '0/3', '0/3', '0/3'] },
                        { name: 'Company Two', data: ['0/3', '0/3', '0/3', '0/3'] },
                    ]
                }
            ]
        };
    },
    methods: {
        toggleVisibility(index) {
            this.tableData[index].isVisible = !this.tableData[index].isVisible;
        }
    }
};
</script>
<style lang="scss" scoped>
.table-container {
    gap: 20px;

    .red {
        color: #CB2E00;
    }

    .green {
        color: #13A094;
    }
}

.inner-table {
    width: 100%;

    th,
    td {
        border: 1px solid #E1E3E9;
    }

    .table-heading-row {
        th {
            padding: 16px;
            gap: 32px;
            border-top: none;
            background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
            font-family: Arial;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #2C2E35;
        }

        th:first-child {
            border-top-left-radius: 16px;
            border-left: none;
        }

        th:last-child {
            border-top-right-radius: 16px;
            border-right: none;
        }
    }

    .table-content-row {
        td {
            height: 68px;
            // padding: 16px 12px 16px 12px;
            gap: 1px;
            font-family: Arial;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #2C2E35;

            img {
                width: 32px;
                height: 32px;
            }
        }

        td:first-child {
            border-left: none;
        }

        td:last-child {
            border-right: none;
        }

        .unaudit-section {
            span {
                gap: 8px;
            }
        }
    }
}

.table-content-row {
    td {
        border-bottom: none;
        height: 68px;
        // padding: 16px 12px 16px 12px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2C2E35;

        img {
            width: 32px;
            height: 32px;
        }
    }

    td:first-child {
        border-left: none;
    }

    td:last-child {
        border-right: none;
    }

    .unaudit-section {
        span {
            gap: 8px;
        }
    }
}
</style>