<script>
/*

  Input should look like this:


  data = [
    {name: 'running', value1: }
  ]




  More information: https://www.chartjs.org/docs/latest/charts/radar.html

*/

import axios from 'axios';
import { Radar } from 'vue-chartjs'
import env from '@/../env';
import Cookies from '@/helpers/cookies'
import IntersectionScore from './IntersectionScoreGBV'
// function to configure charts
import {getTaabColors, initHoverBox} from './ChartFunction'
const _ = require('underscore');


export default {
  name: 'themeInterseection',
  extends: Radar,
  data: function() {
    return {
      countryData: [],
      regionalData: [],
      incomeData: [],
      globalData: [],
      theme:{},
      axes: [],
      axeColors: [],
      max: [],
      one: [],
      extreme: [],
      significant: [],
      severe: [],
      intersections: [],
      countryIntersection: [],
      regionalIntersection: [],
      incomeIntersection: [],
      globalIntersection: [],
      risk_level: ""
    };
  },
  methods: {
    opacity: function(hex){
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
      return 'rgba('+rgb.r+', '+rgb.g+', '+rgb.b+', 0.25)'
    },
    toTitleCase: function(phrase){
      return phrase.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    // getCountry: function(){
    //   const _self = this;
    //   return new Promise(function(resolve){
    //     axios.get(env.apiUrl+"/countries/"+_self.iso+"/sectors/"+_self.$route.params.sector, {
    //       headers: {
    //         'Authorization': 'Bearer '+Cookies.get('session')
    //       }
    //     }).then(function (res) {
    //       resolve(res.data.data);
    //     });
    //   })
    // },
    getTheme: function(){
      const _self = this;
      return new Promise(function(resolve){
        axios.get(env.apiUrl +"/reports/"+_self.iso+'/sectors/'+_self.$route.params.sector+"/themes/"+_self.themeId, {
          headers: {
            'Authorization': 'Bearer '+Cookies.get('session')
          }
        }).then(function (res) {
          resolve(res.data)
        });
      })
    },
  },
  props: ['iso', 'themeId', 'country'],
  //By Vatsal - mounted would be ideal, but keeping created as don't want to change preexisting code.
  async created () { 
    // We will request the country OURSELF because of asyncness...
    this.country = this.country; // lets do an await before rendering other items.
    this.theme = await this.getTheme(); // lets do an await before rendering other items.

    this.intersections = this.theme.intersections;
    
    for (var i = 0; i < this.intersections.length; i++) {
        
        let risk_level = [];

        let array = ['country','region','income','global'];
        this.intersections[i].gender_equality_country = this.intersections[i].gender_equality;

        array.forEach(item => {
          risk_level[`${item}`] = '';

          switch (true) {
            case (this.intersections[i][`gender_equality_${item}`] < 20):
                risk_level[`${item}`] = "Ubiquitous Risk";
                break;
            case (this.intersections[i][`gender_equality_${item}`] < 40):
                risk_level[`${item}`] = "Significant Risk";
                break;
            case (this.intersections[i][`gender_equality_${item}`] < 60):
                risk_level[`${item}`] = "Severe Risk";
                break;
            case (this.intersections[i][`gender_equality_${item}`] <= 100):
                risk_level[`${item}`] = "Extreme Risk";
                break;
            default:
                risk_level[`${item}`] = "NA";
                break;
          }

        })
        
        this.countryIntersection.push(new IntersectionScore(this.intersections[i].gender_equality, risk_level['country']));
        this.regionalIntersection.push(new IntersectionScore(this.intersections[i].gender_equality_region, risk_level['region']));
        this.incomeIntersection.push(new IntersectionScore(this.intersections[i].gender_equality_income, risk_level['income']));
        this.globalIntersection.push(new IntersectionScore(this.intersections[i].gender_equality_global, risk_level['global']));

        this.countryData.push((parseFloat(this.intersections[i].gender_equality) == 0) ? 0 : (parseFloat(this.intersections[i].gender_equality)) ? (parseFloat(this.intersections[i].gender_equality)) : null);
        this.regionalData.push((parseFloat(this.intersections[i].gender_equality_region)) || null);
        this.incomeData.push((parseFloat(this.intersections[i].gender_equality_income)) || null);
        this.globalData.push((parseFloat(this.intersections[i].gender_equality_global)) || null);
        // this.axes.push(this.formatLabel(this.intersections[i].theme, 15));
        this.axes.push(this.intersections[i].taab);

        this.max.push(100);
        this.one.push(60);
        this.extreme.push(60);
        this.severe.push(40);
        this.significant.push(20);
        const taabColors = getTaabColors();
        this.axeColors.push(taabColors[this.intersections[i].taab]);
    }

      /*
       const datasets = [
            { data: this.countryData, label: this.toTitleCase(this.country.humanName), borderColor: '#2F2665', fill: true, backgroundColor: this.opacity('#2F2665') },
            { data: this.regionalData, label: this.toTitleCase(this.country.region), borderColor: '#f56565', fill: true, backgroundColor: this.opacity('#f56565') },
            { data: this.incomeData, label: this.toTitleCase(this.country.income), borderColor: '#f5e063', fill: true, backgroundColor: this.opacity('#f5e063') },
            { data: this.max, label: this.country.income, fill: false, pointBackgroundColor: this.axeColors }

        ];

      */
      const _self = this;
      this.renderChart({
      labels: this.axes,
      datasets: [
          { 
            data: this.countryData, 
            label: this.country.humanName, 
            borderColor: env.geoColors.countryspider,
            pointBackgroundColor: env.geoColors.countryspider, 
            fill: false, 
            backgroundColor: this.opacity(env.geoColors.countryspider),
            borderWidth: 4, 
            pointHitRadius: 8 
          },
          { 
            data: this.regionalData, 
            label: this.country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' : this.country.region+ ' Region', 
            borderColor: env.geoColors.regionspider,
            pointBackgroundColor: env.geoColors.regionspider, 
            fill: false, 
            backgroundColor: this.opacity(env.geoColors.regionspider), 
            pointHitRadius: 8  
          },
          { 
            data: this.incomeData, 
            label: this.toTitleCase(this.country.income)+' Group', 
            borderColor: env.geoColors.incomespider, 
            pointBackgroundColor: env.geoColors.incomespider, 
            fill: false, 
            backgroundColor: this.opacity(env.geoColors.lmicspider), 
            pointHitRadius: 8  
          },
          {
            data: this.globalData,
            label: 'Global',
            borderColor: env.geoColors.lmicspider,
            pointBackgroundColor: env.geoColors.lmicspider,
            fill: false,
            backgroundColor: this.opacity(env.geoColors.lmicspider), 
            pointHitRadius: 8  
          },
          {
            data: this.extreme,
            label: 'Extreme Risk',
            borderColor: '#ED0C04',
            pointRadius: 0,
            fill: false,
            backgroundColor: 'rgba(254, 80, 0, 0.2)',
            borderWidth: 3,
          },
          /*{
            data: this.severe,
            label: 'Severe Risk',
            borderColor: '#FE5000',
            pointRadius: 0,
            fill: false,
            backgroundColor: 'rgba(237, 168, 4, 0.2)',
            borderWidth: 3,
          },
          {
            data: this.significant,
            label: 'Significant Risk',
            borderColor: '#EDA804',
            pointRadius: 0,
            fill: false,
            backgroundColor: 'rgba(250, 224, 83, 0.2)',
            borderWidth: 3,
          },*/
          { 
            data: this.max, 
            label: 'hide', 
            //borderColor: '#636363',
            fill: false,
            //backgroundColor: 'rgba(237, 12, 4, 0.2)', 
            borderWidth: 0,
            pointBackgroundColor: this.axeColors 
          },
      ]
    }, {
      legend: {
        position: 'bottom',
        labels: {
          filter: function (item) {

            // Logic to remove a particular legend item goes here
            return !item.text.includes('hide');
          },
          "fontSize": 13,
          fontFamily: "basier"
        },
        onHover: function (e, legendItem) {
          // Function to only hide Perfect geneder equality
          if(legendItem.text.indexOf('Perfect') == -1){
            e.target.style.cursor = 'pointer';
          }
        },
        onClick: function(e, legendItem) {
          if(legendItem.text.indexOf('Perfect') > -1){
            return false;
          }

             // code from https://github.com/chartjs/Chart.js/blob/master/src/core/core.legend.js
             // modified to prevent hiding all legend items

             var index = legendItem.datasetIndex;
             var ci = this.chart;
             var meta = ci.getDatasetMeta(index);

             // See controller.isDatasetVisible comment
             var hidden = meta.hidden === null? !ci.data.datasets[index].hidden : null;

             if( hidden ) {
                 var someShown = _.some( this.legendItems, function(item) {
                   return item !== legendItem && !item.hidden;
                 });

                 if(!someShown) return;
             }

             meta.hidden = hidden;

             // We hid a dataset ... rerender the chart
             ci.update();
         }
      },
        hover: {
            onHover: function (e) {
                // return false;
                var point = this.getElementAtEvent(e);
                
                if(point && point.length > 0 && point[0]._datasetIndex < 4){
                  e.target.style.cursor = 'pointer';
                    document.getElementById("intersection-info").position = "absolute";
                    document.getElementById("intersection-info").style.zIndex = "99999";
                    document.getElementById("intersection-info").style.top = e.pageY + "px";
                    document.getElementById("intersection-info").style.left = e.pageX + "px";
                    // Prevent from flashing.
                    if(point[0]._index !== _self.hoverIndex+"_"+point[0]._datasetIndex){
                        _self.hoverIndex = point[0]._index+"_"+point[0]._datasetIndex;
                        // initialise hoverbox
                        initHoverBox(e, _self, point);
                        
                        // GIVE LABELS VALUES
                        
                        if (point[0]._datasetIndex === 0) {
                            document.getElementById('intersection-name').innerText = _self.country.humanName;
                            _self.countryIntersection[point[0]._index].gender_equality >= 0 && _self.countryIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-country').innerText = 'Risk Level: ' + _self.countryIntersection[point[0]._index].risk_level : document.getElementById('intersection-country').innerText = 'Risk Level: NA ';
                            _self.countryIntersection[point[0]._index].gender_equality >= 0 && _self.countryIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-region').innerText = 'GBV Risk Score: ' + Math.round((_self.countryIntersection[point[0]._index].gender_equality)) : document.getElementById('intersection-region').innerText = 'GBV Risk Score: NA ';
                            document.getElementById('div-intersection-validity-power').style.display = 'flex';
                        } else if (point[0]._datasetIndex === 1) {
                            document.getElementById('intersection-name').innerText = _self.country.region;
                            _self.regionalIntersection[point[0]._index].gender_equality && _self.regionalIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-country').innerText = 'Risk Level: ' + _self.regionalIntersection[point[0]._index].risk_level : document.getElementById('intersection-country').innerText = 'Risk Level: NA';
                            _self.regionalIntersection[point[0]._index].gender_equality && _self.regionalIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-region').innerText = 'GBV Risk Score: ' + Math.round((_self.regionalIntersection[point[0]._index].gender_equality)) : document.getElementById('intersection-region').innerText = 'GBV Risk Score: NA';
                            document.getElementById('div-intersection-validity-power').style.display = 'none';
                        } else if (point[0]._datasetIndex === 2) {
                            document.getElementById('intersection-name').innerText = _self.country.income;
                            _self.incomeIntersection[point[0]._index].gender_equality && _self.incomeIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-country').innerText = 'Risk Level: ' + _self.incomeIntersection[point[0]._index].risk_level : document.getElementById('intersection-country').innerText = 'Risk Level: NA';
                            _self.incomeIntersection[point[0]._index].gender_equality && _self.incomeIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-region').innerText = 'GBV Risk Score: ' + Math.round((_self.incomeIntersection[point[0]._index].gender_equality)) : document.getElementById('intersection-region').innerText = 'GBV Risk Score: NA';
                            document.getElementById('div-intersection-validity-power').style.display = 'none';
                        } else if (point[0]._datasetIndex === 3) {
                            document.getElementById('intersection-name').innerText = 'Global';
                            _self.globalIntersection[point[0]._index].gender_equality && _self.globalIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-country').innerText = 'Risk Level: ' + _self.globalIntersection[point[0]._index].risk_level : document.getElementById('intersection-country').innerText = 'Risk Level: NA';
                            _self.globalIntersection[point[0]._index].gender_equality && _self.globalIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-region').innerText = 'GBV Risk Score: ' + Math.round((_self.globalIntersection[point[0]._index].gender_equality)) : document.getElementById('intersection-region').innerText = 'GBV Risk Score: NA';
                            document.getElementById('div-intersection-validity-power').style.display = 'none';
                        } else if (point[0]._datasetIndex === 4) {
                            document.getElementById('intersection-info').style.display = 'none';
                        }


                        document.getElementById('intersection-theme-link').style.display = 'none';
                        document.getElementById('intersection-domain-link').style.display = 'none';
                        //document.getElementById('div-intersection-region').style.display = 'none';
                        document.getElementById('div-intersection-income').style.display = 'none';
                        //document.getElementById('div-intersection-lmic').style.display = 'none';
                        document.getElementById("div-intersection-country-four").style.display = "none";
                        document.getElementById("div-intersection-country-five").style.display = "none";
                        document.getElementById("div-intersection-country-six").style.display = "none";
                        document.getElementById("div-intersection-country-seven").style.display = "none";

                        document.getElementById('div-intersection-country').style.color = 'black';
                        document.getElementById('div-intersection-region').style.color = 'black';

                        if (_self.intersections[point[0]._index].gender_equality == "Missing") {
                          document.getElementById('intersection-scores-validity').innerText = "Unknown";
                          document.getElementById('intersection-scores-power').innerText = "Unknown";
                        }
                        
                        //document.getElementById('intersection-domain-link').href = '/#/reports/'+_self.country.ISO2+'/'+_self.$route.params.sector+"/domains/"+_self.axes[point[0]._index];
                    }
                } else {
                    document.getElementById('intersection-info').style.display = 'none';
                    e.target.style.cursor = 'default';
                }
                //if (point.length) e.target.style.cursor = 'pointer';
                //else e.target.style.cursor = 'default';

                //e.target.style.cursor = 'pointer';
         }
        },
      scale: {
        angleLines: {
          display: true,
          //color: "#2F2665",
          lineWidth: 1
        },
        gridLines: {
          display: true,
          //color: "#2F2665",
          lineWidth: 1
        },
        pointLabels: {
          fontColor: this.axeColors,
          fontSize: 14,
          fontFamily: 'basier',
          lineHeight: 2,
          onClick: function(){
          },
        },
        ticks: {
          //suggestedMin: 50,
          suggestedMax: 1.24
        },
      },
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,  
        callbacks: {
            title: function() {
                //return datasets[tooltipItem[0].datasetIndex].label
                return null
            },
            label: function (tooltipItem, data) {
                        const tooltip = data.datasets[tooltipItem.datasetIndex];
                        const value = tooltip.data[tooltipItem.index];
                        return value === 0 ? null : tooltip.label + ': ' + value;
                    }
        },
        backgroundColor: 'rgb(253, 253, 253)',
        xPadding: 20,
        yPadding: 20,

        titleFontStyle: 'semi-bold',
        titleFontColor: '#0066ff',
        titleFontFamily: 'basier',
        titleFontSize: 10,
        titleSpacing: 20,
        titleMarginBottom: 10,

        bodyFontColor: '#333333',
        bodyFontSize: 14,
        bodyFontFamily: 'basier',
        displayColors: false,

        cornerRadius: 2,
        borderColor: 'rgb(240, 240, 240)',
        borderWidth: 3,
        },
    })
  }
}
</script>

<style>
</style>
