<template>
  <div>
    <div class="my-10 mx-10 p-5">
      <div class="flex justify-center">
        <button
          v-if="localAnswerAgain"
          class="mt-2 mb-5 bg-red-500 hover:bg-red-700 text-white text-2xl font-bold py-2 px-4 rounded-lg"
          @click="deleteSurveyData"
        >
          Delete previous answers
        </button>
      </div>
      <transition
        name="fade"
        mode="out-in"
        enter-active-class="transition ease-out duration-500 transform"
        leave-active-class="transition ease-in duration-500 transform"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="toastVisible && error"
          class="toast"
          style="background-color: red"
        >
          An error occurred
        </div>
        <div v-else-if="toastVisible" class="fixed top-1/3 right-1/2 toast">
          Changes have been saved!
        </div>
      </transition>
      <div v-if="!showPopup">
        <div v-if="!initialLoading && survey && survey.visiblePages.length">
          <Survey class="survey" :survey="survey" />
        </div>
        <div v-else class="flex items-center justify-center">
          <div class="flex space-x-2 animate-pulse">
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
            <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
          </div>
        </div>
      </div>
      <div v-else>
        <popup
          text="Your survey responses have been successfully saved. You will be redirected to the landing page to complete the rest of the modules. You may return to edit these responses before submitting your final responses."
        />
      </div>
    </div>
  </div>
</template>

<script>
import "survey-core/defaultV2.min.css";
import { PlainLightPanelless } from "survey-core/themes/plain-light-panelless";
import { Model } from "survey-core";
import { Survey } from "survey-vue-ui";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import tippy from "tippy.js";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away.css";
import popup from "./popup.vue";

export default {
  components: {
    Survey,
    popup,
  },
  props: {
    surveyData: {
      type: [Object, Array],
      default: () => ({}),
    },
    surveyJson: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: [Object, Array],
    },
    surveyId: {
      type: String,
    },
    answerAgain: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    isComplete: {
      type: Boolean,
    },
    userType: {
      type: [String, null],
    },
    entityType: {
      type: [String, null],
    },
    COM_Commitment: {
      type: [Array, Object, null],
    },
    isEmploymentEligible: {
      type: [String, Boolean, null],
    },
    skipFlags: {
      type: [Array, null],
    },
    surveyType: {
      type: [Array, Object, null],
    },
    uuid: {
      type: [Number, String, null],
    },
    entityTypeForOldUsers: {
      type: [String, null],
    },
  },
  data() {
    return {
      survey: null,
      doAnimation: true,
      loading: false,
      error: false,
      localAnswerAgain: this.answerAgain,
      localIsComplete: this.isComplete,
      toastVisible: false,
      isCurrentProgressVisile: true,
      modifiedId: this.id,
      previousValues: {},
      showPopup: false,
      initialLoading: true,
    };
  },
  watch: {
    surveyJson: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.initialLoading = true;
          const refinedSurvey = this.skipFlags
            ? this.refineSurvey(newValue)
            : newValue;
          this.removeUnusedSkipLogic(refinedSurvey);
          this.redefineQuestions(refinedSurvey);
          this.survey = new Model(refinedSurvey);
          this.survey.applyTheme(PlainLightPanelless);
          this.survey.onComplete.add(this.surveyComplete);

          // TOOLTIP code
          this.survey.onAfterRenderQuestion.add((_survey, options) => {
            if (
              options?.question?.choices?.some((element) =>
                element.text.includes("[Varies]")
              ) &&
              this.COM_Commitment
            ) {
              options.question.choices = options.question.choices.map(
                (choice) => {
                  if (
                    choice.value.includes("women in senior management") ||
                    choice.value.includes(
                      "women partners and managing partners"
                    )
                  ) {
                    choice.text = choice.text.replace(
                      "[Varies]",
                      this.COM_Commitment[1]
                    );
                  }
                  if (
                    choice.value.includes("women on IC") ||
                    choice.value.includes("women on Board")
                  ) {
                    choice.text = choice.text.replace(
                      "[Varies]",
                      this.COM_Commitment[2]
                    );
                  }
                  if (choice.value.includes("women in workforce")) {
                    choice.text = choice.text.replace(
                      "[Varies]",
                      this.COM_Commitment[0]
                    );
                  }

                  return choice;
                }
              );
            }
            if (
              options.question.name == "EMPLOY_Workplace health & well-being_9"
            ) {
              if (this.isEmploymentEligible) {
                options.question.visibleIf = `{EMPLOY_Workplace health & well-being_1} contains 'Employer-sponsored health insurance options'`;
              } else {
                options.question.visibleIf = "false";
              }
            }
            let questionTitle = options.htmlElement.querySelector("h5");
            if (questionTitle && options?.question?.jsonObj?.tooltip) {
              tippy(questionTitle, {
                content: options.question.jsonObj.tooltip,
                placement: "top-start",
                animation: "shift-away",
                theme: "dark",
                arrow: true,
              });
            }
          });

          // Trigger DOM when anything changes in Survey
          this.survey.onValueChanged.add((_, options) => {
            this.$emit("survyeEdited", Object.keys(this?.survey?.data).length);

            // ADVANCED SKIP LOGIC
            // `sender` is the survey instance, and `options` contains details about the change
            const changedQuestionName = options.name;
            const newValue = options.value;
            const previousValue = this.previousValues[changedQuestionName];

            this.handleAnswerChange(
              changedQuestionName,
              newValue,
              previousValue
            );

            this.previousValues[changedQuestionName] = newValue;
          });

          // TOOLTIP code for answers
          this.survey.onAfterRenderQuestion.add((_survey, options) => {
            // answer tooltips
            if (options.question.jsonObj && options.question.jsonObj.choices) {
              options.question.jsonObj.choices.forEach((tooltip, index) => {
                let answerElements = options.htmlElement.querySelectorAll(
                  ".sd-item__control-label"
                );

                if (tooltip.answerTooltips) {
                  tippy(answerElements[index], {
                    content: tooltip.answerTooltips,
                    placement: "top-start",
                    animation: "shift-away",
                    theme: "dark",
                    arrow: true,
                  });
                }
              });
            }
            this.textAreasEnhancement();
            this.fileButtonEnhancement();
          });

          this.survey.data = this.surveyData;

          // Ensure survey has visible elements
          this.initialLoading = !this.survey.visiblePages.length;

          // progress bar
          this.survey.onProgressText.add((_survey, options) => {
            options.text = `Answered: ${Math.round(
              (100 * options.answeredQuestionCount) / options.questionCount
            ).toFixed(0)}%`;
          });
        }
      },
    },
  },
  mounted() {
    let containerOne = document.querySelector(
      ".sd-progress-buttons__container"
    );
    let containerTwo = document.querySelector(".sd-page__description");
    containerOne?.remove();
    containerTwo?.remove();
    this.$root.$on("saveCurrentProgressFromParent", async () => {
      await this.saveCurrentProgress();
    });

    // Text Areas elements
    this.textAreasEnhancement();

    // File Buttons elements
    this.fileButtonEnhancement();

    if (this.surveyJson.pages) {
      this.surveyJson?.pages?.forEach((page) => {
        page?.elements?.forEach((question) => {
          this.previousValues[question.name] = null;
        });
      });
    }
  },
  beforeDestroy() {
    this.$root.$off("saveCurrentProgressFromParent");
  },
  methods: {
    async surveyComplete(sender) {
      this.loading = true;
      try {
        let surveyResponse = [];
        for (let key in sender.data) {
          surveyResponse.push({ questionId: key, answer: sender.data[key] });
        }
        if (
          this.surveyId == "2x-intake" &&
          (this.entityType == "fund-manager" ||
            this.entityType == "financial-institution")
        ) {
          let exists = surveyResponse.some(
            (obj) => obj.questionId === "GEN_General_13"
          );
          if (exists) {
            surveyResponse.forEach((data) => {
              if (data?.questionId == "GEN_General_13") {
                data.answer = "Financial Services";
              }
            });
          } else {
            surveyResponse.push({
              questionId: "GEN_General_13",
              answer: "Financial Services",
            });
          }
        }
        if (this.surveyId == "roi-one" && this.$route.path.includes("/ggsf")) {
          let type = "";
          const finInstType = surveyResponse.filter(
            (obj) => obj.questionId === "General300"
          )?.[0]?.answer;
          if (
            finInstType == "Bank" ||
            finInstType == "MFI" ||
            finInstType == "NBFI/NBFC" ||
            finInstType == "Leasing/Factoring" ||
            finInstType == "Payroll Lending" ||
            finInstType == "Cooperative" ||
            finInstType == "NGO"
          ) {
            type = "On-lending facility";
          }
          if (finInstType == "Fund" || finInstType == "Other") {
            type = "Fund";
          }
          let General3Exist = surveyResponse.some(
            (obj) => obj.questionId === "General3"
          );
          if (General3Exist) {
            surveyResponse.forEach((data) => {
              if (data?.questionId == "General3") {
                data.answer = type;
              }
            });
          }
          if (!General3Exist) {
            surveyResponse.push({ questionId: "General3", answer: type });
            this.userType =
              type == "On-lending facility" ? "on-lending-facility" : "fund";
          }
        }
        if (this.surveyId == "roi-one" && this.$route.path.includes("/roi")) {
          let type = "";
          let roiEntityType;
          if(this.entityTypeForOldUsers) { // for old roi users only
            let General3Exist = surveyResponse.some(
              (obj) => obj.questionId === "General3"
            );
            roiEntityType = this.entityTypeForOldUsers;
            if(General3Exist) {
              surveyResponse.forEach((data) => {
                if (data?.questionId == "General3") {
                  data.answer = this.entityTypeForOldUsers;
                }
              });
            } else {
              surveyResponse.push({ questionId: "General3", answer: this.entityTypeForOldUsers });
            }
          } else {
            roiEntityType = surveyResponse.filter(
              (obj) => obj.questionId === "General3"
            )?.[0]?.answer;
          }
          if (roiEntityType == "Institutional Investor") {
            type = "institutional-investor";
          }
          if (roiEntityType == "Fund") {
            type = "fund";
          }
          if (roiEntityType == "Micro-enterprise") {
            type = "micro";
          }
          if (roiEntityType == "Small or medium enterprise") {
            type = "sme";
          }
          if (roiEntityType == "Corporate (large)") {
            type = "large";
          }
          if (roiEntityType == "On-lending facility") {
            type = "on-lending-facility";
          }
          this.userType = type;
        }
        if (!this.answerAgain && !this.modifiedId) {
          const {
            data: { data },
            status,
          } = await axios.post(
            `${env.apiUrl}/survey-answers/`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: true,
              tag: this.userType,
              metadata: this.surveyType,
              uuid: String(this.uuid),
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          console.log(data);
          if (status === 200) {
            // this.isCurrentProgressVisile = false;
            this.error = false;
            this.localIsComplete = true;
            if (this.surveyId.toLowerCase().includes("2x"))
              this.showPopup = true;
            setTimeout(() => {
              this.$emit("surveyComplete");
            }, 4000);
          }
        } else {
          const {
            data: { data },
            status,
          } = await axios.put(
            `${env.apiUrl}/survey-answers/${this.modifiedId}`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: true,
              metadata: this.surveyType,
              uuid: String(this.uuid),
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          console.log(data);
          if (status === 200) {
            // this.isCurrentProgressVisile = false;
            this.error = false;
            this.localAnswerAgain = false;
            this.localIsComplete = true;
            if (this.surveyId.toLowerCase().includes("2x"))
              this.showPopup = true;
            setTimeout(() => {
              this.$emit("surveyComplete");
            }, 4000);
          }
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.$swal.fire({
          icon: "error",
          text:
            error?.response?.data?.error ||
            error?.response?.data?.message ||
            "Something went wrong!",
          showConfirmButton: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async saveCurrentProgress() {
      let surveyResponse = [];
      for (let key in this.survey.data) {
        surveyResponse.push({ questionId: key, answer: this.survey.data[key] });
      }
      try {
        if (!this.modifiedId) {
          const {
            data: { data },
            status,
          } = await axios.post(
            `${env.apiUrl}/survey-answers/`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: false,
              tag: this.userType,
              metadata: this.surveyType,
              uuid: String(this.uuid),
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          console.log(data);
          if (status === 200) {
            this.modifiedId = data._id;
            this.toastVisible = true;
            this.error = false;
          }
        } else {
          const {
            data: { data },
            status,
          } = await axios.put(
            `${env.apiUrl}/survey-answers/${this.modifiedId}`,
            {
              surveyId: this.surveyId,
              surveyResponse,
              isComplete: false,
              metadata: this.surveyType,
              uuid: String(this.uuid),
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          console.log(data);
          if (status === 200) {
            this.error = false;
            this.toastVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.$swal.fire({
          icon: "error",
          text:
            error?.response?.data?.error ||
            error?.response?.data?.message ||
            "Something went wrong!",
          showConfirmButton: true,
        });
      } finally {
        setTimeout(() => {
          this.toastVisible = false;
        }, 3000);
      }
    },
    deleteSurveyData() {
      this.survey.data = {};
    },
    refineSurvey(survey) {
      const newPages = survey?.pages?.map((page) => {
        const newElements = page?.elements?.filter(
          (question) =>
            Array.isArray(question.tags) &&
            question.tags.some((tag) => this.skipFlags.includes(tag))
        );
        return { ...page, elements: newElements };
      });
      const newSurvey = { ...survey, pages: newPages };
      return newSurvey;
    },
    redefineQuestions(survey) {
      survey?.pages?.forEach((page) => {
        page?.elements?.forEach((element) => {
          // Replacing intro
          if (element.type === "intro_text") {
            page.description = element.title;
          }

          // Replacing header
          if (element.type === "heading") {
            page.title = element.title;
          }

          // Field types as "number" come as "text type" as well
          if (element?.type === "text" && !element?.inputType) {
            element.type = "comment";
          }

          // Allow multiple files
          if (element?.type === "file") {
            element.allowMultiple = true;
            element.filePlaceholder = "Drag and drop files here or click the button below to select files to upload."
          }
        });
      });
    },
    textAreasEnhancement() {
      const textAreas = document.querySelectorAll('.sd-input.sd-comment');
      textAreas.forEach((textArea) => {
        textArea.rows = 1;
        textArea.addEventListener('input', function() {
          this.style.height = 'auto';
          this.style.height = `${this.scrollHeight}px`
        });
      });
    },
    fileButtonEnhancement() {
      const fileButtons = document.querySelectorAll('.sd-file__choose-btn.sd-action.sd-file__choose-btn--text span');
      fileButtons.forEach((fileButton) => {
        fileButton.innerHTML = 'Select File(s)';
      });
    },
    // ADVANCED SKIP LOGIC starts here
    removeUnusedSkipLogic(survey) {
      // extracting all question names from the survey
      const questions = survey?.pages?.flatMap((page) => {
        return page?.elements?.map((element) => {
          return element.name;
        });
      });

      // regular expression to match all occurrences between {}
      const regex = /{([^}]+)}/g;
      // iterating over each page and element in the survey
      survey?.pages?.forEach((page) => {
        page?.elements?.forEach((element) => {
          // check if element.visibleIf exists
          if (element?.visibleIf) {
            // extracting all occurrences between {} from element.visibleIf
            const matches = [...element.visibleIf.matchAll(regex)].map(
              (match) => match[1]
            );

            // checking if at least one of these occurrences is not inside the questions array
            const isInvalidReference = !matches.every((match) =>
              questions.includes(match)
            );

            const placeholder = "___";
            const regexSingleQuotesContent = /'([^']*)'/g;
            let stringWithoutQuotes = element.visibleIf.replace(
              regexSingleQuotesContent,
              () => placeholder
            );

            // regex to check for "and" & "or" outside of single quotes
            const hasOr = /\bor\b/.test(stringWithoutQuotes);

            // if there's at least one invalid reference or if "and" is present, set visibleIf to null
            // keep visibleIf if "or" is present and there are no invalid references
            if (isInvalidReference && !hasOr) {
              element.visibleIf = null;
            }
          }
        });
      });
    },
    extractDependencies(visibleIf) {
      const regex =
        /{([\w\s]+)} (contains|equals|=|<>|notcontains|>|<|>=|<=) ('[^']+'|\d+)/g;
      let matches,
        dependencies = [];
      while ((matches = regex.exec(visibleIf)) !== null) {
        dependencies.push({
          questionName: matches[1],
          answers: [matches[3].replace(/'/g, "")],
        });
      }
      return dependencies;
    },
    findQuestionByName(name) {
      // find and return the question object by its name
      for (let page of this.survey.pages) {
        for (let question of page.elements) {
          if (question.name === name) {
            return question;
          }
        }
      }
      return null;
    },
    // Call this method whenever an answer changes
    handleAnswerChange(changedQuestionName, newValue, previousValue) {
      const dependencies = this.dependencyMap[changedQuestionName];

      if (dependencies) {
        dependencies.forEach((dependency) => {
          // check if the current answer matches any of the dependency criteria
          let answersToRemove = [];
          if (Array.isArray(previousValue) && Array.isArray(newValue)) {
            // find answers that were removed
            answersToRemove = previousValue.filter(
              (x) => !newValue.includes(x)
            );
          } else if (previousValue !== newValue && previousValue !== null) {
            // for non-array values, if the value has changed and is not null, consider it as removed
            answersToRemove.push(previousValue);
          }

          answersToRemove.forEach((removedAnswer) => {
            if (dependency.answers.includes(removedAnswer)) {
              // reset dependent questions if necessary
              //console.log("Resetting due to removal of:", removedAnswer);
              this.resetQuestionAnswer(dependency.dependentQuestionName);
            }
          });
        });
      }
    },
    resetQuestionAnswer(questionName) {
      let question = this.survey.getQuestionByName(questionName);
      question.value = null;
      //console.log("Question", question.value);
    },
  },
  computed: {
    dependencyMap() {
      let map = {};
      this.survey.pages.forEach((page, pageIndex) => {
        page.elements.forEach((question) => {
          if (question.visibleIf) {
            const dependencies = this.extractDependencies(question.visibleIf);
            dependencies.forEach((dependency) => {
              if (!map[dependency.questionName]) {
                map[dependency.questionName] = [];
              }
              map[dependency.questionName].push({
                dependentQuestionName: question.name,
                answers: dependency.answers,
                pageIndex: pageIndex,
              });
            });
          }
        });
      });
      return map;
    },
  },
};
</script>

<style>
.survey {
  text-align: left;
}

.toast {
  background: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  z-index: 99;
}

.tippy-box[data-theme~="dark"] {
  background-color: rgb(33, 33, 33);
  color: white;
  padding: 1em;
}

div.sd-description {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #717686 !important;
}

div.sd-dropdown__value {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}
span.sv-string-viewer {
    position: relative !important;
    z-index: 1 !important;
}

div.sv-list__item-body {
  font-family: Arial !important;
}

input.sd-dropdown__filter-string-input {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #717686 !important;
}

h3.sd-title {
  font-family: "Montserrat" !important;
  color: #191b45 !important;
  font-weight: bold !important;
}

.sd-description {
  color: #2c2e35 !important;
  font-family: Arial !important;
}

h4.sd-title {
  font-family: "Montserrat" !important;
  color: #191b45 !important;
  font-weight: bold !important;
}

.sd-question__required-text {
  font-family: Arial !important;
  color: #cb2e00 !important;
  font-weight: bold !important;
}

h5.sd-title {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}

div.sv-tagbox__item {
  background-color: #2177c1 !important;
}

span.sd-item__control-label {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}

input.sd-input {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #2c2e35 !important;
}

span.sd-element__num {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #717686 !important;
}

input.sd-btn {
  color: #2177c1 !important;
}

input.sd-btn--action {
  background-color: #2177c1 !important;
  color: white !important;
}

button[title="Edit"] {
  margin-left: 1em;
  color: #2177c1 !important;
}

button[title="Edit"]:hover {
  color: white !important;
  background-color: #2177c1 !important;
  border-radius: 5px;
}

div.sd-progress {
  background-color: #dbe3e9 !important;
  height: 1em;
}

div.sd-progress__bar {
  background-color: #2177c1 !important;
  height: 1em;
}
.sd-element__title.sd-element__title--disabled {
  opacity: 0.5;
}
.sd-input.sd-input--disabled,
.sd-input.sd-input--disabled::placeholder {
  opacity: 0.5;
}

.sd-element__num {
  font-size: 15px !important;
}

.sv-string-viewer.sv-string-viewer--multiline {
  line-height: 1.4 !important;
}
</style>
