<template>
    <div class="static mt-40">
      <div v-if="!loaded">
        <page-loader></page-loader>
      </div>
      <stripe-pricing-table
        v-if="loaded"
        :pricing-table-id="pricingtableId"
        :publishable-key="stripePublicKey"
        :client-reference-id="clientReferenceId"
        :customer-session-client-secret="stripeClientSecret"
      >
      </stripe-pricing-table>
    </div>
  </template>
  <script>
  import auth from "@/helpers/auth";
  import env from "@/../env.js";
  import pageLoader from "@/components/page-loader.vue";
  import Cookies from '@/helpers/cookies'

  const axios = require('axios');

  export default {
    name: "pricings",  
    data: function () {
      return {
        loaded: false,
        user: [],
        pricingtableId: "",
        stripePublicKey: env.stripePublicKey,
        stripeClientSecret: '',
        clientReferenceId: 'checkout_' + (new Date).getTime()
      };
    },
    components: {
      pageLoader,
    },
    methods: {
      async createStripeCustomerSession() {
        if (!this.user.stripeCustomerId) {
          const createCustomerResp = await axios.post(
            `${env.apiUrl}/stripe/createCustomer`, {
              //
            }, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          );
          const stripeCustomer = createCustomerResp.data;
          this.user.stripeCustomerId = stripeCustomer.id;
        }
        const createCustomerSessionResp = await axios.post(
          `${env.apiUrl}/stripe/createCustomerSession`, {
            customerId: this.user.stripeCustomerId
          }, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        const stripeCustomerSession = createCustomerSessionResp.data;
        if (!stripeCustomerSession.error) this.stripeClientSecret = stripeCustomerSession.client_secret;
      },
      async load(){
        const _self = this;
        if(_self.$route.params.plan_type == 'GESI-AID'){
          this.pricingtableId = env.stripeProductTableIds.GESI_AID
        }else if(_self.$route.params.plan_type == 'Gender-Smart-Company'){
          this.pricingtableId = env.stripeProductTableIds.Gender_Smart_Company
        }if(_self.$route.params.plan_type == 'GLI-Investor'){
          this.pricingtableId = env.stripeProductTableIds.GLI_Investor
        }

        await this.createStripeCustomerSession();
      },
    },
    async mounted() {
      const _self = this;
      window.rewardful('ready', function () {
        if (window.Rewardful.referral) _self.clientReferenceId = window.Rewardful.referral;
      });

      this.user = await auth.me().catch(() => {
        console.log("Error login");
      });
      if (!this.user.admin) {
        this.$router.push({ name: 'notAllowed' })
      }
      this.$store.commit('setBreadcrumbs', [
        {'name': 'Plans', 'url': ''},
      ])
      await this.load();
      this.loaded = true;
    }
  };
  </script>
  