<template>
  <div data-modal="accept-terms" v-if="openCookiePermission">
    <div class="overlay"></div>
    <div class="wr">
      <div class="px-[60px] py-5"><CookiePolicyContent /></div>
      <div class="btn-wr">
        <div class="btn white tooltip" @click="denyTerms">
          <span class="lang-tooltiptext notranslate"
            >Only by accepting the Cookie Policy, you may proceed to the 2X
            Certification Self-Assessment</span
          >
          Deny
        </div>
        <div class="btn" @click="acceptTerms">Accept</div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "@/helpers/cookies";
import CookiePolicyContent from "./CookiePolicyContent.vue";

export default {
  name: "CookiePolicyPopUp",
  data: function () {
    return {
      openCookiePermission: false,
    };
  },
  components: {
    CookiePolicyContent,
  },
  methods: {
    async acceptTerms() {
      Cookies.set("equilo-cookie-consent", true, 180);
      this.openCookiePermission = false;
    },
    denyTerms() {
      this.$router.push("/");
    },
  },
  mounted() {
    if (!Cookies.get("equilo-cookie-consent")) {
      this.openCookiePermission = true;
    }
  },
};
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 520px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 62px;
  top: -45px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  right: 95%;
}

.tooltip .lang-tooltiptext {
  visibility: hidden;
  width: 480px;
  background-color: #6b787f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 20px;
  position: absolute;
  z-index: 9999;
  top: -54px;
  left: -30px;
}

.tooltip:hover .lang-tooltiptext {
  visibility: visible;
}

.tooltip .lang-tooltiptext::after {
  right: 95%;
}
</style>
