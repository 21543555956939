<template>
  <div class="static">
      <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!country.income" key="loading" >
          <page-loader></page-loader>
      </div>
      <div id="loaded" v-if="country.income" key="content">
          
          <div class="flex flex-col items-center justify-center mt-top-5 w-full print:mt-64">
            <div class="container">
       
                <div class="flex flex-row justify-center items-center mt-3">
                    <div class="flex flex-col item-center justify-center">
                      <div class="flex flex-row items-center justify-center print:flex-col">
                          <img v-if="!$route.params.iso2.split(',')[1]" class="mr-5 h-16 print:h-auto print:w-64" :src="country.flagUrl"/>
                          <h1 v-if="sector == false && !$route.params.iso2.split(',')[1]" class="text-6xl tracking-tighter font-medium text-center print:text-7xl">{{country.humanName }}</h1>
                          <h1 v-else class="text-4xl tracking-tighter font-medium text-center print:text-7xl">{{sector.name }} <span v-if="!$route.params.iso2.split(',')[1]"> in {{country.humanName }}</span></h1>
                      </div>
                        <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center" v-if="!$route.params.iso2.split(',')[1]">
                            Region: {{country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' :country.region }} | Income Group: {{toTitle(country.income || '')}}
                            <span v-if="sector && sector.id">| Sector: {{sector.name }}</span>
                        </p>
                    </div>
                </div>
            </div>
          </div>
          <section class="w-full container mx-auto mt-20 text-center px-4 lg:px-0">
              <div class="text-5xl print:text-4xl text-indigo-500 tracking-tighter font-medium flex flex-row items-center justify-center" id="overview">
                  <h1 class="font-medium">All themes</h1>
              </div>
              <div class="flex flex-col ml-auto mt-auto w-4/12 lg:w-3/12">
        <div
          class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center mb-4"
        >
          <svg
            class="absolute left-0 ml-3 h-5 w-5 text-gray-600"
            id="Layer_3"
            data-name="Layer 3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>Artboard 329</title>
            <circle
              cx="11.059"
              cy="11.059"
              r="7.059"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <line
              x1="20"
              y1="20"
              x2="16.05"
              y2="16.05"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <rect width="24" height="24" fill="none" />
          </svg>
          <v-select 
           class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
              
            placeholder="Select Themes"
            label="theme"
            @input="redirectTheme"
          :options="themesOptions"></v-select>

    
        </div>
      </div>
          </section>
          <section class="w-full container mx-auto mt-20 mb-20 px-4 lg:px-0" style="max-width: 1100px" v-if="keyAreasAllSearch.length > 0" :key="updateKey">
              <h3 class="text-indigo-500 print:text-xl print:text-black print:pt-10 text-xl font-semibold vatsal" :style="'color:' + env.brandingColors.navy">In order of lowest to highest gender equality score... </h3>
              <div v-for="intersection in keyAreasAllSearch" v-bind:key="intersection.name" class="opacityone"> 
                  <intersection :country="country" :intersection="intersection"></intersection>
              </div>
          </section>
          <section class="w-full container mx-auto mt-20 mb-20 px-4 lg:px-0" style="max-width: 1100px" v-else-if="displayTrue">
              <h3 class="text-indigo-500 print:text-xl print:text-black print:pt-10 text-xl font-semibold" :style="'color:' + env.brandingColors.navy">In order of lowest to highest gender equality score... </h3>
              <div v-for="intersection in keyAreasAll" v-bind:key="intersection.name" lass="opacityone">
                  <intersection :country="country" :intersection="intersection"></intersection>
              </div>
          </section>
<!--            <section class="w-full container mx-auto mt-32 mb-20 px-4 lg:px-0" style="max-width: 1100px" v-if="nonKeyAreasSearch.length > 0">
              <h3 class="text-indigo-500 print:text-xl print:text-black print:pt-10 text-xl font-semibold vatsal" :style="'color:' + env.brandingColors.navy">The following themes are also important with notable gaps.</h3>
              <div v-for="intersection in nonKeyAreasSearch" v-bind:key="intersection.name" class="opacityone">
                  <intersection :country="country" :intersection="intersection"></intersection>
              </div>
          </section>
          <section class="w-full container mx-auto mt-32 mb-20 px-4 lg:px-0" style="max-width: 1100px" v-else-if="displayTrue">
              <h3 class="text-indigo-500 print:text-xl print:text-black print:pt-10 text-xl font-semibold" :style="'color:' + env.brandingColors.navy">The following themes are also important with notable gaps.</h3>
              <div v-for="intersection in nonKeyAreas" v-bind:key="intersection.name" v-scroll-reveal>
                  <intersection :country="country" :intersection="intersection"></intersection>
              </div>
          </section>-->
<!--            <section class="w-full container mx-auto mt-32 mb-32 print:hidden px-4 lg:px-0 flex flex-col items-start justify-end gap-4">
              <router-link :to="'/reports/'+country.ISO2+'/'+$route.params.sector+'/improvements'" :style="'background-color:' + env.brandingColors.navy"
                           class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="feather feather-arrow-left">
                      <line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                  <p class="font-medium capitalize mr-3">Back to areas of improvements</p>
              </router-link>
          </section>-->
          <section class="w-full container mx-auto mt-32 mb-32 px-4 lg:px-0">
              <div class="flex flex-col mt-10 justify-start">
                  <p class="text-xs text-gray-500">
                      Data Use and Citation: Equilo grants full permission to its users to copy and paste information that appears in the dashboard for their own internal use, directly citing the original data sources and citing Equilo for analysis and scoring. Please request permission from Equilo before utilizing our content for external publications.
                  </p>
                  <p class="text-xs text-gray-500 mt-5 mb-1">Sources on this page:<br/>
                  <ol class="list-decimal mx-5">
                    <li v-for="(citation, c) in allBibliographyData" v-bind:key="citation.id+c">{{((citation || {}).text || '').toString().replace('n.d.', citation.year + '. ')}}
                      {{ citation && citation.text ? "" : "Source not found "}}
                      (<a target="_blank" :href="citation.url" style="word-wrap: break-word;">{{citation.url}}</a>)</li>
                  </ol>
                  </p>
                  <p class="text-xs text-gray-500 mt-5">
                      Equilo uses the TAAP framework, which stands for Transforming Agency, Access, and Power.
                      Comprised of six domains, the TAAP framework provides a structure to analyze and amplify the
                      voices, opportunities, and dignity of all people, especially those from socially excluded
                      groups. More information about this approach can be found at taapinclusion.org. Equilo Dash uses
                      15 themes that cut across two or more of the TAAP domains, for a total of 32 unique TAAP
                      domain/theme intersections for analysis and scoring. Each gender equality score is based on a
                      specific set of indicators within a given intersection.
                  </p>
              </div>
          </section>
      </div>
      </transition>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env.js";
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";

import intersection from "@/components/theme-intersection";
import Cookies from "@/helpers/cookies";

export default {
name: "country-themes",
components: {
  intersection,
  pageLoader,
},
data: function () {
  return {
    country: {},
    print: false,
    improvements: [],
    //nonKeyAreas:[],
    //keyAreas: [],
    //keyAreasSearch:[],
    //nonKeyAreasSearch:[],
    keyAreasAll: [],
    keyAreasAllSearch: [],
    themesOptions: [],
    displayTrue: true,
    env,
    sector: { name: "" },
    countryData: [],
    allBibliographyData: [],
    sourceData: [],
    json_data: [],
    updateKey: 0
  };
},
methods: {
  generateBibliographyCSV() {
    this.allBibliographyData.forEach((data, index) => {
      if (data.text && data.url && data.url != 'Missing' && data.text != 'Missing') {
        let arr = [];
        arr.push(index + 1);
        arr.push(data.text);
        arr.push(data.url);
        this.json_data.push(arr)
      } else {
        let arr = [];
        arr.push(index + 1);
        arr.push("Source not found ()");
        this.json_data.push(arr)
      }
    })
  },
  async getBibliographyData() {
    if (this.bibliographySourceIds && this.bibliographySourceIds.length) {
      let arr = [];
      this.bibliographySourceIds.forEach(async (data) => {
        let val = data.tag.replace(/[[\]]/g, '');
        arr.push(val);
      })
      await this.getSource(arr);
    }
  },
  async getSource(sourceId) {
    let body = { "ids": sourceId };
    return axios.post(env.apiUrl + "/sources/all-citation", body, {
      headers: {
        'Authorization': 'Bearer ' + Cookies.get('session')
      }
    }).then(async (res) => {
      this.sourceData = res.data;
      // let item = { "index": index, "data": this.sourceData };
      this.sourceData = res.data;
      this.allBibliographyData = this.sourceData;
      // if (this.bibliographySourceIds.length == this.allBibliographyData.length) {
      await this.generateBibliographyCSV();
      // }
    })
  },
  redirectTheme(event) {
    if (event) {
      const searchResults = [];
      //const searchResultsNonKey = [];

      for (var i = 0; i < this.keyAreasAll.length; i++) {
        if (
          searchResults.indexOf(this.keyAreasAll[i]) == -1 &&
          this.keyAreasAll[i] &&
          this.keyAreasAll[i]["theme"] &&
          this.keyAreasAll[i]["theme"]
            .toLowerCase()
            .indexOf(event.toLowerCase()) > -1
        ) {
          searchResults.push(this.keyAreasAll[i]);
          //break;
        }
      }

      this.keyAreasAllSearch = [];
      this.keyAreasAllSearch = searchResults;
      //
      //         for (var j = 0; j< this.nonKeyAreas.length; j++) {
      //          if (searchResultsNonKey.indexOf(this.nonKeyAreas[j]) == -1 && this.nonKeyAreas[j] && this.nonKeyAreas[j]["theme"] && this.nonKeyAreas[j]["theme"].toLowerCase().indexOf(event.toLowerCase()) >-1){
      //            searchResultsNonKey.push(this.nonKeyAreas[j]);
      //            //break;
      //          }
      //        }
      //        this.nonKeyAreasSearch = [];
      //        this.nonKeyAreasSearch = searchResultsNonKey;
      this.updateKey++
      this.displayTrue = false;
    } else {
      this.keyAreasAllSearch = [];
      //        this.nonKeyAreasSearch = [];
      this.displayTrue = true;
    }
  },
  getSector: function () {
    const _self = this;
    return new Promise(function (resolve) {
      // Validate if you want general country information (count/base) or a specific sector
      if (["BASE", "country"].indexOf(_self.$route.params.sector) > -1) {
        resolve(false);
      } else {
        axios
          .get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
              'sector' : _self.$route.params.sector == 'country' ? '' : _self.$route.params.sector
            },
            params: { iso : _self.$route.params.iso2 }
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      }
    });
  },

  getCountry: function () {
    const _self = this;
    const isoCode = _self.$route.params.iso2.split(",")[0];
    const iso = isoCode ? isoCode : _self.$route.params.iso2;
    return new Promise(function (resolve) {
      axios
        .get(
          env.apiUrl +
            "/reports/" +
            iso +
            "/sectors/" +
            _self.$route.params.sector,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          resolve(res.data.data);
        });
    });
  },
  toTitle(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
},
computed: {
  bibliographySourceIds() {
    return this.$store.state.bibliographyData;
  },
  citations() {
    const compare = (a, b) => {
      if (a.rank < b.rank) {
        return -1;
      }
      if (a.rank > b.rank) {
        return 1;
      }
      return 0;
    };
    const x = this.$store.state.citations;
    return x.sort(compare);
  },
},
async created() {
  if (document.location.href.indexOf("print=true") > -1) {
    this.print = true;
  }
  this.$store.dispatch("emptyBibliographyData");
  this.$store.commit("resetCitations");
  this.country = await this.getCountry(); // lets do an await before rendering other items.
  // this.country = this.countryData.countries[0];

  this.sector = await this.getSector(); // lets do an await before rendering other items.

  // validate if you have access to this country.
  this.user = await auth.me().catch(() => {});
  if (this.$route.params.iso2 != 'EQ' &&
    !this.user.organization.accessGesi ||
    document.location.href.indexOf("print=") > -1
  ) {
    this.$router.push({ name: "notAllowed" });
  }

  //this.keyAreas = this.country.intersections.slice(0, 5);
  this.themesOptionsIntersections = this.country.intersections;

  for (var j = 0; j < this.themesOptionsIntersections.length; j++) {
    if (
      this.themesOptions.indexOf(this.themesOptionsIntersections[j].theme) ==
      -1
    ) {
      this.themesOptions.push(this.themesOptionsIntersections[j].theme);
    }
  }
  await this.themesOptions.sort();
  //    const nonKey = this.country.intersections.slice(5);
  //    for (var i = 0; i < nonKey.length; i++) {
  //      nonKey[i].nonKeyAreas = true; // to change the color
  //      this.nonKeyAreas.push(nonKey[i]);
  //    }
  this.keyAreasAll = this.country.intersections;
  this.$store.commit("downloadable", true);

  this.$store.commit("setBreadcrumbs", [
    { name: "Countries", url: "/" },
    {
      name:
        this.$route.params.iso2.split(",").length == 1
          ? this.country.humanName || this.country.name
          : "Multiple Countries",
      url: "/gesi-analysis/" + this.$route.params.iso2 + "/choose-sector",
    },
    {
      name: (this.sector || { name: "country" }).name,
      url:
        "/gesi-analysis/" +
        this.$route.params.iso2 +
        "/" +
        this.$route.params.sector +
        "/snapshot",
    },
    {
      name: "Themes",
      url:
        "/reports/" +
        this.$route.params.iso2 +
        "/" +
        this.$route.params.sector +
        "/themes",
    },
  ]);
  let allCountry = this.country.humanName;
  // this.countryData.countries.forEach(data => {
  //   allCountry.push(data.humanName);
  // })
  this.$gtag.event("sectors", {
    'domain_name': "",
    'theme_name': "",
    'page_name': this.$route.name,
    'userId': this.user._id,
    "sector_name": this.sector.name,
    "country_name": allCountry,
    "team_id": this.user.organization._id,
    "organizationName": this.user.organization.name,
  })
  this.$gtag.event("countries", {
    'domain_name': "",
    'theme_name': "",
    'page_name': this.$route.name,
    'userId': this.user._id,
    "sector_name": this.sector.name,
    "country_name": allCountry,
    "team_id": this.user.organization._id,
    "organizationName": this.user.organization.name,
  })
  let cont = [];
  cont.push(allCountry);
  setTimeout(async () => {
    await this.getBibliographyData();
    let data = { "data": this.json_data, "country": cont, "sector": this.sector.name }
    await this.$store.commit("setBibliographyData", data);
  }, 2000);
},
beforeDestroy() {
  this.$store.commit("setBibliographyData", []);
}
};
</script>
<style>
.vs__dropdown-toggle {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
padding: 0 0 4px;
border: 0px !important;
background: none;
border-radius: 4px;
white-space: normal;
}
.opacityone {
opacity: 1 !important;
}
</style>