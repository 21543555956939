<template>
  <div class="static set-height">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0 pb-32"
      v-if="endofcreated"
    >
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper"></div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg">
            <div class="powered-text flex mr-8">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-center">
        <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
          <h1
            class="text-6xl tracking-tighter font-medium text-center print:text-7xl"
            style="color: #201747"
          >
            TA Service Provision
          </h1>
        </div>
      </div>
      <div class="mt-16 grid grid-cols-3 gap-4">
        <router-link to="/ta-service-provision/add-new-service-provision">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/TA_Service_provider/TA_service_provision.svg"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Enter a new service provision record</h2>
              <h4>
                Add a new record of technical advisory services provided each
                discrete time a service is provided. We will track and analyze
                this for you over time.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/TA_Service_provider/TA_service_provision.svg"
              alt=""
            />
          </div>
        </router-link>
        <router-link to="">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>Coming Soon</b></div>
            <img class="lock" src="@/assets/images/lock-removebg.png" alt="" />
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/TA_Service_provider/client_progress.png"
                alt="visibility-icon"
                class="card-icon"
                width="100"
              />
              <h2>Client Progress</h2>
              <h4>
                You can see your client's progress against your TA service
                provision.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/TA_Service_provider/client_progress.png"
              alt=""
              width="100"
            />
          </div>
        </router-link>
        <router-link to="">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>Coming Soon</b></div>
            <img class="lock" src="@/assets/images/lock-removebg.png" alt="" />
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/TA_Service_provider/my_dashboard.svg"
                alt="visibility-icon"
                class="card-icon"
                width="150"
              />
              <h2>My Dashboard</h2>
              <h4>
                To see your results of service provision/ aggregate hours spent,
                services provided, etc.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/TA_Service_provider/my_dashboard.svg"
              alt=""
              width="150"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
export default {
  name: "TAServiceProviderSubCard",
  data: function () {
    return {
      endofcreated: false,
    };
  },
  components: {
    pageLoader,
  },
  async created() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    // Hide condition according to this task - Unlock/ make public TA service provider tool?
    // if (!this.user.organization.taServiceProvider || this.user.organization.taServiceProvider == "") {
    //   this.$router.push({ path: "/" })
    // }

    this.$store.commit("setBreadcrumbs", [
      { name: "TA Service Provision", url: "/ta-service-provision" },
    ]);
    this.endofcreated = true;
  },
};
</script>
<style scoped lang="scss">
.set-height {
  min-height: 101vh !important;
}
.PoweredbyTitle_logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 170px;
  padding: 170px 0 0;

  .PoweredbyTitleWrepper {
    max-width: 847px;
    widows: 100%;
    display: flex;

    // align-items: center;
    img {
      width: 100px;
      height: 100px !important;
      object-fit: contain;
      display: none;
    }

    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 61px;
      color: #000;
    }
  }

  .Poweredby_logoImg {
    display: flex;
    justify-content: flex-end;

    .powered-text {
      span {
        font-size: 14px;
        line-height: 20px;
        margin-right: 35px;
        font-weight: 400;
        color: #2c2e35;
      }
    }

    img {
      width: 100px;
      height: 33px;
      object-fit: contain;
      margin-right: 35px;
      max-width: 100px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
