var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'updateListIndicatorViewComponents' + _vm.updateKey},[_c('div',{staticClass:"my-[1rem] w-full"},_vm._l((_vm.values),function(value,k){return _c('div',{key:k},[_c('h3',{staticClass:"font-bold max-w-[32rem]"},[_vm._v(" "+_vm._s(value.title)+" ")]),_c('div',{staticClass:"flex"},[(value.value && typeof value.value === 'string')?_c('div',[_vm._v(" "+_vm._s(value.value)+" ")]):(value.value.length)?_c('div',[_c('ul',{staticClass:"list-disc ml-[2rem]"},_vm._l((value.value),function(ans){return _c('li',{key:ans},[_c('span',[_vm._v(_vm._s(ans))])])}),0)]):_vm._e(),(value.textForChartInfo)?_c('span',{staticClass:"bg-blue-500 rounded-md p-2 text-md text-white text-center max-w-[25rem]",domProps:{"innerHTML":_vm._s(
            value.textForChartInfo.replace(/(\d+)/g, '<strong>$1</strong>')
          )}}):_vm._e(),(
            _vm.$route.path.includes('/admin') &&
            value?.surveyQType != 'Calculated' &&
            value?.surveyQtype
          )?_c('div',{staticClass:"flex items-center gap-3 justify-end"},[_c('img',{staticClass:"h-6 w-6 cursor-pointer",attrs:{"src":require("@/assets/images/edit-dark.svg"),"alt":"edit-icon"},on:{"click":function($event){return _vm.openEditModal(value)}}}),_c('verification-dropdown',{attrs:{"value":value}}),_c('div',[_c('MLVerifierValue',{attrs:{"value":value,"teamId":_vm.$route.query?.teamId}})],1)],1):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }