var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-40"},[_vm._m(0),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('div',[_c('p',{staticClass:"text-lg text-left text-gray-900"},[_vm._v("Investee company name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyName),expression:"companyName"}],staticClass:"mt-2 w-72 h-12 p-3 rounded-lg border border-gray-300",attrs:{"type":"text","placeholder":"Type investee company name"},domProps:{"value":(_vm.companyName)},on:{"input":function($event){if($event.target.composing)return;_vm.companyName=$event.target.value}}})]),_c('div',[_c('p',{staticClass:"text-lg text-left text-gray-900"},[_vm._v("E-mail address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailAddress),expression:"emailAddress"}],staticClass:"mt-2 w-72 h-12 p-3 rounded-lg border border-gray-300",attrs:{"type":"text","placeholder":"Type e-mail address"},domProps:{"value":(_vm.emailAddress)},on:{"input":function($event){if($event.target.composing)return;_vm.emailAddress=$event.target.value}}})]),_c('div',[_c('p',{staticClass:"text-lg text-left text-gray-9000"},[_vm._v("Portfolio (Optional)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.portfolio),expression:"portfolio"}],staticClass:"mt-2 w-72 h-12 p-3 rounded-lg border border-gray-300",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.portfolio=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.portfolioList),function(portfolio){return _c('option',{key:portfolio},[_vm._v(" "+_vm._s(portfolio)+" ")])}),0)])]),_c('div',{staticClass:"mt-4 text-right"},[_c('button',{staticClass:"px-4 py-2 rounded-lg text-white font-bold",class:{
          'bg-gray-300 cursor-not-allowed': !_vm.companyName || !_vm.emailAddress,
          'bg-blue-600': _vm.companyName && _vm.emailAddress,
        },attrs:{"disabled":!_vm.companyName || !_vm.emailAddress},on:{"click":function($event){return _vm.sendInviteToCompany(
            _vm.companyName,
            _vm.emailAddress,
            _vm.portfolio,
            _vm.portfolioName
          )}}},[_vm._v(" Send invite ")])])]),_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"w-154 h-8"},[_c('h2',{staticClass:"font-bold text-2xl text-left text-gray-800"},[_vm._v(" Investees ("+_vm._s(_vm.companyList.length)+") ")])]),_c('div',{staticClass:"mt-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"w-75 h-12 p-3 rounded-lg border border-gray-300",attrs:{"type":"text","placeholder":"Type company’s name"},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value},_vm.debounceGetCompanyList]}})]),_c('div',{staticClass:"mt-4 w-158 max-h-92 overflow-auto",attrs:{"id":"scrollbar_style"}},_vm._l((_vm.companyList),function(company,index){return _c('div',{key:index,staticClass:"w-154 h-16 p-4 rounded-lg border border-gray-300 mb-3 flex justify-between",class:{
          'bg-yellow-100': company.status == 'Request sent',
          'bg-green-100': company.status == 'Request accepted',
        }},[_c('div',{staticClass:"flex gap-3"},[_c('div',[(company.status == 'Request sent')?_c('img',{attrs:{"src":require("./../../../assets/images/IMM/yellow-circle.svg")}}):_c('img',{attrs:{"src":require("./../../../assets/images/IMM/temp-company-image.png")}})]),_c('div',{staticClass:"text-left text-gray-800"},[_vm._v(_vm._s(company.companyName))])]),_c('div',{staticClass:"text-left text-gray-800"},[_vm._v(" "+_vm._s(company.portfolioType)+" ")]),(company.status == 'Request sent')?_c('div',[_vm._m(1,true)]):_vm._e()])}),0)]),(!_vm.companyList.length)?_c('div',{staticClass:"mt-5 w-154 h-32 p-6 rounded-lg bg-gray-100"},[_vm._m(2),_c('p',{staticClass:"text-center text-gray-600 mt-4"},[_vm._v("Your investees list is empty")])]):_vm._e(),(_vm.isInviteSuccess)?_c('div',{staticClass:"w-68 h-14 p-4 rounded-lg bg-teal-100 m-auto mt-19 flex gap-2"},[_c('img',{attrs:{"src":require("@/assets/images/2x_certify/filled.svg")}}),_c('p',{staticClass:"text-left text-gray-800"},[_vm._v("Invitation was sent via e-mail")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"font-bold text-2xl text-left text-gray-900"},[_vm._v(" Invite your investment targets or portfolio companies to the Equilo IMM platform to start the collection of gender, ESG, climate, impact and financial data. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"w-34 h-8 p-1.5 rounded-full bg-yellow-500 text-white font-bold flex gap-1"},[_c('img',{attrs:{"src":require("./../../../assets/images/IMM/yellow-eye.svg")}}),_vm._v(" Request sent ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"block m-auto",attrs:{"src":require("./../../../assets/images/IMM/box.svg")}})])
}]

export { render, staticRenderFns }