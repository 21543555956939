import axios from 'axios'
import Cookies from '@/helpers/cookies'
import Config from '@/../env'


const salt = 'qmdqlskfuypouj';

// TODO: add auth parameter
// TODO => Check STATUSCODE (otherwise => reject)

const encrypt = (salt, text) => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
}

const me = () => new Promise((resolve) => {
  const session = Cookies.get('session');
  if (session) {
    axios
      .get(`${Config.apiUrl}/users/me`, {
        headers: {
          authorization: 'Bearer '+session
        }
      })
      .then((response) => {
        resolve(response)
      }).catch(() => {
        // reject(e);
        resolve(false);
      })
  } else {
    // reject('User not loggedin.')
    resolve(false);
  }
})

const updateProfile = profileData => new Promise((resolve, reject) => {
  const session = Cookies.get('session')
  axios.put(`${Config.apiUrl}/users/me`, profileData, {
    headers: {
      authorization: 'Bearer '+session
    }
  })
    .then((response) => {
      resolve(response);
    })
    .catch((e) => {
      reject(e)
    })
})

const updateDashboard = dashboard => new Promise((resolve, reject) => {
  const session = Cookies.get('session')
  axios.put(`${Config.apiUrl}/users/dashboard`, dashboard, {
    headers: {
      authorization: 'Bearer '+session
    }
  })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e)
      })
})

const updateCountry = country => new Promise((resolve, reject) => {
  const session = Cookies.get('session')
  axios.put(`${Config.apiUrl}/organizations/update-gbv-gesi-country`, country, {
    headers: {
      authorization: 'Bearer '+session
    }
  })
    .then((response) => {
      resolve(response);
    })
    .catch((e) => {
      reject(e)
    })
})

const updateInterest = type => new Promise((resolve, reject) => {
  const session = Cookies.get('session')
  axios.put(`${Config.apiUrl}/users/interest`, type, {
    headers: {
      authorization: 'Bearer '+session
    }
  })
      .then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e)
      })
})

// : encrypt(salt, password)
const login = (email, password, deviceUserAgent) => new Promise((resolve, reject) => {
  const existingDevicetoken = localStorage.getItem('deviceToken');      
  axios.post(`${Config.apiUrl}/users/me`, {
    email,
    password: encrypt(salt, password),
    existingDevicetoken,
    isAdmin: false,
    deviceName: navigator.appName,
    deviceVersion: navigator.appVersion,
    deviceCookieEnabled: navigator.cookieEnabled,
    devicePlatform: navigator.platform,
    deviceUserAgent
  })
    .then((response) => {
      resolve(response)
    })
    .catch((error) => {
      if (error.response) {
         const errorMetaData = {
          message : error.response.data.message,
          statusCode : error.response.status
         }
         reject(errorMetaData);
      }
    });
})

const destroySession = session => new Promise((resolve, reject) => {
  axios
    .get(`${Config.apiUrl}/users/me`, {
      headers: {
        authorization: 'Bearer '+session
      }
    })
    .then((response) => {
      resolve(response.data)
    }).catch((e) => {
      reject(e)
    })
})

const requestNewPassword = (email) => new Promise((resolve, reject) => {
  axios.post(`${Config.apiUrl}/users/request-new-password`, {
    email
  })
    .then((response) => {
      resolve(response)
    })
    .catch((e) => {
      reject(e)
    })
})

const resetPassword = (resetCode, password) => new Promise((resolve, reject) => {
  axios.post(`${Config.apiUrl}/users/request-new-password/${resetCode}`, {
    password: encrypt(salt, password)
  })
  .then((response) => {
    resolve(response)
  })
  .catch((e) => {
    reject(e)
  })
})

const changepassword = (newpassword, currentpassword, email) => new Promise((resolve, reject) => {
  axios.post(`${Config.apiUrl}/users/changepassword`, {
    newpassword:encrypt(salt, newpassword),
    email,
    currentpassword: encrypt(salt, currentpassword)
  })
    .then((response) => {
      resolve(response)
    })
    .catch((e) => {
      reject(e)
    })
})

export default {
  me,
  login,
  destroySession,
  updateProfile,
  resetPassword,
  requestNewPassword,
  changepassword,
  updateDashboard,
  updateInterest,
  updateCountry
}
