<template>
  <div class="flex flex-row w-full bg-gray-100">
    <sidebar></sidebar>
    <!--TABLE-->
    <div class="px-10 py-10 w-full center-items" style="padding-top: 100px">
      <div
        class="flex flex-col px-10 py-10 w-full"
        v-if="investeeData && investeeOptions"
      >
      <h1 class="text-2xl font-semibold">Edit My Investor Profile</h1>
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="my-profile-button" v-if="user.admin">
            <button
              class="text-white text-sm font-medium"
              @click="viewMyProfile()"
            >
              View My Profile
            </button>
          </div>
          <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
              <!-- <div class="px-4 py-5 pb-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Your team
                </h3>
                <p class=" max-w-2xl text-sm leading-5 text-gray-500">
                  Team details.
                </p>
              </div> -->
              <div class="mt-5" :class="user.admin ? '' : 'pointer-event-none'">
                <dl>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Primary Contact Name
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Primary Contact Name"
                        v-model="investeeData.primaryContactName"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Primary Contact Title
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Primary Contact Title"
                        v-model="investeeData.primaryContactTitle"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Primary Contact Email
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Primary Contact Email"
                        v-model="investeeData.primaryContactEmail"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Primary Contact phone
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Primary Contact phone"
                        v-model="investeeData.primaryContactPhone"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Secondary Contact Name
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Secondary Contact Name"
                        v-model="investeeData.secondaryContactName"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Secondary Contact Title
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Secondary Contact Title"
                        v-model="investeeData.secondaryContactTitle"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Secondary Contact Email
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Secondary Contact Email"
                        v-model="investeeData.secondaryContactEmail"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Secondary Contact Phone
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Secondary Contact Phone"
                        v-model="investeeData.secondaryContactPhone"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      2XC Member
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="2XC Member"
                        v-model="investeeData.member2xc"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Website
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Website"
                        v-model="investeeData.website"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      LinkedIn
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="LinkedIn"
                        v-model="investeeData.linkedin"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Twitter Handle
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Twitter Handle"
                        v-model="investeeData.twitterHandle"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Sectors of Interest
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(sector, index) in sectorsOfInterestType"
                          :key="index"
                          v-show="sector.isSectorSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ sector[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          m-2
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        @change="sectorSelected()"
                        v-model="selectedSector"
                      >
                        <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(sector, index) in sectorsOfInterestType"
                          :key="index"
                          :value="sector[1]"
                          :class="sector.isSectorSelected ? 'selected-class' : ''"
                        >
                          <span>{{ sector.isSectorSelected ? '✅ ' : ''}}{{ sector[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Other Sectors
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Other Sectors"
                        v-model="investeeData.otherSectors"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Geographies of Interest
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(geo, index) in geographicOfInterestType"
                          :key="index"
                          v-show="geo.isGeoGraphicSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ geo[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="geoGraphicSelected()"
                        v-model="selectedGeoGraphic"
                      >
                      <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(geo, index) in geographicOfInterestType"
                          :key="index"
                          :value="geo[1]"
                          :class="
                            geo.isGeoGraphicSelected ? 'selected-class' : ''
                          "
                        >
                        <span>{{ geo.isGeoGraphicSelected ? '✅ ' : ''}}{{ geo[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Geographies of Interest- Grants
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(geo, index) in geographicGrantsOfInterestType"
                          :key="index"
                          v-show="geo.isGeoGraphicGrantsSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ geo[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="geoGraphicGrantsSelected()"
                        v-model="selectedGeographicGrants"
                      >
                      <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(geo, index) in geographicGrantsOfInterestType"
                          :key="index"
                          :value="geo[1]"
                          :class="
                            geo.isGeoGraphicGrantsSelected ? 'selected-class' : ''
                          "
                        >
                        <span>{{ geo.isGeoGraphicGrantsSelected ? '✅ ' : ''}}{{ geo[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Country income group focus
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(
                            conIncome, index
                          ) in countryIncomeGroupFocusType"
                          :key="index"
                          v-show="conIncome.iscountryIncomeGroupFocusSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ conIncome[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="countryIncomeGroupFocusSelected()"
                        v-model="selectedCountryIncomeGroupFocus"
                      >
                      <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(
                            conIncome, index
                          ) in countryIncomeGroupFocusType"
                          :key="index"
                          :value="conIncome[1]"
                          :class="
                            conIncome.iscountryIncomeGroupFocusSelected
                              ? 'selected-class'
                              : ''
                          "
                        >
                        <span>{{ conIncome.iscountryIncomeGroupFocusSelected ? '✅ ' : ''}}{{ conIncome[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Themes of Interest
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(theme, index) in themesOfInterestType"
                          :key="index"
                          v-show="theme.isThemesOfInterestSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ theme[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="themesOfInterestSelected()"
                        v-model="selectedThemesOfInterest"
                      >
                        <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(theme, index) in themesOfInterestType"
                          :key="index"
                          :value="theme[1]"
                          :class="
                            theme.isThemesOfInterestSelected
                              ? 'selected-class'
                              : ''
                          "
                        >
                          <span>{{ theme.isThemesOfInterestSelected ? '✅ ' : ''}}{{ theme[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Other Themes
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Other Themes"
                        v-model="investeeData.otherThemes"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Investee Type/ Routes to Market
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(theme, index) in investeeTypeRoutesToMarketType"
                          :key="index"
                          v-show="theme.isInvesteeTypeRouteSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ theme[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="investeeTypeRouteSelected()"
                        v-model="selectedInvesteeTypeRoute"
                      >
                        <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(theme, index) in investeeTypeRoutesToMarketType"
                          :key="index"
                          :value="theme[1]"
                          :class="
                            theme.isInvesteeTypeRouteSelected
                              ? 'selected-class'
                              : ''
                          "
                        >
                          <span>{{ theme.isInvesteeTypeRouteSelected ? '✅ ' : ''}}{{ theme[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Products Offered
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(product, index) in productsOfferedType"
                          :key="index"
                          v-show="product.isProductsOfferedSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ product[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="productsOfferedSelected()"
                        v-model="selectedProductsOffered"
                      >
                        <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(theme, index) in productsOfferedType"
                          :key="index"
                          :value="theme[1]"
                          :class="
                            theme.isProductsOfferedSelected
                              ? 'selected-class'
                              : ''
                          "
                        >
                          <span>{{ theme.isProductsOfferedSelected ? '✅ ' : ''}}{{ theme[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Ticket Size Range
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(ticket, index) in ticketSizeRangeType"
                          :key="index"
                          v-show="ticket.isTicketSizeRangeSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ ticket[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="ticketSizeRangeSelected()"
                        v-model="selectedTicketSizeRange"
                      >
                        <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(ticket, index) in ticketSizeRangeType"
                          :key="index"
                          :value="ticket[1]"
                          :class="
                            ticket.isTicketSizeRangeSelected
                              ? 'selected-class'
                              : ''
                          "
                        >
                          <span>{{ ticket.isTicketSizeRangeSelected ? '✅ ' : ''}}{{ ticket[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Ticket Size Range- Grants
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="flex flex-row">
                        <div
                          class="m-2 p-2 rounded-lg"
                          v-for="(ticket, index) in ticketSizeRangeGrantType"
                          :key="index"
                          v-show="ticket.isTicketSizeRangeGrantsSelected"
                          style="background-color: gainsboro"
                        >
                          <span class="">{{ ticket[1] }}</span>
                        </div>
                      </div>
                      <select
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                          m-2
                        "
                        @change="ticketSizeRangeGrantsSelected()"
                        v-model="selectedTicketSizeRangeGrants"
                      >
                        <option disabled value="">Select / Unselect from this dropdown menu</option>
                        <option
                          v-for="(ticket, index) in ticketSizeRangeGrantType"
                          :key="index"
                          :value="ticket[1]"
                          :class="
                            ticket.isTicketSizeRangeGrantsSelected
                              ? 'selected-class'
                              : ''
                          "
                        >
                          <span>{{ ticket.isTicketSizeRangeGrantsSelected ? '✅ ' : ''}}{{ ticket[1] }}</span>
                        </option>
                      </select>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Ticket Flexibility in Exceptional Cases
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="toggle-button-section">
                        <p>
                          <span
                            class="
                              text-2xl
                              tracking-tighter
                              font-medium
                              text-center
                              print:text-7xl
                            "
                          ></span>
                          <label class="toggleSwitch large">
                            <input
                              type="checkbox"
                              id="ticketFlexibilityChecked"
                              checked="investeeData.ticketFlexibilityInExceptionalCases"
                              @click="ticketFlexibilitytoggle()"
                            />
                            <span>
                              <span></span>
                              <span></span>
                            </span>
                            <a></a>
                          </label>
                          <span
                            class="
                              text-2xl
                              tracking-tighter
                              font-medium
                              text-center
                              print:text-7xl
                              ml-4
                            "
                          ></span>
                        </p>
                      </div>
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Ticket Flexibility in Exceptional Cases- Grants
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="toggle-button-section">
                        <p>
                          <span
                            class="
                              text-2xl
                              tracking-tighter
                              font-medium
                              text-center
                              print:text-7xl
                            "
                          ></span>
                          <label class="toggleSwitch large">
                            <input
                              type="checkbox"
                              id="ticketFlexibilityGrantsChecked"
                              checked="investeeData.ticketFlexibilityInExceptionalCasesGrants"
                              @click="ticketFlexibilityGrantstoggle()"
                            />
                            <span>
                              <span></span>
                              <span></span>
                            </span>
                            <a></a>
                          </label>
                          <span
                            class="
                              text-2xl
                              tracking-tighter
                              font-medium
                              text-center
                              print:text-7xl
                              ml-4
                            "
                          ></span>
                        </p>
                      </div>
                      <!-- <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Ticket Flexibility in Exceptional Cases- Grants"
                        v-model="
                          investeeData.ticketFlexibilityInExceptionalCasesGrants
                        "
                      /> -->
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Description
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="Description"
                        v-model="investeeData.description"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      2XC Status
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="2XC Status"
                        v-model="investeeData.status2xc"
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      2XC Membership Start Date
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="2XC Membership Start Date"
                        v-model="investeeData.membershipStartDate2xc"
                        v-filterDate="investeeData.membershipStartDate2xc"
                        disabled
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      2XC Membership Renewal Date
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <input
                        type="text"
                        class="
                          border-2 border-gray-200
                          mb-4
                          rounded-lg
                          px-3
                          py-2
                          text-sm
                          w-full
                        "
                        placeholder="2XC Membership Renewal Date"
                        v-model="investeeData.membershipRenewalDate2xc"
                        v-filterDate="investeeData.membershipRenewalDate2xc"
                        disabled
                      />
                    </dd>
                  </div>
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Allow others to see profile
                    </dt>
                    <dd
                      class="
                        text-sm
                        leading-5
                        text-gray-900
                        sm:mt-0 sm:col-span-2
                      "
                    >
                      <div class="toggle-button-section">
                        <p>
                          <span
                            class="
                              text-2xl
                              tracking-tighter
                              font-medium
                              text-center
                              print:text-7xl
                            "
                          ></span>
                          <label class="toggleSwitch large">
                            <input
                              type="checkbox"
                              id="allowOthersToViewProfile"
                              :checked="
                                investeeData.consent == 'Yes' ? true : false
                              "
                              @click="allowOthersToViewProfileToggle()"
                            />
                            <span>
                              <span></span>
                              <span></span>
                            </span>
                            <a></a>
                          </label>
                          <span
                            class="
                              text-2xl
                              tracking-tighter
                              font-medium
                              text-center
                              print:text-7xl
                              ml-4
                            "
                          ></span>
                        </p>
                      </div>
                    </dd>
                  </div>
                  <!-- Logo will use later -->
                  <div
                    class="
                      bg-gray-50
                      px-4
                      py-1
                      sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
                    "
                  >
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Logo
                    </dt>
                    <div>
                      <div class="flex">
                        <div
                          v-for="logo in investeeData.logo"
                          :key="logo"
                          style="margin: auto 0; padding-right: 1rem"
                        >
                          <img
                            class="logo-image"
                            :src="logo.url"
                            width="100px"
                            height="100px"
                          />
                        </div>
                        <input
                          class="pt-2"
                          type="file"
                          id="imgLogo"
                          @change="handleFileUpload($event)"
                          name="img"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                </dl>
                <button
                  @click="update"
                  :class="`text-white my-5 mr-5 py-4 px-8 rounded float-right transform transition duration-200 ease-in-out text-lg ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`"
                  style="background-color: rgb(32, 23, 71)"
                  v-if="user.admin"
                  :disabled="loading"
                >
                  <span v-if="loading">Saving...</span>
                  <span v-else>Save Changes</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <transition name="fade" mode="out-in" enter-active-class="transition ease-out duration-700 transform" leave-active-class="transition ease-in duration-700 transform" enter-class="opacity-0" enter-to-class="opacity-100" leave-class="opacity-100" leave-to-class="opacity-0">
          <div v-if="toastVisible && error" class="toast" style="background-color: red;">An error occurred</div>
          <div v-else-if="toastVisible" class="toast">Changes have been saved!</div>
        </transition>
      </div>
      <transition name="fadeIn" mode="out-in">
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
        <div
          v-if="endofcreated && investeeData.length == 0"
          style="margin-top: 4rem"
        >
          <div class="flex flex-row justify-center items-center mt-3 ml-20">
            <div class="flex flex-col item-center justify-center">
              <div
                class="flex flex-row items-center justify-center print:flex-col"
              >
                <h1
                  class="
                    text-6xl
                    tracking-tighter
                    font-medium
                    text-center
                    print:text-7xl
                    ml-5
                  "
                >
                  No data found
                </h1>
              </div>
              <div
                class="
                  text-gray-600
                  font-medium
                  flex flex-row
                  items-center
                  justify-center
                  print:flex-col
                "
              ></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import auth from "@/helpers/auth";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";
import pageLoader from "@/components/page-loader.vue";

export default {
  name: "TwoXC",
  data: function () {
    return {
      user: {},
      endofcreated: false,
      investeeData: [],
      investeeOptions: [],
      sectorsOfInterestType: [],
      geographicOfInterestType: [],
      geographicGrantsOfInterestType: [],
      countryIncomeGroupFocusType: [],
      themesOfInterestType: [],
      investeeTypeRoutesToMarketType: [],
      ticketSizeRangeType: [],
      ticketSizeRangeGrantType: [],
      productsOfferedType: [],
      selectedSector: "",
      selectedGeoGraphic: "",
      selectedGeographicGrants: "",
      selectedCountryIncomeGroupFocus: "",
      selectedThemesOfInterest: "",
      selectedInvesteeTypeRoute: "",
      selectedTicketSizeRange: "",
      selectedTicketSizeRangeGrants: "",
      selectedProductsOffered: "",
      uploadedImageData: [],
      files: [],
      loading: false,
      toastVisible: false,
      error: false
    };
  },
  components: {
    sidebar,
    pageLoader,
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    if (!this.user.access2x) {
      this.$router.push({ name: "settings" });
    }
    this.$store.commit("downloadable", false);
    await this.getInvesteeData("true");
    this.$store.commit("setBreadcrumbs", [
      { name: "Settings", url: "/settings/account" },
      { name: "Edit My Profile", url: "/settings/2XC" },
    ]);
  },
  methods: {
    viewMyProfile() {
      this.$router.push({
        name: "TwoXCMembership",
        params: {
          investorOrg: this.user.organization._id,
        },
      });
    },
    async update() {
      if (this.files && this.files.length > 0) {
        await this.uploadImage();
      } else {
        await this.updateTwoXC();
      }
      this.toastVisible = true;
      setTimeout(() => {
        this.toastVisible = false;
      }, 3000);
    },
    async updateTwoXC() {
      this.loading = true;
      this.error = false;
      this.investeeData.sectorsOfInterest = [];
      this.investeeData.geographiesOfInterest = [];
      this.investeeData.geographiesOfInterestGrants = [];
      this.investeeData.themesOfInterest = [];
      this.investeeData.investeeTypeRoutesToMarket = [];
      this.investeeData.ticketSizeRange = [];
      this.investeeData.ticketSizeRangeGrants = [];
      this.investeeData.productsOffered = [];

      //for sectors
      this.sectorsOfInterestType.map((data) => {
        if (data.isSectorSelected == true) {
          this.investeeData.sectorsOfInterest.push(data[1]);
        }
      });
      //for geo graphics
      this.geographicOfInterestType.map((data) => {
        if (data.isGeoGraphicSelected == true) {
          this.investeeData.geographiesOfInterest.push(data[1]);
        }
      });
      //for geo graphics grants
      this.geographicGrantsOfInterestType.map((data) => {
        if (data.isGeoGraphicGrantsSelected == true) {
          this.investeeData.geographiesOfInterestGrants.push(data[1]);
        }
      });
      // for country Income Group Focus
      this.countryIncomeGroupFocusType.map((data) => {
        if (data.iscountryIncomeGroupFocusSelected == true) {
          this.investeeData.countryIncomeGroupFocus.push(data[1]);
        }
      });
      // for themes of interest
      this.themesOfInterestType.map((data) => {
        if (data.isThemesOfInterestSelected == true) {
          this.investeeData.themesOfInterest.push(data[1]);
        }
      });
      //for investee type routes for market
      this.investeeTypeRoutesToMarketType.map((data) => {
        if (data.isInvesteeTypeRouteSelected == true) {
          this.investeeData.investeeTypeRoutesToMarket.push(data[1]);
        }
      });
      //for ticket size range
      this.ticketSizeRangeType.map((data) => {
        if (data.isTicketSizeRangeSelected == true) {
          this.investeeData.ticketSizeRange.push(data[1]);
        }
      });
      //for ticket size range grants
      this.ticketSizeRangeGrantType.map((data) => {
        if (data.isTicketSizeRangeGrantsSelected == true) {
          this.investeeData.ticketSizeRangeGrants.push(data[1]);
        }
      });
      //for product offered
      this.productsOfferedType.map((data) => {
        if (data.isProductsOfferedSelected == true) {
          this.investeeData.productsOffered.push(data[1]);
        }
      });

      this.investeeData.ticketFlexibilityInExceptionalCases =
        document.getElementById("ticketFlexibilityChecked").checked;
      this.investeeData.ticketFlexibilityInExceptionalCasesGrants =
        document.getElementById("ticketFlexibilityGrantsChecked").checked;
      await axios
        .post(
          env.apiUrl + "/assesment2x/update-investee-interests",
          this.investeeData,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          if (res.data.data.acknowledged) {
            this.$swal.fire({
              icon: "success",
              text: "Success! Data successfully Updated!",
              showConfirmButton: true,
            });
            this.uploadedImageData = [];
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Please try again!",
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getInvesteeData(showOption) {
      this.investeeData = [];
      axios
        .get(
          env.apiUrl +
            "/assesment2x/get-investee-interests?showOptions=" +
            showOption,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          this.endofcreated = true;
          this.investeeData =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.data &&
            res.data.data.data[0]
              ? res.data.data.data[0]
              : [];
          this.investeeOptions = res.data.data.optionsType;
          if (this.investeeData.ticketFlexibilityInExceptionalCases == "true") {
            document.getElementById("ticketFlexibilityChecked").checked = true;
          }
          if (
            this.investeeData.ticketFlexibilityInExceptionalCases == "false"
          ) {
            document.getElementById("ticketFlexibilityChecked").checked = false;
          }

          if (
            this.investeeData.ticketFlexibilityInExceptionalCasesGrants ==
            "true"
          ) {
            document.getElementById(
              "ticketFlexibilityGrantsChecked"
            ).checked = true;
          }
          if (
            this.investeeData.ticketFlexibilityInExceptionalCasesGrants ==
            "false"
          ) {
            document.getElementById(
              "ticketFlexibilityGrantsChecked"
            ).checked = false;
          }
          if (this.investeeOptions) {
            // for sectors
            this.sectorsOfInterestType = Object.entries(
              this.investeeOptions.sectorsOfInterestType.value
            );
            this.sectorsOfInterestType.map((data) => {
              data["isSectorSelected"] = false;
              this.investeeData.sectorsOfInterest.forEach((item) => {
                if (data[1] == item) {
                  data.isSectorSelected = true;
                }
              });
            });
            // for geo graphics
            this.geographicOfInterestType = Object.entries(
              this.investeeOptions.geographicOfInterestType.value
            );
            this.geographicOfInterestType.map((data) => {
              data["isGeoGraphicSelected"] = false;
              this.investeeData.geographiesOfInterest.forEach((item) => {
                if (data[1] == item) {
                  data.isGeoGraphicSelected = true;
                }
              });
            });
            // for geo graphics grants
            this.geographicGrantsOfInterestType = Object.entries(
              this.investeeOptions.geographiesOfInterestGrantsType.value
            );
            this.geographicGrantsOfInterestType.map((data) => {
              data["isGeoGraphicGrantsSelected"] = false;
              this.investeeData.geographiesOfInterestGrants.forEach((item) => {
                if (data[1] == item) {
                  data.isGeoGraphicGrantsSelected = true;
                }
              });
            });
            // for country Income Group Focus
            this.countryIncomeGroupFocusType = Object.entries(
              this.investeeOptions.countryIncomeGroupFocusType.value
            );
            this.countryIncomeGroupFocusType.map((data) => {
              data["iscountryIncomeGroupFocusSelected"] = false;
              this.investeeData.countryIncomeGroupFocus.forEach((item) => {
                if (data[1] == item) {
                  data.iscountryIncomeGroupFocusSelected = true;
                }
              });
            });
            //for themes of interest
            this.themesOfInterestType = Object.entries(
              this.investeeOptions.themesOfInterestType.value
            );
            this.themesOfInterestType.map((data) => {
              data["isThemesOfInterestSelected"] = false;
              this.investeeData.themesOfInterest.forEach((item) => {
                if (data[1] == item) {
                  data.isThemesOfInterestSelected = true;
                }
              });
            });
            //for investee type routes for market
            this.investeeTypeRoutesToMarketType = Object.entries(
              this.investeeOptions.investeeTypeRoutesToMarketType.value
            );
            this.investeeTypeRoutesToMarketType.map((data) => {
              data["isInvesteeTypeRouteSelected"] = false;
              this.investeeData.investeeTypeRoutesToMarket.forEach((item) => {
                if (data[1] == item) {
                  data.isInvesteeTypeRouteSelected = true;
                }
              });
            });
            //for ticket size range
            this.ticketSizeRangeType = Object.entries(
              this.investeeOptions.ticketSizeRangeType.value
            );
            this.ticketSizeRangeType.map((data) => {
              data["isTicketSizeRangeSelected"] = false;
              this.investeeData.ticketSizeRange.forEach((item) => {
                if (data[1] == item) {
                  data.isTicketSizeRangeSelected = true;
                }
              });
            });
            // for ticket size range grants
            this.ticketSizeRangeGrantType = Object.entries(
              this.investeeOptions.ticketSizeRangeType.value
            );
            this.ticketSizeRangeGrantType.map((data) => {
              data["isTicketSizeRangeGrantsSelected"] = false;
              this.investeeData.ticketSizeRangeGrants.forEach((item) => {
                if (data[1] == item) {
                  data.isTicketSizeRangeGrantsSelected = true;
                }
              });
            });
            this.productsOfferedType = Object.entries(
              this.investeeOptions.productsOfferedType.value
            );
            this.productsOfferedType.map((data) => {
              data["isProductsOfferedSelected"] = false;
              this.investeeData.productsOffered.forEach((item) => {
                if (data[1] == item) {
                  data.isProductsOfferedSelected = true;
                }
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sectorSelected() {
      this.sectorsOfInterestType.map((data) => {
        if (data[1] == this.selectedSector) {
          data.isSectorSelected == true
            ? (data.isSectorSelected = false)
            : (data.isSectorSelected = true);
        }
      });
      this.selectedSector = "";
    },
    geoGraphicSelected() {
      this.geographicOfInterestType.map((data) => {
        if (data[1] == this.selectedGeoGraphic) {
          data.isGeoGraphicSelected == true
            ? (data.isGeoGraphicSelected = false)
            : (data.isGeoGraphicSelected = true);
        }
      });
      this.selectedGeoGraphic = "";
    },
    geoGraphicGrantsSelected() {
      this.geographicGrantsOfInterestType.map((data) => {
        if (data[1] == this.selectedGeographicGrants) {
          data.isGeoGraphicGrantsSelected == true
            ? (data.isGeoGraphicGrantsSelected = false)
            : (data.isGeoGraphicGrantsSelected = true);
        }
      });
      this.selectedGeographicGrants = "";
    },
    countryIncomeGroupFocusSelected() {
      this.countryIncomeGroupFocusType.map((data) => {
        if (data[1] == this.selectedCountryIncomeGroupFocus) {
          data.iscountryIncomeGroupFocusSelected == true
            ? (data.iscountryIncomeGroupFocusSelected = false)
            : (data.iscountryIncomeGroupFocusSelected = true);
        }
      });
      this.selectedCountryIncomeGroupFocus = "";
    },
    themesOfInterestSelected() {
      this.themesOfInterestType.map((data) => {
        if (data[1] == this.selectedThemesOfInterest) {
          data.isThemesOfInterestSelected == true
            ? (data.isThemesOfInterestSelected = false)
            : (data.isThemesOfInterestSelected = true);
        }
      });
      this.selectedThemesOfInterest = "";
    },
    investeeTypeRouteSelected() {
      this.investeeTypeRoutesToMarketType.map((data) => {
        if (data[1] == this.selectedInvesteeTypeRoute) {
          data.isInvesteeTypeRouteSelected == true
            ? (data.isInvesteeTypeRouteSelected = false)
            : (data.isInvesteeTypeRouteSelected = true);
        }
      });
      this.selectedThemesOfInterest = "";
    },
    ticketSizeRangeSelected() {
      this.ticketSizeRangeType.map((data) => {
        if (data[1] == this.selectedTicketSizeRange) {
          data.isTicketSizeRangeSelected == true
            ? (data.isTicketSizeRangeSelected = false)
            : (data.isTicketSizeRangeSelected = true);
        }
      });
      this.selectedTicketSizeRange = "";
    },
    // need to confirm about grants
    ticketSizeRangeGrantsSelected() {
      this.ticketSizeRangeGrantType.map((data) => {
        if (data[1] == this.selectedTicketSizeRangeGrants) {
          data.isTicketSizeRangeGrantsSelected == true
            ? (data.isTicketSizeRangeGrantsSelected = false)
            : (data.isTicketSizeRangeGrantsSelected = true);
        }
      });
      this.selectedTicketSizeRangeGrants = "";
    },
    productsOfferedSelected() {
      this.productsOfferedType.map((data) => {
        if (data[1] == this.selectedProductsOffered) {
          data.isProductsOfferedSelected == true
            ? (data.isProductsOfferedSelected = false)
            : (data.isProductsOfferedSelected = true);
        }
      });
      this.selectedProductsOffered = "";
    },
    ticketFlexibilitytoggle() {
      document.getElementById("ticketFlexibilityChecked").checked !=
        document.getElementById("ticketFlexibilityChecked").checked;
    },
    ticketFlexibilityGrantstoggle() {
      document.getElementById("ticketFlexibilityGrantsChecked").checked !=
        document.getElementById("ticketFlexibilityGrantsChecked").checked;
    },
    allowOthersToViewProfileToggle() {
      this.investeeData.consent =
        this.investeeData.consent == "Yes" ? "No" : "Yes";
    },
    handleFileUpload(event) {
      this.files = event.target.files;
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("type", "assessment");
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("files", file);
      }
      axios
        .post(env.apiUrl + "/other/fileUpload", formData, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.uploadedImageData = res.data.data;
          // this.uploadedImageData.forEach((data) => {
          //   this.investeeData.logo.push(data);
          // });
          this.investeeData.logo = [];
          this.investeeData.logo = this.uploadedImageData;
          await this.updateTwoXC();
        });
    },
  },
  async created() {
    this.$store.commit("isFreeState", true);
  },
  directives: {
    filterDate: {
      update(el) {
        el.value = new Date(el.value).toLocaleString().split(",")[0];
      },
    },
  },
};
</script>
<style scoped>
.toast {
  position: fixed;
  bottom: 1;
  right: 0;
  background: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}
.my-profile-button {
  max-width: 95%;
}
.my-profile-button button {
  float: right;
  background: #fff;
  color: #1b1b1b;
  border-color: #201747;
  border: 1px solid #1b1b1b;
  height: 36px;
  color: #1b1b1b;
  border-radius: 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  padding: 9px 11px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.my-profile-button button:hover {
  background: #201747;
  color: #fff;
}
.pointer-event-none {
  pointer-events: none;
}
.selected-class {
  background-color: green;
}
/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    width: 40px;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 50%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 4px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #0097d1;
    box-shadow: inset 0 0 0 30px #0097d1;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
  /* Switch Sizes */
  .toggleSwitch.large {
    width: 70px;
    height: 30px;
    top: 5px;
  }
  .toggleSwitch.large a {
    width: 17px;
    height: 17px;
    top: 1.8px;
  }
  .toggleSwitch.large > span {
    height: 25px;
    line-height: 35px;
  }
  .toggleSwitch.large input:checked ~ a {
    left: 36px;
  }
  .toggleSwitch.large > span span {
    font-size: 1.1em;
  }
  .toggleSwitch.large > span span:first-of-type {
    left: 50%;
  }
}

/*  End Toggle Switch  */
</style>
