/*
  This function will replace all the data from values in the text.


  Some exceptions:

    {{COUNTRY}}, will be replace by country name



  Todo:

  - Sources
  - normalise the values

*/
const format = (raw, country, rawValues) => {
  let text = raw;

  const values = {};
  for (var i = 0; i < rawValues.length; i++) {
    const key = rawValues[i].varName;
    values[key] = rawValues[i].value
  }

  values["COUNTRY"] = (country || {}).name;
  const keys = Object.keys(values);
  for (var j = 0; j < keys.length; j++) {
    const key = keys[j];
    if(values[key]){
      text = text.split('{{'+keys[j]+'}}').join(values[key]);
    }
  }

  return text;
}

export default {
  format,
}
