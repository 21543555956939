<!-- THIS PAGE IS NO LONGER USED. IF 2X CERTIFY IS RELEASED, DELETE THIS PAGE. -->

<template>
  <div class="static ecosystem-landing">
    <!-- <img
      src="@/assets/images/background-image.jpg"
      alt="background-image"
      class="w-full z-0 -mt-20"
    /> -->

    <!-- <div style="height: 8rem"></div> -->
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-32">
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <div class="PoweredbyTitleWrepper">
            <h1
              class="text-6xl tracking-tighter font-medium print:text-7xl mb-5"
              style="color: #201747; max-width: 880px"
            >
              <span>2X Certification and Basic 2X ESG Screen</span>
            </h1>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex mr-8">
              <span>Powered by:</span>
              <img
                src="@/assets/images/2X.png"
                alt="background-image"
                style="width: 80px"
              />
            </div>

            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>

      <!-- Content Wrepper -->

      <div class="ContentWrapper w-12/12">
        <!-- <iframe
          class="airtable-embed"
          :src="surveyUrl"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="750"
          style="background: transparent; border: 1px solid #ccc"
        ></iframe> -->
        <Survey
          :survey-data="surveyData"
          :survey-json="surveyJson"
          :user="user"
          :survey-id="surveyId"
          :answer-again="answerAgain"
          :id="userSurveyDataId"
          :isComplete="isComplete"
          :userType="entityChosen"
          @surveyComplete="surveyComplete"
        />
        <div
          class="w-12/12"
          style="
            background: transparent;
            border: 1px solid #ccc;
            /* border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-left: 1px solid #ccc; */
          "
        >
          <div style="height: 60px" class="flex items-center mr-6">
            <button
              class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
              style="margin-left: auto; margin-right: 0;"
              @click="previousModule()">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99996 3.33325L11.175 4.50825L6.52496 9.16659H16.6666V10.8333H6.52496L11.175 15.4916L9.99996 16.6666L3.33329 9.99992L9.99996 3.33325Z" fill="#fff"/>
              </svg>
              <span class="pr-1">Edit previous modules</span>
            </button>
          </div>
        </div>
      </div>

      <br />
    </div>
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      id="dealsPrivacy"
      v-if="feedbackModalOpen"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle m-auto">
              <h1
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Give us feedback!
              </h1>
              <button
                style="background-color: #fe5000; color: white !important"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center"
                @click="givesUsFeedback()"
              >
                Click here
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
//import {ref} from "vue";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
//import pageLoader from "@/components/page-loader.vue";
import googleCode from "@/google_translate_languages_code.json";
import jotFormCode from "@/jotForm_languages_code.json";
import Survey from "@/components/surveys/survey.vue";

export default {
  name: "twoxCertifyESG",
  data: function () {
    return {
      user: [],
      endofcreated: false,
      languagePref: "&language=en",
      displayNewSharedPopup: false,
      requestAdminModal: false,
      requestportfoliopermissionModal: false,
      sharedAssesmentData: [],
      certificationAssessData: [],
      certify2xUUID: "",
      surveyData: null,
      isComplete: false,
      surveyJson: {},
      surveyId: "2x-esg",
      answerAgain: false,
      userSurveyDataId: null,
      entityChosen: "",
      feedbackModalOpen: false,
    };
  },
  components: {
    Survey,
  },
  async created() {},
  methods: {
    async previousModule() {
        // this.current_module = 8;
        // this.timeToComplete = 5;
        this.$router.push({ path: '/2X-Challenge/2X-Challenge-Self-Assessment', query: { previous: true} });
    },
    givesUsFeedback() {
      window.open(
        "https://airtable.com/appXzBjEJbD3UkSil/pagyeAU9sz3w4i0VT",
        "_blank"
      );
      this.feedbackModalOpen = false;
    },
    surveyComplete() {
      setTimeout(() => {
        this.$router.push({ path: '/2X-Ecosystem/2X-Certification/PreliminaryResults' });
      }, "5000")
      this.feedbackModalOpen = true;
    },
    async fetchSurvey() {
      try {
        let userType;
        if (
          this.entityChosen == "start-up" ||
          this.entityChosen == "sme" ||
          this.entityChosen == "corporate"
        ) {
          userType = "company";
        }
        if (this.entityChosen == "fund-manager") {
          userType = "fund";
        }
        if (this.entityChosen == "Institutional investor") {
          userType = "investor";
        }
        if (this.entityChosen == "financial-institution") {
          userType = "financial";
        }
        const {
          data: { data },
          status,
        } = await axios.get(`${env.apiUrl}/surveys/2x-esg?tag=${userType}`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        if (status == 200) {
          this.surveyJson = data.json;
        }
      } catch (error) {
        console.log("Login error ", error);
      } finally {
        this.loading = false;
      }
    },
    mouseOver: function (index) {
      this.active = index;
    },
    getCertAssessment() {
      axios
        .get(env.apiUrl + "/certify2x/2x-certification-assessment-details", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.certificationAssessData = res.data.data.dbData;
          if (
            this.certificationAssessData &&
            this?.certificationAssessData?.genIntakeResponses
          ) {
            this.$store.commit(
              "setCertify2xUUID",
              res.data.data.atData.genIntake.recordIdForJotForm
            );
            this.certify2xUUID = this.$store.state.certify2xUUID;
          } else {
            this.$store.commit("setCertify2xUUID", Date.now());
            this.certify2xUUID = this.$store.state.certify2xUUID;
          }
          if (
            this.certificationAssessData?.certify2xESGSurvey ||
            this.certificationAirtableData?.esg
          ) {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
            });
          }
        });
    },
  },
  computed: {
    surveyUrl() {
      return "2x-esg";
    },
  },

  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    // await this.getCertAssessment();
    // For now getting entity type from session but will get this from API response
    this.entityChosen = this.$route.query.entity;
    this.user.surveyData.forEach(data => {
      if(data.surveyId == '2x-intake') {
        this.entityChosen = data.tag;
      }
    })
    if (this.user.surveyData.filter(e => e.surveyId === '2x-esg').length > 0) {
      this.$router.push({ path: '/2X-Ecosystem/2X-Certification/PreliminaryResults' });
    }

    //NEED TO ADD LOGIC TO CHECK FOR RECENTLY COMPLETED SURVEY!!!
    //If survey has already been completed, need to pull *existing* UUID and NOT generate new one
    // this.$store.commit("setCertify2xUUID",Date.now());
    // this.uuid = this.$store.state.certify2xUUID;

    // To check survey data is available
    if (this.user.surveyData) {
      this.surveyData = this.user.surveyData.find(
        (survey) =>
          survey.surveyId === this.surveyId && survey.tag === this.entityChosen
      );
    }

    // if survey has been answered already, mark isComplete as true
    if (this.surveyData) {
      this.userSurveyDataId = this.surveyData?._id;
      this.isComplete = this.surveyData?.isComplete;

      // transform surveyData from array to object with key values
      let surveyDataObject = {};
      this.surveyData.surveyResponse.forEach((survey) => {
        surveyDataObject[survey.questionId] = survey.answer;
      });
      this.surveyData = surveyDataObject;
    }

    await this.fetchSurvey();

    var googleCodeData = "";
    var jotFormCodeData = "";
    googleCode.code.forEach((data) => {
      if (data[this.user.languagePreference]) {
        googleCodeData = data;
      }
    });
    jotFormCode.code.forEach((data) => {
      if (
        googleCodeData &&
        Object.entries(googleCodeData)[0][1] === Object.entries(data)[0][1]
      ) {
        jotFormCodeData = data;
      }
    });
    if (jotFormCodeData) {
      this.languagePref = "&language=" + Object.entries(jotFormCodeData)[0][1];
    }

    // Automatically send user to full assessment once ESG is complete
    // window.addEventListener("message", (message) => {
    //   if (message.data.action) {
    //     if (
    //       message.data.action.includes("submission") &
    //       message.data.action.includes("completed")
    //     ) {
    //       this.$router.push({
    //         path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
    //       });
    //     }
    //   }
    // });

    // if (this.user.organization.subscription.price < 1) {
    //   this.paid = false;
    // } else {
    //   this.paid = true;
    // }

    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      // { name: "2X Ecosystem", url: "/2X-Ecosystem" },
      { name: "2X Certification", url: "/2X-Ecosystem/2X-Certification" },
      { name: "Final submission", url: "/2X-Ecosystem/2X-Certification/ESG" },
    ]);
  },
};
</script>

<style lang="scss" scoped>
.ContentWrapper {
  margin-top: 10px;

  .SelectionBlock {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 40px 20px;
    max-width: 1000px;

    .column-wrapper {
      flex-direction: column;
    }
  }

  .next-button {
    font-weight: bold;
    color: white;
    background: #191b45;
  }

  p {
    color: #717686;
  }
}

.OverviewTitle {
  display: flex;
  align-items: center;
  margin-top: 10px;

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #1b1b1b;
    margin-right: 17px;
    position: relative;
  }

  span {
    display: inline-block;
    border-bottom: 2px solid #1b1b1b;
    width: 75%;
    height: 2px;
  }
}

.learn_more_button {
  cursor: pointer;
}

.cross_Icon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #f2f2f2;
  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 170px;
    padding: 170px 0 0;
    margin-top: 20px;

    .PoweredbyTitleWrepper {
      max-width: 880px;
      widows: 100%;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 45px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .Modal_Readmorestyle {
    padding: 30px;
    position: relative;
    display: flex;
    .ModalInnerStyle {
      overflow: auto;
      background: #fff;
      h1 {
        font-weight: bold;
        line-height: 16px;
        color: #1b1b1b;
        text-align: center;
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        text-align: center;
        line-height: 18px;
        color: #1b1b1b;
      }
      button {
        margin-top: 30px;
        order: none;
        padding: 15px 32px;
        font-size: 16px;
      }
    }
    .cross_Icon {
      position: absolute;
      top: 15px;
      right: 25px;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin: 0 !important;
        cursor: pointer;
      }
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

input {
  margin: 6px;
}

#SPAN_1:hover {
  color: #40a9ff;
}
</style>
