import { render, staticRenderFns } from "./set_password.vue?vue&type=template&id=29553c4e&scoped=true"
import script from "./set_password.vue?vue&type=script&lang=js"
export * from "./set_password.vue?vue&type=script&lang=js"
import style0 from "./set_password.vue?vue&type=style&index=0&id=29553c4e&prod&lang=css"
import style1 from "./set_password.vue?vue&type=style&index=1&id=29553c4e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29553c4e",
  null
  
)

export default component.exports