<template>
  <div class="flex flex-row w-full bg-gray-100 overflow-hidden">


    <sidebar></sidebar>

    <div class="flex flex-col px-5 pb-5 main-contentwrap">
      <div style="overflow-y: auto;">
        <div class="py-2 flex flex-row items-center justify-between w-full sm:px-6 lg:px-8 mb-6">
          <h1 class="text-2xl text-black font-medium leading-7">
            {{results.length}} members Requested to join organizations <br/>
          </h1>
          <div v-if="isadmin" class="flex flex-row items-center justify-end" >
            <input type="text" class="border-2 border-gray-200 rounded-lg px-4 py-2 text-sm mr-2" placeholder="Search a user" v-model="q">
            <!-- <button v-if="results.length < organization.subscription.users && isadmin" @click="add()" class="text-white mr-5 py-2 px-4 rounded-lg text-sm float-right" style="background-color: rgb(32, 23, 71); width: 25%;">
              Invite
            </button> -->
          </div>
        </div>

        <div class="py-2 flex flex-row items-center justify-end w-full sm:px-6 lg:px-8 mb-6">
          <div v-if="isadmin" class="flex flex-row items-center justify-end" >
          <span style="width: 200px;">select status</span>
            
            <select v-model="status" @change="getUserList($event)" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width:100%">
              <option value="pending">Pending</option>
              <option value="accept">Accept</option>
              <option value="reject">Reject</option>
            </select>
          </div>
        </div>
       

        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="divide-y divide-gray-200  bg-white" style="min-width: 1100px">
              <thead>
                <tr>
                  <th v-for="header in headers" v-bind:key="header.id" class="px-4 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {{header.name}}
                  </th>
                  <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" v-if="isadmin && status == 'pending'">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="row in results" v-bind:key="row._id">
                  <td class="px-6 py-4 whitespace-no-wrap" v-for="header in headers" v-bind:key="row._id + '_' + header.id">

                   <!-- default field -->
                   <div v-if="header.type == 'text'">
                      <div class="text-sm leading-5 text-gray-900">{{row[header.id]}}</div>
                    </div>
                    
                    <div v-if="header.type == 'boolean'">
                      <div class="text-sm leading-5 text-gray-900">{{ row[header.id] ? "Yes" : "No" }}</div>
                    </div>

                    <div v-if="header.type == 'image'">
                      <div class="flex-shrink-0 h-10 w-10 overflow-hidden rounded-full">
                        <img class="" :src="row[header.id]" alt="">
                      </div>
                    </div>

                    <div v-if="header.type == 'date'">
                      <div class="text-sm leading-5 text-gray-900">  {{moment(row[header.id]).format('DD MMM YYYY HH:mm')}}</div>
                    </div>
                 </td>

                  <!-- Options -->
                  <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex" v-if="isadmin && status == 'pending'"> 
                    <button  @click="updateStatus(row,'accept')" class="text-white mr-1 py-2 px-3 rounded-lg float-right text-sm" style="background-color:green;">Accept</button>
                    <button  @click="updateStatus(row,'reject')" class="text-white mr-1 py-2 px-3 rounded-lg bg-red-600 float-right text-sm">Reject</button>
                  </td>
                </tr>

                <!-- More rows... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


   
    

    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="askConfirmation">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Are you sure?
                </h3>
                <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                    Are you sure you want to <b><span v-if="requestStatus == 'accept' "> Accept </span> <span v-else> Reject </span></b> request of <b>{{object.firstname}} {{object.lastname}}</b> for join organization.
                </p>
                </div>
            </div>
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button @click="confirmed()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Confirm
            </button>
            </span>
            <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button @click="canceled()" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Cancel
            </button>
            </span>
        </div>
        </div>
    </div>
    </div>





  </div>
</template>
<style>
  .main-contentwrap {
    padding-top: 100px;
    width: calc(100% - 258px);
  }
</style>
<script>
import env from '@/../env.js';
import Cookies from '@/helpers/cookies'
import sidebar from '@/components/sidebar.vue'
import auth from '@/helpers/auth';


const axios = require('axios');


export default {
  name: 'godpage',
  data: function () {
    return {
      headers: [],
      searchFields: [],
      editableFields: [],
      user: this.$store.state.user,
      data: [],
      popup: false,
      askConfirmation: false,
      object: {
        email: '',
        firstname: '',
        lastname: '',
      },
      q: '',
      editUser:false,
      invite: false,
      id: 'users',
      isadmin:false,
      status:'pending',
      requestStatus:false,
      // possible types are: text, textarea, select (add values:[]), boolean
      fields: [
        {'id': 'picture', name: 'Picture', header: true, searchable: false, editable: false, type: 'image' },
        {'id': 'firstname', name: 'Firstname', header: true, searchable: true, editable: false, type: 'text', placeholder: 'John' },
        {'id': 'lastname', name: 'Lastname', header: true, searchable: true, editable: false, type: 'text', placeholder: 'Doe' },
        {'id': 'email', name: 'Email', header: true, searchable: false, editable: false, type: 'text', placeholder: 'John@example.com' },
        {'id': 'lastModifiedByname', name: 'Modified By', header: true, searchable: false, editable: false, type: 'text'},
        {'id': 'createdAt', name: 'Date of Invitation', header: true, searchable: false, editable: false, type: 'date'},
        {'id': 'lastModified', name: 'Modified At', header: true, searchable: false, editable: false, type: 'date'},
     ],
    }
  },
  components: {
      sidebar,
  },
  methods: {
    updateStatus: function(item,requestStatus){
      this.object = item;
      this.askConfirmation = true;
      this.requestStatus = requestStatus;
    },
    canceled: function(){
      this.object = {}
      this.askConfirmation = false;
    },
    confirmed: function(){
      const _self = this;
      axios.post(env.apiUrl+'/organizations/users/request/update', {
        id: _self.object._id,
        status: _self.requestStatus},{
        headers: {
          'Authorization': 'Bearer '+Cookies.get('session')
        }  
    }).then(function () {
        _self.data.splice(_self.data.indexOf(_self.object), 1);
        _self.object = {};
        _self.askConfirmation = false;
        alert('Request Updated Successfully')
      });
    },
    update: function(){
      const _self = this;
      axios.put(env.apiUrl+'/'+this.id+'/'+this.object._id, this.object, {
        headers: {
          'Authorization': 'Bearer '+Cookies.get('session')
        }
      }).then(function () {
        _self.editUser = false;
      });
    },
    add: function(){
      this.object = {};
      this.invite = true;
    },
    edit: function(obj){
      this.object = obj;
      this.editUser = true;
    },
    buildTable:async function(){
      this.headers= [];
      this.searchFields= [];
      this.editableFields= [];
      
      for (var i = 0; i < this.fields.length; i++) {
        if(this.fields[i].searchable){
        this.searchFields.push(this.fields[i].id)
        }

        if(this.fields[i].header){
        if(this.status == 'pending' &&  (this.fields[i].id == "lastModifiedByname" || this.fields[i].id == "lastModified")){  
          continue;       
        }
        else
        {
          this.headers.push(this.fields[i]);        
        }

        }

        if(this.fields[i].editable){
        this.editableFields.push(this.fields[i])
        }
      }

      this.data = await this.load();
    },
    getUserList:async function(event){
      this.status = event.target.value;
      this.buildTable();
    },
    load: function(){
      const _self = this;
      return new Promise(function(resolve){
        axios.get(env.apiUrl+'/organizations/users/'+ _self.status +'/request', {
          headers: {
            'Authorization': 'Bearer '+Cookies.get('session')
          }
        }).then(function (res) {
          resolve(res.data.data);
        });
      })
    }
  },
  computed: {
    results: function(){
      const searchResults = []
      if(this.q){
        for (var i = 0; i < this.data.length; i++) {
            for (var f = 0; f < this.searchFields.length; f++) {
              const field = this.searchFields[f];
              if(searchResults.indexOf(this.data[i]) == -1 && this.data[i] && this.data[i][field] && this.data[i][field].toLowerCase().indexOf(this.q.toLowerCase()) > -1){
                searchResults.push(this.data[i]);
                //break;
              }
            }
        }
        return searchResults;
      } else {
        return this.data;
      }
    }
  },
  async mounted() {

    this.user = await auth.me().catch(() => {});
    this.organization = this.user.organization;
    
    this.buildTable();
    
    if(this.user.admin){
        this.isadmin = true;
    }
    this.$store.commit('downloadable', false);

    this.$store.commit('setBreadcrumbs', [
      {'name': 'Settings', 'url': '/settings/account'},
      {'name': 'Users Request', 'url': '/settings/users/request'},
    ])


  }

}
</script>
