<template>
  <div class="gap-4">
    <select @change="emitSelectedId" v-model="teamId" class="mr-2 rounded-lg">
      <option value="" disabled>Select an organization</option>
      <option v-for="org in organizations" :key="org._id" :value="org._id">
        {{ org.organization.name }}
      </option>
    </select>
    <select @change="emitSelectedId" v-model="type" class="m-2 rounded-lg">
      <option value="" disabled>Select a type</option>
      <option v-for="type in typeOptions" :key="type" :value="type">
        {{ type }}
      </option>
    </select>
    <button @click="resetFilter()"
      class="px-4 py-2 text-base font-medium text-gray-700 rounded-md border border-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      :class="{ 'border-[#201747] bg-[#201747] border-2 opacity-80 !text-white font-bold': type || teamId }"
      :disabled="!type && !teamId"
      >
      Clear Filters
    </button>
  </div>
</template>
<script>
export default {
  name: 'TwoxOrganizationSelect',
  props: {
    organizations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      teamId: '',
      type: '',
      typeOptions: {
        DATA_SHARING_AGREEMENT: 'Data Sharing Agreement',
        BULK_UPLOAD: 'Bulk Upload'
      }
    }
  },
  methods: {
    emitSelectedId() {
      this.$emit('organization-selected', {
        teamId: this.teamId,
        type: this.type
      })
    },
    resetFilter() {
      this.teamId = '',
        this.type = ''
      this.$emit('organization-selected', {
        teamId: '',
        type: '',
      })
    }
  },
}
</script>
