<script>
import { Pie } from "vue-chartjs";

export default {
  name: "pieChart",
  extends: Pie,
  props: ["totalValue", "sectorLabels", "mainTitle"],
  data: function () {
    return {
      max: 0,
      beginAtZero: true,
      labels: [],
      datasets: [],
    };
  },
  mounted() {
    this.renderMyChart();
  },
  methods: {
    renderMyChart() {
      this.labels = this.sectorLabels;
      this.datasets = [
        {
          label: ["Investements"],
          data: this.totalValue,
          backgroundColor: ["#FE5000", "#2BD4C4"],
          hoverOffset: 4,
        },
      ];
      let total = 0;
      this.totalValue.filter((data) => {
        total = total + data;
      });
      this.max = Math.floor(total);
      const _self = this;

      this.renderChart(
        {
          labels: this.labels,
          datasets: this.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          fontFamily: "basier",
          legend: {
            position: "bottom",
            display: true,
            labels: {
              fontSize: 13,
              fontFamily: "basier",
            },
          },
          tooltips: {
            titleFontFamily: "basier",
            bodyFontFamily: "basier",
            callbacks: {
              title: function (tooltipItems) {
                return _self.labels[tooltipItems[0].index];
              },
              label: function (tooltipItems) {
                let numFormat = new Intl.NumberFormat("en-US");
                let val = _self.datasets[0].data;
                let percent = [];
                let percent1 = Math.round((val[0] / (val[0] + val[1])) * 100);
                percent.push(percent1);
                let percent2 = Math.round((val[1] / (val[0] + val[1])) * 100);
                percent.push(percent2);

                return (
                  "$" +
                  numFormat.format(_self.datasets[0].data[tooltipItems.index]) +
                  ", " +
                  numFormat.format(percent[tooltipItems.index]) +
                  "%"
                );
              },
            },
          },
        }
      );
    },
  },
  watch: {
    totalValue: function () {
      this.renderMyChart();
    },
  },
};
</script>