<template>
  <div v-if="status === 'completed' && !noValue" class="flex items-center">
    <div
      class="flex text-white text-sm items-center px-3 py-2 font-bold my-5"
      style="border-radius: 20px; background-color: #4dc9be"
    >
      <svg
        width="13"
        height="10"
        viewBox="0 0 13 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.13911 8.06542L1.17578 5.21493L0.166687 6.17876L4.13911 9.99992L12.6667 1.79708L11.6647 0.833252L4.13911 8.06542Z"
          fill="white"
        />
      </svg>
      <span class="ml-2">{{ elegible ? altText : text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElegibleBadge",
  props: {
    status: String,
    elegible: Boolean,
    text: String,
    altText: String,
    noValue: Boolean,
  },
};
</script>
