<template>
  <div class="w-8 h-8 flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" class=" text-white rounded-full w-11 h-11" :style="{ backgroundColor: color }"
    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
  </svg> 
  </div>
  
</template>

<script>

export default {
  name: 'CheckIcon',
  props: {
    color: String,
  },
};
</script>
