<template>
    <div class="static">

        <transition name="fadeIn" mode="out-in">
        <div id="notLoaded" v-if="!domains.data" key="loading" >
            <page-loader></page-loader>
        </div>
        <div id="loaded" v-if="domains.data" key="content">
            <div class="flex flex-col items-center justify-center mt-top-5 w-full print:hidden">
              <div class="container">

                  <div class="flex flex-row justify-center items-center mt-3">
                      <div class="flex flex-col item-center justify-center">
                        <div class="flex flex-row items-center justify-center print:flex-col">
                            <img class="mr-5 h-16 print:h-auto print:w-64" v-if="countryData.countries.length == 1" :src="country.flagUrl"/>
                            <h1 v-if="sector == false && (countryData.countries.length == 1)" class="text-6xl tracking-tighter font-medium text-center print:text-7xl" >{{country.humanName }}</h1>
                            <h1 v-else class="text-4xl tracking-tighter font-medium text-center print:text-7xl">{{sector.name }} <span v-if="countryData.countries.length == 1">in {{country.humanName }}</span></h1>
                        </div>
                          <p class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center" v-if="countryData.countries.length == 1">
                              Region: {{country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' :country.region }} | Income Group: {{toTitle(country.income || '')}}
                              <span v-if="sector && sector.id">| Sector: {{sector.name }}</span>
                          </p>
                      </div>
                  </div>
              </div>
            </div>
            <section class="w-full container mx-auto mt-20 text-center px-4 lg:px-0">
                <h1 class="text-4xl print:text-4xl tracking-tighter font-semibold" :style="'color:'+ env.brandingColors.navy">All domains</h1>
            </section>
            <section class="w-full container mx-auto mt-20 mb-20 px-4 lg:px-0" style="max-width: 960px">
                <div v-for="domain in domains.data" v-bind:key="domain._id" v-scroll-reveal>
                    <intersection :country="country" :domain="domain" :countryData="countryData" :iso2="$route.params.iso2"></intersection>
                </div>
            </section>
<!--            <section class="w-full container mx-auto mt-32 mb-32 print:hidden px-4 lg:px-0 flex flex-col items-start justify-end gap-4">
                <router-link :to="'/reports/'+country.ISO2+'/'+$route.params.sector+'/improvements'" :style="'background-color:' + env.brandingColors.navy"
                             class="flex-grow-0 px-3 py-2 text-sm hover:bg-blue-400 font-semibold rounded-lg focus:outline-none focus:shadow-outline-indigo text-white transition duration-200 shadow border border-indigo-200 hover:border-indigo-400 flex flex-row items-center justify-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-arrow-left">
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                    </svg>
                    <p class="font-medium capitalize mr-3">Back to areas of improvements</p>
                </router-link>
            </section>-->
            <section class="w-full container mx-auto mt-32 mb-32 px-4 lg:px-0">
                <div class="flex flex-col mt-10 justify-start">
                    <p class="text-xs text-gray-500">
                        Data Use and Citation: Equilo grants full permission to its users to copy and paste information that appears in the dashboard for their own internal use, directly citing the original data sources and citing Equilo for analysis and scoring. Please request permission from Equilo before utilizing our content for external publications.
                    </p>
                    <p class="text-xs text-gray-500 mt-5 mb-1">Sources on this page:<br/>
                    <ol class="list-decimal mx-5">
                      <li v-for="(citation, c) in allBibliographyData" v-bind:key="citation.id+c">{{((citation || {}).text || '').toString().replace('n.d.', citation.year + '. ')}}
                        {{ citation && citation.text ? "" : "Source not found "}}
                        (<a target="_blank" :href="citation.url" style="word-wrap: break-word;">{{citation.url}}</a>)</li>

                    </ol>
                    </p>
                    <p class="text-xs text-gray-500 mt-5">
                        Equilo uses the TAAP framework, which stands for Transforming Agency, Access, and Power.
                        Comprised of six domains, the TAAP framework provides a structure to analyze and amplify the
                        voices, opportunities, and dignity of all people, especially those from socially excluded
                        groups. More information about this approach can be found at taapinclusion.org. Equilo Dash uses
                        15 themes that cut across two or more of the TAAP domains, for a total of 32 unique TAAP
                        domain/theme intersections for analysis and scoring. Each gender equality score is based on a
                        specific set of indicators within a given intersection.
                    </p>
                </div>
            </section>
        </div>
        </transition>
    </div>
</template>

<script>
    import axios from 'axios';
    import env from '@/../env.js';
    import Cookies from '@/helpers/cookies'
    import pageLoader from '@/components/page-loader.vue';
    import intersection from '@/components/domain-intersection';
    import auth from '@/helpers/auth';


    export default {
      name: 'country-domains',
        components: {
            intersection,
            pageLoader
        },
        data: function () {
            return {
                country: {},
                domains: [],
                env,
                sector: { name: '' },
                countryData: [],
                allBibliographyData: [],
                sourceData: [],
                json_data: [],
            };
        },
    methods: {
        generateBibliographyCSV() {
            this.allBibliographyData.forEach((data, index) => {
                if (data.text && data.url && data.url != 'Missing' && data.text != 'Missing') {
                    let arr = [];
                    arr.push(index + 1);
                    arr.push(data.text);
                    arr.push(data.url);
                    this.json_data.push(arr)
                } else {
                    let arr = [];
                    arr.push(index + 1);
                    arr.push("Source not found ()");
                    this.json_data.push(arr)
                }
            })
        },
        async getBibliographyData() {
            if (this.bibliographySourceIds && this.bibliographySourceIds.length) {
                    let arr = [];
                    this.bibliographySourceIds.forEach(async (data) => {
                        let val = data.tag.replace(/[[\]]/g, '');
                        arr.push(val);
                    })
                    await this.getSource(arr);
                }
            },
        async getSource(sourceId) {
                let body = { "ids": sourceId };
                return axios.post(env.apiUrl + "/sources/all-citation", body, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(async (res) => {
                    this.sourceData = res.data;
                    // let item = { "index": index, "data": this.sourceData };
                    this.sourceData = res.data;
                    this.allBibliographyData = this.sourceData;
                    // if (this.bibliographySourceIds.length == this.allBibliographyData.length) {
                    await this.generateBibliographyCSV();
                    // }
                })
            },
            getCountry: function () {
                const _self = this;
                const isoCodes = this.$route.params.iso2.split(',');
                return new Promise(function (resolve) {
                    let iso = { isoCodes }
                    axios.post(env.apiUrl +
                        "/countries" +
                        // _self.$route.params.iso2 +
                        "/sectors/" +
                        _self.$route.params.sector, iso,{
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function (res) {
                        resolve(res.data.data);
                    });
                });
            },
            getSector: function () {
                const _self = this;
                return new Promise(function (resolve) {

                  // Validate if you want general country information (count/base) or a specific sector
                  if(['BASE', 'country'].indexOf(_self.$route.params.sector) > -1){
                    resolve(false);
                  } else {
                    axios.get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        },
                        params: { iso : _self.$route.params.iso2 }
                    }).then(function (res) {
                      resolve(res.data.data)
                    });
                  }
                })
            },
            getDomains: function () {
                const _self = this;
                return new Promise(function (resolve) {
                    const url = env.apiUrl + '/taaps'
                    axios.get(url, {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session'),
                            'sector' : _self.$route.params.sector == 'country' ? '' : _self.$route.params.sector
                        },
                        params: { iso : _self.$route.params.iso2 }
                    }).then(function (res) {
                        resolve(res.data);
                    });
                });
            },
            toTitle(str) {
                return str.replace(/\w\S*/g, function(txt){
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            },
        },
    computed: {
        bibliographySourceIds() {
            return this.$store.state.bibliographyData;
        },
          citations() {
            const compare = ( a, b ) => {
              if ( a.rank < b.rank ){
                return -1;
              }
              if ( a.rank > b.rank ){
                return 1;
              }
              return 0;
            }
            const x = this.$store.state.citations;
          return x.sort(compare)
          },
        },
  async created() {
            // validate if you have access to this country.
            this.user = await auth.me().catch(() => { });
            this.$store.dispatch("emptyBibliographyData");
            if (this.$route.params.iso2 != 'EQ' && !this.user.organization.accessGesi ||  document.location.href.indexOf('print=') > -1){
              this.$router.push({ name: 'notAllowed' })
            }
            this.$store.commit('resetCitations');
            this.countryData = await this.getCountry();
            this.country = this.countryData.countries[0];
            this.domains = await this.getDomains();
            this.sector = await this.getSector(); // lets do an await before rendering other items.

            this.$store.commit('downloadable', true);
            this.$store.commit('setBreadcrumbs', [
                {'name': 'Countries', 'url': '/'},
                {
                    name: this.$route.params.iso2.split(",").length == 1 ? (this.country.humanName || this.country.name) : "Multiple Countries",
                    url: "/gesi-analysis/" +
                    this.$route.params.iso2 +
                    "/choose-sector"
                },
                {
                    name: (this.sector || { name: "country" }).name,
                    url:
                    "/gesi-analysis/" + this.$route.params.iso2 +
                    "/" + this.$route.params.sector + "/snapshot",
                },
                {'name': 'Domains', 'url': '/reports/' + this.$route.params.iso2+'/'+this.$route.params.sector + '/domains'},
            ]);
            let allCountry = [];
            this.countryData.countries.forEach(data => {
                allCountry.push(data.humanName);
            })
            this.$gtag.event("sectors", {
                'domain_name': "",
                'theme_name': "",
                'page_name': this.$route.name,
                'userId': this.user._id,
                "sector_name": this.sector.name,
                "country_name": allCountry,
                "team_id": this.user.organization._id,
                "organizationName": this.user.organization.name,
            })
            this.$gtag.event("countries", {
                'domain_name': "",
                'theme_name': "",
                'page_name': this.$route.name,
                'userId': this.user._id,
                "sector_name": this.sector.name,
                "country_name": allCountry,
                "team_id": this.user.organization._id,
                "organizationName": this.user.organization.name,
            })
            setTimeout(async () => {
                await this.getBibliographyData();
                let data = { "data": this.json_data, "country": allCountry, "sector": this.sector.name }
                await this.$store.commit("setBibliographyData", data);
            }, 2000);
    },
    beforeDestroy() {
        this.$store.commit("setBibliographyData", []);
    }
    };
</script>