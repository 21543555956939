<template>
    <div class="static">
        <img src="@/assets/images/background-image.jpg" alt="background-image" class="w-full z-0 -mt-20">
        <div class="w-full container mx-auto -mt-20 px-4 mb-20 lg:px-0">

            <div class="flex flex-row mt-32 lg:mt-0 justify-center">
                <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
                    <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl" style="color: #201747;">Welcome to Equilo!</h1>
                    <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
                        <p class="leading-7 font-medium gap-10 text-justify leading-7 font-medium text-gray-600">
                            Explore the charts below to understand gender equality gaps and opportunities across countries, regions and income groups 
                            for all global countries. You can also check out the free demo of the breadth and depth of the gender 
                            equality and social inclusions analysis Equilo provides by viewing the demo of the fictitious country of 
                            <router-link style="color: #FE5000; text-decoration: underline;" to="/reports/EQ/AGRI_GENERL">Equiloland</router-link>
                            for the agriculture sector.
                            <br/><br/>
                            Do you want more information about the underlying indicators, data, 
                            or do you want country- and sector-specific analysis? Upgrade your account now for access to more analysis and data!
                            <a href="https://www.equilo.io/countries-sectors" target="_blank" style="color: #FE5000 !important; text-decoration: underline;">Check out what is available</a> and 
                            <router-link style="color: #FE5000; text-decoration: underline;" :to="gesiIdUrl">select a subscription that's right for you.</router-link>
                            <br/><br/>
                            Don't see what you need? Contact us at <a style="color: #FE5000; text-decoration: underline;" href="mailto:hello@equilo.io">hello@equilo.io</a> for customized subscriptions, to answer questions, or to schedule a live demo.
                        </p>
                    </div>

                </div>
            </div>

            <div class="navsticky" style="float: left;">
                <span>Scroll to...</span>
                <ol>
                    <li>
                        <button
                        @click="scrollTo('HeatMap')"
                        id="SectionOverview1"
                        class="buttonlink"
                        style="margin-left:-10px;"
                        >
                        <span>Heat Map</span>
                        </button>
                    </li>
                    
                    <li>
                        <button
                        @click="scrollTo('SpiderChart')"
                        id="SectionIndices1"
                        class="buttonlink"
                        style="margin-left:-10px;"
                        >
                        <span>Snapshot</span>
                        </button>
                    </li>
                    <li>
                        <button
                        @click="scrollTo('DemoVideo')"
                        id="SectionChart1"
                        class="buttonlink"
                        style="margin-left:-10px;"
                        >
                        <span>Demo Video</span>
                        </button>
                    </li>
                </ol>
                <br>
                <span>Go to...</span>
                <ol>
                    <li>
                        <router-link
                        :to="gesiIdUrl"
                        class="buttonlink"
                        style="margin-left:-10px;"
                        ><span>Subscribe!</span></router-link
                        >
                    </li>
                    <li>
                        <router-link
                        to='/reports/EQ/AGRI_GENERL'
                        class="buttonlink"
                        style="margin-left:-10px;"
                        data-tip="Equiloland is a fictitious country for demo purposes only."
                        ><span>View Equiloland</span></router-link
                        >
                    </li>
                </ol>
            </div>

            <div id="HeatMap"></div>
            <div class="flex flex-row mt-32 items-center justify-center">
                <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
                
                    <div class="text-4xl print:text-4xl tracking-tighter font-medium flex flex-row items-center justify-center">
                        <h1 class="font-medium">Gender Equality Heat Map</h1>
                    </div>
                
                    <heatmap />

                    <div class="flex flex-col mt-20 mx-auto" style="max-width: 960px">
                        <p class="leading-7 gap-10 text-justify leading-7 text-gray-600" style="font-size: 14px; line-height: 1.5;">
                            Use Equilo's global gender equality heat map to explore where there are strengths and weaknesses across all global countries. This is a tool to help users to:
                            <ol class="ml-10">
                                <li>1) Target countries for investment in areas with relative challenges or strengths</li>
                                <li>2) Develop projects to target geographies for specific development priority focus</li>
                                <li>3) Benchmark countries with their peers for learning and advocacy purposes</li>
                            </ol>
                            <br/>
                            The map above displays the gender equality score for each intersection of theme and domain. A score of 1 (green) indicates perfect gender equality, while a score of 0 (orange) indicates complete gender inequality. Orange indicates a challenge, while green indicates a strength. Any gender equality score above 1 indicates gender inequality to the advantage of women.
                            <br/><br/>
                            <em><b>Select BOTH a domain and a thematic area in the drop-down menus above to view gender equality scores for that intersection.</b> You may first select a domain and then a theme, or vice versa. <b>If you what to explore a new domain or theme, click "Reset" to clear your selections</b> and view the full menu of domain and theme options.</em>
                        </p>
                    </div>

                </div>
            </div>

            <div id="SpiderChart"></div>
            <div class="flex flex-row mt-32 items-center justify-center">
                <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
                
                    <div class="text-4xl print:text-4xl tracking-tighter font-medium flex flex-row items-center justify-center">
                        <h1 class="font-medium">Gender Analysis Snapshot by Region & Income Group</h1>
                    </div>

                    

                    <div class="relative lg:container md:w-full lg:px-0 mx-auto mt-0 mb-20 print:mt-0 flex flex-row items-center justify-center" v-scroll-reveal="{duration: 200, afterReveal: show }" id="chart">
                        <div class="z-20 relative flex items-center justify-center stupid-container" v-if="appearItems.indexOf('chart') !== -1" :key="1">
                            <spiderchart category='region' :iso='country.ISO2' :paddingL='20' :paddingR='20' class="z-10 overflow-visible" style="position: relative; height:100%; width: 100%;" ></spiderchart>
                                <template v-for="(color, taab) in taabs">
                                    <!--ACCESS-->
                                    <router-link to="/" exact event="" v-if="color === env.brandingColors.orange" class="absolute hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden" style="right: -10%; top: 25%;" id="2uur" v-bind:key="taab.name" :style="'cursor: pointer; background-color:'+color+';color: white'">
                                    <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color+';color: white'"></div>-->
                                        {{taab}}
                                    </router-link>

                                    <!--LAW-->
                                    <router-link to="/" exact event="" v-if="color === env.brandingColors.navy" class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold lg:mb-0 -mb-16 z-50 print:hidden" id="8uur" style="left: -15%; bottom:20%" v-bind:key="taab.name" :style="'cursor: pointer; background-color:'+color+';color: white'">
                                        <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color   +';color: white'" ></div>-->
                                        {{taab}}
                                    </router-link>

                                    <!--POWER-->
                                    <router-link to="/" exact event="" v-if="color === '#F5D10F'" class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold -mt-6 lg:mt-0 print:hidden" id="10uur" style="left: -23%; top:35%" v-bind:key="taab.name" :style="'cursor: pointer; background-color:'+color+';color: white'">
                                    <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color+';color: white'"></div>-->
                                        {{taab}}
                                    </router-link>

                                    <!--knowledge-->
                                    <router-link to="/" exact event="" v-if="color === env.brandingColors.teal" class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden" id="12uur" style="bottom:-5%" v-bind:key="taab.name" :style="'cursor: pointer; background-color:'+color+';color: white'">
                                        <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color   +';color: white'"></div>-->
                                        {{taab}}
                                    </router-link>

                                    <!--human-->
                                    <router-link to="/" exact event="" v-if="color === env.brandingColors.blue" class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold mb-10 z-50 print:hidden" id="9uur" style="right: -20%; top: 60%;" v-bind:key="taab.name" :style="'cursor: pointer; background-color:'+color+';color: white'">
                                    <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color   +';color: white'"></div>-->
                                        {{taab}}
                                    </router-link>

                                    <!--roles-->
                                    <router-link to="/" exact event="" v-if="color == '#666666'" class="absolute  hidden lg:flex flex-row items-center justify-start px-4 py-2 rounded-full text-sm font-bold z-50 print:hidden" id="11uur" style="left: 5%; top: 20%;" v-bind:key="taab.name" :style="'cursor: pointer; background-color:'+env.brandingColors.gray+'; color: white'">
                                        <!--<div class="w-3 h-3 mr-2 rounded-full" :style="'cursor: pointer; background-color:'+color+';color: white'"></div>-->
                                        {{taab}}
                                    </router-link>
                                </template>
                        </div>
                    </div>

                    <div class="flex flex-col mt-20 mx-auto" style="max-width: 960px">
                        <p class="text-justify leading-7 text-gray-600" style="font-size: 14px; line-height: 1.5;">
                            Use Equilo's global gender analysis snapshot to explore gender equality strengths and weaknesses across 
                            regions and income groups for all global countries. This is a tool to help users to: 
                            <ol class="ml-10">
                                <li>1) Understand regional or income group gender equality challenges and opportunities for programming or advocacy efforts</li>
                                <li>2) Benchmark of a particular country within a region or income group and benchmarking between regions and income groups</li>
                            </ol>
                            <br/>
                            This diagram displays gender equality scores across 15 themes and 
                                <span class="triggerElement hoverTrigger">6 TAAP domains.
                                    <span class="hoverMethodology w-customWidth absolutetop-0 right-0 bg-white rounded-lg shadow-xl border w-auto text-sm border-gray-200">
                                        <methodology />
                                    </span>
                                </span>
                            Each point comprises indicators measuring both gender parity and women's empowerment that represent a 
                            unique intersection of a theme and domain. A score of 1 (outer circle) indicates perfect gender equality, 
                            while a score of 0 (inner circle) indicates complete gender inequality. Any gender equality score above 1 
                            indicates gender inequality to the advantage of women.
                            <br/><br/>
                            <em>Click the legend items at the bottom of each chart to show or hide different regions or income groups.</em>
                        </p>
                    </div>

                </div>
            </div>

            

            <div id="DemoVideo"></div>
            <div class="flex flex-row mt-32 items-center justify-center">
                <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
                
                    <div class="text-4xl print:text-4xl tracking-tighter font-medium flex flex-row items-center justify-center">
                        <h1 class="font-medium">Watch our Demo Video</h1>
                    </div>
                
                    <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
                        <p class="leading-7 font-medium gap-10 text-justify leading-7 font-medium text-gray-600">
                                Watch our demo video below to see how Equilo can provide deep-dive information for 132 low and 
                                middle-income countries globally across 18 sectors, with the ability to explore the indicators, 
                                deep-dive into domain and thematic areas, and get tips and ideas on what works to address gaps. 
                                <router-link style="color: #201747;" :to="gesiIdUrl">Upgrade to access this content.</router-link>
                        </p>
                    </div>

                    <div class="mt-10 mb-10">
                      <iframe title="vimeo-player" src="https://player.vimeo.com/video/500414524?title=0&byline=0&portrait=0&sidedock=0" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <a href="https://www.equilo.io/countries-sectors" target="_blank" style="background-color: #FE5000; color: white !important; width: 300px;" class="text-white px-3 py-2 text-xs gap-1 font-semibold rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center">
                        <span>View Country and Sector Options</span>
                        <svg class="w-5 h-5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!--Generated by IJSVG (https://github.com/iconjar/IJSVG)--><g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round"><path d="M16.95,7.05l-9.9,9.9"></path><path d="M10,7l6.95,0.049l0.05,6.951"></path></g><path fill="none" d="M0,0h24v24h-24Z"></path></svg>
                    </a>

                    <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
                        <p class="text-sm leading-7 font-medium gap-10 text-justify text-gray-600">
                                Don't see what you need? Contact us at <a style="color: #201747;" href="mailto:hello@equilo.io">hello@equilo.io</a> for customized subscriptions, to answer questions, or to schedule a live demo.
                        </p>
                    </div>
                </div>
            </div>
                
        </div>
    </div>
</template>

<script>
    const axios = require('axios');
    import env from '@/../env';
    import Cookies from '@/helpers/cookies'
    import auth from '@/helpers/auth';
    import spiderchart from '@/components/charts/demo-spider.vue';
    import heatmap from '@/components/charts/heatmap.vue';
    import methodology from '@/components/methodology';

    export default {
        name: 'welcome',
        data: function () {
            return {
                q: '',
                data: [],
                active: false,
                env,
                user: {},
                gesiIdUrl:'/pricing/'+env.productFamilyIds.gesi_aid,
                country: {
                  income: '', // let's define it. Otherwise we cannot use toLowerCase in the sourcecode
                },
                chartLabels: {},
                taabs: {
                    "Access to Resources": env.taabColors.accessToResources,
                    "Law & Policy": env.taabColors.lawAndPolicy,
                    "Power & Decision-Making": env.taabColors.powerAndDecision,
                    "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
                    "Human Dignity": env.taabColors.humanDignity,
                    "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities
                },
                appearItems: [],
                sector: {},
                category: 'region',
            }
        },
        components: {
            spiderchart,
            heatmap,
            methodology,
        },
        methods: {
            changeCategory: function (cat) {
                if (cat == 'region') {
                    this.category = 'region';
                } else if (cat == 'income') {
                    this.category = 'income';
                }
            },
            mouseOver: function (index) {
                this.active = index;
            },
            getCountry: function () {
                // const _self = this;
                return new Promise(function (resolve) {
                    // axios.get(env.apiUrl + "/countries/" + _self.$route.params.iso2+ "/sectors/" + _self.$route.params.sector, {
                    axios.get(env.apiUrl + "/countries/ZM/sectors/BASE", {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function (res) {
                      resolve(res.data.data)
                    });
                })
            },
            getSector: function () {
                // const _self = this;
                return new Promise(function (resolve) {

                  // Validate if you want general country information (count/base) or a specific sector
                  //if(['BASE', 'country'].indexOf(_self.$route.params.sector) > -1){
                  //  resolve(false);
                  //} else {
                    // axios.get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
                    axios.get(env.apiUrl + "/sectors/BASE", {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function (res) {
                      resolve(res.data.data)
                    });
                  //}
                })
            },
            
            opacity: function (hex) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                const rgb = result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null;
                return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 0.25)'
            },
            scrollTo(id){
                document.getElementById(id).scrollIntoView();
            },
            show(el){
                if(this.appearItems.indexOf(el.id) < 0) {
                    this.appearItems.push(el.id)
                }
            },
            
        },
        computed: {
        },
        async mounted() {

            this.$store.commit('resetCitations');
            

            this.user = await auth.me().catch(() => {
                console.log('Error login');
            });

            if(this.user.organization.subscription.price > 1){
              this.$router.push({path: '/'})
            }


            const _self = this;
            const url = env.apiUrl + "/organizations/" + this.user.organization._id + '/countries'
            axios.get(url, {
                headers: {
                    'Authorization': 'Bearer ' + Cookies.get('session')
                }
            }).then(function (res) {
                _self.data = res.data.data;
                _self.$store.commit('downloadable', false);
                _self.$store.commit('setBreadcrumbs', [
                    {'name': 'Welcome!', 'url': '/'},
                ])

            });

            
        }
    }
</script>
<style>
    
    .stupid-container {
    width: 780px;
    height: 720px;
    }
    
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
        .stupid-container {
        width: 753px;
        height: 600px;
        }
    }

    hover.Trigger .hoverMethodology {
    visibility: hidden;
    position: absolute;
    z-index: 50;
    width: 400px;
    }

    .triggerElement {
        border-bottom: 1px dotted #201747;
    }

    .hoverTrigger:hover .hoverMethodology {
        visibility: visible;
        width: 550px;
    }
    
    .list-item {
        display: inline-block;
        margin-right: 10px;
    }

    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }

    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }

    .slide-in-move {
        transition: opacity 0.6s linear, transform 0.6s ease-in-out;
    }

    .slide-in-leave-active {
        transition: opacity 0.6s linear, transform 0.6s cubic-bezier(0.5, 0, 0.7, 0.4);
        transition-delay: calc(0.1s * (var(--total) - var(--i)));
    }

    .slide-in-enter-active {
        transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
        transition-delay: calc(0.05s * var(--i));
    }

    .slide-in-enter, .slide-in-leave-to {
        opacity: 0;
    }

    .slide-in-enter {
        transform: translateY(2em);
    }

    .slide-in-leave-to {
        transform: translateY(-1em);
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    
[data-tip] {
	position:relative;

}
[data-tip]:before {
	content:'';
	/* hides the tooltip when not hovered */
	display:none;
	content:'';
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #1a1a1a;	
	position:absolute;
	top:30px;
	left:35px;
	z-index:8;
	font-size:0;
	line-height:0;
	width:0;
	height:0;
}
[data-tip]:after {
	display:none;
	content:attr(data-tip);
	position:absolute;
	top:35px;
	left:0px;
	padding:0px 8px;
	background-color: rgb(254, 80, 0);
	color:#fff;
	z-index:9;
	font-size: 0.80em;
	height:35px;
	line-height:35px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	white-space:nowrap;
	word-wrap:normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
	display:block;
}
</style>
