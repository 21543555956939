<template>
  <div class="static assementPageStyle">
    <div class="container">
      <transition name="fadeIn" mode="out-in">
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
        <div id="loaded" key="content">
            <div class="support-feedback-wrapper">
                <img src="@/assets/images/gesi_images/equilo-upgrade-to-premium.svg"/>
                <router-link to="/pricing/GESI-AID" class="upgradetopremium font-bold" >Upgrade to Premium</router-link>
                <!-- <a href="#" class="learnmore">Learn more</a> -->
            </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import pageLoader from "@/components/page-loader.vue";
export default {
    name: "partner-directory",
    data: function() {
     return {
         endofcreated:false
     }
    },
    components: {
      pageLoader
    },
    async created(){
        this.$store.commit('setBreadcrumbs', []);
        this.endofcreated=true;
    }
};
</script>

<style lang="scss" scoped>
.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

    .container {
        margin: 0 auto;
    }
  
    .support-feedback-wrapper{
        margin-top: -40px;
        .upgradetopremium {
          position: absolute;
          top: 60%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          background-color: rgb(254, 80, 0);
          color: white;
          font-size: 16px;
          padding: 12px 24px;
          border: none;
          border-radius: 50px;
        }
        
        .learnmore{
            position: absolute;
              top: 64%;
              left: 50%;
              transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              font-size: 16px;
              color: white;
              padding: 12px 24px;
              text-decoration: underline;
        }
    }
}

</style>
