<template>
  <div class="my-[1rem] w-full">
    <h3 v-if="!clustered" class="font-bold max-w-[32rem]">
      {{ title }}
    </h3>
    <div class="flex" v-if="!noInfo">
      <div v-if="answer">
        {{ answer }}
      </div>
      <div v-else-if="answers.length">
        <ul class="list-disc ml-[2rem]">
          <li v-for="ans in answers" :key="ans">
            <span>{{ ans }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "list",
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    clustered: {
      type: Boolean,
      default: false,
    },
    COM_Commitment: {
      type: [Array],
      default: null
    }
  },
  data() {
    return {
      title: "",
      answer: "",
      answers: [],
      noInfo: false,
    };
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (this.info && this.info.surveyQuestion) {
        this.title = this.info.title;
      }
      if (typeof this.info.value === "string") {
        this.answer = this.info.value;
      } else if (Array.isArray(this.info.value)) {
        this.answers = this.info.value;
        if (
          this.answers?.some((element) =>
            element.includes("[Varies]")
          ) &&
          this.COM_Commitment
        ) {
          this.answers = this.answers.map(
            (choice) => {
              if (
                choice.includes("women in senior management") ||
                choice.includes(
                  "women partners and managing partners"
                )
              ) {
                choice = choice.replace(
                  "[Varies]",
                  this.COM_Commitment[1]
                );
              }
              if (
                choice.includes("women on IC") ||
                choice.includes("women on Board")
              ) {
                choice = choice.replace(
                  "[Varies]",
                  this.COM_Commitment[2]
                );
              }
              if (choice.includes("women in workforce")) {
                choice = choice.replace(
                  "[Varies]",
                  this.COM_Commitment[0]
                );
              }

              return choice;
            }
          );
        }
      } else {
        this.noInfo = true;
      }
    },
  },
};
</script>
