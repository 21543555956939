<script>
import { Bar } from 'vue-chartjs'
import env from '@/../env.js';

export default {
  name: 'barChartVertical',
  extends: Bar,
  props: ['chartLabels', 'totalValue', 'filledValue', 'label1', 'label2', 'mainTitle', 'type'],
  data: function () {
      return {
        max: 100,
        beginAtZero: true,
        labels: [],
        datasets: [],
        totalValue1: '',
      }
  },
  mounted() {
    this.renderMyChart();
  },
  methods:{
    renderMyChart(){
      this.totalValue1 = this.totalValue[0];
      this.labels= [];
      this.labels = this.chartLabels;
      this.datasets= [
        {
          label: this.label1,
          backgroundColor: this.type === 'leadership' ? '#2BD4C4' : env.genderColors.male,
          data: [this.filledValue[0], this.filledValue[1]]
        },
      ]
      this.max = 100;
      let length = (String(Math.round(this.max))).split("").length;
      let num, roundNum;
      roundNum = Math.pow(10, length - 1)
      let onesValue = (String(this.max)).split("")[0];
      num = roundNum * (String(this.max)).split("")[0];
      if (this.max < (num + (roundNum/2))) {
        this.max = num + (roundNum/2);
      } else {
        this.max = num + roundNum;
      }
      let stepRatio = this.max / 5;
      let stepDividend = 5 * Math.pow(10, length - 2);
      if (stepRatio % stepDividend != 0 && onesValue > 1 && length>1) {
        let diff = (stepDividend - (stepRatio % stepDividend))
        this.max = this.max + (diff * 5);
      }
      if(this.max < 10) {
        this.max = 10;
      }
      const _self = this;
      this.max = 100;
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      }, {
        responsive: true,
        maintainAspectRatio: false,
          fontFamily: "basier",
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              "fontSize": 13,
              fontFamily: "basier"
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                max: this.totalValue1 == 0 && this.filledValue[0] == 0
                && this.totalValue2 == 0 && this.filledValue[1] == 0 ? 10 : this.max,
                min: 0,
                stepSize: this.max / 5,
                callback: function (value) {
                  var numFormat = new Intl.NumberFormat("en-US");
                  return numFormat.format(value) ;
                }
              },
              scaleLabel: {
                  display: true,
                  labelString: this.mainTitle ? this.mainTitle : '',
                  fontStyle: 'bold',
                  fontSize: '16',
                  fontFamily: "basier"
              },
              beginAtZero: this.beginAtZero,
              fontFamily: "basier",
              stacked: true,
              fontSize: 12,
            }],
            xAxes: [{
              fontSize: 13,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              fontFamily: "basier",
              stacked: true,
            }]
          },
          tooltips: {
            titleFontFamily: "basier",
            bodyFontFamily: "basier",
            callbacks: {
              title: function(tooltipItems) {
                  const tt = JSON.parse(JSON.stringify(tooltipItems[0]));
                  const xLabel = tt.xLabel;
                  return xLabel.toString().split(',').join(' ') + '/'+_self.datasets[tooltipItems[0].datasetIndex].label;
              },
              label: function (tooltipItem) {
                var numFormat = new Intl.NumberFormat("en-US");
                return numFormat.format(tooltipItem.yLabel || 0);
              }
            }
        }
      })
    },
  },
  watch: {
    totalValue: function () {
      this.renderMyChart();
    }
  },
}
</script>