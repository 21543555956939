import { render, staticRenderFns } from "./twox-invite-companies.vue?vue&type=template&id=57752ac0&scoped=true"
import script from "./twox-invite-companies.vue?vue&type=script&lang=js"
export * from "./twox-invite-companies.vue?vue&type=script&lang=js"
import style0 from "./twox-invite-companies.vue?vue&type=style&index=0&id=57752ac0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57752ac0",
  null
  
)

export default component.exports