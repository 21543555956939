<template>
    <div class="table-container">
        <table class="inner-table">
            <tr class="table-heading-row">
                <th v-for="head in heading" :key="head"> {{ head }}</th>
            </tr>
            <tr class="table-content-row" v-for="(data , index ) in tableData" :key="data.rowHeading">
                <td >
                    <div class="flex justify-between py-4 px-3 items-center">
                        {{ data.rowHeading }}
                        <img v-if="data.isVisible" @click="data.isVisible = !data.isVisible" src="@/assets/images/arrow-up.svg"/>
                        <img v-if="!data.isVisible" @click="data.isVisible = !data.isVisible" src="@/assets/images/arrow-down.svg"/>
                    </div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div>Difference</div>
                        <div class="mt-3">%</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row1 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row2 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row3 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row4 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
                <td class="w-[241px] text-left">
                    <div class="py-4 px-3">
                        <div v-if="data.row5 && data.row5.split(' ').length > 16">{{ data.row5.slice(0, 94) }}...</div>
                        <div v-else>{{ data.row5 }}</div>
                         <button class="text-[#2177C1] outline-none text-sm font-bold leading-5 flex items-center mt-2" v-if="data.row5" @click="handleComments(index , data.row5)">Read More
                            <span>
                                <svg  class="ml-1 w-4 h-2" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3404 3.16602H0.332031V4.83268H10.3404V7.33268L13.6654 3.99935L10.3404 0.666016V3.16602Z" fill="#2177C1"/>
                                </svg>
                            </span>
                         </button>
                         <button  v-else @click="handleComments(index , data.row5)" class="text-[#191B45] outline-none border-2 border-[#191B45] text-sm font-bold leading-5 flex items-center mt-2 rounded-lg py-1 px-3">Make a comment<span>
                                <svg  class="ml-1 w-4 h-2" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3404 3.16602H0.332031V4.83268H10.3404V7.33268L13.6654 3.99935L10.3404 0.666016V3.16602Z" fill="#191B45"/>
                                </svg>
                            </span>
                        </button>
                    </div>
                </td>
            </tr>
        </table>
        <div v-if="isStatementModalOpen">
            <StatementCommentsModal @close="isStatementModalOpen = false" :comment="commentData"
            @saveComment="handleSaveComment"
            ></StatementCommentsModal>
        </div>
    </div>
</template>
<script>
import StatementCommentsModal from './statement-comments-modal.vue'
export default {
    name: "StatementCommentsTable",
    data() {
        return {
            heading : [ '', 'Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4', 'Comments'],
            colHeading: ['Net Sales of Goods & Services', 'Difference %',  'Gross Profit (Loss)', 'EBITDA', 'EBIT', 'Profit (Loss) from Discontinued Operations', 'Corporate Income Tax', 'NET INCOME (LOSS)', 'Adjusted EBITDA for Unusual Items'],
            tableData: [
                { rowHeading: 'Net Sales of Goods & Services', diffPercent : '20.8%', diffAmount: '848', row1: '5723', row2: '5723', row3 : '1,239', row4 : '5723', row5 : "Gross revenue started to pick-up after bottomed in May 20, inline with the gradual increase in number of cups. Gross revenue started to pick-up after bottomed in May 20, inline with the gradual increase in number of cups." , isVisible: true, class: 'red'},
                // { rowHeading: 'Difference %', diffPercent : '33.2%', diffAmount: '742', row1: '5723', row2: '5723', row3 : '525', row4 : '5723', isVisible: true, class: 'green'},
                { rowHeading: 'Gross Profit (Loss)', diffPercent : '61%', diffAmount: '742', row1: '5723', row2: '5723', row3 : '62%', row4 : '5723', row5 : "Gross revenue started to pick-up after bottomed in May 20, inline with the gradual increase in number of cups.", isVisible: false, class: 'green'},
                { rowHeading: 'EBITDA', diffPercent : '33.2%', diffAmount: '776', row1: '5723', row2: '5723', row3 : '991', row4 : '5723', row5 : '', isVisible: false, class: 'green'},
                { rowHeading: 'EBIT', diffPercent : '44.2%', diffAmount: '260', row1: '5723', row2: '5723', row3 : '(180)', row4 : '5723', isVisible: false, class: 'green'},
                { rowHeading: 'Profit (Loss) from Discontinued Operations', diffPercent : '31%', diffAmount: '562', row1: '5723', row2: '5723', row3 : '-15%', row4 : '5723', row5 : "Gross revenue started to pick-up after bottomed in May 20, inline with the gradual increase in number of cups.",isVisible: false, class: 'green'},
                { rowHeading: 'Corporate Income Tax', diffPercent : '2.5%', diffAmount: '218', row1: '5723', row2: '5723', row3 : '(222)', row4 : '5723', row5 : "" ,isVisible: false, class: 'red'},
                { rowHeading: 'NET INCOME (LOSS)', diffPercent : '26%', diffAmount: '123', row1: '5723', row2: '5723', row3 : '-18%', row4 : '5723', isVisible: false, class: 'green'},
                { rowHeading: 'Adjusted EBITDA for Unusual Items', diffPercent : '26%', diffAmount: '762', row1: '5723', row2: '5723', row3 : '-18%', row4 : '5723', row5 : "Gross revenue started to pick-up after bottomed in May 20, inline with the gradual increase in number of cups." ,  isVisible: false, class: 'red'},
            ],
            isStatementModalOpen : false,
            commentData : {
                commentId : '',
                commentContent : '',
            }
        }
    },
    components: {
        StatementCommentsModal
    },
    methods: {
        handleComments(id, data){
            this.isStatementModalOpen = true
            this.commentData = {
            commentId : id,
            commentContent : data
            }
        },
        handleSaveComment(data){
            this.tableData[data?.commentId].row5 = data.commentContent
            this.isStatementModalOpen = false
        }
    },
}
</script>
<style lang="scss" scoped>
.table-container {
    gap: 20px;
    border-radius: 16px;
    border: 1px solid #E1E3E9;
    .inner-table {
        width: 100%;
        th, td {
            border: 1px solid #E1E3E9;
        }
        .table-heading-row {
            th {
                padding: 16px;
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #F4F6F9, #F4F6F9),linear-gradient(0deg, #E1E3E9, #E1E3E9);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2C2E35;
            }
            th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
            }
            th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
            }
        }
        .table-content-row {
            td {
                border-bottom: none;
                height: 68px;
                // padding: 16px 12px 16px 12px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2C2E35;
                .difference-data {
                    background: linear-gradient(0deg, #F4F6F9, #F4F6F9),linear-gradient(0deg, #E1E3E9, #E1E3E9);
                    border-top: 1px solid #E1E3E9;
                }
                img {
                    width: 32px;
                    height: 32px;
                }
            }
            td:first-child {
                border-left: none;
            }
            td:last-child {
                border-right: none;
            }
            .unaudit-section {
                span {
                    gap: 8px;
                }
            }
        }
    }
    .red {
        color: #CB2E00;
    }
    .green {
        color: #13A094;
    }
}
</style>