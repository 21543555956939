<template>

  <div>
    <div class="flex flex-col center-items my-5" v-for="dataset in datasets" v-bind:key="dataset.label">
      <center>
      <p>{{dataset.label}}</p>

        
        <div class="mt-3" v-if="detect(dataset)=== 'positive'">
          <svg style="color: rgb(43, 212, 196);" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
        </div>
        <div class="mt-3" v-else-if="detect(dataset) === 'negative'">
          <svg style="color: #FE5000;" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-frown"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
        </div>
         <div class="mt-3" v-else-if="detect(dataset) === 'straight'">
             <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-frown" style="color:#FAE053;"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-0-0-4-0-4 0-4 0"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
             
        </div>
        <div class="mt-3" v-else-if="detect(dataset) === 'unknown'">
          <center> <p class="text-4xl text-gray-500"><b>Unknown</b></p> </center>
        </div>

      </center>
    </div>
  </div>
</template>

<script>
/*
  IMPORTANT NOTE:

  - Do not use stereotype colors. but use turqoise for women & navy blue for males.
  - Make sure the Y axe is always 100%
  - Fix the label


*/
import env from '@/../env.js';

export default {
  name: 'checkCrossChart',
  props: ['chartData', 'chartLabels', 'country', 'index'],
  data: function () {
      return {
        labels: [],
        data: [],
        datasets: [],
        colors: [env.chartColors.alfaColor, env.chartColors.betaColor, env.chartColors.gammaColor, env.chartColors.deltaColor, env.chartColors.jotaColor, env.chartColors.muColor, env.chartColors.piColor, env.chartColors.rhoColor]
      }
  },
  methods: {
    detect: function(input){
        
      if(input.label.includes("Legal Requirement to Obey Husband") | (input.label.includes("Concerning Developments Regarding Civil Society Participation")) | (input.label.includes("Political Rallies or Events Banned due to COVID-19"))){
          const positiveItems = ['is not', 'no concerning developments', 'were not'];
          if(positiveItems.indexOf(input.data[0].toString()) > -1){
            return "positive";
           } else if(input.data[0].toString() == "Unknown"){
            return "unknown";
          } else {
            return "negative";
          }
      } else if(input.label == "Sanctions Against Women/Girls" || input.label == "Sanctions Against Providers" || input.label == "Parental Consent to Access SRH Services" || input.label == "Inclusion of Workers in General Regime of Employment Protection Legislation on Basis of Size of Enterprise"
      || input.label == "Employment Protection Legislation Against the Exclusion of Workers on the Basis of Enterprise Size") {
          const nagetiveItems = ['1', 'does', 'can', 'do', 'is', 'is a', 'are', 'has'];
          if(nagetiveItems.indexOf(input.data[0].toString()) > -1) {
              return "negative";
          } else if(input.data[0].toString() == "Unknown") {
            return "unknown";
          } else {
            return "positive";
          }
      } else if(input.label.includes("Provision of Paid Sick Leave") || (input.label.includes("Paid Sick Leave Available to Part-Time Workers")) || (input.label.includes("Availability of Paid Leave to Meet Family's Health Needs")) || (input.label.includes("Structure of Paid Leave"))){ 
          const straightItems1 = ['less than 1 month','1 - 2.9 months','3 months - 5.9 months','guaranteed to workers generally, with no exclusions based on part-time work','guaranteed to part-time workers who work fewer than 20 hours per week','guaranteed to part-time workers who work at least 20 hours per week','only leave for women to care for children','leave for both parents to care for children','leave for both parents to care for children and spouse','parental leave but no incentives','2 weeks or fewer reserved for fathers','more than 2 weeks reserved for fathers'];
          const positiveItems1 = ['6 months or more','leave for both men and women to care for any family member','leave length or payment bonus for fathers sharing leave','guaranteed to part-time workers without an explicit minimum hourly requirement'];
          if(positiveItems1.indexOf(input.data[0].toString()) > -1){
              return "positive";
         }else if(straightItems1.indexOf(input.data[0].toString()) > -1){
              return "straight";
         }else if(input.data[0].toString() == "missing"){
            return "missing";
          } else {
            return "negative";
          }
      }else{
          
          const straightItems = ['implemented inconsistently', 'are only in certain instances','are only in some instances','are limited and specific', 'are only to a limited extent'];
          const positiveItems = ['1', 'does', 'can', 'do', 'is', 'is a', 'are', 'has','have','implemented systematically on a larger scale, meaning that they are implemented in many schools or communities or have reached more than 30% of the intended target population in the country'];
          if(positiveItems.indexOf(input.data[0].toString()) > -1){
              return "positive";
         }else if(straightItems.indexOf(input.data[0].toString()) > -1){
              return "straight";
         }else if(input.data[0].toString() == "Unknown"){
            return "unknown";
          } else {
            return "negative";
          }
     }
    },
    changeText: function(text){
      this.country.values['country'] = this.country.humanName;
      this.country.values['region'] = this.country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' : this.country.region+ ' Region';
      this.country.values['income'] = this.country.income+ ' Group';
      const keys = Object.keys(this.country.values);
      for (var k = 0; k < keys.length; k++) {
        const key = keys[k]
        let value = this.country.values[key];
        
        text = text.toString().split('{{'+key+'}}').join(value);
        // let's try to convert to a number
        if(!isNaN(Number(text))){
          text = Number(text);
        }
      }

      return text;
    },
    fillIn: function(array){
      if(array && array.length > 0){
        for (var i = 0; i < array.length; i++) {
          // Replace Global
          if(array[i] == 'LMIC'){
            array[i] = 'Global'
          }


          array[i] = this.changeText(array[i]);
        }

        return array;
      } else {
        return []
      }
    },
    settingUnknowDataSetValue() {
      return this.$route.fullPath.includes('/gbv-risk') ? "Unknown" : "N/A";
    }
  },
  mounted () {
    this.labels = this.fillIn(this.chartLabels);
    this.data = this.chartData || [];
    for (var i = 0; i < this.data.length; i++) {
      this.datasets.push({
        label: this.changeText(this.data[i].label),
        data: this.fillIn(this.data[i].data),
        backgroundColor: this.colors[i]
      });
    }
  }
}
</script>

<style>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
