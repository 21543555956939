<template>
    <div class="table-container">
        <table class="inner-table">
            <tr class="table-heading-row">
                <th v-for="head in heading" :key="head"> {{ head }}</th>
            </tr>
            <tr class="table-content-row" v-for="data in tableData" :key="data.rowHeading">
                <td>
                    <div class="flex justify-between py-4 px-3 items-center"
                    :class="{
                    'font-bold ': ['Gross Profit', 'EBITDA', 'Net Profit' , 'Gross Profit (Loss)' , 'Total current assets' , 'Total Non-Current Assets'].includes(data.rowHeading),
                    }"
                    >
                        {{ data.rowHeading }}
                        <img v-if="data.isVisible" @click="data.isVisible = !data.isVisible" src="@/assets/images/arrow-up.svg"/>
                        <img v-if="!data.isVisible" @click="data.isVisible = !data.isVisible" src="@/assets/images/arrow-down.svg"/>
                    </div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div>Difference</div>
                        <div class="mt-3">%</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row1 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row2 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row3 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="py-4 px-3" style="height:64px">${{ data.row4 }}</div>
                    <div class="difference-data py-4 px-3" v-if="data.isVisible">
                        <div :class="data.class">+{{ data.diffPercent }}</div>
                        <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: "SalesTable",
    data() {
        return {
            heading : [ '', 'Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4'],
            colHeading: ['Net Sales of Goods & Services', 'Difference %',  'Gross Profit (Loss)', 'EBITDA', 'EBIT', 'Profit (Loss) from Discontinued Operations', 'Corporate Income Tax', 'NET INCOME (LOSS)', 'Adjusted EBITDA for Unusual Items'],
            tableData: [
                { rowHeading: 'Net Sales of Goods & Services', diffPercent : '20.8%', diffAmount: '848', row1: '5723', row2: '5723', row3 : '1,239', row4 : '5723', isVisible: true, class: 'red'},
                // { rowHeading: 'Difference %', diffPercent : '33.2%', diffAmount: '742', row1: '5723', row2: '5723', row3 : '525', row4 : '5723', isVisible: true, class: 'green'},
                { rowHeading: 'Gross Profit (Loss)', diffPercent : '61%', diffAmount: '742', row1: '5723', row2: '5723', row3 : '62%', row4 : '5723', isVisible: false, class: 'green'},
                { rowHeading: 'EBITDA', diffPercent : '33.2%', diffAmount: '776', row1: '5723', row2: '5723', row3 : '991', row4 : '5723', isVisible: false, class: 'green'},
                { rowHeading: 'EBIT', diffPercent : '44.2%', diffAmount: '260', row1: '5723', row2: '5723', row3 : '(180)', row4 : '5723', isVisible: false, class: 'green'},
                { rowHeading: 'Profit (Loss) from Discontinued Operations', diffPercent : '31%', diffAmount: '562', row1: '5723', row2: '5723', row3 : '-15%', row4 : '5723', isVisible: false, class: 'green'},
                { rowHeading: 'Corporate Income Tax', diffPercent : '2.5%', diffAmount: '218', row1: '5723', row2: '5723', row3 : '(222)', row4 : '5723', isVisible: false, class: 'red'},
                { rowHeading: 'NET INCOME (LOSS)', diffPercent : '26%', diffAmount: '123', row1: '5723', row2: '5723', row3 : '-18%', row4 : '5723', isVisible: false, class: 'green'},
                { rowHeading: 'Adjusted EBITDA for Unusual Items', diffPercent : '26%', diffAmount: '762', row1: '5723', row2: '5723', row3 : '-18%', row4 : '5723', isVisible: false, class: 'red'},
            ],
            
        }
    }
}
</script>
<style lang="scss" scoped>
.table-container {
    gap: 20px;
    border-radius: 16px;
    border: 1px solid #E1E3E9;
    .inner-table {
        width: 100%;
        th, td {
            border: 1px solid #E1E3E9;
        }
        .table-heading-row {
            th {
                padding: 16px;
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #F4F6F9, #F4F6F9),linear-gradient(0deg, #E1E3E9, #E1E3E9);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2C2E35;
            }
            th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
            }
            th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
            }
        }
        .table-content-row {
            td {
                border-bottom: none;
                height: 68px;
                // padding: 16px 12px 16px 12px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2C2E35;
                .difference-data {
                    background: linear-gradient(0deg, #F4F6F9, #F4F6F9),linear-gradient(0deg, #E1E3E9, #E1E3E9);
                    border-top: 1px solid #E1E3E9;
                }
                img {
                    width: 32px;
                    height: 32px;
                }
            }
            td:first-child {
                border-left: none;
            }
            td:last-child {
                border-right: none;
            }
            .unaudit-section {
                span {
                    gap: 8px;
                }
            }
        }
    }
    .red {
        color: #CB2E00;
    }
    .green {
        color: #13A094;
    }
}
</style>