<template>
  <div class="flex flex-row w-full bg-gray-100">


    <sidebar></sidebar>

    <!--TABLE-->
    <div class="flex flex-col w-full overflow-hidden" style="padding-top: 100px; padding-left: 30px; padding-bottom: 30px padding-right: 30px;">
      <div class="flex flex-col justify-start items-start overflow-x-scroll h-full">


      <div>
        <router-link to="/settings/plans" class="text-sm cursor-pointer">Go back</router-link>

        <p class="text-2xl">{{subscription.name}}</p>
        <p class="text-gray-900" v-html="subscription.desc"></p>

        <p>
          <span v-if="subscription.price > 0">
            This plan will cost ${{subscription.price.toFixed(2)}} and will be valid for
            <span v-if="subscription.days == 365">1 year.</span>
            <span v-else-if="subscription.days == 62">2 months.</span>
            <span v-else>1 month.</span>
          </span>
        </p>


        <p v-if="subscription.idealFor" class="mt-5">
          {{subscription.idealFor}}
        </p>

        <p v-if="subscription.importantNote" class="mt-5">
          {{subscription.importantNote}}
        </p>

      </div>


      <div class="mt-10">
        <span @click="open(subscription.moreInfo)" class="text-white mx-1 px-5 py-2 text-sm rounded-lg hover:bg-green-darkere cursor-pointer" style="background-color: rgb(32, 23, 71);">Read more</span>
        <span @click="payACH(subscription)" class="text-white mx-1 px-5 py-2 text-sm rounded-lg hover:bg-green-darkere cursor-pointer" style="background-color: rgb(32, 23, 71);">Buy with Bank Transfer</span>
        <span @click="payCreditcard(subscription)" class="text-white mx-1 px-5 py-2 text-sm rounded-lg hover:bg-green-darkere cursor-pointer" style="background-color: rgb(32, 23, 71);">Buy with Credit Card</span>

        <a href="mailto:rachel@equilo.io" class="text-white mx-1 px-5 py-2 text-sm rounded-lg hover:bg-green-darkere cursor-pointer" style="background-color: rgb(32, 23, 71);">Contact sales</a>
      </div>



      </div>
    </div>
  </div>
</template>

<script>
  import sidebar from '@/components/sidebar.vue'
  import auth from '@/helpers/auth';
  const axios = require('axios');
  import Cookies from '@/helpers/cookies'
  import env from '@/../env.js';


  export default {
    name: 'Overview',
    data: function () {
      return {
        user: {},
        organization: {},
        subscription: {}
      }
    },
    components: {
      sidebar,
    },
    methods: {
      open: function(href){
        var win = window.open(href, '_blank');
        win.focus();
      },
      payCreditcard: function () {
        this.$router.push({path: '/settings/plans/'+this.$route.params.planId+'/creditcard'})
      },
      payACH: function () {
        this.$router.push({path: '/settings/plans/'+this.$route.params.planId+'/ach'})
      },
      load: function () {
        const _self = this;
        return new Promise(function (resolve) {
          axios.get(env.apiUrl + '/subscriptions/'+_self.$route.params.planId, {
            headers: {
              'Authorization': 'Bearer ' + Cookies.get('session')
            }
          }).then(function (res) {
            resolve(res.data.data);
          });
        })
      }
    },
    async created() {
      this.user = await auth.me().catch(() => {});
      this.organization = this.user.organization;
      this.subscription = await this.load();
      this.$store.commit('downloadable', false);
      this.$store.commit('setBreadcrumbs', [{
          'name': 'Settings',
          'url': '/settings/account'
        },
        {
          'name': this.organization.name,
          'url': '/settings/team'
        },
        {
          'name': 'Subscriptions',
          'url': '/settings/plans'
        },
      ])

    }
  }
</script>
