<template>
  <div class="flex flex-row bg-gray-100">


    <sidebar></sidebar>

    <div class="flex flex-col justify-center items-center w-full">
        <h1 class="text-2xl leading-5 font-bold py-5">View the demo video to see how Equilo works</h1>
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/500414524?title=0&byline=0&portrait=0&sidedock=0" width="670" height="390" frameborder="0" allowfullscreen></iframe>
    </div>

  </div>
</template>

<script>
import sidebar from '@/components/sidebar.vue'


export default {
  name: 'Overview',
  data: function () {
    return {
      openPanels: [],
    }
  },
  components: {
    sidebar,
  },
  methods: {
    togglePanel: function (panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1)
      } else {
        this.openPanels.push(panelid);
      }
    },
  },
  async created() {
    this.$store.commit('downloadable', false);
    this.$store.commit("isFreeState", true);
    this.$store.commit('setBreadcrumbs', [{
      'name': 'Settings',
      'url': '/settings/account'
    },
      {
        'name': 'Demo video',
        'url': '/settings/demo-video'
      }
    ])

  }
}
</script>
