<template>
  <div
    class="relative w-full mr-5"
    :style="{
      cursor: data.length === 0 ? 'not-allowed' : 'pointer',
      opacity: data.length === 0 ? '0.5' : '1',
    }"
  >
    <label for="filters">{{ title }}</label>
    <select
      :disabled="data.length === 0"
      v-model="dataModel"
      class="w-full px-2 py-2 mt-2 placeholder-gray-700 text-gray-900 rounded-md focus:outline-none focus:shadow-outline border-2 border-gray-500"
      @change="logDataModel"
    >
      <option
        v-for="(element, index) in data"
        :key="element + index"
        :value="element"
      >
        {{ element }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "dropdown",
  props: ["data", "label", "title"],
  computed: {
    dataModel: {
      get() {
        return this.data[0];
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    logDataModel(newVal) {
      console.log(newVal);
    },
  },
};
</script>
