<template>
    <div>
        <FullAssessment :validationAssessment="true" @auditLogsData="fetchAuditLogsData" @commentsData="fetchCommentsData" @endofcreated="fetchEndofcreated"></FullAssessment>
        <HistroySection :commentsData="commentsData" :auditLogsData="auditLogsData" v-if="endofcreated"></HistroySection>
    </div>
</template>
<script>
import FullAssessment from "./../reports/my2xCertificationFullAssessmentResult/my2xCertification-Full-AssessmentResult.vue"
import HistroySection from "@/components/2x-certification/verifier-history-section.vue";
import apiService from "@/helpers/apiServices.js";

export default {
  name: "ValidationFullAssessment",
  data: function () {
    return {
      commentsData: [],
      auditLogsData: [],
      endofcreated: false,
    }
  },
  components: {
    FullAssessment,
    HistroySection
  },
  methods: {
    fetchCommentsData(data) {
      this.commentsData = data;
    },
    fetchEndofcreated(data) {
      this.endofcreated = data;
    },
    async fetchAuditLogsData(teamId) {
      const response = await apiService.fetchSummaryReportData(teamId);
      const result = response.data.data.result;

      let dataPointChangeHistories = Object.keys(result).map(key => {
        let item = result[key];
        return item.dataPointChangeHistory.length ? item.dataPointChangeHistory.map(history => ({ ...history, indicator: item?.indicator, question: item.question })) : null;
      }).filter(item => item).flat();

      const sortedDataPointChangeHistories = dataPointChangeHistories.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      this.auditLogsData = sortedDataPointChangeHistories;
    }
  },
  mounted() {
    this.fetchAuditLogsData(this.$route.query.teamId);
  }
}
</script>
<style lang="scss" scoped>
  // Annotation CSS
  .r6o-annotation {}

  .r6o-editor {}
</style>