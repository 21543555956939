const GENDER_DEFAULT = [
  {
    element: "#EQ",
    popover: {
      title: "Explore more",
      description:
        "Explore GESI Context and GBV Risk Score to help with assessing market, workforce and supplier issues.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#GIRL",
    popover: {
      title: "SuperGIRL",
      description:
        "Explore Equilo's Super Gender & Inclusion Resource Library to create your own customized bibliography with filters you set to search for thousands of curated reports and documents.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#GIRL-pd",
    popover: {
      title: "Explore more",
      description:
        "Register as a TA service provider for others to find you, or search and filter to find the expertise you need to support your projects.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#GIRL-tsp",
    popover: {
      title: "Take gender smart actions",
      description:
        "Search and filter for gender smart tools fit for purpose to support specific activities.",
    },
    side: "left",
    align: "start",
  },
];

const IVM_DEFAULT = [
  {
    element: "#IP",
    popover: {
      title: "Create your investor profile",
      description:
        "Creating your investor profile is the first step to unlocking the benefits of tools for investors, including portfolio analytics for gender lens investment and impact measurement.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#IP",
    popover: {
      title: "View your portfolio results",
      description:
        "For investors seeking portfolio analysis, smart matching with suitable investees, and networking with like-minded co-investors.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#twoXC-ie",
    popover: {
      title: "Invite investees to take 2X Assess",
      description:
        "Send a request to investee to self-assess if their organization meets minimum global industry standards.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#twoXC",
    popover: {
      title: "Take your own Assessment",
      description:
        "Self-Assess if your organization meets minimum global industry standards.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#GR",
    popover: {
      title: "Go deeper with Gender ROI™",
      description:
        "Self-assess your organization's gender equality across all areas.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#EQ",
    popover: {
      title: "GESI Contextual Analysis",
      description: "Conduct market level due diligence with GESI Context.",
    },
    side: "left",
    align: "start",
  },
  {
    element: "#GBVA",
    popover: {
      title: "GBV Risk country analytics tool",
      description:
        "Safeguard your investments by utilizing the GBV Risk score for data driven analysis integration in due diligence, ESG safeguarding, and financial modelling.",
    },
    side: "left",
    align: "start",
  },
]

const tour = {
  IVM: IVM_DEFAULT,
  FILB: IVM_DEFAULT,
  ESO: IVM_DEFAULT,
  ACPGSNTID: [
    {
      element: "#twoXC",
      popover: {
        title: "Take your own Assessment",
        description:
          "Self-assess if my organization meets global gender smart industry standards with 2X Assess and begin 2X Certification.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#GR",
      popover: {
        title: "Go deeper with Gender ROI™",
        description:
          "Self-assess your organization's gender equality across all areas.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#EQ",
      popover: {
        title: "Explore more",
        description:
          "Explore GESI Context and GBV Risk Score to help with assessing market, workforce and supplier issues.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#GIRL-tsp",
      popover: {
        title: "Explore more",
        description:
          "View TA Service Provider Directory to find help to get gender smart.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#GIRL-gms",
      popover: {
        title: "Take gender smart actions",
        description:
          "Find gender resources for deeper research to support market analytics, due diligence, and planning.",
      },
      side: "left",
      align: "start",
    },
  ],
  PID: GENDER_DEFAULT,
  HA: GENDER_DEFAULT,
  AR: GENDER_DEFAULT,
  BASIC: [
    {
      element: "#twoXC",
      popover: {
        title: "Take your own Assessment",
        description:
          "Self-Assess if your organization meets minimum global industry standards.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#GR",
      popover: {
        title: "Go deeper with Gender ROI™",
        description:
          "Self-assess your organization's gender equality across all areas.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#IP",
      popover: {
        title: "Create your investor profile",
        description:
          "Creating your investor profile is the first step to unlocking the benefits of tools for investors, including portfolio analytics for gender lens investment and impact measurement.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#EQ",
      popover: {
        title: "Explore more",
        description:
          "Explore GESI Context and GBV Risk Score to help with assessing market, workforce and supplier issues.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#GBVA",
      popover: {
        title: "GBV Risk country analytics tool",
        description:
            "Safeguard your investments by utilizing the GBV Risk score for data driven analysis integration in due diligence, ESG safeguarding, and financial modelling.",
      },
      side: "left",
      align: "start",
    },
    {
      element: "#GIRL",
      popover: {
        title: "SuperGIRL",
        description:
          "Explore Equilo's Super Gender & Inclusion Resource Library to create your own customized bibliography with filters you set to search for thousands of curated reports and documents.",
      },
      side: "left",
      align: "start",
    },
  ],
};

export default tour;
