<template>
    <transition name="fadeIn" mode="out-in">
        <div class="static ecosystem-landing">
            <div id="notLoaded" v-if="isSurveyLoading" key="loading">
                <page-loader></page-loader>
            </div>
            <div class="container mx-auto mt-20 pt-10">
                <div class="PoweredbyTitle_logo border-b">
                    <!-- <div class="PoweredbyContentWrepper">
                        <img src="@/assets/images/2X-Global_logo_transparent.svg" alt="background-image"
                            style="height: 50px" />
                    </div> -->
                    <div class="container flex items-center ">
                        <div class="flex">
                            <h1 class="text-6xl tracking-tighter font-medium print:text-7xl"
                                style="color: #201747; max-width: 845px">
                                <span style="font-family: Work Sans">
                                    Finance</span>
                            </h1>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: flex-end" class="mb-5">
                        <div class="Poweredby_logoImg mt-2 flex items-center">
                            <div class="powered-text flex">
                                <span class="whitespace-nowrap">Powered by:</span>
                            </div>
                            <img class="-ml-6" src="@/assets/images/logo-navy.png" alt="background-image" />
                        </div>
                    </div>
                </div>

                <div class="mx-auto">
                    <div class="mt-8">
                        <div class="text-base text-[#201747] mb-4">
                            Evaluate your company’s financial health across key categories: assets, liabilities, equity,
                            expenses, revenue, and overall profitability. To get started, download the Finance Excel
                            file using the button below. Complete the required data fields in the file, then return to
                            this page to upload the completed document.
                        </div>
                        <button class="upload-documents-button rounded-lg" @click="saveFile()">
                            Download File
                        </button>
                        <div class="text-base text-[#201747] mt-3 -mb-2">
                            Once submitted, your investor will review and approve your financial data. After approval,
                            you can access detailed insights into your finances and profitability directly on your
                            dashboard.
                        </div>
                    </div>
                    <div class="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-2 gap-4">
                        <div class="congrats-box-doc p-5 mb-5 text-center mt-5" id="app" @dragover="dragover" @drop="drop">
                        <div class="flex w-full items-center justify-center text-center">
                            <div>
                                <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
                                    class="w-px h-px opacity-0 overflow-hidden absolute"
                                    @change="handleFileChange($event)" accept=".xls,.xlsx" ref="file" />
                                <label for="assetsFieldHandle" class="block cursor-pointer">
                                    <div class="center-button">
                                        <span
                                            class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                                            Choose files
                                        </span>
                                    </div>

                                    <p class="center-span-modal">
                                        Drag one or more files here
                                    </p>
                                </label>
                            </div>

                        </div>
                    </div>
                    <div class="container mx-auto p-4 border rounded-lg h-40 mt-5">
                        <h2 class="text-gray-700 text-xl font-semibold truncate">Selected File</h2>
                        <div>
                            <div>
                                <div v-if="xlsFilelist.length > 0" class="flex flex-col overflow-y-auto">
                                    <div v-for="(file, index) in xlsFilelist" :key="index"
                                        class="p-1 my-2 border-b flex justify-between items-center">
                                        <h3 class="text-gray-700 text-sm font-medium truncate">
                                            {{ file.name }}
                                        </h3>



                                        <div class="flex items-center">

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor"
                                                class="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer mr-6"
                                                @click="removeSelectedFile(index)">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>

                                        </div>


                                    </div>
                                </div>
                                <div v-else class="text-gray-500 text-sm font-medium py-1">
                                    Select the Finance Excel file.
                                </div>
                            </div>
                            <div class="flex justify-between items-center pt-2">
                                <div>
                                </div>
                                <button @click="generateDocumentPresignURL()"
                                    :disabled="!isUploaded || isDocumentUploading"
                                    :class="{ 'bg-gray-400 cursor-not-allowed text-white font-medium': !isUploaded || isDocumentUploading, 'bg-[#3F7E44] text-white': isUploaded && !isDocumentUploading }"
                                    class="modal-send-button px-3 py-2 text-base gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2">
                                    <p class="flex items-center" v-if="isDocumentUploading">
                                        Uploading...
                                    </p>
                                    <p v-else>
                                        Upload
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                   

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import { saveAs } from 'file-saver';

export default {
    name: "IIMImpactSurvey",
    data() {
        return {
            surveyData: [],
            surveyJson: {},
            user: [],
            surveyId: '',
            answerAgain: false,
            userSurveyDataId: '',
            isComplete: false,
            entityChosen: '',
            isSurveyLoading: false,
            excelData: null,
            filelist: [],
            xlsFilelist: [],
            uploadedFileNames: [],
            isFileUpload: false,
            file: null,
            uploadProgress: 0,
            isDocumentUploaded: false,
            isDocumentUploading: false,
            isUploaded: false,
        };
    },
    components: {
        pageLoader
    },
    async mounted() {
        this.user = await auth.me().catch(() => {
            console.log("Error login");
        });
        await this.fetchFile();
        const footerWrapper = document.getElementById("SITE_FOOTER_WRAPPER");
        if (footerWrapper) {
            footerWrapper.style.position = "fixed";
            footerWrapper.style.bottom = "0";
            footerWrapper.style.left = "0";
            footerWrapper.style.right = "0";
        }

        if (this.xlsFilelist.length) {
            this.isUploaded = true
        } else {
            this.isUploaded = false
        }
    },
    methods: {
        fetchFile() {
            return axios
                .get(env.apiUrl + "/imm/excel-template", {
                    responseType: 'blob',
                    headers: {
                        Authorization: "Bearer " + Cookies.get("session"),
                    },
                })
                .then((res) => {
                    this.excelData = res.data;
                });
        },
        saveFile() {
            saveAs(this.excelData, 'Imm.xlsx');
        },
        dragover(event) {
            event.preventDefault();
        },
        dragleave(event) {
            console.log(event);
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.handleFileChange();
        },
        handleFileChange(event) {
            this.file = event?.currentTarget?.files?.[0];
            const files = this.$refs.file.files;
            const xlsFiles = [];

            for (let i = 0; i < files.length; i++) {
                const fileExtension = files[i].name.split('.').pop().toLowerCase();
                if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                    if (!this.uploadedFileNames.includes(files[i].name)) {
                        this.uploadedFileNames.push(files[i].name);
                        if (this.xlsFilelist.length > 0) {
                            this.uploadedFileNames = this.uploadedFileNames.filter(name => !['xls', 'xlsx'].includes(name.split('.').pop().toLowerCase()));
                            this.xlsFilelist = [];
                        }
                        xlsFiles.push(files[i]);
                    }
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: `Unsupported file type: ${files[i].name}. Please upload files with .xls, .xlsx extensions.`,
                        showConfirmButton: true,
                    });
                }
            }

            this.xlsFilelist.push(...xlsFiles);
            this.filelist = [...this.xlsFilelist];
            this.uploadedFileNames = this.filelist.map((item) => item.name);
            if (this.xlsFilelist.length) {
                this.isUploaded = true;
            } else {
                this.isUploaded = false;
            }
        },
        generateDocumentPresignURL() {
            let body = {
                files: this.uploadedFileNames,
                urlType: "upload",
                module: "IMM_FINANCE_EXCEL",
            };

            if (this.uploadedFileNames.length > 0) {
                this.isDocumentUploading = true
                axios
                    .post(env.apiUrl + `/other/presignedUrl`, body, {
                        headers: {
                            Authorization: "Bearer " + Cookies.get("session"),
                        },
                    })
                    .then(async (res) => {
                        const fileRes = res.data.data;
                        if (fileRes.length) {
                            await this.uploadFile(fileRes);
                        }
                    });
            }
        },
        async uploadFile(dataArray) {
            try {
                const xlsDataArray = dataArray.filter(data => this.xlsFilelist.some(file => file.name === data.fileName));

                const xlsUploadPromises = xlsDataArray.map(async (data) => {
                    const preSignedURL = data.url;
                    const xlsFilefile = this.xlsFilelist.find(file => file.name === data.fileName);

                    if (xlsFilefile) {
                        const uploadResponseXls = await axios.put(preSignedURL, xlsFilefile, {
                            headers: {
                                'Content-Type': xlsFilefile.type,
                                'Access-Control-Allow-Origin': '*',
                            },
                            onUploadProgress: (progressEvent) => {
                                this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            }
                        });

                        if (uploadResponseXls.status === 200) {
                            return { key: data.key, name: xlsFilefile.name };
                        } else {
                            throw new Error(`XLS File upload failed for ${xlsFilefile.name}: ${uploadResponseXls.statusText}`);
                        }
                    }
                });

                const xlsResults = await Promise.all(xlsUploadPromises);
                const combinedResults = [...xlsResults];
                this.addDocuments(combinedResults);
            } catch (error) {
                console.error('An error occurred during file upload:', error.message);
            }
        },
        addDocuments(data) {
            axios
                .post(env.apiUrl + `/imm/excel-upload`, data[0], {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("session"),
                    },
                })
                .then(async (res) => {
                    this.isDocumentUploaded = true;
                    this.$swal.fire({
                        icon: "success",
                        text: `${res?.data?. message ? res?.data?. message : 'IMM Financial Excel uploaded successfully!'}`,
                        showConfirmButton: true,
                    });
                    this.isDocumentUploading = false;
                    setTimeout(() => {
                        this.isDocumentUploaded = false;
                    }, 3000);
                    this.resetUploadMeta()

                }).catch((error) => {
            this.isDocumentUploading = false; 
            let errorMessage = 'An error occurred while uploading the file.';
            this.$swal.fire({
                icon: "error",
                text: errorMessage,
                showConfirmButton: true,
            });
            this.resetUploadMeta()
            console.log(error)
        });
        },
        resetUploadMeta() {
            this.filelist = [];
            this.xlsFilelist = [];
            this.uploadedFileNames = [];
            this.file = null,
            this.isUploaded = false
        },
        removeSelectedFile(idx) {
            this.uploadedFileNames.splice(idx, 1)
            this.xlsFilelist.splice(idx, 1)
            this.filelist.splice(idx, 1)
            if (this.xlsFilelist.length) {
                this.isUploaded = true
            } else {
                this.isUploaded = false
            }
        },
        async fetchSurvey(tag) {
            try {
                this.isSurveyLoading = true
                const {
                    data: { data },
                    status,
                } = await axios.get(
                    `${env.apiUrl}/surveys/finance?tag=${tag}`,
                    {
                        headers: {
                            Authorization: "Bearer " + Cookies.get("session"),
                        },
                    }
                );
                if (status == 200) {
                    this.surveyJson = data.json;
                    this.isSurveyLoading = false
                }
            } catch (error) {
                console.log("Login error ", error);
            } finally {
                this.isSurveyLoading = false
            }
        },
        surveyComplete() {
            setTimeout(() => {
                this.$router.push({ path: "/imm/imm-landing-page/company" });
            }, "3000");
        },
    },
};
</script>
<style scoped lang="scss">
.ContentWrapper {
    margin-top: 10px;

    .SelectionBlock {
        background: #ffffff;
        border-radius: 10px;
        padding: 20px 40px 20px;
        max-width: 1000px;

        .column-wrapper {
            flex-direction: column;
        }
    }

    .standard-button {
        font-weight: bold;
        font-size: 10px;
        color: #191b45;
        border-style: solid;
        border-color: #191b45;
        border-width: 1px;
    }


    p {
        color: #717686;
    }
}

h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: #000;
}


router-link {
    cursor: pointer;
}

.ecosystem-landing {
    background-color: #fff;
    color: #21191d !important;
    font-family: Work Sans !important;

    .congrats-box-doc {
        border: 1px solid #2177c1;
        border-radius: 16px;
        padding: 32px, 0px, 32px, 0px;
        background: #eaf6ff;
        border-style: dashed;
        height: 160px;
    }

    .center-button {
        position: relative;
        top: 27px;
    }

    .center-span-modal {
        position: relative;
        top: 35px;
        line-height: 20px;
        font-family: arial;
        color: #191b45;
    }

    .upload-documents-button {
        font-weight: bold;
        color: white;
        background: #2177c1;
        padding: 8px 16px 8px 16px;
    }

    .breadcrumbs-main-container {
        margin-top: 0;
        padding-top: 7em;
    }

    .PoweredbyTitle_logo {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .PoweredbyTitleWrepper {
            max-width: 847px;
            width: 100%;
            display: flex;

            img {
                width: 100px;
                height: 100px !important;
                object-fit: contain;
                display: none;
            }
        }

        .Poweredby_logoImg {
            display: flex;
            justify-content: flex-end;

            .powered-text {
                span {
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 35px;
                    font-weight: 400;
                    color: #2c2e35;
                }
            }

            img {
                width: 100px;
                height: 33px;
                object-fit: contain;
                margin-right: 35px;
                max-width: 100px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
