<template>
    <div class="flex flex-col mx-auto w-11/12 mt-12 mb-12">
        <a-steps progress-dot :current="currentStep" size="small" @change="handleStepChange">
            <a-step :class="{ 'pointer-events-none': $store.state.certPageAccess.isMinSurveyFill }" title="Step 1"
                description="2X Challenge Self-Assessment" />
            <a-step title="Step 2" description="2X Challenge Self-Assessment Results" />
            <a-step :class="{ 'pointer-events-none': ($store.state.certPageAccess.isFullSurveyFill || twoxEligible !== 'Yes') }" title="Step 3"
                description="2X Certification Self-Assessment" />
            <a-step :class="{ 'pointer-events-none': !$store.state.certPageAccess.isFullSurveyFill }" title="Step 4"
                description="2X Certification Self-Assessment Results" />
            <a-step :class="{ 'pointer-events-none': $store.state.certPageAccess.isGlobalPage }" title="Step 5"
                v-if="$store.state.certPageAccess.isVerificationPage" description="Third Party Verification" />
            <a-step :class="{ 'pointer-events-none': !$store.state.certPageAccess.isUploadDocPage }" title="Step 5"
                v-else description="Upload Documents" />
            <a-step :class="{ 'pointer-events-none': !$store.state.certPageAccess.isGlobalPage }" title="Step 6"
                description="2X Global Certification" />
            <a-step :class="{ 'pointer-events-none': !$store.state.certPageAccess.isGlobalPage }" title="Step 7"
                description="Take Action" />
        </a-steps>
    </div>
</template>

<script>
export default {
    name: "MinSurveyStepsComponentTwox",
    props: {
        currentStep: Number,
        userStatus: String,
        isAllFullSurveysCompleted: Boolean,
        twoxEligible: String,
    },
    data: function () {
        return {
            isFullVerify: ["Validation in Process", "Full Survey Submitted"],
        };
    },
    methods: {
        handleStepChange(step) {
            switch (step) {
                case 0:
                    if (this.$store.state.certPageAccess.isMinSurveyFill) break;
                    this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
                    break;
                case 1:
                    this.$router.push({
                        path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
                    });
                    break;
                case 2:
                    if (this.$store.state.certPageAccess.isFullSurveyFill || this.twoxEligible !== 'Yes') break;
                    this.$router.push({ path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment"});
                    break;
                case 3:
                    if (!this.$store.state.certPageAccess.isFullSurveyFill) break;
                    this.$router.push({
                        path: "/2X-Ecosystem/2X-Certification/PreliminaryResults",
                    });
                    break;
                case 4:
                    if (!this.$store.state.certPageAccess.isUploadDocPage) break;
                    if (this.$store.state.certPageAccess.isVerificationPage) {
                        this.$router.push({
                            path: "/2X-Ecosystem/2X-Certification/Verification",
                        });
                    } else {
                        this.$router.push({
                            path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
                        });
                    }
                    break;
                case 5:
                    if (!this.$store.state.certPageAccess.isGlobalPage) break;
                    this.$router.push({
                        path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
                    });
                    break;
                case 6:
                    if (!this.$store.state.certPageAccess.isScoreCardPage) break;
                    this.$router.push({
                        path: "/2X-Ecosystem/2X-Certification/score-card",
                    });
                    break;

                default:
                    break;
            }
        },
    },
};
</script>
