<template>
  <div class="disclaimer-permission" :class="{ open: this.openTwoxDisclaimer }">
    <div class="container">
      <div class="flex flex-col gap-1 text-sm" v-if="!showFullDisclaimerText">
        <p>
          The information provided is for general informational purposes only
          and is not intended to constitute legal, financial, or investment
          advice, with any reliance placed on it strictly at at your own risk. We
          do not guarantee the accuracy, relevance, timeliness, or completeness
          of information from external sources linked to this .....
          <span class="cursor-pointer text-[#FE5000] font-bold" @click="showFullDisclaimerText = true">Read more</span>
        </p>
      </div>
      <div class="flex flex-col gap-1 text-sm" v-else>
        <p>
          The information provided is for general informational purposes only
          and is not intended to constitute legal, financial, or investment
          advice, with any reliance placed on it strictly at your own risk. We
          do not guarantee the accuracy, relevance, timeliness, or completeness
          of information from external sources linked to this site, and the
          views expressed do not necessarily reflect the official policy of 2X
          Global or its members; we disclaim all liability for damages arising
          from the use of or reliance on this information.
        </p>
        <p>
          Contact Information: For any questions regarding this disclaimer,
          please contact us at
          <a href="mailto:certification@2xglobal.org" class="a-tag-decorate"
            >certification@2xglobal.org</a
          >
        </p>
        <p>
          This disclaimer is subject to change without notice. Please visit our
          website periodically for updates.
        </p>
      </div>
      <button
        type="button"
        class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg transition  duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
        style="background-color: rgb(254, 80, 0); color: white !important; "
        @click="confirmDisclaimer"
      >
        <span>Hide</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "TwoXDisclaimer",
  data: () => {
    return {
      showFullDisclaimerText: false,
    };
  },
  props: {
    openTwoxDisclaimer: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    confirmDisclaimer() {
      localStorage.setItem("2x-disclaimer-accepted", "true");
      this.$emit("setDisclaimer", false);
    },
  },
};
</script>
<style scoped>
.disclaimer-permission {
  position: fixed;
  bottom: 0;
  opacity: 0;
  left: 0;
  width: 100%;
  padding: 15px 0px;
  background-color: #201747;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  transform: scaleY(0);
  transform-origin: bottom;
}
.disclaimer-permission.open {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transform: scaleY(1);
}
.disclaimer-permission .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.disclaimer-permission .container p {
  color: white;
  padding: 0 20px 0 0;
}
.disclaimer-permission .container button {
  white-space: nowrap;
}
.a-tag-decorate {
  text-decoration: underline;
}
</style>
