<template>
  <div class="main_div">
    <h1>We&rsquo;ll be back soon!</h1>
    <div>
      <p>
        Sorry for the inconvenience but we&rsquo;re performing some maintenance
        at the moment. We&rsquo;ll be back online shortly!
      </p>
      <p>&mdash; Equilo Team</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "maintenance",
  data: function () {
    return {};
  },
};
</script>
<style scoped>
.main_div {
  text-align: center;
  margin-top: 5rem;
}
body {
  text-align: center;
  padding: 150px;
}
p {
  font-size: larger;
}
h1 {
  font-size: 50px;
}
body {
  font: 20px Helvetica, sans-serif;
  color: #333;
}
article {
  display: block;
  text-align: left;
  width: 650px;
  margin: 0 auto;
}
a {
  color: #dc8100;
  text-decoration: none;
}
a:hover {
  color: #333;
  text-decoration: none;
}
</style>