<template>
  <div class="static genderPagestyle">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div class="container mx-auto" v-if="endofcreated && toolsData && filterData">
        <div class="PoweredbyTitle_logo flex justify-between w-full pt-3">
          <div class="PoweredbyTitleWrepper flex">
            <h1 class="text-5xl tracking-tighter font-bold print:text-7xl">
              Gender Smart Tools
            </h1>
          </div>
          <div class="Poweredby_logoImg flex">
            <span class="mb-auto pt-4">Powered by:</span>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
        <div class="gender_smart_button">
          <div class="inner_section_gender_smart_button">
            <router-link to="/submitGenderSmartTools">
              <button class="text-white text-sm font-medium" style="width: max-content">
                Submit a Tool
              </button>
            </router-link>
          </div>
        </div>
        <div class="AssementPageWrepper flex">
          <div class="AssementPageWrepper_Left h-full">
            <div class="AssementPageWrepper_Box flex justify-between pb-6">
              <h3 class="text-2xl font-bold print:text-2xl Deals_Heading">
                Tools For You
              </h3>
              <p class="text-lg font-light" style="color: black;font-weight: 500;">
                {{ totalCount }} results
              </p>
            </div>
            <div class="cardBox">
              <div class="card flex justify-between" v-for="(tool, index) in toolsData" :key="index">
                <div class="cardLeft">
                  <h5 class="text-lg font-bold pb-3">
                    {{ tool.data.resource }}
                  </h5>
                  <div class="Rating flex items-center gap-2 pb-3">
                    <img v-if="
                      tool.ratingData.data && tool.ratingData.data.length > 0
                    " src="@/assets/images/roi/star.png" alt="background-image" />
                    <img v-else src="@/assets/images/roi/star-grey.svg" alt="background-image" />
                    <p v-if="tool.ratingData">
                      <strong class="text-sm font-bold" style="color: #1b1b1b" v-if="
                        tool.ratingData.data &&
                        tool.ratingData.data.length > 0
                      ">{{ tool.ratingData.average | calculateRating }}
                      </strong>
                      <strong v-else>No Reviews</strong>
                      | by {{ tool.data.organization }}
                    </p>
                  </div>
                  <div class="">
                    <p class="text-sm font-bold pb-3" v-if="tool.data.toolFormat && tool.data.toolFormat.length"
                      style="color: #1b1b1b">
                      Format
                      <span class="inline-block" :class="colorClass[formatIndex]"
                        v-for="(format, formatIndex) in tool.data.toolFormat" :key="formatIndex">{{ format }}</span>
                    </p>
                    <p class="text-sm font-bold pb-5" v-if="tool.data.access && tool.data.access.length"
                      style="color: #1b1b1b">
                      Access
                      <span>{{ tool.data.access | textWithSlash }}</span>
                    </p>
                  </div>
                  <div class="flex items-center gap-1">
                    <a href="javascript:void(0)" @click="readMoreOpen(tool)" class="font-bold cursor-pointer"
                      style="color: #1b1b1b">
                      Read more</a>
                    <img src="@/assets/images/plushIcon.png" alt="background-image" class="cursor-pointer"
                      @click="readMoreOpen(tool)" />
                  </div>
                </div>
                <div class="cardRight">
                  <div class="contOne pb-6" v-if="tool.data.industry && tool.data.industry.length">
                    <p class="text-sm font-bold" style="color: #1b1b1b">
                      Industries
                    </p>
                    <span class="inline-block" :class="colorClass[industIndex]"
                      v-for="(indust, industIndex) in tool.data.industry" :key="industIndex" v-show="industIndex < 6">{{
                          indust
                      }}</span>
                    <span class="inline-block" style="margin: auto">
                      <img v-if="tool.data.industry.length > 6" src="@/assets/images/roi/dot.svg" />
                    </span>
                  </div>
                  <div class="contSecond" v-if="
                    tool.data.thematicAreas && tool.data.thematicAreas.length
                  ">
                    <p class="text-sm font-bold">Thematic Areas</p>
                    <span class="inline-block" :class="colorClass[thematicIndex]" v-for="(thematic, thematicIndex) in tool.data
                    .thematicAreas" :key="thematicIndex" v-show="thematicIndex < 4">{{ thematic }}</span>
                    <span class="inline-block" style="margin: auto">
                      <img v-if="tool.data.thematicAreas.length > 4" src="@/assets/images/roi/dot.svg" />
                    </span>
                  </div>
                </div>
                <div class="btn flex flex-col items-end">
                  <div class="logo">
                    <img src="@/assets/images/red-heart.png" alt="background-image" v-if="tool.like"
                      class="cursor-pointer" @click="likeTool(tool.data.recordId)" />
                    <img src="@/assets/images/like-btn-bg.png" alt="background-image" v-if="!tool.like"
                      class="cursor-pointer" @click="likeTool(tool.data.recordId)" />
                  </div>
                  <Button class="loadmore-btn flex mt-auto justify-center" @click="launchGenderTools(tool.data.link)">
                    Launch</Button>
                </div>
              </div>
            </div>

            <Button class="loadmore-btn flex mx-auto justify-center" v-if="
              toolsData && toolsDataLength < totalCount && toolsDataLength > 0
            " @click="loadMore()">
              Load More</Button>
          </div>
          <div class="AssementPageWrepper_Right h-full">
            <div class="
                text-sm
                leading-5
                text-gray-900
                w-full
                relative
                flex flex-row
                items-center
                mb-8
              ">
              <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <!-- <title>Artboard 329</title> -->
                <circle cx="11.059" cy="11.059" r="7.059" fill="none" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1.5" />
                <line x1="20" y1="20" x2="16.05" y2="16.05" fill="none" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1.5" />
                <rect width="24" height="24" fill="none" />
              </svg>
              <input type="text" class="
                  border-2 border-gray-200
                  px-3
                  py-2
                  text-sm
                  w-full
                  pl-10
                  font-light
                  focus:outline-none focus:shadow-outline
                " v-model="query" placeholder="Search" />
            </div>
            <!-- <div class="filter flex flex-row items-center gap-3">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14 6H12V0H14V2H18V4H14V6ZM0 4V2H10V4H0ZM0 16V14H6V16H0ZM10 18V16H18V14H10V12H8V18H10ZM4 8V6H6V12H4V10H0V8H4ZM18 10V8H8V10H18Z"
                  fill="#1B1B1B" />
              </svg>
              <h5 class="text-lg font-bold leading-5">Filter by</h5>
              <div style="width: 48%;text-align: right">
                <span class="cursor-pointer font-semibold" @click="resetFilters()">Reset</span>
              </div>
            </div> -->
            <filter-section 
              :query="query" 
              :filters="filtersData" 
              @updateResults="updateResults" 
              @resetSearchBox="resetSearchBox">
            </filter-section>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-row justify-center items-center mt-3 ml-20">
          <div class="flex flex-col item-center justify-center">
            <div class="flex flex-row items-center justify-center print:flex-col">
              <img class="h-16 print:h-auto print:w-64" src="@/assets/images/2X-assessment/gbvh-roadmap.png" alt="" />
              <h1 class="
                  text-6xl
                  tracking-tighter
                  font-medium
                  text-center
                  print:text-7xl
                  ml-5
                ">
                No Data Found
              </h1>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="Modal-Box modal" id="consentModal" :key="updateKey"
      v-if="endofcreated && toolsData && isToolModalOpened">
      <div class="modal-content">
        <div class="Modal-Header flex">
          <div class="ModalBox-Heading">
            <h2 class="font-bold">
              {{ modalToolData.data.resource }}
            </h2>
            <div class="flex items-center gap-2 pb-3">
              <img v-if="
                modalToolData.ratingData.data &&
                modalToolData.ratingData.data.length > 0
              " src="@/assets/images/roi/star.png" alt="background-image" class="cursor-pointer"
                @click="scrollToReviewSection" />
              <img v-else src="@/assets/images/roi/star-grey.svg" alt="background-image" />
              <p v-if="allReview">
                <strong class="text-sm font-bold cursor-pointer" style="color: #1b1b1b" @click="scrollToReviewSection"
                  v-if="
                    modalToolData.ratingData.data &&
                    modalToolData.ratingData.data.length > 0
                  ">{{ allReview.average | calculateRating }}
                </strong>
                <strong v-else>No Reviews</strong>
                | by {{ modalToolData.data.organization }}
              </p>
            </div>
          </div>
          <div class="Model-Icon flex" :key="updateKey">
            <div class="icon pr-4 pl-5">
              <svg width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="cursor-pointer" v-if="!modalToolData.like" @click="likeTool(modalToolData.data.recordId)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18 12.2417C18.9083 11.175 20.3 10.5 21.75 10.5C24.3166 10.5 26.3333 12.5167 26.3333 15.0833C26.3333 18.2307 23.5046 20.7958 19.2189 24.6821L19.2083 24.6917L18 25.7917L16.7916 24.7L16.7587 24.6701C12.4855 20.7869 9.66663 18.2253 9.66663 15.0833C9.66663 12.5167 11.6833 10.5 14.25 10.5C15.7 10.5 17.0916 11.175 18 12.2417ZM18 23.5417L18.0833 23.4583C22.05 19.8667 24.6666 17.4917 24.6666 15.0833C24.6666 13.4167 23.4166 12.1667 21.75 12.1667C20.4666 12.1667 19.2166 12.9917 18.7833 14.1333H17.225C16.7833 12.9917 15.5333 12.1667 14.25 12.1667C12.5833 12.1667 11.3333 13.4167 11.3333 15.0833C11.3333 17.4917 13.95 19.8667 17.9166 23.4583L18 23.5417Z"
                  fill="#D0D0D0" />
              </svg>
              <img src="@/assets/images/red-heart.png" alt="background-image"
                style="width: 40px; top: 5px; position: relative" v-if="modalToolData.like" class="cursor-pointer"
                @click="likeTool(modalToolData.data.recordId)" />
            </div>
            <Button class="loadmore-btn flex justify-center border" @click="launchGenderTools(modalToolData.data.link)">
              Launch</Button>
          </div>
          <div class="close-button">
            <span class="close" @click="closePopup">&times;</span>
          </div>
        </div>
        <div class="Modal-Content flex">
          <div class="Modal-Left">
            <p class="Modal-Para">
              {{ modalToolData.data.description }}
              <br />
              <br />
              <span>Learn more:
                <a :href="modalToolData.data.link" target="_blank" style="color: blue; word-wrap: break-word">
                  {{ modalToolData.data.link }}</a></span>
            </p>
            <div class="Modal-tag pt-8" v-if="
              modalToolData.data.industry &&
              modalToolData.data.industry.length
            ">
              <p class="Tag-title">Industries</p>
              <span class="inline-block" :class="colorClass[industIndex]"
                v-for="(indust, industIndex) in modalToolData.data.industry" :key="industIndex">{{ indust }}</span>
            </div>
            <div class="Modal-tag pt-8" v-if="
              modalToolData.data.thematicAreas &&
              modalToolData.data.thematicAreas.length
            ">
              <p class="Tag-title">Thematic Areas</p>
              <span class="inline-block" :class="colorClass[thematicIndex]" v-for="(thematic, thematicIndex) in modalToolData.data
              .thematicAreas" :key="thematicIndex">{{ thematic }}</span>
            </div>
            <div class="Modal-tag pt-8" v-if="
              modalToolData.data.geography &&
              modalToolData.data.geography.length
            ">
              <p class="Tag-title">Geography</p>
              <span class="inline-block" :class="colorClass[geogIndex]"
                v-for="(geog, geogIndex) in modalToolData.data.geography" :key="geogIndex">{{ geog }}</span>
            </div>
            <div class="Modal-tag pt-8" v-if="
              modalToolData.data.useCases &&
              modalToolData.data.useCases.length
            ">
              <p class="Tag-title">Use Cases</p>
              <span class="inline-block" :class="colorClass[useCaseIndex]"
                v-for="(useCase, useCaseIndex) in modalToolData.data.useCases" :key="useCaseIndex">{{ useCase }}</span>
            </div>
          </div>
          <div class="Modal-Right">
            <div class="Right-Aside">
              <div class="Modal-Aside-content" v-if="
                modalToolData.data.toolFormat &&
                modalToolData.data.toolFormat.length
              ">
                <p class="title">Format</p>
                <span class="inline-block greybtn" v-for="(format, formatIndex) in modalToolData.data.toolFormat"
                  :key="formatIndex">{{ format }}</span>
              </div>
              <div class="Modal-Aside-content" v-if="
                modalToolData.data.access && modalToolData.data.access.length
              ">
                <p class="title">Access</p>
                <p>{{ modalToolData.data.access | textWithSlash }}</p>
              </div>
              <div class="Modal-Aside-content" v-if="
                modalToolData.data.yearDeveloped &&
                modalToolData.data.yearDeveloped
              ">
                <p class="title">Year</p>
                <p>{{ modalToolData.data.yearDeveloped | mathOnlyRound }}</p>
              </div>
              <div class="Modal-Aside-content" v-if="
                modalToolData.data.audience &&
                modalToolData.data.audience.length
              ">
                <p class="title">Audience</p>
                <span class="inline-block" v-for="(aud, audIndex) in modalToolData.data.audience" :key="audIndex">{{ aud
                }}</span>
              </div>
              <div class="Modal-Aside-content" v-if="
                modalToolData.data.stageInInvestmentProcess &&
                modalToolData.data.stageInInvestmentProcess.length
              ">
                <p class="title">Stage in investment process</p>
                <span>{{
                    modalToolData.data.stageInInvestmentProcess | textWithComma
                }}</span>
              </div>
              <div class="Modal-Aside-content" v-if="modalToolData.data.publicPrivateMarkets">
                <p class="title">Public/Private Markets</p>
                <span>{{ modalToolData.data.publicPrivateMarkets }}</span>
              </div>
              <div class="Modal-Aside-content" v-if="
                modalToolData.data.assetClass &&
                modalToolData.data.assetClass.length
              ">
                <p class="title">Asset Class</p>
                <span>{{ modalToolData.data.assetClass | textWithComma }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="Modal-Bottom" v-if="allReview" id="reviewSection">
          <div class="Bottom-Header">
            <div class="Rating">
              <div class="flex items-center gap-2 pb-3">
                <div>
                  <img v-if="
                    modalToolData.ratingData.data &&
                    modalToolData.ratingData.data.length > 0
                  " src="@/assets/images/roi/star.png" alt="background-image" />
                  <img v-else src="@/assets/images/roi/star-grey.svg" alt="background-image" />
                </div>
                <strong class="text-lg font-bold" style="color: #1b1b1b">
                  <span v-if="
                    modalToolData.ratingData.data &&
                    modalToolData.ratingData.data.length > 0
                  ">
                    {{ allReview.average | calculateRating }} |
                  </span>
                  {{
                      allReview.data && allReview.data.length
                        ? allReview.data.length
                        : 0
                  }}
                  Review
                </strong>
              </div>
            </div>
            <div class="btn">
              <Button class="loadmore-btn flex justify-center border" @click="openReviewModal(modalToolData)">
                Write a review</Button>
            </div>
          </div>
          <div class="Testominal" v-if="allReview && allReview.data && allReview.data.length">
            <div class="Testominal-Card" v-for="review in allReview.data" :key="review">
              <div class="flex items-center gap-3">
                <div style="
                    width: 2.5rem;
                    overflow: hidden;
                    border-radius: 50px;
                    height: 2.5rem;
                  ">
                  <img v-if="review && review.picture" :src="review.picture" alt="background-image"
                    style="border-radius: 50px; object-fit: contain;height:40px;width:40px" />
                  <img v-else src="@/assets/images/profile7.png" alt="background-image"
                    style="border-radius: 50px; object-fit: contain;height:40px;width:40px" />
                </div>
                <div class="">
                  <h6>{{ review.userName }}</h6>
                  <p>{{ review.createdAt | getShortMonthYear }}</p>
                </div>
              </div>
              <div class="flex items-center pt-4 pb-1">
                <img v-for="rate in review.rating" :key="rate" src="@/assets/images/roi/star.png"
                  alt="background-image" />
              </div>
              <div class="">
                <p style="word-wrap: break-word">
                  {{ review.comment }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Review Modal -->
    <div class="fixed z-10 inset-0 review_modal_container" v-if="isReviewModalOpened && reviewData">
      <div class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        ">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle
          " role="dialog" aria-modal="true" aria-labelledby="modal-headline"
          style="width: 35% !important; margin-top: 6rem">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" style="
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
            ">
            <div class="sm:flex sm:items-start" style="height: auto">
              <div class="
                  mt-3
                  text-center
                  sm:mt-0 sm:text-left
                  learn_more_modal_text_container
                " style="width: 100%">
                <div class="Testominal-Card">
                  <div class="flex items-center gap-3">
                    <div style="
                        width: 2.5rem;
                        overflow: hidden;
                        border-radius: 50px;
                        height: 2.5rem;
                      ">
                      <img v-if="user.picture" :src="user.picture" alt="background-image"
                        style="border-radius: 50px; object-fit: contain;height:40px;width:40px" />
                      <img v-else src="@/assets/images/profile7.png" alt="background-image"
                        style="border-radius: 50px; object-fit: contain;height:40px;width:40px" />
                    </div>
                    <div class="">
                      <h6>
                        <span v-if="user.firstname">{{
                            user.firstname + " "
                        }}</span>
                        <span v-if="user.lastname">{{ user.lastname }}</span>
                      </h6>
                      <div class="flex" style="align-items: center">
                        <img class="cursor-pointer" v-for="(n, i) in repeat" :key="'image' + i" :id="n" :src="
                          require(`@/assets/images/roi/${selectedRatingData[i]}`)
                        " @click="selectStarRating(i)" alt="background-image" />
                        <span class="ml-4"> {{ selectedRatingCount }}</span>
                      </div>
                    </div>
                    <div class="close-button">
                      <span class="close" @click="closeReviewModal">&times;</span>
                    </div>
                  </div>
                  <!-- <div class="flex items-center pt-4 pb-1">
                    <img src="@/assets/images/roi/star.png" alt="background-image" />
                  </div> -->
                  <div class="mt-4">
                    <textarea v-model="reviewMessage" maxlength="150" />
                  </div>
                </div>
                <button style="background-color: #fe5000; color: white !important" class="
                    text-white text-base
                    px-3
                    py-2
                    text-xs
                    gap-1
                    font-bold
                    rounded-lg
                    text-white
                    transition
                    duration-200
                    shadow
                    flex flex-row
                    items-center
                    justify-center
                    mr-4
                    ml-4
                    submit_review_button
                  " :class="reviewMessage ? '' : 'pointer-events-none'" @click="submitReview()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Review Modal -->

    <!-- External Link Popup -->
    <div class="fixed z-50 inset-0 overflow-y-auto" id="dealsPrivacy" v-if="isExternalLinkPopup">
      <div class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        ">
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle
          " role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 630px; width: 100%">
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <h1 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                <!-- GBVH Privacy Policy -->
              </h1>
              <p>
                You are being redirected to an external web page, please click
                confirm to continue.
              </p>
              <div class="flex mt-8">
                <button style="
                    background-color: #fe5000;
                    color: white !important;
                    width: 100%;
                    margin-left: 50%;
                  " class="
                    text-white
                    px-3
                    py-2
                    text-xs
                    gap-1
                    font-bold
                    rounded-lg
                    text-white
                    transition
                    duration-200
                    shadow
                    flex flex-row
                    items-center
                    justify-center
                    mr-4
                    ml-4
                  " @click="redirectExternalPopup()">
                  Confirm
                </button>
                <button style="background-color: #201747; color: white !important" class="
                    text-white
                    px-3
                    py-2
                    text-xs
                    gap-1
                    font-bold
                    rounded-lg
                    text-white
                    transition
                    duration-200
                    shadow
                    flex flex-row
                    items-center
                    justify-center
                    mr-4
                    ml-4
                  " @click="cancelExternalPopup()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End External Link Popup -->
  </div>
</template>

<script>
import env from "@/../env";
import axios from "axios";
import auth from "@/helpers/auth";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import filterSection from "@/components/genderSmartToolsFilterSection.vue";
export default {
  name: "GenderSmartTools",
  data: function () {
    return {
      fetchedToolsData: [],
      user: [],
      query: "",
      active: false,
      endofcreated: false,
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      toolsData: [],
      colorClass: [
        "greenbtn",
        "babypink",
        "lightblue",
        "yellolight",
        "powerBlue",
        "greybtn",
        "lightGreen",
        "purple",
        "lomengreen",
        "skyblue",
        "pinklight",
        "glassgreen",
        "pink",
        "greenbtn",
        "greybtn",
        "babypink",
        "lightblue",
        "yellolight",
        "powerBlue",
        "lightGreen",
        "purple",
        "lomengreen",
        "skyblue",
        "pinklight",
        "glassgreen",
        "pink",
        "greybtn",
      ],
      modalToolData: [],
      filterData: [],
      selectedFilters: [],
      isReviewModalOpened: false,
      isToolModalOpened: false,
      reviewData: [],
      reviewMessage: "",
      allReview: [],
      toolsDataLength: 0,
      totalCount: 0,
      repeat: 5,
      selectedRatingCount: 0,
      selectedRatingData: [
        "star-grey.svg",
        "star-grey.svg",
        "star-grey.svg",
        "star-grey.svg",
        "star-grey.svg",
      ],
      updateKey: 1,
      page: 1,
      limit: 10,
      isExternalLinkPopup: false,
      externalLink: "",
      initialFilterData: [],
    };
  },
  components: {
    pageLoader,
    filterSection,
  },
  methods: {
    resetSearchBox() {
      this.query = ""
    },
    scrollToReviewSection() {
      document.getElementById("reviewSection").scrollIntoView();
    },
    loadMore() {
      this.page++;
      this.limit = this.limit + 10;
      this.getToolsData();
    },
    launchGenderTools(link) {
      if (link.includes("app.equilo.io")) {
        window.open(link, "_blank");
      } else {
        this.isExternalLinkPopup = true;
        this.externalLink = link;
      }
    },
    cancelExternalPopup() {
      this.isExternalLinkPopup = false;
      this.externalLink = "";
    },
    redirectExternalPopup() {
      window.open(this.externalLink, "_blank");
      this.isExternalLinkPopup = false;
      this.externalLink = "";
    },
    selectStarRating(index) {
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      let count = index + 1;
      let selectImage = "star.png";
      let unSelectImage = "star-grey.svg";
      arr1 = Array(count).fill(selectImage);
      arr2 = Array(5 - count).fill(unSelectImage);
      arr = arr1.concat(arr2);
      this.selectedRatingCount = count;
      this.selectedRatingData = arr;
    },
    openReviewModal(data) {
      this.reviewData = [];
      this.reviewData = data;
      this.isReviewModalOpened = true;
    },
    updateResults(arr) {
      let tempFilterData = [];
      tempFilterData = Object.entries(this.initialFilterData);
      let sortArray = [];
      tempFilterData.filter((it) => {
        if (it[0] == "Use_Cases") {
          sortArray.splice(0, 0, it);
        }
        if (it[0] == "Audience") {
          sortArray.splice(1, 0, it);
        }
        if (it[0] == "Industry") {
          sortArray.splice(2, 0, it);
        }
        if (it[0] == "Market") {
          sortArray.splice(3, 0, it);
        }
        if (it[0] == "Asset_Class") {
          sortArray.splice(4, 0, it);
        }
        if (it[0] == "Thematic_area") {
          sortArray.splice(5, 0, it);
        }
        if (it[0] == "Geography") {
          sortArray.splice(6, 0, it);
        }
      });
      Object.entries(this.initialFilterData).filter((it) => {
        if (it[0] == "Format") {
          sortArray.push(it);
        }
      });
      Object.entries(this.initialFilterData).filter((it) => {
        if (it[0] == "Access") {
          sortArray.push(it);
        }
      });

      let tempFilterData2 = [];
      this.selectedFilters = [];
      sortArray.forEach((data) => {
        var selectedItem = [];
        data[1].forEach((item) => {
          arr.forEach((a) => {
            const lastIndex = a.lastIndexOf("&");
            const beforeText = a.slice(0, lastIndex);
            const afterText = a.slice(lastIndex + 1);
            if (
              item.toLowerCase() == beforeText.toLowerCase() &&
              item != "Show All"
            ) {
              let regInd = afterText;
              if (regInd && data[0] == regInd) {
                selectedItem.push(item);
              }
            }
          });
        });
        let objKey = "";
        if (data[0] == "Format") {
          objKey = "toolFormat";
        }
        if (data[0] == "Access") {
          objKey = "access";
        }
        if (data[0] == "Industry") {
          objKey = "industry";
        }
        if (data[0] == "Thematic_area") {
          objKey = "thematicAreas";
        }
        if (data[0] == "Geography") {
          objKey = "geography";
        }
        if (data[0] == "Use_Cases") {
          objKey = "useCases";
        }
        if (data[0] == "Audience") {
          objKey = "audience";
        }
        if (data[0] == "Market") {
          objKey = "publicPrivateMarkets";
        }
        if (data[0] == "Asset_Class") {
          objKey = "assetClass";
        }
        var obj = { [objKey]: selectedItem };
        tempFilterData2.push(obj);
      });
      tempFilterData2.forEach((data) => {
        if (Object.entries(data)[0][1].length) {
          if (data.audience && data.audience.length) {
            if (!Object.entries(data)[0][1].includes('All audiences'))
              Object.entries(data)[0][1].push('All audiences');
          }
          if (data.industry && data.industry.length) {
            if (!Object.entries(data)[0][1].includes('Industry-agnostic'))
              Object.entries(data)[0][1].push('Industry-agnostic');
          }
          if (data.publicPrivateMarkets && data.publicPrivateMarkets.length) {
            if (!Object.entries(data)[0][1].includes('Both public and private') && !Object.entries(data)[0][1].includes('Not Applicable'))
              Object.entries(data)[0][1].push('Both public and private');
          }
          if (data.assetClass && data.assetClass.length) {
            if (!Object.entries(data)[0][1].includes('Any asset class'))
              Object.entries(data)[0][1].push('Any asset class');
          }
          if (data.geography && data.geography.length) {
            if (!Object.entries(data)[0][1].includes('Global'))
              Object.entries(data)[0][1].push('Global');
          }
          this.selectedFilters.push(data);
        }
      });
      this.page = 1;
      this.limit = 10;
      this.getToolsData();
    },
    readMoreOpen(data) {
      this.modalToolData = [];
      this.isToolModalOpened = true;
      this.modalToolData = data;
      this.allReview = data.ratingData ? data.ratingData : [];
    },
    closePopup() {
      this.isToolModalOpened = false;
    },
    closeReviewModal() {
      this.isReviewModalOpened = false;
    },
    mouseOver: function (index) {
      this.active = index;
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel() {
      this.visible = false;
    },
    getToolsData() {
      let body = {};
      if (this.selectedFilters && this.selectedFilters.length > 0) {
        this.selectedFilters.forEach((data) => {
          Object.assign(body, data);
        });
      } else {
        body;
      }
      axios
        .post(env.apiUrl + "/tools/all-data", body, {
          params: {
            page: this.page,
            limit: this.limit,
          },
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.toolsData = [];
          this.fetchedToolsData = res.data;
          this.toolsData = res.data.data;
          this.toolsDataLength = res.data.total;
          this.totalCount = res.data.totalCount;
        });
    },
    getFiltersData() {
      axios
        .get(env.apiUrl + "/tools/filter", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.filterData = res.data;
          this.initialFilterData = res.data;
        });
    },
    getReviewData(recordId) {
      axios
        .get(env.apiUrl + "/tools/rating-data", {
          params: {
            toolsId: recordId,
          },
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.allReview = res.data;
        });
    },
    submitReview() {
      let body = {
        toolsId: this.modalToolData.data.recordId,
        rating: this.selectedRatingCount,
        comment: this.reviewMessage,
      };
      axios
        .post(env.apiUrl + "/tools/rating", body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          if (res.status == 200) {
            await this.getToolsData();
            setTimeout(async () => {
              await this.updateReviewSection();
            }, 1500);
          }
        });
    },
    updateReviewSection() {
      if (this.isToolModalOpened) {
        this.toolsData.map((data) => {
          if (data.data.recordId == this.modalToolData.data.recordId) {
            this.modalToolData.ratingData.data.push(
              data.ratingData.data[data.ratingData.data.length - 1]
            );
            this.allReview = data.ratingData;
          }
        });

        this.reviewMessage = "";
        this.selectedRatingCount = 0;
        this.isReviewModalOpened = false;
        this.selectedRatingData = [
          "star-grey.svg",
          "star-grey.svg",
          "star-grey.svg",
          "star-grey.svg",
          "star-grey.svg",
        ];
      }
    },
    likeTool(recordId) {
      let body = {
        recordId: recordId,
      };
      axios
        .post(env.apiUrl + "/tools/save-assesments", body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          if (res.status == 200) {
            await this.getToolsData();
            if (this.isToolModalOpened) {
              this.toolsData.map((data) => {
                if (data.data.recordId == this.modalToolData.data.recordId) {
                  this.updateKey++;
                  this.modalToolData.like = !res.data.result.saved;
                }
              });
              this.updateKey++;
            }
          }
        });
    },
  },
  async created() { },
  computed: {
    filtersData() {
      var data = [];
      let tempFilterData = [];
      console.log("filterData", Object.entries(this.filterData));
      Object.entries(this.filterData).forEach((da) => {
        var selectedItem = [];
        da[1].forEach((item) => {
          if (item.toLowerCase().includes(this.query.toLowerCase())) {
            selectedItem.push(item);
          }
        });
        var obj = { [da[0]]: selectedItem };
        tempFilterData.push(obj);
        let tempData = [];
        tempFilterData.forEach((data) => {
          if (Object.entries(data)[0][1].length) {
            tempData.push(data);
          }
        });
        data = tempData;
      });
      let sortArray = [];
      console.log("tempFilterData", data);
      data.forEach((it) => {
        if (Object.entries(it)[0][0] == "Use_Cases") {
          sortArray.splice(0, 0, it);
        }
        if (Object.entries(it)[0][0] == "Audience") {
          sortArray.splice(1, 0, it);
        }
        if (Object.entries(it)[0][0] == "Industry") {
          sortArray.splice(2, 0, it);
        }
        if (Object.entries(it)[0][0] == "Market") {
          sortArray.splice(3, 0, it);
        } 
        if (Object.entries(it)[0][0] == "Asset_Class") {
          sortArray.splice(4, 0, it);
        }
        if (Object.entries(it)[0][0] == "Thematic_area") {
          sortArray.splice(5, 0, it);
        }
        if (Object.entries(it)[0][0] == "Geography") {
          sortArray.splice(6, 0, it);
        }
      });
      data.forEach((it) => {
        if (Object.entries(it)[0][0] == "Format") {
          sortArray.push(it);
        }
      });
      data.forEach((it) => {
        if (Object.entries(it)[0][0] == "Access") {
          sortArray.push(it);
        }
      });
      return sortArray;
    },
    computeReviewAverage() {
      if (this.allReview) {
        return Math.round((this.allReview.average * 5) / 100);
      } else {
        return 0;
      }
    },
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    this.$store.commit("resetCitations");
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      // { name: "2X Assessment", url: "/2X-Ecosystem" },
      { name: "Gender Smart Tools", url: "/GenderSmartTools" },
    ]);
    await this.getToolsData();
    await this.getFiltersData();
    this.endofcreated = true;
  },
  watch: {
    // activeKey(key) {
    //   console.log(key);
    // },
    modalToolData(data) {
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

.review_modal_container {
  textarea {
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    width: 100%;
  }

  .submit_review_button {
    margin: auto 0 auto auto;
  }

  .close-button {
    // width: 14%;
    text-align: right;
    top: 0px;
    // position: relative;
    right: 20px;
    position: absolute;

    span {
      font-size: 40px;
      cursor: pointer;
    }
  }
}

.genderPagestyle {
  background: #f5f6f9;
  margin-top: 65px;

  .container {
    margin: 0 auto;

    .gender_smart_button {
      .inner_section_gender_smart_button {
        text-align: right;

        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .Modal_Readmorestyle {
    padding: 30px;
    position: relative;
    display: flex;

    .ModalInnerStyle {
      max-height: 630px;
      overflow: auto;
      background: #fff;

      // padding-right: 30px;
      h1 {
        font-weight: bold;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
        text-align: center;
        padding-bottom: 30px;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
        line-height: 18px;
        color: #1b1b1b;
      }

      button {
        // margin: 30px auto 10px auto;
        // order: none;
        padding: 15px 32px;
        font-size: 16px;
      }
    }

    .cross_Icon {
      position: absolute;
      top: 15px;
      right: 25px;

      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin: 0 !important;
        cursor: pointer;
      }
    }
  }

  .greybtn {
    padding: 2px 10px 2px 8px;
    background: rgba(208, 208, 208, 0.5);
    border-radius: 10px;
  }

  .loadmore-btn {
    border: 0.9px solid #1b1b1b;
    border-radius: 18px;
    background: rgba(245, 246, 249, 0.5);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #1b1b1b;
    padding: 9px 12px 10px 15px;
    height: 36px;
  }

  .greenbtn {
    background: #71ddc3;
    border-radius: 10px;
    padding: 2px 10px 2px 8px;
  }

  .lightblue {
    background: #cfdfff;
    border-radius: 10px;
    padding: 2px 10px 2px 9px;
  }

  .powerBlue {
    background: #d0f0fd;
    border-radius: 10px;
    padding: 2px 10px 2px 9px;
  }

  .lightGreen {
    background: #c2f4e8;
    border-radius: 10px;
    padding: 2px 10px;
  }

  .lomengreen {
    background: #d1f7c3;
    border-radius: 10px;
    padding: 2px 10px 2px 9px;
  }

  .skyblue {
    background: #9bc6ff;
    border-radius: 10px;
    padding: 2px 10px 2px 9px;
  }

  .yellolight {
    background: #ffe9b6;
    border-radius: 10px;
    padding: 2px 10px 2px 7px;
  }

  .pinklight {
    background: #fee2d5;
    border-radius: 10px;
    padding: 2px 10px;
  }

  .glassgreen {
    background: #d1f7c3;
    border-radius: 10px;
    padding: 2px 10px 2px 7px;
  }

  .pink {
    background-color: #ffdce4;
    border-radius: 10px;
    padding: 2px 10px 2px 9px;
  }

  .purple {
    background-color: #cdb0ff;
    border-radius: 10px;
    padding: 2px 10px 2px 8px;
  }

  .babypink {
    background-color: #ffdaf6;
    border-radius: 10px;
    padding: 2px 10px 2px 9px;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      width: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
        cursor: pointer;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        color: #1b1b1b;
        padding-right: 35px;
        display: flex;
        align-items: flex-start;
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;
    justify-content: space-between;

    .AssementPageWrepper_Left {
      flex: 0 0 74.5%;
      margin-bottom: 18px;
      padding: 35px;
      background: #ffffff;
      border: 1px solid #d0d0d0;
      border-radius: 10px;

      .cardBox {
        border-top: 1px solid #d0d0d0;
        width: 100%;

        .card {
          max-width: 885px;
          width: 100%;
          padding: 25px 10px;
          background-color: #ffffff;
          border-bottom: 1px solid #d0d0d0;

          &:first-child {
            background: rgba(245, 246, 249, 0.5);
          }

          &:last-child {
            border: none;
          }

          .cardLeft {
            width: 100%;
            max-width: 324px;

            .Rating {
              p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;

                color: #1b1b1b;
              }
            }

            span {
              margin: 0 1.5px 5px;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              font-weight: 400;
              border-radius: 10px;
              display: inline-block;
            }
          }

          .cardRight {
            max-width: 400px;
            width: 100%;

            span {
              margin: 0 1.5px 5px;
              padding: 2px 10px;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              font-weight: 400;
              border-radius: 10px;
            }

            // .greenbtn {
            //   background: #71ddc3;
            //   border-radius: 10px;
            //   padding: 2px 10px 2px 8px;
            // }
            // .lightblue {
            //   background: #cfdfff;
            //   border-radius: 10px;
            //   padding: 2px 10px 2px 9px;
            // }
            // .powerBlue {
            //   background: #d0f0fd;
            //   border-radius: 10px;
            //   padding: 2px 10px 2px 9px;
            // }
            // .lightGreen {
            //   background: #c2f4e8;
            //   border-radius: 10px;
            //   padding: 2px 10px;
            // }
            // .lomengreen {
            //   background: #d1f7c3;
            //   border-radius: 10px;
            //   padding: 2px 10px 2px 9px;
            // }
            // .skyblue {
            //   background: #9bc6ff;
            //   border-radius: 10px;
            //   padding: 2px 10px 2px 9px;
            // }
            // .yellolight {
            //   background: #ffe9b6;
            //   border-radius: 10px;
            //   padding: 2px 10px 2px 7px;
            // }
            // .pinklight {
            //   background: #fee2d5;
            //   border-radius: 10px;
            //   padding: 2px 10px;
            // }
            // .glassgreen {
            //   background: #d1f7c3;
            //   border-radius: 10px;
            //   padding: 2px 10px 2px 7px;
            // }
          }
        }
      }
    }
  }

  .AssementPageWrepper_Right {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    flex: 0 0 24%;
    padding: 30px;
    height: 78%;

    .filter {
      border-bottom: 1px solid #d0d0d0;
      padding-bottom: 15px;
      margin-right: 10px;
    }

    .collapse {
      border-bottom: 1px solid #d0d0d0;
      padding-top: 15px;

      .collapsible {
        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          color: #1b1b1b;
          text-align: left;
        }

        cursor: pointer;
        width: 100%;
        border: none;
        outline: none;
      }

      .expand {
        padding-bottom: 15px;
      }

      .content {
        padding: 0 18px;
        display: none;
        overflow: hidden;
        background-color: #f1f1f1;
      }

      // div {

      // }
    }
  }

  .Modal-Box {

    // background-color: #f2f2f2;
    // max-width: 1063px;
    // margin: 0 auto;
    // border: 1px solid #d0d0d0;
    // padding: 45px;
    // border-radius: 10px;
    // margin-bottom: 50px;
    .modal-content {
      margin: 0 auto;
      background-color: white;
      // margin: auto;
      // padding: 20px;
      border: 1px solid #d0d0d0;
      width: 1063px;
      border-radius: 10px;
      padding: 45px;

      .Modal-Header {
        display: flex;

        .close-button {
          width: 14%;
          text-align: right;
          top: -35px;
          position: relative;
          right: -18px;

          span {
            font-size: 40px;
            cursor: pointer;
          }
        }

        .ModalBox-Heading {
          width: 82%;
          padding-bottom: 30px;

          h2 {
            font-size: 25px;
            line-height: 30px;
            color: #1b1b1b;
            font-weight: 700;
            padding-bottom: 20px;
          }

          p {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #1b1b1b;
          }
        }

        .ModalBox-Icon {
          padding-left: 20px;
        }
      }

      .Modal-Content {
        justify-content: space-between;
        padding-bottom: 45px;

        .Modal-Left {
          max-width: 650px;

          .Modal-Para {
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
            word-wrap: break-word;
            // font-weight: 400;
          }

          .Tag-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }
        }

        .Modal-tag {
          span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            display: inline-block;
            margin-right: 5px;
          }
        }

        .Modal-Right {
          width: 240px;
          border-left: 1px solid #d0d0d0;
          padding-left: 30px;

          .Right-Aside {
            .Modal-Aside-content {
              &:not(:last-child) {
                padding-bottom: 15px;
              }

              .title {
                font-weight: 700;
                padding-bottom: 5px;
              }

              p {
                font-family: "Montserrat";
                font-style: normal;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                font-weight: 400;
              }

              span {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
              }
            }
          }
        }
      }

      .Modal-Bottom {
        .Bottom-Header {
          border-top: 1px solid #d0d0d0;
          display: flex;
          justify-content: space-between;
          padding-top: 45px;
        }

        .Testominal {
          max-width: 887px;
          padding-top: 42px;
          flex-wrap: wrap;
          display: flex;
          justify-content: space-between;
          row-gap: 35px;

          .Testominal-Card {
            display: block;
            flex: 0 0 48%;
            max-width: 48%;
            width: 100%;

            h6 {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              padding-bottom: 5px;
            }

            p {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
            }
          }
        }
      }
    }
  }

  .modal {
    // display: none; /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 5%;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
  }
}
</style>
