<template>
  <div class="static" v-if="user && user.organization">
    <div
      class="w-full container mx-auto mt-20 px-4 mb-20 lg:px-0 pb-32"
      v-if="false"
    >
      <div class="flex flex-row mt-32 lg:mt-0 justify-center">
        <div class="flex flex-col w-6/12 lg:w-9/12 items-center justify-center">
          <h1
            class="text-6xl tracking-tighter font-medium text-center print:text-7xl"
            style="color: #201747"
          >
            Welcome to Equilo!
          </h1>
          <div class="flex flex-col mt-10 mx-auto" style="max-width: 960px">
            <p
              class="leading-7 font-medium gap-10 text-justify leading-7 font-medium text-gray-600"
            >
              How can we help you hack gender equality and social inclusion
              today? Whether you are ready to deep-dive into a GESI analysis,
              conduct due diligence, calculate the risk of GBV for investment
              decisions, or find partners to collaborate with globally, Equilo
              is here to help! Get started by making a selection below. Many
              open-source resources are available to help you. Paid features are
              accessible via a subscription.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-16 grid grid-cols-3 gap-4">
        <router-link to="/gesi-analysis/EQ/AGRI_GENERL/snapshot">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/Global-Equality.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>GESI Contextual Analysis</h2>
              <h4>
                Used by anyone to conduct a GESI analysis to understand the
                context/ landscape for project planning, due diligence, advocacy
                efforts, and more. Select a country and sector included in your
                subscription to view a GESI analysis, synthesizing hundreds of
                data points with accompanied qualitative analysis and ideas for
                action.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/Global-Equality.png"
              alt=""
            />
          </div>
        </router-link>
        <router-link to="/global-analysis">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/Global-Analysis.svg"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Global Analysis</h2>
              <h4>
                Used by any organization to explore and compare GESI strengths
                and weaknesses globally by domain, theme, income and region
                classifications. A global gender equality heat and gender
                analysis snapshot leverages thousands of data points to help
                users target countries for investment, develop projects by
                thematic focus, and benchmark countries for learning and
                advocacy purposes.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/Global-Analysis.svg"
              alt=""
            />
          </div>
        </router-link>
        <router-link to="/">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <h2>GBV Risk Score</h2>
              <h4>
                Used by financial system actors to integrate GBV into financial
                models to inform investment decisions. Select a country and
                sector included in your subscription to view the GBV risk score,
                global GBV heat map, materiality of GBV to finance within a
                specific sector, case studies, and ideas for action.
              </h4>
            </article>
            <img
              class="bg-shade"
              style="width: 25%"
              src="https://api.equilo.io/ICONS/Theme icons/gender-based-violence.svg"
              alt=""
            />
          </div>
        </router-link>
      </div>
      <div class="mt-16 grid grid-cols-3 gap-4">
        <router-link to="/2X-Ecosystem" v-if="user && user.organization">
          <div
            class="container_foto container_foto-2x-gender long-text"
            @mouseover="mouseOver(1)"
            @mouseleave="mouseOver(false)"
          >
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left article_cols_two">
              <img
                src="@/assets/images/equilo_genderequalitybuttonicon.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>2X Gender Smart Investment Ecosystem</h2>
              <h4 style="font-size: 13px">
                This integrated platform, co-created with the 2X Global, is to
                support gender smart institutional investors, funds, FIs,
                corporates, MSMEs, and entrepreneurs. Users can assess how
                gender smart their companies and portfolios are, track changes,
                be intelligently matched with investors, technical service
                providers, networks, and deep-dive tools, and prepare for 2X
                Certification
              </h4>
            </article>
            <img class="bg-shade" src="@/assets/images/2X.png" alt="" />
          </div>
        </router-link>

        <!-- ROI assess -->
        <div @click="viewAssessmentRoiPermission()">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>ADVANCED ANALYTICS</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left article_cols_two">
              <img
                src="@/assets/images/Writing-removebg.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Gender ROI™ Assessment</h2>
              <h4 style="font-size: 13px">
                The Gender Resilience, Opportunity, and Inclusion (ROI™)
                Assessment is a robust organizational assessment developed by
                Equilo's partner, SWEEF Capital. It is used by enterprises and
                financial actors of any size to understand how gender smart
                their operations are across leadership, workforce, value chain,
                and their relationship with the broader society. The tool can be
                used for in-depth due diligence and provides instantly analyzed
                results and insights.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/roi/sweef-large.png"
              alt=""
            />
          </div>
        </div>
        <!-- ROI assess -->
        <router-link to="/supergirl">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left article_cols_two">
              <img
                src="@/assets/images/SuperGIRL-name.png"
                alt="visibility-icon"
                class="card-icon height-auto"
              />
              <h2>SuperGIRL</h2>
              <h4 style="font-size: 13px">
                Used by anyone searching for GESI resources to learn, conduct a
                literature review as part of GESI analysis processes, and more.
                Equilo's Curated Super Gender & Inclusion Resource Library
                (SuperGIRL) has thousands of GESI-specific studies, reports,
                guidance documents, and more. Equilo regularly tracks new
                resources to include in the SuperGIRL with its full citation,
                publication year and URL. Resources are tagged by sector-
                sub-sector, thematic area, country, and resource type. Explore
                online, filter by tags, search, and download a .CSV file with
                resources you need.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/SuperGIRL-name.png"
              alt=""
            />
          </div>
        </router-link>
      </div>
      <div class="mt-16 grid grid-cols-3 gap-4">
        <router-link to="/partner-directory">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left article_cols_two">
              <img
                src="@/assets/images/Partner-Directory.svg"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Partner Directory</h2>
              <h4>
                Used by anyone seeking potential partners to collaborate with to
                further collective GESI goals. The partner directory is a
                searchable database of organizations globally working on GESI
                issues, tagged by organization type (e.g. NGO, CBO, government,
                private sector, etc.), country, geographic areas of operation,
                sector and thematic focus, and contact details to get in touch.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/Partner-Directory.svg"
              alt=""
            />
          </div>
        </router-link>

        <router-link to="/tutorials">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/Methodology.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Tutorials</h2>
              <h4>
                Watch our demo video below to see how Equilo can provide
                deep-dive information for all global countries globally across
                18 sectors, with the ability to explore the indicators,
                deep-dive into domain and thematic areas, and get tips and ideas
                on what works to address gaps.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/Methodology.png"
              alt=""
            />
          </div>
        </router-link>
        <router-link to="/support-feedback">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/announcment.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Support and Feedback</h2>
              <h4>
                Need help or want to provide feedback? Complete a quick form
                here.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/announcment.png"
              alt=""
            />
          </div>
        </router-link>
        <!-- Gender Smart Tools -->
        <router-link to="/GenderSmartTools">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/SuperGIRL-alt-2.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>Gender Smart Tools</h2>
              <h4>
                Use this gender smart tool directory to filter and search for
                tools fit your purpose. Search by audience, use case, sector
                geography, asset class and more to find the tool to help you
                improve your gender lens investments and gender smart
                investments.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/SuperGIRL-alt-2.png"
              alt=""
            />
          </div>
        </router-link>
        <!-- End gender Smart Tools -->
        <!-- GBVH Assess Card for future use -->
        <router-link to="/gbvh">
          <div class="container_foto container_foto-2x-gender long-text">
            <div class="open-source"><b>OPEN SOURCE</b></div>
            <div class="ver_mas text-center">
              <img
                src="@/assets/images/eye.png"
                alt="visibility-icon"
                class="visibility-icon"
              />
            </div>
            <article class="text-left">
              <img
                src="@/assets/images/magnifying.png"
                alt="visibility-icon"
                class="card-icon"
              />
              <h2>GBVH Roadmap Assessment</h2>
              <h4>
                The GBVH Roadmap Assessment is a self-assessment diagnostic
                tool. It is used by investors to instantly assess, analyze, and
                plan for GBVH risk and GBVH reduction strategies.
              </h4>
            </article>
            <img
              class="bg-shade"
              src="@/assets/images/2X-assessment/gbvh-roadmap.png"
              alt=""
            />
          </div>
        </router-link>
        <!-- GBVH Assess Card End -->
      </div>

      <!-- Request ROi Admin Modal Start -->
      <div class="fixed z-50 inset-0 overflow-y-auto" v-if="requestAdminModal">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <span class="close" @click="closeRequestModal()" style="right: 8px"
              >&times;</span
            >
            <div class="Modal_Readmorestyle">
              <div class="ModalInnerStyle">
                <p style="font-weight: 600">
                  Thanks for visiting the Gender ROI™ Assess page. Your team's
                  administrator has not shared permissions to allow you to see
                  your organization's Gender ROI™ Assessment results or to
                  initiate a new Gender ROI™ Assessment. Please contact your
                  administrator to request permission to conduct a new survey.
                </p>
                <button
                  style="background-color: #fe5000; color: white"
                  class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                  @click="checkRequestPermission()"
                >
                  Request permission
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->
    </div>

    <investorOrFinancial />
  </div>
</template>
<script>
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import investorOrFinancial from "./investorOrFinancialPage.vue";
import { useUserStore } from "@/stores/user";

export default {
  name: "homePage",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data: function () {
    return {
      active: false,
      paid: false,
      gbvpaid: false,
      user: null,
      requestAdminModal: false,
    };
  },
  components: {
    investorOrFinancial,
  },
  methods: {
    mouseOver: function (index) {
      this.active = index;
    },
    viewAssessmentRoiPermission() {
      if (!this.user.genderRoiAssessment) {
        this.requestAdminModal = true;
      } else {
        this.$router.push({ name: "roi" });
      }
    },

    async closeRequestModal() {
      this.requestAdminModal = false;
    },
    async checkRequestPermission() {
      axios
        .get(env.apiUrl + "/roi/roiAccess-request-to-admin", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  async mounted() {
    this.loading = true;
    if (localStorage.getItem("GbvhAssessUrl")) {
      this.$router.push({ path: localStorage.getItem("GbvhAssessUrl") });
    }
    this.$store.commit("resetCitations");

    this.user = this.userStore.user;
    if (!this.user) {
      await this.userStore.fetchUser();
      this.user = this.userStore.user;
    }

    if (env.gbvteams.includes(this.user.organization.name)) {
      this.gbvpaid = true;
    } else {
      this.gbvpaid = false;
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", []);
    this.$gtag.event("products", {
      page_name: this.$route.name,
      userId: this.user._id,
      product_name: "Home Page",
      team_id: this.user.organization._id,
      organizationName: this.user.organization.name,
    });
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  border-radius: 20px;

  .selected-class {
    background-color: green;
  }
}

.Modal_Readmorestyle {
  padding: 30px 0 10px 35px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: left;
      line-height: 18px;
      color: #1b1b1b;
    }
    button {
      margin: 30px auto;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }
  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}
</style>
