import env from '@/../env';
// configure taabcolors
export function getTaabColors() {
    const taabColors = {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities
    };
    return taabColors;
}
// fill in the values of the labels on hoverbox
export function getLabelValues(_self, point) {
    if (point[0]._datasetIndex === 0) {
        document.getElementById('intersection-name').innerText = _self.country.humanName;
        (_self.countryIntersection[point[0]._index].gender_equality &&
            _self.countryIntersection[point[0]._index].gender_equality !== 'Missing')
            ?
            (
                (document.getElementById('intersection-country').innerText = 'Gender equality score: ' + Math.round(_self.countryIntersection[point[0]._index].gender_equality * 100) / 100)
            )
            :
            (
                (_self.countryIntersection[point[0]._index].gender_equality === 0) ?
                    (document.getElementById('intersection-country').innerText = 'Gender equality score: 0') :
                    (document.getElementById('intersection-country').innerText = 'Gender equality score: NA')
            );
        _self.countryIntersection[point[0]._index].gender_empowerment && _self.countryIntersection[point[0]._index].gender_empowerment !== 'Missing' ? document.getElementById('intersection-region').innerText = 'Empowerment score: ' + Math.round(_self.countryIntersection[point[0]._index].gender_empowerment * 100) / 100 :
            (_self.countryIntersection[point[0]._index].gender_empowerment === 0) ?
                (document.getElementById('intersection-region').innerText = 'Empowerment score: 0') :
                document.getElementById('intersection-region').innerText = 'Empowerment score: NA';
        _self.countryIntersection[point[0]._index].gender_parity && _self.countryIntersection[point[0]._index].gender_parity !== 'Missing' ? document.getElementById('intersection-income').innerText = 'Parity score: ' + Math.round(_self.countryIntersection[point[0]._index].gender_parity * 100) / 100 :
            (_self.countryIntersection[point[0]._index].gender_parity === 0) ? document.getElementById('intersection-income').innerText = 'Parity score: 0' :
                document.getElementById('intersection-income').innerText = 'Parity score: NA';
    } else if (point[0]._datasetIndex === 1) {
        document.getElementById('intersection-name').innerText = _self.country.region;
        _self.regionalIntersection[point[0]._index].gender_equality && _self.regionalIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-country').innerText = 'Gender equality score: ' + Math.round(_self.regionalIntersection[point[0]._index].gender_equality * 100) / 100 : (_self.regionalIntersection[point[0]._index].gender_equality === 0) ? document.getElementById('intersection-country').innerText = 'Gender equality score: 0' : document.getElementById('intersection-country').innerText = 'Gender equality score: NA';
        _self.regionalIntersection[point[0]._index].gender_empowerment && _self.regionalIntersection[point[0]._index].gender_empowerment !== 'Missing' ? document.getElementById('intersection-region').innerText = 'Empowerment score: ' + Math.round(_self.regionalIntersection[point[0]._index].gender_empowerment * 100) / 100 : (_self.regionalIntersection[point[0]._index].gender_empowerment === 0) ? document.getElementById('intersection-region').innerText = 'Empowerment score: 0' : document.getElementById('intersection-region').innerText = 'Empowerment score: NA';
        _self.regionalIntersection[point[0]._index].gender_parity && _self.regionalIntersection[point[0]._index].gender_parity !== 'Missing' ? document.getElementById('intersection-income').innerText = 'Parity score: ' + Math.round(_self.regionalIntersection[point[0]._index].gender_parity * 100) / 100 : (_self.regionalIntersection[point[0]._index].gender_parity === 0) ? document.getElementById('intersection-income').innerText = 'Parity score: 0' : document.getElementById('intersection-income').innerText = 'Parity score: NA';
    } else if (point[0]._datasetIndex === 2) {
        document.getElementById('intersection-name').innerText = _self.country.income;
        _self.incomeIntersection[point[0]._index].gender_equality && _self.incomeIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-country').innerText = 'Gender equality score: ' + Math.round(_self.incomeIntersection[point[0]._index].gender_equality * 100) / 100 : (_self.incomeIntersection[point[0]._index].gender_equality === 0) ? document.getElementById('intersection-country').innerText = 'Gender equality score: 0' : document.getElementById('intersection-country').innerText = 'Gender equality score: NA';
        _self.incomeIntersection[point[0]._index].gender_empowerment && _self.incomeIntersection[point[0]._index].gender_empowerment !== 'Missing' ? document.getElementById('intersection-region').innerText = 'Empowerment score: ' + Math.round(_self.incomeIntersection[point[0]._index].gender_empowerment * 100) / 100 : (_self.incomeIntersection[point[0]._index].gender_empowerment === 0) ? document.getElementById('intersection-region').innerText = 'Empowerment score: 0' : document.getElementById('intersection-region').innerText = 'Empowerment score: NA';
        _self.incomeIntersection[point[0]._index].gender_parity && _self.incomeIntersection[point[0]._index].gender_parity !== 'Missing' ? document.getElementById('intersection-income').innerText = 'Parity score: ' + Math.round(_self.incomeIntersection[point[0]._index].gender_parity * 100) / 100 : (_self.incomeIntersection[point[0]._index].gender_parity === 0) ? document.getElementById('intersection-income').innerText = 'Parity score: 0' : document.getElementById('intersection-income').innerText = 'Parity score: NA';
    } else if (point[0]._datasetIndex === 3) {
        document.getElementById('intersection-name').innerText = 'Global';
        _self.globalIntersection[point[0]._index].gender_equality && _self.globalIntersection[point[0]._index].gender_equality !== 'Missing' ? document.getElementById('intersection-country').innerText = 'Gender equality score: ' + Math.round(_self.globalIntersection[point[0]._index].gender_equality * 100) / 100 : (_self.globalIntersection[point[0]._index].gender_equality === 0) ? document.getElementById('intersection-country').innerText = 'Gender equality score: 0' : document.getElementById('intersection-country').innerText = 'Gender equality score: NA';
        _self.globalIntersection[point[0]._index].gender_empowerment && _self.globalIntersection[point[0]._index].gender_empowerment !== 'Missing' ? document.getElementById('intersection-region').innerText = 'Empowerment score: ' + Math.round(_self.globalIntersection[point[0]._index].gender_empowerment * 100) / 100 : (_self.globalIntersection[point[0]._index].gender_empowerment === 0) ? document.getElementById('intersection-region').innerText = 'Empowerment score: 0' : document.getElementById('intersection-region').innerText = 'Empowerment score: NA';
        _self.globalIntersection[point[0]._index].gender_parity && _self.globalIntersection[point[0]._index].gender_parity !== 'Missing' ? document.getElementById('intersection-income').innerText = 'Parity score: ' + Math.round(_self.globalIntersection[point[0]._index].gender_parity * 100) / 100 : (_self.globalIntersection[point[0]._index].gender_parity === 0) ? document.getElementById('intersection-income').innerText = 'Parity score: 0' : document.getElementById('intersection-income').innerText = 'Parity score: NA';
    } else if (point[0]._datasetIndex === 4) {
        document.getElementById('intersection-info').style.display = 'none';
    }
}
// initialise hoverbox
export function initHoverBox(e, _self, point) {
    document.getElementById('intersection-info').position = 'absolute';
    document.getElementById('intersection-info').style.top = e.pageY + 'px';
    document.getElementById('intersection-info').style.left = e.pageX + 'px';
    document.getElementById('intersection-info').style.display = 'block';
    document.getElementById('intersection-domain').innerText = _self.axes[point[0]._index];
    document.getElementById('intersection-domain').style.backgroundColor = _self.axeColors[point[0]._index];
    document.getElementById('div-intersection-country').style.display = 'block';
    document.getElementById('div-intersection-region').style.display = 'block';
    document.getElementById('div-intersection-income').style.display = 'block';
    document.getElementById('intersection-theme-link').style.display = 'flex';
    document.getElementById('intersection-domain-link').style.display = 'flex';

    // style labels
    document.getElementById('div-intersection-country').style.color = 'black;';
    document.getElementById('div-intersection-region').style.color = 'black';
    document.getElementById('div-intersection-income').style.color = 'black';
    document.getElementById('intersection-country-value').style.display = 'none';
    document.getElementById('intersection-region-value').style.display = 'none';
    document.getElementById('intersection-income-value').style.display = 'none';
    // style goto theme/domain
    document.getElementById('intersection-theme-link').style.background = env.brandingColors.navy;
    document.getElementById('intersection-domain-link').style.background = env.brandingColors.navy;

    document.getElementById('lmic-avg').style.display = 'none';

    document.getElementById('intersection-scores-validity').innerText = Number(_self.intersections[point[0]._index].validity || 0).toFixed(2) == 'NaN' ? ': Unknown' : Number(_self.intersections[point[0]._index].validity || 0).toFixed(2);
    document.getElementById('intersection-scores-power').innerText = Number(_self.intersections[point[0]._index].power || 0).toFixed(2) == 'NaN' ? ': Unknown' : Number(_self.intersections[point[0]._index].power || 0).toFixed(2);
}
