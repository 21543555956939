import { render, staticRenderFns } from "./assessment-climate-table.vue?vue&type=template&id=64bd8528&scoped=true"
import script from "./assessment-climate-table.vue?vue&type=script&lang=js"
export * from "./assessment-climate-table.vue?vue&type=script&lang=js"
import style0 from "./assessment-climate-table.vue?vue&type=style&index=0&id=64bd8528&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bd8528",
  null
  
)

export default component.exports