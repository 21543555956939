<script>
import { Bar } from 'vue-chartjs'
import env from '@/../env.js';

export default {
  name: 'barChartVertical',
  extends: Bar,
  props: ['type', 'chartData', 'chartLabels', 'assessmentYear'],
  data: function () {
    return {
      max: 100,
      beginAtZero: true,
      labels: [],
      data: [],
      datasets: [],
      colors: [env.geoColors.countrybar, env.geoColors.regionbar, env.geoColors.incomebar, env.geoColors.lmicbar, env.chartColors.jotaColor, env.chartColors.muColor, env.chartColors.piColor, env.chartColors],
      line: [],
    }
  },
  methods: {
    toTitleCase: function (phrase) {
      return (phrase || '').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    renderFundsComChart(type) {
      let femaleFirstAssess = [];
      let maleFirstAssess = [];

      if (type) {
        femaleFirstAssess = [
          Number(
            (this.chartData.fundsTotalPtFtWomenEmployees /
              this.chartData.fundsTotalPtFtEmployees) *
            100
          ),
          Number(
            (this.chartData.fundsMidManagerWom /
              this.chartData.fundsMidManagerTot) *
            100
          ),
          Number(
            (this.chartData.fundsFtWomenEmployees /
              this.chartData.fundsFtEmployees) *
            100
          ),
          Number(
            (this.chartData.fundsPtWomenEmployees /
              this.chartData.fundsPtEmployees) *
            100
          ),
          Number(
            (this.chartData.fundsContractorWomenEmployees /
              this.chartData.fundsContractorEmployees) *
            100
          ),
          Number(
            (this.chartData.fundsSupplierWomenEmployees /
              this.chartData.fundsSupplierEmployees) *
            100
          )
        ]
        maleFirstAssess = [
          Number(
            ((this.chartData.fundsTotalPtFtEmployees - this.chartData.fundsTotalPtFtWomenEmployees) /
              this.chartData.fundsTotalPtFtEmployees) *
            100
          ),
          Number(
            ((this.chartData.fundsMidManagerTot - this.chartData.fundsMidManagerWom) /
              this.chartData.fundsMidManagerTot) *
            100
          ),
          Number(
            ((this.chartData.fundsFtEmployees - this.chartData.fundsFtWomenEmployees) /
              this.chartData.fundsFtEmployees) *
            100
          ),
          Number(
            ((this.chartData.fundsPtEmployees - this.chartData.fundsPtWomenEmployees) /
              this.chartData.fundsPtEmployees) *
            100
          ),
          Number(
            ((this.chartData.fundsContractorEmployees - this.chartData.fundsContractorWomenEmployees) /
              this.chartData.fundsContractorEmployees) *
            100
          ),
          Number(
            ((this.chartData.fundsSupplierEmployees - this.chartData.fundsSupplierWomenEmployees) /
              this.chartData.fundsSupplierEmployees) *
            100
          )
        ]
      } else {
        femaleFirstAssess = [
          Number(
            (this.chartData.companyTotalPtFtWomenEmployees /
              this.chartData.companyTotalPtFtEmployees) *
            100
          ),
          Number(
            (this.chartData.companyMidManagerWom /
              this.chartData.companyMidManagerTot) *
            100
          ),
          Number(
            (this.chartData.companyFtWomenEmployees /
              this.chartData.companyFtEmployees) *
            100
          ),
          Number(
            (this.chartData.companyPtWomenEmployees /
              this.chartData.companyPtEmployees) *
            100
          ),
          Number(
            (this.chartData.companyContractorWomenEmployees /
              this.chartData.companyContractorEmployees) *
            100
          ),
          Number(
            (this.chartData.companySupplierWomenEmployees /
              this.chartData.companySupplierEmployees) *
            100
          )
        ]
        maleFirstAssess = [
          Number(
            ((this.chartData.companyTotalPtFtEmployees - this.chartData.companyTotalPtFtWomenEmployees) /
              this.chartData.companyTotalPtFtEmployees) *
            100
          ),
          Number(
            ((this.chartData.companyMidManagerTot - this.chartData.companyMidManagerWom) /
              this.chartData.companyMidManagerTot) *
            100
          ),
          Number(
            ((this.chartData.companyFtEmployees - this.chartData.companyFtWomenEmployees) /
              this.chartData.companyFtEmployees) *
            100
          ),
          Number(
            ((this.chartData.companyPtEmployees - this.chartData.companyPtWomenEmployees) /
              this.chartData.companyPtEmployees) *
            100
          ),
          Number(
            ((this.chartData.companyContractorEmployees - this.chartData.companyContractorWomenEmployees) /
              this.chartData.companyContractorEmployees) *
            100
          ),
          Number(
            ((this.chartData.companySupplierEmployees - this.chartData.companySupplierWomenEmployees) /
              this.chartData.companySupplierEmployees) *
            100
          )
        ]
      }

      if (type) {
        this.max = Math.ceil(
          100 + (100 * 20) / 100
        );
        this.datasets = [
          {
            label: this.assessmentYear[0] + ' - Women',
            data: femaleFirstAssess,
            backgroundColor: '#0077C8',
            stack: 'Stack 0',
          },
          {
            label: this.assessmentYear[0] + ' - Men',
            data: maleFirstAssess,
            backgroundColor: '#FE5000',
            stack: 'Stack 0',
          },
        ]
      } else {
        this.max = Math.ceil(
          100 + (100 * 20) / 100
        );
        this.datasets = [
          {
            label: this.assessmentYear[0] + ' - Women',
            data: femaleFirstAssess,
            backgroundColor: '#0077C8',
            stack: 'Stack 0',
          },
          {
            label: this.assessmentYear[0] + ' - Men',
            data: maleFirstAssess,
            backgroundColor: '#FE5000',
            stack: 'Stack 0',
          },
        ]
      }
      this.max = 100;

      const _self = this;
      this.renderChart(
        {
          labels: this.chartLabels,
          datasets: this.datasets
        },
        {
          fontFamily: "basier",
          legend: {
            display: true,
            labels: {
              "fontSize": 13,
              fontFamily: "basier"
            }
          },
          scales: {
            xAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
                suggestedMax: this.max,
                stepSize: this.max / 5,
                callback: function (value) {
                  var numFormat = new Intl.NumberFormat("en-US");
                  return numFormat.format(value)+'%';
                }
              },

              type: 'linear',
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
          fontSize: 12,
          tooltips: {
            titleFontFamily: "basier",
            bodyFontFamily: "basier",
            callbacks: {
              title: function (tooltipItems) {
                return (
                  _self.datasets[tooltipItems[0].datasetIndex].label
                );
              },
              label: function (tooltipItem) {
                return Number(tooltipItem.yLabel || 0).toFixed(0) + "%";
              },
            },
          },
          annotation: {
            annotations: this.line
          }
        }
      )
    }
  }, mounted() {
    this.$watch('type', type => {
      this.renderFundsComChart(type)
    }, { immediate: true })
  }
}
</script>

<style>

</style>
