<template>

  <div class="w-full">
    <div class="flex flex-col my-5 items-center w-full" v-for="dataset in datasets" v-bind:key="dataset">
      <p class="py-5">Tier ranking</p>

      <div class="flex flex-col w-full" style="width: 300px;">
          <div class="flex flex-row">
              <p v-if="tier == 'tier1'"  class="w-8/12 h-10 mt-3 text-center" style="color: rgb(43, 212, 196); font-size: 15px; font-weight: 500;">Tier 1</p>
              <!-- <p v-else class="w-8/12 h-10 mt-3 text-left" style="color: green; font-size: 15px; font-weight: 500;">Tier 1</p> -->

              <div v-if="tier == 'tier1'" class="h-8 w-10 mb-3 w-4/12"><img :src="country.flagUrl" /></div>
          </div>
          <div class="flex flex-row">
                <p v-if="tier == 'tier2'" class="w-8/12 h-10 mt-3 text-center" style="color: #F6B545; font-size: 15px; font-weight: 500;">Tier 2</p>
              <!-- <p v-else class="w-8/12 h-10 mt-3 text-left" style="color: #F6B545; font-size: 15px; font-weight: 500;">Tier 2</p> -->
              <div v-if="tier == 'tier2'" class="h-8 w-10 mb-3 w-4/12"><img :src="country.flagUrl" /></div>
          </div>
          <div class="flex flex-row">
            <p v-if="tier == 'tier2watch'" class="w-8/12 h-10 mt-3 text-center" style="color: #FE5000; font-size: 15px; font-weight: 500;">Tier 2 Watch</p>
              <!-- <p v-else class="w-8/12 h-10 mt-3 text-left" style="color: rgb(254, 80, 0); font-size: 15px; font-weight: 500;">Tier 2 Watch</p> -->
              <div v-if="tier == 'tier2watch'" class="h-8 w-10 mb-3 w-4/12"><img :src="country.flagUrl" /></div>
          </div>
          <div class="flex flex-row">
            <p v-if="tier == 'tier3'" class="w-8/12 h-10 mt-3 text-center" style="color: red; font-size: 15px; font-weight: 500;">Tier 3</p>
              <!-- <p v-else class="w-8/12 h-10 mt-3 text-left" style="color: rgb(178, 40, 30); font-size: 15px; font-weight: 500;">Tier 3</p> -->
              <div v-if="tier == 'tier3'" class="h-8 w-10 mb-3 w-4/12"><img :src="country.flagUrl" /></div>
          </div>
      </div>


      <!--
      <div class="flex flex-row" style="display:none">
        <div v-for="(datapoint, index) in dataset.data" v-bind:key="datapoint" class="tooltip rounded-full flex items-center justify-center mx-3 text-black" :style="'height: '+datapoint.px/2+'px; width: '+datapoint.px/2+'px; background-color: '+colors[index]">
        </div>
      </div>

    -->

    </div>
  </div>
</template>

<script>


export default {
  name: 'tierChart',
  props: ['chartData', 'chartLabels', 'country', 'index', 'indicator'],
  data: function () {
      return {
        tier: 'tier1',
        data: [],
        datasets: [{}],
      }
  },
  methods: {
    changeText: function(text){

      this.country.values['country'] = this.country.humanName;
      this.country.values['region'] = this.country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' : this.country.region+ ' Region';
      this.country.values['income'] = this.country.income+ ' Group';


      const keys = Object.keys(this.country.values);
      for (var k = 0; k < keys.length; k++) {
        const key = keys[k]
        let value = this.country.values[key];
        text = text.toString().split('{{'+key+'}}').join(value);
        // let's try to convert to a number
        if(!isNaN(Number(text))){
          text = Number(text);
        }
      }

      return text;
    },
    fillIn: function(array){
      if(array && array.length > 0){
        for (var i = 0; i < array.length; i++) {

          // Replace Global
          if(array[i] == 'LMIC'){
            array[i] = 'Global'
          }

          array[i] = this.changeText(array[i]);
        }
        return array;
      } else {
        return []
      }
    }
  },
  mounted () {

// TRF_TPTIER_T_138

/*
    // "value" : "0.33" => TIER2Watch
    // "value" : "0.66" => TIER2
    // "value" : "1" => TIER1
    // "value" : "0" => TIER3
    // "value" : "Missing" => MISSING
*/

    const value = this.changeText(this.chartData[0].data[0]);
     if (value == "Missing" || value == 'Unknown') {
      this.tier = "0";
    } else if (value == "Tier 3" || value == 0) {
      this.tier = "tier3";
    } else if (value == "Tier 2 watch" || value == 0.33) {
      this.tier = "tier2watch";
    } else if (value == "Tier 2" || value == 0.66) {
      this.tier = "tier2";
    } else if (value == "Tier 1" || value == 1) {
      this.tier = "tier1";
    }
    //this.labels = this.fillIn(this.chartLabels);
    //this.data = this.chartData || [];

  }
}
</script>

<style>
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
