import env from "@/../env.js";
import * as d3 from "d3";

// fill in array with text and values
const fillIn = (array, country, indicator) => {
  if (!array || array.length === 0) return [];

  return array.map((item) => {
    if (item === "LMIC") item = "Global";
    return changeText(item, country, indicator);
  });
};

// changes text in array
const changeText = (text, country, indicator) => {
  const { humanName, region, income } = country;
  country.values = {
    ...country.values,
    country: humanName,
    region:
      region === "Eastern Europe & Central Asia"
        ? "Europe & Central Asia"
        : `${region} Region`,
    income: `${toTitleCase(income)} Group`,
  };

  const max =
    indicator?.dataType === "MAX50"
      ? 50
      : indicator?.dataType === "GBVSEX"
      ? 1.1
      : indicator?.dataType === "PEOPLE"
      ? undefined
      : max;
  const beginAtZero = indicator?.dataType === "GBVSEX" ? false : beginAtZero;

  Object.keys(country.values).forEach((key) => {
    const value = country.values[key];
    text = text.toString().replace(`{{${key}}}`, value);

    if (!isNaN(Number(text))) {
      text = Number(text);
    }
  });
  return text;
};

// sets phrase to titleCase
const toTitleCase = (phrase) => {
  return (phrase || "")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// process entire data set
const processDataset = (
  chartData,
  chartLabels,
  colorSchemes,
  colors,
  country,
  indicator
) => {
  const labels = fillIn(chartLabels, country, indicator);
  const data = chartData || [];
  const newData = [];
  data.map((item, index) => {
    let id = item.label.toLowerCase().replace("{", "").replace("}", "");
    if (id.includes("female & male") || id.includes("male & female")) {
      id = "male";
    } else if (
      id.includes("female") ||
      id.includes("women") ||
      id.includes("girl")
    ) {
      id = "female";
    } else if (
      id.includes("male") ||
      id.includes("men") ||
      id.includes("boy")
    ) {
      id = "male";
    }

    const color = colorSchemes[id] || colors[index];

    newData.push({
      label: changeText(item.label, country, indicator),
      data: fillIn(item.data, country, indicator),
      backgroundColor: color,
    });
  });

  return [newData, labels];
};

// color schemes
const colorSchemes = {
  country: env.geoColors.countrybar,
  lmic: env.geoColors.lmicbar,
  income: env.geoColors.incomebar,
  region: env.geoColors.regionbar,
  female: env.genderColors.female,
  male: env.genderColors.male,
};

// colors
const colors = [
  env.geoColors.countrybar,
  env.geoColors.regionbar,
  env.geoColors.incomebar,
  env.geoColors.lmicbar,
  env.chartColors.jotaColor,
  env.chartColors.muColor,
  env.chartColors.piColor,
  env.chartColors,
];

// calculates max value
const getMaxValue = (data) => {
  return Math.round(d3.max(data, (d) => d.value));
};
export {
  processDataset,
  toTitleCase,
  fillIn,
  changeText,
  colorSchemes,
  colors,
  getMaxValue,
};
