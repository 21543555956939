<template>
  <div v-if="isLink" class="fixed z-10 inset-0 overflow-y-auto">
     <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
       <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
       <div
         class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 -mt-3 sm:align-middle sm:w-full max-w-[50%] h-full max-h-[95vh] sm:p-4">
          <div class="loader" v-if="!loaded"></div>
         <iframe ref="iframe"
           :src="isLink"
           frameborder="0" onmousewheel="" width="100%" height="800"
           style="background: transparent"
           @load="handleLoad">
         </iframe>
         <div class="flex justify-between items-center border-t">
           <div></div>
           <button
           @click="closeModal"
           class="bg-[#19486A] font-bold text-white px-4 py-2 rounded-lg shadow mt-3">
           Close
         </button>
         </div>
       </div>
     </div>
  </div>
 </template>
 
 <script>
 export default {
  name: "Feedback-Modal",
  props: {
       isLink: String
  },
  data(){
     return {
       loaded : false
     }
  },
  methods: {
     closeModal() {
       this.$emit("close");
       this.loaded = false
     },
     handleLoad () {
       this.loaded = true;
     }
  },
  beforeUnmount() {
   this.loaded = false
  },
 };
 </script>
<style scoped lang="css">
.loader {
  background: white;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>