<script>
import { Bar } from 'vue-chartjs'

export default {
    name: 'InvesteeFinancePageBarChart',
    extends: Bar,
    props: {
        chartData: {
            type: Array,
            required: true
        },
        chartLabels: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            max: 100,
            beginAtZero: true,
            labels: [],
            datasets: [],
            totalValue1: '',
            totalValue2: '',
        }
    },
    mounted() {
        this.renderMyChart();
    },
    methods: {
        renderMyChart() {
            this.max = 100;
            this.renderChart({
                labels: this.chartLabels,
                datasets: this.chartData
            }, {
                responsive: true,
                maintainAspectRatio: false,
                fontFamily: "basier",
                legend: {
                    position: 'top',
                    display: true,
                    labels: {
                        "fontSize": 13,
                        fontFamily: "basier",
                        usePointStyle: true,
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            max: this.max,
                            min: 0,
                            stepSize: this.max / 5,
                            callback: function (value) {
                                var numFormat = new Intl.NumberFormat("en-US");
                                return numFormat.format(value);
                            }
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.mainTitle ? this.mainTitle : '',
                            fontStyle: 'bold',
                            fontSize: '16',
                            fontFamily: "basier"
                        },
                        beginAtZero: this.beginAtZero,
                        fontFamily: "basier",
                        stacked: true,
                        fontSize: 12,
                    }],
                    xAxes: [{
                        fontSize: 13,
                        autoSkip: false,
                        maxRotation: 0,
                        minRotation: 0,
                        fontFamily: "basier",
                        stacked: true,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            maxTicksLimit: 10,
                        },
                    }]
                },
                tooltips: {
                    titleFontFamily: "basier",
                    bodyFontFamily: "basier",
                    callbacks: {
                        title: function (tooltipItems) {
                            const tt = JSON.parse(JSON.stringify(tooltipItems[0]));
                            const xLabel = tt.xLabel;
                            return xLabel.toString().split(',').join(' ');
                        },
                        label: function (tooltipItem) {
                            var numFormat = new Intl.NumberFormat("en-US");
                            return numFormat.format(tooltipItem.yLabel || 0);
                        }
                    }
                }
            })
        },
    },
    watch: {
        totalValue: function () {
            this.renderMyChart();
        }
    },
}
</script>
<style lang="scss" scoped>
.chartjs-render-monitor {
    // width: 300px !important;
    height: 240px !important;
}
</style>