<template>
  <div class="table-container" v-if="tableData" :key="'updateTableComponents'+updateKey">
    <table class="inner-table">
      <tr class="table-heading-row">
        <th v-for="(head, i) in tableData.heading" :key="'head' + i">
          {{ head }}
        </th>
      </tr>
      <tr
        class="table-content-row"
        v-for="data in tableData.data"
        :key="data.rowHeading"
      >
        <td>{{ data.rowHeading }}</td>
        <td class="text-center">
          <img v-if="data.col1 == 'Yes'" src="@/assets/images/rightsign-2x-challenge.svg" />
          <img v-if="data.col1 == 'No'" src="@/assets/images/Crosscircle-2x-challenge.svg" />
        </td>
        <div class="flex items-center gap-3 justify-end px-3 py-4" v-if="$route.query.fromVerifier && info.surveyQType != 'Calculated'">
          <img
            src="@/assets/images/edit-dark.svg"
            alt="edit-icon"
            class="h-6 w-6 cursor-pointer"
            @click="$emit('openEditModalFromCustomComponent', data)"
          />
          <VerificationDropdown :value="data" />
        </div>
      </tr>
    </table>
  </div>
</template>
<script>
import VerificationDropdown from "../my2xCertificationFullAssessmentResult/verificationDropdown.vue";

export default {
  name: "SalesTable",
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  components: { VerificationDropdown },
  data() {
    return {
      tableData: null,
      updateKey: 0,
    };
  },
  mounted() {
    this.tableData = {};
    this.tableData.heading = ["", new Date().getFullYear()];
    const questions = this.info?.questions.filter(
      (item) => item.value === "Yes" || item.value === "No"
    );
    this.tableData.data = questions.map((item) => {
      return { rowHeading: item.title, col1: item.value, value: item.value, key: item.key, oldKey: item.oldKey, surveyQType: item.surveyQType, isVerified: item.isVerified };
    });
  },
};
</script>
<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #e1e3e9;
  .inner-table {
    width: 100%;
    th,
    td {
      border: 1px solid #e1e3e9;
    }
    .table-heading-row {
      th {
        padding: 16px;
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
          linear-gradient(0deg, #e1e3e9, #e1e3e9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #21191D;
      }
      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }
      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }
    .table-content-row {
      img {
        width: 24px;
        height: 24px;
        margin: auto;
      }
      td {
        border-bottom: none;
        height: 68px;
        padding: 16px 12px 16px 12px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #21191D;
      }
      td:first-child {
        border-left: none;
      }
      td:last-child {
        border-right: none;
      }
    }
  }
  .red {
    color: #FF3A21;
  }
  .green {
    color: #4C9F38;
  }
}
</style>
