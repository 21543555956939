<template>
    <div class="blue-circle tooltip">
        <svg class="m-auto" width="150" height="150" viewBox="-18.75 -18.75 187.5 187.5" version="1.1"
            xmlns="http://www.w3.org/2000/svg" style="transform: rotate(-90deg)">
            <circle r="65" cx="75" cy="75" fill="transparent" stroke="#f4f6f9" stroke-width="27"
                stroke-dasharray="408.2px" stroke-dashoffset="0"></circle>
            <circle r="65" cx="75" cy="75" :stroke="color ? color : '#FE5000'" stroke-width="27" stroke-linecap="round"
                :stroke-dashoffset="blueChartStrokeValue" fill="transparent" stroke-dasharray="408.2px"></circle>
            <text x="40%" y="40%" fill="#2c2e35" font-size="1rem" font-weight="bold"
                style="transform: rotate(90deg) translate(-4%, -77%)">
                {{ chartData }}
            </text>
        </svg>
        <span class="tooltip-text bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200 font-medium">{{ chartData + ' - ' + hoverData[chartData] }}</span>
    </div>
</template>
<script>
export default {
    name: 'PillarDoughnutChart',
    props: ['chartData', 'created', 'color'],
    data: function () {
        return {
            labels: [],
            datasets: [],
            hoverData: [
                'Not doing this in any way',
                'Informal, ad-hoc, or in draft form',
                'Formally adopted and in some level of implementation',
                'Fully operationalized and systematically implemented and practiced'
            ],
            hoverTextIndex: "",
        }
    },
    methods: {
    },
    mounted() { },
    computed: {
        blueChartStrokeValue() {
            let value;
            let convertedValue = (this.chartData/3) * 100;
            const stroke = 408.2;
            value = (stroke / 100) * (100 - convertedValue);
            return value;
        },
    }
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltip-text {
  padding: 20px;
  visibility: hidden;
  background-color: #fff;
  text-align: left;
  position: absolute;
  z-index: 99;
  left: -20%;
  top: 94%;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip .tooltip-text::after {
  right: 95%;
}
</style>
